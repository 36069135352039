import React, { useState } from 'react'
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import moment from 'moment';
import Select from "components/fields/Select";

function General(props) {

    const api_json = {
        purchase_order_number: "",
        shipped_to: "",
        shipping_date: new Date().toLocaleDateString,
        without_shipping_cost: "",
        shipped_from: "",
        shipped_method: "",
        total_po_value: "",
        industry: ""
    }

    const [apiData, setApiData] = useState(api_json)
    const [DatePicker1, setDatePicker1] = useState(new Date());

    const handleChange = (e) => {
        const { name, value } = e.target;
        apiData[name] = value;
        setApiData({ apiData });
    };

    const handleDateChange = (event, name) => {
        if (name === 'shipping_date') {
            let newDate = new Date(event)
            let dateString = newDate.toISOString();

            console.log('newDate', dateString);
            setApiData({
                ...apiData,
                [name]: dateString,
            })

            let newDate1 = moment(new Date(event)).format('YYYY-MM-DD')
            if (name === 'shipping_date') {
                setDatePicker1(newDate1)
            }
        }
    }

    const select_json = [
        { "label": "Chemical", "value": "chemical" },
        { "label": "Chemical1", "value": "chemical1" },
        { "label": "Chemical2", "value": "chemical2" }
    ]
    return (
        <>
            <br></br>
            <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="purchase_order_number"
                        placeholder="Purchase Order Number"
                        required={true}
                        value={apiData.purchase_order_number}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="shipped_to"
                        placeholder="Shipped To"
                        required={true}
                        value={apiData.shipped_to}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="without_shipping_cost"
                        placeholder="Without Shipping Cost"
                        required={true}
                        value={apiData.without_shipping_cost}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="shipped_from"
                        placeholder="Shipped From"
                        required={true}
                        value={apiData.shipped_from}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="shipped_method"
                        placeholder="Shipped Method"
                        required={true}
                        value={apiData.shipped_method}
                        handleChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                    <DateInput
                        placeholder="Shipping Date"
                        value={DatePicker1}
                        handleChange={(value) => handleDateChange(value, "shipping_date")}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Select
                        options={select_json}
                        name="industry"
                        placeholder="Industry"
                        handleChange={handleChange}
                        value={apiData?.apiData?.industry}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="total_po_value"
                        placeholder="Total Po Value"
                        required={true}
                        value={apiData.total_po_value}
                        handleChange={handleChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default General