import React, { useEffect, useRef, useLayoutEffect, useState } from "react";
import SingleToogleTab from "components/UI/ToogleSelect/SingleToogleTab";
import SecondLevelFlow from "./secondLevelFlow";
import { FirstLevelNode } from "./commonNode";
import { ToTitleCase } from "common/utils/toTitleText";
import MultiSteppers from "components/UI/Stepper/MultiLevelStepper";
import { TradeWorkFlow } from "utils/Section/Workflow";

export default ({
  collepseNav,
  handleSecondFlow,
  Data,
  initialState,
  handleSecondFlowSequence,
  secondFlowSequence,
}) => {
  const [stageSequence, setStageSequence] = useState(1);
  const [firstFlow, setFirstFlow] = useState();
  const [currentStage, setCurrentStage] = useState();
  const arr = Data.tradeLifeCycle;
  const ref = useRef(null);
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    if (!Data.error && Data?.tradeLifeCycle?.length > 0) {
      const unique = [
        ...new Set(Data.tradeLifeCycle.map((item) => item.tradeStage)),
      ];
      const finalStatusObject = [];
      unique.forEach((item) => {
        let overAllStatus = "";
        const status = [];
        Data.tradeLifeCycle.forEach((value) => {
          if (item === value.tradeStage) {
            status.push(value.status);
          }
        });
        let count = 0;
        status.forEach((value) => {
          if (value === "NEW") {
            count++;
          }
        });
        if (count === status.length) {
          overAllStatus = "NEW";
        }
        count = 0;
        status.forEach((value) => {
          if (value === "COMPLETED") {
            count++;
          }
        });
        if (count === status.length) {
          overAllStatus = "COMPLETED";
        }
        count = 0;
        status.forEach((value) => {
          if (value === "CANCELLED") {
            count++;
          }
        });
        if (count === status.length) {
          overAllStatus = "CANCELLED";
        }
        count = 0;
        status.forEach((value) => {
          if (value === "IN_PROGRESS") {
            count++;
          }
        });
        if (count > 0) {
          overAllStatus = "IN_PROGRESS";
        }

        finalStatusObject.push({
          tradeStage: item,
          status: overAllStatus,
        });
      });
      setFirstFlow(finalStatusObject);
    }
  }, []);

  const handleClick = (e, value, stage) => {
    setStageSequence(value);
    setCurrentStage(ToTitleCase(stage));
  };

  useEffect(() => {
    handleSecondFlow(stageSequence);
  }, [stageSequence]);

  useEffect(() => {
    if (firstFlow) {
      setCurrentStage(ToTitleCase(firstFlow[0].tradeStage));
    }
  }, [firstFlow]);

  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth);
    const getwidth = () => {
      setWidth(ref.current?.offsetWidth);
    };
    window.addEventListener("resize", getwidth);
  }, []);

  const handleStepClick = (index, value) => {
    setStageSequence(index + 1);
    setCurrentStage(ToTitleCase(value));
  };

  return (
    <>
      <div className="my-4">
        <MultiSteppers
          Value={firstFlow}
          icons={TradeWorkFlow}
          onClick={handleStepClick}
          activeStep={initialState}
        />
      </div>
      <div style={{ borderRadius: "5px", width: "100%" }}>
        <div>
          <div className="my-3">
            <div className="my-4">
              <h6>{`Workflow Sub-Stage: ${currentStage}`}</h6>
            </div>
            <SecondLevelFlow
              initialState={initialState}
              handleSecondFlowSequence={handleSecondFlowSequence}
              Data={Data}
              secondFlowSequence={secondFlowSequence}
              width={window.innerWidth}
            />
          </div>
        </div>
      </div>
    </>
  );
};
