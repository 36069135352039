import { createContext, useState, useRef } from "react";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { NetworkURL } from "../../services/BaseURLInstance";
export const storeContext = createContext(); //type

export const StoreContextProvider = ({ children }) => {
  //context provider

  const { instance, accounts } = useMsal();
  const fetchRef = useRef(false);
  // const [idToken, setIdToken] = useState(null);
  // getIdToken(instance, accounts, setIdToken);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = getClaim(accounts, "company_member_id");
  const [memberList, setMemberList] = useState([]);

  // Using this in Dashboard Screen for Carousel slider
  const [widthSize, SetWidthSize] = useState(50);
  const [activeIndexs, setActiveIndexs] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchData = async (URL) => {
    if (fetchRef.current === false) {
      const idToken = JSON.parse(sessionStorage.getItem("idToken"));
      let payload = {};
      payload.loading = true;
      const requestOptions = {
        method: "GET",
        requireAuthentication: true,
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
      };
      await fetch(URL, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          payload.data = data;
          payload.loading = false;
        })
        .catch((err) => {
          payload.error = err;
          payload.loading = false;
        });
      return payload;
    }
    return () => {
      fetchRef.current = true;
    };
  };

  // Global GET Methods for  fetching data
  const getData = async (URL) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    return await fetch(URL, requestOptions).then((response) => response.json());
  };

  const fetchCompanyList = async (URL) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    return await fetch(URL, requestOptions).then((response) => response.json());
  };

  const fetchMemberData = async (companyId) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    await fetchCompanyList(
      `${process.env.REACT_APP_NETWORK_URL}members?memberId=${companyId}`,
      idToken
    )
      .then((data) => {
        setMemberList(data);
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <storeContext.Provider
      value={{
        fetchCompanyList,
        getData,
        fetchData,
        fetchMemberData,
        memberList,
      }}>
      {children}
    </storeContext.Provider>
  );
};
