import React, { useState, useContext } from 'react';
import Styles from './styles';
import useAxios from "../../../../../services/useAxios";
import { quoteApi, quoteSubmitAPI, loanApi, QuoteAPIEndPoint } from '../../../../../services/BaseURLInstance';
import { useNavigate } from 'react-router-dom';
import { ButtonStyled } from "../../../../../components/Container.styled";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { useEffect } from 'react';
import { storeContext } from '../../../../../contexts/Store/Store'
import Select from "components/fields/Select";
import { NetworkURL } from '../../../../../services/BaseURLInstance';

export default (props) => {
    console.log("DS", props)
    const data = props.data.data
    const [id, setID] = useState()
    const [quote, setQuote] = useState({
        createdBy: "",
        createdDate: 0,
        financeCurrency: "",
        financeDealId: "",
        financeDealStatus: "",
        financeProductCategory: "",
        financeProductType: "",
        financerMemberUID: "",
        financerName: "",
        interestRate: "",
        lastUpdateBy: "",
        lastUpdatedDate: 0,
        notes: "abcd",
        paymentMethodType: "",
        refFinanceRequisitionId: "",
        traderMemberUID: "",
        financeDisbursalDate:""
    });
    const { fetchCompanyList } = useContext(storeContext)
    const { instance, accounts } = useMsal();
    const [companyList, setCompanyList] = useState({
        companies: null,
        userType: ""
    });
    // const [idToken, setIdToken] = useState(null);
    const [enableSyndicate, setEnableSyndicate] = useState(false);
    const [loanTranchesData, setLoanTranchesData] = useState([])
    // getIdToken(instance, accounts, setIdToken);
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const companyId = getClaim(accounts, "company_member_id");
    const [response, setResponse] = useState();
    const navigate = useNavigate();

    // const { response, isLoading, error } = useAxios({
    //     api: quoteApi,
    //     method: "get",
    //     url: `finance/deals/${data.financeDealId}`,
    //     config: JSON.stringify({
    //         requireAuthentication: true,
    //         headers: {
    //             'Content-Type': 'application/json',
    //             "Authorization": "Bearer " + idToken,
    //             "x-api-key":
    //                 "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //         },
    //     }),
    // });
    useEffect(() => {
        let URL = `${QuoteAPIEndPoint}finance/deals/${data.financeDealId}`;
        fetchCompanyList(URL, idToken).then((res) => {
            setResponse(res)
        }).catch((error) => {
            console.log(error)
        })
    }, [])
const handleBackToFinanceDeal =() =>{

}
    useEffect(() => {
        let URL = `${NetworkURL}members?memberId=${companyId}`;
        fetchCompanyList(URL, idToken).then((companyList) => {
            console.log("companyList", companyList)
            let user = companyList.filter(item => {
                if (item.memberUID === companyId) {
                    return item.memberType
                }
            })
            console.log("user", user);
            setCompanyList({
                ...companyList,
                companies: companyList,
                userType: user[0].memberType
            })
        }).catch((error) => {
            console.log(error)
        })
    }, [])
    useEffect(() => {
         if (response?.financeDealId!==undefined) {
            getExistingLoan(response)
          }
            if (response?.financeProductCategory==="SYNDICATE_FINANCE") {
            props.data.setSydicateMenu("syndicatelending");
            //props.data.setHeaderSelect("syndicatelending")
            //props.data.setSyndicate()

        }
    }, [response])
    useEffect(() => {
        if (response && companyList) {
        
            setQuote({
                ...quote,
                loanReferenceId: response.loanReferenceId || "",
                createdBy: response.createdBy || "",
                createdDate: response.createdDate || "",
                financeAmount: response.financeAmount || "",
                financeCurrency: response.financeCurrency || "",
                financeDealId: response.financeDealId || "",
                financeDealStatus: response.financeDealStatus || "",
                financeProductCategory: response.financeProductCategory || "",
                financeProductType: response.financeProductType || "",
                financerMemberUID: response.financerMemberUID || "",
                financerName: response.financerName || "",
                interestRate: response.interestRate || "",
                lastUpdateBy: response.lastUpdateBy || "",
                lastUpdatedDate: response.lastUpdatedDate || "",
                notes: response.notes || "",
                paymentMethodType: response.paymentMethodType || "",
                refFinanceRequisitionId: response.refFinanceRequisitionId || "",
                traderMemberUID: response.traderMemberUID || "",
                requestedFinanceAmount: response.requestedFinanceAmount || "",
                financeRepaymentAmount: response.financeRepaymentAmount || ""

            })
           
        } 
       
    }, [response, companyList])
    const getExistingLoan = async (ele)=>{
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            }
           
        };
        await fetch(`${quoteSubmitAPI}loans?financeDealId=${ele.financeDealId}`, requestOptions)
        .then(res => res.json())
        .then(data => {
            let nData = data;
            if (nData?.length>0 && nData[0]?.loanReferenceId!=="") {
                setID(nData[0]?.loanReferenceId)
                getLoanByID(nData[0])
            // nData = nData.filter(item => item?.financeDealId == ele.financeDealId);
            // console.log("nData-Loans by deals id", nData[0])
            // let datatosend = nData[nData?.length-1];
            // props.data.setSyndicate(datatosend)
            }

        })
        .catch(err => {
            console.log("err", err);
        })
        console.log("while login", quote)
      }
 
      const getLoanByID = async (ele)=>{
        console.log("ele",ele)
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            }
           
        };
        await fetch(`${quoteSubmitAPI}loans/${ele.loanReferenceId}`, requestOptions)
        .then(res => res.json())
        .then(data => {
            let nData = data;
            setLoanTranchesData(nData?.loanTranches)
            props.data.setSydicateMenu("syndicatelending");
            props.data.setSyndicate(nData)
            
        })
        .catch(err => {
            console.log("err", err);
        })
        console.log("while login", quote)
      }
    const handleChange = (e) => {
        let val = e.target.value;
        if (e.target.name === "financeDealExpiryDate") {
            val = parseFloat(val)
        }
        else if (e.target.name === "financeDisbursalDate") {
            val = parseFloat(val)
        }
        else if (e.target.name === "lastUpdatedDate") {
            val = parseFloat(val)
        }
        else if (e.target.name === "createdDate") {
            val = parseFloat(val)
        }
        else if (e.target.name === "financeProductCategory") {
            if (e.target.value === "SYNDICATE_FINANCE") {
                setEnableSyndicate(true)
            }
        }

        setQuote({
            ...quote,
            [e.target.name]: val
        })

    }


    const handleAcceptQuote = async (e, value) => {
        let putData = quote;
        delete putData["loanReferenceId"]
        putData.financeDealStatus = value
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: JSON.stringify(putData)
        };
        //console.log("quoteAPI",`quoteApi}finance/deals/${data.financeDealId}`)
        await fetch(`${quoteSubmitAPI}finance/deals/${data.financeDealId}`, requestOptions)
            .then(res => res.json())
            .then(data => {
 setQuote(data);
                if(value==="INTEREST_EXPRESSED" || value==="DUE_DILIGENCE_INITIATED" 
                || value==="DUE_DILIGENCE_COMPLETED"
                || value==="DECLINED"
                || value==="QUOTE_GENERATED"
                || value==="QUOTE_ACCEPTED"
                || value==="QUOTE_DECLINED"
                || value==="PENDING_T_AND_C_ACCEPTANCE"
                || value==="FINANCE_AGREEMENT_COMPLETE"
                ){
                //updateLoan()
                } 
                else{
                   createLoan() 
                }
            })
            .catch(err => {
                console.log("err", err);
            })
    }

    const createLoan = async () => {

        let nData = quote;

       const putData = {
            createdBy: quote.createdBy,
        createdDate: quote.createdDate,
        financeCurrency: quote.financeCurrency,
        financeProductCategory: quote.financeProductCategory,
        financeProductType: quote.financeProductType,
        financerMemberUID: quote.financerMemberUID,
        interestRate: quote.interestRate,
        lastUpdateBy: quote.lastUpdateBy,
        lastUpdatedDate: quote.lastUpdatedDate,
        paymentMethodType: quote.paymentMethodType,
        //refFinanceRequisitionId: quote.refFinanceRequisitionId,
        financeDealId:quote.financeDealId,
        traderMemberUID: quote.traderMemberUID,
        loanStatus: "DRAFT",
        //Missing element
        loanReferenceId:quote.loanReferenceId,
        referenceFinanceInstrumentId:quote.referenceFinanceInstrumentId,
        paymentCurrencyType:quote.paymentCurrencyType,
        financeDisbursalDate:quote.financeDisbursalDate,
        financeRequisitionId: quote.refFinanceRequisitionId,
        }
        putData["financeDisbursalStatus"] = "DISBURSEMENT_DUE";
        putData["financeRePaymentStatus"] = "REPAYMENT_BLOCKED";

        let loanTranches = [
            // {
            //     "traderMemberUID": quote.traderMemberUID,
            //     "financerMemberUID": quote.financerMemberUID
            // }
        ]
        putData["loanTranches"] = loanTranches;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: JSON.stringify(putData)
        };
        await fetch(`${loanApi}loans`, requestOptions)
            .then(res => res.json())
            .then(data => {
                getLoanByID(data)
                // Line commented - 
               // props.data.setSydicateMenu("syndicatelending");
               // props.data.setSyndicate(data)
            })
            .catch(err => {
                console.log("err", err);
            })
    }


    const updateLoan = async () => {

        let nData = quote;

       const putData = {
            createdBy: quote.createdBy,
        createdDate: quote.createdDate,
        financeCurrency: quote.financeCurrency,
        financeProductCategory: quote.financeProductCategory,
        financeProductType: quote.financeProductType,
        financerMemberUID: quote.financerMemberUID,
        interestRate: quote.interestRate,
        lastUpdateBy: quote.lastUpdateBy,
        lastUpdatedDate: quote.lastUpdatedDate,
        paymentMethodType: quote.paymentMethodType,
        //refFinanceRequisitionId: quote.refFinanceRequisitionId,
        financeDealId:quote.financeDealId,
        traderMemberUID: quote.traderMemberUID,
        loanStatus: "DRAFT",
        //Missing element
        loanReferenceId:quote.loanReferenceId,
        referenceFinanceInstrumentId:quote.referenceFinanceInstrumentId,
        paymentCurrencyType:quote.paymentCurrencyType,
        financeDisbursalDate:quote.financeDisbursalDate
        }
        putData["financeDisbursalStatus"] = "DISBURSEMENT_DUE";
        putData["financeRePaymentStatus"] = "REPAYMENT_BLOCKED";

        let loanTranches = [
            // {
            //     "traderMemberUID": quote.traderMemberUID,
            //     "financerMemberUID": quote.financerMemberUID
            // }
        ]
        putData["loanTranches"] = loanTranches;

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: JSON.stringify(putData)
        };
        await fetch(`${loanApi}loans`, requestOptions)
            .then(res => res.json())
            .then(data => {
                getLoanByID(data)
                // Line commented - 
               // props.data.setSydicateMenu("syndicatelending");
               // props.data.setSyndicate(data)
            })
            .catch(err => {
                console.log("err", err);
            })
    }
    const loanCreate = {
        financeRequisitionId: "",
        traderMemberUID: "",
        financerMemberUID: "",
        loanStatus: "INACTIVE",
        financeProductType: "FINANCE_INVOICE",
        financeProductCategory: "TERM_FINANCE",
        referenceFinanceInstrumentId: "476e09bd-cb60-4d77-b294-3741f18ae10d",
        paymentCurrencyType: "FIAT",
        paymentMethodType: "ACCOUNT",
        financeCurrency: "INR",
        interestRate: "5",
        financeAmount: "1200",
        financeDisbursalDate: "",
        financeDisbursalStatus: "PENDING_INITIATION",
        financeRepaymentAmount: "12",
        financeRepaymentDate: "",
        financeRePaymentStatus: "PENDING_INITIATION",
        loanFees: "123"
    }
    const loanUpdateSyndicate = {
        financeRequisitionId: quote.refFinanceRequisitionId,
        traderMemberUID: "",
        financerMemberUID: "",
        loanStatus: "INACTIVE",
        financeProductType: "FINANCE_PO",
        financeProductCategory: "SYNDICATE_FINANCE",
        referenceFinanceInstrumentId: "476e09bd-cb60-4d77-b294-3741f18ae10d",
        paymentCurrencyType: "FIAT",
        paymentMethodType: "ACCOUNT",
        financeCurrency: "INR",
        interestRate: "5",
        financeAmount: "1200",
        financeDisbursalDate: "",
        financeDisbursalStatus: "PENDING_INITIATION",
        financeRepaymentAmount: "12",
        financeRepaymentDate: "",
        financeRePaymentStatus: "PENDING_INITIATION",
        loanFees: "123"
    }
    const handleSyndicateBookLoan = async () => {
         loanUpdateSyndicate.financeRequisitionId = quote.refFinanceRequisitionId;
        loanUpdateSyndicate.financerMemberUID = quote.financerMemberUID;
        loanUpdateSyndicate.traderMemberUID = quote.traderMemberUID;
        loanUpdateSyndicate.interestRate = quote.interestRate;
        loanUpdateSyndicate.financeDealId = quote.financeDealId;
        loanUpdateSyndicate.referenceFinanceInstrumentId = quote.referenceFinanceInstrumentId ? quote.referenceFinanceInstrumentId : "";
        loanUpdateSyndicate.requestedFinanceAmount = quote.requestedFinanceAmount;
        loanUpdateSyndicate.financeAmount = quote.financeAmount;
        loanUpdateSyndicate.loanFees = quote.loanFees;
        loanUpdateSyndicate["loanTranches"] = loanTranchesData;
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: JSON.stringify(loanUpdateSyndicate)
        };
        
        //console.log("quoteAPI",`quoteApi}finance/deals/${data.financeDealId}`)
        await fetch(`${quoteSubmitAPI}loans/${id}`, requestOptions)
            .then(res => res.json())
            .then(data => {
                
                // props.data.setSydicateMenu("syndicatelending");
                // props.data.setSyndicate(data)
                setQuote(data)
                getLoanByID(data)
            })
            .catch(err => {
                console.log("err", err);
            })
        //console.log("quoteData",quote)
    }

    const handleBookLoan = async () => {
        loanCreate.financeRequisitionId = quote.refFinanceRequisitionId;
        loanCreate.financerMemberUID = quote.financerMemberUID;
        loanCreate.traderMemberUID = quote.traderMemberUID;
        loanCreate.interestRate = quote.interestRate;
        loanCreate.financeDealId = quote.financeDealId;
        loanCreate.referenceFinanceInstrumentId = quote.referenceFinanceInstrumentId ? quote.referenceFinanceInstrumentId : "";
        loanCreate.requestedFinanceAmount = quote.requestedFinanceAmount;
        loanCreate.financeAmount = quote.financeAmount;
        loanCreate.loanFees = quote.loanFees;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: JSON.stringify(loanCreate)
        };
        //console.log("quoteAPI",`quoteApi}finance/deals/${data.financeDealId}`)
        await fetch(`${quoteSubmitAPI}loans`, requestOptions)
            .then(res => res.json())
            .then(data => {
                
                // props.data.setSydicateMenu("syndicatelending");
                // props.data.setSyndicate(data)
                setQuote(data)
                getLoanByID(data)
            })
            .catch(err => {
                console.log("err", err);
            })
        //console.log("quoteData",quote)
    }

    const loan_product_type = [
        { "label": "SYNDICATE_FINANCE", "value": "SYNDICATE_FINANCE" },
        { "label": "TERM_FINANCE", "value": "TERM_FINANCE" }
    ]

    return (
        <Styles>
            <div style={{ marginTop: "33px", marginLeft: "41px" }}></div>
            {
                (quote && companyList.companies) &&
                <div className="loanDetailsFields">
                    <div>
                        <div className="inputFieldView">
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="dealid">Deal Id</label>
                                    </div>
                                    <div>
                                        <input type="text" name="financeDealId" value={quote.financeDealId} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="NBFCID">Seller ID</label>
                                    </div>
                                    <div>
                                        {
                                            (companyList.companies.map(item => {
                                                if (item.memberUID === quote.traderMemberUID) {
                                                    return <input type="text" name="traderMemberUID" value={item.memberName} onChange={handleChange} />
                                                }
                                            }))
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="setOfInput">
                                <div style={{
                                    width: "435px",
                                    margin: "-9px 0px 0px - 8px",
                                    paddingRight: " 5px"
                                }}> 
                                    <Select
                                        options={loan_product_type}
                                        name="financeProductCategory"
                                        placeholder="Loan Product Type"
                                        handleChange={quote.financeDealStatus==="NEW" ? handleChange: null}
                                        value={quote.financeProductCategory}
                                    />
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="InvoiceReference">Invoice Reference</label>
                                    </div>
                                    <div>
                                        <input type="text" name="referenceFinanceInstrumentId" value={quote.refFinanceRequisitionId} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="FinanceAmount">Finance Amount</label>
                                    </div>
                                    <div>
                                        <input type="text" name="financeAmount" value={quote.financeAmount} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="InterestRate">Interest Rate</label>
                                    </div>
                                    <div>
                                        <input type="text" name="interestRate" value={quote.interestRate} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="RequestedFinanceAmount">Requested Finance Amount</label>
                                    </div>
                                    <div>
                                        <input type="text" name="requestedFinanceAmount" value={quote.requestedFinanceAmount} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="SellerID">Finance Status</label>
                                    </div>
                                    <div>
                                        <input type="text" name="financeDealStatus" value={quote.financeDealStatus} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="DealExpiryDate">Deal Expiry Date</label>
                                    </div>
                                    <div>
                                        <input type="text" name="financeDealExpiryDate" value={quote.financeDealExpiryDate} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="PaymentMethod">Payment Method</label>
                                    </div>
                                    <div>
                                        <input type="text" name="paymentMethodType" value={quote.paymentMethodType} onChange={handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="FinanceDate">Finance Date</label>
                                    </div>
                                    <div>
                                        <input type="text" name="financeDisbursalDate" value={quote.financeDisbursalDate} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="CreatedBy">Created By</label>
                                    </div>
                                    <div>
                                        {
                                            (companyList.companies.map(item => {
                                                if (item.memberUID === quote.createdBy) {
                                                    return <input type="text" name="lastUpdateBy" value={item.memberName} onChange={handleChange} />
                                                }
                                            }))
                                        }
                                        {/* <input type="text" name="createdBy" value={quote.createdBy} onChange={handleChange}/> */}
                                    </div>
                                </div>
                            </div>
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="FinanceDate">Currency</label>
                                    </div>
                                    <div>
                                        <input type="text" name="financeCurrency" value={quote.financeCurrency} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="CreatedBy">Financer Id</label>
                                    </div>
                                    <div>
                                        {
                                            (companyList.companies.map(item => {
                                                if (item.memberUID === quote.financerMemberUID) {
                                                    return <input type="text" name="financerMemberUID" value={item.memberName} onChange={handleChange} />
                                                }
                                            }))
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="CreatedDate">Created Date</label>
                                    </div>
                                    <div>
                                        <input type="text" name="createdDate" value={quote.createdDate} onChange={handleChange} />
                                    </div>
                                    <div style={{ marginTop: "40px" }} className="label">
                                        <label For="LastUpdatedBy">Last Updated By</label>
                                    </div>
                                    <div>
                                        {
                                            (companyList.companies.map(item => {
                                                if (item.memberUID === quote.lastUpdateBy) {
                                                    return <input type="text" name="lastUpdateBy" value={item.memberName} onChange={handleChange} />
                                                }
                                            }))
                                        }

                                    </div>
                                    <div style={{ marginTop: "40px" }} className="label">
                                        <label For="LastUpdatedDate">Last Updated Date</label>
                                    </div>
                                    <div>
                                        <input type="text" name="lastUpdatedDate" value={quote.lastUpdatedDate} onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="inputField" >
                                    <div className="label">
                                        <label For="CreatedBy">Append notes in chat:</label>
                                    </div>
                                    <div style={{ width: "10%" }}>
                                        <textarea type="text" placeholder="Some notes from Financer….." />
                                    </div>
                                </div>
                            </div>
                            {/* <div className='requsitionButtonDiv'>
                       <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                        <ButtonStyled onClick={(e)=>handleAcceptQuote(e,"INTEREST_EXPRESSED")}>Accept Quote</ButtonStyled>
                        <ButtonStyled>Decline Quote</ButtonStyled>
                        <ButtonStyled>Abort</ButtonStyled>
                       </div> */}
                            {
                                //console.log(companyList.userType)
                                (quote.financeStatus === "NEW" || enableSyndicate) && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "FINANCER" || enableSyndicate) && <div>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "INTEREST_EXPRESSED")}>Show Interest</ButtonStyled>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline</ButtonStyled>
                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                    <ButtonStyled onClick={handleBookLoan}>Book Loan</ButtonStyled>

                                </div>
                            }
                            {
                                (quote.financeDealStatus === "INTEREST_EXPRESSED") && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "FINANCER") && <div>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DUE_DILIGENCE_INITIATED")}>Initiate Due Diligence</ButtonStyled>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline </ButtonStyled>
                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }
                            {
                                (quote.financeDealStatus === "DUE_DILIGENCE_INITIATED") && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "FINANCER") && <div>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DUE_DILIGENCE_COMPLETED")}>Complete Due Diligence</ButtonStyled>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline </ButtonStyled>
                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }
                            {
                                (quote?.financeProductCategory==="SYNDICATE_FINANCE" && quote.financeDealStatus === "DUE_DILIGENCE_COMPLETED") && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "FINANCER") && <div>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_GENERATED")}>Create Quote</ButtonStyled>
                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }
                            {
                                (quote?.financeProductCategory==="SYNDICATE_FINANCE" && quote.financeDealStatus === "QUOTE_GENERATED") && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "COMPANY") && <div>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_ACCEPTED")}>Accept Quote</ButtonStyled>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_DECLINED")}>Decline Quote</ButtonStyled>
                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }
                            {
                                (quote.financeDealStatus === "QUOTE_DECLINED") && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "FINANCER") && <div>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_GENERATED")}>Revise Quote</ButtonStyled>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline</ButtonStyled>
                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }
                            {
                                (quote.financeDealStatus === "QUOTE_ACCEPTED") && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "FINANCER") && <div>
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "PENDING_T_AND_C_ACCEPTANCE")}>Upload T&C</ButtonStyled>
                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                    <ButtonStyled onClick={handleBookLoan}>Book Loan</ButtonStyled>
                                </div>
                            }
                            {
                                (quote.financeDealStatus === "PENDING_T_AND_C_ACCEPTANCE") && <div className='requsitionButtonDiv'>
                                    {
                                        (companyList.userType === "COMPANY") && <div>
                                            {/* <ButtonStyled onClick={(e) => handleAcceptQuote(e, "SHOW INTEREST")}>Process T&C</ButtonStyled> */}
                                            <ButtonStyled onClick={(e) => handleAcceptQuote(e, "FINANCE_AGREEMENT_COMPLETE")}>Accept T&C</ButtonStyled>

                                        </div>
                                    }
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }
                           {quote?.financeProductCategory==="SYNDICATE_FINANCE" && quote.financeDealStatus === "FINANCE_AGREEMENT_COMPLETE" && (
                <div className="requsitionButtonDiv">
                  {companyList.userType === "FINANCER" && (
                    <div>
                      <ButtonStyled
                        onClick={(e) => handleSyndicateBookLoan(e, { financeDealStatus: "FINANCED" })}
                      >
                        Book Loan
                      </ButtonStyled>
                    </div>
                  )}
                  <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled>
                  <ButtonStyled>Abort</ButtonStyled>
                </div>
              )}

{quote?.financeProductCategory!=="SYNDICATE_FINANCE" && quote.financeDealStatus === "FINANCE_AGREEMENT_COMPLETE" && (
                <div className="requsitionButtonDiv">
                  {companyList.userType === "FINANCER" && (
                    <div>
                      <ButtonStyled
                        onClick={(e) => handleBookLoan(e, { financeDealStatus: "FINANCED" })}
                      >
                        Book Loan
                      </ButtonStyled>
                    </div>
                  )}
                  <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled>
                  <ButtonStyled>Abort</ButtonStyled>
                </div>
              )}
                            {
                                (quote.financeDealStatus === "FINANCED") && <div className='requsitionButtonDiv'>
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                </div>
                            }
                            {
                                (quote.financeDealStatus === "DECLINED") && <div className='requsitionButtonDiv'>
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled onClick={(e) => handleAcceptQuote(e, "SHOW INTEREST")}>Accept Quote</ButtonStyled>
                                    <ButtonStyled>Decline Quote</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }
                            {
                                (quote.financeDealStatus === "ABORT") && <div className='requsitionButtonDiv'>
                                    <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
                                    <ButtonStyled onClick={(e) => handleAcceptQuote(e, "SHOW INTEREST")}>Accept Quote</ButtonStyled>
                                    <ButtonStyled>Decline Quote</ButtonStyled>
                                    <ButtonStyled>Abort</ButtonStyled>
                                </div>
                            }

                        </div>
                    </div>
                </div>

            }
        </Styles >
    )
}