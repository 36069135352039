import react, { useState, useContext } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useNavigate, Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonStyled } from "../../../../../components/Container.styled";
import { useEffect } from "react";
import { storeContext } from "../../../../../contexts/Store/Store";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { NetworkURL } from "../../../../../services/BaseURLInstance";

const Styles = styled.div`
  .row {
    width: 100%;
    display: flex;
  }

  .row > div {
    display: flex;
    height: 50.47px;
    width: 20%;
    justify-content: center;
    align-items: center;
    border: 0.0001px solid #ffffff;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    overflow: hidden;
  }

  .details {
    margin-top: 20px;
    margin-left: 40px;
    padding-bottom: 20px;
    font-family: Roboto;
    font-style: Regular;
    font-size: 18px;
    line-height: 24px;
    line-height: 128%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 26px;
    width: 70%;
  }
  .details-screen {
    display: flex;
  }
  .details-button {
    width: 30%;
    margin-top: 20px;
    margin-left: 120px;
  }
  .details-button > button {
    margin: 4px;
    cursor: pointer;
    height: 50px;
  }
  .details > hr {
    height: 0.0000000001px;
    opacity: 20%;
  }
  .details > div {
  }
`;

const Details = ({ data }) => {
  const { fetchCompanyList } = useContext(storeContext);
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState(null);
  getIdToken(instance, accounts, setIdToken);
  const companyId = getClaim(accounts, "company_member_id");
  const [comapnyList, setCompanyList] = useState("");

  const navigate = useNavigate();
  const [check, setCheck] = useState(true);
  const handleClick = () => {
    if (check) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  };

  useEffect(() => {
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken)
      .then((companyList) => {
        setCompanyList(companyList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleDetails = (e, data) => {
    e.preventDefault();
    localStorage.setItem("loanDetails", JSON.stringify(data));
    navigate("/financedashBoard/pofinancing/loandetails");
  };
  return (
    <Styles>
      {comapnyList && (
        <div>
          {check ? (
            <div className="row">
              <div style={{ borderLeft: "none", paddingLeft: "10px" }}>
                <div onClick={handleClick}>
                  <IoIosArrowDown />
                </div>
                <div onClick={(e) => handleDetails(e, data)}>{data.loanId}</div>
              </div>
              {comapnyList.map((item) => {
                if (item.memberUID === data.financerMemberUID) {
                  return (
                    <div style={{ borderLeft: "none" }}>{item.memberName}</div>
                  );
                }
              })}
              <div style={{ borderLeft: "none" }}>
                <Link
                  to={`/financedashBoard/pofinancing/invoicesDetails/${data.referenceFinanceInstrumentId}`}
                >
                  Invoice Detail
                </Link>
              </div>
              <div style={{ borderLeft: "none" }}>
                {new Date(data.createdDate).toLocaleDateString()}
              </div>
              <div style={{ borderLeft: "none", borderRight: "none" }}>
                <div>{data.loanStatus}</div>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div style={{ borderLeft: "none", paddingLeft: "10px" }}>
                  <div onClick={handleClick}>
                    <IoIosArrowUp />
                  </div>
                  <div onClick={(e) => handleDetails(e, data)}>
                    {data.loanId}
                  </div>
                </div>
                {comapnyList.map((item) => {
                  if (item.memberUID === data.financerMemberUID) {
                    return (
                      <div style={{ borderLeft: "none" }}>
                        {item.memberName}
                      </div>
                    );
                  }
                })}

                <div style={{ borderLeft: "none" }}></div>
                <div style={{ borderLeft: "none" }}>
                  {new Date(data.createdDate).toLocaleDateString()}
                </div>
                <div style={{ borderLeft: "none", borderRight: "none" }}>
                  <div>{data.loanStatus}</div>
                </div>
              </div>
              <div className="details-screen">
                <div className="details">
                  <div>Re-Payment Due :{data.financeRepaymentDate}</div>
                  <hr></hr>
                  <div style={{ display: "flex" }}>
                    <div>LAmount past due :{data.financeRepaymentDate}</div>
                    <span style={{ marginLeft: "30px" }}></span>
                    <div>LAmount past due :{data.financeRepaymentDate}</div>
                  </div>
                  <hr></hr>
                  <div style={{ display: "flex" }}>
                    <div>LAmount past due :{data.financeRepaymentDate}</div>
                    <span style={{ marginLeft: "30px" }}></span>
                    <div>LAmount past due :{data.financeRepaymentDate}</div>
                  </div>
                </div>
                <div className="details-button">
                  <ButtonStyled style={{ width: "200px" }}>
                    Make Payment
                  </ButtonStyled>
                  <ButtonStyled
                    style={{ width: "200px" }}
                    onClick={(e) => handleDetails(e, data)}
                  >
                    View More
                  </ButtonStyled>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Styles>
  );
};
export default Details;
