import React, {useState} from 'react';
import Screen from './login.styled';
import {MdEmail} from "react-icons/md";
import {FiLock} from "react-icons/fi";
import {FaFacebookF} from "react-icons/fa";
import {BsTwitter} from "react-icons/bs";
import {ImGooglePlus} from "react-icons/im";
import { useNavigate } from 'react-router-dom';


const Login = ()=>{

    const navigate=useNavigate();
    const [user,setUser]=useState({
        email:"",
        password:""
    });
    
    const handleChange=(e)=>{
       e.preventDefault();
       const {name,value}=e.target;
       setUser({
           ...user,
           [name]:value
       })
    }
    const handleSignup=()=>{
         navigate('/signup');
    }
    const handleForgotPassword=()=>{
        navigate('/forgotpassword');
   }
   const handleLogin=()=>{
           if(user.email==="admin" && user.password==="admin")
           {
              navigate('/invoicefinancing');
           }
           else
           {
               alert("Wrong Credentials");
               setUser({
                   ...user, 
                   email:"",
                   password:""

               })
           }
   }

    return(
       <Screen>
            <div className='header'>TRADXLINK</div>
            <div className='subHeader'>Seller</div>
            <div className='loginHeading'>Login</div>
            <div className='email'>
                <label For="email"><MdEmail/></label>
                <input type="email" name="email" placeholder="Email Address" value={user.email} onChange={handleChange} />
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <div className='password'>
                <label For="password"><FiLock/></label>
                <input type="password" name="password" placeholder="Password" value={user.password} onChange={handleChange}/>
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <div className='checkBox'>
                <input type="checkbox" className="larger" name="checkBox"/>
                <div className='rememberpassword'>Remember password</div>
                <div className='forgotpassword' onClick={handleForgotPassword}>Forgot password</div>
            </div>
            <button className='loginbutton' onClick={handleLogin}>Login</button>
            <div className='loginIcon'>
                <button className='loginFacebook'><FaFacebookF/></button>
                <button className='loginTwitter'><BsTwitter/></button>
                <button className='loginGmail'><ImGooglePlus/></button>
            </div>
            <div className='accountCheck'>Do not have an account</div>
            <div className='signup' onClick={handleSignup}>Sign-Up</div>
       </Screen>
        
    )
}
export default Login;