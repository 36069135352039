import Axios from "axios";
export const headers = {
  "x-api-key":
    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
};
export const supplierApi = Axios.create({
  baseURL: process.env.REACT_APP_BFF_URL,
  //   https://46971ae5-ae6d-4238-aea4-704590ee2da5.mock.pstmn.io/bff/supplier/dashboard/invoices?memberId=nulla non reprehenderit
});
//invoices?buyerMemberId=adipisicing labore sint tem&sellerMemberId=adipisicing labore sint tem&status=adipisicing labore sint tem
export const financeApi = Axios.create({
  baseURL: process.env.REACT_APP_FINANCE_URL,
  // baseURL: "http://20.204.79.232:50005/",
});

export const docGroupApi = Axios.create({
  baseURL: process.env.REACT_APP_BFF_URL,
});

export const invoiceApi = Axios.create({
  baseURL: process.env.REACT_APP_INVOICE_URL,
});

// export const loanApi = Axios.create({
//   baseURL: "http://20.204.79.232:50005/",
// });

export const requsitionApi = Axios.create({
  baseURL: process.env.REACT_APP_FINANCE_URL,
  // baseURL: "http://20.204.79.232:50005/",
});

export const quoteApi = Axios.create({
  baseURL: process.env.REACT_APP_FINANCE_URL,
  //baseURL: "http://20.204.79.232:50005/",
});

export const documentActivityApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
});
export const shareApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
});
export const networkShareApi = Axios.create({
  baseURL: process.env.REACT_APP_NETWORK_URL,
});

export const DocumentApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
});

// baseURL: "https://315d0ae3-78b1-41d4-aca7-8cacddbc25e5.mock.pstmn.io/"
export const settingUrl = Axios.create({
  baseURL: process.env.REACT_APP_NETWORK_URL,
});

export const companyApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
  //baseURL: "http://20.204.79.232:50010/",
});

export const tradeApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
  //baseURL: "http://20.204.79.232:50010/"
});

export const otherApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
  //baseURL: "http://20.204.79.232:50010/"
});

export const kybApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
  // baseURL: "http://20.204.79.232:50010/"
});

export const financeDocApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
  //baseURL: "http://20.204.79.232:50010/"
});
export const consentApi = Axios.create({
  baseURL: process.env.REACT_APP_DOCUMENT_URL,
});
export const networkApproveUrl = Axios.create({
  baseURL: process.env.REACT_APP_NETWORK_URL,
});
export const misInvoiceApi = Axios.create({
  baseURL: process.env.REACT_APP_INVOICE_URL,
});
export const misFinanceApi = Axios.create({
  baseURL: process.env.REACT_APP_FINANCE_URL,
});

export const addFavNetwork = Axios.create({
  baseURL: process.env.REACT_APP_NETWORK_URL,
});

// export const editcompanytApi = Axios.create({
//   //baseURL: "https://e78db676-dab7-43eb-9712-11ab57ad146b.mock.pstmn.io/",
//   baseURL: "http://localhost:50006/"
// })

// Finance base url
export const financeBaseUrl = Axios.create({
  baseURL: process.env.REACT_APP_FINANCE_URL,
});

// Invoice base url
export const invoiceBaseUrl = Axios.create({
  baseURL: process.env.REACT_APP_INVOICE_URL,
});

// Network member base url
export const networkMemberUrl = Axios.create({
  baseURL: process.env.REACT_APP_NETWORK_URL,
});

export const personMemberId = Axios.create({
  baseURL: process.env.REACT_APP_NETWORK_URL,
});

// Payment base url
export const paymentUrl = Axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_URL,
});

export const paymentServiceURL = process.env.REACT_APP_PAYMENT_URL;

export const networkMemberFav = process.env.REACT_APP_NETWORK_URL;

// for Network section
export const NetworkURL = process.env.REACT_APP_NETWORK_URL;

// For history section
export const HistoryURL = process.env.REACT_APP_HISTORY_URL;

// for Profile section
export const linkMemberURL = process.env.REACT_APP_NETWORK_URL;

// for Profile section
export const profileURL = process.env.REACT_APP_NETWORK_URL;

//For Invoice Section
export const invoiceURL = process.env.REACT_APP_INVOICE_URL;
//For Finance Section
export const financeURL = process.env.REACT_APP_FINANCE_URL;

export const quoteSubmitAPI = process.env.REACT_APP_FINANCE_URL;

export const docUpdateURL = process.env.REACT_APP_DOCUMENT_URL;

export const loanApi = process.env.REACT_APP_FINANCE_URL;

// For docGroup section
export const docGroupURL = process.env.REACT_APP_BFF_URL;

export const documentURL = process.env.REACT_APP_DOCUMENT_URL;
export const serviceURL = process.env.REACT_APP_SERVICE_URL;

// list financiers
export const financierURL = process.env.REACT_APP_NETWORK_URL;
export const tradeURL = process.env.REACT_APP_TRADE_URL;

// OPen trade Account
export const openTradeAccount = process.env.REACT_APP_OPENACCOUNT_URL;

// invoice mis

export const misURL = process.env.REACT_APP_MIS_URL;

// OnBoarding

export const onBoardingURL = process.env.REACT_APP_ONBOARDING_URL;

//New Quote Endpoint
export const QuoteAPIEndPoint = process.env.REACT_APP_FINANCE_URL;

//TradxLink supprot ID
export const TRADXLINK_SUPPORT_ID = process.env.REACT_APP_TRADXLINK_SUPPORT_ID;

// Tracking
export const TRACKING = process.env.REACT_APP_TRACKING;
