import React, { useCallback, useState , useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { IoMdNotifications } from "react-icons/io";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";

import styled from "styled-components";
import style  from "./SavedInvoice.module.css";

import { Summary } from "./summary";
import { Details } from "./details";
import { Workflow } from "./workflow";
import  Financing  from "./financing.jsx";
import {invoiceFinanceContext} from '../../../../../../contexts/invoiceFinance/invoiceFinanceContext'

export const SavedInvoice = () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const [selected, setSelected] = React.useState('');
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const {SavedInvoiceData} = useContext(invoiceFinanceContext)
  
  const data = [
    {
      title: "Workflow",
      description: <Workflow data={SavedInvoiceData} />,
    },

    {
      title: "Summary",
      description: <Summary data={SavedInvoiceData} />,
    },
    {
      title: "Details",
      description: <Details data={SavedInvoiceData} />,
    },
  ];
  
  return (   
    <>
      <div
        onClick={() => handleNavigate(`/financedashBoard/pofinancing/purchaseorder`)}
        style={{ display: "flex", letterSpacing: "1px", color: "#42A5F5" }}
      >
        <span style={{ margin: "0px 10px" }}>
          {" "}
          <HiArrowNarrowLeft size={28} />{" "}
        </span>{" "}
        <span>Back to List </span>
      </div>
  
  { SavedInvoiceData !== '' ? ( 
    <> 
    <ItemDetails>
      <div>
         {SavedInvoiceData && <span>Invoice Number:  {SavedInvoiceData.invoiceNumber} </span>  }
      </div>
      <div>
        <span><FiMail size ={28}/> </span>
        <span><IoMdNotifications size = {28} /> </span>
      </div>
    </ItemDetails>
    <AccordionWrapper> 
    <Accordion>
             {data.map( (item, i) => (
               <div style={cardWapper} key={i}>
                 <div style = {title} onClick={() => toggle(i)}>
                   <span> {item.title}</span>
                   <span >
                     { selected === i ? <CgChevronUp size={30}  /> : <CgChevronDown size={30}/>}
                    </span>
                 </div>
                 <div className = { selected === i ? 'show' : 'description'}>{item.description}</div>
               </div>
             ))
             }
    </Accordion>
    <Financing/>
    </AccordionWrapper>
    </>
    ) : (
      handleNavigate(`/financedashBoard/pofinancing/purchaseorder`)
    )}
    </>
  );
};

const ItemDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  // border:1px solid red;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #2e2e2e;
  font-weight: bold;
  letter-spacing: 1px;

  span {
    margin-right: 10px;
  }
`;

const cardWapper = {
  marginBottom: "15px",
  borderBottom: "0",
  margin: "10px 0px",
  backgroundColor: "black",
  borderRadius: "5px",
  letterSpacing: "2px",
  padding: "10px 20px",
};

const title = {
  // color : "red",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  marginTop: "10px",
};

const Accordion = styled.div`
  width: 100%;
`;

const AccordionWrapper = styled.div`
  padding: 10px 20px;
  background: rgb(46, 46, 46);
  border-radius: 10px;
`;
