import React from "react";
import Input from "components/fields/Input";
import MultiSelect from "components/fields/MultiSelect";
import Button from "components/UI/Button/Button";
import { useState } from "react";
import TextArea from "components/fields/TextArea";
import Content from "components/UI/Layout/Content";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import classes from "./edit.module.css";
import CountryCodes from "jsons/CountryCodes.json";
import { AiOutlineUpload } from "react-icons/ai";
import cn from "classnames";
const AddProduct = () => {
  const [Data, setData] = useState({
    name: "",
    category: "",
    others: "",
    issue: "",
    expiry: "",
    issuing: "",
    country: "",
    description: "",
  });

  const [documentData, setDocumentData] = useState({
    name: "",
    file: "",
  });

  const handleChange = (event) => {
    if (event?.target?.name === "name" || event?.target?.name === "others") {
      setData({
        ...Data,
        [event?.target?.name]: event.target.value,
      });
    }
  };

  const handleDocumentChange = (event) => {
    setDocumentData({
      ...documentData,
      file: event.target.files[0],
      name: event.target.files[0].name,
    });
  };

  return (
    <Content>
      <div>
        <h6 className="p-2">Add Certificate</h6>
      </div>
      <form>
        <div className="d-flex">
          <div className="p-2" style={{ flex: 1 }}>
            <Input
              name="name"
              placeholder="Product Name"
              value={Data?.name}
              handleChange={(event) => handleChange(event)}
            />
          </div>
          <div className="p-2" style={{ flex: 1 }}>
            <MultiSelect
              options={CountryCodes}
              isSingle={true}
              name="category"
              placeholder="Country of Manufacture"
              selected={Data?.category}
              handleChange={(event) => handleChange(event)}
            />
          </div>
        </div>
        <div className="d-flex">
          <div className="p-2" style={{ flex: 1 }}>
            <TextArea rows={4} placeholder="Product Description" />
          </div>
          <div className="p-2" style={{ flex: 1 }}>
            <TextArea rows={4} placeholder="Pricing Description" />
          </div>
        </div>

        <div className="d-flex">
          <div className="p-2" style={{ flex: 1 }}>
            <MultiSelect
              options={CountryCodes}
              isSingle={true}
              name="category"
              placeholder="Product Category"
              selected={Data?.category}
              handleChange={(event) => handleChange(event)}
            />
          </div>
          <div className="p-2" style={{ flex: 1 }}>
            <Input
              name="others"
              value={Data?.others}
              placeholder="HSN Code"
              handleChange={(event) => handleChange(event)}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="p-2" style={{ flex: 1 }}>
            <MultiSelect
              options={CountryCodes}
              isSingle={true}
              name="category"
              placeholder="Hazardous Goods"
              selected={Data?.category}
              handleChange={(event) => handleChange(event)}
            />
          </div>
          <div className="p-2" style={{ flex: 1 }}>
            <Input
              name="others"
              value={Data?.others}
              placeholder="Product Web Link"
              handleChange={(event) => handleChange(event)}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="p-2" style={{ flex: 1 }}>
            <MultiSelect
              options={CountryCodes}
              isSingle={true}
              name="category"
              placeholder="Hazardous Category"
              selected={Data?.category}
              handleChange={(event) => handleChange(event)}
            />
          </div>
          <div className="p-2" style={{ flex: 1 }}>
            <Input
              name="others"
              value={Data?.others}
              placeholder="UN Number"
              handleChange={(event) => handleChange(event)}
            />
          </div>
        </div>

        <div className="d-flex">
          <div className="p-2" style={{ flex: 1 }}>
            <Input
              name="name"
              value={Data?.name}
              placeholder="Product Category"
              handleChange={(event) => handleChange(event)}
            />
          </div>
          <div className="p-2" style={{ flex: 1 }}></div>
        </div>

        <div>
          <span>
            <label
              className="d-flex p-2"
              onChange={handleDocumentChange}
              htmlFor="formId">
              <div className={cn(classes.AddLink, classes.UploadDiv)}>
                <span style={{ color: "#42a5f5" }}>
                  <input
                    name="file"
                    accept=".doc,.  docx,.pdf,.jpeg"
                    type="file"
                    id="formId"
                    hidden
                  />
                </span>
                <span>Product Sample Images & Vides(Max-5mb)</span>
                <span>
                  {documentData?.name?.split(".").slice(0, -1).join(".")}
                </span>
              </div>
              <div className={classes.buttonUpload}>
                Attach Images & Videos <AiOutlineUpload size={22} />
              </div>
            </label>
          </span>
        </div>
        <ButtonDiv>
          <Button type="submit">Create Product</Button>
        </ButtonDiv>
      </form>
    </Content>
  );
};

export default AddProduct;
