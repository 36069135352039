import React from "react";
import TextField from "@mui/material/TextField";
import styles from "./Fields.module.css";
import { styled } from "@mui/material/styles";
import {HiOutlineDocumentText} from 'react-icons/hi';

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgb(66, 165, 245)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(66, 165, 245)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&:hover fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&.MuiFormHelperText-root": {
      color: "white",
    },
  },
});

export default ({
  name,
  placeholder,
  required,
  handleChange,
  value,
  readOnly,
  type,
  helperText,
  max,
  cursor,
  buttonEnable,
  onClick,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <CssTextField
        type={type ? type : "text"}
        name={name}
        max={max}
        required={required}
        className={styles.inputStyle}
        label={placeholder}
        onChange={handleChange}
        value={value}
        helperText={helperText}
        FormHelperTextProps={{
          style: { color: "rgb(66, 165, 245)" },
        }}
        inputProps={{
          readOnly: readOnly,
          style: {
            cursor: cursor ? "pointer" : "text",
            color: "rgb(66, 165, 245)",
            height: "auto", // Set a fixed height for the input field
          },
        }}
        autoComplete="off"
        style={{ color: "rgb(66, 165, 245)" }}
        InputProps={{
          endAdornment: buttonEnable ? <button onClick={onClick} style={{ backgroundColor:"#42A5F5" }}><HiOutlineDocumentText/></button> : "",
        }}
        InputLabelProps={{
          style: {
            color: "rgb(66, 165, 245)",
            shrink: true, // Prevent label from shrinking when there is a value
          },
        }}
      />
    </div>
  );
};
