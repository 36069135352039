import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import "react-datepicker/dist/react-datepicker.css";
import { Grid } from "@mui/material";
import DateInput from "components/fields/DateInput";
import Input from "components/fields/Input";
import Textarea from "components/fields/TextArea";
import Select from "components/fields/Select";
import ConditionCheck from "jsons/conditionCheck.json";
import Currenciesr from "jsons/Currenciesr.json";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { FindCompanyName } from "utils/helper";
import { CompanyList } from "utils/API/CompanyList.js";
import Loader from "components/Loader/Loader";
import cn from "classnames";
import classes from "./quote.module.css";
import { truncateStringID } from "../../../../../utils/helper";
import { fetchMemberdata } from "utils/memberchat";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import PaymentScreen from "./paymentScreen";
import { UseRequest } from "utils/API/Request.js";
import { profileURL } from "services/BaseURLInstance";
import { getIdTokenAsync } from "utils/authUtil";

export default ({
  status,
  handledealStatus,
  serviceDealDetails,
  hanndleCraateGroup,
  setButton,
  setPaymentDetailsScreenParent,
  paymentData,
}) => {
  const { response, isLoading, isError } = CompanyList();

  const payment = [
    { value: "ACCOUNT_TRANSFER", label: "ACCOUNT_TRANSFER" },
    { value: "CHEQUE", label: "CHEQUE" },
    { value: "SI", label: "SI" },
  ];
  // const company_Id = JSON.parse(sessionStorage.getItem("companyId"));
  const [quoteData, setQuoteData] = useState(serviceDealDetails);
  const [currentUser, setCurrentUser] = useState("consumer");
  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const navigate = useNavigate();
  const [toast, setToast] = useState();
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isServiceCompleted, setIsServiceCompleted] = useState(false);
  const [isDeliveryDeclined, setIsDeliveryDeclined] = useState(false);
  const [paymentDetailsScreen, setPaymentDetailsScreen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [personData, setPersonData] = useState(null);

  const fetchData = async (companyId, id2, name) => {
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        hanndleCraateGroup(nData, name, name);
        setMemberList(nData);
      });
  };

  useEffect(() => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${serviceDealDetails.lastUpdateBy}`,
        null,
        response
      ).then((data) => {
        setPersonData(data);
      });
    });
  }, []);

  useEffect(() => {
    if (companyId === serviceDealDetails.consumerMemberUID) {
      setCurrentUser("consumer");
    } else {
      setCurrentUser("provider");
    }
  }, []);

  const handleChange = (e, value) => {
    if (value === "servicePaymentCurrency") {
      setQuoteData({
        ...quoteData,
        servicePaymentCurrency: e.target.value,
      });
    } else if (value === "preferredPaymentMethod") {
      setQuoteData({
        ...quoteData,
        preferredPaymentMethod: e.target.value,
      });
    } else if (value === "modeOfPayment") {
      setQuoteData({
        ...quoteData,
        modeOfPayment: e.target.value,
      });
    } else if (value === "lastUpdatedDate") {
      setQuoteData({
        ...quoteData,
        lastUpdatedDate: e.toISOString(),
      });
    } else if (value === "preferredPaymentDate") {
      setQuoteData({
        ...quoteData,
        preferredPaymentDate: e.toISOString(),
      });
    } else if (value === "createdDate") {
      setQuoteData({
        ...quoteData,
        createdDate: e.toISOString(),
      });
    } else if (value === "serviceDescription") {
      setQuoteData({
        ...quoteData,
        serviceDescription: e.target.value,
      });
    } else if (value === "pricingDescription") {
      setQuoteData({
        ...quoteData,
        pricingDescription: e.target.value,
      });
    } else if (value === "servicePriceOffer") {
      setQuoteData({
        ...quoteData,
        servicePriceOffer: e.target.value,
      });
    } else if (value === "taxes") {
      setQuoteData({
        ...quoteData,
        taxes: e.target.value,
      });
    }
  };

  const handlePaymentScreenOpen = (e, status) => {
    setCurrentStatus(status);
    setPaymentDetailsScreen(true);
  };

  const statusUpdateAfterPaymentVarify = (e, value, result) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let data = quoteData;
    data.serviceDealStatus = value;
    data.paymentProviderName = result.paymentProviderName;
    data.RefSellerVirtualBankAccountId = result.RefSellerVirtualBankAccountId;
    data.RefBuyerVirtualBankAccountId = result.RefBuyerVirtualBankAccountId;
    if (quoteData.serviceDealStatus === "INTEREST_EXPRESSED") {
      Success("Interest Expressed Successfully");
    } else if (quoteData.serviceDealStatus === "QUOTE_CREATED") {
      Success("Quote Created Successfully");
    } else if (quoteData.serviceDealStatus === "QUOTE_ACCEPTED") {
      Success("Quote Accepted Successfully");
    } else if (quoteData.serviceDealStatus === "QUOTE_DECLINED") {
      Success("Quote Declined Successfully");
    } else if (quoteData.serviceDealStatus === "TNC_ACCEPTANCE_PENDING") {
      Success("T&C Uploaded Successfully");
    } else if (quoteData.serviceDealStatus === "BID_COMPLETED") {
      Success("T&C Confirmed Successfully");
    } else if (quoteData.serviceDealStatus === "BID_DECLINED") {
      Success("T&C Declined");
    } else if (quoteData.serviceDealStatus === "SERVICE_IN_PROGRESS") {
      Success("Service Initiated");
    } else if (quoteData.serviceDealStatus === "DELIVERED") {
      Success("Service Delivered Successfully");
    } else if (quoteData.serviceDealStatus === "PAYMENT_PENDING") {
      Success("Payment Requested Successfully");
    } else if (quoteData.serviceDealStatus === "ABORTED") {
      Success("Service Deal Aborted Successfully");
    } else if (quoteData.serviceDealStatus === "COMPLETED") {
      Success("Service Completed Successfully");
    } else if (quoteData.serviceDealStatus === "DELIVERY_CONFIRMED") {
      Success("Service Delivery Confirmed");
    } else if (quoteData.serviceDealStatus === "PAYMENT_COMPLETED") {
      Success("Payment Initiated Successfully");
    }

    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data),
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${serviceDealDetails.serviceDealId}`,
        requestOptions
      )
        .then((data) => data.json())
        .then((response) => {
          handledealStatus(value);
          setQuoteData(response);
          // setToast(`Status Updated Successfully`);
          setTimeout(() => {
            navigate("../service-deals");
          }, 3000);
        });
    };
    fetchData();
  };

  const handleClick = (e, value) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let data = quoteData;
    data.serviceDealStatus = value;
    if (quoteData.serviceDealStatus === "INTEREST_EXPRESSED") {
      Success("Interest Expressed Successfully");
    } else if (quoteData.serviceDealStatus === "QUOTE_CREATED") {
      Success("Quote Created Successfully");
    } else if (quoteData.serviceDealStatus === "QUOTE_ACCEPTED") {
      Success("Quote Accepted Successfully");
    } else if (quoteData.serviceDealStatus === "QUOTE_DECLINED") {
      Success("Quote Declined Successfully");
    } else if (quoteData.serviceDealStatus === "TNC_ACCEPTANCE_PENDING") {
      Success("T&C Uploaded Successfully");
    } else if (quoteData.serviceDealStatus === "BID_COMPLETED") {
      Success("T&C Confirmed Successfully");
    } else if (quoteData.serviceDealStatus === "BID_DECLINED") {
      Success("T&C Declined");
    } else if (quoteData.serviceDealStatus === "SERVICE_IN_PROGRESS") {
      Success("Service Initiated");
    } else if (quoteData.serviceDealStatus === "DELIVERED") {
      Success("Service Delivered Successfully");
    } else if (quoteData.serviceDealStatus === "PAYMENT_PENDING") {
      Success("Payment Requested Successfully");
    } else if (quoteData.serviceDealStatus === "ABORTED") {
      Success("Service Deal Aborted Successfully");
    } else if (quoteData.serviceDealStatus === "COMPLETED") {
      Success("Service Completed Successfully");
    } else if (quoteData.serviceDealStatus === "DELIVERY_CONFIRMED") {
      Success("Service Delivery Confirmed");
    } else if (quoteData.serviceDealStatus === "PAYMENT_COMPLETED") {
      Success("Payment Initiated Successfully");
    }

    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data),
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${serviceDealDetails.serviceDealId}`,
        requestOptions
      )
        .then((data) => data.json())
        .then((response) => {
          handledealStatus(value);
          setQuoteData(response);
          // setToast(`Status Updated Successfully`);

          navigate("../service-deals");
        });
    };
    fetchData();
  };

  const handleClickDecline = (e, value) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let data = quoteData;
    data.serviceDealStatus = value;
    if (
      quoteData.serviceDealStatus === "SERVICE_IN_PROGRESS" &&
      isDeliveryDeclined === "true"
    ) {
      Success("Service Delivery Declined");
    }

    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data),
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${serviceDealDetails.serviceDealId}`,
        requestOptions
      )
        .then((data) => data.json())
        .then((response) => {
          handledealStatus(value);
          setIsDeliveryDeclined(true);
          setQuoteData(response);
          Success("Service Delivery Declined");
          // setToast(`Status Updated Successfully`);
          setTimeout(() => {
            navigate("../service-deals");
          }, 3000);
        });
    };
    fetchData();
  };

  const chatHandler = (data) => {
    if (data?.consumerMemberUID !== "" && data?.providerMemberUID !== "") {
      setItem(data);
      let name = data.serviceDealId
        ? truncateStringID(data.serviceDealId, 7, "SD")
        : "NA";
      fetchData(data?.consumerMemberUID, data?.providerMemberUID, name);
    }
  };

  console.log("quoteDataquoteData", quoteData);
  return (
    <>
      {isLoading ? (
        <>
          <span>Loading...</span>
          <Loader />
        </>
      ) : (
        <>
          {paymentDetailsScreen ? (
            <PaymentScreen
              setPaymentDetailsScreen={setPaymentDetailsScreen}
              statusUpdateAfterPaymentVarify={statusUpdateAfterPaymentVarify}
              currentStatus={currentStatus}
              quoteData={quoteData}
            />
          ) : (
            response &&
            quoteData && (
              <>
                <div style={{ marginTop: "2rem" }}>
                  <Grid item xs={12} md={6} style={{ padding: "10px 0px" }}>
                    <Textarea
                      placeholder="Service Description"
                      readOnly={
                        (((quoteData.serviceDealStatus === "QUOTE_ACCEPTED" ||
                          quoteData.serviceDealStatus === "QUOTE_CREATED" ||
                          quoteData.serviceDealStatus ===
                            "TNC_ACCEPTANCE_PENDING" ||
                          quoteData.serviceDealStatus === "BID_COMPLETED" ||
                          quoteData.serviceDealStatus === "BID_DECLINED" ||
                          quoteData.serviceDealStatus ===
                            "SERVICE_IN_PROGRESS" ||
                          quoteData.serviceDealStatus === "DELIVERED" ||
                          quoteData.serviceDealStatus === "PAYMENT_PENDING" ||
                          quoteData.serviceDealStatus === "ABORTED" ||
                          quoteData.serviceDealStatus === "COMPLETED" ||
                          quoteData.serviceDealStatus ===
                            "DELIVERY_CONFIRMED") &&
                          currentUser === "provider") ||
                          currentUser === "consumer") &&
                        true
                      }
                      value={quoteData.serviceDescription}
                      handleChange={(e) =>
                        handleChange(e, "serviceDescription")
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px 0px" }}>
                    <Textarea
                      placeholder="Pricing Description"
                      readOnly={
                        (((quoteData.serviceDealStatus === "QUOTE_ACCEPTED" ||
                          quoteData.serviceDealStatus === "QUOTE_CREATED" ||
                          quoteData.serviceDealStatus ===
                            "TNC_ACCEPTANCE_PENDING" ||
                          quoteData.serviceDealStatus === "BID_COMPLETED" ||
                          quoteData.serviceDealStatus === "BID_DECLINED" ||
                          quoteData.serviceDealStatus ===
                            "SERVICE_IN_PROGRESS" ||
                          quoteData.serviceDealStatus === "DELIVERED" ||
                          quoteData.serviceDealStatus === "PAYMENT_PENDING" ||
                          quoteData.serviceDealStatus === "ABORTED" ||
                          quoteData.serviceDealStatus === "COMPLETED" ||
                          quoteData.serviceDealStatus ===
                            "DELIVERY_CONFIRMED") &&
                          currentUser === "provider") ||
                          currentUser === "consumer") &&
                        true
                      }
                      value={quoteData.pricingDescription}
                      handleChange={(e) =>
                        handleChange(e, "pricingDescription")
                      }
                    />
                  </Grid>
                  <Grid container>
                    {/* <Grid item xs={12} md={6} style={{ padding: "10px 10px" }}> */}
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ paddingRight: "10px", paddingTop: "10px" }}>
                      <Input
                        placeholder="Price Quote *"
                        readOnly={
                          (((quoteData.serviceDealStatus === "QUOTE_ACCEPTED" ||
                            quoteData.serviceDealStatus === "QUOTE_CREATED" ||
                            quoteData.serviceDealStatus ===
                              "TNC_ACCEPTANCE_PENDING" ||
                            quoteData.serviceDealStatus === "BID_COMPLETED" ||
                            quoteData.serviceDealStatus === "BID_DECLINED" ||
                            quoteData.serviceDealStatus ===
                              "SERVICE_IN_PROGRESS" ||
                            quoteData.serviceDealStatus === "DELIVERED" ||
                            quoteData.serviceDealStatus === "PAYMENT_PENDING" ||
                            quoteData.serviceDealStatus === "ABORTED" ||
                            quoteData.serviceDealStatus === "COMPLETED" ||
                            quoteData.serviceDealStatus ===
                              "DELIVERY_CONFIRMED") &&
                            currentUser === "provider") ||
                            currentUser === "consumer") &&
                          true
                        }
                        handleChange={(e) =>
                          handleChange(e, "servicePriceOffer")
                        }
                        value={quoteData.servicePriceOffer}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px 0px" }}>
                      <Input
                        placeholder="Taxes *"
                        readOnly={
                          (((quoteData.serviceDealStatus === "QUOTE_ACCEPTED" ||
                            quoteData.serviceDealStatus === "QUOTE_CREATED" ||
                            quoteData.serviceDealStatus ===
                              "TNC_ACCEPTANCE_PENDING" ||
                            quoteData.serviceDealStatus === "BID_COMPLETED" ||
                            quoteData.serviceDealStatus === "BID_DECLINED" ||
                            quoteData.serviceDealStatus ===
                              "SERVICE_IN_PROGRESS" ||
                            quoteData.serviceDealStatus === "DELIVERED" ||
                            quoteData.serviceDealStatus === "PAYMENT_PENDING" ||
                            quoteData.serviceDealStatus === "ABORTED" ||
                            quoteData.serviceDealStatus === "COMPLETED" ||
                            quoteData.serviceDealStatus ===
                              "DELIVERY_CONFIRMED") &&
                            currentUser === "provider") ||
                            currentUser === "consumer") &&
                          true
                        }
                        handleChange={(e) => handleChange(e, "taxes")}
                        value={quoteData.taxes}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ paddingRight: "10px", paddingTop: "10px" }}>
                      <Select
                        name="currency"
                        placeholder="Currency"
                        readOnly={
                          (((quoteData.serviceDealStatus === "QUOTE_ACCEPTED" ||
                            quoteData.serviceDealStatus === "QUOTE_CREATED" ||
                            quoteData.serviceDealStatus ===
                              "TNC_ACCEPTANCE_PENDING" ||
                            quoteData.serviceDealStatus === "BID_COMPLETED" ||
                            quoteData.serviceDealStatus === "BID_DECLINED" ||
                            quoteData.serviceDealStatus ===
                              "SERVICE_IN_PROGRESS" ||
                            quoteData.serviceDealStatus === "DELIVERED" ||
                            quoteData.serviceDealStatus === "PAYMENT_PENDING" ||
                            quoteData.serviceDealStatus === "ABORTED" ||
                            quoteData.serviceDealStatus === "COMPLETED" ||
                            quoteData.serviceDealStatus ===
                              "DELIVERY_CONFIRMED") &&
                            currentUser === "provider") ||
                            currentUser === "consumer") &&
                          true
                        }
                        value={quoteData.servicePaymentCurrency}
                        options={Currenciesr}
                        handleChange={(e) =>
                          handleChange(e, "servicePaymentCurrency")
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px 0px" }}>
                      {parseInt(quoteData.taxes) > 0 ? (
                        <Input
                          placeholder="Total Quote *"
                          readOnly={true}
                          value={
                            parseInt(quoteData.servicePriceOffer) +
                            parseInt(quoteData.taxes)
                          }
                        />
                      ) : (
                        <Input
                          placeholder={`Total Quote * (${quoteData.servicePaymentCurrency})`}
                          readOnly={true}
                          value={parseInt(quoteData.servicePriceOffer) + 0}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{ paddingRight: "10px", paddingTop: "10px" }}>
                      <Select
                        name="payment"
                        placeholder="Payment Method"
                        value={quoteData.preferredPaymentMethod}
                        readOnly={
                          (((quoteData.serviceDealStatus === "QUOTE_ACCEPTED" ||
                            quoteData.serviceDealStatus === "QUOTE_CREATED" ||
                            quoteData.serviceDealStatus ===
                              "TNC_ACCEPTANCE_PENDING" ||
                            quoteData.serviceDealStatus === "BID_COMPLETED" ||
                            quoteData.serviceDealStatus === "BID_DECLINED" ||
                            quoteData.serviceDealStatus ===
                              "SERVICE_IN_PROGRESS" ||
                            quoteData.serviceDealStatus === "DELIVERED" ||
                            quoteData.serviceDealStatus === "PAYMENT_PENDING" ||
                            quoteData.serviceDealStatus === "ABORTED" ||
                            quoteData.serviceDealStatus === "COMPLETED" ||
                            quoteData.serviceDealStatus ===
                              "DELIVERY_CONFIRMED") &&
                            currentUser === "provider") ||
                            currentUser === "consumer") &&
                          true
                        }
                        options={payment}
                        handleChange={(e) =>
                          handleChange(e, "preferredPaymentMethod")
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px 0px" }}>
                      <DateInput
                        placeholder="Payment Date"
                        value={quoteData.preferredPaymentDate}
                        readOnly={
                          (((quoteData.serviceDealStatus === "QUOTE_ACCEPTED" ||
                            quoteData.serviceDealStatus === "QUOTE_CREATED" ||
                            quoteData.serviceDealStatus ===
                              "TNC_ACCEPTANCE_PENDING" ||
                            quoteData.serviceDealStatus === "BID_COMPLETED" ||
                            quoteData.serviceDealStatus === "BID_DECLINED" ||
                            quoteData.serviceDealStatus ===
                              "SERVICE_IN_PROGRESS" ||
                            quoteData.serviceDealStatus === "DELIVERED" ||
                            quoteData.serviceDealStatus === "PAYMENT_PENDING" ||
                            quoteData.serviceDealStatus === "ABORTED" ||
                            quoteData.serviceDealStatus === "COMPLETED" ||
                            quoteData.serviceDealStatus ===
                              "DELIVERY_CONFIRMED") &&
                            currentUser === "provider") ||
                            currentUser === "consumer") &&
                          true
                        }
                        handleChange={(e) =>
                          handleChange(e, "preferredPaymentDate")
                        }
                      />
                    </Grid>
                  </Grid>
                </div>

                {toast && <div style={{ color: "green" }}>{toast}</div>}
                <div
                  className={cn(
                    classes.StatusDiv,
                    "d-flex justify-content-between"
                  )}>
                  <div>
                    <CreatedByLabel
                      createdBy={
                        quoteData?.consumerMemberUID &&
                        FindCompanyName(response, quoteData?.consumerMemberUID)
                      }
                      createdDate={new Date(
                        quoteData.createdDate
                      ).toLocaleString("en-US", { timeZone: "Asia/Kolkata" })}
                      lastUpdateBy={
                        // quoteData.lastUpdateBy && response && personData
                        //   ? personData.companyName
                        //   : "NA"
                        companyId && FindCompanyName(response, companyId)
                      }
                      lastUpdatedDate={
                        // quoteData.lastUpdatedDate && response && personData
                        //   ? new Date().toLocaleString("en-US", {
                        //       timeZone: "Asia/Kolkata",
                        //     })
                        //   : "NA"

                        new Date(quoteData?.lastUpdatedDate).toLocaleString(
                          "en-US",
                          { timeZone: "Asia/Kolkata" }
                        )
                      }
                    />
                  </div>
                </div>
                {quoteData.serviceDealStatus === "QUOTE_ACCEPTED" &&
                  currentUser === "provider" && (
                    <div
                      style={{
                        color: "lightGreen",
                        padding: "1%",
                        fontSize: "16px",
                        marginTop: "1rem",
                      }}>
                      Before clicking on Confirm T&C kindly upload relevant
                      documents in Documents tab
                    </div>
                  )}

                <div>
                  {status === "NEW" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button
                            onClick={(e) =>
                              handleClick(e, "INTEREST_EXPRESSED")
                            }>
                            Express Interest
                          </Button>
                        )}
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}
                  {status === "INTEREST_EXPRESSED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button
                            onClick={(e) => handleClick(e, "QUOTE_CREATED")}>
                            Create Quote
                          </Button>
                        )}
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}
                  {status === "QUOTE_CREATED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "consumer" && (
                          <Button
                            onClick={(e) => handleClick(e, "QUOTE_ACCEPTED")}>
                            Accept Quote
                          </Button>
                        )}
                        {currentUser === "consumer" && (
                          <Button
                            onClick={(e) => handleClick(e, "QUOTE_DECLINED")}>
                            Decline Quote
                          </Button>
                        )}

                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}
                  {status === "QUOTE_ACCEPTED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button
                            onClick={(e) =>
                              handlePaymentScreenOpen(
                                e,
                                "TNC_ACCEPTANCE_PENDING"
                              )
                            }>
                            Confirm T&C
                          </Button>
                        )}

                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}
                  {status === "QUOTE_DECLINED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button
                            onClick={(e) => handleClick(e, "QUOTE_CREATED")}>
                            Update Quote
                          </Button>
                        )}
                        <Button>Request More Info</Button>
                        <Button onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}
                  {status === "TNC_ACCEPTANCE_PENDING" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "consumer" && (
                          <Button
                            onClick={(e) =>
                              handlePaymentScreenOpen(e, "BID_COMPLETED")
                            }>
                            Accept T&C
                          </Button>
                        )}
                        {currentUser === "consumer" && (
                          <Button
                            onClick={(e) => handleClick(e, "BID_DECLINED")}>
                            Decline T&C
                          </Button>
                        )}
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  {status === "BID_COMPLETED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button
                            onClick={(e) =>
                              handleClick(e, "SERVICE_IN_PROGRESS")
                            }>
                            Start Service
                          </Button>
                        )}
                        <Button>Request More Info</Button>
                        <Button
                          disabled={true}
                          onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  {status === "BID_DECLINED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button
                            onClick={(e) =>
                              handlePaymentScreenOpen(
                                e,
                                "TNC_ACCEPTANCE_PENDING"
                              )
                            }>
                            Confirm T&C
                          </Button>
                        )}
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  {status === "SERVICE_IN_PROGRESS" &&
                    isServiceCompleted === false && (
                      <>
                        <ButtonDiv>
                          {currentUser === "provider" && (
                            <Button
                              onClick={(e) => {
                                handleClick(e, "DELIVERED");
                                setIsServiceCompleted(true);
                              }}>
                              Delivery Complete
                            </Button>
                          )}
                          <Button onClick={() => chatHandler(quoteData)}>
                            Request More Info
                          </Button>
                          <Button
                            disabled={true}
                            onClick={(e) => handleClick(e, "ABORTED")}>
                            Abort
                          </Button>
                        </ButtonDiv>
                      </>
                    )}

                  {status === "DELIVERED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "consumer" && (
                          <Button
                            onClick={(e) =>
                              handleClick(e, "DELIVERY_CONFIRMED")
                            }>
                            Confirm Delivery
                          </Button>
                        )}
                        {currentUser === "consumer" && (
                          <Button
                            onClick={(e) => {
                              handleClickDecline(e, "SERVICE_IN_PROGRESS");
                            }}>
                            Decline Delivery
                          </Button>
                        )}

                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button
                          disabled={true}
                          onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  {status === "DELIVERY_CONFIRMED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button
                            onClick={(e) => handleClick(e, "PAYMENT_PENDING")}>
                            Request for Payment
                          </Button>
                        )}
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button
                          disabled={true}
                          onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  <div>
                    {quoteData.consumerMemberUID === companyId &&
                      quoteData.serviceDealStatus === "PAYMENT_PENDING" &&
                      (paymentData?.paymentStatus === null ||
                      paymentData?.paymentStatus === "" ||
                      paymentData?.paymentStatus === "new" ? (
                        <div style={{ textAlign: "right" }}>
                          <ButtonDiv>
                            <Button
                              onClick={() => {
                                if (quoteData.modeOfPayment !== "OFFLINE") {
                                  // setOpen(true);
                                  setPaymentDetailsScreenParent(true);
                                  setButton("Make Payment");
                                } else {
                                  setPaymentDetailsScreenParent(true);
                                }
                              }}>
                              Make Payment
                            </Button>
                          </ButtonDiv>
                        </div>
                      ) : paymentData?.paymentStatus === "FAILED" &&
                        paymentData?.createdBy === companyId ? (
                        <div style={{ textAlign: "right" }}>
                          <ButtonDiv>
                            <Button
                              onClick={() => {
                                if (quoteData.modeOfPayment !== "OFFLINE") {
                                  // setOpen(true);
                                  setPaymentDetailsScreenParent(true);
                                  setButton("Make Payment");
                                } else {
                                  setPaymentDetailsScreenParent(true);
                                }
                              }}>
                              Re-Initiate Payment
                            </Button>
                          </ButtonDiv>
                        </div>
                      ) : (paymentData?.paymentStatus ===
                          "MONEY_DISBURED_TO_PROVIDER" ||
                          paymentData?.paymentStatus === "REFUND_FAILED") &&
                        paymentData?.createdBy === companyId ? (
                        <div style={{ textAlign: "right" }}>
                          <ButtonDiv>
                            <Button
                              onClick={() => {
                                setPaymentDetailsScreenParent(true);
                                setButton("Release Payment");
                              }}>
                              Release Payment
                            </Button>
                          </ButtonDiv>
                          <ButtonDiv>
                            <Button
                              onClick={() => {
                                setPaymentDetailsScreenParent(true);
                                setButton("Refund Payment");
                              }}>
                              Refund Payment
                            </Button>
                          </ButtonDiv>
                        </div>
                      ) : paymentData?.paymentStatus ===
                        "RELEASE_INITITATED" ? (
                        <div
                          style={{
                            color: "#19cb46",
                            textAlign: "right",
                            marginTop: "10px",
                          }}>
                          Payment release initiated
                        </div>
                      ) : paymentData?.paymentStatus === "RELEASE_COMPLETED" ? (
                        <div
                          style={{
                            color: "#19cb46",
                            textAlign: "right",
                            marginTop: "10px",
                          }}>
                          Payment release Completed
                        </div>
                      ) : paymentData?.paymentStatus === "RELEASE_PENDING" ? (
                        <div
                          style={{
                            color: "#19cb46",
                            textAlign: "right",
                            marginTop: "10px",
                          }}>
                          Payment release updates Pending
                        </div>
                      ) : (
                        <div
                          style={{
                            color: "#19cb46",
                            textAlign: "right",
                            marginTop: "10px",
                          }}>
                          Payment In-Progress...
                        </div>
                      ))}
                  </div>

                  {status === "PAYMENT_COMPLETED" && (
                    <>
                      <ButtonDiv>
                        {currentUser === "provider" && (
                          <Button onClick={(e) => handleClick(e, "COMPLETED")}>
                            Complete Service
                          </Button>
                        )}
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button
                          disabled={true}
                          onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  {status === "COMPLETED" && (
                    <>
                      <ButtonDiv>
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                        <Button
                          disabled={true}
                          onClick={(e) => handleClick(e, "ABORTED")}>
                          Abort
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  {status === "ABORTED" && (
                    <>
                      <ButtonDiv>
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                      </ButtonDiv>
                    </>
                  )}

                  {status === "EXPIRED" && (
                    <>
                      <ButtonDiv>
                        <Button onClick={() => chatHandler(quoteData)}>
                          Request More Info
                        </Button>
                      </ButtonDiv>
                    </>
                  )}
                </div>
                {/* </div> */}
              </>
            )
          )}

          {isError && <span>{isError}</span>}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
