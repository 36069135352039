import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "../invoice.module.css";
import cn from "classnames";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { truncateStringID } from "utils/helper";
import { AiFillEye } from "react-icons/ai";
import { FindCompanyName } from "utils/helper";
import Content from "components/UI/Layout/Content";
import { LoanTableHeader } from "utils/Section/InvoiceSection";
import LoanDetails from "./LoanDetail";

import { quoteSubmitAPI } from "services/BaseURLInstance";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";

import MultiSelect from "components/fields/MultiSelect";
import DateInput from "components/fields/DateInput";
// import { LoanStatus } from "utils/Section/InvoiceSection";
import { STATUS } from "utils/Constants";
import NoRecord from "components/no-data/noRecord";

const LoanTable = ({ response, companies, LoanStatus }) => {
  const navigate = useNavigate();

  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const [ActiveIndex, setActiveIndex] = useState(null);
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [singleLoanDetails, setSingleLoanDetails] = useState(null);
  const [data, setData] = useState(response);
  const [filterData, setfilterData] = useState(response);

  const [selectStatus, setSelectStatus] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [companyList, setCompanyList] = useState({
    companies: null,
    userType: "",
  });

  const handleToggle = (index) => {
    if (ActiveIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  useEffect(() => {
    if (companies) {
      let user = companies.filter((item) => {
        if (item.memberUID === companyId) {
          return item.memberType;
        }
      });
      setCompanyList({
        ...companyList,
        companies: companies,
        userType: user[0].memberType,
      });
    }
  }, []);

  const handleLoanStatusUpdate = (value) => {
    let testData = data;
    testData.forEach((item, key) => {
      if (item.loanReferenceId === value.loanReferenceId) {
        testData[key] = value;
      }
      setData();
      setData(testData);
    });
  };

  const handleNavigate = (id) => {
    if (id) {
      navigate(`/financing/invoice/invoices/${id}`);
    } else {
      Error(`Invoice : ${id} Not Found`);
    }
  };

  const handleDetails = (item) => {
    setIsDetailOpen(true);
    setSingleLoanDetails(item);
  };

  const handleloanUpdate = async (e, value) => {
    let testData = data;
    let loanCreate = value.data;
    if (value.user) {
      loanCreate.financeRePaymentStatus = value.status;
    } else if (value.status === "CLOSED") {
      loanCreate.loanStatus = value.status;
    } else {
      loanCreate.financeDisbursalStatus = value.status;
    }
    let requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(loanCreate),
    };
    await fetch(
      `${quoteSubmitAPI}loans/${value.data.loanReferenceId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((newData) => {
        testData.forEach((item, key) => {
          if (item.loanReferenceId === value.loanReferenceId) {
            testData[key] = newData;
          }
          setData();
          setData(testData);
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleMultiChange = (e, name) => {
    if (name === "status") {
      const { value } = e.target;
      setSelectStatus(value);
    }
    if (name === "datefrom") {
      setDateFrom(new Date(e).toLocaleDateString());
    }

    if (name === "dateend") {
      setDateTo(new Date(e).toLocaleDateString());
    }
  };

  useEffect(() => {
    if (selectStatus) {
      let temporaryList = [];
      selectStatus.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedStatusId(temporaryList);
    }
  }, [selectStatus]);

  useEffect(() => {
    let newdate = Date.parse(dateTo);
    let olddate = Date.parse(dateFrom);
    if (
      (selectedStatusId && selectedStatusId.length > 0) ||
      dateFrom ||
      dateTo
    ) {
      let filtered =
        response &&
        response.filter(
          (data) =>
            (selectedStatusId.length > 0
              ? selectedStatusId.includes(data.loanStatus)
              : data) &&
            (dateFrom ? Date.parse(data.lastUpdatedDate) >= olddate : data) &&
            (dateTo ? Date.parse(data.lastUpdatedDate) < newdate : data)

          // (dateFrom
          //   ? new Date(data.lastUpdatedDate).toLocaleDateString() >= dateFrom
          //   : data) &&
          // (dateTo
          //   ? new Date(data.lastUpdatedDate).toLocaleDateString() < dateTo
          //   : data)
        );
      setfilterData(filtered);
    } else if (selectedStatusId.length === 0 && !dateFrom && !dateTo) {
      setfilterData(response);
    }
  }, [selectedStatusId, dateFrom, dateTo]);

  const Status = (status) => {
    switch (status) {
      case "DRAFT":
        return STATUS(status, "warning");
      case "IN_REVIEW":
        return STATUS(status, "warning");
      case "ACTIVE":
        return STATUS(status, "primary");
      case "INACTIVE":
        return STATUS(status, "danger");
      case "CLOSED":
        return STATUS(status, "success");
      case "LOCKED":
        return STATUS(status, "danger");
      case "BLOCKED":
        return STATUS(status, "danger");
      default:
        return;
    }
  };

  return (
    <div>
      {isDetailOpen ? (
        <>
          {companyList.companies ? (
            <LoanDetails handleLoanStatusUpdate={handleLoanStatusUpdate} />
          ) : null}
        </>
      ) : (
        <>
          <Content marginBottom="1%" padding="1.4% 1.2% 2% 2%">
            <div>
              <h5>Search Filter</h5>
            </div>
            <div className={classes.searchDiv}>
              <div>
                <MultiSelect
                  name="memberlist"
                  selected={selectStatus}
                  placeholder="Status"
                  options={LoanStatus}
                  handleChange={(e) => handleMultiChange(e, "status")}
                />
              </div>
              <div>
                <DateInput
                  backDate={new Date()}
                  name="DateFrom"
                  value={
                    dateFrom
                    //|| new Date().toLocaleDateString()
                  }
                  placeholder="Date From *"
                  handleChange={(e) => handleMultiChange(e, "datefrom")}
                />
              </div>
              <div>
                <DateInput
                  name="DateFrom"
                  backDate={new Date()}
                  value={
                    dateTo
                    // || new Date().toLocaleDateString()
                  }
                  placeholder="Date To *"
                  handleChange={(e) => handleMultiChange(e, "dateend")}
                />
              </div>
            </div>
          </Content>
          <Content padding="1.4% 2% 2% 2%">
            <h5>Loan List</h5>
            <div className={cn(classes.newTableHead)}>
              {LoanTableHeader &&
                LoanTableHeader.map((header, index) => (
                  <div
                    key={index}
                    className={header.name === "Status" ? "px-2" : ""}>
                    {header.name}
                  </div>
                ))}
            </div>
            <div>
              {filterData.length > 0 &&
                filterData.map((item, index) => (
                  <>
                    <div key={index} className={cn(classes.newTableBody)}>
                      <div>
                        <span
                          className={cn(classes.tableText)}
                          style={{ cursor: "pointer" }}>
                          <span
                            className="loanid"
                            onClick={() =>
                              navigate(`./${item.loanReferenceId}`, {
                                state: { companyList: companyList },
                              })
                            }>
                            {item.loanReferenceId
                              ? truncateStringID(item.loanReferenceId, 7, "LN")
                              : "NA"}
                          </span>
                        </span>
                      </div>
                      <div>
                        <span className={cn(classes.tableText)}>
                          {companyList.companies &&
                            FindCompanyName(
                              companyList.companies,
                              item.financerMemberUID
                            ).length>20? FindCompanyName(
                              companyList.companies,
                              item.financerMemberUID
                            ).substring(0,20).concat("...") :FindCompanyName(
                              companyList.companies,
                              item.financerMemberUID
                            )
                            }
                        </span>
                      </div>
                      <div>
                        <span className={cn(classes.tableText)}>
                          {companyList.companies &&
                            FindCompanyName(
                              companyList.companies,
                              item.traderMemberUID
                            ).length>20? FindCompanyName(
                              companyList.companies,
                              item.traderMemberUID
                            ).substring(0,20).concat("...") :FindCompanyName(
                              companyList.companies,
                              item.traderMemberUID
                            )
                            }
                        </span>
                      </div>
                      <div>
                        <span className={cn(classes.tableText)}>
                          {new Date(item.createdDate).toLocaleDateString()}
                        </span>
                      </div>
                      <div className="px-2">
                        <span className={cn(classes.tableText)}>
                          {Status(item.loanStatus)}
                        </span>
                      </div>
                      <div style={{ color: "#42A5F5" }}>
                        <span className={cn(classes.tableText)}>
                          <span
                            onClick={() =>
                              handleNavigate(item.referenceFinanceInstrumentId)
                            }>
                            <AiFillEye size={24} />
                          </span>
                        </span>
                      </div>
                    </div>
                  </>
                ))}
            </div>
            {filterData && filterData.length === 0 && <NoRecord />}
          </Content>
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default LoanTable;
