import React from "react";
import styles from "../details.module.css";

const ExportDetails = ({ Data }) => {
  const Response = [
    {
      name: "Shipping Bill",
      value: Data ? Data.ShipBNo : "",
    },
    {
      name: "Date",
      value: Data ? Data.ShipBDt : "",
    },
    {
      name: "Port",
      value: Data ? Data.Port : "",
    },
    {
      name: "Supplier Refund Status",
      value: Data ? Data.RefClm : "",
    },
    {
      name: "Additional Currency Code",
      value: Data ? Data.ForCur : "",
    },
    {
      name: "Country of Export",
      value: Data ? Data.CntCode : "",
    },
    {
      name: "Export Duty",
      value: Data ? Data.ExpDuty : "",
    },
  ];
  return (
    <div className={styles.summary}>
      {Response &&
        Response.map((data, i) => (
          <div className={styles.summaryWrapper} key={i}  style={{fontSize:"12px"}}>
            <div>
              <span> {data.name} </span>{" "}
            </div>
            <div>
              <span style={addBlue}> {data.value} </span>{" "}
            </div>
          </div>
        ))}
    </div>
  );
};

export default ExportDetails;

const addBlue = {
  color: "#42A5F5",
};
