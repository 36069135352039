import React from "react";
import classes from "./shipmentSummary.module.css";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsAirplaneEngines, BsTruck,BsArrowUp,BsArrowDown } from "react-icons/bs";
import { GiCargoShip } from "react-icons/gi";
import { TiWeatherStormy } from "react-icons/ti";
import cn from "classnames";
import { BiTimer } from "react-icons/bi";
import { FormattedDateTime } from "utils/helper";
import { Check } from "@material-ui/icons";
import index from "..";

const Vertical = ({ response }) => {
  const DeliveryStatus = (step) => {
    switch (step.status) {
      case "Completed":
        return (
          <>
            <span className="text-success">{step.status}</span>
            <span>
              <BiTimer size={20} color={"#28a745"} />
            </span>
          </>
        );
      case "delay":
        return (
          <>
            <span className="text-warning">
              {new Date(step.pod_actual_departure_lt).toISOString()}
            </span>
            <span>
              <TiWeatherStormy size={20} color={"#ffc107"} />
            </span>
          </>
        );
      case "port":
        return <BsAirplaneEngines size={26} />;
      default:
        return null;
    }
  };

  const TrasportMode = (mode) => {
    switch (mode) {
      case "road":
        return <BsTruck size={26} />;
      case "water":
        return <GiCargoShip size={26} />;
      case "air":
        return <BsAirplaneEngines size={26} />;
      default:
        return null;
    }
  };

  const CheckLastIndex = (index1, index2, response1, response2) => {
    if (response1.length - 1 === index1 && response2.length - 1 === index2) {
    } else {
      return classes.VerticalContentAfter1;
    }
  };

  const isDelayed = (reasons, date1, date2) => {
    const date_1 = new Date(date1);
    const date_2 = new Date(date2);

    const differenceInMilliseconds = date_2 - date_1;

    if (reasons?.length > 0) {
      if (differenceInMilliseconds > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const CheckMarkerColor = (voyagedetails) => {
    // if actual_departure_utc contain value means green  "#218838"
    // actual_arrival_utc contain value   actual_departure_utc === null then blue
    // actual_arrival_utc === null actual_departure_utc === null  orange  "#ffc107"

    if (
      voyagedetails.actual_arrival_utc === null &&
      voyagedetails.actual_departure_utc === null
    ) {
      return "#ffc107";
    } else if (
      voyagedetails.actual_arrival_utc !== null &&
      voyagedetails.actual_departure_utc === null
    ) {
      return "#42a5f5";
    } else {
      return "#218838";
    }
  };

  const CheckMarkerColorStep = (voyagedetails) => {
    // if actual_departure_utc contain value means green  "#218838"
    // actual_arrival_utc contain value   actual_departure_utc === null then blue
    // actual_arrival_utc === null actual_departure_utc === null  orange  "#ffc107"

    if (
      voyagedetails.actual_arrival_utc === null &&
      voyagedetails.actual_departure_utc === null
    ) {
      return classes.step1;
    } else if (
      voyagedetails.actual_arrival_utc !== null &&
      voyagedetails.actual_departure_utc === null
    ) {
      return classes.step2;
    } else {
      return classes.step3;
    }
  };

  return (
    <div className={classes.Vertical}>
      {response &&
        response.map((step, index) => (
          <div key={index}>
            {step &&
              step.voyage_details &&
              step.voyage_details.map((voyagedetails, index1) => (
                <div
                  // style={{ color: CheckMarkerColor(voyagedetails) }}
                  key={index1}
                  className={cn(
                    classes.VerticalContent,
                    CheckLastIndex(
                      index,
                      index1,
                      response,
                      step && step.voyage_details && step.voyage_details
                    ),
                    CheckMarkerColorStep(voyagedetails)
                  )}>
                  <div>
                    <div className={classes.LocationMarker}>
                      <HiOutlineLocationMarker
                        size={28}
                        color={CheckMarkerColor(voyagedetails)}
                      />
                    </div>
                    <div></div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>{TrasportMode(step.mode)}</div>
                    <div className="text-end">
                      <div style={{ fontSize: "14px" }}>Vessel Name:
                        {step.sailing_info && step.sailing_info.vessel_name}
                        {", "}
                        Voyage No:{step.sailing_info && step.sailing_info.voyage_no}
                      </div>
                      <div style={{ fontSize: "12px" }}>
                        <span>{voyagedetails.port_name}</span>
                      </div>
                      <div style={{ fontSize: "13px" }}>
                        <span>
                          <BsArrowDown size={20} color="#00FF00"/>
                          {FormattedDateTime(
                            voyagedetails.actual_arrival_utc ||
                              voyagedetails.predicted_arrival_utc
                          )}
                          <BiTimer
                            size={20}
                            color={
                              isDelayed(
                                voyagedetails?.delay_reasons,
                                voyagedetails?.predicted_arrival_utc,
                                voyagedetails?.scheduled_arrival_utc
                              )
                                ? "red"
                                : "#9ACD32"
                            }
                          />
                        </span>
                        {", "}
                        <span>
                        <BsArrowUp size={20} color="#00FF00"/>
                          {FormattedDateTime(
                            voyagedetails.actual_departure_utc ||
                              voyagedetails.predicted_departure_utc
                          )}
                          <BiTimer
                            size={20}
                            color={
                              isDelayed(
                                voyagedetails?.delay_reasons,
                                voyagedetails?.predicted_departure_utc,
                                voyagedetails?.scheduled_departure_utc
                              )
                                ? "red"
                                : "#9ACD32"
                            }
                          />
                        </span>
                      </div>
                      <div>
                        {}
                        {voyagedetails.delay_reasons &&
                          voyagedetails.delay_reasons.length > 0 &&
                          voyagedetails.delay_reasons[0]}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ))}
    </div>
  );
};

export default Vertical;
