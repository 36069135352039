import React, { useState, useEffect, useCallback, useContext } from 'react'
import { useTheme } from "@mui/material/styles";
import styles from './financing.module.css'
import { BsBell } from "react-icons/bs";
import { CgChevronDown } from "react-icons/cg";
import { AiOutlineArrowRight } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import { financeURL, invoiceURL } from '../../../../../../services/BaseURLInstance'
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import LinkBuyerSeller from './linkBuyerSeller';
import { invoiceFinanceContext } from '../../../../../../contexts/invoiceFinance/invoiceFinanceContext'
import { ToastContainer } from 'react-toastify';
import moment from "moment";

import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import Select from "components/fields/Select";

import { Grid } from '@mui/material';
import {
  Success,
  Alert
} from 'components/notifications/responseHandle';

const Financing = () => {
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState(null);
  getIdToken(instance, accounts, setIdToken);
  const { SavedInvoiceData } = useContext(invoiceFinanceContext)
  const companyId = getClaim(accounts, "company_member_id");
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const [open1, setOpen1] = useState('No')
  const [open2, setOpen2] = useState('No')
  const [open3, setOpen3] = useState('No')
  const [open4, setOpen4] = useState('No')
  const [open5, setOpen5] = useState('No')
  const [open6, setOpen6] = useState('No')
  const [personName, setPersonName] = useState([]);

  const editChangeHandle = () => {
    setOpen1('Yes');
    setOpen2('No');
    setBuyerDetails([])
  }

  //start recipient
  const [recipient, setRecipient] = useState({
    recipients: ''
  })
  const [buyerDetails, setBuyerDetails] = useState('')

  const handleRecipientSubmit = async () => {
    console.log("handleRecipientSubmit", JSON.parse(localStorage.getItem("uploadData")))
    const general = JSON.parse(localStorage.getItem("uploadData"));
    general.txlInvoiceId = SavedInvoiceData.txlInvoiceId;
    localStorage.setItem("uploadData", JSON.stringify(general));
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: localStorage.getItem("uploadData")
    };
    await fetch(`${invoiceURL}invoices/${SavedInvoiceData.txlInvoiceId}`, requestOptions)
      .then(response => response.json())
      .then(data => {
        console.log("linkBuyerResponse", data);
        setBuyerDetails(data);
        setOpen2('Yes')
      })
      .catch(err => {
        console.log("err", err);
      })
   }

  const [data, setData] = useState({
    sellerMemberUID: "",
    buyerMemberUID: '',
    invoiceAmount: '',
    invoiceUID: "",
    sellerID: "",
    financeProductType: '',
    financerPreferences: '',
    financeAmountRequested: '',
    interestRateMin: '',
    preferredPaymentDate: '',
    preferredPaymentMethod: '',
    financeRequsitionStartDate: '',
    financeRequsitionEndDate: '',
    financersSelected: [],
  })

  //   txlInvoiceId
  useEffect(() => {
    setData({
      ...data,
      sellerMemberUID: companyId,
      buyerMemberUID: SavedInvoiceData ? SavedInvoiceData.txlBuyerMemberId : '',
      sellerID: companyId,
      invoiceUID: SavedInvoiceData ? SavedInvoiceData.txlInvoiceId : '',
      invoiceAmount: SavedInvoiceData ? SavedInvoiceData.txlInvoiceAmount : '',
    })
  }, [buyerDetails])

  const [DatePicker1, setDatePicker1] = useState(new Date());
  const [DatePicker2, setDatePicker2] = useState(new Date());
  const [DatePicker3, setDatePicker3] = useState(new Date());

  const handleChange = (event, name) => {
    if (name === 'financeRequsitionStartDate' || name === 'financeRequsitionEndDate' || name === 'preferredPaymentDate') {
      let newDate = new Date(event)
      let dateString = newDate.toISOString();

      console.log('newDate', dateString);
      setData({
        ...data,
        [name]: dateString,
      })

      let newDate1 = moment(new Date(event)).format('YYYY-MM-DD')
      if (name === 'preferredPaymentDate') {
        setDatePicker1(newDate1)
      }
      if (name === 'financeRequsitionStartDate') {
        setDatePicker2(newDate1)
      }
      if (name === 'financeRequsitionEndDate') {
        setDatePicker3(newDate1)
      }

    } else {
      setData({
        ...data,
        [event.target.name]: event.target.value,

      })
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('data changes submit', data);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data)
    };
    await fetch(`${financeURL}finance/requisitions`, requestOptions)
      .then(response => response.json())
      .then(data => {
        Success()
        // setOpen6('Yes');
        // handleNavigate(`/invoicefinancing/invoices`)
      })
      .catch(err => {
        console.log('error', err);
        Alert()
      })
  }


  useEffect(() => {
    if (SavedInvoiceData && SavedInvoiceData.txlBuyerMemberId) {
      setOpen3('Yes')
    }
  }, [SavedInvoiceData])

  const loan_product_type = [
    { "label": "FINANCE_INVOICE", "value": "FINANCE_INVOICE" },
    { "label": "FINANCE_PURCHASE_ORDER", "value": "FINANCE_PURCHASE_ORDER" },
    { "label": "FINANCE_PAYBLES", "value": "FINANCE_PAYBLES" },
    { "label": "FINANCE_RECEIVABLES", "value": "FINANCE_RECEIVABLES" }
  ]

  const disbursement_mode_options = [
    { "label": "ACCOUNT_TRANSFER", "value": "ACCOUNT_TRANSFER" },
    { "label": "CHEQUE", "value": "CHEQUE" },
    { "label": "SI", "value": "SI" }
  ]

  return (
    <div className={styles.container}>

      {SavedInvoiceData && !SavedInvoiceData.txlBuyerMemberId && open1 === 'No' && (
        <div className={styles.LinkBuyer}>
          <div> <span style={{ color: "rgba(51, 195, 12, 1)" }}>Your Invoice has been saved successfully</span> </div>
          <div> <span style={{ color: "rgba(51, 195, 12, 1)" }}>Go ahead and link your buyer</span> </div>
          <div className={styles.buttonDiv}>
            < button className={styles.activeBtn} onClick={() => setOpen1('Yes')} >Link Buyer</ button>
            < button onClick={() => handleNavigate('../../invoicefinancing/invoices')} className={styles.activeBtn} >Back to Invoices</ button>
          </div>
        </div>
      )}

      {SavedInvoiceData && !SavedInvoiceData.txlBuyerMemberId && buyerDetails === '' && open1 === 'Yes' && open2 === 'No' && (
        <div className={styles.Buyer}><LinkBuyerSeller handleRecipientSubmit={handleRecipientSubmit} /></div>
      )}

      {SavedInvoiceData && !SavedInvoiceData.txlBuyerMemberId && buyerDetails !== '' && open2 === 'Yes' && open3 === 'No' && (
        <div className={styles.Buyer}>
          <div className={styles.toggleHeading}>
            <div><span>Link Buyer</span></div>
            <div><CgChevronDown size={28} /></div>
          </div>
          <hr />

          <div className={styles.buyerDetails}>
            <div>
              <div>Member Id</div>
              <div>Share Status</div>
            </div>

            {buyerDetails && buyerDetails.invoiceNumber && <span>
              {buyerDetails.invoiceNumber}
            </span>}
          </div>

          <div className={styles.buttonDiv}>
            < button onClick={() => setOpen3('Yes')} className={styles.activeBtn} >Confirm Invoice</ button>
            < button onClick={editChangeHandle} className={styles.btn} >Edit / Change</ button>
          </div>
        </div>
      )}


      {SavedInvoiceData && SavedInvoiceData.txlStatus !== 'IN_PURSUIT' && open3 === 'Yes' && open4 === 'No' && (
        <div className={styles.div1}>
          <div><span style={{ color: "rgba(51, 195, 12, 1)" }}>Your Invoice has been sent for validate to buyer</span> </div>
          <div className={styles.buttonDiv}>< button onClick={() => setOpen4('Yes')} className={styles.activeBtn} >Proceed to Financing</ button></div>
        </div>
      )}

      {SavedInvoiceData && SavedInvoiceData.txlStatus !== 'IN_PURSUIT' && open4 === 'Yes' && open5 === 'No' && (
        <div className={styles.div2}>
          <div><span>Financing Deals for Invoice ID:<span> {SavedInvoiceData.invoiceNumber}</span></span></div>
          <div style={{ display: 'flex', margin: '15px 0px' }}>
            <div style={{ marginRight: '10px' }}><BsBell size={30} color='#42A5F5' /></div>
            <div>You haven't initiated the funding request on this invoice</div>
          </div>
          <div className={styles.buttonDiv}>
            < button onClick={() => setOpen5('Yes')} className={styles.activeBtn}>Request Financing</ button>
          </div>
        </div>
      )}

      {SavedInvoiceData && SavedInvoiceData.txlStatus !== 'IN_PURSUIT' && open5 === 'Yes' && open6 === 'No' && (
        <div className={styles.FinancingForm}>
          <div className={styles.toggleHeading}>
            <div><span>Financing Request</span></div>
            <div><CgChevronDown size={28} /></div>
          </div>
          <hr />
          <form onSubmit={handleSubmit}>

            <Grid container>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                  name="invoiceAmount"
                  placeholder="Invoice Amount"
                  value={data.invoiceAmount}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                  name="sellerID"
                  placeholder="Seller ID"
                  value={data.sellerID}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Select
                  options={loan_product_type}
                  name="financeProductType"
                  placeholder="Loan Product Type"
                  handleChange={handleChange}
                  value={data.financeProductType}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                  name="financerPreferences"
                  placeholder="Invoice Reference"
                  value={data.financerPreferences}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                  name="financeAmountRequested"
                  placeholder="Required Finance Amount"
                  value={data.financeAmountRequested}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                  name="interestRateMin"
                  placeholder="Interest Rate"
                  value={data.interestRateMin}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                <DateInput
                  placeholder="Disbursement Date"
                  value={DatePicker1}
                  handleChange={(value) => handleChange(value, "preferredPaymentDate")}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Select
                  options={disbursement_mode_options}
                  name="preferredPaymentMethod"
                  placeholder="Disbursement Mode"
                  handleChange={handleChange}
                  value={data.preferredPaymentMethod}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                <DateInput
                  placeholder="Financing Start Date"
                  value={DatePicker2}
                  handleChange={(value) => handleChange(value, "financeRequsitionStartDate")}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                <DateInput
                  placeholder="Financing End Date"
                  value={DatePicker3}
                  handleChange={(value) => handleChange(value, "financeRequsitionEndDate")}
                />
              </Grid>
            </Grid>

            <div className={styles.inputRadio} onChange={handleChange}>
              <div className={styles.radioHeading}>Select Financers</div>
              <div className={styles.radioInput}><input type="radio" value="recommended" name="financersSelected" /><span >Recommended</span> </div>
              <div className={styles.radioInput}><input type="radio" value="publish" name="financersSelected" checked="checked" /><span >Publish to wall</span> </div>
              <div className={styles.radioInput}><input type="radio" value="favorites" name="financersSelected" /><span >Select Favorites</span> </div>
            </div>
            <div></div>

            <button type="submit">Submit</button>
          </form>
        </div>
      )}

      {SavedInvoiceData && SavedInvoiceData.txlStatus !== 'IN_PURSUIT' && open6 === 'Yes' && (
        <div className={styles.submited}>Your Invoice has been published as per your funding perferences</div>
      )}

      {SavedInvoiceData && SavedInvoiceData.txlStatus === 'IN_PURSUIT' && (
        <div className={styles.financeBtn}>
          <button onClick={() => handleNavigate('./')}>
            <div>Proceed to Finance Deals</div>
            <div><AiOutlineArrowRight size={20} /></div>
          </button>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

    </div>
  )
}

export default Financing;
