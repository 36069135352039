import { ImCheckboxChecked, ImCheckboxUnchecked } from "react-icons/im";
import { BsFileEarmarkText } from "react-icons/bs";
import styled from "styled-components";

const ChangeState = () => {
  return (
    <>
      <span>Financing Deals for Invoice (Invoice ID -123456) </span>
      <ChangeStateStyled>
        <ImCheckboxChecked size={36} />
        <BsFileEarmarkText size={36} />
        <SpanText>Draft Completed</SpanText>
      </ChangeStateStyled>

      <ChangeStateStyled>
        <ImCheckboxChecked size={36} />
        <BsFileEarmarkText size={36} />
        <SpanText>Draft Completed</SpanText>
      </ChangeStateStyled>

      <ButtonStyledColor>Upload Status</ButtonStyledColor>
    </>
  );
};
export default ChangeState;

const SpanIcon = styled.span`
  font-size: 26px;
  margin: 12px 10px;
  padding: 10px 0px;
  svg {
  }
`;

const SpanText = styled.span`
  font-size: 26px;
  margin: 10px 10px;
  padding: 10px 0px;
`;

const ChangeStateStyled = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 14px;
  }
`;

const ButtonStyledColor = styled.button`
  padding: 16px 58px;
  border-radius: 5px;
  letter-spacing: 1px;
  font-size: 16px;
  color: white;
  background-color: #42a5f5;
  cursor: pointer;
`;
