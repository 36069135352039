import React, { useState } from "react";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";

import ChangeState from "./Deals/changeState";
// import History from "../../Invoicefinancing/uploadInvoice/Deals/history";
import History from "components/History/history";
import FinanceDeals from "./Deals/financeDeals";
import Documents from "pages/Document/index";
const Deal = ({id}) => {
  const [isActive, setIsActive] = useState("Finance Deals");
  const [Component, setIsComponent] = useState(<FinanceDeals />);

  const Navbar = [
    {
      title: "Finance Deals",
      description: <FinanceDeals />,
    },
    {
      title: "Change State",
      description: <ChangeState />,
    },
    {
      title: "Documents",
      description: <Documents invoiceId={id}/>,
    },
    {
      title: "History",
      description: <History dealstatus="Status" screen="INVOICES" id={id}/>,
    },
  ];

  const handleChange = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };
  return (
    <div>
      <ToogleNavbar
        Navbar={Navbar}
        isActive={isActive}
        handleChange={handleChange}
        handleComponent={handleComponent}
      />

      {Component !== undefined && Component}
    </div>
  );
};

export default Deal;
