import React, { useState } from "react";
import Content from "components/UI/Layout/Content";
import Tradexlink12 from "images/Tradexlink1.png";
import help from "./help.css";
import { Link } from "react-router-dom";
import classes from "./Help.module.css";
import { GrDocumentPerformance, GrServices } from "react-icons/gr";
import LogoSmallBlue from "../../components/Header/image/LogoLargeWhite.jpeg";
import { BiNetworkChart } from "react-icons/bi";
import { FaFileInvoiceDollar,FaShippingFast,FaFileAlt,FaHandshake,FaChartBar,FaHome } from "react-icons/fa";
import { MdPayment } from "react-icons/md";

const Help = () => {
  return (
    <>
      <Content
       padding="1% 2% 2% 2%"
        className="font"
        style={{ marginTop: "15", marginLeft: "4%", marginRight: "4%" }}>
          <div style={{background:"#0084ff", marginBottom:"2%", borderRadius:"5px"}}> 
        <div style={{width:"80%", display:"flex", justifyContent:"space-between", margin:"auto", paddingTop:"3%",paddingBottom:"3%"}}>
         <div style={{width:"60%",background:"rgba(39, 38, 38, 2.5)"}}>
          <img  src={LogoSmallBlue} height={"80px"} width={"20%"}  />
          <span style={{fontFamily:"Mulish,sans-serif", fontSize:"22px"}}>Help Center</span>
          <div style={{fontFamily:"Mulish,sans-serif", fontSize:"35px", marginLeft:"22px", marginBottom:"25px"}}>We are here to help you today.</div>
                   
                      <input
                        style={{ marginLeft:"22px", width:"85%", height:"45px", marginBottom:"4%", borderColor:"blue" }}
                        type="text"
                        name="search"
                        // value={searchData}
                        placeholder="Search your queries"
                        // onChange={(e) => handleChange(e)}
                      />
                      
         </div>
         <div style={{width:"40%", background:"rgba(39, 38, 38, 2.5)"}}>
         <img
          src={Tradexlink12}
          height={235}
          width={449}
        />
         </div>
        </div>
      </div>
        {/* <img
          src={Tradexlink12}
          style={{ marginBottom: "2%", marginLeft: "25%" }}
          height={300}
          width={700}
        /> */}
        <div className="profileWrapper">
         <Content>
           <p style={{margin:"0%",textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"17px"}}>
             <span>
            TradXLink is a digital trade ecosystem platform created for trade
            lifecycle management bringing solutions as Business Network, Trade
            Life Cycle, Services Marketplace, Finance, Digital Documents and
            Communication & Collaboration to all participants in international
            trade i.e. exporters, importers especially SMEs, financing
            institutions, and other service providers across trade value chain.
             </span>
           </p>
          </Content> 
        </div>

      <div className="profileWrapper">
        <Content>
          <span>
            <div>
              <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px"}}>
                <h5 style={{ color: "#0084ff", fontSize:"26px" }}>
                  Solutions Provided on the Application
                </h5>
                Tradxlink platform brings innovative solutions to enable future
                ways of working for participants in international trade markets.
              </span>
            </div>
          <div marginBottom="20%">
            <ol style={{marginBottom:"0%"}}>
              <li>
                <Link to="/networks" style={{color:"#0084ff"}}>NETWORKS</Link>
              </li>

              <li>
                <Link to="/services/overview" style={{color:"#0084ff"}}>SERVICES</Link>
              </li>
            </ol>
            <ul style={{marginBottom:"0%"}}>
              <li>
                <Link to="/financing" style={{color:"#0084ff"}}>APPLICATION: </Link>
                <ol style={{ marginBottom: "0.5%" }} start={3}>
                  <li>
                    <Link to="/financing/invoice/invoices" style={{color:"#0084ff"}}>
                      INVOICE FINANCING APP
                    </Link>
                  </li>

                  <li>
                    <Link to="/financing/pofinancing" style={{color:"#0084ff"}}>
                      SYNDICATE FINANCING APP
                    </Link>
                  </li>
                  <li>
                    <Link to="/trade-account/open-account" style={{color:"#0084ff"}}>
                      OPEN ACCOUNT TRADE
                    </Link>
                  </li>
                </ol>
              </li>
            </ul>
            <ol start={6} style={{marginBottom:"0%"}}>
              <li>
                <Link to="/payment/payment-transactions" style={{color:"#0084ff"}}>
                  PAYMENT SOLUTIONS
                </Link>
              </li>
              <li>
                <Link to="/services/overview" style={{color:"#0084ff"}}>
                  TRADE LIFECYCLE AND INFORMATION MANAGEMENT
                </Link>
              </li>
              <li>
                <Link to="/mistrade" style={{color:"#0084ff"}}>MIS DASHBOARD</Link>
              </li>
              <li>
                <Link to="/documents" style={{color:"#0084ff"}}>DOCUMENTS</Link>
              </li>
              <li>
                <Link to="/" style={{color:"#0084ff"}}>DASHBOARD</Link>
              </li>
            </ol>
            </div>
          </span>
        </Content>
      </div>
      <div className="profileWrapper">
        <Content>
          
          <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif"}}>
            <h5>
              <Link to="/networks" style={{color:"#0084ff"}}>1. NETWORKS MODULE</Link>{" "}
              <GrDocumentPerformance size={20} color={"#42a5f5"} />
            </h5>
          </span>
          <div style={{display:"flex"}}>
          <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
          <span style={{padding:"0% 0% 8% 0%"}}>
          <BiNetworkChart size={100}/>
          </span>
        </div>
          <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px"}}>
            Digitally transform your business by being part of a global trade
            network community on digital platform where you can reach trusted
            Suppliers, Buyers, Financer and Service Providers. Be in control to
            address challenges of international trade through technology-enabled
            network.Tradxlink provides a platform for traders and other trade
              participants to participate and onboard their trusted network
              partners via referrals, allowing them to have a one stop platform
              for all their trading requirements. They can also explore overseas
              partners and service providers on the network with trusted reviews
              & ratings, independent performance metrics provided by the
              platform, secure communications and digitally verified
              documentations. This will allow international trade participants
              to increase their reach, scale and profitability and open new
              markets, as well. Also, their trusted partners onboarded on the
              platform too will be able to utilize these benefits.
              <div style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px"}}>
              You can visit <a href="/networks" style={{color:"#0084ff"}}>Tradxlink Networks page.</a>
            </div>
            </span>
           
          </div>
            
          
          <div style={{marginTop:"1%"}}>
            <span>
            <h4>
              How to add and search your network details in Network Module?
            </h4>

            <ul style={{marginBottom:"0%"}}>
              <li>
                Navigate to Network module and search the network company by
                searching its name. Otherway is to search the participants by
                its type such as Company, Financer, Service Provider
              </li>
              <li>
                Add any network participants to your network list, Trusted
                member list by selecting the specific button.
              </li>
              <li>
                Clicking on a network company will open a page showing its
                Company Profile, Products and services they offer, business
                trust card, company documents and many more details.
              </li>
            </ul>
            </span>
          </div>
        </Content>
      </div>
      {/* <div className="profileWrapper"> */}
        {/* <Content>
          <span>
            <h4>
              How to add and search your network details in Network Module?
            </h4>

            <ul>
              <li>
                Navigate to Network module and search the network company by
                searching its name. Otherway is to search the participants by
                its type such as Company, Financer, Service Provider
              </li>
              <li>
                Add any network participants to your network list, Trusted
                member list by selecting the specific button.
              </li>
              <li>
                Clicking on a network company will open a page showing its
                Company Profile, Products and services they offer, business
                trust card, company documents and many more details.
              </li>
            </ul>
          </span>
        </Content> */}
      {/* </div> */}
      <div className="profileWrapper">
       <Content>
           
          <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif"}}>
            <h5>
              <a href="/services/overview" style={{color:"#0084ff"}}>2. SERVICES</a>
            </h5>
            </span>
        <div style={{display:"flex"}}>
        <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
          <span style={{padding:"0% 0% 8% 0%"}}>
            <GrServices size={100} className="icon"/>
          </span>
        </div>
          <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px"}}>
            Tradxlink services section is a ‘one-stop shop’ for trusted
            Suppliers, Buyers, Financer and Service Providers to discover
            services to help to launch, manage and grow their business. Explore
            a directory of third-party services from Service Providers across
            the globe.Connect to trusted trade ecosystem participants across regions and
            marketplaces to acquire the best and most relevant service providers.
            Tradxlink will bring trusted trade ecosystem participants involved
            in the International Trade Value Chain on single platform through
            the process workflows solutions and integration across the Data
            Sources. The Services Marketplace on the platform provides an
            opportunity to service providers to showcase their offerings,
            solicit requisitions, respond to quotes, and increase their business
            reach and revenue streams. It also promotes healthy competition
            amongst them to complete and provide the best service to customers.
            The consumers of the services also gain from wider options,
            competitive pricing and trusted ratings helping them make informed
            procurement of services.
            <span>
              You can visit <a href="/services" style={{color:"#0084ff"}}>Tradxlink Services page.</a>
            </span>
          </span>
        </div>
            
         
          <div className="profileWrapper" style={{padding:"0.5%", marginBottom:"0.5%", marginTop:"0.5%"}}>
           <Content padding="1%">
            <span>
              <h5>
                <a href="/services/overview" style={{color:"#0084ff"}}>2.1 OVERVIEW TAB ON SERVICES MODULE</a>
              </h5>
              <ul style={{marginBottom:"0%"}}>
                <li>
                  Overview tab will give a summary of all the services related
                  requisitions, service deals, payments information
                </li>
                <li>
                  Summary will also reflect the current status of the services
                  related requisitions, service deals, payments information such
                  as In - Progress, Delayed, Pending, Completed.
                </li>
              </ul>
            </span>
          </Content>
          </div>
          <div className="profileWrapper" style={{padding:"0.5%", marginBottom:"0.5%"}}>
          <Content padding="1%">
            <span>
              <h5>
                <a href="/services/service" style={{color:"#0084ff"}}>2.2 SERVICES SECTION</a>
              </h5>
              <ul style={{marginBottom:"0%"}}>
                <li>
                  Consumer must be able to find your products before they can
                  buy them, and searching is the primary way they can do that.
                  Service section allows us to search for a particular service
                  by selecting the Country where you need serviceselecting the
                  categorySeclecting the sub-category
                </li>
                <li>
                  After all the selections are done, Consumer can go ahead and
                  create the service requisition
                </li>
                <li>
                  Services providers wanting to add a service can also add a
                  service they want to offer by clicking on “OFFER NEW SERVICE”
                </li>
              </ul>
            </span>
          </Content>
          </div>
          <div className="profileWrapper" style={{padding:"0.5%", marginBottom:"0.5%"}}>
          <Content padding="1%">
            <span>
              <h5>
                <a href="/services/service-requests" style={{color:"#0084ff"}}>2.3 SERVICE REQUESTS</a>
              </h5>
              <ul style={{marginBottom:"0%"}}>
                <li>
                  The Service Request View page displays all the service
                  requests generated up until from the starting. You can search
                  for a specific service request by using the filter section to
                  filter it by the status , country and category of service
                  request.
                </li>
                <li>
                  Click on Service Request number to see the details of that
                  service requisition.
                </li>
              </ul>
            </span>
          </Content>
          </div>
          <div className="profileWrapper" style={{padding:"0.5%", marginBottom:"0%"}}>
         <Content padding="1%">
            <span>
              <h5>
                <a href="/services/service-deals" style={{color:"#0084ff"}}>2.4 SERVICE DEALS</a>
              </h5>
              <ul style={{marginBottom:"0%"}}>
                <li>
                  The Service Deal View page displays all the service requests
                  generated up until from the starting. You can search for a
                  specific service request by using the filter section to filter
                  it by the status , country and category of service request
                </li>
                <li>
                  Click on Service Deal number to see the details and status of
                  that service deal
                </li>
                <li>
                  Once you click on any service deals, detail page will open up
                  showing the deal status , journey, Deal Details.
                </li>
                <li>
                  By Navigating to the Quote tab, you can take necessary actions
                  to take the service to closure with the service provider or
                  Trading Company.
                </li>
                <li>
                  Documents Section in Service Deals will allow you to add or
                  view documents required at a particular stage of the deal.
                </li>
              </ul>
            </span>
          </Content>
          </div>
        </Content>
      </div>
      <div>
        {/* <div className="profileWrapper">
          <Content>
            <span>
              <h5>
                <a href="/services/overview" style={{color:"#0084ff"}}>2.1 OVERVIEW TAB ON SERVICES MODULE</a>
              </h5>
              <ul>
                <li>
                  Overview tab will give a summary of all the services related
                  requisitions, service deals, payments information
                </li>
                <li>
                  Summary will also reflect the current status of the services
                  related requisitions, service deals, payments information such
                  as In - Progress, Delayed, Pending, Completed.
                </li>
              </ul>
            </span>
          </Content>
        </div> */}
        {/* <div className="profileWrapper">
          <Content>
            <span>
              <h5>
                <a href="/services/service" style={{color:"#0084ff"}}>2.2 SERVICES SECTION</a>
              </h5>
              <ul>
                <li>
                  Consumer must be able to find your products before they can
                  buy them, and searching is the primary way they can do that.
                  Service section allows us to search for a particular service
                  by selecting the Country where you need serviceselecting the
                  categorySeclecting the sub-category
                </li>
                <li>
                  After all the selections are done, Consumer can go ahead and
                  create the service requisition
                </li>
                <li>
                  Services providers wanting to add a service can also add a
                  service they want to offer by clicking on “OFFER NEW SERVICE”
                </li>
              </ul>
            </span>
          </Content>
        </div> */}
        {/* <div className="profileWrapper" >
          <Content>
            <span>
              <h5>
                <a href="/services/service-requests" style={{color:"#0084ff"}}>2.3 SERVICE REQUESTS</a>
              </h5>
              <ul>
                <li>
                  The Service Request View page displays all the service
                  requests generated up until from the starting. You can search
                  for a specific service request by using the filter section to
                  filter it by the status , country and category of service
                  request.
                </li>
                <li>
                  Click on Service Request number to see the details of that
                  service requisition.
                </li>
              </ul>
            </span>
          </Content>
        </div> */}
        {/* <div className="profileWrapper">
         <Content>
            <span>
              <h5>
                <a href="/services/service-deals" style={{color:"#0084ff"}}>2.4 SERVICE DEALS</a>
              </h5>
              <ul>
                <li>
                  The Service Deal View page displays all the service requests
                  generated up until from the starting. You can search for a
                  specific service request by using the filter section to filter
                  it by the status , country and category of service request
                </li>
                <li>
                  Click on Service Deal number to see the details and status of
                  that service deal
                </li>
                <li>
                  Once you click on any service deals, detail page will open up
                  showing the deal status , journey, Deal Details.
                </li>
                <li>
                  By Navigating to the Quote tab, you can take necessary actions
                  to take the service to closure with the service provider or
                  Trading Company.
                </li>
                <li>
                  Documents Section in Service Deals will allow you to add or
                  view documents required at a particular stage of the deal.
                </li>
              </ul>
            </span>
          </Content>
          </div> */}

          {/* NEW */}
        {/* <div className={classes.Content}>
          <Content>
            <div>
              <div className={classes.title}>SERVICES</div>
              <div>
                Tradxlink services section is a ‘one-stop shop’ for trusted
                Suppliers, Buyers, Financer and Service Providers to discover
                services to help to launch, manage and grow their business.
                Explore a directory of third-party services from Service
                Providers across the globe.Connect to trusted trade ecosystem
                participants across regions and marketplaces to acquire the best
                and most relevant service providers.Tradxlink will bring trusted
                trade ecosystem participants involved in the International Trade
                Value Chain on single platform through the process workflows
                solutions and integration across the Data Sources. The Services
                Marketplace on the platform provides an opportunity to service
                providers to showcase their offerings, solicit requisitions,
                respond to quotes, and increase their business reach and revenue
                streams. It also promotes healthy competition amongst them to
                complete and provide the best service to customers. The
                consumers of the services also gain from wider options,
                competitive pricing and trusted ratings helping them make
                informed procurement of services.
                <div>
                  You can visit{" "}
                  <span className={classes.link}>Tradxlink Services page</span>.
                </div>
              </div>
            </div>
            <div className={classes.Parent}>
              <div>
                <div className={classes.title2}>
                  OVERVIEW TAB ON SERVICES MODULE
                </div>
                <div className={classes.title3}>
                  Overview tab will give a summary of all the services related
                  requisitions, service deals, payments information Summary will
                  also reflect the current status of the services related
                  requisitions, service deals, payments information such as In -
                  Progress, Delayed, Pending, Completed.
                </div>
              </div>
              <div>
                <div className={classes.title2}>SERVICES SECTION</div>
                <div className={classes.title3}>
                  Consumer must be able to find your products before they can
                  buy them, and searching is the primary way they can do that.
                  Service section allows us to search for a particular service
                  by selecting the Country where you need serviceselecting the
                  categorySeclecting the sub-category After all the selections
                  are done, Consumer can go ahead and create the service
                  requisition Services providers wanting to add a service can
                  also add a service they want to offer by clicking on “OFFER
                  NEW SERVICE”
                </div>
              </div>
              <div>
                <div className={classes.title2}>SERVICE REQUESTS</div>
                <div className={classes.title3}>
                  The Service Request View page displays all the service
                  requests generated up until from the starting. You can search
                  for a specific service request by using the filter section to
                  filter it by the status , country and category of service
                  request. Click on Service Request number to see the details of
                  that service requisition.
                </div>
              </div>
              <div>
                <div className={classes.title2}>SERVICE DEALS</div>
                <div className={classes.title3}>
                  The Service Deal View page displays all the service requests
                  generated up until from the starting. You can search for a
                  specific service request by using the filter section to filter
                  it by the status , country and category of service request
                  Click on Service Deal number to see the details and status of
                  that service deal Once you click on any service deals, detail
                  page will open up showing the deal status , journey, Deal
                  Details. By Navigating to the Quote tab, you can take
                  necessary actions to take the service to closure with the
                  service provider or Trading Company. Documents Section in
                  Service Deals will allow you to add or view documents required
                  at a particular stage of the deal.
                </div>
              </div>
            </div>
          </Content>
        </div> */}

          <div className={classes.Content1}>
            <Content>
            <div>
              <div className={classes.title1}>
                <a href="/financing/invoice/overview" style={{color:"#0084ff"}}>3. INVOICE FINANCING APP</a>
              </div>
              <div className={classes.title2} style={{display:"flex"}}>
              <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span style={{marginBottom:"15px"}}>
                  <FaFileInvoiceDollar size={100} />
                  </span>
                  </div>
                <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px"}}>
                Tradxlink platform aims to provide SMEs with an option to easily
                obtain finance on multiple instruments by providing solutions to
                publish instruments, seek financing deals from multiple
                financers and choose from competitive offers made on the
                platform. Financers also have access to network of verified
                SMEs, with verified company information, history and ratings
                across geographies providing them opportunity to grow with more
                informed risk. The platform also encourages healthy competition
                amongst financers to help obtain the most favorable
                opportunities creating a win-win scenario for both financers and
                companies. You can visit Invoice Financing App page.
                </span>
                
              </div>
            </div>
            </Content>
          </div>

          <div className={classes.Content1}>
            <Content>
            <div>
              <div className={classes.title1}>
                <a href="/financing/invoice/overview" style={{color:"#0084ff"}}>
                  4. SYNDICATE FINANCING APP
                </a>
              </div>
              <div className={classes.title2} style={{display:"flex"}}>
              <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span style={{marginBottom:"15px"}}>
                  <FaFileInvoiceDollar size={100} />
                  </span>
              </div>
              <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px", marginTop:"8px"}}>
                Tradxlink platform allows multiple financial institutions to
                come together and participate in co-financing deals, support
                larger trade financing deals, agree on Terms & Conditions,
                follow by creating pre-agreed disbursement schedules and
                re-payments through online collaboration solutions. With an
                inbuilt syndication workflow, each financer participant has a
                real-time view of the status of the financing deals and
                disbursements. The DLT based platform ensures complete
                transparency, provenance, auditability with enhanced security.
                You can visit Syndicate Financing App page.
                </span>
              </div>
            </div>
            </Content>
          </div>

          <div className={classes.Content1}>
            <Content>
            <div>
              <div className={classes.title1}>
                <a href="/trade-account/open-account" style={{color:"#0084ff"}}>5. OPEN ACCOUNT TRADE APP</a>
              </div>
              <div className={classes.title2} style={{display:"flex"}}>
              <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span>
                  <FaShippingFast size={100} />
                  </span>
              </div>
              <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px", marginTop:"8px"}}>
                Manage and create different Trade Life Cycle Stages as per your
                need, enabled through Digital Identity, Digital Signatures,
                Secure Communications, and Data Security, mitigate uncertainties
                and risks with auditable trade transactions and communications
                to deliver diverse, distributed, and diligent trade services.
                You can visit Open Account Trade App page
                </span>
              
              </div>
            </div>
            </Content>
          </div>

          <div className={classes.Content1}>
            <Content>
            <div>
              <div className={classes.title1}>
                <a href="/payment/payment-transactions" style={{color:"#0084ff"}}>6. PAYMENTS MODULE</a>
              </div>
              <div className={classes.title2} style={{display:"flex"}}>
              <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span style={{marginBottom:"15px"}}>
                    <MdPayment size={100} />
                  </span>
              </div>
              <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px", marginTop:"8px"}}>
                The platform supports multiple payments channels through the
                onboarded Payment Service Providers which can be one-time or
                scheduled as per preferred domestic and cross border payment
                services available on Tradxlink Platform. It supports Escrow
                Payments, Payment Collections, P2P Payments and Multi-Currency
                Payments through it’s Payment Service Provider Network. Inside
                apps , Trades and services workflow will have integrated
                payments solutions as well. You can visit Payments Dashboard
                page.
                </span>
              </div>
            </div>
            </Content>
          </div>

          <div className={classes.Content1}>
            <Content>
            <div>
              <div className={classes.title1}>
                <a href="/tradeover" style={{color:"#0084ff"}}>7. TRADES</a>{" "}
              </div>
            <div className={classes.title2} style={{display:"flex"}}>
            <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span>
                  <FaHandshake size={100} />
                  </span>
              </div>
            <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px", marginTop:"8px"}}>
                Tradxlink provides a common and open information infrastructure
                that spans across borders and actors integrating the systems of
                exporters, importers, freight service providers, shipping lines,
                etc. This has the potential to make the collaboration across
                borders more efficient, secure and considerably improve
                facilitation of trade information. This will enable e2e
                international trade services for participants across their trade
                lifecycle stages bringing trust among participants, integrated
                trade lifecycle data, ecosystem partnerships, credibility of
                transactions, trade analytics and data monetization
                possibilities.
              </span>
             
            </div>
            </div>
            </Content>
          </div>

          <div className={classes.Content1}>
            <Content padding="2% 2% 0.2% 2%">
            <div>
              <div className={classes.title1}>
                <a href="/mistrade" style={{color:"#0084ff"}}>8. MIS DASHBOARD</a>
              </div>
              <div className={classes.title2} style={{display:"flex"}}>
              <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span style={{marginBottom:"15px"}}>
                    <FaChartBar size={100} />
                  </span>
              </div>
              <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px", marginTop:"8px"}}>
                MIS & dashboards are available for providing auditability of
                upcoming disbursements, scheduled payments and final settlement
                from buyers providing an holistic view of cash flows for
                participants. You can visit Payments Dashboard page.
              </span>  
              </div>
            </div>
            </Content>
          </div>

          <div className={classes.Content1}>
            <Content>
            <div>
              <div className={classes.title1}>
                <a href="/documents" style={{color:"#0084ff"}}>9. DOCUMENTS MODULE</a>
              </div>
              <div className={classes.title2} style={{display:"flex"}}>
              <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span>
                  <FaFileAlt size={100} />
                  </span>
              </div>
              <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px", marginTop:"8px"}}>
                Tradxlink provides a distributed document storage and sharing
                infrastructure. Documents can be made private or public,
                requested & shared securely with consent of the document owner.
                Also, security and privacy being at the heart of the solution,
                the platform offers digital signing of documents, consent
                management, ability to categorize document and complete
                traceability and auditability of actions performed on documents.
                Document owners can solicit verification/ accreditation from the
                issuers or designated verifiers of the documents creating a
                trust ecosystem. With advanced OCR capabilities, the platform
                can analyze documents, extract information and pre-fill forms
                during Invoice Financing requests to reduce time and effort for
                users to provide intelligent insights to document owners.
              </span>
              
              </div>
            </div>
            </Content>
          </div>

          <div className={classes.Content1}>
            <Content padding="2% 2% 0.2% 2%">
            <div>
              <div className={classes.title1}>
                <a href="/" style={{color:"#0084ff"}}>
                  10. Dashboard: Everything You Need to Know About your Dashboard{" "}
                </a>
              </div>
              <div className={classes.title2} style={{display:"flex"}}>
              <div style={{display:"flex", justifyContent:"center" ,alignContent:"center",flexWrap:"wrap"}}>
                  <span style={{marginBottom:"15px"}}>
                    <FaHome size={100} />
                  </span>
              </div>
              <span style={{textAlign:"justify", fontFamily:"Mulish,sans-serif", fontSize:"15px", marginLeft:"20px", marginTop:"8px"}}>
                The Dashboard section offers a variety of reports/Charts related
                to Trades, Services , Payments , Networks, Financing . Available
                reports are listed in the slider screens. Available data columns
                for Business Reports are in this screen. Mouse over to the far
                right or left to navigate between screens.
                </span>
              </div>
            </div>
            </Content>
          </div>
        </div>
      </Content>
    </>
  );
};

export default Help;
