import React from "react";
import { ActionBarStyled } from "components/Container.styled";
import styled from "styled-components";
import Content from "components/UI/Layout/Content";
import FinanceDeals from "../Deals/financeDeals";
import ChangeState from "../Deals/changeState";
import {useParams} from 'react-router-dom';
// import History from "../Deals/history";
// import History from "../../../../../Invoicefinancing/uploadInvoice/Deals/history";
import History from "components/History/history";
// import Alerts from "../Deals/alerts";
import Documents from "pages/Document/index.js";
export const Deals = () => {
  const [selected, setSelected] = React.useState("FinanceDeals");
  const params= useParams();

  const tabs = [
    {
      title: "Finance Deals",
      description: <FinanceDeals />,
      value: "FinanceDeals",
    },

    {
      title: "Change State",
      description: "Change State",
      value: "ChangeState",
    },
    {
      title: "Documents",
      description: "Documents State",
      value: "Documents",
    },
    {
      title: "History",
      description: "History",
      value: "History",
    },
  ];

  return (
    <>
      <ActionBarStyled>
        {tabs.map((item, index) => (
          <NavBar
            key={index}
            onClick={() => setSelected(item.value)}
            style={selected === item.value ? addBlue : addWhite}
          >
            <li>{item.title}</li>
          </NavBar>
        ))}
      </ActionBarStyled>

      {selected === "FinanceDeals" ? (
        <Content>
          <FinanceDeals />
        </Content>
      ) : (
        ""
      )}

      {selected === "ChangeState" ? (
        <Content>
          <ChangeState />
        </Content>
      ) : (
        ""
      )}

      {selected === "Documents" ? (
        <Content>
          <Documents />
        </Content>
      ) : (
        ""
      )}

      {selected === "History" ? (
        <Content>
          <History screen="INVOICES" id=''/>
        </Content>
      ) : (
        ""
      )}

      {/* {selected === "Alerts" ? (
        <Content>
          
          <Alerts />
        </Content>
      ) : (
        ""
      )} */}
    </>
  );
};

const NavBar = styled.div`
  padding-bottom: 20px;
  li {
    cursor: pointer;
    :hover {
      color: #218eeb;
    }
  }
`;

const DealDetail = styled.div`
  // border: 1px solid #42A5F5;
  padding: 15px;
  margin: 10px 0px;
`;

const addWhite = {
  color: "white",
};
const addBlue = {
  color: "#42A5F5",
};
