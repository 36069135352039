import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
// import ButtonDiv from "components/UI/Layout/ButtonDiv";
// import Button from "components/UI/Button/Button";
// import Loader from "components/Loader/Loader";
// import moment from "moment";
import { Industry } from "utils/Section/trade";
// import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { Countries } from "utils/DropDown/Country";
import port_codes from "jsons/PortCodes.json";
import { Grid } from "@mui/material";

const Freights = ({ response }) => {
  const [serviceDetails, setServiceDetails] = useState([]);
  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails));
    }
  }, [response]);
  const [formdata, setFormData] = useState({
    freightType: "INTERNATIONAL",
    shippingType: "",
    quantity: "",
    grossWeightPerContainer: "",
    CARGO_READY: "",
    PREFERRED_SAILING_DATE: "",
    cargoHazardous: "",
    UN_Number: "",
    originType: "",
    origin: "",
    destinationType: "",
    destination: "",
    commodity: "",
    containerType: "",
    measureUnit: "",
  });
  const LoadHeader = [
    { name: "Container_Count" },
    { name: "Container_Size" },
    { name: "Cargo_Weight_Per_Container" },
    { name: "Cargo_Value" },
  ];

  const LoadHeaderRoad = [
    { name: "Package_Type" },
    { name: "Total_Quantity" },
    { name: "Total_Weight" },
    { name: "Length" },
    { name: "Weidth" },
    { name: "Height" },
  ];

  const LoadHeaderAir = [
    { name: "Total_Units" },
    { name: "Total_Volume" },
    { name: "Total_Weight" },
    { name: "Total_Package" },
  ];
  const [addCargo, setAddCargo] = useState(false);
  const handleCargo = (event) => {
    setAddCargo(true);
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  return (
    <>
      {response && serviceDetails && serviceDetails.cargoDetails ? (
        <>
          <div className={classes.header} style={{ margin: "10px" }}>
            Where Are You Shipping?
          </div>
          <div className={classes.source} style={{ margin: "10px" }}>
            <div style={{ marginLeft: "1%", width: "50%" }}>
              <input
                className={classes.radio}
                type="radio"
                name="freightType"
                value="INTERNATIONAL"
                // onChange={handleRadio}
                checked={
                  serviceDetails &&
                  serviceDetails.freightType == "INTERNATIONAL"
                }
                readOnly={true}
              />
              <label>International Freight</label>
            </div>
            <div
              style={{
                marginLeft: "1%",
                width: "50%",
                textAlign: "right",
                marginRight: "1%",
              }}
            >
              <input
                className={classes.radio}
                type="radio"
                name="freightType"
                value="DOMESTIC"
                // onChange={handleRadio}
                checked={
                  serviceDetails && serviceDetails.freightType == "DOMESTIC"
                }
                readOnly={true}
              />
              <label>Domestic Freight</label>
            </div>
          </div>
          <div style={{ margin: "10px" }}>Mode of Shipment</div>
          <div style={{ width: "25%", margin: "10px", paddingRight: "20px" }}>
            <Input
              name="transportMode"
              placeholder="Transport Mode"
              readOnly={true}
              value={serviceDetails && serviceDetails.transportMode}
            />
          </div>
          {/* <div style={{ marginBottom: "1%", marginTop: "2%" }}>
        Shipment details
      </div>
      <div className={classes.source} style={{ marginBottom: "4%" }}>
        <div className={classes.source} style={{ width: "40%" }}>
          <div style={{ width: "30%" }}>
            <Input
              // options={sourceOptions}
              name="originType"
              placeholder="From"
              // handleChange={handleChange}
              value={serviceDetails && serviceDetails.originType}
              readOnly={true}
            />
          </div>
          <div style={{ width: "70%" }}>
            <Input
              name="origin"
              placeholder="Origin"
              readOnly={true}
              value={serviceDetails && serviceDetails.origin}
              // handleChange={handleChange}
            />
          </div>
        </div>
        <div style={{ width: "20%" }}></div>
        <div className={classes.source} style={{ width: "40%" }}>
          <div style={{ width: "30%" }}>
            <Input
              // options={destinationOptions}
              name="destinationType"
              placeholder="To"
              // handleChange={handleChange}
              value={serviceDetails && serviceDetails.destinationType}
              readOnly={true}
            />
          </div>
          <div style={{ width: "70%" }}>
            <Input
              name="destination"
              placeholder="Destination"
              value={serviceDetails && serviceDetails.destination}
              // handleChange={handleChange}
              readOnly={true}
            />
          </div>
        </div>
      </div>
      <div>
      <div className={classes.source}>
          <div style={{ marginBottom: "1.2%", width: "50%",marginLeft:"1%" }}>
            <label>CARGO READY DATE</label>
          </div>
         {serviceDetails.transportMode && serviceDetails.transportMode === "SEA" ? (
           <div style={{ marginBottom: "1.2%", width: "50%", marginLeft:"1%" }}>
           <label>PREFERRED SAILING DATE</label>
         </div>
         ):( <div style={{ marginBottom: "1.2%", width: "50%", marginLeft:"1%" }}>
         <label>PREFERRED DISPATCH DATE</label>
       </div>)}
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", marginBottom: "2%" }}>
            <Input
              name="CARGO_READY"
              placeholder="Cargo Ready Date"
              value={serviceDetails && serviceDetails.CARGO_READY}
              // handleChange={(e) => handleChange(e, "CARGO_READY")}
              readOnly={true}
            />
          </div>
          <div style={{ width: "50%" }}>
          {serviceDetails.transportMode && serviceDetails.transportMode ==="SEA" ? (
                <Input
                name="PREFERRED_SAILING_DATE"
                placeholder="PREFERRED SAILING DATE MM/DD/YYYY"
                value={serviceDetails && serviceDetails.PREFERRED_SAILING_DATE}
                readOnly={true}
                
              />
              ):(<Input
                name="PREFERRED_SAILING_DATE"
                placeholder="PREFERRED DISPATCH DATE MM/DD/YYYY"
                value={serviceDetails && serviceDetails.PREFERRED_SAILING_DATE}
                readOnly={true}
              />)}
          </div>
        </div>
      </div> */}
          <div style={{ margin: "10px" }}>Shipment details</div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={Countries}
                name="countryFrom"
                placeholder="Country  From"
                value={serviceDetails && serviceDetails.countryFrom}
                readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={Countries}
                name="countryTo"
                placeholder="Country To"
                value={serviceDetails && serviceDetails.countryTo}
                readOnly={true}
              />
            </div>
          </div>
          {/* Countries */}
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="cityFrom"
                placeholder="City From"
                value={serviceDetails && serviceDetails.cityFrom}
                readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="cityTo"
                placeholder="City To"
                value={serviceDetails && serviceDetails.cityTo}
                readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={port_code}
                name="portOrigin"
                placeholder="From Port"
                value={serviceDetails && serviceDetails.portOrigin}
                readOnly={true}
              />
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={port_code}
                name="destinationPort"
                placeholder="To Port"
                value={serviceDetails && serviceDetails.destinationPort}
                readOnly={true}
              />
            </div>
            {/* <Select */}
            {/* options={port_code}
            name="destinationPort" placeholder="To Port" value=
            {serviceDetails && serviceDetails.destinationPort}
            readOnly={true}
            /> */}
          </div>
          <div>
            <div>
              <div style={{ margin: "10px" }}>
                <label>COMMODITY</label>
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Select
                  options={Industry}
                  name="commodity"
                  placeholder="Commodity"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.commodity}
                  // handleChange={handleChange}
                />
              </div>
            </div>
          </div>

          <>
            <div style={{ fontSize: "20px", margin: "10px" }}>
              Cargo Details
            </div>

            <div>
              {/* <Button onClick={handleCargo}>+ Add Loads</Button> */}
            </div>
            <div className={classes.TableContainer}>
              <Table bordered responsive="sm" className={cn(classes.Table)}>
                {serviceDetails && serviceDetails.transportMode && (
                  <>
                    <thead>
                      {(serviceDetails.transportMode === "SEA" ||
                        serviceDetails.transportMode === "RAIL") && (
                        <>
                          <tr>
                            {LoadHeader &&
                              LoadHeader.map((header, i) => (
                                <td key={i}>{header.name}</td>
                              ))}
                          </tr>
                        </>
                      )}
                      {serviceDetails.transportMode === "AIR" && (
                        <>
                          <tr>
                            {LoadHeaderAir &&
                              LoadHeaderAir.map((header, i) => (
                                <td key={i}>{header.name}</td>
                              ))}
                          </tr>
                        </>
                      )}
                      {serviceDetails.transportMode === "ROAD" && (
                        <>
                          <tr>
                            {LoadHeaderRoad &&
                              LoadHeaderRoad.map((header, i) => (
                                <td key={i}>{header.name}</td>
                              ))}
                          </tr>
                        </>
                      )}
                    </thead>
                  </>
                )}
                <tbody>
                  {serviceDetails.transportMode &&
                    serviceDetails.cargoDetails.map((tableCargoData1, i) => (
                      <tr key={i}>
                        {(serviceDetails.transportMode === "SEA" ||
                          serviceDetails.transportMode === "RAIL") && (
                          <>
                            <td>
                              {tableCargoData1 &&
                                tableCargoData1.Containers_Count}
                            </td>
                            <td>
                              {tableCargoData1 &&
                                tableCargoData1.Container_Size}
                            </td>
                            <td>
                              {tableCargoData1 &&
                                tableCargoData1.Cargo_Weight_Per_Container}
                              {" MT"}
                            </td>
                            <td>
                              {tableCargoData1 && tableCargoData1.Cargo_Value}{" "}
                              {tableCargoData1 && tableCargoData1.Currency}
                            </td>
                          </>
                        )}
                        {serviceDetails.transportMode === "ROAD" && (
                          <>
                            <td>
                              {tableCargoData1 && tableCargoData1.Package_Type}
                            </td>
                            <td>
                              {tableCargoData1 &&
                                tableCargoData1.Total_Quantity}
                            </td>
                            <td>
                              {tableCargoData1 && tableCargoData1.Total_Weight}{" "}
                              {tableCargoData1 && tableCargoData1.weight_unit}
                            </td>
                            {/* <td>
                        {tableCargoData1 &&
                          tableCargoData1.Max_Dimension}{" cm cube"}
                      </td> */}
                            <td>
                              {tableCargoData1 && tableCargoData1.Length}
                              {" cm"}
                            </td>
                            <td>
                              {tableCargoData1 && tableCargoData1.Weidth}
                              {" cm"}
                            </td>
                            <td>
                              {tableCargoData1 && tableCargoData1.Height}
                              {" cm"}
                            </td>
                          </>
                        )}
                        {serviceDetails.transportMode === "AIR" && (
                          <>
                            <td>
                              {tableCargoData1 && tableCargoData1.Total_Units}
                            </td>
                            <td>
                              {tableCargoData1 && tableCargoData1.Total_Volume}{" "}
                              {tableCargoData1 && tableCargoData1.Volume_unit}
                            </td>
                            <td>
                              {tableCargoData1 &&
                                tableCargoData1.Total_WeightAir}{" "}
                              {tableCargoData1 && tableCargoData1.weight_unit1}
                            </td>
                            <td>
                              {tableCargoData1 && tableCargoData1.Total_Package}{" "}
                              {tableCargoData1 && tableCargoData1.package_unit}
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>

            {addCargo && (
              <>
                <div className={classes.loads}>
                  <div className={classes.source}>
                    <div style={{ width: "50%", margin: "10px" }}>
                      <Input
                        name="quantity"
                        placeholder="QTY"
                        value={serviceDetails && serviceDetails.quantity}
                        // handleChange={handleChange}
                        readOnly={true}
                      />
                    </div>
                    <div style={{ width: "50%", margin: "10px" }}>
                      <Select
                        // options={containerTypeOptions}
                        name="containerType"
                        placeholder="Container Type"
                        // handleChange={handleChange}
                        value={serviceDetails && serviceDetails.containerType}
                        readOnly={true}
                      />
                    </div>
                  </div>

                  <div className={classes.source}>
                    <div style={{ width: "50%", margin: "10px" }}>
                      <Input
                        name="grossWeightPerContainer"
                        placeholder="Gross Weight Per Container"
                        value={
                          serviceDetails &&
                          serviceDetails.grossWeightPerContainer
                        }
                        // handleChange={handleChange}
                        readOnly={true}
                      />
                    </div>
                    <div style={{ width: "50%", margin: "10px" }}>
                      <Select
                        // options={measureUnitOptions}
                        name="measureUnit"
                        placeholder="Measure Unit"
                        // handleChange={handleChange}
                        value={serviceDetails && serviceDetails.measureUnit}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
          {serviceDetails && serviceDetails.cargoHazardous && (
            <div>
              <input
                className={classes.Checkbox}
                type="checkbox"
                checked={true}
                // onClick={handleCheckbox}
                // onChange={(e) => setIsHazardous(e.target.checked)}
              />
              <label
                style={{
                  marginLeft: "1%",
                  fontSize: "18px",
                  marginBottom: "1%",
                }}
              >
                {" "}
                This cargo is hazardous
              </label>
            </div>
          )}

          {serviceDetails && serviceDetails.cargoHazardous && (
            <div className={classes.source} style={{ marginBottom: "2%" }}>
              <div className={classes.source} style={{ width: "40%" }}>
                <div style={{ width: "50%" }}>
                  <Input
                    // options={cargoHazardousOptions}
                    name="cargoHazardous"
                    placeholder="cargo Hazardous"
                    // handleChange={handleChange}
                    value={serviceDetails && serviceDetails.cargoHazardous}
                    readOnly={true}
                  />
                </div>
                <div style={{ width: "50%" }}>
                  <Input
                    name="UN_Number"
                    placeholder="UN#"
                    value={serviceDetails && serviceDetails.UN_Number}
                    // handleChange={handleChange}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Freights;
