import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { HiArrowSmLeft } from "react-icons/hi";
import styled from "styled-components";
import { ButtonStyled } from "../../../../../components/Container.styled";
import { storeContext } from "../../../../../contexts/Store/Store";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";

import { NetworkURL } from "../../../../../services/BaseURLInstance";

const Styles = styled.div`
  .backtoloan {
    display: flex;
    color: #0067a0;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 15px;
    line-height: 71%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
  }
  .loanSummarytext {
    margin-top: 35px;
    font-family: Roboto;
    font-style: Medium;
    font-size: 20px;
    line-height: 24px;
    line-height: 102%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
  }
  .loandetails {
    background: #ffffff0d;
    border-radius: 8px;
    padding-bottom: 20px;
    padding: 16px;
  }
  .header {
    margin-top: 25px;
    width: 100%;
    display: flex;
    background-color: #343434;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .header > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50.47px;
    width: 20%;
    border-left: 1px solid white;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    border-width: thin;
    overflow: hidden;
  }
  .row {
    width: 100%;
    display: flex;
    background: #010915;
  }

  .row > div {
    display: flex;
    height: 50.47px;
    width: 20%;
    justify-content: center;
    align-items: center;
    border: 0.0001px solid #ffffff;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    overflow: hidden;
  }

  .details {
    padding-bottom: 20px;
    font-family: Roboto;
    font-style: Regular;
    font-size: 16px;
    line-height: 24px;
    line-height: 128%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 26px;
    width: 70%;
  }
  .details-screen {
    display: flex;
    background: #010915;
    padding-left: 20px;
    padding-right: 33px;
  }
  .details-button {
    margin-top: 60px;
    margin-left: 50px;
  }
  .details-button > button {
    display: block;
    margin: 4px;
    background-color: #42a5f5;
    color: white;
    cursor: pointer;
    width: 214.24px;
    height: 50px;
  }
  .details > hr {
    height: 0.0000000001px;
    opacity: 20%;
  }
  .details > div {
    margin-top: 20px;
  }
  .loanDetailsFields {
    margin-top: 20px;
    background: #ffffff0d;
    border-radius: 5px;
    padding-bottom: 1px;
  }
  .loanDetailsHeading {
    padding: 16px;
    color: #42a5f5;
    font-family: Roboto;
    font-style: Bold;
    font-size: 20px;
    line-height: 24px;
    line-height: 102%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 32px;
  }
  .inputFieldView {
    background: #010915;
    border-radius: 5px;
    padding-bottom: 16px;
    width: 97.5%;
    margin: 16px;
  }
  .setOfInput {
    display: flex;
    padding-top: 40px;
    justify-content: center;
  }
  input {
    border-radius: 6px;
    margin-top: -10px;
    margin-right: 20px;
    width: 400px;
    height: 51px;
    outline: none;
    background: none;
    z-index: -11;
    border: white solid;
    color: white;
    padding-left: 20px;
    border: 0.2px solid #42a5f5;
  }
  label {
    padding-left: 5px;
    padding-right: 5px;
    margin-top: -21px;
    position: absolute;
    margin-left: 30px;
    background: #010915;
    z-index: 10;
    font-size: 15px;
    color: #42a5f5;
  }
  textarea {
    border-radius: 6px;
    margin-top: -10px;
    margin-right: 20px;
    width: 400px;
    height: 204px;
    outline: none;
    background: none;
    z-index: -11;
    border: white solid;
    color: white;
    padding-left: 20px;
    padding-top: 20px;
    border: 0.2px solid #42a5f5;
  }
`;

export default () => {
  const data = JSON.parse(localStorage.getItem("loanDetails"));
  const navigate = useNavigate();
  const handleBackToLoan = () => {
    navigate("/financedashBoard/pofinancing/Loans");
  };

  const { fetchCompanyList } = useContext(storeContext);
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState(null);
  getIdToken(instance, accounts, setIdToken);
  const companyId = getClaim(accounts, "company_member_id");
  const [comapnyList, setCompanyList] = useState("");

  useEffect(() => {
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken)
      .then((companyList) => {
        setCompanyList(companyList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <Styles>
      {comapnyList && (
        <div className="loandetails">
          <div className="backtoloan" onClick={handleBackToLoan}>
            <div>
              <HiArrowSmLeft />
            </div>
            <div>Back to Loans</div>
          </div>
          <div className="loanSummarytext">Loan Summary</div>
          <div className="table">
            <div className="header">
              <div
                style={{
                  borderLeft: "none",
                  borderTop: "none",
                  paddingLeft: "31px",
                }}
              >
                Loan Id
              </div>
              <div>Financer</div>
              <div>Reference</div>
              <div>Date</div>
              <div>Status</div>
            </div>
            <div className="row">
              <div style={{ borderLeft: "none", paddingLeft: "31px" }}>
                {data.loanId}
              </div>
              {comapnyList.map((item) => {
                if (item.memberUID === data.financerMemberUID) {
                  return (
                    <div style={{ borderLeft: "none" }}>{item.memberName}</div>
                  );
                }
              })}
              <div style={{ borderLeft: "none" }}>
                <Link
                  to={`/financedashBoard/pofinancing/invoicesDetails/${data.referenceFinanceInstrumentId}`}
                >
                  Invoice Detail
                </Link>
              </div>
              <div style={{ borderLeft: "none" }}>
                {new Date(data.createdDate).toLocaleDateString()}
              </div>
              <div style={{ borderLeft: "none", borderRight: "none" }}>
                <div>{data.loanStatus}</div>
              </div>
            </div>
            <div className="details-screen">
              <div className="details">
                <div>Re-Payment Due : {data.financeRepaymentDate}</div>

                <div style={{ display: "flex" }}>
                  <div>LAmount past due : {data.financeRepaymentDate}</div>
                  <span style={{ marginleft: "40px" }}></span>
                  <div>LAmount past due : {data.financeRepaymentDate}</div>
                </div>

                <div style={{ display: "flex" }}>
                  <div>LAmount past due : {data.financeRepaymentDate}</div>
                  <span style={{ marginleft: "40px" }}></span>
                  <div>LAmount past due : {data.financeRepaymentDate}</div>
                </div>
              </div>
              <div className="details-button">
                <ButtonStyled> Make Payment</ButtonStyled>
              </div>
            </div>
          </div>
        </div>
      )}

      {comapnyList && (
        <div>
          <div className="loanDetailsFields">
            <div>
              <div className="loanDetailsHeading">Loan Details</div>
              <div className="inputFieldView">
                <div className="setOfInput" style={{ paddingTop: "60px" }}>
                  <div className="inputField">
                    <div className="label">
                      <label For="dealid">Loan Id CCCCC</label>
                    </div>
                    <div>
                      <input type="text" value={data.loanId} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="NBFCID">Financer ID</label>
                    </div>
                    <div>
                      {comapnyList.map((item) => {
                        if (item.memberUID === data.financerMemberUID) {
                          return <input type="text" value={item.memberName} />;
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="LoanProductType">Loan Product Type</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeProductCategory} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="InvoiceReference">Invoice Reference</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={data.referenceFinanceInstrumentId}
                      />
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="FinanceAmount">Finance Amount</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeAmount} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="InterestRate">Interest Rate</label>
                    </div>
                    <div>
                      <input type="text" value={data.interestRate} />
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="RequestedFinanceAmount">
                        Requested Finance Amount
                      </label>
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="SellerID">Seller ID</label>
                    </div>
                    <div>
                      {comapnyList.map((item) => {
                        if (item.memberUID === data.traderMemberUID) {
                          return <input type="text" value={item.memberName} />;
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="FinanceDate">Currency</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeCurrency} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="PaymentMethod">Payment Method</label>
                    </div>
                    <div>
                      <input type="text" value={data.paymentMethodType} />
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="FinanceDate">Finance Disbursal Date</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeDisbursalDate} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="CreatedBy">Created By</label>
                    </div>
                    <div>
                      {comapnyList.map((item) => {
                        if (item.memberUID === data.createdBy) {
                          return <input type="text" value={item.memberName} />;
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="FinanceDate">Loan Status</label>
                    </div>
                    <div>
                      <input type="text" value={data.loanStatus} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="CreatedBy">Activation Status</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={data.termsAndConditionsDocument.createdBy}
                      />
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="FinanceDate">Disbursement Status</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeDisbursalStatus} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="CreatedBy">Repayment Amount</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeRepaymentAmount} />
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="FinanceDate">Repayment Due Date</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeRepaymentDate} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="CreatedBy">Repayment Status</label>
                    </div>
                    <div>
                      <input type="text" value={data.financeRePaymentStatus} />
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="FinanceDate">Loan Fee 1</label>
                    </div>
                    <div>
                      <input type="text" value={data.loanFees} />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="CreatedBy">Loan Fee 2</label>
                    </div>
                    <div>
                      <input type="text" />
                    </div>
                  </div>
                </div>
                <div className="setOfInput">
                  <div className="inputField">
                    <div className="label">
                      <label For="CreatedDate">Created Date</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={new Date(data.createdDate).toLocaleDateString()}
                      />
                    </div>
                    <div style={{ marginTop: "40px" }} className="label">
                      <label For="LastUpdatedBy">Last Updated By</label>
                    </div>
                    <div>
                      {comapnyList.map((item) => {
                        if (item.memberUID === data.lastUpdateBy) {
                          return <input type="text" value={item.memberName} />;
                        }
                      })}
                    </div>
                    <div style={{ marginTop: "40px" }} className="label">
                      <label For="LastUpdatedDate">Last Updated Date</label>
                    </div>
                    <div>
                      <input
                        type="text"
                        value={new Date(
                          data.lastUpdatedDate
                        ).toLocaleDateString()}
                      />
                    </div>
                  </div>
                  <div className="inputField">
                    <div className="label">
                      <label For="CreatedBy">Append notes in chat:</label>
                    </div>
                    <div>
                      <textarea
                        type="text"
                        placeholder="Some notes from Financer….."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Styles>
  );
};
