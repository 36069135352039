import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import moment from "moment";

// import {invoiceFinanceDetailsContext} from '../../../../contexts/invoiceFinance/invoiceFinanceDetailsContext'

const Miscellaneous = () => {

    //const {miscellaneousData,handleMiscellaneousChange} = useContext(invoiceFinanceDetailsContext)

    const [miscData, setMiscData] = useState({
        ContrRefr: "",
        ProjRefr: "",
        PORefr: "",

        PORefDt: ""
    });

    useEffect(() => {
        let general = JSON.parse(localStorage.getItem("uploadData"));
        if (general.invoiceDetails.RefDtls.ContrDtls === null) {
            general.invoiceDetails.RefDtls.ContrDtls = {
                "RecAdvRefr": "",
                "RecAdvDt": "",
                "TendRefr": "",
                "ContrRefr": "",
                "ExtRefr": "",
                "ProjRefr": "",
                "PORefr": "",
                "PORefDt": ""
            }
            localStorage.setItem("uploadData", JSON.stringify(general))
        }
        general = JSON.parse(localStorage.getItem("uploadData"));
        setMiscData({
            ...miscData,
            ContrRefr: general.invoiceDetails.RefDtls.ContrDtls.ContrRefr,
            ProjRefr: general.invoiceDetails.RefDtls.ContrDtls.ProjRefr,
            PORefr: general.invoiceDetails.RefDtls.ContrDtls.PORefr,
            PORefDt: general.invoiceDetails.RefDtls.ContrDtls.PORefDt,
        });

    }, [])

    const handleMiscellaneousChange = (event, name) => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        if (name === 'PORefDt') {
            general.invoiceDetails.RefDtls.ContrDtls.PORefDt = event;
            localStorage.setItem("uploadData", JSON.stringify(general));

            let newDate1 = moment(new Date(event)).format('YYYY-MM-DD')
            setDatePicker1(newDate1)
            setMiscData({
                ...miscData,
                [name]: event
            })
            return
        }
        if (event.target.name === "ContrRefr") {
            general.invoiceDetails.RefDtls.ContrDtls.ContrRefr = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setMiscData({
                ...miscData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "ProjRefr") {
            general.invoiceDetails.RefDtls.ContrDtls.ProjRefr = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setMiscData({
                ...miscData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "PORefr") {
            general.invoiceDetails.RefDtls.ContrDtls.PORefr = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setMiscData({
                ...miscData,
                [event.target.name]: event.target.value
            })
        }

    }

    const [DatePicker1, setDatePicker1] = useState(new Date());

    return (
        <Grid container>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="ContrRefr"
                    placeholder="Contact Reference"
                    value={miscData.ContrRefr}
                    handleChange={handleMiscellaneousChange}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="ProjRefr"
                    placeholder="Project Reference"
                    value={miscData.ProjRefr}
                    handleChange={handleMiscellaneousChange}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="PORefr"
                    placeholder="PO Reference"
                    value={miscData.PORefr}
                    handleChange={handleMiscellaneousChange}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                <DateInput
                    placeholder="PO Reference Date"
                    value={DatePicker1}
                    handleChange={(value) => handleMiscellaneousChange(value, "PORefDt")}
                />
            </Grid>
        </Grid>
    )
}
export default Miscellaneous

