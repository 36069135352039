import React from "react";
import styles from "../edit.module.css";
import DateInput from "components/fields/DateInput";
import Input from "components/fields/Input";

const CompanyRegistration = ({ companyData, handleChange }) => {
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  return (
    <>
      <div>
        <div className={styles.heading2} style={{ margin: "10px" }}>
          <span style={{ fontSize: "18px" }}>
            Registration Details (General)
          </span>
        </div>

        <div className={styles.FormDiv}>
          <div style={{ margin: "10px" }}>
            <Input
              name="registrationNumber"
              placeholder="Registration Number*"
              value={companyData.registrationNumber}
              handleChange={handleChange}
              helperText="E.g. CIN for India"
            />
          </div>

          <div style={{ margin: "10px" }}>
            <DateInput
              name="dateOfIncorporation"
              placeholder="Date of Incorporation*"
              value={companyData.dateOfIncorporation}
              handleChange={(e) => handleChange(e, "dateOfIncorporation")}
              maxDate={true}
              backDate={true}
            />
          </div>
        </div>

        <div className={styles.FormDiv}>
          <div style={{ margin: "10px" }}>
            <Input
              name="GSTIN"
              placeholder="Tax Number"
              value={companyData.GSTIN}
              handleChange={handleChange}
              helperText="Eg. GSTIN for India , EIN/TIN for USA"
            />
          </div>

          {memberType !== "FINANCER" ? (
            <div style={{ margin: "10px" }}>
              <Input
                name="sicCode"
                placeholder="SIC Code"
                value={companyData.sicCode}
                handleChange={handleChange}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {memberType !== "FINANCER" && (
          <div className={styles.FormDiv}>
            <div style={{ margin: "10px" }}>
              <Input
                name="lei"
                placeholder="LEI"
                value={companyData.lei}
                handleChange={handleChange}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <Input
                name="dunsNumber"
                placeholder="DUNS Number"
                value={companyData.dunsNumber}
                handleChange={handleChange}
              />
            </div>
          </div>
        )}
      </div>

      <div className={styles.profileForm}>
        <div className={styles.heading2} style={{ margin: "10px" }}>
          <span style={{ fontSize: "18px" }}>Registration Details (India)</span>
        </div>

        <div className={styles.FormDiv}>
          <div style={{ margin: "10px" }}>
            <Input
              placeholder="PAN*"
              name="pan"
              value={companyData.pan}
              handleChange={(e) => handleChange(e)}
            />
          </div>

          <div style={{ margin: "10px" }}>
            <Input
              placeholder="TAN"
              name="tan"
              value={companyData.tan}
              handleChange={(e) => handleChange(e)}
            />
          </div>
        </div>

        <div className={styles.FormDiv}>
          <div style={{ margin: "10px 20px 10px 10px" }}>
            <Input
              placeholder="UDHYAM"
              name="udhyamId"
              // value={companyData.udhyamId}
              // handleChange={(e) => handleChange(e)}
            />
          </div>
          <div></div>
        </div>
      </div>
    </>
  );
};

export default CompanyRegistration;
