import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef, useContext } from "react";
import { Grid, Button } from "@mui/material";
import DateInput from "components/fields/DateInput";
import { tradeURL } from "services/BaseURLInstance";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { storeContext } from "../../../../contexts/Store/Store";
import country_codes from "jsons/CountryCodes.json";
import conditionCheck from "jsons/conditionCheck.json";
import participantStatus from "jsons/participantStatus.json";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { BsPatchQuestion } from "react-icons/bs";
import { Container } from "components/Container.styled";
// import Container from 'components/UI/Layout/Container';
import { participantHeader } from "utils/Section/InvoiceSection";
import Content from "components/UI/Layout/Content";
import Loader from "components/Loader/Loader";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import styles from './../tradeworkflow.module.css'
import { Error, Success } from "components/notifications/responseHandle";
import SelectWithSearch from "components/fields/SelectWithSearch";
import Modal from "components/UI/Modal/Modal";
import APIError from "components/Error/error";


export default ({
  companyList,
  initialState,
  Data,
  secondFlowSequence,
  stageData,
}) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const memberRef = useRef(false);
  const companyId = getClaim(accounts, "company_member_id");
  const [companyList2, setCompanyList2] = useState();
  const [roles, setRoles] = useState();
  const [checked, setChecked] = useState();
  const [participants, setParticipants] = useState();
  const [addStatus, setAddStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [localSubStateData, setLocalSubStageData] = useState();
  const { fetchCompanyList } = useContext(storeContext);
  const [updateData, setUpdateData] = useState({
    participant: "",
    role: "",
    status: "",
    since: "",
    country: "",
    sign: "",
    share: "",
  });

  useEffect(() => {
    if (stageData) {
      setIsLoading(true);
      const idToken = JSON.parse(sessionStorage.getItem("idToken"));
      let URL = `${tradeURL}tradeLifeCycle?stageSequence=${initialState}&subStageSequence=${secondFlowSequence}&tradeId=${Data.tradeId}&tradeStage=${stageData[secondFlowSequence - 1].tradeStage}&tradeSubStage=${stageData[secondFlowSequence - 1].tradeSubStage}`;   
     
      fetchCompanyList(URL, idToken).then((data) => {
        setLocalSubStageData(data);
        setIsLoading(false);
      });
    }
  }, [stageData, secondFlowSequence, initialState, updateStatus]);


  useEffect(() => {
        let temp = companyList.filter((item)=>{
            return item.memberType==="FINANCER" || item.memberType==="COMPANY" || item.memberType==="SERVICE_PROVIDER";
        })
        temp = temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });
        setCompanyList2(temp);
    }, []);
    
  function removeDuplicates(arr) {
      let newArray = [];
      let uniqueObject = {};
      for (let i in arr) {
         let  objTitle = arr[i]['label'];
          uniqueObject[objTitle] = arr[i];
      }
      for (let i in uniqueObject) {
          newArray.push(uniqueObject[i]);
      }
     return newArray;
  }
  
  useEffect(() => {
    if (!localSubStateData?.serviceErrorCode) { 
      let temp2 = localSubStateData?.participants.filter((item,index) => localSubStateData?.participants.indexOf(item) === index);
      if(temp2){
        let temp3 = temp2.map((item)=>{
          return {
           label: item.role.replace(/_/g," "),
           value: item.role,
         };
       })
       setRoles(removeDuplicates(temp3));
       setParticipants(localSubStateData);
      }
    }
  }, [localSubStateData]);

  const handleCheckbox = async (e, key, item) => {
    let checkboxValue = {...item};
    if(checked===key){
      setChecked();
      setUpdateData();
    }
    else{
     const share = localSubStateData.participantsToShare.filter((value)=>{
          return value.participant===item.participant;
      })  
     const sign = localSubStateData.participantsToSign.filter((value)=>{
        return value.participant===item.participant;
    })  
    checkboxValue = {...checkboxValue, sign: sign? true: false, share: share? true: false}
    setUpdateData(checkboxValue);
    setChecked(key);
    }
  };
  const handleModify = () => {
    if (checked !== undefined) {
      setAddStatus(false);
      setUpdateStatus(true);
    }
    else {
      Error("Please select one participant to Update")
    }
  };
  const handleDelete = () => {
       setIsDelete(true);
  };

  const DeleteFromModle = () =>{
    if (checked !== undefined) {
      setAddStatus(false);
      setUpdateStatus(false);
      setIsLoading(true);
      const idToken = JSON.parse(sessionStorage.getItem("idToken"));
      const requestOptions = {
        method: "DELETE",
        requireAuthentication: true,
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
      };
     const fetchData = async () => {
        await fetch(
          `${tradeURL}trades-participants/${updateData.stageParticipantId}`,
          requestOptions)
          .then((response) => {
            setIsLoading(false);
            response.json()
          })
          .then((data) => {
            Success("Participant Delete Successfully")
            setIsDelete(false);
            setUpdateStatus(false);
            let newParticipants = participants?.participants?.filter((item)=>{
                  return item.stageParticipantId !== updateData.stageParticipantId
            })
            let newParticipantsSign = participants?.participantsToSign?.filter((item)=>{
              return item.participant !== updateData.participant
             })
             let newParticipantsShare = participants?.participantsToShare?.filter((item)=>{
              return item.participant!== updateData.participant
            })
            newParticipantsSign = newParticipantsSign.filter((obj, index) => {
              return index === newParticipantsSign.findIndex(o => obj.participant=== o.participant);
            });
            newParticipantsShare = newParticipantsShare.filter((obj, index) => {
              return index === newParticipantsShare.findIndex(o => obj.participant=== o.participant);
            });
            setParticipants({
              ...participants,
              participants: newParticipants,
              participantsToSign: newParticipantsSign,
              participantsToShare: newParticipantsShare
            })
          })
          .catch(() => {
            setIsLoading(false);
            Error("API is Failing");
          })
      };
      fetchData();
     }
    else {
      Error("Please select one participant to Update")
    }
  }
  const handleAdd = () => {
    setUpdateData({
      participant: "",
      role: "",
      status: "",
      since: "",
      country: "",
      sign: "",
      share: "",
    })
    setUpdateStatus(false);
    setAddStatus(true);
  };

  const updateParticipant = () => {
    setIsLoading(true);
    let objectModel = {
    stageParticipantId: updateData.stageParticipantId,
    buyerMemberId: updateData.buyerMemberId,
    sellerMemberId:updateData.sellerMemberId,
    stageUID: updateData.stageUID,
    subStageUID: updateData.subStageUID,
    tradeId: updateData.tradeId,
    stageDetailId: localSubStateData.stageDetailId,
    participantsUid: updateData.participant,
    role: updateData.role,
    status: updateData.status,
    sign: updateData.sign? "Yes" : "No",
    share: updateData.share? "Yes" : "No",
    country: updateData.country,
    since: updateData.since,
     }
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(objectModel),
    };
    const fetchData = async () => {
      await fetch(
        `${tradeURL}trades-participants/${updateData.stageParticipantId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false)
          Success("Participant Updated Successfully")
          setUpdateStatus(false);
          setChecked();
          // setLocalSubStageData(data)
        })
        .catch(() => {
          setIsLoading(false);
          Error("API is Failing");
        })
    };
    fetchData();
  };

  const addParticipant = () => {
    const currentRoles = participants?.participants.map((item)=>{
      return item.role
   })
    if(currentRoles.includes("BUYER") && currentRoles.includes("SELLER") && (updateData.role==="BUYER" || updateData.role==="SELLER"))
    {
     Error("Buyer & Seller can't be added again please go to update participant")
    }
    else if(updateData.role === "BUYER" && currentRoles.includes("BUYER")){
      Error("Buyer can't be added again please go to update participant")
    }
    else if(updateData.role === "SELLER" && currentRoles.includes("SELLER")){
      Error("Seller can't be added again please go to update participant")
    }
    else{
    setIsLoading(true);
    let objectModel = {
      buyerMemberId: localSubStateData.buyerMemberId,
      sellerMemberId:localSubStateData.sellerMemberId,
      stageUID: localSubStateData.stageUID,
      subStageUID: localSubStateData.subStageUID,
      tradeId: localSubStateData.tradeId,
      stageDetailId: localSubStateData.stageDetailId,
      participantsUid: updateData.participant,
      role: updateData.role,
      status: updateData.status,
      sign: updateData.sign? "Yes" : "No",
      share: updateData.share? "Yes" : "No",
      country: updateData.country,
      since: updateData.since,
       }
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "POST",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(objectModel),
    };
    const fetchData = async () => {
      await fetch(
        `${tradeURL}trades-participants`,
        requestOptions
      )
        .then((response) => {
          setIsLoading(false);
          response.json()
        })
        .then((data) => {
          setAddStatus(false);
          Success("Participant Added Successfully");
          setChecked();  
        })
        .catch(() => {
          setIsLoading(false);
          Error("API is Failing");
        })
    };
    fetchData();
  }
  };

  const handleChange = (e) => {
    setUpdateData({
      ...updateData,
      [e.target.name]: e.target.value,
    });
  };
  const handleDateChange = (e) => {
    setUpdateData({
      ...updateData,
      since: new Date(e).toISOString(),
    });
  };
  const handleCancel = () => {
    setChecked();
    setAddStatus(false);
    setUpdateStatus(false);
  }
  
  return (
    <div>
      {isLoading ? <Loader /> :
       localSubStateData?.serviceErrorCode? <APIError status={localSubStateData.message}/> :
        <Container>
           <Modal
              show={isDelete}
              onHide={() => {
                // setServiceDelete(null);
                setIsDelete(false);
              }}
              Heading="Are you sure you want to delete?"
              Data={
                <>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <div className="d-flex flex-row-reverse">
                      <Button 
                        onClick={(e) => DeleteFromModle(e)}
                      >Confirm</Button>
                    </div>
                  )}
                </>
              }
            />
          <Content padding="0% 0% 0.5% 0%">
            {!addStatus && !updateStatus && (
              <div className="participantListTable">
                <div style={{ display: "flex" }}>
                  <div style={{ width: "20%", display:"flex", alignContent:"center",flexWrap:"wrap", fontSize:"16px"}}>Participants</div>
                  { Data.createdBy === companyId &&
                  <div className="participantListTableButtons">
                    { checked===undefined && <button
                      style={{fontSize:"12px"}}
                      className="participantListTableButton"
                      onClick={handleAdd}
                    >
                      Add Participant
                    </button>
                     }
                     { checked!==undefined &&
                    <button
                      style={{fontSize:"12px"}}
                      className="participantListTableButton"
                      onClick={handleModify}
                    >
                      Modify Participant
                    </button>
                     }
                     { checked!==undefined &&
                    <button
                      style={{fontSize:"12px"}}
                      className="participantListTableButton"
                      onClick={handleDelete}
                    >
                      Delete Participant
                    </button>
                     }
                     
                  </div>
                  }
                </div>
                <div style={{marginTop:"1rem"}}>
                  <Table bordered responsive className={cn(styles.Table)}>
                    <thead>
                      <tr>
                        {participantHeader.map((header, index) => (
                          <td key={index}>{header.name}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {participants &&
                        participants?.participants.map((item, key) => (
                          <tr key={key} style={{ cursor: "pointer" }}>
                            <td
                              // onClick={(e) =>
                              //   handleNavigateInvoice(user.txlInvoiceId)
                              // }
                              className={cn(styles.columnHover)}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  borderRight: "1px solid #A5A5A540",
                                  padding: "1%",
                                  justifyContent: "space-between",
                                  height:"30px"
                                }}
                                onClick={(e) => handleCheckbox(e, key, item)}
                              >
                                {key === checked ? (
                                  <input
                                    style={{ width: "5%", marginRight: "3%" }}
                                    type="checkbox"
                                    checked
                                  />
                                ) : (
                                  <input
                                    style={{ width: "5%", marginRight: "3%" }}
                                    type="checkbox"
                                  />
                                )}
                                <div style={{ display: "flex", width: "80%" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "left",
                                      alignItems: "center",
                                      textAlign: "start",
                                      width: "80%",
                                    }}
                                  >
                                    {companyList &&
                                      companyList.map((value) => {
                                        if (value.memberUID === item.participant) {
                                          return value.memberName;
                                        }
                                      })}

                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "right",

                                    }}
                                  >
                                    {participants?.participantsToSign?.map((ele) => {
                                      if (item.participant === ele.participant && item.role === ele.role) {
                                        return (
                                          <div style={{margin:'2px'}}>
                                            {ele.signStatus === "SIGNED" && (
                                              <span>
                                                <BsPatchQuestion
                                                  size={20}
                                                  color={"#34BE0F"}
                                                />
                                              </span>
                                            )}
                                            {ele.signStatus === "CANCELLED" && 
                                              <span>
                                                <BsPatchQuestion
                                                  size={20}
                                                  color={"#FF0000"}
                                                />
                                              </span>
                                            }
                                            {
                                              ele.signStatus === "IN_QUEUE" &&
                                              item.status && (
                                                <span>
                                                  <BsPatchQuestion
                                                    size={20}
                                                    color={"#FFC000"}
                                                  />
                                                </span>
                                              )
                                            }
                                             {
                                              ele.signStatus === "DECLINED" &&
                                              item.status && (
                                                <span>
                                                  <BsPatchQuestion
                                                    size={20}
                                                    color={"#FF0000"}
                                                  />
                                                </span>
                                              )
                                            }
                                            {ele.signStatus === "NOT_REQUESTED" && (
                                              <span>
                                                <BsPatchQuestion
                                                  size={20}
                                                  color={"#42A5F5"}
                                                />
                                              </span>
                                            )}
                                          </div>
                                        );
                                      }
                                    })}
                                    {participants?.participantsToShare?.map((ele) => {
                                      if (item.participant === ele.participant && item.role === ele.role) {
                                        return (
                                          <div style={{margin:'2px'}}>
                                            {ele.signStatus === "SIGNED" && (
                                              <span>
                                                <AiOutlineInfoCircle
                                                  size={20}
                                                  color={"#34BE0F"}
                                                />
                                              </span>
                                            )}
                                            {ele.signStatus === "CANCELLED" && (
                                              <span>
                                                <AiOutlineInfoCircle
                                                  size={20}
                                                  color={"#FF0000"}
                                                />
                                              </span>
                                            ) }
                                            {ele.signStatus === "IN_QUEUE" && (
                                              <span>
                                                <AiOutlineInfoCircle
                                                  size={20}
                                                  color={"#FFC000"}
                                                />
                                              </span>
                                            )}
                                            {
                                              ele.signStatus === "NOT_REQUESTED" &&
                                              item.status && (
                                                <span>
                                                  <AiOutlineInfoCircle
                                                    size={20}
                                                    color={"#42A5F5"}
                                                  />
                                                </span>
                                              )
                                            }
                                            {
                                              ele.signStatus === "DECLINED" &&
                                              item.status && (
                                                <span>
                                                  <AiOutlineInfoCircle
                                                    size={20}
                                                    color={"#FF0000"}
                                                  />
                                                </span>
                                              )
                                            }
                                          </div>
                                        );
                                      }
                                    })}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style={{marginTop:"0.5rem"}}>{item.country}</div>
                            </td>
                            <td>
                              <div style={{marginTop:"0.5rem"}}>{item.role}</div>
                            </td>
                            <td>
                               <div  style={{marginTop:"0.5rem"}}>{new Date(item.since).toLocaleDateString()}</div>
                            </td>
                            <td>
                            <div
                            style={
                              item.status === "SIGNED"? { backgroundColor: "#34BE0F", height:"2rem" , display:"flex", justifyContent:"center", alignItems:"center" }
                                : item.status === "CANCELLED"? { backgroundColor: "#FF0000", height:"2rem" , display:"flex", justifyContent:"center", alignItems:"center"}
                                : item.status === "IN_QUEUE"? { backgroundColor: "#FFC000", height:"2rem" , display:"flex", justifyContent:"center", alignItems:"center"}
                                : item.status === "NOT_REQUESTED"? { backgroundColor: "#42A5F5", height:"2rem" , display:"flex", justifyContent:"center", alignItems:"center"}
                                : item.status === "DECLINED"? { backgroundColor: "#FF0000", height:"2rem" , display:"flex", justifyContent:"center", alignItems:"center"}
                                : { backgroundColor: "#5d5d5d", height:"2rem" , display:"flex", justifyContent:"center", alignItems:"center"}
                            }
                          >
                            {item.status}
                          </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>

                    {participants &&
                        participants?.participants.length === 0 && (
                    <span>No data</span>
                  )}
                  </Table>
                </div>
              </div>
            )}

            {addStatus && (
              <div className="participantListTable">
                <div style={{ display: "flex", marginBottom: "4%" }}>
                  <div style={{ width: "50%" }}>
                    Participants - Add New Participant
                  </div>
                  <div className="participantListTableButtons">
                    <div>{
                      companyList.map(item => {
                        if (item.memberUID === companyId) {
                          return item.memberName
                        }
                      })
                    }</div>
                  </div>
                </div>
                <Grid container>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="participant"
                      placeholder="Participant"
                      value={updateData.participant}
                      options={companyList2}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="country"
                      placeholder="Country"
                      value={updateData.country}
                      options={country_codes}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="status"
                      placeholder="Status"
                      value={updateData.status}
                      options={participantStatus}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="role"
                      placeholder="Role"
                      value={updateData.role}
                      options={roles}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="sign"
                      placeholder="Signatory"
                      //selected={financers}
                      options={conditionCheck}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="share"
                      placeholder="Share"
                      // selected={signatory}
                      options={conditionCheck}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "10px" }}
                  >
                    <DateInput
                      name="since"
                      placeholder="Since *"
                      value={updateData.since}
                      handleChange={(e) => handleDateChange(e, "contractDate")}
                    />
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "right",
                  }}
                >
                  <button
                    style={{fontSize:"12px", width:"20%"}}
                    className="participantListTableButton"
                    onClick={handleCancel}
                  >
                    CANCEL
                  </button>
                  <button
                    style={{fontSize:"12px", width:"20%"}}
                    className="participantListTableButton"
                    onClick={handleAdd}
                  >
                    REMOVE
                  </button>
                  <button
                    style={{fontSize:"12px",  width:"20%"}}
                    className="participantListTableButton"
                    onClick={addParticipant}
                  >
                    ADD
                  </button>
                </div>
              </div>
            )}

            {updateStatus && (
              <div className="participantListTable">
                <div style={{ display: "flex", marginBottom: "4%" }}>
                  <div style={{ width: "50%" }}>
                    Participants - Modify Participant
                  </div>
                  <div className="participantListTableButtons">
                  <div>{
                      companyList.map(item => {
                        if (item.memberUID === companyId) {
                          return item.memberName
                        }
                      })
                    }</div>
                  </div>
                </div>
                <Grid container>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="participant"
                      placeholder="Participant"
                      value={updateData.participant}
                      options={companyList2}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="country"
                      placeholder="Country"
                      value={updateData.country}
                      options={country_codes}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="status"
                      placeholder="Status"
                      value={updateData.status}
                      options={participantStatus}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="role"
                      placeholder="Role"
                      value={updateData.role}
                      options={roles}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="sign"
                      placeholder="Signatory"
                      value={updateData.sign}
                      options={conditionCheck}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <SelectWithSearch
                      name="share"
                      placeholder="Share"
                      value={updateData.share}
                      options={conditionCheck}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                    style={{ padding: "10px" }}
                  >
                    <DateInput
                      name="since"
                      placeholder="Since *"
                      value={updateData.since}
                      handleChange={(e) => handleDateChange(e, "contractDate")}
                    />
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "right",
                  }}
                >
                  <button
                    style={{fontSize:"12px", width:"20%"}}
                    className="participantListTableButton"
                    onClick={handleCancel}
                  >
                    CANCEL
                  </button>
                  <button
                    style={{fontSize:"12px", width:"20%"}}
                    className="participantListTableButton"
                    onClick={handleAdd}
                  >
                    REMOVE
                  </button>
                  <button
                    style={{fontSize:"12px", width:"20%"}}
                    className="participantListTableButton"
                    onClick={updateParticipant}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            )}

            {!addStatus && !updateStatus && (
              <div
                style={{ backgroundColor: "#343434", margin: "0% 2% 2% 2%", padding: "2%" , width:"96%"}}
              >
                <div style={{ fontSize: "100%" }}>Legends</div>
                <div style={{ display: "flex", marginTop: "2%", fontSize: "90%" }}>
                  <div style={{ display: "flex", width: "50%", margin:"0.5%" }}>
                    <span style={{ width: "8%", marginRight: "4%" }}>
                      <BsPatchQuestion size={20} color={"#34BE0F"} />
                    </span>
                    <div style={{ width: "92%", fontSize:"14px" }}>Stage Completion Signed</div>
                  </div>
                  <div style={{ display: "flex", width: "50%" , margin:"0.5%" }}>
                    <span style={{ width: "8%", marginRight: "4%" }}>
                      <AiOutlineInfoCircle size={20} color={"#34BE0F"} />
                    </span>
                    <div style={{ width: "92%", fontSize:"14px" }}>All information shared</div>
                  </div>
                </div>
                <div style={{ display: "flex", fontSize: "90%" }}>
                  <div style={{ display: "flex", width: "50%", margin:"0.5%"  }}>
                    <span style={{ width: "8%", marginRight: "4%" }}>
                      <BsPatchQuestion size={20} color={"#FF0000"} />
                    </span>
                    <div style={{ width: "92%", fontSize:"14px" }}>Stage Completion Rejected</div>
                  </div>
                  <div style={{ display: "flex", width: "50%" , margin:"0.5%"}}>
                    <span style={{ width: "8%", marginRight: "4%" }}>
                      <AiOutlineInfoCircle size={20} color={"#FF0000"} />
                    </span>
                    <div style={{ width: "92%", fontSize:"14px" }}>Information share pending</div>
                  </div>
                </div>
                <div style={{ display: "flex", fontSize: "90%" }}>
                  <div style={{ display: "flex", width: "50%", margin:"0.5%"  }}>
                    <span style={{ width: "8%", marginRight: "4%" }}>
                      <BsPatchQuestion size={20} color={"#FFC000"} />
                    </span>
                    <div style={{ width: "92%" , fontSize:"14px"}}>Stage Completion Pending</div>
                  </div>
                  <div style={{ display: "flex", width: "50%", margin:"0.5%" }}>
                    <span style={{ width: "8%", marginRight: "4%" }}>
                      <AiOutlineInfoCircle size={20} color={"#5D5D5D"} />
                    </span>
                    <div style={{ width: "92%", fontSize:"14px" }}>Share not applicable</div>
                  </div>
                </div>
                <div style={{ display: "flex", fontSize: "90%" }}>
                  <div style={{ display: "flex", width: "50%", margin:"0.5%" }}>
                    <span style={{ width: "8%", marginRight: "4%" }}>
                      <BsPatchQuestion size={20} color={"#5D5D5D"} />
                    </span>
                    <div style={{ width: "92%", fontSize:"14px" }}>Stage Signature N/A</div>
                  </div>
                </div>
              </div>
            )}
          </Content>
        </Container>
      }
    </div>
  );
};
