import {
  FaHandshake,
  FaFileAlt,
  FaFileInvoice,
  FaNetworkWired,
  FaRegBuilding,
  FaShippingFast,
  FaTasks,
  FaChartBar,
  FaShip,
  FaFileInvoiceDollar,
  FaAddressCard,
} from "react-icons/fa";
import { IoMdChatbubbles } from "react-icons/io";
import { MdPayment, MdOutlineNotificationsNone } from "react-icons/md";
import { GrOrganization } from "react-icons/gr";
import { HiUserAdd } from "react-icons/hi";
import {BsSearch} from "react-icons/bs";

export const SOLUTIONS_TYPE = [
  {
    name: "Invoices",
    icon: <FaFileInvoiceDollar />,
    path: "/financing/invoice/invoices",
  },
  {
    name: "Networks",
    icon: <FaNetworkWired />,
    path: "/networks",
  },
  {
    name: "Financing Deals",
    icon: <FaFileInvoice />,
    path: "/financing/invoice/finance-deals",
  },
  {
    name: "Services",
    icon: <FaRegBuilding />,
    path: "/services/service",
  },
  {
    name: "Documents",
    icon: <FaFileAlt />,
    path: "/documents",
  },

  {
    name: "Payments",
    icon: <MdPayment />,
    path: "/payment",
  },
  {
    name: "MIS",
    icon: <FaChartBar />,
    path: "/mistrade",
  },
  {
    name: "Communications",
    icon: <IoMdChatbubbles />,
    path: "/communication",
  },
  {
    name: "Trades",
    icon: <FaHandshake />,
    path: "/tradeover/trades",
  },
  {
    name: "Open Trade Account",
    icon: <FaShippingFast />,
    path: "/trade-account/open-account",
  },
  {
    name: "Refer",
    icon: <HiUserAdd />,
    path: "/invite",
  },
];
export const SOLUTIONS_TYPE_TRADER = [
  {
    name: "Create Service Request",
    icon: <FaRegBuilding />,
    path: "/services/service",
  },
  {
    name: "Create Financing Request",
    icon: <FaFileInvoiceDollar />,
    path: "/financing/invoice/invoices",
  },

  {
    name: "Create Trade",
    icon: <FaHandshake />,
    path: "/tradeover/trades",
  },

  {
    name: "Create Open Account Trade",
    icon: <FaShippingFast />,
    path: "/trade-account/open-account",
  },
  {
    name: "Track Shipping",
    icon: <FaShip />,
    path: "/financing/tracking",
  },
];
export const SOLUTIONS_TYPE_FINANCER = [
  {
    name: "Review Deals",
    icon: <FaFileInvoice />,
    path: "/financing/invoice/finance-deals",
  },
  {
    name: "Check Trust Card",
    icon: <FaAddressCard />,
    path: "/networks",
  },
  {
    name: "Build Network",
    icon: <FaNetworkWired />,
    path: "/networks",
  },
  {
    name: "View Documents",
    icon: <FaFileAlt />,
    path: "/documents",
  },
];
export const SOLUTIONS_TYPE_SERVICE_PROVIDER = [
  {
    name: "Offer Service",
    icon: <FaRegBuilding />,
    path: "/services/service",
  },
  {
    name: "Review Deals",
    icon: <FaRegBuilding />,
    path: "/services/service-deals",
  },
  {
    name: "Explore  Trade",
    icon: <FaHandshake />,
    path: "/tradeover/trades",
  },
  {
    name: "Track Shipping",
    icon: <FaShip />,
    path: "/financing/tracking",
  },
  {
    name: "Build Network",
    icon: <FaNetworkWired />,
    path: "/networks",
  },
];

export const DocumentDashboard = [
  {
    name: "Trade Documents Old",
    icon: <FaFileAlt />,
    value: "AllDocuments",
  },
  {
    name: "Company Documents",
    icon: <FaFileAlt />,
    value: "CompanyDocuments",
  },
  {
    name: "Director Documents",
    icon: <FaFileAlt />,
    value: "Director",
  },
];

export const DocumentsDashboard = [
  {
    name: "Trade Documents",
    icon: <FaFileAlt />,
    value: "AllDocuments",
    path: "/trade",
  },
  {
    name: "Company Documents",
    icon: <FaFileAlt />,
    value: "CompanyDocuments",
    path: "/company",
  },
  {
    name: "Director Documents",
    icon: <FaFileAlt />,
    value: "Director",
    path: "/director",
  },
  {
    name: "Consent Management",
    icon: <FaFileAlt />,
    value: "Consent",
    path: "/consent",
  },
  {
    name: "Search Document",
    icon: <BsSearch />,
    value: "Search",
    path: "/searchDocument",
  },
];

export const DocumentsServiceDealDashboard = [
  {
    name: "Company Documents",
    icon: <FaFileAlt />,
    path: "Company",
  },

  {
    name: "Service Contracts Documents",
    icon: <FaFileAlt />,
    path: "Contracts",
  },
  {
    name: "Service Requirement Documents",
    icon: <FaFileAlt />,
    path: "Requirement",
  },

  {
    name: "Payments Documents",
    icon: <FaFileAlt />,
    path: "Payments",
  },
  {
    name: "Service Delivery Documents",
    icon: <FaFileAlt />,
    path: "Delivery",
  },
];

export const DocumentsOpenTradeDashboard = [
  {
    name: "Company Documents",
    icon: <FaFileAlt />,
    path: "Company",
  },
  {
    name: "Payments Documents",
    icon: <FaFileAlt />,
    path: "Payments",
  },
  {
    name: "Delivery Documents",
    icon: <FaFileAlt />,
    path: "Delivery",
  },
];

export const DocumentsServiceRequestDashboard = [
  {
    name: "Requirement Documents",
    icon: <FaFileAlt />,
    path: "Required",
  },
];

export const DocumentsFinanceDealDashboard = [
  {
    name: "Trade Documents",
    icon: <FaFileAlt />,
    path: "Trade",
  },
  {
    name: "Company Documents",
    icon: <FaFileAlt />,
    path: "Company",
  },
];
