import React, { useState, useEffect } from "react";
import Container from "components/UI/Layout/Container";
import styles from "pages/Documents/dashboard.module.css";
import { DocumentsServiceRequestDashboard } from "utils/Config";
import Document from "pages/Document/index";
import { HiArrowNarrowLeft } from "react-icons/hi";
import classes from "./documentSection.module.css";
// import DocumentUpload from "../../New-ServiceDeal/DocumentSection/DocumentUpload";
import { DocumentGroup } from "utils/DropDown/ServiceDealDocument";
import DocumentUpload from "./DocumentUpload";
import AllDocument from "./Document";

const DocumentIndex = ({ serviceId, response }) => {
  const [hide, setHide] = useState(null);
  // const [isUploading, setIsUploading] = useState(false);

  // const [SelectedCategory, setSelectedCategory] = useState(null);
  // const [selectCategoryOptions, setSelectedCategoryOptions] = useState(null);
  // const [selectedSubCategoryOptions, setSelectedSubCategoryOptions] = useState(
  //   []
  // );
  // const [CategoryListURL, setCategoryListURL] = useState(null);

  // useEffect(() => {
  //   if (response && hide) {
  //     let ServiceCategory =
  //       response &&
  //       response[0] &&
  //       response[0].serviceCategory &&
  //       response[0].serviceCategory[0];

  //     let ServiceSubCategory =
  //       response &&
  //       response[0] &&
  //       response[0].serviceSubCategory &&
  //       response[0].serviceSubCategory[0];

  //     if (hide === "Required") {
  //       const { Category, SubCategory } = DocumentGroup(
  //         ServiceCategory,
  //         ServiceSubCategory,
  //         hide,
  //         SelectedCategory
  //       );
  //       setSelectedCategoryOptions(Category);
  //       let TEMP = [];
  //       Category.map((Service) => TEMP.push(Service.value));

  //       sessionStorage.setItem(
  //         "serviceRequestCategoryListURL",
  //         JSON.stringify(TEMP)
  //       );
  //       setSelectedSubCategoryOptions(SubCategory);
  //     }
  //   }
  // }, [response, hide, SelectedCategory]);

  // useEffect(() => {
  //   if (selectCategoryOptions) {
  //     let TEMP = [];
  //     selectCategoryOptions.map((Service) => TEMP.push(Service.value));
  //     setCategoryListURL(TEMP);
  //   }
  // }, [selectCategoryOptions]);

  return (
    <Container>
      <>
        {!response ? (
          <span>Loading</span>
        ) : (
          <>
            {!hide ? (
              <div className={styles.dashboardWrapper}>
                <h5>Documents</h5>
                <div className={styles.dashboardSolution}>
                  {DocumentsServiceRequestDashboard.map((item, index) => (
                    <div className={styles.solutionCard} key={index}>
                      <div
                        onClick={() => setHide(`${item.path}`)}
                        className={styles.content}
                        style={{ cursor: "pointer" }}>
                        <div className={styles.content_icons}>{item.icon}</div>
                        <h6>{item.name}</h6>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <AllDocument
                response={response}
                serviceId={serviceId}
                hide={hide}
                setHide={setHide}
              />
              // <>
              //   <div
              //     onClick={() => {
              //       setHide(null);
              //       setIsUploading(false);
              //       setSelectedCategory(null);
              //     }}
              //     style={{ color: "#218eeb", cursor: "pointer" }}>
              //     <span>
              //       <HiArrowNarrowLeft size={28} />
              //     </span>
              //     <span>Back to List </span>
              //   </div>

              //   {hide == "Required" && (
              //     <>
              //       {isUploading ? (
              //         <div>
              //           <DocumentUpload
              //             selectedCategory={SelectedCategory} // useState
              //             setSelectedCategory={setSelectedCategory} // useState
              //             Category={selectCategoryOptions} // Drop down option
              //             SubCategory={selectedSubCategoryOptions} // Drop down option
              //             setIsUploading={setIsUploading}
              //             response={response}
              //           />
              //         </div>
              //       ) : (
              //         <>
              //           <div className="d-flex justify-content-end">
              //             <button
              //               className={classes.UploadBtn}
              //               onClick={() => setIsUploading(true)}>
              //               Upload
              //             </button>
              //           </div>
              //           <Document
              //             serviceId={serviceId}
              //             // serviceDealDetails={serviceDealDetails}
              //             serviceDealLocations={{
              //               serviceDealLocation: "serviceRequestRequired",
              //               // docCategoryList: CategoryListURL
              //               //   ? CategoryListURL
              //               //   : [],
              //               docCategoryList: sessionStorage.getItem(
              //                 "serviceRequestCategoryListURL"
              //               )
              //                 ? JSON.parse(
              //                     sessionStorage.getItem(
              //                       "serviceRequestCategoryListURL"
              //                     )
              //                   )
              //                 : [],
              //             }}
              //           />
              //         </>
              //       )}
              //     </>
              //   )}
              // </>
            )}
          </>
        )}
      </>
    </Container>
  );
};

export default DocumentIndex;
