import React, { useState, useEffect, useContext, useRef } from "react";
import { Grid } from "@mui/material";
import Input from "components/fields/Input";
import country_codes from "jsons/CountryCodes.json";
import currenciesr from "jsons/Currenciesr.json";
import industry from "jsons/Industry.json";
import payment from "jsons/payment.json";
import goods_cats from "jsons/goodsCat.json";
import hazMat from "jsons/hazMat.json";
import status from "jsons/status.json";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import { useNavigate, useLocation } from "react-router-dom";
import { tradeURL } from "services/BaseURLInstance";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { CreateNewTrade } from "utils/Section/FormInfo";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import Loader from "components/Loader/Loader";
import { Success } from "components/notifications/responseHandle";
import { Error } from "components/notifications/responseHandle";
import SelectWithSearch from "components/fields/SelectWithSearch";
import { TradeContext } from "./tradeProvider";
import incoterm from "jsons/incoterm.json";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { FindCompanyName } from "utils/helper";
import DocumentFinder from "components/documentFinder/documentFinder";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

export default () => {
  const { companyList } = useContext(TradeContext);
  const { instance, accounts } = useMsal();
  const memberRef = useRef(false);
  sessionStorage.setItem("test", 2);
  // const companyId = getClaim(accounts, "company_member_id");
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [poDocument, setPoDocument] = useState();
  const [invoiceDocument, setInvoiceDocument] = useState();
  const [currentField, setCurrentField] = useState();
  const [open, setOpen] = useState(false);
  let bb = Object.keys(country_codes);
  let cc = Object.values(country_codes);
  let country_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        country_code.push({ label: w.label, value: w.value });
      }
    })
  );

  let aa = Object.keys(goods_cats);
  let dd = Object.values(goods_cats);
  let goods_cat = [];
  aa.map((q, i) =>
    dd.map((w, o) => {
      if (i === o) {
        goods_cat.push({ label: w.label, value: w.value });
      }
    })
  );

  let ee = Object.keys(status);
  let ff = Object.values(status);
  let stat = [];
  ee.map((q, i) =>
    ff.map((w, o) => {
      if (i === o) {
        stat.push({ label: w.label, value: w.value });
      }
    })
  );

  const [tradeData, setTradeData] = useState({
    buyerMemberId: "",
    sellerMemberId: "",
    countryOfOrigin: "",
    countryOfDestination: "",
    poReference: "",
    invoiceReference: "",
    tradeValue: "",
    industry: "",
    contractDate: new Date().toISOString(),
    createdDate: new Date().toISOString(),
    updatedDate: new Date().toISOString(),
    status: "NEW",
    notes: "random",
    paymentCurrency: "",
    preferredPaymentMethod: "",
    incoTerm: "",
  });

  const [companyData, setCompanyList] = useState();
  const [filterCompanyList, setFilterCompanyList] = useState();

  useEffect(() => {
    if (companyList) {
      let temp = [];
      companyList.forEach((item) => {
        if (
          item.memberType &&
          (item.memberType === "COMPANY" || item.memberType === "FINANCER")
        ) {
          temp.push({ label: item.memberName, value: item.memberUID });
        }
      });
      setTradeData({
        ...tradeData,
        sellerMemberId: location.state.provider === "Seller" ? companyId : "",
        buyerMemberId: location.state.provider === "Buyer" ? companyId : "",
      });
      setCompanyList(temp);
    }
    return () => {
      memberRef.current = true;
    };
  }, [companyList]);

  useEffect(() => {
    let temp = [];
    temp =
      companyData &&
      companyData.filter((item) => {
        return item.value !== companyId;
      });
    setFilterCompanyList(companyData);
  }, [companyData]);

  const handleCreateTrade = () => {
    navigate("../tradeflow");
  };

  const handleChange = (e, temp) => {
    if (temp === undefined) {
      setTradeData({
        ...tradeData,
        [e.target.name]: e.target.value,
      });
    } else {
      setTradeData({
        ...tradeData,
        [temp]: e,
      });
    }
  };

  const CheckProvider = (provider) => {
    switch (provider) {
      case "Buyer":
        return "BUYER";
      case "Seller":
        return "SELLER";
      case "Freight Service Provider":
        return "FREIGHT_SERVICE_PROVIDER";
    }
  };

  const createTrade = () => {
    setIsLoading(true);
    const newTradeData = { ...tradeData };
    newTradeData.poReference = poDocument?.docReferenceId || "";
    newTradeData.invoiceReference = invoiceDocument?.docReferenceId || "";
    newTradeData.creatorRole = CheckProvider(location?.state?.provider);
    newTradeData.creatorMemberId = companyId;
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "POST",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(newTradeData),
    };
    const fetchData = async () => {
      await fetch(`${tradeURL}trades`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data?.status >= 400) {
            setIsLoading(false);
            Error("Something Went Wrong");
          } else {
            setIsLoading(false);
            Success("Trade Created successfully");
            navigate(`../tradeflow/${data.tradeId}`);
          }
        });
    };
    fetchData();
  };

  const handleOpenDialog = (e, value) => {
    setCurrentField(value);
    setOpen(true);
  };

  const setDocument = (value) => {
    if (currentField === "poReference") {
      setPoDocument(value);
    } else if (currentField === "invoiceReference") {
      setInvoiceDocument(value);
    }
  };

  return (
    <>
      {isLoading ? (
        <div style={{ margin: "2%" }}>
          <>Creating Trade Lifecycle</>
          <Loader />
        </div>
      ) : (
        <>
          <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            PaperProps={{
              sx: { backgroundColor: "#343434", color: "white" },
            }}>
            <DialogContent sx={{ padding: "0px" }}>
              <div>
                <DocumentFinder setOpen={setOpen} setDocument={setDocument} />
              </div>
            </DialogContent>
          </Dialog>

          {companyData && filterCompanyList && (
            <div
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                padding: "1%",
                paddingTop: "1px",
                borderRadius: "5px",
              }}>
              <div
                style={{
                  backgroundColor: "black",
                  padding: "1%",
                  paddingTop: "5px",
                  marginTop: "1%",
                  borderRadius: "5px",
                }}>
                <OverLayTrigger value={CreateNewTrade} />
                <div>
                  <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        name="buyerMemberId"
                        placeholder="Buyer"
                        value={tradeData.buyerMemberId}
                        options={filterCompanyList}
                        handleChange={(e) => handleChange(e)}
                        readOnly={
                          location.state.provider === "Buyer" ? true : false
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        name="sellerMemberId"
                        placeholder="Seller"
                        options={companyData}
                        handleChange={(e) => handleChange(e)}
                        value={tradeData.sellerMemberId}
                        readOnly={
                          location.state.provider === "Seller" ? true : false
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <div style={{ marginTop: "2%", fontSize: "80%" }}>
                    Trade Contract
                  </div>
                  <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <DateInput
                        name="contractDate"
                        placeholder="Contract Date *"
                        value={tradeData.contractDate}
                        handleChange={(e) => handleChange(e, "contractDate")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        name="incoTerm"
                        placeholder="INCOTERM"
                        options={incoterm}
                        handleChange={(e) => handleChange(e)}
                        value={tradeData.incoTerm}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <Input
                        name="tradeValue"
                        placeholder="Trade Value *"
                        value={tradeData.tradeValue}
                        handleChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        options={currenciesr}
                        name="paymentCurrency"
                        placeholder="Currency *"
                        value={tradeData.paymentCurrency}
                        handleChange={(e) => handleChange(e)}
                      />
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <div style={{ marginTop: "2%", fontSize: "80%" }}>Goods</div>
                  <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        options={industry}
                        name="industry"
                        placeholder="Goods Category *"
                        handleChange={(e) => handleChange(e)}
                        //value={expoData.Port}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <Input
                        name="goodsTradeName"
                        placeholder="Goods HSN Code *"
                        value={tradeData.goodsTradeName}
                        handleChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        options={hazMat}
                        name="hazMat"
                        placeholder="Hazardous Material"
                        handleChange={(e) => handleChange(e)}
                        value={tradeData.hazMat}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <Input
                        name="unNumber"
                        placeholder="UN Number *"
                        value={tradeData.unNumber}
                        handleChange={(e) => handleChange(e)}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <div style={{ marginTop: "2%", fontSize: "80%" }}>
                    Origin and Destination
                  </div>
                  <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        options={country_code}
                        name="countryOfOrigin"
                        placeholder="Country of Origin"
                        handleChange={(e) => handleChange(e)}
                        value={tradeData.countryOfOrigin}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        options={country_code}
                        name="countryOfDestination"
                        placeholder="Country of Destination"
                        handleChange={(e) => handleChange(e)}
                        value={tradeData.countryOfDestination}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                      name="poReference"
                      placeholder="PO Reference *"
                      value={poDocument?.docReferenceId
                        ? truncateStringID(
                          poDocument?.docReferenceId,
                            7,
                            "PO"
                          )
                        : "NA" || ""}
                      buttonEnable={true}
                      readOnly={true}
                      onClick={(e)=>handleOpenDialog(e, "poReference")}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                      name="invoiceReference"
                      placeholder="Invoice Reference *"
                      value={invoiceDocument?.docReferenceId? truncateStringID(
                        invoiceDocument?.docReferenceId,
                         7,
                         "INV"
                       )
                     : "NA" || ""}
                      buttonEnable={true}
                      handleChange={(e) => handleChange(e)}
                      onClick={(e)=>handleOpenDialog(e, "invoiceReference")}
                    />
                  </Grid> */}
                  </Grid>
                </div>

                <div>
                  <div style={{ marginTop: "2%", fontSize: "80%" }}>
                    Payment Details
                  </div>
                  <Grid container>
                    {/* <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Select
                      options={stat}
                      name="status"
                      placeholder="Status *"
                      handleChange={(e) => handleChange(e)}
                      value={tradeData.status}
                      readOnly={true}
                    />
                  </Grid> */}
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <Select
                        options={payment}
                        name="preferredPaymentMethod"
                        placeholder="Preferred Payment Method *"
                        handleChange={(e) => handleChange(e)}
                        value={tradeData.preferredPaymentMethod}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                      <SelectWithSearch
                        options={currenciesr}
                        name="paymentCurrency"
                        placeholder="Currency *"
                        value={tradeData.paymentCurrency}
                        handleChange={(e) => handleChange(e)}
                      />
                    </Grid>
                  </Grid>
                </div>

                <div style={{ display: "flex" }}>
                  <div style={{ margin: "1%", color: "#ffc107", width: "60%" }}>
                    <CreatedByLabel
                      createdBy={
                        companyList && FindCompanyName(companyList, companyId)
                      }
                      createdDate={new Date().toLocaleDateString()}
                      lastUpdateBy={
                        companyList && FindCompanyName(companyList, companyId)
                      }
                      lastUpdatedDate={new Date().toLocaleDateString()}
                    />
                  </div>
                  <div
                    style={{
                      textAlign: "right",
                      width: "40%",
                      marginRight: "2%",
                      marginTop: "2%",
                    }}>
                    <button
                      style={{
                        fontSize: "14px",
                        width: "40%",
                        height: "40px",
                        marginRight: "2%",
                        padding: "1%",
                        backgroundColor: "#42A5F5",
                        border: "none",
                        borderRadius: "5px",
                        color: "white",
                      }}
                      onClick={createTrade}>
                      Create Trade
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};
