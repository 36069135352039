import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export default function CustomizedSteppers({ Value, position, isDeclined }) {
  const PositionBGColor = (status) => {
    switch (status) {
      case "progress":
        return "#ffc107";
      case "completed":
        return "#28a745";
      case "decline":
        return "#dc3545";
    }
  };

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: PositionBGColor(position.status),
      },
    },

    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundColor: isDeclined ? "gray" : "#28a745",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: "#0381ff",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor: "#0381ff",
    zIndex: 1,
    color: "#fff",
    fontSize: "14px !important",
    letterSpacing: "1px !important",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: PositionBGColor(position.status),
    }),
    ...(ownerState.completed && {
      backgroundColor: isDeclined ? "gray" : "#28a745",
    }),
  }));

  function ColorlibStepIcon(props) {
    const { active, completed, className } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}>
        {Value && Value.icons && Value.icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={position && position.index}
        connector={<ColorlibConnector />}>
        {Value &&
          Value.steps &&
          Value.steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {label}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </Stack>
  );
}
