import React from "react";
import Container from "components/UI/Layout/Container";
import Wrapper from "components/UI/Layout/Wrapper";
import Navbar from "components/UI/Navbar/Navbar";
import { Routes, Route, Navigate } from "react-router-dom";

import Invoice from "./Invoice";
import Pofinance from "../Invoicefinancing/finance/pofinance";
import Payables from "./Payables";
import Inventory from "./Inventory";
import Dashboard from "components/UI/Dashboard/index";
import Tracking from "./Tracking/Tracking";
import { FinanceContextProvider } from "./Invoice/financeProvider";

const index = ({ hanndleCraateGroup }) => {
  return (
    <FinanceContextProvider>
    <Container>
      <Routes>
        <Route
          path="/invoice/*"
          element={<Invoice hanndleCraateGroup={hanndleCraateGroup} />}
        />
        <Route
          path="/pofinancing/*"
          element={<Pofinance hanndleCraateGroup={hanndleCraateGroup} />}
        />
        <Route path="/payables/*" element={<Payables />} />
        <Route path="/inventory/*" element={<Inventory />} />
        <Route path="/tracking/*" element={<Tracking />} />
      </Routes>
      <Dashboard />
    </Container>
    </FinanceContextProvider>
  );
};

export default index;
