import _ from "lodash";

export const CreateOpenTradeValdations = (payload) => {
  if (_.isEmpty(payload.buyerMemberId)) {
    return "Please Select Buyer";
  }

  if (_.isEmpty(payload.countryOfOrigin)) {
    return "Please Select Country Of Origin";
  }

  if (_.isEmpty(payload.countryOfDestination)) {
    return "Please Select Country Of Destination";
  }

  // if (_.isEmpty(payload.poReference)) {
  //   return "Please Enter PO Reference";
  // }

  // if (_.isEmpty(payload.invoiceReference)) {
  //   return "Please Enter Invoice Reference";
  // }
  if (_.isEmpty(payload.paymentCurrency)) {
    return "Please Select Payment Currency";
  }
  if (_.isEmpty(payload.tradeValue)) {
    return "Please Enter Trade Value";
  }

  if (_.isEmpty(payload.industryCategory)) {
    return "Please Select Industry Category";
  }

  if (_.isEmpty(payload.contractDate)) {
    return "Please Select Contract Date";
  }

  // if (_.isEmpty(payload.createdDate)) {
  //   return "Please Select Created Date";
  // }

  // if (_.isEmpty(payload.updatedDate)) {
  //   return "Please Select Last Activity Date";
  // }

  if (_.isEmpty(payload.preferredPaymentMethod)) {
    return "Please Select Preferred Payment Method";
  }

  if (_.isEmpty(payload.escrowService)) {
    return "Please Select Mode Of Payments";
  }
};

// export const SelectPaymentValdations = (payload) => {
//   if (_.isEmpty(payload.escrowServiceProviderUID)) {
//     return "Please Select Payment Service Provider";
//   }
//   if (_.isEmpty(payload.RefSellerVirtualBankAccountId)) {
//     return "Please Select Payment Service Provider";
//   }
// };

export const AddMilestoneValdations = (
  payload,
  documents,
  isPercentageComplete
) => {
  if (_.isEmpty(payload.tradeStage)) {
    return "Please Select Milestone Stage";
  }
  if (_.isEmpty(payload.tradeSubStage)) {
    return "Please Select Milestone Sub-Stage";
  }

  if (_.isEmpty(documents.docCategory)) {
    return "Please Select docCategory";
  }

  if (_.isEmpty(documents.docSubCategory)) {
    return "Please Select docSubCategory";
  }

  const isNumber = (str) => {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  };

  if (_.isEmpty(payload.milestonePaymentPercentage)) {
    return "Please Enter Payment Percentage";
  } else {
    if (!isNumber(payload.milestonePaymentPercentage)) {
      return "Please Enter Valid  Number";
    }

    if (payload.milestonePaymentPercentage < 0) {
      return "Can't set milestone payment percentage less than 0";
    }

    if (
      parseFloat(payload.milestonePaymentPercentage) +
        parseFloat(isPercentageComplete) >
      100
    ) {
      return "Can't set milestone payment percentage greater than 100";
    }
  }

  // if (_.isEmpty(payload.milestonePaymentAmount)) {
  //   return "Please Enter Payment Amount";
  // }

  if (_.isEmpty(payload.milestoneDate)) {
    return "Please Select Milestone Date";
  }

  if (_.isEmpty(payload.paymentDate)) {
    return "Please Select Payment Due Date";
  }
};

export const AddParticipantsValdations = (payload) => {
  if (_.isEmpty(payload.participant)) {
    return "Please Select Participant";
  }
  if (_.isEmpty(payload.country)) {
    return "Please Select Country";
  }

  if (_.isEmpty(payload.status)) {
    return "Please Select Status";
  }

  if (_.isEmpty(payload.role)) {
    return "Please Select Role";
  }

  if (payload.signatory === "") {
    return "Please Select Signatory";
  }

  if (payload.share === "") {
    return "Please Select Share";
  }

  if (_.isEmpty(payload.since)) {
    return "Please Enter Since";
  }
};
