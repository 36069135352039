import { makeRequest } from "../index.js";

export const createPayment = (idToken, requestPayload) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        route: `payments`,
        body: JSON.stringify(requestPayload),
        method: 'POST',
        idToken
    })
}

export const makePayment = (idToken, requestPayload) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        idToken: idToken,
        route: `payments/escrow/make-payment`,
        body: JSON.stringify(requestPayload),
        method: 'POST'
    })
}
export const refundPayment = (idToken, paymentid) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        idToken: idToken,
        route: `/payments/refund?paymentId=${paymentid}`,
        method: 'PUT'
    })
}

export const getPayments = (idToken, memberId, status = "INITIATED", paymentType = "ESCROW") => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        idToken: idToken,
        route: `payments?memberId=${memberId}&status=${status}&paymentType=${paymentType}`
    })
}

export const getPaymentByUser = (idToken, userId) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        idToken: idToken,
        route: `payments/user/${userId}`
    })
}

export const addPaymentUser = (idToken, requestPayload) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        idToken: idToken,
        route: `payments/user`,
        body: JSON.stringify(requestPayload),
        method: 'POST'
    })
}

export const updateLoan = (idToken, loanReferenceId, requestPayload) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_FINANCE_URL,
        idToken: idToken,
        route: `loans/${loanReferenceId}`,
        body: JSON.stringify(requestPayload),
        method: 'PUT'
    })
}

export const loanDetail = (idToken, loanReferenceId) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_FINANCE_URL,
        idToken: idToken,
        route: `loans/${loanReferenceId}`,
    })
}

export const paymentDetails = (paymentId, idToken) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        route: `payments/${paymentId}`,
        idToken: idToken,
    })
}

export const getPaymentsByLoanId = (loanId, idToken, memberId) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        route: `payments/get-payment?refId=${loanId}&initiatedBy=${memberId}`,
        idToken: idToken,
    })

}
export const getPaymentCollection = (amount, buyerCountry, buyerCurrency, provider, sellerCountry, idToken) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        route: `payments/fx-rate?amount=${amount}&buyer-country=${buyerCountry}&buyers-currency=${buyerCurrency}&seller-country=${sellerCountry}&provider=TAZAPAY`,
        idToken: idToken,
    })

}
export const getPaymentsByServiceId = (Id, idToken) => {
    return makeRequest({
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        route: `payments/get-payment?serviceId=${Id}`,
        idToken: idToken,
    })
}

export const updatePayment = (res, requestStatus, idToken) => {
    return makeRequest({
        method: 'PUT',
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        route: `payments/${res.paymentId}?status=${requestStatus}`,
        idToken: idToken,
    })
}

export const releasePayment = (paymentPayload, idToken) => {
    return makeRequest({
        method: 'POST',
        baseURL: process.env.REACT_APP_PAYMENT_URL,
        route: `payments/release`,
        body: JSON.stringify(paymentPayload),
        idToken: idToken,
    })
}

export const updateServiceDeal = (serviceDealId, paymentPayload) => {
    return makeRequest({
        method: "PUT",
        baseURL: process.env.REACT_APP_SERVICE_URL,
        route: `services/deals/${serviceDealId}`,
        body: JSON.stringify(paymentPayload)
    })
}