import React from "react";
import styled from "styled-components";
import TableData from "../financing/tableData";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { SendBirdProvider as SBProvider } from "sendbird-uikit";

const Styles = styled.div`
  .table {
    padding: 16px;
    background-color: #000000;
    border-radius: 5px;
  }

  .header1 {
    width: 100%;
    display: flex;
    background-color: #343434;
    border-radius: 5px;
    font-size: 12px;
  }

  .header1 > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50.47px;
    width: 14.285%;
    border-left: 0.1px solid white;
    font-family: Roboto;
    font-style: Medium;
    font-size: 16px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    border-width: 0.1px;
    overflow: hidden;
  }
`;

export default ({ data, financerMemberUID, CompanyListData }) => {
  const { accounts } = useMsal();
  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");

  return (
    <Styles>
      <h6>Finance Deal List</h6>

      <SBProvider appId={APP_ID} userId={memberId} accessToken={chatToken}>
        <div className="table">
          <div className="header1">
            <div>Finance Id</div>
            <div
              style={{
                borderLeft: "none",
                borderTop: "none",
                paddingLeft: "10px",
              }}
            >
              Financer
            </div>
            <div>Borrower</div>
            <div>Finance Type</div>
            <div>Status</div>
            <div>Last Activity Date</div>
            <div>Action</div>
          </div>
          <div>
            {data &&
              data.map((item) => (
                <TableData
                  data={item}
                  financerMemberUID={financerMemberUID}
                  companyList={CompanyListData}
                />
              ))}
          </div>
        </div>
      </SBProvider>
    </Styles>
  );
};
