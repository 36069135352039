import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./opentrade.module.css";

import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { paymentServiceURL } from "services/BaseURLInstance";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { openTradeAccount, NetworkURL } from "services/BaseURLInstance";
import { useParams } from "react-router-dom";
import { UseRequest } from "utils/API/Request.js";
import { AuthContext } from "contexts/Auth/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { PaymentCollection } from "components/payment/paymentCollection";
import { storeContext } from "../../../contexts/Store/Store";
import { AccountInfoSelect } from "utils/Section/FormInfo";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  getPaymentsByLoanId,
  getPaymentByUser,
  paymentDetails,
  makePayment,
  updatePayment,
  releasePayment,
  updateServiceDeal,
  setIsPercentageComplete,
  isPercentageComplete,
  getPaymentCollection,
} from "api/Payments";

const MilestonePayment = ({ setSelected }) => {
  const navigate = useNavigate();
  let { id } = useParams();
  const handleNavigate = (url, value) => {
    navigate(url, { state: value });
  };
  const { fetchCompanyList } = useContext(storeContext);
  const [response, setResponse] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isError1, setIsError1] = useState(null);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isError2, setIsError2] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const { OpenTradeAccountDetails } = useContext(AuthContext);
  const [provider, setProvider] = useState([]);
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const scheduleData = JSON.parse(sessionStorage.getItem("scheduleData"));
  const [OpenTradeAccountDetail, setOpenTradeAccountDetail] = useState();
  const [isError, setIsError] = useState(null);
  const [isMakePaymentFormOpen, setIsMakePaymentFormOpen] = useState(false);
  const [companyList, setCompanyList] = useState();
  const [paymentCollection, setPaymentCollection] = useState();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const theme = createTheme({
    palette: {
      secondary: {
        main: '#808080',
      },
    },
  });

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      setIsLoading1(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}openaccounttrades/${id}`,
          null,
          response
        ).then((data) => {
          setIsLoading1(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError1(message);
          } else {
            setResponse(data);
          }
        });
      });
    }

    if (!isCancelled) {
      setIsLoading2(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading2(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError2(message);
          } else {
            setPaymentData(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (paymentData && paymentData[0] !== null) {
      let temp = paymentData.map((item) => {
        return { label: item.paymentProvider, value: item.paymentProvider };
      });
      setProvider(temp);
    }
  }, [paymentData]);

  const handlePaymentChange = (event) => {
    setResponse({
      ...response,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e, status) => {
    e.preventDefault();
    response.openTradeStatus = status;

    const options1 = {
      method: "PUT",
      body: response,
    };
    let URL1 = `${openTradeAccount}openaccounttrades/${response.openTradeAccountId}`;
    getIdTokenAsync(instance, accounts).then((res) => {
      UseRequest(URL1, options1, res).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          if (response.openTradeStatus === "ABORTED") {
          }
        }
      });
    });

    scheduleData.tradeMilestoneStatus = "ABORTED";
    const options = {
      method: "PUT",
      body: scheduleData,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
        }
      });
    });
  };

  const handleSubmitPayment = async (e, status) => {
    e.preventDefault();
    if (OpenTradeAccountDetails) {
      OpenTradeAccountDetails.RefBuyerVirtualBankAccountId =
        paymentData[0]?.virtualAccountNumber;
    }
    const options2 = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    let URL2 = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;

    scheduleData.tradeMilestoneStatus = status;
    const options = {
      method: "PUT",
      body: scheduleData,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          Success("Payment Approved Successfully...");
          handleNavigate(`../open-account/${id}`, {
            index: 1,
          });
        }
      });
    });
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL2, options2).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError(message);
        } else {
          // Success("Trade Created successfully...");
          setOpenTradeAccountDetail(data);
          handleNavigate(`../open-account/${id}`);
        }
      });
    });
    sessionStorage.setItem(
      "OpenTradeAccountDetail",
      JSON.stringify(OpenTradeAccountDetail)
    );
  };

  const handleClose = () => {
    setIsMakePaymentFormOpen(false);
  };

  useEffect(() => {
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken)
      .then((companyList) => {
        setCompanyList(companyList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (scheduleData && OpenTradeAccountDetails) {
      let oatPaymentAmount =
        (parseFloat(scheduleData.milestonePaymentPercentage) *
          parseFloat(OpenTradeAccountDetails.tradeValue)) /
        100;
      getPaymentCollection(
        oatPaymentAmount,
        OpenTradeAccountDetails.countryOfDestination || "US",
        OpenTradeAccountDetails.paymentCurrency,
        OpenTradeAccountDetails?.buyerMemberId,
        OpenTradeAccountDetails?.countryOfOrigin || "IN",
        idToken
      )
        .then((res) => {
          setPaymentCollection(res);
        })
        .catch((error) => {
          console.log("paymentCollection error", error);
        });
    }
  }, []);


  const makeLoanPayment = () => {
    paymentCollection[0]["state"] = "MAKE_PAYMENT";
    const requestData = {
      paymentId: "",
      collectionRates: paymentCollection,
      tradxlinkPaymentOrderUID: "",
      senderMemberID: OpenTradeAccountDetails.buyerMemberId,
      receiverMemberID: OpenTradeAccountDetails.sellerMemberId,
      senderMemberAccount:
        OpenTradeAccountDetails.RefBuyerVirtualBankAccountId,
      receiverMemberAccount:
        OpenTradeAccountDetails.RefSellerVirtualBankAccountId,
      currencySender: OpenTradeAccountDetails.paymentCurrency || "USD",
      currencyReceiver: OpenTradeAccountDetails.paymentCurrency || "USD",
      paymentAmount: (
        (parseFloat(OpenTradeAccountDetails.tradeValue) *
          parseFloat(scheduleData.milestonePaymentPercentage)) /
        100
      ).toString(),
      referenceInstrumentType: OpenTradeAccountDetails.instrumentType,
      paymentCategory: "DISBURSEMENT",
      paymentCollectionMethod: "BANK",
      paymentDueDate: "2022-11-11T09:09:32.407Z",
      paymentDescriptionSender: "test",
      paymentDescriptionReceiver: "",
      paymentReferenceData: scheduleData.openTradeScheduleUID,
      paymentStatus:
        paymentData.paymentStatus === "INITIATED"
          ? "RELEASE_COMPLETED"
          : "INITIATED",
      txnType: "goods",
      paymentType: OpenTradeAccountDetails.RefSellerVirtualBankAccountId
        ? "ESCROW"
        : "",
      paymentAttemptCount: 0,
      paymentTransdactionDate: new Date().toISOString(),
      paymentServiceProvider:
        OpenTradeAccountDetails.RefSellerVirtualBankAccountId
          ? "TAZAPAY"
          : "OFFLINE_PAYMENT",
      isDeleted: false,
      tag: "",
      createdBy: companyId,
      updateBy: companyId,
      createdDate: OpenTradeAccountDetails.createdDate,
      updatedDate: OpenTradeAccountDetails.updatedDate,
      paymentCompletedRedirectUrl:
        OpenTradeAccountDetails.RefSellerVirtualBankAccountId
          ? `http://${window.location.host}/trade-account/open-account/${id}?status=PENDING`
          : "",
      paymentErrorRedirectUrl:
        OpenTradeAccountDetails.RefSellerVirtualBankAccountId
          ? `http://${window.location.host}/trade-account/open-account/${id}?status=FAILED`
          : "",
    };
    // handleMilestoneComplete("PAYMENT_COMPLETED"); // todo remove after route fix
    makePayment(idToken, requestData)
      .then((response) => {
        window.location.href = response.paymentUrl;
      })
      .catch((error) => {

      });
  };

  return (
    <div>
      {isLoading1 || isLoading2 ? (
        <span>
          Loading...
          <Loader />
        </span>
      ) : (
        <>
          {<Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={isMakePaymentFormOpen}
            onClose={handleClose}
            PaperProps={{
              sx: { backgroundColor: "#343434", color: "white" },
            }}>
            <div style={{ padding: "2%" }}>
              <div style={{ backgroundColor: "black" }}>
                <DialogContent sx={{}}>
                  <div>
                    <PaymentCollection
                      response={scheduleData}
                      response2={OpenTradeAccountDetails}
                      loanId={id}
                      companyList={companyList}
                      memberId={companyId}
                    />
                  </div>
                </DialogContent>
                <div
                  style={{
                    width: "100%",
                    paddingRight: "3.5%",
                    paddingBottom: "2%",
                    display: "flex",
                    justifyContent: "right",
                  }}>
                  <button
                    style={{
                      width: "20%",
                      margin: "1%",
                      padding: "1%",
                      color: "white",
                      border: "none",
                      backgroundColor: "#42a5f5",
                      borderRadius: "5px",
                      fontSize: "14px",
                    }}
                    onClick={handleClose}>
                    Cancel
                  </button>
                  <>
                    <button
                      style={{
                        width: "20%",
                        margin: "1%",
                        padding: "1%",
                        color: "white",
                        border: "none",
                        backgroundColor: "#42a5f5",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                      onClick={() => {
                        makeLoanPayment();
                      }}>
                      Submit
                    </button>
                  </>
                </div>
              </div>
            </div>
          </Dialog>}
          {response && paymentData && (
            <>
              <Content marginBottom="0%">
                <div style={{ marginBottom: "3%" }}>
                  <div>Payment Preference Info</div>
                  <div style={{ display: "flex" }}>
                    <ThemeProvider theme={theme}>
                      <Switch
                        color={response.escrowService === "OFFLINE" ? 'secondary' : "info"}
                        checked={true}
                      />
                    </ThemeProvider>
                    <div style={{ marginTop: "0.6%" }}>{response.escrowService === "OFFLINE" ? 'Offline Payment' : "Online Payment"}</div>
                  </div>
                </div>
                {response.escrowService !== "OFFLINE" && (
                  <div className={classes.FormDiv}>
                    <div>
                      <div>Payment Service Provider</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Service Provider for Escrow
                      </div>
                    </div>
                    <div>
                      <Input
                        name="escrowServiceProviderUID"
                        placeholder="Escrow Service Provider"
                        value={paymentData[0].paymentProvider}
                        handleChange={handlePaymentChange}
                        readOnly={true}
                      />
                    </div>
                    <div>
                      <div>Escrow Account (Seller)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Escrow Account for this trade
                      </div>
                    </div>
                    <div>
                      <Input
                        name="RefSellerVirtualBankAccountId"
                        placeholder="Ref Seller Virtual BankAccount Id"
                        value={response.RefSellerVirtualBankAccountId}
                        handleChange={handlePaymentChange}
                        readOnly={true}
                      />
                    </div>
                    <div>
                      <div>Escrow Account (Buyer)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    <div>
                      <Input
                        name="RefBuyerVirtualBankAccountId"
                        placeholder="Ref Buyer Virtual BankAccount Id *"
                        handleChange={handlePaymentChange}
                        value={paymentData[0].virtualAccountNumber}
                        readOnly={true}
                      />
                    </div>
                  </div>
                )}
              </Content>
              <ButtonDiv>
                <Button
                  onClick={() => {
                    handleNavigate(`../open-account/${id}`);
                  }}>
                  Go Back
                </Button>
                {/* <Button type="submit">Make Payment</Button> */}
                <Button
                  onClick={(e) => {
                    handleSubmit(e, "ABORTED");
                    {
                      handleNavigate(`../open-account`);
                    }
                  }}>
                  Abort Trade
                </Button>
                {OpenTradeAccountDetails.escrowService != "ESCROW_PAYMENT" ? (
                  <Button onClick={(e) => handleSubmitPayment(e, "PAYMENT_APPROVED")}>
                    Confirm Payment Details
                  </Button>
                ) : OpenTradeAccountDetails.escrowService === "ESCROW_PAYMENT" && scheduleData.tradeMilestoneStatus === "PAYMENT_INITIATED" ? (
                  <Button onClick={(e) => handleSubmitPayment(e, "PAYMENT_APPROVED")}>
                    Confirm Payment Details
                  </Button>
                ) : (
                  <Button onClick={() => setIsMakePaymentFormOpen(true)}>Make Payment</Button>
                )}
              </ButtonDiv>
            </>
          )}
          {(isError1 || isError2) && <span>Something went wrong</span>}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default MilestonePayment;
