export const PaymentTab = [
  { name: "Payment Transactions", value: "payment-transactions" },
  { name: "Payment Accounts", value: "payment-accounts" },
  { name: "Payment Providers", value: "payment-providers" },
];

export const PaymentProviderInfo = [
  { value: "businessName", name: "Business Name" },
  { value: "country", name: "Country" },
  { value: "email", name: "Email" },
  { value: "phone", name: "Phone" },
  { value: "paymentProvider", name: "Payment Provider" },
  { value: "memberUID", name: "MemberUID" },
  { value: "customerUID", name: "CustomerUID" },
  { value: "virtualAccountNumber", name: "Virtual Account Number" },
];
