import React, { useState } from "react";
import classes from "./ServiceCard.module.css";
import { RiWechatLine } from "react-icons/ri";
import { MdOutlineAttachEmail, MdVerified } from "react-icons/md";
import { FindCompanyName } from "../../../utils/helper";
import { CheckCountryTitle } from "utils/helper";
import { AiFillStar } from "react-icons/ai";
import { SERVICE_LABEL, INDUSTRY_LABEL } from "utils/Constants";
import cn from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { fetchMemberdata } from "utils/memberchat";

const ServiceCard = ({
  item,
  index,
  selectedIndex,
  companyList,
  companyId,
  handleCheckbox,
  isDisabled,
  hanndleCraateGroup,
  HandleSendDeal,
}) => {
  const text = item.serviceDescription;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const ptext = item.pricingDescription;
  const [pIsReadMore, setPIsReadMore] = useState(true);
  const togglePReadMore = () => {
    setPIsReadMore(!pIsReadMore);
  };

  const handleChat = async (item) => {
    let memberUserId = [companyId, item.memberId];

    let res = await fetchMemberdata(memberUserId);
    let name1 = item.memberId
      ? FindCompanyName(companyList, item.memberId).split(" ")[0]
      : "NA";
    let name2 = FindCompanyName(companyList, companyId).split(" ")[0];
    let nameArray = [name1, name2];
    nameArray.sort();
    let name = nameArray.join("-");
    if (res?.length > 0) {
      hanndleCraateGroup(res, name, name);
    }
  };

  return (
    <div className={classes.ServiceCard} style={{ padding: "1% 2%" }}>
      <div className={classes.ServiceHead}>
        <div className={classes.companyName}>
          <span style={{ fontSize: "17px" }}>
            {companyList && companyList.length > 0
              ? FindCompanyName(companyList, item.memberId)
              : "Unknown"}
          </span>
          <span style={{ margin: "0px 0px 0px 5px" }}>
            {<MdVerified size={22} className={classes.checkIcon} />}
          </span>
        </div>
        <div className={classes.ServiceHead1} style={{ width: "14%" }}>
          {companyId != item.memberId && (
            <span className={classes.ServiceBtn2}>
              <button style={{ backgroundColor: "lightgrey" }}>
                Add to Favorite
              </button>
            </span>
          )}
        </div>
        <div style={{ width: "37.5%" }}></div>
        <div className={classes["service-head-right"]} style={{ width: "6%" }}>
          <div className="mx-2">
            <input
              checked={selectedIndex === index ? true : false}
              className={
                isDisabled
                  ? cn(classes.isDisabled, classes.Checkbox)
                  : cn(classes.Checkbox)
              }
              type="checkbox"
              onClick={() => handleCheckbox(index, item)}
            />
          </div>

          {selectedIndex === index && (
            <button onClick={() => HandleSendDeal()}>Send Deal</button>
          )}
        </div>
      </div>
      <div className={classes.ServiceBody}>
        <div>
          <div className={classes["flexbox-start"]}>
            <span>
              Service Category : {SERVICE_LABEL(item.serviceCategory[0])}
            </span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>
              Service Sub-Category : {SERVICE_LABEL(item.serviceSubCategory[0])}
            </span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>
              Available since :{" "}
              {new Date(item.createdDate).toLocaleDateString()}
            </span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>Completed Subscriptions : 0</span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>
              Service Rating :{" "}
              <span>
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
              </span>
            </span>
          </div>

          <div className={classes["icon-group"]} style={{ cursor: "pointer" }}>
            {companyId != item.memberId && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                key="top"
                placement="top"
                overlay={
                  <Popover id="popover-positioned-top">
                    <Popover.Body>
                      <strong>Chat</strong>
                    </Popover.Body>
                  </Popover>
                }>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => handleChat(item)}>
                  <RiWechatLine size={30} />
                </span>
              </OverlayTrigger>
            )}
            {companyId === item.memberId ? (
              <span
                style={{ marginLeft: "10px" }}
                className={classes.isDisabled}>
                <MdOutlineAttachEmail size={30} />
              </span>
            ) : (
              <span
                style={{ marginLeft: "10px" }}
                className={classes.isDisabled}>
                <MdOutlineAttachEmail size={30} />
              </span>
            )}
          </div>
        </div>

        <div>
          <div className={classes["supplier-text"]}>
            <div style={{ fontSize: "16px" }}>Description :</div>
            <div>
              {isReadMore ? text.slice(0, 80) : text}
              {text.length > 80 && (
                <span
                  onClick={toggleReadMore}
                  style={{ color: "#42a5f5", cursor: "pointer" }}>
                  {isReadMore ? "...Read more" : " Show less"}
                </span>
              )}
            </div>
          </div>
          <div className={classes["supplier-text"]}>
            <div style={{ fontSize: "16px" }}>Pricing :</div>
            <div>
              {pIsReadMore ? ptext.slice(0, 80) : ptext}
              {ptext.length > 80 && (
                <span
                  onClick={togglePReadMore}
                  style={{ color: "#42a5f5", cursor: "pointer" }}>
                  {pIsReadMore ? "...Read more" : " Show less"}
                </span>
              )}
            </div>
          </div>
          <div className={classes.supplier}>
            <div style={{ fontSize: "16px" }}>Industries :</div>
            <div className={classes.supplierList}>
              {item.industry &&
                item.industry.map((item, i) => (
                  <button key={i} style={{ cursor: "default" }}>
                    {INDUSTRY_LABEL(item)}
                  </button>
                ))}
            </div>
          </div>
          <div className={classes.supplier}>
            <div style={{ fontSize: "16px" }}>Geography :</div>
            <div className={classes.supplierList}>
              {item.countriesOfOperations.map((country, i) => (
                <button key={i} style={{ cursor: "default" }}>
                  {CheckCountryTitle(country)}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
