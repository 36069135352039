import React, { useCallback, useContext, useState, useEffect } from "react";
import Table from "components/table/index";
// import TableNew from "components/UI/Table/TableHeader";
import { financeURL } from "services/BaseURLInstance";

import { MdCircleNotifications } from "react-icons/md";
import { GoMail } from "react-icons/go";
import { BsChat } from "react-icons/bs";

import { ImCheckboxUnchecked } from "react-icons/im";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { invoiceFinanceContext } from "contexts/invoiceFinance/invoiceFinanceContext";
import { storeContext } from "contexts/Store/Store";
import { useMsal } from "@azure/msal-react";
import { TableStatus } from "utils/helper";

const FinanceDeals = () => {
  const { getData } = useContext(storeContext);
  const { SavedInvoiceData } = useContext(invoiceFinanceContext);
  const [financeData, setFinanceData] = useState("");
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const navigate = useNavigate();

  useEffect(() => {
    let URL = `${financeURL}finance/deals?invoiceId=${SavedInvoiceData.txlInvoiceId}`;
    if (idToken) {
      getData(URL, idToken).then((data) => {
        setFinanceData(data);
      });
    }
  }, []);

  const handleClickAlert = () => {};
  const handleClickStatus = () => {};
  const handleClickMail = () => {};
  const handleClickChat = () => {};

  const handleClickSelect = (value) => {
    const filteredData = financeData.filter(
      (list) => list.financeDealId === value.financeDealId
    );
    localStorage.setItem("financeDeal", JSON.stringify(filteredData[0]));
    navigate("/financedashBoard/invoicefinancing/summary");
  };

  const headerFieldsList = [
    {
      Header: "Select",
      accessor: "financeDealId",
      Cell: (cell) => {
        return (
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
            }}
            value={cell.accessor}
          >
            <ImCheckboxUnchecked
              onClick={() => handleClickSelect(cell.row.values)}
              size={26}
            />
          </button>
        );
      },
    },
    {
      Header: "Financer",
      accessor: "financerName",
      Cell: (cell) => {
        return (
          <span onClick={() => handleClickSelect(cell.row.values)}>
            {cell.row.values.financerName}
          </span>
        );
      },
    },
    {
      Header: "Offered Interested Rate",
      accessor: "interestRate",
    },
    {
      Header: "Status",
      accessor: "financeDealStatus",
      Cell: (cell, index) => (
        <span>{TableStatus(cell.cell.row.values.financeDealStatus)}</span>
      ),
    },
    {
      Header: "Last Activity Date",
      accessor: "lastUpdatedDate",
      Cell: (cell) => (
        <span>
          {new Date(cell.cell.row.values.lastUpdatedDate).toLocaleDateString()}
        </span>
      ),
    },
    {
      Header: "Action",
      accessor: "Action",
      Cell: (cell, index) => (
        <ActionStyled>
          <button
            className="buttonIcon"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
            }}
            value={cell.accessor}
            onClick={() => handleClickMail(cell.cell.row.values)}
          >
            <GoMail size={30} />
          </button>

          <button
            className="buttonIcon"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
            }}
            value={cell.accessor}
            onClick={() => handleClickChat(cell.cell.row.values)}
          >
            <BsChat size={26} />
          </button>
        </ActionStyled>
      ),
    },
  ];

  const columns = React.useMemo(() => headerFieldsList);

  return (
    <>
      {Array.isArray(financeData) ? (
        <Table columns={columns} data={financeData} />
      ) : (
        <p>No Records</p>
      )}
    </>
  );
};

export default FinanceDeals;

const StatusStyled = styled.button`
  width: 150px;
  border: none !important;
  padding: 10px 0px;
  letter-spacing: 1px;
  cursor: pointer;
`;

const greenStyle = {
  backgroundColor: "green !important",
  color: "white !important",
};

const ActionStyled = styled.div`
  display: flex;
  align-items: center;
  button:nth-child(1) {
    margin-right: 10px;
    cursor: pointer;
  }
  button:nth-child(2) {
    cursor: pointer;
  }
`;
