import React, { useCallback, useState, useContext, useEffect } from "react";
import { AiFillStar } from "react-icons/ai";
import { FaBuilding, FaTwitterSquare, FaWhatsappSquare } from "react-icons/fa";
import { BsCheckCircleFill, BsTelephone } from "react-icons/bs";
import { SiGooglemaps } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { AiFillLinkedin } from "react-icons/ai";

import {
  NetworkURL,
  serviceURL,
  linkMemberURL,
  docGroupURL,
  documentURL,
  docUpdateURL,
} from "services/BaseURLInstance";

import { RiWechatLine } from "react-icons/ri";
import styles from "../companyDetails.module.css";
import { storeContext } from "contexts/Store/Store";
import { MdOutlineAttachEmail } from "react-icons/md";
import { useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import Button from "@mui/material/Button";
import ServiceCard from "components/UI/NetworkSection/ServiceCard";
import { MdCancel } from "react-icons/md";
import { MdOutlineCancel } from "react-icons/md";
import { HiBadgeCheck, HiArrowNarrowLeft } from "react-icons/hi";
import cn from "classnames";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

import { UseRequest } from "utils/API/Request.js";
import OnBoarding from "./OnBoarding";
import PerformanceCard from "./PerformanceCard";
import { LABEL } from "utils/Constants";
import { FindCompanyName, handleMailOpen } from "utils/helper.js";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { fetchMemberdata } from "utils/memberchat";
import { ButtonStyled } from "components/Container.styled";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export default function ({ hanndleCraateGroup, toggleChat }) {
  let { type, id } = useParams();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const { getData } = useContext(storeContext);
  const [companyData, setCompanyData] = useState(null);
  const [financerData, setFinancerData] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [companyList, setCompanyList] = useState("");
  const [companySubType1, setCompanySubType1] = useState("");
  const [companySubType2, setCompanySubType2] = useState("");
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      setIsLoading(true);
      if (type === "FINANCER") {
        getIdTokenAsync(instance, accounts)
          .then((response) => {
            UseRequest(`${NetworkURL}financers/${id}`, null, response).then(
              (data) => {
                let { message } = data;
                if (message) {
                  setIsError(message);
                  Error(message);
                } else {
                  setFinancerData(data);
                }
              }
            );
          })
          .catch(() => {})
          .finally(() => {});
      } else {
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(`${NetworkURL}companies/${id}`, null, response).then(
            (data) => {
              let { message } = data;
              if (message) {
                setIsError(message);
                Error(message);
              } else {
                setCompanyData(data);
              }
            }
          );
        });
      }
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${serviceURL}services`, null, response).then((data) => {
          let { message } = data;
          if (message) {
            setIsError(message);
            Error(message);
          } else {
            const filteredData = data.filter(
              (service) => service.memberId === id
            );
            setServiceData(filteredData);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${linkMemberURL}members?memberId=${companyId}`,
          null,
          response
        ).then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setCompanyList(data);
          }
        });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && (financerData || companyData)) {
      let memberUserId = "";
      if (companyData) {
        memberUserId = companyData.memberUID;
      } else {
        memberUserId = financerData.memberUID;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${docGroupURL}bff/documentsGroups/COMPANY/${memberUserId}`,
          null,
          response
        )
          .then((data) => {
            let { message } = data;
            if (message) {
              Error(message);
              setIsError(message);
            } else {
              setCompanySubType1(data[0]);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [companyData, financerData]);

  const addToFavoite = async () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify({
        memberId: "urn:uuid:8700c8c9-a5e0-c018-03a1-8b27868ce68b",
        role: "in consequat incididunt adipisicing",
        relationShipCategory: "Contact",
        relationshipCreationDate: new Date(),
      }),
    };
    await fetch(`${NetworkURL}/network/${companyId}/Favorites`, requestOptions)
      .then((response) => response.json())
      .then((data) => {});
  };

  const deleteToFavoite = async () => {};

  //Button  Add to Network
  const addToNetwork = async () => {
    const requestOp = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify({
        memberId: "urn:uuid:59e08ca6-13dc-98ef-3fd0-6e1160ead7b4",
        role: "",
        country: "aliquip ",
      }),
    };
    await fetch(
      "https://e78db676-dab7-43eb-9712-11ab57ad146b.mock.pstmn.io/network/",
      requestOp
    )
      .then((response) => response.json())
      .then((item) => {});
  };

  const deleteToNetwork = async () => {};

  const handleChat = () => {
    let url = window.location.href;
    localStorage.setItem("lasturl", url);
    navigate("/chat");
  };

  const handleClick = () => {
    if (
      window.location.href.substring(22, window.location.href.length) !==
        "chat" &&
      window.location.href.substring(26, 27) !== "?"
    ) {
      let url = window.location.href;
      localStorage.setItem("lasturl", url);
      navigate("/chat");
    } else {
      navigate(
        `/${localStorage
          .getItem("lasturl")
          .substring(22, localStorage.getItem("lasturl").length)}`
      );
    }
  };

  const companyNetorkData = [
    { name: "abc1", id: "2767724f-edcb-4beb-a3e6-de108baed448" },
  ];

  const companyFavroiteData = [
    { name: "abc1", id: "2767724f-edcb-4beb-a3e6-de108baed448" },
    { name: "abc2", id: "48ade533-3182-4534-a128-6d4e69e793fb" },
  ];

  const filterNetwork = (memberUID) => {
    let data = companyNetorkData.filter((item) => {
      return item.id === memberUID;
    });
    if (data.length >= 1) {
      return true;
    } else {
      return false;
    }
  };

  const filterFavorite = (memberUID) => {
    let data = companyFavroiteData.filter((item) => {
      return item.id === memberUID;
    });
    if (data.length >= 1) {
      return true;
    } else {
      return false;
    }
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleTwitterNavigate = (e, link) => {
    e.preventDefault();
    handleNavigate(link);
  };

  useEffect(() => {
    let isChancelled = false;
    if (companySubType1) {
      let documents = companySubType1.documents;
      let docObject = documents.filter((doc) => {
        return doc.docReferenceId !== "";
      });
      for (let i = 0; i < docObject.length; i++) {
        let URL2 = `${documentURL}/documents/${docObject[i].docReferenceId}`;
        getData(URL2, idToken).then((response) => {
          setCompanySubType2((e) => [...e, response]);
        });
      }
      return () => {
        isChancelled = true;
      };
    }
  }, [companySubType1]);

  const handleChat1 = async (item) => {
    let memberUserId = [companyId, item.memberId];
    let res = await fetchMemberdata(memberUserId);
    // let name = FindCompanyName(companyList, item.memberId);
    let name1 = item.memberId
      ? FindCompanyName(companyList, item.memberId).split(" ")[0]
      : "NA";
    let name2 = FindCompanyName(companyList, companyId).split(" ")[0];
    let nameArray = [name1, name2];
    nameArray.sort();
    let name = nameArray.join("-");
    if (res?.length > 0) {
      hanndleCraateGroup(res, name, name);
    }
  };

  const RatingDiv = ({ rating }) => {
    const stars = [];
    if (Number(rating) > 0) {
      for (let i = 0; i < Number(rating); i++) {
        stars.push(<AiFillStar key={i} color="#FFA100" />);
      }

      for (let i = Number(rating); i < 5; i++) {
        stars.push(<AiFillStar key={i} color="gray" />);
      }

      return <div>{stars}</div>;
    } else {
      return <div>Not Rated</div>;
    }
  };

  const SendRequest = (data) => {
    const requestParams = {
      documentIds: [data.id],
      ownerMemberIds: [data.owner],
      requesterMemberId: companyId,
      // receiverMemberIds: selectList.map((obj) => obj.value),
      receiverMemberIds: [companyId],
    };

    const options = {
      method: "POST",
      body: requestParams,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(`${docUpdateURL}documents/consent-requests`, options, response)
        .then((data) => {
          Success("Requested Successfully");
        })
        .catch((err) => {
          Error();
        });
    });
  };

  return (
    <div>
      <div
        style={{
          marginBottom: "2%",
          color: "#218eeb",
          cursor: "pointer",
          display: "flex",
        }}>
        <div
          style={{ whiteSpace: "nowrap" }}
          onClick={() => {
            handleNavigate("../");
          }}>
          <span>
            <HiArrowNarrowLeft size={28} />
          </span>
          <span>Back to List </span>
        </div>

        <div style={{ marginLeft: "38%" }}>
          {" "}
          <ButtonStyled
            style={{
              borderRadius: "110px",
              padding: "7px 40px",
              margin: "3px",
            }}
            // }}
            // className="containe1"
            onClick={() => scrollToSection("section1")}>
            Performance Card
          </ButtonStyled>
          <ButtonStyled
            style={{
              borderRadius: "110px",
              padding: "7px 40px",
              margin: "3px",
            }}
            onClick={() => scrollToSection("section2")}>
            Trust Card
          </ButtonStyled>
          <ButtonStyled
            style={{
              borderRadius: "110px",
              padding: "7px 40px",
              margin: "3px",
            }}
            onClick={() => scrollToSection("section3")}>
            View Company
          </ButtonStyled>
          <ButtonStyled
            style={{
              borderRadius: "110px",
              padding: "7px 40px",
              margin: "3px",
            }}
            onClick={() => scrollToSection("section4")}>
            view Service
          </ButtonStyled>
        </div>
      </div>

      {isLoading ? (
        <h6>Loading...</h6>
      ) : (
        <>
          {companyData && (
            <div
              className={styles.container}
              style={{
                padding: "1% 2%",
                backgroundColor: "black",
                marginBottom: "2%",
              }}>
              {/* Section 1 */}
              <div className={styles.wrapper}>
                <div className={styles.leftWrapper}>
                  <div className={styles.imgDiv}>
                    <img src="https://www.jdfulwiler.com/wp-content/uploads/2017/08/abc-company-test-logo-300x205.png" />
                  </div>

                  <div className={styles.profileDiv}>
                    <div className={styles.namingDiv}>
                      <div
                        className={cn(
                          styles.certficateIconDiv,
                          "d-flex align-items-center"
                        )}>
                        {companyData.verified === "VERIFICATION_PASS" ? (
                          <HiBadgeCheck
                            style={{
                              color: "#4CAF50",
                            }}
                            size={24}
                          />
                        ) : (
                          <MdOutlineCancel
                            style={{
                              color: "rgba(255, 161, 0, 1)",
                            }}
                            size={20}
                          />
                        )}
                        <div>{companyData.companyName}</div>
                      </div>

                      <div>
                        <div
                          className={
                            companyData.memberType === "COMPANY"
                              ? styles.companyType
                              : styles.supplierType
                          }>
                          {LABEL(companyData.memberType)}
                        </div>
                      </div>
                    </div>

                    <div className={styles.flexStart}>
                      <div>
                        Country:
                        {companyData.companyCountry
                          ? companyData.companyCountry
                          : "Not Provided"}
                      </div>
                    </div>

                    <div className={styles.flexStart}>
                      <div>
                        SiteURL:
                        {companyData.companySiteURL
                          ? companyData.companySiteURL
                          : "Not Provided"}
                      </div>
                    </div>

                    <div className={styles.flexStart}>
                      <div style={{ marginRight: "4px" }}> Company Rating</div>
                      <div
                        className={styles.iconStyle}
                        style={{ display: "flex" }}>
                        <RatingDiv rating={companyData.rating} />
                        <span style={{ margin: "0px 5px" }}>
                          {companyData.noOfRating}
                        </span>
                        <span>Ratings</span>
                      </div>
                    </div>

                    <div className={styles.iconDiv}>
                      <AiFillLinkedin
                        fill="rgba(53, 153, 238, 1)"
                        size={26}
                        onClick={(e) =>
                          handleTwitterNavigate(e, companyData.twitterLink)
                        }
                      />
                      <FaTwitterSquare fill="rgba(53, 153, 238, 1)" size={24} />
                    </div>
                  </div>
                </div>

                <div className={styles.rightWrapper}>
                  <div className={styles.btnDiv}>
                    <button
                      onClick={
                        filterNetwork(companyData.memberUID)
                          ? (e) => deleteToNetwork(e, companyData.memberUID)
                          : (e) => addToNetwork(e, companyData.memberUID)
                      }
                      className={
                        filterNetwork(companyData.memberUID)
                          ? styles.deleteBtn
                          : styles.addBtn
                      }>
                      {filterNetwork(companyData.memberUID)
                        ? "Remove From Network"
                        : "Add to Network"}
                    </button>

                    <button
                      onClick={
                        filterFavorite(companyData.memberUID)
                          ? (e) => deleteToFavoite(e, companyData.memberUID)
                          : (e) => addToFavoite(e, companyData.memberUID)
                      }
                      className={
                        filterFavorite(companyData.memberUID)
                          ? styles.deleteBtn
                          : styles.addBtn
                      }>
                      {filterFavorite(companyData.memberUID)
                        ? "Remove From Favorite"
                        : "Add to Favorite"}
                    </button>
                  </div>

                  <div className={styles.flexColumn}>
                    <div className={styles.detailsDiv}>
                      <div className={styles.addressDiv}>
                        <div
                          className={styles.iconStyle}
                          style={{ marginRight: "5px" }}>
                          <FaBuilding size={25} fill=" rgba(33, 142, 235, 1)" />
                        </div>
                        <div>
                          Address:
                          {companyData.companyAddress
                            ? companyData.companyAddress.map((item) => (
                                <>
                                  <span>{item}</span>
                                  <br></br>
                                </>
                              ))
                            : "Not Provided"}
                        </div>
                      </div>

                      <div className={styles.phoneDiv}>
                        {companyData.companyPhone ? (
                          companyData.companyPhone.map((item, index) => (
                            <div className={styles.phoneContainer} key={index}>
                              <div
                                className={styles.iconStyle}
                                style={{ marginRight: "5px" }}>
                                <BsTelephone
                                  size={24}
                                  color="rgba(33, 142, 235, 1)"
                                />
                              </div>
                              <div>{item}</div>
                            </div>
                          ))
                        ) : (
                          <span>
                            <span style={{ marginRight: "5px" }}>
                              <BsTelephone
                                size={24}
                                color="rgba(33, 142, 235, 1)"
                              />
                            </span>
                            Not Provided
                          </span>
                        )}
                      </div>
                    </div>

                    <div className={styles.mapDiv}>
                      <div>
                        <div
                          className={styles.iconStyle}
                          style={{ marginRight: "4px" }}>
                          <SiGooglemaps
                            size={24}
                            color="rgba(33, 142, 235, 1)"
                          />
                        </div>
                        <div style={{ color: "rgba(33, 142, 235, 1)" }}>
                          <a
                            style={{
                              textDecoration: "none",
                              color: "rgba(33, 142, 235, 1)",
                            }}
                            href="https://www.google.com/maps/search/trade+links+pvt+ltd/@28.5566977,77.2460797,11z/data=!3m1!4b1"
                            target="_blank">
                            View Google Map
                          </a>
                        </div>
                      </div>

                      <div className={styles.icon_box}>
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          key="top"
                          placement="top"
                          overlay={
                            <Popover id="popover-positioned-top">
                              <Popover.Body>
                                <strong>Chat</strong>
                              </Popover.Body>
                            </Popover>
                          }>
                          <span>
                            <RiWechatLine
                              size={26}
                              color="rgba(53, 153, 238, 1)"
                              onClick={() => handleChat1(companyData)}
                            />
                          </span>
                        </OverlayTrigger>

                        <span onClick={handleMailOpen}>
                          <MdOutlineAttachEmail
                            size={24}
                            color="rgba(53, 153, 238, 1)"
                          />
                        </span>
                        <span className={styles.isDisabled}>
                          <FaWhatsappSquare
                            size={24}
                            color="rgba(53, 153, 238, 1)"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Description Section */}
              <div className={styles.Wrapper1}>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Company Description:
                </div>
                {companyData.discription ? (
                  <span>{companyData.discription}</span>
                ) : (
                  <span>Discription: Not Provided</span>
                )}
              </div>

              {/* Products and Services */}
              <div className={styles.Wrapper1}>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Products and Services:
                </div>
                {companyData.companyProductsServices ? (
                  <span>{companyData.companyProductsServices}</span>
                ) : (
                  <span>Discription: Not Provided</span>
                )}
              </div>
              <div id="section1">
                <PerformanceCard />
              </div>
              <div id="section2">
                <OnBoarding />
              </div>

              <div className={styles.Wrapper2}>
                <div id="section3">
                  <div className={styles.title1}>
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      {companySubType1 &&
                        companySubType1.docCategory &&
                        companySubType1.docCategory}
                    </span>
                  </div>
                  {companySubType2 && companySubType2.length > 0 ? (
                    companySubType2.map((item, index) => (
                      <>
                        {index < 3 && item && item.id && (
                          <div className={styles.documentDiv}>
                            <div className={styles.title2}>
                              <bold>PANCard</bold>
                              <span>: {item.name}</span>
                            </div>
                            <div className={styles.title3}>
                              <span>Issued By: {item.docIssuer}</span>
                            </div>

                            <div className={styles.issuedList}>
                              <div>Issued Date: {item.validityStartDate}</div>
                              <div className={styles.flexCenter}>
                                {item.validated ? (
                                  <BsCheckCircleFill
                                    size={16}
                                    color="#34BE0F"
                                    style={{ marginRight: "5px" }}
                                  />
                                ) : (
                                  <MdCancel
                                    size={16}
                                    color="red"
                                    style={{ marginRight: "5px" }}
                                  />
                                )}
                                {item.validated ? "Approved" : "Rejected"}
                              </div>
                            </div>

                            <div className={styles.issuedList}>
                              <div>
                                No Expiration Date: {item.validityEndDate}
                              </div>
                              <div
                                className={styles.requestCopy}
                                onClick={() => SendRequest(item)}>
                                Request copy
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ))
                  ) : (
                    <>No Documents Found</>
                  )}
                </div>

                <div>
                  <div className={styles.title1}>
                    <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Accreditation and Certifications:
                    </span>
                  </div>
                  <div className={styles.title2}>
                    <span>Certified SAF® Program Consultant </span>
                  </div>
                  <div className={styles.title3}>
                    <span>Issued By: Not Provided </span>
                  </div>

                  <div className={styles.issuedList}>
                    <div>Issued: Not Provided </div>
                    <div>| No Expiration Date</div>
                    <div className={styles.flexCenter}>
                      <BsCheckCircleFill
                        size={16}
                        color="#34BE0F"
                        style={{ marginRight: "5px" }}
                      />
                      Approved
                    </div>
                    <div className={styles.requestCopy}>Request copy</div>
                  </div>
                </div>
              </div>

              {companySubType2 && companySubType2.length > 0 ? (
                <div style={{ marginBottom: "0.5%" }}>
                  <Button
                    variant="contained"
                    onClick={() =>
                      handleNavigate(`/documents/company?currentNetwork=${id}`)
                    }>
                    View All Documents
                  </Button>
                </div>
              ) : null}
            </div>
          )}

          {financerData && (
            <div
              className={styles.container}
              style={{
                padding: "1% 2%",
                backgroundColor: "black",
                marginBottom: "2%",
              }}>
              <div className={styles.wrapper}>
                <div className={styles.leftWrapper}>
                  <div className={styles.imgDiv}>
                    <img src="https://www.jdfulwiler.com/wp-content/uploads/2017/08/abc-company-test-logo-300x205.png" />
                  </div>

                  <div className={styles.profileDiv}>
                    <div className={styles.namingDiv}>
                      {/* "d-flex align-items-center" */}
                      <div
                        className={cn(
                          styles.certficateIconDiv,
                          "d-flex align-items-center"
                        )}>
                        {financerData.verified === "VERIFICATION_PASS" ? (
                          <HiBadgeCheck
                            style={{
                              color: "#4CAF50",
                            }}
                            size={24}
                          />
                        ) : (
                          <MdOutlineCancel
                            style={{
                              color: "rgba(255, 161, 0, 1)",
                            }}
                            size={20}
                          />
                        )}
                        <div>{financerData.financerName}</div>
                      </div>

                      <div>
                        <div
                          className={
                            financerData.memberType === "COMPANY"
                              ? styles.companyType
                              : styles.supplierType
                          }>
                          {LABEL(financerData.memberType)}
                        </div>
                      </div>
                    </div>

                    <div className={styles.flexStart}>
                      <div>Country : Not Provided</div>
                    </div>

                    <div className={styles.flexStart}>
                      <div>
                        <a>SiteURL : Not Provided</a>
                      </div>
                    </div>

                    <div className={styles.flexStart}>
                      <div style={{ marginRight: "4px" }}> Company Rating</div>
                      <div
                        className={styles.iconStyle}
                        style={{ display: "flex" }}>
                        {/* <AiFillStar color="#FFA100" />
                        <AiFillStar color="#FFA100" />
                        <AiFillStar color="#FFA100" />
                        <AiFillStar color="#FFA100" />
                        <AiFillStar color="#FFA100" /> */}
                        <RatingDiv rating={financerData.rating} />
                        <span style={{ margin: "0px 5px" }}>
                          {financerData.noOfRating}
                        </span>
                        <span>Ratings</span>
                      </div>
                    </div>

                    <div className={styles.iconDiv}>
                      <AiFillLinkedin fill="rgba(53, 153, 238, 1)" size={26} />
                      <FaTwitterSquare fill="rgba(53, 153, 238, 1)" size={24} />
                    </div>
                  </div>
                </div>
                <div className={styles.rightWrapper}>
                  <div className={styles.btnDiv}>
                    <button
                      onClick={
                        filterNetwork(financerData.memberUID)
                          ? (e) => deleteToNetwork(e, financerData.memberUID)
                          : (e) => addToNetwork(e, financerData.memberUID)
                      }
                      className={
                        filterNetwork(financerData.memberUID)
                          ? styles.deleteBtn
                          : styles.addBtn
                      }>
                      {filterNetwork(financerData.memberUID)
                        ? "Remove From Network"
                        : "Add to Network"}
                    </button>

                    <button
                      onClick={
                        filterFavorite(financerData.memberUID)
                          ? (e) => deleteToFavoite(e, financerData.memberUID)
                          : (e) => addToFavoite(e, financerData.memberUID)
                      }
                      className={
                        filterFavorite(financerData.memberUID)
                          ? styles.deleteBtn
                          : styles.addBtn
                      }>
                      {filterFavorite(financerData.memberUID)
                        ? "Remove From Favorite"
                        : "Add to Favorite"}
                    </button>
                  </div>

                  <div className={styles.flexColumn}>
                    <div className={styles.detailsDiv}>
                      <div className={styles.addressDiv}>
                        <div
                          className={styles.iconStyle}
                          style={{ marginRight: "5px" }}>
                          <FaBuilding size={25} fill=" rgba(33, 142, 235, 1)" />
                        </div>
                        <div>
                          Address:
                          {financerData.companyAddress
                            ? financerData.companyAddress.map((item) => (
                                <>
                                  <span>{item}</span>
                                  <br></br>
                                </>
                              ))
                            : " Not Provided"}
                        </div>
                      </div>

                      <div className={styles.phoneDiv}>
                        {financerData.companyPhone ? (
                          financerData.companyPhone.map((item, index) => (
                            <div className={styles.phoneContainer} key={index}>
                              <div
                                className={styles.iconStyle}
                                style={{ marginRight: "5px" }}>
                                <BsTelephone
                                  size={24}
                                  color="rgba(33, 142, 235, 1)"
                                />
                              </div>
                              <div>{item}</div>
                            </div>
                          ))
                        ) : (
                          <span>
                            <span style={{ marginRight: "5px" }}>
                              <BsTelephone
                                size={24}
                                color="rgba(33, 142, 235, 1)"
                              />
                            </span>
                            Not Provided
                          </span>
                        )}
                      </div>
                    </div>

                    <div className={styles.mapDiv}>
                      <div>
                        <div
                          className={styles.iconStyle}
                          style={{ marginRight: "4px" }}>
                          <SiGooglemaps
                            size={24}
                            color="rgba(33, 142, 235, 1)"
                          />
                        </div>
                        <div style={{ color: "rgba(33, 142, 235, 1)" }}>
                          <a
                            style={{
                              textDecoration: "none",
                              color: "rgba(33, 142, 235, 1)",
                            }}
                            href="https://www.google.com/maps/search/trade+links+pvt+ltd/@28.5566977,77.2460797,11z/data=!3m1!4b1"
                            target="_blank">
                            View Google Map
                          </a>
                        </div>
                      </div>

                      <div className={styles.icon_box}>
                        <RiWechatLine
                          size={26}
                          color="rgba(53, 153, 238, 1)"
                          // onClick={() => handleClick("/chat")}
                          onClick={() => handleChat1(financerData)}
                        />
                        <MdOutlineAttachEmail
                          size={24}
                          color="rgba(53, 153, 238, 1)"
                        />
                        {/* <FaWhatsappSquare
                          size={24}
                          color="rgba(53, 153, 238, 1)"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Description Section */}
              <div className={styles.Wrapper1}>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Company Description:
                </div>
                {financerData.discription ? (
                  <span>{financerData.discription}</span>
                ) : (
                  <span>Discription: Not Provided</span>
                )}
              </div>

              {/* Products and Services */}
              <div className={styles.Wrapper1}>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Products and Services:
                </div>
                {financerData.companyProductsServices ? (
                  <span>{financerData.companyProductsServices}</span>
                ) : (
                  <span>Discription: Not Provided</span>
                )}
              </div>

              <PerformanceCard id="section1" />
              <OnBoarding id="section3" />

              {companySubType1 &&
                companySubType2 &&
                companySubType1 !== "" &&
                companySubType2 !== "" && (
                  <div className={styles.Wrapper2}>
                    <div>
                      <div className={styles.title1}>
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          {companySubType1 &&
                            companySubType1.docCategory &&
                            companySubType1.docCategory}
                        </span>{" "}
                      </div>
                      {companySubType2 && companySubType2.length > 0 ? (
                        companySubType2.map((item, index) => (
                          <>
                            {index < 3 && item && item.id && (
                              <div className={styles.documentDiv}>
                                <div className={styles.title2}>
                                  <bold>PANCard</bold>
                                  <span>: {item.name}</span>
                                </div>
                                <div className={styles.title3}>
                                  <span>Issued By: {item.docIssuer}</span>
                                </div>

                                <div className={styles.issuedList}>
                                  <div>
                                    Issued Date: {item.validityStartDate}
                                  </div>
                                  <div className={styles.flexCenter}>
                                    {item.validated ? (
                                      <BsCheckCircleFill
                                        size={16}
                                        color="#34BE0F"
                                        style={{ marginRight: "5px" }}
                                      />
                                    ) : (
                                      <MdCancel
                                        size={16}
                                        color="red"
                                        style={{ marginRight: "5px" }}
                                      />
                                    )}
                                    {item.validated ? "Approved" : "Rejected"}
                                  </div>
                                </div>

                                <div className={styles.issuedList}>
                                  <div>
                                    No Expiration Date: {item.validityEndDate}
                                  </div>
                                  <div className={styles.requestCopy}>
                                    Request copy
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))
                      ) : (
                        <div>No documents found</div>
                      )}

                      {companySubType2 && companySubType2.length > 0 ? (
                        <div style={{ marginBottom: "0.5%" }}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleNavigate(
                                `/documents/company?currentNetwork=${id}`
                              )
                            }>
                            View All Documents
                          </Button>
                        </div>
                      ) : null}
                    </div>

                    <div>
                      <div className={styles.title1}>
                        <span style={{ fontSize: "16px", fontWeight: "bold" }}>
                          Accreditation and Certifications:
                        </span>{" "}
                      </div>
                      <div className={styles.title2}>
                        <span>Certified SAF® Program Consultant </span>{" "}
                      </div>
                      <div className={styles.title3}>
                        <span>Issued By: Not Provided </span>{" "}
                      </div>

                      <div className={styles.issuedList}>
                        <div>Issued: Not Provided </div>
                        <div>| No Expiration Date</div>
                        <div className={styles.flexCenter}>
                          <BsCheckCircleFill
                            size={16}
                            color="#34BE0F"
                            style={{ marginRight: "5px" }}
                          />
                          Approved
                        </div>
                        <div className={styles.requestCopy}>Request copy</div>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          )}

          {serviceData && (
            <div
              className={styles.ServiceCardDiv}
              style={{ padding: "1.5% 2% 0.5% 2%" }}>
              <h6 style={{ fontSize: "18px" }}>Services</h6>
              <div id="section4">
                {serviceData.map((item) => {
                  return (
                    <ServiceCard
                      item={item}
                      companyList={companyList}
                      isCheckboxHide={true}
                    />
                  );
                })}
              </div>
              {serviceData.length === 0 && <div>No service data</div>}
            </div>
          )}

          {isError && <span>{isError}</span>}
        </>
      )}
    </div>
  );
}
