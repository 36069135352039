export const validDateInput=(data)=>{
    if(data.financeProductCategory === "" || (data.financeProductCategory !== "SYNDICATE_FINANCE" && data.financeProductCategory !== "TERM_FINANCE") || data.financeProductCategory=== null || data.financeProductCategory=== undefined)
    {
        return ("Loan Product Category can not be null")
    }
    else if(data.financeProductType=== "" || data.financeProductType=== null|| data.financeProductType=== undefined)
    {
        return ("Loan Product Type can not be null")
    }
    else if(data.financeAmount<=0)
    {
        return ("Finance Amount should not be less then 0");
    }
    else if(data.interestRate<=0 || data.interestRate>30)
    {
        return ("Intrest rate should be between 0 to 30 % only");
    }
    else{
        return false;
    }

}