import React, { useState, useMemo } from "react";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";
import Requsition from "../DealDetailsTab/Requsition";
import { useParams } from "react-router-dom";
import Quote from "../DealDetailsTab/Quote";
import SyndicateLending from "pages/syndicatelending/index";
import History from "components/History/history";
import DocumentSection from "./DocumentSection/index";

const DealDetails = ({
  Data,
  hanndleCraateGroup,
  companyList,
  requisitionData,
}) => {
  const [isActive, setIsActive] = useState("Requsition");
  const [sydicateMenu, setSydicateMenu] = useState("");
  const [headerSelect, setHeaderSelect] = useState("requsition");
  const [loanID, setLoanID] = useState();
  const [Component, setIsComponent] = useState(
    <Requsition
      Data={Data}
      requisitionData={requisitionData}
      hanndleCraateGroup={hanndleCraateGroup}
      companyList={companyList.companies}
    />
  );
  const { id } = useParams();
  const [syndicatePage, setSyndicatePage] = useState(false);

  const setSyndicate = (id) => {
    setLoanID(id);
    switch (headerSelect) {
      case "syndicatelending":
        return <SyndicateLending data={loanID} />;
    }
  };

  const syndicate = {
    setSyndicate,
    Data,
    setSydicateMenu,
  };

  const Navbar = useMemo(
    () => [
      {
        title: "Requsition",
        description: (
          <Requsition
            Data={Data}
            requisitionData={requisitionData}
            hanndleCraateGroup={hanndleCraateGroup}
            companyList={companyList.companies}
          />
        ),
      },
      {
        title: "Quote",
        description: (
          <Quote
            data={syndicate}
            setSyndicatePage={setSyndicatePage}
            hanndleCraateGroup={hanndleCraateGroup}
            companyList={companyList}
            requisitionData={requisitionData}
            setIsActive={setIsActive}
          />
        ),
      },
      syndicatePage
        ? {
            title: "SyndicateLending",
            description: <SyndicateLending data={loanID} />,
          }
        : {
            title: "",
            description: "",
          },
      {
        title: "Documents",
        description: <DocumentSection response={Data} />,
      },
      {
        title: "History",
        description: (
          <History
            dealstatus="FINANCE DEAL STATUS"
            screen="FINANCING"
            id={id}
            companyList={companyList.companies}
          />
        ),
      },
    ],
    [companyList, syndicatePage]
  );

  const handleChange = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };

  return (
    <div>
      <ToogleNavbar
        Navbar={Navbar}
        isActive={isActive}
        handleChange={handleChange}
        handleComponent={handleComponent}
        syndicatePage={syndicatePage}
      />
      {Component !== undefined && Component}
    </div>
  );
};

export default DealDetails;
