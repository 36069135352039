import React, { useContext, useEffect, useState } from "react";
import { useFetch } from "./Request.js";
import { NetworkURL,profileURL } from "services/BaseURLInstance";

export const CompanyList = () => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  let URL = `${NetworkURL}members?memberId=${companyId}`;
  const { response, isLoading, isError } = useFetch(URL, null);
  return { response, isLoading, isError };
};

export const CompanyMember = () => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  let URL = `${profileURL}Persons?organizationMemberId=${companyId}`;
  const { response, isLoading, isError } = useFetch(URL, null);
  return { response, isLoading, isError };
};
