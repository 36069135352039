import styled from "styled-components";
export default styled.div`
  .backtoloan {
    color: #0067a0;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 15px;
    line-height: 71%;
    text-align: right;
    padding: 16px;
  }
  .loanSummarytext {
    padding: 16px;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 24px;
    line-height: 102%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
  }
  .loandetails {
    background: #ffffff0d;
    border-radius: 5px;
    width: 97%;
    padding: 16px;
  }
  .header {
    width: 100%;
    display: flex;
    background-color: #343434;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .header > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50.47px;
    width: 16.5%;
    border-left: 1px solid white;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    border-width: 0.1px;
  }

  .row {
    width: 100%;
    display: flex;
    background: #010915;
    borderradius: "10px";
  }
  .row > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50.47px;
    width: 16.5%;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 24px;
    line-height: 114%;
    vertical-align: top;
    paragraph-spacing: 32px;
    border-width: 0.1px;
    border-left: 0.1px solid white;
    border-bottom: 0.1px solid white;
    overflow: hidden;
  }

  .details {
    padding-bottom: 20px;
    font-family: Roboto;
    font-style: Regular;
    font-size: 16px;
    line-height: 24px;
    line-height: 128%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 26px;
  }
  .details-screen {
    display: flex;
    background: #010915;
    padding-left: 33px;
    padding-right: 33px;
    width: 1165px;
  }
  .details-button {
    margin-top: 60px;
    margin-left: 120px;
  }
  .details-button > button {
    display: block;
    margin: 4px;
    background-color: #42a5f5;
    color: black;
    cursor: pointer;
    width: 214.24px;
    height: 38.09px;
  }
  .details > hr {
    height: 0.0000000001px;
    opacity: 20%;
  }
  .details > div {
    margin-top: 20px;
  }
  .loanDetailsFields {
    background: #010915;
    border-radius: 5px;
  }
  .loanDetailsHeading {
    padding-top: 16px;
    color: white;
    font-family: Roboto;
    font-style: Bold;
    font-size: 18px;
    line-height: 24px;
    line-height: 102%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 32px;
    margin: 20px;
    margin-left: 27px;
  }
  .inputFieldView {
    margin-top: 34px;
    background: #010915;
    padding-bottom: 40px;
    margin-left: 28px;
    border-radius: 10px;
  }
  .setOfInput {
    display: flex;
    padding-top: 40px;
    justify-content: center;
  }
  input {
    border-radius: 6px;
    margin-top: -10px;
    margin-right: 20px;
    width: 400px;
    height: 51px;
    outline: none;
    background: none;
    z-index: -11;
    border: 0.2px solid #42a5f5;
    color: white;
    padding-left: 20px;
  }

  label {
    margin-top: -20px;
    position: absolute;
    margin-left: 30px;
    background: #010915;
    z-index: 10;
    font-size: 15px;
    padding-left: 5px;
    padding-right: 5px;
    color: #42a5f5;
  }
  textarea {
    border-radius: 6px;
    margin-top: -10px;
    margin-right: 20px;
    width: 400px;
    height: 204px;
    outline: none;
    background: none;
    z-index: -11;
    border: 0.2px solid #42a5f5;
    color: white;
    padding-left: 20px;
    padding-top: 20px;
  }
  .table {
    padding: 16px;
    border-radius: 10px;
  }
  .summary {
    background: #010915;
    border-radius: 5px;
    width: 100%;
  }
  .summaryheading {
    div {
      width: 100%;
      margin-right: 20px;
    }
  }
  .dealDetailsHeader {
    display: flex;
    justify-content: center;
    margin-top: 42px;
    div {
      display: flex;
      justify-content: center;
      width: 20%;
      font-style: Medium;
      font-size: 18px;
      line-height: 24px;
      line-height: 114%;
      align: center;
      vertical-align: top;
      paragraph-spacing: 32px;
    }
    div:hover {
      color: #42a5f5;
    }
  }
  hr {
    margin-left: 19px;
    margin-right: 19px;
  }
  .requsitionButtonDiv {
    margin-top: 22px;
    display: flex;
    justify-content: center;
  }
`;
