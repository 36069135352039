import React, { useState, useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dashboard from "./Dashboard";
import Welcome from "../components/Welcome/Welcome";
import Networks from "./Networks";
import TradeOver from "./TradeOver";
import { ToastContainer } from "react-toastify";
import Documents from "./Documents";
import { Error, Success } from "components/notifications/responseHandle";
import GlobalStyle from "../global-styles";
import Sidebar from "../components/Sidebar";
import Login from "./auth/login/login";
import ForgotPassword from "./auth/forgotpassword/forgotPassword";
import ForgotPasswordOTP from "./auth/forgotpasswordotp/forgotPasswordOTP";
import NewPassword from "./auth/newpassword/newPassword";
import Signup from "./auth/signup/signup";
import VerifyPhone from "./auth/verifyphone/verifyPhone";
import VerifyPhoneOtp from "./auth/verifyphoneotp/verifyPhoneOtp";
import VerifyEmail from "./auth/verifyemail/verifyEmail";
import VerifyEmailOtp from "./auth/verifyemailotp/verifyEmailOtp";
import Settings from "./settings";
import Help from "./help";
import SyndicateLending from "./syndicatelending/index";
import Profile from "./profile";
import About from "./about";
import Contact from "./contactus";
import DocumentDetails from "./Document/documentDetails/documents";
import MisTrade from "./mis/misTrade";
import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
  useMsalAuthentication,
} from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import ChatApp from "./chatapp/chatApp";
import UploadFile from "components/Upload/file_upload";
import Finance from "./Document/finance";
import FinanceDashBoard from "./Invoicefinancing/finance";
import OpenTradeAccount from "./Trade/OpenTradeAccount";
import NewOpenTradeAccount from "./OpenTradeAccount/index";
import { Invite } from "../components/inviteMember/invite";
import Services from "./Services";
import TradeWorkflow from "./TradeOver/tradeWorkflow";
import { loginRequest } from "../authConfig";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import NewPayment from "./NewPayment/index";
import Alert from "./Alert/index";
import Financing from "./financing";
import { withSendBird, SendBirdState } from "sendbird-uikit";
import { AuthContext } from "contexts/Auth/AuthContext";
var sdk = null;

const mapStoreToProps = (store: SendBirdState) => {
  const {
    stores: { sdkStore, userStore },
    config: {
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub,
    },
  } = store;
  sdk = sdkStore.sdk;
};
function App() {
  const isAuthenticated = useIsAuthenticated();
  const { inProgress, instance, accounts } = useMsal();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenChat, setIsOpenChat] = useState(false);
  const [chatURL, setChatURL] = useState("");
  const { setAccountsData, setIsAuthenticated, setInstance } =
    useContext(AuthContext);

  useEffect(() => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      setTimeout(() => {
        if (accounts.length === 0) {
          instance.loginRedirect(loginRequest);
        }
      }, 500);
    }
  });

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuthenticated(isAuthenticated);
      setAccountsData(accounts);
      setInstance(instance);
    }
  }, [isAuthenticated, instance, accounts]);

  const toggle = () => setIsOpen(!isOpen);

  const toggleChat = (link, sidebar) => {
   
    if (link === "global") {
      setChatURL("");
      setIsOpenChat(!isOpenChat);
    }

    if (link === "header") {
      setChatURL("");
      setIsOpenChat(false);
    }

    if (!link) {
      setChatURL("");
      setIsOpenChat(!isOpenChat);
    }

    if (sidebar) {
      setIsOpenChat(false);
    }
  };

  const hanndleCraateGroup = (users, name, chaturl) => {
    setChatURL(chaturl);
    const selectedUsers = users;
    let params = new sdk.GroupChannelParams();
    params.addUserIds(selectedUsers);
    params.isDistinct = false;
    params.isEphemeral = false;
    params.isPublic = false;

    params.name = name;
    params.channelUrl = chaturl;
    sdk.GroupChannel.createChannel(params, (response, error) => {
      const swapParams = sdk.getErrorFirstCallback();
      let groupChannel = response;
      let err = error;
      if (swapParams) {
        groupChannel = error;
        err = response;
      }
      if (err) {
        if (err?.message == '"channel_url" violates unique constraint.') {
          // Success("Opening Existing Chat session");
          setIsOpenChat(true);
        } else {
          Error(err.message);
          setIsOpenChat(true);
        }
      } else {
        setIsOpenChat(true);
      }
    });
  };

  return (
    <AppWrapper>
      <Helmet titleTemplate="%s - TradXLink" defaultTitle="TradXLink">
        <meta name="description" content="A TradXLink" />
      </Helmet>
      <BrowserRouter>
        <AuthenticatedTemplate>
          <Header
            hanndleCraateGroup={hanndleCraateGroup}
            toggle={toggle}
            isOpen={isOpen}
            toggleChat={toggleChat}
          />
          <div style={{ display: "flex" }}>
            <Sidebar isOpen={isOpen} toggleChat={toggleChat} />

            <Container
              style={isOpenChat ? { display: "none" } : { display: "block" }}>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/chat" element={<ChatApp />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/tradeover/*"
                  element={
                    <TradeOver hanndleCraateGroup={hanndleCraateGroup} />
                  }
                />
                <Route path="/login" element={<Login />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route
                  path="/forgotpassword-otp"
                  element={<ForgotPasswordOTP />}
                />
                <Route path="/newPassword" element={<NewPassword />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/verifyphone" element={<VerifyPhone />} />
                <Route path="/verifyphone-otp" element={<VerifyPhoneOtp />} />
                <Route path="/verifyemail" element={<VerifyEmail />} />
                <Route path="/verifyemail-otp" element={<VerifyEmailOtp />} />
                <Route
                  path="/financedashBoard/*"
                  element={<FinanceDashBoard />}
                />
                <Route
                  path="/financing/*"
                  element={
                    <Financing hanndleCraateGroup={hanndleCraateGroup} />
                  }
                />
                <Route
                  exact
                  path="/networks/*"
                  element={
                    <Networks
                      hanndleCraateGroup={hanndleCraateGroup}
                      toggle={toggle}
                      isOpen={isOpen}
                      toggleChat={toggleChat}
                    />
                  }
                />
                <Route exact path="/documents/*" element={<Documents />} />

                <Route
                  path="/profile/*"
                  element={
                    <Profile
                      hanndleCraateGroup={hanndleCraateGroup}
                      toggle={toggle}
                      isOpen={isOpen}
                      toggleChat={toggleChat}
                    />
                  }
                />
                <Route exact path="/help" element={<Help />} />
                <Route exact path="/settings" element={<Settings />} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/contactus" element={<Contact />} />
                <Route
                  path="/documents/preview/:id"
                  element={<DocumentDetails />}
                />
                <Route path="/mistrade" element={<MisTrade />} />
                <Route exact path="/payment/*" element={<NewPayment />} />
                <Route
                  exact
                  path="/documents/upload"
                  element={<UploadFile />}
                />
                <Route
                  exact
                  path="/syndicate_lending"
                  element={<SyndicateLending />}
                />
                <Route
                  path="/trade/openaccount"
                  element={<OpenTradeAccount />}
                />
                <Route
                  path="/trade-account/*"
                  element={
                    <NewOpenTradeAccount
                      hanndleCraateGroup={hanndleCraateGroup}
                    />
                  }
                />
                <Route
                  path="/invite/"
                  element={<Invite toggleChat={toggleChat} />}
                />
                <Route
                  path="/services/*"
                  element={
                    <Services
                      hanndleCraateGroup={hanndleCraateGroup}
                      toggle={toggle}
                      isOpen={isOpen}
                      toggleChat={toggleChat}
                    />
                  }
                />
                <Route path="/trade/new" element={<TradeWorkflow />} />
                {isAuthenticated && (
                  <Route
                    path="/alert"
                    element={<Alert toggleChat={toggleChat} />}
                  />
                )}
              </Routes>

            </Container>
            {isOpenChat === true && (
              <ChatApp
                toggleChat={toggleChat}
                isOpenChat={isOpenChat}
                isOpen={isOpen}
                hanndleCraateGroup={hanndleCraateGroup}
                chatURL={chatURL}
              />
            )}
          </div>
          <Footer />
        </AuthenticatedTemplate>
      </BrowserRouter>
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AppWrapper>
  );
}
export default withSendBird(App, mapStoreToProps);

const AppWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  min-height: 100%;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 0% 0.5% 0.5% 0.5%;
  flex: 1;
  font-size: 18px;
`;
