import React , {useContext} from 'react'
import styled from "styled-components";
import styles from '../../invoice.module.css';
import {invoiceFinanceContext} from '../../../../contexts/invoiceFinance/invoiceFinanceContext'

const GeneralDetails = () => {
    const {SavedInvoiceData} = useContext(invoiceFinanceContext);
    const Response = [
        {
            name: 'Invoice Reference Number',
            value: SavedInvoiceData.invoiceNumber ? SavedInvoiceData.invoiceNumber : "Not Provided"
        },
        {
            name: 'Tax Schema',
            value: 'PENDING'
        },
        {
            name: 'Supply Types',
            value: '21-Jan-2022'
        },
        {
            name: 'Document Types code',
            value: 'PENDING'
        },
        {
            name: 'Document Number',
            value: 'RS. 12M'
        },
        {
            name: 'Date',
            value: 'Invoice Docs uploaded'
        },
        {
            name: 'Taxable Value Total',
            value: '100 T Wheat'
        },
        {
            name: 'Total Invoice Value',
            value: 'INV-0022234654'
        },    
    ]

    return (
        <div className={styles.summary}>
            { Response && Response.map((data, i) => (
            <div className={styles.summaryWrapper} key={i}>
                <div> <span> {data.name} </span> </div>
                <div> <span style={addBlue}> {data.value} </span> </div>
            </div> 
            ))}
        </div>
    )
}

export default GeneralDetails;

const addBlue = {
    color: "#42A5F5"
}


