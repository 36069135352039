import React, { useState, useEffectS} from 'react'
import { Button, Card } from '@mui/material';
import styles from './syndicate.module.css'
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import TrancheDetails from "./TrancheDetails.js";
import { useEffect } from 'react';

function LoanStructDetail({data}) {
    const trancheList = ["Tranche-1"]

    const [type, setType] = useState(false);

    const handleType = () => {
        setType(!type);
    }
    useEffect(() => {
        console.log("data", data)
    }, [data])
    const {handleSandToFinancer} = data.props; 
 console.log("FIM", data)
    return (
        <Card style={{ backgroundColor: "#1E1E1E", padding: "15px" }}>
            {trancheList.map((d, i) => {
                return (
                    <>
                        <button type="button" className={styles.collapsible} onClick={() => handleType()} style={{ background: "#A5A5A5" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <b style={{ fontSize: "20px", fontWeight: "700", color: "white" }}>
                                    {d}
                                </b>
                                {type ? <FiChevronUp size={25} color="#42A5F5" /> : <FiChevronDown size={25} />}
                            </div>
                        </button>
                        {type && <TrancheDetails loanstructure={true} />}
                        <br></br>
                        <div style={{ minHeight: "100px" }}></div>
                        {!type && <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Button
                                variant="contained"
                                style={{ minWidth: "200px", margin: "0px 20px", color: "#42A5F5", background: "#343434" }}
                                onClick={() => console.log("add another")}
                            >
                                Back to Loan Structure
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "200px", margin: "0px 20px" }}
                                onClick={handleSandToFinancer}
                            >
                                Send to Co-financers
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "200px", margin: "0px 20px" }}
                                onClick={() => console.log("add another")}
                            >
                                Add Trache
                            </Button>
                        </div>}
                    </>
                )
            })
            }
        </Card>
    )
}

export default LoanStructDetail