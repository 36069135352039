import React, { useState, useEffect } from "react";
import classes from "../../Table/GlobalTable.module.css";
import { FindCompanyName, truncateStringID } from "../../../../utils/helper";
import { GoMail } from "react-icons/go";
import { RiWechatLine } from "react-icons/ri";
import { BsBell } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

const ServiceDeal = ({ header, data, companyList, hanndleCraateGroup, chatData }) => {
  const navigate = useNavigate();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const handleNavigate = (id) => {
    navigate(`../service-deals/${id}`, {
      state: { data: data, companyList: companyList },
    });
  };
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const fetchData = async (companyId, id2, name) => {
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        hanndleCraateGroup(nData, name, name);
        setMemberList(nData);
      });
  };

  const chatHandler = (data) => {
    if (data?.consumerMemberUID !== "" && data?.providerMemberUID !== "") {
      setItem(data);
      let name = data.serviceDealId
        ? truncateStringID(data.serviceDealId, 7, "SD")
        : "NA";
      fetchData(data?.consumerMemberUID, data?.providerMemberUID, name);
    }
  };

  return (
    <div>
      <>
        <div className={classes["invoice-tableHeader"]}>
          {header &&
            header.map((list) => (
              <div key={list.name} className={classes[`${list.flex}`]}>
                {list.name}
              </div>
            ))}
        </div>

        {data !== null &&
          companyList !== null &&
          data.map((list) => (
            <>
              {list.providerMemberUID === companyId && (
                <div className={classes["invoice-tablebody"]}>

                  <div className={classes["flex-1"]}
                    style={{ color: "rgb(66, 165, 245)", textDecorationLine: "underline" }}
                    onClick={() => handleNavigate(list.serviceDealId)}>
                    <span>
                      <BsBell style={{ marginRight: "5px" }} />
                    </span>
                    {list.serviceDealId
                      ? truncateStringID(list.serviceDealId, 7, "SD")
                      : "NA"}</div>
                  <div
                    className={classes["flex-1"]}>

                    {FindCompanyName(companyList, list.consumerMemberUID)}
                  </div>

                  {/* <div
                    className={classes["flex-1"]}
                    onClick={() => handleNavigate(list.serviceDealId)}>
                    {list.providerMemberUID === 1 ? (FindCompanyName(companyList, list.providerMemberUID)) : (<></>)}
                  </div> */}
                  <div className={classes["flex-1"]}>
                    {FindCompanyName(companyList, list.providerMemberUID)}
                  </div>

                  <div className={classes["flex-1"]}>
                    {list.servicePriceOffer}{" "}{list.servicePaymentCurrency}
                  </div>
                  <div className={classes["flex-1"]}>
                    {list.serviceDealStatus}
                  </div>
                  <div className={classes["flex-1"]}>
                    {new Date(list.lastUpdatedDate).toLocaleDateString()}
                  </div>
                  <div className={classes["flex-1"]} >
                    <GoMail style={{ marginRight: "15px", cursor: "pointer" }} size="22" />
                    <span onClick={() => chatHandler(list)}
                      style={{ cursor: "pointer" }}>
                      <RiWechatLine size="22" />
                    </span>
                  </div>
                </div>)}

              {list.consumerMemberUID === companyId && (<div className={classes["invoice-tablebody"]}>
                <div className={classes["flex-1"]}
                  style={{ color: "rgb(66, 165, 245)", textDecorationLine: "underline" }}
                  onClick={() => handleNavigate(list.serviceDealId)}>
                  <span>
                    <BsBell style={{ marginRight: "5px" }} />
                  </span>
                  {list.serviceDealId
                    ? truncateStringID(list.serviceDealId, 7, "SD")
                    : "NA"}</div>
                <div
                  className={classes["flex-1"]}>
                  {FindCompanyName(companyList, list.consumerMemberUID)}
                </div>

                <div
                  className={classes["flex-1"]}
                  onClick={() => handleNavigate(list.serviceDealId)}>
                  {(FindCompanyName(companyList, list.providerMemberUID))}
                </div>

                <div className={classes["flex-1"]}>
                  {list.servicePriceOffer}{" "}{list.servicePaymentCurrency}
                </div>
                <div className={classes["flex-1"]}>
                  {list.serviceDealStatus}
                </div>
                <div className={classes["flex-1"]}>
                  {new Date(list.lastUpdatedDate).toLocaleDateString()}
                </div>
                <div className={classes["flex-1"]} style={{ cursor: "pointer" }}>
                  <GoMail style={{ marginRight: "15px" }} size="22" />
                  <OverlayTrigger
                   trigger={["hover", "focus"]}
                   key="top"
                   placement="top"
                   overlay={
                    <Popover id="popover-positioned-top">
                       <Popover.Body>
                        <strong>Chat</strong>
                       </Popover.Body>
                    </Popover>
                  }>
                 <span onClick={() => chatHandler(list)}>
                    <RiWechatLine size="22" />
                  </span>
                </OverlayTrigger>
                  {/* <span onClick={() => chatHandler(list)}>
                    <RiWechatLine size="22" />
                  </span> */}
                </div>
              </div>)
              }
            </>
          ))}
      </>
    </div>
  );
};

export default ServiceDeal;
