import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import { onBoardingURL } from "services/BaseURLInstance";
import DocumentGreenIcon from "components/Icons/green_icon";
import Loader from "components/Loader/Loader";
import { BsFillCheckCircleFill } from "react-icons/bs";
import styles from "../companyDetails.module.css";
import { UseRequest } from "utils/API/Request.js";

const PerformanceCard = () => {
  return (
    <div>
      <div className={styles.performanceCard} style={{margin:"1% 0%" , padding:"1% 2%"}}>
        <div className={styles.performanceHead}>
          <div className={styles.title1}>
            <span className={styles.iconDIv} style={{ fontSize: "16px", fontWeight: "bold" }}>Business Performance Card</span>
            <DocumentGreenIcon />
          </div>
          <div className={styles.title2}>Business Rating Scale Icon</div>
        </div>

        <div className={styles.performanceBody}>
          <div className={styles.performanceBody1}>
            <div className={styles.performanceValue}>
              <div>
                <div className={styles.title2}>No. of Completed Trades</div>
                <div className={styles.title3}>
                  Total trades performed by member
                </div>
              </div>
              <div className={styles.colorBlue}>45</div>
            </div>

            <div className={styles.performanceValue}>
              <div>
                <div className={styles.title2}>Total Trade Value</div>
                <div className={styles.title3}>
                  Total trades performed by member
                </div>
              </div>
              <span className={styles.colorBlue}>$ 45M</span>
            </div>

            <div className={styles.performanceValue}>
              <div>
                <div className={styles.title2}>Total Asset Value</div>
                <div className={styles.title3}>
                  Value of all assets on platform
                </div>
                <div className={styles.title3}>i.e. invoices, PO etc.</div>
              </div>
              <span className={styles.colorBlue}>$ 45M</span>
            </div>

            <div className={styles.performanceValue}>
              <div>
                <div className={styles.title2}>Total Financing</div>
                <div className={styles.title3}>Financing from the network</div>
              </div>
              <div className={styles.colorBlue}>$ 45M</div>
            </div>

            <div className={styles.linkMember}>Network Member link</div>
          </div>

          <div className={styles.performanceBody2}>
            <div className={styles.performanceTop1}>
              <div>
                <div className={styles.title2}>Total Network Partners</div>
                <div className={styles.title3}>Number of transactions</div>
              </div>

              <div className={styles.trade}>
                <div className={styles.title4}>Leo Trader</div>
                <div className={styles.colorBlue}>30</div>
              </div>

              <div className={styles.trade}>
                <div className={styles.title4}>Leo Trader</div>
                <div className={styles.colorBlue}>30</div>
              </div>

              <div className={styles.trade}>
                <div className={styles.title4}>Leo Trader</div>
                <div className={styles.colorBlue}>30</div>
              </div>
            </div>

            <div className={styles.performanceTop1}>
              <div>
                <div className={styles.title2}>Total Financer</div>
                {/* <div className={styles.title3}>Number of transactions</div> */}
              </div>

              <div className={styles.trade}>
                <div className={styles.title4}>Axis Bank</div>
                <div className={styles.colorBlue}>$ 3M</div>
              </div>

              <div className={styles.trade}>
                <div className={styles.title4}>Citi Bank</div>
                <div className={styles.colorBlue}>$ 2M</div>
              </div>

              <div className={styles.trade}>
                <div className={styles.title4}>HSBC</div>
                <div className={styles.colorBlue}>$ 1.5M</div>
              </div>
            </div>

            <div className={styles.performanceTop2}>
              <div className={styles.title2}>Top Trade Countries</div>
              <button>USA</button>
              <button>INDIA</button>
              <button>GERMANY</button>
            </div>

            <div className={styles.performanceTop2}>
              <div className={styles.title2}>Top Trade Countries</div>
              <button>USA</button>
              <button>INDIA</button>
              <button>GERMANY</button>
            </div>

            <div className={styles.performanceFooter}>
              Business performance card is an inductive information derived for
              the transactions with partner on platforms
            </div>
            <div style={{display:"flex", justifyContent:"end", color:"yellow",fontSize:"12px"}}>* Sample Data for representation purpose</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerformanceCard;
