const Document = [
  {
    name: "TRADE_CONTRACT",
    sub_category: ["TRADE_CONTRACT-DRAFT", "TRADE_CONTRACT-SIGNED","OTHERS"],
  },
  {
    name: "TRADE_COMPLIANCE",
    sub_category: ["TRADE_COMPLIANCE_REPORT","FINANCIAL_COMPLIANCE_REPORT","IMPORT_DECLARATION_COMPLIANCE_REPORT"],
  }, 
  {
    name: "PROCUREMENT",
    sub_category: ["PURCHASE_T&C","MoU","PURCHASE_REQUISITION","GOODS_CATALOGUE","GOODS_SAMPLE"],
  },
  {
    name: "COMPANY_PROFILE",
    sub_category: ["COMPANY_PROFILE"],
  },
  {
    name: "ONBOARDING",
    sub_category: ["MEMBER_DUE_DILIGENCE_REPORT"],
  },
  {
    name: "SALES_ORDER",
    sub_category: [
      "PURCHASE_ORDER",
      "SALES_ORDER",
      "RECONCILIATION_REPORT",
      "PROFORMA_INVOICE",
      "INVOICE",
      "FINAL_INVOICE",
    ],
  },
  {
    name: "FINANCE_REQUISITION",
    sub_category: [
      "FINANCE_REQUISITION",
      "FINANCE_DEAL",
    ],
  },
  {
    name: "KYB",
    sub_category: [
      "KYB_ASSESSMENT",
      "CREDIT_ASSESSMENT",
      "COMPANY_BACKGROUND_CHECK",
      "FINANCE_DUE_DILIGENCE",
      "COMPANY_DUE_DILIGENCE",
    ],
  },
  {
    name: "PRE-SALES",
    sub_category: [
      "RFI",
      "RFQ",
    ],
  },
  {
    name: "FINANCE_DUE_DILIGENCE",
    sub_category: ["COMPANY_BACKGROUND_CHECK", "CREDIT_ASSESSMENT","FINANCE_DUE_DILIGENCE"],
  },
  // {
  //   name: "LOAN_STRUCTURE",
  //   sub_category: ["LOAN_STRUCTURE"],
  // },
  {
    name: "FINANCING_AGREEMENT",
    sub_category: ["FINANCING_AGREEMENT(T&C)","LOAN_STRUCTURE"],
  },
  {
    name: "CREDIT_INSURANCE",
    sub_category: ["CREDIT_INSURANCE_CERTIFICATE"],
  },
  {
    name: "DISBURSEMENTS_AND_SETTLEMENTS",
    sub_category: ["PAYMENT_ACKNOWLEDGEMENT","SWIFT_COPY","BANK_RECEIPT","TRANSACTION_DEBIT_ADVICE"],
  },
  {
    name: "MANUFACTURING",
    sub_category: ["PRODUCT_DESIGN_PROTOTYPE","PRODUCT_DESIGN_FINAL","BILL_OF_MATERIAL","GOODS_PICKUP_RECEIPT"],
  },
  {
    name: "DELIVERY_PLANNING",
    sub_category: [
      "FREIGHT_FORWARDER_QUOTE",
      "ADVANCE_SHIPPING_NOTICE",
      "DELIVERY_DOCUMENT",
      "INCOTERM",
      "SHIPPING_BILL",
    ],
  },
  {
    name: "PACKING",
    sub_category: ["PACKING_LIST", "FINAL_PACKING_IMAGE"],
  },
  {
    name: "TRANSPORT(EXPORT)",
    sub_category: [
      "FREIGHT_CARRIERS",
      "CERTIFICATE_OF_ORIGIN",
      "PACKING_LIST",
      "FINAL_INVOICE",
      "FRIEGHT_PLAN_INFO",
      "TRANSPORT_ORDER",
      "CARGO_DECLARATIONS",
      "WAREHOUSE_RECEIPT",
      "BILL_OF_LADING",
      "AIRWAY_BILL",
      "DG_OR_NON_DG_CERTIFICATE",
      "SHIPPING_BILL",
    ],
  },
  {
    name: "GOODS_INSURANCE",
    sub_category: ["GOODS_INSURANCE_CERTIFICATE"],
  },
  {
    name: "CUSTOMS(EXPORT)",
    sub_category: [
      "TARIFF_PAYMENT",
      "BILL_OF_EXCHANGE",
      "EXPORT_GENERAL_MANIFEST",
      "EXPORT_DECLARATION",
      "CUSTOM_CLEARANCE",
      "INSPECTION_CERTIFICATE",
    ],
  },
  {
    name: "TERMINAL(EXPORT)",
    sub_category: [
      "EXPORT_DECLARATION",
      "GOODS_INFO",
      "TERMINAL_WAREHOUSE_DOCUMENT",
      "BILL_OF_LADING"],
  },
  {
    name: "SHIPPING_ORDER",
    sub_category: ["SHIPPING_BILL"],
  },
  {
    name: "SCHEDULING_AGREEMENT",
    sub_category: ["SHIPPING_SCHEDULE"],
  },
  {
    name: "PLAN_CONTAINER",
    sub_category: ["ETA_OF_CONTAINER","ETD_OF_SHIP"],
  },
  // {
  //   name: "Plan Container ETD",
  //   sub_category: ["ETD of ship"],
  // },
  {
    name: "SUMMARY_DECLARATION",
    sub_category: ["SUMMARY_DECLARATION_WITH_B/L"],
  },
  {
    name: "SHIPPING_DELIVERY",
    sub_category: ["DELIVERY_DOCUMENT", "TELEX_B/L"], 
  },
  {
    name: "TERMINAL(IMPORT)",
    sub_category: [
      "IMPORT_DECLARATION",
      "GOODS_INFO",
      "TERMINAL_WAREHOUSE_DOCUMENT",
    ],
  },
  {
    name: "CUSTOMS(IMPORT)",
    sub_category: [
      "INSPECTION_CERTIFICATE",
      "CUSTOM_DECLARATIONS",
      "CUSTOM_CLEARANCE",
      "TARIFF_PAYMENT",
    ],
  },
  {
    name: "INSPECTION",
    sub_category: [
      "SAMPLING_DETAILS",
      "INSPECTION_CERTIFICATE",
      "INSPECTION_REPORTS",
    ],
  },
  {
    name: "TRANSPORT(IMPORT)",
    sub_category: ["TRANSPORT_RECEIPT","PACKING_LIST","FINAL_INVOICE"],
  },
  {
    name: "WAREHOUSE_STORAGE",
    sub_category: ["WAREHOUSE_STORAGE_INVOICE", "GOODS_RECEIPT_ACKNOWLEDGEMENT","WAREHOUSE_RECEIPT","INSPECTION_REPORT"],
  },
  {
    name: "PAYMENT_SETTLEMENT",
    sub_category: ["PAYMENT_ACKNOWLEDGEMENT","SWIFT_COPY","BANK_RECEIPT","TRANSACTION_DEBIT_ADVICE","ACCOUNT_STATEMENT"],
  },
  {
    name: "POST_SETTLEMENT",
    sub_category: ["CREDIT/DEBIT_NOTE"],
  },
  {
    name: "PAYMENTS",
    sub_category: ["PAYMENT_RECEIPT_DOCUMENT"],
  },
  {
    name: "SAMPLING_GOODS",
    sub_category: ["SAMPLE_GOODS_IMAGE"],
  },
  {
    name: "OTHERS",
    sub_category: ["OTHERS"],
  },
];

export const DocumentCatogery = () => {
  let category = Document.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  return category;
};

export const DocumentSubCatogery = (name) => {
  let category = Document.filter((item) => {
    if (item.name === name) {
      return item;
    }
  });
  let temp =
    category &&
    category[0] &&
    category[0].sub_category.map((item) => ({
      label: item,
      value: item,
    }));

  return temp;
};

export const DocumentSelect = (name) => {
  let Category = Document.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const Empty = [];
  let temp = Document.filter((item) => {
    if (item.name === name) {
      return item;
    }
  });
  let SubCategory =
    temp &&
    temp[0] &&
    temp[0].sub_category.map((item) => ({
      label: item,
      value: item,
    }));

  if (name !== null) {
    return { Category, SubCategory };
  } else {
    return { Category, Empty };
  }
};
