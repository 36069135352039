import React, { useEffect, useState, useContext } from "react";
import { BsCircleFill } from "react-icons/bs";
import Style from "./styled";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { ButtonStyled, Wrapper } from "../../components/Container.styled";
import { misFinanceApi } from "../../services/BaseURLInstance";
import { misURL } from "../../services/BaseURLInstance";
import { storeContext } from "../../contexts/Store/Store";
import Select from "../../components/fields/Select";
import { useMsal } from "@azure/msal-react";
import { getClaim } from "utils/authUtil";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  plugins: {
    title: {
      display: true,
      text: "Finance Deals Chart",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    y: {
      grid: {
        color: "grey",
      },
      ticks: {
        precision: 0,
      },
      title: {
        font: {
          size: 15,
        },
        color: "white",
        display: true,
        text: "Number Of Finance Deals",
      },
    },

    x: {
      grid: {
        color: "grey",
      },
      title: {
        font: {
          size: 15,
        },
        color: "white",
        display: true,
        text: "Timeline",
      },
    },
    xAxes: [{ display: false }],
    display: false,
    xAxes: [
      {
        gridLines: {
          color: "white",
          display: false,
        },
      },
    ],
  },
};
const option2 = {
  plugins: {
    title: {
      display: true,
      text: "Finance Deals Amount Chart",
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    y: {
      grid: {
        color: "grey",
      },
      ticks: {
        precision: 0,
      },
      title: {
        font: {
          size: 15,
        },
        color: "white",
        display: true,
        text: "Finance Deals Amount",
      },
    },
    x: {
      grid: {
        color: "grey",
      },
      title: {
        font: {
          size: 15,
        },
        color: "white",
        display: true,
        text: "Timeline",
      },
    },
    xAxes: [{ display: false }],
    display: false,
    xAxes: [
      {
        gridLines: {
          display: false,
        },
      },
    ],
  },
};
const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default () => {
  const { fetchCompanyList } = useContext(storeContext);
  const [percentage, setPercentage] = useState();
  const [years, setYears] = useState([
    { label: new Date().getFullYear(), value: new Date().getFullYear() },
  ]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [barData, setBarData] = useState();
  const [secondBarData, setSecondBarData] = useState();

  const { accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const [response, setResponse] = useState();

  // const [respons, setRespons] = useState({
  //   financeStatusMetrics: {
  //     financingDealsClosed: 12,
  //     financingDealsClosedAmount: 332,
  //     financingDealsReceived: 22,
  //     financingDealsReceivedAmount: 242,
  //     financingDealsRejected: 24,
  //     financingDealsRejectedAmount: 433,
  //     financingDisbursements: 32,
  //     financingDisbursementsAmount: 564,
  //     financingRePayments: 40,
  //     financingRePaymentsAmount: 214,
  //     financingRequests: 19,
  //     financingRequestsAmount: 340,
  //     financingUnderReview: 31,
  //     financingUnderReviewAmount: 435,
  //   },
  //   financeTimeSeries: [
  //     {
  //       year: 2022,
  //       month: "Jan",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 12,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 14,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 16,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 18,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 20,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 22,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 24,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //     {
  //       year: 2022,
  //       month: "Feb",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 1,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 2,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 3,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 4,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 5,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 6,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 7,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //     {
  //       year: 2022,
  //       month: "Mar",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 21,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 22,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 23,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 24,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 25,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 26,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 27,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //     {
  //       year: 2021,
  //       month: "Jan",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 2,
  //         financingDealsClosedAmount: 121,
  //         financingDealsReceived: 3,
  //         financingDealsReceivedAmount: 103,
  //         financingDealsRejected: 4,
  //         financingDealsRejectedAmount: 401,
  //         financingDisbursements: 5,
  //         financingDisbursementsAmount: 501,
  //         financingRePayments: 6,
  //         financingRePaymentsAmount: 623,
  //         financingRequests: 7,
  //         financingRequestsAmount: 709,
  //         financingUnderReview: 8,
  //         financingUnderReviewAmount: 899,
  //       },
  //     },
  //     {
  //       year: 2021,
  //       month: "Feb",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 12,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 14,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 16,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 18,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 20,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 22,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 24,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //     {
  //       year: 2021,
  //       month: "Mar",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 12,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 14,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 16,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 18,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 20,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 22,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 24,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //     {
  //       year: 2020,
  //       month: "Jan",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 12,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 14,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 16,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 18,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 20,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 22,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 24,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //     {
  //       year: 2020,
  //       month: "Feb",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 12,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 14,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 16,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 18,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 20,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 22,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 24,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //     {
  //       year: 2020,
  //       month: "Mar",
  //       financeMetricsForMonth: {
  //         financingDealsClosed: 12,
  //         financingDealsClosedAmount: 2121,
  //         financingDealsReceived: 14,
  //         financingDealsReceivedAmount: 3011,
  //         financingDealsRejected: 16,
  //         financingDealsRejectedAmount: 4011,
  //         financingDisbursements: 18,
  //         financingDisbursementsAmount: 5012,
  //         financingRePayments: 20,
  //         financingRePaymentsAmount: 6232,
  //         financingRequests: 22,
  //         financingRequestsAmount: 7090,
  //         financingUnderReview: 24,
  //         financingUnderReviewAmount: 8990,
  //       },
  //     },
  //   ],
  //   totalAssetValue: 70757,
  //   totalFinancingApproved: 4,
  //   totalFinancingRequested: 40,
  // });

  let graphData = {
    labels,
    datasets: [
      {
        label: "Financing Deals Closed",
        data: [],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "Financing Deals Received",
        data: [],
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Financing Deals Rejected",
        data: [],
        backgroundColor: "rgb(255, 245, 222)",
      },
      {
        label: "Financing Requests",
        data: [],
        backgroundColor: "rgb(255, 234, 111)",
      },
      {
        label: "Financing Under Review",
        data: [],
        backgroundColor: "rgb(155, 534, 111)",
      },
    ],
  };
  let graphDataSecond = {
    labels,
    datasets: [
      {
        label: "Financing Deals Closed Amount",
        data: [],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "Financing Deals Received Amount",
        data: [],
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Financing Deals Rejected Amount",
        data: [],
        backgroundColor: "rgb(255, 245, 222)",
      },
      {
        label: "Financing Requests Amount",
        data: [],
        backgroundColor: "rgb(255, 234, 111)",
      },
      {
        label: "financing Under Review Amount",
        data: [],
        backgroundColor: "rgb(155, 534, 111)",
      },
    ],
  };

  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };

    const memberType = JSON.parse(sessionStorage.getItem("memberType"));

    if (memberType === "TRADER") {
      let URL = `${misURL}finance/finance/mis/dashboard/summary?traderMemberUID=${companyId}`;
      //  let URL = `${misURL}invoices/invoices/mis/summary?traderMemberUID=${companyId}`;
      fetchCompanyList(URL, idToken)
        .then((data) => {
          setResponse(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      let URL = `${misURL}finance/finance/mis/dashboard/summary?financerMemberUID=${companyId}`;
      fetchCompanyList(URL, idToken)
        .then((data) => {
          setResponse(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // let URL = `${misURL}finance/finance/mis/dashboard/summary?financerMemberUID=${companyId}`;
    // fetchCompanyList(URL, idToken)
    //   .then((data) => {
    //     setResponse(data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    if (response) {
      let temp =
        (response.totalFinancingApproved * 100) /
        response.totalFinancingRequested;
      setPercentage(temp);
    }
  }, []);

  useEffect(() => {
    if (response) {
      let temp =
        (response.totalFinancingApproved * 100) /
        response.totalFinancingRequested;

      const sanitizedValue = isNaN(temp) ? 0 : temp;

      setPercentage(sanitizedValue);
    }
    if (response) {
      const temp = [
        ...new Set(
          response.financeTimeSeries.map((item) => {
            return item.year;
          })
        ),
      ];
      const newArray = temp.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      setYears(newArray);
    }
  }, [response]);

  useEffect(() => {
    if (response) {
      graphData.datasets.forEach((item) => {
        item.data = [];
      });
      graphDataSecond.datasets.forEach((item) => {
        item.data = [];
      });
      const temp = [];
      response.financeTimeSeries.forEach((item) => {
        if (item.year === currentYear) {
          temp.push(item.financeMetricsForMonth);
        }
      });
      temp.forEach((item) => {
        delete item.financingDisbursements;
        delete item.financingDisbursementsAmount;
        delete item.financingRePayments;
        delete item.financingRePaymentsAmount;
      });
      temp.forEach((item) => {
        const tempItemList = Object.keys(item);
        let f = 0;
        for (let i = 0; i < tempItemList.length; i = i + 2) {
          graphData.datasets[f++].data.push(item[tempItemList[i]]);
        }
        let l = 0;
        for (let i = 1; i < tempItemList.length; i = i + 2) {
          graphDataSecond.datasets[l++].data.push(item[tempItemList[i]]);
        }
      });
      setBarData(graphData);
      setSecondBarData(graphDataSecond);
    }
  }, [response]);

  const handleChange = (e) => {
    setCurrentYear(e.target.value);
  };
  return (
    <Style>
      {response && (
        <div>
          <div className="cardWrapper">
            <div
              // className="heading"
              style={{ fontSize: "20px", marginLeft: "2%" }}
            >
              <div>Financing Overview</div>
              {/* <div className="btn">
                <ButtonStyled style={{ width: "200px" }}>
                  +Finance Deal
                </ButtonStyled>
              </div> */}
            </div>
            <div
              style={{
                border: "1px solid transparent",
                margin: "2%",
                background: "rgba(25, 25, 25, 25)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "1%",
                  marginRight: "1%",
                  marginTop: "40px",
                }}
              >
                <div style={{ width: "50%", textAlign: "left" }}>
                  <span style={{ fontSize: "20px" }}>
                    {" "}
                    Total Financing Requested{" "}
                    <span style={{ color: "#42a5f5" }}>
                      {" "}
                      {response && response.totalFinancingRequested}
                    </span>
                  </span>
                </div>
                <div style={{ width: "50%", textAlign: "right" }}>
                  <span style={{ fontSize: "20px" }}>
                    {" "}
                    Total Financing{" "}
                    <span style={{ color: "#42a5f5" }}>
                      {" "}
                      {response && response.totalFinancingApproved}
                    </span>
                  </span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "60px",
                }}
              >
                <div
                  style={{
                    width: "15%",
                    background: "rgb(5 15 62)",
                    borderRadius: "50%",
                  }}
                >
                  {percentage && (
                    <CircularProgressbar
                      height={40}
                      value={percentage}
                      text={`${percentage.toFixed(2)}%`}
                      styles={buildStyles({
                        textColor: "white",
                        background: "white",
                        pathColor: "#42A5F5",
                        trailColor: "white",
                      })}
                    />
                  )}
                </div>
              </div>
              <div className="iconDiv">
                <div className="item">
                  <div className="font">
                    {response &&
                      response.financeStatusMetrics.financingUnderReviewAmount}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>In-Review Amount</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response &&
                      response.financeStatusMetrics.financingDisbursements}
                  </div>

                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>Disbursements</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response &&
                      response.financeStatusMetrics.financingUnderReview}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>In-Review</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response &&
                      response.financeStatusMetrics.financingRePayments}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>Repayments</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response &&
                      response.financeStatusMetrics.financingDealsRejected}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>Rejected</div>
                </div>
              </div>
            </div>
            <div
              style={{
                border: "1px solid transparent",
                margin: "2%",
                background: "rgba(25, 25, 25, 25)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  marginTop: "50px",
                  width: "96.5%",
                  margin: "2%",
                  background: "rgba(255, 255, 255, 0.05)",
                  paddingLeft: "10px",
                  borderRadius: "8px",
                }}
              >
                <div style={{ width: "100%", marginLeft: "2%" }}>
                  <div style={{ display: "flex", padding: "2%" }}>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        fontSize: "20px",
                      }}
                    >
                      Financing Trend
                    </div>
                    <div
                      style={{
                        width: "48%",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <div style={{ width: "20%" }}>
                        <Select
                          placeholder="Year"
                          options={years}
                          name="year"
                          handleChange={handleChange}
                          value={currentYear}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "40px" }}>
                  {barData && (
                    <Bar height={80} options={options} data={barData} />
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "50px",
                  width: "96.5%",
                  margin: "2%",
                  background: "rgba(255, 255, 255, 0.05)",
                  paddingLeft: "10px",
                  borderRadius: "8px",
                }}
              >
                <div style={{ width: "100%", marginLeft: "2%" }}>
                  <div style={{ display: "flex", padding: "2%" }}>
                    <div
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Financing Trend Amount
                    </div>
                    <div
                      style={{
                        width: "48%",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <div style={{ width: "20%" }}>
                        <Select
                          placeholder="Year"
                          options={years}
                          name="year"
                          handleChange={handleChange}
                          value={currentYear}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: "40px" }}>
                  {barData && (
                    <Bar height={80} options={option2} data={secondBarData} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Style>
  );
};
