import React, { useState, useEffect } from "react";
import { FaHandshake, FaFileInvoice } from "react-icons/fa";
import { FaNetworkWired } from "react-icons/fa";
import { RiUserSettingsLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import styles from "./dashboard.module.css";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import { useParams } from "react-router-dom";
import cn from "classnames";
const Dashboard = () => {
  const navigate = useNavigate();
  const [selected1, setSelected1] = useState(true);
  const [selected2, setSelected2] = useState(true);
  const [hide, setHide] = useState("");
  let path = useParams();

  const handleNavigate = (e, value) => {
    navigate(`${value}`);
  };
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setHide(path["*"]);
    }
    return () => {
      isCancelled = true;
    };
  }, [path["*"]]);

  return (
    <>
      {hide === "" && (
        <>
          <div className={styles.lowerDiv} style={{ marginBottom: "2%" }}>
            <div
              className={styles.heading1}
              onClick={() => setSelected1(!selected1)}
            >
              <h5>Financing Solutions</h5>
              <span>
                {selected1 === true ? (
                  <CgChevronUp size={30} />
                ) : (
                  <CgChevronDown size={30} />
                )}
              </span>
            </div>

            {selected1 === true && (
              <div className={styles.flexRowJustify}>
                <div
                  className={styles.card}
                  onClick={(e) => handleNavigate(e, "invoice")}
                >
                  <span>
                    <FaFileInvoice size={40} color="#42A5F5" />
                  </span>
                  <div>Invoice Financing</div>
                </div>
                {/* <div
                  className={styles.card}
                  onClick={(e) => handleNavigate(e, "pofinancing")}
                >
                  <span>
                    <FaNetworkWired size={40} color="#42A5F5" />
                  </span>
                  <div>PO Financing</div>
                </div> */}
                <div
                  style={{ width: "20%" }}
                  // className={styles.card}
                  // onClick={(e) => handleNavigate(e, "payablesfinancing")}
                >
                  {/* <span>
                    <FaHandshake size={40} color="#42A5F5" />
                  </span>
                  <div>Payables Financing</div> */}
                </div>
                <div
                  style={{ width: "20%" }}
                  // className={styles.card}
                  // onClick={(e) => handleNavigate(e, "inventoryfinancing")}
                >
                  {/* <span>
                    <RiUserSettingsLine size={40} color="#42A5F5" />
                  </span>
                  <div>Inventory Financing</div> */}
                </div>
              </div>
            )}
          </div>

          {/* Second */}
          {memberType === "FINANCER" ? (
            <div></div>
          ) : (
            <div className={styles.lowerDiv}>
              <div
                className={styles.heading1}
                onClick={() => setSelected2(!selected2)}
              >
                <h5>Trade Solutions</h5>
                <span>
                  {selected2 === true ? (
                    <CgChevronUp size={30} />
                  ) : (
                    <CgChevronDown size={30} />
                  )}
                </span>
              </div>

              {selected2 === true && (
                <div className={styles.flexRowJustify}>
                  {memberType === "SERVICE_PROVIDER" ? (
                    ""
                  ) : (
                    <div
                      className={styles.card}
                      onClick={(e) => handleNavigate(e, "../trade-account")}
                    >
                      <span>
                        <FaFileInvoice size={40} color="#42A5F5" />
                      </span>
                      <div>Open Account Trade</div>
                    </div>
                  )}
                  <div
                    className={styles.card}
                    onClick={(e) => handleNavigate(e, "tracking")}
                  >
                    <span>
                      <FaFileInvoice size={40} color="#42A5F5" />
                    </span>
                    <div>Tracking</div>
                  </div>
                  <div style={{ width: "20%" }}></div>
                  <div style={{ width: "20%" }}></div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
