import React, { useState, useContext, useCallback, useEffect } from "react";
import cn from "classnames";
import classes from "../../invoice.module.css";
import Content from "components/UI/Layout/Content";
import { ToggleTab } from "./toggleTab.js";
// import ToogleTab from "components/UI/ToogleSelect/ToogleTab";
import { BsArrowLeft, BsBell } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import Summary from "./Summary";
import DealDetail from "./DealDetails";
import { UseRequest } from "utils/API/Request.js";
import Loader from "components/Loader/Loader";
import { useMsal } from "@azure/msal-react";
import { useParams, useNavigate } from "react-router-dom";
import { FindCompanyName } from "utils/helper";
import { getIdTokenAsync } from "utils/authUtil";
import {NetworkURL} from "services/BaseURLInstance";
import CustomizedSteppers from "components/UI/Stepper/Stepper";
import {
  FinanceDealFlow,
  FinanceDealFlowDecline,
} from "utils/Section/Workflow";
import { storeContext } from "contexts/Store/Store";
import { getClaim } from "utils/authUtil";
import { FinanceContext } from "../../financeProvider";

const Index = ({ hanndleCraateGroup }) => {
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [selected, setSelected] = useState(0);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const toggle = (i) => {
    setSelected(i);
  };

  const param = useParams();
  const [Data, setData] = useState(null);
  const {companyList} = useContext(FinanceContext);
  const [isError, setError] = useState(null);
  const { instance, accounts } = useMsal();
  const [requisitionData, setRequisitionData] = useState();
  const [companyData, setCompanyList] = useState(companyList);
  const companyId = getClaim(accounts, "company_member_id");

  useEffect(() => {
    let isCancelled = false;
    getIdTokenAsync(instance, accounts).then((response) => {
      let URL = `${process.env.REACT_APP_FINANCE_URL}finance/deals/${param.id}`;
      if (!isCancelled) {
        UseRequest(URL, null, response)
          .then((data) => {
            setData(data);
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
          });
      }
      return () => {
        isCancelled = true;
      };
    });
  }, []);

  useEffect(() => {
    if(companyList){
        let user = companyList.filter((item) => {
          if (item.memberUID === companyId) {
            return item.memberType;
          }
        });
        setCompanyList({
          ...companyList,
          companies: companyList,
          userType: user[0].memberType,
        });
      }
  }, [companyList]);

  useEffect(() => {
    if(Data){
    let isCancelled = false;
    let URL = `${process.env.REACT_APP_FINANCE_URL}finance/requisitions/${Data.refFinanceRequisitionId}`;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            setRequisitionData(data);
          })
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }
  }, [Data]);

  useEffect(() => {
    if (isError) {
      handleNavigate(`/financing/invoice/finance-deals`);
    }
  }, [isError]);

  const TabData = [
    {
      title: "Summary",
      description: (
        <Summary
          Data={Data}
          companyList={companyList}
          hanndleCraateGroup={hanndleCraateGroup}
        />
      ),
    },
    {
      title: "Deal Details",
      description: (
        <DealDetail
          Data={Data}
          hanndleCraateGroup={hanndleCraateGroup}
          companyList={companyData}
          requisitionData={requisitionData}
        />
      ),
    },
  ];

  // 1. New - "NEW",
  // 2. Interest Expressed "INTEREST_EXPRESSED",
  // 3. Due Diligence - "DUE_DILIGENCE_INITIATED","DUE_DILIGENCE_COMPLETED",
  // 4. Deal Quote "QUOTE_DECLINED"/"QUOTE_ACCEPTED", QUOTE_GENERATED
  // 5. Agreement - "PENDING_T_AND_C_ACCEPTANCE","FINANCE_AGREEMENT_COMPLETE",,"DECLINED"
  // 6. Finance Status - "FINANCED", "CLOSED"
  // 7. Aborted - "ABORTED",

  const StepperPosition = (status) => {
    switch (status) {
      case "NEW":
        return { index: 0, status: "progress" };
      case "INTEREST_EXPRESSED":
        return { index: 1, status: "completed" };
      case "DUE_DILIGENCE_INITIATED":
        return { index: 2, status: "progress" };
      case "DUE_DILIGENCE_COMPLETED":
        return { index: 2, status: "completed" };

      case "QUOTE_GENERATED":
        return { index: 3, status: "progress" };
      case "QUOTE_DECLINED":
        return { index: 3, status: "decline" };
      case "QUOTE_ACCEPTED":
        return { index: 3, status: "completed" };

      case "PENDING_T_AND_C_ACCEPTANCE":
        return { index: 4, status: "progress" };
      case "FINANCE_AGREEMENT_COMPLETE":
        return { index: 4, status: "completed" };
      case "DECLINED":
        return { index: 4, status: "decline" };
      case "CLOSED":
        return { index: 5, status: "decline" };

      case "FINANCED":
        return { index: 6, status: "completed" };

      case "ABORTED":
        return { index: 6, status: "decline" };

      default:
        return 0;
    }
  };

  const CheckStatus = (status) => {
    if (status === "ABORTED") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div
        className={cn(classes.AccountSchedule, "d-flex align-items-center")}
        onClick={() => handleNavigate(`/financing/invoice/finance-deals`)}>
        <span>
          <BsArrowLeft />
        </span>
        <span>Back To List</span>
      </div>

      {Data && requisitionData ? (
        <div>
          <Content>
            <div style={{ fontSize: "14px", marginBottom: "1%" }}>
              Finance Deal
            </div>
            {/* <CustomizedSteppers
              Value={FinanceDealFlow}
              position={StepperPosition(Data.financeDealStatus)}
            /> */}
            <CustomizedSteppers
              Value={
                CheckStatus(Data.financeDealStatus)
                  ? FinanceDealFlowDecline(Data.financeDealStatus)
                  : FinanceDealFlow
              }
              position={StepperPosition(Data.financeDealStatus)}
              isDeclined={CheckStatus(Data.financeDealStatus)}
            />
          </Content>
          {TabData.map((item) => {
            return (
              <ToggleTab description={item.description} title={item.title} />
            );
          })}
        </div>
      ) : (
        <Content>
          <span>Loading...</span>
          <Loader />
        </Content>
      )}
    </>
  );
};

export default Index;
