import React, { useContext, useCallback, useEffect, useState } from "react";
import Content from "components/UI/Layout/Content";
import { AccountlistHeader } from "utils/Section/trade";

import cn from "classnames";
import classes from "../opentrade.module.css";
import Table from "react-bootstrap/Table";
import Loader from "components/Loader/Loader";
import { FindCompanyName } from "utils/helper";
import { CompanyList } from "utils/API/CompanyList.js";
import { truncateStringID, CheckCountryTitle } from "utils/helper";
import { UseRequest } from "utils/API/Request.js";
import { openTradeAccount } from "services/BaseURLInstance";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "contexts/Auth/AuthContext";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { RiWechatLine } from "react-icons/ri";
import { CheckPermission } from "utils/Constants/RBAC";
import { profileURL } from "services/BaseURLInstance";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { STATUS, COUNTRY } from "utils/Constants";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import MultiSelect from "components/fields/MultiSelect";
import { getIdTokenAsync } from "utils/authUtil";

import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";

import NoRecord from "components/no-data/noRecord";

const SearchResult = ({
  setIsTradeFormOpen,
  setIsSearchResult,
  hanndleCraateGroup,
}) => {
  const { setOpenTradeAccountDetails } = useContext(AuthContext);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const { instance, accounts } = useMsal();
  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");

  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [response, setResponse] = useState(null);
  const [filteredList, setFilteredList] = useState(null);

  const [selected, setSelected] = useState(true);

  const [selectStatusList, setSelectStatusList] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [selectedOriginList, setSelectedOriginList] = useState([]);
  const [selectedOrigin, setSelectedOrigin] = useState([]);
  const [selectedDestinationList, setSelectedDestinationList] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedConsumer, setSelectedConsumer] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedConsumerId, setSelectedConsumerId] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState([]);
  const [ConsumerList, setConsumerList] = useState([]);
  const [ProviderList, setProviderList] = useState([]);
  //rbac
  const [Role, setRole] = useState(null);
  const [viewSearchRole, setViewSearchRole] = useState(null);
  const [startTradeRole, setStartTradeRole] = useState(null);

  const CompanyObject = CompanyList();
  const List = CompanyList();
  const [StatusTradeFilterList, setStatusTradeFilterList] = useState([]);
  const [CountriesOrigin, setCountriesOrigin] = useState([]);
  const [CountriesDestination, setCountriesDestination] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}openaccounttrades?sellerMemberUID=${companyId}&buyerMemberUID=${companyId}`,
          null,
          response
        ).then((data) => {
          data.sort(
            (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
          );
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setResponse(data);
            setFilteredList(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setViewSearchRole(
        CheckPermission("SearchOAT", "OpenAccountTradeTab", "searchOAT", Role)
      );
      setStartTradeRole(
        CheckPermission(
          "StartNewTrade",
          "OpenAccountTradeTab",
          "startNewTrade",
          Role
        )
      );
    }
  }, [Role]);

  const handleGetSingleData = (id) => {
    handleNavigate(`/trade-account/open-account/${id}`);
  };

  const fetchDatas = async (companyId, id2, name) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];
        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        hanndleCraateGroup(nData, name, name);
        setMemberList(nData);
      });
  };

  const chatHandler = (ds) => {
    if (ds?.buyerMemberId !== "" && ds?.sellerMemberId !== "") {
      setItem(ds);
      let name = ds.openTradeAccountId
        ? truncateStringID(ds.openTradeAccountId, 7, "OT")
        : "NA";
      fetchDatas(ds?.buyerMemberId, ds?.sellerMemberId, name);
    }
  };

  // const hanndleCraateGroup = () => {
  //   if (memberList?.length > 0) {
  //     let url = window.location.href;
  //     localStorage.setItem("lasturl", url);
  //     const selectedUsers = memberList;
  //     const params = new sdk.GroupChannelParams();
  //     params.addUserIds(selectedUsers);
  //     params.isDistinct = false;
  //     params.isEphemeral = false;
  //     params.isPublic = false;

  //     params.name = item.openTradeAccountId
  //       ? truncateStringID(item.openTradeAccountId, 7, "OT")
  //       : "NA";
  //     params.channelUrl = params.name;

  //     sdk.GroupChannel.createChannel(params, (response, error) => {
  //       const swapParams = sdk.getErrorFirstCallback();
  //       let groupChannel = response;
  //       let err = error;
  //       if (swapParams) {
  //         groupChannel = error;
  //         err = response;
  //       }
  //       if (err) {
  //         navigate("/chat?url=" + params.channelUrl);
  //         console.log(err);
  //       } else {
  //         navigate("/chat?url=" + params.channelUrl);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (memberList);
  }, [memberList]);

  const Status = (status) => {
    switch (status) {
      case "DRAFT":
        return STATUS(status, "warning");
      case "NEW":
        return STATUS(status, "primary");
      case "ABORTED":
        return STATUS(status, "danger");
      case "TRADE_DECLINED":
        return STATUS(status, "danger");
      case "TRADE_APPROVED":
        return STATUS(status, "success");
      case "TRADE_COMPLETED":
        return STATUS(status, "success");
      case "BUYER_TRADE_COMPLETED":
        return STATUS(status, "success");
      case "SELLER_TRADE_COMPLETED":
        return STATUS(status, "success");
      case "TRADE_IN_PROGRESS":
        return STATUS(status, "warning");
      case "TRADE_IN_REVIEW":
        return STATUS(status, "warning");
      default:
        return status;
    }
  };

  console.log("responseresponse", response);

  useEffect(() => {
    if (response) {
      //STATUS
      const uniqueStatus = [
        ...new Set(response.map((item) => item.openTradeStatus)),
      ];
      let filterStatus =
        uniqueStatus &&
        uniqueStatus.map((item) => {
          return { label: item, value: item };
        });
      setStatusTradeFilterList(filterStatus);

      //ORIGIN
      const uniqueOrigin = [
        ...new Set(response.map((item) => item.countryOfOrigin)),
      ];
      let filterOrigin =
        uniqueOrigin &&
        uniqueOrigin.map((item) => {
          return { label: CheckCountryTitle(item), value: item };
        });
      setCountriesOrigin(filterOrigin);

      //DESTINATION
      const uniqueDestination = [
        ...new Set(response.map((item) => item.countryOfDestination)),
      ];
      let filterDestination =
        uniqueDestination &&
        uniqueDestination.map((item) => {
          return { label: CheckCountryTitle(item), value: item };
        });
      setCountriesDestination(filterDestination);

      const uniqueConsumer = [
        ...new Set(response.map((item) => item.buyerMemberId)),
      ];

      let filterConsumer =
        uniqueConsumer &&
        uniqueConsumer.map((item) => {
          return {
            label: FindCompanyName(
              CompanyObject.response && CompanyObject.response,
              item
            ),
            value: item,
          };
        });
      setConsumerList(filterConsumer);

      const uniqueProvider = [
        ...new Set(response.map((item) => item.sellerMemberId)),
      ];

      let filterProvider =
        uniqueProvider &&
        uniqueProvider.map((item) => {
          return {
            label: FindCompanyName(
              CompanyObject.response && CompanyObject.response,
              item
            ),
            value: item,
          };
        });
      setProviderList(filterProvider);
    }
  }, [response]);

  const handleMultiChange = (e, name) => {
    if (name === "Status") {
      const { value } = e.target;
      setSelectStatusList(value);
    }

    if (name === "Origin") {
      const { value } = e.target;
      setSelectedOriginList(value);
    }

    if (name === "Destination") {
      const { value } = e.target;
      setSelectedDestinationList(value);
    }
    if (name === "consumer") {
      const { value } = e.target;
      setSelectedConsumer(value);
    }

    if (name === "provider") {
      const { value } = e.target;
      setSelectedProvider(value);
    }
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (selectStatusList) {
      let temporaryList = [];
      selectStatusList.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedStatusId(temporaryList);
    }

    if (selectedOriginList) {
      let temporaryList = [];
      selectedOriginList.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedOrigin(temporaryList);
    }

    if (selectedDestinationList) {
      let temporaryList = [];
      selectedDestinationList.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedDestination(temporaryList);
    }
    if (selectedConsumer) {
      let temporaryList = [];
      selectedConsumer.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedConsumerId(temporaryList);
    }

    if (selectedProvider) {
      let temporaryList = [];
      selectedProvider.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedProviderId(temporaryList);
    }
  }, [
    selectStatusList,
    selectedOriginList,
    selectedDestinationList,
    selectedConsumer,
    selectedProvider,
  ]);

  const filterByName = (searchData, name) => {
    return name.toLowerCase().includes(searchData);
  };

  useEffect(() => {
    if (
      (selectedStatusId && selectedStatusId.length > 0) ||
      (selectedOrigin && selectedOrigin.length > 0) ||
      (selectedDestination && selectedDestination.length > 0) ||
      (selectedConsumerId && selectedConsumerId.length > 0) ||
      (selectedProviderId && selectedProviderId.length > 0) ||
      searchText !== ""
    ) {
      let filtered =
        response &&
        response.filter(
          (data) =>
            (selectedStatusId.length > 0
              ? selectedStatusId.includes(data.openTradeStatus)
              : data) &&
            (selectedOrigin.length > 0
              ? selectedOrigin.includes(data.countryOfOrigin)
              : data) &&
            (selectedDestination.length > 0
              ? selectedDestination.includes(data.countryOfDestination)
              : data) &&
            (searchText !== ""
              ? filterByName(searchText, data.openTradeAccountId)
              : data) &&
            (selectedConsumerId.length > 0
              ? selectedConsumerId.includes(data.buyerMemberId)
              : data) &&
            (selectedProviderId.length > 0
              ? selectedProviderId.includes(data.sellerMemberId)
              : data)
        );
      setFilteredList(filtered);
    } else if (
      selectedStatusId.length === 0 &&
      selectedOrigin.length === 0 &&
      selectedDestination.length === 0 &&
      searchText === "" &&
      selectedConsumerId.length === 0 &&
      selectedProviderId.length === 0
    ) {
      setFilteredList(response);
    }
  }, [
    selectedStatusId,
    selectedOrigin,
    selectedDestination,
    searchText,
    selectedConsumerId,
    selectedProviderId,
  ]);

  return (
    <>
      {isLoading || List.isLoading ? (
        <Content marginBottom="0%">
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {response && List && List.response && (
            <>
              <div
                className={classes.toogleTab}
                style={{ padding: "1% 2% 1.7% 2%" }}>
                <div
                  className={classes.toogleTitle}
                  onClick={() => setSelected(!selected)}>
                  <span style={{ fontSize: "18px" }}>Search Trades</span>
                  <span>
                    {selected ? (
                      <CgChevronUp size={30} />
                    ) : (
                      <CgChevronDown size={30} />
                    )}
                  </span>
                </div>

                <div className={selected ? "show" : "description"}>
                  <div
                    className={cn(
                      classes.TopDiv,
                      "d-flex justify-content-between"
                    )}>
                    <div
                      className={cn(
                        classes.InputDiv,
                        "d-flex align-items-end flex-column"
                      )}>
                      <input
                        //  console.log(Role, viewSearchRole, " setViewReqRole", startTradeRole);
                        disabled={
                          viewSearchRole === "NOTAPP" ||
                          viewSearchRole === "DELETE"
                            ? true
                            : false
                        }
                        name="search"
                        style={{ width: "100%" }}
                        value={searchText}
                        type="search"
                        placeholder="Search Trade ID.."
                        onChange={handleSearchChange}
                      />
                    </div>
                    <div></div>

                    <div
                      className={classes.action}
                      onClick={
                        startTradeRole === "CREATE"
                          ? () => {
                              setIsTradeFormOpen(true);
                              setIsSearchResult(false);
                            }
                          : " "
                      }>
                      + START NEW OPEN TRADE
                    </div>
                  </div>
                  <div
                    className={cn(
                      classes.SearchDiv,
                      "d-flex justify-content-between align-items-baseline"
                    )}>
                    <div>
                      <MultiSelect
                        name="Status"
                        selected={selectStatusList}
                        placeholder="Status.."
                        options={
                          StatusTradeFilterList &&
                          StatusTradeFilterList.sort((a, b) =>
                            a.label.localeCompare(b.label)
                          )
                        }
                        handleChange={(e) => handleMultiChange(e, "Status")}
                      />
                    </div>
                    <div>
                      <MultiSelect
                        name="Country of Origin"
                        options={CountriesOrigin}
                        selected={selectedOriginList}
                        placeholder="Origin"
                        handleChange={(e) => handleMultiChange(e, "Origin")}
                      />
                    </div>
                    <div>
                      <MultiSelect
                        name="Country of Destination"
                        options={CountriesDestination}
                        selected={selectedDestinationList}
                        placeholder="Destination"
                        handleChange={(e) =>
                          handleMultiChange(e, "Destination")
                        }
                      />
                    </div>
                    <div>
                      <MultiSelect
                        name="Status"
                        selected={selectedConsumer}
                        placeholder="Buyer"
                        options={
                          ConsumerList &&
                          ConsumerList.sort((a, b) =>
                            a.label.localeCompare(b.label)
                          )
                        }
                        handleChange={(e) => handleMultiChange(e, "consumer")}
                      />
                    </div>
                    <div>
                      <MultiSelect
                        name="category"
                        options={
                          ProviderList &&
                          ProviderList.sort((a, b) =>
                            a.label.localeCompare(b.label)
                          )
                        }
                        selected={selectedProvider}
                        placeholder="Seller"
                        handleChange={(e) => handleMultiChange(e, "provider")}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Content marginBottom="0%" padding="1.3% 2%">
                <h6 style={{ fontSize: "18px" }}>Search Results</h6>
                <Table bordered responsive className={cn(classes.Table)}>
                  <thead>
                    <tr>
                      {AccountlistHeader &&
                        AccountlistHeader.map((header, i) => (
                          <td key={i}>{header.name}</td>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredList &&
                      filteredList.map((data, i) => (
                        <tr key={i}>
                          <td
                            style={{
                              color: "rgb(33,142,235",
                              textDecorationLine: "underline",
                            }}
                            onClick={() =>
                              handleGetSingleData(data.openTradeAccountId)
                            }>
                            {data.openTradeAccountId
                              ? `${truncateStringID(
                                  data.openTradeAccountId,
                                  7,
                                  "OT"
                                )}`
                              : "Not Available"}
                          </td>
                          <td>
                            {List &&
                              List.response &&
                              FindCompanyName(
                                List && List.response,
                                data.buyerMemberId
                              )}
                          </td>
                          <td>
                            {List &&
                              List.response &&
                              FindCompanyName(
                                List && List.response,
                                data.sellerMemberId
                              )}
                          </td>
                          <td>{COUNTRY(data.countryOfOrigin)}</td>
                          <td>{COUNTRY(data.countryOfDestination)}</td>
                          <td className="text-center">
                            {new Date(data.createdDate).toLocaleDateString()}
                          </td>
                          <td>
                            {data.openTradeStatus
                              ? Status(data.openTradeStatus)
                              : "NA"}
                          </td>
                          <td>
                            <div className={classes["flex-1"]}>
                            <OverlayTrigger
                                trigger={["hover", "focus"]}
                                key="top"
                                placement="top"
                                overlay={
                                <Popover id="popover-positioned-top">
                                <Popover.Body>
                                <strong>Chat</strong>
                                </Popover.Body>
                                </Popover>
                               }>
                            <span
                                className="px-3"
                                onClick={(e) => chatHandler(data)}
                                style={{ cursor: "pointer" }}>
                                <RiWechatLine size={22} />
                              </span>
                      </OverlayTrigger>
                              {/* <span
                                className="px-3"
                                onClick={(e) => chatHandler(data)}
                                style={{ cursor: "pointer" }}>
                                <RiWechatLine size={22} />
                              </span> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                </Table>
                {filteredList && filteredList.length === 0 && <NoRecord />}
              </Content>
            </>
          )}

          {(isError || (List && List.isError)) && (
            <Content marginBottom="0%">Something went wrong</Content>
          )}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}
    </>
  );
};

export default SearchResult;
