import React, { useEffect, useState } from "react";

import Loader from "components/Loader/Loader";
import { serviceURL } from "services/BaseURLInstance";
import ColumnList from "components/UI/ColumnList/ColumnList";
import Content from "components/UI/Layout/Content";
import { serviceOverview } from "utils/Section/InvoiceSection";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import classes from "../service.module.css";
import { TbRosetteFilled, TbCircleFilled } from "react-icons/tb";

export default () => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${serviceURL}services/summary?memberId=${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message || "Something went wrong");
          } else {
            setResponse(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <Content padding="1% 2% 2% 2%">
        {isLoading ? (
          <span>
            Loading....
            <Loader />
          </span>
        ) : (
          <>
            {response && (
              <>
                <div
                  className="d-flex justify-content-between my-2"
                  style={{ color: "#42a5f5" }}>
                  <div>
                    <h5>Services Summary</h5>
                  </div>
                  <div>
                    <h5>All Service Deals</h5>
                  </div>
                </div>
                {/* <ColumnList list={serviceOverview} response={response} /> */}
                <div className={classes.serviceSummary}>
                  <div>
                    <div className={classes.summaryHead}>
                      <div className={classes.summaryHead1}>
                        {response.newServiceRequisitions
                          ? response.newServiceRequisitions
                          : 0}
                      </div>
                      <div className={classes.summaryHead2}>
                        <div style={{ fontSize: "22px" }}>
                          New Service Requisition
                        </div>
                        <div style={{ color: "#42a5f5" }}>Recommended-Na</div>
                      </div>
                    </div>
                    <div className={classes.summaryAll}>
                      <div className={classes.summaryList}>
                        <span>
                          <span style={{ position: "relative" }}>
                            <TbCircleFilled size={38} color={"#dc3545"} />
                            <span className={classes.iconValue}>
                              {response.inPursuit ? response.inPursuit : 0}
                            </span>
                          </span>
                        </span>
                        <span>In-Pursuit</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#dc3545"} />
                          <span className={classes.iconValue}>
                            {response.expiredRequisitions
                              ? response.expiredRequisitions
                              : 0}
                          </span>
                        </span>
                        <span>Expired</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#d39e00"} />
                          <span className={classes.iconValue}>
                            {response.openServiceRequisitions
                              ? response.openServiceRequisitions
                              : 0}
                          </span>
                        </span>
                        <span>In-Delivery</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>
                            {response.closedRequisitions
                              ? response.closedRequisitions
                              : 0}
                          </span>
                        </span>
                        <span>Closed</span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={classes.summaryHead}>
                      <div className={classes.summaryHead1}>
                        {response.newServiceDeals
                          ? response.newServiceDeals
                          : 0}
                      </div>
                      <div className={classes.summaryHead2}>
                        <div style={{ fontSize: "22px" }}>
                          New Service Deals
                        </div>
                        <div style={{ color: "#42a5f5" }}>Recommended-Na</div>
                      </div>
                    </div>
                    <div className={classes.summaryAll}>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#c82333"} />
                          <span className={classes.iconValue}>
                            {response.openServiceDeals
                              ? response.openServiceDeals
                              : 0}
                          </span>
                        </span>
                        <span>In-pursuit</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#c82333"} />
                          <span className={classes.iconValue}>
                            {response.expiredServicedeals
                              ? response.expiredServicedeals
                              : 0}
                          </span>
                        </span>
                        <span>Expired</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#e0a800"} />
                          <span className={classes.iconValue}>
                            {response.approvedServiceDeals
                              ? response.approvedServiceDeals
                              : 0}
                          </span>
                        </span>
                        <span>Approved Quotes</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#e0a800"} />
                          <span className={classes.iconValue}>
                            {response.inProgressServiceDeals
                              ? response.inProgressServiceDeals
                              : 0}
                          </span>
                        </span>
                        <span>In-Progress</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>
                            {response.closedServiceDeals
                              ? response.closedServiceDeals
                              : 0}
                          </span>
                        </span>
                        <span>Closed Deals</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>
                            {response.serviceDealsCompleted
                              ? response.serviceDealsCompleted
                              : 0}
                          </span>
                        </span>
                        <span>Delivered</span>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={classes.summaryHead}>
                      <div className={classes.summaryHead1}>0</div>
                      <div className={classes.summaryHead2}>
                        <div style={{ fontSize: "22px" }}>
                          Upcoming Payments
                        </div>
                        <div style={{ color: "#42a5f5" }}>Recommended</div>
                      </div>
                    </div>
                    <div className={classes.summaryAll}>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#c82333"} />
                          <span className={classes.iconValue}>0</span>
                        </span>
                        <span>Delayed</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#e0a800"} />
                          <span className={classes.iconValue}>
                            {response.serviceDealsPaymentPending
                              ? response.serviceDealsPaymentPending
                              : 0}
                          </span>
                        </span>
                        <span>Pending</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>0</span>
                        </span>
                        <span>Completed</span>
                      </div>
                    </div>
                  </div>  
                </div>
              </>
            )}
            {isError && (
              <div className="alert alert-danger" role="alert">
                {isError}
              </div>
            )}
          </>
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Content>
    </>
  );
};
