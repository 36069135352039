import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { customsForm } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";

const Stevedor = ({ response }) => {
  const [serviceDetails, setServiceDetails] = useState([]);
  let typeOffCargo = [
    {
      label: "Container",
      value: "Container",
    },
    {
      label: "Bulk",
      value: "Bulk",
    },
    {
      label: "Breakbulk",
      value: "Breakbulk",
    },
    {
      label: "ODC (Over Dimensional Cargo)",
      value: "ODC (Over Dimensional Cargo)",
    },
  ];

  let containers = [
    {
      label: "10-Foot Container",
      value: "10-Foot Container",
    },
    {
      label: "20-Foot Container",
      value: "20-Foot Container",
    },
    {
      label: "40-Foot Container",
      value: "40-Foot Container",
    },
    {
      label: "20-Foot High Cube",
      value: "20-Foot High Cube",
    },
    {
      label: "440-Foot High Cube",
      value: "40-Foot High Cube",
    },
    {
      label: "Not relevant",
      value: "Not relevant",
    },
  ];

  let containType = [
    {
      label: " Dry storage container",
      value: " Dry storage container",
    },
    {
      label: " Flat rack container",
      value: " Flat rack container",
    },
    {
      label: "  Open top container",
      value: "  Open top container",
    },
    {
      label: " Open side storage container",
      value: " Open side storage container",
    },
    {
      label: "  Refrigerated ISO containers",
      value: "  Refrigerated ISO containers",
    },
    {
      label: "ISO Tanks",
      value: "ISO Tanks",
    },
    {
      label: "Half height containers",
      value: "Half height containers",
    },
    {
      label: " Special purpose containers",
      value: " Special purpose containers",
    },
  ];

  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails));
    }
  }, [response]);
  console.log(response);
  return (
    <>
      {response && serviceDetails ? (
        <>
          <Content padding="1% 2% 1.4% 2%">
            <div className={classes.source}>Requester Information:</div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="name"
                placeholder="Name:                        "
                style={{ marginTop: "2%", marginBottom: "2%" }}
                readOnly={true}
                value={serviceDetails.name}
              />
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="company"
                placeholder="Company/Organization:"
                readOnly={true}
                value={serviceDetails.company}
              />
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="contactInfo"
                placeholder="Contact Information (Phone/Email )"
                readOnly={true}
                value={serviceDetails.contactInfo}
              />
            </div>
            <div className={classes.source} style={{ marginTop: "4%" }}>
              Cargo Details:
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="vesselName"
                placeholder="Vessel Name:"
                readOnly={true}
                value={serviceDetails.vesselName}
                // readOnly={true}
              />
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="imoNumber"
                placeholder="Vessel IMO Number:"
                readOnly={true}
                value={serviceDetails.imoNumber}
                // readOnly={true}
              />{" "}
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="port"
                placeholder="Port of Call:"
                readOnly={true}
                value={serviceDetails.port}
                // readOnly={true}
              />{" "}
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="arrival"
                placeholder="Arrival Date and Time:"
                readOnly={true}
                value={serviceDetails.arrival}
                // readOnly={true}
              />
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="departure"
                placeholder="Departure Date and Time:"
                readOnly={true}
                value={serviceDetails.departure}
                // readOnly={true}
              />{" "}
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="typeOfCargo"
                placeholder="Type of Cargo:"
                readOnly={true}
                value={serviceDetails.typeOfCargo}
                // readOnly={true}
              />{" "}
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="description"
                placeholder="Description of Cargo (including any special handling requirements):"
                readOnly={true}
                value={serviceDetails.description}
                // readOnly={true}
              />{" "}
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="selectedCargo"
                options={typeOffCargo}
                placeholder="Type of Cargo:"
                readOnly={true}
                value={serviceDetails.selectedCargo}
                // readOnly={true}
              />{" "}
            </div>
            <div
              className={classes.source}
              style={{ margin: "0px 10px", marginTop: "4%" }}
            >
              If Container Cargo
            </div>
            <div className={classes.source} style={{ margin: "0px 10px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  width: "50%",
                  marginRight: "10px",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType3"
                  value="YES"
                  //onChange={handleRadio}
                  checked={serviceDetails.serviceType3 == "YES"}
                />
                <label>YES</label>
              </div>{" "}
              <div
                style={{
                  // fontSize: "25px",
                  marginLeft: "10px",
                  width: "50%",
                  textAlign: "right",
                  marginRight: "10px",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType3"
                  value="NO"
                  //onChange={handleRadio}
                  checked={serviceDetails.serviceType3 == "NO"}
                />
                <label>NO</label>
              </div>
            </div>
            {serviceDetails.serviceType3 === "YES" ? (
              <>
                <div className={classes.source} style={{ marginTop: "2%" }}>
                  {" "}
                  <Input
                    options={containType}
                    name="containorType"
                    placeholder="Container Type:"
                    readOnly={true}
                    value={serviceDetails.containorType}
                    // readOnly={true}
                  />{" "}
                </div>
                <div className={classes.source} style={{ marginTop: "2%" }}>
                  {" "}
                  <Input
                    options={containers}
                    name="containoirsize"
                    placeholder="Container Size:"
                    readOnly={true}
                    value={serviceDetails.containoirsize}
                    // readOnly={true}
                  />{" "}
                </div>
              </>
            ) : (
              ""
            )}
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="requirements"
                placeholder="Packaging Requirements"
                readOnly={true}
                value={serviceDetails.requirements}
                // readOnly={true}
              />{" "}
            </div>
            <div className={classes.source} style={{ marginTop: "2%" }}>
              {" "}
              <Input
                name="Lashing"
                placeholder="Lashing , Dunnaging and Shoring Requirements"
                readOnly={true}
                value={serviceDetails.Lashing}
                // readOnly={true}
              />{" "}
            </div>
            <div
              className={classes.source}
              style={{ margin: "0px 10px", marginTop: "4%" }}
            >
              Inspection done : Y/ N
            </div>
            <div className={classes.source} style={{ margin: "0px 10px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  width: "50%",
                  marginRight: "10px",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType"
                  value="YES"
                  //onChange={handleRadio}
                  checked={serviceDetails.serviceType == "YES"}
                />
                <label>YES</label>
              </div>{" "}
              <div
                style={{
                  // fontSize: "25px",
                  marginLeft: "10px",
                  width: "50%",
                  textAlign: "right",
                  marginRight: "10px",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType"
                  value="NO"
                  //onChange={handleRadio}
                  checked={serviceDetails.serviceType == "NO"}
                />
                <label>NO</label>
              </div>
            </div>

            <div className={classes.source} style={{ margin: "0px 10px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  width: "50%",
                  marginRight: "10px",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType2"
                  value="Unloading"
                  //onChange={handleRadio}
                  checked={serviceDetails.serviceType2 == "Unloading"}
                />
                <label>Unloading</label>
              </div>{" "}
              <div
                style={{
                  // fontSize: "25px",
                  marginLeft: "10px",
                  width: "50%",
                  textAlign: "right",
                  marginRight: "10px",
                  marginTop: "2%",
                  marginBottom: "2%",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType2"
                  value="Loading"
                  //onChange={handleRadio}
                  checked={serviceDetails.serviceType2 == "Loading"}
                />
                <label>Loading</label>
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="loadinginfo"
                  placeholder="Loading/Unloading Information:"
                  readOnly={true}
                  value={serviceDetails.loadinginfo}
                  // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="locationOfCargo"
                  placeholder="Location of Cargo (Berth/Wharf Number):"
                  readOnly={true}
                  value={serviceDetails.locationOfCargo}
                  // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <DateInput
                  name="preferred"
                  placeholder="Preferred Start Date:"
                  value={serviceDetails.preferred}
                  //  handleChange={(e) => handleDateChange(e, "preferred")}
                />
              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <DateInput
                  name="completion"
                  placeholder="Preferred Completion Date :"
                  value={serviceDetails.completion}
                  // handleChange={(e) => handleDateChange(e, "completion")}
                />
              </div>
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="duration"
                placeholder="Estimated Duration (in hours):"
                readOnly={true}
                value={serviceDetails.duration}
                // readOnly={true}
              />
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="equipments"
                  placeholder="Equipment Needed (Cranes, Forklifts, etc.):"
                  readOnly={true}
                  value={serviceDetails.equipments}
                  // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="laborreq"
                  placeholder="Labor Requirements (Number of Workers and Specialized Skills):"
                  readOnly={true}
                  value={serviceDetails.laborreq}
                  // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="environment"
                  placeholder="Safety and Environmental Considerations: "
                  readOnly={true}
                  value={serviceDetails.environment}
                  // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <label>Is Hazardous ?</label>
              </div>

              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  //options={isHazard}
                  name="ishazard"
                  placeholder="Hazardous ?"
                  //  handleChange={handleChange}
                  value={serviceDetails.ishazard}
                  readOnly={true}
                />
              </div>
            </div>
            {serviceDetails.ishazard === "YES" ? (
              <>
                <div style={{ width: "50%", margin: "10px" }}>
                  <Input
                    //  options={cargoHazardousOptions}
                    name="cargoHazardous"
                    placeholder="Cargo Hazardous"
                    readOnly={true}
                    //  handleChange={handleChange}
                    value={serviceDetails.cargoHazardous}
                  />
                </div>
              </>
            ) : (
              " "
            )}
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="unnumber"
                  placeholder="UN NUMBER* "
                  //  handleChange={handleChange}
                  value={serviceDetails.unnumber}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="specialReq"
                  placeholder="Any Hazardous Materials or Special Safety Requirements: "
                  // handleChange={handleChange}
                  value={serviceDetails.specialReq}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="regulations"
                  placeholder="Environmental Regulations Compliance:"
                  // handleChange={handleChange}
                  value={serviceDetails.regulations}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="emergencyplan"
                  placeholder="Emergency Response Plan:"
                  //handleChange={handleChange}
                  value={serviceDetails.emergencyplan}
                  readOnly={true}
                />
              </div>
            </div>
          </Content>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Stevedor;
