import React, { useState, useContext, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import classes from "./user.module.css";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import { CheckPermission } from "utils/Constants/RBAC";
import { profileURL, NetworkURL } from "services/BaseURLInstance";
import {
  UserTableHead,
  UserOnboardingStatus,
  UserStatus,
  UserRole,
} from "utils/Section/Profile";
import { Countries } from "utils/DropDown/Country";

import Loader from "components/Loader/Loader";
import { AddUserValdations } from "utils/Validations/Profile";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { AddUserInfo } from "utils/Section/FormInfo";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { FindCompanyName } from "utils/helper";
import { CompanyList } from "utils/API/CompanyList.js";
import SelectWithSearch from "components/fields/SelectWithSearch";

const UserManagement = () => {
  const CompanyObject = CompanyList();

  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyName = JSON.parse(sessionStorage.getItem("companyName"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const { instance, accounts } = useMsal();
  const [userListData, setUserListData] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [apiTriggers, setApiTriggers] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingPerson, setIsUpdatingPerson] = useState("");

  const [Role, setRole] = useState(null);
  const [addUser, setAddUser] = useState(null);
  const [toast, setToast] = useState();
  const [Data, setData] = useState({
    personName: "",
    country: "",
    phone: [],
    emailID: [],
    role: "",
    verified: "",
    address: [],
    companyName: companyName,
    memberUID: null,
    companyMemberId: companyId,
    createdDate: new Date(),
  });

  useEffect(() => {
    if (Role) {
      setAddUser(CheckPermission("AddUser", "UserManagement", "addUser", Role));
    }
  }, [Role]);

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${profileURL}Persons?organizationMemberId=${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message } = data;
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setUserListData(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [apiTriggers]);

  const handleChange = (e) => {
    const emailIDs = [];
    const address = [];
    const phone = [];

    if (e.target.name === "emailID") {
      emailIDs.push(e.target.value);
    }

    if (e.target.name === "phone") {
      phone.push(e.target.value);
    }

    if (e.target.name === "address") {
      console.log("DataData address");
      address.push(
        e.target.value.split(",").map((item) => {
          return item.trim();
        })
      );
    }

    if (e.target.name === "address") {
      setData({
        ...Data,
        [e.target.name]: address[0],
      });
    } else if (e.target.name === "emailID") {
      setData({
        ...Data,
        [e.target.name]: emailIDs,
      });
    } else if (e.target.name === "phone") {
      setData({
        ...Data,
        [e.target.name]: phone,
      });
    } else {
      setData({
        ...Data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleClickBack = () => {
    setData({
      personName: "",
      country: "",
      phone: [],
      emailID: [],
      role: "",
      verified: "",
      address: [],
      companyName: companyName,
      memberUID: companyId,
      companyMemberId: companyId,
      createdDate: new Date(),
    });
    setIsFormOpen(false);
    setIsUpdating(false);
    setIsUpdatingPerson("");
  };

  const handleAddUser = () => {
    setData({
      personName: "",
      country: "",
      phone: [],
      emailID: [],
      role: "",
      verified: "",
      address: [],
      companyName: companyName,
      memberUID: null,
      companyMemberId: companyId,
      createdDate: new Date(),
    });
    setIsFormOpen(true);
  };

  const HandleSubmit = async (e) => {
    let checkValidation = AddUserValdations(Data);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      e.preventDefault();
      setIsLoading(true);
      const requestOptions = {
        method: isUpdating ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          "X-DEV-COMPANY_MEMBER_ID": companyId,
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(Data),
      };
      let URL = "";
      if (isUpdating) {
        URL = `${NetworkURL}Persons/${isUpdatingPerson}`;
      } else {
        URL = `${NetworkURL}Users/signup`;
      }

      await fetch(URL, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false);
          let { code, message } = data;
          if (code) {
            let error = message || "Something went wrong";
            Error(error);
          } else {
            Success("Added Successfully");
            setIsFormOpen(false);
            setApiTriggers(!apiTriggers);
          }
        });
    }
  };

  const handleUpdate = (id) => {
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(`${profileURL}Persons/${id}`, null, response).then((data) => {
        setIsLoading(false);
        let { message } = data;
        if (message) {
          Error(message);
          setIsError(message);
        } else {
          setIsFormOpen(true);
          setIsUpdating(true);
          setData({
            ...Data,
            personName: data.personName,
            country: data.country,
            phone: data.phone && data.phone.length > 0 ? data.phone : null,
            emailID:
              data.emailID && data.emailID.length > 0 ? data.emailID : null,
            role: data.role,
            verified: data.verified,
            address: data.address,
            createdBy: data.createdBy,
            createdDate: data.createdDate,
            updateBy: data.updateBy,
            updatedDate: data.updatedDate,
            memberUID: data.memberUID,
          });
          setIsUpdatingPerson(data.memberUID);
        }
      });
    });
  };

  const sortedData = [...userListData].sort(
    (a, b) => new Date(b.updatedDate) - new Date(a.updatedDate)
  );

  return (
    <>
      {isLoading || CompanyObject.isLoading ? (
        <Content>
          <span>Loading ...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {!isError && !CompanyObject.isError ? (
            <>
              {!isFormOpen && (
                <>
                  <Content padding="1% 2% 1% 2%">
                    <div className="d-flex flex-row justify-content-between py-3 align-items-center">
                      <h6 style={{fontSize:"18px", margin:"0px"}}>User Management</h6>
                      <Button
                        onClick={
                          addUser === "NOTAPP" ? " " : () => handleAddUser()
                        }>
                        + Add User
                      </Button>
                    </div>
                    <Table bordered responsive className={cn(classes.Table)}>
                      <thead>
                        <tr>
                          {UserTableHead.map((header, index) => (
                            <td key={index}>{header.name}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {sortedData &&
                          sortedData.map((user, index) => (
                            <tr key={index}>
                              <td
                                style={{
                                  cursor: "pointer",
                                  color: "#218eeb",
                                  textDecorationLine: "underline",
                                }}
                                onClick={() => handleUpdate(user.memberUID)}>
                                {user.personName}
                              </td>
                              <td>{user.emailID[0]}</td>
                              <td>
                                {user.phone && user.phone.length > 0
                                  ? user.phone[0]
                                  : "-"}
                              </td>
                              <td>{user.role ? user.role : "-"}</td>
                              <td>
                                {user.updatedDate
                                  ? new Date(
                                      user.updatedDate
                                    ).toLocaleDateString()
                                  : "-"}
                              </td>
                              <td>{user.verified}</td>
                            </tr>
                          ))}
                      </tbody>
                      {userListData && userListData.length === 0 && (
                        <span>No Records Found</span>
                      )}
                    </Table>
                  </Content>
                </>
              )}

              {isFormOpen && (
                <Content>
                  <OverLayTrigger value={AddUserInfo} />
                  <div className={classes.UserManagement}>
                    <div
                      className={classes.InputList}
                      style={{ marginRight: "2%" }}>
                      <Input
                        name="personName"
                        value={Data.personName}
                        placeholder="Person Name *"
                        handleChange={handleChange}
                      />
                    </div>
                    <div className={classes.InputList}>
                      <SelectWithSearch
                        options={Countries}
                        name="country"
                        value={Data.country}
                        placeholder="Country *"
                        handleChange={handleChange}
                      />
                    </div>
                    <div
                      className={classes.InputList}
                      style={{ marginRight: "2%" }}>
                      <Input
                        name="emailID"
                        value={Data.emailID && Data.emailID[0]}
                        placeholder="Email *"
                        handleChange={handleChange}
                      />
                    </div>
                    <div className={classes.InputList}>
                      <Input
                        name="address"
                        value={Data.address && Data.address.toString()}
                        placeholder="Address *"
                        handleChange={handleChange}
                      />
                    </div>
                    <div
                      className={classes.InputList}
                      style={{ marginRight: "2%" }}>
                      <Input
                        name="phone"
                        value={Data.phone && Data.phone[0]}
                        placeholder="Phone No *"
                        handleChange={handleChange}
                      />
                    </div>
                    <div className={classes.InputList}>
                      <SelectWithSearch
                        options={UserRole}
                        name="role"
                        value={Data.role}
                        placeholder="Role *"
                        handleChange={handleChange}
                      />
                    </div>
                    {/* <div className={classes.InputList}>
                      <Select
                        options={UserStatus}
                        name="isActive"
                        value={Data.isActive}
                        placeholder="Status"
                        handleChange={handleChange}
                      />
                    </div> */}
                    <div
                      className={classes.InputList}
                      style={{ marginRight: "51%" }}>
                      <SelectWithSearch
                        options={UserOnboardingStatus}
                        name="verified"
                        value={Data.verified}
                        placeholder="Onboarding Status *"
                        handleChange={handleChange}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}>
                    {toast && <div style={{ color: "green" }}>{toast}</div>}
                    <div
                      className={cn(
                        classes.StatusDiv,
                        "d-flex justify-content-between"
                      )}>
                      <div style={{ color: "#ffc107" }}>
                        <CreatedByLabel
                          createdBy={
                            CompanyObject.response &&
                            FindCompanyName(
                              CompanyObject.response,
                              Data.createdBy
                            )
                          }
                          createdDate={new Date(
                            Data.createdDate
                          ).toLocaleDateString()}
                          lastUpdateBy={
                            CompanyObject.response &&
                            FindCompanyName(
                              CompanyObject.response,
                              Data.updateBy
                            )
                          }
                          lastUpdatedDate={new Date(
                            Data.updatedDate
                          ).toLocaleDateString()}
                        />
                      </div>
                    </div>

                    <ButtonDiv>
                      <Button onClick={() => handleClickBack()}>Cancel</Button>
                      <Button onClick={(e) => HandleSubmit(e)}>Save</Button>
                    </ButtonDiv>
                  </div>
                </Content>
              )}
            </>
          ) : (
            <span>{isError || CompanyObject.isError}</span>
          )}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default UserManagement;
