import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./quote.module.css";
import Content from "components/UI/Layout/Content";
import Select from "components/fields/Select";
import Input from "components/fields/Input";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { AuthContext } from "contexts/Auth/AuthContext";
import { openTradeAccount, paymentServiceURL } from "services/BaseURLInstance";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { CompanyList } from "utils/API/CompanyList.js";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { useNavigate, useParams } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { AccountInfoSelect } from "utils/Section/FormInfo";

const PaymentDetails = ({ handleAcceptQuote, userType, setPaymentDetailsScreen, currentStatus, quoteData, setOpen }) => {
  const { id } = useParams();
  const { OpenTradeAccountDetails } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const { instance, accounts } = useMsal();
  const [companyList, setCompanyList] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [payment, setPayment] = useState(null);
  const [provider, setProvider] = useState([]);
  const [provider2, setProvider2] = useState([]);
  const [servicePaymentDetails, setServicePaymentDetails] = useState();


  const theme = createTheme({
    palette: {

      secondary: {
        // This is green.A700 as hex.
        main: '#808080',
      },
    },
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setPayment(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);


  useEffect(() => {
    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.paymentProvider,
          value: item.paymentProvider,
        };
      });
      setProvider(temp);
    }

    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.virtualAccountNumber,
          value: item.virtualAccountNumber,
        };
      });
      setProvider2(temp);
    }
  }, [payment]);

  const handlePaymentChange = (event) => {
    setServicePaymentDetails({
      ...servicePaymentDetails,
      [event.target.name]: event.target.value
    })
  };

  // CompanyList API
  const List = CompanyList();
  useEffect(() => {
    if (List && List.response) {
      let temp = List.response.filter((item) => {
        if (
          item.memberType === "FINANCER" ||
          item.memberType === "COMPANY" ||
          item.memberUID === companyId
        ) {
          return { label: item.memberName, value: item.memberUID };
        }
      });
      let filter =
        temp &&
        temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });

      setCompanyList(filter);
    }
  }, [List && List.response]);

  const handleAbort = (e) => {
    e.preventDefault();
  };

  const handlePutSubmitPayment = (e) => {
    e.preventDefault();
    const paymentData = {
      PaymentProvider: quoteData?.PaymentProviderNew ? quoteData.PaymentProviderNew : servicePaymentDetails?.paymentProviderName ? servicePaymentDetails?.paymentProviderName : "",
      CompanyAccount: quoteData?.CompanyAccount ? quoteData?.CompanyAccount : servicePaymentDetails?.RefCompanyVirtualBankAccountId ? servicePaymentDetails?.RefCompanyVirtualBankAccountId : "",
      FinancerAccount: servicePaymentDetails?.RefFinancerVirtualBankAccountId ? servicePaymentDetails?.RefFinancerVirtualBankAccountId : quoteData?.FinancerAccount ? quoteData?.FinancerAccount : "",
    }
    if(quoteData.modeOfPayment!=="OFFLINE" && quoteData.financeDealStatus==="FINANCED"){
      setOpen(true)
      setPaymentDetailsScreen(false)
    }
    else if(quoteData.modeOfPayment!=="OFFLINE")
    {
    handleAcceptQuote(e, {financeDealStatus:currentStatus, paymentData:paymentData});
    }
    else{
      handleAcceptQuote(e, {financeDealStatus:currentStatus});
    }
  }
  return (
    <>
      {isLoading || List.isLoading ? (
        <Content marginBottom="0%">
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {!List.isError && !isError && (
            <Content marginBottom="0%">
              <div style={{ marginBottom: "3%" }}>
                <div style={{fontSize:"20px"}}>Payment Preferences</div>
                <div style={{ display: "flex" }}>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Switch
                        color={quoteData.modeOfPayment === "OFFLINE" ? 'secondary' : "info"}
                        checked={true}
                      />
                    </ThemeProvider>
                  </div>
                  <div style={{ marginTop: "0.6%" }}>{quoteData.modeOfPayment === "OFFLINE" ? "Offline Payment" : "Online Payment"}</div>
                </div>
              </div>
              {quoteData.modeOfPayment !== "OFFLINE" &&
                <div>
                  <OverLayTrigger value={AccountInfoSelect} />
                  <div className={classes.FormDiv}>
                    <div>
                      <div>Payment Service Provider</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Service Provider
                      </div>
                    </div>
                    {userType==="COMPANY" || quoteData.financeDealStatus==="FINANCED" ?
                      <div>
                        <Input
                          name="paymentProviderName"
                          placeholder="Payment Service Provider"
                          value={quoteData?.PaymentProviderNew ? quoteData?.PaymentProviderNew : servicePaymentDetails?.paymentProviderName}
                          readOnly={true}
                        />
                      </div>
                      :
                      <div>
                        <Select
                          name="paymentProviderName"
                          placeholder="Payment Service Provider"
                          options={provider}
                          value={quoteData?.PaymentProviderNew ? quoteData?.PaymentProviderNew : servicePaymentDetails?.paymentProviderName}
                          handleChange={handlePaymentChange}
                          readOnly={quoteData.paymentProviderName ? true : false}
                        />
                      </div>
                    }
                    <div>
                      <div>Payment Account (Financer)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    {userType==="COMPANY" ?
                      <div>
                        <Input
                          name="RefFinancerVirtualBankAccountId"
                          placeholder="Ref Financer Virtual BankAccount Id*"
                          value={quoteData?.FinancerAccount ? quoteData?.FinancerAccount : servicePaymentDetails?.RefFinancerVirtualBankAccountId}
                          readOnly={true}
                        />
                      </div>
                      :
                      <div>
                        <Select
                          name="RefFinancerVirtualBankAccountId"
                          options={provider2}
                          placeholder="Ref Financer Virtual BankAccount Id*"
                          value={quoteData?.FinancerAccount ? quoteData?.FinancerAccount : servicePaymentDetails?.RefFinancerVirtualBankAccountId}
                          handleChange={handlePaymentChange}
                          readOnly={quoteData.RefSellerVirtualBankAccountId ? true : false}
                        />
                      </div>
                    }
                    <div>
                      <div>Payment Account (Borrower)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    <div>
                      {userType!=="COMPANY" ?
                        <div>
                          <Input
                            name="RefCompanyVirtualBankAccountId"
                            placeholder="Ref Company Virtual BankAccount Id *"
                            value={quoteData?.CompanyAccount ? quoteData?.CompanyAccount : servicePaymentDetails?.RefCompanyVirtualBankAccountId}
                            readOnly={true}
                          />
                        </div>
                        :
                        <div>
                          <Select
                            options={provider2}
                            name="RefCompanyVirtualBankAccountId"
                            placeholder="Ref Company Virtual BankAccount Id *"
                            value={quoteData?.CompanyAccount ? quoteData?.CompanyAccount : servicePaymentDetails?.RefCompanyVirtualBankAccountId}
                            handleChange={handlePaymentChange}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </Content>
          )}
          <ButtonDiv>
            <Button
              onClick={() => {
                setPaymentDetailsScreen(false);
              }}>
              Go Back
            </Button>
            <Button onClick={handleAbort}>Abort</Button>

            {/* <Button onClick={(e) => handlePutSubmitPayment(e)}>Confirm</Button> */}

            {(userType =="COMPANY" && quoteData.modeOfPayment != "OFFLINE") && (
              ( (servicePaymentDetails && servicePaymentDetails.RefCompanyVirtualBankAccountId
                ) ? (
                <Button onClick={(e) => handlePutSubmitPayment(e)}>Confirm</Button>
               ):(
                 <button className={classes.isDisabled}> Confirm </button>
               ) )
            )}
            {userType =="FINANCER" && quoteData.modeOfPayment != "OFFLINE" &&(
               ( (servicePaymentDetails && servicePaymentDetails.RefFinancerVirtualBankAccountId && servicePaymentDetails.paymentProviderName) || (quoteData.financeDealStatus == "FINANCED")? (
                <Button onClick={(e) => handlePutSubmitPayment(e)}>Confirm</Button>
               ):(
                 <button className={classes.isDisabled}> Confirm </button>
               ) )
            )}
           {quoteData && quoteData.modeOfPayment == "OFFLINE" && (
             <Button onClick={(e) => handlePutSubmitPayment(e)}>Confirm</Button>
           )}

          </ButtonDiv>

          {(List.isError || isError) && (
            <Content marginBottom="0%">
              {List.isError}
              {isError}
              Something went wrong
            </Content>
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default PaymentDetails;
