export const StepperPosition = (status) => {
  switch (status) {
    case "NEW":
      return { index: 0, status: "progress" };
    case "INTEREST_EXPRESSED":
      return { index: 1, status: "progress" };
    case "IN_PURSUIT":
      return { index: 2, status: "progress" };
    case "QUOTE_CREATED":
      return { index: 3, status: "progress" };
    case "QUOTE_ACCEPTED":
      return { index: 3, status: "completed" };
    case "QUOTE_DECLINED":
      return { index: 3, status: "decline" };
    case "TNC_ACCEPTANCE_PENDING":
      return { index: 4, status: "progress" };
    case "BID_COMPLETED":
      return { index: 5, status: "completed" };
    case "BID_DECLINED":
      return { index: 5, status: "decline" };
    case "SERVICE_IN_PROGRESS":
      return { index: 6, status: "progress" };
    case "DELIVERED":
      return { index: 6, status: "progress" };
    case "DELIVERY_CONFIRMED":
      return { index: 6, status: "completed" };
    case "PAYMENT_PENDING":
      return { index: 7, status: "progress" };
    case "PAYMENT_COMPLETED":
      return { index: 7, status: "completed" };
    case "COMPLETED":
      return { index: 8, status: "completed" };
    case "ABORTED":
      return { index: 8, status: "decline" };
    case "EXPIRED":
      return { index: 8, status: "decline" };
    case "CLOSED":
      return { index: 8, status: "completed" };
    default:
      return 0;
  }
};
