import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import ServiceRequisition from "./ServiceRequisition";
import classes from "../New-Service/service.module.css";
import Deal from "../Deals/Deal";
import FreightDetail from "./Category/Freights";
import Onboarding from "./Category/OnBoarding";
import { UseRequest } from "utils/API/Request.js";
import { Error } from "components/notifications/responseHandle";
import { ToastContainer } from "react-toastify";
import Content from "components/UI/Layout/Content";
import Loader from "components/Loader/Loader";
import Transport from "./Category/Transport";
import { ButtonStyled } from "components/Container.styled";
import Warehousing from "./Category/Warehousing";
import { SERVICE_LABEL } from "utils/Constants";
import InsuranceCredit from "./Category/InsuranceCredit";
import CustomInspection from "./Category/CustomInspection";
import CustomClearance from "./Category/CustomClearance";
import InspectionQuality from "./Category/InspectionQuality";
import InspectionReg from "./Category/InspectionReg";
import Insurance from "./Category/Insurance";
import Crediasses from "./Category/Crediasses";
import Stevedor from "./Category/Stevedor";
import FreightInvoice from "./Category/FreightInvoice";
import ComplianceTrade from "./Category/ComplianceTrade";
import Container from "./Category/Container";
import ImportCompliance from "./Category/ImportCompliance";
import ExportCompliance from "./Category/ExportCompliance";
//import otherTrade from "./Category/OtherTrade";
import TradeContract from "./Category/TradeContract";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import SingleToogleTab from "components/UI/ToogleSelect/SingleToogleTab";

const RequestDetails = ({ referenceId, hanndleCraateGroup }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { instance, accounts } = useMsal();
  const [response, setResponse] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let URL = `${process.env.REACT_APP_SERVICE_URL}services/requisitions/${
      referenceId || id
    }`;
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setIsError(message || "Something went wrong try again....");
          } else {
            setResponse(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const CheckCategory = (category, subCategory) => {
    if (category === "FREIGHT") {
      if (subCategory === "EXPORT") {
        return <FreightDetail response={response} />;
      }
      if (subCategory === "IMPORT") {
        return <FreightDetail response={response} />;
      }
    }

    if (category === "INSPECTION") {
      if (subCategory === "QUALITY") {
        return <InspectionQuality response={response} />;
      }
      if (subCategory === "OTHERS") {
        return <InspectionReg response={response} />;
      }
    }

    //credit assesment
    if (category === "FINANCING") {
      if (subCategory === "CREDIT_ASSESSMENT") {
        return <Crediasses response={response} />;
      }
    }
    if (category === "FINANCING") {
      if (subCategory === "FREIGHT_FINANCING") {
        return <FreightInvoice response={response} />;
      }
    }

    //fright invoice

    //stevedoring
    if (category === "STEVEDORING") {
      if (subCategory === "OTHERS") {
        return <Stevedor response={response} />;
      }
    }

    // COMPLIANCE

    if (category === "COMPLIANCE") {
      if (subCategory === "TRADE") {
        return <ComplianceTrade response={response} />;
      }
      if (subCategory === "IMPORT_DECLARATIONS") {
        return <ImportCompliance response={response} />;
      }
      if (subCategory === "EXPORT_DECLARATIONS") {
        return <ExportCompliance response={response} />;
      }
    }

    if (category === "CUSTOMS") {
      if (subCategory === "CUSTOMS_INSPECTION") {
        return <CustomInspection response={response} />;
      }
      if (subCategory === "CUSTOM_CLEARANCE") {
        return <CustomClearance response={response} />;
      }
    }
    if (category === "INSURANCE") {
      if (subCategory === "CARGO") {
        return <Insurance response={response} />;
      }
      if (subCategory === "CREDIT") {
        return <InsuranceCredit response={response} />;
      }
      if (subCategory === "INLAND") {
        return <Insurance response={response} />;
      }
    }

    if (category === "TRANSPORT") {
      return <Transport response={response} />;
    }
    if (category === "CONTAINER") {
      if (subCategory === "OTHERS") {
        return <Container response={response} />;
      }
    }
    if (category === "WAREHOUSE") {
      return <Warehousing response={response} />;
    }

    if (category === "TRADE_CONTRACT") {
      if (subCategory === "OTHERS") {
        return <TradeContract response={response} />;
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <Content>
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {response && (
            <>
              {!referenceId && (
                <>
                  <div>
                    <div className={classes.headDiv}>
                      <div
                        className="d-flex"
                        style={{
                          cursor: "pointer",
                          color: "rgb(66, 165, 245)",
                          marginBottom: "8px",
                        }}
                      >
                        <BsArrowLeftShort size={22} />
                        <div
                          onClick={() => {
                            navigate("../service-requests");
                          }}
                        >
                          Back to Service Requests
                        </div>{" "}
                        <ButtonStyled
                          style={{
                            borderRadius: "110px",
                            padding: "7px 40px",
                            margin: "3px",
                            marginLeft: "45%",
                            //  marginBottom: "1%",
                          }}
                          // }}
                          // className="containe1"
                          onClick={() => scrollToSection("section1")}
                        >
                          General{" "}
                        </ButtonStyled>
                        <ButtonStyled
                          style={{
                            borderRadius: "110px",
                            padding: "7px 40px",
                            margin: "3px",
                          }}
                          onClick={() => scrollToSection("section2")}
                        >
                          {SERVICE_LABEL(
                            response.serviceCategory &&
                              response.serviceCategory[0]
                          )}{" "}
                        </ButtonStyled>
                        <ButtonStyled
                          style={{
                            borderRadius: "110px",
                            padding: "7px 40px",
                            margin: "3px",
                          }}
                          onClick={() => scrollToSection("section3")}
                        >
                          Deals{" "}
                        </ButtonStyled>
                      </div>
                    </div>
                  </div>
                  <div id="section1">
                    <SingleToogleTab
                      title={<h5 style={{ marginBottom: "2px" }}>General</h5>}
                      select={true}
                      Component={<ServiceRequisition Data={response} />}
                    />
                  </div>
                </>
              )}
              <div id="section2">
                <SingleToogleTab
                  title={
                    <h5 style={{ marginBottom: "2px" }}>
                      {SERVICE_LABEL(
                        response.serviceCategory && response.serviceCategory[0]
                      )}
                    </h5>
                  }
                  select={true}
                  Component={
                    <>
                      {CheckCategory(
                        response.serviceCategory && response.serviceCategory[0],
                        response.serviceSubCategory &&
                          response.serviceSubCategory[0]
                      )}
                    </>
                  }
                />
              </div>

              {!referenceId && (
                <div id="section3">
                  <SingleToogleTab
                    title={<h5 style={{ marginBottom: "2px" }}>Deal</h5>}
                    select={true}
                    Component={<Deal hanndleCraateGroup={hanndleCraateGroup} />}
                  />
                </div>
              )}
            </>
          )}

          {isError && (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          )}

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}
    </div>
  );
};

export default RequestDetails;
