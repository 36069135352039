import React, { useState, useEffect } from 'react';
import {FaHandshake,FaFileInvoice} from "react-icons/fa";
import { FaNetworkWired} from "react-icons/fa";
import {RiUserSettingsLine} from "react-icons/ri"
import { useNavigate } from 'react-router-dom';
import Style from './styles';
import styles from "./finance.module.css";
import { CgChevronDown  , CgChevronUp } from "react-icons/cg";
import { useParams } from 'react-router-dom';

export default ()=>{
    const navigate=useNavigate()
    const [selected, setSelected] = useState(true);
    const [hide, setHide] = useState('');
    let  path = useParams();
    
    const handleNavigate = (e,value) => {
       navigate(`${value}`);
    }

    useEffect(() => {
        let isCancelled = false
        if(!isCancelled){
            setHide(path['*'])
        }

        return () => {
            isCancelled = true;
        }

    },[path['*']])

    return(
        <>
        {hide === ''  && (
            <div className={styles.upperDiv}>
                <div className={styles.middleDiv}>
                    <div className={styles.lowerDiv}>
                        <div className={styles.heading1} onClick={ () => setSelected(!selected)}>
                            <span>Financing Solutions</span>
                            <span                                                                      >
                                { selected === true ? <CgChevronUp size={30}  /> : <CgChevronDown size={30}/>}
                            </span>
                        </div>

                        { selected === true && (
                        <div className={styles.flexRowJustify}>
                            <div  className={styles.card}  onClick={(e)=>handleNavigate(e,"invoicefinancing")}>
                                <span><FaFileInvoice color="#42A5F5"/></span>
                                <div>Invoice Financing</div>
                            </div>
                            <div  className={styles.card} onClick={(e)=>handleNavigate(e,"pofinancing")}>
                                <span><FaNetworkWired color="#42A5F5"/></span>
                                <div>PO Financing</div>
                            </div>
                            <div className={styles.card} onClick={(e)=>handleNavigate(e,"payablesfinancing")}>
                                <span><FaHandshake color="#42A5F5"/></span>
                                <div>Payables Financing</div>
                            </div>
                            <div className={styles.card} onClick={(e)=>handleNavigate(e,"inventoryfinancing")}>
                                <span><RiUserSettingsLine color="#42A5F5"/></span>
                                <div>Inventory Financing</div>
                            </div>
                        </div>
                        )}

                    </div>
                </div>
            </div>
        )}
        </>
    )
}
