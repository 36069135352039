import React, { useCallback, useEffect, useState, useContext } from "react";

import Loader from "../../components/Loader";
import { Routes, Route, Link, Outlet, NavLink } from "react-router-dom";
import UploadInvoice from "./uploadInvoice/Invoice";
import UploadDocs from "./uploadInvoice/Upload";
import { SuccessInvoice } from "../Invoicefinancing/uploadInvoice/SuccessInvoice";
import { SavedInvoice } from "./uploadInvoice/SavedInvoice/savedInvoice";
import styled from "styled-components";
import Summary from "./financing/summary";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";

import {
  Container,
  CardWapper,
  ActionBarStyled,
} from "../../components/Container.styled";
import { CheckPermission } from "utils/Constants/RBAC";
import { invoiceURL, profileURL } from "services/BaseURLInstance";
import { getIdTokenAsync } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { UseRequest } from "utils/API/Request.js";

import Loans from "./loans";
import LoanDetails from "./loandetails";
import Invoice from "./Invoice";
import Overview from "./overview";
import Financing from "./financing";
import styles from "./invoice.module.css";

export default () => {
  const navigate = useNavigate();
  const [Role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);

  const { instance, accounts } = useMsal();
  const tabs = [
    { name: "Overview", value: "overview" },
    { name: "Invoices", value: "invoices" },
    { name: "Finance Deals", value: "financing" },
    { name: "Loans", value: "loans" },
  ];
  //rbac
  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  const handleChatOpen = () => {
    navigate("/chat");
  };
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "98%" }}>
        <Container className={styles.container}>
          <ActionBarStyled className={styles.actionBarStyled}>
            {tabs.map((item, index) => (
              <NavLink
                to={item.value}
                key={index}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <li>{item.name}</li>
              </NavLink>
            ))}
          </ActionBarStyled>
          <div className={styles.containerWrapper}>
            <Routes>
              <Route path="overview" element={<Overview />} />
              <Route path="invoices" element={<Invoice />} />
              <Route path="invoices/:id" element={<SuccessInvoice />} />
              <Route path="invoicesDetails/:id" element={<SavedInvoice />} />
              <Route path="upload" element={<UploadDocs />} />
              <Route path="financing" element={<Financing />} />
              <Route path="success" element={<SuccessInvoice />} />
              <Route path="loans" element={<Loans />} />
              <Route path="loandetails" element={<LoanDetails />} />
              <Route path="/:id" element={<Summary />} />
              <Route path="" element={<Overview />} />
            </Routes>
          </div>
        </Container>
      </div>
      <div style={{ marginTop: "400px" }} onClick={handleChatOpen}>
        <IoIosArrowDropleftCircle size={35} />
      </div>
    </div>
  );
};
