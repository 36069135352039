import react, { useState, useEffect, useContext } from "react";
import DetailComponent from "./detailComponent";
// import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";
import { storeContext } from "../../../../contexts/Store/Store";
import { tradeURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import { TradeContext } from "../../tradeProvider";

export default ({
  flowStage,
  secondFlowSequence,
  initialState,
  stageData,
  Data,
  hanndleCraateGroup,
}) => {
  // const [dropDown, setDropDown] = useState(false);
  const [subStageDate, setSubStageData] = useState();
  const { fetchCompanyList } = useContext(storeContext);
  const { companyList } = useContext(TradeContext);
  const [isLoading, setIsLoading] = useState(false);

  // const handleDrop = () => {
  //   if (dropDown) {
  //     setDropDown(false);
  //   } else {
  //     setDropDown(true);
  //   }
  // };

  useEffect(() => {
    if (stageData) {
      setIsLoading(true);
      const idToken = JSON.parse(sessionStorage.getItem("idToken"));
      let URL = `${tradeURL}tradeLifeCycle?stageSequence=${initialState}&subStageSequence=${secondFlowSequence}&tradeId=${
        Data.tradeId
      }&tradeStage=${
        stageData[secondFlowSequence - 1].tradeStage
      }&tradeSubStage=${stageData[secondFlowSequence - 1].tradeSubStage}`;

      fetchCompanyList(URL, idToken).then((data) => {
        setSubStageData(data);
        setIsLoading(false);
      });
    }
  }, [stageData, secondFlowSequence, flowStage, initialState]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <DetailComponent
          flowStage={flowStage}
          secondFlowSequence={secondFlowSequence}
          initialState={initialState}
          stageData={stageData}
          Data={Data}
          companyList={companyList}
          subStageDate={subStageDate}
          setSubStageData={setSubStageData}
          hanndleCraateGroup={hanndleCraateGroup}
        />
      )}
    </>
  );
};
