import React, { useCallback, useEffect, useContext, useState } from "react";
import { invoiceURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import Loader from "components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import styles from "../invoice.module.css";
import { invoiceFinanceContext } from "contexts/invoiceFinance/invoiceFinanceContext";
import { storeContext } from "contexts/Store/Store";
import { getIdTokenAsync } from "utils/authUtil";
import Content from "components/UI/Layout/Content";
import Table from "react-bootstrap/Table";
import { InvoiceListHeader } from "utils/Section/InvoiceSection";
import cn from "classnames";
import { FindCompanyName } from "utils/helper";
import Upload from "./Upload";
import InvoiceDetailsForm from "./InvoiceDetailsForm/InvoiceDetailsForm";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
// import { InvoiceStatus } from "utils/Section/InvoiceSection";
import MultiSelect from "components/fields/MultiSelect";
import { useMsal } from "@azure/msal-react";
import { STATUS } from "utils/Constants";
import DateInput from "components/fields/DateInput";
import { BsPencilSquare } from "react-icons/bs";
import { OverlayTrigger } from "react-bootstrap";
import Popover from "react-bootstrap/Popover";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FinanceContext } from "../financeProvider";
import { Suspense } from "react";
import NoRecord from "components/no-data/noRecord";
import { HiBadgeCheck } from "react-icons/hi";
export default () => {
  const { instance, accounts } = useMsal();
  const { fetchData } = useContext(storeContext);
  const { Role, companyList } = useContext(FinanceContext);
  const { setSaveInvoiceData } = useContext(invoiceFinanceContext);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isError, setIsError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  const [isInvoiveDetailFormOpen, setIsInvoiveDetailFormOpen] = useState(false);
  const companyObject = companyList;
  const [isLoading, setIsLoading] = useState();
  const [Data, setData] = useState(null);
  const [selectStatus, setSelectStatus] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [searchInvoicesRole, setSearchInvoicesRole] = useState(null);
  const [uploadInvoiceRole, setUploadInvoiceRole] = useState(null);
  const [InvoiceStatus, setInvoiceStatus] = useState([]);
  const [buyerOption, setBuyerOption] = useState([]);
  const [selectedBuyer, setSelectedBuyer] = useState([]);
  const [selectedBuyerId, setSelectedBuyerId] = useState([]);

  const [sellerOption, setSellerOption] = useState([]);
  const [selectedSeller, setSelectedSeller] = useState([]);
  const [selectedSellerId, setSelectedSellerId] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${invoiceURL}invoices?sellerMemberId=${companyId}&buyerMemberId=${companyId}`,
          null,
          response
        ).then((data) => {
          data.sort(
            (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
          );
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            // STATUS
            const uniqueStatus = [
              ...new Set(data.map((item) => item.txlStatus)),
            ];
            let filterStatus =
              uniqueStatus &&
              uniqueStatus.map((item) => {
                return { label: item, value: item };
              });
            setInvoiceStatus(filterStatus);

            setData(data);
            setFilterData(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (Data) {
      // BUYER
      const uniqueBuyer = [
        ...new Set(Data.map((item) => item.txlBuyerMemberId)),
      ];
      let filterBuyer =
        uniqueBuyer &&
        uniqueBuyer.map((item) => {
          return {
            label: companyObject && FindCompanyName(companyObject, item),
            value: item,
          };
        });
      setBuyerOption(filterBuyer);

      // SELLER
      const uniqueSeller = [
        ...new Set(Data.map((item) => item.txlSellerMemberId)),
      ];
      let filterSeller =
        uniqueSeller &&
        uniqueSeller.map((item) => {
          return {
            label: companyObject && FindCompanyName(companyObject, item),
            value: item,
          };
        });
      setSellerOption(filterSeller);
    }
  }, [Data]);

  const handleNavigateInvoice = async (id) => {
    let URL = `${invoiceURL}invoices/${id}`;
    const Data = await fetchData(URL, idToken);
    localStorage.setItem("uploadData", JSON.stringify(Data.data));
    if (Data && Data.data && !Data.error) {
      if (Data.data) {
        // Data.data.sort((a,b)=>Date.parse(b.createdDate) - Date.parse(a.createdDate));
        setSaveInvoiceData(Data.data);
        handleNavigate(`/financing/invoice/invoices/${id}`);
      } else {
        setIsUploadOpen(true);
      }
    }
  };

  const filterByName = (searchData, name) => {
    return name.toLowerCase().includes(searchData);
  };

  useEffect(() => {
    let newdate = Date.parse(dateTo);
    let olddate = Date.parse(dateFrom);
    if (
      (selectedStatusId && selectedStatusId.length > 0) ||
      dateFrom ||
      dateTo ||
      inputText !== "" ||
      selectedBuyerId ||
      selectedSellerId
    ) {
      // function epoch (date) {
      //   return Date.parse(date)
      // }

      // const dateToday = new Date() // Mon Jun 08 2020 16:47:55 GMT+0800 (China Standard Time)
      // const timestamp = epoch(dateToday)

      let filtered =
        Data &&
        Data.filter(
          (data) =>
            (selectedStatusId.length > 0
              ? selectedStatusId.includes(data.txlStatus)
              : data) &&
            (selectedBuyerId.length > 0
              ? selectedBuyerId.includes(data.txlBuyerMemberId)
              : data) &&
            (selectedSellerId.length > 0
              ? selectedSellerId.includes(data.txlSellerMemberId)
              : data) &&
            (dateFrom ? Date.parse(data.createdDate) >= olddate : data) &&
            (dateTo ? Date.parse(data.createdDate) <= newdate : data) &&
            (inputText !== ""
              ? filterByName(inputText, data.invoiceNumber)
              : data)
        );

      setFilterData(filtered);
    } else if (
      selectedStatusId.length === 0 &&
      inputText === "" &&
      !dateFrom &&
      !dateTo &&
      selectedBuyerId.length === 0 &&
      selectedSellerId.length === 0
    ) {
      setFilterData(Data);
    }
  }, [
    selectedStatusId,
    inputText,
    dateFrom,
    dateTo,
    selectedBuyerId,
    selectedSellerId,
  ]);

  const handleDocumentPreview = (id) => {
    if (id === undefined) {
      Error("Not available");
    } else {
      handleNavigate(`/documents/preview/${id}`);
    }
  };

  const handleSearchChange = (e) => {
    setInputText(e.target.value);
  };

  const handleMultiChange = (e, name) => {
    if (name === "status") {
      const { value } = e.target;
      setSelectStatus(value);
    }

    if (name === "datefrom") {
      setDateFrom(new Date(e).toLocaleDateString());
    }

    if (name === "dateend") {
      setDateTo(new Date(e).toLocaleDateString());
    }

    if (name === "Buyer") {
      const { value } = e.target;
      setSelectedBuyer(value);
    }

    if (name === "Seller") {
      const { value } = e.target;
      setSelectedSeller(value);
    }
  };

  useEffect(() => {
    if (selectStatus) {
      let temporaryList = [];
      selectStatus.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedStatusId(temporaryList);
    }

    if (selectedBuyer) {
      let temporaryList = [];
      selectedBuyer.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedBuyerId(temporaryList);
    }

    if (selectedSeller) {
      let temporaryList = [];
      selectedSeller.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedSellerId(temporaryList);
    }
  }, [selectStatus, selectedBuyer, selectedSeller]);

  const Status = (status) => {
    switch (status) {
      case "NEW":
        return STATUS(status, "primary");
      case "IN_PURSUIT":
        return STATUS(status, "primary");
      case "FINANCED":
        return STATUS(status, "primary");
      case "CLOSED":
        return STATUS(status, "danger");
      case "BLOCKED":
        return STATUS(status, "danger");
      case "OPEN":
        return STATUS(status, "primary");
      case "PENDING":
        return STATUS(status, "warning");
      default:
        return;
    }
  };

  const handleListNav = async (id) => {
    let URL = `${invoiceURL}invoices/${id}`;
    const Data = await fetchData(URL, idToken);
    localStorage.setItem("uploadData", JSON.stringify(Data.data));
    if (Data && Data.data && !Data.error) {
      if (Data.data) {
        // Data.data.sort((a,b)=>Date.parse(b.createdDate) - Date.parse(a.createdDate));
        setSaveInvoiceData(Data.data);
        navigate(`/financing/invoice/invoices/${id}`, {
          state: {
            detailEdit: "true",
            selected: 1,
          },
        });
      } else {
        setIsUploadOpen(true);
      }
    }
  };

  useEffect(() => {
    if (Role) {
      setUploadInvoiceRole(
        CheckPermission("Invoice", "Invoices", "UploadInvoices", Role)
      );
      setSearchInvoicesRole(
        CheckPermission("Invoice", "Invoices", "searchInvoices", Role)
      );
    }
  }, [Role]);

  return (
    <>
      <Suspense fallback={<Loader />}>
        <>
          {!isUploadOpen && !isInvoiveDetailFormOpen && (
            <>
              <div style={{ marginBottom: "2%" }}>
                <Content>
                  <div className={styles.invoiceTopHeader}>
                    <div style={{ fontSize: "16px" }}> Search Invoices </div>
                    <button
                      disabled={uploadInvoiceRole === "CREATE" ? false : true}
                      style={{ fontSize: "12px", marginLeft: "75%" }}
                      className={styles.uploadInvoiceBtn}
                      onClick={() => setIsUploadOpen(true)}>
                      Upload Invoice
                    </button>
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      key="top"
                      placement="top"
                      overlay={
                        <Popover id="popover-positioned-top">
                          <Popover.Body>
                            <strong>Upload New Invoice</strong>
                          </Popover.Body>
                        </Popover>
                      }>
                      <span className="text-warning">
                        <AiOutlineInfoCircle size={26} />
                      </span>
                    </OverlayTrigger>
                  </div>

                  <div className={styles.searchDiv}>
                    <div>
                      <MultiSelect
                        name="memberlist"
                        selected={selectStatus}
                        placeholder="Status"
                        options={InvoiceStatus}
                        handleChange={(e) => handleMultiChange(e, "status")}
                      />
                    </div>
                    <div>
                      <DateInput
                        backDate={new Date()}
                        name="DateFrom"
                        value={
                          dateFrom
                          //|| new Date().toLocaleDateString()
                        }
                        placeholder="Date From *"
                        handleChange={(e) => handleMultiChange(e, "datefrom")}
                      />
                    </div>
                    <div>
                      <DateInput
                        backDate={new Date()}
                        name="dateend"
                        value={
                          dateTo
                          //|| new Date().toLocaleDateString()
                        }
                        placeholder="Date To *"
                        handleChange={(e) => handleMultiChange(e, "dateend")}
                      />
                    </div>

                    <input
                      placeholder="Search..."
                      name="search"
                      value={inputText}
                      type="search"
                      onChange={handleSearchChange}
                      style={{ padding: "0% 1%" }}
                      disabled={
                        searchInvoicesRole === "DELETE" ||
                        searchInvoicesRole === "NOTAPP"
                          ? true
                          : false
                      }
                    />

                    {/* {searchInvoicesRole === "DELETE" ||
                    searchInvoicesRole === "NOTAPP" ? (
                      <div> </div>
                    ) : (
                      <input
                        placeholder="Search..."
                        name="search"
                        value={inputText}
                        type="search"
                        onChange={handleSearchChange}
                        style={{ padding: "0% 1%" }}
                      />
                    )} */}
                  </div>

                  <div className={styles.searchDiv1}>
                    <div>
                      <MultiSelect
                        name="memberlist"
                        selected={selectedBuyer}
                        placeholder="Buyer"
                        options={buyerOption}
                        handleChange={(e) => handleMultiChange(e, "Buyer")}
                      />
                    </div>
                    <div>
                      <MultiSelect
                        name="memberlist"
                        selected={selectedSeller}
                        placeholder="Seller"
                        options={sellerOption}
                        handleChange={(e) => handleMultiChange(e, "Seller")}
                      />
                    </div>
                  </div>
                </Content>
              </div>

              <Content marginTop="-1%" className={styles.wrapper}>
                <h6>Search Results</h6>
                <Table bordered responsive className={cn(styles.Table)}>
                  <thead>
                    <tr>
                      {InvoiceListHeader.map((header, index) => (
                        <td key={index}>{header.name}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {filterData &&
                      filterData.map((user, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              color: "#42a5f5",
                              textDecorationLine: "underline",
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              handleNavigateInvoice(user.txlInvoiceId)
                            }
                            className={cn(styles.columnHover)}>
                            {user.invoiceNumber}
                          </td>
                          <td>
                            {companyObject &&
                              FindCompanyName(
                                companyObject,
                                user.txlBuyerMemberId
                              )}{" "}
                            {user?.buyerConsent === "COMPLETED" && (
                              <HiBadgeCheck
                                style={{
                                  color: "#4CAF50",
                                }}
                                size={24}
                              />
                            )}
                          </td>
                          <td>
                            {companyObject &&
                              FindCompanyName(
                                companyObject,
                                user.txlSellerMemberId
                              )}
                          </td>
                          <td>
                            {new Date(user.createdDate).toLocaleDateString()}
                          </td>
                          <td style={{ minWidth: "100px" }}>
                            {Status(user.txlStatus)}
                          </td>
                          <td className={styles.invoiceNavigation}>
                            <button
                              onClick={() =>
                                handleDocumentPreview(user.document.id)
                              }
                              style={{
                                background: "transparent",
                                border: "none",
                                marginRight: "2px",
                              }}>
                              <AiFillEye size={26} color="#42A5F5" />
                            </button>
                            <button
                              onClick={(e) => handleListNav(user.txlInvoiceId)}
                              style={{
                                background: "transparent",
                                border: "none",
                                marginRight: "2px",
                              }}>
                              <BsPencilSquare size={20} color="#42A5F5" />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {filterData && filterData.length === 0 && <NoRecord />}
              </Content>
            </>
          )}

          {isError && <Content>{isError}</Content>}

          {isUploadOpen && !isInvoiveDetailFormOpen && (
            <Upload
              setIsUploadOpen={setIsUploadOpen}
              setIsInvoiveDetailFormOpen={setIsInvoiveDetailFormOpen}
            />
          )}
          {isInvoiveDetailFormOpen && (
            <InvoiceDetailsForm
              setIsInvoiveDetailFormOpen={setIsInvoiveDetailFormOpen}
            />
          )}
        </>
      </Suspense>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
