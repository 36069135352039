import React, { useState, useEffect } from "react";
import styles from "./Document.module.css";
import Loader from "components/Loader/Loader";
import MultiSelect from "components/fields/MultiSelect";
import { Grid } from "@mui/material";
import GlobalDoc from "./docsum-category.json";
import { InputLabel, Button } from "@material-ui/core";
import { docUpdateURL } from "services/BaseURLInstance";
import { CompanyList } from "utils/API/CompanyList.js";
import Content from "components/UI/Layout/Content";
import { Success, Error } from "components/notifications/responseHandle";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

export default ({
  // listMember,
  companyId,
  // idToken,
  tempData,
  memListWithLoggedUser,
  docResponse,
  currentNetwork, // Using in Newtwork Section Company Details Document
  documentTemplate, // Using for Request Document
}) => {
  const DocumentType = [
    {
      label: "Global",
      value: "Global",
    },
    {
      label: "Director",
      value: "Director",
    },
    {
      label: "Trade",
      value: "Trade",
    },
  ];

  const { instance, accounts } = useMsal();
  const [companyList, setCompanyList] = useState(null);
  const { response, isLoading, isError } = CompanyList();

  useEffect(() => {
    if (response) {
      let temp = response.map((item) => {
        return { label: item.memberName, value: item.memberUID };
      });
      setCompanyList(temp);
    }
  }, [response]);

  const [memberList, setMemberList] = useState("");
  const [loader, setLoader] = useState(!tempData.id ? true : false);
  const [selectList, setSelectList] = useState([]);

  const [docTemplete, setDocTemplete] = useState({
    label: documentTemplate === "" ? "Global" : documentTemplate,
    value: documentTemplate === "" ? "Global" : documentTemplate,
  });

  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const [selectedListValue, setSelectedListValue] = useState(null);
  const [globalCategory, setGlobalCategory] = useState(null);
  const [globalSubCategory, setGlobalSubCategory] = useState(null);

  useEffect(() => {
    if (tempData.id) {
      setLoader(false);
      let options = [];
      memListWithLoggedUser.forEach((obj) => {
        let sampleObj = {
          value: obj.memberUID,
          label: obj.memberName,
          type: obj.memberType,
        };
        options.push(sampleObj);
      });
      setMemberList(options);
      setCategory(tempData.docCategory);
      setSubCategory(tempData.docSubCategory);
      // updateSubCategory(tempData.docCategory);
    }
  }, [tempData, loader]);

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectList(value);
  };

  const handleShareDocument = async () => {
    if (tempData?.id && selectList.lenght > 0) {
      Error("Must select one document and categories");
      return;
    }

    const requestParams = {
      documentIds: [tempData.id],
      ownerMemberIds: [docResponse?.owner], // to be fixed
      receiverMemberIds:[
        selectList.map((obj) => obj.value)?.length > 0
          ? selectList.map((obj) => obj.value)
          : companyId],

      //receiverMemberIds: selectList.map((obj) => obj.value),
      ownerMemberIds: [tempData?.ownerId],
      requesterMemberId: companyId,
    };

    const options = {
      method: "POST",
      body: requestParams,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${docUpdateURL}documents/consent-requests`,
        options,
        response
      ).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          Success("Requested Successfully");
        }
      });
    });
  };

  useEffect(() => {
    if (currentNetwork && companyList) {
      let TEMP =
        companyList &&
        companyList.filter((data) => data.value === currentNetwork);
      if (TEMP && TEMP.length > 0) {
        setSelectedListValue(TEMP);
      }
    }
  }, [currentNetwork, companyList]);

  useEffect(() => {
    if (tempData && tempData.id) {
      setCategory(tempData.docCategory);
      setSubCategory(tempData.docSubCategory);
    }
  }, [tempData]);

  useEffect(() => {
    if (GlobalDoc && category) {
      let options = [];
      GlobalDoc.forEach((obj) => {
        let sampleObj = {
          value: obj.name,
          label: obj.name,
        };
        options.push(sampleObj);
      });
      setGlobalCategory(options);

      let temp = GlobalDoc.filter((doc) => doc.name === category);
      if (temp && temp.length > 0) {
        let options1 = [];
        temp.forEach((obj) => {
          let sampleObj = {
            value: obj,
            label: obj,
          };
          options1.push(sampleObj);
        });
        setGlobalSubCategory(options1);
      }
    }
  }, [GlobalDoc, category]);

  return (
    <div className={styles.Card} style={{ marginTop: "16px" }}>
      {isLoading ? (
        <Content style={{ marginTop: "2%" }}>
          Loading...
          <Loader />
        </Content>
      ) : (
        <>
          {response && (
            <div>
              <div
                style={{
                  padding: "16px",
                  background: "black",
                  borderRadius: "10px",
                }}>
                {loader && (
                  <div
                    style={{
                      color: "red",
                      fontWeight: "bolder",
                      justifyContent: "center",
                    }}>
                    Before the request, Please select the document
                  </div>
                )}

                <Grid container style={{ padding: "40px" }}>
                  <h5>Request Document</h5>
                  <Grid item xs={12} md={12} style={{ marginBottom: "20px" }}>
                    <InputLabel
                      id="brand-label"
                      style={{ color: "white", margin: "10px 0px" }}>
                      {"Document Template"}
                    </InputLabel>
                    <MultiSelect
                      name="memberlist"
                      selected={category ? docTemplete : null}
                      options={DocumentType}
                      // handleChange={handleChange}
                      isDisabled={currentNetwork ? true : false}
                    />
                  </Grid>

                  <Grid item xs={12} md={12} style={{ marginBottom: "20px" }}>
                    <InputLabel
                      id="brand-label"
                      style={{ color: "white", margin: "10px 0px" }}>
                      {"Select Members"}
                    </InputLabel>
                    <MultiSelect
                      name="memberlist"
                      placeholder="Select members..."
                      selected={
                        selectedListValue ? selectedListValue : selectList
                      }
                      // options={companyList}
                      options={
                        companyList &&
                        companyList.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )
                      }
                      handleChange={handleChange}
                      isDisabled={currentNetwork ? true : false}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Grid
                      item
                      xs={12}
                      md={12}
                      sm={12}
                      style={{ marginBottom: "20px" }}>
                      <InputLabel
                        id="brand-label"
                        style={{ color: "white", margin: "10px 0px" }}>
                        {"Select Document Category"}
                      </InputLabel>
                      <MultiSelect
                        name="memberlist"
                        placeholder="Select members..."
                        selected={{
                          label: category,
                          value: category,
                        }}
                        options={globalCategory}
                        // handleChange={handleTargetValueChange}
                        isDisabled={currentNetwork ? true : false}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      sm={12}
                      style={{ marginBottom: "20px" }}>
                      <InputLabel
                        id="brand-label"
                        style={{ color: "white", margin: "10px 0px" }}>
                        {"Select Sub Category"}
                      </InputLabel>
                      <MultiSelect
                        name="memberlist"
                        placeholder="Select members..."
                        selected={{
                          label: subCategory,
                          value: subCategory,
                        }}
                        options={globalSubCategory}
                        // handleChange={handleTargetValueChange}
                        isDisabled={currentNetwork ? true : false}
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Button
                      variant="contained"
                      size="large"
                      style={{ backgroundColor: "#42A5F5", float: "right" }}
                      onClick={handleShareDocument}>
                      Send Request
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}

          {isError && <Content>Something went wrong</Content>}
        </>
      )}
    </div>
  );
};
