import axios from 'axios';
import { useMsal } from "@azure/msal-react";
import { useState } from 'react';
import { getIdToken } from "utils/authUtil";

export const ApiToken = () => {
    const [idToken, setIdToken] = useState(null);
    const { instance, accounts } = useMsal();
    getIdToken(instance, accounts, setIdToken);
    return idToken;
}

export const makeRequest = ({ body, baseURL = '${process.env.REACT_APP_BFF_URL}', method = 'GET', route, idToken }) => {
    return fetch(baseURL + route, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + idToken,
            "x-api-key":
                "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
    }).then(async response => {
        if (response.ok) {
            return response.json();
        }
        throw [response.status, await response.json()];
    });
}