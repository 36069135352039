import React, { useState, useContext } from 'react';
import Styles from './styles';
import useAxios from "../../../services/useAxios";
import { quoteApi, quoteSubmitAPI, NetworkURL } from '../../../services/BaseURLInstance';
import { useNavigate } from 'react-router-dom';
import { ButtonStyled } from "../../../components/Container.styled";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { useEffect } from 'react';
import { storeContext } from '../../../contexts/Store/Store';
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import TextArea from 'components/fields/TextArea';


export default ({ data }) => {

    const [quote, setQuote] = useState({
        createdBy: "",
        createdDate: 0,
        financeCurrency: "",
        financeDealId: "",
        financeDealStatus: "",
        financeProductCategory: "",
        financeProductType: "",
        financerMemberUID: "",
        financerName: "",
        interestRate: "",
        lastUpdateBy: "",
        lastUpdatedDate: 0,
        notes: "abcd",
        paymentMethodType: "",
        refFinanceRequisitionId: "",
        traderMemberUID: "",
        financeDealExpiryDate: 0,
        financeDisbursalDate: 0,
        financeRepaymentDate: 0
    });

    const { fetchCompanyList } = useContext(storeContext)
    const { instance, accounts } = useMsal();
    
    const [companyList, setCompanyList] = useState({
        companies: null,
        userType: ""
    });

    const [idToken, setIdToken] = useState(null);
    getIdToken(instance, accounts, setIdToken);
    const companyId = getClaim(accounts, "company_member_id");
    const navigate = useNavigate();


    const { response, isLoading, error } = useAxios({
        api: quoteApi,
        method: "get",
        url: `finance/deals/${data.financeDealId}`,
        config: JSON.stringify({
            requireAuthentication: true,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
        }),
    });

    const handleBackToFinanceDeal = () => {
    }
    console.log("financeDealSummary", data);
    useEffect(() => {
        let URL = `${NetworkURL}members?memberId=${companyId}`;
        fetchCompanyList(URL, idToken).then((companyList) => {
            console.log("companyList", companyList)
            let user = companyList.filter(item => {
                if (item.memberUID === companyId) {
                    return item.memberType
                }
            })
            console.log("user", user);
            setCompanyList({
                ...companyList,
                companies: companyList,
                userType: user[0].memberType
            })
        }).catch((error) => {
            console.log(error)
        })
    }, [])


    useEffect(() => {
        if (response && companyList) {
            setQuote({
                ...quote,
                createdBy: response.createdBy || "",
                createdDate: response.createdDate || 0,
                financeAmount: response.financeAmount || "",
                financeCurrency: response.financeCurrency || "",
                financeDealId: response.financeDealId || "",
                financeDealStatus: response.financeDealStatus || "",
                financeProductCategory: response.financeProductCategory || "",
                financeProductType: response.financeProductType || "",
                financerMemberUID: response.financerMemberUID || "",
                financerName: response.financerName || "",
                interestRate: response.interestRate || "",
                lastUpdateBy: response.lastUpdateBy || "",
                lastUpdatedDate: response.lastUpdatedDate || 0,
                notes: response.notes || "",
                paymentMethodType: response.paymentMethodType || "",
                refFinanceRequisitionId: response.refFinanceRequisitionId || "",
                traderMemberUID: response.traderMemberUID || "",
                requestedFinanceAmount: response.requestedFinanceAmount || "",
                financeRepaymentAmount: response.financeRepaymentAmount || "",
                financeDealExpiryDate: response.financeDealExpiryDate || 0,
                financeDisbursalDate: response.financeDisbursalDate || 0,
                financeRepaymentDate: response.financeRepaymentDate || 0,

            })
        }
    }, [response, companyList])

    const handleChange = (e) => {
        let val = e.target.value;
        if (e.target.name === "financeDealExpiryDate") {
            val = parseFloat(val)
            // val=new Date(val).toLocaleDateString()

        }
        else if (e.target.name === "financeDisbursalDate" && (e.target.value !== null || e.target.value !== "")) {
            val = parseFloat(val)
            // val=new Date(val).toLocaleDateString()
        }
        else if (e.target.name === "lastUpdatedDate" && (e.target.value !== null || e.target.value !== "")) {
            val = parseFloat(val)
            // val=new Date(val).toLocaleDateString()
        }
        else if (e.target.name === "createdDate" && (e.target.value !== null || e.target.value !== "")) {
            val = parseFloat(val)
            // val=new Date(val).toLocaleDateString()
        }
        else if (e.target.name === "financeRepaymentDate" && (e.target.value !== null || e.target.value !== "")) {
            val = parseFloat(val)
            // val=new Date(val).toLocaleDateString()
        }

        setQuote({
            ...quote,
            [e.target.name]: val
        })

    }


    const handleAcceptQuote = async (e, value) => {
        let putData = quote;
        putData.financeDealStatus = value
        console.log("handleAcceptQuote", quote)
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: JSON.stringify(putData)
        };
        //console.log("quoteAPI",`quoteApi}finance/deals/${data.financeDealId}`)
        await fetch(`${quoteSubmitAPI}finance/deals/${data.financeDealId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("quoteAccept", data)
                setQuote(data)
            })
            .catch(err => {
                console.log("err", err);
            })
        console.log("quoteData", quote)
    }


    const loanCreate = {
        financeRequisitionId: "",
        traderMemberUID: "",
        financerMemberUID: "",
        loanStatus: "DRAFT",
        financeProductType: "FINANCE_INVOICE",
        financeProductCategory: "TERM_FINANCE",
        referenceFinanceInstrumentId: "476e09bd-cb60-4d77-b294-3741f18ae10d",
        paymentCurrencyType: "FIAT",
        paymentMethodType: "ACCOUNT",
        financeCurrency: "INR",
        interestRate: "5",
        financeAmount: "1200",
        financeDisbursalDate: "",
        financeDisbursalStatus: "DISBURSEMENT_COMPLETED",
        financeRepaymentAmount: "12",
        financeRepaymentDate: "",
        financeRePaymentStatus: "REPAYMENT_DUE",
        loanFees: "123"
    }

    const handleBookLoan = async () => {
        loanCreate.financeRequisitionId = quote.refFinanceRequisitionId;
        loanCreate.financerMemberUID = quote.financerMemberUID;
        loanCreate.traderMemberUID = quote.traderMemberUID;
        loanCreate.interestRate = quote.interestRate;

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: JSON.stringify(loanCreate)
        };
        //console.log("quoteAPI",`quoteApi}finance/deals/${data.financeDealId}`)
        await fetch(`${quoteSubmitAPI}loans`, requestOptions)
            .then(response => response.json())
            .then(data => {
                console.log("loansCreated", data)
                setQuote(data)
                data.data.setSydicateMenu("syndicatelending");
                data.data.setSyndicate(data)
            })
            .catch(err => {
                console.log("err", err);
            })
        //console.log("quoteData",quote)
    }



    return (
        <div style={{ backgroundColor: "black", padding: "2%", marginTop: "1%", borderRadius: "5px" }}>
            {(quote && companyList.companies) &&
                <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="financeDealId"
                            placeholder="Deal Id *"
                            value={quote.financeDealId}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        {
                            (companyList.companies.map(item => {
                                if (item.memberUID === quote.traderMemberUID) {
                                    return <Input
                                        name="traderMemberUID"
                                        placeholder="Seller ID *"
                                        value={item.memberName}
                                        handleChange={handleChange}
                                    />
                                }
                            }))
                        }
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="financeProductCategory"
                            placeholder="Loan Product Type *"
                            value={quote.financeProductCategory}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="referenceFinanceInstrumentId"
                            placeholder="Invoice Reference *"
                            value={quote.refFinanceRequisitionId}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="financeAmount"
                            placeholder="Finance Amount *"
                            value={(quote.financeAmount)?quote.financeAmount.toString():""}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="interestRate"
                            placeholder="Interest Rate *"
                            value={quote.interestRate}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="requestedFinanceAmount"
                            placeholder="Requested Finance Amount *"
                            value={(quote.requestedFinanceAmount)?quote.requestedFinanceAmount.toString():""}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="financeDealStatus"
                            placeholder="Finance Status *"
                            value={quote.financeDealStatus}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px", marginRight: ".82%" }}>
                        <DateInput
                            name="financeDealExpiryDate"
                            placeholder="Deal Expiry Date *"
                            value={quote.financeDealExpiryDate}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="paymentMethodType"
                            placeholder="Payment Method *"
                            value={quote.paymentMethodType}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px", marginRight: ".82%" }}>
                        <DateInput
                            name="financeDisbursalDate"
                            placeholder="Finance Date *"
                            value={quote.financeDisbursalDate}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        {
                            (companyList.companies.map(item => {
                                if (item.memberUID === quote.createdBy) {
                                    return <Input
                                        name="lastUpdateBy"
                                        placeholder="Created By *"
                                        value={item.memberName}
                                        handleChange={handleChange}
                                    />
                                }
                            }))
                        }
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="financeCurrency"
                            placeholder=" Currency *"
                            value={quote.financeCurrency}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        {
                            (companyList.companies.map(item => {
                                if (item.memberUID === quote.financerMemberUID) {
                                    return <Input
                                        name="financerMemberUID"
                                        placeholder="Financer Id *"
                                        value={item.memberName}
                                        handleChange={handleChange}
                                    />
                                }
                            }))
                        }
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px", marginRight: ".82%" }}>
                        <DateInput
                            name="financeRepaymentDate"
                            placeholder="Repayment Date *"
                            value={quote.financeRepaymentDate}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px", marginRight: ".82%" }}>
                        <DateInput
                            name="createdDate"
                            placeholder="Created Date *"
                            value={quote.createdDate}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        {
                            (companyList.companies.map(item => {
                                if (item.memberUID === quote.lastUpdateBy) {
                                    return <Input
                                        name="lastUpdateBy"
                                        placeholder="Last Updated By *"
                                        value={item.memberName}
                                        handleChange={handleChange}
                                    />
                                }
                            }))
                        }
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px", marginRight: ".82%" }}>
                        <DateInput
                            name="lastUpdatedDate"
                            placeholder="Last Updated Date *"
                            value={quote.lastUpdatedDate}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <TextArea
                            name="notes"
                            placeholder="Append notes in chat:"
                            value={quote.notes}
                            handleChange={handleChange}
                        />
                    </Grid>
                </Grid>
            }
            {
    (quote.financeDealStatus === "NEW") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "FINANCER") &&
            <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "INTEREST_EXPRESSED")}>Show Interest</ButtonStyled>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline</ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
        <ButtonStyled onClick={handleBookLoan}>Book Loan</ButtonStyled>

    </div>
}
{
    (quote.financeDealStatus === "INTEREST_EXPRESSED") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "FINANCER") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DUE_DILIGENCE_INITIATED")}>Initiate Due Diligence</ButtonStyled>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline </ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "DUE_DILIGENCE_INITIATED") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "FINANCER") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DUE_DILIGENCE_COMPLETED")}>Complete Due Diligence</ButtonStyled>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline </ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "DUE_DILIGENCE_COMPLETED") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "FINANCER") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_GENERATED")}>Create Quote</ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "QUOTE_GENERATED") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "COMPANY") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_ACCEPTED")}>Accept Quote</ButtonStyled>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_DECLINED")}>Decline Quote</ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "QUOTE_DECLINED") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "FINANCER") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "QUOTE_GENERATED")}>Revise Quote</ButtonStyled>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "DECLINED")}>Decline</ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "QUOTE_ACCEPTED") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "FINANCER") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "SHOW INTEREST")}>Upload T&C</ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
        <ButtonStyled onClick={handleBookLoan}>Book Loan</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "PENDING T&C ACCEPTANCE") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "COMPANY") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "SHOW INTEREST")}>Process T&C</ButtonStyled>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "FINANCE_AGREEMENT_COMPLETE")}>Accept T&C</ButtonStyled>

            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "FINANCE AGREEMENT COMPLETE") && <div className='requsitionButtonDiv'>
        {
            (companyList.userType === "FINANCER") && <div>
                <ButtonStyled onClick={(e) => handleAcceptQuote(e, "SHOW INTEREST")}>Book Loan</ButtonStyled>
            </div>
        }
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "FINANCED") && <div className='requsitionButtonDiv'>
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "DECLINED") && <div className='requsitionButtonDiv'>
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled onClick={(e) => handleAcceptQuote(e, "SHOW INTEREST")}>Accept Quote</ButtonStyled>
        <ButtonStyled>Decline Quote</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}
{
    (quote.financeDealStatus === "ABORT") && <div className='requsitionButtonDiv'>
        <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled>
        <ButtonStyled onClick={(e) => handleAcceptQuote(e, "")}>Accept Quote</ButtonStyled>
        <ButtonStyled>Decline Quote</ButtonStyled>
        <ButtonStyled>Abort</ButtonStyled>
    </div>
}

        </div>
    )
}
