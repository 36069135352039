import _ from "lodash";
export const SyndicateOverviewValdations = (payload) => {
    if (_.isEmpty(payload.trancheFinanceAmount)) {
      return "Please Enter Tranche Finance Amount";
    }
    if (_.isEmpty(payload.interestRate)) {
      return "Please Enter Interest Rate";
    }
    if (isValidDate(payload.loanFinanceStartDate)) {
        return "Please Enter Loan Finance Start Date";
      }
      if (isValidDate(payload.loanFinanceEndDate)) {
        return "Please Enter Loan Finance End Date";
      }
    }
    export const SyndicateSelectedFinancerValdations = (payload) => {
        if (payload.financers?.length===0) {
          return "Please Select Financer";
        }
    }
    export const SyndicateshowfinacerTableValdations = (payload) => {
          for(let i=0; i<payload.length;i++){
            if(_.isEmpty(payload[i]?.trancheFinanceAmount)){
                return "Please Enter Tranche Finance Amount";
            }
            if(_.isEmpty(payload[i]?.interestRate)){
                return "Please Enter Interest Rate";
            }
            if(isValidDate(payload[i]?.trancheFinanceStartDate)){
                return "Please Enter Tranche Finance Start Date";
            }
            if(isValidDate(payload[i]?.trancheFinanceEndDate)){
                return "Please Enter Tranche Finance End Date";
            }
            if(_.isEmpty(payload[i]?.assetRatio)){
                return "Please Enter Asset Ratio";
            }
          }  
    }

    export const SyndicateshowDradownTableValdations = (payload) => {
        for(let i=0; i<payload.length;i++){
          if(_.isEmpty(payload[i]?.Bank)){
              return "Please Select Bank";
          }
          if(_.isEmpty(payload[i]?.trancheComponentDrawdownAmount)){
              return "Please Enter Tranche Component Drawdown Amount";
          }
          if(isValidDate(payload[i]?.trancheComponentDrawdownStartDate)){
              return "Please Enter Tranche Component Drawdown Start Date";
          }
          if(isValidDate(payload[i]?.trancheComponentDrawdownEndDate)){
              return "Please Enter Tranche Component Drawdown End Date";
          }
        }  
  }

    const isValidDate = (dateObject) =>{
        return new Date(dateObject).toString() === 'Invalid Date';
    }