import React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import styles from "./Fields.module.css";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgb(66, 165, 245)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(66, 165, 245)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(66, 165, 245) ! important" ,
    },
    "&:hover fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
  },
});

export default function DateInput({
  placeholder,
  value,
  handleChange,
  readOnly,
  name,
  backDate,
  maxDate,
}) {
  return (
    <div className={styles.inputWrapper1}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={4}>
          <DatePicker
            name={name}
            label={placeholder}
            value={value}
            readOnly={readOnly}
            minDate={ backDate ? '' : new Date()}
            maxDate={maxDate ? new Date(): ""}
            onChange={(date) => handleChange(date)}
            renderInput={(params) => (
              <CssTextField
                InputLabelProps={{
                  style: { color: "rgb(66, 165, 245)" },
                }}
                sx={{
                  svg: { color: "rgb(66, 165, 245)" },
                }}
                {...params}
              />
            )}
          />
        </Stack>
      </LocalizationProvider>
    </div>
  );
}
