import React, { useState, useEffect } from "react";
import classes from "../../New-Service/Category/freight.module.css";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import port_codes from "jsons/PortCodes.json";
import { Grid } from "@mui/material";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";


const Container = ({ response }) => {
  const [serviceDetails, setServiceDetails] = useState(null);
  // useEffect(() => {
  //     if (serviceDetails && serviceDetails.ServiceReqDetails) {
  //         setServiceDetails(JSON.parse(serviceDetails.ServiceReqDetails))
  //     }
  // }, [response]);
  let containerTypeOptions = [
    {
      label: "Dry storage container",
      value: "DRY_STORAGE_CONTAINER",
    },
    {
      label: "Flat rack container",
      value: "FLAT_RACK_CONTAINER",
    },
    {
      label: "Open top container",
      value: "OPEN_TOP_CONTAINER",
    },
    {
      label: "Open side storage container",
      value: "OPEN_SIDE_STORAGE_CONTAINER",
    },
    {
      label: "Refrigerated ISO container",
      value: "REFRIGERATED_ISO_CONTAINER",
    },
    {
      label: "ISO Tanks",
      value: "ISO_Tanks",
    },
    {
      label: "Half height containers",
      value: "HALF_HEIGHT_CONTAINERS",
    },
    {
      label: "Special purpose containers",
      value: "SPECIAL_PURPOSE_CONTAINER",
    },
    {
      label: "Others",
      value: "OTHERS",
    },
  ];

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (response && response.ServiceReqDetails) {
        setServiceDetails(JSON.parse(response.ServiceReqDetails))
       
      }
      
    }
    return () => {
      isCancelled = true;
    };
  }, [response]);

  const [DatePicker1, setDatePicker1] = useState(new Date());
  const [DatePicker2, setDatePicker2] = useState(new Date());
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);

  console.log("Responseee", response);
  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );
 
  return (<>
    {response && serviceDetails ?
      <>
        <div style={{  margin: "10px" }}>Mode of Shipment</div>
        <div style={{ width: '25%', margin: "10px" }}>
          <Input
            name="shipmentMode"
            placeholder="Mode of Shipment"
            readOnly={true}
            value={serviceDetails.shipmentMode}
          />
        </div>
        <div style={{ margin: "10px" }}>
          Shipment details
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Select
              options={Countries}
              name="countryFrom"
              placeholder="Country  From"
              value={serviceDetails.countryFrom}
              readOnly={true}
            />
          </div>


          <div style={{ width: "50%", margin: "10px" }}>
            <Select
              options={Countries}
              name="countryTo"
              placeholder="Country To"
              value={serviceDetails.countryTo}
              readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input

              name="cityFrom"
              placeholder="City From"
              value={serviceDetails.cityFrom}
              readOnly={true}
            />
          </div>


          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityTo"
              placeholder="City To"
              value={serviceDetails.cityTo}
              readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Select
              options={port_code}
              name="portOrigin"
              placeholder="From Port (If Applicable)"
              value={serviceDetails.portOrigin}
              readOnly={true}
            />
          </div>


          <div style={{ width: "50%", margin: "10px" }}>
            <Select
              options={port_code}
              name="destinationPort"
              placeholder="To Port (If Applicable)"
              value={serviceDetails.destinationPort}
              readOnly={true}
            />
          </div>
        </div>
        <div>
          {/* <div className={classes.source}>
              <div style={{ marginBottom: "1.2%", width: "50%" }}>
                <label>Estimated Date of Loading</label>
              </div>
              <div style={{ marginBottom: "1.2%", width: "50%" }}>
                <label>Estimated date of Unloading</label>
              </div>
            </div> */}
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Grid item xs={12} md={6} >
                <Input
                  name="loadingDate"
                  placeholder="Estimated Loading Date"
                  value={new Date(
                    serviceDetails.loadingDate
                  ).toLocaleDateString()}
                  readOnly={true}
                />
              </Grid>
            </div>
            <div style={{ width: "50%", margin:"10px" }}>
              <Grid item xs={12} md={5.9} >
                <Input
                  name="unloadingDate"
                  placeholder="Estimated Unloading Date"
                  value={new Date(
                    serviceDetails.unloadingDate
                  ).toLocaleDateString()}
                  readOnly={true}
                />
              </Grid>
            </div>
          </div>
        </div>
        <div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="containerCount"
                placeholder="Expected No. of Containers"
                readOnly={true}
                value={serviceDetails.containerCount}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="containerWeight"
                placeholder="Average Weight per Container (Metric Ton)"
                readOnly={true}
                value={serviceDetails.containerWeight}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={containerTypeOptions}
                name="containerType"
                placeholder="Container Type"
                readOnly={true}
                value={serviceDetails.containerType}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="containerSize"
                placeholder="Container Size"
                readOnly={true}
                value={serviceDetails.containerSize}
              />
            </div>
          </div>

          <div className={classes.source}>

            <div style={{ width: "100%", margin:"10px" }}>
            <MultiSelect
              options={Industry}
              name="commodity"
              placeholder="Product Category"
              isDisabled={true}
              selected={serviceDetails.commodity}
            />
            </div>
          </div>
        </div>
      </> : <>HI</>}
  </>
  );
};

export default Container;
