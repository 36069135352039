// USING this for Fetch Request and POST Request
import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { Error as ERROR } from "components/notifications/responseHandle";

export const useFetch = (URL, initialData) => {
  const [response, setResponse] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { instance, accounts } = useMsal();

  useEffect(() => {
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      const requestOptions = {
        method: "GET",
        requireAuthentication: true,
        headers: {
          Authorization: "Bearer " + (response && response.idToken),
          "Content-Type": "application/json",
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
      };

      fetch(URL, requestOptions)
        .then((res) => res.json())
        .then((response) => {
          setIsLoading(false);
          let { message, status } = response;
          if (message || status) {
            setIsError(message || "Something Went Wrong");
          } else {
            setResponse(response);
          }
        });
    });
  }, []);

  return { response, isLoading, isError };
};

export const UseRequest = async (url, options, response) => {
  const { method, body } = options || {};
  const Token =
    (response && response.idToken) ||
    JSON.parse(sessionStorage.getItem("idToken"));

  const requestOptions = {
    method: method || "GET",
    requireAuthentication: true,
    headers: {
      Authorization: "Bearer " + Token,
      "Content-Type": "application/json",
      "x-api-key":
        "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    },
    body: JSON.stringify(body),
  };

  return await fetch(url, requestOptions).then((response) => {
    // Handle all systems level error below
    if (response.status === 429) {
      ERROR(
        "Your API limit has exceeded as per your current subscriptions plans. Please contact support"
      );
      throw new Error("API_LIMIT_EXCEEDED");
    } else if (response.status === 409) {
      ERROR("Transaction not submitted as duplicate record found.");
      throw new Error("DUPLICATE");
    } else if (!response.ok) {
      ERROR("Network request unsuccessful");
      throw new Error("Network response was not ok");
    }
    return response.json();
  });
};

export const UseRequestDownload = async (url, response) => {
  const Token = response && response.idToken;
  const requestOptions = {
    method: "GET",
    requireAuthentication: true,
    headers: {
      Authorization: "Bearer " + Token,
      "Content-Type": "application/json",
      "x-api-key":
        "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    },
  };

  return await fetch(url, requestOptions).then((res) => res.blob());
};
