import React from "react";
import classes from "./Layout.module.css";
const Content = (props) => {
  return (
    <div
      className={classes.Content}
      style={{
        backgroundColor: props.backgroundColor,
        marginBottom: props.marginBottom,
        marginTop: props.marginTop,
        paddingBottom: props.paddingBottom,
        padding: props.padding,
        // paddingBottom: props.paddingBottom,
      }}
    >
      {props.children}
    </div>
  );
};

export default Content;
