import React from "react";
import classes from "./shipmentSummary.module.css";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BsAirplaneEngines, BsTruck, BsFillSquareFill } from "react-icons/bs";
import { GiCargoShip } from "react-icons/gi";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FormattedDateTime } from "utils/helper";
import Content from "components/UI/Layout/Content";
const MultiStatus = ({ response }) => {
  const TrasportMode = (data) => {
    switch (data.mode_of_transport) {
      case "TRUCK":
        return (
          <>
            <span style={{ margin: "0px 10px" }}>
              <BsTruck size={18} color={"#00FF00"} />
            </span>
            <span>On Truck</span>
          </>
        );
      case "VESSEL":
        return (
          <>
            <span style={{ margin: "0px 10px" }}>
              <GiCargoShip size={18} color={"#00FF00"} />
            </span>
            <span>
              <span>On Vessel</span>
            </span>
          </>
        );
      case "air":
        return (
          <>
            <span style={{ margin: "0px 10px" }}>
              <BsAirplaneEngines size={18} color={"#00FF00"} />
            </span>
            <span>
              <span>On Air</span>
            </span>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Content>
        <div className={classes.header}>
          <span>Shipping Events</span>
        </div>

        <div>
          {response &&
            response.map((step, index) => (
              <div key={index} className={classes.newMultiContent}>
                <div>
                  {index !== 0 &&
                  response[index].location_raw ===
                    response[index - 1].location_raw ? (
                    <></>
                  ) : (
                    <>{step.location_raw}</>
                  )}
                </div>
                <div
                  className={
                    index === response.length - 1
                      ? classes.newMultiContentB
                      : classes.newMultiContentA
                  }>
                  <div style={{ marginLeft: "-15px" }}>
                    <BsFillSquareFill size={26} color={"green"} />
                  </div>
                </div>
                <div>
                  <div>
                    <div style={{ fontSize: "14px" }}>
                      {step.event_type_code ? step.event_type_code + " : " : ""}
                      {step.event_raw}
                    </div>
                    <div style={{ whiteSpace: "nowrap", margin: "10px 0px" }}>
                      <span>
                        <HiOutlineLocationMarker size={16} color={"#42a5f5"} />
                        <span style={{ marginLeft: "10px" }}>
                          At {step.location_raw}
                        </span>
                      </span>
                      <span>{TrasportMode(step)}</span>
                      <span>
                        <span style={{ margin: "0px 10px" }}>
                          <AiOutlineClockCircle size={16} color={"#9ACD32"} />
                        </span>
                        {FormattedDateTime(step.event_time)}
                      </span>
                    </div>
                    <div style={{ fontSize: "12px" }}>
                      <span>
                        {step.vessel_name
                          ? "Vessel Name : " + step.vessel_name + " , "
                          : "Vessel info not available"}
                      </span>
                      <span>
                        {step.vessel_imo ? "IMO : " + step.vessel_imo : ""}
                      </span>
                    </div>
                  </div>
                  {index !== response && response.length - 1 ? (
                    <hr className={classes.HRStyle}></hr>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ))}
        </div>
      </Content>
    </>
  );
};

export default MultiStatus;
