import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { COMPLIANCE_IMPORT } from "utils/Section/FormInfo";

import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";
import SelectWithSearch from "components/fields/SelectWithSearch";

const ComplianceImport = ({ importData, setImportData }) => {
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];
  let paymentTerms = [
    {
      label: "Advance Payment",
      value: "Advance Payment",
    },
    {
      label: "Letter of credit",
      value: "Letter of credit",
    },
    {
      label: "Documents against Payments - D.A.P or D/P basis",
      value: "Documents against Payments - D.A.P or D/P basis",
    },
    {
      label: "Documents against Acceptance (D/A)",
      value: "Documents against Acceptance (D/A)",
    },
    {
      label: "Open Account",
      value: "Open Account",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let incoterm = [
    {
      label: "EXW",
      value: "EXW",
    },
    {
      label: "DDP (Delivered Duty Paid)",
      value: "DDP (Delivered Duty Paid)",
    },
    {
      label: "FOB (Free on Board)",
      value: "FOB (Free on Board)",
    },
    {
      label: "CIF (Cost, Insurance and Freight) ",
      value: "CIF (Cost, Insurance and Freight)",
    },
    {
      label: "FAS (Free Alongside Ship)",
      value: "FAS (Free Alongside Ship)",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let qtyUnitOptions = [
    {
      label: "MT",
      value: "MT",
    },
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "LB",
      value: "LB",
    },
    {
      label: "BOXES",
      value: "BOXES",
    },
    {
      label: "PCS",
      value: "PCS",
    },
  ];

  // let inspectionType = [
  //   {
  //     label: "Packaging ",
  //     value: "Packaging",
  //   },
  //   {
  //     label: "Labeling",
  //     value: "Labeling",
  //   },

  //   {
  //     label: "Documentation and Document Verification",
  //     value: "Documentation and Document Verification",
  //   },
  //   {
  //     label: "Compliance with all relevant regulations and standards",
  //     value: "Compliance with all relevant regulations and standards",
  //   },
  //   {
  //       label: "Physical inspection",
  //       value: "Physical inspection",
  //     },
  //     {
  //       label: "Sample testing",
  //       value: "Sample testing",
  //     },
  // ];

  const [selectList, setSelectList] = useState([]);
  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setImportData({
      ...importData,
      [name]: value,
    });
  };

  let services = [
    {
      label: " Ensure shipment is custom compliant",
      value: " Ensure shipment is custom compliant",
    },
    {
      label: "Make all necessary customs and additional agency declarations",
      value: "Make all necessary customs and additional agency declarations",
    },
    {
      label: "Make payment for the duties and taxes",
      value: "Make payment for the duties and taxes",
    },
    {
      label:
        "Follow up with the government authorities regarding the status of imported / Exported goods",
      value:
        "BOXFollow up with the government authorities regarding the status of imported / Exported goodsES",
    },
    {
      label: "Apply for import/export permits",
      value: "Apply for import/export permits",
    },
  ];

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  // const handleMultiChange = (event) => {
  //   if (event.target.name === "validatedBy") {
  //     setDocumentData({
  //       ...DocumentData,
  //       [event.target.name]: event.target.value,
  //     });
  //   }
  // const handleMultiChange = (e) => {
  //   const { value } = e.target;
  //   setSelectList(value);
  // };
  const handleMultiChange = (e, name) => {
    if (name === "service") {
      setImportData({
        ...importData,

        [e.target.name]: e.target.value,
      });
      console.log(importData, "hello");
    }
    //   else if(name === "inspectionType"){

    //      setImportData({
    //       ...importData,
    //       [e.target.name]: e.target.value,
    //     });

    //   }
  };

  // const handleRadio = (event) => {
  //   const target = event.target;
  //   const name = target.name;
  //   const value = target.value;

  //   setImportData({
  //     ...importData,
  //     [name]: value,
  //   });
  // };

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "loadingDate") {
        setDatePicker1(newDate1);
        // setImportData({
        //   ...importData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "unloadingDate") {
        setDatePicker2(newDate1);
        // setImportData({
        //   ...importData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setImportData({
        ...importData,
        [date]: dateString,
      });
    }
    // else if (e.target.name === "serviceType") {
    //   setImportData({
    //     ...importData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    else if (e.target.name === "countryFrom") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerm") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contactInfoShipper") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contactInfoBuyer") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requirement") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ProductName") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "productDescription") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ShipperName") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverAddress") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverName") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ShipperAddress") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerms") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "incoterms") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "hsnOfCode") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyType") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "transactionResDetail") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "licenseReqDetail") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "serviceExportRegDetail") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyAmount") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requirement") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "quantityType") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "quantity") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "serviceResDetail") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "incoterm") {
      setImportData({
        ...importData,
        [e.target.name]: e.target.value,
      });
    }
  };
  sessionStorage.setItem("importData", JSON.stringify(importData));
  console.log("importData", "saurabh", importData);
  return (
    <>
      <Content padding="1% 2% 1.4% 2%">
        <div style={{ marginLeft: "10px" }}>
          <OverLayTrigger value={COMPLIANCE_IMPORT} />
        </div>
        <div style={{ margin: "0px 10px" }}>COMPLIANCE IMPORT_DECLARATIONS</div>

        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom:"2%" ,marginTop:"1%", marginLeft:"10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="ShipperName"
              placeholder="Supplier Name*"
              handleChange={handleChange}
              value={importData.ShipperName}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom:"2%"  ,marginTop:"1%", marginLeft:"10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="ReceiverName"
              placeholder="Buyer Name*"
              handleChange={handleChange}
              value={importData.ReceiverName}

              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              //  marginBottom:"2%" ,marginTop:"1%", marginLeft:"10px",
              margin: "10px",
            }}
          >
            <Input
              name="contactInfoShipper"
              placeholder="Supplier Contact Information*"
              handleChange={handleChange}
              value={importData.contactInfoShipper}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              //  marginBottom:"2%"  ,marginTop:"1%", marginLeft:"10px",
              margin: "10px",
            }}
          >
            <Input
              name="contactInfoBuyer"
              placeholder="Buyer Contact Information*"
              handleChange={handleChange}
              value={importData.contactInfoBuyer}

              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              //  marginBottom:"2%", marginLeft:"10px",
              margin: "10px",
            }}
          >
            <Input
              name="ShipperAddress"
              placeholder="Supplier Address*"
              handleChange={handleChange}
              value={importData.ShipperAddress}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              //  marginBottom:"2%" , marginLeft:"10px",
              margin: "10px",
            }}
          >
            <Input
              name="ReceiverAddress"
              placeholder="Buyer Address* "
              handleChange={handleChange}
              value={importData.ReceiverAddress}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom:"2%", marginLeft:"10px",
              margin: "10px",
            }}
          >
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country  From*"
              handleChange={handleChange}
              value={importData.countryFrom}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom:"2%", marginLeft:"10px",
              margin: "10px",
            }}
          >
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country To*"
              handleChange={handleChange}
              value={importData.countryTo}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        <hr
          style={{ border: "2px solid white", margin: "10px 10px 10px 10px" }}
        ></hr>
        <div>
          <div>
            <div>
              <label style={{ margin: "5px 10px 0px 10px" }}>COMMODITY</label>
              <div className={classes.source}>
                <div
                  style={{
                    width: "50%",
                    // marginBottom:"2%" , marginTop:"1%", marginLeft:"10px",
                    margin: "5px 10px 10px 10px",
                    paddingRight: "20px",
                  }}
                >
                  <Select
                    options={Industry}
                    name="commodity"
                    placeholder="Product Category*"
                    handleChange={handleChange}
                    value={importData.commodity}
                    // readOnly={true}
                    //
                    //     name="memberlist"
                    //     selected={selectStatus}
                    //     placeholder="Status"
                    //     options={InvoiceStatus}
                    //
                    //   />
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.source}>
            <div
              style={{
                width: "50%",
                //  marginBottom:"2%", marginLeft:"10px",
                margin: "10px",
              }}
            >
              <Input
                name="ProductName"
                placeholder="Product Name*"
                handleChange={handleChange}
                value={importData.ProductName}
                // readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="productDescription"
                placeholder="Product Description*"
                handleChange={handleChange}
                value={importData.productDescription}
                // readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
              <Input
                name="hsnOfCode"
                placeholder="HSN code of Goods*"
                handleChange={handleChange}
                value={importData.hsnOfCode}
                // readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={qtyUnitOptions}
                name="quantityType"
                placeholder="Quantity*"
                value={importData.quantityType}
                handleChange={handleChange}
              />
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="quantity"
                placeholder="Quantity*"
                handleChange={handleChange}
                value={importData.quantity}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={Currenciesr}
                name="currencyType"
                placeholder="Currency*"
                value={importData.currencyType}
                handleChange={handleChange}
              />
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="currencyAmount"
                placeholder={`Estimated Cost of Goods or Services (${importData.currencyType})`}
                handleChange={handleChange}
                value={importData.currencyAmount}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}></div>
        </div>

        <div>
          {/* <label>INCOTERM 


</label> */}
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Select
              options={incoterm}
              name="incoterm"
              placeholder="Incoterm*"
              value={importData.incoterm}
              handleChange={handleChange}
            />
          </div>

          {importData.incoterm == "OTHERS" ? (
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="paymentTerms"
                placeholder="Please Enter Your Incoterm"
                value={importData.incoterms}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
            <Select
              options={paymentTerms}
              name="paymentTerms"
              placeholder="Payment Term*"
              value={importData.paymentTerms}
              handleChange={handleChange}
            />
          </div>
          {importData.paymentTerms == "OTHERS" ? (
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="paymentTerm"
                placeholder="Please Enter Your Payment Term"
                value={importData.paymentTerm}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div></div>

        <div style={{ width: "100%", margin: "10px", paddingRight: "20px" }}>
          <TextArea
            name="requirement"
            placeholder="Give your own Import Declaration Compliance Specific coverage Requirements*  "
            value={importData.requirement}
            handleChange={handleChange}
          />
        </div>
      </Content>
    </>
  );
};

export default ComplianceImport;
