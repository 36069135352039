import React, { useState, useContext, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import classes from "../PaymentAccounts/PaymentAccounts.module.css";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { paymentServiceURL } from "services/BaseURLInstance";

import { TableStatus } from "utils/helper";
import { PaymentAccountListProviderHeader } from "utils/TableHeader/PaymentSection";

import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";

const PaymentTransaction = ({
  setIsInitiateOpen,
  isAssociate,
  isSubscribed,
  setIsSubscribed,
}) => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const { instance, accounts } = useMsal();
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [Data, setData] = useState(null);
  const [checkedIndex, setCheckedIndex] = useState(null);

  const handlePutData = (data, index) => {
    setData(data);
    setCheckedIndex(index);
  };

  useEffect(() => {
    let isCancelled = false;
    let URL = `${paymentServiceURL}payments/account?memberId=${companyId}`;
    setIsLoading(true);
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            // setIsLoading(false);
            // let { message, status } = data;
            // if (message || status) {
            //   Error(message || "Something went wrong");
            //   setError(message || "Something went wrong");
            // } else {
            //   setResponse(data);
            // }
            setResponse(data);
          })
          .catch(() => {
            Error("Something went wrong");
            setError("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const newData = {
      memberID: Data && Data.memberID,
      paymentAccountCurrency: Data && Data.paymentAccountCurrency,
      // country: Data && Data.country,
      country: "IN",
      paymentProviderAccountId: Data && Data.paymentProviderAccountId, // unique
      providerDetails: [
        {
          providerName: isAssociate.toUpperCase(),
        },
      ],

      paymentAccountHolderName: Data && Data.paymentAccountHolderName,
      accountType: Data && Data.accountType,
      bankName: Data && Data.bankName,
      paymentAccountNumber: Data && Data.paymentAccountNumber, // unique
      status: Data && Data.status,
      // countryCode: Data && Data.countryCode,
      countryCode: "+91",
      bankCodes: Data && Data.bankCodes,
    };

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-DEV-COMPANY_MEMBER_ID": companyId,
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(newData),
    };
    let URL = `${paymentServiceURL}payments/account/${Data.memberID}`;
    await fetch(URL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        // setIsLoading(false);
        // let { message, status } = data;
        // if (message || status) {
        //   Error(message || "Something went wrong");
        //   setError(message || "Something went wrong");
        // } else {
        //   Success("Associated SuccessFully");
        //   setIsInitiateOpen(false);
        //   setIsSubscribed(!isSubscribed);
        // }
        Success("Associated SuccessFully");
        setIsInitiateOpen(false);
        setIsSubscribed(!isSubscribed);
      })
      .catch((error) => {
        Error("Something went wrong");
        setError("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading ? (
        <span>
          Loading...
          <Loader />
        </span>
      ) : (
        <>
          {response && (
            <Content>
              <div
                onClick={() => setIsInitiateOpen(false)}
                style={{ color: "#218eeb", cursor: "pointer" }}>
                <span>
                  <HiArrowNarrowLeft size={28} />
                </span>
                <span>Back to List </span>
              </div>
              <Table bordered responsive className={cn(classes.Table)}>
                <thead>
                  <tr>
                    {PaymentAccountListProviderHeader.map((header, index) => (
                      <td key={index}>{header.name}</td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {response &&
                    response.map((user, index) => (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>
                          <input
                            type="checkbox"
                            onChange={() => {
                              handlePutData(user, index);
                            }}
                            checked={checkedIndex === index ? true : false}
                            style={{ height: "24px", width: "24px" }}
                          />
                        </td>
                        <td>{user.bankName}</td>
                        <td>{user.paymentAccountHolderName}</td>
                        <td>{user.paymentAccountNumber}</td>
                        <td>{user.country}</td>
                        <td>{TableStatus(`${"VERIFICATION_PASS"}`)}</td>
                      </tr>
                    ))}
                  {response && response.length === 0 && <span>No Data</span>}
                </tbody>
              </Table>

              <ButtonDiv>
                <Button onClick={() => setIsInitiateOpen(false)}>Cancel</Button>
                <Button onClick={(e) => handleSubmit(e)}>
                  Associate Account
                </Button>
              </ButtonDiv>
            </Content>
          )}
          {isError && <span>Something went wrong</span>}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default PaymentTransaction;
