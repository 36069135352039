import React, { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { IoMdNotifications } from "react-icons/io";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";

import styled from "styled-components";
import "./DetailForm.module.css";

import Summary from "./SuccessInvoice/summary";
import  Details  from "./SuccessInvoice/details";
import { Deals } from "./SuccessInvoice/deals";
import { invoiceFinanceContext } from "contexts/invoiceFinance/invoiceFinanceContext";

import { BsArrowLeft, BsBell } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import Content from "components/UI/Layout/Content";
import ToogleTab from "components/UI/ToogleSelect/ToogleTab";

const InvoiceDetailsForm = ({ setIsInvoiveDetailFormOpen }) => {
  const { invoiceData } = useContext(invoiceFinanceContext);
  const { invoiceNumber } = invoiceData;

  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const [selected, setSelected] = useState(0);
  const toggle = (i) => {
    setSelected(i);
  };

  const TabData = [
    {
      title: "Summary",
      description: <Summary />,
    },
    {
      title: "Details",
      description: <Details />,
    },
    {
      title: "Deals",
      description: <Deals />,
    },
  ];

  return (
    <>
      {invoiceData !== "" ? (
        <>
          <div onClick={() => setIsInvoiveDetailFormOpen(false)}>
            <span>
              <BsArrowLeft />
            </span>
            <span>Back To List</span>
          </div>

          <Content>
            <div className="d-flex justify-content-between">
              <span style={{ color: "#42A5F5" }}>
                Invoices<span>:{invoiceNumber} </span>
              </span>
              <span>
                <HiOutlineMail size={26} className="mx-4" />
                <BsBell size={26} />
              </span>
            </div>
          </Content>
          <ToogleTab selected={selected} toggle={toggle} TabData={TabData} />
        </>
      ) : (
        <>
          <span>No Record Found</span>
          <span onClick={() => setIsInvoiveDetailFormOpen(false)}>Back ?</span>
        </>
      )}
    </>
  );
};

export default InvoiceDetailsForm;
