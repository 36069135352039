import React, { useCallback } from "react";
import Table from "../../../../../../components/table/index";

import useAxios from "../../../../../../services/useAxios";
import { financeBaseUrl } from "../../../../../../services/BaseURLInstance";

import { MdCircleNotifications } from "react-icons/md";
import {GoMail} from "react-icons/go";
import { BsChat } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const FinanceDeals = ()=> {

    const navigate = useNavigate();

    const { response, isLoading, error } = useAxios({
    api: financeBaseUrl,
    method: "get",
    url: "finance/deals?traderMemberUID=dc428603-4f18-b99b-b712-6fcb7fde510e&financerMemberUID=dc428603-4f18-b99b-b712-6fcb7fde510e&invoiceId=dc428603-4f18-b99b-b712-6fcb7fde510e&status=elit ut",
    config: JSON.stringify({
      requireAuthentication: true,
      headers: {
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    }),
    });

    const handleNavigate = useCallback((url) => navigate(url), []);

    const handleClickAlert = () => {};
    const handleClickStatus = () => {};
    const handleClickMail = () => {};
    const handleClickChat = () => {};

    const headerFieldsList = [

        {
          Header: "Financer",
          accessor: "financerMemberUID",
          Cell: (cell) => (
            
            <FinancerStyled>
            <button
              className="buttonIcon"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
              value={cell.accessor}
              onClick={() => handleClickAlert(cell.cell.row.values)}
            >
              <MdCircleNotifications size={26} />
            </button>
            <span>{cell.cell.row.values.financerMemberUID}</span>
            </FinancerStyled>
          ),
        },
        {
          Header: "Offered Interested Rate",
          accessor: "interestRate",
        },
        {
          Header: "Status",
          accessor: "txlStatus",
          Cell: (cell , index) => (
            
            
            <StatusStyled
              className="buttonIcon"
              background = { index/2 == 0 ? "red" : "green" }
              style = {statusStyle}
              value={'activate'}
              onClick={() => handleClickStatus(cell.cell.row.values)}
            > Activate               
            </StatusStyled>
          ),
        },
        {
          Header: "Last Activity Date",
          accessor: "lastUpdatedDate",
          Cell: (cell) => (
            <span>{new Date(cell.cell.row.values.lastUpdatedDate).toLocaleDateString()}</span>
          ),
        },
        {
          Header: "Action",
          accessor: "Action",
          Cell: (cell , index) => (  
            <ActionStyled>
            <button
              className="buttonIcon"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
              value={cell.accessor}
              onClick={() => handleClickMail(cell.cell.row.values)}
            >
              <GoMail size={30} />
            </button>

            <button
              className="buttonIcon"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
              value={cell.accessor}
              onClick={() => handleClickChat(cell.cell.row.values)}
            >
              <BsChat size={26} />
            </button>
            </ActionStyled>
          ),
        },      
    ];
    
    const columns = React.useMemo(() => headerFieldsList);
      
  return (
      <>
      {Array.isArray(response) ? (
            <Table columns={columns} data={response} />
          ) : (
            <p>No Records</p>
        )}
      </>
  )
}

export default FinanceDeals;

const FinancerStyled = styled.div`
   display:flex;
   align-items: center;
   button:nth-child(1){
    margin-right: 5px;
    cursor: pointer;
  }
`;

const StatusStyled = styled.button`
  padding: 25px;
  font-size:16px;
  border:none !important;
  border-radius: 5px;
  color: white;
  background-color: ${(props) => props.background || 'blue' };
`;

const statusStyle = {
  padding: '10px 40px',
  letterSpacing: "1px",
  cursor: 'pointer',
}

const ActionStyled = styled.div`
  display:flex;
  align-items: center;
  button:nth-child(1){
    margin-right: 10px;
    cursor: pointer;
  }
  button:nth-child(2){
    cursor: pointer;
  }
`;
