import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Invoicefinancing from "../../Invoicefinancing";
import FinanceDashboard from './financeDashboard';
import Pofinance from './pofinance';

export default ()=>{
    
    return(
        <div>
         <Routes>
            <Route path="/invoicefinancing/*" element={<Invoicefinancing />} />
            <Route path="/pofinancing/*" element={<Pofinance/>} />
            <Route path="/payablesfinancing/*" element={<Invoicefinancing />} />
            <Route path="/inventoryfinancing/*" element={<Invoicefinancing />} />
        </Routes>
        <FinanceDashboard/>
        </div>
    )
}