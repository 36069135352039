import React, { useState } from "react";
import { Card, Grid, Button } from '@mui/material';
import { FinancerList } from "./FinancerList";

export const SelectedFinancers = (props) => {
    console.log("SAT",props)
    const { financers } = props?.props?.apiData;
    

    const  showFinancers = props.props.showFinancers;
    const createFinancer =props.createFinancer;
    const showfinacerTable = props.showfinacerTable;
    const enableDrawdown = props.enableDrawdown;
    const showDrawdown = props.showDrawdown;
    const changeFinancer = props.changeFinancer;
    const handleChange = props.props.handleChange;
    const enableComp = props.enableComp;
    const resetFlow = props.resetFlow;
    const backFormTranchTable= props.backFormTranchTable;
    const showTable = props.props.showTable;
    const setShowTable = props.props.setShowTable;
    const showFinancersTable= props.showFinancersTable; 
    const setShowFinancersTable= props.setShowFinancersTable;
    const editTranche = props.editTranche;
    const trancheCalc =(d) =>{
        let tdata =[];
        tdata = d.map(item =>({
            ...item,
            loanStatus:item?.loanStatus==="" ? "DRAFT" : item?.loanStatus
        }))
        return tdata
        
    }
    const propsData = {
        data: props?.props?.apiData,
        trancheData: trancheCalc(props.props.tranchelist),
        drawdownData: props.props.drawdownList,
        financers,
        backFormTranchTable,
        setListFinancer:props.setListFinancer,
        showfinacerTable,
        enableDrawdown,
        showDrawdown,
        changeFinancer,
        handleChange,
        enableComp,
        resetFlow,
        cancelDraw: props.props.cancelDraw,
        goback: props.props.back,
        removeFinancer: props.props.removeFinancer,
        memberList:props.memberList,
        showFinancersTable,
        setShowFinancersTable,
        showTable,
        setShowTable,
        showFinancers,
        createFinancer,
        editTranche
    }
    return (
        <>
  
            {showFinancers ?
                <Card style={{
                    backgroundColor: "#1E1E1E",
                    padding: "30px 20px",
                    borderRadius: "5px",
                    margin: "0px 10px"
                }}>
                    <h4 style={{ margin: "0px", color: "white" }}>
                        Selected Financers
                    </h4>
                    <br></br>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <>
                                <div>
                                    {financers?.map(e => {
                                        return (
                                            <>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    borderBottom: "1px solid #FFFFFF",
                                                    background: "black",
                                                    padding: "5px 10px"
                                                }}>
                                                    <h4 style={{ margin: "3px 0px", color: "white" }}>{e.label}</h4>
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </>
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => props.changeFinancer()}
                            style={{ width: "250px", margin: "0px 20px" }}
                        >
                            Back to Selection
                        </Button>

                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => createFinancer()}
                            style={{ width: "250px" }}
                        >
                            Create Deal Structure
                        </Button>
                    </div>
                </Card>
                :
                <FinancerList financers={propsData} />
            }
        </>
    );
};
