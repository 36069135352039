export default (title) => {
  let Categories = {
    TRADE_CONTRACT: "Trade Contract",
    MEMBER_ONBOARDING: "Onboarding",
    KYB: "KYB",
    IDENTITY_VERIFICATION: "Identity Verification",
    BACKGROUND_CHECK: "Background Check",
    FINANCING: "Financing",
    FINANCING_PO: "PO Financing",
    FINANCING_INVOICE: "Invoice Financing",
    SYNDICATION_FINANCE: "Syndicate Finance",
    CREDIT_ASSESSMENT: "Credit Assessment",
    ACCOUNTS_TAXATION: "Accounts Taxation",
    TAXES: "Taxes",
    ACCOUNTING: "Accounting",
    ANALYTICS_REPORTING: "Analytics & Reports",
    COMPLIANCE: "Compliance",
    TRADE: "Trade",
    FINANCIAL: "Financial",
    IMPORT_DECLARATIONS: "Import Declarations",
    EXPORT_DECLARATIONS: "Export Declarations",
    LMPC_IMPORT_LICENSE: "LMPC Import License",
    LUT: "LUT",
    PAYMENTS: "Payments",
    PAYMENT_ADVANCES: "Payment Advances", // done
    ESCROW: "ESCROW",
    CROSS_BORDER: "Cross Border",
    DOMESTIC: "Domestic",
    FOREIGN_EXCHANGE: "Foreign Exchange",
    FREIGHT: "Freight",
    IMPORT: "Import",
    EXPORT: "Export",
    TRANSPORT: "Transport",
    TRUCK: "Truck",
    MARITIME: "Maritime",
    RAIL: "Rail",
    AIR: "Air",
    TERMINAL: "Terminal",
    SHIPPING: "Shipping",
    CONTAINER: "Container",
    CUSTOMS: "Customs",
    CUSTOM_CLEARANCE: "Custom Clearance",
    CUSTOMS_INSPECTION: "Customs Inspection",
    INSPECTION: "Inspection",
    QUALITY: "Quality",
    WAREHOUSE: "Warehouse",
    INVOICING: "Invoicing",
    OTHERS: "Others",
    FREIGHT_FINANCING: "Freight Financing",

    INSURANCE: "Insurance", // Check below here using or not
    FREIGHT_SERVICES: "Freight Services",
    GOODS_INSURANCE: "Goods Insurance",
    TRANSPORT_SERVICES: "Transport Services",
    TERMINAL_LOADING: "Terminal Loading",
    CUSTOMS_INSPECTION: "Customs Inspection",
    SHIPPING_SERVICES: "Shipping Services",
    CONTAINER_SERVICES: "Container Services",
    CUSTOMS_SERVICES: "Customs Services",
    INSPECTION_SERVICES: "Inspection Services",
    WAREHOUSE_SERVICES: "Warehouse Services",
    LEGAL: "Legal",
    CREDIT_INSURANCE: "Credit Insurance",
    PACKAGE: "Package",
    AGENCY: "Agency",
    INLAND: "Inland",
    CARGO: "CARGO",
    STEVEDORING: "Stevedoring",
    CREDIT: "CREDIT",
  };

  if (title) {
    return Categories[`${title}`];
  } else {
    return "UNDEFINED";
  }
};
