import React, {useState} from 'react';
import Screen from './verifyEmail.styled';
import { useNavigate } from 'react-router-dom';
import {MdEmail} from "react-icons/md";

const VerifyEmail = ()=>{

    const navigate=useNavigate();
    const [email,setEmail]=useState("");

    const handleChange=(e)=>{
        e.preventDefault();
        setEmail(e.target.value)
     }
     const handleVerify=()=>{   
        navigate('/verifyemail-otp')
     }

    return(
       <Screen>
            <div className='header'>TRADXLINK</div>
            <div className='subHeader'>Seller</div>
            <div className='verifyPhoneHeading'>Email Verify</div>
            <div className='email'>
                <label For="email"><MdEmail/></label>
                <input type="email" name="email" placeholder="Email" value={email} onChange={handleChange} />
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <button className='nextbutton' onClick={handleVerify}>Send Verification code</button>
       </Screen>
        
    )
}
export default VerifyEmail;