import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Navbar.module.css";

const Navbar = (props) => {
  return (
    <div className={classes.navbar} style={{ backgroundColor: "rgba(255, 255, 255, 0.1)"}}>
      {props.tabs.map((item, index) => (
        <NavLink
          to={item.value}
          key={index}
          className={({ isActive }) =>
            isActive ? classes.active : classes.notActive
          }
        >
          <li>{item.name}</li>
        </NavLink>
      ))}
    </div>
  );
};

export default Navbar;
