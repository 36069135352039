import styled from "styled-components";
export default styled.div`

   .parentgrid {
     display:flex;
     background-color: #1E1E1E;
     
   }
   .firstscreen{
      width:100%;
      background-color: black;
      margin-right:1%;
      padding:1%;
      margin-top: 1%
   }
   .firstlevelflow{
     padding-top:1%;
     width:100%;
   }
   .icon{
     background-color:#218EEB;
     display:flex;
     align-items:center;
     justify-content:center;
     border-radius:50%;
     margin-top:-5%;
   }
   .icon:hover{
    box-shadow: white .1px .3px 5px 8px;
   }
   .icon4{
    background-color:#218EEB;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    margin-top:-5%;
    box-shadow: white .1px .3px 5px 8px;
    
  }
   .hline{
     border-left: 4px solid #218EEB;
     height: 3rem;
     margin-top:-4%;
     margin-left:48%;
   }
   .heading{
     font-size:18px;
     color:#218EEB;
   }
   .secondscreen{
     width:95%;
   }

   .mainflow{
    overflow: auto;
    display: flex; 
    align-items: center;
    justify-content: center;
    padding-left:4%;
    margin-top: 4rem;
 }

   .icon2{
     border-radius: 5px;
     display:flex;
     align-items:center;
     justify-content:center;
     color: black;
   }

   .icon2:hover{
     box-shadow: white .1px .3px 5px 8px;
   }

   .icon3{
    border-radius: 5px;
    display:flex;
    align-items:center;
    justify-content:center;
    box-shadow: white .1px .3px 5px 8px;
    color: black;
    
  }
  .vline{
    width:100%;
    border: 2px solid #218EEB;
    height:0rem;
  }
   .wholeicon2{
     display: flex;
     align-items: center;
     justify-content: left;
     width: 100%;
   }

   .subflow{
    margin-top: 1%;
     width:100%;
     background-color:black;
     padding:2%;
   }
   .secondlevelflow{
     width:100%;
     margin-top:1rem;
   }
   .mainflowtext{
    width: 100%;
    display: flex; 
    justify-content: left;
    margin-top:.5rem;
   
   }
   .textmaindiv{
      height: 6rem;
      width: 3rem;
      margin-left: 2%;
      display: flex;
      text-align: left;
      
   }
   .textinnerdiv{
    font-size: 15px; 
   }
   .summaryheading{
    padding: 1%;
    width: 100%;
    font-size: 16px; 
    position:relative;
   }
   .summaryheading .chaticon{
    position:absolute;
    right:10px;
    top:10px;
    cursor:pointer;
   }
   .summaryheadingsub{
    padding-left: 1%;
    width: 100%;
    font-size: 13px; 
   }
   .participantname{
    font-size:85%;
    width:70%;
    color:#2180D1;
    display: flex;
    justify-content: left;
    margin-left: 4%;

   }
   .participantcompany{
    font-size:80%;
    width:30%;
    display: flex;
    justify-content: right;

   }
   .participantcard{
    border-radius: 5px;
    width: 98%;
    background-color: rgba(165, 165, 165, 0.25); 
    padding: 1%;
    margin: 1%;
    padding-bottom:1%;
    
   }
   .participantlist{
     display: flex;
     justify-content: center;
     margin-top:2%; 
   }
   .participantlistdata{
      width: 45%;
      text-align:center;
   }
   .participantListTable{
      background-color: black;
      padding: 2%;
   }
   .participantListTableButtons{
      display: flex;
      width: 80%;
      justify-content: right;
   }

   .headerTop{
     display: flex;
     width: 100%;
     margin-top: 2%;
   }
   .headerdiv{
    text-align: center;
    border-right: 1px solid #A5A5A540;
    padding: 1%; 
   }
   .dataheader{
      display: flex;
       width: 100%;
        background-color: black;
         border-bottom: 1px solid #A5A5A540;
          font-size:74%;
   }
   .datadiv{
    width: 20%;
     text-align: center;
      border-right: 1px solid #A5A5A540;
       padding: 1%;
       display:flex;
       justify-content:center;
       align-items:center;
       overflow: clip;
   }
   .divlast{
    width: 24.5%;
    text-align: center;
    padding: 1%;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color:red;
    margin:2%;
   }
   .overviewListItem{
        display: flex;
        width: 50%;
        justify-content:center;
        align-items: center;
        background: rgba(255, 255, 255, 0.2);
        margin: 1%;
        padding-left:1.5%;
        padding-top:1%;
        padding-bottom:1%;
        padding-right:1.5%;
        border-radius: 5px;
        div{
          font-size:12px;
          width:50%;
        }
   }

`;