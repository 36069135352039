let DirectorDocument = [
  {
    docCategory: "DIRECTOR",
    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DIRECTOR",
        docSubCategory: "PAN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DIRECTOR",
        docSubCategory: "AADHAR",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DIRECTOR",
        docSubCategory: "Passport",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "KYC",
    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYC",
        docSubCategory: "KYB Report",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "ACCREDITATIONS",
    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "ACCREDITATIONS",
        docSubCategory: "ACCREDITATIONS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "OTHERS",
    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "OTHERS",
        docSubCategory: "OTHERS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
];

let GlobalDocument = [
  {
    docCategory: "COMPANY",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "CoI",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "PANCard",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "TAN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "GSTIN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "ExportLicense",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "ImportLicense",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "KYB",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "KYB_ASSESSMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "CREDIT_ASSESSMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "AADHAR",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "TRADE",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE",
        docSubCategory: "INVOICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE",
        docSubCategory: "PO",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE",
        docSubCategory: "BL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "FINANCE",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE",
        docSubCategory: "ANNUAL_STATEMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE",
        docSubCategory: "P&L",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "ACCREDITATIONS",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "ACCREDITATIONS",
        docSubCategory: "ACCREDITATIONS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "OTHERS",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "OTHERS",
        docSubCategory: "OTHERS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
];

let TradeDocument = [
  {
    docCategory: "TRADE_CONTRACT",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE_CONTRACT",
        docSubCategory: "TRADE_CONTRACT-DRAFT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE_CONTRACT",
        docSubCategory: "TRADE_CONTRACT-SIGNED",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "TRADE_COMPLIANCE",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE_COMPLIANCE",
        docSubCategory: "TRADE_COMPLIANCE_REPORT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "ONBOARDING",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "ONBOARDING",
        docSubCategory: "MEMBER_DUE_DILIGENCE_REPORT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "SALES_ORDER",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SALES_ORDER",
        docSubCategory: "PURCHASE_ORDER",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SALES_ORDER",
        docSubCategory: "SALES_ORDER",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SALES_ORDER",
        docSubCategory: "RECONCILIATION_REPORT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SALES_ORDER",
        docSubCategory: "PROFORMA_INVOICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SALES_ORDER",
        docSubCategory: "FINAL_INVOICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "FINANCE_REQUISITION",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: "FINAL_INVOICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: "FINANCE_REQUISITION",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: "FINANCE_DEAL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "KYB",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "COMPANY_PAN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "COMPANY_BACKGROUND_CHECK",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "CERTIFICATE_OF_INCORPORATION",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "GSTIN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "EXPORT/IMPORT_LICENSE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "KYC",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYC",
        docSubCategory: "AADHAR",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYC",
        docSubCategory: "PASSPORT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYC",
        docSubCategory: "PAN_CARD",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYC",
        docSubCategory: "DRIVING_LICENSE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "PRE-SALES",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PRE-SALES",
        docSubCategory: "RFI",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PRE-SALES",
        docSubCategory: "RFQ",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "FINANCE_DUE_DILIGENCE",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE_DUE_DILIGENCE",
        docSubCategory: "COMPANY_BACKGROUND_CHECK",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE_DUE_DILIGENCE",
        docSubCategory: "CREDIT_ASSESSMENT_REPORT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "LOAN_STRUCTURE",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "LOAN_STRUCTURE",
        docSubCategory: "LOAN_STRUCTURE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "FINANCING_AGREEMENT",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCING_AGREEMENT",
        docSubCategory: "FINANCING_AGREEMENT(T&C)",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "CREDIT_INSURANCE",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CREDIT_INSURANCE",
        docSubCategory: "CREDIT_INSURANCE_CERTIFICATE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "DISBURSEMENTS_AND_SETTLEMENTS",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DISBURSEMENTS_AND_SETTLEMENTS",
        docSubCategory: "PAYMENT_ACKNOWLEDGEMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DISBURSEMENTS_AND_SETTLEMENTS",
        docSubCategory: "SWIFT_COPY",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DISBURSEMENTS_AND_SETTLEMENTS",
        docSubCategory: "BANK_RECEIPT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DISBURSEMENTS_AND_SETTLEMENTS",
        docSubCategory: "TRANSACTION_DEBIT_ADVICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "MANUFACTURING",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "MANUFACTURING",
        docSubCategory: "PRODUCT_DESIGN_PROTOTYPE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "MANUFACTURING",
        docSubCategory: "PRODUCT_DESIGN_FINAL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "MANUFACTURING",
        docSubCategory: "BILL_OF_MATERIAL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "DELIVERY_PLANNING",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: "FREIGHT_FORWARDER_QUOTE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: "ADVANCE_SHIPPING_NOTICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: "DELIVERY_DOCUMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: "INCOTERM",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: "SHIPPING_BILL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "PACKING",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PACKING",
        docSubCategory: "PACKING_LIST",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PACKING",
        docSubCategory: "FINAL_PACKING_IMAGE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "TRANSPORT",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "FREIGHT_CARRIERS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "CERTIFICATE_OF_ORIGIN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "PACKING_LIST",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "FINAL_INVOICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "FRIEGHT_PLAN_INFO",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "TRANSPORT_ORDER",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "CARGO_DECLARATIONS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "WAREHOUSE_RECEIPT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "BILL_OF_LADING",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "AIRWAY_BILL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT",
        docSubCategory: "DG_OR_NON_DG_CERTIFICATE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "GOODS_INSURANCE",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "GOODS_INSURANCE",
        docSubCategory: "GOODS_INSURANCE_CERTIFICATE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "CUSTOMS(EXPORT)",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: "TARIFF_PAYMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: "EXPORT_GENERAL_MANIFEST",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: "BILL_OF_EXCHANGE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: "EXPORT_DECLARATION",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: "CUSTOM_CLEARANCE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: "INSPECTION_CERTIFICATE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "TERMINAL(EXPORT)",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: "BILL_OF_LADING",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "SHIPPING_ORDER",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SHIPPING_ORDER",
        docSubCategory: "SHIPPING_BILL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "SCHEDULING_AGREEMENT",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SCHEDULING_AGREEMENT",
        docSubCategory: "SHIPPING_SCHEDULE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "PLAN_CONTAINER",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PLAN_CONTAINER",
        docSubCategory: "ETA_OF_CONTAINER",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PLAN_CONTAINER",
        docSubCategory: "ETD_OF_SHIP",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "SUMMARY_DECLARATION",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SUMMARY_DECLARATION",
        docSubCategory: "SUMMARY_DECLARATION_WITH_B/L",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "SHIPPING_DELIVERY",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SHIPPING_DELIVERY",
        docSubCategory: "DELIVERY_DOCUMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SHIPPING_DELIVERY",
        docSubCategory: "TELEX_B/L",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "TERMINAL(IMPORT)",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: "IMPORT_DECLARATION",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: "GOODS_INFO",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: "TERMINAL _WAREHOUSE_DOCUMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "CUSTOMS(IMPORT)",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(IMPORT)",
        docSubCategory: "INSPECTION_CERTIFICATE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(IMPORT)",
        docSubCategory: "CUSTOM_DECLARATIONS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(IMPORT)",
        docSubCategory: "CUSTOM_CLEARANCE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "CUSTOMS(IMPORT)",
        docSubCategory: "TARIFF_PAYMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "INSPECTION(IMPORT)",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "INSPECTION(IMPORT)",
        docSubCategory: "SAMPLING_DETAILS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "INSPECTION(IMPORT)",
        docSubCategory: "INSPECTION_CERTIFICATE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "INSPECTION(IMPORT)",
        docSubCategory: "INSPECTION_REPORTS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "TRANSPORT(IMPORT)",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRANSPORT(IMPORT)",
        docSubCategory: "TRANSPORT_RECEIPT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "WAREHOUSE_STORAGE",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: "WAREHOUSE_STORAGE_INVOICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: "GOODS_RECEIPT_ACKNOWLEDGEMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: "WAREHOUSE_RECEIPT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: "INSPECTION_REPORT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "PAYMENT_SETTLEMENT",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: "PAYMENT_ACKNOWLEDGEMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: "SWIFT_COPY",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: "BANK_RECEIPT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: "TRANSACTION_DEBIT_ADVICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: "ACCOUNT_STATEMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "POST_SETTLEMENT",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "POST_SETTLEMENT",
        docSubCategory: "CREDIT/DEBIT_NOTE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "PAYMENTS",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "PAYMENTS",
        docSubCategory: "PAYMENT_RECEIPT_DOCUMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "SAMPLING_GOODS",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "SAMPLING_GOODS",
        docSubCategory: "SAMPLE_GOODS_IMAGE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "OTHERS",
    template: "Trade",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "OTHERS",
        docSubCategory: "OTHERS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
];

export const CommonDocumentSelect = (name) => {
  const children1 = GlobalDocument.concat(DirectorDocument);

  const children2 = TradeDocument.concat(children1);

  let Category = children2.map((item) => ({
    label: item.docCategory,
    value: item.docCategory,
  }));

  const Empty = [];
  let temp = children2.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });
  let SubCategory =
    temp &&
    temp[0] &&
    temp[0].documents.map((item) => ({
      label: item.docSubCategory,
      value: item.docSubCategory,
    }));

  if (name !== null) {
    return { Category, SubCategory };
  } else {
    return { Category, Empty };
  }
};
