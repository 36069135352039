import { useState } from "react";
import ChangeState from "./changeState";
import DocumentGroup from "pages/Document";
// import History from "../../Invoicefinancing/uploadInvoice/Deals/history";
import History from "components/History/history";
// import Alert from "../../../Invoicefinancing/uploadInvoice/Deals/alerts";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Summary from "./summary";
import ParticipantsDetails from "./participantsDetails";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import "./changeState.css";
import Button from "components/UI/Button/Button";
import { SendBirdProvider as SBProvider } from "sendbird-uikit";
import Loader from "components/Loader/Loader";
// import MultiSelect from "components/fields/MultiSelect";

import { useEffect } from "react";
import Select from "components/fields/Select";
import { Success, Error } from "components/notifications/responseHandle";
import classes from "./upload.module.css";
import { MdDriveFolderUpload } from "react-icons/md";
import { docGroupURL, documentURL } from "services/BaseURLInstance";

export default ({
  flowStage,
  secondFlowSequence,
  initialState,
  stageData,
  Data,
  companyList,
  subStageDate,
  hanndleCraateGroup,
  setSubStageData,
}) => {
  const [headerSelect, setHeaderSelect] = useState("summary");
  const navigate = useNavigate();
  const location = useLocation();
  const { accounts } = useMsal();
  const param = useParams();

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isUploading, setUploading] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [docCategory, setDocCategory] = useState(null);
  const [selectedCat, setSelectedCat] = useState(null);
  const [docSubCategory, setDocSubCategory] = useState(null);
  const [selectedSubCat, setSelectedSubCat] = useState(null);
  const [data, setData] = useState({
    name: "",
    file: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  // const [first, setFirst] = useState();
  // const [second, setSecond] = useState();

  const handleHeaderNav = (e, value) => {
    if (param.node) {
      const newPath = location.pathname.replace(param.node, "");
      navigate(`${newPath}${value}`);
    } else {
      navigate(`./${value}`);
    }
  };

  const Component = () => {
    // useEffect(() => {
    //   setFirst(initialState);
    // }, [initialState]);

    // useEffect(() => {
    //   setSecond(secondFlowSequence);
    // }, [secondFlowSequence]);

    const componentLocations = {
      componentLocation: "tradeDocuments",
    };

    const handleHeaderSelectChange = (value) => {
      if (param.node) {
        const newPath = location.pathname.replace(param.node, "");
        navigate(`${newPath}${value}`);
      } else {
        navigate(`./${value}`);
      }
    };

    switch (param?.node) {
      case "summary":
        return (
          <Summary
            handleHeaderSelectChange={handleHeaderSelectChange}
            subStageDate={subStageDate}
            hanndleCraateGroup={hanndleCraateGroup}
            companyList={companyList}
            trade={"Trade"}
            data={Data}
          />
        );
      case "changestate":
        return <ChangeState subStageDate={subStageDate} />;

      case "documents":
        return (
          <DocumentGroup
            companyList={companyList}
            subStageDate={subStageDate}
            trade={"Trade"}
            componentLocations={componentLocations}
          />
        );
      case "history":
        return (
          <History
            screen="TRADE"
            id={subStageDate.subStageUID}
            companyList={companyList}
          />
        );
      case "participants":
        return (
          <ParticipantsDetails
            companyList={companyList}
            Data={Data}
            stageData={stageData}
            secondFlowSequence={secondFlowSequence}
            initialState={initialState}
          />
        );
      default:
        return (
          <Summary
            handleHeaderSelectChange={handleHeaderSelectChange}
            subStageDate={subStageDate}
            hanndleCraateGroup={hanndleCraateGroup}
            companyList={companyList}
            trade={"Trade"}
            data={Data}
          />
        );
    }
  };

  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");

  useEffect(() => {
    let Temp = [];
    const uniqueValues = new Set();
    subStageDate?.documentGroups?.forEach((group) => {
      const docCategory = group.docCategory;
      if (!uniqueValues.has(docCategory)) {
        Temp.push({
          label: docCategory,
          value: docCategory,
        });
        uniqueValues.add(docCategory);
      }
    });
    setDocCategory(Temp);
  }, [subStageDate]);

  const handleChange = (e) => {
    if (e?.target?.name === "Category") {
      const filteredSubcategories = subStageDate?.documentGroups?.filter(
        (data) => data.docCategory === e?.target?.value
      );

      let Temp = [];
      const uniqueValues = new Set();
      filteredSubcategories?.forEach((group) => {
        const docCategory = group.docSubCategory;
        if (!uniqueValues.has(docCategory)) {
          Temp.push({
            label: docCategory,
            value: docCategory,
          });
          uniqueValues.add(docCategory);
        }
      });
      setDocSubCategory(Temp);
      setSelectedCat(e?.target?.value);
      setSelectedSubCat(null);
    } else if (e?.target?.name === "subCategory") {
      setSelectedSubCat(e?.target?.value);
    } else {
      setData({
        ...data,
        file: e.target.files[0],
        name: e.target.files[0].name,
      });
    }
  };

  const handleNext = () => {
    if (selectedCat && selectedSubCat) {
      setIsNext(true);
    } else if (!selectedCat) {
      Error("Please select a category");
    } else if (!selectedSubCat) {
      Error("Please select a sub-category");
    }
  };

  const handleDocumentUpdate = async (event) => {
    event.preventDefault();
    var body = new FormData();
    body.append("name", data.name);
    body.append("file", data.file);
    body.append("public", true);
    if (selectedCat === "" || selectedSubCat === "") {
      Error("Please Select Category & Sub-Category");
      return;
    } else if (data.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${documentURL}documents`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setIsLoading(false);
          } else {
            handleDocumentGroupUpdate(data);
          }
        });
    }
  };

  const handleDocumentGroupUpdate = async (data) => {
    let instance = param?.id;
    let requestPayload = {
      memberId: companyId,
      ownerId: companyId,
      docReferenceId: data.id,
      documentName: data.name,
      docCategory: selectedCat,
      docSubCategory: selectedSubCat,
      participantsToSign: [],
      participantsToShare: [],
      referenceData: [],
      tags: [
        `TRADE-${subStageDate?.tradeId}`,
        `TRADE-STAGE-${subStageDate?.stageUID}`,
        `TRADE-SUB-STAGE-${subStageDate?.subStageUID}`,
      ],
    };
    await fetch(
      docGroupURL +
        `bff/documentsGroups/documentUpdate?documentId=${data.id}&template=Trade&instanceID=${instance}`, // instanceID === trade id   if refence date has TRADE key use it value to populate instance ID  if trade is not present use SERVICE DEAL ID is instanceID  which is in URL // URL will become &instanceId= '2sddfdfdfdfdfb'
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(requestPayload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setUploading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          Success("Uploaded successfully");
        }
      });
  };

  return (
    <>
      {subStageDate ? (
        <div
          style={{
            backgroundColor: "black",
            width: "100%",
            padding: "1%",
          }}>
          <div style={{ marginTop: "1%" }}>
            <div style={{ display: "flex", textAlign: "center" }}>
              <button
                className="headerHover"
                style={
                  param.node === "summary" || param.node === undefined
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                        fontSize: "16px",
                      }
                    : { fontSize: "16px" }
                }
                onClick={(e) => handleHeaderNav(e, "summary")}>
                Summary
              </button>
              <button
                className="headerHover"
                style={
                  param.node === "changestate"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                        fontSize: "16px",
                      }
                    : { fontSize: "16px" }
                }
                onClick={(e) => handleHeaderNav(e, "changestate")}>
                Change State
              </button>
              <button
                className="headerHover"
                style={
                  param.node === "documents"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                        fontSize: "16px",
                      }
                    : { fontSize: "16px" }
                }
                onClick={(e) => handleHeaderNav(e, "documents")}>
                Documents
              </button>
              <button
                className="headerHover"
                style={
                  param.node === "participants"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                        fontSize: "16px",
                      }
                    : { fontSize: "16px" }
                }
                onClick={(e) => handleHeaderNav(e, "participants")}>
                Participants
              </button>
              <button
                className="headerHover"
                style={
                  param.node === "history"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                        fontSize: "16px",
                      }
                    : { fontSize: "16px" }
                }
                onClick={(e) => handleHeaderNav(e, "history")}>
                History
              </button>
            </div>
            <hr style={{ margin: "1%" }}></hr>
            <SBProvider
              appId={APP_ID}
              userId={memberId}
              accessToken={chatToken}>
              <div>
                {headerSelect && (
                  <>
                    {isUploading ? (
                      <>
                        {isNext ? (
                          <div>
                            {isLoading ? (
                              <div>Loading....</div>
                            ) : (
                              <form
                                onSubmit={(e) => handleDocumentUpdate(e)}
                                className={classes.UploadForm}>
                                <h5>Upload Document</h5>
                                <div className={classes.Upload}>
                                  <label
                                    onChange={handleChange}
                                    htmlFor="formId">
                                    <div className={classes.UploadDiv}>
                                      <span style={{ color: "#42a5f5" }}>
                                        <input
                                          name="file"
                                          accept=".doc,.  docx,.pdf,.jpeg"
                                          type="file"
                                          id="formId"
                                          hidden
                                        />
                                        <MdDriveFolderUpload size={40} />
                                      </span>

                                      <span className="mx-2">
                                        {data.name
                                          .split(".")
                                          .slice(0, -1)
                                          .join(".")}
                                      </span>
                                    </div>
                                  </label>
                                </div>
                                <div className="d-flex justify-content-end my-2">
                                  <Button
                                    onClick={() => {
                                      setUploading(false);
                                      setIsNext(false);
                                      setSelectedCat(null);
                                      setSelectedSubCat(null);
                                    }}>
                                    Cancel
                                  </Button>
                                  <Button
                                    type="submit"
                                    style={{ cursor: "pointer" }}>
                                    Submit
                                  </Button>
                                </div>
                              </form>
                            )}
                          </div>
                        ) : (
                          <>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                              className="my-2">
                              <div
                                style={{ width: "30%", marginRight: "10px" }}>
                                <Select
                                  placeholder="Document Category"
                                  options={docCategory}
                                  value={selectedCat}
                                  name="Category"
                                  handleChange={handleChange}
                                />
                              </div>
                              <div style={{ width: "30%" }}>
                                <Select
                                  placeholder="Document Sub-Category"
                                  name="subCategory"
                                  value={selectedSubCat}
                                  options={docSubCategory}
                                  handleChange={handleChange}
                                />
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <Button
                                onClick={() => {
                                  setUploading(false);
                                  setIsNext(false);
                                  setSelectedCat(null);
                                  setSelectedSubCat(null);
                                }}>
                                Cancel
                              </Button>
                              <Button onClick={() => handleNext()}>Next</Button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {param?.node === "documents" && (
                          <div className="d-flex justify-content-end">
                            <Button onClick={() => setUploading(true)}>
                              Upload
                            </Button>
                          </div>
                        )}
                        {Component()}
                      </>
                    )}
                  </>
                )}
              </div>
            </SBProvider>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
