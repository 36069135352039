import "./styles.css";
import React, { useState, useMemo } from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
  TextField,
  InputAdornment,
} from "@mui/material";
import { BiSearchAlt } from "react-icons/bi";
export default ({
  name,
  placeholder,
  required,
  options,
  handleChange,
  value,
  readOnly,
  disabled,
  small,
  selected,
}) => {
  //   const allOptions = options?.map(item=>{
  //     return item.label
  //   })
  const containsText = (text, searchText) => {
    if (text) {
      return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
    }
  };
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(
    () => options?.filter((option) => containsText(option.label, searchText)),
    [searchText, options]
  );

  return (
    <Box>
      <FormControl
        fullWidth
        focused
        required={required}
        disabled={disabled}
        size={small}>
        <InputLabel id="search-select-label">{placeholder}</InputLabel>
        <Select
          name={name}
          inputProps={{ readOnly: readOnly }}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: 230,
                "& .MuiMenuItem-root": {
                  padding: 0.5,
                  paddingLeft: 2,
                  fontSize: 12,
                },
              },
            },
          }}
          labelId="search-select-label"
          id="search-select"
          value={value}
          selected={selected}
          label={placeholder}
          onChange={(e) => handleChange(e)}
          onClose={() => setSearchText("")}
          variant="outlined"
          sx={{
            color: "#fff",
            "& .MuiSvgIcon-root": {
              color: "rgb(66, 165, 245)",
            },
          }}

          // This prevents rendering empty string in Select's value
          // if search text would exclude currently selected option.
          // renderValue={() => value}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader
            sx={{
              color: "red",
              backgroundColor: "black",
              margin: 1,
              borderRadius: 0.5,
              padding: 2,
              paddingBottom: 1,
              marginTop: 0,
            }}>
            <TextField
              focused
              sx={{}}
              size="small"
              label="Search Item"
              autoFocus
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <BiSearchAlt color="rgb(66, 165, 245)" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions?.map((option, i) => (
            <MenuItem key={i} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
