import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { invoiceURL, docGroupURL, documentURL } from "services/BaseURLInstance";
import { getCookie } from "components/Helper";
import "assets/css/file_upload.css";

import classes from "./upload.module.css";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { MdDriveFolderUpload } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import Content from "components/UI/Layout/Content";
import Loader from "components/Loader/Loader";
import Container from "components/UI/Layout/Container";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DocumentFinder from "components/documentFinder/documentFinder";

export default () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("locationlocation", location);

  const { instance, accounts } = useMsal();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  sessionStorage.setItem("test", 1);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const docData = JSON.parse(getCookie("upload_doc"));
  const [open, setOpen] = useState(false);

  const [data, setData] = useState({
    name: "",
    file: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  };

  const handleInvoiceUpload = async (event) => {
    event.preventDefault();

    if (data.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      setIsLoading(true);
      var body = new FormData();
      body.append("name", data.name);
      body.append("file", data.file);
      const requestOptions = {
        method:
          location.state?.state?.docReferenceId === "" ||
          location.state?.state?.docReferenceId === undefined
            ? "POST"
            : "PUT",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${invoiceURL}invoices/upload`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let { message, path } = data;
          if (message || path) {
            setIsLoading(false);
            Error(message);
            setIsError("Something went wrong");
          } else {
            handleUpdateCall(data.document.id);
          }
        });
    }
  };

  const handleDocumentAddUpdate = async (event, value) => {
    event.preventDefault();
    if (data.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      let id = "";
      if (value) {
        id = "/" + value;
      }

      setIsLoading(true);
      var body = new FormData();
      body.append("name", data.name);
      body.append("file", data.file);
      body.append(
        "public",
        location?.state?.state?.docReferenceId == ""
          ? false
          : location?.state?.state?.publicView
      );
      const requestOptions = {
        method:
          location.state?.state?.docReferenceId === "" ||
          location.state?.state?.docReferenceId === undefined
            ? "POST"
            : "PATCH",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${documentURL}documents${id}`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let { message, path } = data;
          if (message || path) {
            setIsLoading(false);
            Error(message);
            setIsError("Something went wrong");
          } else {
            handleUpdateCall(data.id);
          }
        });
    }
  };

  const handleOpenDocumentFinder = () => {
    setOpen(true);
  };

  const setDocument = (value) => {
    handleUpdateCall(value.docReferenceId, value);
  };

  const handleUpdateCall = async (value, Payload) => {
    let id = location.state.state.serviceDealDetails.serviceDealId
      ? location.state.state.serviceDealDetails.serviceDealId
      : location.state.state.subStageDate.tradeId
      ? location.state.state.subStageDate.tradeId
      : companyId;

    let customTags = null;

    if (location.state.OpenTradeAccountDetails) {
      // USIng for Open Trade Account document Section
      if (
        location.state.folderLocation === "paymentDocOAT" ||
        location.state.folderLocation === "deliveryDocOAT"
      ) {
        customTags = [
          `OAT-${location.state.OpenTradeAccountDetails.openTradeAccountId}`,
        ];
      }
    }
    let requestPayload = {};

    if (Payload) {
      requestPayload = Payload;
    } else {
      requestPayload = {
        ...docData,
        memberId: companyId,
        ownerId: companyId,
        docReferenceId: value,
        documentName: data.name,
        tags: location.state.state.dealData
          ? [
              `FINANCE_DEAL-${location.state.state.dealData.financeDealId}`,
              `FINANCE_REQUISITION-${location.state.state.dealData.refFinanceRequisitionId}`,
              `INVOICE-${location.state.state.dealData.referenceFinanceInstrumentId}`,
            ]
          : location.state.state.serviceDealDetails &&
            location.state.folderLocation === "requirementDoc"
          ? [
              // `SERVICE_DEAL-${location.state.state.serviceDealDetails.serviceDealId}`,
              `SERVICE_REQUISITION-${location.state.state.serviceDealDetails.referenceServiceRequisitionId}`,
            ]
          : location.state.state.serviceDealDetails &&
            (location.state.folderLocation === "contractsDoc" ||
              location.state.folderLocation === "paymentsDoc" ||
              location.state.folderLocation === "deliveryDoc")
          ? [
              `SERVICE_DEAL-${location.state.state.serviceDealDetails.serviceDealId}`,
              `SERVICE_REQUISITION-${location.state.state.serviceDealDetails.referenceServiceRequisitionId}`,
            ]
          : location.state.state.subStageDate
          ? [
              `TRADE-${location.state.state.subStageDate.tradeId}`,
              `TRADE_STAGE-${location.state.state.subStageDate.stageUID}`,
              `TRADE_SUB_STAGE-${location.state.state.subStageDate.subStageUID}`,
            ]
          : location.state.state.invoiceId
          ? [`INVOICE-${location.state.state.invoiceId}`]
          : location.state.state.serviceId // serviceId is  Service Request Id
          ? [`SERVICE_REQUISITION-${location.state.state.serviceId}`] // serviceId is  Service Request Id
          : customTags
          ? customTags
          : [],
        participantsToSign: [],
        participantsToShare: [],
        docCategory:
          location.state?.state?.subStageDate &&
          location.state?.state?.subStageDate?.documentGroups[0]?.docCategory
            ? location.state.state.subStageDate.documentGroups[0].docCategory
            : location.state?.state?.docCategory,
        docSubCategory:
          location.state?.state?.subStageDate &&
          location.state.state.subStageDate.documentGroups[0].docSubCategory
            ? location.state.state.subStageDate.documentGroups[0].docSubCategory
            : location.state?.state?.docSubCategory,
        referenceData: location.state.state.referenceData
          ? location.state.state.referenceData
          : [],
      };
    }

    let docTemplateName = "";
    if (
      location.state.state.dealData ||
      location.state.state.serviceDealDetails ||
      location.state.state.invoiceId
    ) {
      docTemplateName = "Global";
    } else if (
      location.state.state.subStageDate ||
      location.state.state.serviceId // serviceId is  Service Request Id
    ) {
      docTemplateName = "Trade";
    } else {
      docTemplateName = "Global";
    }

    let instanceID = null;

    if (location.state.OpenTradeAccountDetails) {
      // USIng for Open Trade Account document Section
      if (location.state.folderLocation === "companyDocOAT") {
        docTemplateName = "Global";
        instanceID = companyId;
      } else if (
        location.state.folderLocation === "paymentDocOAT" ||
        location.state.folderLocation === "deliveryDocOAT"
      ) {
        instanceID = location.state.OpenTradeAccountDetails.openTradeAccountId;
        docTemplateName = "Trade";
      }
    }

    if (location.state.folderLocation === "companyDoc") {
      instanceID = companyId;
      docTemplateName = "Global";
    } else if (location.state.folderLocation === "serviceRequestRequired") {
      instanceID = location.state.state.serviceId; // serviceId is  Service Request Id
      docTemplateName = "Trade";
    } else if (location.state.folderLocation === "requirementDoc") {
      instanceID =
        location.state.state.serviceDealDetails.referenceServiceRequisitionId;
      docTemplateName = "Trade";
    } else if (
      location.state.folderLocation === "paymentsDoc" ||
      location.state.folderLocation === "contractsDoc" ||
      location.state.folderLocation === "deliveryDoc"
    ) {
      docTemplateName = "Trade";
      instanceID = location.state.state.serviceDealDetails.serviceDealId;
    }

    const options = {
      method: "PUT",
      body: requestPayload,
    };
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        docGroupURL +
          `bff/documentsGroups/documentUpdate?documentId=${value}&instanceID=${
            instanceID || id
          }&template=${docTemplateName}`,
        options,
        response
      )
        .then((data) => {
          setIsLoading(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError("Something went wrong");
          } else {
            Success(
              Payload ? "Document Linked Successfully" : "Uploaded Successfully"
            );
            sessionStorage.setItem("profileStepperIndex", JSON.stringify(3));
            navigate(-1);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <>
      {isLoading ? (
        <Container>
          <Content>
            <span>Loading..</span>
            <Loader />
          </Content>
        </Container>
      ) : (
        <Container>
          <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={open}
            PaperProps={{
              sx: { backgroundColor: "#343434", color: "white" },
            }}>
            <DialogContent sx={{ padding: "0px" }}>
              <div>
                <DocumentFinder
                  setOpen={setOpen}
                  setDocument={setDocument}
                  data={location.state}
                />
              </div>
            </DialogContent>
          </Dialog>
          {!isError && (
            <div>
              <form
                onSubmit={
                  !location.state.state.invoiceId
                    ? (e) =>
                        handleDocumentAddUpdate(e, location.state.state.ref)
                    : (e) => handleInvoiceUpload(e)
                }
                className={classes.UploadForm}>
                <h5>Upload Document</h5>

                <div className={classes.Upload}>
                  <label onChange={handleChange} htmlFor="formId">
                    <div className={classes.UploadDiv}>
                      <span style={{ color: "#42a5f5" }}>
                        <input
                          name="file"
                          accept=".doc,.  docx,.pdf,.jpeg"
                          type="file"
                          id="formId"
                          hidden
                        />
                        <MdDriveFolderUpload size={40} />
                      </span>

                      <span className="mx-2">
                        {data.name.split(".").slice(0, -1).join(".")}
                      </span>
                    </div>
                  </label>
                </div>

                <ButtonDiv>
                  <Button
                    onClick={() => handleOpenDocumentFinder()}
                    style={{ cursor: "pointer" }}>
                    Link Existing Document
                  </Button>
                  <Button
                    onClick={() => {
                      sessionStorage.setItem(
                        "profileStepperIndex",
                        JSON.stringify(3)
                      );
                      window.history.back();
                    }}
                    style={{ cursor: "pointer" }}>
                    Cancel
                  </Button>
                  <Button type="submit" style={{ cursor: "pointer" }}>
                    Upload
                  </Button>
                </ButtonDiv>
              </form>
            </div>
          )}
          {isError && (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          )}
        </Container>
      )}
    </>
  );
};
