import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import Wrapper from "components/UI/Layout/Wrapper";
import classes from "./tracking.module.css";
import { TrackingListHeader } from "utils/Section/InvoiceSection";
import cn from "classnames";
import Table from "react-bootstrap/Table";
import MultiSelect from "components/fields/MultiSelect";
import Loader from "components/Loader/Loader";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { TRACKING } from "services/BaseURLInstance";
import { HiArrowNarrowLeft } from "react-icons/hi";
import Input from "components/fields/Input";
import ShipmentSummary from "pages/financing/Tracking/shipmentSummary";
import GoogleMap from "pages/financing/Tracking/GoogleMap";
import DateInput from "components/fields/DateInput";
import { FormatDate } from "utils/helper";
import { CiFilter } from "react-icons/ci";
import AddTrackingForm from "./AddTrackingForm";
import NoRecord from "components/no-data/noRecord";

const Tracking = () => {
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [filteredResponse, setFilteredResponse] = useState(null);
  const [isDetail, setIsDetail] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [isAddTracking, setAddTracking] = useState(false);
  const [selectStatus, setSelectStatus] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [inputSearch, setInputSearch] = useState("");

  // const [formData, setFormData] = useState({
  //   carrier_no: "",
  //   cntr_no: "",
  //   bl_no: "",
  // });

  // const handleChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const STATUS = [
    { label: "Selected 1", value: "Value selected 1" },
    { label: "Selected 2", value: "Value selected 2" },
    { label: "Selected 3", value: "Value selected 3" },
  ];

  useEffect(() => {
    let isCancelled = false;

    // Use the below URL for testing purposes on UAT and integration testing
    // let URL = `${TRACKING}shipment/trackingData?memberUID=${process.env.REACT_APP_TRACKING_TEST_MEMEBERID}`;

    // Todo to be uncommented for PROD.
    let URL = `${TRACKING}shipment/trackingData?memberUID=${companyId}`;

    setLoading(true);
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          setLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setError(message || "Something Went Wrong");
          } else {
            setResponse(data);
            console.log("datattatatattatatatta",data)
            setFilteredResponse(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const handleMultiChange = (e, name) => {
    if (name === "status") {
      const { value } = e.target;
      setSelectStatus(value);
    }

    if (name === "textsearch") {
      const { value } = e.target;
      setInputSearch(value);
    }

    if (name === "datefrom") {
      setDateFrom(new Date(e).toLocaleDateString());
    }

    if (name === "dateend") {
      setDateTo(new Date(e).toLocaleDateString());
    }
  };

  useEffect(() => {
    let temporaryList = [];
    selectStatus.map((select) => {
      temporaryList.push(select.value);
    });
    setSelectedStatusId(temporaryList);
  }, [selectStatus]);

  const GetDetails = (data) => {
    // Use the below URL for testing purposes on UAT and integration testing
    // let URL = `${TRACKING}shipment/details?carrierNo=${data.carrierNo}&containerNo=${data.contNo}&memberUID=${process.env.REACT_APP_TRACKING_TEST_MEMEBERID}`;

    // TODO uncommented for production use
    let URL = `${TRACKING}shipment/details?carrierNo=${data.carrierNo}&containerNo=${data.contNo}&memberUID=${companyId}`;

    setLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response).then((data) => {
        setLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
          setError(message || "Something Went Wrong");
        } else {
          setDetailData(data);
          setIsDetail(true);
        }
      });
    });
  };

  // useEffect(() => {
  //   let newdate = Date.parse(dateTo);
  //   let olddate = Date.parse(dateFrom);

  //   if (
  //     (selectedStatusId && selectedStatusId.length > 0) ||
  //     dateFrom ||
  //     dateTo ||
  //     inputSearch
  //   ) {
  //     let filtered =
  //       response &&
  //       response.filter((data) => {
  //         return (
  //           (selectedStatusId.length > 0
  //             ? selectedStatusId.includes(data.paymentStatus)
  //             : data) &&
  //           (inputSearch ? inputSearch.includes(data.contNo) : data) &&
  //           (dateFrom ? Date.parse(data.updatedDate) >= olddate : data) &&
  //           (dateTo ? Date.parse(data.updatedDate) <= newdate : data)
  //         );
  //       });
  //     setFilteredResponse(filtered);
  //   } else if (
  //     selectedStatusId.length === 0 &&
  //     !dateFrom &&
  //     !dateTo &&
  //     !inputSearch
  //   ) {
  //     setFilteredResponse(response);
  //   }
  // }, [selectedStatusId, dateFrom, dateTo, inputSearch]);

  useEffect(() => {
    let newdate = Date.parse(dateTo);
    let olddate = Date.parse(dateFrom);

    if (selectedStatusId.length > 0 || dateFrom || dateTo || inputSearch) {
      let filtered = response.filter((data) => {
        const isStatusMatch =
          selectedStatusId.length > 0
            ? selectedStatusId.includes(data.paymentStatus)
            : true;

        const isDateFromMatch = dateFrom
          ? Date.parse(data.updatedDate) >= olddate
          : true;

        const isDateToMatch = dateTo
          ? Date.parse(data.updatedDate) <= newdate
          : true;

        const isSearchMatch = inputSearch
          ? data.contNo
              .toLocaleLowerCase()
              .includes(inputSearch.toLocaleLowerCase())
          : true;

        return (
          isStatusMatch && isDateFromMatch && isDateToMatch && isSearchMatch
        );
      });
      setFilteredResponse(filtered);
    } else {
      setFilteredResponse(response);
    }
  }, [selectedStatusId, dateFrom, dateTo, inputSearch, response]);

  const ClearFilter = () => {
    setInputSearch("");
    setDateTo(null);
    setDateFrom(null);
    setSelectedStatusId([]);
    setSelectStatus([]);
  };

  return (
    <div className={classes.Wrapper}>
      {isLoading ? (
        <Content>
          <span>Loading....</span>
          <Loader />
        </Content>
      ) : (
        <>
          {!isError ? (
            <>
              {isAddTracking ? (
                <>
                  <AddTrackingForm
                    setLoading={setLoading}
                    setAddTracking={setAddTracking}
                    // TODO UNCOMMENT FOR PROD
                     memberuid={companyId}

                    // memberuid={process.env.REACT_APP_TRACKING_TEST_MEMEBERID}
                    
                  />
                  {/* <Content>
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <h5>Add Tracking</h5>
                      <div className={classes.FormDiv}>
                        <div>
                          <Input
                            name="carrier_no"
                            value={formData.carrier_no}
                            placeholder="Carrier Number"
                            handleChange={handleChange}
                          />
                        </div>
                        <div>
                          <Input
                            name="cntr_no"
                            value={formData.cntr_no}
                            placeholder="Container Number"
                            handleChange={handleChange}
                          />
                        </div>
                        <div>
                          <Input
                            name="bl_no"
                            value={formData.bl_no}
                            placeholder="Bill of Lading"
                            handleChange={handleChange}
                          />
                        </div>
                        <div></div>
                      </div>
                      <ButtonDiv>
                        <Button onClick={() => setAddTracking(false)}>
                          Cancel
                        </Button>
                        <Button type="submit">Submit</Button>
                      </ButtonDiv>
                    </form>
                  </Content> */}
                </>
              ) : (
                <>
                  {isDetail ? (
                    <div>
                      <div
                        style={{ color: "#218eeb", cursor: "pointer" }}
                        onClick={() => setIsDetail(false)}>
                        <span>
                          <HiArrowNarrowLeft size={28} />
                        </span>
                        <span>Back to List </span>
                      </div>
                      <ShipmentSummary
                        response={detailData && detailData.tracking_data}
                      />
                    </div>
                  ) : (
                    <>
                      <Content marginBottom="2%">
                        <div className={classes.headerDiv}>
                          <div style={{ fontSize: "20px" }}>
                            Search Shipment Tracking
                          </div>
                        </div>
                        <div className={classes.InputDiv}>
                          <input
                            type="text"
                            name="searchtext"
                            value={inputSearch}
                            placeholder="Enter Container Number"
                            onChange={(e) => handleMultiChange(e, "textsearch")}
                          />
                          <div>
                            <button onClick={() => setAddTracking(true)}
                            style={{backgroundColor:"#42a5f5",
                            color:"white",
                            padding:"10px 10px",
                            border:"none",
                            borderRadius:"5px",
                            marginLeft:"10px"}}>
                              Add Tracking
                            </button>
                          </div>
                        </div>
                        <div className={classes.searchDiv} style={{display:"flex", justifyContent:"space-between"}} >
                          <div style={{margin:"0px"}}>
                            <MultiSelect
                              name="memberlist"
                              selected={selectStatus}
                              placeholder="Status"
                              options={STATUS}
                              handleChange={(e) =>
                                handleMultiChange(e, "status")
                              }
                            />
                          </div>
                          <div style={{margin:"0px 8px"}}>
                            <DateInput
                              backDate={true}
                              name="DateFrom"
                              value={dateFrom}
                              placeholder="Date From *"
                              handleChange={(e) =>
                                handleMultiChange(e, "datefrom")
                              }
                            />
                          </div>
                          <div style={{margin:"0px"}}>
                            <DateInput
                              backDate={true}
                              name="DateFrom"
                              value={dateTo}
                              placeholder="Date To *"
                              handleChange={(e) =>
                                handleMultiChange(e, "dateend")
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={classes.clearFilterDiv}
                          onClick={() => ClearFilter()}>
                          <button style={{backgroundColor:"#42a5f5",
                             color:"white",
                             padding:"5px 10px",
                             border:"none",
                             borderRadius:"5px"}}>
                              Clear Filter
                              </button>
                          {/* <span>
                            <CiFilter />
                          </span> */}
                        </div>
                      </Content>
                      <Content padding="1.5% 2% 1% 2%">
                        <h5>Search Result</h5>
                        <Table
                          bordered
                          responsive
                          className={cn(classes.Table)}>
                          <thead>
                            <tr>
                              {TrackingListHeader.map((header, index) => (
                                <td key={index}>{header.name}</td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredResponse &&
                              filteredResponse.length > 0 &&
                              filteredResponse.map((data, index) => (
                                <tr key={index}>
                                  <td
                                    onClick={() => GetDetails(data)}
                                    style={{
                                      color: "rgb(33, 142, 235)",
                                      textDecorationLine: "underline",
                                    }}>
                                    {data.carrierNo ? data.carrierNo : "-"}
                                  </td>
                                  <td>{data.contNo ? data.contNo : "-"}</td>
                                  <td>{data.blNo ? data.blNo : "-"}</td>
                                  <td>
                                    {data.bookmarkId ? data.bookmarkId : "-"}
                                  </td>
                                  {/* <td>
                                    {data.updatedDate
                                      ? new Date(
                                          data.updatedDate
                                        ).toLocaleDateString()
                                      : "-"}
                                  </td> */}
                                  <td>{FormatDate(data.updatedDate)}</td>
                                  <td></td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                        {filteredResponse && filteredResponse.length === 0 && <NoRecord />}
                      </Content>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <Content>{isError}</Content>
          )}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Tracking;
