import { useState, useContext } from "react";
import classes from "./Upload.module.css";

import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Content from "components/UI/Layout/Content";
import { documentURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import { MdDriveFolderUpload } from "react-icons/md";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { Success, Error } from "components/notifications/responseHandle";
import { NetworkURL } from "services/BaseURLInstance";
import { AuthContext } from "contexts/Auth/AuthContext";

const Upload = ({ imageData, setImageData, companyData, setIsUpload }) => {
  const { isApiTrigger, setApiTrigger } = useContext(AuthContext);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  const handleChange = (e) => {
    setImageData({
      ...imageData,
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  };

  const handleDocumentUpload = async (event) => {
    event.preventDefault();
    if (imageData.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      setIsLoading(true);
      var body = new FormData();
      body.append("name", imageData.name.split(".").slice(0, -1).join("."));
      body.append("public", false);
      body.append("file", imageData.file);
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${documentURL}documents`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsLoading(false);
          } else {
            handlePut(data);
          }
        });
    }
  };

  const handlePut = async (data) => {
    if (memberType === "FINANCER") {
      companyData.financerLogo = data.id;
    } else {
      companyData.companyLogoURL = data.id;
    }
    const options = {
      method: "PUT",
      body: companyData,
    };
    let URL = "";
    if (memberType === "FINANCER") {
      URL = `${NetworkURL}financers/${companyId}`;
    } else {
      URL = `${NetworkURL}companies/${companyId}`;
    }
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          setIsLoading(false);
          let { message, error } = data;
          if (message || error) {
            let display = message || "Something went wrong";
            Error(display);
            setIsError(display);
          } else {
            Success("Added Successfully");
            setIsUploaded("Uploaded Successfully");
            setIsUpload(false);
          }
        })
        .finally(() => {
          setApiTrigger(!isApiTrigger);
        });
    });
  };

  return (
    <>
      <Content>
        {isLoading ? (
          <span>
            Loading...
            <Loader />
          </span>
        ) : (
          <>
            {isUploaded ? (
              <span>Uploaded successfully</span>
            ) : (
              <form className={classes.UploadForm}>
                <h5>Upload Image</h5>

                <div className={classes.Upload}>
                  <label onChange={handleChange} htmlFor="formId">
                    <div className={classes.UploadDiv}>
                      <span style={{ color: "#42a5f5" }}>
                        <input
                          name="file"
                          accept=".doc,.docx,.pdf,.jpeg,.png,.jpg"
                          type="file"
                          id="formId"
                          hidden
                        />
                        <MdDriveFolderUpload size={40} />
                      </span>
                      <span className="mx-2">300 &#10060; 300</span>

                      <span className="mx-2">
                        {imageData.name.split(".").slice(0, -1).join(".")}
                      </span>
                    </div>
                  </label>
                </div>

                <ButtonDiv>
                  <Button onClick={(e) => handleDocumentUpload(e)}>
                    Upload
                  </Button>
                </ButtonDiv>
              </form>
            )}

            {isError && <span>Something went wrong</span>}

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
      </Content>
    </>
  );
};
export default Upload;
