import React from "react";
import styles from "../../invoice.module.css";

const SupplierDetails = ({ data }) => {
  const Response = [
    {
      name: "Supplier GSTIN",
      value: data ? data.Gstin : "",
    },
    {
      name: "Supplier Legal Name",
      value: data ? data.LglNm : "",
    },
    {
      name: "Supplier Address 1",
      value: data ? data.Addr1 : "",
    },
    {
      name: "Supplier Address 2",
      value: data ? data.Addr2 : "",
    },
    {
      name: "Supplier place",
      value: data ? data.Loc : "",
    },
    {
      name: "Pincode",
      value: data ? data.Pin : "",
    },
    {
      name: "Supplier State Code",
      value: data ? data.Stcd : "",
    },
  ];
  return (
    <div className={styles.summary}>
      {Response &&
        Response.map((data, i) => (
          <div className={styles.summaryWrapper} key={i}>
            <div>
              {" "}
              <span> {data.name} </span>{" "}
            </div>
            <div>
              {" "}
              <span style={addBlue}> {data.value} </span>{" "}
            </div>
          </div>
        ))}
    </div>
  );
};

export default SupplierDetails;

const addBlue = {
  color: "#42A5F5",
};
