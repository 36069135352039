import React from "react";
import classes from "../../../../components/UI/Table/GlobalTable.module.css";

const itemList = ({ itemList }) => {
  const header = [
    {
      name: "SI No.",
      flex: "flex-1",
    },
    {
      name: "Description",
      flex: "flex-2",
    },
    {
      name: "Type",
      flex: "flex-1",
    },
    {
      name: "HSN Code",
      flex: "flex-1",
    },
    {
      name: "Quantity",
      flex: "flex-1",
    },
    {
      name: "Unit Price",
      flex: "flex-1",
    },
    {
      name: "Gross Amt",
      flex: "flex-1",
    },
    {
      name: "Taxable Amt",
      flex: "flex-1",
    },
    {
      name: "Tax Rate",
      flex: "flex-1",
    },
    {
      name: "Tax Amount",
      flex: "flex-1",
    },
    {
      name: "Total Amount",
      flex: "flex-1",
    },
  ];

  //  SI No. === SlNo
  // Description == PrdDesc
  // Type ====
  // HSN Code === HsnCd
  // Quantity === Qty
  // Unit Price === UnitPrice
  // Gross Amt === TotalItemVal
  // Taxable Amt === PreTaxVal
  // Tax Rate ===  GstRt
  // Tax Amount === CgstAmt
  // Total Amount ===  TotAmt

  return (
    <div>
      <div>
        <div className={classes["invoice-tableHeader"]}>
          {header.map((list) => (
            <div key={list.name} className={classes[`${list.flex}`]}>
              {list.name}
            </div>
          ))}
        </div>
      </div>
      {itemList &&
        itemList.map((item, index) => (
          <>
            <div className={classes["invoice-tablebody"]} key={index}>
              <div className={classes["flex-1"]}>
                {item.SlNo ? item.SlNo : "NA"}
              </div>

              <div className={classes["flex-2"]}>
                {item.PrdDesc ? item.PrdDesc : "NA"}
              </div>

              <div className={classes["flex-1"]}>NA</div>

              <div className={classes["flex-1"]}>
                {item.HsnCd ? item.HsnCd : "NA"}
              </div>

              <div className={classes["flex-1"]}>
                {item.Qty ? item.Qty : "NA"}
              </div>

              <div className={classes["flex-1"]}>
                {item.UnitPrice ? item.UnitPrice : "NA"}
              </div>

              <div className={classes["flex-1"]}>
                {item.TotalItemVal ? item.TotalItemVal : "NA"}
              </div>

              <div className={classes["flex-1"]}>
                {item.PreTaxVal ? item.PreTaxVal : "NA"}
              </div>

              <div className={classes["flex-1"]}>
                {item.GstRt ? item.GstRt : "NA"}
              </div>

              <div className={classes["flex-1"]}>
                {item.CgstAmt ? item.CgstAmt : "NA"}
              </div>

              <div className={classes["flex-1"]}>
                {item.TotAmt ? item.TotAmt : "0"}
              </div>
            </div>
          </>
        ))}
    </div>
  );
};

export default itemList;
