import React, { useState, useEffect } from "react";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { Success, Error } from "components/notifications/responseHandle";
import Loader from "components/Loader/Loader";
import { HiArrowNarrowLeft } from "react-icons/hi";
import Content from "components/UI/Layout/Content";
import MultiSelect from "components/fields/MultiSelect";
import cn from "classnames";
import style from "./add.module.css";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { SearchServices } from "utils/Section/FormInfo";
import ServiceList from "components/UI/NetworkSection/AddDeal_ServiceCard";
import { CompanyList } from "utils/API/CompanyList.js";
import { CheckCountryTitle } from "utils/helper";
const AddServiceDeal = ({ setIsAddServiceDeal, clickedServiceData }) => {
  const CompanyObject = CompanyList();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [response, setResponse] = useState(null);
  const [filterList, setFilterList] = useState(null);
  const { instance, accounts } = useMsal();

  const [countryOptions, setCountryOptions] = useState(null);
  const [country, setCountry] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState(null);
  const [category, setCategory] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState(null);
  const [subCategory, setSubCategory] = useState([]);
  const [IndustryOptions, setIndustryOptions] = useState(null);
  const [Industry, setIndustry] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_SERVICE_URL}services`,
          null,
          response
        )
          .then((data) => {
            setResponse(data);
          })
          .catch((error) => {
            Error();
            setIsError("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (clickedServiceData) {
      let TEMP = [];
      clickedServiceData?.serviceCategory?.map((data) => {
        TEMP.push({
          label: data,
          value: data,
        });
        setCategory([...category, data]);
      });
      setCategoryOptions(TEMP);

      let TEMP1 = [];
      clickedServiceData?.serviceSubCategory?.map((data) => {
        TEMP1.push({
          label: data,
          value: data,
        });
        setSubCategory([...subCategory, data]);
      });
      setSubCategoryOptions(TEMP1);

      let TEMP2 = [];
      clickedServiceData?.industry?.map((data) => {
        TEMP2.push({
          label: data,
          value: data,
        });
        setIndustry([...Industry, data]);
      });
      setIndustryOptions(TEMP2);

      let TEMP3 = [];
      clickedServiceData?.countriesForSubscription?.map((data) => {
        TEMP3.push({
          label: CheckCountryTitle(data),
          value: CheckCountryTitle(data),
        });
        setCountry([...country, data]);
      });
      setCountryOptions(TEMP3);
    }
  }, [clickedServiceData]);

  useEffect(() => {
    if (response) {
      let filtered =
        response &&
        response?.filter(
          (data) =>
            (country
              ? country.includes(data.countriesOfOperations[0])
              : data) &&
            (category ? category.includes(data.serviceCategory[0]) : data) &&
            (subCategory
              ? subCategory.includes(data.serviceSubCategory[0])
              : data) &&
            (Industry
              ? Industry.every((item) => data.industry.includes(item))
              : data)
        );
      setFilterList(filtered);
    }
  }, [response]);

  const handleCheckbox = (index, data) => {
    if (selectedIndex === index) {
      setSelectedIndex(null);
      setSelectedData(null);
    } else {
      setSelectedIndex(index);
      setSelectedData(data);
    }
  };

  const HandleSendDeal = () => {
    const options = {
      method: "POST",
    };
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/addServiceDeal?serviceProviderId=${selectedData.memberId}&serviceRequisitionId=${clickedServiceData.serviceRequisitionId}`,
        options,
        response
      )
        .then((data) => {
          Success("Service Requisition shared with selected service provider");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <div>
      <div>
        <div
          style={{ color: "#218eeb", cursor: "pointer" }}
          onClick={() => setIsAddServiceDeal(false)}>
          <span>
            <HiArrowNarrowLeft size={28} />
          </span>
          <span>Back to List </span>
        </div>
      </div>

      {isLoading || CompanyObject.isLoading ? (
        <span>
          Loading...
          <Loader />
        </span>
      ) : (
        <>
          {response && (
            <>
              <Content marginBottom="2%" padding="1% 2% 1% 2% ">
                <div>
                  <h5>
                    <OverLayTrigger value={SearchServices} />
                  </h5>
                </div>
                <div>
                  <div className={cn(style.searchDiv, "d-flex")}>
                    <div>
                      <div className="d-flex">
                        <div className={cn(style.selectDiv)}>
                          <MultiSelect
                            placeholder="Select Country"
                            selected={countryOptions}
                            isDisabled={true}
                          />
                        </div>
                        <div className={cn(style.selectDiv)}>
                          <MultiSelect
                            placeholder="Service Category"
                            selected={categoryOptions}
                            isDisabled={true}
                          />
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className={cn(style.selectDiv)}>
                          <MultiSelect
                            placeholder="Service Sub-Category"
                            selected={subCategoryOptions}
                            isDisabled={true}
                          />
                        </div>
                        <div className={cn(style.selectDiv)}>
                          <MultiSelect
                            selected={IndustryOptions}
                            placeholder="Industry"
                            isDisabled={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div></div>
                  </div>
                </div>
              </Content>

              <Content>
                {CompanyObject?.response &&
                  filterList?.map((item, index) => (
                    <div key={index}>
                      <ServiceList
                        item={item}
                        index={index}
                        selectedIndex={selectedIndex}
                        HandleSendDeal={HandleSendDeal}
                        companyId={companyId}
                        handleCheckbox={handleCheckbox}
                        companyList={CompanyObject?.response}
                      />
                    </div>
                  ))}

                {filterList?.length === 0 && (
                  <div className="alert alert-danger" role="alert">
                    <span>No Record Found</span>
                  </div>
                )}
              </Content>
            </>
          )}

          {isError && (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddServiceDeal;
