import React, { useCallback , useState } from "react";
// import useAxios from "../../../../services/useAxios";
// import { supplierApi, invoiceApi } from "../../../../services/BaseURLInstance";
// import Loader from "../../../../components/Loader";
// import { Routes, Route, Link, Outlet, NavLink } from "react-router-dom";
import { ActionBarStyled} from "../../../../../../components/Container.styled";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import style from './SavedInvoice.module.css'

import GeneralDetail from '../DetailsSaved/GeneralDetails'
import SupplierDetails from "../DetailsSaved/supplierDetails";
import BuyerDetails from "../DetailsSaved/BuyerDetails"
import ExportDetails from "../DetailsSaved/ExportDetails"
import Miscellaneous from '../DetailsSaved/Miscellaneous'

export const Details = ({data}) => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  
  const [selected , setSelected] = React.useState('General')

    const tabs = [
        { 
          title: "General", 
          description : "General",
          value: "General"
        },
    
        { 
          title: "Supplier Details", 
          description : "Supplier Details",
          value : "SupplierDetails"
        },
        { 
          title: "Buyer Details", 
          description : "Buyer Details",
          value : "BuyerDetails"
        },
        { 
          title: "Export Details", 
          description : "Export Details",
          value : "ExportDetails"
        },
        { 
            title: "Miscellaneous", 
            description : "Miscellaneous",
            value : "Miscellaneous"
        },

    ]
    
  return (
    <>
        <ActionBarStyled>
          {tabs.map((item, index) => (
            <div className={style.navbar} key={index} onClick={() =>  setSelected(item.value)} style = { selected === item.value ? addBlue : addWhite } >
              <li>{item.title}</li>
            </div>
          ))}  
        </ActionBarStyled>

        { selected === 'General' ? (
           <div> 
              <GeneralDetail data={data.invoiceDetails}/>
              <div className={style.btnDiv}>
              <button onClick={() =>  setSelected('SupplierDetails')} >Next</button>
              </div>
            </div>
        ) : '' }

        { selected === 'SupplierDetails' ? (
           <div> 
            <SupplierDetails data={data.invoiceDetails.SellerDtls}/>
            <div className={style.btnDiv}> 
                <button onClick={() =>  setSelected('General')} >Previous</button>
                <button onClick={() =>  setSelected('BuyerDetails')} >Next</button>
            </div>
           </div>
        ) : '' }

        { selected === 'BuyerDetails' ? (
           <div> 
            <BuyerDetails data={data.invoiceDetails.BuyerDtls}/>
            <div className={style.btnDiv}>
            <button onClick={() =>  setSelected('SupplierDetails')} >Previous</button>
            <button onClick={() =>  setSelected('ExportDetails')} >Next</button>
            </div>
           </div>
        ) : '' }

        { selected === 'ExportDetails' ? (
           <div>
            <ExportDetails data={data.invoiceDetails.ExpDtls}/>
            <div className={style.btnDiv}>
            <button onClick={() =>  setSelected('BuyerDetails')} >Previous</button>
            <button onClick={() =>  setSelected('Miscellaneous')} >Next</button>
            </div>
           </div>
        ) : '' }

        { selected === 'Miscellaneous' ? (
           <div>
            <Miscellaneous data={data.invoiceDetails}/>
            <div className={style.btnDiv}>
            <button onClick={() =>  setSelected('ExportDetails')} >Previous</button>
            </div>
            </div>
        ) : '' }

        </>
  )
}



// const NavBar = styled.div`
// // margin-button: 20px;
// padding-bottom:20px;
//  li{
//     cursor: pointer;
//     :hover{
//         color:#218eeb;
//     }
// }`;

// const DealDetail = styled.div `
//     // border: 1px solid #42A5F5;
//     padding: 15px;
//     margin: 10px 0px;
// `;

const addWhite = {
    color: 'white'
}
const addBlue = {
    color: '#42A5F5'
}
