import { useState, useEffect } from "react";
import classes from "./documentNew.module.css";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Content from "components/UI/Layout/Content";
import { docGroupURL, documentURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import { MdDriveFolderUpload } from "react-icons/md";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DocumentFinder from "components/documentFinder/documentFinder";

const Upload = ({
  Data,
  TradeDocumentsDetails,
  apiTrigger,
  setApiTrigger,
  location,
  setIsUploading,
}) => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [open, setOpen] = useState(false);

  const [data, setData] = useState({
    name: "",
    file: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  };

  const handleDocumentUpload = async (event) => {
    event.preventDefault();
    if (data.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      setIsLoading(true);
      var body = new FormData();
      body.append("name", data.name.split(".").slice(0, -1).join("."));
      body.append("public", Data?.publicView);
      body.append("file", data.file);

      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${documentURL}documents`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsLoading(false);
          } else {
            handleDocumentGroupUpdate(data);
          }
        });
    }
  };

  const handleOpenDocumentFinder = () => {
    setOpen(true);
  };

  const setDocument = (value) => {
    handleDocumentGroupUpdate(value);
  };

  const handleDocumentGroupUpdate = async (data) => {
    let requestPayload = {
      memberId: companyId,
      ownerId: companyId,
      docReferenceId: data.id,
      documentName: data.name,
      tags:
        location === "Director"
          ? []
          : location === "TradeSection"
          ? Data && Data.tags
          : [`OPEN_TRADE-${data.id}`, `OPEN_TRADE_MILESTONE-${data.id}`],
      docCategory: Data && Data.docCategory,
      docSubCategory: Data && Data.docSubCategory,
      participantsToSign:
        Data && Data.participantsToSign ? Data.participantsToSign : [],
      participantsToShare:
        Data && Data.participantsToShare ? Data.participantsToShare : [],
      referenceData: Data && Data.referenceData ? Data.referenceData : [],
    };

    const options = {
      method: "PUT",
      body: requestPayload,
    };

    let URL = "";

    if (TradeDocumentsDetails) {
      URL = `${docGroupURL}bff/documentsGroups/summary?memberUID=${companyId}&template=Trade${`&docFilterTags=Trade-${TradeDocumentsDetails.tradeId}&instanceID=${TradeDocumentsDetails.tradeId}`}`;
    } else {
      URL =
        docGroupURL +
        `bff/documentsGroups/documentUpdate?documentId=${data.id}&template=${location}`;
    }

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            // setIsError(message || "Something Went Wrong");
          } else {
            Success("Uploaded Successfully");
            setApiTrigger(!apiTrigger);
            setIsUploading(false);
          }
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        open={open}
        PaperProps={{
          sx: { backgroundColor: "#343434", color: "white" },
        }}>
        <DialogContent sx={{ padding: "0px" }}>
          <div>
            <DocumentFinder
              setOpen={setOpen}
              setDocument={setDocument}
              data={location.state}
            />
          </div>
        </DialogContent>
      </Dialog>
      <Content>
        {isLoading ? (
          <span>
            Loading...
            <Loader />
          </span>
        ) : (
          <>
            {!isError && (
              <form className={classes.UploadForm}>
                <h5>Upload Document</h5>

                <div className={classes.Upload}>
                  <label onChange={handleChange} htmlFor="formId">
                    <div className={classes.UploadDiv}>
                      <span style={{ color: "#42a5f5" }}>
                        <input
                          name="file"
                          accept=".doc,.  docx,.pdf,.jpeg"
                          type="file"
                          id="formId"
                          hidden
                        />
                        <MdDriveFolderUpload size={40} />
                      </span>

                      <span className="mx-2">
                        {data.name.split(".").slice(0, -1).join(".")}
                      </span>
                    </div>
                  </label>
                </div>

                <ButtonDiv>
                  <Button
                    onClick={() => handleOpenDocumentFinder()}
                    style={{ cursor: "pointer" }}>
                    Link Existing Document
                  </Button>
                  <Button
                    style={{ cursor: "pointer" }}
                    onClick={() => setIsUploading(false)}>
                    Cancel
                  </Button>
                  <Button
                    onClick={(e) => handleDocumentUpload(e)}
                    style={{ cursor: "pointer" }}>
                    Upload
                  </Button>
                </ButtonDiv>
              </form>
            )}

            {isError && <span>Something went wrong</span>}

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
      </Content>
    </>
  );
};
export default Upload;
