import React, { useState, useEffect } from "react";
import styles from "./Document.module.css";
import { docGroupURL, docUpdateURL } from "services/BaseURLInstance";

import MultiSelect from "components/fields/MultiSelect";
import Content from "components/UI/Layout/Content";
import Loader from "components/Loader/Loader";
import cn from "classnames";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import { Success, Error } from "components/notifications/responseHandle";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { ShareDocuments } from "utils/Section/FormInfo";

import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

export default ({
  tempData,
  listMember,
  // docResponse,
  // tags,
  // trade,
  serviceId, // using for service Request details ID
  serviceDealDetails, // using for service contract details
  folderLocation, // using for folder location of every document
  OpenTradeAccountDetails, // using for Open Trade Account details
  subStageDate, // Using for Trade Section .. Trade Details
}) => {
  const { instance, accounts } = useMsal();
  const companyIds = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const [selectList, setSelectList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [CompanyList, setCompanyList] = useState([]);

  // using
  const handleMultiChange = (e) => {
    const { value } = e.target;
    setSelectList(value);
  };

  // CompanyList API  // using
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (listMember && listMember) {
        let temp =
          listMember &&
          listMember.filter((item) => {
            if (
              item.memberType === "FINANCER" ||
              item.memberType === "COMPANY" ||
              item.memberType === "SERVICE_PROVIDER" ||
              item.memberUID === companyIds
            ) {
              return { label: item.memberName, value: item.memberUID };
            }
          });

        let filter =
          temp &&
          temp.map((item) => {
            return { label: item.memberName, value: item.memberUID };
          });
        setCompanyList(filter);
      }
    }

    return () => {
      isCancelled = true;
    };
  }, [listMember]);

  // using
  useEffect(() => {
    let temporaryList = [];
    const list = selectList.map((select) => {
      temporaryList.push(select.value);
    });
    setSelectedId(temporaryList);
  }, [selectList]);

  const handleSubmit = async () => {
    if (!tempData) {
      Error("You must select one document");
      return;
    }

    if (selectedId.length === 0) {
      Error("You must select one Member");
      return;
    }

    if (tempData.ownerId !== companyIds) {
      Error("You are not allowed to further share a second party document");
      return;
    }

    const requestParams = {
      docIds: [tempData?.id],
      members: selectedId,
    };

    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(requestParams),
    };

    await fetch(`${docUpdateURL}documents/share`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          Error("Network request unsuccessful");
          setIsLoading(false);
        }
        if (response.ok) {
          return response;
        }
      })

      .then((data) => {
        let { message, status } = data;
        if (message) {
          Error(message);
          setIsLoading(false);
          setIsError(message);
        } else {
          selectedId.map((item, index) => {
            handleUpdateCall(item);
            if (selectedId.length === item) {
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          });
          setIsLoading(false);
        }
      });
  };

  const handleUpdateCall = async (memberId) => {
    let requestPayload = {
      docCategory: tempData.docCategory,
      docSubCategory: tempData.docSubCategory,
      documentName: tempData.documentName,
      memberId: memberId,
      ownerId: companyIds,
      docReferenceId: tempData.id,
      participantsToShare: selectedId,
      tags: tempData.tags,
    };

    let customTemplate = null;
    let instanceID = null;

    if (OpenTradeAccountDetails) {
      // USIng for Open Trade Account document Section
      if (folderLocation === "companyDocOAT") {
        customTemplate = "Global";
        instanceID = "";
      } else if (
        folderLocation === "paymentDocOAT" ||
        folderLocation === "deliveryDocOAT"
      ) {
        customTemplate = "Trade";
        instanceID = OpenTradeAccountDetails.openTradeAccountId;
      }
    }

    if (subStageDate) {
      // Using for Trade Section .. Trade Details
      customTemplate = "Trade";
      instanceID = subStageDate.tradeId;
    }

    // Using this in Service Deal Document
    if (folderLocation === "companyDoc") {
      instanceID = "";
      customTemplate = "Global";
    } else if (folderLocation === "serviceRequestRequired") {
      instanceID = serviceId; // serviceId is  Service Request Id
      customTemplate = "Trade";
    } else if (folderLocation === "requirementDoc") {
      instanceID = serviceDealDetails.referenceServiceRequisitionId;
      customTemplate = "Trade";
    } else if (
      folderLocation === "paymentsDoc" ||
      folderLocation === "contractsDoc" ||
      folderLocation === "deliveryDoc"
    ) {
      customTemplate = "Trade";
      instanceID = serviceDealDetails.serviceDealId;
    } else if (folderLocation === "DirectorDocument") {
      // Using fro director document
      customTemplate = "Director";
      instanceID = companyIds;
    }

    const options = {
      method: "PUT",
      body: requestPayload,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        docGroupURL +
          `bff/documentsGroups/documentUpdate?documentId=${
            tempData.id
          }&memberUID=${companyIds}&template=${
            customTemplate ? customTemplate : "Global"
          }&instanceID=${instanceID ? instanceID : ""}`,
        options,
        response
      ).then((data) => {
        Success(`Success Document Shared ${memberId} Member`);
      });
    });
  };

  return (
    <>
      <div className="my-4">
        {isLoading ? (
          <Content>
            <Content>
              <span>Loading...</span>
              <Loader />
            </Content>
          </Content>
        ) : (
          <>
            {!isError && (
              <Content padding="1% 2%">
                <OverLayTrigger value={ShareDocuments} />
                <div className="d-flex">
                  <div className="col-4 my-2">
                    <h6>Recipient</h6>
                  </div>
                  <div className="col">
                    <form>
                      <div>
                        <MultiSelect
                          name="memberlist"
                          placeholder="Select Members...."
                          selected={selectList}
                          options={
                            CompanyList &&
                            CompanyList.sort((a, b) =>
                              a.label.localeCompare(b.label)
                            )
                          }
                          handleChange={handleMultiChange}
                        />
                      </div>
                      <ButtonDiv>
                        <button
                          type="button"
                          onClick={handleSubmit}
                          className={cn(styles.ButtonReq, "my-2")}>
                          Share Document
                        </button>
                      </ButtonDiv>
                    </form>
                  </div>
                </div>
              </Content>
            )}

            {isError && <span>{isError}</span>}
          </>
        )}
      </div>
    </>
  );
};
