import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./pages/App";
import Routes from "./pages/Routes";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import ReactGA from "react-ga4";
import { AuthContextProvider } from "./contexts/Auth/AuthContext";

import { InvoiceFinanceContextProvider } from "./contexts/invoiceFinance/invoiceFinanceContext";
import { FinanceDetailsContextProvider } from "./contexts/invoiceFinance/invoiceFinanceDetailsContext";
import { StoreContextProvider } from "./contexts/Store/Store";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import RootApp from "pages/RootApp";
const root = ReactDOM.createRoot(document.getElementById("root"));
export const msalInstance = new PublicClientApplication(msalConfig);

ReactGA.initialize("G-BZYQQW70NQ");
root.render(
  <React.StrictMode>
    <StoreContextProvider>
      <AuthContextProvider>
        <InvoiceFinanceContextProvider>
          <FinanceDetailsContextProvider>
            <Routes msalInstance={msalInstance}>
           
              <RootApp />
              
            </Routes>
          </FinanceDetailsContextProvider>
        </InvoiceFinanceContextProvider>
      </AuthContextProvider>
    </StoreContextProvider>
  </React.StrictMode>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
