import React from 'react';
import Style from 'components/StyledCss/styled';
import Overview from './overview';

const SyndicateLending = ({ data }) => {
 console.log("Baidya", data)
    return (
        <Style>
            <Overview data={data} />
        </Style>
    )
}

export default SyndicateLending