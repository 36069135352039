import React, { useState } from "react";
// import { invoiceFinanceDetailsContext } from '../../../../contexts/invoiceFinance/invoiceFinanceDetailsContext'
import { useEffect } from "react";

import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import Select from "components/fields/Select";
import moment from "moment";
import { Success, Error } from "components/notifications/responseHandle";

const GeneralDetail = () => {
    // const {generalData, handleGeneralChange} = useContext(invoiceFinanceDetailsContext)
   
    const [generalData, setGeneralData] = useState({
        invoiceNumber: "",
        TaxSch: "",
        SupTyp: "",
        DocDtlsType: "",
        DocDtlsNo: "",
        Dt: "",
        txlInvoiceAmount: "",
        TotlnvVal: "",
    });
    useEffect(() => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        general.invoiceDetails.ValDtls.TotInvVal=general.txlInvoiceAmount;
        setGeneralData({
            ...generalData,
            invoiceNumber: general.invoiceNumber,
            TaxSch: general.invoiceDetails.TranDtls.TaxSch,
            SupTyp: general.invoiceDetails.TranDtls.SupTyp,
            DocDtlsType:  general.invoiceDetails.DocDtls.Type,
            DocDtlsNo:  general.invoiceDetails.DocDtls.No,
            Dt: general.invoiceDetails.DocDtls.Dt,
            AssValue: general.invoiceDetails.ValDtls.TotInvValFc,
            txlInvoiceAmount: general.txlInvoiceAmount,
        });
    }, [])

    const handleGeneralChange = (event, name) => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        if (name === "Dt") {
            general.invoiceDetails.DocDtls.Dt = new Date(event).toISOString();
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [name]: new Date(event).toISOString()
            })
            return
        }
        if (event.target.name === "invoiceNumber") {
            general.invoiceNumber = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "TaxSch") {
            general.invoiceDetails.TranDtls.TaxSch = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "SupTyp") {
            general.invoiceDetails.TranDtls.SupTyp = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "DocDtlsType") {
            general.invoiceDetails.DocDtls.Type = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "DocDtlsNo") {
            general.invoiceDetails.DocDtls.No = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === 'AssValue') {
            let requestFinanceAmountTemp = parseFloat(generalData.txlInvoiceAmount);
            let financeAmountTemp = parseFloat( event.target.value === '' ? '0' : event.target.value);
            if (financeAmountTemp <= requestFinanceAmountTemp) {
                general.invoiceDetails.ValDtls.TotInvValFc= event.target.value;
                localStorage.setItem("uploadData", JSON.stringify(general));
                setGeneralData({
                    ...generalData,
                    [event.target.name]: event.target.value
                })
            }
            else {
              Error("Total taxable value should not be greater then total invoice value")
            }
          }
        // else if (event.target.name === "AssValue") {
        //     general.invoiceDetails.ValDtls.TotInvValFc= event.target.value;
        //     localStorage.setItem("uploadData", JSON.stringify(general));
        //     setGeneralData({
        //         ...generalData,
        //         [event.target.name]: event.target.value
        //     })
        // }
        else if (event.target.name === "txlInvoiceAmount") {
            general.txlInvoiceAmount= event.target.value;
            general.invoiceDetails.ValDtls.TotInvVal=event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        // else if (event.target.name === 'financeAmountRequested') {
        //     let requestFinanceAmountTemp = parseFloat(data.totalAmountWithInstrument);
        //     let financeAmountTemp = parseFloat( event.target.value === '' ? '0' : event.target.value);
        //     if (financeAmountTemp <= requestFinanceAmountTemp) {
        //         general.invoiceDetails.ValDtls.TotInvValFc= event.target.value;
        //         localStorage.setItem("uploadData", JSON.stringify(general));
        //         setGeneralData({
        //             ...generalData,
        //             [event.target.name]: event.target.value
        //         })
        //     }
        //     else {
        //       alert("Required finance amount can't be greater then invoice amount")
        //     }
        //   }
    }

    const supply_types = [
        { "label": "B2B", "value": "B2B" },
        { "label": "SEZWP", "value": "SEZWP" },
        { "label": "SEZWOP", "value": "SEZWOP" },
        { "label": "EXPWP", "value": "EXPWP" },
        { "label": "EXPWOP", "value": "EXPWOP" },
        { "label": "DEXP", "value": "DEXP" }
    ]
    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="invoiceNumber"
                        placeholder="Invoice Number"
                        value={generalData.invoiceNumber}
                        handleChange={handleGeneralChange}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="TaxSch"
                        placeholder="Tax Scheme"
                        value={generalData.TaxSch}
                        handleChange={handleGeneralChange}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Select
                        options={supply_types}
                        name="SupTyp"
                        placeholder="Supply Types"
                        handleChange={handleGeneralChange}
                        value={generalData.SupTyp}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} md={5.9} style={{ padding: "10px" }}>
                    <DateInput
                        placeholder="Document Date *"
                        value={generalData.Dt}
                        handleChange={(e) => handleGeneralChange(e, "Dt")}
                        required={true}
                        backDate={true}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="DocDtlsNo"
                        placeholder="Document Number"
                        value={generalData.DocDtlsNo}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="DocDtlsType"
                        placeholder="Document Types Code"
                        value={generalData.DocDtlsType}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="AssValue"
                        placeholder="Taxable Value Total"
                        value={generalData.AssValue}
                        handleChange={handleGeneralChange}
                        required={true}
                        type="number"
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="txlInvoiceAmount"
                        placeholder="Total Invoice Value"
                        value={generalData.txlInvoiceAmount}
                        handleChange={handleGeneralChange}
                        required={true}
                        type="number"
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default GeneralDetail;
