import React, { useCallback, useState, useEffect, useContext } from "react";

import A from "../../components/A";
import styled from "styled-components";
import { Container } from "../../components/Container.styled";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { ButtonStyled } from "../../components/Container.styled";
import useAxios from "../../services/useAxios";
import { NetworkURL } from "../../services/BaseURLInstance";

import { useMsal } from "@azure/msal-react";
import { getClaim, getIdToken } from "utils/authUtil";

import { storeContext } from "../../contexts/Store/Store";
import { notify } from "../../utils/helper";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader/Loader";

export default () => {
  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const { getData } = useContext(storeContext);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const [setingdata, setSettingData] = useState("");
  const [settingsPostData, setSettingsPostData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (idToken) {
      let URL = `${NetworkURL}members/${companyId}/preferences`;
      getData(URL, idToken).then((data) => {
        if (!isCancelled) {
          setSettingData(data);
        }
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [settingsPostData]);

  const [checkedData, setCheckedData] = useState({
    memberUID: companyId,
    preferredCommunicationMode: "SYSTEM_CHAT",
    nodeId: "",
    // profileDiscoverable:  false,
    // docConsentMandatory:  false,
    // shareDocWithSignature:  false,
    // docHistoryEnabled:  false,
    // shareContactDetails:  false,
    // openForChatInvetation: false,
    // biometricAuth: false,
    // verifyEMailAlways: false,
  });

  const handleChange = (e) => {
    setCheckedData({
      ...checkedData,
      [e.target.name]: true,
    });
  };

  useEffect(() => {
    const fetch = () => {
      if (setingdata) {
        setCheckedData({
          ...checkedData,
          profileDiscoverable: setingdata.profileDiscoverable ? true : false,
          docConsentMandatory: setingdata.docConsentMandatory ? true : false,
          shareDocWithSignature: setingdata.shareDocWithSignature
            ? true
            : false,
          docHistoryEnabled: setingdata.docHistoryEnabled ? true : false,
          shareContactDetails: setingdata.shareContactDetails ? true : false,
          openForChatInvetation: setingdata.openForChatInvetation
            ? true
            : false,
          biometricAuth: setingdata.biometricAuth ? true : false,
          verifyEMailAlways: setingdata.verifyEMailAlways ? true : false,
        });
      }
    };
    fetch();
  }, [setingdata]);

  const HandleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(checkedData),
    };
    await fetch(`${NetworkURL}members/${companyId}/preferences`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setSettingsPostData(data);
        notify("success");
        setLoading(false);
      })
      .catch((err) => {
        notify("warn");
        setLoading(false);
      });
  };

  return (
    <>
      {(setingdata === "" || loading === true) && <Loader />}
      {setingdata !== "" && (
        <Container>
          <form onSubmit={(e) => HandleSubmit(e)}>
            <Title>
              <A>My Company Profile</A>
            </Title>
            <CardWapper>
              <A>Profile</A>
              <Wrapper>
                <div className="card">
                  <div className="content">
                    <div>Make Profile discoverable</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.profileDiscoverable}
                        value={checkedData.profileDiscoverable}
                        name="profileDiscoverable"
                        onChange={(e) => handleChange(e)}
                      />
                    </span>
                  </div>
                </div>
                <div className="card">
                  <div className="content">
                    <div>Make Consent mandatory</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.docConsentMandatory}
                        value={checkedData.docConsentMandatory}
                        name="docConsentMandatory"
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                </div>
              </Wrapper>
            </CardWapper>
            <CardWapper>
              <A>Documents</A>
              <Wrapper>
                <div className="card">
                  <div className="content">
                    <div>Only share docs with digital signature</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.shareDocWithSignature}
                        value={checkedData.shareDocWithSignature}
                        name="shareDocWithSignature"
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                </div>
                <div className="card">
                  <div className="content">
                    <div>Keep Document history</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.docHistoryEnabled}
                        value={checkedData.docHistoryEnabled}
                        name="docHistoryEnabled"
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                </div>
              </Wrapper>
            </CardWapper>
            <CardWapper>
              <A>Communications</A>
              <Wrapper>
                <div className="card">
                  <div className="content">
                    <div>Share contact details</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.shareContactDetails}
                        value={checkedData.shareContactDetails}
                        name="shareContactDetails"
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                </div>
                <div className="card">
                  <div className="content">
                    <div>Allow open chat invitions</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.openForChatInvetation}
                        value={checkedData.openForChatInvetation}
                        name="openForChatInvetation"
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                </div>
              </Wrapper>
            </CardWapper>
            <CardWapper>
              <A>Boimetric Validation</A>
              <Wrapper>
                <div className="card">
                  <div className="content">
                    <div>Email Verification</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.verifyEMailAlways}
                        value={checkedData.verifyEMailAlways}
                        name="verifyEMailAlways"
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                </div>
                <div className="card">
                  <div className="content">
                    <div>Face Boimetric capture</div>
                    <span>
                      <input
                        type="checkbox"
                        defaultChecked={checkedData.biometricAuth}
                        value={checkedData.biometricAuth}
                        name="biometricAuth"
                        onChange={handleChange}
                      />
                    </span>
                  </div>
                </div>
                <div className="card">
                  <div className="content">
                    <div>Reset Pin</div>
                    <span>
                      <input type="checkbox" name="" onChange={handleChange} />
                    </span>
                  </div>
                </div>
              </Wrapper>
            </CardWapper>
            <ButtonStyled type="submit" style={{ marginLeft: "88%" }}>
              SAVE
            </ButtonStyled>
          </form>
        </Container>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .card {
    display: flex;
    flex: 100%;
    flex-direction: column;
    background: #010915;
    border-radius: 10px;
    margin: 5px 10px 5px 0px;
    .content {
      font-weight: 500;
      font-size: 16px;
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;
      flex: 1 1 auto;
      span {
        color: #218eeb;
        /* background-color: #42A5F5; */
        input {
          width: 30px;
          height: 30px;
          background: none;
        }
      }
    }
  }
`;
export const CardWapper = styled.div`
  padding: 16px;
  background: #1e1e1e;
  border-radius: 10px;
  // border-bottom: 27px solid rgba(255, 255, 255, 0.05);
  margin-bottom: 16px;
`;
const Title = styled.div`
  padding: 0px 8px 8px 0px;
`;
