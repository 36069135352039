import React from "react";
import styled from "styled-components";
const LoaderStyled = styled.div`
  z-index: 1009;
  left: 0;
  width: 100%;
  height: 100%;
  top: 60px;
  position: ${({ position }) => (position ? position : "fixed")};
  background: #f1f1f1;
  opacity: 0.4;
  .loader {
    border: 0.2rem solid #f3f3ff;
    border-top: 0.2rem solid #3498db;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    left: 50%;
    position: absolute;
    top: 30%;
    transform: translate(-50%, -50%);
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default ({ position }) => {
  return (
    <LoaderStyled position={position}>
      <div className="loader"></div>
    </LoaderStyled>
  );
};
