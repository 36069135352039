import React, { useCallback, useEffect, useState, useContext } from "react";
import { NetworkURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";

import { useNavigate } from "react-router-dom";
import { AiFillStar } from "react-icons/ai";
import { FaBuilding, FaTwitterSquare, FaWhatsappSquare } from "react-icons/fa";
import { BsTelephone } from "react-icons/bs";
import { SiGooglemaps } from "react-icons/si";
import { AiFillLinkedin } from "react-icons/ai";
import { getClaim, getIdToken } from "utils/authUtil";
import { invoiceURL, profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import xLogo from "images/xLogo.jpeg";

import { AiOutlineShareAlt } from "react-icons/ai";

import { useMsal } from "@azure/msal-react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiWechatLine } from "react-icons/ri";
import styles from "./NetworkDetail.module.css";
import { MdOutlineAttachEmail } from "react-icons/md";
import { Success, Error } from "components/notifications/responseHandle";
import { ToastContainer } from "react-toastify";
import { MdOutlineModeEditOutline, MdOutlineCancel } from "react-icons/md";
import { HiBadgeCheck } from "react-icons/hi";
import { withSendBird, SendBirdState } from "sendbird-uikit";
import cn from "classnames";
import { fetchMemberdata } from "utils/memberchat";
import { NodeName, FindCompanyName, ImageURLSrc } from "utils/helper.js";
import { LABEL } from "utils/Constants";
import { UseRequest } from "utils/API/Request.js";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { CompanyList } from "utils/API/CompanyList.js";
import { getIdTokenAsync } from "utils/authUtil";
import RadioGroupRating from "components/UI/Input/Rating";
import Modal from "react-bootstrap/Modal";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  EmailIcon,
  LineShareButton,
  LineIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";
import MailModal from "components/UI/Modal/Mail";

var sdk = null;

const mapStoreToProps = (store: SendBirdState) => {
  const {
    stores: { sdkStore, userStore },
    config: {
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub,
    },
  } = store;
  sdk = sdkStore.sdk;
};

const CardNetwork = ({
  response,
  setResponse,
  hanndleCraateGroup,
  hadTriggered,
  sethadTriggered,
}) => {
  const { instance, accounts } = useMsal();
  const [sortedData, setSortedData] = useState();

  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const CompanyObject = CompanyList();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [favoriteData, setFavoriteData] = useState("");
  const [favoriteAddData, setFavoriteAddData] = useState("");
  const [favoriteDeleteData, setFavoriteDeleteData] = useState("");

  const [networkData, setNetworkData] = useState("");
  const [networkAddData, setNetworkAddData] = useState("");
  const [networkDeleteData, setNetworkDeleteData] = useState("");
  const [Role, setRole] = useState(null);
  const [addAndRemoveRole, setAddandRemoveRole] = useState(null);
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const [selectedMember, setSelectedMember] = useState([]);
  const [isRatingIndex, setIsRatingIndex] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [sharingData, setSharingData] = useState(null);
  const [isOpening, setOpening] = useState(false);
  const [mailData, setMailData] = useState(null);

  const {
    Token,
    personName,
    companyName,
    isAuthenticated,
    loginCompanyData,
    setLoginCompanyData,
  } = useContext(AuthContext);

  const handleDetailNavigation = (type, id) => {
    handleNavigate(`./type/${type}/id/${id}`);
  };

  const handleClick = () => {
    if (
      window.location.href.substring(22, window.location.href.length) !==
        "chat" &&
      window.location.href.substring(26, 27) !== "?"
    ) {
      let url = window.location.href;
      localStorage.setItem("lasturl", url);
      navigate("/chat");
    } else {
      navigate(
        `/${localStorage
          .getItem("lasturl")
          .substring(22, localStorage.getItem("lasturl").length)}`
      );
    }
  };

  useEffect(() => {
    if (Role) {
      // setUploadInvoiceRole(
      //   CheckPermission("Invoice", "Invoices", "UploadInvoices", Role)
      // );
      setAddandRemoveRole(
        CheckPermission("AddtoNetwork", "homepage", "addtonetwork", Role)
      );
    }
  }, [Role]);

  //Gives company favorite and network details
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${NetworkURL}network/${companyId}/?memberId=${companyId}`,
          null,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setError(message);
          } else {
            setFavoriteData(data.favoriteMembers);
            setNetworkData(data.connectedMembers);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${profileURL}Persons/${response.idTokenClaims.member_id}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setError(message);
          } else {
            setRole(data.role);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [
    favoriteAddData,
    favoriteDeleteData,
    networkAddData,
    networkDeleteData,
    Role,
  ]);

  // Mapping Network function
  const filterNetwork = (memberUID) => {
    if (networkData) {
      for (let data of networkData) {
        if (data === memberUID) {
          return true;
        }
      }
    }
  };

  //Adding Network function
  const addToNetwork = async (e, memberUID) => {
    e.preventDefault();
    // const requestOp = {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + idToken,
    //     "x-api-key":
    //       "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //   },
    //   body: JSON.stringify({
    //     originMemberId: companyId,
    //     targetMemberId: memberUID,
    //   }),
    // };
    // await fetch(`${NetworkURL}network/${companyId}/members`, requestOp)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let { message, path } = data;
    //     if (message || path) {
    //       Error(message);
    //       setError(message);
    //     } else {
    //       Success("Added to Network");
    //       setNetworkAddData(data);
    //     }
    //   });

    const options = {
      method: "PUT",
      body: {
        originMemberId: companyId,
        targetMemberId: memberUID,
      },
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${NetworkURL}network/${companyId}/members`,
        options,
        response
      ).then((data) => {
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setError(message);
        } else {
          Success("Added to Network");
          setNetworkAddData(data);
        }
      });
    });
  };

  //Deleting Network function
  const deleteToNetwork = async (e, memberUID) => {
    e.preventDefault();
    // const requestOptions = {
    //   method: "DELETE",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + idToken,
    //     "x-api-key":
    //       "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //   },
    //   body: JSON.stringify({
    //     originMemberId: companyId,
    //     targetMemberId: memberUID,
    //     relationShipCategory: "Contact",
    //     referralCategory: true,
    //     referred: true,
    //     referredBy: "5dfb8d86-b4a3-f7c0-ea72-20a453ac0649",
    //     isDeleted: false,
    //     createdBy: "sit",
    //     updateBy: "",
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     tag: "sit exercitation",
    //   }),
    // };

    // await fetch(`${NetworkURL}network/${companyId}/members`, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let { message, path } = data;
    //     if (message || path) {
    //       Error(message);
    //       setError(message);
    //     } else {
    //       Success("Deleted from Network");
    //       setNetworkDeleteData(data);
    //     }
    //   });

    const options = {
      method: "DELETE",
      body: {
        originMemberId: companyId,
        targetMemberId: memberUID,
        relationShipCategory: "Contact",
        referralCategory: true,
        referred: true,
        referredBy: "5dfb8d86-b4a3-f7c0-ea72-20a453ac0649",
        isDeleted: false,
        createdBy: "sit",
        updateBy: "",
        createdDate: new Date(),
        updatedDate: new Date(),
        tag: "sit exercitation",
      },
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${NetworkURL}network/${companyId}/members`,
        options,
        response
      ).then((data) => {
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setError(message);
        } else {
          Success("Deleted from Network");
          setNetworkDeleteData(data);
        }
      });
    });
  };

  //Mapping favorites function
  const filterFavorite = (memberUID) => {
    if (favoriteData) {
      for (let data of favoriteData) {
        if (data === memberUID) {
          return true;
        }
      }
    }
  };

  //Adding favorites function
  const addToFavoite = async (e, memberUID) => {
    e.preventDefault();
    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + idToken,
    //     "x-api-key":
    //       "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //   },
    //   body: JSON.stringify({
    //     originMemberId: companyId,
    //     targetMemberId: memberUID,
    //   }),
    // };
    // await fetch(`${NetworkURL}network/${companyId}/Favorites`, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let { message, path } = data;
    //     if (message || path) {
    //       Error(message);
    //       setError(message);
    //     } else {
    //       Success("Added to favorites");
    //       setFavoriteAddData(data);
    //     }
    //   });

    const options = {
      method: "POST",
      body: {
        originMemberId: companyId,
        targetMemberId: memberUID,
      },
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${NetworkURL}network/${companyId}/Favorites`,
        options,
        response
      ).then((data) => {
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setError(message);
        } else {
          Success("Added to favorites");
          setFavoriteAddData(data);
        }
      });
    });
  };

  //deleting favorites function
  const deleteToFavoite = async (e, memberUID) => {
    e.preventDefault();
    // const requestOptions = {
    //   method: "DELETE",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + idToken,
    //     "x-api-key":
    //       "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //   },

    //   body: JSON.stringify({
    //     originMemberId: companyId,
    //     targetMemberId: memberUID,
    //     relationShipCategory: "Favorite",
    //     referralCategory: true,
    //     referred: true,
    //     referredBy: "5dfb8d86-b4a3-f7c0-ea72-20a453ac0649",
    //     isDeleted: false,
    //     createdBy: "sit",
    //     updateBy: "",
    //     createdDate: new Date(),
    //     updatedDate: new Date(),
    //     tag: "sit exercitation",
    //   }),
    // };
    // await fetch(`${NetworkURL}network/${companyId}/Favorites`, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     let { message, path } = data;
    //     if (message || path) {
    //       Error(message);
    //       setError(message);
    //     } else {
    //       Success("Deleted from favorites");
    //       setFavoriteDeleteData(data);
    //     }
    //   });

    const options = {
      method: "DELETE",
      body: {
        originMemberId: companyId,
        targetMemberId: memberUID,
        relationShipCategory: "Favorite",
        referralCategory: true,
        referred: true,
        referredBy: "5dfb8d86-b4a3-f7c0-ea72-20a453ac0649",
        isDeleted: false,
        createdBy: "sit",
        updateBy: "",
        createdDate: new Date(),
        updatedDate: new Date(),
        tag: "sit exercitation",
      },
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${NetworkURL}network/${companyId}/Favorites`,
        options,
        response
      ).then((data) => {
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setError(message);
        } else {
          Success("Deleted from favorites");
          setFavoriteDeleteData(data);
        }
      });
    });
  };

  //   const chatHandler = async (ndata) => {
  //     if (
  //       response?.consumerMemberUID !== "" &&
  //       response?.providerMemberUID !== ""
  //     ) {
  //       setItem(ndata);
  //       let res = await fetchMemberdata([
  //         companyId,
  //         ndata?.memberUID,
  //       ]);
  //       debugger;
  //       setMemberList(res);
  //       setSelectedMember(res)

  //       let nameData = [
  //         accounts[0]?.idTokenClaims?.company_name,
  //         ndata?.companyName,
  //       ];
  //       nameData = nameData.sort();
  //       let name = nameData[0] + " and " + nameData[1];
  //       var firstWord = nameData[0].replace(/ .*/, "");
  //       var secondWord = nameData[1].replace(/ .*/, "");
  //       let urlstring = firstWord + secondWord;
  // debugger
  //       urlstring = "NTA-"+urlstring
  //       hanndleCraateGroup(res, "B-BKUMARAAA", "KA-BAIDYAS");
  //     }
  //   };
  //const sortedData = data.sort((a, b) => a.name.localeCompare(b.name));
  // useEffect(() => {
  //   const sorted = response.sort((a, b) => {
  //     if (a.name < b.name) {
  //       return -1;
  //     }
  //     if (a.name > b.name) {
  //       return 1;
  //     }
  //     return 0;
  //   });

  //   setSortedData(sorted);
  //   // response = sortedData;
  // }, [sortedData]);

  const handleChat = async (response) => {
    let memberUserId = [companyId, response.memberUID];
    let res = await fetchMemberdata(memberUserId);
    let name1 = response.memberUID
      ? FindCompanyName(CompanyObject.response, response.memberUID).split(
          " "
        )[0]
      : "NA";
    let name2 = FindCompanyName(CompanyObject.response, companyId).split(
      " "
    )[0];
    if (response.name === "Tradxlink Support") {
      name1 = "TradxSupport";
    }
    let nameArray = [name1, name2];
    nameArray.sort();
    let name = nameArray.join("-");
    if (res?.length > 0) {
      hanndleCraateGroup(res, name, name);
    }
  };

  // const hanndleCraateGroup = () => {
  //   let nameData = [accounts[0]?.idTokenClaims?.company_name, item?.companyName];
  //   nameData = nameData.sort();
  //   let url = window.location.href;
  //   localStorage.setItem("lasturl", url);
  //   const selectedUsers = [accounts[0]?.localAccountId, item?.memberUID];
  //   const params = new sdk.GroupChannelParams();
  //   params.addUserIds(selectedUsers);
  //   params.isDistinct = false;
  //   params.isEphemeral = false;
  //   params.isPublic = false;
  //   params.name = nameData[0] + "-" + nameData[1];
  //   params.channelUrl = params.name;
  //   sdk.GroupChannel.createChannel(params, (response, error) => {
  //     const swapParams = sdk.getErrorFirstCallback();
  //     let groupChannel = response;
  //     let err = error;
  //     if (swapParams) {
  //       groupChannel = error;
  //       err = response;
  //     }
  //     if (err) {
  //       setIsOpenChat(true)
  //     } else {
  //       setIsOpenChat(true)
  //     }
  //   });
  // };

  useEffect(() => {
    if (memberList);
  }, [memberList]);

  const handleRatingIndex = (index) => {
    if (isRatingIndex === index) {
      setIsRatingIndex(null);
    } else {
      setIsRatingIndex(index);
    }
  };

  const RatingDiv = ({ rating }) => {
    const stars = [];
    if (Number(rating) > 0) {
      for (let i = 0; i < Number(rating); i++) {
        stars.push(<AiFillStar key={i} color="#FFA100" />);
      }

      for (let i = Number(rating); i < 5; i++) {
        stars.push(<AiFillStar key={i} color="gray" />);
      }
      return <div>{stars}</div>;
    } else {
      return <div>Not Rated</div>;
    }
  };

  const handleRating = (value, data) => {
    const options = {
      method: "POST",
      body: {
        originMemberId: companyId,
        targetMemberId: data.memberUID,
        rating: value,
      },
    };

    getIdTokenAsync(instance, accounts).then((res) => {
      UseRequest(`${NetworkURL}network/${companyId}/Ratings`, options, res)
        .then((data) => {
          Success("Member Rating Submitted Successfully");
          setIsRatingIndex(null);

          const updatedResponse = [...response];
          updatedResponse[isRatingIndex].rating = value;
          setResponse(updatedResponse);
        })
        .catch(() => {
          Error();
        });
    });
  };

  const GetURL = (memberType, id) => {
    return `${process.env.REACT_APP_ROOT_URL}networks/type/${memberType}/id/${id}`;
  };

  const shareUrl = "https://example.com"; // Replace with your desired share URL
  const title = "Check out this link!"; // Replace with your desired share title

  const handleSendMailOpen = (data) => {
    setMailData(data?.companyEMail);
    setOpening(true);
  };

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Share with apps
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ margin: " 0px 0px 10px 0px" }}>
            Please select app to share
          </div>
          <div>
            <span>
              <WhatsappShareButton
                title={
                  FindCompanyName(CompanyObject?.response, companyId) +
                  " will like to share the Tradxlink Company Profile Page for " +
                  FindCompanyName(
                    CompanyObject?.response,
                    sharingData?.memberUID
                  )
                }
                url={
                  GetURL(sharingData?.memberType, sharingData?.memberUID) +
                  "\n" +
                  `Please click to Sign-In or Register if already not a member of Tradxlink bussiness network to view the profile. \n Thanks, \n ${FindCompanyName(
                    CompanyObject?.response,
                    companyId
                  )} `
                }>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </span>

            <span className="mx-3">
              <TwitterShareButton
                title={
                  FindCompanyName(CompanyObject?.response, companyId) +
                  " will like to share the Tradxlink Company Profile Page for " +
                  FindCompanyName(
                    CompanyObject?.response,
                    sharingData?.memberUID
                  )
                }
                url={
                  GetURL(sharingData?.memberType, sharingData?.memberUID) +
                  "\n" +
                  `Please click to Sign-In or Register if already not a member of Tradxlink bussiness network to view the profile. \n Thanks, \n ${FindCompanyName(
                    CompanyObject?.response,
                    companyId
                  )} `
                }>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
            </span>

            <span>
              <LinkedinShareButton
                title={
                  FindCompanyName(CompanyObject?.response, companyId) +
                  " will like to share the Tradxlink Company Profile Page for " +
                  FindCompanyName(
                    CompanyObject?.response,
                    sharingData?.memberUID
                  )
                }
                url={
                  GetURL(sharingData?.memberType, sharingData?.memberUID) +
                  "\n" +
                  `Please click to Sign-In or Register if already not a member of Tradxlink bussiness network to view the profile. \n Thanks, \n ${FindCompanyName(
                    CompanyObject?.response,
                    companyId
                  )} `
                }>
                <LinkedinIcon size={32} round />
              </LinkedinShareButton>
            </span>

            <span className="mx-3">
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </span>

            <span>
              <EmailShareButton url={shareUrl} subject={title}>
                <EmailIcon size={32} round />
              </EmailShareButton>
            </span>

            <span className="mx-3">
              <LineShareButton
                title={
                  FindCompanyName(CompanyObject?.response, companyId) +
                  " will like to share the Tradxlink Company Profile Page for " +
                  FindCompanyName(
                    CompanyObject?.response,
                    sharingData?.memberUID
                  )
                }
                url={
                  GetURL(sharingData?.memberType, sharingData?.memberUID) +
                  "\n" +
                  `Please click to Sign-In or Register if already not a member of Tradxlink bussiness network to view the profile. \n Thanks, \n ${FindCompanyName(
                    CompanyObject?.response,
                    companyId
                  )} `
                }>
                <LineIcon size={32} round />
              </LineShareButton>
            </span>

            <span>
              <TelegramShareButton
                title={
                  FindCompanyName(CompanyObject?.response, companyId) +
                  " will like to share the Tradxlink Company Profile Page for " +
                  FindCompanyName(
                    CompanyObject?.response,
                    sharingData?.memberUID
                  )
                }
                url={
                  GetURL(sharingData?.memberType, sharingData?.memberUID) +
                  "\n" +
                  `Please click to Sign-In or Register if already not a member of Tradxlink bussiness network to view the profile. \n Thanks, \n ${FindCompanyName(
                    CompanyObject?.response,
                    companyId
                  )} `
                }>
                <TelegramIcon size={32} round />
              </TelegramShareButton>
            </span>
          </div>
        </Modal.Body>
      </Modal>

      <MailModal show={isOpening} setShow={setOpening} response={mailData} />

      {isLoading || CompanyObject.isLoading ? (
        <span>
          Loading...
          <Loader />
        </span>
      ) : (
        <>
          {response && CompanyObject.response && (
            <>
              {response.map(
                (data, i) =>
                  data.memberType &&
                  data.memberType !== "PERSON" &&
                  data.memberUID !== companyId && (
                    <div className={styles.profileWrapper} key={i}>
                      <div className={styles.HeaderLeft}>
                        <div className={styles.profileContainer}>
                          <div className={styles.imgDiv}>
                            <img
                              style={Rectangle}
                              src={xLogo}
                              alt="Company Logo"
                            />
                          </div>

                          <div className={styles.profileDiv}>
                            <div className={styles.namingDiv}>
                              <div
                                onClick={() =>
                                  handleDetailNavigation(
                                    data.memberType,
                                    data.memberUID
                                  )
                                }
                                className={cn(
                                  styles.certficateIconDiv,
                                  "d-flex align-items-center"
                                )}>
                                {data.verified === "VERIFICATION_PASS" ? (
                                  <HiBadgeCheck
                                    style={{
                                      color: "#4CAF50",
                                    }}
                                    size={24}
                                  />
                                ) : (
                                  <MdOutlineCancel
                                    style={{
                                      color: "rgba(255, 161, 0, 1)",
                                    }}
                                    size={20}
                                  />
                                )}
                                <div> {data.companyName}</div>
                              </div>

                              <div>
                                <div
                                  className={
                                    data.memberType === "COMPANY"
                                      ? styles.companyType
                                      : styles.supplierType
                                  }>
                                  {LABEL(data.memberType)}
                                </div>
                              </div>
                            </div>

                            <div className={styles.flexStart}>
                              <div>
                                Country :
                                {data && data.companyCountry ? (
                                  <span className="mx-2">
                                    {data.companyCountry}
                                  </span>
                                ) : (
                                  <span className="mx-2">Not Provided</span>
                                )}
                              </div>
                            </div>

                            <div className={styles.flexStart}>
                              <div>
                                <a>
                                  SiteURL :
                                  {data && data.companySiteURL ? (
                                    <span className="mx-2">
                                      {data.companySiteURL}
                                    </span>
                                  ) : (
                                    <span className="mx-2">Not Provided</span>
                                  )}
                                </a>
                              </div>
                            </div>

                            <div className="d-flex justify-content-between align-item-center">
                              <div className={styles.flexStart}>
                                <div>Rating :</div>
                                <div className={cn(styles.iconStyle, "mx-2")}>
                                  <RatingDiv rating={data.rating} />
                                </div>
                              </div>
                              <div
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "underline",
                                }}
                                onClick={() => {
                                  handleRatingIndex(i);
                                }}>
                                Rate now
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}>
                              {isRatingIndex === i && (
                                <RadioGroupRating
                                  handleRating={handleRating}
                                  data={data}
                                  rating={data.rating}
                                />
                              )}
                            </div>
                          </div>
                        </div>

                        <div className={styles.NodeDiv}>
                          <div className={styles.NodeText}>
                            {data.nodeNetworkName
                              ? NodeName(data.nodeNetworkName)
                              : "NA"}
                          </div>
                          <div className={styles.iconDiv}>
                            <AiFillLinkedin
                              fill="rgba(53, 153, 238, 1)"
                              size={26}
                            />
                            <FaTwitterSquare
                              fill="rgba(53, 153, 238, 1)"
                              size={24}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.profileDiv}>
                        <div className={styles.btnDiv}>
                          <div className={styles.btnDiv}>
                            <button
                              disabled={
                                addAndRemoveRole === "DELETE" ||
                                addAndRemoveRole === "NOTAPP"
                                  ? true
                                  : false
                              }
                              onClick={
                                filterNetwork(data.memberUID)
                                  ? (e) => deleteToNetwork(e, data.memberUID)
                                  : (e) => addToNetwork(e, data.memberUID)
                              }
                              className={
                                filterNetwork(data.memberUID)
                                  ? styles.deleteBtn
                                  : styles.addBtn
                              }>
                              {filterNetwork(data.memberUID)
                                ? "Remove From Network"
                                : "Add To Network"}
                            </button>
                          </div>

                          <div className={styles.btnDiv}>
                            <button
                              disabled={
                                addAndRemoveRole === "DELETE" ||
                                addAndRemoveRole === "NOTAPP"
                                  ? true
                                  : false
                              }
                              onClick={
                                filterFavorite(data.memberUID)
                                  ? (e) => deleteToFavoite(e, data.memberUID)
                                  : (e) => addToFavoite(e, data.memberUID)
                              }
                              className={
                                filterFavorite(data.memberUID)
                                  ? styles.deleteBtn
                                  : styles.addBtn
                              }>
                              {filterFavorite(data.memberUID)
                                ? "Remove Trusted Member "
                                : "Add Trusted Member"}
                            </button>
                          </div>
                        </div>

                        <div className={styles.flexColumn}>
                          <div className={styles.detailsDiv}>
                            <div className={styles.addressDiv}>
                              <div className={styles.iconStyle}>
                                <FaBuilding
                                  size={25}
                                  fill=" rgba(33, 142, 235, 1)"
                                />
                              </div>
                              <div>
                                {data.companyAddress ? (
                                  <span className="mx-2">
                                    {data.companyAddress[0]}
                                  </span>
                                ) : (
                                  <span className="mx-2">
                                    Address : Not Provided
                                  </span>
                                )}
                              </div>
                            </div>

                            <div className={styles.phoneDiv}>
                              {data.companyPhone ? (
                                data.companyPhone.map((item, index) => (
                                  <div className="mb-2" key={index}>
                                    <span>
                                      <BsTelephone
                                        size={24}
                                        color="rgba(33, 142, 235, 1)"
                                      />
                                    </span>
                                    <span className="pl-2">{item}</span>
                                  </div>
                                ))
                              ) : (
                                <span>Phone no: Not Provided</span>
                              )}
                            </div>
                          </div>

                          <div className={styles.mapDiv}>
                            <div>
                              <div
                                className={styles.iconStyle}
                                style={{ marginRight: "4px" }}>
                                <SiGooglemaps
                                  size={24}
                                  color="rgba(33, 142, 235, 1)"
                                />
                              </div>
                              <div style={{ color: "rgba(33, 142, 235, 1)" }}>
                                <a
                                  style={{
                                    textDecoration: "none",
                                    color: "rgba(33, 142, 235, 1)",
                                  }}
                                  href="https://www.google.com/maps/search/trade+links+pvt+ltd/@28.5566977,77.2460797,11z/data=!3m1!4b1"
                                  target="_blank">
                                  View Google Map
                                </a>
                              </div>
                            </div>

                            <div className={styles.icon_box}>
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                key="top"
                                placement="top"
                                overlay={
                                  <Popover id="popover-positioned-top">
                                    <Popover.Body>
                                      <strong>Chat</strong>
                                    </Popover.Body>
                                  </Popover>
                                }>
                                <span>
                                  <RiWechatLine
                                    size={26}
                                    color="rgba(53, 153, 238, 1)"
                                    onClick={() => handleChat(data)}
                                  />
                                </span>
                              </OverlayTrigger>

                              <span onClick={() => handleSendMailOpen(data)}>
                                <MdOutlineAttachEmail
                                  size={24}
                                  color="rgba(53, 153, 238, 1)"
                                />
                              </span>

                              <span
                                onClick={() => {
                                  setSharingData(data);
                                  setModalShow(true);
                                }}>
                                <AiOutlineShareAlt
                                  color="rgba(53, 153, 238, 1)"
                                  size={26}
                                  rond
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              )}
            </>
          )}
          {response && response.length === 0 && <span>No Records Found</span>}
          {isError && <span>Something went wrong</span>}
        </>
      )}
    </>
  );
};
export default CardNetwork;

const Rectangle = {
  width: "100%",
  height: "auto",
  background: "cover",
  objectFit: "cover",
  // border: "1px solid white",
  // cursor: "pointer",
};
