import React, { useState, useCallback, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import classes from "../service.module.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Onboarding from "./Onboarding";
import Requisition from "../Requisition";
import Freight from "./Freights";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import ServicrProviders from "./servicrProviders";
import { SERVICE_LABEL } from "utils/Constants";
import Transport from "./Transport";
import Warehousing from "./Warehousing";
import Insurance from "./Insurance";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import InsuranceCredit from "./InsuranceCredit";
import Container from "./Container";
import CustomClearance from "./CustomeClearance";
import CustomsInspection from "./CustomsInspection";
import InspectionQuality from "./InspectionQuality";
import InspectionReg from "./InspectionReg";
import FrightInvoicing from "./FrightInvoicing";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import TradeCompliance from "./TradeCompliance";
import ComplianceImport from "./ComplianceImport";
import CreditAsessment from "./CreditAsessment";
import SteveDoring from "./SteveDoring";
import ComplianceExport from "./ComplianceExport";
import { AiOutlineInfoCircle } from "react-icons/ai";
import Popover from "react-bootstrap/Popover";
import TradesOthers from "./TradesOthers";
import { ToastContainer } from "react-toastify";
import { AddServiceValdations } from "utils/Validations/Services";

import { Success, Error, Alert } from "components/notifications/responseHandle";
import {
  freightinvoieCheck,
  steveDoringCheck,
  creditAssesCheck,
  tradeOthersCheck,
  complianceImportCheck,
  compliancetradeCheck,
  complianceExportCheck,
  insuranceCreditCheck,
  insuranceCheck,
  inspectionOthersCheck,
  inspectionQualityCheck,
  warehouseCheck,
  warehouseCargoCheck,
  inspectionCheck,
  clearanceCheck,
  containCheck,
  frieghtCheck,
  customCheck,
  AddServiceRequisitions,
  AddServiceRequisitionsINSURENCECredit,
} from "utils/Validations/Services";

const Index = () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const location = useLocation();
  const selectedServiceList = location.state.selectedList;
  const [toggleGeneral, setToggleGeneral] = useState(true);
  const [toggleGeneral1, setToggleGeneral1] = useState(true);
  const [isServiceRequOpen, setIsServiceReqOpen] = useState(true);
  const [isServiceRequDetailsopen, setIsServiceReqDetailsOpen] =
    useState(false);
  const [isServiceRequSummaryOpen, setIsServiceReqSummaryOpen] =
    useState(false);
  const [isNoRequisition, setIsNoRequisition] = useState(false);
  const [isNoRequisitionBack, setIsNoRequisitionBack] = useState(false);
  const [isGeneralReq, setIsGeneralReq] = useState(false);

  const [selected, setSelected] = useState(0);

  const toggle = (i) => {
    if (selected == i) {
      setSelected(null);
    } else {
      setSelected(i);
    }
  };

  const uniqueCategory = [
    ...new Set(
      selectedServiceList &&
        selectedServiceList.map((item) => item.serviceCategory[0])
    ),
  ];

  const uniqueSubCategory = [
    ...new Set(
      selectedServiceList &&
        selectedServiceList.map((item) => item.serviceSubCategory[0])
    ),
  ];

  sessionStorage.setItem(
    "selectedServiceList",
    JSON.stringify(selectedServiceList)
  );

  let memberArray = [];
  let newArr =
    selectedServiceList &&
    selectedServiceList.map((service) => {
      memberArray.push(service.memberId);
    });

  const [requisitionData, setRequisitionData] = useState({
    serviceRequisitionId: "",
    consumerMemberUID: "",
    ServiceReqDescription: "",
    pricingPreferences: "",
    referenceServiceId: selectedServiceList[0].serviceId
      ? selectedServiceList[0].serviceId
      : "",
    servicePriceLow: "",
    servicePriceHigh: "",
    servicePaymentCurrency: "",
    serviceRequisitionStartDate: "",
    serviceRequisitionEndDate: "",
    serviceRequisitionStatus: "NEW",
    preferredPaymentDate: "",
    escrowPayment: true,
    isDeleted: false,
    createdBy: "",
    createdDate: "",
    lastUpdateBy: "",
    lastUpdatedDate: "",
    serviceProvidersSelected: memberArray,
    serviceCategory: selectedServiceList[0].serviceCategory[0]
      ? [selectedServiceList[0].serviceCategory[0]]
      : [],
    serviceSubCategory: selectedServiceList[0].serviceSubCategory[0]
      ? [selectedServiceList[0].serviceSubCategory[0]]
      : [],
    industry: selectedServiceList[0].industry
      ? selectedServiceList[0].industry
      : [],
    countriesForSubscription: selectedServiceList[0].countriesOfOperations
      ? selectedServiceList[0].countriesOfOperations
      : [],
    serviceProviderPreferences: "SELECTED",
    preferredPaymentMethod: "",
    referenceData: [],
  });

  const [freightCargoDataList, setFreightCargoDataList] = useState([]);
  const [warehouseCargoDataList, setWarehouseCargoDataList] = useState([]);
  const [transportCargoDataList, setTransportCargoDataList] = useState([]);

  const [isWhAdded, setIsWhAdded] = useState(true);
  const [addWhCargo, setAddWhCargo] = useState(false);
  const [isWhHazardous, setisWhHazardous] = useState(false);

  const [warehouseData, setWarehouseData] = useState({
    freightType: "INTERNATIONAL",
    transportMode: "",
    country: "",
    city: "",
    destinatqtyUnitionType: "",
    destination: "",
    CARGO_READY: "",
    ESTIMATED_STORAGE_DATE: "",
    cargoHazardous: "",
    UN_Number: "",
    commodity: "",
    cargoDetails: [],
    cargoHazardous: "",
    UN_Number: "",
  });

  const [isAdded, setIsAdded] = useState(true);
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);

  const [formdata, setFormData] = useState({
    freightType: "INTERNATIONAL",
    countryFrom: "",
    countryTo: "",
    cityFrom: "",
    cityTo: "",
    portOrigin: "",
    destinationPort: "",
    destinationType: "",
    transportMode: "",
    destination: "",
    CARGO_READY: "",
    PREFERRED_SAILING_DATE: "",
    cargoHazardous: "",
    UN_Number: "",
    commodity: "",
    cargoDetails: [],
    cargoHazardous: "",
    UN_Number: "",
  });

  //feright-incoicing
  const [fiData, setFiData] = useState({
    RequestorName: "",
    requesterDepart: "",
    requestEmail: "",
    requestPhone: "",
    requestdate: "",
    supplierName: "",
    supplierContact: "",
    supplierPhone: "",
    supplierEmail: "",
    supplierAdd: "",
    refferenceNo: "",
    describeGoods: "",
    countryFrom: "",
    countryTo: "",
    serviceTyp: "",
    serviceLevel: "",
    billingMethod: "",
    specialinstruct: "",
    estimated: "",
    currency: "",
    paymentTerms: "",
    paymentTerm: "",
    shipmentDate: "",
    requestdate: "",
  });

  const [steveData, setSteveData] = useState({
    name: "",
    company: "",
    contactInfo: "",
    vesselName: "",
    imoNumber: "",
    port: "",
    arrival: "",
    departure: "",
    typeOfCargo: "",
    description: "",
    selectedCargo: "",
    containorType: "",
    containoirsize: "",
    Lashing: "",
    loadinginfo: "",
    locationOfCargo: "",
    requirements: "",
    duration: "",
    equipments: "",
    laborreq: "",
    environment: "",
    completion: "",
    preferred: "",
    serviceType2: "",
    serviceType: "",
    serviceType3: "",
    ishazard: "",
    cargoHazardous: "",
    specialReq: "",
    regulations: "",
    emergencyplan: "",
    unnumber: "",
  });

  const [isTpAdded, setIsTpAdded] = useState(true);
  const [addTpCargo, setAddTpCargo] = useState(false);
  const [isTpHazardous, setIsTpHazardous] = useState(false);

  const [transportData, setTransportData] = useState({
    freightType: "INTERNATIONAL",
    transportMode: uniqueSubCategory[0],
    countryFrom: "",
    countryTo: "",
    cityFrom: "",
    cityTo: "",
    portOrigin: "",
    destinationPort: "",
    originType: "",
    origin: "",
    destinationType: "",
    destination: "",
    CARGO_READY: "",
    PREFERRED_SAILING_DATE: "",
    cargoHazardous: "",
    UN_Number: "",
    commodity: "",
    cargoDetails: [],
    cargoHazardous: "",
    UN_Number: "",
  });

  const [containerData, setContainerData] = useState({
    shipmentMode: "",
    originType: "",
    countryFrom: "",
    countryTo: "",
    portOrigin: "",
    destinationPort: "",
    cityFrom: "",
    cityTo: "",
    origin: "",
    destinationType: "",
    destination: "",
    loadingDate: "",
    unloadingDate: "",
    containerWeight: "",
    containerCount: "",
    containerSize: "",
    containerType: "",
    commodity: [],
  });
  //InspectionQuality
  const [inspectionQualityData, setInspectionQualityData] = useState({
    ServiceReqDescription: "",
    inspectionService: [],
    inspectionMedium: "",
    commodity: [],
    qouteBy: "",
    reportBy: "",
    loadsType: "",
    packed: "",
    packType: "",
    approvedSample: "",
    companyLocation: "",
    companyName: "",
    contactinfo: "",
    address: "",
  });

  const [regData, setRegData] = useState({
    ServiceReqDescription: "",
    regInspection: [],
    inspectionMedium: "",
    commodity: [],
    qouteBy: "",
    reportBy: "",
    loadsType: "",
    packed: "",
    packType: "",
    approvedSample: "",
    companyLocation: "",
    companyName: "",
    contactinfo: "",
    address: "",
  });
  //credit assesment
  const [CreditData, setCreditData] = useState({
    serviceType: "",
    Factors: [],
    requirement: "",
    companyName: "",
    contactInfo: "",
    address: "",
    newCompanyName:"",
    newContactInfo:"",
    newAddress:"",
  });

  //tradecompliance
  const [complianceData, setComplianceDataa] = useState({
    serviceType: "",

    inspectiontype: "",
    ProductName: "",
    ProductDescription: "",
    ShipperName: "",
    ShipperAddress: "",
    ReceiverName: "",
    ReceiverAddress: "",
    commodity: "",
    contactInfo: "",
    hsnOfCode: "",
    incoterms: "",
    incoterm: "",
    paymentTerms: "",

    countryFrom: "",
    countryTo: "",
    contactInfoShipper: "",
    contactInfoBuyer: "",

    currencyType: "",
    currencyAmount: "",
    service: [],
    requirement: "",
    serviceRes: "",

    serviceExportReg: "",
    serviceResDetail: "",
    serviceExportRegDetail: "",
    transactionRes: "",
    transactionResDetail: "",
    licenseReq: "",
    licenseReqDetail: "",
  });
  //trades

  const [tradesData, setTradesData] = useState({
    tradeType: "",
    contratDetails: "",
    personName: "",
    contactName: "",
    emailadd: "",
    companyName: "",
    exporterName: "",
    exporterCountry: "",
    exporterCompanyName: "",
    exporterEAdd: "",
    exporterAddress: "",
    importerCompanyName: "",
    importerCountry: "",
    importerEmail: "",
    importerAddress: "",

    countryFrom: "",
    countryTo: "",
    countryj: "",
    commodity: "",

    CommodityValue: "",
    currencyAmount: "",

    currencyType: "",
    currencyAmount: "",
    incoterm: "",
    incoterms: "",
    paymentTerms: "",

    paymentTerm: "",
    startDate: "",
    enddate: "",
    origin: "",
    destination: "",
    transport: "",
    requirement: "",
  });

  //  compliance import
  const [importData, setImportData] = useState({
    serviceType: "",
    incoterms: "",
    inspectiontype: "",
    ProductName: "",
    ProductDescription: "",
    ShipperName: "",
    ShipperAddress: "",
    ReceiverName: "",
    ReceiverAddress: "",
    commodity: "",
    contactInfo: "",
    hsnOfCode: "",
    incoterm: "",
    paymentTerms: "",
    paymentTerm: "",

    countryFrom: "",
    countryTo: "",
    contactInfoShipper: "",
    contactInfoBuyer: "",

    quantity: "",
    quantityType: "",

    currencyType: "",
    currencyAmount: "",
    service: [],
    requirement: "",
    serviceRes: "",

    serviceExportReg: "",
    serviceResDetail: "",
    serviceExportRegDetail: "",
    transactionRes: "",
    transactionResDetail: "",
    licenseReq: "",
    licenseReqDetail: "",
  });

  //Export Data
  const [exportData, setExportData] = useState({
    serviceType: "",
    incoterms: "",
    inspectiontype: "",
    ProductName: "",
    ProductDescription: "",
    ShipperName: "",
    ShipperAddress: "",
    ReceiverName: "",
    ReceiverAddress: "",
    commodity: "",
    contactInfo: "",
    hsnOfCode: "",
    incoterm: "",
    paymentTerms: "",
    exportType: "",
    paymentTerm: "",

    countryFrom: "",
    countryTo: "",
    contactInfoShipper: "",
    contactInfoBuyer: "",

    quantity: "",
    quantityType: "",

    currencyType: "",
    currencyAmount: "",
    service: [],
    requirement: "",
    serviceRes: "",

    serviceExportReg: "",
    serviceResDetail: "",
    serviceExportRegDetail: "",
    transactionRes: "",
    transactionResDetail: "",
    licenseReq: "",
    licenseReqDetail: "",
  });

  const [customeClearanceData, setCustomeClearanceData] = useState({
    serviceType: "",
    portOrigin: "",
    destinationPort: "",
    inspectiontype: "",
    ProductName: "",
    ProductDescription: "",
    ShipperName: "",
    ShipperAddress: "",
    ReceiverName: "",
    ReceiverAddress: "",
    commodity: [],
    contactInfo: "",
    hsnOfCode: "",
    incoterm: "",
    incoterms: "",
    paymentTerms: "",
    paymentTerm: "",
    quantity: "",
  });

  const [customInspectionData, setCustomInspectionData] = useState({
    serviceType: "",
    portOrigin: "",
    destinationPort: "",
    inspectionType: [],
    ProductName: "",
    ProductDescription: "",
    ShipperName: "",
    ShipperAddress: "",
    ReceiverName: "",
    ReceiverAddress: "",
    commodity: [],
    contactInfo: "",
    hsnOfCode: "",
    requirement: "",

    quantity: "",
  });

  const [insuranceData, setInsuranceData] = useState({
    insuranceType: "",
    transitMode: "",
    commodity: "",
    currency: "",
    insuredAmount: "",
    pslAmount: "",
    pllAmount: "",
    temperatureControl: "",
    isOversized: "",
    fragileCargo: "",
  });

  const [insuranceCreditData, setInsuranceCreditData] = useState({
    tenure: "Tenure",
    currency: "",
    turnoverAmount: "",
    salesValue: "",
    insuranceType1: "EXPORT",
    insuranceType2: "",
    insuranceType3: "",
    credit: "",
    cash: "",
    secured: "",
    interCompany: "",
    total: "",
    countryMS: [],
    country: "",
    product: [],
  });

  const CheckCategory = (category, subCategory) => {
    if (category === "FREIGHT") {
      if (subCategory === "EXPORT") {
        return (
          <Freight
            freightCargoDataList={freightCargoDataList}
            setFreightCargoDataList={setFreightCargoDataList}
            formdata={formdata}
            setFormData={setFormData}
            isAdded ={isAdded}
            setIsAdded={setIsAdded}
            addCargo={addCargo}
            setAddCargo={setAddCargo}
            isHazardous={isHazardous}
            setIsHazardous={setIsHazardous}
          />
        );
      }
    }
    if (category === "FREIGHT") {
      if (subCategory === "IMPORT") {
        return (
          <Freight
            freightCargoDataList={freightCargoDataList}
            setFreightCargoDataList={setFreightCargoDataList}
            formdata={formdata}
            setFormData={setFormData}
            isAdded ={isAdded}
            setIsAdded={setIsAdded}
            addCargo={addCargo}
            setAddCargo={setAddCargo}
            isHazardous={isHazardous}
            setIsHazardous={setIsHazardous}
          />
        );
      }
    }

    //stevedoring
    if (category === "STEVEDORING") {
      if (subCategory === "OTHERS") {
        return (
          <SteveDoring steveData={steveData} setSteveData={setSteveData} />
        );
      }
    }

    if (category === "TRANSPORT") {
      return (
        <Transport
          transportCargoDataList={transportCargoDataList}
          setTransportCargoDataList={setTransportCargoDataList}
          transportData={transportData}
          setTransportData={setTransportData}
          uniqueSubCategory={uniqueSubCategory}
          isTpAdded={isTpAdded}
          setIsTpAdded={setIsTpAdded}
          addTpCargo={addTpCargo}
          setAddTpCargo={setAddTpCargo}
          isTpHazardous={isTpHazardous}
          setIsTpHazardous={setIsTpHazardous}
        />
      );
    }

    if (category === "WAREHOUSE") {
      return (
        <Warehousing
          warehouseData={warehouseData}
          setWarehouseData={setWarehouseData}
          // warehouseCargoData={warehouseCargoData}
          // setWarehouseCargoData={setWarehouseCargoData}
          isWhAdded={isWhAdded}
          setIsWhAdded={setIsWhAdded}
          warehouseCargoDataList={warehouseCargoDataList}
          setWarehouseCargoDataList={setWarehouseCargoDataList}
          addWhCargo={addWhCargo}
          setAddWhCargo={setAddWhCargo}
          isWhHazardous={isWhHazardous}
          setisWhHazardous={setisWhHazardous}
        />
      );
    }
    if (category === "CONTAINER") {
      return (
        <Container
          containerData={containerData}
          setContainerData={setContainerData}
        />
      );
    }
    if (category === "CUSTOMS") {
      if (subCategory === "CUSTOM_CLEARANCE") {
        return (
          <CustomClearance
            customeClearanceData={customeClearanceData}
            setCustomeClearanceData={setCustomeClearanceData}
          />
        );
      }
    }

    //Trades Others
    if (category === "TRADE_CONTRACT") {
      if (subCategory === "OTHERS") {
        return (
          <TradesOthers tradesData={tradesData} setTradesData={setTradesData} />
        );
      }
    }

    //credit assesment
    if (category === "FINANCING") {
      if (subCategory === "CREDIT_ASSESSMENT") {
        return (
          <CreditAsessment
            CreditData={CreditData}
            setCreditData={setCreditData}
          />
        );
      }
    }

    //fright-invoicing
    if (category === "FINANCING") {
      if (subCategory === "FREIGHT_FINANCING") {
        return <FrightInvoicing fiData={fiData} setFiData={setFiData} />;
      }
    }
    //COMPLIANCE
    if (category === "COMPLIANCE") {
      if (subCategory === "TRADE") {
        return (
          <TradeCompliance
            complianceData={complianceData}
            setComplianceDataa={setComplianceDataa}
          />
        );
      }
      if (subCategory === "IMPORT_DECLARATIONS") {
        return (
          <ComplianceImport
            importData={importData}
            setImportData={setImportData}
          />
        );
      }
      if (subCategory === "EXPORT_DECLARATIONS") {
        return (
          <ComplianceExport
            exportData={exportData}
            setExportData={setExportData}
          />
        );
      }
    }
    //INSPECTION
    if (category === "INSPECTION") {
      if (subCategory === "QUALITY") {
        return (
          <InspectionQuality
            inspectionQualityData={inspectionQualityData}
            setInspectionQualityData={setInspectionQualityData}
          />
        );
      }
      if (subCategory === "OTHERS") {
        return <InspectionReg regData={regData} setRegData={setRegData} />;
      }
    }
    if (category === "CUSTOMS") {
      if (subCategory === "CUSTOMS_INSPECTION") {
        return (
          <CustomsInspection
            customInspectionData={customInspectionData}
            setCustomInspectionData={setCustomInspectionData}
          />
        );
      }
    }
    if (category === "INSURANCE") {
      if (subCategory === "CARGO") {
        return (
          <Insurance
            insuranceData={insuranceData}
            setInsuranceData={setInsuranceData}
          />
        );
      }
      if (subCategory === "CREDIT") {
        return (
          <InsuranceCredit
            insuranceCreditData={insuranceCreditData}
            setInsuranceCreditData={setInsuranceCreditData}
          />
        );
      }
      if (subCategory === "INLAND") {
        return (
          <Insurance
            insuranceData={insuranceData}
            setInsuranceData={setInsuranceData}
          />
        );
      }
    }
  };

  const handleNext = () => {
    let Check = AddServiceRequisitions(requisitionData);
    console.log("CheckCheck", Check);
    if (Check) {
      Error(Check);
      return;
    }

    window.scrollTo(0, 0);
    setIsServiceReqOpen(false);
    setIsServiceReqDetailsOpen(true);
    setIsGeneralReq(false);
    setIsNoRequisitionBack(false);
    if (
      uniqueCategory[0] === "MEMBER_ONBOARDING" ||
      uniqueCategory[0] === "KYB" ||
      // uniqueCategory[0] === "FINANCING" ||
      uniqueCategory[0] === "ACCOUNTS_TAXATION" ||
      uniqueCategory[0] === "PAYMENTS" ||
      uniqueCategory[0] === "TERMINAL" ||
      uniqueCategory[0] === "SHIPPING" ||
      uniqueCategory[0] === "INVOICING"
    ) {
      setIsNoRequisition(true);
    }
  };

  const moveNext = () => {
    // let check = customCheck(transportData);
    // console.log("check", check);
    if (selectedServiceList[0].serviceCategory[0] === "TRANSPORT") {
      let check = customCheck(transportData);
      if (check) {
        Error(check);
        return;
      } if(transportData.commodity !== "" && isTpAdded ){
        Error("Please fill all the cargo details after clicking on '+Add Loads'");
        return;
      }if( addTpCargo){
        Error("Please fill all the cargo details and Add");
        return;
      } if(isTpHazardous){
        if(transportData.cargoHazardous===""){
          Error("Please enter Cargo Hazardous");
          return;
        } if(transportData.UN_Number ===""){
          Error("Please enter UN#");
          return;
        }
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    if (selectedServiceList[0].serviceCategory[0] === "FREIGHT") {
      let check = frieghtCheck(formdata);
      if (check) {
        Error(check);
        return;
      }if(formdata.commodity !== "" && isAdded ){
        Error("Please fill all the cargo details after clicking on '+Add Loads'");
        return;
      } if( addCargo){
        Error("Please fill all the cargo details and Add");
        return;
      } if(isHazardous){
        if(formdata.cargoHazardous===""){
          Error("Please enter Cargo Hazardous");
          return;
        } if(formdata.UN_Number ===""){
          Error("Please enter UN#");
          return;
        }
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }
    //containor
    if (selectedServiceList[0].serviceCategory[0] === "CONTAINER") {
      let check = containCheck(containerData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //wherhouseCheck
    if (selectedServiceList[0].serviceCategory[0] === "WAREHOUSE") {
      let check = warehouseCheck(warehouseData);
      // let checked = warehouseCargoCheck(warehouseCargoDataList);

      if (check) {
        Error(check);
        return;
      } if(warehouseData.ESTIMATED_STORAGE_DATE !== "" && isWhAdded ){
        Error("Please fill all the cargo details after clicking on '+Add Loads'");
        return;
      } if( addWhCargo){
        Error("Please fill all the cargo details and Add");
        return;
      } if(isWhHazardous){
        if(warehouseData.cargoHazardous===""){
          Error("Please enter Cargo Hazardous");
          return;
        } if(warehouseData.UN_Number ===""){
          Error("Please enter UN#");
          return;
        }
      }

      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //steveDoring
    if (selectedServiceList[0].serviceCategory[0] === "STEVEDORING") {
      let check = steveDoringCheck(steveData);
      // let checked = warehouseCargoCheck(warehouseCargoDataList);

      if (check) {
        Error(check);
        return;
      }

      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }
    //contract trade
    if (selectedServiceList[0].serviceCategory[0] === "TRADE_CONTRACT") {
      let check = tradeOthersCheck(tradesData);
      // let checked = warehouseCargoCheck(warehouseCargoDataList);

      if (check) {
        Error(check);
        return;
      }

      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //compliance trade
    if (
      selectedServiceList[0].serviceCategory[0] === "COMPLIANCE" &&
      selectedServiceList[0].serviceSubCategory[0] === "TRADE"
    ) {
      let check = compliancetradeCheck(complianceData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //FINANCING
    if (
      selectedServiceList[0].serviceCategory[0] === "FINANCING" &&
      selectedServiceList[0].serviceSubCategory[0] === "CREDIT_ASSESSMENT"
    ) {
      let check = creditAssesCheck(CreditData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    if (
      selectedServiceList[0].serviceCategory[0] === "FINANCING" &&
      selectedServiceList[0].serviceSubCategory[0] === "FREIGHT_FINANCING"
    ) {
      let check = freightinvoieCheck(fiData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //compliance import
    if (
      selectedServiceList[0].serviceCategory[0] === "COMPLIANCE" &&
      selectedServiceList[0].serviceSubCategory[0] === "IMPORT_DECLARATIONS"
    ) {
      let check = complianceImportCheck(importData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //compliance export
    if (
      selectedServiceList[0].serviceCategory[0] === "COMPLIANCE" &&
      selectedServiceList[0].serviceSubCategory[0] === "EXPORT_DECLARATIONS"
    ) {
      let check = complianceExportCheck(exportData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    // if (selectedServiceList[0].serviceCategory[0] === "WAREHOUSE") {
    //   let check = warehouseCargoCheck(warehouseCargoDataList);
    //   if (check) {
    //     Error(check);
    //     return;
    //   }
    //   setIsServiceReqDetailsOpen(false);
    //   setIsServiceReqSummaryOpen(true);
    // }
    // //custom inspection
    //inspection quality
    if (
      selectedServiceList[0].serviceCategory[0] === "INSPECTION" &&
      selectedServiceList[0].serviceSubCategory[0] === "QUALITY"
    ) {
      let check = inspectionQualityCheck(inspectionQualityData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //inspection others
    if (
      selectedServiceList[0].serviceCategory[0] === "INSPECTION" &&
      selectedServiceList[0].serviceSubCategory[0] === "OTHERS"
    ) {
      let check = inspectionOthersCheck(regData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }
    //insurance
    if (
      selectedServiceList[0].serviceCategory[0] === "INSURANCE" &&
      selectedServiceList[0].serviceSubCategory[0] === "INLAND"
    ) {
      let check = insuranceCheck(insuranceData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    if (
      selectedServiceList[0].serviceCategory[0] === "INSURANCE" &&
      selectedServiceList[0].serviceSubCategory[0] === "CARGO"
    ) {
      let check = insuranceCheck(insuranceData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //insurancce credit
    if (
      selectedServiceList[0].serviceCategory[0] === "INSURANCE" &&
      selectedServiceList[0].serviceSubCategory[0] === "CREDIT"
    ) {
      let check = insuranceCreditCheck(insuranceCreditData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }
    if (
      selectedServiceList[0].serviceCategory[0] === "CUSTOMS" &&
      selectedServiceList[0].serviceSubCategory[0] === "CUSTOMS_INSPECTION"
    ) {
      let check = inspectionCheck(customInspectionData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    //custom clearance
    if (
      selectedServiceList[0].serviceCategory[0] === "CUSTOMS" &&
      selectedServiceList[0].serviceSubCategory[0] === "CUSTOM_CLEARANCE"
    ) {
      let check = clearanceCheck(customeClearanceData);
      if (check) {
        Error(check);
        return;
      }
      setIsServiceReqDetailsOpen(false);
      setIsServiceReqSummaryOpen(true);
    }

    setIsServiceReqDetailsOpen(false);
    setIsServiceReqSummaryOpen(true);
  };

  const handleCancel = () =>{
    console.log("AkashTesting212", location.state?.tradeArray)
    if(location.state?.tradeArray === undefined){
      navigate(-1);
    }
    else if (location.state?.tradeArray !== "") {
      navigate(`../../tradeover/tradeflow/${location.state?.tradeArray[0].value}`);
    }
  }
  return (
    <>
      {(isServiceRequOpen ||
        isGeneralReq ||
        (isNoRequisitionBack && isNoRequisition)) && (
        <>
          <div onClick={() => handleNavigate("../service")}>
            <div
              className="d-flex"
              style={{ cursor: "pointer", color: "rgb(66, 165, 245)" }}
            >
              <BsArrowLeftShort size={22} />{" "}
              <div>Back to Services Search Results</div>
            </div>
          </div>
          <div className={classes.toogleTab}>
            {/* <div className={toggleGeneral ? "show" : "description"}> */}
            <div style={{ marginLeft: "10px" }}>
              <h5>General</h5>
            </div>
            <div>
              <Requisition
                requisitionData={requisitionData}
                setRequisitionData={setRequisitionData}
              />
            </div>
          </div>
          <ButtonDiv>
            <Button
              onClick={() => {
                handleNext();
                // window.scrollTo(0, 0);
                // setIsServiceReqOpen(false);
                // setIsServiceReqDetailsOpen(true);
                // setIsGeneralReq(false);
                // setIsNoRequisitionBack(false);
                // if (
                //   uniqueCategory[0] === "MEMBER_ONBOARDING" ||
                //   uniqueCategory[0] === "KYB" ||
                //   uniqueCategory[0] === "FINANCING" ||
                //   uniqueCategory[0] === "ACCOUNTS_TAXATION" ||
                //   uniqueCategory[0] === "PAYMENTS" ||
                //   uniqueCategory[0] === "TERMINAL" ||
                //   uniqueCategory[0] === "SHIPPING" ||
                //   uniqueCategory[0] === "INVOICING"
                // ) {
                //   setIsNoRequisition(true);
                // }
              }}
            >
              Next
            </Button>
            <Button
              onClick={() => {
                handleCancel();
              }}
            >
              Cancel
            </Button>
          </ButtonDiv>
        </>
      )}

      {isServiceRequDetailsopen && isNoRequisition === false && (
        <>
          <div>
            {selectedServiceList.map((item, i) => (
              // <div className={classes.toogleTab} key={i}>
              //   <div className={classes.toogleTitle} onClick={() => toggle(i)}>
              <div>
                <div style={{ marginLeft: "2%", marginBottom: "2%" }}>
                  <span>
                    <h5>
                      {/* {SERVICE_LABEL(item.serviceCategory && item.serviceCategory[0])} */}

                      {/* {<OverlayTrigger
                trigger={["hover", "focus"]}
                key="top"
                placement="top"
                overlay={
                  <Popover id="popover-positioned-top">
                    <Popover.Body>
                    <strong>Create your {SERVICE_LABEL(item.serviceSubCategory && item.serviceSubCategory[0])} Form</strong>
                    </Popover.Body>
                  </Popover>
                 
                }> 
                <span >                  <AiOutlineInfoCircle size={26} />
                </span>
              </OverlayTrigger>} */}
                      {/* {SERVICE_LABEL(item.serviceSubCategory && item.serviceSubCategory[0])} */}
                    </h5>
                  </span>
                  {/* <span>
                  {selected === i ? (
                    <CgChevronUp size={30} />
                  ) : (
                    <CgChevronDown size={30} />
                  )}
                </span> */}
                </div>

                <div className={selected === i ? "show" : "description"}>
                  {CheckCategory(
                    item.serviceCategory && item.serviceCategory[0],
                    item.serviceSubCategory && item.serviceSubCategory[0]
                  )}
                </div>
              </div>
            ))}
          </div>
          <ButtonDiv>
            <Button
              onClick={() => {
                setIsServiceReqOpen(true);
                setIsServiceReqDetailsOpen(false);
              }}
            >
              Back
            </Button>
            <Button
              onClick={() => {
                // if (selectedServiceList[0].serviceCategory[0] === "TRANSPORT") {
                moveNext();

                // setIsServiceReqDetailsOpen(false);
                // setIsServiceReqSummaryOpen(true);
              }}
            >
              Next
            </Button>
          </ButtonDiv>
        </>
      )}

      {(isServiceRequSummaryOpen || isNoRequisition) && (
        <>
          <div className={classes.toogleTab}>
            <div
              className={classes.toogleTitle}
              onClick={() => setToggleGeneral1(!toggleGeneral1)}
            >
              <h5>Publish to Service Providers</h5>
              <span>
                {toggleGeneral1 ? (
                  <CgChevronUp size={30} />
                ) : (
                  <CgChevronDown size={30} />
                )}
              </span>
            </div>

            <div className={toggleGeneral1 ? "show" : "description"}>
              <ServicrProviders
                requisitionData={requisitionData}
                setRequisitionData={setRequisitionData}
                freightData={formdata}
                freightCargoDataList={freightCargoDataList}
                transportData={transportData}
                transportCargoDataList={transportCargoDataList}
                warehouseData={warehouseData}
                warehouseCargoDataList={warehouseCargoDataList}
                insuranceData={insuranceData}
                insuranceCreditData={insuranceCreditData}
                containerData={containerData}
                customeClearanceData={customeClearanceData}
                customInspectionData={customInspectionData}
                inspectionQualityData={inspectionQualityData}
                regData={regData}
                complianceData={complianceData}
                tradesData={tradesData}
                importData={importData}
                exportData={exportData}
                CreditData={CreditData}
                steveData={steveData}
                fiData={fiData}
                tradeData={
                  location.state?.tradeArray ? location.state?.tradeArray : ""
                }
                // tableCargoData={tableCargoData}
              />
            </div>
          </div>
          <ButtonDiv>
            <Button
              onClick={() => {
                setIsServiceReqSummaryOpen(false);
                setIsNoRequisitionBack(true);
                setIsNoRequisition(false);
                if (isNoRequisition) {
                  setIsGeneralReq(true);
                  setIsServiceReqDetailsOpen(false);
                } else {
                  setIsServiceReqDetailsOpen(true);
                }
              }}
            >
              Back
            </Button>
          </ButtonDiv>
        </>
      )}
    </>
  );
};

export default Index;
