export default (title) => {
  let MemberType = {
    COMPANY: "Company",
    SERVICE_PROVIDER: "Service Provider",
    FINANCER: "Financer",
    CHANNEL_PARTNER: "Channel Partner",

    TRADE_APPROVED: "Trade Approved",
    APPROVED_BUYER_CONSENT: "Approved Buyer Consent",

    SYNDICATE_FINANCE: "SYNDICATE FINANCE", // financeProductCategory
    GENERAL_FINANCE: "GENERAL FINANCE",
    TERM_FINANCE: "TERM FINANCE",
  };

  if (title) {
    return MemberType[`${title}`];
  } else {
    return "UNDEFINED";
  }
};
