import _ from "lodash";

export const AddUserValdations = (payload) => {
  if (_.isEmpty(payload.personName)) {
    return "Please Enter Person Name";
  }

  if (_.isEmpty(payload.country)) {
    return "Please Select Country";
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  if (_.isEmpty(payload.emailID)) {
    return "Please Enter Email";
  } else {
    let isValid = validateEmail(payload.emailID);
    if (!isValid) {
      return "Please Enter Valid Email";
    }
  }

  if (_.isEmpty(payload.address)) {
    return "Please Enter Address";
  }

  const isNumber = (str) => {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  };

  if (_.isEmpty(payload.phone)) {
    return "Please Enter Phone Number";
  } else {
    if (!isNumber(payload.phone[0])) {
      return "Please Enter Valid Phone Number";
    }

    if (payload.phone[0].length !== 10) {
      return "Please Enter 10 Digit Phone";
    }
  }

  if (_.isEmpty(payload.role)) {
    return "Please Select Role";
  }

  if (_.isEmpty(payload.verified)) {
    return "Please Select Onboarding Status";
  }
};

export const EditProfileValdations = (payload) => {
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  if (memberType === "FINANCER") {
    if (_.isEmpty(payload.financerName)) {
      return "Please Enter Company Name";
    }
  } else {
    if (_.isEmpty(payload.companyName)) {
      return "Please Enter Company Name";
    }
  }

  if (memberType === "FINANCER") {
    if (_.isEmpty(payload.countryCode)) {
      return "Please Select Country ?";
    }
  } else {
    if (_.isEmpty(payload.companyCountry)) {
      return "Please Select Country ?";
    }
  }

  // if (_.isEmpty(payload.companySiteURL)) {
  //   return "Please Enter Company Website URL";
  // }

  if (_.isEmpty(payload.companyType)) {
    return "Please Select Company Type";
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  if (memberType === "FINANCER") {
    if (_.isEmpty(payload.financerEMail)) {
      return "Please Enter Email";
    } else {
      let isValid = validateEmail(payload.financerEMail);
      if (!isValid) {
        return "Please Enter Valid Email";
      }
    }
  } else {
    if (_.isEmpty(payload.companyEMail)) {
      return "Please Enter Email";
    } else {
      let isValid = validateEmail(payload.companyEMail);
      if (!isValid) {
        return "Please Enter Valid Email";
      }
    }
  }

  if (memberType === "FINANCER") {
    if (_.isEmpty(payload.financerAddress && payload.financerAddress[0])) {
      return "Please Enter Address";
    }
  } else {
    if (_.isEmpty(payload.companyAddress && payload.companyAddress[0])) {
      return "Please Enter Address";
    }
  }

  const isNumber = (str) => {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  };

  if (memberType === "FINANCER") {
    if (_.isEmpty(payload.financerPhone)) {
      return "Please Enter Primary Phone Number";
    } else {
      if (!isNumber(payload.financerPhone[0])) {
        return "Please Enter Valid Primary Phone Number";
      }
      if (payload.financerPhone[0].length !== 10) {
        return "Please Enter 10 Digit Phone";
      }
      // if (_.isEmpty(payload.financerPhone[1])) {
      //   return "Please Enter Secondary Phone Number";
      // }

      // if (!isNumber(payload.financerPhone[1])) {
      //   return "Please Enter Valid Primary Phone Number";
      // }
      // if (payload.financerPhone[1].length !== 10) {
      //   return "Please Enter 10 Digit Phone";
      // }
    }
  } else {
    if (_.isEmpty(payload.companyPhone)) {
      return "Please Enter Primary Phone Number";
    } else {
      if (!isNumber(payload.companyPhone[0])) {
        return "Please Enter Valid Primary Phone Number";
      }
      if (payload.companyPhone[0].length !== 10) {
        return "Please Enter 10 Digit Phone";
      }
    }
  }

  // if (_.isEmpty(payload.companyPhone)) {
  //   return "Please Enter Primary Phone Number";
  // } else {
  //   if (!isNumber(payload.companyPhone[0])) {
  //     return "Please Enter Valid Primary Phone Number";
  //   }
  //   if (payload.companyPhone[0].length !== 10) {
  //     return "Please Enter 10 Digit Phone";
  //   }
  //   if (_.isEmpty(payload.companyPhone[1])) {
  //     return "Please Enter Secondary Phone Number";
  //   }

  //   if (!isNumber(payload.companyPhone[1])) {
  //     return "Please Enter Valid Primary Phone Number";
  //   }
  //   if (payload.companyPhone[1].length !== 10) {
  //     return "Please Enter 10 Digit Phone";
  //   }
  // }

  // if (_.isEmpty(payload.twitterLink)) {
  //   return "Please Enter Twitter Link";
  // }

  // if (_.isEmpty(payload.linkedInLink)) {
  //   return "Please Enter LinkedIn Link";
  // }

  if (memberType === "FINANCER") {
    if (_.isEmpty(payload.financerDescription)) {
      return "Please Enter Company Description";
    }
  } else {
    if (_.isEmpty(payload.companyDescription)) {
      return "Please Enter Company Description";
    }
  }

  if (memberType === "FINANCER") {
    if (
      _.isEmpty(
        payload.financerProductsServices &&
          payload.financerProductsServices[0] &&
          payload.financerProductsServices[0]
      )
    ) {
      return "Please Enter Company Products & Services";
    }
  } else {
    if (
      _.isEmpty(
        payload.companyProductsServices &&
          payload.companyProductsServices[0] &&
          payload.companyProductsServices[0]
      )
    ) {
      return "Please Enter Company Products & Services";
    }
  }

  if (_.isEmpty(payload.registrationNumber)) {
    return "Please Enter Registration Number";
  }

  // console.log("payload", payload);
  // if (_.isEmpty(payload.GSTIN)) {
  //   return "Please Enter GSTIN";
  // }

  // if (_.isEmpty(payload.pan)) {
  //   return "Please Enter PAN"; // Enter PAN
  // }

  // if (_.isEmpty(payload.tan)) {
  //   return "Please Enter TAN"; // Enter TAN
  // }

  // if (_.isEmpty(payload.lei)) {
  //   return "Please Enter LEI";
  // }

  // if (_.isEmpty(payload.dunsNumber)) {
  //   return "Please Enter Duns Number";
  // }

  // if (_.isEmpty(payload.sicCode)) {
  //   return "Please Enter Sic Code";
  // }

  if (_.isEmpty(payload.dateOfIncorporation)) {
    return "Please Enter Date Of Incorporation";
  }
};

export const EditProfileOnboardingValdations = (payload, stepperStatus) => {
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  if (stepperStatus && stepperStatus.index === 0) {
    if (memberType === "FINANCER") {
      if (_.isEmpty(payload.financerName)) {
        return "Please Enter Company Name";
      }
    } else {
      if (_.isEmpty(payload.companyName)) {
        return "Please Enter Company Name";
      }
    }

    if (memberType === "FINANCER") {
      if (_.isEmpty(payload.countryCode)) {
        return "Please Select Country ?";
      }
    } else {
      if (_.isEmpty(payload.companyCountry)) {
        return "Please Select Country ?";
      }
    }

    if (_.isEmpty(payload.companyType)) {
      return "Please Select Company Type";
    }

    if (memberType === "FINANCER") {
      if (_.isEmpty(payload.financerDescription)) {
        return "Please Enter Company Description";
      }
    } else {
      if (_.isEmpty(payload.companyDescription)) {
        return "Please Enter Company Description";
      }
    }

    if (memberType === "FINANCER") {
      if (
        _.isEmpty(
          payload.financerProductsServices &&
            payload.financerProductsServices[0] &&
            payload.financerProductsServices[0]
        )
      ) {
        return "Please Enter Company Products & Services";
      }
    } else {
      if (
        _.isEmpty(
          payload.companyProductsServices &&
            payload.companyProductsServices[0] &&
            payload.companyProductsServices[0]
        )
      ) {
        return "Please Enter Company Products & Services";
      }
    }
  }

  if (stepperStatus && stepperStatus.index && stepperStatus.index === 1) {
    if (memberType === "FINANCER") {
      if (_.isEmpty(payload.financerAddress && payload.financerAddress[0])) {
        return "Please Enter Address";
      }
    } else {
      if (_.isEmpty(payload.companyAddress && payload.companyAddress[0])) {
        return "Please Enter Address";
      }
    }

    const isNumber = (str) => {
      if (str.trim() === "") {
        return false;
      }
      return !isNaN(str);
    };

    if (memberType === "FINANCER") {
      if (_.isEmpty(payload.financerPhone)) {
        return "Please Enter Primary Phone Number";
      } else {
        if (!isNumber(payload.financerPhone[0])) {
          return "Please Enter Valid Primary Phone Number";
        }
        if (payload.financerPhone[0].length !== 10) {
          return "Please Enter 10 Digit Phone";
        }
      }
    } else {
      if (_.isEmpty(payload.companyPhone)) {
        return "Please Enter Primary Phone Number";
      } else {
        if (!isNumber(payload.companyPhone[0])) {
          return "Please Enter Valid Primary Phone Number";
        }
        if (payload.companyPhone[0].length !== 10) {
          return "Please Enter 10 Digit Phone";
        }
      }
    }

    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    if (memberType === "FINANCER") {
      if (_.isEmpty(payload.financerEMail)) {
        return "Please Enter Email";
      } else {
        let isValid = validateEmail(payload.financerEMail);
        if (!isValid) {
          return "Please Enter Valid Email";
        }
      }
    } else {
      if (_.isEmpty(payload.companyEMail)) {
        return "Please Enter Email";
      } else {
        let isValid = validateEmail(payload.companyEMail);
        if (!isValid) {
          return "Please Enter Valid Email";
        }
      }
    }
  }

  if (stepperStatus && stepperStatus.index && stepperStatus.index === 2) {
    if (_.isEmpty(payload.registrationNumber)) {
      return "Please Enter Registration Number";
    }

    if (_.isEmpty(payload.pan)) {
      return "Please Enter PAN"; // Enter PAN
    }
  }
};

export const InviteMemberValdations = (payload) => {
  if (_.isEmpty(payload.referredOrganizationName)) {
    return "Please Enter Company Name";
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  if (_.isEmpty(payload.email)) {
    return "Please Enter Email";
  } else {
    let isValid = validateEmail(payload.email);
    if (!isValid) {
      return "Please Enter Valid Email";
    }
  }

  const isNumber = (str) => {
    if (str.trim() === "") {
      return false;
    }
    return !isNaN(str);
  };

  if (_.isEmpty(payload.phone)) {
    return "Please Enter Phone Number";
  } else {
    if (!isNumber(payload.phone)) {
      return "Please Enter Valid Phone Number";
    }
    if (payload.phone.length !== 10) {
      return "Please Enter 10 Digit Phone";
    }
  }
};
