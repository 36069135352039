import React from 'react'
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import Table from './index'
export default function SechedulePayment( {hanndleCraateGroup}) {

    const { instance, accounts } = useMsal();
    const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
    const memberId = getClaim(accounts, "member_id");
    const chatToken = getClaim(accounts, "chat_token");
  return (
    <Table  hanndleCraateGroup={hanndleCraateGroup}/>
   
  )
}
