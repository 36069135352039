import React, { useEffect, useState } from "react";
import Loader from "components/Loader/Loader";

import { docGroupURL } from "services/BaseURLInstance";
import ColumnList from "components/UI/ColumnList/ColumnList";
import Content from "components/UI/Layout/Content";
import { InvoiceOverview } from "utils/Section/InvoiceSection";
import { UseRequest } from "utils/API/Request.js";
import { getIdTokenAsync } from "utils/authUtil";

import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { CheckPermission } from "utils/Constants/RBAC";

export default () => {
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${docGroupURL}bff/supplier/dashboard/invoices?financerMemberUID=${
            memberType !== "TRADER" ? companyId : ""
          }&sellerMemberUID=${memberType === "TRADER" ? companyId : ""}`,
          null,
          response
        )
          .then((data) => {
            setIsLoading(false);
            let { message, status } = data;
            if (message || status) {
              Error(message || "Something Went Wrong");
              setIsError(message);
            } else {
              data.totalFinanceApprovedAmount = `USD ${data.totalFinanceApprovedAmount}`
              data.openFinanceDealsAmount = `USD ${data.openFinanceDealsAmount}`
              data.totalInvoiceAmount = `USD ${parseFloat(data.totalInvoiceAmount).toFixed()}`
              setResponse(data);
            }
          })
          .catch(() => {
            Error("Something Went Wrong");
          })
          .finally(() => {});
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <Content marginBottom="0%">
          <span>Loading....</span>
          <Loader />
        </Content>
      ) : (
        <>
          {response && (
            <Content marginBottom="0%">
              <ColumnList list={InvoiceOverview} response={response} />
            </Content>
          )}
          {isError && (
            <Content marginBottom="0%">
              <span>{isError}</span>
            </Content>
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
