import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Input from "components/fields/Input";
import { ButtonStyled, CardNetworkWapper } from "components/Container.styled";
const PaymentPage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  console.log(sessionStorage);
  const handlePayment = async () => {
    try {
      const response = {
        ok: true,
        json: () =>
          Promise.resolve({
            url: "",
            payload: {
              email,
              name,
              amount,
            },
          }),
      };

      if (!response.ok) {
        throw new Error("Failed to fetch payment details");
      }

      const { url, payload } = await response.json();

      const callbackUrl = "";

      const queryString = new URLSearchParams({
        callbackUrl,
        payload: JSON.stringify(payload),
      }).toString();
      window.location.href = `${url}?${queryString}`;
    } catch (error) {
      setError(error.message);
    }
  };

  // Step 4: Handle redirect after payment gateway
  useEffect(() => {
    const handleRedirect = async () => {
      try {
        const paymentStatus = "success";
        setPaymentStatus(paymentStatus);
      } catch (error) {
        setError(error.message);
      }
    };

    handleRedirect();
  }, []);
  const handleChange = (e, date) => {};
  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <CardNetworkWapper>
      <div>
        {/* {error && <p>Error: {error}</p>}
      {paymentStatus ? ( */}
        <div style={{ display: "flex", marginTop: "2%", marginBottom: "2%" }}>
          {/* <p>Payment Status: {paymentStatus}</p> */}
          <ButtonStyled onClick={handleGoBack}>Go Back</ButtonStyled>
        </div>

        <div style={{ display: "flex", marginTop: "2%", marginBottom: "2%" }}>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          {/* <label htmlFor="name">Name:</label> */}
          <Input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div style={{ display: "flex", marginTop: "2%", marginBottom: "2%" }}>
          {/* <label htmlFor="amount">Amount:</label> */}
          <Input
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
          <Input
            type="number"
            placeholder="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </div>
        <ButtonStyled
          style={{ marginLeft: "80%", marginBottom: "2%" }}
          onClick={handlePayment}
        >
          Proceed to Payment
        </ButtonStyled>
      </div>
    </CardNetworkWapper>
  );
};

export default PaymentPage;
