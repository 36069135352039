import React, { useCallback } from "react";
import useAxios from "../../../../services/useAxios";
import {financeApi } from "../../../../services/BaseURLInstance";

import { MdCircleNotifications} from "react-icons/md";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

// Custom component to render Genres 
// const Genres = ({ values }) => {
//   // Loop through the array and create a badge-like component instead of a comma-separated string
//   return (
//     <>
//       {values.map((genre, idx) => {
//         return (
//           <span key={idx} className="badge">
//             {genre}
//           </span>
//         );
//       })}
//     </>
//   );
// };

const Alert = ()=> {

    const navigate = useNavigate();

    const { response, isLoading, error } = useAxios({
    api: financeApi,
    method: "get",
    url: "finance/deals?traderMemberUID=dc428603-4f18-b99b-b712-6fcb7fde510e&financerMemberUID=dc428603-4f18-b99b-b712-6fcb7fde510e&invoiceId=dc428603-4f18-b99b-b712-6fcb7fde510e&status=elit ut",
    config: JSON.stringify({
      requireAuthentication: true,
      headers: {
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    }),
    });
    
    const handleNavigate = useCallback((url) => navigate(url), []);

    const handleClickAlert = () => {};
    const handlePromise = () => {};

    const headerFieldsList = [
        {
          Header: "Alert",
          accessor: "financeProductType",
          Cell: (cell) => (
            
            <button
              className="buttonIcon"
              style={{
                backgroundColor: "transparent",
                border: "none",
                color: "white",
              }}
              value={cell.accessor}
              onClick={() => handleClickAlert(cell.cell.row.values)}
            >
              <MdCircleNotifications size={26} />
            </button>
          ),
        },
        {
          Header: "Origin",
          accessor: "origin",
        },
        {
          Header: "Type",
          accessor: "type",
        },

        {
          Header: "Reference",
          accessor: "reference",
        },
        {
          Header: "Date",
          accessor: "date",
        },

        {
          Header: "Description",
          accessor: "description",
        },     
    ];
    
    // const columns = React.useMemo(() => headerFieldsList);
      
  return (
      <>
       <AlertStyled>

          <AlertStyledHeader style = { {borderRadius: '15px 15px 0px 0px' , background: 'rgb(128, 128, 128)', }}>
             <div style = {{ flex: 1 }}>Alert</div>
             <div style = {{ flex: 1 }} >Origin</div>
             <div style = {{ flex: 1 }}>Type</div>
             <div style = {{ flex: 1 }}>Response</div>
             <div style = {{ flex: 1 }}>Date</div>
          </AlertStyledHeader>

          <div>
            
          <AlertStyledHeader>
             <div style = {{ flex: 1 }}>Alert</div>
             <div style = {{ flex: 1 }} >Origin</div>
             <div style = {{ flex: 1 }}>Type</div>
             <div style = {{ flex: 1 }}>Response</div>
             <div style = {{ flex: 1 }}>Date</div>
          </AlertStyledHeader>

          <AlertDis>
            <div> Discription:</div>
            <div>Hi. This isn’t the first time we’ve interrupted your reading recently, but 98% of our readers don't donate. Many think they’ll give later, but then forget. This Sunday we request you to protect Wikipedia. All we ask is ₹ 25, or whatever seems right to you, to sustain our future. We request you: Please don't scroll away. If you are one of our rare donors, we warmly thank you</div>
          </AlertDis>

          <AlertStyledHeader>
             <div style = {{ flex: 1 }}>Alert</div>
             <div style = {{ flex: 1 }} >Origin</div>
             <div style = {{ flex: 1 }}>Type</div>
             <div style = {{ flex: 1 }}>Response</div>
             <div style = {{ flex: 1 }}>Date</div>
          </AlertStyledHeader>

          <AlertDis>
            <div> Discription:</div>
            <div>Hi. This isn’t the first time we’ve interrupted your reading recently, but 98% of our readers don't donate. Many think they’ll give later, but then forget. This Sunday we request you to protect Wikipedia. All we ask is ₹ 25, or whatever seems right to you, to sustain our future. We request you: Please don't scroll away. If you are one of our rare donors, we warmly thank you</div>
          </AlertDis>

          </div>

       </AlertStyled>
      </>
  )
}

export default Alert;


const AlertStyled = styled.div`

`;

const AlertStyledHeader = styled.div`
// border-radius: 15px 15px 0px 0px;
    display: flex;
    justify-content: space-between;
    // background: rgb(128, 128, 128);
    padding: 15px 0 15px 40px;
    color: white;
    margin-bottom: 5px;
    align-items: center;
    &div{
      border: 1px solid red;
    }
`;

const  AlertDis = styled.div`
   display:flex;
   letter-spacing: 2px;
   word-spacing: 1px;
   margin: 10px 0px;
   border-bottom : 1px solid #ccc;
   div:nth-child(1){
    margin-right:15px;
   }
   div:nth-child(2) {
    background: white;
    color:black;
    padding: 1rem;
    margin-bottom: 1rem;
   }
`;
