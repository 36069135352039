import React, { useState, useEffect } from "react";
// import { invoiceFinanceDetailsContext } from '../../../../contexts/invoiceFinance/invoiceFinanceDetailsContext'

import { Grid } from '@mui/material';
import Input from "components/fields/Input";

const BuyerDetails = () => {

    //const {buyerData, handleBuyerChange} = useContext(invoiceFinanceDetailsContext)

    const [buyerData, setBuyerData] = useState({
        Gstin: "",
        LglNm: "",
        Addr1: "",
        Addr2: "",
        Loc: "",
        Pin: "",

    });

    useEffect(() => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        setBuyerData({
            ...buyerData,
            Gstin: general.invoiceDetails.BuyerDtls.Gstin,
            LglNm: general.invoiceDetails.BuyerDtls.LglNm,
            Addr1: general.invoiceDetails.BuyerDtls.Addr1,
            Addr2: general.invoiceDetails.BuyerDtls.Addr2,
            Loc: general.invoiceDetails.BuyerDtls.Loc,
            Pin: general.invoiceDetails.BuyerDtls.Pin,
        });
    }, [])

    const handleBuyerChange = (event) => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        if (event.target.name === "Gstin") {
            general.invoiceDetails.BuyerDtls.Gstin = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setBuyerData({
                ...buyerData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "LglNm") {
            general.invoiceDetails.BuyerDtls.LglNm = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setBuyerData({
                ...buyerData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Addr1") {
            general.invoiceDetails.BuyerDtls.Addr1 = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setBuyerData({
                ...buyerData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Addr2") {
            general.invoiceDetails.BuyerDtls.Addr2 = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setBuyerData({
                ...buyerData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Loc") {
            general.invoiceDetails.BuyerDtls.Loc = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setBuyerData({
                ...buyerData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Pin") {
            general.invoiceDetails.BuyerDtls.Pin = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setBuyerData({
                ...buyerData,
                [event.target.name]: event.target.value
            })
        }

    }

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="Gstin"
                        placeholder="Buyer GSTIN"
                        value={buyerData.Gstin}
                        handleChange={handleBuyerChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="LglNm"
                        placeholder="Buyer Legal Name"
                        value={buyerData.LglNm}
                        handleChange={handleBuyerChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="Addr1"
                        placeholder="Buyer Address 1"
                        value={buyerData.Addr1}
                        handleChange={handleBuyerChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="Addr2"
                        placeholder="Buyer Address 2"
                        value={buyerData.Addr2}
                        handleChange={handleBuyerChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="Loc"
                        placeholder="Buyer Place"
                        value={buyerData.Loc}
                        handleChange={handleBuyerChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="Pin"
                        placeholder="Pincode"
                        value={buyerData.Pin}
                        handleChange={handleBuyerChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}
export default BuyerDetails

