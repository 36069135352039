// import _ from "lodash";

// export const FinancingValdations = (payload) => {
//   if (_.isEmpty(payload.financeProductType)) {
//     return "Please Enter Loan Product Type";
//   }

//   if (_.isEmpty(payload.financeAmountRequested)) {
//     return "Please Enter Required Finance Amount";
//   } else if (payload.financeAmountRequested <= 0) {
//     return "Required Finance Amount cannot be less than 0";
//   }
// };

import _ from "lodash";

export const AddPaymentValdations = (payload, isBankCodes) => {
  if (_.isEmpty(payload.memberID)) {
    return "Memeber ID missing Please Refesh Page";
  }

  if (_.isEmpty(payload.paymentAccountHolderName)) {
    return "Please Enter Account Holder Name";
  }

  if (_.isEmpty(payload.bankName)) {
    return "Please Enter Bank Name";
  }

  if (_.isEmpty(payload.country)) {
    return "Please Select Country";
  }

  if (_.isEmpty(payload.paymentAccountCurrency)) {
    return "Please Select Currency";
  }

  if (_.isEmpty(isBankCodes)) {
    return "Please Select Country & Currency";
  }

  if (_.isEmpty(payload.bankCodes && payload.bankCodes.length > 0)) {
    for (let i = 0; i < payload.bankCodes.length; i++) {
      if (_.isEmpty(payload.bankCodes[i].bankCode)) {
        return `Please Enter ${payload.bankCodes[i].bankCodeType}`;
      }
    }
  }

  if (_.isEmpty(payload.accountType)) {
    return "Please Select Account Type";
  }

  if (_.isEmpty(payload.paymentAccountNumber)) {
    return "Please Enter Account Number";
  }

  if (_.isEmpty(payload.status)) {
    return "Please Select Status";
  }

  if (_.isEmpty(isBankCodes)) {
    return "Please Select Country & Currency";
  }
};
