import React, { useState, useContext, useEffect } from "react";
import classes from "./Schedules.module.css";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import { UseRequest } from "utils/API/Request.js";
import moment from "moment";
import { openTradeAccount } from "services/BaseURLInstance";
import { AuthContext } from "contexts/Auth/AuthContext";

import { DocumentSelect } from "utils/DropDown/DocumentCatogery.js";
import { STAGE } from "utils/DropDown/Stage";

import { ToastContainer } from "react-toastify";
import { Success, Error, Alert } from "components/notifications/responseHandle";
import { AddMilestoneValdations } from "utils/Validations/OpenTradeAccount";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { addMilestone } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";

const MilestoneForm = ({
  setIsScheduleFormOpen,
  setIsPercentageComplete,
  isPercentageComplete,
}) => {
  const {
    OpenTradeAccountDetails,
    setOpenTradeAccountDetails,
    isApiTrigger,
    setApiTrigger,
  } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isTradevalue, setisTradevalue] = useState(null);
  const { instance, accounts } = useMsal();

  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");

  const [schedules, setSchedules] = useState({
    openTradeScheduleUID: "",
    openTradeAccountId: OpenTradeAccountDetails.openTradeAccountId,
    tradeStage: "",
    tradeSubStage: "",
    documentGroups: [], // DocumentCategrory DocSubCategory
    milestonePaymentPercentage: "", // Done
    milestonePaymentAmount: "",
    paymentDate: "",
    milestoneDate: "", // ask // input_type
    // tradeMilestoneStatus: "OPEN",
    providerMemberUID: OpenTradeAccountDetails.sellerMemberId, // seller
    consumerMemberUID: OpenTradeAccountDetails.buyerMemberId, // buyer
    participants: [],
    participantsToSign: [],
    participantsToShare: [],
  });

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "rgb(66, 165, 245)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgb(66, 165, 245)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgb(66, 165, 245)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(66, 165, 245)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(66, 165, 245)",
      },
    },
  });

  const [select, setSelect] = useState(null);
  const { Category, SubCategory } = DocumentSelect(select);
  const { StageList, SubStageList } = STAGE(null || schedules.tradeStage);

  const [documents, setDocuments] = useState({
    docCategory: "",
    docSubCategory: "",
  });

  const handleChange = (event, date) => {
    if (date === "paymentDate" || date === "milestoneDate") {
      let newDate = new Date(event);
      let dateString = newDate.toISOString();

      let newDate1 = moment(new Date(event)).format("YYYY-MM-DD");

      if (date === "paymentDate") {
        setDatePicker1(newDate1);
      }
      if (date === "milestoneDate") {
        setDatePicker2(newDate1);
      }

      setSchedules({
        ...schedules,
        [date]: dateString,
      });
    } else if (event.target.name === "docCategory") {
      setSelect(event.target.value);
      setDocuments({
        ...documents,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "docSubCategory") {
      setDocuments({
        ...documents,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "milestonePaymentPercentage") {
      setSchedules({
        ...schedules,
        [event.target.name]: event.target.value,
      });
    }

    // else if (event.target.name === "milestonePaymentPercentage") {
    //   const newMilestonePaymentPercentage = parseFloat(event.target.value);
    //   setSchedules({
    //     ...schedules,
    //     milestonePaymentPercentage: newMilestonePaymentPercentage,
    //   });
    //   setIsPercentageComplete(parseFloat(isPercentageComplete) + newMilestonePaymentPercentage);
    // }
    else {
      setSchedules({
        ...schedules,
        [event.target.name]: event.target.value,
      });
    }
  };

  useEffect(() => {
    if (schedules && schedules.milestonePaymentPercentage) {
      if (
        parseFloat(schedules.milestonePaymentPercentage) +
          parseFloat(isPercentageComplete) >
        100
      ) {
        Alert("Can't set milestone payment percentage greater than 100");
      }
    }
  }, [schedules]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let checkValidation = AddMilestoneValdations(
      schedules,
      documents,
      isPercentageComplete
    );
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      schedules.tradeMilestoneStatus = "MILESTONE_CREATED";
      schedules.documentGroups.push({
        docCategory: documents.docCategory,
        docSubCategory: documents.docSubCategory,
      });
      const options = {
        method: "POST",
        body: schedules,
      };
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}openaccounttrades/schedules`,
          options,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            setisTradevalue(
              parseInt(OpenTradeAccountDetails.tradeValue) -
                parseInt(
                  parseInt(isTradevalue) *
                    (parseInt(schedules.milestonePaymentPercentage) / 100)
                )
            );
            Success("Milestone Added Successfully");
            handleGetSingleData();
            setIsScheduleFormOpen(false);
            setApiTrigger(!isApiTrigger);
          }
        });
      });
    }
  };

  const handleGetSingleData = async () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`,
        null,
        response
      ).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          setOpenTradeAccountDetails(data);
          setApiTrigger(!isApiTrigger);
        }
      });
    });
  };

  return (
    <div className={classes.Schedules}>
      {isLoading ? (
        <Loader />
      ) : (
        <form className={classes.MilestoneDiv} onSubmit={handleSubmit}>
          <OverLayTrigger value={addMilestone} />
          <div className={classes.FormDiv}>
            <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
              <SelectWithSearch
                name="tradeStage"
                placeholder="Milestone Stage"
                options={
                  StageList &&
                  StageList.sort((a, b) => a.label.localeCompare(b.label))
                }
                value={schedules.tradeStage}
                handleChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
              <SelectWithSearch
                name="tradeSubStage"
                placeholder="Milestone Sub-Stage"
                options={
                  SubStageList &&
                  SubStageList.sort((a, b) => a.label.localeCompare(b.label))
                }
                value={schedules.tradeSubStage}
                handleChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
              <SelectWithSearch
                name="docCategory"
                placeholder="Document Category"
                options={
                  Category &&
                  Category.sort((a, b) => a.label.localeCompare(b.label))
                }
                value={documents.docCategory}
                handleChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
              <SelectWithSearch
                name="docSubCategory"
                placeholder="Document Sub-Category"
                options={
                  SubCategory &&
                  SubCategory.sort((a, b) => a.label.localeCompare(b.label))
                }
                value={documents.docSubCategory}
                handleChange={(e) => handleChange(e)}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
              <Input
                name="milestonePaymentPercentage"
                placeholder="Payment Percentage %"
                value={schedules.milestonePaymentPercentage}
                handleChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
              {schedules.milestonePaymentPercentage > 0 ? (
                <Input
                  // name="milestonePaymentAmount"
                  placeholder={`Payment Amount(${OpenTradeAccountDetails.paymentCurrency})`}
                  value={(
                    parseFloat(OpenTradeAccountDetails.tradeValue) *
                    (parseFloat(schedules.milestonePaymentPercentage) / 100)
                  ).toFixed(2)}
                  // handleChange={(e) => handleChange(e)}
                  readOnly={true}
                />
              ) : (
                <Input
                  value={0}
                  placeholder={`Payment Amount(${OpenTradeAccountDetails.paymentCurrency})`}
                  readOnly={true}
                />
              )}
            </Grid>
            <div>
              <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                <DateInput
                  name="milestoneDate"
                  placeholder="Milestone Date"
                  value={DatePicker2}
                  handleChange={(e) => handleChange(e, "milestoneDate")}
                />
              </Grid>
            </div>
            <div>
              <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <Stack spacing={4}>
                    <DatePicker
                      name="paymentDate"
                      label="Payment Due Date"
                      value={DatePicker1}
                      minDate={schedules.milestoneDate}
                      onChange={(e) => handleChange(e, "paymentDate")}
                      renderInput={(params) => (
                        <CssTextField
                          InputLabelProps={{
                            style: { color: "rgb(66, 165, 245)" },
                          }}
                          sx={{
                            svg: { color: "rgb(66, 165, 245)" },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Stack>
                </LocalizationProvider>
              </Grid>
            </div>
          </div>
          <ButtonDiv>
            <Button onClick={() => setIsScheduleFormOpen(false)}>Cancel</Button>
            <Button type="submit">Add</Button>
          </ButtonDiv>
        </form>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default MilestoneForm;
