import { Wrapper } from 'components/Container.styled'
import React from 'react'
import styles from './syndicate.module.css'
export default function LoanSummary({data}) {
  return (
    <Wrapper>
        <div className={styles.loanSummary}>
            <h2>Loan Summary</h2>
       <div className={styles.rowbox}>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Loan ID</div>
                <span style={{color: "#42A5F5"}}>{data?.loanId}</span>
              </div>
            </div>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Updated Date</div>
                <span style={{color: "#42A5F5"}}>{new Date(data?.lastUpdatedDate).toLocaleDateString()}</span>
              </div>
            </div>
            </div>

            <div className={styles.rowbox}>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Loan Status</div>
                <span style={{color: "#42A5F5"}}>{data?.loanStatus}</span>
              </div>
            </div>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Finance Amount</div>
                <span style={{color: "#42A5F5"}}>{data?.financeAmount}</span>
              </div>
            </div>
            </div>


            <div className={styles.rowbox}>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Finance Disbursal Status</div>
                <span style={{color: "#42A5F5"}}>{data?.financeDisbursalStatus}</span>
              </div>
            </div>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Finance Currency</div>
                <span style={{color: "#42A5F5"}}>{data?.financeCurrency}</span>
              </div>
            </div>
            </div>



            <div className={styles.rowbox}>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Finance Re-Payment Status</div>
                <span style={{color: "#42A5F5"}}>{data?.financeRePaymentStatus}</span>
              </div>
            </div>
            <div className={styles.card}  >
              <div className={styles.contents}>
                <div>Interest Rate</div>
                <span style={{color: "#42A5F5"}}>{data?.interestRate}</span>
              </div>
            </div>
            </div>
            </div>
        </Wrapper>
  )
}
