import React from "react";
import Img from "images/page.png";
import classes from "./record.module.css";

const NoRecord = () => {
  return (
    <div className={classes.Container}>
      <div className={classes.imgDiv}>
        <img src={Img} alt="No Record found" />
      </div>
      <div style={{ fontSize: "20px" }}>No Record found</div>
    </div>
  );
};

export default NoRecord;
