import React, { useState, useEffect } from "react";
import Container from "components/UI/Layout/Container";
import styles from "pages/Documents/dashboard.module.css";
import Document from "pages/Document/index";
import { HiArrowNarrowLeft } from "react-icons/hi";
import classes from "./documentSection.module.css";
import { DocumentGroup } from "utils/DropDown/ServiceDealDocument";
import DocumentUpload from "./DocumentUpload";

const AllDocument = ({ response, hide, setHide, serviceId }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [SelectedCategory, setSelectedCategory] = useState(null);
  const [selectCategoryOptions, setSelectedCategoryOptions] = useState(null);
  const [selectedSubCategoryOptions, setSelectedSubCategoryOptions] = useState(
    []
  );
  const [CategoryListURL, setCategoryListURL] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (response && hide) {
        let ServiceCategory =
          response &&
          response[0] &&
          response[0].serviceCategory &&
          response[0].serviceCategory[0];

        let ServiceSubCategory =
          response &&
          response[0] &&
          response[0].serviceSubCategory &&
          response[0].serviceSubCategory[0];

        if (hide === "Required") {
          const { Category, SubCategory } = DocumentGroup(
            ServiceCategory,
            ServiceSubCategory,
            hide,
            SelectedCategory
          );
          setSelectedCategoryOptions(Category);
          let TEMP = [];
          Category.map((Service) => TEMP.push(Service.value));

          sessionStorage.setItem(
            "serviceRequestCategoryListURL",
            JSON.stringify(TEMP)
          );
          setCategoryListURL(TEMP);
          setSelectedSubCategoryOptions(SubCategory);
        }
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [response, hide, SelectedCategory]);

  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (!shouldRender) {
    return <div>Loading...</div>; // or a loading indicator
  }

  return (
    <div>
      {isLoading ? (
        <div>Loading...</div>
      ) : (
        <>
          <div
            onClick={() => {
              setHide(null);
              setIsUploading(false);
              setSelectedCategory(null);
            }}
            style={{ color: "#218eeb", cursor: "pointer" }}>
            <span>
              <HiArrowNarrowLeft size={28} />
            </span>
            <span>Back to List </span>
          </div>

          {hide == "Required" && (
            <>
              {isUploading ? (
                <div>
                  <DocumentUpload
                    selectedCategory={SelectedCategory} // useState
                    setSelectedCategory={setSelectedCategory} // useState
                    Category={selectCategoryOptions} // Drop down option
                    SubCategory={selectedSubCategoryOptions} // Drop down option
                    setIsUploading={setIsUploading}
                    response={response}
                  />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-end">
                    <button
                      className={classes.UploadBtn}
                      onClick={() => setIsUploading(true)}>
                      Upload
                    </button>
                  </div>
                  <Document
                    serviceId={serviceId}
                    serviceDealLocations={{
                      serviceDealLocation: "serviceRequestRequired",
                      docCategoryList:
                        JSON.parse(
                          sessionStorage.getItem(
                            "serviceRequestCategoryListURL"
                          )
                        )?.length > 0
                          ? JSON.parse(
                              sessionStorage.getItem(
                                "serviceRequestCategoryListURL"
                              )
                            )
                          : ["OTHERS"],
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AllDocument;
