import React, { useState, useEffect } from "react";
import { FindCompanyName, truncateStringID } from "utils/helper";
import { GoMail } from "react-icons/go";
import { RiWechatLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { SendBirdState } from "sendbird-uikit";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import classes from "../service.module.css";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { SERVICE_LABEL, STATUS, COUNTRY } from "utils/Constants";
import { IoPersonAddOutline } from "react-icons/io5";

var sdk = null;
const mapStoreToProps = (store: SendBirdState) => {
  const {
    stores: { sdkStore, userStore },
    config: {
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub,
    },
  } = store;
  sdk = sdkStore.sdk;
};
const Body = ({
  data,
  companyList,
  handleNavigateInvoice,
  hanndleCraateGroup,
  setIsAddServiceDeal,
  setClickedServiceData,
}) => {
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const [searchServiceRole, setSearchServiceRole] = useState(null);
  const [isError, setIsError] = useState(null);
  const [Role, setRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const header = [
    {
      name: "Service",
      flex: "flex-3",
    },
    {
      name: "Consumer",
      flex: "flex-3",
    },
    {
      name: "Provider",
      flex: "flex-3",
    },
    {
      name: "Category",
      flex: "flex-3",
    },
    {
      name: "Sub-Category",
      flex: "flex-3",
    },
    {
      name: "Country",
      flex: "flex-3",
    },
    {
      name: "Created Date",
      flex: "flex-2",
    },
    {
      name: "Status",
      flex: "flex-2",
    },
    ,
    {
      name: "Action",
      flex: "flex-2",
    },
  ];

  const navigate = useNavigate();
  const fetchData = async (companyId, id2, name) => {
    let organizationMemberId = "";
    companyId.forEach((item) => {
      organizationMemberId =
        organizationMemberId + `organizationMemberId=${item}&`;
    });
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?${organizationMemberId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];
        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        hanndleCraateGroup(nData, name, name);
        setMemberList(nData);
      });
  };

  useEffect(() => {
    if (Role) {
      // setUploadInvoiceRole(
      //   CheckPermission("Invoice", "Invoices", "UploadInvoices", Role)
      // );
      setSearchServiceRole(
        CheckPermission(
          "ServiceRequisitionView",
          "ServicesRequest",
          "ServiceRequisitionView",
          Role
        )
      );
    }
  }, [Role]);

  const chatHandler = (data) => {
    if (
      data?.serviceProvidersSelected?.length > 0 &&
      data?.consumerMemberUID !== ""
    ) {
      setItem(data);
      let name = data.serviceRequisitionId
        ? truncateStringID(data.serviceRequisitionId, 7, "SR")
        : "NA";
      fetchData(data?.serviceProvidersSelected, data?.consumerMemberUID, name);
    }
  };

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${profileURL}Persons/${response.idTokenClaims.member_id}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setRole(data.role);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const Status = (status) => {
    switch (status) {
      case "NEW":
        return STATUS(status, "primary");
      case "IN_PURSUIT":
        return STATUS(status, "warning");
      case "ABORTED":
        return STATUS(status, "danger");
      case "EXPIRED":
        return STATUS(status, "primary");
      case "CLOSED":
        return STATUS(status, "success");
      case "DELIVERED":
        return STATUS(status, "primary");
      case "PAYMENT_COMPLETED":
        return STATUS(status, "primary");
      default:
        return status;
    }
  };

  return (
    <div>
      <Table bordered responsive className={cn(classes.Table)}>
        <thead>
          <tr>
            {header.map((header, index) => (
              <td key={index}>{header.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((list, index1) => (
            <tr key={index1}>
              <td
                style={{
                  color: "rgb(33,142,235",
                  textDecorationLine: "underline",
                }}
                onClick={() =>
                  searchServiceRole === "DELETE" ||
                  searchServiceRole === "NOTAPP"
                    ? " "
                    : handleNavigateInvoice(list.serviceRequisitionId)
                }>
                {list.serviceRequisitionId
                  ? truncateStringID(list.serviceRequisitionId, 7, "SR")
                  : "NA"}
              </td>
              <td>{FindCompanyName(companyList, list.consumerMemberUID)}</td>
              <td>
                {companyId != list.consumerMemberUID ? (
                  FindCompanyName(companyList, companyId)
                ) : (
                  <div className="d-flex align-items-center">
                    {FindCompanyName(
                      companyList,
                      list.serviceProvidersSelected[0]
                    )}
                    {list.serviceProvidersSelected.length > 1 ? (
                      <div>
                        {" ..."}
                        <OverlayTrigger
                          trigger={["hover", "focu`s"]}
                          key="top"
                          placement="top"
                          overlay={
                            <Popover id="popover-positioned-top">
                              <Popover.Body>
                                <strong>
                                  <span>List of Providers</span>
                                  {list.serviceProvidersSelected.map(
                                    (id, index) => {
                                      return (
                                        <div key={index}>
                                          <li>
                                            {FindCompanyName(companyList, id)}
                                            {","}{" "}
                                          </li>
                                        </div>
                                      );
                                    }
                                  )}
                                </strong>
                              </Popover.Body>
                            </Popover>
                          }>
                          <span>
                            <AiOutlineInfoCircle size={26} />
                          </span>
                        </OverlayTrigger>
                      </div>
                    ) : (
                      " "
                    )}
                  </div>
                )}
              </td>
              <td>{SERVICE_LABEL(list.serviceCategory)}</td>
              <td>{SERVICE_LABEL(list.serviceSubCategory)}</td>
              <td>{COUNTRY(list.countriesForSubscription[0])}</td>
              <td>{new Date(list.createdDate).toLocaleDateString()}</td>
              <td style={{ minWidth: "120px" }}>
                {Status(list.serviceRequisitionStatus)}
              </td>
              <td className="d-flex">
                <span
                  onClick={() => {
                    setIsAddServiceDeal(true);
                    setClickedServiceData(list);
                  }}>
                  <IoPersonAddOutline size={22} />
                </span>
                <span style={{ margin: "0px 5px" }}>
                  <GoMail size={22} />
                </span>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Body>
                        <strong>Chat</strong>
                      </Popover.Body>
                    </Popover>
                  }>
                  <span
                    onClick={() => chatHandler(list)}
                    style={{ cursor: "pointer" }}>
                    <RiWechatLine size={22} />
                  </span>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Body;
