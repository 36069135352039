import React, { useContext, useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { CompanyNameFilter, notify } from "../../../../utils/helper";
import { storeContext } from "../../../../contexts/Store/Store";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import styles from "../../invoice.module.css";
import { invoiceFinanceContext } from "../../../../contexts/invoiceFinance/invoiceFinanceContext";

import { NetworkURL } from "../../../../services/BaseURLInstance";

const Summary = () => {
  const { invoiceData } = React.useContext(invoiceFinanceContext);

  const [CompanyList, setCompanyList] = useState([]);
  const { fetchData } = useContext(storeContext);
  const { instance, accounts } = useMsal();
  // const [idToken, setIdToken] = useState(null);
  // getIdToken(instance, accounts, setIdToken);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = getClaim(accounts, "company_member_id");
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  useEffect(() => {
    const getData = async () => {
      let URL = `${NetworkURL}members?memberId=${companyId}`;
      const Data = await fetchData(URL);
      {
        Data && Data.data && setCompanyList(Data.data);
      }
      if (Data && Data.error) {
        notify("warn");
      }
    };
    getData();
  }, []);

  const handleDocumentPreview = (e, id) => {
    e.preventDefault();
    if (id === undefined) {
      alert("Not available");
    } else {
      handleNavigate(`/documents/preview/COMPANY/${id}`);
    }
  };

  const Response = [
    {
      name: "Status",
      value: invoiceData.txlStatus ? invoiceData.txlStatus : "Unknown",
    },
    {
      name: "Funding Details",
      value: "PENDING",
    },
    {
      name: "Buyer Consent",
      value: "PENDING",
    },
    {
      name: "Value",
      value: invoiceData.txlInvoiceAmount ? invoiceData.txlInvoiceAmount : "NA",
    },
    {
      name: "Last Activity",
      value: invoiceData.updatedDate ? invoiceData.updatedDate : "NA",
    },

    {
      name: "Invoice Document",
      value: invoiceData.invoiceNumber ? (
        <span
          onClick={(e) => handleDocumentPreview(e, invoiceData.document.id)}
          className="cursor-pointer"
        >
          {invoiceData.invoiceNumber}
        </span>
      ) : (
        "NA"
      ),
    },
    {
      name: "Buyer",
      value:
        CompanyNameFilter(CompanyList, invoiceData.txlBuyerMemberId).length >= 1
          ? CompanyNameFilter(CompanyList, invoiceData.txlBuyerMemberId)[0]
              .memberName
          : "NA",
    },
    {
      name: "Seller",
      value:
        CompanyNameFilter(CompanyList, invoiceData.txlSellerMemberId).length >=
        1
          ? CompanyNameFilter(CompanyList, invoiceData.txlSellerMemberId)[0]
              .memberName
          : "NA",
    },
  ];

  return (
    <div className={styles.summary}>
      {Response &&
        Response.map((data, i) => (
          <div className={styles.summaryWrapper} key={i}>
            <div>
              {" "}
              <span> {data.name} </span>{" "}
            </div>
            <div>
              {" "}
              <span
                style={
                  data.value === "PENDING"
                    ? addRed
                    : data.name === "Buyer"
                    ? addBlue
                    : data.name === "Invoice Document"
                    ? addBlue
                    : data.name === "Seller"
                    ? addBlue
                    : addWhite
                }
              >
                {" "}
                {data.value}{" "}
              </span>{" "}
            </div>
          </div>
        ))}
    </div>
  );
};

export default Summary;

const addRed = {
  color: "red",
};

const addWhite = {
  color: "white",
};

const addGreen = {
  color: "green",
};

const addBlue = {
  color: "#42A5F5",
};
