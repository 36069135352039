import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";

import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";

import Table from "react-bootstrap/Table";
import cn from "classnames";
import { Countries } from "utils/DropDown/Country";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from 'jsons/Currenciesr.json';
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";

import { Grid } from "@mui/material";


const InspectionReg = ({response}) => {

 
let inspectionMedium = [
    {
        label : "Visual inspection",
        value : "Visual inspection",
    },
    {
        label : "Physical inspection",
        value : "Physical inspection",
    },
    {
        label : "Remote inspection",
        value : "Remote inspection",
    }
]

let inspectionServices =[
    {
        label : "Pre-production Inspections",
        value : "Pre-production Inspections",
    },
    {
        label : " During Production Inspections ",
        value : " During Production Inspections"
    },
    {
        label : "Pre-shipment Inspections ",
        value : "Pre-shipment Inspections ",
    },
    {
        label : "Sample Checking ",
        value : "Sample Checking ",
    },
    {
        label : "Container loading Supervision ( CLS ) and Unloading supervision ",
        value : "Container loading Supervision ( CLS ) and Unloading supervision ",
    },
    {
        label : "CLC – Container Loading Check",
        value : "CLC – Container Loading Check",
    },
    {
      label : "IPC – Initial Production Check",
      value : "IPC – Initial Production Check",
  },
  {
    label : "Piece by Piece Inspection (or Sorting Inspection)",
    value : "Piece by Piece Inspection (or Sorting Inspection)",
},
{
  label : "Final Random Inspection",
  value : "Final Random Inspection",
},


]

let loadstype = [
  {
      label : "Full container loads",
      value : "Full container loads",
  },
  {
      label : "General Cargo",
      value : "General Cargo",
  },
  {
      label : "Boxes/packages",
      value : "Boxes/packages",
  }
]


let Packaging = [
  {
      label : "Palettes",
      value : "Palettes",
  },
  {
      label : "Drums",
      value : "Drums",
  },
  {
      label : "Containers",
      value : "Containers",
  },
  {
    label : "Boxes",
    value : "Boxes",
},
{
  label : "Bulk",
  value : "Bulk",
},
{
  label : "Break Bulk",
  value : "Break Bulk",
},


{ label : "Not relevant",
value : "Not relevant",
},
]





let containers = [
  {
      label : "10-Foot Container",
      value : "10-Foot Container",
  },
  {
      label : "20-Foot Container",
      value : "20-Foot Container",
  },
  {
      label : "40-Foot Container",
      value : "40-Foot Container",
  },
  {
    label : "20-Foot High Cube",
    value : "20-Foot High Cube",
},
{
  label : "440-Foot High Cube",
  value : "40-Foot High Cube",
},
{
  label : "Not relevant",
  value : "Not relevant",
}
]



let samples = [
  {
      label : "No approved sample",
      value : "No approved sample",
  },
  {
      label : "Approved sample at factory",
      value : "Approved sample at factory",
  },
  {
      label : "Not relevant",
      value : "Not relevant",
  },
  {
    label : "Other",
    value : "Other",
}
]


let locations = [
  {
      label : "Supplier",
      value : "Supplier",
  },
  {
      label : "Freight forwarder",
      value : "Freight forwarder",
  },
  {
      label : "Logistic hub",
      value : "Logistic hub",
  },
  {
    label : "Warehouse",
    value : "Warehouse",
},
{
  label : "Building/site",
  value : "Building/site",
}
]















const [serviceDetails, setServiceDetails] = useState([]);
useEffect(() =>{
if(response && response.ServiceReqDetails){
setServiceDetails(JSON.parse(response.ServiceReqDetails))
}
},[response]);



let listService = serviceDetails.regInspection?.map((user)=>{
  return (user.value)
})
let products = serviceDetails.commodity?.map((user)=>{
  return (user.value)
})





// console.log(  serviceDetails && serviceDetails , "saurabh" )

  return ( <>
    { response && serviceDetails ?
    <>
    <div style={{ margin:"10px" }}>Regulatory Inspection 

    </div>
    
   
         
    <div style={{ margin:"10px" }}>
    Types of Regulatory Inspection(RI) ?


              </div>
              <div className={classes.source}>
                <div style={{ width: "50%", margin:"10px" }}>
                <Input
                  //   options={regInspections}
                      name="regInspection"
                      placeholder="Regulatory Inspection(RI)"
               // handleChange={(e) => handleMultiChange(e, "regInspection")}
                      value={listService}
                    readOnly={true}
                    />
                  </div>
                 
                </div>
    
              <div className={classes.source}>
                <div style={{ width: "100%", margin:"10px" }}>
                  <Input
                    // options={inspectionMedium}
                      name="inspectionMedium"
                      placeholder="Inspection Medium"
                      readOnly={true}
                      value={  serviceDetails && serviceDetails.inspectionMedium}
                    // readOnly={true}
                    />
                  </div>
                 
                </div>
              {/* Countries */}
             
          <Grid container> 
              <div style={{ width: "50%", margin:"10px" }}>
              <Input
                     // options={inspectionServices}
                      name="commodity"
                      placeholder="Product Category"
                      //handleChange={(e) => handleMultiChange(e, "commodity")}
                      value={products}
                      />
                    </div>
                    </Grid>

            
    
             <div>
                 <div>
              
                </div> 
                <div className={classes.source}>
                <div style={{ width: "50%", margin:"10px" }}>
                <label>When do you need your inspection quote by?*
             </label>

                </div>
                <div style={{ width: "50%", margin:"10px" }}>
                <label>When do you need your inspection quote by?*
</label>

                </div></div>
              <  div className={classes.source}> 
           <div style={{ width: "50%", margin:"10px" }}>

               
                <Input
                name="qouteBy"
                placeholder="Date *"
                value={new Date(
                  serviceDetails.qouteBy
                ).toLocaleDateString()}
                // handleChange={(e) =>
                //   handleDateChange(e, "qouteBy")
                // }
                />
                </div>
                 
      
                <div style={{ width: "50%", margin : "10px" }}>
                
                <Input
                name="reportBy"
                placeholder="Date *"
                value={new Date(
                  serviceDetails.reportBy
                ).toLocaleDateString()}
                // handleChange={(e) =>
                //   handleDateChange(e, "reportBy")
                // }
                    />
                      </div>
                      </div>
                  
              
                
                 

                
                
               
                  
                
    
               
                </div>
    
    
    
    
    
    
    
    
    
                <div className={classes.source}>
                <div style={{  width: "100%",  margin:"10px" }}>
            <TextArea
              name="ServiceReqDescription"
              placeholder="Give your own short description of the job*"
              value={ serviceDetails && serviceDetails.ServiceReqDescription}
              readOnly={true}
            />
            </div>
     </div>
    
                  {/* <div style={{  width: "100%", marginBottom:"2%" }}>
                    <Input
                   options={samples}
                      name="companyLocation"
                      placeholder="Select *"
                      value={  serviceDetails && serviceDetails.companyLocation}
                      readOnly={true
                    />
                   
                  </div> */}
                  <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
            <Input
                  name="companyName"
                  placeholder="Company Name"
                  readOnly={true}
                  value={  serviceDetails && serviceDetails.companyName}
                // readOnly={true}
                />
            
              </div>
              <div style={{ width: "50%", margin:"10px" }}>
              <Input
                  name="contactinfo"
                  placeholder="Contact Information"
                  readOnly={true}
                  value={  serviceDetails && serviceDetails.contactinfo}
                // readOnly={true}
                />
              </div>
              </div>
              <div className={classes.source}>
              <Input
                  name="address"
                  placeholder="Address"
                  readOnly={true}
                  value={  serviceDetails && serviceDetails.address}
                // readOnly={true}
                />
              </div>
        
       
        
    
    
    
    
    
    
    
     
              </> : <></>
        }
    
        </>
        
  
  )
}


export default InspectionReg ;