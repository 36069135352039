import React, { useState } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { documentURL, docGroupURL } from "services/BaseURLInstance";
import DocumentsPreview from "./documentsPreview";
import DocumentsProperties from "./documentsProperties";
import DocumentAction from "./documentAction";
import { useEffect } from "react";
import styles from "./documents.module.css";
import cn from "classnames";
import { useParams } from "react-router-dom";
import Loader from "components/Loader/Loader";
import Container from "components/UI/Layout/Container";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Content from "components/UI/Layout/Content";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { CompanyList } from "utils/API/CompanyList.js";
import { Error } from "components/notifications/responseHandle";
import DocumentGraph from "./documentTrace";
import { useNavigate } from "react-router-dom";

export default () => {
  const navigate = useNavigate();
  const { response, isLoading, isError } = CompanyList();

  let { id } = useParams();
  const [header, setHeader] = useState("documentpreview");
  const { instance, accounts } = useMsal();

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [template, setTemplate] = useState(null);

  const [isLoading1, setIsLoading1] = useState(false);
  const [isError1, setError1] = useState(null);

  const handleHeader = (e, value) => {
    e.preventDefault();
    setHeader(value);
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading1(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${documentURL}documents/${id}`, null, response).then(
          (data) => {
            let { message } = data;
            if (message) {
              Error(message);
              setError1(message);
            } else {
              setData1(data);
            }
          }
        );
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${docGroupURL}bff/documentsGroups/document?documentId=${id}&memberUID=${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading1(false);
          let { message } = data;
          if (message) {
            Error(message);
            setError1(message);
          } else {
            setData2(data[0].documents);
            setTemplate(data[0].template);
          }
        });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  const Component = () => {
    switch (header) {
      case "documentpreview":
        return (
          <DocumentsPreview data1={data1} data2={data2} response={response} />
        );
      case "documentproperties":
        return (
          <DocumentsProperties
            data1={data1}
            data2={data2}
            template={template}
            response={response}
            handleHeader={handleHeader}
          />
        );
      // case "documentactions":
      //   return <DocumentAction />;
      case "documentgraph":
        return <DocumentGraph />;
    }
  };

  return (
    <>
      <Container>
        <div
          style={{ color: "#218eeb", cursor: "pointer" }}
          onClick={() => navigate(-1)}>
          <span>
            <HiArrowNarrowLeft size={28} />
          </span>
          <span>Back to List </span>
        </div>
        <div
          className={
            isLoading || isLoading1
              ? cn(styles.isDisabled, styles.previewNav)
              : styles.previewNav
          }>
          <div
            style={header === "documentpreview" ? { color: "#42A5F5" } : {}}
            onClick={(e) => handleHeader(e, "documentpreview")}>
            Document Preview
          </div>
          <div
            style={header === "documentproperties" ? { color: "#42A5F5" } : {}}
            onClick={(e) => handleHeader(e, "documentproperties")}>
            Document Properties
          </div>
          {/* <div
            style={header === "documentactions" ? { color: "#42A5F5" } : {}}
            onClick={(e) => handleHeader(e, "documentactions")}>
            Document Actions
          </div> */}

          <div
            style={header === "documentgraph" ? { color: "#42A5F5" } : {}}
            onClick={(e) => handleHeader(e, "documentgraph")}>
            Document Trace
          </div>
        </div>

        {isLoading || isLoading1 ? (
          <Content>
            Loading...
            <Loader />
          </Content>
        ) : (
          <>
            {data1 && data2 && response && (
              <div className={styles.documents}>{header && Component()}</div>
            )}

            {(isError || isError1) && <Content>Something went wrong</Content>}
          </>
        )}
      </Container>
    </>
  );
};
