import { useState } from "react"
import { CgChevronDown, CgChevronUp } from "react-icons/cg";

export const ToggleTab = ({description, title}) =>{
    const [dropDown, setDropDown] = useState(false);
    return (
        <div style={{backgroundColor:"black", marginTop:"1%", padding:"20px", borderRadius:"10px", paddingBottom:"-20px"}}>
          <div style={{display:"flex", marginBottom:"1%"}}>
            <div style={{width:"50%"}}>{title}</div>
            {dropDown 
            ? <div style={{width:"50%", textAlign:"right"}}>
                <button style={{background:"transparent", border:"none", color:"white"}} onClick={()=>setDropDown(!dropDown)}>
                     <CgChevronDown size={30} />
                </button></div>
            :<div style={{width:"50%", textAlign:"right"}}>
                <button style={{background:"transparent", border:"none", color:"white"}} onClick={()=>setDropDown(!dropDown)}>
                <CgChevronUp size={30} />
                </button>
            </div>
            }
          </div>
          {!dropDown && description}
        </div>
    )
}