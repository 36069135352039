import React, { useState } from "react";
import classes from "./freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// import { Tooltip } from 'react-tippy';
import { IoIosHelpCircle } from "react-icons/io";

const Insurance = ({ insuranceData, setInsuranceData }) => {
  let insuranceTypeOptions = [
    {
      label: "EXPORT",
      value: "EXPORT",
    },
    {
      label: "IMPORT",
      value: "IMPORT",
    },
    {
      label: "INLAND",
      value: "INLAND",
    },
  ];

  let transitModeOptions = [
    {
      label: "AIR",
      value: "AIR",
    },
    {
      label: "RAIL",
      value: "RAIL",
    },
    {
      label: "ROAD",
      value: "ROAD",
    },
    {
      label: "SEA",
      value: "SEA",
    },
  ];

  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");

  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setInsuranceData({
      ...insuranceData,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  const handleChange = (e, date) => {
    if (date === "CARGO_READY" || date === "PREFERRED_SAILING_DATE") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "CARGO_READY") {
        setDatePicker1(newDate1);
        // setInsuranceData({
        //   ...insuranceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "PREFERRED_SAILING_DATE") {
        setDatePicker2(newDate1);
        // setInsuranceData({
        //   ...insuranceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setInsuranceData({
        ...insuranceData,
        [date]: dateString,
      });
    } else if (e.target.name === "CARGO_READY") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "insuranceType") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "PREFERRED_SAILING_DATE") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "transitMode") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currency") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "insuredAmount") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "pllAmount") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "pslAmount") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destination") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cargoHazardous") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "UN_Number") {
      setInsuranceData({
        ...insuranceData,
        [e.target.name]: e.target.value,
      });
    }

    sessionStorage.setItem("insuranceData", JSON.stringify(insuranceData));
    // sessionStorage.setItem("tabletransportLoadData", JSON.stringify(tabletransportLoadData));

    console.log("insuranceData", insuranceData);
  };
  return (
    <>
      <Content padding="1% 2% 2% 2%">
        <div style={{ margin: "10px 10px 0px 10px" }}>
          INSURANCE REQUIRED FOR?
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
            <Select
              options={insuranceTypeOptions}
              name="insuranceType"
              placeholder="Insurance Required for?*"
              value={insuranceData.insuranceType}
              handleChange={handleChange}
            />
          </div>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
            <Select
              options={transitModeOptions}
              name="transitMode"
              placeholder="Transit Mode*"
              value={insuranceData.transitMode}
              handleChange={handleChange}
            />
          </div>
        </div>
        <div style={{ margin: "10px" }}>
          <Select
            options={Industry}
            name="commodity"
            placeholder="Commodity*"
            handleChange={handleChange}
            value={insuranceData.commodity}
          />
        </div>
        <div style={{ margin: "10px 10px 0px 10px" }}>Yearly Sum Insured</div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
            <Select
              options={Currenciesr}
              name="currency"
              placeholder="Currency*"
              handleChange={handleChange}
              value={insuranceData.currency}
            />
          </div>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
            <Input
              name="insuredAmount"
              placeholder={`Insurance Value(${insuranceData.currency})*`}
              handleChange={handleChange}
              value={insuranceData.insuredAmount}
            />
          </div>
        </div>
        <div style={{ marginTop: "1%", margin: "0px 10px" }}>
          Per Sending Limit*
          <OverlayTrigger
            trigger="hover"
            key="top"
            placement="right"
            overlay={
              <Popover id="popover-positioned-right">
                {/* <Popover.Header as="h3">Document Name</Popover.Header> */}
                <Popover.Body>
                  <strong>
                    Maximum Sum Insured you send in a single transport/transit
                  </strong>
                </Popover.Body>
              </Popover>
            }
          >
            <span>
              <IoIosHelpCircle />
            </span>
          </OverlayTrigger>
        </div>

        <div className={classes.source}>
          <div style={{ width: "100%", margin: "5px 10px 10px 10px" }}>
            <Input
              name="pslAmount"
              placeholder={`Insurance Value(${insuranceData.currency})`}
              handleChange={handleChange}
              value={insuranceData.pslAmount}
            />
          </div>
        </div>
        <div style={{ marginTop: "1%", margin: "0px 10px" }}>
          Per Location Limit*
          <OverlayTrigger
            trigger="hover"
            key="top"
            placement="right"
            overlay={
              <Popover id="popover-positioned-right">
                {/* <Popover.Header as="h3">Document Name</Popover.Header> */}
                <Popover.Body>
                  <strong>Maximum Sum Insured you keep at your location</strong>
                </Popover.Body>
              </Popover>
            }
          >
            <span>
              <IoIosHelpCircle />
            </span>
          </OverlayTrigger>
        </div>

        <div className={classes.source}>
          <div style={{ width: "100%", margin: "5px 10px 10px 10px" }}>
            <Input
              name="pllAmount"
              placeholder=" Per Location Limit*"
              //{`Insurance Value(${insuranceData.currency})`}
              handleChange={handleChange}
              value={insuranceData.pllAmount}
            />
          </div>
        </div>

        <div style={{ margin: "10px 10px 0px 10px" }}>
          {" "}
          Do you have temperature control in your cargo?{" "}
        </div>
        <span style={{ marginLeft: "20px", marginRight: "10px" }}>
          {" "}
          <input
            className={classes.radio}
            type="radio"
            name="temperatureControl"
            value="Yes"
            onChange={handleRadio}
            checked={insuranceData.temperatureControl == "Yes"}
          />
          <label>Yes</label>
        </span>
        <span style={{ marginLeft: "20px", marginRight: "10px" }}>
          {" "}
          <input
            className={classes.radio}
            type="radio"
            name="temperatureControl"
            value="No"
            onChange={handleRadio}
            checked={insuranceData.temperatureControl == "No"}
          />
          <label>No</label>
        </span>

        <div style={{ margin: "10px 10px 0px 10px" }}>
          {" "}
          Is your cargo oversized?{" "}
        </div>
        <span style={{ marginLeft: "20px", marginRight: "10px" }}>
          {" "}
          <input
            className={classes.radio}
            type="radio"
            name="isOversized"
            value="Yes"
            onChange={handleRadio}
            checked={insuranceData.isOversized == "Yes"}
          />
          <label>Yes</label>
        </span>
        <span style={{ marginLeft: "20px", marginRight: "10px" }}>
          {" "}
          <input
            className={classes.radio}
            type="radio"
            name="isOversized"
            value="No"
            onChange={handleRadio}
            checked={insuranceData.isOversized == "No"}
          />
          <label>No</label>
        </span>

        <div style={{ margin: "10px 10px 0px 10px" }}>
          {" "}
          Do you have Fragile/ Glass/ LED items in this cargo?{" "}
        </div>
        <span style={{ marginLeft: "20px", marginRight: "10px" }}>
          {" "}
          <input
            className={classes.radio}
            type="radio"
            name="fragileCargo"
            value="Yes"
            onChange={handleRadio}
            checked={insuranceData.fragileCargo == "Yes"}
          />
          <label>Yes</label>
        </span>
        <span style={{ marginLeft: "20px", marginRight: "10px" }}>
          {" "}
          <input
            className={classes.radio}
            type="radio"
            name="fragileCargo"
            value="No"
            onChange={handleRadio}
            checked={insuranceData.fragileCargo == "No"}
          />
          <label>No</label>
        </span>
      </Content>
    </>
  );
};

export default Insurance;
