import React, { useCallback, useContext, useEffect, useState } from "react";
import useAxios from "../../../services/useAxios";
import { supplierApi } from "../../../services/BaseURLInstance";
import A from "../../../components/A";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import styles from "../invoice.module.css";

import { docGroupURL } from "../../../services/BaseURLInstance";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { storeContext } from "../../../contexts/Store/Store";
import ColumnList from "../../../components/UI/ColumnList/ColumnList";
import Content from "components/UI/Layout/Content";
import { InvoiceOverview } from "utils/Section/InvoiceSection";

export default () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const { instance, accounts } = useMsal();

  const companyId = getClaim(accounts, "company_member_id");
  const { getData } = useContext(storeContext);
  const [response, setResponse] = useState("");

  useEffect(() => {
    let isCancelled = false;

    let URL = `${docGroupURL}bff/supplier/dashboard/invoices?memberId=${companyId}`;
    getData(URL, idToken).then((data) => {
      if (!isCancelled) {
        setResponse(data);
      }
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      {response === "" && <Loader />}

      {response !== "" && (
        <>
          <Content>
            <ColumnList list={InvoiceOverview} response={response} />
          </Content>
        </>
      )}
    </>
  );
};

const SectionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 10px;
  align-items: baseline;
`;

const cardWrapper = {
  letterSpacing: "1px",
  borderBottom: "0",
};

const cardWrappering = {
  marginTop: "40px",
  letterSpacing: "1px",
  borderBottom: "0",
};
