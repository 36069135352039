import React, { useState, useEffect } from "react";
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import { Success, Error } from "components/notifications/responseHandle";

const SupplierDetails = () => {

    // const {supplierData , handleChangeSupplier} = useContext(invoiceFinanceDetailsContext)    

    const [supplierData, setSupplierData] = useState({
        Gstin: "",
        LglNm: "",
        Addr1: "",
        Addr2: "",
        Loc: "",
        Pin: "",
        Stcd: "",
    });

    useEffect(() => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        general.invoiceDetails.SellerDtls.Pin = isNaN(parseInt(general.invoiceDetails.SellerDtls.Pin))? '0': general.invoiceDetails.SellerDtls.Pin;
        localStorage.setItem("uploadData", JSON.stringify(general));
        setSupplierData({
            ...supplierData,
            Gstin: general.invoiceDetails.SellerDtls.Gstin,
            LglNm: general.invoiceDetails.SellerDtls.LglNm,
            Addr1: general.invoiceDetails.SellerDtls.Addr1,
            Addr2: general.invoiceDetails.SellerDtls.Addr2,
            Loc: general.invoiceDetails.SellerDtls.Loc,
            Pin: general.invoiceDetails.SellerDtls.Pin,
            Stcd: general.invoiceDetails.SellerDtls.Stcd,
        });
    }, [])


    const handleChangeSupplier = (event) => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        if (event.target.name === "Gstin") {
            general.invoiceDetails.SellerDtls.Gstin = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setSupplierData({
                ...supplierData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "LglNm") {
            general.invoiceDetails.SellerDtls.LglNm = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setSupplierData({
                ...supplierData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Addr1") {
            general.invoiceDetails.SellerDtls.Addr1 = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setSupplierData({
                ...supplierData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Addr2") {
            general.invoiceDetails.SellerDtls.Addr2 = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setSupplierData({
                ...supplierData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Loc") {
            general.invoiceDetails.SellerDtls.Loc = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setSupplierData({
                ...supplierData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Pin") {
            general.invoiceDetails.SellerDtls.Pin = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setSupplierData({
                ...supplierData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Stcd") {
            general.invoiceDetails.SellerDtls.Stcd = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setSupplierData({
                ...supplierData,
                [event.target.name]: event.target.value
            })
        }
    }

    return (
        <Grid container>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="Gstin"
                    placeholder="Supplier Tax Number"
                    value={supplierData.Gstin}
                    handleChange={handleChangeSupplier}
                    required={true}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="LglNm"
                    placeholder="Supplier Legal Name"
                    value={supplierData.LglNm}
                    handleChange={handleChangeSupplier}
                    required={true}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="Addr1"
                    placeholder="Supplier Address 1"
                    value={supplierData.Addr1}
                    handleChange={handleChangeSupplier}
                    required={true}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="Addr2"
                    placeholder="Supplier Address 2"
                    value={supplierData.Addr2}
                    handleChange={handleChangeSupplier}
                    required={true}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="Loc"
                    placeholder="Supplier Place"
                    value={supplierData.Loc}
                    handleChange={handleChangeSupplier}
                    required={true}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="Pin"
                    placeholder="Pincode"
                    value={supplierData.Pin}
                    handleChange={handleChangeSupplier}
                    type='number'
                    required={true}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="Stcd"
                    placeholder="State Code"
                    value={supplierData.Stcd}
                    handleChange={handleChangeSupplier}
                    required={true}
                />
            </Grid>
        </Grid>
    )
}

export default SupplierDetails;
