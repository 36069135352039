import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";

import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { COMPLIANCE_TRADE } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";

const TradeCompliance = ({ complianceData, setComplianceDataa }) => {
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];
  let paymentTerms = [
    {
      label: "Advance Payment",
      value: "Advance Payment",
    },
    {
      label: "Letter of credit",
      value: "Letter of credit",
    },
    {
      label: "Documents against Payments - D.A.P or D/P basis",
      value: "Documents against Payments - D.A.P or D/P basis",
    },
    {
      label: "Documents against Acceptance (D/A)",
      value: "Documents against Acceptance (D/A)",
    },
    {
      label: "Open Account",
      value: "Open Account",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let incoterm = [
    {
      label: "EXW",
      value: "EXW",
    },
    {
      label: "DDP (Delivered Duty Paid)",
      value: "DDP (Delivered Duty Paid)",
    },
    {
      label: "FOB (Free on Board)",
      value: "FOB (Free on Board)",
    },
    {
      label: "CIF (Cost, Insurance and Freight) ",
      value: "CIF (Cost, Insurance and Freight)",
    },
    {
      label: "FAS (Free Alongside Ship)",
      value: "FAS (Free Alongside Ship)",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let qtyUnitOptions = [
    {
      label: "MT",
      value: "MT",
    },
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "LB",
      value: "LB",
    },
    {
      label: "BOXES",
      value: "BOXES",
    },
    {
      label: "PCS",
      value: "PCS",
    },
  ];

  // let inspectionType = [
  //   {
  //     label: "Packaging ",
  //     value: "Packaging",
  //   },
  //   {
  //     label: "Labeling",
  //     value: "Labeling",
  //   },

  //   {
  //     label: "Documentation and Document Verification",
  //     value: "Documentation and Document Verification",
  //   },
  //   {
  //     label: "Compliance with all relevant regulations and standards",
  //     value: "Compliance with all relevant regulations and standards",
  //   },
  //   {
  //       label: "Physical inspection",
  //       value: "Physical inspection",
  //     },
  //     {
  //       label: "Sample testing",
  //       value: "Sample testing",
  //     },
  // ];

  const [selectList, setSelectList] = useState([]);
  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setComplianceDataa({
      ...complianceData,
      [name]: value,
    });
  };

  let services = [
    {
      label: " Ensure shipment is custom compliant",
      value: " Ensure shipment is custom compliant",
    },
    {
      label: "Make all necessary customs and additional agency declarations",
      value: "Make all necessary customs and additional agency declarations",
    },
    {
      label: "Make payment for the duties and taxes",
      value: "Make payment for the duties and taxes",
    },
    {
      label:
        "Follow up with the government authorities regarding the status of imported / Exported goods",
      value:
        "BOXFollow up with the government authorities regarding the status of imported / Exported goodsES",
    },
    {
      label: "Apply for import/export permits",
      value: "Apply for import/export permits",
    },
  ];

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  // const handleMultiChange = (event) => {
  //   if (event.target.name === "validatedBy") {
  //     setDocumentData({
  //       ...DocumentData,
  //       [event.target.name]: event.target.value,
  //     });
  //   }
  // const handleMultiChange = (e) => {
  //   const { value } = e.target;
  //   setSelectList(value);
  // };
  const handleMultiChange = (e, name) => {
    if (name === "service") {
      setComplianceDataa({
        ...complianceData,

        [e.target.name]: e.target.value,
      });
      console.log(complianceData, "hello");
    }
    //   else if(name === "inspectionType"){

    //      setComplianceDataa({
    //       ...complianceData,
    //       [e.target.name]: e.target.value,
    //     });

    //   }
  };

  // const handleRadio = (event) => {
  //   const target = event.target;
  //   const name = target.name;
  //   const value = target.value;

  //   setComplianceDataa({
  //     ...complianceData,
  //     [name]: value,
  //   });
  // };

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "loadingDate") {
        setDatePicker1(newDate1);
        // setComplianceDataa({
        //   ...complianceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "unloadingDate") {
        setDatePicker2(newDate1);
        // setComplianceDataa({
        //   ...complianceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setComplianceDataa({
        ...complianceData,
        [date]: dateString,
      });
    }
    // else if (e.target.name === "serviceType") {
    //   setComplianceDataa({
    //     ...complianceData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    else if (e.target.name === "countryFrom") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contactInfoShipper") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contactInfoBuyer") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requirement") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ProductName") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "productDescription") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ShipperName") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverAddress") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverName") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ShipperAddress") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerms") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "hsnOfCode") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyType") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "transactionResDetail") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "licenseReqDetail") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "serviceExportRegDetail") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyAmount") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requirement") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "serviceResDetail") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    }   else if (e.target.name === "incoterms") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "incoterm") {
      setComplianceDataa({
        ...complianceData,
        [e.target.name]: e.target.value,
      });
    }
  };
  sessionStorage.setItem("complianceData", JSON.stringify(complianceData));
  console.log("complianceData", complianceData);
  return (
    <>
      <Content padding="1% 2% 1.4% 2%">
        <div style={{ margin: "10px" }}>
          <OverLayTrigger value={COMPLIANCE_TRADE} />
        </div>
        <div style={{ margin: "10px 10px 0px 10px" }}>
          Trade Compliance Services Required for ?
        </div>

        <div className={classes.source} style={{ margin: "0px 10px" }}>
          <div
            style={{ margin: "0px 10px", width: "50%" }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="IMPORT"
              onChange={handleRadio}
              checked={complianceData.serviceType == "IMPORT"}
            />
            <label>IMPORT</label>
          </div>
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="EXPORT"
              onChange={handleRadio}
              checked={complianceData.serviceType == "EXPORT"}
            />
            <label>EXPORT</label>
          </div>
        </div>

        {/* <div className={classes.source} style={{ marginBottom: "4%" }}>
          <div className={classes.source} style={{ width: "40%" }}>
          <div style={{ width: "30%" }}>
                <Select
                  name="originType"
                  options={sourceOptions}
                  placeholder="From"
                  handleChange={handleChange}
                  value={complianceData.originType}
                />
              </div>
              {complianceData.originType && complianceData.originType === "PORT" ?(
              <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="origin"
                  placeholder="Port"
                  value={complianceData.origin}
                  handleChange={handleChange}
                />
              </div>
              ):(<div style={{ width: "70%" }}>
              <Input
                name="origin"
                placeholder="Country"
                value={complianceData.origin}
                handleChange={handleChange}
              />
            </div>)}
            </div>
            <div style={{ width: "20%" }}></div>
    
            <div className={classes.source} style={{ width: "40%" }}>
              <div style={{ width: "30%" }}>
                <Select
                  options={destinationOptions}
                  name="destinationType"
                  placeholder="To"
                  handleChange={handleChange}
                  value={complianceData.originType}
                />
              </div>

              {complianceData.originType && complianceData.originType === "PORT" ?(
                <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="destination"
                  placeholder="Port"
                  value={complianceData.destination}
                  handleChange={handleChange}
                />
              </div>
              ):(<div style={{ width: "70%" }}>
                  <Input
                name="destination"
                placeholder="Country"
                value={complianceData.destination}
                handleChange={handleChange}
              />
               </div>)}
               </div>
          </div>
          {/* cityFrom
          cityTo */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "15px 10px 10px 10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country  From*"
              handleChange={handleChange}
              value={complianceData.countryFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "15px 10px 10px 10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country To*"
              handleChange={handleChange}
              value={complianceData.countryTo}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}

        <div>
          <div>
            <div style={{ margin: "0px 10px" }}>
              <label>COMMODITY</label>
            </div>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "5px 10px 10px 10px", paddingRight: "20px" }}
              >
                <Select
                  options={Industry}
                  name="commodity"
                  placeholder="Product Category*"
                  handleChange={handleChange}
                  value={complianceData.commodity}
                  // readOnly={true}
                  //
                  //     name="memberlist"
                  //     selected={selectStatus}
                  //     placeholder="Status"
                  //     options={InvoiceStatus}
                  //
                  //   />
                />
              </div>
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="ProductName"
                placeholder="Product Name*"
                handleChange={handleChange}
                value={complianceData.ProductName}
                // readOnly={true}
              />
            </div>
            {/* : [Insert Product Name]
: [Insert Product Description]
: []
Shipper Address : [ Insert Address ] */}

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="productDescription"
                placeholder="Product Description*"
                handleChange={handleChange}
                value={complianceData.productDescription}
                // readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
              <Input
                name="hsnOfCode"
                placeholder="HSN code of Goods*"
                handleChange={handleChange}
                value={complianceData.hsnOfCode}
                // readOnly={true}
              />
            </div>
          </div>
          {/* <label>Shipment Details</label> */}
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="ShipperName"
                placeholder="Supplier Name*"
                handleChange={handleChange}
                value={complianceData.ShipperName}
                // readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="ReceiverName"
                placeholder="Buyer Name*"
                handleChange={handleChange}
                value={complianceData.ReceiverName}

                // readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="contactInfoShipper"
                placeholder="Supplier Contact Information*"
                handleChange={handleChange}
                value={complianceData.contactInfoShipper}
                // readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="contactInfoBuyer"
                placeholder="Buyer Contact Information*"
                handleChange={handleChange}
                value={complianceData.contactInfoBuyer}

                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="ShipperAddress"
                placeholder="Supplier Address*"
                handleChange={handleChange}
                value={complianceData.ShipperAddress}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="ReceiverAddress"
                placeholder="Buyer Address* "
                handleChange={handleChange}
                value={complianceData.ReceiverAddress}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={Currenciesr}
                name="currencyType"
                placeholder="Currency"
                value={complianceData.currencyType}
                handleChange={handleChange}
              />
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="currencyAmount"
                placeholder={`Estimated Cost of Goods or Services (${complianceData.currencyType})*`}
                handleChange={handleChange}
                value={complianceData.currencyAmount}
                // readOnly={true}
              />
            </div>
          </div>
          {/* <div className={classes.source}>
            <div style={{ width: "50%", marginBottom:"2%" }}>
            <Select
                  options={qtyUnitOptions}
                  name="weightType"
                  placeholder="Quantity"
                  value={complianceData.weightType}
                  handleChange={handleChange}
                />
         
              </div>
              <div style={{ width: "50%", marginBottom:"2%" }}>
              <Input
                  name="quantity"
                  placeholder="Quantity"
                  handleChange={handleChange}
                  value={complianceData.quantity}
                // readOnly={true}
                />
              </div>
              </div> */}

          <div className={classes.source}></div>
        </div>

        <div>
          {/* <label>INCOTERM 


</label> */}
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              // marginBottom: "2%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Select
              options={incoterm}
              name="incoterms"
              placeholder="Incoterm*"
              value={complianceData.incoterms}
              handleChange={handleChange}
            />
          </div>

          {complianceData?.incoterms === "OTHERS" ? (
            <div
              style={{
                width: "100%",
                // marginBottom: "2%",
                // marginLeft: "10px",
                margin: "10px",
              }}
            >
              <Input
                name="incoterm"
                placeholder="Please Enter Your Incoterm"
                value={complianceData.incoterm}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {/* <div style={{ width: "100%", margin: "10px", paddingRight: "20px" }}>
          <Select
            options={incoterm}
            name="incoterm"
            placeholder="Incoterm*"
            value={complianceData.incoterm}
            handleChange={handleChange}
          /> */}
          {/* <textarea
                  style={{ height: "7rem", width: "100%" , background :"Black"}}
                  type="textarea"
                  name="requirement"
                  value={complianceData.requirement}
                  onChange={(e) => handleChange(e, "requirement")}
                /> */}
        {/* </div> */}
        <hr style={{border:"2px solid white", margin:"20px 10px 5px 10px"}}></hr>
        <label style={{ margin: "10px 10px 0px 10px" }}>
          What type of Trade Compliance Services you need?*
        </label>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px", paddingRight: "20px" }}>
            <MultiSelect
              options={services}
              name="service"
              placeholder="Service *"
              handleChange={(e) => handleMultiChange(e, "service")}
              selected={complianceData.service}
              // readOnly={true}
              //
              //     name="memberlist"
              //     selected={selectStatus}
              //     placeholder="Status"
              //     options={InvoiceStatus}
              //
              //   />
            />
          </div>
        </div>

        <div style={{ margin: "10px" }}>
          <label>
            Give your own Trade Compliance Specific coverage Requirements
          </label>
        </div>
        <div style={{ width: "100%", margin: "10px", paddingRight: "20px" }}>
          <TextArea
            name="requirement"
            placeholder="Requirements *"
            value={complianceData.requirement}
            handleChange={handleChange}
          />

          {/* <Input
                  name="contactInfo"
                  placeholder="Contact Information"
                  handleChange={handleChange}
                  value={customInspectionData.contactInfo}
                // readOnly={true}
                /> */}
        </div>
        
        <hr style={{border:"2px solid white", margin:"20px 10px 5px 10px"}}></hr>
        <label style={{ margin: "10px 10px 0px 10px" }}>
          Is this purchase subject to any trade restrictions (i.e. sanctions,
          embargoes, or denial orders)?
        </label>

        <div
          className={classes.source}
          style={{ marginLeft: "10px", marginRight: "10px",  marginTop:"0px" }}
        >
          <div
            style={{ marginLeft: "10px", width: "50%", marginRight: "10px" }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceRes"
              value="YES"
              onChange={handleRadio}
              checked={complianceData.serviceRes == "YES"}
            />
            <label>YES</label>
          </div>
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceRes"
              value="NO"
              onChange={handleRadio}
              checked={complianceData.serviceRes == "NO"}
            />
            <label>NO</label>
          </div>
        </div>

        <div style={{ width: "100%",margin: "5px 10px 10px 10px", paddingRight: "20px" }}>
          <Input
            name="serviceResDetail"
            placeholder="If yes, please provide details"
            handleChange={handleChange}
            value={complianceData.serviceResDetail}
            // readOnly={true}
          />
        </div>

        {/* hell 1 */}

        <label style={{ margin: "10px 10px 0px 10px" }}>
          Is this product classified as dual-use or controlled under export
          control regulations (e.g. ITAR, EAR)?
        </label>

        <div
          className={classes.source}
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          <div
            style={{
              marginLeft: "10px",
              width: "50%",
              marginRight: "10px",
              marginBottom: "10px",
               marginTop:"0px" 
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceExportReg"
              value="YES"
              onChange={handleRadio}
              checked={complianceData.serviceExportReg == "YES"}
            />
            <label>YES</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
              marginBottom: "10px",
               marginTop:"0px" 
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceExportReg"
              value="NO"
              onChange={handleRadio}
              checked={complianceData.serviceExportReg == "NO"}
            />
            <label>NO</label>
          </div>
        </div>

        <div style={{ width: "100%",margin: "5px 10px 10px 10px", paddingRight: "20px" }}>
          <Input
            name="serviceExportRegDetail"
            placeholder="If yes, please provide details"
            handleChange={handleChange}
            value={complianceData.serviceExportRegDetail}
            // readOnly={true}
          />
        </div>
        {/* //hell 3 */}
        <label style={{margin: "10px 10px 0px 10px" }}>
          Is the supplier or any entity involved in the transaction listed on a
          restricted party list (e.g. OFAC, BIS, EU, etc.)?
        </label>

        <div
          className={classes.source}
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          <div
            style={{
              marginLeft: "10px",
              width: "50%",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop:"0px" 
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="transactionRes"
              value="YES"
              onChange={handleRadio}
              checked={complianceData.transactionRes == "YES"}
            />
            <label>YES</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop:"0px" 
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="transactionRes"
              value="NO"
              onChange={handleRadio}
              checked={complianceData.transactionRes == "NO"}
            />
            <label>NO</label>
          </div>
        </div>

        <div style={{ width: "100%", margin: "5px 10px 10px 10px", paddingRight: "20px" }}>
          <Input
            name="transactionResDetail"
            placeholder="If yes, please provide details"
            handleChange={handleChange}
            value={complianceData.transactionResDetail}
            // readOnly={true}
          />
        </div>

        {/* hell 4 */}
        <label style={{ margin: "10px 10px 0px 10px"}}>
          Is an export license required for this transaction?
        </label>

        <div
          className={classes.source}
          style={{ marginLeft: "10px", marginRight: "10px" }}
        >
          <div
            style={{
              marginLeft: "10px",
              width: "50%",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop:"0px" 
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="licenseReq"
              value="YES"
              onChange={handleRadio}
              checked={complianceData.licenseReq == "YES"}
            />
            <label>YES</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
              marginBottom: "10px",
              marginTop:"0px" 
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="licenseReq"
              value="NO"
              onChange={handleRadio}
              checked={complianceData.licenseReq == "NO"}
            />
            <label>NO</label>
          </div>
        </div>

        <div style={{ width: "100%", margin: "5px 10px 10px 10px", paddingRight: "20px" }}>
          <Input
            name="licenseReqDetail"
            placeholder="If yes, please provide details"
            handleChange={handleChange}
            value={complianceData.licenseReqDetail}
            // readOnly={true}
          />
        </div>
      </Content>
    </>
  );
};

export default TradeCompliance;
