import React, { useState } from "react";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import NewOpenTradeForm from "./NewOpenTradeForm";
import SearchResult from "./Table/SearchResult";

const Index = ({ hanndleCraateGroup }) => {
  const { instance, accounts } = useMsal();
  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");

  // const [activeToggle, setActiveToggle] = useState("");
  const [isSearchResult, setIsSearchResult] = useState(true);
  const [IsTradeFormOpen, setIsTradeFormOpen] = useState(false);

  return (
    <>
      {isSearchResult && (
        <>
          <SearchResult
            setIsTradeFormOpen={setIsTradeFormOpen}
            setIsSearchResult={setIsSearchResult}
            hanndleCraateGroup={hanndleCraateGroup}
          />
        </>
      )}

      {IsTradeFormOpen && (
        <NewOpenTradeForm
          setIsTradeFormOpen={setIsTradeFormOpen}
          setIsSearchResult={setIsSearchResult}
        />
      )}
    </>
  );
};

export default Index;
