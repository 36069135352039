import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import Wrapper from "components/UI/Layout/Wrapper";
import classes from "./tracking.module.css";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Input from "components/fields/Input";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { TRACKING } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";

const AddTrackingForm = ({ setAddTracking,memberuid }) => {
  const { instance, accounts } = useMsal();
  const [isLoading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    carrier_no: "",
    cntr_no: "",
    bl_no: "",
  });
  console.log(memberuid)
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
      memberUID:memberuid,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formData.carrier_no === "" &&
      formData.cntr_no === "" &&
      formData.bl_no === ""
    ) {
      Error("Please enter atleast one");
      return;
    } else {
      const options = {
        method: "POST",
        body: formData,
      };

      let URL = `${TRACKING}shipment`;
      setLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, options, response)
          .then((data) => {
            // setLoading(false);
            // let { message, status } = data;
            // if (message || status) {
            //   Error(message);
            // } else {
            //   setAddTracking(false);
            // }
          })
          .catch((error) => {
            Error();
          })
          .finally(() => {
            setLoading(false);
            setAddTracking(false);
          });
      });
    }
  };

  return (
    <Content padding="1.3% 2% 0.8% 2%">
      {!isLoading ? (
        <form onSubmit={(e) => handleSubmit(e)}>
          <h5>Add Tracking</h5>
          <div className={classes.FormDiv}>
            <div>
              <Input
                name="carrier_no"
                value={formData.carrier_no}
                placeholder="Carrier Number"
                handleChange={handleChange}
              />
            </div>
            <div>
              <Input
                name="cntr_no"
                value={formData.cntr_no}
                placeholder="Container Number"
                handleChange={handleChange}
              />
            </div>
            <div style={{margin:"0px"}}>
              <Input
                name="bl_no"
                value={formData.bl_no}
                placeholder="Bill of Lading"
                handleChange={handleChange}
              />
            </div>
            <div></div>
          </div>
          <ButtonDiv>
            <Button onClick={() => setAddTracking(false)}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </ButtonDiv>
        </form>
      ) : (
        <Loader />
      )}
    </Content>
  );
};

export default AddTrackingForm;
