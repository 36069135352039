import React, { useState, useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import {
  Card,
  Grid,
  Button,
  Badge,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";

import { FiMail } from "react-icons/fi";
import { AiOutlineBell } from "react-icons/ai";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles from "./trade.module.css";
import Style from "components/StyledCss/styled";
import General from "./General";
import SupplierDetails from "./SupplierDetails";
import Schedules from "./Schedules";
import { MdAdd } from "react-icons/md";
import Table from "components/table";
import { ScheduleColumn } from "./column";
import Buyer from "./buyer";
import Payment from "../NewPayment/index";

function Info(props) {
  const [type, setType] = useState("summary");
  const [header, setHeader] = useState("general");
  const [scheduleheader, setScheduleheader] = useState("schedules");
  const [schedule, setSchedule] = useState(false);
  const [milestone, setMilestone] = useState(false);
  const [buyer, setBuyer] = useState(false);
  const [msg, setMsg] = useState(false);

  const handleType = (e) => {
    setType(e);
    if (e !== "buyer") {
      setBuyer(false);
    }
  };

  const json = [
    { Status: "Open" },
    { "Funding Details": "PENDING" },
    { "Contract Date": "20/08/2022" },
    { "Buyer Consent": "PENDING" },
    { Value: "Rs. 12 M" },
    { "Last Activity": "PO Docs Uploaded" },
  ];

  const handleHeader = (e, value) => {
    setHeader(value);
  };

  const handleScheduleHeader = (value) => {
    setScheduleheader(value);
  };
  const component = () => {
    switch (header) {
      case "general":
        return (
          <General cnfSch={props.enableSchedulePayment?.confirmSchedule} />
        );
      case "supplier_details":
        return <SupplierDetails />;
    }
  };

  const scheduleComp = () => {
    switch (scheduleheader) {
      case "schedules":
        return (
          <Schedules
            enableSchedulePayment={
              props.Upload?.enableSchedulePayment ||
              props.enableSchedulePayment?.confirmSchedule
            }
          />
        );
    }
  };

  useEffect(() => {
    if (props.enableSchedulePayment?.confirmSchedule) {
      setMilestone(true);
      setSchedule(true);
    }
  });

  const enableBuy = () => {
    setBuyer(false);
    setMsg(true);
    setType("none");
  };

  const api_response = [
    {
      serial_number: "1",
      milestone: "ICICI",
      document: "Aadhar",
      payment: "20%",
      amount: "1000",
      status: "Active",
      action: true,
    },
    {
      serial_number: "2",
      milestone: "HDFC",
      document: "license",
      payment: "30%",
      amount: "20000",
      status: "InProgress",
      action: true,
    },
  ];

  const Function = {
    enableBuy,
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          margin: "30px",
          color: "#0067A0",
          alignItems: "center",
        }}
      >
        <BiArrowBack size={20} />
        <h4
          onClick={() => props.Upload.setTade()}
          style={{ marginLeft: "20px" }}
        >
          Back To List
        </h4>
      </div>
      <Card
        style={{
          backgroundColor: "#343434",
          padding: "25px 25px 15px 25px",
          borderRadius: "5px",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={8}>
            <h4 style={{ color: "white" }}>PO # -T-00 1134567 </h4>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Badge badgeContent={4} color="error">
              <FiMail size={35} color="#A5A5A5" />
            </Badge>
            <div style={{ paddingLeft: "20px" }}>
              <Badge badgeContent={4} color="error">
                <AiOutlineBell size={35} color="#A5A5A5" />
              </Badge>
            </div>
          </Grid>
        </Grid>
      </Card>
      <br></br>
      <Card
        style={{
          backgroundColor: "black",
          padding: "25px 25px 15px 25px",
          borderRadius: "5px",
        }}
      >
        <button
          type="button"
          className={styles.collapsible}
          onClick={() => handleType("summary")}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: type === "summary" ? "#42A5F5" : "white",
              }}
            >
              Summary
            </b>
            {type == "summary" ? (
              <FiChevronUp size={25} color="#42A5F5" />
            ) : (
              <FiChevronDown size={25} />
            )}
          </div>
        </button>
        {type === "summary" && (
          <Card
            style={{
              backgroundColor: "#343434",
            }}
          >
            <Grid container style={{ padding: "15px 0px 0px 0px" }}>
              {json.map((f) => (
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Card
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      background: "#1E1E1E",
                      borderRadius: "5px",
                      margin: "0px",
                      padding: "0px 20px",
                    }}
                  >
                    <h4
                      style={{
                        color: "white",
                        margin: "15px 0px",
                        textAlign: "end",
                      }}
                    >
                      {" "}
                      {Object.keys(f)}{" "}
                    </h4>
                    <h4 style={{ margin: "15px 0px", color: "white" }}>
                      {" "}
                      {Object.values(f)}{" "}
                    </h4>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        )}
        <br></br>
        <br></br>
        <button
          type="button"
          className={styles.collapsible}
          onClick={() => handleType("details")}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: type === "details" ? "#42A5F5" : "white",
              }}
            >
              Details
            </b>
            {type == "details" ? (
              <FiChevronUp size={25} color="#42A5F5" />
            ) : (
              <FiChevronDown size={25} />
            )}
          </div>
        </button>
        {type === "details" && (
          <Style>
            <div>
              {schedule ? (
                <h4
                  style={{
                    color: "#33C30C",
                    background: "#343434",
                    padding: "30px 0px 20px 20px",
                  }}
                >
                  PO for Open Trade updated successfully, create Schedule below.
                </h4>
              ) : (
                <div className="main">
                  <div className="navbar">
                    <div
                      onClick={(e) => handleHeader(e, "general")}
                      style={
                        header === "general"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      General
                    </div>
                    <div
                      onClick={(e) => handleHeader(e, "supplier_details")}
                      style={
                        header === "supplier_details"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Supplier Details
                    </div>
                    <div
                      onClick={(e) => handleHeader(e, "buyer_details")}
                      style={
                        header === "buyer_details"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Buyer Details
                    </div>
                    <div
                      onClick={(e) => handleHeader(e, "export__details")}
                      style={
                        header === "export__details"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Export Details
                    </div>
                    <div
                      onClick={(e) => handleHeader(e, "miscellaneous")}
                      style={
                        header === "miscellaneous"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Miscellaneous
                    </div>
                  </div>
                  <hr />
                  {header ? component() : ""}
                </div>
              )}
            </div>
          </Style>
        )}

        <br></br>
        <br></br>
        {schedule && (
          <button
            type="button"
            className={styles.collapsible}
            onClick={() => handleType("schedule")}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: type === "schedule" ? "#42A5F5" : "white",
                }}
              >
                Schedule
              </b>
              {type == "schedule" ? (
                <FiChevronUp size={25} color="#42A5F5" />
              ) : (
                <FiChevronDown size={25} />
              )}
            </div>
          </button>
        )}
        {(type === "schedule" ||
          props.enableSchedulePayment?.confirmSchedule) &&
          !buyer && (
            <Style>
              <div>
                <div className="main">
                  <div className="navbar">
                    <div
                      onClick={() => handleScheduleHeader("schedules")}
                      style={
                        scheduleheader === "schedules"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Schedules
                    </div>
                    <div
                      onClick={() => handleScheduleHeader("change_state")}
                      style={
                        scheduleheader === "change_state"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Change State
                    </div>
                    <div
                      onClick={() => handleScheduleHeader("documents")}
                      style={
                        scheduleheader === "documents"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Documents
                    </div>
                    <div
                      onClick={() => handleScheduleHeader("history")}
                      style={
                        scheduleheader === "history"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      History
                    </div>
                    <div
                      onClick={() => handleScheduleHeader("alerts")}
                      style={
                        scheduleheader === "alerts"
                          ? {
                              color: "#42A5F5",
                              textDecoration: "underline",
                              textUnderlineOffset: "10px",
                              textAlign: "center",
                            }
                          : { textAlign: "center", color: "white" }
                      }
                    >
                      Alerts
                    </div>
                  </div>
                  <hr />
                  {scheduleheader === "schedules" && !milestone && (
                    <>
                      <br></br>
                      <br></br>

                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setMilestone(true)}
                      >
                        <MdAdd size={25} />
                        Create Schedule
                      </Button>
                    </>
                  )}
                  {milestone &&
                  scheduleheader &&
                  !props.enableSchedulePayment?.confirmSchedule
                    ? scheduleComp()
                    : ""}
                </div>
              </div>
            </Style>
          )}

        {schedule && props.enableSchedulePayment?.confirmSchedule && !buyer && (
          <Style>
            <div>
              <div className="main">
                <div className={styles.wrapper}>
                  <div className={styles.tableContainer}>
                    {Array.isArray(api_response) ? (
                      <Table columns={ScheduleColumn} data={api_response} />
                    ) : (
                      <p>No Records</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "40px 25px 20px 0px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => console.log(e)}
                style={{ visibility: "hidden" }}
              >
                Update PO Details
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => console.log(e)}
              >
                Update Details
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => console.log(e)}
              >
                Update Milestones
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => console.log(e)}
              >
                Cancel
              </Button>
            </div>
          </Style>
        )}
        {buyer && (
          <>
            <br></br>
            <br></br>
            <button
              type="button"
              className={styles.collapsible}
              onClick={() => handleType("buyer")}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <b
                  style={{
                    fontSize: "20px",
                    fontWeight: "700",
                    color: type === "buyer" ? "#42A5F5" : "white",
                  }}
                >
                  Link Buyer (collapsible)
                </b>
                {type == "buyer" ? (
                  <FiChevronUp size={25} color="#42A5F5" />
                ) : (
                  <FiChevronDown size={25} />
                )}
              </div>
            </button>
          </>
        )}

        {type === "buyer" && <Buyer Function={Function} />}
      </Card>
      {type === "details" &&
        !schedule &&
        !props.enableSchedulePayment?.confirmSchedule && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              margin: "40px 25px 20px 0px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => setSchedule(true)}
            >
              Update PO Details
            </Button>
          </div>
        )}
      {schedule &&
        props.enableSchedulePayment?.confirmSchedule &&
        !buyer &&
        !msg && (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "40px 25px 20px 0px",
              }}
            >
              <h4 style={{ color: "#33C30C" }}>
                Would you like to request for an escrow arrangement?
              </h4>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="yes"
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        color: "#218EEB",
                        "&.Mui-checked": {
                          color: "#218EEB",
                        },
                      }}
                    />
                  }
                  label="No"
                  style={{ color: "#33C30C", marginRight: "50px" }}
                />
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        color: "#218EEB",
                        "&.Mui-checked": {
                          color: "#218EEB",
                        },
                      }}
                    />
                  }
                  label="Yes"
                  style={{ color: "#33C30C" }}
                />
              </RadioGroup>
            </div>
            <div style={{ textAlign: "end" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setBuyer(true)}
              >
                Search Buyer
              </Button>
            </div>
          </div>
        )}
      {msg && (
        <h4 style={{ color: "#33C30C", margin: "30px 0px" }}>
          Your Open Trade request has been sent for validation to Buyer
        </h4>
      )}

      {/* <Payment /> */}
    </>
  );
}

export default Info;
