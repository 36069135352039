import React, { useState, useEffect } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import styles from "./documents.module.css";
import { useParams } from "react-router-dom";
import { documentURL, docGroupURL } from "services/BaseURLInstance";
import Input from "components/fields/Input";
import ReactSelect from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import Content from "components/UI/Layout/Content";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import Form from "react-bootstrap/Form";

import MultiSelect from "components/UI/Input/MultiSelect";

import { FindCompanyName } from "utils/helper";
import { Success, Error } from "components/notifications/responseHandle";

import { AiOutlineDelete } from "react-icons/ai";
import { BiEdit } from "react-icons/bi";
import { DocumentPropertiesValdations } from "utils/Validations/Document";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { DocumentProperties } from "utils/Section/FormInfo";
import { TradeDocumentSelect } from "utils/DropDown/TradeDocumentGroup";
import { CommonDocumentSelect } from "utils/DropDown/CommonDocument";

export default ({ data1, data2, template, response, handleHeader }) => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  let { id } = useParams();
  const [DatePicker1, setDatePicker1] = useState(new Date());
  const [DatePicker2, setDatePicker2] = useState(new Date());
  const [companyList, setCompanyList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedId, setSelectedId] = useState("");
  const [selectList, setSelectList] = useState([]);

  const [DocumentData, setDocumentData] = useState({
    public: "",
    validityStartDate: new Date(),
    validityEndDate: new Date(),
    validatedBy: [],
    docIssuer: "",
  });

  const [DocumentGroupData, setDocumentGroupData] = useState({
    docCategory: "",
    docSubCategory: "",
    createdBy: "",
    tags: ["DocumnetGroup/Document/Tag", "DocumnetGroup/Document/Tag"],
  });

  const { Category, SubCategory } = CommonDocumentSelect(
    DocumentGroupData && DocumentGroupData.docCategory
  );

  useEffect(() => {
    setDocumentData({
      ...DocumentData,
      name: data1.name,
      public: data1.public,
      file: data1.file,
      owner: data1.owner,
      validatedBy: data1.validatedBy
        ? data1.validatedBy.map((res) => {
            return {
              label: FindCompanyName(response && response, res),
              value: res,
            };
          })
        : null,
      validityStartDate: data1.validityStartDate || new Date().toISOString(),
      validityEndDate: data1.validityEndDate || new Date().toISOString(),
    });

    setDocumentGroupData({
      ...DocumentGroupData,
      memberId: data2[0].memberId,
      docReferenceId: data2[0].docReferenceId,
      documentName: data2[0].documentName,
      docCategory: data2[0].docCategory,
      docSubCategory: data2[0].docSubCategory,
      tags: data2[0].tags,
      createdBy: data2[0].createdBy,
      updateBy: data2[0].updateBy,
      ownerId: data2[0].ownerId,
      participantsToSign: data2[0].participantsToSign,
      participantsToShare: data2[0].participantsToShare,
      referenceData: data2[0].referenceData,
    });
  }, [data1, data2]);

  useEffect(() => {
    if (response && response) {
      let tempList =
        response &&
        response.filter((item) => {
          if (item.memberType !== "PERSON" || item.memberUID !== companyId) {
            return { label: item.memberName, value: item.memberUID };
          }
        });

      let filter =
        tempList &&
        tempList.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });
      setCompanyList(filter);
    }
  }, [response]);

  const handleDocumentChange = (event, date) => {
    if (date === "validityStartDate" || date === "validityEndDate") {
      let newDate = new Date(event);
      let dateString = newDate.toISOString();

      let newDate1 = moment(new Date(event)).format("YYYY-MM-DD");

      if (date === "validityStartDate") {
        setDatePicker1(newDate1);
      }
      if (date === "validityEndDate") {
        setDatePicker2(newDate1);
      }

      setDocumentData({
        ...DocumentData,
        [date]: dateString,
      });
    } else {
      setDocumentData({
        ...DocumentData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleMultiChange = (event) => {
    if (event.target.name === "validatedBy") {
      setDocumentData({
        ...DocumentData,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === "docIssuer" || event.target.name === "public") {
      setDocumentData({
        ...DocumentData,
        [event.target.name]: event.target.value.value,
      });
    }

    if (
      event.target.name === "docCategory" ||
      event.target.name === "docSubCategory"
    ) {
      setDocumentGroupData({
        ...DocumentGroupData,
        [event.target.name]: event.target.value.value,
      });
    }
  };

  const handleDocumentSubmit = async (e) => {
    e.preventDefault();
    var Payload = new FormData();
    let ValidatedArray = [];
    DocumentData.validatedBy &&
      DocumentData.validatedBy.map((select) => {
        ValidatedArray.push(`${select.value}`);
      });
    Payload.append("public", DocumentData.public);
    Payload.append("validityStartDate", DocumentData.validityStartDate);
    Payload.append("validityEndDate", DocumentData.validityEndDate);
    Payload.append("validatedBy", ValidatedArray);
    Payload.append("docIssuer", DocumentData.docIssuer);
    Payload.append("file", new Blob(["test text"], { type: "text/plain" }), "");
    setIsLoading(true);

    const requestOptions = {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + idToken,

        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },

      body: Payload,
    };
    await fetch(`${documentURL}documents/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          Success("Document Updated Successfully");
        }
      });
  };

  const handleDocumentGroupSubmit = async (e) => {
    e.preventDefault();
    let temporaryList = [];
    selectList &&
      selectList.map((select) => {
        temporaryList.push(`${select}`);
      });
    DocumentGroupData.tags = temporaryList;
    DocumentGroupData.publicView = DocumentData?.public;
    setIsLoading(true);
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(DocumentGroupData),
    };
    await fetch(
      `${docGroupURL}bff/documentsGroups/documentUpdate?documentId=${id}&memberUID=${companyId}&template=${template}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          Success("Document Updated Successfully");
        }
      });
    // }
  };

  const PublicDropDown = [
    {
      label: "True",
      value: true,
    },
    {
      label: "False",
      value: false,
    },
  ];

  const handleTagChange = (e) => {
    setSelectedId(e.target.value);
  };

  const handleSelectTag = (e) => {
    e.preventDefault();

    if (selectedId === "") {
      alert("Please Select Tags");
      Error("Please Select Tags");
      return;
    }
    const findId = selectList.filter((find, index) => {
      return find === selectedId;
    });

    if (findId.length === 0) {
      setSelectList([...selectList, selectedId]);
    } else {
      alert("Please Select Different Tags");
    }
    setSelectedId("");
  };

  const handleTagEdit = (tag) => {
    setSelectedId(tag);
    const findId = selectList.filter((find, index) => {
      return find !== tag;
    });
    setSelectList(findId);
  };

  const handleTagDelete = (tag) => {
    const findId = selectList.filter((find, index) => {
      return find !== tag;
    });
    setSelectList(findId);
  };

  return (
    <Content marginTop="0.8%" padding="1% 2%">
      {isLoading ? (
        <span>
          <span>Loading...</span>
          <Loader />
        </span>
      ) : (
        <>
          {data1 && data2 && (
            <Form
              onSubmit={(e) => {
                handleDocumentGroupSubmit(e);
                handleDocumentSubmit(e);
              }}
              className={styles.Form}>
              <OverLayTrigger value={DocumentProperties} />

              <div className={styles.InputDiv}>
                <div style={{ width: "50%", margin: "10px" }}>
                  <Input
                    readOnly={DocumentData.owner !== companyId ? true : false}
                    name="name"
                    placeholder="Document Name"
                    value={DocumentData.name}
                    handleChange={handleDocumentChange}
                  />
                </div>
                <div style={{ width: "50%", margin: "10px" }}>
                  <Input
                    name="owner"
                    placeholder="Document Owner"
                    value={
                      DocumentData.owner && response
                        ? FindCompanyName(response, DocumentData.owner)
                        : null
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className={styles.InputDiv}>
                <div style={{ width: "50%", margin: "10px" }}>
                  <MultiSelect
                    isDisabled={DocumentData.owner !== companyId ? true : false}
                    options={companyList}
                    name="docIssuer"
                    placeholder="Document Issuer"
                    value={{
                      label: FindCompanyName(response, DocumentData.docIssuer),
                      value: DocumentData.docIssuer,
                    }}
                    handleChange={handleMultiChange}
                  />
                </div>
                <div style={{ width: "50%", margin: "10px" }}>
                  <Input
                    name="createdBy"
                    placeholder="Document Created By DOC"
                    value={
                      DocumentGroupData.createdBy && response
                        ? FindCompanyName(response, DocumentGroupData.createdBy)
                        : null
                    }
                    readOnly={true}
                  />
                </div>
              </div>
              <div className={styles.InputDiv}>
                <div style={{ width: "50%", margin: "10px" }}>
                  <MultiSelect
                    isDisabled={DocumentData.owner !== companyId ? true : false}
                    options={Category}
                    name="docCategory"
                    placeholder="Document Category DOC"
                    value={{
                      label: DocumentGroupData.docCategory,
                      value: DocumentGroupData.docCategory,
                    }}
                    handleChange={handleMultiChange}
                  />
                </div>
                <div style={{ width: "50%", margin: "10px" }}>
                  <MultiSelect
                    isDisabled={DocumentData.owner !== companyId ? true : false}
                    options={SubCategory}
                    name="docSubCategory"
                    placeholder="Document Sub-Category DOC"
                    value={{
                      label: DocumentGroupData.docSubCategory,
                      value: DocumentGroupData.docSubCategory,
                    }}
                    handleChange={handleMultiChange}
                  />
                </div>
              </div>
              <div className={styles.InputDiv}>
                <div style={{ width: "50%", margin: "10px" }}>
                  <MultiSelect
                    isDisabled={DocumentData.owner !== companyId ? true : false}
                    isMulti={true}
                    name="validatedBy"
                    placeholder="Validated By"
                    value={DocumentData.validatedBy}
                    options={companyList}
                    handleChange={handleMultiChange}
                  />
                </div>

                <div style={{ width: "50%", margin: "10px" }}>
                  <ReactSelect
                    readOnly={DocumentData.owner !== companyId ? true : false}
                    options={PublicDropDown}
                    name="public"
                    placeholder="Public"
                    value={DocumentData.public}
                    handleChange={(e) => handleDocumentChange(e, "public")}
                  />
                </div>
              </div>
              <div className={styles.InputDiv}>
                <div style={{ width: "50%", margin: "10px" }}>
                  <DateInput
                    readOnly={DocumentData.owner !== companyId ? true : false}
                    name="validityStartDate"
                    placeholder="Validity Start Date"
                    value={DatePicker1}
                    handleChange={(e) =>
                      handleDocumentChange(e, "validityStartDate")
                    }
                  />
                </div>
                <div style={{ width: "50%", margin: "10px" }}>
                  <DateInput
                    readOnly={DocumentData.owner !== companyId ? true : false}
                    name="validityEndDate"
                    placeholder="Validity End Date"
                    value={DatePicker2}
                    handleChange={(e) =>
                      handleDocumentChange(e, "validityEndDate")
                    }
                  />
                </div>
              </div>

              <div>
                <div
                  className={styles.selectedListDiv}
                  style={{ margin: "0px" }}>
                  {selectList &&
                    selectList.map((list, index) => (
                      <div key={index} className={styles.selectedListRow}>
                        <div>
                          <span>
                            {index + 1}. {list}
                          </span>
                        </div>
                        <div className={styles.selectedListIcon}>
                          <span onClick={() => handleTagEdit(list)}>
                            <BiEdit size={30} />
                          </span>
                          <span onClick={() => handleTagDelete(list)}>
                            <AiOutlineDelete size={30} />
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="d-flex" style={{ margin: "10px" }}>
                  <Input
                    readOnly={DocumentData.owner !== companyId ? true : false}
                    name="tag"
                    placeholder="Enter New Tags"
                    value={selectedId}
                    handleChange={handleTagChange}
                  />
                  {selectedId !== "" && (
                    <Button onClick={(e) => handleSelectTag(e)}>
                      Select Tag
                    </Button>
                  )}
                </div>
              </div>
              <ButtonDiv>
                <Button onClick={(e) => handleHeader(e, "documentpreview")}>
                  Cancel
                </Button>
                <Button
                  disabled={DocumentData.owner !== companyId ? true : false}
                  type="submit">
                  Save
                </Button>
              </ButtonDiv>
            </Form>
          )}
        </>
      )}
    </Content>
  );
};
