let GlobalDocument = [
  {
    docCategory: "COMPANY",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "CoI",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "PANCard",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "TAN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "GSTIN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "ExportLicense",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "COMPANY",
        docSubCategory: "ImportLicense",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "KYB",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "KYB_ASSESSMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "CREDIT_ASSESSMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYB",
        docSubCategory: "AADHAR",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "TRADE",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE",
        docSubCategory: "INVOICE",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE",
        docSubCategory: "PO",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "TRADE",
        docSubCategory: "BL",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "FINANCE",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE",
        docSubCategory: "ANNUAL_STATEMENT",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "FINANCE",
        docSubCategory: "P&L",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "ACCREDITATIONS",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "ACCREDITATIONS",
        docSubCategory: "ACCREDITATIONS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "OTHERS",
    template: "Global",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "OTHERS",
        docSubCategory: "OTHERS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
];

export const GlobalDocumentSelect = (name) => {
  let Category = GlobalDocument.map((item) => ({
    label: item.docCategory,
    value: item.docCategory,
  }));

  const Empty = [];
  let temp = GlobalDocument.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });
  let SubCategory =
    temp &&
    temp[0] &&
    temp[0].documents.map((item) => ({
      label: item.docSubCategory,
      value: item.docSubCategory,
    }));

  if (name !== null) {
    return { Category, SubCategory };
  } else {
    return { Category, Empty };
  }
};

export const GlobalDocumentSubCatogery = (name) => {
  let temp = GlobalDocument.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });
  let SubCategory =
    temp &&
    temp[0] &&
    temp[0].documents.map((item) => ({
      label: item.docSubCategory,
      value: item.docSubCategory,
    }));
  return SubCategory;
};
