import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Invoice from "./New-ServiceRequest";
import Overview from "./New-Overview";
import classes from "./service.module.css";
import Service from "./New-Service";
import ServiceRequisition from "./services/serviceRequisition/serviceRequisition";
import Servicedeal from "./New-ServiceDeal/index";
import DealDetails from "./New-ServiceDeal/DealDetail";
import RequestDetails from "./New-ServiceRequest/RequestDetails";
import { UseRequest } from "utils/API/Request.js";
import { CheckPermission } from "utils/Constants/RBAC";

import Navbar from "../../components/UI/Navbar/Navbar";
import Details from "./New-Service/Category/Freights";
import Category from "./New-Service/Category";
import { getIdTokenAsync } from "utils/authUtil";

export default ({ toggle, isOpen, toggleChat, hanndleCraateGroup }) => {
  const tabs = [
    { name: "Overview", value: "overview" },
    { name: "Services", value: "service" },
    { name: "Service Requests", value: "service-requests" },
    { name: "Service Deals", value: "service-deals" },
  ];

  return (
    <div>
      <div>
        <div className={classes.Wrapper}>
          <Navbar tabs={tabs} />

          <div className={classes.Container}>
            <Routes>
              <Route exact path="/" element={<Navigate to="overview" />} />
              <Route exact path="overview" element={<Overview />} />
              <Route
                exact
                path="service-requests"
                element={<Invoice hanndleCraateGroup={hanndleCraateGroup} />}
              />
              <Route
                exact
                path="service-requests/:id"
                element={
                  <RequestDetails hanndleCraateGroup={hanndleCraateGroup} />
                }
              />
              <Route
                exact
                path="service"
                element={
                  <Service
                    hanndleCraateGroup={hanndleCraateGroup}
                    toggle={toggle}
                    isOpen={isOpen}
                    toggleChat={toggleChat}
                  />
                }
              />
              <Route
                exact
                path="service/requisition"
                element={<ServiceRequisition />}
              />
              <Route
                exact
                path="service-deals"
                element={
                  <Servicedeal hanndleCraateGroup={hanndleCraateGroup} />
                }
              />
              <Route
                exact
                path="service-deals/:id"
                element={
                  <DealDetails hanndleCraateGroup={hanndleCraateGroup} />
                }
              />
               <Route
                exact
                path="service-deals/*"
                element={
                  <DealDetails hanndleCraateGroup={hanndleCraateGroup} />
                }
              />
              <Route
                exact
                path="service/requisition/details"
                element={<Details />}
              />
              <Route exact path="service/category" element={<Category />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};
