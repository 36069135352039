import React from "react";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import {IoIosArrowUp,IoIosArrowDown} from "react-icons/io";
import TableData from "../financing/tableData";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";

import { SendBirdProvider as SBProvider } from "sendbird-uikit";

const Styles = styled.div`
    
    .table{
        padding:16px;
        background-color:#000000;
        border-radius: 5px;
    }
    
   .header{
       width:100%;
       display:flex;
       background-color: #343434; 
       border-radius: 5px;

   }
   
   .header> div{
       display:flex;
       justify-content: center;
       align-items: center;
       height:50.47px;
       width:20%;
       border-left: .1px solid white;
       font-family: Roboto;
       font-style: Medium;
        font-size: 18px;
        line-height: 24px;
        line-height: 114%;
        align: left;
        vertical-align: top;
        paragraph-spacing: 32px;
        border-width: .1px;
        overflow:hidden;
   }
`;

export default ({ data ,financerMemberUID, companyList, hanndleCraateGroup}) => {
    const { accounts } = useMsal();
    const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
    //const USER_ID="zark"
    const memberId = getClaim(accounts, "member_id");
    const chatToken = getClaim(accounts, "chat_token");
  return (
    <Styles>
               <div className="table">
                    <div className="header">
                    <div style={{borderLeft: "none",borderTop:"none",paddingLeft:"10px"}}>Finance Id</div>
                        <div>Financer</div>
                        <div >Offer Details</div>
                        <div >Status</div>
                        <div >Last Activity Date</div>
                        <div >Action</div>
                    </div>
                    <div>
                        {data.map((item)=>{
                            return <TableData data={item} 
                            hanndleCraateGroup={hanndleCraateGroup}
                            financerMemberUID={financerMemberUID} 
                            companyList={companyList}/>
                        })}
                    </div>
               </div>
        </Styles>
)}