import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import { onBoardingURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import styles from "../companyDetails.module.css";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { MdOutlineCancel } from "react-icons/md";
import { HiBadgeCheck } from "react-icons/hi";
import { Error } from "components/notifications/responseHandle";

const OnBoarding = () => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [response, setResponse] = useState(null);
  const { instance, accounts } = useMsal();
  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${onBoardingURL}onboarding/businessTrustCard/${companyId}`,
          null,
          response
        )
          .then((data) => {
            let { message, status } = data;
            if (message || status) {
              Error(message);
              setIsError(message);
            } else {
              setResponse(data);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <Content>
          Business Trust Card Loading ...
          <Loader />
        </Content>
      ) : (
        <>
          <div className={styles.performanceCard} style={{margin:"0.9% 0% 0.2% 0%", padding:"1% 2%"}}>
            <div className={styles.performanceHead}>
              <div className={styles.title1}>
                <span className={styles.iconDIv} style={{ fontSize: "16px", fontWeight: "bold" }}>Business Trust Card</span>
                {response && (
                  <>
                    {response.businessIdentity === "VERIFICATION_SUCCESS" ? (
                      <HiBadgeCheck
                        style={{
                          color: "#4CAF50",
                        }}
                        size={24}
                      />
                    ) : (
                      <MdOutlineCancel
                        style={{
                          color: "rgba(255, 161, 0, 1)",
                        }}
                        size={20}
                      />
                    )}
                  </>
                )}
              </div>
              <div className={styles.title2}>Business Rating Scale Icon</div>
            </div>

            <div className={styles.performanceBodyNew} style={{margin:"1% 0%"}}>
              <div className={styles.performanceGrid}>
                <div>
                  <div className={styles.performanceValue}>
                    <div>
                      <div className={styles.title2}>Business Identity</div>
                      <div className={styles.title3}>
                        Verification status of the business identity
                      </div>
                    </div>
                    <div>
                      {response && (
                        <>
                          {response.businessIdentity ===
                          "VERIFICATION_SUCCESS" ? (
                            <HiBadgeCheck
                              style={{
                                color: "#4CAF50",
                              }}
                              size={24}
                            />
                          ) : (
                            <MdOutlineCancel
                              style={{
                                color: "rgba(255, 161, 0, 1)",
                              }}
                              size={20}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.trustValue}>
                    <div>
                      <div className={styles.title2}>Financial Crime Check</div>
                      <div className={styles.title3}>
                        AML and Fraud Check screen scan indicator
                      </div>
                    </div>
                    <div>
                      {response && (
                        <>
                          {response.financialCrimeCheck ===
                          "MONEY_LAUNDERING_NEGATIVE" ? (
                            <MdOutlineCancel
                              style={{
                                color: "rgba(255, 161, 0, 1)",
                              }}
                              size={20}
                            />
                          ) : (
                            <HiBadgeCheck
                              style={{
                                color: "#4CAF50",
                              }}
                              size={24}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.performanceValue}>
                    <div>
                      <div className={styles.title2}>Credit Assessment</div>
                      <div className={styles.title3}>
                        TradXLink Credit assessment rating
                      </div>
                    </div>
                    <div className={styles.colorBlue}>
                      {response &&
                        response.creditAssessment &&
                        response.creditAssessment}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.trustValue}>
                    <div>
                      <div className={styles.title2}>Sanctions List</div>
                      <div className={styles.title3}>
                        Sanctions Check hit indicator
                      </div>
                    </div>
                    <div>
                      {response && (
                        <>
                          {response.sanctionsListCheck === "NOT_VERIFIED" ? (
                            <MdOutlineCancel
                              style={{
                                color: "rgba(255, 161, 0, 1)",
                              }}
                              size={20}
                            />
                          ) : (
                            <HiBadgeCheck
                              style={{
                                color: "#4CAF50",
                              }}
                              size={24}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.performanceValue}>
                    <div>
                      <div className={styles.title2}>Ability To Pay</div>
                      <div className={styles.title3}>
                        Derived on multiple factors
                      </div>
                      <div className={styles.title3}>
                        Revenue, Expenses, Profitability, Cash Flow, Assets &
                        Liabilities
                      </div>
                    </div>
                    <div>
                      {response && (
                        <>
                          <div className={styles.colorBlue}>
                            {response &&
                            response.abilityToPay &&
                            response.abilityToPay.rating !== "NOT_AVAILABLE"
                              ? response.abilityToPay.rating
                              : "NA"}
                          </div>
                          {/* <div className={styles.title3}>
                            {response &&
                            response.abilityToPay &&
                            response.abilityToPay.currency !== "NOT_AVAILABLE"
                              ? response.abilityToPay.currency
                              : ""}
                            {response &&
                            response.abilityToPay &&
                            response.abilityToPay.profitPostExpenses !==
                              "NOT_AVAILABLE" ? (
                              <span>
                                {response.abilityToPay.profitPostExpenses} 'PA'
                              </span>
                            ) : (
                              ""
                            )}
                          </div> */}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  <div className={styles.trustValue}>
                    <div>
                      <div className={styles.title2}>Media Check</div>
                      <div className={styles.title3}>
                        Media check indicator across the open media
                      </div>
                    </div>
                    <div>
                      {response && (
                        <>
                          {response.mediaCheck === "NOT_VERIFIED" ? (
                            <MdOutlineCancel
                              style={{
                                color: "rgba(255, 161, 0, 1)",
                              }}
                              size={20}
                            />
                          ) : (
                            <HiBadgeCheck
                              style={{
                                color: "#4CAF50",
                              }}
                              size={24}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className={styles.performanceValue}>
                    <div>
                      <div className={styles.title2}>Legal</div>
                      <div className={styles.title3}>
                        Legal report indicator in home country
                      </div>
                    </div>
                    <div className={styles.colorBlue}>
                      {response && (
                        <>
                          {response.legalCheck === "NOT_VERIFIED" ? (
                            <MdOutlineCancel
                              style={{
                                color: "rgba(255, 161, 0, 1)",
                              }}
                              size={20}
                            />
                          ) : (
                            <HiBadgeCheck
                              style={{
                                color: "#4CAF50",
                              }}
                              size={24}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className={styles.trustValue}>
                    <div>
                      <div className={styles.title2}>
                        Corporate Compliance Check
                      </div>
                      <div className={styles.title3}>
                        Corporate Compliance Check
                      </div>
                    </div>
                    <div>
                      {response && (
                        <>
                          {response.corporateComplianceCheck &&
                          response.corporateComplianceCheck
                            .corporateComplianceCheckResult &&
                          response.corporateComplianceCheck
                            .corporateComplianceCheckResult ===
                            "CORPORATE_COMPLIANCE_NEGATIVE" ? (
                            <MdOutlineCancel
                              style={{
                                color: "rgba(255, 161, 0, 1)",
                              }}
                              size={20}
                            />
                          ) : (
                            <HiBadgeCheck
                              style={{
                                color: "#4CAF50",
                              }}
                              size={24}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className={styles.performanceValue}>
                    <div>
                      <div className={styles.title2}>Trusted Partners</div>
                      <div className={styles.title3}>
                        Partners who have endorsed this member
                      </div>
                    </div>
                    <div className={styles.colorBlue}>
                      {response && response.trustedPartners}
                    </div>
                  </div>
                </div>

                <div style={{ marginBottom: "10px" }}>
                  <div className={styles.trustValue}>
                    <div>
                      <div className={styles.title2}>
                        Labour Compliance Check
                      </div>
                      <div className={styles.title3}>
                        Covers Labour Compliance Check for the Organization
                      </div>
                    </div>
                    <div>
                      {response && (
                        <>
                          {response.labourComplianceCheck &&
                          response.labourComplianceCheck ===
                            "EPFO_FRAUD_NEGATIVE" ? (
                            <MdOutlineCancel
                              style={{
                                color: "rgba(255, 161, 0, 1)",
                              }}
                              size={20}
                            />
                          ) : (
                            <HiBadgeCheck
                              style={{
                                color: "#4CAF50",
                              }}
                              size={24}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div>
                  <div className={styles.linkMember}>
                    KYB Providers For This Member
                  </div>
                </div>
                <div>
                  <div className={styles.performanceFooter}>
                    Business performance card is an inductive information
                    derived for the transactions with partner on platforms
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <span className={styles.title3}>
                        <HiBadgeCheck
                          style={{
                            color: "#4CAF50",
                          }}
                          size={24}
                        />

                        <span className="mx-1">Verified</span>
                      </span>
                    </div>
                    <div>
                      <span className={styles.title3}>
                        <MdOutlineCancel
                          style={{
                            color: "rgb(255, 0, 0)",
                          }}
                          size={20}
                        />
                        <span className="mx-1">Verification Fail</span>
                      </span>
                    </div>
                    <div>
                      <span className={styles.title3}>
                        <MdOutlineCancel
                          style={{
                            color: "rgba(255, 161, 0, 1)",
                          }}
                          size={20}
                        />
                        <span className="mx-1">Not Verified</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isError && <Content>Data not available</Content>}
        </>
      )}
    </div>
  );
};

export default OnBoarding;
