import React from "react";
import Select from "react-select";
import { useState } from "react";
import { serviceURL } from "services/BaseURLInstance";
import CountryCodes from "jsons/CountryCodes.json";
import Industry from "jsons/Industry.json";
import classes from "./service.module.css";
import Content from "components/UI/Layout/Content";
import { ServiceType } from "utils/DropDown/ServiceType";
import { useEffect } from "react";
import { Success, Error } from "components/notifications/responseHandle";
import Loader from "components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import { AddServiceValdations } from "utils/Validations/Services";

import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

export default ({
  isUpdatingData,
  setIsAddService,
  RequestFunction,
  setIsLimited,
}) => {
  const { instance, accounts } = useMsal();

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [country, setCountry] = useState(null);
  const [industry, setIndustry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [serviceData, setServiceData] = useState({
    createdBy: "",
    createdDate: "",
    updateBy: "",
    updatedDate: "",
    isDeleted: false,
    countriesOfOperations: [],
    industry: [],
    memberId: companyId,
    pricingDescription: "",
    serviceCategory: [],
    serviceSubCategory: [],
    serviceDescription: "",
    serviceId: "",
    subscriberMemberIds: [],
    tags: ["Not applicable"],
    targetParticipants: ["ALL"],
    serviceWebSite: "",
  });

  useEffect(() => {
    if (isUpdatingData) {
      setServiceData({
        createdBy: isUpdatingData.createdBy,
        createdDate: isUpdatingData.createdDate,
        updateBy: isUpdatingData.updateBy,
        updatedDate: isUpdatingData.updatedDate,
        isDeleted: isUpdatingData.isDeleted,
        countriesOfOperations: isUpdatingData.countriesOfOperations,
        industry: isUpdatingData.industry,
        memberId: isUpdatingData.memberId,
        pricingDescription: isUpdatingData.pricingDescription,
        serviceCategory: isUpdatingData.serviceCategory,
        serviceSubCategory: isUpdatingData.serviceSubCategory,
        serviceDescription: isUpdatingData.serviceDescription,
        serviceId: isUpdatingData.serviceId,
        subscriberMemberIds: isUpdatingData.subscriberMemberIds,
        tags: ["Not applicable"],

        targetParticipants: ["ALL"],
        serviceWebSite: isUpdatingData.serviceWebSite,
      });
      setService({
        serviceCategory:
          isUpdatingData.serviceCategory &&
          isUpdatingData.serviceCategory.length > 0
            ? isUpdatingData.serviceCategory[0]
            : "",
        serviceSubCategory:
          isUpdatingData.serviceSubCategory &&
          isUpdatingData.serviceSubCategory.length > 0
            ? isUpdatingData.serviceSubCategory[0]
            : "",
      });
      if (
        isUpdatingData.countriesOfOperations &&
        isUpdatingData.countriesOfOperations.length > 0
      ) {
        let filterCountry =
          isUpdatingData.countriesOfOperations &&
          isUpdatingData.countriesOfOperations.map((item) => {
            return { label: item, value: item };
          });
        setCountry(filterCountry);
      }

      if (isUpdatingData.industry && isUpdatingData.industry.length > 0) {
        let filterIndustry =
          isUpdatingData.industry &&
          isUpdatingData.industry.map((item) => {
            return { label: item, value: item };
          });
        setIndustry(filterIndustry);
      }
    }
  }, [isUpdatingData]);

  const [select, setSelect] = useState(null);

  const [service, setService] = useState({
    serviceCategory: "",
    serviceSubCategory: "",
  });

  const { serviceType, serviceSubType } = ServiceType(
    select || (service && service.serviceCategory)
  );

  const handleService = (e, name) => {
    if (name === "serviceCategory") {
      setSelect(e.value);
      setService({
        ...service,
        [name]: e.value,
      });
    } else {
      setService({
        ...service,
        [name]: e.value,
      });
    }

    setServiceData({
      ...serviceData,
      [name]: [e.value],
    });
  };

  const handleChange = (e, name) => {
    if (name === "countriesOfOperations") {
      setCountry(e);
      let temp = [];
      let Value =
        e &&
        e.map((value) => {
          temp.push(value.value);
        });
      setServiceData({
        ...serviceData,
        [name]: temp,
      });
    } else if (name === "industry") {
      setIndustry(e);
      let temp = [];
      let Value =
        e &&
        e.map((value) => {
          temp.push(value.value);
        });
      setServiceData({
        ...serviceData,
        [name]: temp,
      });
    } else {
      setServiceData({
        ...serviceData,
        [name]: e.target.value,
      });
    }
  };

  const handleAddService = async () => {
    let checkValidation = AddServiceValdations(serviceData);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      setIsLoading(true);

      let URL = "";
      if (isUpdatingData) {
        URL = `${serviceURL}services/${isUpdatingData.serviceId}`;
      } else {
        URL = `${serviceURL}services`;
      }

      const options = {
        method: isUpdatingData ? "PUT" : "POST",
        body: serviceData,
      };

      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, options, response)
          .then((data) => {
            setIsLoading(false);
            let { message, path } = data;
            if (message || path) {
              Error(message);
            } else {
              if (isUpdatingData) {
                Success("Updated Successfully");
              } else {
                Success("Added Successfully");
              }
              setIsAddService(false);
              RequestFunction();
            }
          })
          .catch((error) => {
            const parsedError = JSON.parse(error.message);
            if (parsedError?.code === 429) {
              setIsLimited(true);
            }
          });
      });
    }
  };

  return (
    <div style={{ backgroundColor: "#343434" }}>
      {isLoading ? (
        <Content>
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <div className={classes.createService}>
          <div className={classes.createServiceDiv}>
            <Content marginBottom="2%">
              <div className={classes.createServiceList}>
                <div>
                  <label>Service Request Type</label>
                </div>
                <div>
                  <Select
                    name="serviceCategory"
                    options={
                      serviceType &&
                      serviceType.sort((a, b) => a.label.localeCompare(b.label))
                    }
                    value={
                      serviceType &&
                      serviceType.filter(function (option) {
                        return option.value === service.serviceCategory;
                      })
                    }
                    className={classes.reactSelect}
                    onChange={(e) => handleService(e, "serviceCategory")}
                  />
                </div>
              </div>
            </Content>
            <Content marginBottom="2%">
              <div className={classes.createServiceList}>
                <div>
                  <label>Service Request Sub-Type</label>
                </div>
                <div>
                  <Select
                    name="serviceSubCategory"
                    options={
                      serviceSubType &&
                      serviceSubType.sort((a, b) =>
                        a.label.localeCompare(b.label)
                      )
                    }
                    value={
                      serviceSubType &&
                      serviceSubType.filter(function (option) {
                        return option.value === service.serviceSubCategory;
                      })
                    }
                    className={classes.reactSelect}
                    onChange={(e) => handleService(e, "serviceSubCategory")}
                  />
                </div>
              </div>
            </Content>
            <Content marginBottom="2%">
              <div className={classes.createServiceList2}>
                <div>
                  <label>Offered Service Description</label>
                </div>
                <div>
                  <textarea
                    style={{
                      height: "7rem",
                      width: "100%",
                      padding: "5px",
                      outline: "none",
                    }}
                    type="textarea"
                    name="serviceDescription"
                    value={serviceData.serviceDescription}
                    onChange={(e) => handleChange(e, "serviceDescription")}
                  />
                </div>
              </div>
            </Content>
            <Content marginBottom="2%">
              <div className={classes.createServiceList2}>
                <div>
                  <label>Offered Service Pricing Description</label>
                </div>
                <div>
                  <textarea
                    style={{
                      height: "7rem",
                      width: "100%",
                      padding: "5px",
                      outline: "none",
                    }}
                    type="textarea"
                    name="pricingDescription"
                    value={serviceData.pricingDescription}
                    onChange={(e) => handleChange(e, "pricingDescription")}
                  />
                </div>
              </div>
            </Content>
            <Content marginBottom="2%">
              <div className={classes.createServiceList}>
                <div>
                  <label>Industries Supported</label>
                </div>
                <div>
                  <Select
                    isMulti
                    menuPosition="fixed"
                    name="industry"
                    options={
                      Industry &&
                      Industry.sort((a, b) => a.label.localeCompare(b.label))
                    }
                    value={industry && industry}
                    className={classes.reactSelect}
                    onChange={(e) => handleChange(e, "industry")}
                  />
                </div>
              </div>
            </Content>
            <Content>
              <div className={classes.createServiceList}>
                <div>
                  <label>Country Supported For Service</label>
                </div>
                <div>
                  <Select
                    isMulti
                    menuPosition="fixed"
                    value={country && country}
                    name="countriesOfOperations"
                    options={CountryCodes}
                    className={classes.reactSelect}
                    onChange={(e) => handleChange(e, "countriesOfOperations")}
                  />
                </div>
              </div>
            </Content>

            <div className={classes.btnDiv}>
              <button onClick={handleAddService}>
                {isUpdatingData ? "Update Service" : "Add Service"}
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};
