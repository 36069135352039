import React, { useState, useEffect } from "react";
import { HistoryURL } from "services/BaseURLInstance";
import styled from "styled-components";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Loader from "components/Loader/Loader";
import classes from "./history.module.css";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { FindCompanyName } from "utils/helper";
import { useContext } from "react";
import { FinanceContext } from "pages/financing/Invoice/financeProvider";
import APIError from "components/Error/error";

// Serive Deals

const History = ({ screen, id, dealstatus, companyList }) => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      let URL = `${HistoryURL}history?domain=${screen}&entityId=${id}`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          setIsLoading(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message || "Something went wrong");
          } else {
            let newData = data.sort((a,b)=>
            Date.parse(b.createdDate)-Date.parse(a.createdDate)
            );
            setResponse(newData);
          }
        })
        .catch((error)=>{
          setIsLoading(false);
          setIsError("Something went wrong");
        })
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  console.log("AkashTesting33", isError);

  return (
    <>
      {isLoading ? (
        <div>
          <span>Loading...</span>
          <Loader />
        </div>
      ) : (
        <>
          {isError? <APIError status={isError}/> : response && companyList && (
            <>
              <Table bordered responsive className={cn(classes.Table)}>
                <thead>
                  <tr>
                    <td>Category</td>
                    <td>Activity</td>
                    <td>Actor</td>
                    <td style={{width:"20%"}}>{dealstatus}</td>
                    <td>Date</td>
                    <td>Link</td>
                  </tr>
                </thead>
                <tbody>
                  {response &&
                    response.length > 0 &&
                    response.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {item.domainEntityType ? item.domainEntityType : "-"}
                        </td>
                        <td>{item.eventType ? item.eventType : "-"}</td>
                        <td>
                          {item.actorMemberUID
                            ? FindCompanyName(
                                companyList,
                                item.actorMemberUID
                              )
                            : "-"}
                        </td>
                        <td>{item.eventObject ? item.eventObject.financeDealStatus : "-"}</td>
                        <td>
                          {new Date(item.createdDate).toLocaleDateString()
                            ? new Date(item.createdDate).toLocaleDateString()
                            : "-"}
                        </td>
                        <td>
                          {/* {item.entityId ? item.entityId : "-"} */}
                          <a
                        href={item.entityId ? item.entityId : "-"}
                        target="_blank"
                        rel="noopener noreferrer"
                        >
                          Click
                        </a>
                          
                          </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </>
          )}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default History;

const HistoryStyled = styled.div`
  //  border: 1px solid red;
`;

const HistoryStyledHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  color: white;
  margin-bottom: 5px;
  align-items: center;
  background-color: rgb(128, 128, 128);
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  margin-top: 2%;
  width: 100%;
`;

const HistoryStyledBody = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px;
  color: white;
  margin-bottom: 5px;
  align-items: center;
  border: 1px solid rgb(66, 165, 245); ;
`;

const TableStyled = styled.div`
  div:last-child() {
    border-buttom-right-radius: 15px;
    border-buttom-left-radius: 15px;
  }
`;
