//shakib ka code neeche hai
const RBAC = [
  {
    domainName: "InvoiceFinancing",
    feature: "Invoice",
    subDomainName: "Overview",
    resource: "getOverview",
    permissions: [
      {
        participantType: ["Company", "FINANCER"],
        role: ["FINANCEANDACCOUNTS", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
      {
        participantType: ["SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS"],
        operations: "NOTAPP",
      },
    ],
  },
  //payments
  {
    domainName: "Payments",
    subDomainName: "PaymentAccounts",
    feature: "AddAccount",
    resource: "addAccount",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "CREDIT", "ADMIN", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["OPERATIONS", "LEGAL"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Payments",
    subDomainName: "PaymentProviders",
    feature: "Action",
    resource: "action",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "CREDIT", "ADMIN", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["OPERATIONS", "LEGAL"],
        operations: "READ",
      },
    ],
  },

  //company documents
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "",
    resource: "*",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "LEGAL",
        ],
        operations: "CREATE",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "SearchFilter",
    resource: "*",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "LEGAL",
        ],
        operations: "CREATE",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "Actions",
    resource: "Upload",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "Actions",
    resource: "ESign",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "Actions",
    resource: "Share",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "Actions",
    resource: "DigitalSignature",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "Actions",
    resource: "Information",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "OPERATIONS",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
        ],
        operations: "CREATE",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "Actions",
    resource: "History",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "OPERATIONS",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
        ],
        operations: "CREATE",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "CompanyDocuments",
    feature: "Actions",
    resource: "Download",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "OPERATIONS",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
        ],
        operations: "CREATE",
      },
    ],
  },

  //director Documents
  {
    domainName: "Documents",
    subDomainName: "DirectorDocuments",
    feature: "Actions",
    resource: "Upload",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "DirectorDocuments",
    feature: "Actions",
    resource: "ESign",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "DirectorDocuments",
    feature: "Actions",
    resource: "Share",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "DirectorDocuments",
    feature: "Actions",
    resource: "DigitalSignature",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "LEGAL", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "DirectorDocuments",
    feature: "Actions",
    resource: "Information",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "OPERATIONS",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
        ],
        operations: "CREATE",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "DirectorDocuments",
    feature: "Actions",
    resource: "History",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "OPERATIONS",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
        ],
        operations: "CREATE",
      },
    ],
  },
  {
    domainName: "Documents",
    subDomainName: "DirectorDocuments",
    feature: "Actions",
    resource: "Download",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "MANAGEMENT",
          "CREDIT",
          "ADMIN",
          "OPERATIONS",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
        ],
        operations: "CREATE",
      },
    ],
  },

  {
    domainName: "InvoiceFinancing",
    subDomainName: "Invoices",
    feature: "Invoice",
    resource: "UploadInvoices",
    permissions: [
      {
        participantType: ["Company"],
        role: ["LEGAL", "FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "OPERATIONS", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "LEGAL",
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
        ],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "InvoiceFinancing",
    feature: "Invoice",
    subDomainName: "Invoices",
    resource: "searchInvoices",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS", "OPERATIONS"],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
      {
        participantType: ["FINANCER"],
        role: ["MANAGEMENT", "CREDIT", "OPERATIONS", "ADMIN", "LEGAL"],
        operations: "NOTAPP",
      },
      {
        participantType: ["SERVICE_PROVIDER"],
        role: ["*"],
        operations: "READ",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "Invoice",
    feature: "Requisition",
    resource: "LinkBuyer",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "OPERATIONS", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "LEGAL",
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "Invoice",
    feature: "Requisition",
    resource: "RequestFinancing",
    permissions: [
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "OPERATIONS", "ADMIN", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "LEGAL",
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  // {
  //   domainName: "POFinancing",
  //   subDomainName: "PurchaseOrder",
  //   feature: "Search",
  //   resource: "search",
  //   permissions: [
  //     {
  //       participantType: ["Company"],
  //       role: ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS"],
  //       operations: "READ",
  //     },
  //     {
  //       participantType: ["Company"],
  //       role: ["ADMIN"],
  //       operations: "CREATE",
  //     },
  //     {
  //       participantType: ["Company"],
  //       role: ["LEGAL"],
  //       operations: "NOTAPP",
  //     },
  //     {
  //       participantType: ["FINANCER", "SERVICE_PROVIDER"],
  //       role: [
  //         "CREDIT",
  //         "MANAGEMENT",
  //         "FINANCEANDACCOUNTS",
  //         "OPERATIONS",
  //         "ADMIN",
  //         "LEGAL",
  //       ],
  //       operations: "NOTAPP",
  //     },
  //   ],
  // },
  // {
  //   domainName: "POFinancing",
  //   subDomainName: "PurchaseOrder",
  //   feature: "UploadInvoices",
  //   resource: "upload",
  //   permissions: [
  //     {
  //       participantType: ["Company"],
  //       role: ["MANAGEMENT", "FINANCEANDACCOUNTS"],
  //       operations: "READ",
  //     },
  //     {
  //       participantType: ["Company"],
  //       role: ["ADMIN", "OPERATIONS"],
  //       operations: "CREATE",
  //     },
  //     {
  //       participantType: ["FINANCER", "SERVICE_PROVIDER"],
  //       role: [
  //         "CREDIT",
  //         "MANAGEMENT",
  //         "FINANCEANDACCOUNTS",
  //         "OPERATIONS",
  //         "ADMIN",
  //         "LEGAL",
  //       ],
  //       operations: "NOTAPP",
  //     },
  //   ],
  // },
  // {
  //   domainName: "POFinancing",
  //   subDomainName: "PurchaseOrder",
  //   feature: "UpdateInvoiceDetails",
  //   resource: "updateInvoiceDetails",
  //   permissions: [
  //     {
  //       participantType: ["Company"],
  //       role: ["MANAGEMENT", "FINANCEANDACCOUNTS"],
  //       operations: "READ",
  //     },
  //     {
  //       participantType: ["Company"],
  //       role: ["ADMIN", "OPERATIONS"],
  //       operations: "CREATE",
  //     },
  //     {
  //       participantType: ["FINANCER", "SERVICE_PROVIDER"],
  //       role: [
  //         "CREDIT",
  //         "MANAGEMENT",
  //         "FINANCEANDACCOUNTS",
  //         "OPERATIONS",
  //         "ADMIN",
  //         "LEGAL",
  //       ],
  //       operations: "NOTAPP",
  //     },
  //   ],
  // },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "ViewReqisition",
    permissions: [
      {
        participantType: ["Company", "FINANCER"],
        role: ["FINANCEANDACCOUNTS", "OPERATIONS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
      {
        participantType: ["SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS"],
        operations: "NOTAPP",
      },
    ],
  },

  //finance deals

  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "ExpressInterest",
    permissions: [
      {
        participantType: ["FINANCER"],
        role: ["OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["FINANCER"],
        role: ["MANAGEMENT", "CREDIT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "InitiateDueDiligence",
    permissions: [
      {
        participantType: ["FINANCER"],
        role: ["LEGAL", "FINANCEANDACCOUNTS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["FINANCER"],
        role: ["MANAGEMENT", "OPERATIONS", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "CompleteDueDiligence",
    permissions: [
      {
        participantType: ["FINANCER"],
        role: ["CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["FINANCER"],
        role: ["MANAGEMENT", "OPERATIONS", "ADMIN", "LEGAL"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "CreateQuote",
    permissions: [
      {
        participantType: ["FINANCER"],
        role: ["LEGAL", "FINANCEANDACCOUNTS", "OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["FINANCER"],
        role: ["MANAGEMENT", "CREDIT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "AcceptQuote",
    permissions: [
      {
        participantType: ["Company"],
        role: ["LEGAL", "OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "LEGAL",
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "DeclineQuote",
    permissions: [
      {
        participantType: ["Company"],
        role: ["OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: [
          "LEGAL",
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
        ],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "CreateT&C",
    permissions: [
      {
        participantType: ["FINANCER"],
        role: ["OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["FINANCER"],
        role: ["MANAGEMENT", "CREDIT", "ADMIN", "LEGAL"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "Acceptt&C",
    permissions: [
      {
        participantType: ["Company"],
        role: ["OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "CREDIT", "ADMIN", "LEGAL"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "Declinet&C",
    permissions: [
      {
        participantType: ["Company"],
        role: ["OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "CREDIT", "ADMIN", "LEGAL"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "InvoiceFinancing",
    subDomainName: "FinanceDeals",
    feature: "Requisition",
    resource: "Abort",
    permissions: [
      {
        participantType: [""],
        role: ["", ""],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "CREATE",
      },
      {
        participantType: ["SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS"],
        operations: "NOTAPP",
      },
    ],
  },

  //open account trade
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "SearchOAT",
    resource: "searchOAT",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS", "OPERATIONS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "StartNewTrade",
    resource: "startNewTrade",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "CreateMilestone",
    resource: "createMilestone",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  //oat

  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "SendForBuyerApproval",
    resource: "sendForBuyerApproval",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "ApproveMilestones",
    resource: "",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS", "LEGAL"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "CompleteMilestone",
    resource: "completeMilestone",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "ApproveTrade",
    resource: "",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS", "LEGAL"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "InitiateDelivery",
    resource: "initiateDelivery",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "CompleteDelivery",
    resource: "",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "ConfirmDelivery",
    resource: "confirmDelivery",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  // declineDeliveryRole  confirmDeliveryRole
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "DeclineDelivery",
    resource: "declineDelivery",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "RequestPayment",
    resource: "",
    permissions: [
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  //approve payment decline payment confirmPaymentRole declinePaymentRole

  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "DeclinePayment",
    resource: "declinePayment",
    permissions: [
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "ApprovePayment",
    resource: "approvePayment",
    permissions: [
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  //make payment completePaymentRole
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "CompletePayment",
    resource: "completePayment",
    permissions: [
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "RequestTradeCompletion",
    resource: "",
    permissions: [
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  //complete trADE completeTradeRole tradeAbortRole
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "CompleteTrade",
    resource: "completeTrade",
    permissions: [
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "OpenAccountTrade",
    subDomainName: "OpenAccountTradeTab",
    feature: "AbortTrade",
    resource: "abortTrade",
    permissions: [
      {
        participantType: ["Company"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["Company"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },
      {
        participantType: ["FINANCER", "SERVICE_PROVIDER"],
        role: [
          "CREDIT",
          "MANAGEMENT",
          "FINANCEANDACCOUNTS",
          "OPERATIONS",
          "ADMIN",
          "LEGAL",
        ],
        operations: "NOTAPP",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  //old section

  {
    domainName: "Networks",
    subDomainName: "homepage",
    feature: "AddtoNetwork",
    resource: "addtonetwork",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "OPERATIONS", "CREDIT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "READ",
      },
      {
        participantType: ["FINANCER"],
        role: ["FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },
      {
        participantType: ["Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "Profile",
    subDomainName: "EditProfile",
    feature: "Edit",
    resource: "edit",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "OPERATIONS", "LEGAL", "CREDIT"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "Profile",
    subDomainName: "Onboarding",
    feature: "CompleteOnboarding",
    resource: "CompleteOnboarding",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "OPERATIONS", "LEGAL", "CREDIT"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "Profile",
    subDomainName: "UserManagement",
    feature: "AddUser",
    resource: "addUser",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "OPERATIONS", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "Profile",
    subdomainName: "UserManagement",
    feature: "viewUser",
    resource: "viewUser",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN"],
        operations: "CREATE",
      },
      {
        participantType: ["Company"],
        role: ["OPERATIONS", "FINANCEANDACCOUNTS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["Legal"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "Services",
    subDomainName: "ServicesSection",
    feature: "CreateRequisition",
    resource: "createRequisition",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["MANAGEMENT", "ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },

      {
        participantType: ["FINANCER", "Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "Services",
    subDomainName: "ServicesSection",
    feature: "OfferNewServices",
    resource: "offerNewServices",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["FINANCEANDACCOUNTS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["ADMIN", "OPERATIONS", "MANAGEMENT"],
        operations: "CREATE",
      },
      {
        participantType: ["Company", "FINANCER"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "Services",
    subdomainName: "ServicesSection",
    feature: "ViewmyServices",
    resource: "viewmyServices",
    permissions: [
      {
        participantType: ["Company", "FINANCER", ""],
        role: ["FINANCEANDACCOUNTS", "CREDIT"],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER", ""],
        role: ["ADMIN", "OPERATIONS", "MANAGEMENT"],
        operations: "CREATE",
      },

      {
        participantType: ["FINANCER", "Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },
  {
    domainName: "Services",
    subDomainName: "ServicesRequest",
    feature: "ServiceRequisitionView",
    resource: "ServiceRequisitionView",
    permissions: [
      {
        participantType: [""],
        role: [""],
        operations: "READ",
      },
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["ADMIN", "OPERATIONS", "MANAGEMENT", "FINANCEANDACCOUNTS"],
        operations: "CREATE",
      },

      {
        participantType: ["FINANCER", "Company"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  {
    domainName: "Services",
    subDomainName: "ServicesDeal",
    feature: "Payments",
    resource: "payments",
    permissions: [
      {
        participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
        role: ["ADMIN", "OPERATIONS"],
        operations: "CREATE",
      },

      {
        participantType: ["Company", "FINANCER"],
        role: ["LEGAL"],
        operations: "NOTAPP",
      },
    ],
  },

  // {
  //   domainName: "InvoiceFinancing",
  //   feature: "Invoice",
  //   subDomainName: "Invoices",

  //   resource: "UploadInvoices",
  //   permissions: [
  //     {
  //       participantType: ["Company"],
  //       role: ["MANAGEMENT", "FINANCEANDACCOUNTS"],
  //       operations: "READ",
  //     },
  //     {
  //       participantType: ["Company"],
  //       role: ["OPERATIONS", "ADMIN"],
  //       operations: "CREATE",
  //     },
  //     {
  //       participantType: ["FINANCER", "SERVICE_PROVIDER"],
  //       role: [
  //         "LEGAL",
  //         "CREDIT",
  //         "MANAGEMENT",
  //         "FINANCEANDACCOUNTS",
  //         "OPERATIONS",
  //         "ADMIN",
  //       ],
  //       operations: "NOTAPP",
  //     },
  //   ],
  // },
];
//import RBAC from "./rbac.json";

export const CheckPermission = (feature, subDomainName, resource, role) => {
  let participantType = null;
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  if (memberType === "TRADER") {
    participantType = "Company";
  } else {
    participantType = memberType;
  }
  // if (role) {
  //   const Permissions = RBAC.filter(
  //     (response) =>
  //       response.feature === feature &&
  //       response.resource === resource &&
  //       response.subDomainName === subDomainName
  //   );

  //   if (Permissions && Permissions.length > 0) {
  //     let Check = Permissions[0].permissions.filter(
  //       (response) =>
  //         response.participantType.includes(participantType) &&
  //         response.role.includes(role)
  //     );

  //     if (Check && Check.length > 0) {
  //       return Check[0].operations;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }
  // }

  if (role) {
    for (const key in RBAC) {
      const permission = RBAC[key];
      if (
        permission.feature === feature &&
        permission.resource === resource &&
        permission.subDomainName === subDomainName
      ) {
        const check = permission.permissions.filter(
          (response) =>
            response.participantType.includes(participantType) &&
            response.role.includes(role)
        );
        if (check.length > 0) {
          return check[0].operations;
        }
      }
    }
  }
  return false;
};

// feature = ["Invoice"];
// subDomainName = ['Overview', 'Invoices']
// resource = ["getOverview", "searchInvoices"];
// participantType = ["Company", "FINANCER", "SERVICE_PROVIDER"]
// role = ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS", "CREDIT" , "ADMIN", "CREDIT", "LEGAL"];

// "enum" = [ "NOTAPP", "CREATE", "READ", "UPDATE", "DELETE" ]

//old codes

// // const RBAC = [
// //   {
// //     domainName: "InvoiceFinancing",
// //     feature: "Invoice",
// //     subDomainName: "Overview",
// //     resource: "",
// //     permissions: [
// //       {
// //         participantType: ["Company", "FINANCER"],
// //         role: ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS", "CREDIT"],
// //         operations: "READ",
// //       },
// //       {
// //         participantType: ["Company", "FINANCER"],
// //         role: ["ADMIN"],
// //         operations: "CREATE",
// //       },
// //       {
// //         participantType: ["Company", "FINANCER", "SERVICE_PROVIDER"],
// //         role: ["LEGAL"],
// //         operations: "NOTAPP",
// //       },
// //       {
// //         participantType: ["SERVICE_PROVIDER"],
// //         role: ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS"],
// //         operations: "NOTAPP",
// //       },
// //     ],
// //   },
// //   {
// //     domainName: "InvoiceFinancing",
// //     feature: "Invoice",
// //     subDomainName: "Invoices",
// //     resource: "searchInvoices",
// //     permissions: [
// //       {
// //         participantType: ["Company"],
// //         role: ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS"],
// //         operations: "NOTAPP",
// //       },
// //       {
// //         participantType: ["Company"],
// //         role: ["ADMIN"],
// //         operations: "CREATE",
// //       },
// //       {
// //         participantType: ["Company"],
// //         role: ["LEGAL"],
// //         operations: "NOTAPP",
// //       },
// //       {
// //         participantType: ["FINANCER"],
// //         role: ["MANAGEMENT", "CREDIT", "OPERATIONS", "ADMIN", "LEGAL"],
// //         operations: "NOTAPP",
// //       },
// //       // {
// //       //   participantType: ["SERVICE_PROVIDER"],
// //       //   role: ["*"],
// //       //   operations: "READ",
// //       // },
// //     ],
// //   },
// // ];
// import RBAC from "./rbac.json";

// export const CheckPermission = (feature, subDomainName, resource, role) => {
//   let participantType = null;
//   const memberType = JSON.parse(sessionStorage.getItem("memberType"));
//   if (memberType === "TRADER") {
//     participantType = "Company";
//   } else {
//     participantType = memberType;
//   }

//   if (role) {
//     for (const key in RBAC) {
//       const permission = RBAC[key];
//       if (
//         permission.feature === feature &&
//         permission.resource === resource &&
//         permission.subDomainName === subDomainName
//       ) {
//         const check = permission.permissions.filter(
//           (response) =>
//             response.participantType.includes(participantType) &&
//             response.role.includes(role)
//         );
//         if (check.length > 0) {
//           return check[0].operations;
//         }
//       }
//     }
//   }
//   return false;
// };

// // feature = ["Invoice"];
// // subDomainName = ['Overview', 'Invoices']
// // resource = ["getOverview", "searchInvoices"];
// // participantType = ["Company", "FINANCER", "SERVICE_PROVIDER"]
// // role = ["MANAGEMENT", "FINANCEANDACCOUNTS", "OPERATIONS", "CREDIT" , "ADMIN", "CREDIT", "LEGAL"];

// // "enum" = [ "NOTAPP", "CREATE", "READ", "UPDATE", "DELETE" ]
