import { useState, useEffect } from "react";
import classes from "./Upload.module.css";
import cn from "classnames";

import ButtonDiv from "../../../components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Content from "components/UI/Layout/Content";
import {
  docGroupURL,
  documentURL,
  openTradeAccount,
} from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import { MdDriveFolderUpload } from "react-icons/md";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { Success, Error } from "components/notifications/responseHandle";
const Upload = ({ Data }) => {
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const scheduleData = JSON.parse(sessionStorage.getItem("scheduleData"));
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isError1, setIsError1] = useState(null);
  const [isError2, setIsError2] = useState(null);
  const [isError3, setIsError3] = useState(null);

  const [isUploaded, setIsUploaded] = useState(false);

  const [data, setData] = useState({
    name: "",
    file: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  };

  const handleDocumentUpload = async (event) => {
    event.preventDefault();
    var body = new FormData();
    body.append("name", data.name.split(".").slice(0, -1).join("."));
    body.append("public", false);
    body.append("file", data.file);

    if (data.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${documentURL}documents`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError1(message);
            setIsLoading(false);
          } else {
            handleDocumentGroupUpdate(data);
          }
        });
    }
  };

  const handleDocumentGroupUpdate = async (data) => {
    let requestPayload = {
      memberId: companyId,
      ownerId: companyId,
      docReferenceId: data.id,
      documentName: data.name,
      tags: [`OPEN_TRADE-${data.id}`, `OPEN_TRADE_MILESTONE-${data.id}`],
      docCategory:
        Data.documentGroups &&
        Data.documentGroups[0] &&
        Data.documentGroups[0].docCategory,
      docSubCategory:
        Data.documentGroups &&
        Data.documentGroups[0] &&
        Data.documentGroups[0].docSubCategory,
      participantsToSign: [],
      participantsToShare: [],
      referenceData: [],
    };

    const options = {
      method: "PUT",
      body: requestPayload,
    };

    // await fetch(
    //   docGroupURL +
    //     `bff/documentsGroups/documentUpdate?documentId=${data.id}&template=Global`,
    //   {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + idToken,
    //       "x-api-key":
    //         "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //     },
    //     body: JSON.stringify(requestPayload),
    //   }
    // )
    // .then((response) => response.json())
    // .then((data) => {
    //   let { message, status } = data;
    //   if (message || status) {
    //     Error(message || "Something Went Wrong");
    //     setIsError2(message);
    //     setIsLoading(false);
    //   } else {
    //     handleTradeMilestoneUpdate(data);
    //   }
    // });
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        docGroupURL +
          `bff/documentsGroups/documentUpdate?documentId=${data.id}&template=Global`,
        options,
        response
      ).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError2(message);
          setIsLoading(false);
        } else {
          handleTradeMilestoneUpdate(data);
        }
      });
    });
  };

  const handleTradeMilestoneUpdate = (data) => {
    Data.documentGroup[0].docReferenceId = data.docReferenceId;
    const options = {
      method: "PUT",
      body: Data,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${Data.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError3(message);
        } else {
          setIsUploaded(true);
        }
      });
    });
  };

  return (
    <>
      <Content>
        {isLoading ? (
          <span>
            Loading...
            <Loader />
          </span>
        ) : (
          <>
            {isUploaded ? (
              <span>Uploaded successfully</span>
            ) : (
              <form
                onSubmit={(e) => handleDocumentUpload(e)}
                className={classes.UploadForm}>
                <h5>Upload File...</h5>

                <div className={classes.Upload}>
                  <label onChange={handleChange} htmlFor="formId">
                    <div className={classes.UploadDiv}>
                      <span style={{ color: "#42a5f5" }}>
                        <input
                          name="file"
                          accept=".doc,.  docx,.pdf,.jpeg"
                          type="file"
                          id="formId"
                          hidden
                        />
                        <MdDriveFolderUpload size={40} />
                      </span>

                      <span className="mx-2">
                        {data.name.split(".").slice(0, -1).join(".")}
                      </span>
                    </div>
                  </label>
                </div>

                <ButtonDiv>
                  <Button type="submit">Upload</Button>
                </ButtonDiv>
              </form>
            )}

            {(isError1 || isError2 || isError3) && (
              <span>Something went wrong</span>
            )}

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
      </Content>
    </>
  );
};
export default Upload;
