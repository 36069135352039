import React, { useCallback } from "react";
import useAxios from "../../../services/useAxios";
import { supplierApi, invoiceApi } from "../../../services/BaseURLInstance";
import A from "../../../components/A";
import Loader from "../../../components/Loader";
import {
  CardWapper,
  Wrapper,
  ButtonStyled,
  HeaderActionInvoiceStyled,
  ActionBarStyled,
} from "../../../components/Container.styled";
import { useNavigate } from "react-router-dom";
import Table from "../../../components/table";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { NavLink, useParams } from "react-router-dom";
import { InputField, SelectField, TextArea } from "../../../components/fields";
import styled from "styled-components";
import {SuccessInvoice} from "./SuccessInvoice"

export default () => {
  const navigate = useNavigate();
  const params = useParams();
  
  const { response, isLoading, error } = useAxios({
    api: invoiceApi,
    method: "get",
    url: "invoices?buyerMemberId=adipisicing labore sint tem&sellerMemberId=adipisicing labore sint tem&status=adipisicing labore sint tem",
    config: JSON.stringify({
      requireAuthentication: true,
      headers: {
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    }),
  });

  const handleNavigate = useCallback((name) => navigate(name), []);

  const invoiceFields = React.useMemo(() => [
    {
      
      title: "General Information",
      fields: [
        {
          palceholder: "Invoice Reference Number",
          name: "invoiceRefNumber",
          input_type: "text",
          require: true,
        },
        {
          palceholder: "Tax Scheme",
          name: "taxScheme",
          input_type: "select",
          require: true,
          values: ["Type1", "type2"],
        },
        {
          palceholder: "Supply Types",
          name: "name",
          input_type: "select",
          require: true,
          values: ["Type1", "type2"],
        },
        {
          palceholder: "Supply Types",
          name: "name",
          input_type: "select",
          require: true,
          values: ["Type1", "type2"],
        },
        {
          palceholder: "Supply Types",
          name: "name",
          input_type: "select",
          require: true,
          values: ["Type1", "type2"],
        },
        {
          palceholder: "Supply Types",
          name: "name",
          input_type: "select",
          require: true,
          values: ["Type1", "type2"],
        },
        {
          palceholder: "Supply Types",
          name: "name",
          input_type: "select",
          require: true,
          values: ["Type1", "type2"],
        },
      ],
    },
    {
      title: "Supplier  Details",
      fields: [
        {
          palceholder: "Supplier GSTIN",
          name: "suppliergstin",
          input_type: "text",
          require: true,
        },
        {
          palceholder: "Supplier Legal Name",
          name: "name",
          input_type: "text",
          require: true,
        },
        {
          palceholder: "Supplier Address",
          name: "address1",
          input_type: "text",
          require: true,
        },
        {
          palceholder: "Supplier Address",
          name: "address1",
          input_type: "text",
          require: true,
        },
        {
          palceholder: "Supplier Address",
          name: "address1",
          input_type: "text",
          require: true,
        },
      ],
    },
    {
      title: "Buyer Details",
      fields: [
        {
          palceholder: "Supplier GSTIN",
          name: "suppliergstin",
          input_type: "text",
          require: true,
        },
        {
          palceholder: "Supplier Legal Name",
          name: "name",
          input_type: "text",
          require: true,
        },
        {
          palceholder: "Supplier Address",
          name: "address1",
          input_type: "text",
          require: true,
        },
      ],
    },
  ]);

  const renderField = (fieldProps) => {

    switch (fieldProps.input_type) {
      case "text":
        return <InputField {...fieldProps} />;
        break;
      case "select":
        return <SelectField {...fieldProps} />;
        break;
      case "textarea":
        return <TextArea {...fieldProps} />;
        break;
      default:
        return null;
    }
  };
  
  return (
    <>
      {isLoading && <Loader />}
      <CardWapper>
        <A>Invoices Details11 - {params.id}</A>
        <Wrapper className="test">
          <FormStyled onSubmit={() => {}}>
            {invoiceFields.map((section, index) => (
              <div key={index}>
                <h3>{section.title}</h3>
                <SectionStyled>
                  {section.fields.map((field) => renderField(field))}
                </SectionStyled>
              </div>
            ))}
          </FormStyled>
        </Wrapper>
        <SectionStyled1>
          <Button onClick={() => handleNavigate(`/invoicefinancing/success`) } > Submit Form </Button> 
        </SectionStyled1>    
      </CardWapper>
    </>
  );
};

const FormStyled = styled.form`
  flex: 1;
`;
const SectionStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const SectionStyled1 = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-end; 
`;


const Button = styled.button`
  display: inline-block;
  padding: 10px 90px;
  border-radius: 5px;
  font-family: Roboto;
  font-size: 18px;
  color: white;
  background-color:#42A5F5;
`;
