export const invoiceSavedDetailsTab = [
  {
    title: "General",
    description: "General",
    value: "General",
  },

  {
    title: "Supplier Details",
    description: "Supplier Details",
    value: "SupplierDetails",
  },
  {
    title: "Buyer Details",
    description: "Buyer Details",
    value: "BuyerDetails",
  },
  {
    title: "Item List",
    description: "Item List",
    value: "ItemList",
  },
  {
    title: "Export Details",
    description: "Export Details",
    value: "ExportDetails",
  },
  {
    title: "Miscellaneous",
    description: "Miscellaneous",
    value: "Miscellaneous",
  },
];
