import React, { useEffect, useState } from "react";
import "vis-network/styles/vis-network.css";
import { NetworkURL, TRACKING, documentURL } from "services/BaseURLInstance";
import Content from "components/UI/Layout/Content";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { Error } from "components/notifications/responseHandle";
import Loader from "components/Loader/Loader";
import NewGraph from "./Graph";
import { useParams } from "react-router-dom";
function NetworkGraph() {
  let { id } = useParams();

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const { instance, accounts } = useMsal();
  const [memeberList, setMemberList] = useState(null);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [response, setResponse] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading1(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${NetworkURL}members?memberId=${companyId}`, null, response)
          .then((data) => {
            setMemberList(data);
          })
          .catch((error) => {
            Error();
          })
          .finally((err) => {
            setIsLoading1(false);
          });
      });
    }

    if (!isCancelled) {
      setIsLoading2(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${documentURL}document/graphVisualization?documentUID=&memberUID=&refDocUID=${id}`,
          null,
          response
        )
          .then((data) => {
            setResponse(data);
          })
          .catch((error) => {
            Error();
          })
          .finally(() => {
            setIsLoading2(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  // Sort the data
  response?.sort((a, b) => {
    if (a.isParent && !b.isParent) {
      return -1; // a comes before b
    } else if (!a.isParent && b.isParent) {
      return 1; // b comes before a
    } else {
      return 0; // Leave the order unchanged
    }
  });

  return (
    <Content>
      {isLoading1 || isLoading2 ? (
        <div>
          Loading....
          <Loader />
        </div>
      ) : (
        <>
          {response && memeberList ? (
            <NewGraph memeberList={memeberList} Data={response} />
          ) : (
            <div>Something went wrong</div>
          )}
        </>
      )}
    </Content>
  );
}

export default NetworkGraph;
