import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import ColumnList from "components/UI/ColumnList/ColumnList";
import { TradeOverview } from "utils/Overview/Trade";
import { UseRequest } from "utils/API/Request.js";
import { openTradeAccount } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import classes from "../Overview/Over.module.css";
import { TbRosetteFilled, TbCircleFilled } from "react-icons/tb";

const Index = () => {
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const { instance, accounts } = useMsal();

  useEffect(() => {
    let URL = `${openTradeAccount}openaccounttrades/summary/?memberId=${JSON.parse(
      sessionStorage.getItem("companyId")
    )}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((token) => {
      UseRequest(URL, null, token).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
          setIsError(message);
        } else {
          setResponse(data);
        }
      });
    });
  }, []);
  console.log(response, " data ");
  return (
    <>
      <Content padding="1% 2%">
        {isLoading ? (
          <span>
            Loading....
            <Loader />
          </span>
        ) : (
          <>
            {response && (
              <>
                <div
                  className="d-flex justify-content-between my-2"
                  style={{ color: "#42a5f5" }}>
                  <div>
                    <h5>Summary</h5>
                  </div>
                  <div>{/* <h5>All Trades</h5> */}</div>
                </div>
                {/* <ColumnList list={serviceOverview} response={response} /> */}
                <div className={classes.serviceSummary}>
                  <div
                    style={{
                      boxSizing: "border-box",
                      width: "50%",
                      padding: "1% 5% 1% 10%",
                    }}>
                    <div className={classes.summaryHead}>
                      <div className={classes.summaryHead1}>
                        {response.newOpenAccountTrades
                          ? response.newOpenAccountTrades
                          : 0}
                      </div>
                      <div className={classes.summaryHead2}>
                        <div style={{ fontSize: "22px" }}>New Trades</div>
                        <div style={{ color: "#42a5f5" }}>Recommended-Na</div>
                      </div>
                    </div>
                    <div className={classes.summaryAll}>
                      <div className={classes.summaryList}>
                        <span>
                          <span style={{ position: "relative" }}>
                            <TbCircleFilled size={38} color={"#dc3545"} />
                            <span className={classes.iconValue}>
                              {response.pendingOpenAccountPayments
                                ? response.pendingOpenAccountPayments
                                : 0}
                            </span>
                          </span>
                        </span>
                        <span>In-Pursuit</span>
                      </div>
                      {/* <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#dc3545"} />
                          <span className={classes.iconValue}>
                            {response.expiredRequisitions
                              ? response.expiredRequisitions
                              : 0}
                          </span>
                        </span>
                        <span>Expired</span>
                      </div> */}
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#d39e00"} />
                          <span className={classes.iconValue}>
                            {response.approvedOpenAccountTrade
                              ? response.approvedOpenAccountTrade
                              : 0}
                          </span>
                        </span>
                        <span>Approved</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#d39e00"} />
                          <span className={classes.iconValue}>
                            {response.completedOpenAccountTrades
                              ? response.completedOpenAccountTrades
                              : 0}
                          </span>
                        </span>
                        <span>Completed Trades</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>
                            {response.inReviewOpenAccountTrade
                              ? response.inReviewOpenAccountTrade
                              : 0}
                          </span>
                        </span>
                        <span>In-Review</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>
                            {response.newOpenAccountTrades
                              ? response.newOpenAccountTrades
                              : 0}
                          </span>
                        </span>
                        <span>New</span>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      boxSizing: "border-box",
                      width: "50%",
                      padding: "1% 5% 1% 12%",
                    }}>
                    <div className={classes.summaryHead}>
                      <div className={classes.summaryHead1}>0</div>
                      <div className={classes.summaryHead2}>
                        <div style={{ fontSize: "22px" }}>
                          Upcoming Payments
                        </div>
                        <div style={{ color: "#42a5f5" }}>Recommended</div>
                      </div>
                    </div>
                    <div className={classes.summaryAll}>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#c82333"} />
                          <span className={classes.iconValue}>
                            {response.delayedOpenAccountPayments
                              ? response.delayedOpenAccountPayments
                              : 0}
                          </span>
                        </span>
                        <span>Delayed</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#e0a800"} />
                          <span className={classes.iconValue}>
                            {response.pendingOpenAccountPayments
                              ? response.pendingOpenAccountPayments
                              : 0}
                          </span>
                        </span>
                        <span>Pending</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>
                            {" "}
                            {response.completedOpenAccountPayments
                              ? response.completedOpenAccountPayments
                              : 0}
                          </span>
                        </span>
                        <span>Completed</span>
                      </div>
                      <div className={classes.summaryList}>
                        <span style={{ position: "relative" }}>
                          <TbCircleFilled size={38} color={"#218838"} />
                          <span className={classes.iconValue}>
                            {" "}
                            {response.newPayments ? response.newPayments : 0}
                          </span>
                        </span>
                        <span>New</span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {isError && (
              <div className="alert alert-danger" role="alert">
                {isError}
              </div>
            )}
          </>
        )}
      </Content>
    </>
  );
};
export default Index;
