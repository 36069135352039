import React, { useState } from "react";
import { FindCompanyName } from "../../../utils/helper";
import { GoMail } from "react-icons/go";
import { RiWechatLine } from "react-icons/ri";
import { BsBell } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { truncateStringID } from "../../../utils/helper";
import { SERVICE_LABEL, STATUS } from "utils/Constants";

import Table from "react-bootstrap/Table";
import cn from "classnames";
import classes from "../service.module.css";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";

const header = [
  {
    name: "Service Deal",
    flex: "flex-1",
  },
  {
    name: "Consumer",
    flex: "flex-1",
  },
  {
    name: "Provider",
    flex: "flex-1",
  },
  {
    name: "Quoted Price",
    flex: "flex-1",
  },
  {
    name: "Category",
    flex: "flex-1",
  },
  {
    name: "Sub-Category",
    flex: "flex-1",
  },
  {
    name: "Status",
    flex: "flex-1",
  },
  {
    name: "Last Activity Date",
    flex: "flex-1",
  },
  {
    name: "Action",
    flex: "flex-1",
  },
];

const Body = ({ data, companyList, hanndleCraateGroup }) => {
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const navigate = useNavigate();
  const handleDetails = (item) => {
    navigate(`./${item.serviceDealId}`, {
      state: { data: [item], companyList: companyList },
    });
    console.log(data);
  };

  const fetchData = async (companyId, id2, name) => {
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        hanndleCraateGroup(nData, name, name);
        setMemberList(nData);
      });
  };

  const chatHandler = (data) => {
    if (data?.consumerMemberUID !== "" && data?.providerMemberUID !== "") {
      setItem(data);
      let name = data.serviceDealId
        ? truncateStringID(data.serviceDealId, 7, "SD")
        : "NA";
      fetchData(data?.consumerMemberUID, data?.providerMemberUID, name);
    }
  };

  const Status = (status) => {
    switch (status) {
      case "NEW":
        return STATUS(status, "primary");
      case "INTEREST_EXPRESSED":
        return STATUS(status, "primary");
      case "QUOTE_CREATED":
        return STATUS(status, "primary");
      case "QUOTE_ACCEPTED":
        return STATUS(status, "success");
      case "QUOTE_DECLINED":
        return STATUS(status, "danger");
      case "TNC_ACCEPTANCE_PENDING":
        return STATUS(status, "warning");
      case "BID_COMPLETED":
        return STATUS(status, "success");
      case "SERVICE_IN_PROGRESS":
        return STATUS(status, "warning");
      case "BID_DECLINED":
        return STATUS(status, "danger");
      case "DELIVERED":
        return STATUS(status, "success");
      case "PAYMENT_PENDING":
        return STATUS(status, "warning");
      case "PAYMENT_COMPLETED":
        return STATUS(status, "success");
      case "COMPLETED":
        return STATUS(status, "success");
      case "DELIVERY_CONFIRMED":
        return STATUS(status, "success");
      case "ABORTED":
        return STATUS(status, "danger");
      case "EXPIRED":
        return STATUS(status, "danger");
      case "IN_PURSUIT":
        return STATUS(status, "warning");
      case "CLOSED":
        return STATUS(status, "danger");
      default:
        return "NA";
    }
  };

  const sortedData = [...data].sort(
    (a, b) => new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate)
  );

  // console.log("AkashTesting", companyList);
  // const dummtData = [
  //   {
  //    serviceDealId: "asdfsafrw324e23easdcszafr",
  //    consumerMemberUID:"0f96ce3a-28fd-4762-8fc4-d95a37e3c7a7",
  //    providerMemberUID: "fce301ad-39e9-4c8e-8af4-9804f1b673ff",
  //    servicePaymentCurrency: "USD",
  //    serviceDealStatus: "NEW",
  //    lastUpdatedDate: new Date().toISOString(),
  //    serviceSubCategory: "IDENTITY_VERIFICATION",
  //    serviceCategory: "FINANCING",
  //   },
  //   {
  //     serviceDealId: "asdfsafrw324e23easdcszafr",
  //     consumerMemberUID:"0f96ce3a-28fd-4762-8fc4-d95a37e3c7a7",
  //     providerMemberUID: "fce301ad-39e9-4c8e-8af4-9804f1b673ff",
  //     servicePaymentCurrency: "USD",
  //     serviceDealStatus: "TNC_ACCEPTANCE_PENDING",
  //     lastUpdatedDate: new Date().toISOString(),
  //     serviceSubCategory: "IDENTITY_VERIFICATION",
  //     serviceCategory: "FINANCING",
  //    },
  //    {
  //     serviceDealId: "asdfsafrw324e23easdcszafr",
  //     consumerMemberUID:"0f96ce3a-28fd-4762-8fc4-d95a37e3c7a7",
  //     providerMemberUID: "fce301ad-39e9-4c8e-8af4-9804f1b673ff",
  //     servicePaymentCurrency: "USD",
  //     serviceDealStatus: "TNC_ACCEPTANCE_PENDING",
  //     lastUpdatedDate: new Date().toISOString(),
  //     serviceSubCategory: "IDENTITY_VERIFICATION",
  //     serviceCategory: "FINANCING",
  //    },
  //    {
  //     serviceDealId: "asdfsafrw324e23easdcszafr",
  //     consumerMemberUID:"0f96ce3a-28fd-4762-8fc4-d95a37e3c7a7",
  //     providerMemberUID: "fce301ad-39e9-4c8e-8af4-9804f1b673ff",
  //     servicePaymentCurrency: "USD",
  //     serviceDealStatus: "TNC_ACCEPTANCE_PENDING",
  //     lastUpdatedDate: new Date().toISOString(),
  //     serviceSubCategory: "IDENTITY_VERIFICATION",
  //     serviceCategory: "FINANCING",
  //    },
  //    {
  //     serviceDealId: "asdfsafrw324e23easdcszafr",
  //     consumerMemberUID:"0f96ce3a-28fd-4762-8fc4-d95a37e3c7a7",
  //     providerMemberUID: "fce301ad-39e9-4c8e-8af4-9804f1b673ff",
  //     servicePaymentCurrency: "USD",
  //     serviceDealStatus: "TNC_ACCEPTANCE_PENDING",
  //     lastUpdatedDate: new Date().toISOString(),
  //     serviceSubCategory: "IDENTITY_VERIFICATION",
  //     serviceCategory: "FINANCING",
  //    },
  // ]
   

  return (
    <div
    style={{
      width: "100%",
      overflow:"scroll"
    }}
    >
      <Table bordered responsive className={cn(classes.Table)}>
        <thead>
          <tr>
            {header.map((header, index) => (
              <td key={index}>{header.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index} style={{ whiteSpace: "nowrap" }}>
              <td
                style={{
                  color: "rgb(33,142,235",
                  textDecorationLine: "underline",
                }}
                onClick={() => handleDetails(item)}>
                <BsBell style={{ marginRight: "5px" }} />
                {item.serviceDealId && item.serviceDealId
                  ? truncateStringID(item.serviceDealId, 7, "SD")
                  : "NA"}
              </td>
              <td >{FindCompanyName(companyList, item.consumerMemberUID).length>15 ? FindCompanyName(companyList, item.consumerMemberUID).substring(0,15).concat("...") : FindCompanyName(companyList, item.consumerMemberUID) }</td>
              <td >{FindCompanyName(companyList, item.providerMemberUID).length>15 ? FindCompanyName(companyList, item.providerMemberUID).substring(0,15).concat("..."): FindCompanyName(companyList, item.providerMemberUID)}</td>
              <td >
                {item.servicePriceOffer
                  ? `${item.servicePriceOffer} ${item.servicePaymentCurrency}`
                  : `0 ${item.servicePaymentCurrency}`}
              </td>
              <td >{SERVICE_LABEL(item.serviceCategory)}</td>
              <td >{SERVICE_LABEL(item.serviceSubCategory)}</td>
              <td style={{  maxWidth: "170px", overflow:"hidden" }}>
                {Status(item.serviceDealStatus)}
              </td>
              <td >{new Date(item.lastUpdatedDate).toLocaleDateString()}</td>
              <td className="d-flex">
                <span>
                  <GoMail style={{ marginRight: "15px" }} size={22} />
                </span>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Body>
                        <strong>Chat</strong>
                      </Popover.Body>
                    </Popover>
                  }>
                  <span
                    onClick={() => chatHandler(item)}
                    style={{ cursor: "pointer" }}>
                    <RiWechatLine size={22} />
                  </span>
                </OverlayTrigger>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Body;
