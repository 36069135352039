import React, { useContext, useEffect, useState } from "react";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { FindCompanyName, truncateString } from "utils/helper";
import { TradeContext } from "../tradeProvider";
import { Error } from "components/notifications/responseHandle";
import classes from "./tradeOverview.module.css";
import { FaFileAlt, FaHandsHelping } from "react-icons/fa";
import { COUNTRY, STATUS } from "utils/Constants";
import { BsChatLeftDots } from "react-icons/bs";
import { MdOutlinePayment } from "react-icons/md";
import { HiOutlineUserGroup } from "react-icons/hi";
import { FiPackage } from "react-icons/fi";
import { docGroupURL } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

import cn from "classnames";
export default ({ data, setSelectedItem, participantList }) => {
  const { companyList } = useContext(TradeContext);
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isLoading, setIsLoading] = useState(false);
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      let URL = `${docGroupURL}bff/documentsGroups/summary?memberUID=${companyId}&template=Trade${`&docFilterTags=TRADE-${data.tradeId}&instanceID=${data.tradeId}`}`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            let { message } = data;
            if (message) {
              Error(message);
            } else {
              let newData = [];

              data &&
                data.map(
                  (doc) =>
                    doc &&
                    doc.documents &&
                    doc.documents.length > 0 &&
                    doc.documents.map((dd) => {
                      if (dd.docReferenceId !== "") {
                        newData.push(dd);
                      }
                    })
                );
              setDocumentData([...newData]);
            }
          })
          .catch(() => {})
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const Data = [
    {
      name: "Suppliers and Buyers",
      value: "22",
      list: [
        { name: "Buyer", value: "buyerMemberId" },
        { name: "Seller", value: "sellerMemberId" },
        {
          name: "Contract Date",
          value: "contractDate",
        },
        {
          name: "Country of Origin",
          value: "countryOfOrigin",
        },
        {
          name: "Country of Destination",
          value: "countryOfDestination",
        },
      ],
    },
    {
      name: "Goods",
      value: "22",
      list: [
        {
          name: "Goods Category",
          value: "industry",
        },
        {
          name: "Goods HSN Code",
          value: "goodsTradeName",
        },
        {
          name: "Goods UN Number",
          value: "unNumber", // TODO
        },
        {
          name: "Hazardous Material",
          value: "hazMat",
        },
        {
          name: "Goods Value",
          value: "tradeValue", // TODO
        },
      ],
    },
    {
      name: "Participants",
      value: "22",
      list: [
        {
          name: "Frieght & Logistics",
          value: "hazMat",
        },
        {
          name: "Transport",
          value: "goodsTradeName",
        },
        {
          name: "Warehouse",
          value: "goodsTradeName",
        },
        {
          name: "Custom Clearing House",
          value: "hazMat",
        },
      ],
    },
    {
      name: "Documents",
      value: "22",
      list: [
        {
          name: "Trade Contract",
          value: "Trade Contract",
        },
        {
          name: "PO",
          value: "PO",
        },
        {
          name: "Invoice",
          value: "Invoice",
        },
        {
          name: "Transport Order",
          value: "Transport Order",
        },
      ],
    },
    {
      name: "Events",
      value: "22",
      list: [
        {
          name: "Goods Manufactured",
          value: "Goods Manufactured",
        },
        {
          name: "Transport Road",
          value: "Transport Road",
        },
        {
          name: "Terminal Loading",
          value: "Terminal Loading",
        },
        {
          name: "Customs",
          value: "Customs",
        },
      ],
    },
    {
      name: "Payments",
      value: "22",
      list: [
        {
          name: "Currency",
          value: "Currency",
        },
        {
          name: "Payment Status",
          value: "Payment Status",
        },
        {
          name: "Payment Date",
          value: "Payment Date",
        },
        {
          name: "Payment Method",
          value: "Payment Method",
        },
      ],
    },
  ];

  const OverviewMapping = (item) => {
    switch (item.value) {
      case "buyerMemberId":
        return FindCompanyName(companyList && companyList, data?.buyerMemberId);
      case "sellerMemberId":
        return FindCompanyName(
          companyList && companyList,
          data?.sellerMemberId
        );
      case "contractDate":
        return new Date(data?.contractDate).toLocaleDateString("en-US");
      case "countryOfOrigin":
        return COUNTRY(data?.countryOfOrigin);
      case "countryOfDestination":
        return COUNTRY(data?.countryOfDestination);

      case "industry":
        return data?.industry;
      case "goodsTradeName":
        return data?.goodsTradeName ? data?.goodsTradeName : "NA";
      case "hazMat":
        return data?.hazMat ? data?.hazMat : "NA";

      case "tradeValue":
        return data?.tradeValue ? data?.tradeValue : "NA";
      case "unNumber":
        return data?.unNumber ? data?.unNumber : "NA";
      case "":
        return;
      case "":
        return;
      case "":
        return;
      case "":
        return;
      case "":
        return;

      default:
        return "NA";
    }
  };

  const STATUS_LABEL = (status) => {
    switch (status) {
      case "NEW":
        return STATUS(status, "success");
      case "IN_PROGRESS":
        return STATUS(status, "warning");
      case "Declined":
        return STATUS(status, "danger");
    }
  };

  const Color = (index) => {
    if (index === 0) {
      return "#198754";
    } else if (index === 1) {
      return "#0d6efd";
    } else if (index === 2) {
      return "#eb5e28";
    } else if (index === 3) {
      return "#12486B";
    } else if (index === 4) {
      return "#8d0801";
    } else if (index === 5) {
      return "#713ABE";
    }
  };

  const IconPicker = (location) => {
    switch (location) {
      case "Suppliers and Buyers":
        return <FaHandsHelping size={22} />;
      case "Goods":
        return <FiPackage size={22} />;
      case "Participants":
        return <HiOutlineUserGroup size={22} />;
      case "Documents":
        return <FaFileAlt size={22} />;
      case "Events":
        return <BsChatLeftDots size={22} />;
      case "Payments":
        return <MdOutlinePayment size={22} />;
    }
  };

  console.log("datadata", data);

  return (
    <div style={{ backgroundColor: "black", width: "100%" }}>
      {isLoading ? (
        <span>Loading....</span>
      ) : (
        <>
          <div className={classes.NewOverview}>
            <div className={classes.Head}>
              {/* <div></div> */}
              <div>Trade ID : {data?.tradeId ? data?.tradeId : "NA"}</div>
              <div className="d-flex align-items-left">
                Status{" "}
                <span className={cn("mx-3", classes.status)}>
                  {STATUS_LABEL(data?.status)}
                </span>
              </div>
            </div>

            <div className={classes.List}>
              {Data.map((respone, index) => (
                <div
                  key={index}
                  className={classes.Single}
                  style={{ backgroundColor: Color(index) }}>
                  <div className={classes.head}>
                    <div>{respone.name}</div>
                    <div className={classes.IconDiv}>
                      {respone.name === "Goods" && (
                        <span className="mx-2">
                          INCOTERM : {data?.incoTerm ? data?.incoTerm : "NA"}
                        </span>
                      )}
                      {respone.name === "Participants" && (
                        <span className="mx-2">
                          {participantList?.total ? participantList?.total : ""}
                        </span>
                      )}

                      {respone.name === "Documents" && (
                        <span className="mx-2">
                          {documentData?.length ? documentData?.length : ""}
                        </span>
                      )}

                      {IconPicker(respone.name)}
                    </div>
                  </div>

                  <div className={classes.Parent}>
                    {respone.name === "Participants" ? (
                      <>
                        {participantList?.list?.map((list, index) => (
                          <>
                            {(index === 0 ||
                              index === 1 ||
                              index === 2 ||
                              index === 3 ||
                              index === 4) && (
                              <div key={index} className={classes.Single2}>
                                <div>{list.role}</div>
                                <div>
                                  {FindCompanyName(
                                    companyList && companyList,
                                    list.participant
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                      </>
                    ) : (
                      <>
                        {respone.name === "Documents" ? (
                          <>
                            {documentData?.map((list, index) => (
                              <>
                                {(index === 0 ||
                                  index === 1 ||
                                  index === 2 ||
                                  index === 3 ||
                                  index === 4) && (
                                  <div key={index} className={classes.Single2}>
                                    <div>{list.docCategory}</div>
                                    <div>
                                      {truncateString(list.documentName, 23)}
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          </>
                        ) : (
                          <div className={classes.list2}>
                            {respone?.list?.map((list, i) => (
                              <div key={i} className={classes.Single2}>
                                <div>{list.name}</div>
                                <div>{OverviewMapping(list)}</div>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                    <div
                      className="d-flex"
                      style={{ justifyContent: "space-between" }}>
                      <div style={{ fontSize: "12px" }}>
                        {(index === 4 || index === 5) &&
                          "Sample data for representation"}
                      </div>
                      {(index === 2 || index === 3) && (
                        <div
                          onClick={() => {
                            if (respone.name === "Participants") {
                              setSelectedItem("globalParticipants");
                            } else {
                              setSelectedItem("documents");
                            }
                          }}
                          style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}>
                          See All
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ margin: "1%", color: "#ffc107" }}>
            <CreatedByLabel
              createdBy={
                data?.createdBy &&
                companyList &&
                FindCompanyName(companyList, data.createdBy)
              }
              createdDate={new Date(data?.createdDate).toLocaleDateString()}
              lastUpdateBy={
                data?.updateBy &&
                companyList &&
                FindCompanyName(companyList, data.updateBy)
              }
              lastUpdatedDate={new Date(data?.updatedDate).toLocaleDateString()}
            />
          </div>
        </>
      )}
    </div>
  );
};
