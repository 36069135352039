import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./Schedules.module.css";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import { AuthContext } from "contexts/Auth/AuthContext";
import { UseRequest } from "utils/API/Request.js";
import moment from "moment";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import { openTradeAccount, NetworkURL } from "services/BaseURLInstance";
import { DocumentSelect } from "utils/DropDown/DocumentCatogery.js";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { STAGE } from "utils/DropDown/Stage";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { PaymentCollection } from "components/payment/paymentCollection";
import { storeContext } from "../../../../contexts/Store/Store";
import {
  getPaymentsByLoanId,
  getPaymentByUser,
  paymentDetails,
  makePayment,
  updatePayment,
  releasePayment,
  refundPayment,
  updateServiceDeal,
  setIsPercentageComplete,
  isPercentageComplete,
  getPaymentCollection,
} from "api/Payments";

const UpdateSchedule = ({
  setIsScheduleFormOpen,
  setSelected,
  isSingleScheduleOpen,
  setIsSingleScheduleOpen,
}) => {
  const { fetchCompanyList } = useContext(storeContext);
  const {
    OpenTradeAccountDetails,
    setOpenTradeAccountDetails,

    isApiTrigger,
    setApiTrigger,
  } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(false);
  const scheduleData = JSON.parse(sessionStorage.getItem("scheduleData"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const [senderDetail, setSenderDetail] = useState({});
  const [receiverDetail, setReceiverDetail] = useState({});
  const [dealStatus, setDealStatus] = useState();
  const { id } = useParams();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();

  //rbac
  const [Role, setRole] = useState(null);
  const [sendApprovalRole, setSendApprovalRole] = useState(null);
  const [completeMilestoneRole, setcompleteMilestoneRole] = useState(null);
  const [confirmDeliveryRole, setConfirmdeliveryRole] = useState(null);
  const [declineDeliveryRole, setDeclineDeliveryRole] = useState(null);
  const [initiateDeliveryRole, setinitiateDeliveryRole] = useState(null);
  const [confirmPaymentRole, setConfirmpaymentRole] = useState(null);
  const [declinePaymentRole, setDeclinePaymentRole] = useState(null);
  const [completePaymentRole, setcompletePaymentRole] = useState(null);

  const handleNavigate = (url, value) => {
    navigate(url, { state: value });
  };

  const location = useLocation();
  const [response, setResponse] = useState();
  const [userPaymentData, setUserPaymentData] = useState({});
  const [receiverPaymentDetail, setReceiverPaymentDetail] = useState({});
  const [paymentResponse, setPaymentResponse] = useState({});
  const [paymentData, setPaymentData] = useState({ paymentStatus: "new" });
  const [redirectUrl, setRedirectUrl] = useState("");
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isMakePaymentFormOpen, setIsMakePaymentFormOpen] = useState(false);
  const [memberType, setMemberType] = useState();
  const [paymentStatusText, setPaymentStatusText] = useState();
  const [button, setButton] = useState();
  const [companyList, setCompanyList] = useState();
  const [ispaymentData, setIsPaymentData] = useState();

  const [DatePicker1, setDatePicker1] = useState(new Date());
  const [DatePicker2, setDatePicker2] = useState(new Date());
  const [docId, setDocId] = useState();

  const [select, setSelect] = useState(null);
  const [isAction, setIsAction] = useState(false);
  const { Category, SubCategory } = DocumentSelect(
    select ||
      (scheduleData &&
        scheduleData.documentGroups &&
        scheduleData.documentGroups[0] &&
        scheduleData.documentGroups[0].docCategory)
  );
  const [paymentCollection, setPaymentCollection] = useState();

  const [schedules, setSchedules] = useState({
    openTradeScheduleUID: scheduleData.openTradeScheduleUID,
    openTradeAccountId: scheduleData.openTradeAccountId,
    tradeStage: scheduleData.tradeStage,
    tradeSubStage: scheduleData.tradeSubStage,
    paymentDate: scheduleData.paymentDate,
    milestonePaymentPercentage: scheduleData.milestonePaymentPercentage, // Done
    milestonePaymentAmount: scheduleData.milestonePaymentAmount,
    participants: scheduleData.participants,
    participantsToSign: scheduleData.participantsToSign,
    participantsToShare: scheduleData.participantsToShare,
    providerMemberUID: scheduleData.providerMemberUID, // seller
    consumerMemberUID: scheduleData.consumerMemberUID, // buyer
    documentGroups: scheduleData.documentGroups,
    milestoneDate: scheduleData.milestoneDate, // ask
    tradeMilestoneStatus: scheduleData.tradeMilestoneStatus,
  });

  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "rgb(66, 165, 245)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "rgb(66, 165, 245)",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgb(66, 165, 245)",
      },
      "&:hover fieldset": {
        borderColor: "rgb(66, 165, 245)",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgb(66, 165, 245)",
      },
    },
  });

  const { StageList, SubStageList } = STAGE(null || schedules.tradeStage);

  const [documents, setDocuments] = useState({
    docCategory: "",
    docSubCategory: "",
  });

  const handleChange = (event, date) => {
    if (date === "paymentDate" || date === "milestoneDate") {
      let newDate = new Date(event);
      let dateString = newDate.toISOString();

      let newDate1 = moment(new Date(event)).format("YYYY-MM-DD");
      if (date === "paymentDate") {
        setDatePicker1(newDate1);
      }
      if (date === "milestoneDate") {
        setDatePicker2(newDate1);
      }

      setSchedules({
        ...schedules,
        [date]: dateString,
      });
    } else if (event.target.name === "docCategory") {
      setSelect(event.target.value);
      setDocuments({
        ...documents,
        [event.target.name]: event.target.value,
      });
    } else if (event.target.name === "docSubCategory") {
      setDocuments({
        ...documents,
        [event.target.name]: event.target.value,
      });
    } else {
      setSchedules({
        ...schedules,
        [event.target.name]: event.target.value,
      });
    }
  };
  //rbac
  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setSendApprovalRole(
        CheckPermission(
          "SendForBuyerApproval",
          "OpenAccountTradeTab",
          "sendForBuyerApproval",
          Role
        )
      );
      setcompleteMilestoneRole(
        CheckPermission(
          "CompleteMilestone",
          "OpenAccountTradeTab",
          "completeMilestone",
          Role
        )
      );
      setinitiateDeliveryRole(
        CheckPermission(
          "InitiateDelivery",
          "OpenAccountTradeTab",
          "initiateDelivery",
          Role
        )
      );
      setConfirmdeliveryRole(
        CheckPermission(
          "ConfirmDelivery",
          "OpenAccountTradeTab",
          "confirmDelivery",
          Role
        )
      );
      setDeclineDeliveryRole(
        CheckPermission(
          "DeclineDelivery",
          "OpenAccountTradeTab",
          "declineDelivery",
          Role
        )
      );
      setConfirmpaymentRole(
        CheckPermission(
          "ApprovePayment",
          "OpenAccountTradeTab",
          "approvePayment",
          Role
        )
      );
      setDeclinePaymentRole(
        CheckPermission(
          "DeclinePayment",
          "OpenAccountTradeTab",
          "declinePayment",
          Role
        )
      );
      setcompletePaymentRole(
        CheckPermission(
          "CompletePayment",
          "OpenAccountTradeTab",
          "completePayment",
          Role
        )
      );
    }
  }, [Role]);

  const handleSubmit = (e, status) => {
    setIsLoading(true);
    e.preventDefault();
    schedules.tradeMilestoneStatus = status;
    if (status === "MILESTONE_COMPLETED") {
      Success("Milestone completed Successfully...");
    }
    if (status === "MILESTONE_APPROVAL_REQUESTED") {
      Success("Milestone Approval Requested Successfully...");
    }
    if (status === "DELIVERY_INITIATED") {
      Success("Delivery Initiated Successfully...");
    }
    if (status === "PAYMENT_INITIATED") {
      Success("Payment Initiated Successfully...");
    }
    schedules.documentGroups[0].docCategory = documents.docCategory;
    schedules.documentGroups[0].docSubCategory = documents.docSubCategory;
    const options = {
      method: "PUT",
      body: schedules,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setError(message);
          } else {
            UpdateOpenAccountTrade();
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
          setError("Something Went Wrong");
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken)
      .then((companyList) => {
        setCompanyList(companyList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleMilestoneApprove = (e) => {
    e.preventDefault();
    setIsAction(true);

    const options = {
      method: "PUT",
      body: schedules,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        setIsPaymentData(data);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setError(message);
        } else {
          setIsScheduleFormOpen(false);
          handleNavigate(
            `../open-account/Schedule-payment/${data.openTradeAccountId}`
          );
        }
      });
    });
  };

  const handleMilestoneComplete = (status) => {
    schedules.tradeMilestoneStatus = status;
    const options = {
      method: "PUT",
      body: schedules,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        setIsPaymentData(data);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setError(message);
        } else {
          setIsScheduleFormOpen(false);
          // handleNavigate(
          //   `../open-account/Schedule-payment/${data.openTradeAccountId}`
          // );
        }
      });
    });
  };

  const handleApprove = (e, status) => {
    e.preventDefault();
    setIsAction(true);
    schedules.tradeMilestoneStatus = status;
    if (status === "PAYMENT_DECLINED") {
      Success("Delivery Declined Successfully...");
    }
    if (status === "DELIVERY_APPROVED") {
      Success("Delivery Approved Successfully...");
    }
    if (status === "MILESTONE_APPROVED") {
      Success("Milestone Approved Successfully...");
    }

    const options = {
      method: "PUT",
      body: schedules,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            if (status === "PAYMENT_DECLINED") {
              Success("Declined Successfully");
            }
            if (status === "PAYMENT_APPROVED") {
              Success("Approved Successfully");
            }
            UpdateOpenAccountTrade();
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
          setError("Something Went Wrong");
          setIsLoading(false);
        });
    });

    // if (schedules.tradeMilestoneStatus === "ABORTED") {
    //   OpenTradeAccountDetails.openTradeStatus = "ABORTED";
    // }
    // const options1 = {
    //   method: "PUT",
    //   body: OpenTradeAccountDetails,
    // };
    // let URL1 = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;
    // getIdTokenAsync(instance, accounts).then((response) => {
    //   UseRequest(URL1, options1, response).then((data) => {
    //     setIsLoading(false);
    //     let { message, status } = data;
    //     if (message || status) {
    //       Error(message || "Something Went Wrong");
    //     }
    //   });
    // });
  };

  const UpdateOpenAccountTrade = async () => {
    if (
      schedules.tradeMilestoneStatus === "DELIVERY_INITIATED" &&
      OpenTradeAccountDetails.openTradeStatus === "TRADE_APPROVED"
    ) {
      OpenTradeAccountDetails.openTradeStatus = "TRADE_IN_PROGRESS";
    }
    if (
      schedules.tradeMilestoneStatus === "MILESTONE_APPROVAL_REQUESTED" &&
      OpenTradeAccountDetails.openTradeStatus === "NEW"
    ) {
      OpenTradeAccountDetails.openTradeStatus = "TRADE_IN_REVIEW";
    }
    const options1 = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    let URL1 = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL1, options1, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setError(message);
          } else {
            setApiTrigger(!isApiTrigger);
            setOpenTradeAccountDetails(data);
            // setIsSingleScheduleOpen(!isSingleScheduleOpen);
            // setIsScheduleFormOpen(!isSingleScheduleOpen);
            setIsSingleScheduleOpen(false);
            setIsScheduleFormOpen(false);
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
          setError("Something Went Wrong");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    let isCancelled = false;
    if (scheduleData && !isCancelled) {
      let newDate = new Date(scheduleData && scheduleData.paymentDate);
      let paymentDateString = newDate.toISOString();

      let paymentDate = moment(
        new Date(scheduleData && scheduleData.paymentDate)
      ).format("YYYY-MM-DD");
      if (scheduleData && scheduleData.paymentDate) {
        setDatePicker1(paymentDate);
      }

      let newDate2 = new Date(scheduleData.milestoneDate);
      let milestoneDateString = newDate2.toISOString();

      let milestoneDate = moment(new Date(scheduleData.milestoneDate)).format(
        "YYYY-MM-DD"
      );
      if (scheduleData && scheduleData.milestoneDate) {
        setDatePicker2(milestoneDate);
      }

      setSchedules({
        ...schedules,
        paymentDate: paymentDateString,
        milestoneDate: milestoneDateString,
      });

      setDocuments({
        ...documents,
        ["docCategory"]:
          scheduleData.documentGroups &&
          scheduleData.documentGroups[0] &&
          scheduleData.documentGroups[0].docCategory,
        ["docSubCategory"]:
          scheduleData.documentGroups &&
          scheduleData.documentGroups[0] &&
          scheduleData.documentGroups[0].docSubCategory,
      });
    }
    console.log("DDDD", companyId);
    console.log("eee", schedules);
    return () => {
      isCancelled = true;
    };
  }, []);

  // const findPaymentUser = (sender = true, companyId) => {
  //   getPaymentByUser(idToken, companyId)
  //     .then((userData) => {
  //       if (sender) {
  //         setUserPaymentData(userData);
  //       } else {
  //         setReceiverPaymentDetail(userData);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log("Get Payment API error", error)
  //     });
  // };

  const UpdateMilestone = (status) => {
    schedules.tradeMilestoneStatus = status;
    const options = {
      method: "PUT",
      body: schedules,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            Success("Completed Successfully");
          }
          UpdateOpenAccountTrade();
        })
        .catch(() => {
          Error("Something Went Wrong");
          setError("Something Went Wrong");
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    if (response?.consumerMemberUID) {
      findPaymentUser(false, response?.consumerMemberUID);
    }
    if (response?.providerMemberUID) {
      findPaymentUser(true, response?.providerMemberUID);
    }
  }, [response]);

  useEffect(() => {
    getPaymentsByLoanId(scheduleData.openTradeScheduleUID, idToken, companyId)
      .then((res) => {
        let dateArray = [];
        res.length > 0 &&
          res.forEach((item) => {
            dateArray.push(new Date(item.updatedDate));
          });
        dateArray.sort((date1, date2) => date1 - date2);
        const latestDate = new Date(
          dateArray[dateArray.length - 1]
        ).toISOString();
        let newRes = [];
        newRes =
          res.length > 0 &&
          res.filter((item) => {
            return new Date(item.updatedDate).toISOString() == latestDate;
          });
        setPaymentData(newRes[0]);
        setPaymentResponse(newRes[0]);
        if (location.search === "?status=PENDING") {
          setTimeout(() => {
            if (OpenTradeAccountDetails.escrowService === "ESCROW_PAYMENT") {
              UpdateMilestone("MILESTONE_APPROVED");
            }
            if (OpenTradeAccountDetails.escrowService === "DIRECT_TRANSFER") {
              UpdateMilestone("PAYMENT_COMPLETED");
            }
            fetchUpdatePayment(newRes[0], "PENDING");
          }, 1000);
        } else if (location.search === "?status=FAILED") {
          setTimeout(() => {
            fetchUpdatePayment(newRes[0], "FAILED");
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("paymentByLoan error", error);
      });
  }, [location]);

  const fetchUpdatePayment = async (res, value) => {
    await updatePayment(res, value, idToken)
      .then((resData) => {
        if (
          resData.paymentStatus === "RELEASE_COMPLETED" &&
          OpenTradeAccountDetails.escrowService === "OFFLINE"
        ) {
          UpdateMilestone("PAYMENT_COMPLETED");
        }
        setPaymentData(resData);
        setPaymentResponse(resData);
        navigate(`/trade-account/open-account/${id}`);
      })
      .catch((error) => {
        console.log("updatePayment error", error);
      });
  };

  // const fetchUpdatePayment = async (res, value) => {
  //   await updatePayment(res, value, idToken)
  //     .then((resData) => {
  //       setPaymentData(resData);
  //       setPaymentResponse(resData);
  //       navigate(`/financing/invoice/loans/${res.paymentReferenceData}`);
  //     })
  //     .catch((error) => {
  //       console.log("updatePayment error", error);

  useEffect(() => {
    response && setDealStatus(response.serviceDealStatus);
  }, [response]);

  useEffect(() => {
    findPaymentUser(true, companyId);
    if (paymentData.paymentId) {
      getPaymentDetailsData(paymentData.paymentId);
    }
  }, []);

  const findPaymentUser = (sender = true, companyId) => {
    getPaymentByUser(idToken, companyId)
      .then((userData) => {
        if (sender) {
          setUserPaymentData(userData);
        } else {
          setReceiverPaymentDetail(userData);
        }
      })
      .catch((error) => {
        console.log("Get Payment API error", error);
      });
  };

  // const getPaymentDetailsData = async (paymentId) => {
  //   if (paymentId)
  //     await paymentDetails(paymentId, idToken)
  //       .then((response) => {
  //         setPaymentData(response);
  //       })
  //       .catch((error) => {
  //         console.log("error", error);
  //       });
  // };

  useEffect(() => {
    if (scheduleData && OpenTradeAccountDetails) {
      let oatPaymentAmount =
        (parseFloat(scheduleData.milestonePaymentPercentage) *
          parseFloat(OpenTradeAccountDetails.tradeValue)) /
        100;
      getPaymentCollection(
        oatPaymentAmount,
        OpenTradeAccountDetails.countryOfDestination || "US",
        OpenTradeAccountDetails.paymentCurrency,
        OpenTradeAccountDetails?.buyerMemberId,
        OpenTradeAccountDetails?.countryOfOrigin || "IN",
        idToken
      )
        .then((res) => {
          setPaymentCollection(res);
        })
        .catch((error) => {
          console.log("paymentCollection error", error);
        });
    }
  }, []);

  const getPaymentDetailsData = async (paymentId) => {
    if (paymentId)
      await paymentDetails(paymentId)
        .then((data) => {
          setPaymentData(data);
        })
        .catch((error) => console.log("error", error));
  };

  // const UpdateLoanStatus = async () => {
  //   updateServiceDeal(idToken, response.loanReferenceId, {
  //     ...response,
  //     paymentStatus: "INACTIVE",
  //     financeRePaymentStatus: "REPAYMENT_DUE",
  //     financeDisbursalStatus: "DISBURSEMENT_COMPLETED", /// After Release Payment
  //   })
  //     .then(() => { })
  //     .catch(() => { });
  // };

  const refundPaymentApi = () => {
    setIsLoading(true);
    refundPayment(idToken, paymentData.paymentId)
      .then(() => {
        setIsLoading(false);
        UpdateMilestone("REFUND_INITIATED");
        // setRedirectUrl(response.paymentUrl);
        // handleloanUpdate({ response: response, status: "DISBURSEMENT_INPROGRESS" })
        // window.location.href = response.paymentUrl;
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const findReleasePayment = async () => {
    const requestData = {
      documentIds: [docId],
      paymentId: paymentData.paymentId,
    };
    releasePayment(requestData, idToken).then(() => {
      UpdateMilestone("PAYMENT_COMPLETED"); // todo currently it is set as payment completed but we need to change it in actual senerio
      Success("Release Completed");
    });
  };

  const makeLoanPayment = () => {
    if (paymentCollection >= 0) {
      paymentCollection[0]["state"] = "MAKE_PAYMENT";
    }
    const requestData = {
      paymentId: "",
      collectionRates: paymentCollection,
      referenceInstrumentType: "OPEN_ACCOUNT_TRADE",
      tradxlinkPaymentOrderUID: "",
      senderMemberID: OpenTradeAccountDetails.buyerMemberId,
      receiverMemberID: OpenTradeAccountDetails.sellerMemberId,
      senderMemberAccount: OpenTradeAccountDetails.RefBuyerVirtualBankAccountId,
      receiverMemberAccount:
        OpenTradeAccountDetails.RefSellerVirtualBankAccountId,
      currencySender: OpenTradeAccountDetails.paymentCurrency || "USD",
      currencyReceiver: OpenTradeAccountDetails.paymentCurrency || "USD",
      paymentAmount: (
        (parseFloat(OpenTradeAccountDetails.tradeValue) *
          parseFloat(schedules.milestonePaymentPercentage)) /
        100
      ).toString(),
      paymentCategory: "DISBURSEMENT",
      paymentCollectionMethod: "BANK",
      paymentDueDate: "2022-11-11T09:09:32.407Z",
      paymentDescriptionSender: "test",
      paymentDescriptionReceiver: "",
      paymentReferenceData: scheduleData.openTradeScheduleUID,
      paymentStatus:
        paymentData.paymentStatus === "INITIATED"
          ? "RELEASE_COMPLETED"
          : "INITIATED",
      txnType: "goods",

      paymentType:
        OpenTradeAccountDetails.escrowService === "DIRECT_TRANSFER"
          ? "CHECKOUT"
          : OpenTradeAccountDetails.escrowService === "OFFLINE"
          ? ""
          : "ESCROW",
      paymentAttemptCount: 0,
      paymentTransdactionDate: new Date().toISOString(),
      paymentServiceProvider:
        OpenTradeAccountDetails.escrowService === "OFFLINE"
          ? "OFFLINE_PAYMENT"
          : "TAZAPAY",
      isDeleted: false,
      tag: "",
      createdBy: companyId,
      updateBy: companyId,
      createdDate: OpenTradeAccountDetails.createdDate,
      updatedDate: OpenTradeAccountDetails.updatedDate,
      paymentCompletedRedirectUrl:
        OpenTradeAccountDetails.RefSellerVirtualBankAccountId
          ? `http://${window.location.host}/trade-account/open-account/${id}?status=PENDING`
          : "",
      paymentErrorRedirectUrl:
        OpenTradeAccountDetails.RefSellerVirtualBankAccountId
          ? `http://${window.location.host}/trade-account/open-account/${id}?status=FAILED`
          : "",
    };

    makePayment(idToken, requestData)
      .then((response) => {
        if (
          response.status === "success" &&
          OpenTradeAccountDetails.escrowService === "OFFLINE"
        ) {
          fetchUpdatePayment(response, "RELEASE_COMPLETED");
        }
        setIsLoading(false);
        if (response.paymentUrl) {
          window.location.href = response.paymentUrl;
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
    // }
    // };
  };

  const paymentApiCall = () => {
    if (button === "Make Payment") {
      makeLoanPayment();
    } else if (button === "Release Payment") {
      findReleasePayment();
    } else if (button === "Refund Payment") {
      refundPaymentApi();
    }
  };

  const handleClose = () => {
    setIsMakePaymentFormOpen(false);
  };

  useEffect(() => {
    let isCancelled = false;
    let memberUID = schedules.providerMemberUID;
    let instanceID = id;
    let docCategories =
      schedules.documentGroups &&
      schedules.documentGroups[0] &&
      schedules.documentGroups[0].docCategory;
    let docSubCategory =
      schedules.documentGroups &&
      schedules.documentGroups[0] &&
      schedules.documentGroups[0].docSubCategory;
    let docFilterTags = [`OAT-${id}`];
    let URL = `${process.env.REACT_APP_BFF_URL}bff/documentsGroups/summary?memberUID=${memberUID}&instanceID=${instanceID}&template=Trade&docCategories=${docCategories}&docFilterTags=${docFilterTags}`;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          let filter =
            data &&
            data[0] &&
            data[0].documents &&
            data[0].documents.filter((doc) => {
              return doc.docSubCategory === docSubCategory;
            });
          filter && filter[0]?.docReferenceId
            ? setDocId(filter && filter[0] && filter[0].docReferenceId)
            : setDocId("");
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <div className={classes.Schedules}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {schedules && (
            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              open={isMakePaymentFormOpen}
              onClose={handleClose}
              PaperProps={{
                sx: { backgroundColor: "#343434", color: "white" },
              }}>
              <div style={{ padding: "2%" }}>
                <div style={{ backgroundColor: "black" }}>
                  <DialogContent sx={{}}>
                    <div>
                      <PaymentCollection
                        response={schedules}
                        response2={OpenTradeAccountDetails}
                        loanId={id}
                        companyList={companyList}
                        memberId={companyId}
                      />
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      width: "100%",
                      paddingRight: "3.5%",
                      paddingBottom: "2%",
                      display: "flex",
                      justifyContent: "right",
                    }}>
                    <button
                      style={{
                        width: "20%",
                        margin: "1%",
                        padding: "1%",
                        color: "white",
                        border: "none",
                        backgroundColor: "#42a5f5",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                      onClick={handleClose}>
                      Cancel
                    </button>
                    <>
                      <button
                        style={{
                          width: "20%",
                          margin: "1%",
                          padding: "1%",
                          color: "white",
                          border: "none",
                          backgroundColor: "#42a5f5",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                        onClick={() => {
                          paymentApiCall();
                          setIsMakePaymentFormOpen(false);
                        }}>
                        Submit
                      </button>
                    </>
                  </div>
                </div>
              </div>
            </Dialog>
          )}

          {schedules.consumerMemberUID === companyId && companyList ? (
            <>
              <form className={classes.MilestoneDiv}>
                <h6>Update Milestone</h6>
                <div className={classes.FormDiv}>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="tradeStage"
                      placeholder="Milestone Stage"
                      options={StageList}
                      value={schedules.tradeStage}
                      readOnly={true}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="tradeSubStage"
                      placeholder="Milestone Sub-Stage"
                      options={SubStageList}
                      readOnly={true}
                      value={schedules.tradeSubStage}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="docCategory"
                      placeholder="Document Category"
                      options={Category}
                      value={documents.docCategory}
                      readOnly={true}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="docSubCategory"
                      placeholder="Document Sub-Category"
                      options={SubCategory}
                      readOnly={true}
                      value={documents.docSubCategory}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Input
                      name="milestonePaymentPercentage"
                      placeholder="Payment Percentage"
                      readOnly={true}
                      value={schedules.milestonePaymentPercentage}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    {schedules.milestonePaymentPercentage > 0 ? (
                      <Input
                        name="milestonePaymentAmount"
                        placeholder={`Payment Amount(${OpenTradeAccountDetails?.paymentCurrency})`}
                        value={(
                          parseFloat(OpenTradeAccountDetails.tradeValue) *
                          (parseFloat(schedules.milestonePaymentPercentage) /
                            100)
                        ).toFixed(2)}
                        handleChange={(e) => handleChange(e)}
                        readOnly={true}
                      />
                    ) : (
                      <Input
                        value={0}
                        placeholder={`Payment Amount(${OpenTradeAccountDetails.paymentCurrency})`}
                        readOnly={true}
                      />
                    )}
                  </Grid>
                  <div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <DateInput
                        name="milestoneDate"
                        placeholder="Milestone Date"
                        value={DatePicker2}
                        handleChange={(e) => handleChange(e, "milestoneDate")}
                        readOnly={true}
                      />
                    </Grid>
                  </div>
                  <div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <DateInput
                        name="paymentDate"
                        placeholder="Payment Due Date"
                        value={DatePicker1}
                        handleChange={(e) => handleChange(e, "paymentDate")}
                        readOnly={true}
                      />
                    </Grid>
                  </div>

                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Input
                      name="tradeMilestoneStatus"
                      placeholder="Milestone Status"
                      value={schedules.tradeMilestoneStatus}
                      handleChange={(e) => handleChange(e)}
                      readOnly={true}
                    />
                  </Grid>
                </div>
                {!isAction &&
                  OpenTradeAccountDetails.openTradeStatus !== "ABORTED" &&
                  schedules.tradeMilestoneStatus ===
                    "MILESTONE_APPROVAL_REQUESTED" && (
                    <ButtonDiv>
                      {schedules.tradeMilestoneStatus !== "ABORTED" &&
                        OpenTradeAccountDetails.openTradeStatus !=
                          "TRADE_IN_REVIEW" && (
                          <Button
                            onClick={(e) => {
                              handleSubmit(e, "ABORTED");
                              {
                                handleNavigate(`../open-account/${id}`);
                              }
                            }}>
                            Abort Milestone
                          </Button>
                        )}
                      {OpenTradeAccountDetails.openTradeStatus !=
                        "TRADE_IN_REVIEW" && (
                        <Button
                          type="button"
                          onClick={(e) => {
                            handleApprove(e, "MILESTONE_DECLINED");
                          }}>
                          Decline Milestone
                        </Button>
                      )}
                      {OpenTradeAccountDetails.escrowService !=
                        "ESCROW_PAYMENT" &&
                        OpenTradeAccountDetails.openTradeStatus !=
                          "TRADE_IN_REVIEW" && (
                          <Button
                            type="button"
                            onClick={(e) => {
                              handleApprove(e, "MILESTONE_APPROVED");
                            }}>
                            Approve Milestone
                          </Button>
                        )}

                      {OpenTradeAccountDetails.escrowService ===
                        "ESCROW_PAYMENT" &&
                        OpenTradeAccountDetails.openTradeStatus ===
                          "TRADE_APPROVED" && (
                          <Button
                            type="button"
                            onClick={(e) => {
                              handleMilestoneApprove(e);
                            }}>
                            Approve Milestone
                          </Button>
                        )}
                    </ButtonDiv>
                  )}

                {!isAction &&
                  OpenTradeAccountDetails.openTradeStatus !== "ABORTED" &&
                  schedules.tradeMilestoneStatus === "DELIVERY_INITIATED" && (
                    <ButtonDiv>
                      {schedules.tradeMilestoneStatus !== "ABORTED" && (
                        <Button
                          disabled={true}
                          onClick={(e) => {
                            handleSubmit(e, "ABORTED");
                            {
                              handleNavigate(`../open-account/${id}`);
                            }
                          }}>
                          Abort Milestone
                        </Button>
                      )}
                      <Button
                        type="button"
                        onClick={
                          declineDeliveryRole === "CREATE"
                            ? (e) => {
                                handleApprove(e, "DELIVERY_DECLINED");
                              }
                            : " "
                        }>
                        Decline Delivery
                      </Button>
                      <Button
                        type="button"
                        onClick={
                          confirmDeliveryRole === "CREATE"
                            ? (e) => {
                                handleApprove(e, "DELIVERY_APPROVED");
                              }
                            : " "
                        }>
                        Approve Delivery
                      </Button>
                    </ButtonDiv>
                  )}

                {!isAction &&
                  OpenTradeAccountDetails.openTradeStatus !== "ABORTED" &&
                  schedules.tradeMilestoneStatus === "PAYMENT_INITIATED" &&
                  OpenTradeAccountDetails.openTradeStatus !== "ABORTED" && (
                    <ButtonDiv>
                      {schedules.tradeMilestoneStatus !== "ABORTED" && (
                        <Button
                          disabled={true}
                          onClick={(e) => {
                            handleSubmit(e, "ABORTED");
                            {
                              handleNavigate(`../open-account/${id}`);
                            }
                          }}>
                          Abort Milestone
                        </Button>
                      )}
                      <Button
                        type="button"
                        onClick={
                          declinePaymentRole === "CREATE"
                            ? (e) => {
                                handleApprove(e, "PAYMENT_DECLINED");
                              }
                            : " "
                        }>
                        DECLINE PAYMENT
                      </Button>
                      <Button
                        type="button"
                        onClick={(e) => {
                          handleMilestoneApprove(e);
                        }}>
                        APPROVE PAYMENT
                      </Button>
                    </ButtonDiv>
                  )}

                {!isAction &&
                  schedules.tradeMilestoneStatus === "PAYMENT_APPROVED" &&
                  OpenTradeAccountDetails.openTradeStatus !== "ABORTED" && (
                    <ButtonDiv>
                      {/* <Button
                        type="button"
                        onClick={
                          confirmPaymentRole === "CREATE"
                            ? (e) => {
                                handleMilestoneApprove(e);
                              }
                            : " "
                        }>
                        CONFIRM PAYMENT
                      </Button> */}
                      <div>
                        {paymentData?.paymentStatus === null ||
                        paymentData?.paymentStatus === "" ||
                        paymentData?.paymentStatus === "new" ? (
                          <div style={{ textAlign: "right" }}>
                            {OpenTradeAccountDetails.escrowService ===
                            "OFFLINE" ? (
                              <Button
                                onClick={
                                  completePaymentRole === "CREATE"
                                    ? () => {
                                        if (
                                          OpenTradeAccountDetails.escrowService ===
                                          "OFFLINE"
                                        ) {
                                          console.log("makeLoanPayment");
                                          makeLoanPayment();
                                        } else {
                                          setButton("Make Payment");
                                          setIsMakePaymentFormOpen(true);
                                        }
                                      }
                                    : " "
                                }>
                                Confirm Payment Completion
                              </Button>
                            ) : (
                              <Button
                                onClick={
                                  completePaymentRole === "CREATE"
                                    ? () => {
                                        if (
                                          OpenTradeAccountDetails.escrowService ===
                                          "OFFLINE"
                                        ) {
                                          makeLoanPayment();
                                        } else {
                                          setButton("Make Payment");
                                          setIsMakePaymentFormOpen(true);
                                        }
                                      }
                                    : " "
                                }>
                                Make Payment
                              </Button>
                            )}
                          </div>
                        ) : paymentData?.paymentStatus === "INITIATED" &&
                          paymentData?.createdBy === companyId ? (
                          <div style={{ textAlign: "right" }}>
                            <Button onClick={() => makeLoanPayment()}>
                              Confirm Payment Completion
                            </Button>
                            {/* todo check the mark complete status flow with soham */}
                          </div>
                        ) : paymentData?.paymentStatus === "FAILED" &&
                          paymentData?.createdBy === companyId ? (
                          <div style={{ textAlign: "right" }}>
                            <Button
                              onClick={() => {
                                setIsMakePaymentFormOpen(true);
                                setButton("Re-Initiate Payment");
                                // paymentApiCall();
                              }}>
                              Re-Initiate Payment
                            </Button>
                          </div>
                        ) : (paymentData?.paymentStatus ===
                            "MONEY_DISBURED_TO_PROVIDER" ||
                            paymentData?.paymentStatus === "REFUND_FAILED") &&
                          paymentData?.createdBy === companyId ? (
                          <div style={{ textAlign: "right" }}>
                            <Button
                              onClick={() => {
                                setIsMakePaymentFormOpen(true);
                                setButton("Release Payment");
                                // paymentApiCall();
                              }}>
                              Release Payment
                            </Button>
                            <Button
                              onClick={() => {
                                setIsMakePaymentFormOpen(true);
                                setButton("Refund Payment");
                                // paymentApiCall();
                              }}>
                              Refund Payment
                            </Button>
                          </div>
                        ) : paymentData?.paymentStatus ===
                          "RELEASE_INITITATED" ? (
                          <div style={{ color: "#19cb46", textAlign: "right" }}>
                            Payment release initiated
                          </div>
                        ) : paymentData?.paymentStatus ===
                          "RELEASE_COMPLETED" ? (
                          <div style={{ color: "#19cb46", textAlign: "right" }}>
                            Payment release Completed
                          </div>
                        ) : paymentData?.paymentStatus === "RELEASE_PENDING" ? (
                          <div style={{ color: "#19cb46", textAlign: "right" }}>
                            Payment release updates Pending
                          </div>
                        ) : (
                          <div style={{ color: "#19cb46", textAlign: "right" }}>
                            Payment In-Progress...
                          </div>
                        )}
                      </div>
                    </ButtonDiv>
                  )}
              </form>
            </>
          ) : (
            <>
              <form className={classes.MilestoneDiv}>
                <h6>Update Milestone</h6>
                <div className={classes.FormDiv}>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="tradeStage"
                      placeholder="Milestone Stage"
                      options={StageList}
                      value={schedules.tradeStage}
                      readOnly={
                        (schedules.tradeMilestoneStatus ===
                          "MILESTONE_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_COMPLETED" ||
                          schedules.tradeMilestoneStatus ===
                            "MILESTONE_COMPLETED") &&
                        true
                      }
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="tradeSubStage"
                      placeholder="Milestone Sub-Stage"
                      options={SubStageList}
                      value={schedules.tradeSubStage}
                      readOnly={
                        (schedules.tradeMilestoneStatus ===
                          "MILESTONE_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_COMPLETED" ||
                          schedules.tradeMilestoneStatus ===
                            "MILESTONE_COMPLETED") &&
                        true
                      }
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="docCategory"
                      placeholder="Document Category"
                      options={Category}
                      readOnly={
                        (schedules.tradeMilestoneStatus ===
                          "MILESTONE_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_COMPLETED" ||
                          schedules.tradeMilestoneStatus ===
                            "MILESTONE_COMPLETED") &&
                        true
                      }
                      value={documents.docCategory}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="docSubCategory"
                      placeholder="Document Sub-Category"
                      options={SubCategory}
                      readOnly={
                        (schedules.tradeMilestoneStatus ===
                          "MILESTONE_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_COMPLETED" ||
                          schedules.tradeMilestoneStatus ===
                            "MILESTONE_COMPLETED") &&
                        true
                      }
                      value={documents.docSubCategory}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Input
                      name="milestonePaymentPercentage"
                      placeholder="Payment Percentage"
                      value={schedules.milestonePaymentPercentage}
                      readOnly={
                        (schedules.tradeMilestoneStatus ===
                          "MILESTONE_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_INITIATED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_COMPLETED" ||
                          schedules.tradeMilestoneStatus ===
                            "MILESTONE_COMPLETED") &&
                        true
                      }
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    {schedules.milestonePaymentPercentage > 0 ? (
                      <Input
                        name="milestonePaymentAmount"
                        placeholder={`Payment Amount(${OpenTradeAccountDetails?.paymentCurrency})`}
                        // value={schedules.milestonePaymentAmount}
                        value={(
                          parseFloat(OpenTradeAccountDetails.tradeValue) *
                          (parseFloat(schedules.milestonePaymentPercentage) /
                            100)
                        ).toFixed(2)}
                        readOnly={
                          (schedules.tradeMilestoneStatus ===
                            "MILESTONE_APPROVED" ||
                            schedules.tradeMilestoneStatus ===
                              "DELIVERY_INITIATED" ||
                            schedules.tradeMilestoneStatus ===
                              "DELIVERY_APPROVED" ||
                            schedules.tradeMilestoneStatus ===
                              "PAYMENT_INITIATED" ||
                            schedules.tradeMilestoneStatus ===
                              "PAYMENT_COMPLETED" ||
                            schedules.tradeMilestoneStatus ===
                              "MILESTONE_COMPLETED") &&
                          true
                        }
                        handleChange={(e) => handleChange(e)}
                      />
                    ) : (
                      <Input
                        value={0}
                        placeholder={`Payment Amount(${OpenTradeAccountDetails.paymentCurrency})`}
                        readOnly={true}
                      />
                    )}
                  </Grid>
                  <div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <DateInput
                        name="milestoneDate"
                        placeholder="Milestone Date"
                        value={DatePicker2}
                        readOnly={
                          (schedules.tradeMilestoneStatus ===
                            "MILESTONE_APPROVED" ||
                            schedules.tradeMilestoneStatus ===
                              "DELIVERY_INITIATED" ||
                            schedules.tradeMilestoneStatus ===
                              "DELIVERY_APPROVED" ||
                            schedules.tradeMilestoneStatus ===
                              "PAYMENT_INITIATED" ||
                            schedules.tradeMilestoneStatus ===
                              "PAYMENT_COMPLETED" ||
                            schedules.tradeMilestoneStatus ===
                              "MILESTONE_COMPLETED") &&
                          true
                        }
                        handleChange={(e) => handleChange(e, "milestoneDate")}
                      />
                    </Grid>
                  </div>
                  {/* <div>
                    <DateInput
                      name="paymentDate"
                      placeholder="Payment Due Date"
                      value={DatePicker1}
                      handleChange={(e) => handleChange(e, "paymentDate")}
                    />
                  </div> */}
                  <div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Stack spacing={4}>
                          <DatePicker
                            name="paymentDate"
                            label="Payment Due Date"
                            value={DatePicker1}
                            readOnly={
                              (schedules.tradeMilestoneStatus ===
                                "MILESTONE_APPROVED" ||
                                schedules.tradeMilestoneStatus ===
                                  "DELIVERY_INITIATED" ||
                                schedules.tradeMilestoneStatus ===
                                  "DELIVERY_APPROVED" ||
                                schedules.tradeMilestoneStatus ===
                                  "PAYMENT_INITIATED" ||
                                schedules.tradeMilestoneStatus ===
                                  "PAYMENT_COMPLETED" ||
                                schedules.tradeMilestoneStatus ===
                                  "MILESTONE_COMPLETED") &&
                              true
                            }
                            minDate={schedules.milestoneDate}
                            onChange={(e) => handleChange(e, "paymentDate")}
                            renderInput={(params) => (
                              <CssTextField
                                InputLabelProps={{
                                  style: { color: "rgb(66, 165, 245)" },
                                }}
                                sx={{
                                  svg: { color: "rgb(66, 165, 245)" },
                                }}
                                {...params}
                              />
                            )}
                          />
                        </Stack>
                      </LocalizationProvider>
                    </Grid>
                  </div>

                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Input
                      name="tradeMilestoneStatus"
                      placeholder="Milestone Status"
                      value={schedules.tradeMilestoneStatus}
                      handleChange={(e) => handleChange(e)}
                      readOnly={true}
                    />
                  </Grid>
                </div>
                {schedules.providerMemberUID === companyId ? (
                  <ButtonDiv>
                    {schedules.tradeMilestoneStatus === "PAYMENT_COMPLETED" &&
                    OpenTradeAccountDetails.openTradeStatus !== "ABORTED" ? (
                      <Button
                        type="buton"
                        onClick={
                          completeMilestoneRole === "CREATE"
                            ? (e) => {
                                handleSubmit(e, "MILESTONE_COMPLETED");
                              }
                            : " "
                        }>
                        Confirm Milestone Completion
                      </Button>
                    ) : (
                      <>
                        {schedules &&
                          OpenTradeAccountDetails.openTradeStatus !==
                            "ABORTED" &&
                          schedules.tradeMilestoneStatus !== "ABORTED" &&
                          schedules.tradeMilestoneStatus !==
                            "MILESTONE_APPROVED" && (
                            <Button
                              disabled={true}
                              onClick={(e) => {
                                handleSubmit(e, "ABORTED");
                                {
                                  handleNavigate(`../open-account/${id}`);
                                }
                              }}>
                              Abort Milestone
                            </Button>
                          )}

                        {OpenTradeAccountDetails.openTradeStatus !==
                          "ABORTED" &&
                        (schedules.tradeMilestoneStatus ===
                          "DELIVERY_APPROVED" ||
                          schedules.tradeMilestoneStatus ===
                            "PAYMENT_DECLINED") ? (
                          <Button
                            type="buton"
                            onClick={(e) => {
                              handleSubmit(e, "PAYMENT_INITIATED");
                            }}>
                            Request Payment
                          </Button>
                        ) : (
                          <>
                            {schedules.tradeMilestoneStatus ===
                              "MILESTONE_CREATED" &&
                              OpenTradeAccountDetails.openTradeStatus !==
                                "ABORTED" && (
                                <Button
                                  type="buton"
                                  onClick={(e) => {
                                    handleSubmit(e, "MILESTONE_CREATED");
                                  }}>
                                  Update
                                </Button>
                              )}

                            {schedules.tradeMilestoneStatus ===
                              "MILESTONE_DECLINED" &&
                              OpenTradeAccountDetails.openTradeStatus !==
                                "ABORTED" && (
                                <Button
                                  type="buton"
                                  onClick={(e) => {
                                    handleSubmit(e, "MILESTONE_CREATED");
                                  }}>
                                  Modify Milestone
                                </Button>
                              )}
                          </>
                        )}

                        {schedules.tradeMilestoneStatus ===
                          "MILESTONE_CREATED" &&
                          OpenTradeAccountDetails.openTradeStatus !==
                            "ABORTED" && (
                            <Button
                              type="button"
                              onClick={
                                sendApprovalRole === "CREATE"
                                  ? (e) => {
                                      handleSubmit(
                                        e,
                                        "MILESTONE_APPROVAL_REQUESTED"
                                      );
                                    }
                                  : " "
                              }>
                              Send for Buyer Approval
                            </Button>
                          )}
                      </>
                    )}

                    <>
                      {schedules.tradeMilestoneStatus ===
                        "MILESTONE_APPROVED" && (
                        <>
                          {schedules &&
                            OpenTradeAccountDetails.openTradeStatus !==
                              "ABORTED" &&
                            schedules.tradeMilestoneStatus !== "ABORTED" && (
                              <Button
                                disabled={true}
                                onClick={(e) => {
                                  handleSubmit(e, "ABORTED");
                                  {
                                    handleNavigate(`../open-account/${id}`);
                                  }
                                }}>
                                Abort Milestone
                              </Button>
                            )}
                        </>
                      )}

                      {schedules &&
                        OpenTradeAccountDetails.openTradeStatus !== "ABORTED" &&
                        ((schedules.documentGroups[0] &&
                          schedules.documentGroups[0].docReferenceId) ||
                          (schedules.tradeMilestoneStatus ===
                            "MILESTONE_APPROVED" &&
                            OpenTradeAccountDetails.openTradeStatus ===
                              "TRADE_APPROVED") ||
                          schedules.tradeMilestoneStatus ===
                            "DELIVERY_DECLINED") && (
                          <Button
                            type="buton"
                            onClick={
                              initiateDeliveryRole === "CREATE"
                                ? (e) => {
                                    handleSubmit(e, "DELIVERY_INITIATED");
                                  }
                                : " "
                            }>
                            Initiate Delivery
                          </Button>
                        )}
                    </>
                  </ButtonDiv>
                ) : (
                  <></>
                )}
              </form>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default UpdateSchedule;
