
import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";

import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import { CheckCategoryTitle, CheckCountryTitle, CheckCurrencyTitle ,CheckPortTitle } from "utils/helper";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { Countries } from "utils/DropDown/Country";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from 'jsons/Currenciesr.json';
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";

const CustomInspection = ({ response }) => {




  const [serviceDetails, setServiceDetails] = useState([]);
  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails))
    }
  }, [response]);


  // const [customInspectionData, setCustomInspectionData] = useState({
  //   serviceType: "",
  //   portOrigin:"",
  //   destinationPort:"",
  //   inspectiontype:"",
  //   ProductName:"",
  //   ProductDescription:"",
  //   ShipperName:"",
  //   ShipperAddress:"",
  //   ReceiverName:"",
  //   ReceiverAddress:"",
  //   commodity:"",
  //   contactInfo:"",
  //   hsnOfCode:"",
  //   requirement:"",
  //   inspectiontype:"",
  //   quantity:"",
  //   commodity:"",
  // });
  let listCommodity = serviceDetails.commodity?.map((employee, index) => {
    return (
      employee.value
    )
  })

  let listType = serviceDetails.inspectionType?.map((employee, index) => {
    return (
      employee.value
    )
  })
  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  console.log(listCommodity, listType, "saurabh")

  return (
    <>
      {response && serviceDetails ?
        <>
          <div style={{ margin: "10px" }}>Customs Clearance Inspection Services Required For ?
          </div>

          <div className={classes.source} style={{ margin: "10px" }}>
            <div style={{ marginLeft: "1%", width: "50%" }}>


              <input
                className={classes.radio}
                type="radio"
                name="serviceType"
                value="IMPORT"
                readOnly={true}
                checked={serviceDetails.serviceType == "IMPORT"}
              />
              <label>IMPORT</label>
            </div>  <div
              style={{
                // fontSize: "25px",
                marginLeft: "1%",
                width: "50%",
                textAlign: "right",
                marginRight: "1%",
              }}
            >
              <input
                className={classes.radio}
                type="radio"
                name="serviceType"
                value="EXPORT"
                readOnly={true}
                checked={serviceDetails.serviceType == "EXPORT"}
              />
              <label>EXPORT</label>
            </div>
          </div>
          <div style={{ margin: "10px" }}>
            Shipment details
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
            <Input
                options={Countries}
                name="countryFrom"
                placeholder="Country From"
                //  //  //handleChange={handleChange}
                value={CheckCountryTitle(serviceDetails.countryFrom)}
                readOnly={true}
              />
            </div>


            <div style={{ width: "50%", margin: "10px" }}>
            <Input
                options={Countries}
                name="countryTo"
                placeholder="Country To"
                //  //handleChange={handleChange}
                value={CheckCountryTitle(serviceDetails.countryTo)}
                readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="cityFrom"
                placeholder="City From"
                //  //handleChange={handleChange}
                value={serviceDetails && serviceDetails.cityFrom}
                readOnly={true}
              />
            </div>


            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="cityTo"
                placeholder="City To"
                //  //handleChange={handleChange}
                value={serviceDetails && serviceDetails.cityTo}
                readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
            <Input
                options={port_code}
                name="portOrigin"
                placeholder="From Port"
                //  //handleChange={handleChange}
                value={serviceDetails && serviceDetails.portOrigin}
              />
            </div>


            <div style={{ width: "50%", margin: "10px" }}>
            <Input
                options={port_code}
                name="destinationPort"
                placeholder="To Port"
                value={serviceDetails && serviceDetails.destinationPort}
              //  //handleChange={handleChange}
              />
            </div>
          </div>


          <div style={{ margin: "10px" }}>
            <label>Customs Clearance Inspection Services required for ?
            </label>
          </div>
          <div style={{ width: "100%", margin: "10px", paddingRight:"20px" }}>
            <Input
                // options={inspectionType}
              name="inspectiontype"
              placeholder="Inspection Type"
              //   handleChange={(e) => handleMultiChange(e, "commodity")}
              value={listType}
              readOnly={true}
            />
          </div>

          <div>
            <div>
              <div style={{ margin: "10px" }}>
                <label>COMMODITY</label>
                </div>
                <div style={{ width: "50%",margin: "10px" , paddingRight:"20px" }}>
                  <Input
                    options={Industry}
                    name="commodity"
                    placeholder="Product Category"
                    // handleChange={(e) => handleMultiChange(e, "commodity")}
                    value={listCommodity}
                    readOnly={true}
                  //     
                  //     name="memberlist"
                  //     selected={selectStatus}
                  //     placeholder="Status"
                  //     options={InvoiceStatus}
                  //    
                  //   />
                  />
                </div>
              
            </div>

            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="ProductName"
                  placeholder="Product Name"
                  //  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.ProductName}
                  readOnly={true}
                />
              </div>
              {/* : [Insert Product Name]
: [Insert Product Description]
: []
Shipper Address : [ Insert Address ] */}

              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="productDescription"
                  placeholder="Product Description"
                  //  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.productDescription}
                  readOnly={true}
                />
              </div>
            </div>
            <label style={{margin:"10px"}}>Shipment Details</label>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="ShipperName"
                  placeholder="Shipper Name"
                  //  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.ShipperName}
                  readOnly={true}
                />
              </div>


              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="ReceiverName"
                  placeholder="Receiver Name"
                  //  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.ReceiverName}

                  readOnly={true}
                />


              </div>
            </div>



            <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="ShipperAddress"
                placeholder="Shipper Address"
                //  //handleChange={handleChange}
                value={serviceDetails && serviceDetails.ShipperAddress}
                readOnly={true}
              />
            </div>
            </div>
            <div className={classes.source}>
            <div style={{ width: "100%", marginBottom: "10px" }}>
              <Input
                name="ReceiverAddress"
                placeholder="Receiver Address "
                //  //handleChange={handleChange}
                value={serviceDetails && serviceDetails.ReceiverAddress}
                readOnly={true}
              />
            </div>
            </div>
            <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
              <Input
                name="hsnOfCode"
                placeholder="HSN code of Goods"
                //  //handleChange={handleChange}
                value={serviceDetails && serviceDetails.hsnOfCode}
                readOnly={true}
              />
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
              <Input
                  options={Currenciesr}
                  name="currencyType"
                  placeholder="Currency"
                  value={ CheckCurrencyTitle(serviceDetails.currencyType)}
                //  //handleChange={handleChange}
                />

              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="currencyAmount"
                  placeholder=  {`Trade Amount (${serviceDetails && serviceDetails.currencyType})`}
                  //  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.currencyAmount}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  //   options={qtyUnitOptions}
                  name="weightType"
                  placeholder="Quantity"
                  value={serviceDetails && serviceDetails.weightType}
                //  //handleChange={handleChange}
                />

              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="quantity"
                  placeholder="Quantity"
                  //  //  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.quantity}
                  readOnly={true}
                />
              </div>
            </div>



            <div className={classes.source}>


            </div>
          </div>










          <div style={{ margin: "10px" }}>
            <label>Contact information for the person responsible for the shipment
            </label>
          </div>
          <div style={{ width: "100%", margin: "10px", paddingRight:"20px" }}>
            <TextArea
              name="contactInfo"
              placeholder=" "
              //  //  //handleChange={handleChange}
              value={serviceDetails && serviceDetails.contactInfo}
              readOnly={true}
            />
          </div>

          <div style={{ margin: "10px" }}>
            <label>Any special instructions or requirements for the Customs Clearance Inspection

            </label>
          </div>
          <div style={{ width: "100%", margin: "10px", paddingRight:"20px" }}>
            <TextArea

              name="requirement"
              placeholder=" Requirement "
              //  //  //handleChange={handleChange}
              value={serviceDetails && serviceDetails.requirement}
              readOnly={true}
            />
            {/* <textarea
                  style={{ height: "7rem", width: "100%" , background :"Black"}}
                  type="textarea"
                  name="requirement"
                        value={serviceDetails && serviceDetails.requirement}
                  onChange={(e) => handleChange(e, "requirement")}
                /> */}
          </div>














        </> : <></>
      }
    </>
  )
}

export default CustomInspection;