import React, { useState, useEffect, useMemo } from "react";
import Document from "pages/Document/index";
import { HiArrowNarrowLeft } from "react-icons/hi";
import classes from "./documentSection.module.css";
import DocumentUpload from "./DocumentUpload";
import {
  CompanyDocuments,
  ServiceContractDocuments,
  PaymentsDocuments,
  DocumentGroup,
} from "utils/DropDown/ServiceDealDocument";

const AllDocument = ({
  serviceDealDetails,
  hide,
  setHide,
  CategoryListURL,
  setCategoryListURL,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const { CompanyCategory, CompanySubCategory } = CompanyDocuments(null);
  const CompanyURL = [];
  CompanyCategory.map((Service) => CompanyURL.push(Service.value));

  const [selectedServiceCategory, setSelectedServiceCategory] = useState("");
  const { ServiceContractCategory, ServiceContractSubCategory } =
    ServiceContractDocuments(selectedServiceCategory || null);
  const ServiceURL = [];
  ServiceContractCategory.map((Service) => ServiceURL.push(Service.value));

  const [selectedPaymentCategory, setSelectedPaymentCategory] = useState("");
  const { PaymentCategory, PaymentSubCategory } = PaymentsDocuments(
    selectedPaymentCategory || null
  );

  const PaymentURL = [];
  PaymentCategory.map((Service) => PaymentURL.push(Service.value));

  const [SelectedCategory, setSelectedCategory] = useState(null);
  const [CategoryOption, setSelectedCategoryOption] = useState([]);
  const [SubCategoryOption, setSelectedSubCategoryOption] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (serviceDealDetails && hide) {
        let ServiceCategory =
          serviceDealDetails &&
          serviceDealDetails.serviceCategory &&
          serviceDealDetails.serviceCategory[0];

        let ServiceSubCategory =
          serviceDealDetails &&
          serviceDealDetails.serviceSubCategory &&
          serviceDealDetails.serviceSubCategory[0];

        if (hide === "Requirement" || hide === "Delivery") {
          const { Category, SubCategory } = DocumentGroup(
            ServiceCategory,
            ServiceSubCategory,
            hide,
            SelectedCategory
          );
          const TEMP = [];
          Category?.map((Service) => TEMP.push(Service.value));
          setSelectedCategoryOption(Category);
          sessionStorage.setItem("CategoryListURL", JSON.stringify(TEMP));
          setSelectedSubCategoryOption(SubCategory);
          setCategoryListURL(TEMP);
        }
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [hide, SelectedCategory]);

  // TODO to be implemented or remove
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRender(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (!shouldRender) {
    return <div>Loading...</div>; // or a loading indicator
  }

  return (
    <>
      <>
        <div
          onClick={() => {
            setHide(null);
            setIsUploading(false);
            setSelectedServiceCategory("");
            setSelectedPaymentCategory("");
            setSelectedCategory(null);
            // setCategoryListURL(null);
            sessionStorage.setItem("CategoryListURL", null);
          }}
          style={{ color: "#218eeb", cursor: "pointer" }}>
          <span>
            <HiArrowNarrowLeft size={28} />
          </span>
          <span>Back to List </span>
        </div>

        {hide == "Company" && (
          <Document
            serviceDealDetails={serviceDealDetails}
            serviceDealLocations={{
              serviceDealLocation: "companyDoc",
              docCategoryList: CompanyURL?.length > 0 ? CompanyURL : ["OTHERS"], // Working
            }}
          />
        )}

        {hide == "Contracts" && (
          <>
            {isUploading ? (
              <div>
                <DocumentUpload
                  selectedCategory={selectedServiceCategory} // useState
                  setSelectedCategory={setSelectedServiceCategory} // useState
                  Category={ServiceContractCategory}
                  SubCategory={ServiceContractSubCategory}
                  setIsUploading={setIsUploading}
                  response={serviceDealDetails}
                  location={"contractsDoc"}
                />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <button
                    className={classes.UploadBtn}
                    onClick={() => setIsUploading(true)}>
                    Upload
                  </button>
                </div>
                <Document
                  serviceDealDetails={serviceDealDetails}
                  serviceDealLocations={{
                    serviceDealLocation: "contractsDoc",
                    docCategoryList:
                      ServiceURL?.length > 0 ? ServiceURL : ["OTHERS"], // Working
                  }}
                />
              </>
            )}
          </>
        )}

        {hide == "Payments" && (
          <>
            {isUploading ? (
              <div>
                <DocumentUpload
                  selectedCategory={selectedPaymentCategory}
                  setSelectedCategory={setSelectedPaymentCategory}
                  Category={PaymentCategory}
                  SubCategory={PaymentSubCategory}
                  setIsUploading={setIsUploading}
                  response={serviceDealDetails}
                  location={"paymentsDoc"}
                />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <button
                    className={classes.UploadBtn}
                    onClick={() => setIsUploading(true)}>
                    Upload
                  </button>
                </div>
                <Document
                  serviceDealDetails={serviceDealDetails}
                  serviceDealLocations={{
                    serviceDealLocation: "paymentsDoc",
                    docCategoryList:
                      PaymentURL?.length > 0 ? PaymentURL : ["OTHERS"], // Working
                  }}
                />
              </>
            )}
          </>
        )}

        {hide == "Requirement" && (
          <>
            {isUploading ? (
              <div>
                <DocumentUpload
                  selectedCategory={SelectedCategory} // useState
                  setSelectedCategory={setSelectedCategory} // useState
                  Category={CategoryOption} // Drop down option
                  SubCategory={SubCategoryOption} // Drop down option
                  setIsUploading={setIsUploading}
                  response={serviceDealDetails}
                  location={"requirementDoc"}
                />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <button
                    className={classes.UploadBtn}
                    onClick={() => setIsUploading(true)}>
                    Upload
                  </button>
                </div>
                <Document
                  serviceDealDetails={serviceDealDetails}
                  serviceDealLocations={{
                    serviceDealLocation: "requirementDoc",
                    docCategoryList:
                      JSON.parse(sessionStorage.getItem("CategoryListURL"))
                        ?.length > 0
                        ? JSON.parse(sessionStorage.getItem("CategoryListURL"))
                        : ["OTHERS"],
                  }}
                />
              </>
            )}
          </>
        )}

        {hide == "Delivery" && (
          <>
            {isUploading ? (
              <div>
                <DocumentUpload
                  selectedCategory={SelectedCategory} // useState
                  setSelectedCategory={setSelectedCategory} // useState
                  Category={CategoryOption} // Drop down option
                  SubCategory={SubCategoryOption} // Drop down option
                  setIsUploading={setIsUploading}
                  response={serviceDealDetails}
                />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-end">
                  <button
                    className={classes.UploadBtn}
                    onClick={() => setIsUploading(true)}>
                    Upload
                  </button>
                </div>
                <Document
                  serviceDealDetails={serviceDealDetails}
                  serviceDealLocations={{
                    serviceDealLocation: "deliveryDoc",
                    docCategoryList:
                      CategoryListURL?.length > 0
                        ? CategoryListURL
                        : ["OTHERS"],
                  }}
                />
              </>
            )}
          </>
        )}
      </>
    </>
  );
};

export default AllDocument;
