import styled from "styled-components";

const Style = styled.div`
  .main {
    width: 97%;
    padding: 20px;
    background: #000000;
    border-radius: 8px;
  }
  .cardWrapper {
    border: 1px solid transparent;
    margin-top: 20px;
    background: rgba(255, 255, 255, 0.05);
    margin-bottom: 15px;
    border-radius: 10px;
  }
  .tableWrapper {
    border: 1px solid transparent;
    margin: 2%;
    background: rgba(25, 25, 25, 25);
    border-radius: 8px;
  }
  .navbar {
    display: flex;
    div {
      width: 25%;
      text-align: center;
    }
  }
  .heading {
    margin-top: 20px;
    display: flex;
    margin-left: 10px;
    div {
      width: 50%;
      text-align: left;
      margin-top: 18px;
    }
    .btn {
      width: 50%;
      text-align: right;
      button {
        width: 152px;
        height: 53px;
        background: #42a5f5;
      }
    }
  }
  .iconDiv {
    display: flex;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    background: #ffffff0d;
    height: 170px;
    margin-bottom: 20px;
    border-radius: 5px;
    .item {
      text-align: center;
      margin: 10px;
      width: 20%;
      padding-top: 15px;
      margin-top: 30px;
    }
  }
  .font {
    font-family: Roboto;
    font-style: Medium;
    font-size: 40px;
    line-height: 13.5px;
    line-height: 29%;
    align: Center;
    vertical-align: Top;
    paragraph-spacing: 15px;
    margin-bottom: 20px;
  }
  .firstgraph {
    margin-top: 93px;
    width: 40%;
    height: 100px;
    background: rgb(224, 224, 222);
    margin-left: 30%;
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 15px;
    div {
      margin-top: -65px;
      @media (max-width: 1200%) {
        margin-top: -65px;
      }
      @media (max-width: 1100px) {
        margin-top: -40px;
      }
      @media (max-width: 900px) {
        margin-top: -20px;
      }
    }
  }
  .repayment {
    text-align: right;
  }
  .graphbtn {
    margin-left: 40px;
    width: 120px;
    height: 40px;
    padding-right: 30px;
    background: transparent;
    border: 1px solid #218eeb;
    color: #218eeb;
    border-radius: 5px;
    span {
      position: absolute;
      margin-left: 20px;
    }
  }
  .dropdownlist {
    width: 118px;
    margin-left: 40px;
    border: 1px solid #218eeb;
    border-radius: 5px;
    border-top: none;
    div {
      text-align: center;
      color: #218eeb;
    }
  }
  .tableheading {
    display: flex;
    div {
      display: flex;
      width: 50%;
    }
    .heading {
      font-family: Roboto;
      font-style: Bold;
      font-size: 18px;
      line-height: 24px;
      line-height: 68%;
      align: left;
      vertical-align: Top;
      paragraph-spacing: 32px;
    }
    .seeall {
      margin-top: 45px;
      font-family: Roboto;
      font-style: Bold;
      font-size: 16px;
      line-height: 14px;
      line-height: 60%;
      align: center;
      vertical-align: top;
    }
  }
  .tableheader {
    display: flex;
    background: #343434;
    border-radius: 5px;
    /* margin: 2%; */
    div {
      display: flex;
      width: 17%;
      height: 50.47px;
      align-items: center;
      justify-content: center;
      border: 0.1px solid lightgray;
      border-bottom: none;
      border-top: none;
    }
  }
  .firsttable {
    padding: 16px;
    background: #ffffff0d;
    border-radius: 5px;
    /* margin: 2%; */
  }
  .row {
    display: flex;
    background: black;
    div {
      display: flex;
      width: 17%;
      height: 50.47px;
      align-items: center;
      justify-content: center;
      border: 0.1px solid lightgray;
      overflow: hidden;
    }
  }
  .row1 {
    display: flex;
    background: black;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    div {
      display: flex;
      width: 17%;
      height: 50.47px;
      align-items: center;
      justify-content: center;
      border: 0.1px solid lightgray;
      border-bottom: none;
      overflow: hidden;
    }
  }
  .table {
    margin-top: 30px;
  }
  .rows {
    margin-top: -4px;
  }
  .tablechart {
    /* margin-top:80px; */
    border-radius: 10px;
    margin: 2%;
  }
  .container {
    background-color: pink;
  }
`;

export default Style;
