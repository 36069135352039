import React, { useState } from 'react'
import { DDColumn } from "./column";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Button } from '@mui/material';
import { FaDownload } from "react-icons/fa";
import { StepperOne } from './StepperOne';
import TableNoChat from "components/table/TableNoChat";
import styles from './syndicate.module.css'
import FinancerTable from './FinancerTable';
import StepperThree from './StepperThree';
function TrancheDetails(props) {
    const [overview, setOverview] = useState(false);
    const [drawdown, setDrawdown] = useState(false);
    const [dd, setDd] = useState(false);
    const [disbursement, setDisbursement] = useState(false);
    const [showFinancersTable, setShowFinancersTable] = useState(false);
    
    const {companyId} = props;
    
    const api_response2 = [
        {
            "bank": "ABC",
            "reference": "Ref 1",
            "amount": "10,000",
            "dis_date": "01-01-2022",
            "overdue": "12 Days",
            "action": ["Modify"]
        },
        {
            "bank": "DEF",
            "reference": "Ref 2",
            "amount": "20,000",
            "dis_date": "01-01-2022",
            "overdue": "N/A",
            "action": ["New"]
        }
    ]
    
    const stepOne = {
        data: props.data.apiData,
        handleChange: props.data.handleChange,
        idx:props?.data?.idx,
        companyId
    }
  

    const enableDrawdown = props.data.enableDrawdown;
    const showDrawdown = props.data.showDrawdown;
    const changeFinancer = props.data.changeFinancer;
    const handleChange = props.data.handleChange;
    const enableComp = props.data.enableComp;
    const enableStepper2 = props.data.enableStepper2;
    const removeFinancer = props.data.removeFinancer;
    const removeDrowdown = props.data.removeDrowdown;
    const addDrawdown = props.data.addDrawdown;
    const onSubmit = props.data.onSubmit;
    const handleAccept = props.data.handleAccept;
    const handleReject = props.data.handleReject;
    const showTable = props.data.showTable;
    const setShowTable = props.data.setShowTable;
    const handleAcceptDD = props.data.handleAcceptDD;
    const handleRejectDD = props.data.handleRejectDD;
    const editTranche = props.data.editTranche;
    const finacerTable = () => {
        setShowFinancersTable(true)
        props.data.showfinacerTable();
    }

   

    const changeStructure = () => {
        //setShowFinancersTable(false)
        //enableDrawdown()
        enableStepper2();
    }
    
    const Tranch = {
        trancheData: props.data.tranchelist,
        backFormTranchTable:props.data.backFormTranchTable,
        enableDrawdown,
        apiData:props.data.apiData,
        showDrawdown,
        // data: props.data.data,
        drawdownData: props.data.drawdownData,
        resetFlow: props.data.resetFlow,
        handleChange,
        enableComp,
        enableDrawdown,
        changeStructure,
        companyId,
         //changeStructure:props.data.changeStructure,
         cancelDraw: props.data.cancelDraw,
         removeFinancer,
         idx:props?.data?.idx,
         handleAccept,
         handleReject,
         showTable,
         setShowTable,
         editTranche
    }

    const Draw = {
        data: props.data.drawdownList,
        showDrawdown,
        enableDrawdown,
        resetFlow: props.data.resetFlow,
        cancelDraw: props.data.cancelDraw,
        removeDrowdown,
        addDrawdown,
        companyId,
        apiData:props.data.apiData,
        backFormTranchTable:props.data.backFormTranchTable,
        idx:props?.data?.idx,
        handleAcceptDD,
        handleRejectDD,
        showTable,
         setShowTable
    }
    return (
        <div className='innerpadding'>
            <button type="button" className={styles.collapsible} onClick={() => setOverview(!overview)} style={{ background: "rgb(93, 93, 93)", margin: "0px 0px 5px 0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between"}}>
                    <b style={{ fontSize: "14px", fontWeight: "700", color: "white"}}>
                        Tranche Details
                    </b>
                    {overview ? <FiChevronUp size={25} color="#42A5F5" /> : <FiChevronDown size={25} />}
                </div>
            </button>
            {overview &&
                 <div className={styles.wrapper}>
                    <StepperOne data={stepOne} />
                    <br></br>
                    <br></br>
                    {(props?.data?.apiData?.loanTranches[props?.data?.idx]?.loanTrancheStatus==="DRAFT") && (props.data.tranchelist?.length>0 && props?.data?.apiData?.financerMemberUID === companyId) &&(
                    <div style={{textAlign:"right" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ minWidth: "200px", margin: "0px 20px" }}
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </div>)}
                </div>
            }
            <br></br>
            <button type="button" className={styles.collapsible} onClick={() => setDrawdown(!drawdown)} style={{ background: "rgb(93, 93, 93)", margin: "0px 0px 5px 0px", }}>
                <div style={{ display: "flex", justifyContent: "space-between"  }}>
                    <b style={{ fontSize: "14px", fontWeight: "700", color: "white"  }}>
                        Tranche Items
                    </b>
                    {overview ? <FiChevronUp size={25} color="#42A5F5" /> : <FiChevronDown size={25} />}
                </div>
            </button>
           
            {drawdown && 
            <div className={styles.wrapper}>
                <FinancerTable data={Tranch} companyId={companyId} /> 
            </div>
            }
           

            <br></br>
            <button type="button" className={styles.collapsible} onClick={() => setDd(!dd)} style={{ background: "rgb(93, 93, 93)", margin: "0px 0px 5px 0px", }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <b style={{ fontSize: "14px", fontWeight: "700", color: "white" }}>
                        Drawdowns Items
                    </b>
                    {overview ? <FiChevronUp size={25} color="#42A5F5" /> : <FiChevronDown size={25} />}
                </div>
            </button>

            {dd &&
            <div className={styles.wrapper}>
                <StepperThree {...Draw} />

                {(props?.data?.apiData?.loanTranches[props?.data?.idx]?.loanTrancheStatus==="DRAFT") && (props.data.tranchelist?.length>0 && props?.data?.apiData?.financerMemberUID === companyId) &&(
                <div style={{textAlign:"right" }}>
                <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "150px", margin: "0px 5px" }}
                onClick={() => enableDrawdown()}
            >
                Add Drawdown
            </Button>
            </div>)}
            </div>
            }

            {!props.loanstructure &&
                <>
                    <br></br>
                    <button type="button" className={styles.collapsible} onClick={() => setDisbursement(!disbursement)} style={{ background: "rgb(93, 93, 93)", margin: "0px 0px 5px 0px", }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <b style={{ fontSize: "14px", fontWeight: "700", color: "white" }}>
                                Disbursements
                            </b>
                            <h4 style={{ margin: "0px" }}>
                                <span style={{ fontSize: "14px", color: "#42A5F5" }}> DownLoad All </span> <FaDownload size={20} style={{ marginLeft: "10px" }} />
                            </h4>
                        </div>
                    </button>
                </>}
            {disbursement && !props.loanstructure &&
                <div className={styles.wrapper}>
                    <div className={styles.tableContainer}>
                        {Array.isArray(api_response2) ? (
                            <TableNoChat financerMemberUID={props?.data?.apiData?.financerMemberUID} columns={DDColumn} data={api_response2} companyId={companyId} />
                        ) : (
                            <p>No Records</p>
                        )}
                    </div>
                </div>
            }
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div style={{ display: "flex", justifyContent: "end" }}>

            {(props?.data?.apiData?.loanTranches[props?.data?.idx]?.loanTrancheStatus==="DRAFT") && (props.data.tranchelist?.length>0 && props?.data?.apiData?.financerMemberUID === companyId) &&(
                <>
                   
                    <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "150px", margin: "0px 5px" }}
                onClick={onSubmit}
            >
               Update Tranche
            </Button>
            <Button
                        variant="contained"
                        color="error"
                        style={{ minWidth: "200px" }}
                        onClick={() => console.log("delete")}
                    >
                        Delete Tranche
                    </Button>
                    </>
                   
            )}
            </div>


           
        </div>

    )
}

export default TrancheDetails