import React, { useState } from "react";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Styles from "../styles";
import { useNavigate } from "react-router-dom";
import { serviceURL } from "services/BaseURLInstance";
import { Success, Error } from "components/notifications/responseHandle";
import { ToastContainer } from "react-toastify";
import {
  AddServiceRequisitions,
  AddServiceRequisitionsINSURENCECredit,
} from "utils/Validations/Services";

import _ from "lodash";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Loader from "components/Loader/Loader";

const ServicrProviders = ({
  requisitionData,
  setRequisitionData,
  freightData,
  freightCargoDataList,
  transportData,
  transportCargoDataList,
  warehouseData,
  warehouseCargoDataList,
  insuranceData,
  insuranceCreditData,
  containerData,
  customeClearanceData,
  tradeData,
  customInspectionData,
  inspectionQualityData,
  regData,
  CreditData,
  complianceData,
  importData,
  exportData,
  tradesData,
  steveData,
  fiData,

  // tableCargoData,
}) => {
  const [requisitionResponse, setRequisitionResponse] = useState(false);
  const [requisitionResponseData, setRequisitionResponseData] = useState(null);
  const tableCargoData = JSON.parse(sessionStorage.getItem("tableCargoData"));
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const navigate = useNavigate();
  const tabletransportLoadData = JSON.parse(
    sessionStorage.getItem("tabletransportLoadData")
  );

  const onChangeValue = (e) => {
    setRequisitionData({
      ...requisitionData,
      serviceProviderPreferences: e.target.value,
    });
  };
  const checkService = JSON.parse(
    sessionStorage.getItem("selectedServiceList")
  );

  const handleSendRequest = async () => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));

    let Check = AddServiceRequisitions(requisitionData);
    if (Check) {
      Error(Check);
      return;
    } else {
      requisitionData.referenceData = [];

      if (checkService && checkService[0].serviceCategory[0] === "FREIGHT") {
        freightData.cargoDetails = freightCargoDataList;
        requisitionData.ServiceReqDetails = JSON.stringify(freightData);
      }

      if (checkService && checkService[0].serviceCategory[0] === "TRANSPORT") {
        transportData.cargoDetails = transportCargoDataList;
        requisitionData.ServiceReqDetails = JSON.stringify(transportData);
      }

      if (checkService && checkService[0].serviceCategory[0] === "WAREHOUSE") {
        warehouseData.cargoDetails = warehouseCargoDataList;
        requisitionData.ServiceReqDetails = JSON.stringify(warehouseData);
      }

      if (checkService && checkService[0].serviceCategory[0] === "CONTAINER") {
        requisitionData.ServiceReqDetails = JSON.stringify(containerData);
      }
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "COMPLIANCE" &&
        checkService[0].serviceSubCategory[0] === "TRADE"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(complianceData);
      }
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "COMPLIANCE" &&
        checkService[0].serviceSubCategory[0] === "IMPORT_DECLARATIONS"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(importData);
      }

      if (
        checkService &&
        checkService[0].serviceCategory[0] === "TRADE_CONTRACT" &&
        checkService[0].serviceSubCategory[0] === "OTHERS"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(tradesData);
      }

      if (
        checkService &&
        checkService[0].serviceCategory[0] === "CUSTOMS" &&
        checkService[0].serviceSubCategory[0] === "CUSTOMS_INSPECTION"
      ) {
        requisitionData.ServiceReqDetails =
          JSON.stringify(customInspectionData);
      }
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "STEVEDORING" &&
        checkService[0].serviceSubCategory[0] === "OTHERS"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(steveData);
      }

      if (
        checkService &&
        checkService[0].serviceCategory[0] === "CUSTOMS" &&
        checkService[0].serviceSubCategory[0] === "CUSTOM_CLEARANCE"
      ) {
        requisitionData.ServiceReqDetails =
          JSON.stringify(customeClearanceData);
      }

      //compliance export
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "COMPLIANCE" &&
        checkService[0].serviceSubCategory[0] === "EXPORT_DECLARATIONS"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(exportData);
      }

      if (
        checkService &&
        checkService[0].serviceCategory[0] === "INSURANCE" &&
        checkService[0].serviceSubCategory[0] !== "CREDIT"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(insuranceData);
      }
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "INSPECTION" &&
        checkService[0].serviceSubCategory[0] !== "OTHERS"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(regData);
      }
      // if (checkService && (checkService[0].serviceCategory[0] === "CUSTOMS") && (checkService[0].serviceSubCategory[0] !== "CUSTOM_CLEARANCE") ) {
      //   requisitionData.ServiceReqDetails = JSON.stringify(customInspectionData);

      // }
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "FINANCING" &&
        checkService[0].serviceSubCategory[0] === "CREDIT_ASSESSMENT"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(CreditData);
      }

      if (
        checkService &&
        checkService[0].serviceCategory[0] === "FINANCING" &&
        checkService[0].serviceSubCategory[0] === "FREIGHT_FINANCING"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(fiData);
      }

      if (
        checkService &&
        checkService[0].serviceCategory[0] === "INSURANCE" &&
        checkService[0].serviceSubCategory[0] === "CREDIT"
      ) {
        let checkValidation =
          AddServiceRequisitionsINSURENCECredit(insuranceCreditData);
        if (checkValidation) {
          Error(checkValidation);
          return;
        }
        requisitionData.ServiceReqDetails = JSON.stringify(insuranceCreditData);
      }
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "INSPECTION" &&
        checkService[0].serviceSubCategory[0] === "QUALITY"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(
          inspectionQualityData
        );
      }
      if (
        checkService &&
        checkService[0].serviceCategory[0] === "INSPECTION" &&
        checkService[0].serviceSubCategory[0] === "OTHERS"
      ) {
        requisitionData.ServiceReqDetails = JSON.stringify(regData);
      }
      if (tradeData !== "") {
        requisitionData["referenceData"] = tradeData;
      }

      const options = {
        method: "POST",
        body: requisitionData,
      };

      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${serviceURL}services/requisitions`,
          options,
          response
        ).then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something went wrong");
          } else {
            setRequisitionResponse(true);
            setRequisitionResponseData(data);
            Success("Added Successfully");
            if (tradeData !== "") {
              navigate(`../../tradeover/tradeflow/${tradeData[0].value}`);
            } else {
              navigate("../service-requests");
            }
            setIsLoading(false);
          }
        });
      });
    }
  };
  sessionStorage.setItem("freightData", JSON.stringify(freightData));

  const handleCancel = () =>{
    if (tradeData !== "") {
      navigate(`../../tradeover/tradeflow/${tradeData[0].value}`);
    } else {
      navigate(-1);
    }
  }
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Styles>
          {
            <div className="my-2">
              <div>Select Service Providers</div>
              <div style={{ display: "flex" }}>
                <div onChange={onChangeValue}>
                  <input
                    style={{ marginTop: "20px" }}
                    type="radio"
                    value="All"
                    name="All"
                    disabled
                  />{" "}
                  <br />
                  <input
                    style={{ marginTop: "20px" }}
                    type="radio"
                    value="Selected"
                    name="Selected"
                    checked="checked"
                  />{" "}
                  <br />
                  <input
                    style={{ marginTop: "20px" }}
                    type="radio"
                    value="Favourites"
                    name="Favourites"
                    disabled
                  />
                </div>
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      marginTop: "15px",
                      backgroundColor: "transparent",
                      border: "3px solid #42A5F5",
                      borderRadius: "5px",
                    }}>
                    Publish to All Providers
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      marginTop: "30px",
                      backgroundColor: "transparent",
                      border: "3px solid #42A5F5",
                      borderRadius: "5px",
                    }}>
                    Publish to Selected Providers
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      marginTop: "30px",
                      backgroundColor: "transparent",
                      border: "3px solid #42A5F5",
                      borderRadius: "5px",
                    }}>
                    Publish to Favourites
                  </div>
                </div>
              </div>
            </div>
          }
          {requisitionResponse && (
            <div style={{ color: "lightgreen", marginLeft: "2%" }}>
              Your Service Request has been sent to the selected Service
              Providers
            </div>
          )}
          <ButtonDiv>
            <Button onClick={handleSendRequest}>Submit</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </ButtonDiv>
        </Styles>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default ServicrProviders;
