import React, { useState } from "react";
import styled from "styled-components";

import {
  FaHandshake,
  FaFileAlt,
  FaFileInvoice,
  FaRegUser,
  FaRocket,
  FaHome,
} from "react-icons/fa";
import { NavLink } from "react-router-dom";
import {
  MdHelp,
  MdOutlineSpeed,
  MdOutlineDesignServices,
  MdOutlineDashboardCustomize,
  MdOutlinePayment,
} from "react-icons/md";
import { FaNetworkWired } from "react-icons/fa";
import { VscGraph } from "react-icons/vsc";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Tooltip from "react-bootstrap/Tooltip";

const Sidebar = ({ children, isOpen, toggleChat }) => {
  const menuItem = [
    {
      path: "/",
      name: "Dashboard",
      icon: <FaHome />,
      // <MdOutlineSpeed />,
    },
    {
      path: "/tradeover",
      name: "Trades",
      icon: <FaHandshake />,
    },

    {
      path: "/financing",
      name: "Apps",
      icon: <MdOutlineDashboardCustomize />,
      // <FaFileInvoice />,
    },
    {
      path: "/services",
      name: "Services",
      icon: <MdOutlineDesignServices />,
    },
    {
      path: "/networks",
      name: "Networks",
      icon: <FaNetworkWired />,
    },
    {
      path: "/documents",
      name: "Documents",
      icon: <FaFileAlt />,
    },
    {
      path: "/payment",
      name: "Payment",
      icon: <MdOutlinePayment />,
      // <FaRocket />,
    },
    {
      path: "/mistrade",
      name: "MIS",
      icon: <VscGraph />,
      // <FaFileAlt />,
    },
    {
      path: "/profile",
      name: "Profile",
      icon: <FaRegUser />,
    },

    {
      path: "/help",
      name: "Help",
      icon: <MdHelp />,
    },
  ];
  return (
    <Wrapper
      style={
        isOpen
          ? { width: "215px", minWidth: "215px" }
          : { width: "54px", minWidth: "54px", background: "#0d48a1" }
      }
      className="sidebar"
    >
      {menuItem.map((item, index) => (
        <NavLink
          onClick={() => toggleChat("global", "sidebar")}
          to={item.path}
          key={index}
          className={({ isActive }) => (isActive ? "link active" : "link")}
        >
          {isOpen ? (
            <div className="icon">{item.icon}</div>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ padding: "2px", backgroundColor: "blue" }}>
                  {item.name}
                </Tooltip>
              }
            >
              <div className="icon">{item.icon}</div>
            </OverlayTrigger>
          )}
          <div
            style={{ display: isOpen ? "block" : "none" }}
            className="link_text"
          >
            {item.name}
          </div>
        </NavLink>
      ))}
    </Wrapper>
  );
};

export default Sidebar;

const Wrapper = styled.div`
  background: #1e1e1e;
  color: #fff;
  min-height: 100vh;
  width: 215px;
  min-width: 215px;
  transition: all 0.5s;
  .link {
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
    text-decoration: none;
    border: 1px solid rgba(33, 142, 235, 0.1);
    &:hover {
      background: #42a5f5;
      color: #fff;
      transition: all 0.5s;
    }
  }
  .icon,
  .link_text {
    font-size: 17px;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
  }
  .active {
    background: #42a5f5;
    color: #fff;
  }
`;
const Container = styled.div`
  padding: 26px;
  // background: red;
  flex: 1;
  font-size: 18px;
`;
