import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import { useNavigate } from "react-router-dom";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { RiWechatLine } from "react-icons/ri";
import classes from "../../components/UI/Table/GlobalTable.module.css";

import { truncateStringID } from "utils/helper";
const Styles = styled.div`
  table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    letter-spacing: 1px;
    thead {
      // border-bottom: 0.1px solid white;
      // color: #1d9bf0;
      background: #343434;

      tr {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        th {
          border-right: 1px solid white;
          color: white;
          letter-spacing: 1px;
          font-weight: 200;
        }
        th:first-child {
          border-top-left-radius: 5px;
        }
        th:last-child {
          border-top-right-radius: 5px;
        }
      }
      th,
      td {
        margin: 0;
        padding: 12px 0px;
        border-bottom: 1px solid white;
        :last-child {
          border-right: 0;
        }
      }
    }

    tbody {
      tr {
        cursor: default;
        td {
          div {
            cursor: pointer;
            &:hover {
              color: #1d9bf0;
              text-decoration: underline;
            }
          }
          border-right: 0.1px solid white;
          border-bottom: 0.1px solid white;
          text-align: center;
        }

        &:hover {
          opacity: 0.5;
        }
        :last-child {
          border-bottom: 0.1px solid white;
        }
      }

      th,
      td {
        margin: 0;
        padding: 8px;
        border-bottom: 0.1px solid white;
        :last-child {
          border-right: 0;
        }
        // button {
        //   padding: 0.2rem;
        // }
      }
    }
  }
  /* .pagination {
    padding: 0.5rem;
    font-weight: 200;
    button {
      padding: 0.5rem;
    }
    select {
      padding: 0.5rem;
      border: 1px solid rgba(33, 142, 235, 0.4);
      border-radius: 5px;
    }
  } */
`;

const Table = ({ columns, data, hanndleCraateGroup }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );
  const { instance, accounts } = useMsal();
  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const navigate = useNavigate();

  const fetchDatas = async (companyId, id2, name) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        setMemberList(nData);
        hanndleCraateGroup(nData, name, name);
      });
  };
  const chatHandler = (ds) => {
    if (
      ds?.buyerMemberId !== undefined &&
      ds?.buyerMemberId !== "" &&
      ds?.sellerMemberId !== "" &&
      ds?.sellerMemberId !== undefined
    ) {
      setItem(ds);
      let name = ds.tradeId ? truncateStringID(ds.tradeId, 7, "TR") : "NA";
      fetchDatas(ds?.buyerMemberId, ds?.sellerMemberId, name);
    }
    if (
      ds?.invoiceNumber !== undefined &&
      ds?.invoiceNumber !== "" &&
      ds?.txlBuyerMemberId !== "" &&
      ds?.txlBuyerMemberId !== undefined
    ) {
      setItem(ds);
      let name = ds.invoiceNumber;
      fetchDatas(ds?.txlBuyerMemberId, memberId, name);
    }
  };
  // const hanndleCraateGroup =()=>{

  //   if(memberList?.length>0){
  //     let url = window.location.href;
  //     localStorage.setItem("lasturl", url);
  //     const selectedUsers = memberList;
  //     const params = new sdk.GroupChannelParams();
  //     params.addUserIds(selectedUsers);
  //     params.isDistinct = true;
  //     params.isEphemeral = false;
  //     params.isPublic = false;

  //     params.name =  item.tradeId
  //     ? truncateStringID(item.tradeId, 7, "TR")
  //     : "NA"
  //     sdk.GroupChannel.createChannel(params, (response, error) => {
  //       const swapParams = sdk.getErrorFirstCallback();
  //       let groupChannel = response;
  //       let err = error;
  //       if (swapParams) {
  //         groupChannel = error;
  //         err = response;
  //       }
  //       if (err) {
  //         navigate("/chat?url=" + "NW-" + item.memberUID);
  //       }
  //       else{
  //        navigate("/chat?url=" + groupChannel.url);
  //       }
  //      });

  //     }
  // }

  // Render the UI for your table
  const renderCode = (fields) => (
    <pre>
      <code>{JSON.stringify(fields, null, 5)}</code>
    </pre>
  );
  return (
    <Styles>
      <>
        <table {...getTableProps()} key="table">
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={`thead_${i}`}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        width: 100,
                        textAlign: "center",
                        fontSize: "90%",
                      },
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
                <th
                  style={{ width: 100, textAlign: "center", fontSize: "90%" }}
                >
                  Action
                </th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={`tbody_${i}`}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        className="rahul"
                        {...cell.getCellProps({
                          style: {
                            width: 100,
                            textAlign: "center",
                            fontSize: "90%",
                          },
                        })}
                      >
                        {cell.column.id == "communication"
                          ? renderCode(cell.value)
                          : cell.render("Cell")}
                      </td>
                    );
                  })}
                  <td>
                    <div className={classes["flex-1"]}>
                      <span
                        className="px-3"
                        onClick={(e) => chatHandler(row?.values)}
                      >
                        <RiWechatLine size={22} />
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        {/* <div className="pagination" key="pagination">
          <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </button>{" "}
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            {"<"}
          </button>{" "}
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>{" "}
          <span>
            Page{" "}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          {/* <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPage(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '} */}
        {/* <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        // </div> */}
      </>
    </Styles>
  );
};
export default Table;
//https://codesandbox.io/s/react-table-full-width-resizable-046k
