import {
  AiFillSetting,
  AiOutlineUsergroupDelete,
  AiTwotoneVideoCamera,
  AiFillAccountBook,
  AiOutlineBook,
  AiTwotoneContainer,
  AiTwotoneLike,
  AiTwotoneCreditCard,
  AiOutlineSolution,
  AiFillCheckSquare,
  AiFillProject,
  AiOutlineFileSync,
  AiFillContacts,
  AiOutlineShoppingCart,
  AiOutlineFileDone,
} from "react-icons/ai";

import { FcApproval } from "react-icons/fc";
import { FaShip } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
import { BsFillPersonLinesFill, BsTruck } from "react-icons/bs";

import { TbMoneybag } from "react-icons/tb";
import { MdOutlineFactory } from "react-icons/md";

export const DealWorkFlow = {
  // Service deals
  icons: {
    1: <AiOutlineBook size={24} />,
    2: <AiOutlineUsergroupDelete size={24} />,
    3: <AiFillProject size={24} />,
    4: <AiOutlineSolution size={24} />,
    5: <AiTwotoneContainer size={24} />,
    6: <AiFillProject size={24} />,
    7: <AiFillSetting size={24} />,
    8: <AiTwotoneCreditCard size={24} />,
    9: <AiFillCheckSquare size={24} />,
  },
  steps: [
    "New",
    "Interest Expressed",
    "In-Pursuit",
    "Quote",
    "Terms & Conditions",
    "Deal",
    "Service Delivery",
    "Payments",
    "Completed",
  ],
};

export const DealWorkFlowDecline = (value) => {
  // Service deals
  return {
    icons: {
      1: <AiOutlineBook size={24} />,
      2: <AiOutlineUsergroupDelete size={24} />,
      3: <AiFillProject size={24} />,
      4: <AiFillSetting size={24} />,
      5: <AiOutlineUsergroupDelete size={24} />,
      6: <AiFillProject size={24} />,
      7: <AiFillSetting size={24} />,
      8: <AiOutlineUsergroupDelete size={24} />,
      9: <AiTwotoneVideoCamera size={24} />,
    },
    steps: [
      "New",
      "Interest Expressed",
      "In-Pursuit",
      "Quote",
      "Terms & Conditions",
      "Deal",
      "Service Delivery",
      "Payments",
      `${value}`,
    ],
  };
};

// export const TradeFirstFlow = {
//   icons: {
//     1: <AiFillSetting />,
//     2: <AiOutlineUsergroupDelete />,
//     3: <AiTwotoneVideoCamera />,
//     4: <AiFillSetting />,
//     5: <AiOutlineUsergroupDelete />,
//     6: <AiTwotoneVideoCamera />,
//     7: <AiFillSetting />,
//     8: <AiOutlineUsergroupDelete />,
//     9: <AiTwotoneVideoCamera />,
//   },
//   steps: [
//     "New",
//     "Interest-Expressed",
//     "In-Pursuit",
//     "Quote",
//     "T&C",
//     "Bid",
//     "Service",
//     "Payments",
//     "Completed",
//   ],
// };

// export const TradeSecondFlow = {
//   icons: {
//     1: <AiFillSetting />,
//     2: <AiOutlineUsergroupDelete />,
//     3: <AiTwotoneVideoCamera />,
//     4: <AiFillSetting />,
//     5: <AiOutlineUsergroupDelete />,
//     6: <AiTwotoneVideoCamera />,
//     7: <AiFillSetting />,
//     8: <AiOutlineUsergroupDelete />,
//     9: <AiTwotoneVideoCamera />,
//   },
//   steps: [
//     "New",
//     "Interest-Expressed",
//     "In-Pursuit",
//     "Quote",
//     "T&C",
//     "Bid",
//     "Service",
//     "Payments",
//     "Completed",
//   ],
// };

// Draft
// New
// Trade Confirmed (Buyer)
// Trade In-Progress
// Trade Completion Requested (Buyer)
// Trade Completed/Aborted

export const OpenAccountFlow = {
  icons: {
    1: <AiFillSetting size={24} />,
    2: <AiOutlineBook size={24} />,
    3: <BsFillPersonLinesFill size={24} />,
    4: <FcApproval size={24} />,
    5: <AiOutlineUsergroupDelete size={24} />,
    6: <AiOutlineFileSync size={24} />,
    7: <AiFillCheckSquare size={24} />,
  },
  steps: [
    "Draft",
    "New",
    "Buyer Review",
    "Buyer Approval",
    "In-Progress",
    "Completion Request",
    "Completed",
  ],
};

export const OpenAccountFlowDecline = (value) => {
  // Service deals
  return {
    icons: {
      1: <AiFillSetting size={24} />,
      2: <AiOutlineBook size={24} />,
      3: <BsFillPersonLinesFill size={24} />,
      4: <FcApproval size={24} />,
      5: <AiOutlineUsergroupDelete size={24} />,
      6: <AiOutlineFileSync size={24} />,
      7: <AiFillCheckSquare size={24} />,
      8: <AiOutlineUsergroupDelete size={24} />,
    },
    steps: [
      "Draft",
      "New",
      "Buyer Review",
      "Buyer Approval",
      "In-Progress",
      "Completion Request",
      `${value}`,
    ],
  };
};

//   1. Draft - DRAFT
// 2. New - Nnpm  - TRADE_APPROVED/DECLINED
// 5. In-PRogress - TRADE_IN_PROGRESS
// 6. Completion Request - BUYER_TRADE_COMPLETED
// 8. Completed - TRADE_COMPLETED,SELLER_TRADE_COMPLETED
// 9. Aborted - ABORTED, OTHERS

export const FinanceDealFlow = {
  icons: {
    1: <AiOutlineBook size={24} />,
    2: <AiOutlineUsergroupDelete size={24} />,
    3: <AiTwotoneVideoCamera size={24} />,
    4: <AiFillSetting size={24} />,
    5: <AiOutlineUsergroupDelete size={24} />,
    6: <AiTwotoneVideoCamera size={24} />,
    7: <AiFillCheckSquare size={24} />,
  },
  steps: [
    "New Deal",
    "Interest Expressed",
    "Due Diligence",
    "Deal Quote",
    "Finance Agreement",
    "Financing",
    "Completed",
  ],
};

export const FinanceDealFlowDecline = (value) => {
  return {
    icons: {
      1: <AiOutlineBook size={24} />,
      2: <AiOutlineUsergroupDelete size={24} />,
      3: <AiTwotoneVideoCamera size={24} />,
      4: <AiFillSetting size={24} />,
      5: <AiOutlineUsergroupDelete size={24} />,
      6: <AiTwotoneVideoCamera size={24} />,
      7: <AiFillSetting size={24} />,
    },
    steps: [
      "New Deal",
      "Interest Expressed",
      "Due Diligence",
      "Deal Quote",
      "Finance Agreement",
      "Financing",
      `${value}`,
    ],
  };
};

// 1. New - "NEW",
// 2. Interest Expressed "INTEREST_EXPRESSED",
// 3. Due Diligence - "DUE_DILIGENCE_INITIATED","DUE_DILIGENCE_COMPLETED",
// 4. Deal Quote "QUOTE_DECLINED"/"QUOTE_ACCEPTED", QUOTE_GENERATED
// 5. Agreement - "PENDING_T_AND_C_ACCEPTANCE","FINANCE_AGREEMENT_COMPLETE",,"DECLINED"
// 6. Finance Status - "FINANCED", "CLOSED"
// 7. Aborted - "ABORTED",

export const ScheduleAndPaymentsFlow = {
  icons: {
    1: <AiFillSetting size={24} />,
    2: <AiOutlineBook size={24} />,
    3: <AiFillProject size={24} />,
    4: <FcApproval size={24} />,
    5: <AiOutlineUsergroupDelete size={24} />,
    6: <AiFillProject size={24} />,
    7: <AiFillSetting size={24} />,
    8: <AiFillCheckSquare size={24} />,
    9: <AiFillSetting size={24} />,
  },
  steps: [
    "Draft",
    "New",
    "Milestone",
    "Approval",
    "Delivery",
    "Delivery Confirmed",
    "Payments",
    "Completed",
    "Aborted",
  ],
};

// 1. Draft - "DRAFT",
// 2. New   - "NEW",
// 3. Milestone "MILESTONE_CREATED",
// 4. Approval - "MILESTONE_APPROVAL_REQUESTED", "MILESTONE_APPROVED", "MILESTONE_DECLINED",
// 6. Delivery - "MILESTONE_REACHED","DELIVERY_INITIATED", "DELIVERY_IN_PROGRESS", "DELIVERY_COMPLETED",
// 7. Delivery Confirmed - "DELIVERY_APPROVED","DELIVERY_DECLINED",
// 8. Payments - "PAYMENT_INITIATED", "PAYMENT_APPROVED", "PAYMENT_PAST_DUE_DATE", "PAYMENT_COMPLETED", "PAYMENT_DECLINED",
// 9. Completed -    "MILESTONE_COMPLETED","ABORTED", "OTHERS"
// 5. Aborted  - "MILESTONE_ABORTED",

export const CompanyOnboardingFlow = {
  icons: {
    1: <AiFillSetting size={24} />,
    2: <AiFillContacts size={24} />,
    3: <AiFillProject size={24} />,
    4: <AiFillSetting size={24} />,
    5: <AiOutlineUsergroupDelete size={24} />,
    6: <AiFillCheckSquare size={24} />,
  },
  steps: [
    "Company Description",
    "Company Contacts",
    "Company Registration Details",
    "Documents (Company)",
    "Documents (Director)",
    "Submit For Onboarding",
  ],
};

export const TradeWorkFlow = {
  // Service deals
  icons: {
    1: <AiOutlineShoppingCart size={26} />,
    2: <AiOutlineFileDone size={26} />,
    3: <AiOutlineFileDone size={26} />,
    4: <TbMoneybag size={26} />,
    5: <MdOutlineFactory size={26} />,
    6: <BsTruck size={26} />,
    7: <FaShip size={26} />,
    8: <BsTruck size={26} />,
    9: <RiSecurePaymentLine size={26} />,
  },
  steps: [
    "Procurement",
    "Trade Contract",
    "Financing",
    "Manufacturing",
    "Transport Export",
    "Shipping",
    "Transport Import",
    "Settlement",
  ],
};
