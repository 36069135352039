import { useContext, useCallback, useState } from "react";
import { AuthContext } from "contexts/Auth/AuthContext";
import classes from "../opentrade.module.css";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { CompanyList } from "utils/API/CompanyList.js";
import { FindCompanyName } from "utils/helper";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { openTradeAccount, paymentServiceURL } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { CheckPermission } from "utils/Constants/RBAC";

import cn from "classnames";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { ToastContainer } from "react-toastify";
import { profileURL } from "services/BaseURLInstance";

import { Success, Error } from "components/notifications/responseHandle";
import { COUNTRY } from "utils/Constants";

const Summary = ({ setSelected }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const {
    OpenTradeAccountDetails,
    setOpenTradeAccountDetails,
    isApiTrigger,
    setApiTrigger,
  } = useContext(AuthContext);

  const { response } = CompanyList();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const { instance, accounts } = useMsal();
  const [isAllMilestoneCompleted, setIsAllMilestoneCompleted] = useState(false);
  const [isAllMilestoneAborted, setIsAllMilestoneAborted] = useState(false);
  const [isAllMilestoneApproved, setIsAllMilestoneApproved] = useState(false);
  const [isAllMilestoneCreated, setIsAllMilestoneCreated] = useState(false);
  const [isAnyMilestoneCreated, setIsAnyMilestoneCreated] = useState(false);
  const [isProcessMilestoneCreated, setIsProcessMilestoneCreated] =
    useState(false);
  const [isBuyerTradeCompleted, setIsBuyerTradeCompleted] = useState(false);
  const [isSellerTradeCompleted, setIsSellerTradeCompleted] = useState(false);
  const [IsPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [toast, setToast] = useState();
  const [personData, setPersonData] = useState(null);

  //rbac
  const [Role, setRole] = useState(null);
  const [completeTradeRole, setCompleteTradeRole] = useState(null);
  const [tradeAbortRole, setTradeAbortRole] = useState(null);
  const [payment, setPayment] = useState(null);

  const SellerVirtualBankAccount = (id) => {
    let temp =
      payment && payment.filter((data) => data.virtualAccountNumber === id);

    if (temp && temp.length > 0) {
      return temp[0].paymentProvider;
    } else {
      return "TAZAPAY"; // TODO to be removed when we get data of multiple
    }
  };

  const SummaryStatus = (item) => {
    switch (item.value) {
      case "buyerMemberId":
        return OpenTradeAccountDetails.buyerMemberId && response
          ? FindCompanyName(response, OpenTradeAccountDetails.buyerMemberId)
          : "NA";
      case "sellerMemberId":
        return OpenTradeAccountDetails.sellerMemberId && response
          ? FindCompanyName(response, OpenTradeAccountDetails.sellerMemberId)
          : "NA";
      case "countryOfOrigin":
        return COUNTRY(OpenTradeAccountDetails.countryOfOrigin);
      case "countryOfDestination":
        return COUNTRY(OpenTradeAccountDetails.countryOfDestination);
      case "tradeValue":
        return OpenTradeAccountDetails.tradeValue;
      case "paymentCurrency":
        return OpenTradeAccountDetails.paymentCurrency;
      case "industryCategory":
        return OpenTradeAccountDetails.industryCategory;
      case "contractDate":
        return new Date(
          OpenTradeAccountDetails.contractDate
        ).toLocaleDateString("en-US");
      case "escrowService":
        return OpenTradeAccountDetails.escrowService;
      case "createdDate":
        return new Date(
          OpenTradeAccountDetails.createdDate
        ).toLocaleDateString();
      case "openTradeStatus":
        return OpenTradeAccountDetails.openTradeStatus;
      case "updatedDate":
        return new Date(
          OpenTradeAccountDetails.updatedDate
        ).toLocaleDateString();
      case "escrowConsentStatus":
        return OpenTradeAccountDetails.escrowConsentStatus;
      case "escrowServiceProviderUID":
        return OpenTradeAccountDetails.escrowServiceProviderUID
          ? SellerVirtualBankAccount(
              OpenTradeAccountDetails.escrowServiceProviderUID
            )
          : "NA";
      case "RefBuyerVirtualBankAccountId":
        return OpenTradeAccountDetails.RefBuyerVirtualBankAccountId
          ? OpenTradeAccountDetails.RefBuyerVirtualBankAccountId
          : "NA";
      case "RefSellerVirtualBankAccountId":
        return OpenTradeAccountDetails.RefSellerVirtualBankAccountId
          ? OpenTradeAccountDetails.RefSellerVirtualBankAccountId
          : "NA";

      case "unNumber":
        return OpenTradeAccountDetails.unNumber
          ? OpenTradeAccountDetails.unNumber
          : "NA";
      case "goodsTradeName":
        return OpenTradeAccountDetails.goodsTradeName
          ? OpenTradeAccountDetails.goodsTradeName
          : "NA";
      case "hazMat":
        return OpenTradeAccountDetails.hazMat
          ? OpenTradeAccountDetails.hazMat
          : "NA";
      case "incoTerm":
        return OpenTradeAccountDetails.incoTerm
          ? OpenTradeAccountDetails.incoTerm
          : "NA";

      default:
        return "NA";
    }
  };

  const MilestoneDeclineStatus = (Data) => {
    if (Data.tradeMilestoneStatus === "MILESTONE_APPROVAL_REQUESTED") {
      Data.tradeMilestoneStatus = "MILESTONE_DECLINED";
    }
    const options = {
      method: "PUT",
      body: Data,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${Data.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            Success("Milestone Declined Successfully");
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
        });
    });
  };

  const handleDeclineMilestone = (e) => {
    let URL = `${openTradeAccount}openaccounttrades/schedules?openAccountTradeId=${id}&sellerMemberUID=&buyerMemberUID=&status=`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            data &&
              data.map((item) => {
                return MilestoneDeclineStatus(item);
              });
            setApiTrigger(!isApiTrigger);
            // handleUpdateOpenAccountTrade();
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
          setIsLoading(false);
        });
    });
  };

  const handleApproveStatus = (event, status1) => {
    event.preventDefault();
    OpenTradeAccountDetails.openTradeStatus = status1;

    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    let URL = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;

    UseRequest(URL, options).then((data) => {
      let { message, status } = data;
      if (message || status) {
        Error(message || "Something Went Wrong");
      } else {
        if (status1 === "DRAFT") {
          handleNavigate(
            `../open-account/trade-form/${data.openTradeAccountId}`
          );
        }
      }
    });
  };

  const handleDeclineStatus = (event) => {
    event.preventDefault();

    OpenTradeAccountDetails.openTradeStatus = "TRADE_DECLINED";
    OpenTradeAccountDetails.escrowConsentStatus = "DECLINED_BUYER_CONSENT";
    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    let URL = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;

    UseRequest(URL, options).then((data) => {
      let { message, status } = data;
      if (message || status) {
        Error(message || "Something Went Wrong");
      } else {
        Success("Trade Declined successfully...");
        handleDeclineMilestone();
        handleNavigate(`../open-account/${id}`);
      }
    });
  };

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setCompleteTradeRole(
        CheckPermission(
          "CompleteTrade",
          "OpenAccountTradeTab",
          "completeTrade",
          Role
        )
      );
      setTradeAbortRole(
        CheckPermission("AbortTrade", "OpenAccountTradeTab", "abortTrade", Role)
      );
    }
  }, [Role]);

  const handleAddPayment = (event, status) => {
    event.preventDefault();
    OpenTradeAccountDetails.openTradeStatus = status;

    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    let URL = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;

    UseRequest(URL, options).then((data) => {
      let { message, status } = data;
      if (message || status) {
        Error(message || "Something Went Wrong");
      } else {
        if (status === "NEW") {
          handleNavigate(`../open-account/Add-payment/${id}`);
        }
        if (status === "DECLINED_BUYER_CONSENT") {
          Success("Buyer Consent Declined successfully...");
          handleNavigate(`../open-account/${id}`);
        }
      }
    });
  };

  const handleTradeApproveStatus = (event, status1) => {
    event.preventDefault();
    OpenTradeAccountDetails.openTradeStatus = status1;

    if (OpenTradeAccountDetails.status1 === "BUYER_TRADE_COMPLETED") {
      // Success("Buyer trade completed successfully...");
      setIsBuyerTradeCompleted(true);
    }
    if (OpenTradeAccountDetails.openTradeStatus === "TRADE_COMPLETED") {
      Success("Trade Completed Successfully...");
    }
    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };

    let URL = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;
    UseRequest(URL, options).then((data) => {
      let { message, status } = data;
      if (message || status) {
        Error(message || "Something Went Wrong");
      } else {
        if (status1 === "BUYER_TRADE_COMPLETED") {
          Success("Trade Completed Successfully...");
          handleNavigate(`/trade-account/open-account/`);
        }

        if (status1 === "TRADE_COMPLETED") {
          // Success("Trade completed successfully...");
          handleNavigate(`/trade-account/open-account/`);
        }
      }
    });
  };

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${profileURL}Persons/${OpenTradeAccountDetails.updateBy}`,
          null,
          response
        ).then((data) => {
          setPersonData(data);
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          let { message, status } = data;
          if (message || status) {
            // setIsLoading(false);
            Error(message || "Something Went Wrong");
          } else {
            setPayment(data);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}/openaccounttrades/schedules?openAccountTradeId=${id}&sellerMemberUID=&buyerMemberUID=&status=`,
          null,
          response
        )
          .then((data) => {
            let { message, status } = data;
            if (message || status) {
              Error(message || "Something Went Wrong");
            } else {
              const CheckStatue =
                data &&
                data.filter(
                  (item, i) =>
                    item.tradeMilestoneStatus !== "MILESTONE_COMPLETED"
                );
              if (data.length !== 0 && CheckStatue.length === 0) {
                setIsAllMilestoneCompleted(true);
              }
              const CheckStatus =
                data &&
                data.filter(
                  (item, i) => item.tradeMilestoneStatus !== "ABORTED"
                );
              if (data.length !== 0 && CheckStatus.length === 0) {
                setIsAllMilestoneAborted(true);
              }

              const CheckApproveStatus =
                data &&
                data.filter(
                  (item, i) =>
                    item.tradeMilestoneStatus !== "MILESTONE_APPROVED"
                );
              if (data.length !== 0 && CheckApproveStatus.length === 0) {
                setIsAllMilestoneApproved(true);
              }
              const CheckMilestone =
                data &&
                data.filter(
                  (item, i) => item.tradeMilestoneStatus === "MILESTONE_CREATED"
                );
              if (data.length !== 0 && CheckMilestone.length > 0) {
                setIsAllMilestoneCreated(true);
              }
              const CheckAnyMilestone =
                data &&
                data.filter(
                  (item, i) =>
                    item.tradeMilestoneStatus ===
                      "MILESTONE_APPROVAL_REQUESTED" ||
                    item.tradeMilestoneStatus === "DELIVERY_INITIATED" ||
                    item.tradeMilestoneStatus === "PAYMENT_INITIATED"
                );
              if (data.length !== 0 && CheckAnyMilestone.length > 0) {
                setIsAnyMilestoneCreated(true);
              }
              const CheckProcessMilestone =
                data &&
                data.filter(
                  (item, i) =>
                    item.tradeMilestoneStatus === "MILESTONE_APPROVED" ||
                    item.tradeMilestoneStatus === "DELIVERY_APPROVED" ||
                    item.tradeMilestoneStatus === "PAYMENT_COMPLETED"
                );
              if (data.length !== 0 && CheckProcessMilestone.length > 0) {
                setIsProcessMilestoneCreated(true);
              }
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  }, [isApiTrigger]);

  useEffect(() => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${openTradeAccount}openaccounttrades/schedules?openAccountTradeId=${id}&sellerMemberUID=&buyerMemberUID=&status=`,
        null,
        response
      ).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          setSchedule(data);
        }
      });
    });
  }, [isApiTrigger]);

  const MilestoneStatus = (Data) => {
    if (Data.tradeMilestoneStatus === "MILESTONE_CREATED") {
      Data.tradeMilestoneStatus = "MILESTONE_APPROVAL_REQUESTED";
    }

    const options = {
      method: "PUT",
      body: Data,
    };
    // let URL = ;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${openTradeAccount}openaccounttrades/schedules/${Data.openTradeScheduleUID}`,
        options,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          Success("Buyer Consent Sent Successfully");
        }
      });
    });
  };

  const handleApprovalStatus = (e) => {
    e.preventDefault();
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${openTradeAccount}openaccounttrades/schedules?openAccountTradeId=${id}&sellerMemberUID=&buyerMemberUID=&status=`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          data &&
            data.map((item) => {
              return MilestoneStatus(item);
            });
          // handleNavigate(`../open-account/${id}`);
          setApiTrigger(!isApiTrigger);
        }
      });
    });

    if (OpenTradeAccountDetails) {
      OpenTradeAccountDetails.openTradeStatus = "TRADE_IN_REVIEW";
    }

    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`,
        options,
        response
      ).then((data) => {
        // OpenTradeAccountDetails.openTradeStatus = "TRADE_IN_REVIEW";
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          if (data.openTradeStatus === "ABORTED") {
            Success("Trade Aborted...");
            handleNavigate(`../open-account/`);
          }
        }
      });
    });
  };

  const handleAbort = (e) => {
    e.preventDefault();
    OpenTradeAccountDetails.openTradeStatus = "ABORTED";
    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`,
        options,
        response
      ).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
        } else {
          if (data.openTradeStatus === "ABORTED") {
            Success("Trade Aborted...");
            handleNavigate(`../open-account/${id}`);
          }
        }
      });
    });
  };

  const SummaryList = [
    { name: "Buyer", value: "buyerMemberId" },
    { name: "Seller", value: "sellerMemberId" },
    { name: "Country of Origin", value: "countryOfOrigin" },
    { name: "Country of Destination", value: "countryOfDestination" },
    { name: "UN Number of Cargo", value: "unNumber" },
    { name: "Trade Name i.e goods name", value: "goodsTradeName" },
    { name: "Hazardous Material", value: "hazMat" },
    { name: "INCOTERM", value: "incoTerm" },

    { name: "Trade Value", value: "tradeValue" },
    { name: "Payment Currency", value: "paymentCurrency" },
    { name: "Industry", value: "industryCategory" },
    { name: "Contract Date", value: "contractDate" },
    { name: "Status", value: "openTradeStatus" },
    { name: "Buyer Consent Status", value: "escrowConsentStatus" },
    { name: "Mode of Payment", value: "escrowService" },
  ];

  const PaymentList = [
    {
      name: "Payment Service Provider",
      value:
        OpenTradeAccountDetails.escrowService === "OFFLINE"
          ? "NA"
          : "escrowServiceProviderUID" || "paymentProvider",
    },
    {
      name: "Seller Virtual Bank Id",
      value:
        OpenTradeAccountDetails.escrowService === "OFFLINE"
          ? "NA"
          : "RefSellerVirtualBankAccountId",
    },
    {
      name: "Buyer Virtual Bank Id",
      value:
        OpenTradeAccountDetails.escrowService === "OFFLINE"
          ? "NA"
          : "RefBuyerVirtualBankAccountId",
    },
  ];

  const CheckConditions = (status) => {
    if (
      status === "DRAFT" ||
      status === "NEW" ||
      status === "TRADE_IN_REVIEW"
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.column}>
            {SummaryList &&
              SummaryList.map((item, index) => (
                <div className={classes.columnList} key={index}>
                  <div className={classes.columnContent}>
                    <div>{item.name}</div>
                    <span style={{ color: "#42A5F5" }}>
                      {SummaryStatus(item)}
                    </span>
                  </div>
                </div>
              ))}
            {PaymentList &&
              PaymentList.map((item, index) => (
                <div className={classes.columnList} key={index}>
                  <div className={classes.columnContent}>
                    <div>{item.name}</div>
                    <span style={{ color: "#42A5F5" }}>
                      {SummaryStatus(item)}
                    </span>
                  </div>
                </div>
              ))}
          </div>

          {OpenTradeAccountDetails.buyerMemberId === companyId ? (
            <ButtonDiv>
              {/* {OpenTradeAccountDetails.openTradeStatus === "TRADE_COMPLETED" ||
              OpenTradeAccountDetails.openTradeStatus === "ABORTED" ? (
                <></>
              ) : (
                <Button
                  disabled={CheckConditions(
                    OpenTradeAccountDetails.openTradeStatus
                  )}
                  onClick={handleAbort}>
                  Abort Trade
                </Button>
              )} */}
              <Button
                disabled={CheckConditions(
                  OpenTradeAccountDetails.openTradeStatus
                )}
                onClick={handleAbort}>
                Abort Trade
              </Button>

              {schedule &&
              schedule[0] &&
              (schedule[0].tradeMilestoneStatus ===
                "MILESTONE_APPROVAL_REQUESTED" ||
                schedule[0].tradeMilestoneStatus === "DELIVERY_INITIATED" ||
                schedule[0].tradeMilestoneStatus === "PAYMENT_INITIATED" ||
                isAnyMilestoneCreated) ? (
                <Button onClick={(e) => setSelected(1)}>
                  Review Milestone
                </Button>
              ) : (
                <></>
              )}
              {schedule &&
              schedule[0] &&
              schedule[0].tradeMilestoneStatus ===
                "MILESTONE_APPROVAL_REQUESTED" &&
              OpenTradeAccountDetails.openTradeStatus === "TRADE_IN_REVIEW" ? (
                <>
                  <Button
                    className={classes.isDisabled}
                    onClick={(e) => handleDeclineStatus(e)}>
                    Decline Trade
                  </Button>
                  <Button
                    onClick={(e) =>
                      handleNavigate(`../open-account/escrow-payment/${id}`)
                    }>
                    Approve Trade
                  </Button>
                </>
              ) : (
                <></>
              )}

              {(isAllMilestoneCompleted || isAllMilestoneAborted) &&
              OpenTradeAccountDetails.openTradeStatus !==
                "BUYER_TRADE_COMPLETED" &&
              OpenTradeAccountDetails.openTradeStatus !== "TRADE_COMPLETED" ? (
                <Button
                  onClick={(e) =>
                    completeTradeRole === "CREATE"
                      ? handleTradeApproveStatus(e, "BUYER_TRADE_COMPLETED")
                      : " "
                  }>
                  Complete Trade
                </Button>
              ) : (
                <></>
              )}
            </ButtonDiv>
          ) : (
            <></>
          )}

          {OpenTradeAccountDetails.sellerMemberId === companyId ? (
            <>
              <ButtonDiv>
                {OpenTradeAccountDetails.openTradeStatus === "DRAFT" ||
                OpenTradeAccountDetails.openTradeStatus === "NEW" ||
                OpenTradeAccountDetails.openTradeStatus === "TRADE_DECLINED" ? (
                  <button
                    className={classes.button}
                    onClick={(e) => handleApproveStatus(e, "DRAFT")}>
                    Update Trade
                  </button>
                ) : (
                  <></>
                )}
                {OpenTradeAccountDetails.openTradeStatus === "DRAFT" ? (
                  <button
                    className={classes.button}
                    onClick={() =>
                      handleNavigate(`../open-account/Add-payment/${id}`)
                    }>
                    Add Payment
                  </button>
                ) : (
                  <></>
                )}
                {OpenTradeAccountDetails.openTradeStatus === "NEW" ||
                OpenTradeAccountDetails.openTradeStatus ===
                  "TRADE_IN_REVIEW" ? (
                  <>
                    <button
                      className={classes.button}
                      onClick={() =>
                        handleNavigate(`../open-account/Modify-payment/${id}`)
                      }>
                      Update Payment
                    </button>
                    <button
                      className={classes.button}
                      onClick={(e) => setSelected(1)}>
                      Setup Milestone
                    </button>
                  </>
                ) : (
                  <></>
                )}

                {OpenTradeAccountDetails.openTradeStatus === "NEW" ||
                  OpenTradeAccountDetails.openTradeStatus === "TRADE_DECLINED"}

                {(OpenTradeAccountDetails.openTradeStatus === "NEW" ||
                  OpenTradeAccountDetails.openTradeStatus ===
                    "TRADE_DECLINED") &&
                isAllMilestoneCreated ? (
                  <button
                    className={classes.button}
                    onClick={handleApprovalStatus}>
                    Send for Buyer Approval
                  </button>
                ) : (
                  <></>
                )}
                {schedule &&
                schedule[0] &&
                (schedule[0].tradeMilestoneStatus === "MILESTONE_APPROVED" ||
                  schedule[0].tradeMilestoneStatus === "DELIVERY_APPROVED" ||
                  schedule[0].tradeMilestoneStatus === "PAYMENT_COMPLETED" ||
                  isProcessMilestoneCreated) ? (
                  <button
                    className={classes.button}
                    onClick={(e) => setSelected(1)}>
                    Process Milestone
                  </button>
                ) : (
                  <></>
                )}

                {/* {OpenTradeAccountDetails.openTradeStatus ===
                  "TRADE_COMPLETED" ||
                OpenTradeAccountDetails.openTradeStatus === "ABORTED" ? (
                  <></>
                ) : (
                  <button
                    disabled={CheckConditions(
                      OpenTradeAccountDetails.openTradeStatus
                    )}
                    className={classes.button}
                    onClick={tradeAbortRole === "CREATE" ? handleAbort : " "}>
                    Abort Trade
                  </button>
                )} */}
                <button
                  disabled={CheckConditions(
                    OpenTradeAccountDetails.openTradeStatus
                  )}
                  className={classes.button}
                  onClick={tradeAbortRole === "CREATE" ? handleAbort : " "}>
                  Abort Trade
                </button>

                {OpenTradeAccountDetails.openTradeStatus ===
                  "BUYER_TRADE_COMPLETED" &&
                (isAllMilestoneCompleted || isAllMilestoneAborted) ? (
                  <button
                    className={classes.button}
                    onClick={(e) =>
                      completeTradeRole === "CREATE"
                        ? handleTradeApproveStatus(e, "TRADE_COMPLETED")
                        : " "
                    }>
                    Complete Trade
                  </button>
                ) : (
                  <></>
                )}
              </ButtonDiv>
            </>
          ) : (
            <></>
          )}
          {toast && <div style={{ color: "green" }}>{toast}</div>}
          <div
            className={cn(classes.StatusDiv, "d-flex justify-content-between")}>
            <div>
              <CreatedByLabel
                createdBy={
                  OpenTradeAccountDetails.sellerMemberId && response
                    ? FindCompanyName(
                        response,
                        OpenTradeAccountDetails.sellerMemberId
                      )
                    : "NA"
                }
                createdDate={new Date(
                  OpenTradeAccountDetails.createdDate
                ).toLocaleString("en-US", { timeZone: "Asia/Kolkata" })}
                lastUpdateBy={
                  OpenTradeAccountDetails.updateBy && response && personData
                    ? personData.companyName
                    : "NA"
                }
                lastUpdatedDate={
                  OpenTradeAccountDetails.updateBy && response && personData
                    ? new Date().toLocaleString("en-US", {
                        timeZone: "Asia/Kolkata",
                      })
                    : "NA"
                }
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Summary;
