import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./opentrade.module.css";
import { AuthContext } from "contexts/Auth/AuthContext";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { openTradeAccount, paymentServiceURL } from "services/BaseURLInstance";
import { useParams } from "react-router-dom";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import Switch from "@mui/material/Switch";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { AccountInfoSelect } from "utils/Section/FormInfo";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const EscrowPayment = () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  let { id } = useParams();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { instance, accounts } = useMsal();
  const [isError, setIsError] = useState(null);
  const [payment, setPayment] = useState(null);
  const [provider, setProvider] = useState([]);
  const [PaymentData, setPaymentData] = useState({
    escrowServiceProviderUID: "",
    RefSellerVirtualBankAccountId: "",
    RefBuyerVirtualBankAccountId: "",
    referenceEscrowAccount: "",
  });

  const theme = createTheme({
    palette: {
      secondary: {
        main: "#808080",
      },
    },
  });

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}openaccounttrades/${id}`,
          null,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            setIsError(message || "SOmething went wrong");
          } else {
            setResponse(data);

            setPaymentData({
              ...PaymentData,
              escrowServiceProviderUID: data.escrowServiceProviderUID,
              RefSellerVirtualBankAccountId: data.RefSellerVirtualBankAccountId,
              RefBuyerVirtualBankAccountId: data.RefBuyerVirtualBankAccountId,
              referenceEscrowAccount: data.referenceEscrowAccount,
            });
          }
          setIsLoading(false);
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            setPayment(data);
          }
        });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.virtualAccountNumber,
          value: item.virtualAccountNumber,
        };
      });
      setProvider(temp);
    }
  }, [payment]);

  const handlePaymentChange = (event) => {
    setPaymentData({
      ...PaymentData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitPayment = async (e, status) => {
    e.preventDefault();
    response.openTradeStatus = status;
    if (response) {
      response.escrowServiceProviderUID = provider[0]?.value;
      response.escrowConsentStatus = "APPROVED_BUYER_CONSENT";
      response.RefBuyerVirtualBankAccountId =
        PaymentData.RefBuyerVirtualBankAccountId;
    }
    response.escrowConsentStatus = "APPROVED_BUYER_CONSENT";
    const options = {
      method: "PUT",
      body: response,
    };
    let URL = `${openTradeAccount}openaccounttrades/${response.openTradeAccountId}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            Success("Trade Approved Successfully...");
            handleNavigate(`../open-account/${id}`);
          }
        })
        .catch((error) => {})
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <div>
      Payments
      {isLoading ? (
        <Content marginBottom="0%">
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {response && (
            <>
              <form>
                {/* <div className={classes.FormDiv}>
                  <div>
                    <div>Payment Service Provider</div>
                    <div style={{ fontSize: "10px", opacity: "0.6" }}>
                      Select the Payment Service Provider
                    </div>
                  </div>
                  <div>
                    <Input
                      name="escrowServiceProviderUID"
                      placeholder="Escrow ServiceProvider"
                      value={PaymentData.escrowServiceProviderUID}
                      handleChange={handlePaymentChange}
                      readOnly={true}
                    />
                  </div>
                  <div>
                    <div>Payment Account (Seller)</div>
                    <div style={{ fontSize: "10px", opacity: "0.6" }}>
                      Select the Payment Account for this trade
                    </div>
                  </div>
                  <div>
                    <Input
                      name="RefSellerVirtualBankAccountId"
                      placeholder="Ref Seller Virtual BankAccount Id"
                      value={PaymentData.RefSellerVirtualBankAccountId}
                      handleChange={handlePaymentChange}
                      readOnly={true}
                    />
                  </div>
                  <div>
                    <div>Payment Account (Buyer)</div>
                    <div style={{ fontSize: "10px", opacity: "0.6" }}>
                      Select the Payment Account for this trade
                    </div>
                  </div>
                  <div>
                    <Select
                      options={provider}
                      name="RefBuyerVirtualBankAccountId"
                      placeholder="Ref Buyer Virtual BankAccount Id *"
                      value={PaymentData.RefBuyerVirtualBankAccountId}
                      handleChange={handlePaymentChange}
                    />
                  </div>
                </div> */}

                {!isError && (
                  <Content marginBottom="0%">
                    <div style={{ marginBottom: "3%" }}>
                      <div>Payment Preference Info</div>
                      <div style={{ display: "flex" }}>
                        <ThemeProvider theme={theme}>
                          <Switch
                            color={
                              response.escrowService === "OFFLINE"
                                ? "secondary"
                                : "info"
                            }
                            checked={true}
                          />
                        </ThemeProvider>
                        <div style={{ marginTop: "0.6%" }}>
                          {response.escrowService === "OFFLINE"
                            ? "Offline Payment"
                            : "Online Payment"}
                        </div>
                      </div>
                    </div>
                    {response.escrowService !== "OFFLINE" && (
                      <div>
                        <OverLayTrigger value={AccountInfoSelect} />
                        <div className={classes.FormDiv}>
                          <div>
                            <div>Payment Service Provider</div>
                            <div style={{ fontSize: "10px", opacity: "0.6" }}>
                              Select the Payment Service Provider
                            </div>
                          </div>
                          <div>
                            <Input
                              name="escrowServiceProviderUID"
                              placeholder="Payment Service Provider"
                              // options={provider}
                              value={"TAZAPAY"}
                              handleChange={handlePaymentChange}
                              readOnly={true}
                            />
                          </div>
                          <div>
                            <div>Payment Account (Seller)</div>
                            <div style={{ fontSize: "10px", opacity: "0.6" }}>
                              Select the Payment Account for this trade
                            </div>
                          </div>
                          <div>
                            <Input
                              name="RefSellerVirtualBankAccountId"
                              placeholder="Ref Seller Virtual BankAccount Id"
                              value={PaymentData.RefSellerVirtualBankAccountId}
                              handleChange={handlePaymentChange}
                              readOnly={true}
                            />
                          </div>
                          <div>
                            <div>Payment Account (Buyer)</div>
                            <div style={{ fontSize: "10px", opacity: "0.6" }}>
                              Select the Payment Account for this trade
                            </div>
                          </div>
                          <div>
                            <Select
                              name="RefBuyerVirtualBankAccountId"
                              placeholder="Ref Buyer Virtual BankAccount Id *"
                              value={PaymentData.RefBuyerVirtualBankAccountId}
                              handleChange={handlePaymentChange}
                              options={provider}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Content>
                )}
                <ButtonDiv>
                  <Button
                    onClick={() => {
                      handleNavigate(`../open-account`);
                    }}>
                    Go Back
                  </Button>
                  {response.escrowService === "OFFLINE" ? (
                    <Button
                      onClick={(e) => handleSubmitPayment(e, "TRADE_APPROVED")}>
                      Confirm Payment Mode
                    </Button>
                  ) : PaymentData.RefBuyerVirtualBankAccountId ? (
                    <Button
                      onClick={(e) => handleSubmitPayment(e, "TRADE_APPROVED")}>
                      Confirm
                    </Button>
                  ) : (
                    <button className={classes.isDisabled}> Confirm </button>
                  )}
                </ButtonDiv>
              </form>
            </>
          )}
          {isError && <span>Something went wrong</span>}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default EscrowPayment;
