import _ from "lodash";

export const AddServiceValdations = (payload) => {
  if (_.isEmpty(payload.serviceCategory)) {
    return "Please Enter Service Request Type";
  }

  if (_.isEmpty(payload.serviceSubCategory)) {
    return "Please Enter Service Request Sub-Type";
  }

  if (_.isEmpty(payload.serviceDescription)) {
    return "Please Enter Service Request Description";
  }

  if (_.isEmpty(payload.pricingDescription)) {
    return "Please Enter Service Pricing Description";
  }

  if (_.isEmpty(payload.industry)) {
    return "Please Enter Industries";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};
export const customCheck = (payload) => {
  if (_.isEmpty(payload.CARGO_READY)) {
    return "Please Enter CARGO READY DATE*";
  }

  if (_.isEmpty(payload.PREFERRED_SAILING_DATE)) {
    return "Please Enter PREFERRED SAILING DATE*";
  }

  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country From*";
  }

  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Commodity*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//customclearance check
export const clearanceCheck = (payload) => {
  if (_.isEmpty(payload.serviceType)) {
    return "Please Enter Customs Services Required For ?*";
  }

  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country From*";
  }

  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To*";
  }

  if (_.isEmpty(payload.portOrigin)) {
    return "Please Enter From Port*";
  }

  if (_.isEmpty(payload.destinationPort)) {
    return "Please Enter To Port*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Commodity*";
  }
  if (_.isEmpty(payload.ProductName)) {
    return "Please Enter Product Name*";
  }
  if (_.isEmpty(payload.productDescription)) {
    return "Please Enter Product Description*";
  }
  if (_.isEmpty(payload.ShipperName)) {
    return "Please Enter Shipper Name*";
  }
  if (_.isEmpty(payload.ReceiverName)) {
    return "Please Enter Receiver Name*";
  }
  if (_.isEmpty(payload.ShipperAddress)) {
    return "Please Enter Shipper Address*";
  }
  if (_.isEmpty(payload.ReceiverAddress)) {
    return "Please Enter Receiver Address*";
  }
  if (_.isEmpty(payload.hsnOfCode)) {
    return "Please Enter HSN code of Goods**";
  }
  if (_.isEmpty(payload.currencyType)) {
    return "Please Enter Currency* ";
  }
  if (_.isEmpty(payload.currencyAmount)) {
    return "Please Enter Trade Amount*";
  }
  if (_.isEmpty(payload.paymentTerms)) {
    return "Please Enter Payment Terms*";
  }
  if (_.isEmpty(payload.incoterms)) {
    return "Please Enter Incoterm*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//stevedoring
export const steveDoringCheck = (payload) => {
  if (_.isEmpty(payload.name)) {
    return "Please Enter Name*";
  }

  if (_.isEmpty(payload.company)) {
    return "Please Enter Company/Organization*";
  }

  if (_.isEmpty(payload.contactInfo)) {
    return "Please Enter Contact Information (Phone/Email )*";
  }

  if (_.isEmpty(payload.vesselName)) {
    return "Please Enter Vessel Name:*";
  }

  if (_.isEmpty(payload.imoNumber)) {
    return "Please Enter Vessel IMO Number:*";
  }

  if (_.isEmpty(payload.port)) {
    return "Please Enter Port of Call:*";
  }
  if (_.isEmpty(payload.arrival)) {
    return "Please Enter Arrival Date and Time:*";
  }
  if (_.isEmpty(payload.departure)) {
    return "Please Enter Departure Date and Time:*";
  }
  if (_.isEmpty(payload.typeOfCargo)) {
    return "Please Enter Type of Cargo:*";
  }
  if (_.isEmpty(payload.description)) {
    return "Please Enter Description of Cargo (including any special handling requirements)*";
  }
  if (_.isEmpty(payload.serviceType3)) {
    return "Please Enter  If Container Cargo**";
  }
  if (_.isEmpty(payload.Lashing)) {
    return "Please Enter Lashing , Dunnaging and Shoring Requirements*";
  }
  if (_.isEmpty(payload.serviceType)) {
    return "Please Enter Inspection done : Y/ N**";
  }
  if (_.isEmpty(payload.serviceType2)) {
    return "Please select LOADING/UNLOADING** ";
  }
  if (_.isEmpty(payload.loadinginfo)) {
    return "Please Enter Loading/Unloading Information:*";
  }
  if (_.isEmpty(payload.locationOfCargo)) {
    return "Please Enter Location of Cargo (Berth/Wharf Number):*";
  }
  if (_.isEmpty(payload.preferred)) {
    return "Please Enter Preferred Start Date:*";
  }
  if (_.isEmpty(payload.completion)) {
    return "Please Enter Preferred Completion Date :*";
  }
  if (_.isEmpty(payload.duration)) {
    return "Please Enter Estimated Duration (in hours):* ";
  }
  if (_.isEmpty(payload.equipments)) {
    return "Please Enter Equipment Needed (Cranes, Forklifts, etc.):*";
  }
  if (_.isEmpty(payload.laborreq)) {
    return "Please Enter Labor Requirements (Number of Workers and Specialized Skills):*";
  }
  if (_.isEmpty(payload.ishazard)) {
    return "Please Enter Is Hazardous ?*";
  }
  if (_.isEmpty(payload.unnumber)) {
    return "Please Enter UN NUMBER*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//inspection check
export const inspectionCheck = (payload) => {
  if (_.isEmpty(payload.serviceType)) {
    return "Please Enter Customs Services Required For ?*";
  }

  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country From*";
  }

  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To*";
  }

  if (_.isEmpty(payload.portOrigin)) {
    return "Please Enter From Port*";
  }

  if (_.isEmpty(payload.destinationPort)) {
    return "Please Enter To Port*";
  }

  // if (_.isEmpty(payload.inspectionType)) {
  //   return "Please Enter Country To*";
  // }
  if (_.isEmpty(payload.inspectionType)) {
    return "Please Enter Inspection Type*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Commodity*";
  }
  if (_.isEmpty(payload.ProductName)) {
    return "Please Enter Product Name*";
  }
  if (_.isEmpty(payload.productDescription)) {
    return "Please Enter Product Description*";
  }
  if (_.isEmpty(payload.ShipperName)) {
    return "Please Enter Shipper Name*";
  }
  if (_.isEmpty(payload.ReceiverName)) {
    return "Please Enter Receiver Name*";
  }
  if (_.isEmpty(payload.ShipperAddress)) {
    return "Please Enter Shipper Address*";
  }
  if (_.isEmpty(payload.ReceiverAddress)) {
    return "Please Enter Receiver Address*";
  }
  if (_.isEmpty(payload.hsnOfCode)) {
    return "Please Enter HSN code of Goods*";
  }
  if (_.isEmpty(payload.currencyType)) {
    return "Please Enter Currency* ";
  }
  if (_.isEmpty(payload.currencyAmount)) {
    return "Please Enter Trade Amount*";
  }
  if (_.isEmpty(payload.weightType)) {
    return "Please Enter Quantity Type*";
  }
  if (_.isEmpty(payload.quantity)) {
    return "Please Enter Quantity*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//
//fright invoicing chechk
export const freightinvoieCheck = (payload) => {
  if (_.isEmpty(payload.RequestorName)) {
    return "Please Requestor Name:*";
  }

  if (_.isEmpty(payload.requesterDepart)) {
    return "Please Enter Requestor Department:*";
  }

  if (_.isEmpty(payload.requestEmail)) {
    return "Please Enter Requestor Email:*";
  }

  if (_.isEmpty(payload.requestPhone)) {
    return "Please Enter Requestor Phone Number: *";
  }

  if (_.isEmpty(payload.requestdate)) {
    return "Please Enter Date Of Request*";
  }

  // if (_.isEmpty(payload.inspectionType)) {
  //   return "Please Enter Country To*";
  // }
  if (_.isEmpty(payload.supplierName)) {
    return "Please Enter Supplier Name*";
  }

  if (_.isEmpty(payload.supplierContact)) {
    return "Please Enter Supplier Contact Information*";
  }
  if (_.isEmpty(payload.supplierPhone)) {
    return "Please Enter Supplier Phone number*";
  }
  if (_.isEmpty(payload.supplierEmail)) {
    return "Please Enter Supplier Email*";
  }
  if (_.isEmpty(payload.supplierAdd)) {
    return "Please Enter Supplier Address*";
  }
  if (_.isEmpty(payload.shipmentDate)) {
    return "Please Enter Shipment Date*";
  }
  if (_.isEmpty(payload.refferenceNo)) {
    return "Please Enter Shipment Reference Number:*";
  }
  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country of Origin*";
  }
  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country of Destination*";
  }
  if (_.isEmpty(payload.serviceTyp)) {
    return "Please Enter Freight Service Type** ";
  }
  if (_.isEmpty(payload.serviceLevel)) {
    return "Please Enter Service Level*";
  }
  if (_.isEmpty(payload.billingMethod)) {
    return "Please Enter Billing Method*";
  }
  if (_.isEmpty(payload.estimated)) {
    return "Please Enter Estimated Freight Cost:**";
  }
  if (_.isEmpty(payload.currency)) {
    return "Please Enter Currency*";
  }
  if (_.isEmpty(payload.paymentTerm)) {
    return "Please Enter Payment Terms**";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};
//creditAssesCheck

export const creditAssesCheck = (payload) => {
  if (_.isEmpty(payload.serviceType)) {
    return "Please Select Credit Assesment Services Required for ?";
  }

  if (payload.serviceType === "Existing") {
    if (_.isEmpty(payload.companyName)) {
      return "Please Select Company Name";
    }

    if (_.isEmpty(payload.contactInfo)) {
      return "Please Enter Company Contact Information";
    }

    if (_.isEmpty(payload.address)) {
      return "Please Enter  Company Address";
    }
  }
  if (payload.serviceType === "New") {
    if (_.isEmpty(payload.newCompanyName)) {
      return "Please Enter Company Name";
    }

    if (_.isEmpty(payload.newContactInfo)) {
      return "Please Enter Company Contact Information";
    }

    if (_.isEmpty(payload.newAddress)) {
      return "Please Enter Company Address";
    }
  }

  if (_.isEmpty(payload.Factors)) {
    return "Please Enter Credit Assesment should cover what all factors";
  }

  if (_.isEmpty(payload.requirement)) {
    return "Please Enter Credit Assesment coverage specific coverage Requirements";
  }
};

//inspectionQuality
export const inspectionQualityCheck = (payload) => {
  if (_.isEmpty(payload.ServiceReqDescription)) {
    return "Please Enter short description of the job *";
  }
  if (_.isEmpty(payload.inspectionMedium)) {
    return "Please Enter inspection Medium*";
  }
  if (_.isEmpty(payload.inspectionService)) {
    return "Please Enter Inspection Service*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Product Category *";
  }

  if (_.isEmpty(payload.qouteBy)) {
    return "Please Enter inspection quote by*";
  }

  if (_.isEmpty(payload.reportBy)) {
    return "Please Enter report by?*";
  }

  // if (_.isEmpty(payload.reportBy)) {
  //   return "Please Enter To Port*";
  // }

  // if (_.isEmpty(payload.inspectionType)) {
  //   return "Please Enter Country To*";
  // }
  if (_.isEmpty(payload.companyLocation)) {
    return "Please Select Location *";
  }

  if (_.isEmpty(payload.companyName)) {
    return "Please Enter Company Name*";
  }
  if (_.isEmpty(payload.contactinfo)) {
    return "Please Enter Contact Information*";
  }

  if (_.isEmpty(payload.address)) {
    return "Please Enter Address*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//inspection others
export const inspectionOthersCheck = (payload) => {
  if (_.isEmpty(payload.regInspection)) {
    return "Please Enter  Types of Regulatory Inspection(RI) *";
  }
  if (_.isEmpty(payload.inspectionMedium)) {
    return "Please Enter Inspection Medium*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Product Category *";
  }

  if (_.isEmpty(payload.qouteBy)) {
    return "Please Enter inspection quote by*";
  }

  if (_.isEmpty(payload.reportBy)) {
    return "Please Enter report by?*";
  }

  if (_.isEmpty(payload.ServiceReqDescription)) {
    return "Please Enter short description of the job*";
  }

  // if (_.isEmpty(payload.inspectionType)) {
  //   return "Please Enter Country To*";
  // }

  if (_.isEmpty(payload.companyName)) {
    return "Please Enter Company Name*";
  }
  if (_.isEmpty(payload.contactinfo)) {
    return "Please Enter Contact Information*";
  }
  if (_.isEmpty(payload.address)) {
    return "Please Select Address *";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};
//insurancecheck
export const insuranceCheck = (payload) => {
  if (_.isEmpty(payload.insuranceType)) {
    return "Please Enter  Insurance Required for? *";
  }
  if (_.isEmpty(payload.transitMode)) {
    return "Please Enter Transit Mode*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Commodity *";
  }

  if (_.isEmpty(payload.currency)) {
    return "Please Enter Currency*";
  }

  if (_.isEmpty(payload.insuredAmount)) {
    return "Please Enter Insurance Value*";
  }

  if (_.isEmpty(payload.pslAmount)) {
    return "Please Enter Per Sending Limit*";
  }

  // if (_.isEmpty(payload.inspectionType)) {
  //   return "Please Enter Country To*";
  // }

  if (_.isEmpty(payload.pllAmount)) {
    return "Please Enter  Per Location Limit*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//credit insurance
export const insuranceCreditCheck = (payload) => {
  if (_.isEmpty(payload.tenure)) {
    return "Please Enter Tenure *";
  }
  if (_.isEmpty(payload.currency)) {
    return "Please Enter Currency*";
  }

  if (_.isEmpty(payload.turnoverAmount)) {
    return "Please Enter Turnover Amount *";
  }

  if (_.isEmpty(payload.salesValue)) {
    return "Please Enter Sales Value*";
  }

  if (_.isEmpty(payload.product)) {
    return "Please Enter Product Category*";
  }
  // if (_.isEmpty(payload.product)) {
  //   return "Please Enter Product Category*";
  // }
  // if (_.isEmpty(payload.country && payload.countryMS)) {
  //   return "Please Enter Product Country*";
  // }

  //vallidation for country
  // if (payload.insuranceType1) {
  //   if (payload.insuranceType1 === "DOMESTIC") {
  //     if (_.isEmpty(payload.country)) {
  //       return "Please Enter Country*";
  //     }
  //   }
  //   if (payload.insuranceType1 === "BOTH") {
  //     if (_.isEmpty(payload.countryMS)) {
  //       return "Please Enter Country*";
  //     }
  //   }
  //   if (payload.insuranceType1 === "EXPORT") {
  //     if (_.isEmpty(payload.countryMS)) {
  //       return "Please Enter Country*";
  //     }
  //   }
  // }
  // {payload.insuranceType1 === "DOMESTIC" ?   if (_.isEmpty(payload.country)) {
  //   return "Please Enter Country*";
  // }  :   if (_.isEmpty(payload.countryMS)) {
  //   return "Please Enter Country*";
  // }   }

  // if (_.isEmpty(payload.inspectionType)) {
  //   return "Please Enter Country To*";
  // }

  if (_.isEmpty(payload.credit)) {
    return "Please Enter  % Credit*";
  }
  if (_.isEmpty(payload.cash)) {
    return "Please Enter  % Cash*";
  }
  if (_.isEmpty(payload.secured)) {
    return "Please Enter  % Secured*";
  }
  if (_.isEmpty(payload.interCompany)) {
    return "Please Enter  % Inter-Company*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};
//import compliance
export const complianceImportCheck = (payload) => {
  if (_.isEmpty(payload.ShipperName)) {
    return "Please Enter Supplier Name *";
  }
  if (_.isEmpty(payload.ReceiverName)) {
    return "Please Enter Buyer Name*";
  }

  if (_.isEmpty(payload.contactInfoShipper)) {
    return "Please Enter Supplier Contact Information*";
  }

  if (_.isEmpty(payload.contactInfoBuyer)) {
    return "Please Enter Buyer Contact Information*";
  }

  if (_.isEmpty(payload.ShipperAddress)) {
    return "Please Enter Supplier Address*";
  }
  if (_.isEmpty(payload.ReceiverAddress)) {
    return "Please Enter Buyer Address*";
  }

  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country  From*";
  }
  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Product Category*";
  }
  if (_.isEmpty(payload.ProductName)) {
    return "Please Enter Product Name*";
  }

  if (_.isEmpty(payload.productDescription)) {
    return "Please Enter Product Description*";
  }
  if (_.isEmpty(payload.hsnOfCode)) {
    return "Please Enter HSN code of Goods*";
  }

  if (_.isEmpty(payload.quantityType)) {
    return "Please Enter Quantity of Goods*";
  }
  if (_.isEmpty(payload.quantity)) {
    return "Please Enter Quantity*";
  }

  if (_.isEmpty(payload.currencyType)) {
    return "Please Enter Currency*";
  }
  if (_.isEmpty(payload.currencyAmount)) {
    return "Please Enter Estimated Cost of Goods or Services*";
  }

  if (_.isEmpty(payload.quantityType)) {
    return "Please Enter Quantity of Goods*";
  }
  // if (_.isEmpty(payload.exportType)) {
  //   return "Please Enter Select the Type of Export*";
  // }

  if (_.isEmpty(payload.incoterm)) {
    return "Please Enter Incoterm*";
  }
  if (_.isEmpty(payload.paymentTerms)) {
    return "Please Enter Payment Term*";
  }

  if (_.isEmpty(payload.requirement)) {
    return "Please Enter Requirement*";
  }
};
//export compliance
export const complianceExportCheck = (payload) => {
  if (_.isEmpty(payload.ShipperName)) {
    return "Please Enter Supplier Name *";
  }
  if (_.isEmpty(payload.ReceiverName)) {
    return "Please Enter Buyer Name*";
  }

  if (_.isEmpty(payload.contactInfoShipper)) {
    return "Please Enter Supplier Contact Information*";
  }

  if (_.isEmpty(payload.contactInfoBuyer)) {
    return "Please Enter Buyer Contact Information*";
  }

  if (_.isEmpty(payload.ShipperAddress)) {
    return "Please Enter Supplier Address*";
  }
  if (_.isEmpty(payload.ReceiverAddress)) {
    return "Please Enter Buyer Address*";
  }

  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country  From*";
  }
  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Product Category*";
  }
  if (_.isEmpty(payload.ProductName)) {
    return "Please Enter Product Name*";
  }

  if (_.isEmpty(payload.productDescription)) {
    return "Please Enter Product Description*";
  }
  if (_.isEmpty(payload.hsnOfCode)) {
    return "Please Enter HSN code of Goods*";
  }

  if (_.isEmpty(payload.quantityType)) {
    return "Please Enter Quantity of Goods*";
  }
  if (_.isEmpty(payload.quantity)) {
    return "Please Enter Quantity*";
  }

  if (_.isEmpty(payload.currencyType)) {
    return "Please Enter Currency*";
  }
  if (_.isEmpty(payload.currencyAmount)) {
    return "Please Enter Estimated Cost of Goods or Services*";
  }

  if (_.isEmpty(payload.quantityType)) {
    return "Please Enter Quantity of Goods*";
  }
  if (_.isEmpty(payload.exportType)) {
    return "Please Enter Select the Type of Export*";
  }

  if (_.isEmpty(payload.incoterm)) {
    return "Please Enter Incoterm*";
  }
  if (_.isEmpty(payload.paymentTerms)) {
    return "Please Enter Payment Term*";
  }

  if (_.isEmpty(payload.requirement)) {
    return "Please Enter Requirement*";
  }
};
//compliance trade
export const compliancetradeCheck = (payload) => {
  if (_.isEmpty(payload.serviceType)) {
    return "Please Enter Trade Compliance Services Required for *";
  }
  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country  From*";
  }

  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To *";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Product Category*";
  }

  if (_.isEmpty(payload.ProductName)) {
    return "Please Enter Product Name*";
  }

  if (_.isEmpty(payload.productDescription)) {
    return "Please Enter  Product Description*";
  }
  if (_.isEmpty(payload.hsnOfCode)) {
    return "Please Enter  HSN code of Goods*";
  }
  if (_.isEmpty(payload.ShipperName)) {
    return "Please Enter  Supplier Name*";
  }
  if (_.isEmpty(payload.ReceiverName)) {
    return "Please Enter  Buyer Name*";
  }

  if (_.isEmpty(payload.contactInfoShipper)) {
    return "Please Enter  Supplier Contact Information*";
  }
  if (_.isEmpty(payload.contactInfoBuyer)) {
    return "Please Enter Buyer Contact Information*";
  }
  if (_.isEmpty(payload.ShipperAddress)) {
    return "Please Enter  Supplier Address*";
  }
  if (_.isEmpty(payload.ReceiverAddress)) {
    return "Please Enter  Buyer Address*";
  }

  if (_.isEmpty(payload.currencyAmount)) {
    return "Please Enter  Estimated Cost of Goods or Services*";
  }
  if (_.isEmpty(payload.incoterm)) {
    return "Please Enter Incoterm*";
  }
  if (_.isEmpty(payload.service)) {
    return "Please Enter  Service*";
  }
  if (_.isEmpty(payload.requirement)) {
    return "Please Enter  Requirements*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};
//warehouse
export const warehouseCheck = (payload) => {
  if (_.isEmpty(payload.country)) {
    return "Please Enter Country*";
  }

  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Commodity *";
  }

  if (_.isEmpty(payload.ESTIMATED_STORAGE_DATE)) {
    return "Please Enter Estimated Storage Date*";
  }

  // if (_.isEmpty(payload.portOrigin)) {
  //   return "Please Enter Origin Port*";
  // }
  // if (_.isEmpty(payload.destinationPort)) {
  //   return "Please Enter Destination Port*";
  // }
  // if (_.isEmpty(payload.commodity)) {
  //   return "Please Enter Commodity*";
  // }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//contract TRade
export const tradeOthersCheck = (payload) => {
  if (_.isEmpty(payload.serviceType)) {
    return "Please Enter Trade Contract Required for *";
  }
  if (_.isEmpty(payload.tradeType)) {
    return "Please   Select Type of Service required*";
  }

  if (_.isEmpty(payload.personName)) {
    return "Please Enter Name *";
  }

  if (_.isEmpty(payload.contactName)) {
    return "Please Enter Contact Details*";
  }

  if (_.isEmpty(payload.emailadd)) {
    return "Please Enter E-mail Address*";
  }

  if (_.isEmpty(payload.companyName)) {
    return "Please Enter  Comapany Name*";
  }
  if (_.isEmpty(payload.exporterCompanyName)) {
    return "Please Enter Exporter Company Name*";
  }
  if (_.isEmpty(payload.exporterCountry)) {
    return "Please Enter  Exporter Country*";
  }
  if (_.isEmpty(payload.exporterEAdd)) {
    return "Please Enter  Exporter E-mail Address*";
  }

  if (_.isEmpty(payload.exporterAddress)) {
    return "Please Enter Exporter Address*";
  }
  if (_.isEmpty(payload.importerName)) {
    return "Please Enter Importer Company Name*";
  }
  if (_.isEmpty(payload.importerCountry)) {
    return "Please Enter  Importer Country*";
  }
  if (_.isEmpty(payload.importerEmail)) {
    return "Please Enter  Importer E-mail Address*";
  }

  if (_.isEmpty(payload.importerAddress)) {
    return "Please Enter  Importer Address*";
  }
  if (_.isEmpty(payload.countryj)) {
    return "Please Enter Country*";
  }
  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Product Category*";
  }
  if (_.isEmpty(payload.CommodityValue)) {
    return "Please Enter  Commodity Value*";
  }

  if (_.isEmpty(payload.currencyType)) {
    return "Please Enter Currency";
  }

  if (_.isEmpty(payload.currencyAmount)) {
    return "Please Enter Estimated Cost of Goods or Services";
  }

  if (_.isEmpty(payload.incoterms)) {
    return "Please Enter Incoterm";
  }

  if (_.isEmpty(payload.paymentTerms)) {
    return "Please Enter Payment Term";
  }
  if (_.isEmpty(payload.startDate)) {
    return "Please Enter Shipment Start Date";
  }
  if (_.isEmpty(payload.enddate)) {
    return "Please Enter Shipment End Date";
  }
  if (_.isEmpty(payload.origin)) {
    return "Please Enter Origin Of Shipment";
  }
  if (_.isEmpty(payload.destination)) {
    return "Please Enter Destination of Shipment";
  }

  if (_.isEmpty(payload.transport)) {
    return "Please Enter Mode Of transport";
  }
};

//wareHouseCargoLoad
// export const warehouseCargoCheck = (payload) => {
//   if (_.isEmpty(payload.quantity)) {
//     return "Please Enter QTY*";
//   }
//   if (_.isEmpty(payload.qtyUnit)) {
//     return "Please Enter Quantity Unit*";
//   }
//   if (_.isEmpty(payload.areaRequired)) {
//     return "Please Enter Area Required (sq ft)*";
//   }

//   if (_.isEmpty(payload.grossWeightPerContainer)) {
//     return "Please Enter Gross Weight Per Container*";
//   }

//  if (_.isEmpty(payload.measureUnit)) {
//     return "Please Enter Measure Unit*";
//   }

//   // if (_.isEmpty(payload.portOrigin)) {
//   //   return "Please Enter Origin Port*";
//   // }
//   // if (_.isEmpty(payload.destinationPort)) {
//   //   return "Please Enter Destination Port*";
//   // }
//   // if (_.isEmpty(payload.commodity)) {
//   //   return "Please Enter Commodity*";
//   // }

//   // if (_.isEmpty(payload.countriesOfOperations)) {
//   //   return "Please Enter Country";
//   // }
// };

//frightcheck
export const frieghtCheck = (payload) => {
  if (_.isEmpty(payload.transportMode)) {
    return "Please Enter Transport Mode*";
  }

  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country From*";
  }

  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To*";
  }

  if (_.isEmpty(payload.portOrigin)) {
    return "Please Enter Origin Port*";
  }
  if (_.isEmpty(payload.destinationPort)) {
    return "Please Enter Destination Port*";
  }
  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Commodity*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

//Containor
export const containCheck = (payload) => {
  if (_.isEmpty(payload.shipmentMode)) {
    return "Please Enter Mode of Shipment**";
  }

  if (_.isEmpty(payload.countryFrom)) {
    return "Please Enter Country From*";
  }

  if (_.isEmpty(payload.countryTo)) {
    return "Please Enter Country To*";
  }

  if (_.isEmpty(payload.loadingDate)) {
    return "Please Enter Estimated Loading Date*";
  }
  if (_.isEmpty(payload.unloadingDate)) {
    return "Please Enter Estimated Unloading Date*";
  }

  if (_.isEmpty(payload.containerCount)) {
    return "Please Enter Expected No. of Containers*";
  }
  if (_.isEmpty(payload.containerWeight)) {
    return "Please Enter Average Weight per Container *";
  }
  if (_.isEmpty(payload.commodity)) {
    return "Please Enter Product Category*";
  }

  // if (_.isEmpty(payload.countriesOfOperations)) {
  //   return "Please Enter Country";
  // }
};

export const AddServiceRequisitions = (payload) => {
  if (_.isEmpty(payload.serviceCategory)) {
    return "Please Enter Service Request Type";
  }

  if (_.isEmpty(payload.servicePaymentCurrency)) {
    return "Please Enter Service Payment Currency";
  }

  if (_.isEmpty(payload.servicePriceLow)) {
    return "Please Enter Min Price";
  }
  if (_.isEmpty(payload.preferredPaymentMethod)) {
    return "Please Enter Payment Method";
  }
  if (_.isEmpty(payload.servicePriceHigh)) {
    return "Please Enter Max Price";
  } else if (+payload.servicePriceHigh < +payload.servicePriceLow) {
    return "Max Price can not be Less than min Price";
  }

  if (_.isEmpty(payload.modeOfPayment)) {
    return "Please Enter Mode Of Payment";
  }
  if (_.isEmpty(payload.preferredPaymentDate)) {
    return "Please Enter Preferred Payment Date";
  }
  if (_.isEmpty(payload.ServiceReqDescription)) {
    return "Please Enter Service Request Description";
  }
  if (_.isEmpty(payload.pricingPreferences)) {
    return "Please Enter Service Pricing Description";
  }
  if (_.isEmpty(payload.serviceRequisitionStartDate)) {
    return "Please Enter Requisition Start Date";
  }
  if (_.isEmpty(payload.serviceRequisitionEndDate)) {
    return "Please Enter Requisition End Date";
  }
};

export const AddServiceRequisitionsINSURENCECredit = (payload) => {
  if (_.isEmpty(payload.tenure)) {
    return "Please Enter Tenure";
  }

  if (_.isEmpty(payload.currency)) {
    return "Please Enter Currency";
  }

  if (_.isEmpty(payload.turnoverAmount)) {
    return "Please Enter Turnover Amount";
  }

  if (_.isEmpty(payload.salesValue)) {
    return "Please Enter Estimated Sales/Trade Value";
  }

  if (_.isEmpty(payload.insuranceType1)) {
    return "Please Enter Insurance Type ";
  }

  if (_.isEmpty(payload.credit)) {
    return "Please Enter % Credit";
  }

  if (_.isEmpty(payload.cash)) {
    return "Please Enter % Cash";
  }

  if (_.isEmpty(payload.secured)) {
    return "Please Enter % Secured";
  }

  if (_.isEmpty(payload.interCompany)) {
    return "Please Enter % inter-Company";
  }
};
