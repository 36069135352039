import React from "react";
import { useEffect } from "react";
import "./paymentCollection.css";
import { getPaymentsByLoanId, getPaymentCollection } from "api/Payments";
import { useState } from "react";
import { CardNetworkWapper } from "components/Container.styled";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Table from "react-bootstrap/Table";
import { useNavigate } from "react-router-dom";
import {profileURL,} from "services/BaseURLInstance";

export const PaymentCollection = ({
  response,
  loanId,
  companyList,
  memberId,
  response2,
  isChecked,
  setIsChecked,
}) => {
  let oatPaymentAmount = 0;
  if (response2) {
    oatPaymentAmount =
      (parseFloat(response2.tradeValue) *
        parseFloat(response.milestonePaymentPercentage)) /
      100;
  }
  const [data, setData] = useState([]);
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const URL = "https://tradxlink.com/terms-conditions/";
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [paymentDetail, setPaymentDetails] = useState();
  const [paymentCollection, setPaymentCollection] = useState();
  const [isError, setIsError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [consumer, setConsumer] = useState();
  const [selectedOption, setSelectedOption] = useState("");
  const [provider, setprovider] = useState();

  const history = useNavigate();

  const handleClick = (url) => {
    history.push(url);
  };

  const tax = (response?.taxes!=="" && response?.taxes!==" ") ? parseFloat(response?.taxes) : 0;
  const servicePriceOffer = response?.servicePriceOffer!=='' && response?.servicePriceOffer!==' ' ? parseFloat(response?.servicePriceOffer) : "0";
  const amount = (servicePriceOffer +  tax).toString(); 
  const [role, setRole] = useState();
  useEffect(() => {
    getPaymentCollection(
      amount,
      response?.countryOfDestination || "US",
      response?.servicePaymentCurrency,
      response?.consumerMemberUID,
      response?.countryOfOrigin || "IN",
      idToken
    )
      .then((res) => {
        setPaymentCollection(res);
      })
      .catch((error) => {
        console.log("paymentCollection error", error);
      });
  }, [response2, response]);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setData(data);
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  const Provide = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}companies/${companyId}`,
        null,
        response
      ).then((data) => {
        setConsumer(data);
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setIsError(message);
        }
      });
    });
  };
  const consume = () => {
    getIdTokenAsync(instance, accounts).then((resp) => {
      UseRequest(
        `${profileURL}financers/${response.providerMemberUID}`,
        null,
        resp
      ).then((data) => {
        setprovider(data);
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setIsError(message);
        }
      });
    });
  };

  useEffect(() => {
    if (response) {
      Provide();
      Request();
      consume();
    }
  }, []);

  useEffect(() => {
    getPaymentsByLoanId(loanId, idToken, memberId)
      .then((res) => {
        let dateArray = [];
        res.length > 0 &&
          res.forEach((item) => {
            dateArray.push(new Date(item.updatedDate));
          });
        dateArray.sort((date1, date2) => date1 - date2);
        const latestDate = new Date(
          dateArray[dateArray.length - 1]
        ).toISOString();
        let newRes = [];
        newRes =
          res.length > 0 &&
          res.filter((item) => {
            return new Date(item.updatedDate).toISOString() == latestDate;
          });
        setPaymentDetails(newRes[0]);
      })
      .catch((error) => {
        console.log("paymentByLoan error", error);
      });
  }, []);

  const header = [
    {
      name: "Service Items",
      flex: "flex-1",
    },
    {
      name: "Charges",
      flex: "flex-1",
    },
  ];

  const buyerMemberId = paymentDetail?.receiverMemberID
    ? paymentDetail?.receiverMemberID
    : response?.receiverMemberID
    ? response?.receiverMemberID
    : paymentDetail?.consumerMemberUID
    ? paymentDetail?.consumerMemberUID
    : response?.consumerMemberUID && response?.consumerMemberUID;

  const sellerMemberId = paymentDetail?.senderMemberID
    ? paymentDetail?.senderMemberID
    : response?.senderMemberID
    ? response?.senderMemberID
    : paymentDetail?.providerMemberUID
    ? paymentDetail?.providerMemberUID
    : response?.providerMemberUID && response?.providerMemberUID;

  const currency = paymentDetail?.currencyReceiver
    ? paymentDetail.currencyReceiver
    : response?.financeCurrency
    ? response?.financeCurrency
    : paymentDetail?.servicePaymentCurrency
    ? paymentDetail?.servicePaymentCurrency
    : response?.servicePaymentCurrency
    ? response?.servicePaymentCurrency
    : response2?.paymentCurrency;

  const paymentAmount = paymentDetail?.paymentAmount
    ? paymentDetail.paymentAmount
    : response?.financeAmount
    ? response?.financeAmount
    : paymentDetail?.paymentAmount
    ? paymentDetail?.paymentAmount
    : response?.servicePriceOffer
    ? amount
    : oatPaymentAmount;

  const paymentMethodType = paymentDetail?.paymentMethodType
    ? paymentDetail.paymentMethodType
    : response?.paymentMethodType
    ? response?.paymentMethodType
    : paymentDetail?.paymentMethodType
    ? paymentDetail?.paymentMethodType
    : response?.preferredPaymentMethod
    ? response?.preferredPaymentMethod
    : response2?.preferredPaymentMethod;

    const handleCheckChange = () =>{
      setIsChecked(!isChecked);
    };

  return (
    <div className="panel">
      <div className="panelHeading" style={{ display: "flex" }}>
        <h4 style={{ color: "#42a5f5" }}> Payment Checkout For Service</h4>
        <span style={{ marginLeft: "50%" }}>
          Transaction Date : {new Date().toLocaleDateString()}
        </span>
      </div>

      <CardNetworkWapper>
        <div style={{ display: "flex" }}>
          <div style={{ width: "50%", padding: "0%" }}>
            <h4 style={{ color: "#42a5f5" }}>Service Consumer </h4>
            <div>{data.companyName}</div>
            <div>{consumer && consumer.companyAddress}</div>
            <div>{consumer && consumer.companyCountry}</div>
            <div>Phone No :{consumer && consumer.companyPhone[0]}</div>
          </div>
          <div style={{ marginLeft: "20%" }}>
            <h4>
              <h4 style={{ color: "#42a5f5" }}>Service Provider </h4>{" "}
            </h4>
            <div>{provider && provider.companyName} </div>
            <div>{provider && provider.companyAddress} </div>
            <div>{provider && provider.companyCountry} </div>
            <div>Phone No :{provider && provider.companyPhone[0]} </div>
          </div>
        </div>
      </CardNetworkWapper>

      <CardNetworkWapper style={{ marginTop: "2%" }}>
        <h4 style={{ color: "#42a5f5" }}>Order Details</h4>
        <Table>
          {" "}
          <thead style={{ color: "#42a5f5" }}>
            {" "}
            <tr>
              {header.map((header, index) => (
                <td key={index}>{header.name}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {response.serviceCategory}-{response.serviceSubCategory}
              </td>

              <td>
                {response.servicePaymentCurrency} {(parseFloat(response?.servicePriceOffer) + parseFloat(response?.taxes)).toString()}
              </td>
            </tr>
            <tr>
              <td>Service Fee</td>

              <td>{response.servicePaymentCurrency} 0</td>
            </tr>
            <tr>
              <td>Total</td>

              <td>
                {response.servicePaymentCurrency}{" "}
                {0 + Number((parseFloat(response?.servicePriceOffer) + parseFloat(response?.taxes)).toString())}
              </td>
            </tr>
          </tbody>
        </Table>
      </CardNetworkWapper>
      <CardNetworkWapper
        style={{
          marginTop: "2%",
        }}
      >
        <div>Payment transaction details</div>
        <div
          style={{
            marginTop: "2%",
            backgroundColor: "black",
            width: "100%",
            padding: "2%",
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                marginTop: "1%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="overviewListItem">
                <div className="overViewListFirstTile">Status</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">
                  {paymentDetail?.paymentStatus
                    ? paymentDetail.paymentStatus
                    : "New"}
                </div>
              </div>
              <div className="overviewListItem">
                <div className="overViewListFirstTile">
                  Payment Collection Method
                </div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">
                  {paymentMethodType}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="overviewListItem">
                <div className="overViewListFirstTile">Provider Country</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">
                  {" "}
                  {companyList.map((item) => {
                    if (item.memberUID === sellerMemberId) {
                      return item.countryCode;
                    }
                  })}
                </div>
              </div>
              <div className="overviewListItem">
                <div className="overViewListFirstTile">Consumer Country</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">
                  {companyList &&
                    companyList.map((item) => {
                      if (item.memberUID === buyerMemberId) {
                        return item.countryCode;
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="overviewListItem">
                <div className="overViewListFirstTile">Payment Amount</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">{paymentAmount}</div>
              </div>
              <div className="overviewListItem">
                <div className="overViewListFirstTile">Payment Currency</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">{currency}</div>
              </div>
            </div>
          </div>
        </div>
      </CardNetworkWapper>
      { response.modeOfPayment!=="OFFLINE" &&
      <CardNetworkWapper style={{ marginTop: "2%" }}>
        <div>Payment Rate (Exchange Rates)</div>
        {
          paymentCollection &&
            paymentCollection.map((item) => {
              return (
                <div
                  style={{
                    backgroundColor: "black",
                    width: "100%",
                    padding: "2%",
                    marginTop: "2%",
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "1%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div className="overviewListItem">
                        <div className="overViewListFirstTile">Currency</div>
                        <div className="colon">:</div>
                        <div className="overViewListSecondTile">
                          {item.currency}
                        </div>
                      </div>
                      <div className="overviewListItem">
                        <div className="overViewListFirstTile">Rate</div>
                        <div className="colon">:</div>
                        <div className="overViewListSecondTile">
                          {item.rate}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "1%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="overviewListItem"
                        style={{ width: "49%" }}
                      >
                        <div className="overViewListFirstTile">Amount</div>
                        <div className="colon">:</div>
                        <div className="overViewListSecondTile">
                          {item.amount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          // <div style={{ backgroundColor: "black", width: "100%", padding: "0%" }}>
          //     <div>
          //         <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
          //             <div className='overviewListItem'>
          //                 <div className='overViewListFirstTile'>Status</div>
          //                 <div className='colon'>:</div>
          //                 <div className='overViewListSecondTile'>{paymentDetail?.paymentStatus ? paymentDetail.paymentStatus : 'New'}</div>
          //             </div>
          //             <div className='overviewListItem'>
          //                 <div className='overViewListFirstTile'>Buyer Country</div>
          //                 <div className='colon'>:</div>
          //                 <div className='overViewListSecondTile'>{
          //                     companyList && companyList.map((item) => {
          //                         if (item.memberUID === buyerMemberId) {
          //                             return item.countryCode;
          //                         }
          //                     })
          //                 }</div>
          //             </div>
          //         </div>
          //     </div>
          //     <div>
          //         <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          //             <div className='overviewListItem'>
          //                 <div className='overViewListFirstTile'>Seller Country</div>
          //                 <div className='colon'>:</div>
          //                 <div className='overViewListSecondTile'> {companyList.map((item) => {
          //                     if (item.memberUID === sellerMemberId) {
          //                         return item.countryCode;
          //                     }
          //                 })}</div>
          //             </div>
          //             <div className='overviewListItem'>
          //                 <div className='overViewListFirstTile'>Payment Currency</div>
          //                 <div className='colon'>:</div>
          //                 <div className='overViewListSecondTile'>{currency}</div>
          //             </div>
          //         </div>
          //     </div>
          //     <div>
          //         <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          //             <div className='overviewListItem'>
          //                 <div className='overViewListFirstTile'>Payment Amount</div>
          //                 <div className='colon'>:</div>
          //                 <div className='overViewListSecondTile'>{paymentAmount}</div>
          //             </div>
          //             <div className='overviewListItem'>
          //                 <div className='overViewListFirstTile'>Payment Collection Method</div>
          //                 <div className='colon'>:</div>
          //                 <div className='overViewListSecondTile'>{paymentMethodType}</div>
          //             </div>
          //         </div>
          //     </div>
          // </div>
        }
      </CardNetworkWapper>
      }
      <CardNetworkWapper style={{ marginTop: "2%" }}>
        <div style={{ color: "#42a5f5" }}>
          Please select the preferred payment method to use on this order.
        </div>
        <div>
          <input
            type="radio"
            value="option2"
            checked={selectedOption === "option2"}
            onChange={handleRadioChange}
          ></input>
          Credit Card / Debit Card / Net Banking
        </div>
        <div>
          <input
            type="radio"
            value="option1"
            checked={selectedOption === "option1"}
            onChange={handleRadioChange}
          ></input>
          UPI
        </div>
        <div style={{ marginTop: "3%" }}>
          <input type="checkbox"
          value={isChecked}
          onChange={(e) => handleCheckChange(e)}></input>I have read and agree to the{" "}
          {/* <Link
            to="https://tradxlink.com/terms-conditions/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Terms & Conditions
          </Link> */}
          <a href="https://tradxlink.com/terms-conditions/" target="_blank" rel="noopener noreferrer" onClick={handleClick}>
          Terms & Conditions
          </a>
        </div>
      </CardNetworkWapper>
    </div>
  );
};
