import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { HiArrowSmLeft } from "react-icons/hi";
import Content from "components/UI/Layout/Content";
import Table from "react-bootstrap/Table";
import classes from "../invoice.module.css";
import cn from "classnames";
import { LoanTableHeader } from "utils/Section/InvoiceSection";
import { FindCompanyName } from "utils/helper";
import { AiFillEye } from "react-icons/ai";
import { truncateStringID } from "utils/helper";
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import { quoteSubmitAPI, NetworkURL, loanApi, invoiceURL } from "services/BaseURLInstance";
import { getIdTokenAsync } from "utils/authUtil";
import { storeContext } from "contexts/Store/Store";
import { validation } from './validation';
import './loan.css';
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { PaymentCollection } from "./paymentCollection";
import { PaymentsRepaymentSummary } from "./paymentRepaymentSummary";
import { PaymentsSummary} from "./paymentDetails";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";
import {
  makePayment,
  getPaymentsByLoanId,
  updateLoan,
  updatePayment,
  releasePayment,
  refundPayment,
  getPaymentByUser,
  paymentDetails,
  getPaymentCollection
} from "api/Payments";
import { Error } from "components/notifications/responseHandle";
import Loader from "components/Loader/Loader";
import { getUserType } from "common/hooks/getUserType";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import PaymentDetails from "../FinanceDeals/DealDetailsTab/PaymentDetail";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { getCountryCode } from "common/utils/getCountries";

const LoanDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let param = useParams();
  let id = param.id;
  const { instance, accounts } = useMsal();
  const [isError, setIsError] = useState(null);
  const [response, setResponse] = useState();
  const [userPaymentData, setUserPaymentData] = useState({});
  const [receiverPaymentDetail, setReceiverPaymentDetail] = useState({});
  const [paymentResponseDisbursal, setPaymentResponseDisbursal] = useState({});
  const [paymentResponseRepayment, setPaymentResponseRepayment] = useState({});
  const [paymentDisbursalData, setPaymentDisburaslData] = useState({ paymentStatus: 'new' });
  const [paymentRepaymentData, setPaymentRepaymentData] = useState({ paymentStatus: 'new' });
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const { fetchCompanyList } = useContext(storeContext);
  const [isFinancer, setIsFinancer] = useState(false);
  const [companyList, setCompanyList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [collectionModalOpen, setCollectionModalOpen] = useState();
  const [open, setOpen] = React.useState(false);
  const [memberType, setMemberType] = useState();
  const [paymentStatusText, setPaymentStatusText] = useState();
  const [button, setButton] = useState();
  const [isActive, setIsActive] = useState("Loan Details");
  const [Component, setIsComponent] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [paymentCollection, setPaymentCollection] = useState();
  const [quote, setQuote] = useState("");
  const [paymentDetailsScreen, setPaymentDetailsScreen] = useState(false);
  const currentDate = new Date().toISOString();
  const handleNavigate = (id) => {
    if (id) {
      navigate(`/financing/invoice/invoices/${id}`);
    } else {
      alert(`Invoice : ${id}`);
    }
  };

  useEffect(() => {
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken).then((companyList) => {
      setCompanyList(companyList);
      setMemberType(getUserType(companyList));
    });
  }, []);

  const getQuoteData = () => {
    let URL = `${quoteSubmitAPI}finance/deals/${response.financeDealId}`;
    fetchCompanyList(URL, idToken)
      .then((data) => {
        setQuote(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    if(response)
    {
    getQuoteData();
    }
  }, [response]);

  useEffect(() => {
    setIsLoading(true);
    let URL = `${quoteSubmitAPI}loans/${id}`;
    fetchCompanyList(URL, idToken)
      .then((response) => {
        if (!response?.serviceErrorCode) {
          setIsLoading(false);
          setResponse(response);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if(response && response.loanStatus==="ACTIVE"){
    let URL = `${invoiceURL}invoices/${response.referenceFinanceInstrumentId}`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL)
          .then((data) => {
            setInvoiceData(data);
          })
          .catch((error) => {
            setIsError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      })
  }
  }, [response]);

  useEffect(() => {
    if (companyList) {
      companyList.forEach((item) => {
        if (item.memberUID === companyId && item.memberType === "FINANCER") {
          setIsFinancer(true);
        }
      })
    }
  }, [companyList])

  useEffect(() => {
    if (response  && response.loanStatus==="ACTIVE") {
      getPaymentsByLoanId(response.loanReferenceId, idToken, response.financerMemberUID)
        .then((res) => {
          let dateArray = [];
          res.length > 0 && res.forEach(item => {
            dateArray.push(new Date(item.updatedDate));
          })
          dateArray.sort((date1, date2) => date1 - date2);
          const latestDate = new Date(dateArray[dateArray.length - 1]).toISOString();
          let newRes = [];
          newRes = res.length > 0 && res.filter((item) => {
            return new Date(item.updatedDate).toISOString() == latestDate;
          })
          setPaymentDisburaslData(newRes[0]);
          setPaymentResponseDisbursal(newRes[0]);
          if (location.search === '?status=PENDING') {
            setTimeout(() => {
              fetchUpdateDisbursalPayment(newRes[0], "PENDING");
              UpdateLoanStatus(); //todo after event implementation we need to set DISBURSEMENT_INPROGRESS
            }, 1000);
          }
          else if (location.search === '?status=FAILED') {
            setTimeout(() => {
              fetchUpdateDisbursalPayment(newRes[0], "FAILED");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log("paymentByLoan error", error)
        });
    }
  }, [response]);

  useEffect(() => {
    if (response  && response.loanStatus==="ACTIVE") {
      getPaymentsByLoanId(response.loanReferenceId, idToken, response.traderMemberUID)
        .then((res) => {
          let dateArray = [];
          res.length > 0 && res.forEach(item => {
            dateArray.push(new Date(item.updatedDate));
          })
          dateArray.sort((date1, date2) => date1 - date2);
          const latestDate = new Date(dateArray[dateArray.length - 1]).toISOString();
          let newRes = [];
          newRes = res.length > 0 && res.filter((item) => {
            return new Date(item.updatedDate).toISOString() == latestDate;
          })
          setPaymentRepaymentData(newRes[0]);
          setPaymentResponseRepayment(newRes[0]);
          if (location.search === '?status=PENDING' && newRes) {
            setTimeout(() => {
              fetchUpdateRepaymentPayment(newRes[0], "PENDING");
              UpdateLoanRePaymentStatus(); //todo after event implementation we need to set DISBURSEMENT_INPROGRESS
            }, 1000);
          }
          else if (location.search === '?status=FAILED') {
            setTimeout(() => {
              fetchUpdateRepaymentPayment(newRes[0], "FAILED");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log("paymentByLoan error", error)
        });
    }
  }, [response]);

  const fetchUpdateRepaymentPayment = async (res, value) => {
    await updatePayment(res, value, idToken)
      .then((resData) => {
        setPaymentRepaymentData(resData);
        setPaymentResponseRepayment(resData);
        if(res?.paymentReferenceData && quote.modeOfPayment!=="OFFLINE")
        {
        navigate(`/financing/invoice/loans/${res.paymentReferenceData}`);
        }
      })
      .catch((error) => {
        console.log("updatePayment error", error)
      });
  };
  const fetchUpdateDisbursalPayment = async (res, value) => {
    await updatePayment(res, value, idToken)
      .then((resData) => {
        setPaymentDisburaslData(resData);
        setPaymentResponseDisbursal(resData);
        if(res?.paymentReferenceData && quote.modeOfPayment!=="OFFLINE")
        {
        navigate(`/financing/invoice/loans/${res.paymentReferenceData}`);
        }
      })
      .catch((error) => {
        console.log("updatePayment error", error)
      });
  };

  useEffect(() => {
    findPaymentUser(true, companyId);
    if (paymentResponseDisbursal.paymentId) {
      getPaymentDetailsDisburalData(paymentResponseDisbursal.paymentId);
    }
    if (paymentResponseRepayment.paymentId) {
      getPaymentDetailsRepaymentData(paymentResponseRepayment.paymentId);
    }
  }, []);


  const findPaymentUser = (sender = true, companyId) => {
    getPaymentByUser(idToken, companyId)
      .then((userData) => {
        if (sender) {
          setUserPaymentData(userData);
        } else {
          setReceiverPaymentDetail(userData);
        }
      })
      .catch((error) => {
        console.log("Get Payment API error", error)
      });
  };

  const getPaymentDetailsDisburalData = async (paymentId) => {
    if (paymentId)
      await paymentDetails(paymentId, idToken)
        .then((response) => {
          setPaymentDisburaslData(response);
        })
        .catch((error) => {
          console.log("error", error)
        });
  };
  const getPaymentDetailsRepaymentData = async (paymentId) => {
    if (paymentId)
      await paymentDetails(paymentId, idToken)
        .then((response) => {
          setPaymentRepaymentData(response);
        })
        .catch((error) => {
          console.log("error", error)
        });
  };

  useEffect(() => {
    if(response  && response.loanStatus==="ACTIVE" && companyList){
      const countries = getCountryCode(response, companyList);
      const paymentAmount = response.financeDisbursalStatus==="DISBURSEMENT_COMPLETED"? response.financeRepaymentAmount :response.financeAmount;
    getPaymentCollection(paymentAmount, countries.financerCountry, response.financeCurrency, response.financerMemberUID, countries.traderCountry, idToken)
        .then((res) => {
            setPaymentCollection(res);
        })
        .catch((error) => {
            console.log("paymentCollection error", error)
        });
      }
}, [response])

  const handleloanUpdate = async (e, response, status) => {
    setIsLoading(true);
    let loanCreate = response;
    loanCreate.financeDisbursalStatus = status
    let requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(loanCreate),
    };
    await fetch(`${quoteSubmitAPI}loans/${id}`, requestOptions)
      .then((response) => response.json())
      .then((newData) => {
        setIsLoading(false);
        setResponse();
        setResponse(newData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  }

  const findReleasePayment = async () => {
    setIsLoading(true);
    const payload = {
      documentIds: [invoiceData.document.id],
      paymentId: paymentResponseDisbursal.paymentId
    }
    releasePayment(payload, idToken).then(() => {
      setIsLoading(false);
      setButton();
      window.location.reload(true);
      // handleloanUpdate({ response: response, status: "DISBURSEMENT_COMPLETED" })
      UpdateLoanStatus();
    });
  };

  const UpdateLoanStatus = async () => {
    setIsLoading(true);
    updateLoan(idToken, response.loanReferenceId, {
      ...response,
      // paymentStatus: "INACTIVE",
      financeRePaymentStatus: "REPAYMENT_DUE",
      // financeDisbursalStatus: "DISBURSEMENT_INPROGRESS", /// After Payment create
      financeDisbursalStatus: "DISBURSEMENT_COMPLETED", /// After Release Payment
    })
      .then(() => { 
        setIsLoading(false);
        window.location.reload();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("loan status update error", error);
      });
  };
  const UpdateLoanRePaymentStatus = async () => {
    setIsLoading(true);
    updateLoan(idToken, response.loanReferenceId, {
      ...response,
      financeRePaymentStatus: "REPAYMENT_COMPLETED",
      financeDisbursalStatus: "DISBURSEMENT_COMPLETED", /// After Release Payment
    })
      .then(() => { 
        setIsLoading(false);
        window.location.reload();
       })
      .catch((error) => {
        setIsLoading(false);
        console.log("loan status update error", error);
      });
  };

  const makeLoanPayment = () => {
    setIsLoading(true);
    const requestParam = {
      collectionRates: paymentCollection,
      paymentId: "",
      // referenceInstrumentType:"LOAN",
      // tradxlinkPaymentOrderUID: "",
      senderMemberID: companyId,
      receiverMemberID: response.traderMemberUID,
      senderMemberAccount: userPaymentData.virtualAccountNumber,
      receiverMemberAccount: receiverPaymentDetail.virtualAccountNumber,
      currencySender:   "USD",
      currencyReceiver:  response.financeCurrency,
      paymentAmount: paymentCollection[0].amount,
      // referenceInstrumentType: "INVOICE_FINANCING_LOAN",
      paymentCategory: "DISBURSEMENT",
      paymentCollectionMethod: "BANK_TRANSFER",
      paymentDueDate: response.financeDisbursalDate,
      paymentDescriptionSender: "test", //TODO COLLECT FROM USER
      paymentDescriptionReceiver: "test", //TODO COLLECT FROM USER
      paymentReferenceData: response.loanReferenceId,
      paymentStatus: "INITIATED",
      txnType: "goods",
      paymentType: quote.modeOfPayment!=="OFFLINE"? quote.modeOfPayment==="ESCROW" ? "ESCROW" : "CHECKOUT":"",
      paymentAttemptCount: 0,
      paymentTransdactionDate: new Date().toISOString(),
      paymentServiceProvider: quote.modeOfPayment==="OFFLINE"? "OFFLINE_PAYMENT" : "TAZAPAY",
      isDeleted: false,
      referenceInstrumentType: "LOAN", //LOAN_DISBURSEMENT,
      tag: "",
      createdBy: companyId,
      updateBy: companyId,
      // paymentId: paymentResponse.paymentId,
      // txnNo: paymentResponse.txnNumber,
      paymentCompletedRedirectUrl: `http://${window.location.host}/financing/invoice/loans/${id}?status=PENDING`,
      paymentErrorRedirectUrl: `http://${window.location.host}/financing/invoice/loans/${id}?status=FAILED`,
      // paymentCallbackUrl: "http://49.36.35.67:50006/payments/tazapay/callback",
    };
    makePayment(idToken, requestParam)
      .then((response) => {
        setIsLoading(false);
        if(quote.modeOfPayment==="OFFLINE"){
          fetchUpdateDisbursalPayment(response, "RELEASE_COMPLETED");
          UpdateLoanStatus();
        }
        else{
          window.location.href = response.paymentUrl;
        }
        // handleloanUpdate({ response: response, status: "DISBURSEMENT_INPROGRESS" })
        // window.location.href = response.paymentUrl;
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const repaymentByBorrower = () => {
    setIsLoading(true);
    const requestParam = {
      collectionRates: paymentCollection,
      paymentId: "",
      // referenceInstrumentType:"LOAN",
      // tradxlinkPaymentOrderUID: "",
      senderMemberID: companyId,
      receiverMemberID: response.financerMemberUID,
      // senderMemberAccount: userPaymentData.virtualAccountNumber,
      // receiverMemberAccount: receiverPaymentDetail.virtualAccountNumber,
      currencySender:   "USD",
      currencyReceiver:  "USD",
      paymentAmount: paymentCollection[0].amount,
      // referenceInstrumentType: "INVOICE_FINANCING_LOAN",
      paymentCategory: "REPAYMENT",
      paymentCollectionMethod: "BANK_TRANSFER",
      paymentDueDate: response.financeRepaymentDate,
      paymentDescriptionSender: "test", //TODO COLLECT FROM USER
      paymentDescriptionReceiver: "test", //TODO COLLECT FROM USER
      paymentReferenceData: response.loanReferenceId,
      paymentStatus: "INITIATED",
      txnType: "goods",
      paymentType: quote.modeOfPayment!=="OFFLINE"? quote.modeOfPayment==="ESCROW" ? "ESCROW" : "CHECKOUT":"",
      paymentAttemptCount: 0,
      paymentTransdactionDate: new Date().toISOString(),
      paymentServiceProvider: quote.modeOfPayment==="OFFLINE"? "OFFLINE_PAYMENT" : "TAZAPAY",
      isDeleted: false,
      referenceInstrumentType: "LOAN",// LOAN_REPAYMENT,
      tag: "",
      createdBy: companyId,
      updateBy: companyId,
      // paymentId: paymentResponse.paymentId,
      // txnNo: paymentResponse.txnNumber,
      paymentCompletedRedirectUrl: `http://${window.location.host}/financing/invoice/loans/${id}?status=PENDING`,
      paymentErrorRedirectUrl: `http://${window.location.host}/financing/invoice/loans/${id}?status=FAILED`,
      // paymentCallbackUrl: "http://49.36.35.67:50006/payments/tazapay/callback",
    };
    makePayment(idToken, requestParam)
      .then((data) => {
        setIsLoading(false);
        if(quote.modeOfPayment==="OFFLINE"){
          fetchUpdateRepaymentPayment(data, "RELEASE_COMPLETED");
          UpdateLoanRePaymentStatus();
        }
        else{
          window.location.href = data.paymentUrl;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  }
  const refundPaymentApi = () => {
    setIsLoading(true);
    refundPayment(idToken, paymentDisbursalData.paymentId)
      .then(() => {
        setIsLoading(false);
        // setRedirectUrl(response.paymentUrl);
        // handleloanUpdate({ response: response, status: "DISBURSEMENT_INPROGRESS" })
        // window.location.href = response.paymentUrl;
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const paymentApiCall = (e) => {
    setOpen(false);
    if (button === "Make Payment" || button === "Re-Initiate Payment") {
      makeLoanPayment();
    } else if (button === "Release Payment") {
      findReleasePayment();
    }
    else if (button === "Make Re-Payment") {
      repaymentByBorrower();
    }
    else if (button === "Refund Payment") {
      refundPaymentApi();
    }
  };

  

  const handleDateChange = (e, name) => {
    setResponse({
      ...response,
      [name]: e
    })
  }
  const handleClose = () => {
    setOpen(false);
  };
  

  const LoanDetail = ({response}) => {
    const [updateLoadData, setUpdateLoanData] = useState();
    useEffect(() => {
      if(response && response.loanStatus==="ACTIVE"){
      let URL = `${invoiceURL}invoices/${response.referenceFinanceInstrumentId}`;
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(URL)
            .then((data) => {
              setInvoiceData(data);
            })
            .catch((error) => {
              setIsError(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
    }
    }, [response]);
    const handleChange = (e) => {
      if (e.target.value <= 0) {
        Error("Amount can be less or equal to 0")
        setUpdateLoanData({
          ...updateLoadData,
          [e.target.name]: ''
        })
      }
      else if(e.target.name==="financeRepaymentAmount"){
        setUpdateLoanData({
          ...updateLoadData,
          financeRepaymentAmount: e.target.value,
        })
      }
      else if(e.target.name==="loanFees"){
        setUpdateLoanData({
          ...updateLoadData,
          loanFees: e.target.value,
        })
      }
    }
    const createLoan = async () => {
      let putData = {...response};
      putData.financeRepaymentAmount=updateLoadData?.financeRepaymentAmount ? updateLoadData.financeRepaymentAmount : "";
      putData.loanFees=updateLoadData?.loanFees? updateLoadData.loanFees : "";
      const validate = validation(putData);
      if (validate) {
        Error(validate);
      }
      else {
        setIsLoading(true);
        putData['loanStatus'] = "ACTIVE";
        const requestOptions = {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + idToken,
            "x-api-key":
              "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
          },
          body: JSON.stringify(putData)
        };
  
        await fetch(`${loanApi}loans/${putData.loanReferenceId}`, requestOptions)
          .then(res => res.json())
          .then(data => {
            setIsLoading(false);
            if (data.code) {
              Error("API is Failing")
            }
            else {
              setResponse(data);
            }
            // Line commented - 
            // props.data.setSydicateMenu("syndicatelending");
            // props.data.setSyndicate(data)
          })
          .catch(err => {
            setIsLoading(false);
            Error("API is Failing")
            console.log("err", err);
          })
      }
    }
    return (
      <>
        {companyList && response ? (
          <div style={{ margin: '1%', marginLeft:"2%", marginRight:"2%", backgroundColor: "black", padding: '1%', paddingBottom:"0%", border:"1px white solid" }}>
            <div style={{ marginBottom: "1%", fontSize: "14px" }}>Loan Details</div>
            <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
              <div style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                <Input
                  name="Loan Id"
                  placeholder="Loan Id *"
                  value={response.loanId}
                  readOnly={true}
                />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                {companyList && response
                  ? companyList.map((item) => {
                    if (item.memberUID === response.financerMemberUID) {
                      return (
                        <Input
                          name="Financer ID"
                          placeholder="Financer ID *"
                          value={item.memberName}
                          readOnly={true}
                        />
                      );
                    }
                  })
                  :
                  <Input
                    name="Financer ID"
                    placeholder="Financer ID *"
                    value={''}
                    readOnly={true}
                  />
                }
              </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                <Input
                  name="Loan Product Type"
                  placeholder="Loan Product Type *"
                  value={response.financeProductCategory}
                  readOnly={true}
                />
                </div>
                <div style={{ display: "flex", width: "49%" }} onClick={() => navigate(`../../invoice/invoices/${response.referenceFinanceInstrumentId}`)}>
                  <Input
                    name="Invoice Reference"
                    placeholder="Invoice Reference *"
                    value={response.referenceFinanceInstrumentId
                      ? truncateStringID(response.referenceFinanceInstrumentId, 7, "IR")
                      : "NA"}
                    readOnly={true}
                  />
                </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{ display: "flex", width: "49%", marginRight: "2%" }} onClick={() => navigate(`../../invoice/finance-deals/${response.financeDealId}`)}>
                  <Input
                    name="Finance Deal Reference"
                    placeholder="Finance Deal Reference *"
                    value={response.financeDealId
                      ? truncateStringID(response.financeDealId, 7, "FD")
                      : "NA"}
                    readOnly={true}
                  />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                <Input
                  name="Finance Amount"
                  placeholder={`Finance Amount (${response.financeCurrency}) *`}
                  value={
                    response.financeAmount ? response.financeAmount.toString() : ""
                  }
                  readOnly={true}
                />
              </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                <Input
                  name="Interest Rate"
                  placeholder="Interest Rate *"
                  value={response.interestRate}
                  readOnly={true}
                />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                <Input
                  name="Requested Finance Amount"
                  placeholder={`Requested Finance Amount (${response.financeCurrency}) *`}
                  value={
                    response.requestedFinanceAmount
                      ? response.requestedFinanceAmount.toString()
                      : ""
                  }
                  readOnly={true}
                />
              </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                {companyList &&
                  companyList.map((item) => {
                    if (item.memberUID === response.traderMemberUID) {
                      return (
                        <Input
                          name="Seller ID"
                          placeholder="Seller ID *"
                          value={item.memberName}
                          readOnly={true}
                        />
                      );
                    }
                  })}
              </div>
                <div style={{ display: "flex", width: "49%" }}>
                <Input
                  name="Currency"
                  placeholder="Currency *"
                  value={response.financeCurrency}
                  readOnly={true}
                />
             </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                <Input
                  name="Payment Method"
                  placeholder="Payment Method *"
                  value={response.paymentMethodType}
                  readOnly={true}
                />
                </div>
                <div style={{ width: "49%" }}>
                {response.loanStatus === "DRAFT"
                  ? <DateInput
                    name="financeDisbursalDate"
                    placeholder="Finance Disbursal Date *"
                    value={response.financeDisbursalDate}
                    handleChange={(e) => handleDateChange(e, 'financeDisbursalDate')}
                  />
                  : <DateInput
                    name="Finance Disbursal Date"
                    placeholder="Finance Disbursal Date *"
                    value={response.financeDisbursalDate}
                    readOnly={true}
                  />
                }
               </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                <Input
                  name="Loan Status"
                  placeholder="Loan Status *"
                  value={response.loanStatus}
                  readOnly={true}
                />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                <Input
                  name="Activation Status"
                  placeholder="Activation Status *"
                  value={response?.termsAndConditionsDocument?.createdBy}
                  readOnly={true}
                />
              </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                <Input
                  name="Disbursement Status"
                  placeholder="Disbursement Status *"
                  value={response.financeDisbursalStatus}
                  readOnly={true}
                />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                {response.loanStatus === "DRAFT"
                  ? <Input
                    name="financeRepaymentAmount"
                    placeholder={`Repayment Amount (${response.financeCurrency}) *`}
                    value={updateLoadData?.financeRepaymentAmount && updateLoadData.financeRepaymentAmount}
                    handleChange={handleChange}
                    type='number'
                  />
                  :
                  <Input
                    name="financeRepaymentAmount"
                    placeholder={`Repayment Amount (${response.financeCurrency}) *`}
                    value={response.financeRepaymentAmount}
                    handleChange={handleChange}
                    type='number'
                    readOnly={true}
                  />
                }
                </div>
              </div>

              <div style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", marginBottom: "1%"}}>
                <div style={{width: "49%", marginRight: "2%" }}>
                {response.loanStatus === "DRAFT"
                  ? <DateInput
                    name="financeRepaymentDate"
                    placeholder="Repayment Due Date *"
                    value={response?.financeRepaymentDate}
                    handleChange={(e) => handleDateChange(e, 'financeRepaymentDate')}
                  />
                  : <DateInput
                    name="financeRepaymentDate"
                    placeholder="Repayment Due Date *"
                    value={response?.financeRepaymentDate}
                    handleChange={(e) => handleDateChange(e, 'financeRepaymentDate')}
                    readOnly={true}
                  />
                }
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                <Input
                  name="Repayment Status"
                  placeholder="Repayment Status *"
                  value={response.financeRePaymentStatus}
                  readOnly={true}
                />
               </div>
              </div>

              <div style={{ display: "flex", width: "100%", alignItems: "center", marginBottom: "1%"}}>
                <div style={{width: "49%", marginRight: "2%" }}>
                {response.loanStatus === "DRAFT"
                  ? <Input
                    name="loanFees"
                    placeholder="Loan Processing fee % *"
                    value={updateLoadData?.loanFees &&  updateLoadData.loanFees}
                    handleChange={handleChange}
                    type='number'
                  />
                  :
                  <Input
                    name="loanFees"
                    placeholder="Loan Processing fee % *"
                    value={response.loanFees}
                    handleChange={handleChange}
                    type='number'
                    readOnly={true}
                  />
                }
              </div>
              </div>
            <div style={{ display: "flex" }}>
              <div style={{ width: "50%" }}>
                {companyList && response &&
                  <div style={{ margin: "1%", color: '#ffc107' }}>
                    <CreatedByLabel
                      createdBy={
                        response?.createdBy && companyList &&
                        FindCompanyName(companyList, response.createdBy)
                      }
                      createdDate={new Date(
                        response?.createdDate
                      ).toLocaleDateString()}
                      lastUpdateBy={
                        response?.lastUpdateBy && companyList &&
                        FindCompanyName(companyList, response.lastUpdateBy)
                      }
                      lastUpdatedDate={new Date(
                        response?.lastUpdatedDate
                      ).toLocaleDateString()}
                    />
                  </div>
                }
              </div>
              <div style={{ width: "50%" }}>
                {response.loanStatus === "DRAFT" && memberType==="FINANCER" &&
                  <div style={{ textAlign: "right" }}>
                    <button style={{ border: "none" }} onClick={createLoan} className={cn(classes.activeButton)}>Activate Loan</button>
                  </div>
                }
              </div>
            </div>
          </div>
        ) : null}
      </>
    )
  }

  useEffect(()=>{
    setIsComponent(<LoanDetail  response={response}/>)
  },[companyList, response])

  const Navbar = [
    {
      title: "Loan Details",
      description: <LoanDetail response={response} />,
    },
    {
      title: "Payments Summary (Disbursement)",
      description: <PaymentsSummary response={response} loanId={id} companyList={companyList} memberId={response?.financerMemberUID} quote={quote} />,
    },
    {
      title: "Payments Summary (Re-payment)",
      description: <PaymentsRepaymentSummary response={response} loanId={id} companyList={companyList} memberId={response?.traderMemberUID} quote={quote} />,
    },
  ];

  const handleActiveChange = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };
  return (
    <> {isLoading
      ? <Loader />
      : paymentDetailsScreen ? <PaymentDetails
         setOpen={setOpen}
         userType={memberType}
         setPaymentDetailsScreen={setPaymentDetailsScreen}
         quoteData={quote}
      /> 
      :<div>
        <button style={{ paddingBottom: "2rem", display: 'flex', background: "transparent", border: 'none', color: '#42A5F5' }} onClick={() => navigate(-1)}>
          <div style={{ marginTop: "-1%" }}><HiArrowSmLeft /></div>
          <div> Back to Loan</div>
        </button>
        {
          response &&
          <Content marginTop="-2%" paddingBottom="1%">
            <Table bordered responsive className={cn(classes.Table)}>
              <thead>
                <tr>
                  {LoanTableHeader &&
                    LoanTableHeader.map((header, index) => (
                      <td key={index}>{header.name}</td>
                    ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <span>
                      {response.loanReferenceId
                        ? truncateStringID(response.loanReferenceId, 7, "LN")
                        : "NA"}
                    </span>
                  </td>
                  <td>
                    {companyList &&
                      FindCompanyName(companyList, response.financerMemberUID)}
                  </td>
                  <td>
                    {companyList &&
                      FindCompanyName(companyList, response.traderMemberUID)}
                  </td>
                  <td>{new Date(response.createdDate).toLocaleDateString()}</td>
                  <td>{response.loanStatus}</td>
                  <td>
                    <span
                      onClick={() =>
                        handleNavigate(response.referenceFinanceInstrumentId)
                      }
                    >
                      <AiFillEye size={24} />
                    </span>
                  </td>
                </tr>
              </tbody>
            </Table>
            { response.loanStatus !=="CLOSED" &&
            <div>
              {
                memberType === 'FINANCER' && response.loanStatus !== 'DRAFT' && ((response.loanStatus !== 'DRAFT'
                  && (paymentDisbursalData?.paymentStatus === null || paymentDisbursalData?.paymentStatus === ""
                    || paymentDisbursalData?.paymentStatus === "new")) ?
                  (<div style={{ textAlign: "right" }}>
                     <ButtonDiv>
                      <Button
                   onClick={() => {
                    if(quote.modeOfPayment==="OFFLINE")
                    {
                      makeLoanPayment();
                      // setOpen(true)
                      // setButton("Make Payment")
                    }
                    else{
                      setPaymentDetailsScreen(true);
                      setButton("Make Payment")
                    }
                  }}>Make Payment 
                   </Button>
                  </ButtonDiv> </div>)
                  : paymentDisbursalData?.paymentStatus === "FAILED" && (paymentDisbursalData?.createdBy === companyId) ?
                    (<div style={{ textAlign: "right" }}>
                      <ButtonDiv>
                      <Button
                      onClick={() => {
                      if(quote.modeOfPayment==="OFFLINE")
                      {
                        makeLoanPayment();
                      // setOpen(true)
                      // setButton("Re-Initiate Payment")
                      }
                      else{
                      setPaymentDetailsScreen(true);
                      setButton("Re-Initiate Payment")
                      }
                    }}>Re-Initiate Payment
                    </Button>
                  </ButtonDiv> 
                    </div>)
                    : (paymentDisbursalData?.paymentStatus === "MONEY_DISBURED_TO_PROVIDER" || paymentDisbursalData?.paymentStatus === "REFUND_FAILED") && (paymentDisbursalData?.createdBy === companyId) ?
                      (<div style={{ textAlign: "right" }}>
                        <ButtonDiv>
                      <Button
                        onClick={() => {
                          setOpen(true)
                          setButton("Release Payment")
                        }}>Release Payment
                        </Button>
                  </ButtonDiv> 
                        <ButtonDiv>
                        <Button
                        onClick={() => {
                          setOpen(true)
                          setButton("Refund Payment")
                        }}>Refund Payment
                        </Button>
                  </ButtonDiv> 
                      </div>
                      )
                      : (paymentDisbursalData?.paymentStatus === "RELEASE_INITITATED") ?
                        (<div style={{ color: "#19cb46", textAlign: "right" }}>Payment release initiated</div>)
                        : (paymentDisbursalData?.paymentStatus === "RELEASE_COMPLETED") ?
                          (<div style={{ color: "#19cb46", textAlign: "right" }}>Payment release Completed</div>)
                          : (paymentDisbursalData?.paymentStatus === "RELEASE_PENDING") ?
                            (<div style={{ color: "#19cb46", textAlign: "right" }}>Payment release updates Pending</div>)
                            : (<div style={{ color: "#19cb46", textAlign: "right" }}>Payment In-Progress...</div>))
              }
            </div>
            }
            { response.loanStatus !=="CLOSED" &&
            <div>
              {
                memberType === 'COMPANY' && response.loanStatus !== 'DRAFT' && ((response.loanStatus !== 'DRAFT'
                  && (response.financeDisbursalStatus==="DISBURSEMENT_COMPLETED" && response.financeRePaymentStatus!=="REPAYMENT_COMPLETED" && currentDate >= response.financeRepaymentDate)) ?
                  (
                    <div style={{ display: "flex" }}>
                      <div style={{ color: "#19cb46", textAlign: "left", width: "70%" }}>Money is disbursed on {new Date(paymentDisbursalData?.paymentTransdactionDate).toLocaleDateString()}</div>
                      <div style={{ textAlign: "right", width: "30%" }}>
                      <ButtonDiv>
                      <Button
                        style={{ padding: "10px" }} onClick={() => {
                          if(quote.modeOfPayment==="OFFLINE")
                          {
                            repaymentByBorrower();
                            // setOpen(true)
                            // setButton("Make Re-Payment")
                          }
                          else{
                            setPaymentDetailsScreen(true);
                            setButton("Make Re-Payment")
                          }
                        }}>Make Re-Payment
                      </Button>
                      </ButtonDiv> 
                        </div>
                    </div>)
                  : paymentRepaymentData?.paymentStatus === "FAILED" && (paymentRepaymentData?.createdBy === companyId) ?
                    (<div style={{ textAlign: "right" }}>
                      <ButtonDiv>
                      <Button
                      onClick={() => {
                      if(quote.modeOfPayment==="OFFLINE")
                          {
                            repaymentByBorrower();
                            // setOpen(true)
                            // setButton("Make Re-Payment")
                          }
                          else{
                            setPaymentDetailsScreen(true);
                            setButton("Make Re-Payment")
                          }
                    }}>Re-Initiate Payment
                    </Button>
                    </ButtonDiv> 
                    </div>)
                    : (response.financeRePaymentStatus==="REPAYMENT_COMPLETED") ?
                    (<div style={{ color: "#19cb46", textAlign: "right" }}>Re-Payment release Completed</div>)
                    : paymentRepaymentData?.paymentStatus === 'new' ? (<div style={{ color: "#19cb46", textAlign: "right" }}>Disbursal In-Progress...</div>)
                    : (paymentDisbursalData?.paymentStatus === "RELEASE_INITITATED") ?
                    (<div style={{ color: "#19cb46", textAlign: "right" }}>Payment release initiated</div>)
                      : (paymentDisbursalData?.paymentStatus === "RELEASE_PENDING") ?
                        (<div style={{ color: "#19cb46", textAlign: "right" }}>Payment release updates Pending</div>)
                        : (<div style={{ color: "#19cb46", textAlign: "right" }}>Payment In-Progress...</div>))
              }
            </div>
            }
          </Content>
        }
        {response &&
          <Dialog fullWidth={true}
            maxWidth={'lg'} open={open} onClose={handleClose}
            PaperProps={{ sx: { backgroundColor: "#343434", color: 'white' } }} >
            <div style={{ padding: '2%' }}>
              <div style={{ backgroundColor: 'black' }}>
                <DialogContent sx={{}}>
                  <div>
                    <PaymentCollection response={response} loanId={id} companyList={companyList} memberId={companyId} quoteData={quote} />
                  </div>
                </DialogContent>
                <div style={{ width: '100%', paddingRight: "3.5%", paddingBottom: "2%", display: "flex", justifyContent: "right" }}>
                  <button
                    style={{ width: '20%', margin: "1%", padding: "1%", color: "white", border: 'none', backgroundColor: "#42a5f5", borderRadius: "5px", fontSize: "14px" }}
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                  <>
                    <button
                      style={{ width: '20%', margin: "1%", padding: "1%", color: "white", border: 'none', backgroundColor: "#42a5f5", borderRadius: "5px", fontSize: "14px" }}
                      onClick={(e) => {
                        paymentApiCall();
                      }}
                    >
                      Submit
                    </button>
                  </>
                </div>
              </div>
            </div>
          </Dialog>
        }
        <div style={{backgroundColor:"black", borderTopLeftRadius:"10px", borderTopRightRadius:"10px", padding:"0% 2% 1%", paddingTop:"0%"}}>
          { companyList && response &&
          <ToogleNavbar
            Navbar={Navbar}
            isActive={isActive}
            handleChange={handleActiveChange}
            handleComponent={handleComponent}
          />
          }
          {Component !== undefined && Component} 
        </div>
      </div>
    }
    </>
  );
};

export default LoanDetail;
