import React, { useState } from "react";
import Styles from "./styles";
import useAxios from "services/useAxios";
import { requsitionApi, serviceURL } from "services/BaseURLInstance";
import { useNavigate } from "react-router-dom";
import { ButtonStyled } from "components/Container.styled";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import Select from "components/fields/Select";
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import { TextArea } from "components/fields";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ServiceCategory from "jsons/serviceCategory.json";
import PaymentType from "jsons/payment.json";
import ServiceSubCategory from "jsons/serviceSubCategories.json";
import Currenciesr from "jsons/Currenciesr.json";
import ConditionCheck from "jsons/conditionCheck.json";
import { Grid } from "@mui/material";

export default () => {
  const data = [
    { value: 1, label: "KYB" },
    { value: 2, label: "LEGAL" },
    { value: 3, label: "FINANCING" },
    { value: 4, label: "CREDIT_ASSESSMENT" },
    { value: 5, label: "INVOICING" },
    { value: 6, label: "PAYMENTS" },
    { value: 7, label: "OTHERS" },
  ];

  const location = useLocation();
  const selectedServiceList = location.state;
  console.log("selectedServiceList", selectedServiceList);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedValue, setSelectedValue] = useState({
    value: 1,
    label: selectedServiceList[0].serviceCategory[0],
  });
  const [paymentType, setPaymentType] = useState();
  const [currencyType, setCurrencyType] = useState();
  const [escrowPayment, setEscrowPayment] = useState();
  const [requisitionResponse, setRequisitionResponse] = useState();
  // let serviceArray = [];
  let memberArray = [];
  let newArr =
    selectedServiceList &&
    selectedServiceList.map((service) => {
      // serviceArray.push(service.serviceId)
      memberArray.push(service.updateBy);
    });

  const [requisitionData, setRequisitionData] = useState({
    serviceRequisitionId: "",
    consumerMemberUID: "",
    ServiceReqDescription: "",
    pricingPreferences: "",
    referenceServiceId: selectedServiceList[0].serviceId
      ? selectedServiceList[0].serviceId
      : "",
    // referenceServiceId: serviceArray,
    servicePriceLow: "",
    servicePriceHigh: "",
    servicePaymentCurrency: "",
    serviceRequisitionStartDate: "2022-09-16T10:19:33.179+05:30",
    serviceRequisitionEndDate: "2022-09-16T10:19:33.179+05:30",
    serviceRequisitionStatus: "NEW",
    preferredPaymentDate: "2022-09-16T10:19:33.179+05:30",
    escrowPayment: true,
    isDeleted: false,
    createdBy: "",
    createdDate: "",
    lastUpdateBy: "",
    lastUpdatedDate: "",
    //  serviceProvidersSelected: selectedServiceList[0].memberId? [selectedServiceList[0].memberId]:[],
    serviceProvidersSelected: memberArray,
    serviceCategory: selectedServiceList[0].serviceCategory[0]
      ? [selectedServiceList[0].serviceCategory[0]]
      : [],
    serviceSubCategory: selectedServiceList[0].serviceSubCategory[0]
      ? [selectedServiceList[0].serviceSubCategory[0]]
      : [],
    industry: selectedServiceList[0].industry
      ? selectedServiceList[0].industry
      : [],
    countriesForSubscription: selectedServiceList[0].countriesOfOperations
      ? selectedServiceList[0].countriesOfOperations
      : [],
    serviceProviderPreferences: "SELECTED",
    preferredPaymentMethod: "",
    referenceData: [],
  });

  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const navigate = useNavigate();

  useEffect(() => {
    setRequisitionData({
      ...requisitionData,
      consumerMemberUID: companyId,
    });
  }, [companyId]);

  const handleBack = () => {
    navigate("../service");
  };

  const handleInputChange = (e) => {
    setRequisitionData({
      ...requisitionData,
      [e.target.name]: e.target.value,
    });
  };
  const handleDateChange = (e, field) => {
    setRequisitionData({
      ...requisitionData,
      [field]: new Date(e).toISOString(),
    });
  };

  const handleChange = (e) => {
    setRequisitionData({
      ...requisitionData,
      [e.target.name]: [e.target.value],
    });
  };

  const onChangeValue = (e) => {
    setRequisitionData({
      ...requisitionData,
      serviceProviderPreferences: e.target.value,
    });
  };

  const handleSendRequest = () => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    console.log("requisition data", requisitionData);

    // requisitionData.referenceData= selectedServiceList[0]!==null ? selectedServiceList[0].data : [];
    requisitionData.referenceData = [];

    const requestOptions = {
      method: "POST",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(requisitionData),
    };
    const fetchData = async () => {
      await fetch(`${serviceURL}services/requisitions`, requestOptions)
        .then((response) => {
          console.log("requisition data", response);
          setRequisitionResponse(response);
          setTimeout(() => {
            navigate("../service-requests");
          }, 1000);
        })
        .catch(() => {
          alert("Api is not working");
        });
    };
    fetchData();
  };

  console.log("requisition data", selectedServiceList);
  return (
    <>
      <div
        style={{
          marginTop: "12px",
          fontSize: "12px",
          color: "#42A5F5",
          marginLeft: "41px",
        }}
        onClick={handleBack}
      >
        Back to Service Requests
      </div>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div
          style={{
            marginTop: "40px",
            backgroundColor: "rgb(1, 9, 21)",
            width: "95%",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Select
                name="serviceCategory"
                placeholder="Service Request Type"
                value={requisitionData.serviceCategory[0]}
                options={ServiceCategory}
                handleChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Select
                name="serviceSubCategory"
                placeholder="Sub-Category"
                value={requisitionData.serviceSubCategory[0]}
                options={ServiceSubCategory}
                handleChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Select
                name="servicePaymentCurrency"
                placeholder="Currency"
                value={requisitionData.servicePaymentCurrency}
                options={Currenciesr}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Input
                name="servicePriceLow"
                placeholder="Min Price *"
                value={requisitionData.servicePriceLow}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Select
                name="preferredPaymentMethod"
                placeholder="Payment Method"
                value={requisitionData.preferredPaymentMethod}
                options={PaymentType}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Input
                name="servicePriceHigh"
                placeholder="Max Price *"
                value={requisitionData.servicePriceHigh}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Select
                name="escrowPayment"
                placeholder="Escrow Payment"
                value={requisitionData.escrowPayment}
                options={ConditionCheck}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={5.9} style={{ padding: "10px" }}>
              <DateInput
                name="preferredPaymentDate"
                placeholder="Preferred Payment Date  *"
                value={requisitionData.preferredPaymentDate}
                handleChange={(e) =>
                  handleDateChange(e, "preferredPaymentDate")
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "10px" }}>
            <TextArea
              name="ServiceReqDescription"
              placeholder="Service Request Description"
              value={requisitionData.requestDescription}
              handleChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "10px" }}>
            <TextArea
              name="pricingPreferences"
              placeholder="Service Pricing Description"
              value={requisitionData.pricingDescription}
              handleChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} md={5.95} style={{ padding: "10px" }}>
              <DateInput
                name="serviceRequisitionStartDate"
                placeholder="Requisition Start Date *"
                value={requisitionData.serviceRequisitionStartDate}
                handleChange={(e) =>
                  handleDateChange(e, "serviceRequisitionStartDate")
                }
              />
            </Grid>
            <Grid item xs={12} md={5.95} style={{ padding: "10px" }}>
              <DateInput
                name="serviceRequisitionEndDate"
                placeholder="Requisition End Date *"
                value={requisitionData.serviceRequisitionEndDate}
                handleChange={(e) =>
                  handleDateChange(e, "serviceRequisitionEndDate")
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      <Styles>
        {
          <div
            style={{
              margin: "2.1%",
              padding: "20px",
              backgroundColor: "#010915",
              borderRadius: "5px",
              width: "94.2%",
              color: "#42A5F5",
            }}
          >
            <div>Select Service Providers</div>
            <div style={{ display: "flex" }}>
              <div onChange={onChangeValue}>
                <input
                  style={{ marginTop: "20px" }}
                  type="radio"
                  value="All"
                  name="All"
                />{" "}
                <br />
                <input
                  style={{ marginTop: "20px" }}
                  type="radio"
                  value="Selected"
                  name="Selected"
                  checked="checked"
                />{" "}
                <br />
                <input
                  style={{ marginTop: "20px" }}
                  type="radio"
                  value="Favourites"
                  name="Favourites"
                />
              </div>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "15px",
                    backgroundColor: "transparent",
                    border: "3px solid #42A5F5",
                    borderRadius: "5px",
                  }}
                >
                  Publish to All Providers
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "30px",
                    backgroundColor: "transparent",
                    border: "3px solid #42A5F5",
                    borderRadius: "5px",
                  }}
                >
                  Publish to Selected Providers
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "30px",
                    backgroundColor: "transparent",
                    border: "3px solid #42A5F5",
                    borderRadius: "5px",
                  }}
                >
                  Publish to Favourites
                </div>
              </div>
            </div>
          </div>
        }
        {requisitionResponse && (
          <div style={{ color: "lightgreen", marginLeft: "2%" }}>
            Your Service Request has been sent to the selected Service Providers
          </div>
        )}
        {
          <div
            style={{
              marginTop: "2%",
              display: "flex",
              justifyContent: "right",
              marginRight: "14.5%",
            }}
          >
            <button
              style={{
                backgroundColor: "#42A5F5",
                border: "none",
                color: "white",
                width: "20%",
                height: "40px",
                borderRadius: "5px",
              }}
              onClick={handleSendRequest}
            >
              Submit
            </button>
          </div>
        }
      </Styles>
    </>
  );
};
