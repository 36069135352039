import React, { useState } from "react";
import { Card, Grid, Button } from '@mui/material';
import Input from "components/fields/Input";
import FinancerTable from "./FinancerTable.js";
import DateInput from "components/fields/DateInput";
import Select from "components/fields/Select";

export const FinancerList = (props) => {
    //const [showFinancersTable, setShowFinancersTable] = useState(false);
    const enableDrawdown = props.financers.enableDrawdown;
    const showDrawdown = props.financers.showDrawdown;
    const changeFinancer = props.financers.changeFinancer;
    const handleChange = props.financers.handleChange;
    const enableComp = props.financers.enableComp;
    const backFormTranchTable= props.financers.backFormTranchTable;
    const showfinacerTable = props.financers.showfinacerTable;
    const showFinancersTable= props.financers.showFinancersTable; 
    const setShowFinancersTable= props.financers.setShowFinancersTable;
    const financeTable = props.financers.financeTable;
    const showTable = props.financers.showTable;
    const setShowTable = props.financers.setShowTable;
    const editTranche = props.financers.editTranche;
    const finacerTable = () => {
        showfinacerTable();
    }

    const changeStructure = () => {
        setShowFinancersTable(false)
        showfinacerTable();
    }

    const propsData = {
        enableDrawdown,
        showDrawdown,
        data: props.financers.data,
        trancheData: props.financers.trancheData,
        drawdownData: props.financers.drawdownData,
        resetFlow: props.financers.resetFlow,
        handleChange,
        enableComp,
        changeStructure,
        backFormTranchTable,
        setListFinancer:props.financers.setListFinancer,
        changeFinancer,
        cancelDraw: props.financers.cancelDraw,
        removeFinancer: props.financers.removeFinancer,
        memberList:props.financers.memberList,
        showfinacerTable,
        showTable,
        setShowTable,
        editTranche
    }
    const loanstatus_options = [
        { "label": "DRAFT", "value": "DRAFT" },
        { "label": "IN_REVIEW", "value": "IN_REVIEW" },
        { "label": "ACTIVE", "value": "ACTIVE" },
        { "label": "INACTIVE", "value": "INACTIVE" },
        { "label": "LOCKED", "value": "LOCKED" }
    ]
    return (
        <>
            {showTable ?
                <FinancerTable data={propsData} />
                :
                props.financers?.trancheData?.map((e, i) => {
                    return (
                        <Card style={{
                            backgroundColor: "#1E1E1E",
                            padding: "20px",
                            borderRadius: "5px",
                            margin: "15px 10px"
                        }}>
                            <h4 style={{ margin: "0px", color: "white" }}>{e.financerName}</h4>
                            <Grid container>
                                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                                    <Input
                                        name="trancheFinanceAmount"
                                        placeholder="Required Finance Amount"
                                        required={false}
                                        value={e.trancheFinanceAmount}
                                        handleChange={(e) => handleChange(e, i, "Tranche")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                                    <Input
                                        name="interestRate"
                                        placeholder="Interest Rate"
                                        required={false}
                                        value={e.interestRate}
                                        handleChange={(e) => handleChange(e, i, "Tranche")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                                    <DateInput
                                        placeholder="Finance Start Date"
                                        value={e.trancheFinanceStartDate || new Date().toLocaleDateString}
                                        handleChange={(event) => handleChange(event, i, "trancheFinanceStartDate")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                                    <DateInput
                                        placeholder="Finance End Date"
                                        value={e.trancheFinanceEndDate || new Date().toLocaleDateString}
                                        handleChange={(event) => handleChange(event, i, "trancheFinanceEndDate")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                                    <Input
                                        name="assetRatio"
                                        placeholder="Asset Rate"
                                        required={false}
                                        value={e.assetRatio}
                                        handleChange={(event) => handleChange(event, i, "Tranche")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                                    <Select
                                        options={loanstatus_options}
                                        name="loanStatus"
                                        placeholder="Status"
                                        disabled={e.loanStatus==="DRAFT"}
                                        handleChange={(event) => handleChange(event, i, "Tranche")}
                                        value={e.loanStatus}
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    )
                })}
            {!showTable  && <div style={{ display: "flex", justifyContent: "flex-end" }}>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => changeFinancer()}
                    style={{ width: "200px", margin: "0px 20px" }}
                >
                    Change Financers
                </Button>

                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={() => showfinacerTable()}
                    style={{ width: "200px", marginRight: "10px" }}
                > 
                    Next
                </Button>
            </div>}
        </>
    );
};
