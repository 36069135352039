import Table from "react-bootstrap/Table";
import { participantHeader } from "utils/Section/InvoiceSection";
import React from "react";
import { useState, useContext } from "react";
import { tradeURL } from "services/BaseURLInstance";
import cn from "classnames";
import styles from './tradeworkflow.module.css'
import { Error } from "components/notifications/responseHandle";
import { storeContext } from "../../../contexts/Store/Store";
import { TradeContext } from '../tradeProvider';

export const GlobalParticipant = ({ Data, stageData }) => {
    const [participants, setParticipants] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const { fetchData, fetchCompanyList } = useContext(storeContext);
    const { companyList } = useContext(TradeContext);
    useState(() => {
        const getGlobalParticipantData = async () => {
            let idToken = JSON.parse(sessionStorage.getItem("idToken"));
            let URL = `${tradeURL}trades-participants?tradeId=${Data.tradeId}`;
            fetchCompanyList(URL, idToken)
                .then((data) => {
                    if(data.code || data.message)
                    {
                        Error("Global Participant API is failing")
                    }
                    else{
                    setParticipants(data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        getGlobalParticipantData()
    }, [stageData])
    return (
        <div style={{ marginTop: "2rem" }}>
            {participants && participantHeader.length > 0 && companyList &&
                <Table bordered responsive className={cn(styles.Table)}>
                    <thead>
                        <tr>
                            {participantHeader.map((header, index) => (
                                <td key={index}>{header.name}</td>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {console.log('participants', participants)}
                        { !participants.error && participants?.map((item, key) => (
                                <tr key={key} style={{ cursor: "pointer" }}>
                                    <td
                                        className={cn(styles.columnHover)}
                                    >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                    width: "100%",
                                        
                                                }}
                                            >
                                                {companyList &&
                                                    companyList.map((value) => {
                                                        if (value.memberUID === item.participant) {
                                                            return value.memberName;
                                                        }
                                                    })}

                                            </div>
                                       
                                    </td>
                                    <td>
                                        <div style={{ marginTop: "0.5rem" }}>{item.country}</div>
                                    </td>
                                    <td>
                                        <div style={{ marginTop: "0.5rem" }}>{item.role}</div>
                                    </td>
                                    <td>
                                        <div style={{ marginTop: "0.5rem" }}>{new Date(item.since).toLocaleDateString()}</div>
                                    </td>
                                    <td>
                                        <div
                                            style={
                                                item.status === "ACTIVE"
                                                    ? { backgroundColor: "#6BCD95", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }
                                                    : { backgroundColor: "#FF6969", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }
                                            }
                                        >
                                            {item.status}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>

                    {participants &&
                        participants.length === 0 && (
                            <span>No data</span>
                        )}
                </Table>
            }
            {/* {participants &&
                participants.length === 0 && (
                    <span>No data</span>
                )} */}
        </div>
    )
} 
