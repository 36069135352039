import React, { useState, useContext, useCallback } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles from "./syndicate.module.css";
import { ToastContainer } from "react-toastify";
import { Error, Success } from "components/notifications/responseHandle";
import { Button, Card, Step, Stepper, StepLabel, Box } from "@mui/material";
import { StepperOne } from "./StepperOne";
import { StepperTwo } from "./StepperTwo";
import StepperThree from "./StepperThree";
import NewDrawdown from "./NewDrawdown";
import { useEffect } from "react";
import {
  NetworkURL,
  financierURL,
  quoteSubmitAPI,
} from "services/BaseURLInstance";
import {
  BankNameFilter,
  BankNameFilterDD,
  CompanyNameFilter,
  notify,
} from "utils/helper";
import { storeContext } from "contexts/Store/Store";
import { useMsal } from "@azure/msal-react";
import { getClaim, getIdToken } from "utils/authUtil";
import {
  SyndicateOverviewValdations,
  SyndicateSelectedFinancerValdations,
  SyndicateshowfinacerTableValdations,
  SyndicateshowDradownTableValdations,
} from "./sydicateValidation";
export default function Dealstructure({ data }) {
  console.log("SYN", data);
  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const { fetchCompanyList } = useContext(storeContext);
  const [idx, setIdx] = useState(0);
  const [sendBool, setSendBool] = useState(false);
  const [loanReferenceId, setLoanReferenceId] = useState(data);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [listFinancer, setListFinancer] = useState(false);
  const [showSelectedFinancer, setShowSelectedFinancer] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showFinancers, setShowFinancers] = useState(true);
  function getSteps() {
    return ["Basic Info", "Add Participating Banks", "Create Drawdowns"];
  }

  const changeFinancer = () => {
    setShowSelectedFinancer(false);
    setListFinancer(true);
  };
  const confirmFinancers = () => {
    let checkValidation = SyndicateSelectedFinancerValdations(apiData);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      setShowSelectedFinancer(false);
      setListFinancer(true);
    }
  };
  const selectedFinanacerFunc = () => {
    let checkValidation = SyndicateSelectedFinancerValdations(apiData);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      setShowFinancers(true);
      setListFinancer(false);
      setShowSelectedFinancer(true);
    }
  };
  const tran = {
    trancheFinanceAmount: "",
    interestRate: "",
    trancheFinanceStartDate: new Date().toLocaleDateString,
    trancheFinanceEndDate: new Date().toLocaleDateString,
    assetRatio: "",
    loanStatus: "",
  };
  const api_json = {
    trancheFinanceAmount: "",
    interestRate: "",
    loanFinanceEndDate: new Date().toLocaleDateString,
    loanFinanceStartDate: new Date().toLocaleDateString,
    searchData: [],
    financers: [],
  };

  const draw = {
    Bank: "",
    trancheComponentDrawdownAmount: "",
    //loanTrancheComponentDrawdowns:"DRAFT",
    trancheComponentDrawdownStartDate: new Date().toLocaleDateString,
    trancheComponentDrawdownEndDate: new Date().toLocaleDateString,
    traderMemberUID: loanReferenceId?.traderMemberUID,
  };

  const [type, setType] = useState(true);
  const [addNewTrancheBool, setAddNewTrancheBool] = useState(false);
  const handleAddNewTranche = () => {
    setAddNewTrancheBool(true);
    if (loanReferenceId?.loanTranches?.length !== 0) {
      setAcitveStep(0);
      setIdx(loanReferenceId?.loanTranches?.length + 1);
    }
  };
  const handleType = () => {
    setType(!type);
  };

  const [apiData, setApiData] = useState(api_json);
  const [CompanyList, setCompanyList] = useState("");

  const createDrawdown = () => {
    let card = drawdownList;
    card.push({
      Bank: "",
      trancheComponentDrawdownAmount: "",
      trancheComponentDrawdownStartDate: new Date().toLocaleDateString,
      trancheComponentDrawdownEndDate: new Date().toLocaleDateString,
    });

    let drawDownData = card.map((d) => {
      return {
        ...d,
        borrower: CompanyList,
        traderMemberUID: loanReferenceId?.traderMemberUID,
      };
    });
    setDrawdownList(drawDownData);
  };
  const [showFinancersTable, setShowFinancersTable] = useState(false);
  const [activeStep, setAcitveStep] = useState(0);
  const [transactionSet, setTransactionSet] = useState(false);
  const [stepper, setStepper] = useState(true);
  const [financeTable, setFinanceTable] = useState(false);
  const [drowdown, setDrawdown] = useState(false);
  const [dealStructure, setDealStructure] = useState(true);
  const [drawdownList, setDrawdownList] = useState([{ ...draw }]);
  const [tranchelist, setTrancheList] = useState([]);
  const [options, setOptions] = useState([]);
  const [apicall, setApicall] = useState(false);
  const [cancelDraw, setCancelDraw] = useState(false);
  const [newDrawdown, setNewDrawdown] = useState(false);
  const [back, setBack] = useState(false);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const steps = getSteps();

  const prev = (e) => {
    setAcitveStep(activeStep - 1);
  };

  const fetchFinancer = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    let URL = `${financierURL}financers`;

    await fetch(URL, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        let opt = [];
        data.map((q) =>
          opt.push({ label: q.financerName, value: q.memberUID })
        );
        setOptions(opt);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchCompany = () => {
    let URL = `${NetworkURL}members?memberId=${loanReferenceId?.traderMemberUID}`;
    fetchCompanyList(URL, idToken)
      .then((companyList) => {
        let name =
          CompanyNameFilter(companyList, loanReferenceId?.traderMemberUID)
            .length >= 1
            ? CompanyNameFilter(
                companyList,
                loanReferenceId?.traderMemberUID
              )[0].memberName
            : "";
        setCompanyList(name);
      })
      .catch((error) => {
        notify("warn");
      });
  };
  useEffect(() => {
    if (options.length === 0) {
      fetchFinancer();
    }
    if (CompanyList === "") {
      console.log("first", CompanyList);
      fetchCompany();
    }
  }, [CompanyList, drawdownList]);

  const selectedFinanacer = useCallback(
    (ops) => {
      let nData = options;
      const myArrayFiltered = nData.filter((el) => {
        return ops.some((f) => {
          return f === el.value;
        });
      });
      setApiData((state) => ({
        ...state,
        financers: myArrayFiltered,
      }));
    },
    [options, apiData]
  );
  useEffect(() => {
    if (
      addNewTrancheBool === false &&
      loanReferenceId &&
      loanReferenceId?.loanReferenceId !== undefined
    ) {
      console.log("dddd", loanReferenceId);
      setTransactionSet(true);
      setAcitveStep(0);
      setApiData((state) => ({
        ...state,
        ...loanReferenceId,
        interestRate:
          loanReferenceId?.loanTranches != null
            ? loanReferenceId?.loanTranches[idx]?.interestRate
            : "0",
        trancheFinanceAmount:
          loanReferenceId?.loanTranches != null
            ? loanReferenceId?.loanTranches[idx]?.trancheFinanceAmount
            : "0",
        loanFinanceEndDate:
          loanReferenceId?.loanTranches != null
            ? new Date(loanReferenceId?.loanTranches[idx]?.loanFinanceEndDate)
            : new Date(),
        loanFinanceStartDate:
          loanReferenceId?.loanTranches != null
            ? new Date(loanReferenceId?.loanTranches[idx]?.loanFinanceStartDate)
            : new Date(),
      }));
      if (
        addNewTrancheBool === false &&
        loanReferenceId &&
        loanReferenceId?.loanTranches != null &&
        loanReferenceId?.loanTranches[idx] &&
        loanReferenceId?.loanTranches[idx]?.loanTrancheComponents &&
        loanReferenceId?.loanTranches[idx]?.loanTrancheComponents?.length > 0
      ) {
        let tranchelist =
          loanReferenceId?.loanTranches[idx]?.loanTrancheComponents;
        tranchelist = tranchelist.map((item) => ({
          ...item,
          financerName: BankNameFilter(
            options,
            item.syndicateFinancerMemberUID
          ),
          borrower: CompanyList,
        }));

        let newSelectedTranch = tranchelist;
        newSelectedTranch = newSelectedTranch.map(
          (j) => j.syndicateFinancerMemberUID
        );

        selectedFinanacer(newSelectedTranch);
        setTrancheList(tranchelist);
        setApicall(true);
        extraCollopse();
      }
      if (
        addNewTrancheBool === false &&
        loanReferenceId &&
        loanReferenceId?.loanTranches != null &&
        loanReferenceId?.loanTranches[idx] &&
        loanReferenceId?.loanTranches[idx]?.loanTrancheComponentDrawdowns &&
        loanReferenceId?.loanTranches[idx]?.loanTrancheComponentDrawdowns
          ?.length > 0
      ) {
        let ndTlist =
          loanReferenceId?.loanTranches[idx]?.loanTrancheComponentDrawdowns;
        ndTlist = ndTlist.map((item) => ({
          ...item,
          Bank: item?.syndicateFinancerMemberUID,
          Fianciers: BankNameFilterDD(
            options,
            item?.syndicateFinancerMemberUID
          ),
          borrower: CompanyList,
        }));
        setDrawdownList(ndTlist);
        setApicall(true);
        extraCollopse();
      }
    }
    if (addNewTrancheBool === true) {
      console.log("T-TRUE");

      setTransactionSet(true);
      setAcitveStep(0);
      resetFlow();
    }
  }, [loanReferenceId, options, addNewTrancheBool, idx]);

  const dataFormatNewTrance = (actionType) => {
    let tranche_list = tranchelist.map(
      ({
        financerName,
        Bank,
        borrower,
        createdBy,
        createdDate,
        financeCurrency,
        lastUpdateBy,
        lastUpdatedDate,
        loanFees,
        loanTrancheComponentDrawdownId,
        paymentCurrencyType,
        paymentMethodType,
        ...rest
      }) => ({ ...rest })
    );
    let draw_downList = drawdownList.map(
      ({
        Fianciers,
        Borrower,
        createdBy,
        createdDate,
        lastUpdateBy,
        lastUpdatedDate,
        loanTrancheComponentId,
        trancheComponentdrawdownRepaymentDate,
        trancheComponentdrawdownRePaymentStatus,
        ...rest
      }) => ({ ...rest })
    );
    draw_downList = draw_downList.map((item) => ({
      ...item,
      trancheComponentDrawdownStatus: actionType,
      syndicateFinancerMemberUID: item.Bank,
      //loanTrancheComponentDrawdownId:item?.loanTrancheComponentDrawdownId,
    }));
    tranche_list = tranche_list.map((item) => ({
      ...item,
      loanStatus: actionType,
      //loanTrancheComponentId:item?.loanTrancheComponentId,
      syndicateFinancerMemberUID:
        item.syndicateFinancerMemberUID || item.financerId,
    }));

    tranche_list = tranche_list.map(
      ({ financerId, interestRate, ...rest }) => ({ ...rest })
    );
    draw_downList = draw_downList.map(({ Bank, borrower, ...rest }) => ({
      ...rest,
    }));
    delete loanReferenceId["loanTranches"];

    let json = {
      ...loanReferenceId,
    };
    console.log("FINAN", tranchelist);

    json["paymentCurrencyType"] = "FIAT";

    json["financeDealId"] = apiData.financeDealId;
    json["loanId"] = apiData.loanId || localStorage.getItem("loanid");
    json["financeAmount"] = apiData.financeAmount;

    delete json["interestRate"];
    delete json["requestedFinanceAmount"];
    delete json["financerName"];
    delete json["selectedFinancerMemberUID"];
    delete json["financeDealExpiryDate"];
    delete json["financeDealStatus"];
    delete json["notes"];
    delete json["isDeleted"];
    json["financeRequisitionId"] = json["refFinanceRequisitionId"];
    delete json["refFinanceRequisitionId"];
    json["loanTranches"] = getTrancheCalcNewTranche(
      apiData,
      actionType,
      draw_downList,
      tranche_list
    );
    return json;
  };

  const getTrancheCalcNewTranche = (
    data,
    actionType,
    loanTrancheComponentDrawdowns,
    loanTrancheComponents
  ) => {
    let nData = [];
    if (data?.loanTranches?.length > 0) {
      console.log("apiData?.financers", apiData?.financers);
      for (let i = 0; i < data?.loanTranches?.length; i++) {
        if (i === idx) {
          nData.push({
            trancheFinanceAmount: apiData.trancheFinanceAmount,
            loanFinanceStartDate: apiData.loanFinanceStartDate,
            //financeDealId:apiData?.financeDealId,
            loanTrancheStatus: actionType,
            loanFinanceEndDate: apiData.loanFinanceEndDate,
            financerMemberUID: apiData?.financers[i]?.value,
            interestRate: apiData.interestRate,
            traderMemberUID: loanReferenceId?.traderMemberUID,
            //loanTrancheId:data?.loanTranches[i]?.loanTrancheId,
            loanTrancheComponentDrawdowns: loanTrancheComponentDrawdowns,
            loanTrancheComponents: loanTrancheComponents,
          });
        } else {
          nData.push({
            trancheFinanceAmount: data?.loanTranches[i]?.trancheFinanceAmount,
            loanFinanceStartDate: data?.loanTranches[i]?.loanFinanceStartDate,
            //financeDealId:apiData?.financeDealId,
            loanTrancheStatus: actionType,
            loanFinanceEndDate: data?.loanTranches[i]?.loanFinanceEndDate,
            financerMemberUID: apiData?.financers[i]?.value,
            interestRate: data?.loanTranches[i]?.interestRate,
            traderMemberUID: data?.loanTranches[i]?.traderMemberUID,
            //loanTrancheId:data?.loanTranches[i]?.loanTrancheId,
            loanTrancheComponentDrawdowns:
              data?.loanTranches[i]?.loanTrancheComponentDrawdowns,
            loanTrancheComponents: data?.loanTranches[i]?.loanTrancheComponents,
          });
        }
      }
    } else {
      nData.push({
        trancheFinanceAmount: apiData.trancheFinanceAmount,
        loanFinanceStartDate: apiData.loanFinanceStartDate,
        //financeDealId:apiData?.financeDealId,
        loanTrancheStatus: actionType,
        loanFinanceEndDate: apiData.loanFinanceEndDate,
        financerMemberUID: apiData?.financers[0]?.value,
        interestRate: apiData.interestRate,
        traderMemberUID: loanReferenceId?.traderMemberUID,
        //loanTrancheId:data?.loanTranches[idx]?.loanTrancheId,
        loanTrancheComponentDrawdowns: loanTrancheComponentDrawdowns,
        loanTrancheComponents: loanTrancheComponents,
      });
    }
    return nData;
  };
  const handlSaveTranche = async (type) => {
    let data = dataFormatNewTrance("DRAFT");
    let id =
      loanReferenceId.loanReferenceId || localStorage.getItem("loanrefid");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },

      body: JSON.stringify(data),
    };
    await fetch(`${quoteSubmitAPI}loans/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoanReferenceId(data);
        setApicall(true);
        extraCollopse();
      })
      .catch((err) => {
        setAcitveStep(2);
        console.log("err", err);
      });
  };
  const handleChange = (e, v, n) => {
    if (v === "loanFinanceStartDate" || v === "loanFinanceEndDate") {
      setApiData({
        ...apiData,
        [v]: e,
      });
    } else if (
      n === "Tranche" ||
      n === "trancheFinanceStartDate" ||
      n === "trancheFinanceEndDate"
    ) {
      trancheChange(e, v, n);
    } else if (
      n === "Drawdown" ||
      n === "trancheComponentDrawdownStartDate" ||
      n === "trancheComponentDrawdownEndDate"
    ) {
      drawDownChange(e, v, n);
    } else {
      const { name, value } = e.target;
      let nData = apiData;
      nData[name] = value;
      setApiData((state) => ({
        ...state,
        ...nData,
      }));
      if (name === "financers") {
        finacerChange(apiData.financers);
      }
    }
    dataFormat("DRAFT");
  };

  const finacerChange = useCallback(
    (data) => {
      let trancheData = data.map((d) => {
        return {
          ...tran,
          financerName: d.label,
          financerId: d.value,
          borrower: CompanyList,
          traderMemberUID: loanReferenceId?.traderMemberUID,
        };
      });
      setTrancheList(trancheData);
      dataFormat("DRAFT");
    },
    [tranchelist]
  );

  const trancheChange = (event, value, name) => {
    let card = JSON.parse(JSON.stringify(tranchelist));
    if (
      name === "trancheFinanceStartDate" ||
      name === "trancheFinanceEndDate"
    ) {
      card[value][name] = event;
      setTrancheList(card);

      // setTrancheList((state)=>({
      //     ...state,
      //     ...card
      // }))
    } else {
      card[value][event.target.name] = event.target.value;
      setTrancheList(card);

      // setTrancheList((state)=>({
      //     ...state,
      //     ...card
      // }))
    }
    dataFormat("DRAFT");
  };
  const editTranche = () => {
    setShowTable(false);
    // setListFinancer(true)
    // setShowSelectedFinancer(true)
  };
  const drawDownChange = (event, value, name) => {
    let card = JSON.parse(JSON.stringify(drawdownList));
    if (
      name === "trancheComponentDrawdownStartDate" ||
      name === "trancheComponentDrawdownEndDate"
    ) {
      card[value][name] = event;
      setDrawdownList(card);
    } else if (event.target.name === "Bank") {
      card[value][event.target.name] = event.target.value;
      card[value]["Fianciers"] = options.filter(
        (item) => item.value === event.target.value
      )[0];
      card[value]["Borrower"] = CompanyList;
      setDrawdownList(card);
    } else {
      card[value][event.target.name] = event.target.value;
      setDrawdownList(card);
    }
    dataFormat("DRAFT");
  };

  const enableStepper = () => {
    setTransactionSet(true);
    setAcitveStep(0);
  };

  const getTrancheCalc = (
    data,
    actionType,
    loanTrancheComponentDrawdowns,
    loanTrancheComponents
  ) => {
    let nData = [];
    if (data?.loanTranches?.length > 0) {
      console.log("apiData?.financers", apiData?.financers);
      for (let i = 0; i < data?.loanTranches?.length; i++) {
        if (i === idx) {
          nData.push({
            trancheFinanceAmount: apiData.trancheFinanceAmount,
            loanFinanceStartDate: apiData.loanFinanceStartDate,
            //financeDealId:apiData?.financeDealId,
            loanTrancheStatus:
              actionType === undefined
                ? data?.loanTranches[i]?.loanTrancheStatus
                : actionType,
            loanFinanceEndDate: apiData.loanFinanceEndDate,
            financerMemberUID:
              apiData?.financers[i]?.value ||
              data?.loanTranches[i]?.financerMemberUID,
            interestRate: apiData.interestRate,
            traderMemberUID: loanReferenceId?.traderMemberUID,
            loanTrancheId: data?.loanTranches[i]?.loanTrancheId,
            loanTrancheComponentDrawdowns: loanTrancheComponentDrawdowns,
            loanTrancheComponents: loanTrancheComponents,
          });
        } else {
          nData.push({
            trancheFinanceAmount: data?.loanTranches[i]?.trancheFinanceAmount,
            loanFinanceStartDate: data?.loanTranches[i]?.loanFinanceStartDate,
            //financeDealId:apiData?.financeDealId,
            loanTrancheStatus:
              actionType === undefined
                ? data?.loanTranches[i]?.loanTrancheStatus
                : actionType,
            loanFinanceEndDate: data?.loanTranches[i]?.loanFinanceEndDate,
            financerMemberUID:
              apiData?.financers[i]?.value ||
              data?.loanTranches[i]?.financerMemberUID,
            interestRate: data?.loanTranches[i]?.interestRate,
            traderMemberUID: data?.loanTranches[i]?.traderMemberUID,
            loanTrancheId: data?.loanTranches[i]?.loanTrancheId,
            loanTrancheComponentDrawdowns:
              data?.loanTranches[i]?.loanTrancheComponentDrawdowns,
            loanTrancheComponents: data?.loanTranches[i]?.loanTrancheComponents,
          });
        }
      }
    } else {
      nData.push({
        trancheFinanceAmount: apiData.trancheFinanceAmount,
        loanFinanceStartDate: apiData.loanFinanceStartDate,
        //financeDealId:apiData?.financeDealId,
        loanTrancheStatus:
          actionType === undefined
            ? data?.loanTranches[idx]?.loanTrancheStatus
            : actionType,
        loanFinanceEndDate: apiData.loanFinanceEndDate,
        financerMemberUID: apiData?.financers[0]?.value,
        interestRate: apiData.interestRate,
        traderMemberUID: loanReferenceId?.traderMemberUID,
        //loanTrancheId:data?.loanTranches[idx]?.loanTrancheId,
        loanTrancheComponentDrawdowns: loanTrancheComponentDrawdowns,
        loanTrancheComponents: loanTrancheComponents,
      });
    }
    return nData;
  };
  const dataFormat = (actionType) => {
    let tranche_list = tranchelist.map(
      ({
        financerName,
        Bank,
        borrower,
        createdBy,
        createdDate,
        financeCurrency,
        lastUpdateBy,
        lastUpdatedDate,
        loanFees,
        loanTrancheComponentDrawdownId,
        paymentCurrencyType,
        paymentMethodType,
        ...rest
      }) => ({ ...rest })
    );
    let draw_downList = drawdownList.map(
      ({
        Fianciers,
        Borrower,
        createdBy,
        createdDate,
        lastUpdateBy,
        lastUpdatedDate,
        loanTrancheComponentId,
        trancheComponentdrawdownRepaymentDate,
        trancheComponentdrawdownRePaymentStatus,
        ...rest
      }) => ({ ...rest })
    );
    draw_downList = draw_downList.map((item) => ({
      ...item,
      trancheComponentDrawdownStatus:
        actionType === undefined
          ? item?.trancheComponentDrawdownStatus
          : actionType,
      syndicateFinancerMemberUID: item.Bank,
      loanTrancheComponentDrawdownId: item?.loanTrancheComponentDrawdownId,
    }));
    tranche_list = tranche_list.map((item) => ({
      ...item,
      loanStatus: actionType === undefined ? item?.loanStatus : actionType,
      loanTrancheComponentId: item?.loanTrancheComponentId,
      syndicateFinancerMemberUID:
        item.syndicateFinancerMemberUID || item.financerId,
    }));
    console.log("tranchelist", tranche_list);
    console.log("draw_downList", draw_downList);

    tranche_list = tranche_list.map(
      ({ financerId, interestRate, ...rest }) => ({ ...rest })
    );
    draw_downList = draw_downList.map(({ Bank, borrower, ...rest }) => ({
      ...rest,
    }));
    delete loanReferenceId["loanTranches"];

    let json = {
      ...loanReferenceId,
    };
    console.log("FINAN", tranchelist);

    json["paymentCurrencyType"] = "FIAT";

    json["financeDealId"] = apiData.financeDealId;
    json["loanId"] = apiData.loanId || localStorage.getItem("loanid");
    json["financeAmount"] = apiData.financeAmount;

    delete json["interestRate"];
    delete json["requestedFinanceAmount"];
    delete json["financerName"];
    delete json["selectedFinancerMemberUID"];
    delete json["financeDealExpiryDate"];
    delete json["financeDealStatus"];
    delete json["notes"];
    delete json["isDeleted"];
    json["financeRequisitionId"] = json["refFinanceRequisitionId"];
    delete json["refFinanceRequisitionId"];
    json["loanTranches"] = getTrancheCalc(
      apiData,
      actionType,
      draw_downList,
      tranche_list
    );
    return json;
  };
  const getFinancerids = (flist) => {
    let findlist = [];
    for (let i = 0; i < flist.length; i++) {
      findlist.push(flist[i]?.financerId);
    }
    return findlist;
  };

  const onSubmit = async (e) => {
    let data = dataFormat("DRAFT");
    let id =
      loanReferenceId.loanReferenceId || localStorage.getItem("loanrefid");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },

      body: JSON.stringify(data),
    };
    await fetch(`${quoteSubmitAPI}loans/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoanReferenceId(data);
        setApicall(true);
        extraCollopse();
      })
      .catch((err) => {
        setAcitveStep(2);
        console.log("err", err);
      });
  };
  const handleSandToFinancer = async () => {
    let data = dataFormat("IN_REVIEW");
    let id =
      loanReferenceId.loanReferenceId || localStorage.getItem("loanrefid");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },

      body: JSON.stringify(data),
    };
    await fetch(`${quoteSubmitAPI}loans/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoanReferenceId(data);
        setApicall(true);
        extraCollopse();
      })
      .catch((err) => {
        setAcitveStep(2);
        console.log("err", err);
      });
  };
  const createDealStructure = () => {
    setStepper(false);
  };

  const showfinacerTable = () => {
    let checkValidation = SyndicateshowfinacerTableValdations(tranchelist);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      setShowTable(true);
      setShowFinancersTable(true);
      setStepper(true);
      setFinanceTable(true);
    }
  };
  const backFormCreateDrwodown = () => {
    setDrawdown(false);
    setNewDrawdown(false);
    setShowTable(true);
    setStepper(true);
    setAcitveStep(1);
    // setShowFinancersTable(true)
    // setFinanceTable(true);
  };
  const createFinancer = () => {
    setShowTable(false);
    setShowFinancers(false);
    setStepper(false);
    setShowFinancers(false);
  };

  const backFormTranchTable = () => {
    setFinanceTable(false);
    setDrawdown(false);
    setStepper(false);
    setAcitveStep(2);
  };
  const enableDrawdown = () => {
    let checkValidation = SyndicateshowDradownTableValdations(drawdownList);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      setDrawdown(true);
      setNewDrawdown(false);
      setFinanceTable(false);
      setStepper(true);
      setAcitveStep(2);
    }
  };

  const skipDrawdown = () => {
    setStepper(false);
    setAcitveStep(2);
    setApicall(true);
  };

  const cancelDrawdown = () => {
    setStepper(true);
    setAcitveStep(1);
    setCancelDraw(true);
  };
  const showDrawdown = () => {
    setFinanceTable(false);
    setStepper(false);
    setBack(false);
    setDrawdown(true);
    setNewDrawdown(true);
  };

  const extraCollopse = () => {
    setAcitveStep(2);
    setStepper(false);
    setDrawdown(false);
  };

  const disabledealStructure = () => {
    setStepper(false);
    setDealStructure(false);
  };

  const enableStepper2 = () => {
    let checkValidation = SyndicateOverviewValdations(apiData);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      setListFinancer(true);
      setIsLoading(true);
      setAcitveStep(1);
    }
  };

  const resetFlow = () => {
    setAcitveStep(0);
    setApiData(api_json);
    setDrawdownList([{ ...draw }]);
    setBack(false);
  };

  const addDrawdown = () => {
    setDrawdown(true);
    setNewDrawdown(true);
    setStepper(false);
  };

  const goBack = () => {
    setAcitveStep(1);
    setBack(true);
  };
  //this is for bool vlaue
  useEffect(() => {
    console.log("setSendBool", loanReferenceId);
    let nData = loanReferenceId?.loanTranches;
    let compboll = true;
    let tranche = true;
    if (nData?.length > 0) {
      for (let i = 0; i < nData.length; i++) {
        for (
          let dd = 0;
          dd < nData[i].loanTrancheComponentDrawdowns.length;
          dd++
        ) {
          if (
            nData[i].loanTrancheComponentDrawdowns[dd]
              ?.trancheComponentDrawdownStatus !== "APPROVED" &&
            nData[i].loanTrancheComponentDrawdowns[dd]
              ?.trancheComponentDrawdownStatus !== "INACTIVE"
          ) {
            compboll = false;
          }
        }

        for (let dd = 0; dd < nData[i].loanTrancheComponents.length; dd++) {
          if (
            nData[i].loanTrancheComponents[dd]?.loanStatus !== "APPROVED" &&
            nData[i].loanTrancheComponents[dd]?.loanStatus !== "INACTIVE"
          ) {
            tranche = false;
          }
        }
      }
    }
    if (compboll === true && tranche === true) {
      setSendBool(true);
    } else {
      setSendBool(false);
    }
  }, [loanReferenceId]);
  const removeFinancer = useCallback(
    (index, data) => {
      let filteredName = data?.values?.financerName;
      let nData = tranchelist;
      nData = nData.filter((item) => item.financerName !== filteredName);
      setTrancheList(nData);
      dataFormat("DRAFT");
    },
    [tranchelist]
  );

  const handleStatusUpdate = async (payload) => {
    let data = payload;
    let id =
      loanReferenceId.loanReferenceId || localStorage.getItem("loanrefid");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },

      body: JSON.stringify(data),
    };
    await fetch(`${quoteSubmitAPI}loans/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoanReferenceId(data);
        setApicall(true);
        extraCollopse();
      })
      .catch((err) => {
        setAcitveStep(2);
        console.log("err", err);
      });
  };
  const handleAccept = (status, i, item) => {
    let data = dataFormat();
    data.loanTranches[idx].loanTrancheComponents[i].loanStatus = status;
    handleStatusUpdate(data);
  };
  const handleReject = (status, i, item) => {
    let data = dataFormat();
    data.loanTranches[idx].loanTrancheComponents[i].loanStatus = status;
    handleStatusUpdate(data);
  };
  const handleAcceptDD = (status, i, item) => {
    let data = dataFormat();
    data.loanTranches[idx].loanTrancheComponentDrawdowns[
      i
    ].trancheComponentDrawdownStatus = status;
    handleStatusUpdate(data);
  };
  const handleRejectDD = (status, i, item) => {
    let data = dataFormat();
    data.loanTranches[idx].loanTrancheComponentDrawdowns[
      i
    ].trancheComponentDrawdownStatus = status;
    handleStatusUpdate(data);
  };
  const removeDrowdown = useCallback(
    (index, data) => {
      let filteredName = data?.values?.Fianciers?.value;
      let nData = drawdownList;
      nData = nData.filter((item) => item?.Fianciers?.value !== filteredName);
      setDrawdownList(nData);
      dataFormat("DRAFT");
    },
    [drawdownList]
  );

  const dataFormatCompLoanStruct = (actionType) => {
    let nvar = {};
    nvar = { ...loanReferenceId };
    nvar["loanStatus"] = "IN_REVIEW";
    nvar["loanTranches"] = nvar?.loanTranches.map((items) => ({
      ...items,
      loanTrancheStatus: actionType,
      loanTrancheComponentDrawdowns: items?.loanTrancheComponentDrawdowns.map(
        (item) => ({
          ...item,
          trancheComponentDrawdownStatus:
            item?.trancheComponentDrawdownStatus === "APPROVED"
              ? actionType
              : item?.trancheComponentDrawdownStatus,
        })
      ),
      loanTrancheComponents: items?.loanTrancheComponents.map((item) => ({
        ...item,
        loanStatus:
          item?.loanStatus === "APPROVED" ? actionType : item?.loanStatus,
      })),
    }));
    let json = {
      ...nvar,
    };
    return json;
  };

  const completeLoanStructure = (status) => {
    let payload = dataFormatCompLoanStruct(status);
    handleStatusUpdate(payload);
    console.log(payload);
  };

  const sendProps = {
    handleChange,
    handleSandToFinancer,
    createDealStructure,
    showfinacerTable,
    enableDrawdown,
    showDrawdown,
    extraCollopse,
    disabledealStructure,
    createDrawdown,
    enableStepper2,
    resetFlow,
    skipDrawdown,
    cancelDrawdown,
    addDrawdown,
    goBack,
    removeFinancer,
    apiData,
    tranchelist,
    drawdownList,
    options,
    apicall,
    newDrawdown,
    cancelDraw,
    back,
    removeDrowdown,
    onSubmit,
    setIdx,
    idx,
    enableStepper,
    backFormCreateDrwodown,
    backFormTranchTable,
    handleAccept,
    handleReject,
    handleAcceptDD,
    handleRejectDD,
    handleAddNewTranche,
    addNewTrancheBool,
    handlSaveTranche,
    sendBool,
    completeLoanStructure,
    listFinancer,
    confirmFinancers,
    showFinancersTable,
    setShowFinancersTable,
    financeTable,
    showTable,
    setShowTable,
    createFinancer,
    showFinancers,
    setShowFinancers,
    selectedFinanacerFunc,
    showSelectedFinancer,
    changeFinancer,
    editTranche,
  };

  return (
    <>
      {dealStructure && (
        <button type="button" className={styles.collapsible}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b
              style={{
                fontSize: "20px",
                fontWeight: "700",
                color: type === true ? "#42A5F5" : "white",
              }}>
              {drowdown ? "Drawdowns" : "Deal Structure (Syndicate Finance)"}
            </b>
          </div>
        </button>
      )}
      {type && (
        <>
          <div style={{ background: "black", paddingBottom: "30px" }}>
            <Card
              style={{
                backgroundColor:
                  activeStep === 0
                    ? "#1E1E1E"
                    : stepper === false
                    ? "#000000"
                    : "#343434",
                padding: "30px 20px",
                borderRadius: "5px",
                margin: "0px 10px",
              }}>
              <div style={{ color: "white" }}>
                {!transactionSet ? (
                  <div>
                    <h4> Tranches</h4>
                    <br></br>
                    <br></br>
                    <h4 style={{ color: "#33C30C" }}>
                      No tranche has been added yet
                    </h4>
                    <br></br>
                    <br></br>
                  </div>
                ) : (
                  <form
                    className={styles.formbox}
                    // onSubmit={(e) => {
                    //     e.preventDefault();
                    //     if (financeTable===true) {
                    //         setAcitveStep(1)
                    //     }
                    //     else {
                    //         setAcitveStep(activeStep + 1)
                    //     }
                    //     if (activeStep === 2) {
                    //         onSubmit(e);
                    //     }
                    // }}
                  >
                    {stepper && (
                      <>
                        <h4 style={{ margin: "0px" }}>Tranche Set-Up</h4>
                        <br></br>
                        <br></br>
                        <Box sx={{ width: "100%" }}>
                          <Stepper
                            className="boxsteper"
                            activeStep={activeStep}
                            alternativeLabel>
                            {steps.map((label, index) => (
                              <Step key={label} style={{ cursor: "pointer" }}>
                                <StepLabel>{label}</StepLabel>
                              </Step>
                            ))}
                          </Stepper>
                          <br></br>
                          <br></br>
                        </Box>
                      </>
                    )}

                    {!newDrawdown &&
                      {
                        0: <StepperOne {...sendProps} companyId={companyId} />,
                        1: <StepperTwo {...sendProps} companyId={companyId} />,
                        2: (
                          <StepperThree {...sendProps} companyId={companyId} />
                        ),
                      }[activeStep]}

                    <br></br>
                    {activeStep !== 1 && (
                      <div style={{ display: "flex", justifyContent: "end" }}>
                        {activeStep !== 0 && (
                          <Button
                            onClick={(e) => prev(e)}
                            style={{
                              display: activeStep !== 1 ? "none" : "block",
                            }}>
                            Back
                          </Button>
                        )}
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={enableStepper2}
                          style={{
                            width: "100px",
                            display: activeStep === 2 ? "none" : "block",
                          }}>
                          {activeStep === steps.length - 1 ? "Finish" : "Next"}
                        </Button>
                      </div>
                    )}
                  </form>
                )}
                {drowdown && (
                  <NewDrawdown {...sendProps} companyId={companyId} />
                )}
              </div>
            </Card>

            {!transactionSet && (
              <div style={{ textAlign: "right" }}>
                <br></br>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => prev(e)}
                  style={{ width: "200px" }}>
                  Back to Deal
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => enableStepper()}
                  style={{
                    width: "200px",
                    marginLeft: "50px",
                    marginRight: "10px",
                  }}>
                  Add Tranche
                </Button>
              </div>
            )}
          </div>
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}
