import React, { useCallback, useEffect, useContext } from "react";
import { invoiceURL, NetworkURL } from "../../../services/BaseURLInstance";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { AiFillEye } from "react-icons/ai";
import styles from "../invoice.module.css";
import { useState } from "react";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { invoiceFinanceContext } from "../../../contexts/invoiceFinance/invoiceFinanceContext";
import { storeContext } from "../../../contexts/Store/Store";
import { CompanyNameFilter, notify } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import Content from "../../../components/UI/Layout/Content";
import Table from "react-bootstrap/Table";
import { InvoiceListHeader } from "../../../utils/Section/InvoiceSection";
import cn from "classnames";
import { TableStatus, FindCompanyName } from "utils/helper";
export default () => {
  const { setSaveInvoiceData, setInvoiceData } = useContext(
    invoiceFinanceContext
  );
  const { fetchData, fetchCompanyList } = useContext(storeContext);
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const companyId = getClaim(accounts, "company_member_id");
  const companyType = getClaim(accounts, "company_type");

  const [Data, setData] = useState("");
  const [Error, setError] = useState("");
  const [active, setActive] = useState("");
  const [filterData, setFilterData] = useState([]);
  const handleNavigate = useCallback((url) => navigate(url), []);
  const handlePromise = async (item) => {};
  const [CompanyList, setCompanyList] = useState([]);

  useEffect(() => {
    const findData = async () => {
      let URL = `${invoiceURL}invoices?sellerMemberId=${companyId}&status=NEW`;
      const Data = await fetchData(URL);
      {
        Data && Data.data && setData(Data.data);
      }
      {
        Data && Data.error && setError(Data.error);
      }
    };
    findData();
  }, []);

  const handleNavigateInvoice = async (event, id) => {
    event.preventDefault();
    let URL = `${invoiceURL}invoices/${id}`;
    const Data = await fetchData(URL, idToken);
    localStorage.setItem("uploadData", JSON.stringify(Data.data));
    if (Data && Data.data && !Data.error) {
      if (Data.data) {
        setSaveInvoiceData(Data.data);
        handleNavigate(`../invoicesDetails/${id}`);
      } else {
        setInvoiceData(Data.data);
        handleNavigate(`${id.invoiceNumber}`);
      }
    }
    if (Data && Data.error) {
      notify("warn");
    }
  };

  useEffect(() => {
    if (Data) {
      setFilterData(Data);
    }
  }, [Data]);

  useEffect(() => {
    if (active === 0) {
      let data = Data.filter(
        (x) => x.txlStatus === "NEW" || x.txlStatus === "OPEN"
      );
      setFilterData(data);
    } else if (active === 1) {
      let data = Data.filter((x) => x.txlStatus === "FINANCED");
      setFilterData(data);
    } else if (active === 2) {
      let data = Data.filter((x) => x.txlStatus === "CLOSED");
      setFilterData(data);
    } else if (active === 3) {
      let data = Data.filter(
        (x) => x.txlStatus === "BLOCKED" || x.txlStatus === "PENDING"
      );
      setFilterData(data);
    }
  }, [active]);

  useEffect(() => {
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken).then((companyList) => {
      setCompanyList(companyList);
    });
  }, []);

  // const headerFieldsList = [
  //   {
  //     Header: "Invoice ID",
  //     accessor: "invoiceNumber",
  //     Cell: (cell) => (
  //       <div
  //         value={cell.accessor}
  //         onClick={(e) => handleNavigateInvoice(e, cell.cell.row.values)}
  //       >
  //         {cell.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     Header: "Counter Party",
  //     accessor: "txlBuyerMemberId",
  //     Cell: (cell) => (
  //       <span>
  //         {CompanyNameFilter(CompanyList, cell.value).length >= 1
  //           ? CompanyNameFilter(CompanyList, cell.value)[0].memberName
  //           : ""}
  //       </span>
  //     ),
  //   },
  //   {
  //     Header: "Created Date",
  //     accessor: "createdDate",
  //     Cell: (cell) => <span>{new Date(cell.value).toLocaleDateString()}</span>,
  //   },
  //   {
  //     Header: "Status",
  //     accessor: "txlStatus",
  //   },
  // ];
  // const columns = React.useMemo(() => headerFieldsList);

  const tabs = [
    { name: "New Invoice", value: "new" },
    { name: "Financed", value: "financed" },
    { name: "Closed", value: "closed" },
    { name: "In-Progress", value: "progress" },
  ];

  const handleDocumentPreview = (e, id) => {
    e.preventDefault();
    if (id === undefined) {
      alert("Not available");
    } else {
      handleNavigate(`/documents/preview/COMPANY/${id}`);
    }
  };

  return (
    <>
      {Data === "" && <Loader />}

      {Data !== "" && (
        <div>
          <div className={styles.invoiceHeader}>
            <div className={styles.invoiceTopHeader}>
              <span className={styles.heading1}> Search Invoices </span>
              <button
                className={styles.uploadInvoiceBtn}
                onClick={() =>
                  handleNavigate(`/financedashBoard/invoicefinancing/upload`)
                }
              >
                + Upload Invoice
              </button>
            </div>
            <div className={styles.heading2}>
              <span>Quick Filter</span>
            </div>
            <div className={styles.searchDiv}>
              {tabs.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setActive(index)}
                  className={
                    index == active ? styles.activeDiv : styles.nonActiveDiv
                  }
                >
                  {item.name}
                </div>
              ))}
              <input type="text" placeholder="Search" />
            </div>
          </div>
          <Content className={styles.wrapper}>
            <div className={styles.wrapperTitle}>Search Results</div>

            <Table bordered responsive className={cn(styles.Table)}>
              <thead>
                <tr>
                  {InvoiceListHeader.map((header, index) => (
                    <td key={index}>{header.name}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Data !== "" &&
                  Data.map((user, index) => (
                    <tr key={index} style={{ cursor: "pointer" }}>
                      <td
                        className="rahul"
                        onClick={(e) =>
                          handleNavigateInvoice(e, user.txlInvoiceId)
                        }
                      >
                        {user.invoiceNumber}
                      </td>
                      <td>
                        {FindCompanyName(CompanyList, user.txlBuyerMemberId)}
                      </td>
                      <td>
                        {FindCompanyName(CompanyList, user.txlSellerMemberId)}
                      </td>
                      <td>{new Date(user.createdDate).toLocaleDateString()}</td>
                      <td>{TableStatus(`${user.txlStatus}`)}</td>
                      <td
                        onClick={(e) =>
                          handleDocumentPreview(e, user.document.id)
                        }
                        className={styles.invoiceNavigation}
                      >
                        {/* Invoice Doc */}
                        <AiFillEye size={26} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Content>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
