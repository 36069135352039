import React, { useState, useEffect } from "react";
import {
  invoiceURL,
  profileURL,
  NetworkURL,
  networkShareApi,
} from "services/BaseURLInstance";
import "./Subscriptionpayment.css";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { ButtonStyled } from "components/Container.styled";
import { CardNetworkWapper } from "components/Container.styled";
import { Link, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";

const SubscriptionPayment = ({
  paymentData,
  setIsTermChecked,
  isTermChecked,
}) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const { instance, accounts } = useMsal();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [response, setResponse] = useState([]);
  const [role, setRole] = useState();
  const [check, setCheck] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  const history = useNavigate();

  const handleClick = (url) => {
    history.push(url);
  };

  const Request = () => {
    if (memberType === "FINANCER") {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${profileURL}financers/${companyId}`,
          null,
          response
        ).then((data) => {
          setFilterData(data);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message);
          }
        });
      });
    }
    else if(memberType === "COMPANY"){
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setData(data);
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
    }
  else{
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}companies/${companyId}`,
        null,
        response
      ).then((data) => {
        setFilterData(data);
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setIsError(message);
        }
      });
    });
  }
  };
  useEffect(() => {
    Request();
  }, []);
  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };
  function urlencodeFormData(fd) {
    var s = "";
    function encode(s) {
      return encodeURIComponent(s).replace(/%20/g, "+");
    }
    for (var pair of fd.entries()) {
      if (typeof pair[1] == "string") {
        s += (s ? "&" : "") + encode(pair[0]) + "=" + encode(pair[1]);
      }
    }
    return s;
  }

  const handleCheckChange = (event) => {
    if (isTermChecked) {
      setIsTermChecked(false);
    } else {
      setIsTermChecked(true);
    }
  };

  const header = [
    {
      name: "Items",
      flex: "flex-1",
    },
    {
      name: "Charges",
      flex: "flex-1",
    },
  ];

  return (
    <div className="panel">
      <div className="panelHeading">
        <h3
          style={{
            marginLeft: "29%",
            marginRight: "20%",

            marginBottom: "2%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Checkout for Tradxlink Subscription
        </h3>
      </div>
      <CardNetworkWapper>
        <div style={{ display: "flex" }}>
          <h4 style={{ color: "#42a5f5" }}>Customer Billing Details</h4>{" "}
          <span style={{ marginLeft: "54%" }}>
            Transaction Date : {new Date().toLocaleDateString()}
          </span>
        </div>
        <div>{data && data.companyName}</div>
        <div>
          {memberType === "FINANCER"
            ? filterData && filterData.financerAddress
            : filterData && filterData.companyAddress}
        </div>
        <div>
          {memberType === "FINANCER"
            ? filterData && filterData.countryCode
            : filterData && filterData.companyCountry}
        </div>
        <div>
          Phone :{" "}
          {memberType === "FINANCER"
            ? filterData && filterData.financerPhone
            : filterData && filterData.companyPhone}
        </div>
      </CardNetworkWapper>
      <CardNetworkWapper style={{ marginTop: "2%" }}>
        <h4 style={{ color: "#42a5f5" }}>Order Details</h4>
        <Table>
          {" "}
          <thead style={{ color: "#42a5f5" }}>
            {" "}
            <tr>
              {header.map((header, index) => (
                <td key={index}>{header.name}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Subscription(Premium - 1 Month)</td>
              <td>INR 800</td>
            </tr>
            <tr>
              <td>Sub Total</td>
              <td>INR 800</td>
            </tr>
            <tr>
              <td>GST(18%)</td>
              <td>INR 144</td>
            </tr>
            <tr>
              <td>Total</td>
              <td>INR 944</td>
            </tr>
          </tbody>
        </Table>
        {/* <div style={{ backgroundColor: "black", width: "100%", padding: "2%" }}>
          <div>
            <div
              style={{
                display: "flex",
                marginTop: "1%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="overviewListItem">
                <div className="overViewListFirstTile">Product Name</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">
                  Premium Subscription
                  {/* {paymentDetail?.paymentStatus ? paymentDetail.paymentStatus : 'New'} */}
        {/* </div>
              </div>
              <div className="overviewListItem">
                <div className="overViewListFirstTile">Subscription Type</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">
                  Premium */}
        {/* {paymentData &&
                  paymentData.map((item) => {
                    if (item.memberUID === buyerMemberId) {
                      return item.countryCode;
                    }
                  })} */}
        {/* </div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            > */}
        {/* <div className="overviewListItem">
                <div className="overViewListFirstTile">Months</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">
                  1 Month */}
        {/* {companyList.map((item) => {
                        if (item.memberUID === sellerMemberId) {
                            return item.countryCode;
                        }
                    })} */}
        {/* </div> */}
        {/* </div> */}
        {/* <div className="overviewListItem">
                <div className="overViewListFirstTile">Payment Currency</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">INR</div>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            > */}
        {/* <div className="overviewListItem">
                <div className="overViewListFirstTile">Payment Amount</div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile"> Rs. 100 </div>
              </div>
              <div className="overviewListItem">
                <div className="overViewListFirstTile">
                  Total Payment with GST
                </div>
                <div className="colon">:</div>
                <div className="overViewListSecondTile">Rs. 118</div>
              </div>
            </div>
          </div>
        </div> */}
      </CardNetworkWapper>

      <div className="panelHeading" style={{ marginTop: "3%" }}></div>
      <CardNetworkWapper>
        <div style={{ color: "#42a5f5" }}>
          Please select the preferred payment method to use on this order.
        </div>
        <div>
          <input
            type="radio"
            value="option2"
            checked={selectedOption === "option2"}
            onChange={handleRadioChange}
          ></input>{" "}
          Credit Card / Debit Card / Net Banking
        </div>
        <div>
          <input
            type="radio"
            value="option1"
            checked={selectedOption === "option1"}
            onChange={handleRadioChange}
          ></input>{" "}
          UPI
        </div>
        <div style={{ marginTop: "3%" }}>
          <input
            type="checkbox"
            value={isTermChecked}
            onChange={(e) => handleCheckChange(e)}
          ></input>{" "}
          I have read and agree to the
          {/* <Link
            to= "https://tradxlink.com/terms-conditions/"
            rel="noopener noreferrer"
            target="_blank"
          >
            {" "}
            Terms & Conditions
          </Link> */}{" "}
          <a
            href="https://tradxlink.com/terms-conditions/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleClick}
          >
            Terms & Conditions
          </a>
        </div>
      </CardNetworkWapper>

      {/* <ButtonStyled style={{ marginTop: "3%", marginLeft: "70%" }}>
        Make Payment
      </ButtonStyled> */}
    </div>
  );
};

export default SubscriptionPayment;
