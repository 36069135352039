import React, {
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { CheckPermission } from "utils/Constants/RBAC";
import { FiEdit } from "react-icons/fi";
import { AiFillStar } from "react-icons/ai";
import { FaBuilding, FaTwitterSquare, FaWhatsappSquare } from "react-icons/fa";
import { BsCheckCircleFill, BsTelephone } from "react-icons/bs";
import { SiGooglemaps } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { AiFillLinkedin } from "react-icons/ai";
import { RiWechatLine } from "react-icons/ri";
import {
  MdOutlineAttachEmail,
  MdCancel,
  MdOutlineCancel,
} from "react-icons/md";
import styles from "../Networks/companyDetails.module.css";
import { HiBadgeCheck } from "react-icons/hi";
import Button from "components/UI/Button/Button";
import {
  profileURL,
  docGroupURL,
  documentURL,
  serviceURL,
  linkMemberURL,
} from "services/BaseURLInstance";
import Content from "components/UI/Layout/Content";
import { storeContext } from "contexts/Store/Store";
import Loader from "components/Loader/Loader";
import ServiceCard from "components/UI/NetworkSection/ServiceCard";
import cn from "classnames";
import { UseRequest } from "utils/API/Request.js";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { NetworkURL, onBoardingURL } from "services/BaseURLInstance";
import ProfileStepper from "./profileStepper";
import classes from "./profile.module.css";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import { ImageURLSrc, CheckCountryTitle } from "utils/helper";
import OnBoarding from "pages/Networks/Detail/OnBoarding";
import PerformanceCard from "pages/Networks/Detail/PerformanceCard";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import Modal from "components/UI/Modal/Modal";
import AddCertificate from "./AddCertificate";
import AddProduct from "./AddProduct";

export default function ({ toggleChat, hanndleCraateGroup }) {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const currRef = useRef(false);
  const { getData } = useContext(storeContext);
  const [companyData, setCompanyData] = useState(null);
  const [financerData, setFinancerData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const [companySubType1, setCompanySubType1] = useState("");
  const [companySubType2, setCompanySubType2] = useState("");
  const [serviceData, setServiceData] = useState(null);
  const [listNumber, setListNumber] = useState(3);
  const [hideBtn, setHideBtn] = useState(false);
  const { instance, accounts } = useMsal();
  const [companyList, setCompanyList] = useState(null);
  const [isOnboarding, setIsOnboarding] = useState(false);
  const { companyName } = useContext(AuthContext);
  const [networkData, setNetworkData] = useState(null);
  const [favoriteData, setFavoriteData] = useState(null);
  const [Role, setRole] = useState(null);
  const [onboardingRole, setOnboardingRole] = useState(null);
  const [editProfileRole, setEditProfileRole] = useState(null);
  const [isAddCertificate, setIsAddCertificate] = useState(false);
  const [trustData, setTrustData] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const [Direction, setDirection] = useState({});

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setOnboardingRole(
        CheckPermission(
          "CompleteOnboarding",
          "Onboarding",
          "CompleteOnboarding",
          Role
        )
      );

      setEditProfileRole(CheckPermission("Edit", "EditProfile", "edit", Role));
    }
  }, [Role]);

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${onBoardingURL}onboarding/participant/${companyId}`,
          null,
          response
        ).then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setTrustData(data);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${NetworkURL}network/${companyId}/?memberId=${companyId}`,

          null,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message);
          } else {
            setFavoriteData(data.favoriteMembers);
            setNetworkData(data.connectedMembers);
          }
        });
      });
    }

    if (!isCancelled) {
      if (memberType === "FINANCER") {
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(
            `${profileURL}financers/${companyId}`,
            null,
            response
          ).then((data) => {
            let { message } = data;
            if (message) {
              Error(message);
              setIsError(message);
            } else {
              setFinancerData(data);
              let id = data.companyLogoURL || data.financerLogo;
              if (data.companyLogoURL || data.financerLogo) {
                getIdTokenAsync(instance, accounts).then((response) => {
                  UseRequest(
                    `${documentURL}documents/${id}`,
                    null,
                    response
                  ).then((res) => {
                    let { message, path } = res;
                    if (message || path) {
                      Error(message);
                    } else {
                      setDocumentData(res);
                    }
                  });
                });
              }
            }
          });
        });
      } else {
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(
            `${profileURL}companies/${companyId}`,
            null,
            response
          ).then((data) => {
            let { message } = data;
            if (message) {
              Error(message);
              setIsError(message);
            } else {
              setCompanyData(data);
              let id = data.companyLogoURL || data.financerLogo;
              if (data.companyLogoURL || data.financerLogo) {
                getIdTokenAsync(instance, accounts).then((response) => {
                  UseRequest(
                    `${documentURL}documents/${id}`,
                    null,
                    response
                  ).then((res) => {
                    let { message, path } = res;
                    if (message || path) {
                      Error(message);
                    } else {
                      setDocumentData(res);
                    }
                  });
                });
              }
            }
          });
        });
      }
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${docGroupURL}bff/documentsGroups/COMPANY/${companyId}`,
          null,
          response
        ).then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setCompanySubType1(data[0]);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${docGroupURL}bff/documentsGroups/ACCREDITATION/${companyId}`,
          null,
          response
        ).then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            // setAccreditation1(data[0]);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${serviceURL}services`, null, response).then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setServiceData(data);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${linkMemberURL}members?memberId=${companyId}`,
          null,
          response
        )
          .then((data) => {
            let { message } = data;
            if (message) {
              Error(message);
              setIsError(message);
            } else {
              setCompanyList(data);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (idToken && companySubType1 && currRef.current === false) {
      let documents = companySubType1.documents;
      let docObject = documents.filter((doc) => {
        return doc.docReferenceId !== "";
      });
      for (let i = 0; i < docObject.length; i++) {
        let URL2 = `${documentURL}documents/${docObject[i].docReferenceId}`;
        getData(URL2, idToken).then(async (response) => {
          setCompanySubType2((e) => [...e, response]);
        });
      }
      return () => {
        currRef.current = true;
      };
    }
  }, [companySubType1]);

  const handleClick = () => {
    if (
      window.location.href.substring(22, window.location.href.length) !==
        "chat" &&
      window.location.href.substring(26, 27) !== "?"
    ) {
      let url = window.location.href;
      localStorage.setItem("lasturl", url);
      navigate("/chat");
    } else {
      navigate(
        `/${localStorage
          .getItem("lasturl")
          .substring(22, localStorage.getItem("lasturl").length)}`
      );
    }
  };

  const handleToggle = () => {
    if (hideBtn === false) {
      setHideBtn(!hideBtn);
      setListNumber(companySubType2.length);
    } else {
      setHideBtn(!hideBtn);
      setListNumber(3);
    }
  };

  useEffect(() => {
    if (companyData) {
      const Data =
        companyData &&
        companyData.memberGeoLocation &&
        JSON.parse(companyData && companyData.memberGeoLocation);
      if (Data) {
        setDirection({
          ...Direction,
          Latitude: Data[0],
          Longitude: Data[1],
        });
      }
    }
  }, [companyData]);

  const ACCREDITATION = [
    {
      certificationId: "ISO 920001",
      memberUID: "77e41659-6277-4c33-85b6-39731a7b9770",
      certificationName: "ISO Manufacturing",
      certificateDescription: "Manufaction Quality Process Certification",
      certificationIssueDate: "1989-45-14T10:29:76.196Z",
      certificationExpiryDate: "2903-25-33T19:35:00.337Z",
      certificationIssuingOrganization: "ISO",
      certificationIssuingCountry: "IN",
      certificationDocuments: [
        {
          documentId: "",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
      ],
      status: "NOT_REQUESTED",
      verifier: "77e41659-6277-4c33-85b6-39731a7b9771",
      verificationDate: "1437-88-09T10:67:49.954Z",
      createdBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      updatedBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      createdDate: "1480-90-52T35:14:25.805Z",
      updatedDate: "9537-78-56T82:03:18.469Z",
    },
    {
      certificationId: "ISO 920001",
      memberUID: "77e41659-6277-4c33-85b6-39731a7b9770",
      certificationName: "ISO Manufacturing",
      certificateDescription: "Manufaction Quality Process Certification",
      certificationIssueDate: "1989-45-14T10:29:76.196Z",
      certificationExpiryDate: "2903-25-33T19:35:00.337Z",
      certificationIssuingOrganization: "ISO",
      certificationIssuingCountry: "IN",
      certificationDocuments: [
        {
          documentId: "",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
      ],
      status: "NOT_REQUESTED",
      verifier: "77e41659-6277-4c33-85b6-39731a7b9771",
      verificationDate: "1437-88-09T10:67:49.954Z",
      createdBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      updatedBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      createdDate: "1480-90-52T35:14:25.805Z",
      updatedDate: "9537-78-56T82:03:18.469Z",
    },
    {
      certificationId: "ISO 920001",
      memberUID: "77e41659-6277-4c33-85b6-39731a7b9770",
      certificationName: "ISO Manufacturing",
      certificateDescription: "Manufaction Quality Process Certification",
      certificationIssueDate: "1989-45-14T10:29:76.196Z",
      certificationExpiryDate: "2903-25-33T19:35:00.337Z",
      certificationIssuingOrganization: "ISO",
      certificationIssuingCountry: "IN",
      certificationDocuments: [
        {
          documentId: "",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
      ],
      status: "NOT_REQUESTED",
      verifier: "77e41659-6277-4c33-85b6-39731a7b9771",
      verificationDate: "1437-88-09T10:67:49.954Z",
      createdBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      updatedBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      createdDate: "1480-90-52T35:14:25.805Z",
      updatedDate: "9537-78-56T82:03:18.469Z",
    },
    {
      certificationId: "ISO 920001",
      memberUID: "77e41659-6277-4c33-85b6-39731a7b9770",
      certificationName: "ISO Manufacturing",
      certificateDescription: "Manufaction Quality Process Certification",
      certificationIssueDate: "1989-45-14T10:29:76.196Z",
      certificationExpiryDate: "2903-25-33T19:35:00.337Z",
      certificationIssuingOrganization: "ISO",
      certificationIssuingCountry: "IN",
      certificationDocuments: [
        {
          documentId: "",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
      ],
      status: "NOT_REQUESTED",
      verifier: "77e41659-6277-4c33-85b6-39731a7b9771",
      verificationDate: "1437-88-09T10:67:49.954Z",
      createdBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      updatedBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      createdDate: "1480-90-52T35:14:25.805Z",
      updatedDate: "9537-78-56T82:03:18.469Z",
    },
    {
      certificationId: "ISO 920001",
      memberUID: "77e41659-6277-4c33-85b6-39731a7b9770",
      certificationName: "ISO Manufacturing",
      certificateDescription: "Manufaction Quality Process Certification",
      certificationIssueDate: "1989-45-14T10:29:76.196Z",
      certificationExpiryDate: "2903-25-33T19:35:00.337Z",
      certificationIssuingOrganization: "ISO",
      certificationIssuingCountry: "IN",
      certificationDocuments: [
        {
          documentId: "",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
        {
          documentId: "77e41659-6277-4c33-85b6-39731a7b9770",
        },
      ],
      status: "NOT_REQUESTED",
      verifier: "77e41659-6277-4c33-85b6-39731a7b9771",
      verificationDate: "1437-88-09T10:67:49.954Z",
      createdBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      updatedBy: "77e41659-6277-4c33-85b6-39731a7b9770",
      createdDate: "1480-90-52T35:14:25.805Z",
      updatedDate: "9537-78-56T82:03:18.469Z",
    },
  ];

  return (
    <>
      <Modal
        show={isAddCertificate}
        onHide={() => setIsAddCertificate(false)}
        Data={
          <>
            {/* <AddCertificate /> */}
            <AddProduct />
          </>
        }
        centered={"false"}
        Heading={"Add Certificate"}
      />

      {isLoading ? (
        <Content>
          <span>Loading..........</span>
          <Loader />
        </Content>
      ) : (
        <>
          {isOnboarding ? (
            <ProfileStepper setIsOnboarding={setIsOnboarding} />
          ) : (
            <>
              <div
                className="d-flex justify-content-between"
                style={{ padding: "0% 2% 1% 2%" }}>
                <div
                  className={classes.onboardingStatus}
                  style={{ margin: "0px 0px 10px 0px" }}>
                  <span>Onboarding Status</span>
                  <span>{trustData && trustData.onboardingStatus}</span>
                  {trustData &&
                    trustData.onboardingStatus &&
                    trustData.onboardingStatus !== "PASSED" && (
                      <span
                        // disabled={onboardingRole === "NOTAPP" ? " " : handleNavigate(`onboarding`)}}
                        onClick={() =>
                          onboardingRole === "NOTAPP"
                            ? " "
                            : handleNavigate(`onboarding`)
                        }>
                        Complete Onboarding
                      </span>
                    )}

                  {trustData == null && (
                    <span
                      // disabled={onboardingRole === "NOTAPP" ? " " : handleNavigate(`onboarding`)}}
                      onClick={() =>
                        onboardingRole === "NOTAPP"
                          ? " "
                          : handleNavigate(`onboarding`)
                      }>
                      Complete Onboarding
                    </span>
                  )}
                </div>
                <div
                  className={styles.navigateToEdit}
                  onClick={() =>
                    editProfileRole === "READ" || editProfileRole === "NOTAPP"
                      ? handleNavigate(`/profile/company-profile`)
                      : // handleNavigate(`/company-profile`)

                        handleNavigate(`/profile/edit`)
                  }>
                  <FiEdit size={26} />
                  <span>Edit</span>
                </div>
              </div>

              {companyData && (
                <div
                  className={styles.container}
                  style={{
                    padding: "1% 2%",
                    backgroundColor: "black",
                    marginBottom: "2%",
                  }}>
                  <div className={styles.wrapper}>
                    <div className={styles.leftWrapper}>
                      <div className={styles.imgDiv}>
                        <img
                          src={ImageURLSrc(documentData && documentData.id)}
                        />
                      </div>

                      <div className={styles.profileDiv}>
                        <div className={styles.namingDiv}>
                          <div
                            className={cn(
                              styles.certficateIconDiv,
                              "d-flex align-items-center"
                            )}>
                            <div>
                              {companyData.verified === "VERIFICATION_PASS" ? (
                                <HiBadgeCheck
                                  style={{
                                    color: "#4CAF50",
                                  }}
                                  size={24}
                                />
                              ) : (
                                <MdOutlineCancel
                                  style={{
                                    color: "rgba(255, 161, 0, 1)",
                                  }}
                                  size={20}
                                />
                              )}
                            </div>

                            <div className={cn("px-1")}>
                              {companyData.companyName}
                            </div>
                          </div>

                          <div>
                            <div
                              className={
                                companyData.memberType === "COMPANY"
                                  ? styles.companyType
                                  : styles.supplierType
                              }>
                              {companyData.memberType}
                            </div>
                          </div>
                        </div>

                        <div className={styles.flexStart}>
                          <div>
                            Country:
                            {companyData?.companyCountry
                              ? CheckCountryTitle(companyData?.companyCountry)
                              : "Not Provided"}
                          </div>
                        </div>

                        <div className={styles.flexStart}>
                          <div>
                            SiteURL:
                            {companyData.companySiteURL
                              ? companyData.companySiteURL
                              : "Not Provided"}
                          </div>
                        </div>

                        <div className={styles.flexStart}>
                          <div style={{ marginRight: "5px" }}>
                            Company Rating
                          </div>
                          <div className={styles.iconStyle}>
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                          </div>
                        </div>

                        <div className={styles.iconDiv}>
                          <span
                            onClick={() =>
                              window.open(companyData.linkedInLink)
                            }>
                            <AiFillLinkedin
                              style={{ marginLeft: "10%" }}
                              fill="rgba(53, 153, 238, 1)"
                              size={26}
                            />
                          </span>
                          <span
                            onClick={() =>
                              window.open(companyData.twitterLink)
                            }>
                            <FaTwitterSquare
                              style={{ marginLeft: "10%" }}
                              fill="rgba(53, 153, 238, 1)"
                              size={24}
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.rightWrapper}>
                      <div className={styles.flexColumn}>
                        <div className={styles.detailsDiv}>
                          <div className={styles.addressDiv}>
                            <div
                              className={styles.iconStyle}
                              style={{ marginRight: "5px" }}>
                              <FaBuilding
                                size={25}
                                fill=" rgba(33, 142, 235, 1)"
                              />
                            </div>
                            <div>
                              Address:
                              {companyData.companyAddress
                                ? companyData.companyAddress.map((item) => (
                                    <>
                                      <span>{item}</span>
                                      <br></br>
                                    </>
                                  ))
                                : " Not Provided"}
                            </div>
                          </div>

                          <div className={styles.phoneDiv}>
                            {companyData.companyPhone &&
                            companyData.companyPhone.length > 0 ? (
                              <>
                                {companyData.companyPhone &&
                                  companyData.companyPhone[0] &&
                                  companyData.companyPhone[0] !== "" && (
                                    <div className={styles.phoneContainer}>
                                      <div
                                        className={styles.iconStyle}
                                        style={{ marginRight: "5px" }}>
                                        <BsTelephone
                                          size={24}
                                          color="rgba(33, 142, 235, 1)"
                                        />
                                      </div>
                                      <div>{companyData.companyPhone[0]}</div>
                                    </div>
                                  )}

                                {companyData.companyPhone &&
                                  companyData.companyPhone[1] &&
                                  companyData.companyPhone[1] !== "" && (
                                    <div className={styles.phoneContainer}>
                                      <div
                                        className={styles.iconStyle}
                                        style={{ marginRight: "5px" }}>
                                        <BsTelephone
                                          size={24}
                                          color="rgba(33, 142, 235, 1)"
                                        />
                                      </div>
                                      <div>{companyData.companyPhone[1]}</div>
                                    </div>
                                  )}
                              </>
                            ) : (
                              <span>
                                <span style={{ marginRight: "5px" }}>
                                  <BsTelephone
                                    size={24}
                                    color="rgba(33, 142, 235, 1)"
                                  />
                                </span>
                                Not Provided
                              </span>
                            )}
                          </div>
                        </div>

                        <div className={styles.mapDiv}>
                          <div>
                            <div className={styles.iconStyle}>
                              <SiGooglemaps
                                size={24}
                                color="rgba(33, 142, 235, 1)"
                              />
                            </div>
                            <div style={{ color: "rgba(33, 142, 235, 1)" }}>
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "rgba(33, 142, 235, 1)",
                                }}
                                // href="https://www.google.com/maps/search/trade+links+pvt+ltd/@28.5566977,77.2460797,11z/data=!3m1!4b1"
                                href={`https://www.google.com/maps/search/?api=1&query=${
                                  Direction && Direction.Latitude
                                },${Direction && Direction.Longitude}`}
                                target="_blank">
                                View Google Map
                              </a>
                            </div>
                          </div>

                          <div className={styles.icon_box}>
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              key="top"
                              placement="top"
                              overlay={
                                <Popover id="popover-positioned-top">
                                  <Popover.Body>
                                    <strong>Chat</strong>
                                  </Popover.Body>
                                </Popover>
                              }>
                              <span>
                                <RiWechatLine
                                  size={24}
                                  color="rgba(53, 153, 238, 1)"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => toggleChat("global")}
                                />
                              </span>
                            </OverlayTrigger>

                            <MdOutlineAttachEmail
                              size={24}
                              color="rgba(53, 153, 238, 1)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Description Section */}
                  <div className={styles.Wrapper1}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Company Description:
                    </div>

                    {companyData.companyDescription ? (
                      <span>{companyData.companyDescription}</span>
                    ) : (
                      <span>Discription: NA</span>
                    )}
                  </div>

                  {/* Products and Services */}
                  <div className={styles.Wrapper1}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Products and Services:
                    </div>

                    {companyData.companyProductsServices ? (
                      <span>{companyData.companyProductsServices}</span>
                    ) : (
                      <span>Products and Services: NA</span>
                    )}
                  </div>

                  {/*Registration Details*/}
                  <div className={styles.Wrapper1}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Registration Details:
                    </div>
                    <div className={styles.registrationDiv}>
                      <div>
                        <div className={styles.registrationList}>
                          <div>Registration Number</div>
                          <div className={styles.colorBlue2}>
                            {companyData.registrationNumber
                              ? companyData.registrationNumber
                              : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>LEI</div>
                          <div className={styles.colorBlue2}>
                            {companyData.lei ? companyData.lei : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>SIC Code</div>
                          <div className={styles.colorBlue2}>
                            {companyData.sicCode
                              ? companyData.sicCode
                              : "Not Provided"}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className={styles.registrationList}>
                          <div>GSTIN</div>
                          <div className={styles.colorBlue2}>
                            {companyData.GSTIN
                              ? companyData.GSTIN
                              : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>DUNS Number</div>
                          <div className={styles.colorBlue2}>
                            {companyData.dunsNumber
                              ? companyData.dunsNumber
                              : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>Date Of Incorporation</div>
                          <div className={styles.colorBlue2}>
                            {companyData.dateOfIncorporation
                              ? new Date(
                                  companyData.dateOfIncorporation
                                ).toLocaleDateString()
                              : "Not Provided"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="section1">
                    <PerformanceCard />
                  </div>
                  <div id="section2">
                    <OnBoarding />
                  </div>

                  {companySubType1 !== "" && companySubType2 !== "" && (
                    <>
                      <div className={styles.Wrapper2}>
                        <div>
                          <div className={styles.title1}>
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}>
                              {companySubType1.docCategory &&
                                companySubType1.docCategory}
                            </span>
                          </div>
                          {companySubType2.length !== 0 &&
                            companySubType2.map((item, index) => (
                              <>
                                {index < Number(listNumber) && (
                                  <div className={styles.documentDiv}>
                                    <div className={styles.title2}>
                                      <bold>PANCard</bold>
                                      <span>: {item.name}</span>
                                    </div>
                                    <div className={styles.title3}>
                                      <span>Issued By: {item.docIssuer}</span>
                                    </div>

                                    <div className={styles.issuedList}>
                                      <div>
                                        Issued Date: {item.validityStartDate}
                                      </div>
                                      <div className={styles.flexCenter}>
                                        {item.validated ? (
                                          <BsCheckCircleFill
                                            size={16}
                                            color="#34BE0F"
                                            style={{ marginRight: "5px" }}
                                          />
                                        ) : (
                                          <MdCancel
                                            size={16}
                                            color="red"
                                            style={{ marginRight: "5px" }}
                                          />
                                        )}
                                        {item.validated
                                          ? "Approved"
                                          : "Rejected"}
                                      </div>
                                    </div>

                                    <div className={styles.issuedList}>
                                      <div>
                                        No Expiration Date:{" "}
                                        {item.validityEndDate}
                                      </div>
                                      <div className={styles.requestCopy}>
                                        Request copy
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          {companySubType2.length === 0 && (
                            <>No Documents Available</>
                          )}
                        </div>

                        <div>
                          <div
                            className={cn(
                              styles.title1,
                              "d-flex justify-content-between mb-2"
                            )}>
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}>
                              Accreditation and Certifications:
                            </span>
                            <Button onClick={() => setIsAddCertificate(true)}>
                              Add Certificate
                            </Button>
                          </div>
                          {ACCREDITATION.map((data) => (
                            <Content marginBottom="10px">
                              <div className={styles.title2}>
                                <span>{data.certificationName}</span>
                              </div>
                              <div className={styles.title3}>
                                <span>{data.certificateDescription}</span>
                              </div>
                              <div className={styles.title3}>
                                <span>Issued By: Not Provided </span>
                              </div>

                              <div className={styles.issuedList}>
                                <div>Issued: Not Provided </div>
                                <div>| No Expiration Date</div>
                                <div className={styles.flexCenter}>
                                  <BsCheckCircleFill
                                    size={16}
                                    color="#34BE0F"
                                    style={{ marginRight: "5px" }}
                                  />
                                  Approved
                                </div>
                                <div className={styles.requestCopy}>
                                  Request copy
                                </div>
                              </div>
                            </Content>
                          ))}
                        </div>
                      </div>
                      <div
                        className={styles.viewBtnDiv}
                        style={{ padding: "0% 0% 0.8% 0%" }}>
                        <button onClick={handleToggle}>
                          {hideBtn === true
                            ? "Hide Documents"
                            : "View all Documents"}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              )}

              {financerData && (
                <div
                  className={styles.container}
                  style={{
                    padding: "1% 2%",
                    backgroundColor: "black",
                    marginBottom: "2%",
                  }}>
                  <div className={styles.wrapper}>
                    <div className={styles.leftWrapper}>
                      <div className={styles.imgDiv}>
                        <img src="https://www.jdfulwiler.com/wp-content/uploads/2017/08/abc-company-test-logo-300x205.png" />
                      </div>

                      <div className={styles.profileDiv}>
                        <div className={styles.namingDiv}>
                          <div
                            className={cn(
                              styles.certficateIconDiv,
                              "d-flex align-items-center"
                            )}>
                            <div>
                              {financerData.verified === "VERIFICATION_PASS" ? (
                                <HiBadgeCheck
                                  style={{
                                    color: "#4CAF50",
                                  }}
                                  size={24}
                                />
                              ) : (
                                <MdOutlineCancel
                                  style={{
                                    color: "rgba(255, 161, 0, 1)",
                                  }}
                                  size={20}
                                />
                              )}
                            </div>

                            <div className={cn("px-1")}>
                              {financerData.financerName}
                            </div>
                          </div>

                          <div>
                            <div
                              className={
                                financerData.memberType === "COMPANY"
                                  ? styles.companyType
                                  : styles.supplierType
                              }>
                              {financerData.memberType}
                            </div>
                          </div>
                        </div>

                        <div className={styles.flexStart}>
                          <div>
                            Country:
                            {financerData?.countryCode
                              ? CheckCountryTitle(financerData?.countryCode)
                              : "Not Provided"}
                          </div>
                        </div>

                        <div className={styles.flexStart}>
                          <div>
                            <a>
                              SiteURL:
                              {financerData.websiteUrl
                                ? financerData.websiteUrl
                                : "Not Provided"}
                            </a>
                          </div>
                        </div>

                        <div className={styles.flexStart}>
                          <div style={{ marginRight: "5px" }}>
                            Company Rating
                          </div>
                          <div className={styles.iconStyle}>
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                            <AiFillStar color="#FFA100" />
                          </div>
                        </div>

                        <div className={styles.iconDiv}>
                          <span
                            onClick={() =>
                              window.open(financerData.linkedInLink)
                            }>
                            <AiFillLinkedin
                              fill="rgba(53, 153, 238, 1)"
                              size={26}
                            />
                          </span>
                          <span
                            onClick={() =>
                              window.open(financerData.twitterLink)
                            }>
                            <FaTwitterSquare
                              fill="rgba(53, 153, 238, 1)"
                              size={24}
                            />
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.rightWrapper}>
                      <div className={styles.flexColumn}>
                        <div className={styles.detailsDiv}>
                          <div className={styles.addressDiv}>
                            <div
                              className={styles.iconStyle}
                              style={{ marginRight: "5px" }}>
                              <FaBuilding
                                size={25}
                                fill=" rgba(33, 142, 235, 1)"
                              />
                            </div>
                            <div>
                              Address:
                              {financerData.financerAddress
                                ? financerData.financerAddress.map((item) => (
                                    <>
                                      <span>{item}</span>
                                      <br></br>
                                    </>
                                  ))
                                : " Not Provided"}
                            </div>
                          </div>

                          <div className={styles.phoneDiv}>
                            {financerData.financerPhone ? (
                              financerData.financerPhone.map((item, index) => (
                                <div
                                  className={styles.phoneContainer}
                                  key={index}>
                                  <div
                                    className={styles.iconStyle}
                                    style={{ marginRight: "5px" }}>
                                    <BsTelephone
                                      size={24}
                                      color="rgba(33, 142, 235, 1)"
                                    />
                                  </div>
                                  <div>{item}</div>
                                </div>
                              ))
                            ) : (
                              <span>
                                <span style={{ marginRight: "5px" }}>
                                  <BsTelephone
                                    size={24}
                                    color="rgba(33, 142, 235, 1)"
                                  />
                                </span>
                                Not Provided
                              </span>
                            )}
                          </div>
                        </div>

                        <div className={styles.mapDiv}>
                          <div>
                            <div className={styles.iconStyle}>
                              <SiGooglemaps
                                size={24}
                                color="rgba(33, 142, 235, 1)"
                              />
                            </div>
                            <div style={{ color: "rgba(33, 142, 235, 1)" }}>
                              <a
                                style={{
                                  textDecoration: "none",
                                  color: "rgba(33, 142, 235, 1)",
                                }}
                                href="https://www.google.com/maps/search/trade+links+pvt+ltd/@28.5566977,77.2460797,11z/data=!3m1!4b1"
                                target="_blank">
                                View Google Map
                              </a>
                            </div>
                          </div>

                          <div className={styles.icon_box}>
                            <RiWechatLine
                              size={26}
                              color="rgba(53, 153, 238, 1)"
                              onClick={() => handleClick("/chat")}
                            />
                            <MdOutlineAttachEmail
                              size={24}
                              color="rgba(53, 153, 238, 1)"
                            />
                            <FaWhatsappSquare
                              size={24}
                              color="rgba(53, 153, 238, 1)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Description Section */}
                  <div className={styles.Wrapper1}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Company Description:
                    </div>

                    {financerData.financerDescription ? (
                      <span>{financerData.financerDescription}</span>
                    ) : (
                      <span>Discription: NA</span>
                    )}
                  </div>

                  {/* Products and Services */}
                  <div className={styles.Wrapper1}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Products and Services:
                    </div>

                    {financerData.financerProductsServices ? (
                      <span>{financerData.financerProductsServices}</span>
                    ) : (
                      <span>Products and Services: NA</span>
                    )}
                  </div>

                  {/*Registration Details*/}
                  <div className={styles.Wrapper1}>
                    <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                      Registration Details:
                    </div>
                    <div className={styles.registrationDiv}>
                      <div>
                        <div className={styles.registrationList}>
                          <div>Registration Number</div>
                          <div className={styles.colorBlue2}>
                            {financerData.registrationNumber
                              ? financerData.registrationNumber
                              : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>LEI</div>
                          <div className={styles.colorBlue2}>
                            {financerData.lei
                              ? financerData.lei
                              : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>SIC Code</div>
                          <div className={styles.colorBlue2}>
                            {financerData.sicCode
                              ? financerData.sicCode
                              : "Not Provided"}
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className={styles.registrationList}>
                          <div>GSTIN</div>
                          <div className={styles.colorBlue2}>
                            {financerData.GSTIN
                              ? financerData.GSTIN
                              : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>DUNS Number</div>
                          <div className={styles.colorBlue2}>
                            {financerData.dunsNumber
                              ? financerData.dunsNumber
                              : "Not Provided"}
                          </div>
                        </div>
                        <div className={styles.registrationList}>
                          <div>Date Of Incorporation</div>
                          <div className={styles.colorBlue2}>
                            {financerData.dateOfIncorporation
                              ? new Date(
                                  financerData.dateOfIncorporation
                                ).toLocaleDateString()
                              : "Not Provided"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="section1">
                    <PerformanceCard />
                  </div>
                  <div id="section2">
                    <OnBoarding />
                  </div>

                  {companySubType1 !== "" && companySubType2 !== "" && (
                    <div className={styles.Wrapper2}>
                      <div>
                        <div className={styles.title1}>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}>
                            {companySubType1.docCategory &&
                              companySubType1.docCategory}
                          </span>
                        </div>
                        {companySubType2.length !== 0 &&
                          companySubType2.map((item, index) => (
                            <>
                              {index < Number(listNumber) && (
                                <div className={styles.documentDiv}>
                                  <div className={styles.title2}>
                                    <bold>PANCard</bold>
                                    <span>: {item.name}</span>
                                  </div>
                                  <div className={styles.title3}>
                                    <span>Issued By: {item.docIssuer}</span>
                                  </div>

                                  <div className={styles.issuedList}>
                                    <div>
                                      Issued Date: {item.validityStartDate}
                                    </div>
                                    <div className={styles.flexCenter}>
                                      {item.validated ? (
                                        <BsCheckCircleFill
                                          size={16}
                                          color="#34BE0F"
                                          style={{ marginRight: "5px" }}
                                        />
                                      ) : (
                                        <MdCancel
                                          size={16}
                                          color="red"
                                          style={{ marginRight: "5px" }}
                                        />
                                      )}
                                      {item.validated ? "Approved" : "Rejected"}
                                    </div>
                                  </div>

                                  <div className={styles.issuedList}>
                                    <div>
                                      No Expiration Date: {item.validityEndDate}
                                    </div>
                                    <div className={styles.requestCopy}>
                                      Request copy
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          ))}
                        {companySubType2.length === 0 && (
                          <>No Documents Available</>
                        )}
                      </div>

                      <div>
                        <div className={styles.title1}>
                          <span
                            style={{ fontSize: "16px", fontWeight: "bold" }}>
                            Accreditation and Certifications:
                          </span>
                        </div>
                        <div className={styles.title2}>
                          <span>Certified SAF® Program Consultant </span>
                        </div>
                        <div className={styles.title3}>
                          <span>Issued By: Not Provided </span>
                        </div>

                        <div className={styles.issuedList}>
                          <div>Issued: Not Provided </div>
                          <div>| No Expiration Date</div>
                          <div className={styles.flexCenter}>
                            <BsCheckCircleFill
                              size={16}
                              color="#34BE0F"
                              style={{ marginRight: "5px" }}
                            />
                            Approved
                          </div>
                          <div className={styles.requestCopy}>Request copy</div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={styles.viewBtnDiv}
                    style={{ padding: "1.5% 0% 0.8% 0%" }}>
                    <button onClick={handleToggle}>
                      {hideBtn === true
                        ? "Hide Documents"
                        : "View all Documents"}
                    </button>
                  </div>
                </div>
              )}

              {serviceData && (
                <div
                  className={styles.ServiceCardDiv}
                  style={{ padding: "1.5% 2% 0.5% 2%" }}>
                  <h5>Services</h5>

                  <div>
                    {serviceData.map((item) => {
                      if (item.memberId === companyId) {
                        return (
                          <ServiceCard
                            item={item}
                            companyList={companyList}
                            isCheckboxHide={true}
                          />
                        );
                      }
                    })}
                  </div>
                  {serviceData.length === 0 && <div>No service data</div>}
                </div>
              )}
            </>
          )}

          {isError && <div className={styles.container}>{isError}</div>}
        </>
      )}
    </>
  );
}
