export const validateInput = (data) =>{
   if(data.financeProductType==='' || data.financeProductType===null || data.financeProductType===undefined){
    return ("Loan product type can't be empty")
   }
   else if(data.financeAmountRequested<=0){
     return ("Required Finance Amound Should not be Less then 0")
   }
   else if(data.interestRateMin<=0 ||  data.interestRateMin>30){
    return ("Interest rate should be between 0 to 30 % only")
   }
   else if(data.modeOfPayment === ""){
    return ("Please select a payment mode")
   }
   else if(data.preferredPaymentMethod==='')
   {
    return ('Disbursement Mode can not be empty');
   }
   else
   {
    return false;
   }
}