import React from "react";
import classes from "./infotable.module.css";
import { Info } from "utils/TableHeader/Documents";
import Content from "components/UI/Layout/Content";
import { FindCompanyName } from "utils/helper";
import { CompanyList } from "utils/API/CompanyList.js";
import { truncateStringID, truncateString } from "utils/helper";
import Loader from "components/Loader/Loader";

const InfoTable = ({ Data }) => {
  const { response, isLoading, isError } = CompanyList();

  const Value = (value) => {
    switch (value) {
      case "name":
        return Data[value] ? truncateString(Data[value], 20) : "NA";
      case "owner":
        return Data[value] && response
          ? FindCompanyName(response, Data[value])
          : "NA";
      case "validated":
        return Data[value] ? "True" : "False";
      case "isDeleted":
        return Data[value] ? "True" : "False";
      case "id":
        return Data[value] ? truncateStringID(Data[value], 7, "DOC") : "NA";
      case "docIssuer":
        return Data[value] ? Data[value] : "Null";
    }
  };
  return (
    <>
      {Data === null || isLoading ? (
        <Loader />
      ) : (
        <>
          {Data && response && (
            <Content>
              <div className={classes.column}>
                {Info &&
                  Info.map((header, index) => (
                    <div className={classes.columnList} key={index}>
                      <div className={classes.columnContent}>
                        <div>{header.name}</div>
                        <div style={{ color: "#42a5f5" }}>
                          {Value(header.value)}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </Content>
          )}
          {isError && <Content>{isError}</Content>}
        </>
      )}
    </>
  );
};

export default InfoTable;
