import { createContext, useState, useEffect } from "react";
import { NetworkURL } from "../../services/BaseURLInstance";
import { loginRequest } from "authConfig";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [accountsData, setAccountsData] = useState(null);
  const [instance, setInstance] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [Token, setToken] = useState(null);
  const [personName, setPersonName] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [loginCompanyData, setLoginCompanyData] = useState(null);
  const [MemberType, setMemberType] = useState(null);

  // Using for OpenTradeAccountDetails in Open Trade Account
  const [OpenTradeAccountDetails, setOpenTradeAccountDetails] = useState(null);
  const [SchedulesMilestone, setSchedulesMilestone] = useState(null);
  const [isSingleScheduleOpen, setIsSingleScheduleOpen] = useState(false);

  // Using for API triggers
  const [isApiTrigger, setApiTrigger] = useState(false);

  //Using for Service DocCategoryTags in Service Request & Service Deal Document
  const [CategoryListURL, setCategoryListURL] = useState(null);

  const requiresInteraction = (errorMessage) => {
    if (!errorMessage || !errorMessage.length) {
      return false;
    }

    return (
      errorMessage.indexOf("consent_required") > -1 ||
      errorMessage.indexOf("interaction_required") > -1 ||
      errorMessage.indexOf("login_required") > -1
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      setCompanyId(accountsData[0]?.idTokenClaims["company_member_id"]);

      setMemberType(accountsData[0]?.idTokenClaims["company_type"]);
      sessionStorage.setItem(
        "companyId",
        JSON.stringify(accountsData[0]?.idTokenClaims["company_member_id"])
      );
      sessionStorage.setItem(
        "memberType",
        JSON.stringify(accountsData[0]?.idTokenClaims["company_type"])
      );
      if (accountsData.length > 0) {
        const request = {
          scopes: [...loginRequest.scopes],
          account: accountsData[0],
        };
        instance
          .acquireTokenSilent(request)
          .then((response) => {
            setToken(response.idToken);
            setPersonName(
              response.account &&
                response.account.idTokenClaims &&
                response.account.idTokenClaims.given_name
            );
            setCompanyName(
              response.account &&
                response.account.idTokenClaims &&
                response.account.idTokenClaims.company_name
            );
            sessionStorage.setItem("idToken", JSON.stringify(response.idToken));
            sessionStorage.setItem(
              "personId",
              JSON.stringify(
                response.account &&
                  response.account.idTokenClaims &&
                  response.account.idTokenClaims.member_id
              )
            );
          })
          .catch((error) => {
            // acquireTokenSilent can fail for a number of reasons, fallback to interaction
            if (requiresInteraction(error.errorCode)) {
              instance.acquireTokenPopup(request).then((response) => {
                // setToken(response.idToken);
                // sessionStorage.setItem("idToken", JSON.stringify(response.idToken));
              });
            }
          });
      }
    }
  }, [isAuthenticated]);

  return (
    <AuthContext.Provider
      value={{
        setIsAuthenticated,
        setAccountsData,
        setInstance,
        Token,
        companyId,
        personName,
        companyName,
        loginCompanyData,
        setLoginCompanyData,
        isAuthenticated,
        OpenTradeAccountDetails,
        setOpenTradeAccountDetails,
        SchedulesMilestone,
        setSchedulesMilestone,
        isSingleScheduleOpen,
        setIsSingleScheduleOpen,
        isApiTrigger,
        setApiTrigger,
        CategoryListURL,
        setCategoryListURL,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
