import React from 'react'
import {
    Card,
    Grid,
    Badge
} from '@mui/material';

import { FiMail } from "react-icons/fi";
import { AiOutlineBell } from "react-icons/ai";
import Dealstructure from "./dealstructure";

function Overview({ data }) {
    return (
        <>
            <Card style={{ backgroundColor: "rgb(52, 52, 52)", padding: "20px 50px 20px 20px", margin: "0px" }}>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <h3>
                           
                        Syndicate Deal {''}
                        </h3>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <Badge badgeContent={4} color="error">
                            <FiMail size={35} color="#A5A5A5" />
                        </Badge>
                        <div style={{ paddingLeft: "20px" }}>

                            <Badge badgeContent={4} color="error">
                                <AiOutlineBell size={35} color="#A5A5A5" />
                            </Badge>
                        </div>
                    </Grid>
                </Grid>
            </Card>

            <Card  style={{ backgroundColor: "#343434", padding: "30px 20px", overflow:"visible" }}>
                
                <Dealstructure data={data} />
            </Card>
        </>
    )
}

export default Overview