import React, { useState } from "react";
import Navbar from "components/UI/Navbar/Navbar";
import Participant from "pages/OpenTradeAccount/OpenTradeAccount/ScheduleTab/Participant";
import cn from "classnames";
import UpdateSchedule from "pages/OpenTradeAccount/OpenTradeAccount/ScheduleTab/UpdateSchedule";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";
import { BsArrowLeft } from "react-icons/bs";
import classes from "./Scheduled_Payments.module.css";

const SchedulePaymentDetails = ({ data }) => {
  // const schedulePaymentTab = [
  //     {name:"Milestone"},
  //     {name:"Participants"}
  // ];
  const Navbar = [
    {
      title: "Milestone",
      description: <UpdateSchedule />,
    },

    {
      title: "Participant",
      description: <Participant />,
    },
  ];

  const [isActive, setIsActive] = useState("Milestone");
  const [Component, setIsComponent] = useState(<UpdateSchedule />);

  const handleToogle = (title) => {
    setIsActive(title);
  };
  const handleComponent = (component) => {
    setIsComponent(component);
  };

  return (
    <div>
    {/* <span className={cn(classes.action)}>
      <span>
        <BsArrowLeft />
      </span>
      <span>Back To List</span>
      </span> */}
      <div>
        <ToogleNavbar
          Navbar={Navbar}
          isActive={isActive}
          handleChange={handleToogle}
          handleComponent={handleComponent}
        />
      </div>
      {Component !== undefined && Component}
    </div>
  );
};

export default SchedulePaymentDetails;
