import _ from "lodash";

export const FinancingValdations = (payload) => {
  if (_.isEmpty(payload.financeProductType)) {
    return "Please Enter Loan Product Type";
  }

  if (_.isEmpty(payload.invoiceUID)) {
    return "Please Enter Invoice Reference";
  }

  if (_.isEmpty(payload.financeAmountRequested)) {
    return "Please Enter Required Finance Amount";
  } else if (payload.financeAmountRequested <= 0) {
    return "Required Finance Amount cannot be less than 0";
  }

  if (_.isEmpty(payload.interestRateMin)) {
    return "Please Enter Interest Rate";
  } else if (payload.interestRateMin <= 0) {
    return "Interest Rate cannot be less than 0";
  }

  if (_.isEmpty(payload.preferredPaymentDate)) {
    return "Please Enter Disbursement Date";
  }

  if (_.isEmpty(payload.preferredPaymentMethod)) {
    return "Please Enter Disbursement Mode";
  }

  if (_.isEmpty(payload.financeRequsitionStartDate)) {
    return "Please Enter Financing Start Date";
  }

  if (_.isEmpty(payload.financeRequsitionEndDate)) {
    return "Please Enter Financing End Date";
  }
};
