import React from "react";
import Wrapper from "./Wrapper";
import { NavLink, useNavigate } from "react-router-dom";

function Footer() {
  const history = useNavigate();

  const handleClick = (url) => {
    history.push(url);
  };
  return (
    <Wrapper>
      <section>Copyright &copy; {new Date().getFullYear()} Tradxlink</section>
      <section>
        <a href="https://tradxlink.com/terms-conditions/" onClick={handleClick}>
          Terms & Conditions
        </a>
        | <NavLink to="about">About</NavLink> |
        <NavLink to="contactus">Contact Us</NavLink> |
        <NavLink to="/">Tradxlink</NavLink>
      </section>
    </Wrapper>
  );
}

export default Footer;
