import React, { useState } from "react";
import classes from "./freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { TRANSPORT } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";
import { Error } from "components/notifications/responseHandle";

const Transport = ({
  uniqueSubCategory,
  transportCargoDataList,
  setTransportCargoDataList,
  transportData,
  setTransportData,
  isTpAdded,
  setIsTpAdded,
  addTpCargo,
  setAddTpCargo,
  isTpHazardous,
  setIsTpHazardous,
}) => {
  console.log("uniqueSubCategory11: ", uniqueSubCategory[0]);
  const [cargoData, setCargoData] = useState({
    currency: "",
    cargoValue: "",
    qty: "",
    qtyUnit: "",
    volume: "",
    volumeUnit: "",
  });
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "ISD",
      value: "ISD",
    },
    {
      label: "FACTORY",
      value: "FACTORY",
    },
    {
      label: "CFS",
      value: "CFS",
    },
  ];

  let transportModeOptions = [
    {
      label: "AIR",
      value: "AIR",
    },
    {
      label: "RAIL",
      value: "RAIL",
    },
    {
      label: "ROAD",
      value: "ROAD",
    },
    {
      label: "SEA",
      value: "SEA",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "ISD",
      value: "ISD",
    },
    {
      label: "FACTORY",
      value: "FACTORY",
    },
    {
      label: "CFS",
      value: "CFS",
    },
  ];

  let volumeUnitOptions = [
    {
      label: "CUBIC METER",
      value: "CUBIC_METER",
    },
    {
      label: "PALLET",
      value: "PALLET",
    },
  ];

  let qtyUnitOptions = [
    {
      label: "MT",
      value: "MT",
    },
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "LB",
      value: "LB",
    },
    {
      label: "BOXES",
      value: "BOXES",
    },
    {
      label: "PCS",
      value: "PCS",
    },
  ];

  let cargoHazardousOptions = [
    {
      label: "EXPLOSIVES",
      value: "EXPLOSIVES",
    },
    {
      label: "GASES",
      value: "GASES",
    },
    {
      label: "FLAMMABLE_SOLIDS",
      value: "FLAMMABLE_SOLIDS",
    },
    {
      label: "OXIDIZERS_ORGANIC_PEROXIDES",
      value: "OXIDIZERS_ORGANIC_PEROXIDES",
    },
    {
      label: "TOXIC INFECTIOUS",
      value: "TOXIC_INFECTIOUS",
    },
    {
      label: "RADIOACTIVE",
      value: "RADIOACTIVE",
    },
    {
      label: "CORROSIVES",
      value: "CORROSIVES",
    },
    {
      label: "MISCELLANEOUS_HAZARDOUS",
      value: "MISCELLANEOUS_HAZARDOUS",
    },
    {
      label: "NOT_APPLICABLE",
      value: "NOT_APPLICABLE",
    },
  ];


  const LoadHeader = [
    // { name: "currency" },
    { name: "cargoValue" },
    { name: "Quantity" },
    // { name: "qtyUnit" },
    { name: "volume" },
    // { name: "volumeUnit" },
  ];
  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setTransportData({
      ...transportData,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  const handleCargo = (event) => {
    setAddTpCargo(true);
  };
  const handleAddLoad = () => {
    if(cargoData.currency ===""){
      Error("Please enter Currency");
      return;
    } if(cargoData.cargoValue ===""){
      Error("Please enter Cargo Value");
      return;
    } if(cargoData.qty ===""){
      Error("Please enter Quantity");
      return;
    } if(cargoData.qtyUnit ===""){
      Error("Please enter Quantity Unit");
      return;
    } if(cargoData.volume ===""){
      Error("Please enter Volume");
      return;
    } if(cargoData.volumeUnit ===""){
      Error("Please enter Volume Unit");
      return;
    }

    setTransportCargoDataList([...transportCargoDataList, cargoData]);
    setAddTpCargo(false);
    setCargoData({
      currency: "",
      cargoValue: "",
      qty: "",
      qtyUnit: "",
      volume: "",
      volumeUnit: "",
    });
  };
  const handleDateChange = (e, field) => {
    setTransportData({
      ...transportData,
      [field]: new Date(e).toISOString(),
    });
  };
  const handleChange = (e, date) => {
    if (e.target.name === "transportMode") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currency") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cargoValue") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "qty") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "qtyUnit") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "volume") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "volumeUnit") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "originType") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "origin") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryFrom") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityFrom") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityTo") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "portOrigin") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationPort") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationType") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destination") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cargoHazardous") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "UN_Number") {
      setTransportData({
        ...transportData,
        [e.target.name]: e.target.value,
      });
    }
    sessionStorage.setItem("transportData", JSON.stringify(transportData));
    // sessionStorage.setItem("tabletransportLoadData", JSON.stringify(tabletransportLoadData));

    console.log("transportData", transportData);
  };
  useEffect(() => {
    if (!isTpHazardous) {
      setTransportData({
        ...transportData,
        cargoHazardous: "",
        UN_Number: "",
      });
    }
  }, [isTpHazardous]);

  const checkAddLoad = () =>{setIsTpAdded(false)};
  const combineFncAddload =() =>{
    handleCargo();
    checkAddLoad();
    };

  return (
    <>
      <Content padding="1% 2% 1.4% 2%">
        <div style={{ margin: "10px" }}>
          <OverLayTrigger value={TRANSPORT} />
        </div>
        <div className={classes.header} style={{ margin: "10px 10px 0px 10px" }}>
          Where Are You Shipping?
        </div>
        <div className={classes.source} style={{ margin: "5px 10px 10px 10px" }}>
          <div style={{ marginLeft: "10px", width: "50%" }}>
            <input
              className={classes.radio}
              type="radio"
              name="freightType"
              value="INTERNATIONAL"
              onChange={handleRadio}
              checked={transportData.freightType == "INTERNATIONAL"}
            />
            <label>International Freight</label>
          </div>
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "1%",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="freightType"
              value="DOMESTIC"
              onChange={handleRadio}
              checked={transportData.freightType == "DOMESTIC"}
            />
            <label>Domestic Freight</label>
          </div>
        </div>
        <div style={{ margin: "10px" }}>Mode of Shipment</div>
        <div style={{ width: "25%", margin: "10px" }}>
          <Input
            name="transportMode"
            placeholder="Transport Mode"
            value={uniqueSubCategory[0]}
            readOnly={true}
          />
        </div>

        {/* <div className={classes.source} style={{ marginBottom: "4%" }}>
            <div className={classes.source} style={{ width: "40%" }}>
              <div style={{ width: "30%" }}>
                <Select
                  name="originType"
                  options={sourceOptions}
                  placeholder="From"
                  handleChange={handleChange}
                  value={transportData.originType}
                />
              </div>
              <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="origin"
                  placeholder="Origin"
                  value={transportData.origin}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div style={{ width: "20%" }}></div>
            <div className={classes.source} style={{ width: "40%" }}>
              <div style={{ width: "30%" }}>
                <Select
                  options={destinationOptions}
                  name="destinationType"
                  placeholder="To"
                  handleChange={handleChange}
                  value={transportData.destinationType}
                />
              </div>
              <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="destination"
                  placeholder="Destination"
                  value={transportData.destination}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div> */}
        <div>
          <div className={classes.source}>
            {/* <div style={{ width: "50%", margin: "10px" }}>
              <label>CARGO READY DATE</label>
            </div> */}
            {/* <div style={{ width: "50%", margin: "10px" }}>
              {transportData &&
              transportData.transportMode &&
              transportData.transportMode === "MARITIME" ? (
                <label>PREFERRED SAILING DATE</label>
              ) : (
                <label>PREFERRED DISPATCH DATE</label>
              )}
            </div> */}
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Grid item xs={12} md={6}>
                <DateInput
                  name="CARGO_READY"
                  placeholder="Cargo Ready Date*"
                  value={transportData.CARGO_READY}
                  handleChange={(e) => handleDateChange(e, "CARGO_READY")}
                />
              </Grid>
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Grid item xs={12} md={6}>
                <DateInput
                  name="PREFERRED_SAILING_DATE"
                  placeholder="PREFERRED SAILING DATE*"
                  value={transportData.PREFERRED_SAILING_DATE}
                  handleChange={(e) =>
                    handleDateChange(e, "PREFERRED_SAILING_DATE")
                  }
                />
              </Grid>
            </div>
          </div>
        </div>
        <hr style={{border:"2px solid white", margin:"10px 10px 10px 10px"}}></hr>
        <div style={{ margin: "10px 10px 8px 10px" }}>Shipment details</div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              marginBottom: "2%",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country  From*"
              handleChange={handleChange}
              value={transportData.countryFrom}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              marginBottom: "2%",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country To*"
              handleChange={handleChange}
              value={transportData.countryTo}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              marginBottom: "2%",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Input
              name="cityFrom"
              placeholder="City From"
              handleChange={handleChange}
              value={transportData.cityFrom}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              marginBottom: "2%",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <Input
              name="cityTo"
              placeholder="City To"
              handleChange={handleChange}
              value={transportData.cityTo}
              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              marginBottom: "2%",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="portOrigin"
              placeholder="From Port"
              handleChange={handleChange}
              value={transportData.portOrigin}
            />
          </div>

          <div
            style={{
              width: "50%",
              marginBottom: "2%",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="destinationPort"
              placeholder="To Port"
              value={transportData.destinationPort}
              handleChange={handleChange}
            />
          </div>
        </div>
        <hr style={{border:"2px solid white", margin:"0px 10px 10px 10px"}}></hr>
        <div>
          <div>
            <div style={{ margin: "10px 10px 8px 10px" }}>
              <label>COMMODITY</label>
            </div>
          </div>
          <div className={classes.source}>
            <div
              style={{ width: "100%", marginLeft: "10px", marginRight: "10px" }}
            >
              <SelectWithSearch
                options={Industry}
                name="commodity"
                placeholder="Commodity*"
                handleChange={handleChange}
                value={transportData.commodity}
                // readOnly={true}
              />
            </div>
          </div>
        </div>

        <>
          <div
            style={{ marginBottom: "1%", marginTop: "2%", marginLeft: "10px" }}
          >
            Cargo Details
          </div>

          <div style={{ marginBottom: "2%" }}>
            <Button onClick={combineFncAddload}>+ Add Loads</Button>
          </div>
          <div className={classes.TableContainer}>
            <Table bordered responsive="sm" className={cn(classes.Table)}>
              <thead>
                <tr>
                  {LoadHeader &&
                    LoadHeader.map((header, i) => (
                      <td key={i}>{header.name}</td>
                    ))}
                </tr>
              </thead>
              <tbody>
                {transportCargoDataList &&
                  transportCargoDataList.map((tabletransportLoadData1, i) => (
                    <tr key={i}>
                      {/* <td>
                      {tabletransportLoadData1 &&
                        tabletransportLoadData1.currency}
                    </td> */}
                      <td>
                        {tabletransportLoadData1 &&
                          tabletransportLoadData1.cargoValue}{" "}
                        {tabletransportLoadData1 &&
                          tabletransportLoadData1.currency}
                      </td>
                      <td>
                        {tabletransportLoadData1 && tabletransportLoadData1.qty}{" "}
                        {tabletransportLoadData1 &&
                          tabletransportLoadData1.qtyUnit}
                      </td>
                      {/* <td>
                      {tabletransportLoadData1 &&
                        tabletransportLoadData1.qtyUnit}
                    </td> */}
                      <td>
                        {tabletransportLoadData1 &&
                          tabletransportLoadData1.volume}{" "}
                        {tabletransportLoadData1 &&
                          tabletransportLoadData1.volumeUnit}
                      </td>
                      {/* <td>
                      {tabletransportLoadData1 &&
                        tabletransportLoadData1.volumeUnit}
                    </td> */}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          {addTpCargo && (
            <>
              <div className={classes.loads} style={{backgroundColor:"black"}}>
                <div className={classes.source} style={{ marginBottom: "2%" }}>
                  <div
                    style={{
                      width: "50%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <SelectWithSearch
                      options={Currenciesr}
                      name="currency"
                      placeholder="Currency"
                      value={cargoData.currency}
                      handleChange={handleChange}
                    />
                  </div>
                  <div
                    style={{
                      width: "50%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <Input
                      // options={containerTypeOptions}
                      name="cargoValue"
                      placeholder={`Cargo Value(${cargoData.currency})`}
                      handleChange={handleChange}
                      value={cargoData.cargoValue}
                    />
                  </div>
                </div>

                <div className={classes.source}>
                  <div className={classes.source} style={{ width: "50%" }}>
                    <div
                      style={{
                        width: "30%",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <Input
                        name="qty"
                        placeholder="Quantity"
                        handleChange={handleChange}
                        value={cargoData.qty}
                      />
                    </div>
                    <div
                      style={{
                        width: "70%",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <Select
                        options={qtyUnitOptions}
                        name="qtyUnit"
                        placeholder="Quantity Unit"
                        handleChange={handleChange}
                        value={cargoData.qtyUnit}
                      />
                    </div>
                  </div>
                  <div className={classes.source} style={{ width: "50%" }}>
                    <div
                      style={{
                        width: "30%",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <Input
                        name="volume"
                        placeholder="Volume"
                        handleChange={handleChange}
                        value={cargoData.volume}
                      />
                    </div>
                    <div
                      style={{
                        width: "70%",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <Select
                        options={volumeUnitOptions}
                        name="volumeUnit"
                        placeholder="Volume Unit"
                        handleChange={handleChange}
                        value={cargoData.volumeUnit}
                      />
                    </div>
                  </div>
                </div>
                <ButtonDiv>
                  <Button onClick={handleAddLoad}>Add</Button>
                </ButtonDiv>
              </div>
            </>
          )}
        </>
        <div>
          <input
            className={classes.Checkbox}
            type="checkbox"
            onChange={(e) => setIsTpHazardous(e.target.checked)}
          />
          <label
            style={{ marginLeft: "1%", fontSize: "18px", marginBottom: "1%" }}
          >
            {" "}
            This cargo is hazardous
          </label>
        </div>

        {isTpHazardous && (
          <div className={classes.source} style={{ marginBottom: "2%" }}>
            <div className={classes.source} style={{ width: "40%" }}>
              <div
                style={{
                  width: "50%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Select
                  options={cargoHazardousOptions}
                  name="cargoHazardous"
                  placeholder="Cargo Hazardous"
                  handleChange={handleChange}
                  value={transportData.cargoHazardous}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Input
                  name="UN_Number"
                  placeholder="UN#"
                  value={transportData.UN_Number}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}
      </Content>
    </>
  );
};

export default Transport;
