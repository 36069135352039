/**
 * A link to a certain page, an anchor tag
 */

import styled from "styled-components";

const A = styled.a`
  color: #ffffff;
  &:hover {
    color: #f1f1f1;
  }
`;

export default A;
