import React, { useState, useEffect } from "react";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";

import Schedules from "../ScheduleTab/Schedules";

import ChangeState from "../ScheduleTab/ChangeState";
// import Documents from "pages/Document/index";
import DocumentIndex from "./DocumentSection/documentIndex";
// import History from "../ScheduleTab/History";
import History from "components/History/history";
import { useParams } from "react-router-dom";

const Schedule = ({ setSelected}) => {
  const [isActive, setIsActive] = useState("Milestone");
  const [Component, setIsComponent] = useState(<Schedules />);
  const {id} = useParams();

  const Navbar = [
    {
      title: "Milestones",
      description: <Schedules setSelected={setSelected} />,
    },
    // {
    //   title: "Change State",
    //   description: <ChangeState />,
    // },
    {
      title: "Documents",
      description: <DocumentIndex />,
    },
    {
      title: "History",
      description: <History dealstatus="MILESTONE STATUS" screen="OPEN_TRADE" id={id} />,
    },
  ];

  const handleChange = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };

  return (
    <div>
      <ToogleNavbar
        Navbar={Navbar}
        isActive={isActive}
        handleChange={handleChange}
        handleComponent={handleComponent}
      />

      {Component !== undefined && Component}
    </div>
  );
};

export default Schedule;
