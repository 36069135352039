import React, { useState, useEffect, useContext } from "react";
import Container from "components/UI/Layout/Container";
import styles from "pages/Documents/dashboard.module.css";
import { DocumentsOpenTradeDashboard } from "utils/Config";
import Document from "pages/Document/index";
import { HiArrowNarrowLeft } from "react-icons/hi";
import classes from "./documentSection.module.css";
import DocumentUpload from "./DocumentUpload";
import {
  CompanyDocuments,
  PaymentsDocuments,
} from "utils/DropDown/ServiceDealDocument";
import { AuthContext } from "contexts/Auth/AuthContext";
import { openTradeAccount } from "services/BaseURLInstance";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";

const DocumentIndex = () => {
  const { instance, accounts } = useMsal();
  const { OpenTradeAccountDetails } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [response, setResponse] = useState(null);

  const [hide, setHide] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const { CompanyCategory } = CompanyDocuments(null);
  const CompanyURL = [];
  CompanyCategory.map((Service) => CompanyURL.push(Service.value));

  const [selectedPaymentCategory, setSelectedPaymentCategory] = useState(null);
  const { PaymentCategory, PaymentSubCategory } = PaymentsDocuments(
    selectedPaymentCategory || null
  );

  const PaymentURL = [];
  PaymentCategory.map((Service) => PaymentURL.push(Service.value));

  const [documentGroups, setDocumentGroups] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [CategoryOption, setCategoryOption] = useState([]);
  const [categoryURL, setCategoryURL] = useState(null);
  const [SubCategoryOption, setSubCategoryOption] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      let URL = `${openTradeAccount}openaccounttrades/schedules?openAccountTradeId=${OpenTradeAccountDetails.openTradeAccountId}&sellerMemberUID=&buyerMemberUID=&status=`;
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            setError(message);
          } else {
            setResponse(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (response) {
      let Temp = [];
      let Data =
        response &&
        response.map((res) => {
          Temp.push(res.documentGroups[0]);
        });
      setDocumentGroups(Temp);
    }
  }, [response]);

  useEffect(() => {
    if (documentGroups) {
      let Temp = [];
      documentGroups &&
        documentGroups.map((group) => {
          Temp.push({
            label: group.docCategory,
            value: group.docCategory,
          });
        });
      setCategoryOption(Temp);
    }
    if (documentGroups) {
      let Temp = [];
      documentGroups &&
        documentGroups.map((group) => {
          Temp.push(group.docCategory);
        });
      setCategoryURL(Temp);
    } else {
      let Temp = ["OTHERS"];
      setCategoryURL(Temp);
    }
  }, [documentGroups]);

  useEffect(() => {
    if (selectedCategory) {
      let Temp = [];
      documentGroups &&
        documentGroups.map((group) => {
          if (selectedCategory === group.docCategory) {
            Temp.push({
              label: group.docSubCategory,
              value: group.docSubCategory,
            });
          }
        });
      setSubCategoryOption(Temp);
    }
  }, [selectedCategory]);

  return (
    <Container>
      {!hide ? (
        <div className={styles.dashboardWrapper}>
          <h5>Documents</h5>
          <div className={styles.dashboardSolution}>
            {DocumentsOpenTradeDashboard.map((item, index) => (
              <div className={styles.solutionCard} key={index}>
                <div
                  onClick={() => setHide(`${item.path}`)}
                  className={styles.content}
                  style={{ cursor: "pointer" }}>
                  <div className={styles.content_icons}>{item.icon}</div>
                  <h6>{item.name}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <>
          <div
            onClick={() => {
              setHide(null);
              setIsUploading(false);
              setSelectedPaymentCategory(null);
              setSelectedCategory(null);
            }}
            style={{ color: "#218eeb", cursor: "pointer" }}>
            <span>
              <HiArrowNarrowLeft size={28} />
            </span>
            <span>Back to List </span>
          </div>

          {hide == "Company" && (
            <Document
              OpenTradeAccountDetails={OpenTradeAccountDetails}
              serviceDealLocations={{
                serviceDealLocation: "companyDocOAT",
                docCategoryList: CompanyURL ? CompanyURL : [], // Working
              }}
            />
          )}

          {hide == "Payments" && (
            <>
              {isUploading ? (
                <div>
                  <DocumentUpload
                    selectedCategory={selectedPaymentCategory} // useState
                    setSelectedCategory={setSelectedPaymentCategory}
                    Category={PaymentCategory} // Drop down option
                    SubCategory={PaymentSubCategory} // Drop down option
                    setIsUploading={setIsUploading}
                    response={OpenTradeAccountDetails}
                  />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-end">
                    <button
                      className={classes.UploadBtn}
                      onClick={() => setIsUploading(true)}>
                      Upload
                    </button>
                  </div>
                  <Document
                    OpenTradeAccountDetails={OpenTradeAccountDetails}
                    serviceDealLocations={{
                      serviceDealLocation: "paymentDocOAT",
                      docCategoryList: PaymentURL ? PaymentURL : [], // Working
                    }}
                  />
                </>
              )}
            </>
          )}

          {hide == "Delivery" && (
            <>
              {isUploading ? (
                <div>
                  <DocumentUpload
                    selectedCategory={selectedCategory} // useState
                    setSelectedCategory={setSelectedCategory}
                    Category={CategoryOption} // Drop down option
                    SubCategory={SubCategoryOption} // Drop down option
                    setIsUploading={setIsUploading}
                    response={OpenTradeAccountDetails}
                  />
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-end">
                    <button
                      className={classes.UploadBtn}
                      onClick={() => setIsUploading(true)}>
                      Upload
                    </button>
                  </div>
                  <Document
                    OpenTradeAccountDetails={OpenTradeAccountDetails}
                    serviceDealLocations={{
                      serviceDealLocation: "deliveryDocOAT",
                      docCategoryList:
                        categoryURL?.length > 0 ? categoryURL : ["OTHERS"],
                    }}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default DocumentIndex;
