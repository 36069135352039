import React from "react";
import classes from "./ToogleNavbar.module.css";

const ToogleNavbar = ({
  Navbar,
  isActive,
  handleChange,
  handleComponent,
  isSydicateManu,
}) => {
  return (
    <>
      <div className={classes.ToogleNavbar}>
        {Navbar &&
          Navbar.map((item) =>
            isSydicateManu === "syndicatelending" ? (
              <>
                {item.title !== '' &&
                  <div
                    key={item.title}
                    className={
                      isActive === item.title ? classes.isActive : classes.notActive
                    }
                    onClick={() => {
                      handleChange(item.title);
                      handleComponent(item.description);
                    }}>
                    {item.title}
                  </div>
                }
              </>
            ) : (
              <>
                {  item.title !== '' &&
                  <div
                    key={item.title}
                    className={
                      isActive === item.title ? classes.isActive : classes.notActive
                    }
                    onClick={() => {
                      handleChange(item.title);
                      handleComponent(item.description);
                    }}>
                    {item.title}
                  </div>
                }
              </>
            )
          )}
      </div>
    </>
  );
};

export default ToogleNavbar;
