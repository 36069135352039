import React, { useState } from "react";
import Style from "./styled";
import Invoice from "./invoice";
import Financing from "./financing";
import Repayment from "./repayment";

export default () => {
  const [header, setHeader] = useState("invoice");
  const state = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    datasets: [
      {
        label: "Finance Approved",
        backgroundColor: "Orange",
        data: [3, 7, 4],
      },
      {
        label: "Deal In-progress",
        backgroundColor: "lightgreen",
        data: [4, 3, 5],
      },
      {
        label: "Finance Declined",
        backgroundColor: "green",
        data: [7, 2, 6],
      },
    ],
  };

  const handleHeader = (e, value) => {
    setHeader(value);
  };

  const component = () => {
    switch (header) {
      case "invoice":
        return <Invoice />;
      case "financing":
        return <Financing />;
      case "repayment":
        return <Repayment />;
    }
  };

  return (
    <Style>
      <div>
        <div className="main">
          <div className="navbar">
            {/* <div style={{ textAlign: "center" }}>Trades</div> */}
            <div
              onClick={(e) => handleHeader(e, "invoice")}
              style={
                header === "invoice"
                  ? {
                      cursor: "pointer",
                      color: "#42A5F5",
                      textDecoration: "underline",
                      textUnderlineOffset: "10px",
                      textAlign: "center",
                      marginLeft: "10%",
                    }
                  : {
                      cursor: "pointer",
                      textAlign: "center",
                      marginLeft: "10%",
                    }
              }
            >
              Invoices
            </div>
            <div
              onClick={(e) => handleHeader(e, "financing")}
              style={
                header === "financing"
                  ? {
                      cursor: "pointer",
                      color: "#42A5F5",
                      textDecoration: "underline",
                      textUnderlineOffset: "10px",
                      textAlign: "center",
                      marginRight: "10%",
                    }
                  : {
                      cursor: "pointer",
                      textAlign: "center",
                      marginRight: "10%",
                    }
              }
            >
              Financing
            </div>
            {/* <div
              className="repayment"
              style={
                header === "repayment"
                  ? {
                      cursor: "pointer",
                      color: "#42A5F5",
                      textDecoration: "underline",
                      textUnderlineOffset: "10px",
                      textAlign: "center",
                    }
                  : { cursor: "pointer", textAlign: "center" }
              }
              onClick={(e) => handleHeader(e, "repayment")}
            >
              Repayment
            </div> */}
          </div>
          <hr />
          {header ? component() : ""}
        </div>
      </div>
    </Style>
  );
};
