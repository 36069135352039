import React, { useState, useEffect, useCallback } from "react";
import classes from "./opentrade.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import cn from "classnames";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { openTradeAccount, paymentServiceURL } from "services/BaseURLInstance";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { CompanyList } from "utils/API/CompanyList.js";
import { Countries } from "utils/DropDown/Country.js";
import Currenciesr from "jsons/Currenciesr.json";
import {
  Industry,
  EscrowService,
  PreferredPaymentMethod,
} from "utils/Section/trade";
import { FindCompanyName } from "utils/helper";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { useNavigate, useParams } from "react-router-dom";
import { CreateOpenTradeValdations } from "utils/Validations/OpenTradeAccount";
import { Grid } from "@mui/material";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { OpenTradeInfo } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";

const hazMatOptions = [
  {
    label: "NOT_APPLICABLE",
    value: "NOT_APPLICABLE",
  },
  {
    label: "EXPLOSIVES",
    value: "EXPLOSIVES",
  },
  {
    label: "GASES",
    value: "GASES",
  },
  {
    label: "FLAMMABLE_LIQUIDS",
    value: "FLAMMABLE_LIQUIDS",
  },
  {
    label: "FLAMMABLE_SOLIDS",
    value: "FLAMMABLE_SOLIDS",
  },
  {
    label: "OXIDIZERS_ORGANIC_PEROXIDES",
    value: "OXIDIZERS_ORGANIC_PEROXIDES",
  },
  {
    label: "TOXIC_INFECTIOUS",
    value: "TOXIC_INFECTIOUS",
  },
  {
    label: "RADIOACTIVE",
    value: "RADIOACTIVE",
  },
  {
    label: "CORROSIVES",
    value: "CORROSIVES",
  },
  {
    label: "MISCELLANEOUS_HAZARDOUS",
    value: "MISCELLANEOUS_HAZARDOUS",
  },
];

const incoTermOptions = [
  {
    label: "EXW",
    value: "EXW",
  },
  {
    label: "FCA",
    value: "FCA",
  },
  {
    label: "FAS",
    value: "FAS",
  },
  {
    label: "FOB",
    value: "FOB",
  },
  {
    label: "CPT",
    value: "CPT",
  },
  {
    label: "CIP",
    value: "CIP",
  },
  {
    label: "CFR",
    value: "CFR",
  },
  {
    label: "CIF",
    value: "CIF",
  },
  {
    label: "DAP",
    value: "DAP",
  },
  {
    label: "DAU",
    value: "DAU",
  },

  {
    label: "DDP",
    value: "DDP",
  },
  {
    label: "DAT",
    value: "DAT",
  },
];
const NewOpenTradeForm = ({
  setIsTradeFormOpen,
  setIsSearchResult,
  setIsPaymentFormOpen,
  IsPaymentFormOpen,
}) => {
  const { id } = useParams();
  const { response } = CompanyList();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const { instance, accounts } = useMsal();
  const [companyList, setCompanyList] = useState(null);
  const [companyListForSeller, setCompanyListForSeller] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const [DatePicker1, setDatePicker1] = useState(null);
  const [DatePicker2, setDatePicker2] = useState(null);
  const [DatePicker3, setDatePicker3] = useState(null);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [newTradeResponse, setNewTradeResponse] = useState(null);
  const [isAdded, setIsAdded] = useState(null);
  const [payment, setPayment] = useState(null);
  const [provider, setProvider] = useState([]);
  const [provider2, setProvider2] = useState([]);
  const [isAborted, setIsAborted] = useState(true);
  const [OpenTradeAccountDetail, setOpenTradeAccountDetail] = useState();
  const [toast, setToast] = useState();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setPayment(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const [NewTradeData, setNewTradeData] = useState({
    sellerMemberId: companyId,
    buyerMemberId: "",
    countryOfOrigin: "",
    countryOfDestination: "",
    tradeValue: "",
    industryCategory: "",
    industrySubCategory: "", //OTHERS
    contractDate: "",
    createdDate: new Date(),
    updatedDate: "",
    openTradeStatus: "DRAFT",
    escrowService: true, // boolean_type
    paymentCurrency: "",
    preferredPaymentMethod: "",
    escrowPayment: true, // boolean_type
  });

  const [PaymentData, setPaymentData] = useState({
    escrowServiceProviderUID: "",
    RefSellerVirtualBankAccountId: "",
    escrowConsentStatus: "NEW",
    referenceEscrowAccount: "",
  });

  useEffect(() => {
    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.paymentProvider,
          value: item.paymentProvider,
        };
      });
      setProvider(temp);
    }

    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.virtualAccountNumber,
          value: item.virtualAccountNumber,
        };
      });
      setProvider2(temp);
    }
  }, [payment]);

  const handleChange = (event, date) => {
    if (
      date === "contractDate" ||
      date === "createdDate" ||
      date === "updatedDate"
    ) {
      let newDate = new Date(event);
      let dateString = newDate.toISOString();

      let newDate1 = moment(new Date(event)).format("YYYY-MM-DD");
      if (date === "contractDate") {
        setDatePicker1(newDate1);
      }
      if (date === "createdDate") {
        setDatePicker2(newDate1);
      }
      if (date === "updatedDate") {
        setDatePicker3(newDate1);
      }

      setNewTradeData({
        ...NewTradeData,
        [date]: dateString,
      });
    } else if (event.target.name === "industryCategory") {
      setNewTradeData({
        ...NewTradeData,
        [event.target.name]: [event.target.value],
      });
    } else if (
      event.target.name === "countryOfOrigin" ||
      event.target.name === "countryOfDestination"
    ) {
      setNewTradeData({
        ...NewTradeData,
        [event.target.name]: event.target.value,
      });
    } else {
      setNewTradeData({
        ...NewTradeData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handlePaymentChange = (event) => {
    if (event.target.name === "escrowServiceProviderUID") {
      let value = event.target.value;
      const temp =
        payment &&
        payment[0] !== null &&
        payment.filter((res) => res.paymentProvider === event.target.value);
      setPaymentData({
        ...PaymentData,
        [event.target.name]: event.target.value,
        ["RefSellerVirtualBankAccountId"]: temp[0].virtualAccountNumber,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let checkValidation = CreateOpenTradeValdations(NewTradeData);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      const options = {
        method: "POST",
        body: newTradeResponse ? newTradeResponse : NewTradeData,
      };

      setIsLoading(true);
      // if(NewTradeData){
      //   NewTradeData.openTradeStatus = "NEW";
      // }
      // if(newTradeResponse){
      //   newTradeResponse.openTradeStatus = "NEW";
      // }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}openaccounttrades`,
          options,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            Success("Trade Added Successfully...");
            setIsTradeFormOpen(false);
            setIsSearchResult(true);
            // if (NewTradeData && NewTradeData.escrowService) {
            //   setIsPaymentFormOpen(true);
            //   if (!newTradeResponse) {
            //     setNewTradeResponse(data);
            //   }
            // } else {
            //
            //
            // }
          }
        });
      });
    }
  };

  // CompanyList API
  const List = CompanyList();
  useEffect(() => {
    if (List && List.response) {
      let temp = List.response.filter((item) => {
        if (item.memberUID !== companyId && item.memberType === "COMPANY") {
          return { label: item.memberName, value: item.memberUID };
        }
      });

      let filter =
        temp &&
        temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });
      setCompanyList(filter);
    }

    if (List && List.response) {
      let temp = List.response.filter((item) => {
        if (item.memberType === "FINANCER" || item.memberType === "COMPANY") {
          return { label: item.memberName, value: item.memberUID };
        }
      });

      let filter =
        temp &&
        temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });
      setCompanyListForSeller(filter);
    }
  }, [List && List.response]);

  const handleAbort = (e) => {
    e.preventDefault();
    if (newTradeResponse) {
      newTradeResponse.openTradeStatus = "ABORTED";
    }
    if (NewTradeData) {
      newTradeResponse.openTradeStatus = "ABORTED";
    }
    const options = {
      method: "PUT",
      body: newTradeResponse ? newTradeResponse : NewTradeData,
    };
    let URL = `${openTradeAccount}openaccounttrades/${newTradeResponse.openTradeAccountId}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError(message);
        } else {
          setIsAborted(false);
          // Success("Buyer Added successfully...");
          if (data.openTradeStatus === "ABORTED") {
            handleNavigate(`../open-account/${id}`);
          }
        }
      });
    });
  };

  const handlePutSubmitPayment = (e) => {
    e.preventDefault();
    if (newTradeResponse) {
      newTradeResponse.escrowServiceProviderUID = companyId;
      newTradeResponse.RefSellerVirtualBankAccountId =
        PaymentData.RefSellerVirtualBankAccountId;
      newTradeResponse.RefBuyerVirtualBankAccountId =
        PaymentData.RefSellerVirtualBankAccountId;

      newTradeResponse.escrowConsentStatus = PaymentData.escrowConsentStatus;
      newTradeResponse.referenceEscrowAccount =
        PaymentData.referenceEscrowAccount;
    }

    const options = {
      method: "PUT",
      body: newTradeResponse,
    };
    newTradeResponse.escrowConsentStatus = "BUYER_LINKED";
    let URL = `${openTradeAccount}openaccounttrades/${newTradeResponse.openTradeAccountId}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError(message);
        } else {
          setIsAdded(data);
          Success("Trade Created successfully...");
          setOpenTradeAccountDetail(data);
          // setIsTradeFormOpen(false);
          setIsPaymentFormOpen(false);
          handleNavigate(`../open-account/${id}`);
        }
      });
    });
    // }
  };

  sessionStorage.setItem(
    "OpenTradeAccountDetail",
    JSON.stringify(OpenTradeAccountDetail)
  );
  return (
    <>
      {isLoading || List.isLoading ? (
        <Content marginBottom="0%">
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {!List.isError && !isError && (
            <Content marginBottom="0%">
              <OverLayTrigger value={OpenTradeInfo} />
              {IsPaymentFormOpen ? (
                <form onSubmit={(e) => handlePutSubmitPayment(e)}>
                  <div className={classes.FormDiv}>
                    <div>
                      <div>Payment Service Provider</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Service Provider
                      </div>
                    </div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Select
                        name="escrowServiceProviderUID"
                        placeholder="Payment Service Provider"
                        options={provider}
                        value={PaymentData.escrowServiceProviderUID}
                        handleChange={handlePaymentChange}
                      />
                    </Grid>
                    <div>
                      <div>Payment Account (Seller)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Select
                        name="RefSellerVirtualBankAccountId"
                        options={provider2}
                        placeholder="Ref Seller Virtual BankAccount Id"
                        value={PaymentData.RefSellerVirtualBankAccountId}
                        handleChange={handlePaymentChange}
                      />
                    </Grid>
                    <div>
                      <div>Payment Account (Buyer)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Select
                        name="RefBuyerVirtualBankAccountId"
                        placeholder="Ref Buyer Virtual BankAccount Id *"
                        value={PaymentData.RefBuyerVirtualBankAccountId}
                        handleChange={handlePaymentChange}
                        readOnly={true}
                      />
                    </Grid>
                  </div>
                  <ButtonDiv>
                    <Button
                      onClick={() => {
                        handleNavigate(`../open-account/${id}`);
                      }}>
                      {" "}
                      Go Back
                    </Button>
                    <Button onClick={handleAbort}>Abort Trade</Button>
                    <Button type="submit">Confirm</Button>
                  </ButtonDiv>
                </form>
              ) : (
                <form>
                  <div className={classes.FormDiv}>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <SelectWithSearch
                        name="buyerMemberId"
                        placeholder="Buyer"
                        options={
                          companyList &&
                          companyList.sort((a, b) =>
                            a.label.localeCompare(b.label)
                          )
                        }
                        value={NewTradeData.buyerMemberId}
                        handleChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Select
                        name="sellerMemberId"
                        placeholder="Seller"
                        options={companyListForSeller}
                        value={NewTradeData.sellerMemberId}
                        handleChange={handleChange}
                        readOnly={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <SelectWithSearch
                        options={Countries}
                        name="countryOfOrigin"
                        placeholder="Country of Origin"
                        handleChange={handleChange}
                        value={NewTradeData.countryOfOrigin}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <SelectWithSearch
                        options={Countries}
                        name="countryOfDestination"
                        placeholder="Country of Destination*"
                        handleChange={handleChange}
                        value={NewTradeData.countryOfDestination}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <SelectWithSearch
                        options={
                          Currenciesr &&
                          Currenciesr.sort((a, b) =>
                            a.label.localeCompare(b.label)
                          )
                        }
                        name="paymentCurrency"
                        placeholder="Payment Currency*"
                        handleChange={handleChange}
                        value={NewTradeData.paymentCurrency}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Input
                        name="tradeValue"
                        placeholder={`Trade Value*(${NewTradeData.paymentCurrency})`}
                        value={NewTradeData.tradeValue}
                        handleChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <SelectWithSearch
                        options={
                          Industry &&
                          Industry.sort((a, b) =>
                            a.label.localeCompare(b.label)
                          )
                        }
                        name="industryCategory"
                        placeholder="Industry *"
                        handleChange={handleChange}
                        value={NewTradeData.industryCategory}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Grid item xs={12} md={6}>
                        <DateInput
                          name="contractDate"
                          placeholder="Contract Date*"
                          value={DatePicker1}
                          backDate={true}
                          handleChange={(e) => handleChange(e, "contractDate")}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Input
                        name="openTradeStatus"
                        placeholder="Trade Status*"
                        handleChange={handleChange}
                        value={NewTradeData.openTradeStatus}
                        readOnly={true}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Select
                        options={PreferredPaymentMethod}
                        name="preferredPaymentMethod"
                        placeholder="Preferred Payment Method*"
                        handleChange={handleChange}
                        value={NewTradeData.preferredPaymentMethod}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Select
                        options={EscrowService}
                        name="escrowService"
                        placeholder="Mode Of Payments*"
                        handleChange={handleChange}
                        value={NewTradeData.escrowService}
                      />
                    </Grid>

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Input
                        name="ConsentStatus"
                        placeholder="Buyer Consent Status"
                        value={
                          (isAdded && isAdded.escrowConsentStatus) || "DRAFT"
                        }
                        handleChange={handleChange}
                        readOnly={true}
                      />
                    </Grid>
                    {/* NEW */}

                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Input
                        name="unNumber"
                        placeholder="UN Number of Cargo"
                        handleChange={handleChange}
                        value={NewTradeData.unNumber}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Input
                        name="goodsTradeName"
                        placeholder="Trade Name i.e goods name"
                        handleChange={handleChange}
                        value={NewTradeData.goodsTradeName}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <SelectWithSearch
                        options={hazMatOptions}
                        name="hazMat"
                        placeholder="Hazardous Material"
                        handleChange={handleChange}
                        value={NewTradeData.hazMat}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <SelectWithSearch
                        options={incoTermOptions}
                        name="incoTerm"
                        placeholder="INCOTERM"
                        handleChange={handleChange}
                        value={NewTradeData.incoTerm}
                      />
                    </Grid>
                  </div>

                  <ButtonDiv>
                    <Button
                      onClick={() => {
                        handleNavigate(`../open-account/${id}`);
                      }}>
                      Go Back
                    </Button>
                    {!isAdded && (
                      <>
                        {newTradeResponse && isAborted ? (
                          <>
                            <Button onClick={handleAbort}>Abort Trade</Button>
                          </>
                        ) : (
                          <Button
                            onClick={(e) => handleSubmit(e)}
                            type="submit">
                            Create Open Trade
                          </Button>
                        )}
                      </>
                    )}
                  </ButtonDiv>
                </form>
              )}
              {toast && <div style={{ color: "green" }}>{toast}</div>}
              <div
                className={cn(
                  classes.StatusDiv,
                  "d-flex justify-content-between"
                )}>
                <div>
                  <CreatedByLabel
                    createdBy={
                      NewTradeData.sellerMemberId && response
                        ? FindCompanyName(response, NewTradeData.sellerMemberId)
                        : "NA"
                    }
                    createdDate={new Date().toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    })}
                    lastUpdateBy={"NA"}
                    lastUpdatedDate={"NA"}
                  />
                </div>
              </div>
            </Content>
          )}

          {(List.isError || isError) && (
            <Content marginBottom="0%">
              {List.isError}
              {isError}
              Something went wrong
            </Content>
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default NewOpenTradeForm;
