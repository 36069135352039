import {invoiceFinanceContext} from './invoiceFinanceContext'
import React , {createContext,useState,useReducer,useEffect,useContext} from 'react';

export const invoiceFinanceDetailsContext = createContext(); //type

// const formReducer = (state, event) => {
//     return {
//       ...state,
//       [event.name]: event.value
//     }
// }

export const FinanceDetailsContextProvider = ({ children }) => { //context provider

    const {invoiceData} = useContext(invoiceFinanceContext)

    const [generalData, setGeneralData] = useState({
      invoiceNumber: "",
      TaxSch: "",
      SupTyp: "",
      DocDtlsType: "",
      DocDtlsNo: "",
      DocDtlsDt: "",
      AssValue: "",
      TotlnvVal: "",
    });

    const handleGeneralChange = event => {
       setGeneralData({
        ...generalData,
        [event.target.name]: event.target.value
   });
   }

    // Supplier Details
    const [supplierData, setSupplierData] = useState({
      Gstin: "",
      LglNm: "",
      Addr1: "",
      Addr2: "",
      Loc: "",
      Pin: "",
      Stcd: "",
    })
    const handleChangeSupplier = (event) => {
      setSupplierData({
          ...supplierData,
          [event.target.name]: event.target.value
     });
    }
  const { SellerDtls } = invoiceData.invoiceDetails || {};
  React.useEffect( ()=> {
    const fetch = async ()=> {
      if( SellerDtls ) { 
      setSupplierData({
        ...supplierData,
        Gstin: SellerDtls.Gstin || '',
        LglNm: SellerDtls.LglNm || '',
        Addr1: SellerDtls.Addr1 || '',
        Addr2: SellerDtls.Addr2 || '',
        Loc: SellerDtls.Loc || '',
        Pin: SellerDtls.Pin || '',
        Stcd: SellerDtls.Stcd || '',
      });
    }
    }
    fetch();  
   },[invoiceData.invoiceDetails])
    
    // Buyer Details
    const [buyerData, setBuyerData] = useState({
      Gstin: "",
      LglNm: "",
      Addr1: "",
      Addr2: "",
      Loc: "",
      Pin: "",
    })
    const handleBuyerChange = event => {
      setBuyerData({
        ...buyerData,
        [event.target.name]: event.target.value
      });
    }

    const { BuyerDtls } = invoiceData.invoiceDetails || {};
    React.useEffect( ()=> {
      const fetch = async ()=> { 
        if( BuyerDtls ) { 
        setBuyerData({
          ...buyerData,
          Gstin: BuyerDtls.Gstin || '',
          LglNm: BuyerDtls.LglNm || '',
          Addr1: BuyerDtls.Addr1 || '',
          Addr2: BuyerDtls.Addr2 || '',
          Loc: BuyerDtls.Loc || '',
          Pin: BuyerDtls.Pin || '',
        });
      }
      }
      fetch();  
    },[invoiceData.invoiceDetails])
    
    //Export Details
    const [exportData, setExportData] = useState({
      ShipBNo: "",
      ShipDate: "",
      Port: "",
      RefClm: "",
      ForCur: "",
      CntCode: "",
      ExpDuty: "",
    });

    const handleExportChange = event => {
      setExportData({
        ...exportData,
        [event.target.name]: event.target.value
      });
    }
    const { ExpDtls } = invoiceData.invoiceDetails || {};
    React.useEffect( ()=> {
      const fetch = async ()=> {
        if( ExpDtls ) { 
        setExportData({
          ...exportData,
          ShipBNo: ExpDtls.ShipBNo || '',
          ShipDate: ExpDtls.ShipDate || '',
          Port: ExpDtls.Port || '',
          RefClm: ExpDtls.RefClm || '',
          ForCur: ExpDtls.ForCur || '',
          CntCode: ExpDtls.CntCode || '',
          Export: ExpDtls.Export || '', 
        });
      }
      }
      fetch();  
    },[invoiceData.invoiceDetails])
    
    // miscellaneousData Details
    const [miscellaneousData, setMiscellaneousData] = useState({
      ContrRefr: "",
      ProjRefr: "",
      PORefr: "",
      PORefDt: "",
    });

    const handleMiscellaneousChange = event => {
      setMiscellaneousData({
        ...miscellaneousData,
        [event.target.name]: event.target.value
      });
    }
    const { ContrDtls } = invoiceData.invoiceDetails || {};
    React.useEffect( ()=> {
      const fetch = async ()=> { 
        if( ContrDtls ) { 
        setMiscellaneousData({
          ...miscellaneousData,
          ContrRefr: ContrDtls.ContrRefr || '',
          ProjRefr: ContrDtls.ProjRefr || '',
          PORefr: ContrDtls.PORefr || '',
          PORefDt: ContrDtls.PORefDt || '',
        });
      }
      }
      fetch();  
    },[invoiceData.invoiceDetails])


    const handleNext = event => {
      event.preventDefault();
    }

    const [details , setDetails] = useState({});
    useEffect( () => {
      setDetails({
        ...invoiceData,
        invoiceDetails:{
         ...invoiceData.invoiceDetails,
        'GeneralDtls' : generalData,
        'SellerDtls' : supplierData,
        'BuyerDtls' : buyerData,
        'ExpDtls' : exportData,
        'ContrDtls' : miscellaneousData,
        }
    })
    }, [generalData,supplierData,buyerData,exportData,miscellaneousData])    

      return <invoiceFinanceDetailsContext.Provider value={{details,invoiceData,generalData,supplierData,buyerData,exportData,miscellaneousData,
            handleGeneralChange,handleBuyerChange,handleExportChange,handleMiscellaneousChange,
            handleChangeSupplier,
            handleNext}}>
         {children}
     </invoiceFinanceDetailsContext.Provider>
};