import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import "./tradeworkflow.module.css";
import { SecondLevelNode } from "./commonNode";
import MultiSteppers from "components/UI/Stepper/MutliLevelStepper2";

export default ({
  initialState,
  handleSecondFlowSequence,
  Data,
  secondFlowSequence,
  width,
}) => {
  const [initialArr, setInitialArr] = useState({
    data: [],
    width: "",
  });

  useEffect(() => {
    if (!Data.error && Data?.tradeLifeCycle?.length > 0) {
      const temp = Data.tradeLifeCycle.filter((item) => {
        return item.stageSequence === initialState.toString();
      });
      temp.forEach((item)=>{
           item.subStageSequence = parseInt(item.subStageSequence);
      })
      temp.sort((a, b) => (a.subStageSequence > b.subStageSequence ? 1 : -1));
      setInitialArr({
        ...initialArr,
        data: temp,
        width: 100 / temp.lengths,
      });
    }
  }, [initialState]);

  const handleSecondFlow = (e, item) => {
    handleSecondFlowSequence(item.subStageSequence);
  };

  const handleThirdFlow = (item) => {
    handleSecondFlowSequence(item.subStageSequence);
  };
  console.log("AkashTesting12", initialArr?.data);
  return (
    <div>
      <MultiSteppers
        Value={initialArr?.data}
        activeStep={secondFlowSequence}
        handleClick={handleThirdFlow}
      />
    </div>
  );
};
