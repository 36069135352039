export const PaymentAccountListHeader = [
  { name: "Bank Name" },
  { name: "Account Name" },
  { name: "Account Number" },
  { name: "Country" },
  { name: "Currency" },
  { name: "Status" },
];

export const PaymentAccountListProviderHeader = [
  { name: "Select" },
  { name: "Bank Name" },
  { name: "Account Name" },
  { name: "Account Number" },
  { name: "Country" },
  { name: "Status" },
];

export const TransactionsHeader = [
  { name: "Sender" },
  { name: "Receiver" },
  { name: "Currency" },
  { name: "Amount" },
  { name: "Payment Provider" },
  { name: "Payment Type" },
  { name: "Transaction Date" },
  { name: "Status" },
  { name: "Reference" },
];

export const QuickFilters = [
  { label: "INITIATED", value: "INITIATED" },
  { label: "MONEY_DISBURED_TO_PROVIDER", value: "MONEY_DISBURSED_TO_PROVIDER" },
  { label: "PENDING", value: "PENDING" },
  { label: "RELEASE_FAILED", value: "RELEASE_FAILED" },
  { label: "RELEASE_COMPLETED", value: "RELEASE_COMPLETED" },
  { label: "VERIFICATION_DOC_FAILED", value: "VERIFICATION_DOC_FAILED" },
  { label: "ADDITIONAL_DOC_REQUIRED", value: "ADDITIONAL_DOC_REQUIRED" },
  { label: "FAILED", value: "FAILED" },
  { label: "REFUND_INITATED", value: "REFUND_INITATED" },
  { label: "REFUND_FAILED", value: "REFUND_FAILED" },
  { label: "REFUND_REJECTED", value: "REFUND_REJECTED" },
  { label: "VERIFICATION_DOC_FAILED", value: "VERIFICATION_DOC_FAILED" },
  { label: "REFUND_COMPLETED", value: "REFUND_PENDING" },
];

export const ScheduledPaymentsHeader = [
  { name: "Payment Id" },
  { name: "Milestone Stage" },
  { name: "Milestone Sub-Stage" },
  { name: "Document Category" },
  { name: "Document Sub-Category" },
  { name: "Payment Due Date" },
  { name: "Payment Amount" },
  { name: "Status" },
  { name: "Action" },
];

export const ScheduledPaymentsDummyData = [
  { name1: "Silver" },
  { name1: "Achiever" },
  { name1: "Medicine" },
  { name1: "ENT" },
  { name1: "23-11-2022" },
  { name1: "Unpaid" },
  { name1: "$1250" },
  { name1: "Pending" },
  { name1: "pay now" },
];
