import React, { useContext, useState } from "react";
import styled from "styled-components";
import { MdOutlineUploadFile } from "react-icons/md";

import {
  CardWapper,
  ButtonStyled,
  CancelButtonStyled,
} from "components/Container.styled";

import { invoiceFinanceContext } from "contexts/invoiceFinance/invoiceFinanceContext";
import Info from "./Info";

// import { useParams } from "react-router-dom";

function Fileupload(props) {
  const { data, handleChange } = useContext(invoiceFinanceContext);
  const [tradeInfo, setTradeInfo] = useState(false);
  // const params = useParams();

  const FormStyled = styled.form`
    flex: 1;
    padding: 16px;
  `;

  const UploadStyle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 160px;
    border: 2px dashed #d7dadf;
    p {
      color: #99abbe;
      padding: 5px 0px;
    }
  `;

  const CircleDiv = styled.div`
    border-radius: 50%;
    border: 1px solid;
    padding: 5px;
    display: flex;
    align-items: center;
    background: #aed4ff;
  `;

  const UploadButton = styled.label`
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #0071e9;
    background: #ffffff;
    border: 1px solid #0071ef;
    border-radius: 4px;
    padding: 9px 35px;
    &:hover {
      opacity: 0.5;
    }
  `;

  const ActionSection = styled.div`
    display: flex;
    justify-content: end;
    button {
      margin-right: 30px;
    }
  `;

  const handleUpload = async (event) => {
    event.preventDefault();
    var body = new FormData();
    body.append("name", "invoice");
    body.append("file", data.file);
    console.log(body);
    setTradeInfo(true);
  };

  const SectionStyled = styled.div`
    padding: 40px;
    margin: 16px;
    background-color: #2e2e2e;
  `;
  const enableSchedulePayment = props.goBack.enableSchedulePayment.enableSP;

  const setTade = () => {
    setTradeInfo(false);
  };

  const enableUpload = {
    setTade,
    enableSchedulePayment,
  };
  return (
    <>
      {tradeInfo ? (
        <Info Upload={enableUpload} />
      ) : (
        <CardWapper>
          <FormStyled onSubmit={(e) => handleUpload(e)}>
            <SectionStyled>
              <UploadStyle>
                <CircleDiv>
                  <MdOutlineUploadFile fontSize={60} backgroundColor="#fff" />
                </CircleDiv>
                <p>Drag and drop files here</p>
                <UploadButton
                  htmlFor="uploadId"
                  class="btn"
                  type="file"
                  onChange={handleChange}
                >
                  Choose File
                </UploadButton>
              </UploadStyle>
            </SectionStyled>
            <h4 style={{ padding: "0px 15px" }}>Upload Documents</h4>
            <ActionSection>
              <CancelButtonStyled onClick={() => props.goBack()}>
                Cancel
              </CancelButtonStyled>
              <ButtonStyled onClick={() => setTradeInfo(true)}>
                {" "}
                Upload{" "}
              </ButtonStyled>
            </ActionSection>
          </FormStyled>
        </CardWapper>
      )}
    </>
  );
}

export default Fileupload;
