import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaFileSignature } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { MdCloudUpload } from "react-icons/md";
import { RiGitPullRequestFill } from "react-icons/ri";
import ShareDocument from "../shareDocument";
import DigitalSign from "../DigitalSign";
import RequestDocument from "../requestDocument";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import DocumentIcon from "components/Icons/document_icon";
import { setCookie } from "components/Helper";
import { FindCompanyName } from "utils/helper";
import { documentURL } from "services/BaseURLInstance";
import classes from "./company.module.css";
import Table from "react-bootstrap/Table";
import { Header } from "utils/Section/Document";
import cn from "classnames";
import { Success, Error } from "components/notifications/responseHandle";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Select from "components/fields/Select";
import Content from "components/UI/Layout/Content";
import { BsThreeDotsVertical } from "react-icons/bs";
import Action from "./Action";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

export default ({
  subStageDate,
  category,
  ActiveTab,
  CompanyList,
  memberDetails,
  list,
  tags,
  trade,
  dealData,
  referenceData,
  invoiceId,
  componentLocations,
  serviceId, // using for Service Request ID
  serviceDealDetails, // using for service contract details
  folderLocation, // using for folder location of every document
  OpenTradeAccountDetails, // using for Open Trade Account details
  currentNetwork, // Using in Newtwork Section Company Details Document
  documentTemplate, // Using in Request Document
}) => {
  let PropsDataObject = {
    subStageDate,
    category,
    ActiveTab,
    CompanyList,
    memberDetails,
    list,
    tags,
    trade,
    dealData,
    referenceData,
    serviceDealDetails,
    invoiceId,
    serviceId,
  };

  useEffect(() => {
    if (list && list.documents && componentLocations) {
      componentLocations &&
        componentLocations.MandatoryCatsubCatMap &&
        componentLocations.MandatoryCatsubCatMap(list && list.documents);
    }
  }, [componentLocations]);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] = useState([]);
  const [temp, setTemp] = useState(null);
  const [userType, setUserType] = useState("");
  const [listMember, setListMember] = useState(null);
  const [memberList, setMemberList] = useState("");
  const [docResponse, setDocResponse] = useState(null);
  const { instance, accounts } = useMsal();
  const [Role, setRole] = useState(null);
  const [uploadRole, setUploadRole] = useState(null);
  const [eSignRole, setEsignRole] = useState(null);
  const [shareRole, setSharerole] = useState(null);
  const [digitalSignRole, setDigitalSignRole] = useState(null);
  const [data, setData] = useState({});
  const [selected, setSelected] = useState(null);
  const [selectedActionData, setSelectedData] = useState(null);

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [selectedProvider, setSelectedProvider] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    if (CompanyList) {
      let user = CompanyList.find((item) => {
        if (item.memberUID === companyId) {
          return item.memberName;
        }
      });
      setUserType(user);

      let TEMP = CompanyList?.filter((item) => item.memberUID !== companyId);
      let allList = TEMP?.filter(
        (obj) => obj.memberType !== "PERSON" && obj.memberType
      );
      setListMember(allList);
      let list = CompanyList.filter(
        (obj) => obj.memberType !== "PERSON" && obj.memberType
      );
      setMemberList(list);
    }
  }, [CompanyList]);

  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "end",
      });
    }
  }, [selected]);

  const handleClick = (value) => {
    // if (!docResponse) {
    //   Error("No response");
    // } else {
    //   setSelected(value);
    // }
    setSelected(value);
  };

  const handleDocumentPreview = (e, id) => {
    e.preventDefault();
    if (id.docReferenceId === undefined) {
      Error("Not available");
    } else {
      handleNavigate(`/documents/preview/${id.docReferenceId}`);
    }
  };

  //rbac
  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setUploadRole(
        CheckPermission("Actions", "CompanyDocuments", "Upload", Role)
      );
      setEsignRole(
        CheckPermission("Actions", "CompanyDocuments", "ESign", Role)
      );
      setSharerole(
        CheckPermission("Actions", "CompanyDocuments", "Share", Role)
      );
      setDigitalSignRole(
        CheckPermission("Actions", "CompanyDocuments", "DigitalSignature", Role)
      );
    }
  }, [Role]);

  const fetchDocumentDetails = (Data, index) => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${documentURL}documents/${Data.docReferenceId}`,
        null,
        response
      )
        .then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
          } else {
            const {
              name,
              owner,
              validated,
              isDeleted,
              id,
              docIssuer,
              ...rest
            } = data;

            const finalRes = {
              name,
              owner: FindCompanyName(CompanyList, owner),
              validated,
              isDeleted,
              documentId: id,
              docIssuer,
            };
            setData(finalRes);
            setDocResponse(data);

            Success(`Selected ${Data.documentName}`);
            setTemp({
              index: index,
              value: true,
              id: Data.docReferenceId,
              documentName: Data.documentName,
              docCategory: Data.docCategory,
              docSubCategory: Data.docSubCategory,
              ownerId: Data.ownerId,
              tags: Data.tags,
            });
          }
        })
        .catch((error) => {
          setData(null);
          setDocResponse(null);
          // setTemp(null);
        });
    });
  };

  const handleClickSelect = (Data, index) => {
    if (
      Data.docReferenceId === "" ||
      Data.docReferenceId === undefined ||
      Data.docReferenceId === null
    ) {
      Error("Document not found");
      setData(null);
      setDocResponse(null);
      setTemp(null);
    } else {
      setTemp({
        index: index,
        value: true,
        id: Data.docReferenceId,
        documentName: Data.documentName,
        docCategory: Data.docCategory,
        docSubCategory: Data.docSubCategory,
        ownerId: Data.ownerId,
        tags: Data.tags,
      }); // Remove temporary  setTemp using at line  221

      fetchDocumentDetails(Data, index);
    }
  };

  const childProps = {
    companyId,
    idToken,
    tempData: temp,
    listMember, // memeber list company list
    userType,
    trade, // location trade
    docResponse,
    tags,
    memListWithLoggedUser: memberList,
    serviceId, // using for Service Request ID
    serviceDealDetails, // using for service contract details
    folderLocation, // using for folder location of every document
    OpenTradeAccountDetails, // using for Open Trade Account details
    subStageDate, // Using for Trade Section .. Trade Details
    currentNetwork, // Using in Newtwork Section Company Details Document
    documentTemplate, // Using in Request Document
  };

  const Component = () => {
    switch (selected) {
      case "shareDoc":
        return <ShareDocument {...childProps} />;
      case "digitalSign":
        return <DigitalSign {...childProps} />;
      case "requestDocument":
        return <RequestDocument {...childProps} />;
      default:
        return "";
    }
  };

  const handleNavigate = (url, value) => {
    navigate(url, { state: value });
  };

  const Provider = [
    { label: "My Documents", value: "MyDocuments" },
    { label: "Shared Documents", value: "SharedDocuments" },
    { label: "Pending Signature", value: "PendingSignature" },
    { label: "All Documents", value: "AllDocuments" },
  ];

  const handleFilterChange = (event) => {
    setSelectedProvider(event.target.value);
  };

  // participantsToShare
  useEffect(() => {
    if (selectedProvider === "MyDocuments") {
      const Filtered =
        list &&
        list.documents &&
        list.documents.filter((res) => res.ownerId === companyId);
      setResponse(Filtered);
    } else if (selectedProvider === "SharedDocuments") {
      const Filtered =
        list &&
        list.documents &&
        list.documents.filter(
          (res) => res.participantsToShare && res.participantsToShare.length > 0
        );
      setResponse(Filtered);
    } else if (selectedProvider === "PendingSignature") {
    } else if (selectedProvider === "AllDocuments") {
      setResponse(list && list.documents);
    } else {
      setResponse(list && list.documents);
    }
  }, [selectedProvider, category]);

  return (
    <>
      <Content>
        <div
          className={cn(
            classes.Header,
            "d-flex justify-content-between align-item-center"
          )}>
          <div>
            <h6>{ActiveTab} Documents</h6>
          </div>
          <div>
            <Select
              name="Provider"
              placeholder="Search Filter"
              options={Provider}
              handleChange={handleFilterChange}
            />
          </div>
          <div className={classes.buttonDiv}>
            <button
              onClick={
                shareRole === "CREATE"
                  ? (e) => {
                      handleClick("shareDoc");
                    }
                  : " "
              }>
              <span className="mx-1">
                <HiShare />
              </span>
              <span>Share Document</span>
            </button>

            {memberDetails && memberDetails.memberUID !== companyId && (
              <button
                onClick={(e) => {
                  handleClick("requestDocument");
                }}>
                <span className="mx-1">
                  <RiGitPullRequestFill />
                </span>
                <span>Request Document</span>
              </button>
            )}

            <button
              onClick={
                digitalSignRole === "CREATE"
                  ? (e) => {
                      handleClick("digitalSign");
                    }
                  : " "
              }>
              <span className="mx-1">
                <FaFileSignature />
              </span>
              <span>Digital Signature</span>
            </button>

            {ActiveTab === "OTHERS" &&
              memberDetails &&
              memberDetails.memberUID === companyId && (
                <button
                  onClick={() => {
                    setCookie("upload_doc", JSON.stringify(data), 60);
                    setCookie("DocCategory", "COMPANY", 60);
                    handleNavigate(`/documents/upload`, {
                      state: {
                        dealData: dealData ? dealData : "",
                        serviceDealDetails: serviceDealDetails
                          ? serviceDealDetails
                          : "",
                        subStageDate: subStageDate ? subStageDate : "",
                        ref: data.docReferenceId,
                        referenceData: referenceData ? referenceData : "",
                        invoiceId: invoiceId ? invoiceId : "",
                        serviceId: serviceId ? serviceId : "",
                        publicView: false,
                      },
                    });
                  }}>
                  <span className="mx-1">
                    <MdCloudUpload size={20} />
                  </span>
                  <span>Upload</span>
                </button>
              )}
          </div>
        </div>

        {response && (
          <Table bordered responsive className={cn(classes.Table)}>
            <thead>
              <tr>
                {Header.map((header, index) => (
                  <td key={index}>{header.name}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {response.length > 0 ? (
                response.map((res, index) => (
                  <>
                    {componentLocations && componentLocations.memberLoginId ? (
                      <>
                        {/* this is only for Onboarding Screen */}
                        {res.memberId === componentLocations.memberLoginId && (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <td>
                              <input
                                className={cn(classes.Checkbox)}
                                type="checkbox"
                                onClick={() => handleClickSelect(res, index)}
                                checked={
                                  temp && temp.id === res.docReferenceId
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td
                              className={cn("d-flex justify-content-left")}
                              onClick={(e) => handleDocumentPreview(e, res)}>
                              <span className={cn("d-flex px-2")}>
                                {res.documentName && <DocumentIcon />}
                              </span>
                              <span>
                                {res.documentName ? res.documentName : "NA"}
                              </span>
                            </td>
                            <td>{res.docSubCategory}</td>

                            <td>
                              {CompanyList &&
                                FindCompanyName(CompanyList, res.ownerId)}
                            </td>
                            <td>
                              {res.createdDate
                                ? new Date(res.createdDate).toLocaleDateString()
                                : "-"}
                            </td>
                            <td>
                              {res.updatedDate
                                ? new Date(res.updatedDate).toLocaleDateString()
                                : "-"}
                            </td>
                            <td>
                              <OverlayTrigger
                                trigger={["click", "focus"]}
                                key="center"
                                rootClose
                                placement="left"
                                overlay={
                                  <Popover>
                                    <Popover.Body>
                                      <Action
                                        Data={selectedActionData}
                                        PropsData={PropsDataObject}
                                        folderLocation={folderLocation} // USING for identify location
                                        OpenTradeAccountDetails={
                                          OpenTradeAccountDetails
                                        } // using for open trade account details
                                        componentLocations={componentLocations} // using in Profile Stepper
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }>
                                <span
                                  style={{ color: "#42a5f5" }}
                                  onClick={() => {
                                    setSelectedData(res);
                                  }}>
                                  <BsThreeDotsVertical size={26} />
                                </span>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr key={index} style={{ cursor: "pointer" }}>
                        <td>
                          <input
                            className={cn(classes.Checkbox)}
                            type="checkbox"
                            onClick={() => handleClickSelect(res, index)}
                            checked={
                              temp && temp.id === res.docReferenceId
                                ? true
                                : false
                            }
                          />
                        </td>
                        <td
                          style={{ borderWidth: "0px" }}
                          className={cn("d-flex justify-content-left")}
                          onClick={(e) => handleDocumentPreview(e, res)}>
                          <span className={cn("d-flex px-2")}>
                            {res.documentName && <DocumentIcon />}
                          </span>
                          <span>
                            {res.documentName ? res.documentName : "NA"}
                          </span>
                        </td>
                        <td>{res.docSubCategory}</td>

                        <td>
                          {CompanyList &&
                            FindCompanyName(CompanyList, res.ownerId)}
                        </td>
                        <td>
                          {res.createdDate
                            ? new Date(res.createdDate).toLocaleDateString()
                            : "-"}
                        </td>
                        <td>
                          {res.updatedDate
                            ? new Date(res.updatedDate).toLocaleDateString()
                            : "-"}
                        </td>
                        <td>
                          <OverlayTrigger
                            trigger={["click", "focus"]}
                            key="center"
                            rootClose
                            placement="left"
                            overlay={
                              <Popover>
                                <Popover.Body>
                                  <Action
                                    Data={selectedActionData}
                                    PropsData={PropsDataObject}
                                    folderLocation={folderLocation}
                                    OpenTradeAccountDetails={
                                      OpenTradeAccountDetails
                                    } // using for open trade account details
                                  />
                                </Popover.Body>
                              </Popover>
                            }>
                            <span
                              style={{ color: "#42a5f5" }}
                              onClick={() => {
                                // setSelectedData(data);
                                setSelectedData(res);
                              }}>
                              <BsThreeDotsVertical size={26} />
                            </span>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    )}
                  </>
                ))
              ) : (
                <>No Records Found</>
              )}
            </tbody>
          </Table>
        )}
      </Content>
      <div ref={ref}>{selected && <div>{Component()}</div>}</div>
    </>
  );
};
