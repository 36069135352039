export default (value, type) => {
  let StatusType = {
    //   "enum": [
    //     "INITIATED",
    //     "MONEY_DISBURED_TO_PROVIDER",
    //     "PENDING",
    //     "RELEASE_INITITATED",
    //     "RELEASE_FAILED",
    //     "RELEASE_COMPLETED",
    //     "VERIFICATION_DOC_FAILED",
    //     "ADDITIONAL_DOC_REQUIRED",
    //     "ACKNOWLEDGED",
    //     "ABORTED",
    //     "FAILED",
    //     "REFUND_INITATED",
    //     "REFUND_FAILED",
    //     "REFUND_REJECTED",
    //     "REFUND_COMPLETED",
    //     "REFUND_PENDING"
    // ]

    INITIATED: "INITIATED", // Payment Transaction Status enum
    MONEY_DISBURED_TO_PROVIDER: "MONEY DISBURSED TO PROVIDER",
    PENDING: "PENDING",
    RELEASE_INITITATED: "RELEASE_INITITATED",
    RELEASE_FAILED: "RELEASE_FAILED",
    RELEASE_COMPLETED: "RELEASE_COMPLETED",
    VERIFICATION_DOC_FAILED: "VERIFICATION_DOC_FAILED",
    ADDITIONAL_DOC_REQUIRED: "ADDITIONAL_DOC_REQUIRED",
    ACKNOWLEDGED: "ACKNOWLEDGED",
    ABORTED: "ABORTED",
    FAILED: "FAILED",
    REFUND_INITATED: "REFUND_INITATED",
    REFUND_FAILED: "REFUND_FAILED",
    REFUND_REJECTED: "REFUND_REJECTED",
    REFUND_COMPLETED: "REFUND_COMPLETED",
    REFUND_PENDING: "REFUND_PENDING",

    DRAFT: "DRAFT", //openTradeStatus
    NEW: "NEW",
    ABORTED: "ABORTED",
    TRADE_APPROVED: "TRADE APPROVED",
    TRADE_DECLINED: "TRADE DECLINED",
    TRADE_COMPLETED: "TRADE COMPLETED",
    TRADE_IN_PROGRESS: "TRADE IN-PROGRESS",
    TRADE_COMPLETED: "TRADE COMPLETED",
    BUYER_TRADE_COMPLETED: "BUYER TRADE COMPLETED",
    SELLER_TRADE_COMPLETED: "SELLER TRADE COMPLETED",
    MILESTONE_CREATED: "MILESTONE CREATED",
    OTHERS: "OTHERS",
    ABORTED: "ABORTED",
    BUYER_NOT_LINKED: "BUYER NOT LINKED",
    BUYER_LINKED: "BUYER LINKED",
    TRADE_IN_REVIEW: "TRADE IN-REVIEW",

    NOT_REQUESTED: "NOT REQUESTED", // Participant status
    SIGNED: "SIGNED",
    IN_QUEUE: "IN-QUEUE",
    DECLINED: "DECLINED",
    CANCELLED: "CANCELLED",

    MILESTONE_CREATED: "MILESTONE CREATED", // tradeMilestoneStatus
    MILESTONE_APPROVAL_REQUESTED: "MILESTONE APPROVAL REQUESTED",
    MILESTONE_APPROVED: "MILESTONE APPROVED",
    MILESTONE_DECLINED: "MILESTONE DECLINED",
    MILESTONE_ABORTED: "MILESTONE ABORTED",
    MILESTONE_REACHED: "MILESTONE REACHED",
    MILESTONE_COMPLETED: "MILESTONE COMPLETED",
    DELIVERY_INITIATED: "DELIVERY INITIATED",
    DELIVERY_COMPLETED: "DELIVERY COMPLETED",
    DELIVERY_IN_PROGRESS: "DELIVERY IN-PROGRESS",
    DELIVERY_APPROVED: "DELIVERY APPROVED",
    DELIVERY_DECLINED: "DELIVERY DECLINED",
    PAYMENT_INITIATED: "PAYMENT INITIATED",
    PAYMENT_APPROVED: "PAYMENT APPROVED",
    PAYMENT_PAST_DUE_DATE: "PAYMENT PAST DUE DATE",
    PAYMENT_COMPLETED: "PAYMENT COMPLETED",
    PAYMENT_DECLINED: "PAYMENT DECLINED",
    OTHERS: "OTHERS",
    ABORTED: "ABORTED",

    NEW: "NEW", // txlStatus Invoice Status Enum
    IN_PURSUIT: "IN_PURSUIT",
    FINANCED: "FINANCED",
    CLOSED: "CLOSED",
    BLOCKED: "BLOCKED",
    OPEN: "OPEN",
    PENDING: "PENDING",

    NEW: "NEW",
    INTEREST_EXPRESSED: "INTEREST_EXPRESSED",
    QUOTE_CREATED: "QUOTE_CREATED",
    QUOTE_ACCEPTED: "QUOTE_ACCEPTED",
    QUOTE_DECLINED: "QUOTE_DECLINED",
    TNC_ACCEPTANCE_PENDING: "TNC_ACCEPTANCE_PENDING",
    BID_COMPLETED: "BID_COMPLETED",
    BID_DECLINED: "BID_DECLINED",
    SERVICE_IN_PROGRESS: "SERVICE_IN_PROGRESS",
    DELIVERED: "DELIVERED",
    PAYMENT_PENDING: "PAYMENT_PENDING",
    DELIVERY_CONFIRMED: "DELIVERY_CONFIRMED",
    PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
    COMPLETED: "COMPLETED",
    ABORTED: "ABORTED",
    EXPIRED: "EXPIRED",
    IN_PURSUIT: "IN_PURSUIT",
    CLOSED: "CLOSED",

    NEW: "NEW",
    IN_PURSUIT: "IN_PURSUIT",
    ABORTED: "ABORTED",
    EXPIRED: "EXPIRED",
    CLOSED: "CLOSED",
    DELIVERED: "DELIVERED",
    PAYMENT_COMPLETED: "PAYMENT_COMPLETED",

    INTEREST_EXPRESSED: "INTEREST_EXPRESSED", // financeDealStatus
    DUE_DILIGENCE_INITIATED: "DUE DILIGENCE INITIATED",
    DUE_DILIGENCE_COMPLETED: "DUE_DILIGENCE_COMPLETED",
    QUOTE_GENERATED: "QUOTE_GENERATED",
    QUOTE_DECLINED: "QUOTE_DECLINED",
    QUOTE_ACCEPTED: "QUOTE_ACCEPTED",
    PENDING_T_AND_C_ACCEPTANCE: "PENDING_T_AND_C_ACCEPTANCE",
    FINANCE_AGREEMENT_COMPLETE: "FINANCE_AGREEMENT_COMPLETE",
    FINANCED: "FINANCED",
    DECLINED: "DECLINED",
    ABORTED: "ABORTED",
    CLOSED: "CLOSED",

    ACTIVE: "ACTIVE", // loanStatus
    INACTIVE: "INACTIVE",
    DRAFT: "DRAFT",
    IN_REVIEW: "IN_REVIEW",
    CLOSED: "CLOSED",
    LOCKED: "LOCKED",
    BLOCKED: "BLOCKED",

    IN_PROGRESS: "IN_PROGRESS",
    NOT_STARTED: "NOT_STARTED",

    // Consent Status
    Approved: "Approved",
    Pending: "Pending",
    Declined: "Declined",
  };

  let title = "";
  if (value) {
    title = StatusType[`${value}`];
  }

  if (title === "" || title === undefined || title === null) {
    title = value;
  }

  switch (type) {
    case "danger":
      return <div className={"py-1 px-1 bg-danger"}>{title}</div>;
    case "primary":
      return <div className={"py-1 px-1 bg-primary"}>{title}</div>;
    case "warning":
      return <div className={"py-1 px-1 bg-warning"}>{title}</div>;
    case "success":
      return <div className={"py-1 px-1 bg-success"}>{title}</div>;
    default:
      <div>{title}</div>;
      return;
  }
};
