import React , { useState } from 'react';
import {BsCircleFill} from 'react-icons/bs'
import Style from './styled';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'
import {IoIosArrowUp,IoIosArrowDown} from "react-icons/io";
import { withTheme } from 'styled-components';
import { ButtonStyled } from '../../components/Container.styled';



export default ()=>{
    // const { response, isLoading, error } = useAxios({
    //     api: misInvoiceApi,
    //     method: "get",
    //     url: "/bff/supplier/mis/payments?memberId=12345",
    //     config: JSON.stringify({
    //       requireAuthentication: true,
    //       headers: {
    //         "x-api-key":
    //           "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //       },
    //     }),  
    //   });
    // useEffect(()=>{
    //      if(response){
    //         console.log( response)
    //      }
    // },[response])

    const years=["2019","2020","2021","2022"];
    const data=[
        {
            name:"Random",
            invoice:"12345",
            amount:"USD 100,000",
            dueDate:"23-03-2003",
            overdueBy:"12 Days",
            status: "Pay Now"
        },
        {
            name:"Random",
            invoice:"12345",
            amount:"USD 100,000",
            dueDate:"23-03-2003",
            overdueBy:"12 Days",
            status: "Pay Now"
        }
    ]
    
    const [yearsState,setYearsState]=useState({
        trendDropdown:false,
        yearTrend:"2022",
        flowDropDown:false,
        yearFlow:"2022",

    } )
    const state = {
        labels: ['Jan', 'Feb', 'Mar',
                 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [
          {
          label: "Re-Payments Pending",
          backgroundColor: "rgb(33, 142, 235)",
          data: [3, 7, 4]
        }, {
          label: "Re-Payments Completed",
          backgroundColor: "#0d4fd2",
          data: [4, 3, 5]
        }
        ]
      }
      const handleTrendDropDown=()=>{
        if(yearsState.trendDropdown===true)
        {
          setYearsState({
              ...yearsState,
              trendDropdown:false
          });
        }
        else{
          setYearsState({
              
              ...yearsState,
              trendDropdown:true        
               });
        }
        
   } 
   const handleYearTrend=(e,item)=>{
       
      setYearsState({
          ...yearsState,
                trendDropdown:false,
                yearTrend:item
      })
   }
    return(
    <Style>
           <div>
           <div className="cardWrapper">
                        <div className='heading'>
                            <div>Re-Payments</div>
                            <div className='btn'>
                                <ButtonStyled>+Payments</ButtonStyled>
                            </div>  
                        </div>
                        <div
          style={{
            border: "1px solid transparent",
            margin: "2%",
            background: "rgba(25, 25, 25, 25)",
            borderRadius: "8px",

          }}
        >
                        <div style={{display:"flex", marginTop:"40px", marginLeft:"10%", marginRight:"10%",marginBottom:"0px"}}>
                            <div style={{width:"50%", textAlign: "left"}}><span style={{fontSize:"22px"}}>Total Financing Requested  <b style={{fontSize: "40px"}}> 10M</b></span></div>
                            <div style={{width:"50%", textAlign: "right"}}><span style={{fontSize:"22px"}}>Total Financing  <b style={{fontSize: "40px"}}>10M</b></span></div>
                        </div>
                        <div className='iconDiv'>
                            <div className='item'>
                                <div className='font'>21</div>
                                <div><BsCircleFill color='#218EEB' size={40}/></div>
                                <div>Re-Payments</div>
                            </div>
                            <div className='item'>
                                <div className='font'>21</div>
                                <div><BsCircleFill color='#218EEB' size={40}/></div>
                                <div>Re-Payments In-Progress</div>
                            </div>
                            <div className='item'>
                                <div className='font'>21</div>
                                <div><BsCircleFill color='#218EEB' size={40}/></div>
                                <div>Re-Payments Upcoming</div>
                            </div>
                            <div className='item'>
                                <div className='font'>21</div>
                                <div><BsCircleFill color='#218EEB' size={40}/></div>
                                <div>Re-Payments Delayed</div>
                            </div>
                            <div className='item'>
                                <div className='font'>21</div>
                                <div><BsCircleFill color='#218EEB' size={40}/></div>
                                <div>Re-Payments Declined</div>
                            </div>
                        </div>
                        </div> 
                        <div
          style={{
            border: "1px solid transparent",
            margin: "2%",
            background: "rgba(25, 25, 25, 25)",
            borderRadius: "8px",

          }}
        >               
                        <div style={{marginTop:"80px",width:"80%",marginLeft:"4%"}}>
                            <div style={{display:"flex", margin:"2%" }}>
                                <div>Monetary Flow</div>
                                <div class = "btn-group">
                                    <button className='graphbtn'>{yearsState.yearTrend}<span onClick={handleTrendDropDown}><IoIosArrowDown/></span></button>
                                    <div>
                                        {
                                            (yearsState.trendDropdown) &&  <div className='dropdownlist'>
                                                  {
                                                    years.map(item=>{
                                                        return <div onClick={(e)=>handleYearTrend(e,item)}>{item}</div>
                                                    })  
                                                  }
                                                 </div> 
                                        } 
                                    </div>  
                                 </div>
                            </div>
                            <div style={{marginTop:"40px"}}>
                                <Bar
                                    data={state}
                                    options={{
                                       
                                        title:{
                                        display:true,
                                        fontSize:80,
                                        },
                                        legend:{
                                        display:true,
                                        position:'left',
                                        align: 'left'
                                        },
                                        scales: {
                                            y: { 
                                              beginAtZero: true,
                                              ticks: { 
                                                  font: { 
                                                      size: 25, 
                                                    } ,
                                                    color:'white'
                                                }
                                            },
                                            x: { 
                                                ticks: { 
                                                    font: { 
                                                        size: 25, 
                                                       
                                                      } ,
                                                      color:'white'
                                                  }
                                              }
                                            }
                                        }}
                                    />
                            </div>
                        </div>
                        </div>  
                         <div className="tableWrapper">
                                    <div className='tablechart' style={{background:"black"}}>
                                    <div className='firsttable'>
                                        <div className='tableheading'>
                                            
                                            <div className='heading'>Upcoming Re-Payments</div>
                                            
                                            <div className='seeall' style={{justifyContent: "right"}}>See All</div>
                                        </div>
                                        <div className='table'>
                                            <div className='tableheader'>
                                                <div style={{borderLeft:"none"}}>NBFC Name</div>
                                                <div>Invoice No.</div>
                                                <div>Amount</div>
                                                <div>Due Date</div>
                                                <div>Overdue by*</div>
                                                <div style={{borderRight:"none"}}>Status</div>
                                            </div>
                                            <div className='rows'>
                                                {
                                                    data.map((item ,count)=>{
                                                        if(count!==data.length-1)
                                                        {
                                                        return <div className='row'>
                                                            <div style={{borderLeft:"none"}}>{item.name}</div>
                                                            <div>{item.invoice}</div>
                                                            <div>{item.amount}</div>
                                                            <div>{item.dueDate}</div>
                                                            <div>{item.overdueBy}</div>
                                                            <div style={{borderRight:"none"}}>
                                                                <ButtonStyled style={{height:"40px",display:"flex",alignItem:"center"}}>{item.status}</ButtonStyled></div>
                                                            </div>
                                                        }
                                                        else{
                                                            return<div className='row1'>
                                                            <div style={{borderLeft:"none"}}>{item.name}</div>
                                                            <div>{item.invoice}</div>
                                                            <div>{item.amount}</div>
                                                            <div>{item.dueDate}</div>
                                                            <div>{item.overdueBy}</div>
                                                            <div style={{borderRight:"none"}}>
                                                                <ButtonStyled style={{height:"40px"}}>{item.status}</ButtonStyled></div>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>


                                        <div className='tableheading'>
                                            <div className='heading'>Upcoming Disbursements</div>
                                            <div className='seeall' style={{justifyContent: "right"}}>See All</div>
                                        </div>
                                        <div className='table'>
                                            <div className='tableheader'>
                                                <div style={{borderLeft:"none"}}>NBFC Name</div>
                                                <div>Invoice No.</div>
                                                <div>Amount</div>
                                                <div>Due Date</div>
                                                <div>Overdue by*</div>
                                                <div style={{borderRight:"none"}}>Status</div>
                                            </div>
                                            <div className='rows'>
                                                {
                                                    data.map((item ,count)=>{
                                                        if(count!==data.length-1)
                                                        {
                                                        return <div className='row'>
                                                            <div style={{borderLeft:"none"}}>{item.name}</div>
                                                            <div>{item.invoice}</div>
                                                            <div>{item.amount}</div>
                                                            <div>{item.dueDate}</div>
                                                            <div>{item.overdueBy}</div>
                                                            <div style={{borderRight:"none"}}>
                                                                <ButtonStyled style={{height:"40px",display:"flex",alignItem:"center"}}>{item.status}</ButtonStyled></div>
                                                            </div>
                                                        }
                                                        else{
                                                            return<div className='row1'>
                                                            <div style={{borderLeft:"none"}}>{item.name}</div>
                                                            <div>{item.invoice}</div>
                                                            <div>{item.amount}</div>
                                                            <div>{item.dueDate}</div>
                                                            <div>{item.overdueBy}</div>
                                                            <div style={{borderRight:"none"}}>
                                                                <ButtonStyled style={{height:"40px",display:"flex",alignItem:"center"}}>{item.status}</ButtonStyled></div>
                                                            </div>
                                                        }
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div>

                                    </div>
                                    </div>
                         </div>
            </div>
                </div>       
    </Style>   
)}