export const EditProfileInfo = {
  title: "Edit Profile",
  Info: "Edit your profile Here, * Marks fields are Mandatory",
};

export const OnBoardingProfileInfo = {
  title: "OnBoarding Profile Form",
  Info: "Edit Your Profile Here",
};

export const OpenTradeInfo = {
  title: "Create New Open Account Trade",
  Info: "Open new Trade account",
};
export const AccountInfoSelect = {
  title: "Select Account Details",
  Info: "Mandatory to select Provider and account details of yours",
};

export const ModifyTadeInfo = {
  title: "Modify Trade Info",
  Info: "Modify Trade Information",
};

export const AddUserInfo = {
  title: "Add User Info",
  Info: "Add User Information",
};

export const AddPaymentAccount = {
  title: "Add Payment Account",
  Info: "Add New Payment Account",
};

export const UpdatePaymentAccount = {
  title: "Update Payment Account",
  Info: "Update Payment Account",
};

export const DocumentProperties = {
  title: "DocumentProperties",
  Info: "DocumentProperties",
};

export const ShareDocuments = {
  title: "Share Documents",
  Info: "Share Documents",
};

export const SearchServicesResult = {
  title: "Services Search Results",
  Info: "List of Service Provider Companies",
};

export const createMilestone = {
  title: " ",
  Info: "List of Service Provider Companies",
};

export const SearchServices = {
  title: "Search Services",
  Info: "Search required services by selecting the country , category and sub category",
};

export const DigitalSignature = {
  title: "Digital Signature",
  Info: "Digital Signature",
};

export const CreateNewTrade = {
  title: "Create New Trade",
  Info: "Create New Trade",
};

export const Participants = {
  title: "Search Participants",
  Info: "Search Network Participants on the Tradxlink Platform",
};

export const NetworkParticipants = {
  title: "Network Participants",
  Info: "List of Companies - Financers , Traders , Service Providers , Channel Partners",
};
export const AddtoNetwork = {
  title: "Add to Network ",
  Info: "Add to Network",
};
export const searchReqrequest = {
  title: "Service Requisitions ",
  Info: "Click on Service Request ID to open",
};
export const serviceDeals = {
  title: "Service Requisitions ",
  Info: "Click on Service Deal ID to open Requisiton",
};
export const customsForm = {
  title: "CUSTOMS",
  Info: "Fill Up Details For Customs Clearance Services Requisition   ",
};
//
export const COMPLIANCE_TRADE = {
  title: "COMPLIANCE",
  Info: "Fill Up Details For Trade Compliance Requisition  ",
};
export const COMPLIANCE_IMPORT = {
  title: "COMPLIANCE",
  Info: "Fill Up Details For Compliance ( Import_Declarations ) Requisition  ",
};
export const COMPLIANCE_EXPORT = {
  title: "COMPLIANCE",
  Info: "Fill Up Details For Compliance ( Export_Declarations ) Requisition  ",
};
export const INSPECTION_QUALITY = {
  title: "INSPECTION",
  Info: "Fill Up Details For Quality ( General ) Inspection Requisition  ",
};
export const INSPECTION_OTHERS = {
  title: "INSPECTION",
  Info: "Fill Up Details For Other ( Regulatory )Inspection Requisition  ",
};
export const CUSTOMS_INS = {
  title: "CUSTOMS",
  Info: "Fill Up Details For Customs Inspection Requisition  ",
};

export const FREIGHT_IMPORT = {
  title: "FREIGHT",
  Info: "Fill Up Details For Freight Service Requisition   ",
};
export const FREIGHT_EXPORT = {
  title: "FREIGHT",
  Info: "Fill Up Details For Freight Service Requisition  ",
};
export const WAREHOUSE = {
  title: "WAREHOUSE",
  Info: "Fill Up Details For Warehousing Solution Requisition  ",
};

export const TRADE_FORM = {
  title: "TRADE_CONTRACT",
  Info: "Fill Up Details For Trade Contract  Requisition   ",
};
export const CONTAINER = {
  title: "CONTAINER",
  Info: "Fill Up Details For Container Requirement Requisition   ",
};

export const TRANSPORT = {
  title: "TRANSPORT",
  Info: "Fill Up Details For Transport Service Requisition.For Eg Truck , Maritime , Rail , Air",
};
export const addMilestone = {
  title: "ADD MILESTONE",
  Info: "ADD MILESTONE",
};
