import React from 'react';
import { useState } from 'react';
import { GiBookCover, GiHamburgerMenu } from 'react-icons/gi';
import { FaRegHandshake , FaShippingFast } from 'react-icons/fa';
import {BiRecycle} from 'react-icons/bi';
import {BsBarChartSteps} from 'react-icons/bs';
import {MdSummarize} from 'react-icons/md';
import {HiOutlineDocumentText} from 'react-icons/hi';
import {RiWechatLine} from 'react-icons/ri';
import './tradesidenav.css';
import { HiOutlineUserGroup } from "react-icons/hi";
import { fetchMemberdata } from "utils/memberchat";
import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';


const SideNav = ({globalParticipantMemberId, handleSelectedItem , handleCollepseNav , selectedItem, hanndleCraateGroup}) => {
    const [collepse, setCollepse] = useState(true);
    const handleCollepse = () => {
        if (collepse) {
            setCollepse(false);
            handleCollepseNav(false);
        }
        else {
            setCollepse(true);
            handleCollepseNav(true);
        }
    }
    const chatHandler = async (ds) => {
          let res = await fetchMemberdata(ds);
          console.log("ds",ds);
          if (res?.length > 0) {
            let name = "Global-Participant-Chat"
            hanndleCraateGroup(res, name, name)
          }
        };

    return (
        <>
            {collepse
                ?
                <>
                    <div className='collepse' style={{ height: "96.7vh" }}>
                        <div className='headerHandbugger' onClick={handleCollepse} style={{cursor:"pointer"}}>
                            <div className='headerHandbuggerDiv'>
                                {/* <GiHamburgerMenu size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Trade Lifecycle
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                   <GiHamburgerMenu size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <hr className="new1" />
                        <div className='navItemCollepse' style={selectedItem==='overview'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('overview')}>
                            <div className='navItemCollepseDiv'>
                                {/* <GiBookCover size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Overview
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                  <GiBookCover size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <hr className="new1" />
                        <div className='navItemCollepse' style={selectedItem==='shipmentSummary'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('shipmentSummary')}>
                            <div className='navItemCollepseDiv'>
                                {/* <FaShippingFast size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Tracking
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                 <FaShippingFast size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <hr className="new1" />
                        <div className='navItemCollepse' style={selectedItem==='lifeCycle'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('lifeCycle')}>
                            <div className='navItemCollepseDiv'>
                                {/* <BiRecycle size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Workflow
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                 <BiRecycle size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        {/* <hr className="new1" />
                        <div className='navItemCollepse' style={selectedItem==='stages'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('stages')}>
                            <div className='navItemCollepseDiv'>
                                <BsBarChartSteps size={15} color='white' />
                            </div>
                        </div> */}
                        <hr className="new1" />
                        <div className='navItemCollepse' style={selectedItem==='shipments'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('shipments')}>
                            <div className='navItemCollepseDiv'>
                                {/* <MdSummarize size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Summary
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                 <MdSummarize size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <hr className="new1" />
                        <div className='navItemCollepse'  style={selectedItem==='documents'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('documents')}>
                            <div className='navItemCollepseDiv'>
                                {/* <HiOutlineDocumentText size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Documents
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                 <HiOutlineDocumentText size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <hr className="new1" />
                        <div className='navItemCollepse' style={selectedItem==='globalParticipants'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('globalParticipants')}>
                            <div className='navItemCollepseDiv'>
                                {/* <HiOutlineUserGroup size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Participants
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                 <HiOutlineUserGroup size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <hr className="new1" />
                        <div className='navItemCollepse' style={selectedItem==='chat'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>{
                            handleSelectedItem('chat');
                            chatHandler(globalParticipantMemberId);
                            }}>
                            <div className='navItemCollepseDiv'>
                                {/* <RiWechatLine size={15} color='white' /> */}
                                <OverlayTrigger
                                   placement="top"
                                   overlay={
                                     <Tooltip>
                                       Chat
                                     </Tooltip>
                                    }
                                >
                                 <span>
                                 <RiWechatLine size={15} color='white' />
                                 </span>
                                </OverlayTrigger>
                            </div>
                        </div>
                        <hr className="new1" />
                    </div>
                </>
                :
                <div className='notCollepse' style={{ height: "96.7vh" }}>
                    <div className='headerHandbuggerNotCollepse' onClick={handleCollepse}>
                        <div className='headerHandbuggerNotCollepseDiv' style={{cursor:"pointer"}}>
                            <div style={{ color: "#42a5f5", fontSize:"15px", display:"flex", alignItems:"center" }}>Trade Lifecycle</div>
                            <div style={{ marginLeft: "10px" }}><GiHamburgerMenu size={15} color='white' /></div>
                        </div>
                    </div>
                    <hr className="new1" />
                    <div className='navItemNotCollepse'  style={selectedItem==='overview'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('overview')}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40%", textAlign: "center" }}><GiBookCover size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"15px", display:"flex", alignItems:"center"}}>Overview</div>
                        </div>
                    </div>
                    <hr className="new1" />
                    <div className='navItemNotCollepse' style={selectedItem==='shipmentSummary'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('shipmentSummary')}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40%", textAlign: "center" }}>< FaShippingFast size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"15px", display:"flex", alignItems:"center"}}>Tracking</div>
                        </div>
                    </div>
                    <hr className="new1" />
                    <div className='navItemNotCollepse' style={selectedItem==='lifeCycle'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('lifeCycle')}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40% ", textAlign: "center" }}><BiRecycle size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"15px", display:"flex", alignItems:"center" }}>Workflow</div>
                        </div>
                    </div>
                    <hr className="new1" />
                    {/* <div className='navItemNotCollepse' style={selectedItem==='stages'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('stages')}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40% ", textAlign: "center" }}><BsBarChartSteps size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"11px", display:"flex", alignItems:"center" }}>Stages</div>
                        </div>
                    </div>
                    <hr className="new1" /> */}
                    <div className='navItemNotCollepse' style={selectedItem==='shipments'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('shipments')}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40% ", textAlign: "center" }}><MdSummarize size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"15px", display:"flex", alignItems:"center" }}>Summary</div>
                        </div>
                    </div>
                    <hr className="new1" />
                    <div className='navItemNotCollepse' style={selectedItem==='documents'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('documents')}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40% ", textAlign: "center" }}><HiOutlineDocumentText size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"15px", display:"flex", alignItems:"center" }}>Documents</div>
                        </div>
                    </div>
                    <hr className="new1" />
                    <div className='navItemNotCollepse' style={selectedItem==='globalParticipants'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>handleSelectedItem('globalParticipants')}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40% ", textAlign: "center" }}><HiOutlineUserGroup size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"15px", display:"flex", alignItems:"center" }}>Participants</div>
                        </div>
                    </div>
                    <hr className="new1" />
                    <div className='navItemNotCollepse' style={selectedItem==='chat'?{backgroundColor:"#42a5f5"}:{}} onClick={()=>{
                        handleSelectedItem('chat')
                        chatHandler(globalParticipantMemberId)
                     }}>
                        <div className='navItemNotCollepseDiv'>
                            <div style={{ marginLeft: "10px", width: "40% ", textAlign: "center" }}><RiWechatLine size={15} color='white' /></div>
                            <div style={{ width: "60% ", fontSize:"15px", display:"flex", alignItems:"center" }}>Chat</div>
                        </div>
                    </div>
                    <hr className="new1" />
                </div>
            }
        </>
    )
}

export default SideNav;