import React, { useState, useContext } from "react";
import Loader from "components/Loader/Loader";
import useAxios from "services/useAxios";
import {
  quoteApi,
  quoteSubmitAPI,
  NetworkURL,
  loanApi,
  profileURL,
} from "services/BaseURLInstance";
import { useNavigate } from "react-router-dom";
import { ButtonStyled } from "components/Container.styled";
import Styles from "./styles";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { storeContext } from "contexts/Store/Store";
import { Grid } from "@mui/material";
import Input from "components/fields/Input";
import { UseRequest } from "utils/API/Request.js";
import Currenciesr from "../../../../../jsons/Currenciesr.json";
import Button from "components/Button";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import { CheckPermission } from "utils/Constants/RBAC";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import TextArea from "components/fields/TextArea";
import { truncateStringID } from "utils/helper";
import DealDetails from "../FinanceDetail/DealDetails";
import EscrowPayment from "pages/OpenTradeAccount/OpenTradeAccount/EscrowPayment";
import PaymentDetails from "./PaymentDetail";
import { getIdTokenAsync } from "utils/authUtil";
import { validDateInput } from "./validateInput";
import {
  Success,
  Error,
  Info,
  Alert,
} from "components/notifications/responseHandle";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import { FindCompanyName } from "utils/helper";

const Quote = ({ data, setSyndicatePage, hanndleCraateGroup, requisitionData, companyList, setIsActive }) => {
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [quote, setQuote] = useState({
    financeAmount: 0,
    requestedFinanceAmount: 0,
    interestRate: 0,
  });
  const [loanTranchesData, setLoanTranchesData] = useState([]);
  const [loanID, setLoanID] = useState();
  const { fetchCompanyList } = useContext(storeContext);
  const { instance, accounts } = useMsal();
  const [paymentDetailsScreen, setPaymentDetailsScreen] = useState(false);
  const [statusValue, setStatusValue] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setID] = useState();
  const [response, setResponse] = useState();
  const [Role, setRole] = useState(null);
  const [expressRole, setExpressRole] = useState(null);
  const [initiateRole, setInitiateRole] = useState(null);
  const [completeRole, setCompleteRole] = useState(null);
  const [createQouteRole, setQouteRole] = useState(null);
  const [accpetQouteRole, setAccepttRole] = useState(null);
  const [declineRole, setDeclineRole] = useState(null);
  const [createTC, setCreateTCRole] = useState(null);
  const [acceptTC, setAcceptTCRole] = useState(null);
  const [declineTC, setDeclineTCRole] = useState(null);
  const [abortRole, setAbortRole] = useState(null);

  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();

  const companyId = getClaim(accounts, "company_member_id");
  const navigate = useNavigate();

  const loan_product_type = [
    { value: "SYNDICATE_FINANCE", label: "SYNDICATE_FINANCE" },
    { value: "TERM_FINANCE", label: "TERM_FINANCE" },
  ];
  const payment_mode = [
    { value: "ESCROW", label: "ESCROW" },
    { value: "DIRECT", label: "DIRECT" },
    { value: "OFFLINE", label: "OFFLINE" },
  ];

  const getQuoteData = () => {
    setIsLoading(true);
    let URL = `${quoteSubmitAPI}finance/deals/${data.Data.financeDealId}`;
    fetchCompanyList(URL, idToken)
      .then((data) => {
        setIsLoading(false);
        setQuote(data);
        setResponse(data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getQuoteData();
  }, []);
  
  useEffect(() => {
    if (response?.financeDealId !== undefined) {
      getExistingLoan(response);
    }
    if (response?.financeProductCategory === "SYNDICATE_FINANCE") {
      data.setSydicateMenu("syndicatelending");
    }
  }, [response]);

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setExpressRole(
        CheckPermission("Requisition", "FinanceDeals", "ExpressInterest", Role)
      );
      setInitiateRole(
        CheckPermission(
          "Requisition",
          "FinanceDeals",
          "InitiateDueDiligence",
          Role
        )
      );
      setCompleteRole(
        CheckPermission(
          "Requisition",
          "FinanceDeals",
          "CompleteDueDiligence",
          Role
        )
      );
      setQouteRole(
        CheckPermission("Requisition", "FinanceDeals", "CreateQuote", Role)
      );
      setAccepttRole(
        CheckPermission("Requisition", "FinanceDeals", "AcceptQuote", Role)
      );
      setDeclineRole(
        CheckPermission("Requisition", "FinanceDeals", "DeclineQuote", Role)
      );
      setCreateTCRole(
        CheckPermission("Requisition", "FinanceDeals", "CreateT&C", Role)
      );
      setAcceptTCRole(
        CheckPermission("Requisition", "FinanceDeals", "Acceptt&C", Role)
      );
      setDeclineTCRole(
        CheckPermission("Requisition", "FinanceDeals", "Declinet&C", Role)
      );
      setAbortRole(
        CheckPermission("Requisition", "FinanceDeals", "Abort", Role)
      );
    }
  }, [Role]);

  const fetchData = async (companyId, id2, name) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        setMemberList(nData);
        hanndleCraateGroup(nData, name, name);
      });
  };

  const chatHandler = (Data) => {
    if (Data?.financerMemberUID !== "" && Data?.traderMemberUID !== "") {
      setItem(Data);
      let name = Data.financeDealId
        ? truncateStringID(Data.financeDealId, 7, "FD")
        : "NA";
      fetchData(Data?.financerMemberUID, Data?.traderMemberUID, name);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "financeAmount") {
      let requestFinanceAmountTemp = parseInt(quote.requestedFinanceAmount);
      let financeAmountTemp = parseInt(
        e.target.value === "" ? "0" : e.target.value
      );
      if (financeAmountTemp <= requestFinanceAmountTemp) {
        let val = e.target.value;
        if (val <= 0) {
          Error("Required Finance Amount can not be less or equal to 0");
          setQuote({
            ...quote,
            [e.target.name]: "",
          });
        } else {
          setQuote({
            ...quote,
            [e.target.name]: val,
          });
        }
      } else {
        alert("Finance amount can't be greater then Requested finance amount");
      }
    } else if (e.target.name === "interestRate") {
      let val = e.target.value;
      if (val <= 0) {
        Error("Interest rate should not be less or equal to 0");
        setQuote({
          ...quote,
          [e.target.name]: "",
        });
      } else {
        setQuote({
          ...quote,
          [e.target.name]: val,
        });
      }
    } else if (
      e.target.name !== "interestRate" &&
      e.target.name !== "financeAmount"
    ) {
      let val = e.target.value;
      setQuote({
        ...quote,
        [e.target.name]: val,
      });
    }
  };

  const handleDateChange = (e, name) => {
    if(new Date(e).toISOString()>requisitionData.financeRequsitionEndDate){
      Error("Deal expiry date should not be greater then requisition end date");
    }
    else{
    setQuote({
      ...quote,
      [name]: e,
    });
  }
  };

  const createLoan = async () => {
    setIsLoading(true);
    let nData = quote;

    const putData = {
      createdBy: quote.createdBy,
      createdDate: quote.createdDate,
      financeCurrency: quote.financeCurrency,
      financeProductCategory: quote.financeProductCategory,
      financeProductType: quote.financeProductType,
      financerMemberUID: quote.financerMemberUID,
      interestRate: quote.interestRate,
      lastUpdateBy: quote.lastUpdateBy,
      lastUpdatedDate: quote.lastUpdatedDate,
      paymentMethodType: quote.paymentMethodType,
      //refFinanceRequisitionId: quote.refFinanceRequisitionId,
      financeDealId: quote.financeDealId,
      traderMemberUID: quote.traderMemberUID,
      loanStatus: "DRAFT",
      //Missing element
      loanReferenceId: quote.loanReferenceId,
      referenceFinanceInstrumentId: quote.referenceFinanceInstrumentId,
      paymentCurrencyType: "FIAT",
      financeDisbursalDate: quote.financeDisbursalDate,
      financeRequisitionId: quote.refFinanceRequisitionId,
    };
    putData["financeDisbursalStatus"] = "DISBURSEMENT_DUE";
    putData["financeRePaymentStatus"] = "REPAYMENT_BLOCKED";

    let loanTranches = [
      // {
      //     "traderMemberUID": quote.traderMemberUID,
      //     "financerMemberUID": quote.financerMemberUID
      // }
    ];
    putData["loanTranches"] = loanTranches;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(putData),
    };

    await fetch(`${loanApi}loans`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        setQuote(data);
        getQuoteData();
        getLoanByID(data);
        setIsLoading(false);
        setTimeout(window.location.reload(), 3000)
        // Line commented -
        // props.data.setSydicateMenu("syndicatelending");
        // props.data.setSyndicate(data)
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleAcceptQuote = async (e, value) => {
    let putData = { ...quote };
    const valid = validDateInput(putData);
    if (valid) {
      Error(valid);
    } else {
      if (putData.financeProductCategory === "SYNDICATE_FINANCE") {
        setSyndicatePage(true);
      }
      putData.FinancerAccount = value.paymentData
        ? value.paymentData.FinancerAccount
        : "";
      putData.CompanyAccount = value.paymentData
        ? value.paymentData.CompanyAccount
        : "";
      putData.PaymentProviderNew = value.paymentData
        ? value.paymentData.PaymentProvider
        : "";
      putData.financeDealStatus = value.financeDealStatus;
      putData.lastUpdatedDate = new Date().toISOString();
      putData.lastUpdateBy = companyId;
      setIsLoading(true);
      setPaymentDetailsScreen(false);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(putData),
      };
      await fetch(
        `${quoteSubmitAPI}finance/deals/${data?.Data?.financeDealId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false);
          getQuoteData();
          setQuote(data);
          if (value?.financeDealStatus === "INTEREST_EXPRESSED") {
            Success("Interest Expressed Successfully");
          }
          if (value?.financeDealStatus === "DUE_DILIGENCE_INITIATED") {
            Success("Due Diligence Initiated Successfully");
          }
          if (value?.financeDealStatus === "DUE_DILIGENCE_COMPLETED") {
            Success("Due Diligence Completed Successfully");
          }
          if (value?.financeDealStatus === "QUOTE_GENERATED") {
            Success("Quote Created Successfully");
          }
          if (value?.financeDealStatus === "QUOTE_ACCEPTED") {
            Success("Quote Accepted Successfully");
          }
          if (value?.financeDealStatus === "PENDING_T_AND_C_ACCEPTANCE") {
            Success("T&C Uploaded Successfully");
          }
          if (value?.financeDealStatus === "FINANCE_AGREEMENT_COMPLETE") {
            Success("T&C Accepted Successfully");
          }
          if (value?.financeDealStatus === "DECLINED") {
            Success("Finance Requisition Declined");
          }
          if (value?.financeDealStatus === "QUOTE_DECLINED") {
            Success("Quote Declined");
          }
          if (
            value?.financeDealStatus === "DUE_DILIGENCE_INITIATED" ||
            value?.financeDealStatus === "DUE_DILIGENCE_COMPLETED" ||
            value?.financeDealStatus === "DECLINED" ||
            value?.financeDealStatus === "QUOTE_GENERATED" ||
            value?.financeDealStatus === "QUOTE_ACCEPTED" ||
            value?.financeDealStatus === "QUOTE_DECLINED" ||
            value?.financeDealStatus === "PENDING_T_AND_C_ACCEPTANCE" ||
            value?.financeDealStatus === "FINANCE_AGREEMENT_COMPLETE"
          ) {
            //updateLoan()
          } else {
            if (quote.financeProductCategory !== "TERM_FINANCE") {
              createLoan();
            }
          }
          setIsActive("Requsition")
          // setTimeout(window.location.reload(), 3000)
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const handleAcceptQuoteDecline = async (e, value) => {
    let putData = quote;
    const valid = validDateInput(putData);
    if (valid) {
      Error(valid);
    } else {
      if (putData.financeProductCategory === "SYNDICATE_FINANCE") {
        setSyndicatePage(true);
      }
      putData.FinancerAccount = value.paymentData
        ? value.paymentData.FinancerAccount
        : "";
      putData.CompanyAccount = value.paymentData
        ? value.paymentData.CompanyAccount
        : "";
      putData.PaymentProviderNew = value.paymentData
        ? value.paymentData.PaymentProvider
        : "";
      putData.financeDealStatus = value.financeDealStatus;
      putData.lastUpdatedDate = new Date().toISOString();
      putData.lastUpdateBy = companyId;
      setIsLoading(true);
      setPaymentDetailsScreen(false);
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(putData),
      };
      await fetch(
        `${quoteSubmitAPI}finance/deals/${data?.Data?.financeDealId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false);
          getQuoteData();
          setQuote(data);
          if (value?.financeDealStatus === "QUOTE_ACCEPTED") {
            Success("T&C Declined");
          }
          if (
            value?.financeDealStatus === "DUE_DILIGENCE_INITIATED" ||
            value?.financeDealStatus === "DUE_DILIGENCE_COMPLETED" ||
            value?.financeDealStatus === "DECLINED" ||
            value?.financeDealStatus === "QUOTE_GENERATED" ||
            value?.financeDealStatus === "QUOTE_ACCEPTED" ||
            value?.financeDealStatus === "QUOTE_DECLINED" ||
            value?.financeDealStatus === "PENDING_T_AND_C_ACCEPTANCE" ||
            value?.financeDealStatus === "FINANCE_AGREEMENT_COMPLETE"
          ) {
            //updateLoan()
          } else {
            if (quote.financeProductCategory !== "TERM_FINANCE") {
              createLoan();
            }
          }
          setTimeout(window.location.reload(), 3000)
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };
  const getExistingLoan = async (ele) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${quoteSubmitAPI}loans?financeDealId=${ele.financeDealId}`,
      requestOptions
    )
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(false);
        let nData = data;
        setID(nData[0]?.loanReferenceId);
        if (nData?.length > 0 && nData?.loanReferenceId !== "") {
          getLoanByID(nData[0]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getLoanByID = async (ele) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(`${quoteSubmitAPI}loans/${ele.loanReferenceId}`, requestOptions)
      .then((res) => res.json())
      .then((res) => {
        setIsLoading(false);
        let nData = res;
        setLoanTranchesData(nData?.loanTranches);
        data.setSydicateMenu("syndicatelending");
        data.setSyndicate(nData);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const loanCreate = {
    financeRequisitionId: "",
    traderMemberUID: "",
    financerMemberUID: "",
    loanStatus: "DRAFT",
    financeProductType: "",
    financeProductCategory: "",
    referenceFinanceInstrumentId: "",
    paymentCurrencyType: "",
    paymentMethodType: "",
    financeCurrency: "",
    interestRate: "",
    financeAmount: "",
    financeDisbursalDate: "",
    financeDisbursalStatus: "PENDING_INITIATION",
    financeRepaymentAmount: "",
    financeRepaymentDate: "",
    financeRePaymentStatus: "PENDING_INITIATION",
    loanFees: "",
  };
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  console.log("memberType",memberType)

  const handleBookLoan = async (e, value) => {
    setIsLoading(true);
    loanCreate.financeCurrency = quote.financeCurrency;
    loanCreate.financeDisbursalDate = quote.financeDisbursalDate;
    loanCreate.financeRequisitionId = quote.refFinanceRequisitionId;
    loanCreate.financerMemberUID = quote.financerMemberUID;
    loanCreate.traderMemberUID = quote.traderMemberUID;
    loanCreate.interestRate = quote.interestRate;
    loanCreate.financeDealId = quote.financeDealId;
    loanCreate.referenceFinanceInstrumentId = quote.referenceFinanceInstrumentId
      ? quote.referenceFinanceInstrumentId
      : "";
    loanCreate.requestedFinanceAmount = quote.requestedFinanceAmount;
    loanCreate.financeAmount = quote.financeAmount;
    loanCreate.loanFees = quote.loanFees;
    loanCreate.financeProductType = quote.financeProductType;
    loanCreate.financeProductCategory = quote.financeProductCategory;
    loanCreate.financeRepaymentAmount = quote.financeRepaymentAmount;
    loanCreate.financeRepaymentDate = quote.financeRepaymentDate;
    loanCreate.paymentMethodType = quote.paymentMethodType;
    loanCreate.paymentCurrencyType = quote.paymentCurrencyType;
    loanCreate.lastUpdatedDate = new Date().toISOString();
    loanCreate.lastUpdateBy = companyId;
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(loanCreate),
    };
    await fetch(`${quoteSubmitAPI}loans`, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        getQuoteData();
        setIsLoading(false);
        getLoanByID(res);
        data.setHeaderSelect("syndicatelending");
        localStorage.setItem("loanrefid", res?.loanReferenceId);
        localStorage.setItem("loanid", res?.loanId);
      })
      .catch((err) => {
        console.log("err", err);
      });
    let putData = quote;
    let financeDealId = data.financeDealId || putData.financeDealId;
    putData.financeDealStatus = value.financeDealStatus;
    requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(putData),
    };
    setIsLoading(true);
    await fetch(
      `${quoteSubmitAPI}finance/deals/${financeDealId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((responsedata) => {
        setIsLoading(false);
        setQuote(responsedata);
        getLoanByID(responsedata);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleUploadTandC = (e, value) => {
    setPaymentDetailsScreen(true);
    setStatusValue(value);
  };

  const loanUpdateSyndicate = {
    financeRequisitionId: quote.refFinanceRequisitionId,
    traderMemberUID: "",
    financerMemberUID: "",
    loanStatus: "INACTIVE",
    financeProductType: "",
    financeProductCategory: "",
    referenceFinanceInstrumentId: "476e09bd-cb60-4d77-b294-3741f18ae10d",
    paymentCurrencyType: "FIAT",
    paymentMethodType: "ACCOUNT",
    financeCurrency: "INR",
    interestRate: "5",
    financeAmount: "1200",
    financeDisbursalDate: "",
    financeDisbursalStatus: "PENDING_INITIATION",
    financeRepaymentAmount: "12",
    financeRepaymentDate: "",
    financeRePaymentStatus: "PENDING_INITIATION",
    loanFees: "123",
  };

  const handleSyndicateBookLoan = async () => {
    loanUpdateSyndicate.financeRequisitionId = quote.refFinanceRequisitionId;
    loanUpdateSyndicate.financerMemberUID = quote.financerMemberUID;
    loanUpdateSyndicate.traderMemberUID = quote.traderMemberUID;
    loanUpdateSyndicate.interestRate = quote.interestRate;
    loanUpdateSyndicate.financeDealId = quote.financeDealId;
    loanUpdateSyndicate.referenceFinanceInstrumentId =
      quote.referenceFinanceInstrumentId
        ? quote.referenceFinanceInstrumentId
        : "";
    loanUpdateSyndicate.requestedFinanceAmount = quote.requestedFinanceAmount;
    loanUpdateSyndicate.financeAmount = quote.financeAmount;
    loanCreate.loanFees = quote.loanFees;
    loanUpdateSyndicate["loanTranches"] = loanTranchesData;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(loanUpdateSyndicate),
    };
    await fetch(`${quoteSubmitAPI}loans/${id}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // props.data.setSydicateMenu("syndicatelending");
        // props.data.setSyndicate(data)
        setQuote(data);
        getLoanByID(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div
      style={{
        backgroundColor: "black",
        padding: "2%",
        marginTop: "1%",
        borderRadius: "5px",
        paddingBottom:"0px",
      }}>
      {isLoading ? (
        <Loader />
      ) : paymentDetailsScreen ? (
        <PaymentDetails
          handleAcceptQuote={handleAcceptQuote}
          userType={companyList.userType}
          setPaymentDetailsScreen={setPaymentDetailsScreen}
          quoteData={quote}
          currentStatus={statusValue}
        />
      ) : (
        <>
          {Object.keys(quote).length > 3 && companyList.companies && (
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div
                  style={{ display: "flex", width: "49%", marginRight: "2%" }}>
                  <Input
                    name="financeDealId"
                    placeholder="Deal Id *"
                    value={
                      quote.financeDealId
                        ? truncateStringID(quote.financeDealId, 7, "FD")
                        : "NA"
                    }
                    handleChange={handleChange}
                    readOnly={true}
                  />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                  {companyList.companies.map((item) => {
                    if (item.memberUID === quote.traderMemberUID) {
                      return (
                        <Input
                          name="traderMemberUID"
                          placeholder="Seller ID"
                          value={item.memberName}
                          handleChange={handleChange}
                          readOnly={true}
                          required={true}
                        />
                      );
                    }
                  })}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div style={{ width: "49%", marginRight: "2%" }}>
                  <Select
                    options={loan_product_type}
                    name="financeProductCategory"
                    placeholder="Loan Product Category"
                    handleChange={
                      quote.financeDealStatus === "NEW" ? handleChange : null
                    }
                    value={quote.financeProductCategory}
                    required={true}
                    readOnly={quote.financeDealStatus === "NEW" ? false : true}
                  />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                  <Input
                    name="Loan Product Type"
                    placeholder="Loan Product Type *"
                    value={quote.financeProductType}
                    readOnly={true}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div style={{ width: "49%", marginRight: "2%" }}>
                  <Select
                    options={payment_mode}
                    name="modeOfPayment"
                    placeholder="Payment Mode"
                    handleChange={handleChange}
                    value={quote?.modeOfPayment}
                    required={true}
                    readOnly={true}
                  />
                </div>
                <div
                  style={{ display: "flex", width: "49%" }}
                  onClick={() =>
                    navigate(
                      `../../invoice/invoices/${quote.referenceFinanceInstrumentId}`
                    )
                  }>
                  <Input
                    name="referenceFinanceInstrumentId"
                    placeholder="Invoice Reference"
                    value={
                      quote.referenceFinanceInstrumentId
                        ? truncateStringID(
                            quote.referenceFinanceInstrumentId,
                            7,
                            "IR"
                          )
                        : "NA"
                    }
                    handleChange={handleChange}
                    readOnly={true}
                    required={true}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div style={{ width: "49%", marginRight: "2%" }}>
                  {companyList.userType === "FINANCER" &&
                  quote.financeDealStatus !== "QUOTE_ACCEPTED" &&
                  quote.financeDealStatus !== "QUOTE_GENERATED" &&
                  quote.financeDealStatus !== "ABORTED" &&
                  quote.financeDealStatus !== "PENDING_T_AND_C_ACCEPTANCE" &&
                  quote.financeDealStatus !== "FINANCE_AGREEMENT_COMPLETE" &&
                  quote.financeDealStatus !== "FINANCED" ? (
                    <Input
                      name="financeAmount"
                      placeholder="Finance Amount"
                      value={
                        quote.financeAmount
                          ? quote.financeAmount.toString()
                          : ""
                      }
                      handleChange={handleChange}
                      type="number"
                      required={true}
                    />
                  ) : (
                    <Input
                      name="financeAmount"
                      placeholder="Finance Amount"
                      value={
                        quote.financeAmount
                          ? quote.financeAmount.toString()
                          : ""
                      }
                      handleChange={handleChange}
                      type="number"
                      required={true}
                      readOnly={true}
                    />
                  )}
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                  {companyList.userType === "FINANCER" &&
                  quote.financeDealStatus !== "QUOTE_ACCEPTED" &&
                  quote.financeDealStatus !== "QUOTE_GENERATED" &&
                  quote.financeDealStatus !== "ABORTED" &&
                  quote.financeDealStatus !== "PENDING_T_AND_C_ACCEPTANCE" &&
                  quote.financeDealStatus !== "FINANCE_AGREEMENT_COMPLETE" &&
                  quote.financeDealStatus !== "FINANCED" ? (
                    <Input
                      name="interestRate"
                      placeholder="Interest Rate Per Annum"
                      value={quote.interestRate}
                      handleChange={handleChange}
                      required={true}
                      type="number"
                    />
                  ) : (
                    <Input
                      name="interestRate"
                      placeholder="Interest Rate Per Annum"
                      value={quote.interestRate}
                      handleChange={handleChange}
                      required={true}
                      type="number"
                      readOnly={true}
                    />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div style={{ width: "49%", marginRight: "2%" }}>
                  <Input
                    name="requestedFinanceAmount"
                    placeholder="Requested Finance Amount"
                    value={
                      quote.requestedFinanceAmount
                        ? quote.requestedFinanceAmount.toString()
                        : ""
                    }
                    handleChange={handleChange}
                    required={true}
                    readOnly={true}
                  />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                  <Input
                    name="financeDealStatus"
                    placeholder="Finance Status"
                    value={quote.financeDealStatus}
                    handleChange={handleChange}
                    readOnly={true}
                    required={true}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div style={{ width: "49%", marginRight: "2%" }}>
                  <Input
                    name="paymentMethodType"
                    placeholder="Payment Method"
                    value={quote.paymentMethodType}
                    handleChange={handleChange}
                    readOnly={true}
                    required={true}
                  />
                </div>
                <div style={{ display: "flex", width: "49%" }}>
                  <Select
                    options={Currenciesr}
                    name="financeCurrency"
                    value={quote.financeCurrency}
                    placeholder=" Currency"
                    handleChange={handleChange}
                    required={true}
                    readOnly={true}
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div style={{ width: "49%", marginRight: "2%" }}>
                  {companyList.companies.map((item) => {
                    if (item.memberUID === quote.financerMemberUID) {
                      return (
                        <Input
                          name="financerMemberUID"
                          placeholder="Financer Id"
                          value={item.memberName}
                          handleChange={handleChange}
                          readOnly={true}
                          required={true}
                        />
                      );
                    }
                  })}
                </div>
                <div style={{ width: "49%" }}>
                  {companyList.userType === "FINANCER" &&
                  quote.financeDealStatus !== "QUOTE_ACCEPTED" &&
                  quote.financeDealStatus !== "QUOTE_GENERATED" &&
                  quote.financeDealStatus !== "ABORTED" &&
                  quote.financeDealStatus !== "PENDING_T_AND_C_ACCEPTANCE" &&
                  quote.financeDealStatus !== "FINANCE_AGREEMENT_COMPLETE" &&
                  quote.financeDealStatus !== "FINANCED" ? (
                    <DateInput
                      name="financeDealExpiryDate"
                      placeholder="Deal Expiry Date *"
                      value={quote.financeDealExpiryDate}
                      handleChange={(e) =>
                        handleDateChange(e, "financeDealExpiryDate")
                      }
                    />
                  ) : (
                    <DateInput
                      name="financeDealExpiryDate"
                      placeholder="Deal Expiry Date *"
                      value={quote.financeDealExpiryDate}
                      handleChange={(e) =>
                        handleDateChange(e, "financeDealExpiryDate")
                      }
                      readOnly={true}
                    />
                  )}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1%",
                }}>
                <div style={{ width: "49%", marginRight: "2%" }}>
                  {companyList.userType === "FINANCER" &&
                  quote.financeDealStatus !== "QUOTE_ACCEPTED" &&
                  quote.financeDealStatus !== "QUOTE_GENERATED" &&
                  quote.financeDealStatus !== "ABORTED" &&
                  quote.financeDealStatus !== "PENDING_T_AND_C_ACCEPTANCE" &&
                  quote.financeDealStatus !== "FINANCE_AGREEMENT_COMPLETE" &&
                  quote.financeDealStatus !== "FINANCED" ? (
                    <DateInput
                      name="financeDisbursalDate"
                      placeholder="Finance Date *"
                      value={quote.financeDisbursalDate}
                      handleChange={(e) =>
                        handleDateChange(e, "financeDisbursalDate")
                      }
                    />
                  ) : (
                    <DateInput
                      name="financeDisbursalDate"
                      placeholder="Disbursement Date *"
                      value={quote.financeDisbursalDate}
                      handleChange={(e) =>
                        handleDateChange(e, "financeDisbursalDate")
                      }
                      readOnly={true}
                    />
                  )}
                </div>
                <div style={{ width: "49%" }}>
                  {companyList.userType === "FINANCER" &&
                  quote.financeDealStatus !== "QUOTE_ACCEPTED" &&
                  quote.financeDealStatus !== "ABORTED" &&
                  quote.financeDealStatus !== "QUOTE_GENERATED" &&
                  quote.financeDealStatus !== "PENDING_T_AND_C_ACCEPTANCE" &&
                  quote.financeDealStatus !== "FINANCE_AGREEMENT_COMPLETE" &&
                  quote.financeDealStatus !== "FINANCED" ? (
                    <DateInput
                      name="financeRepaymentDate"
                      placeholder="Repayment Date *"
                      value={quote.financeRepaymentDate}
                      handleChange={(e) =>
                        handleDateChange(e, "financeRepaymentDate")
                      }
                    />
                  ) : (
                    <DateInput
                      name="financeRepaymentDate"
                      placeholder="Repayment Date *"
                      value={quote.financeRepaymentDate}
                      handleChange={(e) =>
                        handleDateChange(e, "financeRepaymentDate")
                      }
                      readOnly={true}
                    />
                  )}
                </div>
              </div>
            </div>
          )}

          {companyList.companies && quote && (
            <div style={{ margin: "1%", color: "#ffc107" }}>
              <CreatedByLabel
                createdBy={
                  quote?.createdBy &&
                  companyList &&
                  FindCompanyName(companyList.companies, quote.createdBy)
                }
                createdDate={new Date(quote?.createdDate).toLocaleDateString()}
                lastUpdateBy={
                  quote?.lastUpdateBy &&
                  companyList &&
                  FindCompanyName(companyList.companies, quote.lastUpdateBy)
                }
                lastUpdatedDate={new Date(
                  quote?.lastUpdatedDate
                ).toLocaleDateString()}
              />
            </div>
          )}
          {quote.financeDealStatus === "QUOTE_ACCEPTED" &&
            companyList.userType === "FINANCER" && (
              <div
                style={{
                  color: "lightGreen",
                  padding: "1%",
                  fontSize: "16px",
                  marginTop: "1rem",
                }}>
                Before clicking on Confirm T&C kindly upload relevant documents
                in Documents tab
              </div>
            )}
          {quote.financeDealStatus === "FINANCED" && (
            <div
              style={{
                color: "lightGreen",
                padding: "1%",
                fontSize: "16px",
                marginTop: "1rem",
              }}>
              Kindly Proceed to Loans Tab to disburse the Loan
            </div>
          )}
          <Styles>
            {quote.financeDealStatus === "NEW" && (
              <div className="requsitionButtonDiv">
                {companyList.userType === "FINANCER" && (
                  <div>
                    <ButtonStyled
                      onClick={(e) =>
                        expressRole === "CREATE"
                          ? handleAcceptQuote(e, {
                              financeDealStatus: "INTEREST_EXPRESSED",
                            })
                          : " "
                      }>
                      Show Interest
                    </ButtonStyled>
                    <ButtonStyled
                      onClick={(e) =>
                        handleAcceptQuote(e, { financeDealStatus: "DECLINED" })
                      }>
                      Decline
                    </ButtonStyled>
                  </div>
                )}
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>Request More Info</ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeDealStatus === "INTEREST_EXPRESSED" && (
              <div className="requsitionButtonDiv">
                {companyList.userType === "FINANCER" && (
                  <div>
                    <ButtonStyled
                      onClick={(e) =>
                        initiateRole === "CREATE"
                          ? handleAcceptQuote(e, {
                              financeDealStatus: "DUE_DILIGENCE_INITIATED",
                            })
                          : " "
                      }>
                      Initiate Due Diligence
                    </ButtonStyled>
                    <ButtonStyled
                      onClick={(e) =>
                        handleAcceptQuote(e, { financeDealStatus: "DECLINED" })
                      }>
                      Decline
                    </ButtonStyled>
                  </div>
                )}
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeDealStatus === "DUE_DILIGENCE_INITIATED" && (
              <div className="requsitionButtonDiv">
                {companyList.userType === "FINANCER" && (
                  <div>
                    <ButtonStyled
                      onClick={(e) =>
                        completeRole === "CREATE"
                          ? handleAcceptQuote(e, {
                              financeDealStatus: "DUE_DILIGENCE_COMPLETED",
                            })
                          : " "
                      }>
                      Complete Due Diligence
                    </ButtonStyled>
                    <ButtonStyled
                      onClick={(e) =>
                        handleAcceptQuote(e, { financeDealStatus: "DECLINED" })
                      }>
                      Decline
                    </ButtonStyled>
                  </div>
                )}
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeDealStatus === "DUE_DILIGENCE_COMPLETED" && (
              <div className="requsitionButtonDiv">
                {companyList.userType === "FINANCER" && (
                  <div>
                    <ButtonStyled
                      onClick={(e) =>
                        createQouteRole === "CREATE"
                          ? handleAcceptQuote(e, {
                              financeDealStatus: "QUOTE_GENERATED",
                            })
                          : " "
                      }>
                      Create Quote
                    </ButtonStyled>
                  </div>
                )}
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeDealStatus === "QUOTE_GENERATED" && (
              <div className="requsitionButtonDiv">
                {companyList.userType === "COMPANY" && (
                  <div>
                    <ButtonStyled
                      onClick={(e) =>
                        accpetQouteRole === "CREATE"
                          ? handleAcceptQuote(e, {
                              financeDealStatus: "QUOTE_ACCEPTED",
                            })
                          : " "
                      }>
                      Accept Quote
                    </ButtonStyled>
                    <ButtonStyled
                      onClick={(e) =>
                        declineRole === "CREATE"
                          ? handleAcceptQuote(e, {
                              financeDealStatus: "QUOTE_DECLINED",
                            })
                          : " "
                      }>
                      Decline Quote
                    </ButtonStyled>
                  </div>
                )}
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeDealStatus === "QUOTE_DECLINED" && (
              <div className="requsitionButtonDiv">
                {companyList.userType === "FINANCER" && (
                  <div>
                    <ButtonStyled
                      onClick={(e) =>
                        handleAcceptQuote(e, {
                          financeDealStatus: "QUOTE_GENERATED",
                        })
                      }>
                      Revise Quote
                    </ButtonStyled>
                    <ButtonStyled
                      onClick={(e) =>
                        handleAcceptQuote(e, { financeDealStatus: "DECLINED" })
                      }>
                      Decline
                    </ButtonStyled>
                  </div>
                )}
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeProductCategory !== "SYNDICATE_FINANCE" &&
              quote.financeDealStatus === "QUOTE_ACCEPTED" && (
                <div className="requsitionButtonDiv">
                  {companyList.userType === "FINANCER" && (
                    <div>
                      <ButtonStyled
                        onClick={(e) =>
                          handleUploadTandC(e, "PENDING_T_AND_C_ACCEPTANCE")
                        }>
                        Confirm T&C
                      </ButtonStyled>
                    </div>
                  )}
                  {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                  <ButtonStyled
                    onClick={() => chatHandler(quote)}
                    style={{ cursor: "pointer" }}>
                    Request More Info
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={(e) =>
                      abortRole === "CREATE"
                        ? handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                        : " "
                    }>
                    Abort
                  </ButtonStyled>
                </div>
              )}
            {quote.financeProductCategory === "SYNDICATE_FINANCE" &&
              quote.financeDealStatus === "QUOTE_ACCEPTED" && (
                <div className="requsitionButtonDiv">
                  {companyList.userType === "FINANCER" && (
                    <div>
                      <ButtonStyled
                        onClick={(e) =>
                          handleAcceptQuote(e, {
                            financeDealStatus: "PENDING_T_AND_C_ACCEPTANCE",
                          })
                        }>
                        Confirm T&C ---
                      </ButtonStyled>
                    </div>
                  )}
                  {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                  <ButtonStyled
                    onClick={() => chatHandler(quote)}
                    style={{ cursor: "pointer" }}>
                    Request More Info
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={(e) =>
                      abortRole === "CREATE"
                        ? handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                        : " "
                    }>
                    Abort
                  </ButtonStyled>
                </div>
              )}
            {quote.financeDealStatus === "PENDING_T_AND_C_ACCEPTANCE" && (
              <div className="requsitionButtonDiv">
                {companyList.userType === "COMPANY" && (
                  <div>
                    <ButtonStyled
                      onClick={(e) =>
                        declineTC === "CREATE"
                          ? handleAcceptQuoteDecline(e, {
                              financeDealStatus: "QUOTE_ACCEPTED",
                            })
                          : " "
                      }>
                      Decline T&C
                    </ButtonStyled>
                    <ButtonStyled
                      onClick={(e) =>
                        acceptTC === "CREATE"
                          ? handleUploadTandC(e, "FINANCE_AGREEMENT_COMPLETE")
                          : " "
                      }>
                      Accept T&C
                    </ButtonStyled>
                  </div>
                )}
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote?.financeProductCategory !== "SYNDICATE_FINANCE" &&
              quote.financeDealStatus === "FINANCE_AGREEMENT_COMPLETE" && (
                <div className="requsitionButtonDiv">
                  {companyList.userType === "FINANCER" && (
                    <div>
                      <ButtonStyled
                        onClick={(e) =>
                          handleBookLoan(e, { financeDealStatus: "FINANCED" })
                        }>
                        Book Loan
                      </ButtonStyled>
                    </div>
                  )}
                  {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                  <ButtonStyled
                    onClick={() => chatHandler(quote)}
                    style={{ cursor: "pointer" }}>
                    Request More Info
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={(e) =>
                      abortRole === "CREATE"
                        ? handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                        : " "
                    }>
                    Abort
                  </ButtonStyled>
                </div>
              )}
            {quote?.financeProductCategory === "SYNDICATE_FINANCE" &&
              quote.financeDealStatus === "FINANCE_AGREEMENT_COMPLETE" && (
                <div className="requsitionButtonDiv">
                  {companyList.userType === "FINANCER" && (
                    <div>
                      <ButtonStyled
                        onClick={(e) =>
                          handleSyndicateBookLoan(e, {
                            financeDealStatus: "ACTIVE",
                          })
                        }>
                        Book Loan
                      </ButtonStyled>
                    </div>
                  )}
                  {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                  <ButtonStyled
                    onClick={() => chatHandler(quote)}
                    style={{ cursor: "pointer" }}>
                    Request More Info
                  </ButtonStyled>
                  <ButtonStyled
                    onClick={(e) =>
                      abortRole === "CREATE"
                        ? handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                        : " "
                    }>
                    Abort
                  </ButtonStyled>
                </div>
              )}
            {quote.financeDealStatus === "FINANCED" && (
              <div className="requsitionButtonDiv">
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                  Request More Info
                </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeDealStatus === "DECLINED" && (
              <div className="requsitionButtonDiv">
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
                <ButtonStyled
                  onClick={(e) =>
                    abortRole === "CREATE"
                      ? handleAcceptQuote(e, { financeDealStatus: "ABORTED" })
                      : " "
                  }>
                  Abort
                </ButtonStyled>
              </div>
            )}
            {quote.financeDealStatus === "ABORTED" && (
              <div className="requsitionButtonDiv">
                {/* <ButtonStyled onClick={handleBackToFinanceDeal}>
                    Request More Info
                  </ButtonStyled> */}
                <ButtonStyled
                  onClick={() => chatHandler(quote)}
                  style={{ cursor: "pointer" }}>
                  Request More Info
                </ButtonStyled>
              </div>
            )}
          </Styles>
        </>
      )}
    </div>
  );
};

export default Quote;
