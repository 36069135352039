import React, {useState} from 'react';
import Screen from './forgotPassword.styled';
import {MdEmail} from "react-icons/md";
import {FiLock} from "react-icons/fi";
import {FaFacebookF} from "react-icons/fa";
import {BsTwitter} from "react-icons/bs";
import {ImGooglePlus} from "react-icons/im";
import { useNavigate } from 'react-router-dom';


const ForgotPassword = ()=>{

    const navigate=useNavigate();
    const [user,setUser]=useState({
        email:"",
        password:""
    });
    const handleChange=(e)=>{
        e.preventDefault();
        const {name,value}=e.target;
        setUser({
            ...user,
            [name]:value
        })
     }
     const handleNext=()=>{
        if(user.email==="admin")
        {
        navigate('/forgotpassword-otp')
        }
        else{
            alert("Please enter correct email or phone");
        }
     }

    return(
       <Screen>
            <div className='header'>TRADXLINK</div>
            <div className='subHeader'>Seller</div>
            <div className='loginHeading'>Forgot Password ?</div>
            <div className='email'>
                <label For="email"><MdEmail/></label>
                <input type="email" name="email" placeholder="Email or Phone" value={user.email} onChange={handleChange} />
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <button className='nextbutton' onClick={handleNext}>next</button>
       </Screen>
        
    )
}
export default ForgotPassword;