import { useEffect, useState, useContext } from "react";
import Wrapper from "components/UI/Layout/Wrapper";
import Navbar from "components/UI/Navbar/Navbar";
import { Routes, Route, Navigate } from "react-router-dom";
import Overview from "./Overview/index";
import Invoices from "./Invoices";
import FinanceDeals from "./FinanceDeals";
import FinanceDetails from "./FinanceDeals/FinanceDetail";
import Loans from "./Loans";
import LoanDetails from "./Loans/LoanDetail";
import InvoiceDetails from "../SavedInvoiceDetails/index";
import { InvoiceTab, InvoiceTabRestrict, invoiceTabForFinancer } from "utils/Section/InvoiceSection";
import { CheckPermission } from "utils/Constants/RBAC";
import Loader from "components/Loader/Loader";
import { FinanceContext } from "./financeProvider";
import { Suspense } from "react";
import { getUserType } from "common/hooks/getUserType";


const Index = ({ hanndleCraateGroup }) => {
  const {Role, companyList} = useContext(FinanceContext);
  const userType = getUserType(companyList);
  return (
    <Suspense fallback={<Loader/>}>
        <div>
          <Navbar
            tabs={
              CheckPermission("Invoice", "Overview", "getOverview", Role) ===
              "NOTAPP"
                ? InvoiceTabRestrict
                : userType==="FINANCER"? invoiceTabForFinancer: InvoiceTab
            }
          />
          <Wrapper>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  CheckPermission(
                    "Invoice",
                    "Overview",
                    "getOverview",
                    Role
                  ) === "NOTAPP" ? (
                    <Navigate to="invoices" replace={true} />
                  ) : (
                    <Navigate to="overview" replace={true} />
                  )
                }
              />
              <Route exact path="overview" element={<Overview />} />
              <Route exact path="invoices" element={<Invoices />} />
              <Route exact path="invoices/:id" element={<InvoiceDetails />} />
              <Route
                exact
                path="finance-deals"
                element={
                  <FinanceDeals hanndleCraateGroup={hanndleCraateGroup} role={Role} />
                }
              />
              <Route
                exact
                path="finance-deals/:id"
                element={
                  <FinanceDetails hanndleCraateGroup={hanndleCraateGroup} />
                }
              />
              <Route exact path="loans" element={<Loans />} />
              <Route exact path="loans/:id" element={<LoanDetails />} />
              <Route exact path="loans/*" element={<LoanDetails />} />
            </Routes>
          </Wrapper>
        </div>
        </Suspense>
  );
};

export default Index;
