import React, { useState } from "react";
import classes from "./shipmentSummary.module.css";
import { BiFlag, BiTimer } from "react-icons/bi";
import { MdTripOrigin } from "react-icons/md";
import { BsFillRecordCircleFill } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { GiCargoCrate, GiCargoShip, GiTrafficLightsRed } from "react-icons/gi";
import { IoIosContract } from "react-icons/io";
import { TiWeatherStormy } from "react-icons/ti";
import Vertical from "./Vertical";
import GoogleMap from "./GoogleMap";
import MultiStatus from "./MultiStatus";
import Content from "components/UI/Layout/Content";
import AddTrackingForm from "./AddTrackingForm";
import { useEffect } from "react";

const ShipmentSummary = ({ response, location }) => {
  const [isAddTracking, setAddTracking] = useState(false);
  const [lastValue, setLastValue] = useState(null);

  let Data = {
    target_port_code: response?.bill_of_lading?.pod, // Done
    target_port_eta: response?.bill_of_lading?.pod_actual_arrival_lt, // Done
    target_port_name: response?.bill_of_lading?.pod_name, // Done
    actual_arrival_utc:
      response?.bill_of_lading?.pol_actual_arrival_lt_from_ais,
    cntr_no: response?.bill_of_lading?.cntr_no,
    voyage_no: response?.bill_of_lading?.pod,
    event_type_name: response?.bill_of_lading?.pod,
    delay_reasons: response?.bill_of_lading?.pod,
    carrier_no: response?.bill_of_lading?.carrier_no,
    pod: response?.bill_of_lading?.pod,
    pod_name: response?.bill_of_lading?.pod_name,
    pol: response?.bill_of_lading?.pol,
    pol_name: response?.bill_of_lading?.pol_name,
  };

  useEffect(() => {
    let length1 = response?.sailing_info_tracking?.length;
    if (length1 > 0) {
      let length2 =
        response?.sailing_info_tracking[length1 - 1]?.voyage_details?.length;
      let lastValue =
        response?.sailing_info_tracking[length1 - 1]?.voyage_details[
          length2 - 1
        ];
      setLastValue(lastValue);
    }
  }, [response]);

  const isDelayed = (reasons, location) => {
    if (location === "weather") {
      let check = reasons?.includes("weather");
      if (check) {
        return "red";
      } else {
        return "#218838";
      }
    }
    if (location === "traffic") {
      let check = reasons?.includes("traffic");
      if (check) {
        return "red";
      } else {
        return "#218838";
      }
    }
    if (location === "timer") {
      if (reasons?.length > 0) {
        return "red";
      } else {
        return "#218838";
      }
    }
  };

  console.log("");
  return (
    <>
      {isAddTracking ? (
        <AddTrackingForm setAddTracking={setAddTracking} />
      ) : (
        <>
          {location === "TradeSection" && (
            <div className={classes.AddTradeBtnDiv}>
              <button onClick={() => setAddTracking(true)}>Add Tracking</button>
            </div>
          )}
          <div style={{backgroundColor:"rgb(30, 30, 30)", padding:"1%"}}>
          <Content>
            <div className="d-flex justify-content-between my-2">
              <div>
                <h6>Shipping Summary</h6>
              </div>
              <div style={{ textAlign: "right" }}>
                <div>Voyage UKR : {Data.voyage_no ? Data.voyage_no : "-"}</div>
                <div>Carrier Number : {Data.carrier_no}</div>
                <div>Container Number : {Data.cntr_no}</div>
              </div>
            </div>

            <div className={classes.Steppers}>
              <div className={classes.SteppersFrom}>
                <div className={classes.fromText}>
                  <div style={{ fontSize: "16px" }}>FROM</div>
                  <div>TradCo Limited</div>
                </div>
                <div className={classes.inconDiv}>
                  <MdTripOrigin size={32} color={"#42a5f5"} />
                  <div className={classes.inconDiv1}>
                    <BiFlag size={24} />
                  </div>
                </div>
              </div>
              <div className={classes.SteppersMiddle}>
                <span>
                  {Data.pol_name}, {Data.pol}
                </span>
                <span>
                  {Data.pod_name}, {Data.pod}{" "}
                </span>
              </div>
              <div className={classes.SteppersTo}>
                <div className={classes.inconDiv}>
                  <BsFillRecordCircleFill size={28} color={"#42a5f5"} />
                  <div className={classes.inconDiv1}>
                    <BiFlag size={24} />
                  </div>
                </div>

                <div className={classes.toText}>
                  <div style={{ fontSize: "16px" }}>TO</div>
                  <div>Reliance Limited</div>
                </div>
              </div>
            </div>

            <div className={classes.Steppers2}>
              <div>
                <div className={classes.font10}>
                  <div style={{ marginBottom: "10px" }}>
                    <SlCalender size={24} />
                  </div>
                  <div style={{ color: "#42a5f5" }}>24 Jan, 2015</div>
                </div>
              </div>

              <div className={classes.Steppers2Middle}>
                <div>
                  <div>
                    <div>
                      <div>
                        <GiCargoShip size={24} />
                      </div>
                      <div style={{ color: "#42a5f5" }}>Sea FCL</div>
                    </div>
                  </div>

                  <div style={{ margin: "0px 30px" }}>
                    <div>
                      <div>
                        <IoIosContract size={24} />
                      </div>
                      <div style={{ color: "#42a5f5" }}>EXW</div>
                    </div>
                  </div>

                  <div>
                    <div>
                      <div>
                        <GiCargoCrate size={24} />
                      </div>
                      <div style={{ color: "#42a5f5" }}>20 FT * 10</div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className={classes.font10}>
                  <div style={{ marginBottom: "10px" }}>
                    <SlCalender size={24} />
                  </div>
                  <div style={{ color: "#42a5f5" }}>
                    <span style={{ color: "red" }}>
                      {new Date(Data.actual_arrival_utc).toLocaleDateString()}{" "}
                    </span>
                    {new Date(Data.target_port_eta).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>

            <div className={classes.footerIcon}>
              {/* {Data.event_type_name} {Data.delay_reasons} */}
              <div>
                <TiWeatherStormy
                  size={20}
                  color={isDelayed(lastValue?.delay_reasons, "weather")}
                />
              </div>
              <div>
                <GiTrafficLightsRed
                  size={20}
                  color={isDelayed(lastValue?.delay_reasons, "traffic")}
                />
              </div>
              <div>
                <BiTimer
                  size={20}
                  color={isDelayed(lastValue?.delay_reasons, "timer")}
                />
              </div>
            </div>
          </Content>
          </div>
         <div style={{backgroundColor:"rgb(30, 30, 30)", padding:"1% 1%"}}>
          <div
            style={{
              display: "flex",
              width: "100%",
              backgroundColor: "black",
              margin: "0px 0px",
              borderRadius: "10px",
              padding: "16px",
            }}>
            <div className={classes.Left}>
              <div className={classes.header}>
                <span>Shipping Route Map</span>
              </div>
              <GoogleMap Data={response && response.sailing_info_tracking} />
            </div>
            <div className={classes.Right}>
              <div className={classes.header}>
                <span>Shipping Route</span>
              </div>
              <div>
                <Vertical
                  response={
                    response && response.sailing_info_tracking
                      ? response.sailing_info_tracking
                      : null
                  }
                />
              </div>
            </div>
          </div>
          </div>
          <div style={{backgroundColor:"rgb(30, 30, 30)", padding:"1% 1%"}}>
          <MultiStatus
            response={
              response && response.container_event_list
                ? response.container_event_list
                : null
            }
          />
          </div>
        </>
      )}
    </>
  );
};

export default ShipmentSummary;
