export const checkInvoiceUploadValidation = (value) => {
    const general = JSON.parse(localStorage.getItem("uploadData"));
    if (value === 'SupplierDetails') {
        if (!general.invoiceNumber) {
            return "Please Enter Invoice Number"
        }
        else if (!general.invoiceDetails.TranDtls.TaxSch) {
            return "Please Enter Tax Scheme"
        }
        else if (!general.invoiceDetails.TranDtls.SupTyp) {
            return "Please Enter Supply Type"
        }
        else if (!general.invoiceDetails.DocDtls.Dt) {
            return "Please Enter Document Date"
        }
        else if (!general.invoiceDetails.ValDtls.TotInvValFc) {
            return "Please Enter Taxable value Total"
        }
        else if (!general.txlInvoiceAmount) {
            return "Please Enter Total Invoice Value"
        }
        else {
            return "";
        }
    }
    else if (value === 'BuyerDetails') {
        if (!general.invoiceDetails.SellerDtls.Gstin) {
            return "Please Enter Supplier GSTIN"
        }
        else if (!general.invoiceDetails.SellerDtls.LglNm) {
            return "Please Enter Supplier Legal Name"
        }
        else if (!general.invoiceDetails.SellerDtls.Addr1) {
            return "Please Enter Supplier Address 1"
        }
        else if (!general.invoiceDetails.SellerDtls.Addr2) {
            return "Please Enter Supplier Address 2"
        }
        else if (!general.invoiceDetails.SellerDtls.Loc) {
            return "Please Enter Supplier Place"
        }
        else if (String(general.invoiceDetails.SellerDtls.Pin).length !== 6) {
            return "Please enter a valid supplier pincode"
        }

        else if (general.invoiceDetails.SellerDtls.Stcd.length === 0 || general.invoiceDetails.SellerDtls.Stcd.length>10) {
            return "Please enter a valid supplier state code"
        }
        else {
            return "";
        }
    }
    else if (value === 'ItemList') {
        if (!general.invoiceDetails.BuyerDtls.Gstin) {
            return "Please Enter Buyer GSTIN"
        }
        else if (!general.invoiceDetails.BuyerDtls.LglNm) {
            return "Please Enter Buyer Legal Name"
        }
        else if (!general.invoiceDetails.BuyerDtls.Addr1) {
            return "Please Enter Buyer Address 1"
        }
        else if (!general.invoiceDetails.BuyerDtls.Addr2) {
            return "Please Enter Buyer Address 2"
        }
        else if (!general.invoiceDetails.BuyerDtls.Loc) {
            return "Please Enter Buyer Place"
        }
        else if (String(general.invoiceDetails.BuyerDtls.Pin).length !== 6) {
            return "Please enter a valid buyer pincode"
        }
        else if (general.invoiceDetails.BuyerDtls.Stcd.length === 0 || general.invoiceDetails.BuyerDtls.Stcd.length >10) {
            return "Please enter a valid buyer state code"
        }
        else {
            return "";
        }
    }
    else if (value === 'Miscellaneous') {
        if (!general.invoiceDetails.ExpDtls.ShipBNo) {
            return "Please Enter Shiping no."
        }
        else if (!general.invoiceDetails.ExpDtls.ShipBDt) {
            return "Please Enter Ship date"
        }
        else if (!general.invoiceDetails.ExpDtls.Port) {
            return "Please select Port"
        }
        else if (!general.invoiceDetails.ExpDtls.RefClm) {
            return "Please Enter Refund Status"
        }
        else if (!general.invoiceDetails.ExpDtls.ForCur) {
            return "Please select Currency"
        }
        else if (!general.invoiceDetails.ExpDtls.CntCode) {
            return "Please select Country of Export"
        }

        else if (general.invoiceDetails.ExpDtls.ExpDuty == '0') {
            return "Please enter a valid export duty"
        }
        else {
            return "";
        }
    }
    else if (value === 'last'){
        if (!general.invoiceNumber) {
            return "Please Enter Invoice Number"
        }
        else if (!general.invoiceDetails.TranDtls.TaxSch) {
            return "Please Enter Tax Scheme"
        }
        else if (!general.invoiceDetails.TranDtls.SupTyp) {
            return "Please Enter Supply Type"
        }
        else if (!general.invoiceDetails.DocDtls.Dt) {
            return "Please Enter Document Date"
        }
        else if (!general.invoiceDetails.ValDtls.TotInvValFc) {
            return "Please Enter Taxable value Total"
        }
        else if (!general.txlInvoiceAmount) {
            return "Please Enter Total Invoice Value"
        }
        else if (!general.invoiceDetails.SellerDtls.Gstin) {
            return "Please Enter Supplier GSTIN"
        }
        else if (!general.invoiceDetails.SellerDtls.LglNm) {
            return "Please Enter Supplier Legal Name"
        }
        else if (!general.invoiceDetails.SellerDtls.Addr1) {
            return "Please Enter Supplier Address 1"
        }
        else if (!general.invoiceDetails.SellerDtls.Addr2) {
            return "Please Enter Supplier Address 2"
        }
        else if (!general.invoiceDetails.SellerDtls.Loc) {
            return "Please Enter Supplier Place"
        }
        else if (String(general.invoiceDetails.SellerDtls.Pin).length !== 6) {
            return "Please enter a valid supplier pincode"
        }
        else if (general.invoiceDetails.SellerDtls.Stcd.length === 0 || general.invoiceDetails.SellerDtls.Stcd.length >10) {
            return "Please enter a valid supplier state code"
        }
        else if (!general.invoiceDetails.BuyerDtls.Gstin) {
            return "Please Enter Buyer GSTIN"
        }
        else if (!general.invoiceDetails.BuyerDtls.LglNm) {
            return "Please Enter Buyer Legal Name"
        }
        else if (!general.invoiceDetails.BuyerDtls.Addr1) {
            return "Please Enter Buyer Address 1"
        }
        else if (!general.invoiceDetails.BuyerDtls.Addr2) {
            return "Please Enter Buyer Address 2"
        }
        else if (!general.invoiceDetails.BuyerDtls.Loc) {
            return "Please Enter Buyer Place"
        }
        else if (String(general.invoiceDetails.BuyerDtls.Pin).length !== 6) {
            return "Please enter a valid buyer pincode"
        }
        else if (general.invoiceDetails.BuyerDtls.Stcd.length === 0 || general.invoiceDetails.BuyerDtls.Stcd.length >10) {
            return "Please enter a valid buyer state code"
        }
        else if (!general.invoiceDetails.ExpDtls.ShipBNo) {
            return "Please Enter Shiping no."
        }
        else if (!general.invoiceDetails.ExpDtls.ShipBDt) {
            return "Please Enter Ship date"
        }
        else if (!general.invoiceDetails.ExpDtls.Port) {
            return "Please select Port"
        }
        else if (!general.invoiceDetails.ExpDtls.RefClm) {
            return "Please Enter Refund Status"
        }
        else if (!general.invoiceDetails.ExpDtls.ForCur) {
            return "Please select Currency"
        }
        else if (!general.invoiceDetails.ExpDtls.CntCode) {
            return "Please select Country of Export"
        }
        else if (general.invoiceDetails.ExpDtls.ExpDuty == '0') {
            return "Please enter a valid export duty"
        }
        else {
            return "";
        }
    }

}