import React, { useCallback , useContext, useState } from "react";
import useAxios from "../../../../../../services/useAxios";
import { invoiceURL } from "../../../../../../services/BaseURLInstance";
import Loader from "../../../../../../components/Loader";
import {
    Container,
    CardWapper,
    ActionBarStyled,
} from "../../../../../../components/Container.styled";

import { Routes, Route, Link, Outlet,useNavigate,NavLink } from "react-router-dom";
import styled from "styled-components";
import GeneralDetail from '../Details/generals'
import SupplierDetail from '../Details/supplierDetails'
import BuyerDetail from '../Details/buyerDetails';
import ExportDetails from '../Details/exportDetails';
import Miscellaneous from '../Details/miscellaneous';
import styles from '../../../../invoice.module.css';
import {invoiceFinanceDetailsContext} from '../../../../../../contexts/invoiceFinance/invoiceFinanceDetailsContext'
import {invoiceFinanceContext} from '../../../../../../contexts/invoiceFinance/invoiceFinanceContext'
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

export const Details = () => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState(null);
  getIdToken(instance, accounts, setIdToken);
  const companyId = getClaim(accounts, "company_member_id");

  const handleNavigate = useCallback((url) => navigate(url), []);
  const [selected , setSelected] = React.useState('General');

    const tabs = [
        { 
          title: "General", 
          description : "General",
          value: "General"
        },
    
        { 
          title: "Supplier Details", 
          description : "Supplier Details",
          value : "SupplierDetails"
        },
        { 
          title: "Buyer Details", 
          description : "Buyer Details",
          value : "BuyerDetails"
        },
        { 
          title: "Export Details", 
          description : "Export Details",
          value : "ExportDetails"
        },
        { 
            title: "Miscellaneous", 
            description : "Miscellaneous",
            value : "Miscellaneous"
        },

    ]
    
    const {handleNext} = useContext(invoiceFinanceDetailsContext)
    const {setSaveInvoiceData} = useContext(invoiceFinanceContext)
    
    const handleSubmitDetails = async(event) => {
      let general=JSON.parse(localStorage.getItem("uploadData"));
      general.txlSellerMemberId=companyId;
      general.document.downloadUri="asdsdsad";
      localStorage.setItem("uploadData",JSON.stringify(general));
      event.preventDefault();
      const requestOptions = {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + idToken,
          "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
         },
        body: localStorage.getItem("uploadData")
    };
    await fetch(`${invoiceURL}invoices/${general.txlInvoiceId}`, requestOptions)
    .then(response => response.json())
    .then(data => {
        setSaveInvoiceData(data);
        handleNavigate(`/invoicefinancing/invoicesDetails/${data.invoiceNumber}`)
      })
      .catch(err=>{
        console.log("err",err);
      })
    }
  
  return (
    <>
        <ActionBarStyled className ={styles.actionBarStyled}>
          {tabs.map((item, index) => (
            <NavBar key={index} onClick={() =>  setSelected(item.value)} style = { selected === item.value ? addBlue : addWhite } >
              <li>{item.title}</li>
            </NavBar>
          ))}  
        </ActionBarStyled>

        { selected === 'General' ? (
          <form className ={styles.detailsForm} onSubmit={handleNext}>
          <GeneralDetail />
          <div className ={styles.detailsBtnDiv} >
            <button type="submit" onClick={() => setSelected('SupplierDetails')}>Next</button>
          </div>
          </form>
        ) : '' }

        { selected === 'SupplierDetails' ? (
           <form className ={styles.detailsForm} onSubmit={handleNext}>
            <SupplierDetail/> 
           <div className ={styles.detailsBtnDiv} >
            <button type="submit" onClick={() =>  setSelected('General')} >Previous</button>
            <button type="submit" onClick={() =>  setSelected('BuyerDetails')} >Next</button>
          </div>
           </form>
        ) : '' }

        { selected === 'BuyerDetails' ? (
           <form className ={styles.detailsForm} onSubmit={handleNext }> 
            <BuyerDetail/>
            <div className ={styles.detailsBtnDiv} >
            <button type="submit" onClick={() =>  setSelected('SupplierDetails')} >Previous</button>
            <button type="submit" onClick={() =>  setSelected('ExportDetails')} >Next</button>
            </div>
            </form>
        ) : '' }

        { selected === 'ExportDetails' ? (
           <form className ={styles.detailsForm} onSubmit={handleNext }> 
            <ExportDetails/>
            <div className ={styles.detailsBtnDiv} >
            <button type="submit" onClick={() =>  setSelected('BuyerDetails')} >Previous</button>
            <button type="submit" onClick={() =>  setSelected('Miscellaneous')} >Next</button>
            </div>
            </form>
        ) : '' }

        { selected === 'Miscellaneous' ? (
           <form className ={styles.detailsForm} onSubmit={e => handleSubmitDetails(e)}> 
            <Miscellaneous/>
            <div className ={styles.detailsBtnDiv} >
            <button type="submit" onClick={() =>  setSelected('ExportDetails')} >Previous</button>
            <button type="submit">Submit Form</button>
            </div>
            </form>
        ) : '' }
        </>
  )
}


const NavBar = styled.div`
padding-bottom:20px;
 li{
    cursor: pointer;
    :hover{
        color:#218eeb;
    }
}`;

const addWhite = {
    color: 'white'
}
const addBlue = {
    color: '#42A5F5'
}
