export const DocumentsTab = [
  { name: "Payment Transactions", value: "payment-transactions" },
  { name: "Payment Accounts", value: "payment-accounts" },
  { name: "Payment Providers", value: "payment-providers" },
];

export const Header = [
  { name: "Select" },
  { name: "Name" },
  { name: "Type" },
  { name: "Owner" },
  { name: "Created On" },
  { name: "Last modified" },
  { name: "Actions" },
];

export const AllDocumentsHeader = [
  { name: "Select" },
  { name: "Name" },
  { name: "Category" },
  { name: "Sub-Category" },
  { name: "Owner" },
  { name: "Last modified" },
  { name: "Created On" },
  { name: "Actions" },
];

export const Info = [
  { name: "NAME:", value: "name" },
  { name: "OWNER:", value: "owner" },
  { name: "VALIDATED:", value: "validated" },
  { name: "ISDELETED:", value: "isDeleted" },
  { name: "DOCUMENTID:", value: "id" },
  { name: "DOCISSUER:", value: "docIssuer" },
];

export const ConsentHeader = [
  { name: "Document" },
  { name: "Validator" },
  { name: "Requester" },
  { name: "Owner" },
  { name: "Status" },
  { name: "Created On" },
  { name: "Category" },
  { name: "Action" },
];

export const ProfileOnComDoc = {
  // Using for Profile Onbaording Company Document
  // For additional VALIDATION update Category AND SUBcategory
  docCategory: ["COMPANY"],
  docSubCategory: ["CoI", "PANCard", "GSTIN"],
};

export const ProfileOnDirector = {
  // Using for Profile Onbaording Director Document
  // For additional VALIDATION update Category AND SUBcategory
  docCategory: ["DIRECTOR"],
  docSubCategory: ["PAN", "AADHAR"],
};
