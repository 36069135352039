import React, { useCallback, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { BsInfoCircle } from "react-icons/bs";
import { FaCheck, FaQuestion } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { BiUserCircle } from "react-icons/bi";
import styles from "../../pages/Document/Document.module.css";
import { personMemberId } from "../../services/BaseURLInstance";
import useAxios from "../../services/useAxios";
import axios from "axios";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";



const Styles = styled.div`
  
  
  .column2 {
    width: 25%;
    margin-top: 30px;
    text-align: center;
    margin-left: 3%;
    div {
      display: flex;
      font-family: Roboto;
      font-style: Regular;
      font-size: 14px;
      line-height: 15px;
      line-height: 91%;
      align: left;
      vertical-align: Top;
      paragraph-spacing: 32px;
      margin: 14px;
      span {
        margin-top: 16px;
      }
    }
    .icon {
      position: absolute;
      margin-top: 18px;
      margin-left: 18px;
      size: 6px;
      color: black;
    }
  }
  .iconDiv {
    margin-top: -15px;
    margin-left: -18px;
    display: flex;
    .icon {
      position: absolute;
      margin-top: -32px;
      margin-left: 4px;
      size: 6px;
      color: black;
    }
  }



  label {
    font-family: Roboto;
    font-style: Regular;
    font-size: 16px;
    line-height: 16px;
    line-height: 85%;
    vertical-align: top;
    width: 300px;
  }
  .searchitems {
    background-color: #ffffff0d;
  }

  .searchitem {
    display: flex;
    width: 100%;
    height: 52px;
    align-items: center;
    color: white;
    border-bottom: 1px solid rgb(196, 215, 245);
    .container {
      margin-left: 75%;
      float: right;
    }
  }
  .checkbox {
    background: transparent;
    color: #42a5f5;
    border: 1px solid #5d5d5d;
    width: 35px;
    height: 35px;
  }
  .lastButton {
    margin-left: 26%;
    margin-top: 20px;
    button {
      width: 263px;
      height: 53px;
      margin-left: 2%;
      border-radius: 5px;
      /* border: none; */
      font-family: Roboto;
      font-style: Bold;
      font-size: 18px;
      line-height: 24px;
      line-height: 114%;
      align: center;
      vertical-align: top;
      paragraph-spacing: 32px;
    }
  }
  .innerInputDiv {
    overflow-x: scroll;
    background: white;
    border: none;
    width: 750px;
    ::-webkit-scrollbar{
      display: none;
    }
  }
  .searchBox{
    .memberSearchDiv{
      display:flex;
       div{
        padding-top:0.6rem;
        padding-left:10px;
        position:relative;
        margin-top: 1rem;
        color:blue;
        background-color:white;
        height:3rem;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
       
      }
    }
    input{
       width: 30.3rem;
       height:3rem;
       margin-top:1rem;
       background-color: white;
       padding-left:.6rem;
       margin-left: -2px;
       font-size: 1.2rem;
       border-radius: 5px;
       border-top-left-radius: 0px;
       border-bottom-left-radius: 0px;
       border: none;
       outline:none;
      
    }
    
  }
  .filterMember{
       overflow-y: auto;
       width: 100%;
       height:6rem;
       background-color: rgb(170, 170, 170);
       border-radius: 5px;
       align-items:center;
       
      &::-webkit-scrollbar-track {
        background-color: #fff;
        width:5px;
        border-radius:10px;
      }

      &::-webkit-scrollbar {
        width: 5px;
        background-color: #fff;
        border-radius:10px;
      }

      &::-webkit-scrollbar-thumb{
        background-color: #ffe700;
        border-radius:10px;
      }
       div{
         
          align-items: center;
          padding-left:1rem;
          input{
            margin-top: 0.65rem;
            margin-right: 1rem;
            text-align: center;
          }
      }
    }
    .searchList
    {
        display:flex;
        background-color: #1e1e1e;
        color:#fff;
        height:100%;
        border-bottom:1px solid #fff;
        div{
            padding-left:1rem;
            width:50%;
            padding-top: .3rem;
        }
        .checkBoxDiv{
           text-align: right;
           padding-right:1rem;
            input{
              width:20px;
              height:20px;
              margin-top: .2rem;
              border:none;
            }
        }
    }
    .filterMemberInput{
       background-color:black;
       height: 1.8rem;
       border:none;
       font-size: 1rem;
       color:white;
       border-radius:5px;
    }
    .searchListDisplay{
      max-height: 18rem;
    }
    .searchListDisplayMember{
      overflow-y: scroll;
      max-height: 8rem;
     
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  


`;
export default ({ handleMember, handleGroupName }) => {
  const [search, setSearch] = useState();
  const [newList, setNewList] = useState({
    member: [],
    company: []
  });
  const [checkItem, setCheckItem] = useState([]);
  const [status, setStatus] = useState("signatureDeclined");
  const [personData, setPersonData] = useState("");
  const { instance, accounts } = useMsal();
  // const [idToken, setIdToken] = useState(null);
  // getIdToken(instance, accounts, setIdToken);
  const companyId = getClaim(accounts, "company_member_id");
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isVisible, setIsVisible] = useState(false);
  const [memberList, setMemberList] = useState("");




  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: 'GET',
      requireAuthentication: true,
      headers: {
        "Authorization": "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    const fetchData = async () => {
      await fetch(`${process.env.REACT_APP_NETWORK_URL}members?memberId=${companyId}`, requestOptions)
        .then(response => response.json()).then((data) => {
         
          let nMember = data;
          nMember = nMember.filter(item => item?.memberType !==undefined && item?.memberType!=="PERSON");
          nMember = nMember.map(item=>({
            ...item,
            isChecked:false
          }))
          setMemberList(nMember)
        }
        )
    }
    fetchData();
  }, [])

  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsVisible(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  }, []);

  useEffect(() => {
    let personArray = [];
    personArray = checkItem.map(item => {
      return item.memberUID
    })
    handleMember(personArray);
 
  }, [checkItem])


  const handleChange = (e) => {
    setIsVisible(false)
    if (e.target.value !== "") {
      setIsVisible(true)
      let temp = [];
      let temp2 = [];
      let nData = memberList;
      nData = nData.filter((item) => item?.memberType!==undefined);
    
      temp = nData.filter((item) => {
        if (item.memberName.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase())) {
          return item;
        }
        else {
          return "";
        }
      });
      setNewList({
        member: temp
      });
    }
    else {
      setNewList("");
    }

  };

  const handleChecked = (e, item) => {
    if (checkItem.length !== 0 && checkItem.includes(item)) {
      let temp = checkItem.filter((k) => {
        if (k !== item) {
          return k;
        }
      })
      setCheckItem(temp);
    } else {
      setCheckItem((c) => [...checkItem, item]);
    }
    handleItemCheckedUnchecked()
  };




  const handleDelete = (e, value) => {
    let temp = checkItem.filter((k) => {
      if (k !== value) {
        return k;
      }
    });
    setCheckItem(temp);
    
  };

  const handleChangeName = (e) => {
    handleGroupName(e.target.value)
  }
  useEffect(() => {
    handleItemCheckedUnchecked();
}, [checkItem])

const handleItemCheckedUnchecked =() =>{
  for(let i=0; i<memberList.length; i++){
    for(let j=0; j<checkItem.length; j++){
         if(memberList[i].memberUID===checkItem[j]?.memberUID){
           memberList[i].isChecked=memberList[i].isChecked===true ? false : true
         }
    }
    setMemberList(memberList)
  }
  setNewList({
   member: memberList
 });
}

  return (
    <div className={styles.Cards} style={{ marginTop: "16px" }} >
      <div style={{ padding: "16px", background: "#313131", borderRadius: "10px" }}>
        <Styles>
        {(memberList) &&<div className="searchListDisplay">
            <input style={{ height: "2.5rem", paddingLeft: "1rem", width: "100%", marginBottom: "1rem", fontSize: "1rem" }} placeholder="Group Name" type="text" name="groupName" onChange={handleChangeName} ></input>

            <div className="filterMember">
              <div >
                {
                  checkItem && checkItem.map(item => {
                    return <>
                      <input className="filterMemberInput" type="text" value={item.memberName}></input>
                      {/* <div style={{position:"relative",marginTop:".65rem",color:"white",marginLeft:"-3.5rem"}} onClick={(e)=>handleDelete(e,item)}><MdCancel/></div> */}
                    </>
                  })
                }
              </div>
            </div>
            <div className="searchBox">
              <div className="memberSearchDiv">
                <div><FiSearch size={20} /></div>
                <input type="text" placeholder="Search Member" onChange={handleChange}></input>
              </div>
            
              <div className="searchListDisplayMember"  ref={ref}>
              {isVisible &&( 
                <div>
                  {
                    newList && newList.member?.length > 0 && newList.member.map((item, i) => {
                      return <div>
                        <div className="searchList">
                          <div>{item?.memberName}</div>
                          <div className="checkBoxDiv"><input checked={item.isChecked} type="checkBox" onClick={(e) => handleChecked(e, item)}></input></div>
                        </div>
                        <hr style={{ margin: "0px" }} />
                      </div>
                    })
                  }
                </div> )}
              </div>
         
            </div>
          </div>}
        </Styles>
      </div>
    </div>
  );
};


