import React from "react";
import classes from "./Toogle.module.css";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import {BsPencilSquare} from 'react-icons/bs';

const ToogleTab = ({ selected, toggle, TabData, setDetailUpdateCheck, detailUpdateCheck, setEditSummaryCheck, editSummaryCheck, invoiveData, companyId }) => {
  const handleEdit = (e, value) =>{
    setDetailUpdateCheck(!detailUpdateCheck);
    toggle(value);
  }
  const handleSummaryEdit = (e, value) =>{
    setEditSummaryCheck(!editSummaryCheck);
    toggle(value);
  }
  return (
    <div>
      {TabData.map((item, i) => (
        <div className={classes.toogleTab} key={i}>
          <div className={classes.toogleTitle} onClick={() => toggle(i)}>
            <span style={{fontSize:"18px"}}> {item.title}</span>
            <span>
              {selected === i ? (
                <div style={{display:"flex"}}>
                { (item.title==='Details (Optional)*' || item.title==='Summary *' || item.title==='Details (Optional)' || item.title==='Summary' )  
                && invoiveData?.txlBuyerMemberId!==companyId && invoiveData?.buyerConsent!== "COMPLETED" && <button onClick={(e)=>{
                  item.title === 'Details (Optional)*' || item.title === 'Details (Optional)' ?
                  handleEdit(e,i) : handleSummaryEdit(e,i)}}
                  style={{background:"transparent", border:'none', marginRight:'2px'}}><BsPencilSquare size={20} color='#42A5F5'/></button>}
                <CgChevronUp size={30} />
                </div>
              ) : (
                <div>
                {/* {item.title==='Details' && <button style={{background:"transparent", border:'none', marginRight:'2px'}}><BsPencilSquare size={20} color='#42A5F5'/></button>} */}
                <CgChevronDown size={30} />
                </div>
              )}
            </span>
          </div>

          <div className={selected === i ? "show" : "description"}>
            {item.description}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ToogleTab;
