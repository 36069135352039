import { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ButtonStyled } from "../Container.styled";
import { truncateStringID } from "utils/helper";
import { AiFillEye } from "react-icons/ai";
import classes from "./details.module.css";
import { FindCompanyName } from "utils/helper";
import cn from "classnames";

const Styles = styled.div`
  .row {
    width: 100%;
    display: flex;
  }

  .row > div {
    display: flex;
    height: 50.47px;
    width: 16.5%;
    justify-content: center;
    align-items: center;
    border: 0.0001px solid #ffffff;
    font-family: Roboto;
    font-style: Medium;
    font-size: 14px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    overflow: hidden;
  }

  .details {
    margin-top: 20px;
    margin-left: 40px;
    padding-bottom: 20px;
    font-family: Roboto;
    font-style: Regular;
    font-size: 18px;
    line-height: 24px;
    line-height: 128%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 26px;
    width: 70%;
  }
  .details-screen {
    display: flex;
  }
  .details-button {
    width: 30%;
    margin-top: 20px;
    margin-left: 120px;
  }
  .details-button > button {
    margin: 4px;
    cursor: pointer;
    height: 50px;
  }
  .details > hr {
    height: 0.0000000001px;
    opacity: 20%;
  }
  .details > div {
  }
`;

const Details = ({ data, companyList }) => {
  const navigate = useNavigate();
  const [check, setCheck] = useState(true);
  const handleClick = () => {
    if (check) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  };

  const handleDetails = (e, data) => {
    e.preventDefault();
    navigate("/financedashBoard/invoicefinancing/loandetails", {
      state: { data: data, companyList: companyList },
    });
  };

  return (
    <Styles>
      {companyList && (
        <div>
          {check ? (
            <div className="row">
              <div style={{ borderLeft: "none", paddingLeft: "10px" }}>
                <div onClick={handleClick} className="px-2">
                  <IoIosArrowDown size={22} />
                </div>
                <div onClick={(e) => handleDetails(e, data)}>
                  {data.loanId ? truncateStringID(data.loanId, 7, "L") : "NA"}
                </div>
              </div>
              <div style={{ borderLeft: "none" }}>
                {companyList &&
                  FindCompanyName(companyList, data.financerMemberUID)}
              </div>
              <div style={{ borderLeft: "none" }}>
                {companyList &&
                  FindCompanyName(companyList, data.traderMemberUID)}
              </div>
              {/* <div style={{ borderLeft: "none" }} onClick={()=>navigate(`../${data.referenceFinanceInstrumentId}`)}><a href=''>Invoice Detail</a></div> */}
              <div style={{ borderLeft: "none" }}>
                {new Date(data.createdDate).toLocaleDateString()}
              </div>
              <div style={{ borderLeft: "none", borderRight: "none" }}>
                <div>{data.loanStatus}</div>
              </div>
              <div
                style={{ borderLeft: "none" }}
                onClick={() =>
                  navigate(`../${data.referenceFinanceInstrumentId}`)
                }
              >
                <a href="">
                  <AiFillEye size={24} />
                </a>
              </div>
            </div>
          ) : (
            <>
              <div className="row">
                <div style={{ borderLeft: "none", paddingLeft: "10px" }}>
                  <div onClick={handleClick} className="px-2">
                    <IoIosArrowUp size={22} />
                  </div>
                  <div onClick={(e) => handleDetails(e, data)}>
                    {data.loanId ? truncateStringID(data.loanId, 7, "L") : "NA"}
                  </div>
                </div>
                <div style={{ borderLeft: "none" }}>
                  {companyList &&
                    FindCompanyName(companyList, data.financerMemberUID)}
                </div>

                <div style={{ borderLeft: "none" }}>
                  {companyList &&
                    FindCompanyName(companyList, data.traderMemberUID)}
                </div>
                <div style={{ borderLeft: "none" }}>
                  {new Date(data.createdDate).toLocaleDateString()}
                </div>
                <div style={{ borderLeft: "none", borderRight: "none" }}>
                  <div>{data.loanStatus}</div>
                </div>
                <div
                  style={{ borderLeft: "none" }}
                  onClick={() =>
                    navigate(`../${data.referenceFinanceInstrumentId}`)
                  }
                >
                  <a href="">
                    <AiFillEye size={24} />
                  </a>
                </div>
              </div>

              <div className={cn(classes.Details)}>
                <div className={cn(classes.column)}>
                  <div className={cn(classes.column1)}>
                    <div className={classes.columnContent}>
                      <div>Finance Amount</div>
                      <div>
                        {data.financeAmount
                          ? data.financeAmount
                          : "0" + " " + data.financeCurrency}
                      </div>
                    </div>
                  </div>
                  <div className={cn(classes.column1)}>
                    <div className={classes.columnContent}>
                      <div>Loan Product Type</div>
                      <div>
                        {data.financeProductCategory
                          ? data.financeProductCategory
                          : "NA"}
                      </div>
                    </div>
                  </div>

                  <div className={cn(classes.column2)}>
                    <button>Make Payment </button>
                  </div>
                </div>

                <div className={cn(classes.column)}>
                  <div className={cn(classes.column1)}>
                    <div className={classes.columnContent}>
                      <div>Finance disbursement Date</div>
                      <div>
                        {data.financeDisbursalDate
                          ? new Date(
                              data.financeDisbursalDate
                            ).toLocaleDateString()
                          : "NA"}
                      </div>
                    </div>
                  </div>
                  <div className={cn(classes.column1)}>
                    <div className={classes.columnContent}>
                      <div>% Interest Rate Per Annum</div>
                      <div>{data.interestRate && data.interestRate + "%"}</div>
                    </div>
                  </div>

                  <div className={cn(classes.column2)}>
                    <button onClick={(e) => handleDetails(e, data)}>
                      View More
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </Styles>
  );
};
export default Details;
