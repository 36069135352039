import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import classes from "./PaymentAccounts.module.css";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import { paymentServiceURL } from "services/BaseURLInstance";
import { Countries } from "utils/DropDown/Country";
import { AccountType, status } from "utils/DropDown/Payment";
import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { AddPaymentValdations } from "utils/Validations/Payment";

import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { UpdatePaymentAccount } from "utils/Section/FormInfo";
import country_of_exports from "jsons/CountryCodes.json";

const UpdateAccount = ({ isUpdatingData, setIsUpdating }) => {
  const [Data, setData] = useState(isUpdatingData);

  // const Country = [
  //   { label: "Canada", value: "Canada" },
  //   { label: "India", value: "India" },
  //   { label: "Singapore", value: "Singapore" },
  //   { label: "US", value: "US" },
  // ];

  // const Currency = [
  //   { label: "USD", value: "USD" },
  //   { label: "SGD", value: "SGD" },
  //   { label: "INR", value: "INR" },
  // ];
  const [CurrencyOptions, SetCurrencyOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const { instance, accounts } = useMsal();
  const [isCountry, setIsCountry] = useState(null);
  const [isCurrency, setIsCurrency] = useState(null);
  const [isBankCodes, setIsBankCodes] = useState(null);
  const [isInvalid, setIsInvalid] = useState(null);

  const handleChange = (e) => {
    if (e.target.name === "country") {
      setIsCountry(e.target.value);

      let countryFilter = Countries.filter(
        (country) => country.value === e.target.value
      );

      if (countryFilter.length > 0) {
        setIsCountry(countryFilter[0].label);
        setData({
          ...Data,
          [e.target.name]: countryFilter[0].label,
        });
      }
    } else if (e.target.name === "currency") {
      setIsCurrency(e.target.value);
      setData({
        ...Data,
        ["paymentAccountCurrency"]: e.target.value,
      });
    } else {
      setData({
        ...Data,
        [e.target.name]: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (isCountry) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}banks/code/${isCountry}`,
          null,
          response
        )
          .then((data) => {
            let TEMP = [];
            data.forEach((code, index) =>
              TEMP.push({
                label: code.currency,
                value: code.currency,
              })
            );
            SetCurrencyOptions(TEMP);
          })
          .catch((error) => {
            Error("Something went wrong");
          });
      });
    }
  }, [isCountry]);

  useEffect(() => {
    if (isCountry || isCurrency) {
      let countryFilter = Countries.filter(
        (country) => country.value === Data.country
      );

      Data.bankCodes = [];
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}banks/codes?country=${
            isCountry || countryFilter[0].label
          }&currency=${isCurrency || Data.paymentAccountCurrency}`,
          null,
          response
        )
          .then((data) => {
            let { bankCodes } = data;
            if (bankCodes) {
              setIsBankCodes(data);
              bankCodes.forEach(
                (code, index) =>
                  (Data.bankCodes[index] = { bankCodeType: code, bankCode: "" })
              );
              setIsInvalid(null);
            } else {
              setIsBankCodes(null);
              setIsInvalid(data);
            }
          })
          .catch((error) => {
            Error("Something went wrong");
          });
      });
    }
  }, [isCountry, isCurrency]);

  const HandlePUTSubmit = async (e) => {
    let checkValidation = AddPaymentValdations(Data, isBankCodes);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      e.preventDefault();
      setIsLoading(true);
      const options = {
        method: "PUT",
        body: Data,
      };
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/account/${Data.memberID}`,
          options,
          response
        )
          .then((data) => {
            // setIsLoading(false);
            // let { message } = data;
            // if (message) {
            //   Error(message);
            // } else {
            //   Success("Account updated successfully");
            //   setIsBankCodes(null);
            //   setIsUpdating(false);
            // }
            Success("Account updated successfully");
            setIsBankCodes(null);
            setIsUpdating(false);
          })
          .catch((error) => {
            Error("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  };

  const handleChangeBankCode = (event, index) => {
    let Object = {
      bankCodeType: event.target.name,
      bankCode: event.target.value,
    };
    Data.bankCodes[index] = Object;
  };

  return (
    <div>
      {isLoading ? (
        <Content>
          <span>Loading.....</span>
          <Loader />
        </Content>
      ) : (
        <>
          <OverLayTrigger value={UpdatePaymentAccount} />
          <div className={classes.UserManagement}>
            <div className={classes.InputList}>
              <Input
                name="paymentAccountHolderName"
                value={Data.paymentAccountHolderName}
                placeholder="Account Holder Name"
                handleChange={handleChange}
              />
            </div>
            <div className={classes.InputList}>
              <Input
                name="bankName"
                value={Data.bankName}
                placeholder="Bank Name"
                handleChange={handleChange}
              />
            </div>
            <div className={classes.InputList}>
              <Select
                options={country_of_exports}
                name="country"
                // value={Data.country}
                placeholder="Country"
                handleChange={handleChange}
              />
            </div>
            <div className={classes.InputList}>
              <Select
                options={CurrencyOptions}
                name="currency"
                value={Data.paymentAccountCurrency}
                placeholder="Currency"
                handleChange={handleChange}
              />
            </div>

            {isBankCodes &&
              isBankCodes.bankCodes.map((item, index) => (
                <div className={classes.InputList}>
                  <Input
                    name={item}
                    placeholder={item}
                    handleChange={(e) => handleChangeBankCode(e, index)}
                  />
                </div>
              ))}

            {!isBankCodes &&
              Data &&
              Data.bankCodes.length > 0 &&
              Data.bankCodes.map((item, index) => (
                <div className={classes.InputList}>
                  <Input
                    name={item.bankCodeType}
                    placeholder={item.bankCodeType}
                    value={item.bankCode}
                    handleChange={(e) => handleChangeBankCode(e, index)}
                    // readOnly={true}
                  />
                </div>
              ))}

            {isInvalid && isCountry && isCurrency && (
              <div>
                <span style={{ color: "red" }}>{isInvalid}</span>
              </div>
            )}

            <div className={classes.InputList}>
              <Select
                options={AccountType}
                name="accountType"
                value={Data.accountType}
                placeholder="Account Type"
                handleChange={handleChange}
              />
            </div>

            <div className={classes.InputList}>
              <Input
                name="paymentAccountNumber"
                value={Data.paymentAccountNumber}
                placeholder="Account Number"
                handleChange={handleChange}
              />
            </div>
            <div className={classes.InputList}>
              <Select
                options={status}
                name="status"
                value={Data.status}
                placeholder="Status"
                handleChange={handleChange}
              />
            </div>
            <div></div>
          </div>

          <ButtonDiv>
            <Button onClick={() => setIsUpdating(false)}>Cancel</Button>
            <Button onClick={(e) => HandlePUTSubmit(e)}>Update</Button>
          </ButtonDiv>
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default UpdateAccount;
