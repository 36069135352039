import { useState } from "react";
import { createContext, useEffect } from "react";
import { profileURL } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
export const FinanceContext = createContext();

export const FinanceContextProvider = ({ children }) => {
  const [companyList, setCompanyList] = useState();
  const [Role, setRole] = useState(null);
  const { instance, accounts } = useMsal();

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_NETWORK_URL}members?memberId=${companyId}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          let nMember = data;
          nMember = nMember.filter(
            (item) =>
              item?.memberType !== undefined && item?.memberType !== "PERSON"
          );
          nMember = nMember.map((item) => ({
            ...item,
            isChecked: false,
          }));
          setCompanyList(nMember);
        });
    };
    fetchData();
  }, []);

  // useEffect(() => {
  //   const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  //   const requestOptions = {
  //     method: "GET",
  //     requireAuthentication: true,
  //     headers: {
  //       Authorization: "Bearer " + idToken,
  //       "x-api-key":
  //         "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
  //     },
  //   };
  //   const fetchData = async () => {
  //     await fetch(
  //       `${profileURL}Persons/${response.idTokenClaims.member_id}`,
  //       requestOptions
  //     ).then((data) => {
  //       let { message, status } = data;
  //       if (message || status) {
  //         Error(message);
  //       } else {
  //         setRole(data.role);
  //       }
  //     });
  //   };
  //   fetchData();
  // }, []);

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  return (
    <FinanceContext.Provider value={{ companyList: companyList, Role: Role }}>
      {children}
    </FinanceContext.Provider>
  );
};
