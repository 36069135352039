import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { RiWechatLine, RiArrowDownSLine } from "react-icons/ri";
import Styles from "./styles";
import RequsitionField from "./requsitionField";
import Quote from "./quoteInputField";

import Documents from "../../Document/company/index";
import History from "../../../components/UI/History/History";
import Alert from "../uploadInvoice/Deals/alerts";
import DocumentNavbar from "../../Document/index";
import SyndicateLending from "pages/syndicatelending/index";
import { useSearchParams } from "react-router-dom";
import { storeContext } from "../../../contexts/Store/Store";
import { NetworkURL } from "../../../services/BaseURLInstance";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
// import { export1 as alias1 } from "module-name";

export default () => {
  const { fetchCompanyList } = useContext(storeContext);
  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const [searchParams] = useSearchParams();
  const param = useParams();
  const dealHeader = searchParams.get("dealHeader");
  const [headerSelect, setHeaderSelect] = useState(dealHeader || "requsition");
  const [data, setData] = useState();
  const [companyList, setCompanyList] = useState();
  const [loanID, setLoanID] = useState();
  const [sydicateMenu, setSydicateMenu] = useState("");
  const navigate = useNavigate();

  const handleBackToLoan = () => {
    navigate("/invoicefinancing/Loans");
  };

  const handleDealDetailsHeader = (e, value) => {
    setHeaderSelect(value);
  };

  const setSyndicate = (id) => {
    setLoanID(id);
    setHeaderSelect("syndicatelending");
    switch (headerSelect) {
      case "syndicatelending":
        return <SyndicateLending data={loanID} />;
    }
  };

  const syndicate = {
    setSyndicate,
    data,
    setSydicateMenu,
    setHeaderSelect
  };
  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let URL = `${process.env.REACT_APP_FINANCE_URL}finance/deals/${param.id}`;
    fetchCompanyList(URL, idToken)
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken)
      .then((companyList) => {
        setCompanyList(companyList);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  var params = {
    memberID: "2fd6727b-2503-4877-b1bb-ea0866cc9ece",
    domain: "FINANCING",
    entityId: "38928b86-f397-4982-a9d0-2705fc79e80c",
  };

  const Component = () => {
    switch (headerSelect) {
      case "requsition":
        return <RequsitionField data={data} />;
      case "quote":
        return <Quote data={syndicate} />;
      case "documents":
        return (
          <>
            <DocumentNavbar dealData={data} />
          </>
        );
      case "history":
        return <History params={params} />;
      case "alerts":
        return <Alert />;
      case "syndicatelending":
        return <SyndicateLending data={loanID} />;

      default:
        return <RequsitionField data={data} />;
    }
  };

  return (
    <Styles>
      {data && companyList && (
        <div className="loandetails">
          <div className="summary">
            <div className="summaryheading" style={{ display: "flex" }}>
              <div className="loanSummarytext">Summary</div>
              <div className="backtoloan">
                <div>
                  <RiArrowDownSLine />
                </div>
                <div>11 Invoice Typeid: {data.createdBy.substr(6)}</div>
              </div>
            </div>
            <div className="table">
              <div className="header1">
                <div
                  style={{
                    borderLeft: "none",
                    borderTop: "none",
                    paddingLeft: "10px",
                  }}
                >
                  Financer
                </div>
                <div>Borrower</div>
                <div>Finance Type</div>
                <div>Status</div>
                <div>Last Activity Date</div>
                <div>Action</div>
              </div>
              <div className="row">
                <div style={{ borderLeft: "none", paddingLeft: "10px" }}>
                  <div>
                    <BsBell />
                  </div>
                  <div style={{ paddingLeft: "10px" }}>{data.financerName}</div>
                </div>
                <div>
                  {companyList.map((item) => {
                    if (item.memberUID === data.traderMemberUID) {
                      return (
                        <div
                          style={{ borderLeft: "none", paddingLeft: "10px" }}
                        >
                          {item.memberName}
                        </div>
                      );
                    }
                  })}
                </div>
                <div>{data.financeProductCategory}</div>
                <div>{data.financeDealStatus}</div>
                <div>{new Date(data.lastUpdatedDate).toLocaleDateString()}</div>
                <div style={{ borderRight: "none" }}>
                  <div style={{ margin: "20px" }}>
                    <FiMail />
                  </div>
                  <div style={{ margin: "20px" }}>
                    <RiWechatLine />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="loanDetailsFields">
            <div>
              <div className="loanDetailsHeading">Deal Details</div>
              <div className="dealDetailsHeader">
                <div
                  id="requsition"
                  style={
                    headerSelect === "requsition"
                      ? {
                          color: "#42A5F5",
                          textDecoration: "underline",
                          textUnderlineOffset: "10px",
                        }
                      : {}
                  }
                  onClick={(e) => handleDealDetailsHeader(e, "requsition")}
                >
                  Requsition
                </div>
                <div
                  id="quote"
                  style={
                    headerSelect === "quote"
                      ? {
                          color: "#42A5F5",
                          textDecoration: "underline",
                          textUnderlineOffset: "10px",
                        }
                      : {}
                  }
                  onClick={(e) => handleDealDetailsHeader(e, "quote")}
                >
                  Quote
                </div>
                <div
                  id="syndicatelending"
                  style={
                    headerSelect === "syndicatelending"
                      ? {
                          color: "#42A5F5",
                          textDecoration: "underline",
                          textUnderlineOffset: "10px",
                        }
                      : {}
                  }
                  onClick={(e) =>
                    handleDealDetailsHeader(e, "syndicatelending")
                  }
                >
                  SyndicateLending
                </div>
                <div
                  id="documents"
                  style={
                    headerSelect === "documents"
                      ? {
                          color: "#42A5F5",
                          textDecoration: "underline",
                          textUnderlineOffset: "10px",
                        }
                      : {}
                  }
                  onClick={(e) => handleDealDetailsHeader(e, "documents")}
                >
                  Documents
                </div>
                <div
                  id="history"
                  style={
                    headerSelect === "history"
                      ? {
                          color: "#42A5F5",
                          textDecoration: "underline",
                          textUnderlineOffset: "10px",
                        }
                      : {}
                  }
                  onClick={(e) => handleDealDetailsHeader(e, "history")}
                >
                  History
                </div>
                <div
                  id="alerts"
                  style={
                    headerSelect === "alerts"
                      ? {
                          color: "#42A5F5",
                          textDecoration: "underline",
                          textUnderlineOffset: "10px",
                        }
                      : {}
                  }
                  onClick={(e) => handleDealDetailsHeader(e, "alerts")}
                >
                  Alerts
                </div>
              </div>
              <hr></hr>
              <div style={{ paddingBottom: "20px" }}>
                {headerSelect && Component()}
              </div>
            </div>
          </div>
        </div>
      )}
    </Styles>
  );
};
