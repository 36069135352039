import React, { useContext, useState, useEffect } from "react";
import { financeURL } from "services/BaseURLInstance";
import { GoMail } from "react-icons/go";
import { BsChat } from "react-icons/bs";

import { useNavigate } from "react-router-dom";
import { invoiceFinanceContext } from "contexts/invoiceFinance/invoiceFinanceContext";
import { TableStatus } from "utils/helper";
import {STATUS} from "utils/Constants";
import { UseRequest } from "utils/API/Request.js";
import Loader from "components/Loader/Loader";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import cn from "classnames";
import classes from "./deals.module.css";
import Table from "react-bootstrap/Table";
import { truncateStringID } from "utils/helper";
import { FinanceDealsHeader } from "utils/Section/InvoiceSection";
import { useParams } from "react-router-dom";
const FinanceDeals = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const [financeData, setFinanceData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);
  const { instance, accounts } = useMsal();
  let financerMemberUID = "";
  let traderMemberUID = "";

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    if (memberType === "TRADER") {
      traderMemberUID = companyId;
    } else {
      financerMemberUID = companyId;
    }
    let URL = `${financeURL}finance/deals?traderMemberUID=${traderMemberUID}&financerMemberUID=${financerMemberUID}&invoiceId=${id}`;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL,null,response)
        .then((data) => {
          setIsLoading(false);
           data.sort(
            (a, b) => Date.parse(b.lastUpdatedDate) - Date.parse(a.lastUpdatedDate)
          );
          setFinanceData(data);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });})
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const handleClickSelect = (value) => {
    localStorage.setItem("financeDeal", JSON.stringify(value));
    navigate(`/financing/invoice/finance-deals/${value.financeDealId}`);
  };

  const Status = (status) => {
    switch (status) {
      case "NEW":
        return STATUS(status, "primary");
      case "INTEREST_EXPRESSED":
        return STATUS(status, "warning");
      case "DUE_DILIGENCE_INITIATED":
        return STATUS(status, "warning");
      case "DUE_DILIGENCE_COMPLETED":
        return STATUS(status, "success");
      case "QUOTE_GENERATED":
        return STATUS(status, "primary");
      case "QUOTE_DECLINED":
        return STATUS(status, "danger");
      case "QUOTE_ACCEPTED":
        return STATUS(status, "warning");
      case "PENDING_T_AND_C_ACCEPTANCE":
        return STATUS(status, "warning");
      case "FINANCE_AGREEMENT_COMPLETE":
        return STATUS(status, "success");
      case "FINANCED":
        return STATUS(status, "success");
      case "DECLINED":
        return STATUS(status, "danger");
      case "ABORTED":
        return STATUS(status, "danger");
      case "CLOSED":
        return STATUS(status, "danger");
      default:
        return;
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {financeData && (
            <Table bordered responsive className={cn(classes.Table)}>
              <thead>
                <tr>
                  {FinanceDealsHeader &&
                    FinanceDealsHeader.map((header, i) => (
                      <td key={i}>{header.name}</td>
                    ))}
                </tr>
              </thead>
              <tbody>
                {financeData &&
                  financeData.map((data, i) => (
                    <tr key={i}>
                      <td onClick={() => handleClickSelect(data)}>
                        {data.financeDealId
                          ? `${truncateStringID(data.financeDealId, 7, "FD")}`
                          : "Not Available"}
                      </td>
                      <td>{data.financerName}</td>
                      <td>{data.interestRate}</td>
                      <td>{Status(data.financeDealStatus)}</td>
                      <td className="text-center">
                        {new Date(data.lastUpdatedDate).toLocaleDateString()}
                      </td>
                      <td style={{display:"flex", justifyContent:"center"}}>
                        <span className="d-flex text-center">
                          <GoMail size={28} style={{ marginRight: "5px" }} />
                          <BsChat size={24} />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {isError && <span>Failed to load</span>}
        </>
      )}
    </>
  );
};

export default FinanceDeals;
