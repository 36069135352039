import React, { useState, useEffect} from 'react'
import styles from './syndicate.module.css'
import { Button, Card } from '@mui/material';
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import TrancheDetails from "./TrancheDetails.js";
import LoanStructDetail from "./LoanStructDetail.js";
import { useMsal } from "@azure/msal-react";
import { getClaim, getIdToken } from "utils/authUtil";
import LoanSummary from './loanSummary';
function TrancheList(props) {
    const { instance, accounts } = useMsal();
    const [type, setType] = useState(false);
    const [loanstructure, setLoanstructure] = useState(false);
    const [tranche, setTranche] = useState(true);
    const [localIdx, setLocalIdx] = useState(0);

    const handleType = (index) => {
        setLocalIdx(index)
        setType(!type);
        //props.disabledealStructure()
        setTranche(false)
        props.props.setIdx(index)
    }
    const companyId = getClaim(accounts, "company_member_id");

    const trancheList = props.props?.apiData?.loanTranches || props.props.tranchelist;
    const handleAddNewTranche =  props.props?.handleAddNewTranche;
    const completeLoanStructure = props.props?.completeLoanStructure;
    const addNewTrancheBool =  props.props?.addNewTrancheBool;
    const handlSaveTranche = props?.props?.handlSaveTranche;
    const sendBool = props?.props?.sendBool;
    const enableStepper = props.props.enableStepper;
    console.log("LTN", trancheList);
    console.log("companyId", companyId)
    const loanStructs =[{
        loanStucture:{},
        index:1,

    }]

    

    const loanStruct = () => {
        setLoanstructure(true)
        setType(false)
    }
    useEffect(() => {
        console.log("props-bd", props)
    }, [props])
    const {handleSandToFinancer} = props.props; 
    return (
        <>
         <LoanSummary data={props.props?.apiData} />    
<div className={styles.trancheBtnBox}>
             {(props?.props?.apiData?.financerMemberUID!==undefined) && (props.props?.apiData?.loanTranches[props.props?.idx]?.loanTrancheStatus==="DRAFT") && (trancheList?.length>0 && props?.props?.apiData?.financerMemberUID === companyId) &&(
                  
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                            style={{ minWidth: "220px", color: "white" }}
                            onClick={handleSandToFinancer}
                        >
                            Send To Co-Financers
                        </Button>
                      
                        )}

{(props?.props?.apiData?.loanTranches[0]?.loanTrancheStatus!=="LOCKED") && (props?.props?.apiData?.financerMemberUID !==undefined) && (props?.props?.apiData?.financerMemberUID === companyId) &&(
  
       
  <Button
variant="contained"
color="primary"
style={{ minWidth: "150px", margin: "0px 5px" }}
onClick={handleAddNewTranche}
>
Add Tranche
</Button>



  
 
)}
                          </div>
            {loanstructure ?
                <LoanStructDetail data={props}  companyId={props?.props?.companyId} />
                :
                <Card style={{ backgroundColor: "#1E1E1E", padding: "15px" }}>
                    {tranche && <h4 style={{ color: "white" }}>Tranches</h4>}
                    {trancheList && trancheList.map((d, i) => {
                        return (
                            <>
                                <button type="button" className={styles.collapsible} onClick={() => handleType(i)} style={{ background: "rgb(0, 0, 0)", margin: "0px 0px 5px 0px", }}>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <b style={{ fontSize: "20px", fontWeight: "700", color: "#0d48a1" ,}}>
                                        Tranche-{i+1}
                                        </b>
                                        {type ? <FiChevronUp size={25} color="#42A5F5" /> : <FiChevronDown size={25} />}
                                    </div>
                                </button>
                               <div className='tranchlistdata' id={`toggle_`+i}>
                                {type && localIdx==i && <TrancheDetails 
                                data={props.props}
                                companyId={props?.props?.companyId}
                                  />}
</div>
                                {/* <br></br>
                                <div style={{ minHeight: "100px" }}></div>
                                {!type && <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Button
                                        variant="contained"
                                        type="button"
                                        style={{ minWidth: "200px", margin: "0px 20px", color: "#42A5F5", background: "#343434" }}
                                        onClick={() => console.log("add another")}
                                    >
                                        Back to Loan Structure
                                    </Button>
                                    <Button
                                     type="button"
                                        variant="contained"
                                        color="primary"
                                        style={{ minWidth: "200px", margin: "0px 20px" }}
                                        onClick={handleSandToFinancer}
                                    >
                                        Send to Co-financers
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                         type="button"
                                        style={{ minWidth: "200px", margin: "0px 20px" }}
                                        onClick={() => enableStepper()}
                                    >
                                        Add Trache
                                    </Button>
                                </div>} */}
                            </>
                        )
                    })
                    }
                </Card>
            }
           
            <div style={{ display: "flex", justifyContent: "end" }}>


{(addNewTrancheBool === true)  &&(
 
       
 <Button
variant="contained"
color="primary"
style={{ minWidth: "150px", margin: "0px 5px" }}
onClick={handlSaveTranche}
>
Save
</Button>
)}
</div>

     
          
<div style={{textAlign:"right", marginTop:"10px"}}>
             {(props?.props?.apiData?.financerMemberUID!==undefined) && (props.props?.apiData?.loanTranches[props.props?.idx]?.loanTrancheStatus==="DRAFT") && (trancheList?.length>0 && props?.props?.apiData?.financerMemberUID === companyId) &&(
                  
                    <Button
                        type="button"
                        variant="contained"
                        color="primary"
                            style={{ minWidth: "220px", color: "white" }}
                            onClick={handleSandToFinancer}
                        >
                            Send To Co-Financers
                        </Button>
                      
                        )}

{(props?.props?.apiData?.loanTranches[0]?.loanTrancheStatus!=="LOCKED") && (props?.props?.apiData?.financerMemberUID !==undefined) && (props?.props?.apiData?.financerMemberUID === companyId) &&(
  <>
       
  <Button
variant="contained"
color="primary"
style={{ minWidth: "150px", margin: "0px 5px" }}
onClick={handleAddNewTranche}
>
Add Tranche
</Button>
{sendBool===true &&(
<Button
variant="contained"
color="primary"
style={{ minWidth: "150px", margin: "0px 5px" }}
onClick={()=>completeLoanStructure("LOCKED")}
>
Complete Loan Structure
</Button>)}
</>


  
 
)}
                          </div>
                          {/* <div>BAID- {JSON.stringify(sendBool)}</div> */}
                          {(props?.props?.apiData?.loanTranches[0]?.loanTrancheStatus==="LOCKED") && (
                          <div className={styles.messagenotify}>
                          The loan structuring has been completed and you can refer to finance deal flow for futhur actions.
                          </div>)}
        </>
    )
}

export default TrancheList