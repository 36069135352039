import React, { useState, useContext } from "react";
import { Grid } from "@mui/material";
import DateInput from "components/fields/DateInput";
import Input from "components/fields/Input";
import Textarea from "components/fields/TextArea";
import "react-datepicker/dist/react-datepicker.css";
import Currenciesr from "jsons/Currenciesr.json";
import Select from "components/fields/Select";

export default ({ Data }) => {
  console.log("DataData", Data);
  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder="Service Category"
            readOnly={true}
            value={Data.serviceCategory[0]}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder="Service Sub-Category"
            readOnly={true}
            value={Data.serviceSubCategory[0]}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Select
            options={Currenciesr}
            placeholder="Currency"
            readOnly={true}
            value={Data.servicePaymentCurrency}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder={`Min Price(${Data.servicePaymentCurrency})`}
            readOnly={true}
            value={Data.servicePriceLow}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder="Payment Method"
            readOnly={true}
            value={Data.preferredPaymentMethod}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder={`Max Price(${Data.servicePaymentCurrency})`}
            readOnly={true}
            value={Data.servicePriceHigh}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder="Mode Of Payment"
            readOnly={true}
            value={Data.modeOfPayment}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder="Preferred Payment Date"
            readOnly={true}
            value={new Date(
              Data.preferredPaymentDate
            ).toLocaleDateString()}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} style={{ padding: "10px" }}>
        <Textarea
          placeholder="Service Description"
          readOnly={true}
          value={Data.ServiceReqDescription}
        />
      </Grid>
      <Grid item xs={12} md={6} style={{ padding: "10px" }}>
        <Textarea
          placeholder="Pricing Description"
          readOnly={true}
          value={Data.pricingPreferences}
        />
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder="Requisition Start Date"
            readOnly={true}
            value={new Date(
              Data.serviceRequisitionStartDate
            ).toLocaleDateString()}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            placeholder="Requisition End Date"
            readOnly={true}
            value={new Date(
              Data.serviceRequisitionEndDate
            ).toLocaleDateString()}
          />
        </Grid>
      </Grid>
    </div>
  );
};
