import React from "react";
import Modal from "components/UI/Modal/Modal";

const LimitedApiModal = ({ isLimited, setIsLimited }) => {
  return (
    <div>
      <Modal
        show={isLimited}
        onHide={() => setIsLimited(false)}
        Data={
          <>
            <span>
              Your API usage limit has exceeded. Please contact tradxlink
              support for increasing the API limit
            </span>
          </>
        }
        Heading={"API usage limit"}
      />
    </div>
  );
};

export default LimitedApiModal;
