import React, { useState } from "react";
import { Card, Grid, Button } from "@mui/material";
import { MdAdd } from "react-icons/md";
import styles from "./trade.module.css";
import Table from "components/table";
import { TradeAccountColumn } from "./column";
import FileUpload from "./fileupload.js";

function OpenAccount(props) {
  const [active, setActive] = useState("");
  const [fileUpload, setFileUpload] = useState(false);

  const tabs = [
    { name: "New Invoice", value: "new" },
    { name: "Financed", value: "financed" },
    { name: "Closed", value: "closed" },
    { name: "In-Progress", value: "progress" },
  ];

  const api_response = [
    {
      select: "true",
      open_trade_id: "HDFC",
      counter_party: "1000",
      created_at: "01-01-2022",
      status: ["Modify"],
    },
    {
      select: "false",
      open_trade_id: "HDFC_1",
      counter_party: "1000",
      created_at: "01-01-2022",
      status: ["Success"],
    },
  ];
  const enableSchedulePayment = props.enableSchedulePayment;
  const reverse = () => {
    setFileUpload(false);
  };

  const goBack = {
    reverse,
    enableSchedulePayment,
  };
  return (
    <>
      {fileUpload ? (
        <FileUpload goBack={goBack} />
      ) : (
        <div
          style={{
            borderRadius: "10px",
            background: "#343434",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          <Card
            style={{
              backgroundColor: "#000000",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h4 style={{ color: "white", margin: "0px" }}>Search Trades</h4>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setFileUpload(true)}
              >
                <MdAdd size={25} />
                Start New Open Trade
              </Button>
            </div>
            <Grid container style={{ padding: "15px 0px 0px 0px" }}>
              <Grid container xs={12} md={10}>
                {tabs.map((item, index) => (
                  <Grid item xs={6} md={3} style={{ padding: "10px" }}>
                    <div className={styles.searchDiv}>
                      <div
                        key={index}
                        onClick={() => setActive(index)}
                        className={
                          index == active
                            ? styles.activeDiv
                            : styles.nonActiveDiv
                        }
                      >
                        {item.name}
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} md={2} style={{ padding: "10px" }}>
                <input
                  type="text"
                  placeholder="Search"
                  className={styles.inputSearch}
                />
              </Grid>
            </Grid>
          </Card>
          <br></br>
          <Card
            style={{
              backgroundColor: "#000000",
              padding: "20px",
              borderRadius: "5px",
            }}
          >
            <h4 style={{ color: "white", margin: "0px" }}>Search Results</h4>

            <div className={styles.wrapper}>
              <div className={styles.tableContainer}>
                {Array.isArray(api_response) ? (
                  <Table columns={TradeAccountColumn} data={api_response} />
                ) : (
                  <p>No Records</p>
                )}
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
}

export default OpenAccount;
