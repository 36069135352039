import React, { useState } from 'react'
import TableNoChat from "components/table/TableNoChat";
import styles from './syndicate.module.css'
import { DrawDownColumn } from "./column";
import { Button, Grid } from '@mui/material';
import TrancheList from "./TrancheList";
import { useEffect } from 'react';

export default function StepperThree(props) {
    console.log("FInal", props)
    const [apicalls, setApicalls] = useState(false);
    const {companyId} = props;
    const handleAcceptDD = props.handleAcceptDD;
    const handleRejectDD = props.handleAcceptDD;
    const editTranche = props.editTranche;
    const dealStructure = {
        disabledealStructure: props.disabledealStructure,
        props,
        handleAcceptDD,
        handleRejectDD,
        editTranche
    }
    const haddleSubmit =() =>{
        dealStructure.push({
            disabledealStructure: props.disabledealStructure,
            props:props
        })
        setApicalls(true)
    }
    const editDrawdown = ()=>{
        props.addDrawdown();
    }
    const removeDrowdown = (e, item) => {
        props.removeDrowdown(e, item)
    }
    useEffect(()=>{

    },[props])
    return (
        <>
            {props.apicall ?
                <TrancheList {...dealStructure} companyId={companyId} />
                :
                <>

                    {props.drawdownList &&
                        <div style={{ textAlign: "right" }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "250px", margin: "0px 20px" }}
                                onClick={() => props.addDrawdown()}
                            >
                                Add Another Drawdowns
                            </Button>
                        </div>}
                    <br></br>
                  
                    <div className={styles.wrapper}>
                        <div className={styles.tableContainer}>
                            {Array.isArray(props.drawdownList || props.data) ? (
                                <TableNoChat columns={DrawDownColumn(editDrawdown, removeDrowdown, companyId, props?.apiData?.financerMemberUID, handleAcceptDD, handleRejectDD)} data={props.drawdownList || props.data} companyId={companyId} financerMemberUID={props?.apiData?.financerMemberUID}/>
                            ) : (
                                <p>No Records</p>
                            )}
                        </div>
                    </div>

                    <br></br>

                    {props.drawdownList &&
                         <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ minWidth: "200px", margin: "0px 20px" }}
                                    onClick={() => props.showDrawdown()}
                                >
                                    Back
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ minWidth: "200px", margin: "0px 20px" }}
                                    type="button"
                                    onClick={props.onSubmit}
                                  
                                >
                                    Submit
                                </Button>
                          
                        </div>
                    }
                </>
            }
        </>
    )
}
