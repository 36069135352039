import React, { useCallback, useState, useEffect, useContext } from "react";
import { ButtonStyled } from "components/Container.styled";
import { NetworkURL, documentURL } from "services/BaseURLInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Error, Success } from "components/notifications/responseHandle";
import styles from "./edit.module.css";
import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import Content from "components/UI/Layout/Content";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { EditProfileInfo } from "utils/Section/FormInfo";
import { getIdTokenAsync } from "utils/authUtil";
import Modal from "components/UI/Modal/Modal";
import Upload from "./Upload";
import CompanyDescription from "./ProfileForm/companyDescription";
import CompanyContacts from "./ProfileForm/companyContacts";
import CompanyRegistration from "./ProfileForm/companyRegistrationDetails";
import { AuthContext } from "contexts/Auth/AuthContext";

import { GoLocation } from "react-icons/go";
import { EditProfileValdations } from "utils/Validations/Profile";
import cn from "classnames";

const Edit = () => {
  const { isApiTrigger } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  const { instance, accounts } = useMsal();
  const [companyData, setCompanyData] = useState(null);
  const [coordinate, setCoordinate] = useState({});
  const [isUpload, setIsUpload] = useState(null);
  const [documentData, setDocumentData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const [imageData, setImageData] = useState({
    name: "",
    file: "",
  });

  const [contactNo, setContactNo] = useState({
    primaryNo: "",
    secondaryNo: "",
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${NetworkURL}financers/${companyId}`;
      } else {
        URL = `${NetworkURL}companies/${companyId}`;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          let { message } = data;
          setIsLoading(false);
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setCompanyData(data);
            let id = data.companyLogoURL || data.financerLogo;
            if (data.companyLogoURL || data.financerLogo) {
              getIdTokenAsync(instance, accounts).then((response) => {
                UseRequest(
                  `${documentURL}documents/${id}`,
                  null,
                  response
                ).then((res) => {
                  let { message, path } = res;
                  if (message || path) {
                    Error(message);
                  } else {
                    setDocumentData(res);
                  }
                });
              });
            }
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [isApiTrigger]);

  useEffect(() => {
    if (companyData) {
      if (memberType === "FINANCER") {
        setContactNo({
          ...contactNo,
          primaryNo:
            (companyData &&
              companyData.financerPhone &&
              companyData.financerPhone[0]) ||
            "",
          secondaryNo:
            (companyData &&
              companyData.financerPhone &&
              companyData.financerPhone[1]) ||
            "",
        });
      } else {
        setContactNo({
          ...contactNo,
          primaryNo:
            (companyData &&
              companyData.companyPhone &&
              companyData.companyPhone[0]) ||
            "",
          secondaryNo:
            (companyData &&
              companyData.companyPhone &&
              companyData.companyPhone[1]) ||
            "",
        });

        setCoordinate({
          ...coordinate,
          locationCordinate:
            companyData && companyData.memberGeoLocation
              ? JSON.parse(companyData.memberGeoLocation)
              : {},
        });
      }
    }
  }, [companyData]);

  useEffect(() => {
    let contactNum = [];
    contactNum.push(contactNo.primaryNo);
    contactNum.push(contactNo.secondaryNo);
    if (memberType === "FINANCER") {
      setCompanyData({
        ...companyData,
        financerPhone: contactNum,
      });
    } else {
      setCompanyData({
        ...companyData,
        companyPhone: contactNum,
      });
    }
  }, [contactNo.primaryNo, contactNo.secondaryNo]);

  const handleChange = (e, name) => {
    if (name === "country") {
      const { name, value } = e?.target;
      setCompanyData({
        ...companyData,
        [name]: value?.value,
      });
    } else if (name === "dateOfIncorporation") {
      setCompanyData({
        ...companyData,
        [name]: new Date(e).toISOString(),
      });
    } else if (
      e.target.name === "companyAddress" ||
      e.target.name === "financerAddress"
    ) {
      let TempAddAddress = [];
      TempAddAddress.push(e.target.value);
      setCompanyData({
        ...companyData,
        [e.target.name]: TempAddAddress,
      });
    } else if (e.target.name === "companyPhonePrimary") {
      setContactNo({
        ...contactNo,
        primaryNo: e.target.value,
      });
    } else if (e.target.name === "companyPhoneSecondary") {
      setContactNo({
        ...contactNo,
        secondaryNo: e.target.value,
      });
    } else if (
      e.target.name === "companyProductsServices" ||
      e.target.name === "financerProductsServices"
    ) {
      setCompanyData({
        ...companyData,
        [e.target.name]: [e.target.value],
      });
    } else {
      setCompanyData({
        ...companyData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // const handleLocation = () => {
  //   setIsLoading(true);
  //   navigator.geolocation.getCurrentPosition(function (position) {
  //     let cordArray = [];
  //     cordArray.push(position.coords.latitude);
  //     cordArray.push(position.coords.longitude);
  //     if (memberType === "FINANCER") {
  //       setCompanyData({
  //         ...companyData,
  //         financerGeoLocation: JSON.stringify(cordArray),
  //       });
  //     } else {
  //       setCompanyData({
  //         ...companyData,
  //         memberGeoLocation: JSON.stringify(cordArray),
  //       });
  //     }
  //     setIsLoading(false);
  //   });
  // };

  const handleLocation = () => {
    setIsLoading(true);

    navigator.geolocation.getCurrentPosition(
      function (position) {
        console.log("positionposition", position);
        const cordArray = [position.coords.latitude, position.coords.longitude];
        setCompanyData((prevCompanyData) => {
          if (memberType === "FINANCER") {
            return {
              ...prevCompanyData,
              financerGeoLocation: JSON.stringify(cordArray),
            };
          } else {
            return {
              ...prevCompanyData,
              memberGeoLocation: JSON.stringify(cordArray),
            };
          }
        });
        setIsLoading(false);
      },
      function (error) {
        console.error("Error getting location:", error);
        setIsLoading(false);
      }
    );
  };

  const handleSave = (e) => {
    e.preventDefault();
    let checkValidation = EditProfileValdations(companyData);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      if (memberType === "FINANCER") {
        companyData.financerLegalName = "financerLegalName";
        companyData.financerTradeName = "financerTradeName";
        companyData.reference = "reference";
        companyData.rating = "rating";
        companyData.financerWhatsApp = "financerWhatsApp";
        companyData.financerPlace = "financerPlace";
        companyData.financerPin = "financerPin";
        companyData.financerStateCode = "financerStateCode";
        companyData.financerLogo = "";
        companyData.profileCompletion = "profileCompletion";
        companyData.verified = "verified";
        // companyData.financerAddress = "financerAddress";
        companyData.isActive = true;
        companyData.isDeleted = "isDeleted";
        companyData.tag = "tag";
        companyData.updateBy = "updateBy";
      } else {
        companyData.dateOfIncorporation =
          companyData.dateOfIncorporation || new Date();
      }

      setIsLoading(true);
      const options = {
        method: "PUT",
        body: companyData,
      };
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${NetworkURL}financers/${companyId}`;
      } else {
        URL = `${NetworkURL}companies/${companyId}`;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, options, response).then((data) => {
          setIsLoading(false);
          let { message, error } = data;
          if (message || error) {
            let display = message || "Something went wrong";
            Error(display);
            setIsError(message);
          } else {
            Success("Added Successfully");
            handleNavigate(`/profile`);
          }
        });
      });
    }
  };

  return (
    <Content>
      {isLoading ? (
        <>
          <span>Loading...</span>
          <Loader />
        </>
      ) : (
        <>
          <Modal
            show={isUpload}
            onHide={() => setIsUpload(false)}
            Data={
              <Upload
                imageData={imageData}
                setImageData={setImageData}
                companyData={companyData}
                setIsUpload={setIsUpload}
              />
            }
            Heading={"Upload Image"}
          />

          {companyData && !isError && (
            <Content padding="0%">
              <OverLayTrigger value={EditProfileInfo} marginleft={"10px"} />
              <form onSubmit={(e) => handleSave(e)}>
                <CompanyDescription
                  companyData={companyData}
                  handleChange={handleChange}
                  handleLocation={handleLocation}
                  setIsUpload={setIsUpload}
                  coordinate={coordinate}
                  documentData={documentData}
                />

                <hr
                  style={{
                    border: "2px solid white",
                    borderRadius: "6px",
                    margin: "10px 20px 10px 10px",
                  }}
                />

                <CompanyContacts
                  companyData={companyData}
                  handleChange={handleChange}
                  handleLocation={handleLocation}
                  contactNo={contactNo}
                />

                <hr
                  style={{
                    border: "2px solid white",
                    borderRadius: "6px",
                    margin: "10px 10px 10px 10px",
                  }}
                />

                <CompanyRegistration
                  companyData={companyData}
                  handleChange={handleChange}
                />

                <hr
                  style={{
                    border: "2px solid white",
                    borderRadius: "6px",
                    margin: "10px 10px 10px 10px",
                  }}
                />

                <div className={styles.btnDiv}>
                  <ButtonStyled onClick={() => handleNavigate(`/profile`)}>
                    Cancel
                  </ButtonStyled>
                  <ButtonStyled type="submit">Save</ButtonStyled>
                </div>
              </form>
            </Content>
          )}

          {isError && <>{isError}</>}
        </>
      )}
    </Content>
  );
};

export default Edit;
