import styled from "styled-components";

const Screen= styled.div`
  width: 1366px;
  height: 1289px;
  blend: pass through;
  background: linear-gradient(180deg, #218EEB 0%, rgba(33, 142, 235, 0.76) 100%);
  .header{
    width: 270px;
    height: 59px;
    padding-top: 71px;
    margin-left: 548px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 50px;
    line-height: 59px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
  }
  .subHeader{
    width: 78px;
    height: 35px;
    margin-top: 10px;
    margin-left: 645px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 30px;
    line-height: 35px;
    line-height: 100%;
    align: Left;
    vertical align: Top;
  }
  .newPasswordHeading{
    width: 426px;
    height: 59px;
    margin-top: 93px;
    margin-left: 250px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 50px;
    line-height: 59px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
  }
  .horizontalLine{
    width: 866px;
    margin-top: 9px;
    margin-left: 250px;
    align:center
    hr{
        width:3px
    }
  }
    
  .password{
    display: flex;  
    width: 526px;
    height: 59px;
    margin-top: 57px;
    margin-left: 250px;
    background: Solid #E6E6E6;
  }
  .password> label{
    display: flex;  
    align-items: flex-end;
  }

  .password> input{ 
    border: none;
    color:#E6E6E6;
    width: 526px;
    padding-top:43px;
    margin-left:14px;
    background: transparent;
    flex-wrap: wrap;
    ::placeholder { 
       color: white;
       opacity: 1;  
    }
  }
  .password>input:focus {
    outline: none;
  }
  .checkBox{
    display: flex;
  }
  .larger{
    width: 30px;
    height: 30px;
    margin-left: 250px;
    margin-top: 85px;
    border-radius: 5px;
    border: 2px solid #E6E6E6;
  }
  .rememberpassword{
    width: 190px;
    height: 23px;
    margin-left: 12px;
    margin-top: 85px;
    font-family: Roboto;
    font-style: Regular;
    font-size: 20px;
    line-height: 23px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
    color: #E6E6E6;

  }
  .forgotpassword{
    width: 151px;
    height: 23px;
    margin-left: 447px;
    margin-top: 85px;
    color: #FFFFFF;
    font-family: Roboto;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
    text-decoration: underline;
  }
  .submitbutton{
    width: 867px;
    height: 85px;
    margin-left: 250px;
    margin-top: 76px;
    background: #FFFFFF;
    border-radius: 10px

  }

`;

export default Screen;
