export const AlertHeader = [
    { name: "Alert" },
    { name: "Origin" },
    { name: "Type" },
    { name: "Response" },
    { name: "Date" }
];

export const api_response = [
    {
        "Alert": "Alert_New1",
        "Origin": "Br_Dem1",
        "Type": "Scheduled",
        "Response": "Validated",
        "Date": "12-08-2022"
    },
    {
        "Alert": "Alert_past",
        "Origin": "Br_Dem2",
        "Type": "In-Progress",
        "Response": "due",
        "Date": "08-01-2022"
    },
]

