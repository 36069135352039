import React, { useState, useEffect, useContext } from "react";
import classes from "./Schedules.module.css";

import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import Table from "react-bootstrap/Table";
import { ParticipantHeader } from "utils/Section/trade";
import cn from "classnames";

import { CompanyList } from "utils/API/CompanyList.js";
import { Countries } from "utils/DropDown/Country.js";
import { FindCompanyName } from "utils/helper";

import {
  ParticipantRoles,
  ConditionCheck,
  ParticipantStatus,
} from "utils/Section/trade";
import { openTradeAccount } from "services/BaseURLInstance";
import moment from "moment";

import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { AddParticipantsValdations } from "utils/Validations/OpenTradeAccount";

import { STATUS, COUNTRY } from "utils/Constants";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

const Participant = () => {
  const { instance, accounts } = useMsal();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isUpdateFormOpen, setIsUpdateFormOpen] = useState(false);
  const [companyList, setCompanyList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(0);

  const scheduleData = JSON.parse(sessionStorage.getItem("scheduleData"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const List = CompanyList();
  useEffect(() => {
    if (List.response) {
      let temp = List.response.map((item) => {
        return { label: item.memberName, value: item.memberUID };
      });
      setCompanyList(temp);
    }
  }, [List.response]);

  const [DatePicker, setDatePicker] = useState(new Date());

  const [updateParticipants, setUpdateParticipants] = useState({
    participant: "",
    role: "",
    status: "",
    since: "",
    country: "",
    signatory: "",
    share: "",
  });

  const handleChange = (event) => {
    if (event.target.name === "since") {
      let newDate = new Date(event.target.value);
      let dateString = newDate.toISOString();

      let newDate1 = moment(new Date(event.target.value)).format("YYYY-MM-DD");
      if (event.target.name === "since") {
        setDatePicker(newDate1);
      }

      setUpdateParticipants({
        ...updateParticipants,
        [event.target.name]: dateString,
      });
    } else if (event.target.name === "country") {
      setUpdateParticipants({
        ...updateParticipants,
        [event.target.name]: event.target.value,
      });
    } else {
      setUpdateParticipants({
        ...updateParticipants,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let checkValidations = AddParticipantsValdations(updateParticipants);
    if (checkValidations) {
      Error(checkValidations);
      return;
    } else {
      scheduleData.participants.push({
        participant: updateParticipants.participant,
        role: updateParticipants.role,
        status: updateParticipants.status,
        since: updateParticipants.since,
        country: updateParticipants.country,
        // signatory: updateParticipants.signatory,
        // share: updateParticipants.share,
      });
      setIsLoading(true);
      // const requestOptions = {
      //   method: "PUT",
      //   requireAuthentication: true,
      //   headers: {
      //     Authorization: "Bearer " + idToken,
      //     "Content-Type": "application/json",
      //     "x-api-key":
      //       "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      //   },
      //   body: JSON.stringify(scheduleData),
      // };
      let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
      // await fetch(URL, requestOptions)
      //   .then((response) => response.json())
      //   .then((data) => {
      //     setIsLoading(false);
      //     let { message, status } = data;
      //     if (message || status) {
      //       Error(message || "Something Went Wrong");
      //     } else {
      //       Success("Added Successfully");
      //       setIsAddFormOpen(false);
      //     }
      //   });
      const options = {
        method: "PUT",
        body: scheduleData,
      };

      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, options, response).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            Success("Added Successfully");
            setIsAddFormOpen(false);
          }
        });
      });
    }
  };

  const handleModify = (item, index) => {
    setUpdateIndex(index);
    setIsUpdateFormOpen(true);
    setUpdateParticipants({
      participant: item && item.participant ? item.participant : "",
      role: item && item.role ? item.role : "",
      status: item && item.status ? item.status : "",
      since: item && item.since ? item.since : "",
      country: item && item.country ? item.country.slice(-2) : "",
      // signatory: item && item.signatory ? item.signatory : "",
      // share: item && item.share ? item.share : "",
    });
    setDatePicker(moment(new Date(item.since)).format("YYYY-MM-DD"));
  };

  const handleModifyParticipant = async (e) => {
    e.preventDefault();
    let checkValidations = AddParticipantsValdations(updateParticipants);
    if (checkValidations) {
      Error(checkValidations);
      return;
    } else {
      scheduleData.participants[updateIndex].participant =
        updateParticipants.participant;
      scheduleData.participants[updateIndex].role = updateParticipants.role;
      scheduleData.participants[updateIndex].status = updateParticipants.status;
      scheduleData.participants[updateIndex].since = updateParticipants.since;
      scheduleData.participants[updateIndex].country =
        updateParticipants.country;
      setIsLoading(true);
      const requestOptions = {
        method: "PUT",
        requireAuthentication: true,
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(scheduleData),
      };
      let URL = `${openTradeAccount}openaccounttrades/schedules/${scheduleData.openTradeScheduleUID}`;
      await fetch(URL, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            Success("Updated Successfully");
            setIsAddFormOpen(false);
          }
        });
    }
  };

  const Status = (status) => {
    switch (status) {
      case "NOT_REQUESTED":
        return STATUS(status, "primary");
      case "SIGNED":
        return STATUS(status, "success");
      case "IN_QUEUE":
        return STATUS(status, "warning");
      case "DECLINED":
        return STATUS(status, "danger");
      case "CANCELLED":
        return STATUS(status, "danger");
      default:
        return status;
    }
  };

  return (
    <div style={{ marginTop: "20px" }}>
      {isLoading || List.isLoading ? (
        <Loader />
      ) : (
        <>
          {List && List.response && (
            <>
              {!isAddFormOpen && !isUpdateFormOpen && (
                <>
                  <ButtonDiv>
                    <Button
                      onClick={() => {
                        setIsAddFormOpen(true);
                        setIsUpdateFormOpen(false);
                      }}>
                      + Add Participant
                    </Button>
                  </ButtonDiv>
                  <Table bordered responsive className={cn(classes.Table)}>
                    <thead>
                      <tr>
                        {ParticipantHeader &&
                          ParticipantHeader.map((header, i) => (
                            <td key={i}>{header.name}</td>
                          ))}
                      </tr>
                    </thead>

                    <tbody>
                      {scheduleData &&
                        scheduleData.participants &&
                        scheduleData.participants.map((item, index) => (
                          <tr key={index}>
                            <td onClick={() => handleModify(item, index)}>
                              {item.participant &&
                                List.response &&
                                FindCompanyName(
                                  List.response,
                                  item.participant
                                )}
                            </td>
                            <td>{COUNTRY(item.country)}</td>
                            <td>{item.role}</td>
                            <td>{new Date(item.since).toLocaleDateString()}</td>
                            <td>{Status(item.status)}</td>
                          </tr>
                        ))}
                      {scheduleData &&
                        scheduleData.participants &&
                        scheduleData.participants.length === 0 && (
                          <span>No participants</span>
                        )}
                    </tbody>
                  </Table>
                </>
              )}

              {isAddFormOpen && (
                <>
                  <h6 className="pt-10">Add Participant</h6>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.FormDiv}>
                      <div>
                        <Select
                          name="participant"
                          placeholder="Participant"
                          value={updateParticipants.participant}
                          options={companyList}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="country"
                          placeholder="Country"
                          value={updateParticipants.country}
                          options={Countries}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="status"
                          placeholder="Status"
                          value={updateParticipants.status}
                          options={ParticipantStatus}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="role"
                          placeholder="Role"
                          value={updateParticipants.role}
                          options={ParticipantRoles}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="signatory"
                          placeholder="Signatory"
                          options={ConditionCheck}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="share"
                          placeholder="Share"
                          options={ConditionCheck}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <input
                          type="date"
                          name="since"
                          placeholder="Since"
                          value={DatePicker}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <ButtonDiv>
                      <Button onClick={() => setIsAddFormOpen(false)}>
                        Cancel
                      </Button>
                      <Button type="submit">Add</Button>
                    </ButtonDiv>
                  </form>
                </>
              )}

              {isUpdateFormOpen && (
                <>
                  <h6 className="pt-10">Update Participant</h6>
                  <form onSubmit={handleModifyParticipant}>
                    <div className={classes.FormDiv}>
                      <div>
                        <Select
                          name="participant"
                          placeholder="Participant"
                          value={updateParticipants.participant}
                          options={companyList}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="country"
                          placeholder="Country"
                          value={updateParticipants.country}
                          options={Countries}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="status"
                          placeholder="Status"
                          value={updateParticipants.status}
                          options={ParticipantStatus}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="role"
                          placeholder="Role"
                          value={updateParticipants.role}
                          options={ParticipantRoles}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="signatory"
                          placeholder="Signatory"
                          value={updateParticipants.signatory}
                          options={ConditionCheck}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <Select
                          name="share"
                          placeholder="Share"
                          value={updateParticipants.share}
                          options={ConditionCheck}
                          handleChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <input
                          type="date"
                          name="since"
                          placeholder="Since"
                          value={DatePicker}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <ButtonDiv>
                      <Button onClick={() => setIsUpdateFormOpen(false)}>
                        Cancel
                      </Button>
                      <Button type="submit">Add</Button>
                    </ButtonDiv>
                  </form>
                </>
              )}
            </>
          )}

          {List && List.isError && (
            <span>
              <span>{List.isError}</span>
              <span>Something went wrong</span>
            </span>
          )}
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}
    </div>
  );
};

export default Participant;
