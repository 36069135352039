import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    line-height: 1.5;
  }

  body {
    font-family: 'Roboto', sans-serif;
  }

  body.fontLoaded {
    font-family: 'Roboto', sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
    background: #000000;
    color: #fff;
  }

  p,
  label {
    font-family: 'Roboto', sans-serif;
    line-height: 1.5em;
  }

  .table { 
    color: #fff !important;
  }

  .header1 {
    margin-left: -1% !important;
  }

  .row > div {
    border: none !important;
    border-bottom: 0.1px solid #343434 !important;
  }

  .header1 > div {
    border-left: none !important;
  }

`;

export default GlobalStyle;
