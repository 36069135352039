import React, { useState } from "react";
import classes from "./ServiceCard.module.css";
import { RiWechatLine } from "react-icons/ri";
import { MdOutlineAttachEmail, MdVerified } from "react-icons/md";
import { FindCompanyName } from "../../../utils/helper";
import { CheckCountryTitle } from "utils/helper";
import { FiEdit } from "react-icons/fi";
import { AiFillStar, AiOutlineDelete } from "react-icons/ai";
import { SERVICE_LABEL, INDUSTRY_LABEL } from "utils/Constants";
import cn from "classnames";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { fetchMemberdata } from "utils/memberchat";

const ServiceCard = ({
  item,
  companyId,
  companyList,
  selectedCategory,
  handleCheckbox,
  handleCreateRequisition,
  isCheckboxHide, // it will be hide in Network profile
  selectedList,
  handleServiceUpdate, // update Service
  isDisabled,
  handleServiceDelete,
  toggle,
  isOpen,
  toggleChat,
  hanndleCraateGroup,
}) => {
  const text = item.serviceDescription;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const ptext = item.pricingDescription;
  const [pIsReadMore, setPIsReadMore] = useState(true);
  const togglePReadMore = () => {
    setPIsReadMore(!pIsReadMore);
  };

  const handleChat = async (item) => {
    let memberUserId = [companyId, item.memberId];

    let res = await fetchMemberdata(memberUserId);
    // let name = FindCompanyName(companyList, item.memberId);
    let name1 = item.memberId
      ? FindCompanyName(companyList, item.memberId).split(" ")[0]
      : "NA";
    let name2 = FindCompanyName(companyList, companyId).split(" ")[0];
    let nameArray = [name1, name2];
    nameArray.sort();
    let name = nameArray.join("-");
    if (res?.length > 0) {
      hanndleCraateGroup(res, name, name);
    }
  };

  return (
    <div className={classes.ServiceCard} style={{ padding: "1% 2%" }}>
      <div className={classes.ServiceHead}>
        <div className={classes.companyName}>
          <span style={{ fontSize: "17px" }}>
            {companyList && companyList.length > 0
              ? FindCompanyName(companyList, item.memberId)
              : "Unknown"}
          </span>
          <span style={{ margin: "0px 0px 0px 5px" }}>
            {<MdVerified size={22} className={classes.checkIcon} />}
          </span>
        </div>
        <div className={classes.ServiceHead1} style={{ width: "14%" }}>
          {companyId != item.memberId && (
            <span className={classes.ServiceBtn2}>
              <button style={{ backgroundColor: "lightgrey" }}>
                Add to Favorite
              </button>
            </span>
          )}
        </div>
        <div style={{ width: "37.5%" }}></div>
        <div className={classes["service-head-right"]} style={{ width: "6%" }}>
          {companyId === item.memberId && (
            <>
              <span
                className={classes["service-head-right"]}
                onClick={() => handleServiceUpdate(item)}
                style={{
                  paddingRight: "10px",
                  color: "#42a5f5",
                  cursor: "pointer",
                }}>
                <FiEdit size={30} />
              </span>

              <span
                onClick={() => handleServiceDelete(item.serviceId)}
                style={{ cursor: "pointer", color: "red" }}>
                <AiOutlineDelete size={30} />
              </span>
            </>
          )}

          {selectedCategory !== "ALL" &&
            companyId !== item.memberId &&
            !isCheckboxHide && (
              <>
                <input
                  className={
                    isDisabled
                      ? cn(classes.isDisabled, classes.Checkbox)
                      : cn(classes.Checkbox)
                  }
                  // className={classes.Checkbox}
                  type="checkbox"
                  // checked={CheckboxValue}
                  onClick={(e) => handleCheckbox(e, item)}
                />
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Body>
                        <strong>
                          To create a requisition search the service and click
                          on checkbox to proceed{" "}
                        </strong>
                      </Popover.Body>
                    </Popover>
                  }>
                  <span>
                    <AiOutlineInfoCircle size={26} />
                  </span>
                </OverlayTrigger>
              </>
            )}
          {/* {<OverlayTrigger
            trigger={["hover", "focus"]}
            key="top"
            placement="top"
            overlay={
              <Popover id="popover-positioned-top">
                <Popover.Body>
                  <strong>Select to create Requistion Form </strong>
                </Popover.Body>
              </Popover>

            }>
            <span >
              <AiOutlineInfoCircle size={26} />
            </span>
          </OverlayTrigger>} */}
        </div>
      </div>
      <div className={classes.ServiceBody}>
        <div>
          <div className={classes["flexbox-start"]}>
            <span>
              Service Category : {SERVICE_LABEL(item.serviceCategory[0])}
            </span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>
              Service Sub-Category : {SERVICE_LABEL(item.serviceSubCategory[0])}
            </span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>
              Available since :{" "}
              {new Date(item.createdDate).toLocaleDateString()}
            </span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>Completed Subscriptions : 0</span>
          </div>

          <div className={classes["flexbox-start"]}>
            <span>
              Service Rating :{" "}
              <span>
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
                <AiFillStar color="#FFA100" />
              </span>
            </span>
          </div>

          <div className={classes["icon-group"]} style={{ cursor: "pointer" }}>
            {companyId != item.memberId && (
              <OverlayTrigger
                trigger={["hover", "focus"]}
                key="top"
                placement="top"
                overlay={
                  <Popover id="popover-positioned-top">
                    <Popover.Body>
                      <strong>Chat</strong>
                    </Popover.Body>
                  </Popover>
                }>
                <span
                  style={{ cursor: "pointer" }}
                  // onClick={() => toggleChat("global")}
                  onClick={() => handleChat(item)}>
                  <RiWechatLine size={30} />
                </span>
              </OverlayTrigger>
              // <span
              //   style={{ cursor: "pointer" }}
              //   // onClick={() => toggleChat("global")}
              //   onClick={() => handleChat(item)}>
              //   <RiWechatLine size={30} />
              // </span>
            )}
            {companyId === item.memberId ? (
              <span
                style={{ marginLeft: "10px" }}
                className={classes.isDisabled}>
                <MdOutlineAttachEmail size={30} />
              </span>
            ) : (
              <span
                style={{ marginLeft: "10px" }}
                className={classes.isDisabled}>
                <MdOutlineAttachEmail size={30} />
              </span>
            )}

            {/* <FaWhatsappSquare size={30} /> */}
          </div>
        </div>

        <div>
          <div className={classes["supplier-text"]}>
            <div style={{ fontSize: "16px" }}>Description :</div>
            <div>
              {isReadMore ? text.slice(0, 80) : text}
              {text.length > 80 && (
                <span
                  onClick={toggleReadMore}
                  style={{ color: "#42a5f5", cursor: "pointer" }}>
                  {isReadMore ? "...Read more" : " Show less"}
                </span>
              )}
            </div>
          </div>
          <div className={classes["supplier-text"]}>
            <div style={{ fontSize: "16px" }}>Pricing :</div>
            <div>
              {pIsReadMore ? ptext.slice(0, 80) : ptext}
              {ptext.length > 80 && (
                <span
                  onClick={togglePReadMore}
                  style={{ color: "#42a5f5", cursor: "pointer" }}>
                  {pIsReadMore ? "...Read more" : " Show less"}
                </span>
              )}
            </div>
          </div>
          <div className={classes.supplier}>
            <div style={{ fontSize: "16px" }}>Industries :</div>
            <div className={classes.supplierList}>
              {item.industry &&
                item.industry.map((item, i) => (
                  <button key={i} style={{ cursor: "default" }}>
                    {INDUSTRY_LABEL(item)}
                  </button>
                ))}
            </div>
          </div>
          <div className={classes.supplier}>
            <div style={{ fontSize: "16px" }}>Geography :</div>
            <div className={classes.supplierList}>
              {item.countriesOfOperations.map((country, i) => (
                <button key={i} style={{ cursor: "default" }}>
                  {CheckCountryTitle(country)}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
