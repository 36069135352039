import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import { CheckCategoryTitle, CheckCountryTitle, CheckCurrencyTitle ,CheckPortTitle } from "utils/helper";
import Currenciesr from 'jsons/Currenciesr.json';
import { Countries } from "utils/DropDown/Country";


import { TextArea } from "components/fields";
import MultiSelect from "components/fields/MultiSelect";



const ComplianceTrade =({ response })=>{





    const [serviceDetails, setServiceDetails] = useState([]);
    useEffect(() =>{
    if(response && response.ServiceReqDetails){
    setServiceDetails(JSON.parse(response.ServiceReqDetails))
    
    //JSON.parse(response.ServiceReqDetails.commodity)
    }
    },[response]);









    let listService = serviceDetails.service?.map((employee, index) => {
        return (
         employee.value
        )
      })










    
    return( <>
        {response && serviceDetails ?
            <>
    
      <div style={{  margin:"10px" }}>Trade Compliance Services Required for ?

</div>

<div className={classes.source} style={{ margin: "10px" }}>
        <div style={{ marginLeft: "1%", width: "50%" }}>
        
      
      <input
            className={classes.radio}
            type="radio"
            name="serviceType"
            value="IMPORT"
            //onChange={handleRadio}
            checked={serviceDetails && serviceDetails.serviceType =="IMPORT"}
            
          />
          <label>IMPORT</label>
         </div>  <div
          style={{
            // fontSize: "25px",
            marginLeft: "1%",
            width: "50%",
            textAlign: "right",
            marginRight: "1%",
          }}
        >
      <input
            className={classes.radio}
            type="radio"
            name="serviceType"
            value="EXPORT"
            //onChange={handleRadio}
           
            checked={serviceDetails && serviceDetails.serviceType == "EXPORT"}
          />
          <label>EXPORT</label>
          </div>
      </div>
     
          <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
  <Input
                   options={Countries}
                  name="countryFrom"
                  placeholder="Country  From"
                 readOnly={true}
                 value={CheckCountryTitle(serviceDetails.countryFrom)}
                // readOnly={true}
                />
              </div>
          

              <div style={{ width: "50%", margin:"10px" }}>
              <Input
                  options={Countries}
                  name="countryTo"
                  placeholder="Country To"
                 readOnly={true}
                 value={CheckCountryTitle(serviceDetails.countryTo)}
                // readOnly={true}
                />
              </div>
            </div>
          {/* Countries */}
        
             
         
    
    
       

              <div>
             <div>
              <div style={{ margin: "10px" }}>
                <label>COMMODITY</label>
                </div>
                <div className={classes.source}>
                <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
                <Input
                   // options={Industry}
                  name="commodity"
                  placeholder="Product Category"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.commodity}
                // readOnly={true}
            //     
            //     name="memberlist"
            //     selected={selectStatus}
            //     placeholder="Status"
            //      // options={InvoiceStatus}
            //    
            //   />
                />
                </div>
              </div>
              
            </div> 
            
            <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
            <Input
                  name="ProductName"
                  placeholder="Product Name"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.ProductName}
                // readOnly={true}
                />
              </div>
              {/* : [Insert Product Name]
: [Insert Product Description]
: []
Shipper Address : [ Insert Address ] */}

              <div style={{ width: "50%", margin:"10px" }}>
                <Input
                  name="productDescription"
                  placeholder="Product Description"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.productDescription}
                // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>

            <div style={{ width: "50%", margin:"10px", paddingRight:"20px" }}>
              <Input
                  name="hsnOfCode"
                  placeholder="HSN code of Goods"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.hsnOfCode}
                // readOnly={true}
                />
              </div>
            </div>
{/* <label>Shipment Details</label> */}
            <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px"}}>
            <Input
                  name="ShipperName"
                  placeholder="Supplier Name"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.ShipperName}
                // readOnly={true}
                />
              </div>
              

              <div style={{ width: "50%", margin:"10px"}}>
              <Input
                  name="ReceiverName"
                  placeholder="Buyer Name"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.ReceiverName}
                  
                // readOnly={true}
                />
             
  
              </div>
            </div>
            <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px"}}>
            <Input
                  name="contactInfoShipper"
                  placeholder="Supplier Contact Information"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.contactInfoShipper}
                // readOnly={true}
                />
              </div>
              

              <div style={{ width: "50%", margin:"10px"}}>
              <Input
                  name="contactInfoBuyer"
                  placeholder="Buyer Contact Information"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.contactInfoBuyer}
                  
                // readOnly={true}
                />
             
  
              </div>
            </div>
            


            <div className={classes.source}>
            <div style={{ width: "100%", margin:"10px"  }}>
            <Input
                  name="ShipperAddress"
                  placeholder="Supplier Address"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.ShipperAddress}
                // readOnly={true}
                />
              </div>
              </div>
              <div className={classes.source}>
              <div style={{ width: "100%", margin:"10px" }}>
            <Input
                  name="ReceiverAddress"
                  placeholder="Buyer Address "
                 readOnly={true}
                  value={serviceDetails && serviceDetails.ReceiverAddress }
                // readOnly={true}
                />
              </div>
              </div>
              
           <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
             <Input
                   options={Currenciesr}
                  name="currencyType"
                  placeholder="Currency"
                  
                  value={ CheckCurrencyTitle(serviceDetails.currencyType)}
                 readOnly={true}
                />
            
              </div>
              <div style={{ width: "50%", margin:"10px" }}>
              <Input
                  name="currencyAmount"
                  placeholder=  {`Estimated Cost of Goods or Services (${serviceDetails && serviceDetails.currencyType})`}
              
                 readOnly={true}
                  value={serviceDetails && serviceDetails.currencyAmount}
                // readOnly={true}
                />
              </div>
              </div>
              {/* <div className={classes.source}>
            <div style={{ width: "50%", marginBottom:"2%" }}>
            <Input
                   // options={qtyUnit // options}
                  name="weightType"
                  placeholder="Quantity"
                  value={serviceDetails && serviceDetails.weightType}
                 readOnly={true}
                />
         
              </div>
              <div style={{ width: "50%", marginBottom:"2%" }}>
              <Input
                  name="quantity"
                  placeholder="Quantity"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.quantity}
                // readOnly={true}
                />
              </div>
              </div> */}
              
            

            <div className={classes.source}>

              
            </div>
            </div>










          

              <div >
                {/* <label>INCOTERM 


</label> */}
              </div>
              <div style={{  width: "100%", margin:"10px", paddingRight:"20px" }}>
              <Input
                   // options={incoterm}
                  name="incoterm"
                  placeholder="Incoterm"
                  value={serviceDetails && serviceDetails.incoterm}
                 readOnly={true}
                />
                {/* <textarea
                  style={{ height: "7rem", width: "100%" , background :"Black"}}
                  type="textarea"
                  name="requirement"
                  value={serviceDetails && serviceDetails.requirement}
                  onChange={(e) => handleChange(e, "requirement")}
                /> */}
              </div>
              <label style={{margin:"10px"}}>What type of Trade Compliance Services you need?*
</label>
              <div className={classes.source}>
               
                <div style={{ width: "100%", margin:"10px"}}>
              <Input
                   //  // options={services}
                  name="service"
                  placeholder="Service *"
                
                  value={listService}
                 readOnly={true}
            //     
            //     name="memberlist"
            //     selected={selectStatus}
            //     placeholder="Status"
            //      //  // options={InvoiceStatus}
            //    
            //   />
                />
                </div>
              </div>
    

              <div style={{ margin: "10px" }}>
                <label>Give your own Trade Compliance Specific coverage Requirements

</label>
              </div>
              <div style={{ width: "100%", margin:"10px", paddingRight:"20px" }}>
              <TextArea
              name="requirement"
              placeholder="Requirements *"
              value={serviceDetails && serviceDetails.requirement}
             readOnly={true}
            />



                {/* <Input
                  name="contactInfo"
                  placeholder="Contact Information"
                 readOnly={true}
                  value={customInspectionData.contactInfo}
                // readOnly={true}
                /> */}
              </div>


<label style={{margin:"10px"}}>Is this purchase subject to any trade restrictions (i.e. sanctions, embargoes, or denial orders)? 
</label>

              <div className={classes.source} style={{  }}>
        <div style={{ marginLeft: "1%", width: "50%" }}>
        
      
      <input
            className={classes.radio}
            type="radio"
            name="serviceRes"
            value="YES"
            //onChange={handleRadio}
            checked={serviceDetails && serviceDetails.serviceRes =="YES"}
            
          />
          <label>YES</label>
         </div>  <div
          style={{
            // fontSize: "25px",
            marginLeft: "1%",
            width: "50%",
            textAlign: "right",
            marginRight: "1%",
          }}
        >
      <input
            className={classes.radio}
            type="radio"
            name="serviceRes"
            value="NO"
            //onChange={handleRadio}
           
            checked={serviceDetails && serviceDetails.serviceRes == "NO"}
          />
          <label>NO</label>
          </div>
      </div>
      {serviceDetails && serviceDetails.serviceRes == "YES" ?   <div style={{ width: "100%", margin:"10px"  }}>
            <Input
                  name="serviceResDetail"
                  placeholder="If yes, please provide details"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.serviceResDetail}
                // readOnly={true}
                />
              </div> : <div></div>}
{/*      
      <div style={{ width: "100%", marginBottom:"2%"  }}>
            <Input
                  name="serviceResDetail"
                  placeholder="If yes, please provide details"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.serviceResDetail}
                // readOnly={true}
                />
              </div> */}


              {/* hell 1 */}
              
<label style={{margin:"10px"}}>Is this product classified as dual-use or controlled under export control regulations (e.g. ITAR, EAR)? 

</label>

              <div className={classes.source} >
        <div style={{ marginLeft: "1%", width: "50%" }}>
        
      
      <input
            className={classes.radio}
            type="radio"
            name="serviceExportReg"
            value="YES"
            //onChange={handleRadio}
            checked={serviceDetails && serviceDetails.serviceExportReg =="YES"}
            
          />
          <label>YES</label>
         </div>  <div
          style={{
            // fontSize: "25px",
            marginLeft: "1%",
            width: "50%",
            textAlign: "right",
            marginRight: "1%",
          }}
        >
      <input
            className={classes.radio}
            type="radio"
            name="serviceExportReg"
            value="NO"
            //onChange={handleRadio}
           
            checked={serviceDetails && serviceDetails.serviceExportReg == "NO"}
          />
          <label>NO</label>
          </div>
      </div>
      {serviceDetails && serviceDetails.serviceExportReg == "YES" ? 
      <div style={{ width: "100%", margin:"10px"  }}>
            <Input
                  name="serviceExportRegDetail"
                  placeholder="If yes, please provide details"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.serviceExportRegDetail}
                // readOnly={true}
                />
              </div>
               : <div></div>}
{/* //hell 3 */}
<label style={{margin:"10px"}}>Is the supplier or any entity involved in the transaction listed on a restricted party list (e.g. OFAC, BIS, EU, etc.)? 

</label>

              <div className={classes.source} >
        <div style={{ marginLeft: "1%", width: "50%" }}>
        
      
      <input
            className={classes.radio}
            type="radio"
            name="transactionRes"
            value="YES"
            //onChange={handleRadio}
            checked={serviceDetails && serviceDetails.transactionRes =="YES"}
            
          />
          <label>YES</label>
         </div>  <div
          style={{
            // fontSize: "25px",
            marginLeft: "1%",
            width: "50%",
            textAlign: "right",
            marginRight: "1%",
          }}
        >
      <input
            className={classes.radio}
            type="radio"
            name="transactionRes"
            value="NO"
            //onChange={handleRadio}
           
            checked={serviceDetails && serviceDetails.transactionRes == "NO"}
          />
          <label>NO</label>
          </div>
      </div>
      {serviceDetails && serviceDetails.transactionRes == "YES" ? 
      <div style={{ width: "100%", margin:"10px"  }}>
            <Input
                  name="transactionResDetail"
                  placeholder="If yes, please provide details"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.transactionResDetail}
                // readOnly={true}
                />
              </div>
 : <div></div>}

              {/* hell 4 */}
              <label style={{margin:"10px"}}>Is an export license required for this transaction? 
</label>

              <div className={classes.source} >
        <div style={{ marginLeft: "1%", width: "50%" }}>
        
      
      <input
            className={classes.radio}
            type="radio"
            name="licenseReq"
            value="YES"
            //onChange={handleRadio}
            checked={serviceDetails && serviceDetails.licenseReq =="YES"}
            
          />
          <label>YES</label>
         </div>  <div
          style={{
            // fontSize: "25px",
            marginLeft: "1%",
            width: "50%",
            textAlign: "right",
            marginRight: "1%",
          }}
        >
      <input
            className={classes.radio}
            type="radio"
            name="licenseReq"
            value="NO"
            //onChange={handleRadio}
           
            checked={serviceDetails && serviceDetails.licenseReq == "NO"}
          />
          <label>NO</label>
          </div>
      </div>
      {serviceDetails && serviceDetails.licenseReq == "YES" ? 
      <div style={{ width: "100%", margin:"10px"  }}>
            <Input
                  name="licenseReqDetail"
                  placeholder="If yes, please provide details"
                 readOnly={true}
                  value={serviceDetails && serviceDetails.licenseReqDetail}
                // readOnly={true}
                />
              </div>
 : <div></div>}
              </> : <></>
            }
    </>
    )
};

export default ComplianceTrade;