import React, { useCallback, useEffect, useContext } from "react";
import useAxios from "../../../../../services/useAxios";
import {
  invoiceURL,
  invoiceApi,
  NetworkURL,
  profileURL,
} from "../../../../../services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
// import Loader from "../../../components/Loader";
import { useNavigate } from "react-router-dom";
import { CheckPermission } from "utils/Constants/RBAC";

import Table from "../../../../../components/table";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import styles from "../../../invoice.module.css";
import { useState } from "react";
import { getClaim, getIdToken } from "utils/authUtil";
import { getIdTokenAsync } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { invoiceFinanceContext } from "../../../../../contexts/invoiceFinance/invoiceFinanceContext";
import { storeContext } from "../../../../../contexts/Store/Store";
import { CompanyNameFilter, notify } from "../../../../../utils/helper";
import { ToastContainer } from "react-toastify";
export default ({ hanndleCraateGroup }) => {
  const { setSaveInvoiceData, setInvoiceData } = useContext(
    invoiceFinanceContext
  );
  const { fetchData, fetchCompanyList } = useContext(storeContext);
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState(null);
  getIdToken(instance, accounts, setIdToken);
  const [Role, setRole] = useState(null);
  const companyId = getClaim(accounts, "company_member_id");
  const [Data, setData] = useState("");
  const [Error, setError] = useState("");
  const [active, setActive] = useState("");
  const [searchPoRole, setSearchPoeRole] = useState(null);
  const [uploadPoRole, setUploadPoRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [filterData, setFilterData] = useState([]);
  const handleNavigate = useCallback((url) => navigate(url), []);
  const handlePromise = async (item) => {};
  const [CompanyList, setCompanyList] = useState([]);

  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");
  useEffect(() => {
    if (idToken !== null) {
      const getData = async () => {
        let URL = `${invoiceURL}invoices?sellerMemberId=${companyId}&status=NEW`;
        const Data = await fetchData(URL, idToken);
        {
          Data && Data.data && setData(Data.data);
        }
        {
          Data && Data.error && setError(Data.error);
        }
        {
          Data && Data.error && notify("warn");
        }
      };
      getData();
    }
  }, [idToken]);

  const handleNavigateInvoice = async (event, id) => {
    event.preventDefault();
    let URL = `${invoiceURL}invoices/${id.txlInvoiceId}`;
    const Data = await fetchData(URL, idToken);
    localStorage.setItem("uploadData", JSON.stringify(Data.data));

    if (Data && Data.data && !Data.error) {
      if (Data.data.txlBuyerMemberId) {
        setSaveInvoiceData(Data.data);
        handleNavigate(`../invoicesDetails/${id.invoiceNumber}`);
      } else {
        setInvoiceData(Data.data);
        handleNavigate(`${id.invoiceNumber}`);
      }
    }
    if (Data && Data.error) {
      notify("warn");
    }
  };

  useEffect(() => {
    if (Data) {
      setFilterData(Data);
    }
  }, [Data]);

  useEffect(() => {
    if (active === 0) {
      let data = Data.filter(
        (x) => x.txlStatus === "NEW" || x.txlStatus === "OPEN"
      );
      setFilterData(data);
    } else if (active === 1) {
      let data = Data.filter((x) => x.txlStatus === "FINANCED");
      setFilterData(data);
    } else if (active === 2) {
      let data = Data.filter((x) => x.txlStatus === "CLOSED");
      setFilterData(data);
    } else if (active === 3) {
      let data = Data.filter(
        (x) => x.txlStatus === "BLOCKED" || x.txlStatus === "PENDING"
      );
      setFilterData(data);
    }
  }, [active]);

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setUploadPoRole(
        CheckPermission("UploadInvoices", "PurchaseOrder", "upload", Role)
      );
      setSearchPoeRole(
        CheckPermission("Search", "PurchaseOrder", "search", Role)
      );
    }
  }, [Role]);

  useEffect(() => {
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken)
      .then((companyList) => {
        setCompanyList(companyList);
      })
      .catch((error) => {
        notify("warn");
      });
  }, []);

  // useEffect(() => {
  //   let isCancelled = false;

  //   if (!isCancelled) {
  //     getIdTokenAsync(instance, accounts).then((response) => {
  //       UseRequest(
  //         `${profileURL}Persons/${response.idTokenClaims.member_id}`,
  //         null,
  //         response
  //       ).then((data) => {
  //         setIsLoading(false);
  //         let { message, status } = data;
  //         if (message || status) {
  //           Error(message || "Something Went Wrong");
  //           setError(message);
  //         } else {
  //           setRole(data.role);
  //         }
  //       });
  //     });
  //   }
  //   return () => {
  //     isCancelled = true;
  //   };
  // }, []);
  // const Request = () => {
  //   getIdTokenAsync(instance, accounts).then((response) => {
  //     UseRequest(
  //       `${profileURL}Persons/${response.idTokenClaims.member_id}`,
  //       null,
  //       response
  //     ).then((data) => {
  //       setIsLoading(false);
  //       let { message, status } = data;
  //       if (message || status) {
  //         Error(message);
  //       } else {
  //         setRole(data.role);
  //       }
  //     });
  //   });
  // };

  // useEffect(() => {
  //   Request();
  // }, []);
  const headerFieldsList = [
    {
      width: 100,
      Header: "Select",
      accessor: "txlInvoiceId",
      Cell: (cell) => (
        <span
          value={cell.accessor}
          onClick={() => handlePromise(cell.cell.row.values)}
        >
          <MdCheckBoxOutlineBlank size={26} color="#5D5D5D" />
        </span>
      ),
    },
    {
      Header: "Invoice ID",
      accessor: "invoiceNumber",
      Cell: (cell) => (
        <div
          value={cell.accessor}
          onClick={(e) => handleNavigateInvoice(e, cell.cell.row.values)}
          style={{color:"rgb(33, 142, 235)",textDecorationLine:"underline"}}
        >
          {cell.value}
        </div>
      ),
    },
    {
      Header: "Counter Party",
      accessor: "txlBuyerMemberId",
      Cell: (cell) => (
        <span>
          {CompanyNameFilter(CompanyList, cell.value).length >= 1
            ? CompanyNameFilter(CompanyList, cell.value)[0].memberName
            : ""}
        </span>
      ),
    },
    {
      Header: "Created Date",
      accessor: "createdDate",
    },
    {
      Header: "Status",
      accessor: "txlStatus",
    },
  ];

  const columns = React.useMemo(() => headerFieldsList);

  const tabs = [
    { name: "New Purchase Order", value: "new" },
    { name: "Funding Requested", value: "fundrequested" },
    { name: "Funded", value: "funded" },
    { name: "Pending Action", value: "pending" },
  ];

  //console.log("invoice Page", Data);

  // useEffect(() => {
  //   if (Data) {
  //     setFilterData(Data);
  //   }
  // }, [Data])

  // useEffect(() => {
  //   if (active === 0) {
  //     let data = Data.filter(x => x.txlStatus === 'NEW' || x.txlStatus === 'OPEN');
  //     setFilterData(data)
  //   } else if (active === 1) {
  //     let data = Data.filter(x => x.txlStatus === 'FINANCED');
  //     setFilterData(data)
  //   } else if (active === 2) {
  //     let data = Data.filter(x => x.txlStatus === 'CLOSED');
  //     setFilterData(data)
  //   } else if (active === 3) {
  //     let data = Data.filter(x => x.txlStatus === 'BLOCKED' || x.txlStatus === 'PENDING');
  //     setFilterData(data)
  //   }
  // }, [active])

  return (
    <>
      {Error && <span>Failed to connect......!</span>}
      {Data && (
        <div>
          <div className={styles.invoiceHeader}>
            <div className={styles.invoiceTopHeader}>
              <span className={styles.heading1}> Search Invoices </span>
              <button
                className={styles.uploadInvoiceBtn}
                onClick={() =>
                  handleNavigate(`/financedashBoard/pofinancing/upload`)
                }
              >
                + Upload New PO
              </button>
            </div>
            <div className={styles.heading2}>
              <span>Quick Filter</span>
            </div>

            <div className={styles.searchDiv}>
              {tabs.map((item, index) => (
                <div
                  key={index}
                  onClick={() => setActive(index)}
                  className={
                    index == active ? styles.activeDiv : styles.nonActiveDiv
                  }
                >
                  {item.name}
                </div>
              ))}
              <input
                type="text"
                placeholder="Search"
                // disabled={
                //   searchPoRole === "DELETE" || searchPoRole === "NOTAPP"
                //     ? true
                //     : false
                // }
              />
            </div>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.wrapperTitle}>Search Results</div>
            <div className={styles.tableContainer}>
              {filterData.length > 0 ? (
                <Table
                  columns={columns}
                  hanndleCraateGroup={hanndleCraateGroup}
                  data={Data}
                />
              ) : (
                <p>No Records</p>
              )}
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
