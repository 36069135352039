import React, { useState } from "react";
import classes from "../serviceQuote/quote.module.css";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Select from "components/fields/Select";
import { Grid } from "@mui/material";
import DateInput from "components/fields/DateInput";
import Input from "components/fields/Input";
import Textarea from "components/fields/TextArea";
import Currenciesr from "jsons/Currenciesr.json";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Loader from "components/Loader/Loader";
import { truncateStringID } from "utils/helper";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";

export default ({
  status,
  handledealStatus,
  referenceServiceRequisitionId,
  hanndleCraateGroup,serviceDealDetails
}) => {
  const { instance, accounts } = useMsal();
  const location = useLocation();

  const [requiData, setRequiData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [item, setItem] = useState();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [memberList, setMemberList] = useState();
  const [quoteData, setQuoteData] = useState(serviceDealDetails);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_SERVICE_URL}services/requisitions/${referenceServiceRequisitionId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message || "Something went wrong");
          } else {
            setRequiData(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const fetchData = async (companyId, id2, name) => {
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        hanndleCraateGroup(nData, name, name);
        setMemberList(nData);
      });
  };
  
  const chatHandler = (data) => {
    if (data?.consumerMemberUID !== "" && data?.providerMemberUID !== "") {
      setItem(data);
      let name = data.serviceDealId
        ? truncateStringID(data.serviceDealId, 7, "SD")
        : "NA";
      fetchData(data?.consumerMemberUID, data?.providerMemberUID, name);
    }
  };

  console.log("requiData :", requiData);
  return (
    <>
      {isLoading ? (
        <div>
          <span>Loading...</span>
          <span>
            <Loader />
          </span>
        </div>
      ) : (
        <>
          {requiData && (
            <div style={{ marginTop: "2rem" }}>
              <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Service Request ID"
                    readOnly={true}
                    value={
                      requiData.serviceRequisitionId
                        ? truncateStringID(
                          requiData.serviceRequisitionId,
                          7,
                          "RQ"
                        )
                        : "NA"
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}></Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Service Category"
                    readOnly={true}
                    value={requiData.serviceCategory[0]}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Service Sub-Category"
                    readOnly={true}
                    value={requiData.serviceSubCategory[0]}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Select
                    options={Currenciesr}
                    placeholder="Currency"
                    readOnly={true}
                    value={requiData.servicePaymentCurrency}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder={`Min Price (${requiData.servicePaymentCurrency})`}
                    readOnly={true}
                    value={requiData.servicePriceLow}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Payment Method"
                    readOnly={true}
                    value={requiData.preferredPaymentMethod}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder={`Max price (${requiData.servicePaymentCurrency})`}
                    readOnly={true}
                    value={requiData.servicePriceHigh}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Mode Of Payment"
                    readOnly={true}
                    value={requiData.modeOfPayment}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Preferred Payment Date"
                    readOnly={true}
                    value={new Date(
                      requiData.preferredPaymentDate
                    ).toLocaleDateString()}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Textarea
                  placeholder="Service Description"
                  readOnly={true}
                  value={requiData.ServiceReqDescription}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Textarea
                  placeholder="Pricing Description"
                  readOnly={true}
                  value={requiData.pricingPreferences}
                />
              </Grid>
              <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Requisition Start Date"
                    readOnly={true}
                    value={new Date(
                      requiData.serviceRequisitionStartDate
                    ).toLocaleDateString()}
                  // handleChange={handleGeneralChange}
                  />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <Input
                    placeholder="Requisition End Date"
                    readOnly={true}
                    value={new Date(
                      requiData.serviceRequisitionEndDate
                    ).toLocaleDateString()}
                  // handleChange={handleGeneralChange}
                  />
                </Grid>
              </Grid>
              <ButtonDiv>
                {/* <Button onClick={() => chatHandler(requiData)}>
                  Request More Info
                </Button> */}
                <button className={classes.isDisabled}>
                  Abort
                </button>
              </ButtonDiv>
            </div>

          )}

          {isError && (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          )}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
