import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Countries } from "./DropDown/Country";
import Currenciesr from "jsons/Currenciesr.json";
import port_codes from "jsons/PortCodes.json";
import { Ports } from "./DropDown/Ports";
import { documentURL } from "services/BaseURLInstance";

// import { ToastContainer} from 'react-toastify';
{
  /* <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover /> */
}
export const notify = (success, message = "") => {
  switch (success) {
    case "success":
      toast.success(message || "🦄 Wow so easy!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "black",
        },
      });
      break;
    case "error":
      toast.error(message || "🦄Please Enter Order!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "black",
        },
      });
      break;
    case "alert":
      toast.alert(message || "🦄 Updating in Progress!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "black",
        },
      });
      break;
    case "warn":
      toast.warn(message || "🦄 Failed to fetch.........!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          backgroundColor: "black",
        },
      });
      break;
    default:
  }
};

export const CompanyNameFilter = (CompanyList, filterdId) => {
  let data =
    CompanyList &&
    CompanyList.filter((item) => {
      if (item.memberUID === filterdId) {
        return item.memberName;
      }
    });
  return data;
};

export const FindCompanyName = (CompanyList, filterdId) => {
  if (CompanyList && CompanyList.length > 0 && filterdId) {
    let data =
      CompanyList &&
      CompanyList.filter((item) => {
        if (item.memberUID === filterdId) {
          return item.memberName;
        }
      });
    if (data && data.length > 0) return data[0].memberName;
    else return "Unknown";
  } else {
    return "Invalid ID";
  }
};

export const TableStatus = (stats) => {
  switch (stats) {
    case "NEW":
      return <div className={"py-1 bg-danger"}>NEW</div>;
    case "FINANCED":
      return <div className={"py-1 bg-danger"}>FINANCED</div>;
    case "CLOSED":
      return <div className={"py-1 bg-danger"}>Closed</div>;
    case "VERIFICATION_PASS":
      return <div className={"py-1 bg-primary"}>Verified</div>;
    case "IN_PROCESS":
      return <div className={"py-1 bg-warning"}>IN_PROCESS</div>;
    case "VERIFICATION_FAIL":
      return <div className={"py-1 bg-danger"}>VERIFICATION_FAIL</div>;
    case "NOT_STARTED":
      return <div className={"py-1 bg-warning"}>NOT_STARTED</div>;
    default:
      return;
  }
};

export const truncateString = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
};

export const truncateStringOnly = (str, n) => {
  return str.length > n ? str.slice(0, n - 1) : str;
};

export const truncateStringID = (str, n, preString) => {
  return str.length > n
    ? `${preString}-${str.slice(0, n - 1)}`.toUpperCase() + ""
    : `${preString}-${str}`;
};

export const NodeName = (string) => {
  if (string) {
    let stringNew = string.split(",").map((item) => item.trim());
    let Country = stringNew[1].split("=").map((item) => item.trim())[1];
    let City = stringNew[2].split("=").map((item) => item.trim())[1];
    let NewString = `Node - ${Country}, ${City}`;
    return NewString;
  }
};

export const ImageURLSrc = (id) => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  if (id) {
    let src = "";
    return (src = `${documentURL}external/documents/${id}/download?memberId=${companyId}`);
  }
  // return "";
  return "https://www.jdfulwiler.com/wp-content/uploads/2017/08/abc-company-test-logo-300x205.png";
};

//Bank Name Filter
export const BankNameFilter = (CompanyList, filterdId) => {
  let data =
    CompanyList && CompanyList.filter((item) => item.value === filterdId);
  if (data?.length > 0) return data[0].label;
};

export const BankNameFilterDD = (CompanyList, filterdId) => {
  let data =
    CompanyList && CompanyList.filter((item) => item.value === filterdId);
  if (data?.length > 0) return data[0];
};

export const CheckCategoryTitle = (status) => {
  switch (status) {
    case "TRADE_CONTRACT":
      return "Trade Contract";
    case "MEMBER_ONBOARDING":
      return "Onboarding Details";
    case "FINANCING_PO":
      return "FINANCING PO";
    case "FINANCING_INVOICE":
      return "FINANCING INVOICE";
    case "SYNDICATION_FINANCE":
      return "SYNDICATION FINANCE";
    case "INSURANCE":
      return "INSURANCE";
    case "IDENTITY_VERIFICATION":
      return "IDENTITY VERIFICATION";
    case "KYB":
      return "KYB";
    case "CREDIT_ASSESSMENT":
      return "CREDIT ASSESSMENT";
    case "PAYMENTS":
      return "PAYMENTS";
    case "FREIGHT_SERVICES":
      return "Freight Details";
    case "GOODS_INSURANCE":
      return "GOODS INSURANCE";
    case "TRANSPORT":
      return "TRANSPORT";
    case "TRANSPORT_SERVICES":
      return "TRANSPORT SERVICES";
    case "TRANSPORT_SERVICES":
      return "TRANSPORT SERVICES";
    case "EXPORT_DECLARATIONS":
      return "EXPORT DECLARATIONS";
    case "TERMINAL_LOADING":
      return "TERMINAL LOADING";
    case "CUSTOMS_INSPECTION":
      return "CUSTOMS INSPECTION";
    case "CUSTOMS_INSPECTION":
      return "CUSTOMS INSPECTION";
    case "CUSTOMS_INSPECTION":
      return "CUSTOMS INSPECTION";
    case "SHIPPING_SERVICES":
      return "SHIPPING_SERVICES";
    case "CONTAINER_SERVICES":
      return "CONTAINER SERVICES";
    case "CUSTOMS_SERVICES":
      return "CUSTOMS SERVICES";
    case "INSPECTION_SERVICES":
      return "INSPECTION SERVICES";
    case "WAREHOUSE_SERVICES":
      return "WAREHOUSE_SERVICES";
    case "LEGAL":
      return "LEGAL";
    case "FINANCING":
      return "FINANCING";
    case "INVOICING":
      return "INVOICING";

    default:
      return "NONE";
  }
};

export const CheckCountryTitle = (status) => {
  const Filtered =
    Countries && Countries.filter((country) => country.value === status);

  let temp = "";
  if (Filtered.length > 0) {
    temp = Filtered[0].label;
  } else {
    temp = "";
  }
  return temp;
};

export const CheckCurrencyTitle = (status) => {
  const Filtered =
    Currenciesr && Currenciesr.filter((currency) => currency.value === status);

  let temp = "";
  if (Filtered.length > 0) {
    temp = Filtered[0].label;
  } else {
    temp = "";
  }
  return temp;
};

export const CheckPortTitle = (status) => {
  const Filtered = Ports && Ports.filter((port) => port.port === status);

  let temp = "";
  if (Filtered.length > 0) {
    temp = Filtered[0].name;
  } else {
    temp = "";
  }
  return temp;
};

export const FormattedDateTime = (time) => {
  const dateObj = new Date(time);

  const formattedDate = dateObj.toLocaleDateString("en-US", {
    day: "2-digit",
    month: "short",
    year: "2-digit",
  });

  const formattedTime = dateObj.toLocaleTimeString("en-US", {
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  });

  const formattedDateTime = `${formattedDate} ${formattedTime}`;

  return formattedDateTime;
};

// Create a function to format the date as "dd/mm/yyyy"
export const FormatDate = (dateString) => {
  if (!dateString) return "-";
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

// const gmailComposeUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(
//   TO
// )}`;
// window.open(gmailComposeUrl, "_blank");

export const handleMailOpen = (TO) => {
  const gmailComposeUrl = `https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${encodeURIComponent(
    TO
  )}`;
  window.open(gmailComposeUrl, "_blank");
};
