import styled from "styled-components";

export const Container = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 25px;
`;
export const CardWapper = styled.div`
  padding: 16px;
  background: #000000;
  border-radius: 10px;
  border-bottom: 27px solid rgba(255, 255, 255, 0.05);
`;

export const CardNetworkWapper = styled.div`
  padding: 16px;
  background: #1e1e1e;
  border-radius: 10px;
  border-bottom: 27px solid rgba(255, 255, 255, 0.05);
  &.network {
    border-bottom: none;
    margin-bottom: 15px;
    ul {
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        width: 50%;
        border-right: 1px solid #000;
        padding: 0px 15px;
        color: #ffffff;
        &:last-child {
          border-right: 0;
        }
        .card {
          font-size: 13px;
          .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            h2 {
              margin: 0;
              font-size: 16px;
              font-weight: 500;
            }
            span {
              &.edit {
                float: right;
                font-size: 14px;
                margin-left: auto;
                a {
                  color: #90caf9;
                  text-decoration: none;
                  &:hover {
                    text-decoration: underline;
                  }
                }
              }
            }
          }
        }
        &.left {
          .companydetails {
            padding-left: 40px;
            h1 {
              font-size: 18px;
              margin: 0 0 5px 0;
              padding: 0;
              font-weight: 600;
            }
            p {
              margin-bottom: 5px;
            }
            ul {
              clear: both;
              display: block;
              margin: 15px 0;
              padding: 0;
              float: left;
              width: 100%;
              li {
                font-size: 13px;
                float: left;
                border-right: 1px solid #fff;
                padding: 0 10px 0 0;
                margin-right: 10px;
                width: auto;
                &:last-child {
                  border-right: none;
                  padding-right: 0;
                  margin-right: 0;
                }
              }
            }
            .badge {
              background: #000;
              color: #fff;
              border-radius: 0.25rem;
              float: left;
              clear: both;
              padding: 5px 10px;
              text-transform: uppercase;
              font-size: 11px;
              font-weight: 500;
              &.orange {
                background: #ffa100;
              }
              &.pink {
                background: #f67b7b;
              }
            }
          }
        }
        &.right {
          .addressDetails {
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            .column {
              -ms-flex: 0 0 50%;
              flex: 0 0 50%;
              max-width: 50%;
              ul {
                display: flex;
                flex-direction: column;
                li {
                  padding-left: 50px;
                  width: auto;
                  border: none;
                  &.viewgoogle {
                    margin-top: 30px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const ButtonStyled = styled.button`
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 12px 45px;
  background: #42a5f5;
  margin:0px 10px;
  border: none;
  border-radius: 5px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

export const ButtonStyledDisable = styled.button`
font-weight: 500;
font-size: 15px;
line-height: 24px;
text-transform: capitalize;
color: #ffffff;
padding: 12px 45px;
background: #42a5f5;
margin:0px 10px;
border: none;
border-radius: 5px;
opacity: 0.4;  
`;
export const CancelButtonStyled = styled.button`
  min-width: 50px;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-transform: capitalize;
  background: #ffffff;
  padding: 12px 45px;
  color: #42a5f5;
  border: none;
  border-radius: 5px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;
export const ActionSection = styled.div`
  display: flex;
  justify-content: end;
  button {
    margin-right: 30px;
  }
`;
export const HeaderActionStyled = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 27px 29px 27px 42px;
  margin-bottom: 18px;
  display: flex;
  justify-content: center;
  button {
    margin: 0 16px;
  }
`;
export const HeaderActionInvoiceStyled = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 27px 29px 27px 42px;
  margin-bottom: 18px;
  display: flex;
  // justify-content: end;
  flex-direction: column;
  div {
    padding: 10px 15px;
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      text-transform: capitalize;
      color: #ffffff;
      padding: 12px 32px;
      background: #343434;
      //42a5f5;
      // border-radius: 10px;
      &.active {
        border-radius: 20px;
        background: #42a5f5;
      }
    }
  }

  button {
    margin: 0 16px;
  }
`;
export const ActionBarStyled = styled.div`
  
  border-radius: 10px;
  padding: 27px 29px 0px 42px;
  margin-bottom: 18px;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #fff;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  list-style: none;
  a {
    color: #fff;
    text-decoration: none;
    padding: 0px 16px 16px 16px;
    &:hover {
      color: #218eeb;
      cursor: pointer;
    }
    &.active {
      color: #218eeb;
      border-bottom: 2px solid #218eeb;
    }
  }
  button {
    margin: 0 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .card {
    display: flex;
    flex: 47%;
    flex-direction: column;
    background: #1e1e1e;
    border-radius: 10px;
    margin: 5px 10px 5px 0px;
    .content {
      font-weight: 500;
      font-size: 16px;
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;
      flex: 1 1 auto;
      span {
        color: #218eeb;
      }
    }
  }
`;

export const SubHeading = styled.div`
  color: #218eeb;
  margin-bottom: 15px;
`;
