import React, { useState, useEffect } from "react";
import classes from "../../New-Service/Category/freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import { key } from "vega";
import MultiSelect from "components/fields/MultiSelect";
import { Grid } from "@mui/material";

const InsuranceCredit = ({ response }) => {

    const [serviceDetails, setServiceDetails] = useState(null);
    useEffect(() => {
        if (response && response.ServiceReqDetails) {
            setServiceDetails(JSON.parse(response.ServiceReqDetails))
        }
    }, [response]);
    console.log("Service Details: ", serviceDetails);
    return (<>
        <Content>
            {response && serviceDetails ?
                <>

                    <div style={{ margin: "10px" }}>
                        <span >
                            <input
                                className={classes.radio}
                                type="radio"
                                name="tenure"
                                value="Tenure"
                                readOnly={true}
                                checked={serviceDetails.tenure == "Tenure"}
                            />
                            <label>Tenure (FY)</label>
                        </span>
                        <span style={{ marginLeft: "20px" }}>
                            <input
                                className={classes.radio}
                                type="radio"
                                name="tenure"
                                value="OneTime"
                                readOnly={true}
                                checked={serviceDetails.tenure == "OneTime"}
                            />
                            <label>One Time</label>
                        </span>
                    </div>
                    <div style={{ margin: "10px" }}>TURNOVER</div>
                    <div className={classes.source} >
                        <div style={{ width: "50%", margin:"10px" }}>
                            <Input
                                name="currency"
                                placeholder="Currency"
                                readOnly={true}
                                value={serviceDetails.currency}
                            />
                        </div>
                        <div style={{ width: "50%", margin:"10px" }}>
                            <Input
                                name="turnoverAmount"
                                placeholder={`Turnover Amount(${serviceDetails.currency})`}
                                readOnly={true}
                                value={serviceDetails.turnoverAmount}
                            />
                        </div>
                    </div>
                    {serviceDetails.tenure === "Tenure" &&
                        serviceDetails.tenure === "Tenure" ? (
                        <>
                            {/* <div style={{ marginBottom: "1%" }}>
                                    Estimated insurable sales t/o for current financial year
                                </div> */}
                            <div style={{ margin: "10px" }}>
                                <Input
                                    name="salesValue"
                                    placeholder={`Estimated Insurabe sales t/o for Current FY(${serviceDetails.currency})`}
                                    readOnly={true}
                                    value={serviceDetails.salesValue}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            {/* <div style={{ marginBottom: "1%" }}>Trade Value</div> */}
                            <div style={{ margin: "10px" }}>
                                <Input
                                    name="salesValue"
                                    placeholder={`Trade value(${serviceDetails.currency})`}
                                    readOnly={true}
                                    value={serviceDetails.salesValue}
                                />
                            </div>
                        </>
                    )}

                    <div style={{margin: "10px" }}>
                        Are you seeking Credit Insurance for
                    </div>
                    <div>
                        <span style={{ margin: "10px" }}>
                            <input
                                className={classes.radio}
                                type="radio"
                                name="insuranceType1"
                                value="EXPORT"
                                readOnly={true}
                                checked={serviceDetails.insuranceType1 == "EXPORT"}
                            />
                            <label>Export</label>
                        </span>
                        <span style={{ margin: "10px" }}>
                            <input
                                className={classes.radio}
                                type="radio"
                                name="insuranceType1"
                                value="DOMESTIC"
                                readOnly={true}
                                checked={serviceDetails.insuranceType1 == "DOMESTIC"}
                            />
                            <label>Domestic</label>
                        </span>
                        <span style={{ margin: "10px" }}>
                            <input
                                className={classes.radio}
                                type="radio"
                                name="insuranceType1"
                                value="BOTH"
                                readOnly={true}
                                checked={serviceDetails.insuranceType1 == "BOTH"}
                            />
                            <label>Both</label>
                        </span>
                    </div>
                    <div className={classes.source} >
                        <div style={{ width: "50%", margin:"10px" }}>
                            Product
                        </div>
                        {serviceDetails.insuranceType1 && serviceDetails.insuranceType1 === "DOMESTIC" ? (<div style={{ width: "50%", margin:"10px" }}>
                            Country
                        </div>) : (<div style={{ width: "50%" , margin:"10px" }}>
                            Exporting Countries
                        </div>)}

                    </div>
                    <div
                        className={classes.source}
                
                    >
                        <div style={{ width: "50%", margin:"10px" }}>
                            <Grid item xs={12} md={6} >
                                <MultiSelect
                                    name="commodity"
                                    placeholder="Commodity"
                                    isDisabled={true}
                                    selected={serviceDetails.commodity}
                                />
                            </Grid>
                        </div>
                        {serviceDetails.insuranceType1 && serviceDetails.insuranceType1 === "DOMESTIC" ? (<div style={{ width: "50%", margin:"10px" }}>
                            <Grid item xs={12} md={6} >
                                <Input
                                    name="country"
                                    placeholder="Country"
                                    readOnly={true}
                                    value={serviceDetails.country}
                                />
                            </Grid>
                        </div>) : (<div style={{ width: "50%", margin:"10px" }}>
                            <Grid item xs={12} md={6} >
                                <MultiSelect
                                    name="countryMS"
                                    placeholder="Country"
                                    isDisabled={true}
                                    selected={serviceDetails.countryMS}
                                />
                            </Grid>
                        </div>)}

                    </div>
                    {serviceDetails.tenure === "Tenure" &&
                        serviceDetails.tenure === "Tenure" ? (
                        <>
                            <div style={{ margin: "10px" }}>
                                Pecentage of total Turnover by:
                            </div>
                            <div className={classes.source}>
                                <div style={{ width: "50%", margin:"10px" }}>
                                    <Input
                                        name="credit"
                                        placeholder="% Credit"
                                        value={serviceDetails.credit}
                                        readOnly={true}
                                    />
                                </div>
                                <div style={{ width: "50%", margin:"10px" }}>
                                    <Input
                                        name="cash"
                                        placeholder="% Cash"
                                        value={serviceDetails.cash}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={classes.source} >
                                <div style={{ width: "50%", margin:"10px" }}>
                                    <Input
                                        name="secured"
                                        placeholder="% Secured"
                                        value={serviceDetails.secured}
                                        readOnly={true}
                                    />
                                </div>
                                <div style={{ width: "50%",margin:"10px" }}>
                                    <Input
                                        name="interCompany"
                                        placeholder="% Inter-Company"
                                        value={serviceDetails.interCompany}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
                                <Input
                                    name="total"
                                    placeholder="% Total"
                                    value={serviceDetails.total}
                                    readOnly={true}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div style={{ margin: "10px" }}>
                                Pecentage of total Trade Value by:
                            </div>
                            <div className={classes.source} >
                                <div style={{ width: "50%" ,margin: "10px" }}>
                                    <Input
                                        name="credit"
                                        placeholder="% Credit"
                                        value={serviceDetails.credit}
                                        readOnly={true}
                                    />
                                </div>
                                <div style={{ width: "50%" ,margin: "10px"}}>
                                    <Input
                                        name="cash"
                                        placeholder="% Cash"
                                        value={serviceDetails.cash}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className={classes.source} >
                                <div style={{ width: "50%",margin: "10px" }}>
                                    <Input
                                        name="secured"
                                        placeholder="% Secured"
                                        value={serviceDetails.secured}
                                        readOnly={true}
                                    />
                                </div>
                                <div style={{ width: "50%",margin: "10px" }}>
                                    <Input
                                        name="interCompany"
                                        placeholder="% Inter-Company"
                                        value={serviceDetails.interCompany}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div style={{ width: "50%",margin: "10px", paddingRight:"20px" }}>
                                <Input
                                    name="total"
                                    placeholder="% Total"
                                    value={serviceDetails.total}
                                    readOnly={true}
                                />
                            </div>
                        </>
                    )}


                </>
                : <></>}
        </Content>
    </>
    );
};

export default InsuranceCredit;
