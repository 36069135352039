export const validation = (data) => {
    if (!data.financeDisbursalDate) {
        return "Please Enter disbursal date"
    }
    else if (!data.financeRepaymentAmount) {
        return "Please Enter Repayment Amount Before Proceed"
    }
    else if (!data.financeRepaymentDate) {
        return "Please Enter Repayment Date Before Proceed"
    }
    else if (!data.loanFees) {
        return "Please Enter Repayment Loan Fees Before Proceed"
    }
    else {
        return false;
    }
} 