import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./opentrade.module.css";

import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import Currenciesr from "jsons/Currenciesr.json";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "contexts/Auth/AuthContext";
import { openTradeAccount, paymentServiceURL } from "services/BaseURLInstance";

import { UseRequest } from "utils/API/Request.js";
import { CompanyList } from "utils/API/CompanyList.js";
import { Countries } from "utils/DropDown/Country.js";
import {
  Industry,
  TradeStatus,
  EscrowService,
  PaymentCurrencies,
  PreferredPaymentMethod,
  EscrowPaymentDropDown,
} from "utils/Section/trade";

import moment from "moment";
import { Success, Error } from "components/notifications/responseHandle";
import { ModifyTadeInfo } from "utils/Section/FormInfo";
import { useCalendarState } from "@mui/x-date-pickers/internals";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Switch from '@mui/material/Switch';
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { AccountInfoSelect } from "utils/Section/FormInfo";
import { createTheme, ThemeProvider } from '@mui/material/styles';

// const ModifyPayment = () => {
//   const { instance, accounts } = useMsal();
//   const [isLoading, setIsLoading] = useState(false);
//   const [isError, setIsError] = useState(null);
//   const [companyList, setCompanyList] = useState(null);
//   // const [tradeResponse, setTradeResponse] = useState(null);

//   const [DatePicker1, setDatePicker1] = useState(new Date());
//   const [DatePicker2, setDatePicker2] = useState(new Date());
//   const [DatePicker3, setDatePicker3] = useState(new Date());
//   const companyId = JSON.parse(sessionStorage.getItem("companyId"));
//   const [IsPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
//   // const [isConsentStatus, setIsConsentStatus] = useState("DRAFT");
//   const [isAdded, setIsAdded] = useState(null);
//   const [isMemberId, setIsMemberId] = useState(null);
//   const [isBuyerConsent, setIsBuyerConsent] = useState(false);
//   const [payment, setPayment] = useState(null);
//   const [provider, setProvider] = useState([]);
//   const [provider2, setProvider2] = useState([]);
//   const { OpenTradeAccountDetails } = useContext(AuthContext);
//   const [modifyData, setModifyData] = useState(null);
//   const { id } = useParams();
//   console.log("providerprovider", provider);
//   const navigate = useNavigate();
//   const handleNavigate = useCallback((url) => navigate(url), []);
//   const [isLoading1, setIsLoading1] = useState(false);
//   const [isError1, setIsError1] = useState(false);
//   const [isLoading2, setIsLoading2] = useState(false);
//   const [isError2, setIsError2] = useState(false);

//   const [PaymentData, setPaymentData] = useState({
//     escrowServiceProviderUID: "",
//     RefSellerVirtualBankAccountId: "",
//     escrowConsentStatus: "BUYER_NOT_LINKED",
//     referenceEscrowAccount: "",
//   });

//   useEffect(() => {
//     let isCancelled = false;
//     if (!isCancelled) {
//       setIsLoading1(true);
//       getIdTokenAsync(instance, accounts).then((response) => {
//         UseRequest(
//           `${paymentServiceURL}payments/user/${companyId}`,
//           null,
//           response
//         ).then((data) => {
//           setIsLoading1(false);
//           let { message, status } = data;
//           if (message || status) {
//             Error(message || "Something Went Wrong");
//             setIsError(message);
//           } else {
//             setPayment(data);
//           }
//         });
//       });
//     }

//     if (!isCancelled) {
//       getIdTokenAsync(instance, accounts).then((response) => {
//         UseRequest(
//           `${openTradeAccount}openaccounttrades/${id}`,
//           null,
//           response
//         ).then((data) => {
//           let { message, status } = data;
//           if (message || status) {
//             Error(message || "Something Went Wrong");
//           } else {
//             setModifyData(data);
//           }
//         });
//       });
//     }

//     return () => {
//       isCancelled = true;
//     };
//   }, []);

//   useEffect(() => {
//     if (modifyData) {
//       setPaymentData({
//         escrowServiceProviderUID: modifyData.escrowServiceProviderUID,
//         RefSellerVirtualBankAccountId: modifyData.RefSellerVirtualBankAccountId,
//         escrowConsentStatus: "NEW",
//         referenceEscrowAccount: modifyData.referenceEscrowAccount,
//       });
//     }
//   }, [modifyData]);

//   useEffect(() => {
//     if (payment && payment[0] !== null) {
//       let temp = payment.map((item) => {
//         return {
//           id: item.virtualAccountNumber,
//           label: item.paymentProvider,
//           value: item.virtualAccountNumber,
//         };
//       });
//       setProvider(temp);
//     }

//     if (payment && payment[0] !== null) {
//       let temp = payment.map((item) => {
//         return {
//           id: item.virtualAccountNumber,
//           label: item.virtualAccountNumber,
//           value: item.virtualAccountNumber,
//         };
//       });
//       setProvider2(temp);
//     }
//   }, [payment]);

//   const handlePaymentChange = (event) => {
//     if (event.target.name === "escrowServiceProviderUID") {
//       let value = event.target.value;
//       const temp =
//         payment &&
//         payment[0] !== null &&
//         payment.filter((res) => res.paymentProvider === event.target.value);

//       setPaymentData({
//         ...PaymentData,
//         [event.target.name]: event.target.value,
//         ["RefSellerVirtualBankAccountId"]: temp[0].virtualAccountNumber,
//       });
//     }
//   };

//   const List = CompanyList();
//   useEffect(() => {
//     if (List && List.response) {
//       let temp =
//         List &&
//         List.response.filter((item) => {
//           if (
//             item.memberType === "FINANCER" ||
//             item.memberType === "COMPANY" ||
//             item.memberUID === companyId
//           ) {
//             return { label: item.memberName, value: item.memberUID };
//           }
//         });

//       let filter =
//         temp &&
//         temp.map((item) => {
//           return { label: item.memberName, value: item.memberUID };
//         });

//       setCompanyList(filter);
//     }
//   }, [List && List.response]);

//   const handleSubmitPayment = (e) => {
//     e.preventDefault();
//     if (OpenTradeAccountDetails) {
//       OpenTradeAccountDetails.escrowServiceProviderUID =
//         PaymentData.RefSellerVirtualBankAccountId;
//       OpenTradeAccountDetails.RefSellerVirtualBankAccountId =
//         PaymentData.RefSellerVirtualBankAccountId;
//       OpenTradeAccountDetails.RefBuyerVirtualBankAccountId =
//         PaymentData.RefSellerVirtualBankAccountId;

//       OpenTradeAccountDetails.escrowConsentStatus =
//         PaymentData.escrowConsentStatus;
//       OpenTradeAccountDetails.referenceEscrowAccount =
//         PaymentData.referenceEscrowAccount;
//     }

//     setIsPaymentFormOpen(false);
//   };

//   const handleAbort = (e) => {
//     e.preventDefault();
//     if (OpenTradeAccountDetails) {
//       OpenTradeAccountDetails.openTradeStatus = "ABORTED";
//     }
//     const options = {
//       method: "PUT",
//       body: OpenTradeAccountDetails,
//     };

//     let URL = `${openTradeAccount}openaccounttrades/${id}`;
//     setIsLoading(true);
//     getIdTokenAsync(instance, accounts).then((response) => {
//       UseRequest(URL, options, response).then((data) => {
//         setIsLoading(false);
//         let { message, status } = data;
//         if (message || status) {
//           Error(message || "Something Went Wrong");
//           setIsError(message);
//         } else {
//           Success("Buyer Added successfully...");
//           if (data.openTradeStatus === "ABORTED") {
//             handleNavigate(`../open-account`);
//           }
//         }
//       });
//     });
//   };

//   const handlePutSubmitPayment = (e) => {
//     e.preventDefault();
//     e.preventDefault();
//     if (OpenTradeAccountDetails) {
//       OpenTradeAccountDetails.escrowServiceProviderUID =
//         PaymentData.RefSellerVirtualBankAccountId;
//       OpenTradeAccountDetails.RefSellerVirtualBankAccountId =
//         PaymentData.RefSellerVirtualBankAccountId;
//       //   OpenTradeAccountDetails.RefBuyerVirtualBankAccountId =
//       //     PaymentData.RefSellerVirtualBankAccountId;

//       OpenTradeAccountDetails.escrowConsentStatus =
//         PaymentData.escrowConsentStatus;
//       OpenTradeAccountDetails.referenceEscrowAccount =
//         PaymentData.referenceEscrowAccount;
//     }
//     const options = {
//       method: "PUT",
//       body: OpenTradeAccountDetails,
//     };
//     OpenTradeAccountDetails.escrowConsentStatus = "BUYER_NOT_LINKED";
//     let URL = `${openTradeAccount}openaccounttrades/${id}`;
//     setIsLoading(true);
//     getIdTokenAsync(instance, accounts).then((response) => {
//       UseRequest(URL, options, response).then((data) => {
//         setIsLoading(false);
//         let { message, status } = data;
//         if (message || status) {
//           Error(message || "Something Went Wrong");
//           setIsError(message);
//         } else {
//           Success("Payment Updated Successfully...");
//           setIsAdded(data);
//           handleNavigate(`../open-account/${id}`);
//         }
//       });
//     });
//   };

//   return (
//     <>
//       {isLoading || List.isLoading || isLoading1 || isLoading2 ? (
//         <Loader />
//       ) : (
//         <>
//           {List && List.response && (
//             <Content>
//               <OverLayTrigger value={ModifyTadeInfo} />

//               <form onSubmit={(e) => handlePutSubmitPayment(e)}>
//                 <div className={classes.FormDiv}>
//                   <div>
//                     <div>Payment Service Provider</div>
//                     <div style={{ fontSize: "10px", opacity: "0.6" }}>
//                       Select the Payment Service Provider
//                     </div>
//                   </div>
//                   <div>
//                     <Select
//                       name="escrowServiceProviderUID"
//                       placeholder="Payment Service Provider"
//                       options={provider}
//                       value={PaymentData.escrowServiceProviderUID}
//                       handleChange={handlePaymentChange}
//                     />
//                   </div>
//                   <div>
//                     <div>Payment Account (Seller)</div>
//                     <div style={{ fontSize: "10px", opacity: "0.6" }}>
//                       Select the Payment Account for this trade
//                     </div>
//                   </div>
//                   <div>
//                     <Select
//                       name="RefSellerVirtualBankAccountId"
//                       options={provider2}
//                       placeholder="Ref Seller Virtual BankAccount Id"
//                       value={PaymentData.RefSellerVirtualBankAccountId}
//                       handleChange={handlePaymentChange}
//                     />
//                   </div>
//                   <div>
//                     <div>Payment Account (Buyer)</div>
//                     <div style={{ fontSize: "10px", opacity: "0.6" }}>
//                       Select the Payment Account for this trade
//                     </div>
//                   </div>
//                   <div>
//                     <Select
//                       name="RefBuyerVirtualBankAccountId"
//                       placeholder="Ref Buyer Virtual BankAccount Id *"
//                       value={PaymentData.RefBuyerVirtualBankAccountId}
//                       handleChange={handlePaymentChange}
//                       readOnly={true}
//                     />
//                   </div>
//                 </div>
//                 <ButtonDiv>
//                   <Button onClick={handleAbort}>Abort Trade</Button>
//                   <Button
//                     onClick={() => {
//                       handleNavigate(`../open-account`);
//                     }}>
//                     {" "}
//                     Cancel
//                   </Button>
//                   <Button type="submit">Confirm</Button>
//                 </ButtonDiv>
//               </form>
//             </Content>
//           )}

//           {(isError1 || isError2) && <span>Something went wrong</span>}
//         </>
//       )}
//       <ToastContainer
//         position="top-right"
//         autoClose={5000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable
//         pauseOnHover
//       />
//     </>
//   );
// };
const ModifyPayment = () => {
  const { id } = useParams();
  const { OpenTradeAccountDetails } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const { instance, accounts } = useMsal();
  const [companyList, setCompanyList] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const [DatePicker1, setDatePicker1] = useState(null);
  const [DatePicker2, setDatePicker2] = useState(null);
  const [DatePicker3, setDatePicker3] = useState(null);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isAdded, setIsAdded] = useState(null);
  const [payment, setPayment] = useState(null);
  const [provider, setProvider] = useState([]);
  const [provider2, setProvider2] = useState([]);
  const [isAborted, setIsAborted] = useState(true);
  const [OpenTradeAccountDetail, setOpenTradeAccountDetail] = useState();
  const [modifyData, setModifyData] = useState(null);


  const theme = createTheme({
    palette: {

      secondary: {
        // This is green.A700 as hex.
        main: '#808080',
      },
    },
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setPayment(data);
          }
        });
      });
    }
	
	if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}openaccounttrades/${id}`,
          null,
          response
        ).then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            setModifyData(data);
          }
        });
      });
    }
	
    return () => {
      isCancelled = true;
    };
  }, []);
  
   useEffect(() => {
    if (modifyData) {
      setPaymentData({
        escrowServiceProviderUID: modifyData.escrowServiceProviderUID,
        RefSellerVirtualBankAccountId: modifyData.RefSellerVirtualBankAccountId,
        escrowConsentStatus: "NEW",
        referenceEscrowAccount: modifyData.referenceEscrowAccount,
      });
    }
  }, [modifyData]);

  const [PaymentData, setPaymentData] = useState({
    escrowServiceProviderUID: "",
    RefSellerVirtualBankAccountId: "",
    escrowConsentStatus: "NEW",
    referenceEscrowAccount: "",
  });

  useEffect(() => {
    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.paymentProvider,
          value: item.paymentProvider,
        };
      });
      setProvider(temp);
    }

    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.virtualAccountNumber,
          value: item.virtualAccountNumber,
        };
      });
      setProvider2(temp);
    }
  }, [payment]);

  const handlePaymentChange = (event) => {
    if (event.target.name === "escrowServiceProviderUID") {
      let value = event.target.value;
      const temp =
        payment &&
        payment[0] !== null &&
        payment.filter((res) => res.paymentProvider === event.target.value);

      setPaymentData({
        ...PaymentData,
        [event.target.name]: event.target.value,
        ["RefSellerVirtualBankAccountId"]: temp[0].virtualAccountNumber,
      });
    }
  };

  // CompanyList API
  const List = CompanyList();
  useEffect(() => {
    if (List && List.response) {
      let temp = List.response.filter((item) => {
        if (
          item.memberType === "FINANCER" ||
          item.memberType === "COMPANY" ||
          item.memberUID === companyId
        ) {
          return { label: item.memberName, value: item.memberUID };
        }
      });

      let filter =
        temp &&
        temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });

      setCompanyList(filter);
    }
  }, [List && List.response]);

  const handleAbort = (e) => {
    e.preventDefault();
    OpenTradeAccountDetails.openTradeStatus = "ABORTED";
    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    let URL = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError(message);
        } else {
          setIsAborted(false);
          if (data.openTradeStatus === "ABORTED") {
            handleNavigate(`../open-account/${id}`);
          }
        }
      });
    });
  };

  const handlePutSubmitPayment = (e) => {
    e.preventDefault();
    if (OpenTradeAccountDetails) {
      OpenTradeAccountDetails.escrowServiceProviderUID =
        PaymentData.RefSellerVirtualBankAccountId;
      OpenTradeAccountDetails.RefSellerVirtualBankAccountId =
        PaymentData.RefSellerVirtualBankAccountId;
      //   OpenTradeAccountDetails.RefBuyerVirtualBankAccountId =
      //     PaymentData.RefSellerVirtualBankAccountId;

      OpenTradeAccountDetails.escrowConsentStatus =
        PaymentData.escrowConsentStatus;
      OpenTradeAccountDetails.referenceEscrowAccount =
        PaymentData.referenceEscrowAccount;
    }

    const options = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    OpenTradeAccountDetails.escrowConsentStatus = "BUYER_NOT_LINKED";
    OpenTradeAccountDetails.openTradeStatus = "NEW";
    let URL = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError(message);
        } else {
          setIsAdded(data);
          Success("Trade Created successfully...");
          setOpenTradeAccountDetail(data);
          handleNavigate(`../open-account/${id}`);
        }
      });
    });
  };
  sessionStorage.setItem(
    "OpenTradeAccountDetail",
    JSON.stringify(OpenTradeAccountDetail)
  );
  return (
    <>
      {isLoading || List.isLoading ? (
        <Content marginBottom="0%">
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {!List.isError && !isError && (
            <Content marginBottom="0%">
              <div style={{ marginBottom: "3%" }}>
                <div>Payment Preference Info</div>
                <div style={{ display: "flex" }}>
                  <ThemeProvider theme={theme}>
                    <Switch
                      color={OpenTradeAccountDetails.escrowService === "OFFLINE" ? 'secondary' : "info"}
                      checked={true}
                    />
                  </ThemeProvider>
                  <div style={{ marginTop: "0.6%" }}>{OpenTradeAccountDetails.escrowService === "OFFLINE" ? 'Offline Payment' : "Online Payment"}</div>
                </div>
              </div>
              {OpenTradeAccountDetails.escrowService !== "OFFLINE" &&
                <div>
                  <OverLayTrigger value={AccountInfoSelect} />
                  <div className={classes.FormDiv}>
                    <div>
                      <div>Payment Service Provider</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Service Provider
                      </div>
                    </div>
                    <div>
                      <Select
                        name="escrowServiceProviderUID"
                        placeholder="Payment Service Provider"
                        options={provider}
                        value={PaymentData.escrowServiceProviderUID}
                        handleChange={handlePaymentChange}
                      />
                    </div>
                    <div>
                      <div>Payment Account (Seller)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    <div>
                      <Select
                        name="RefSellerVirtualBankAccountId"
                        options={provider2}
                        placeholder="Ref Seller Virtual BankAccount Id"
                        value={PaymentData.RefSellerVirtualBankAccountId}
                        handleChange={handlePaymentChange}
                      />
                    </div>
                    <div>
                      <div>Payment Account (Buyer)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    <div>
                      <Select
                        name="RefBuyerVirtualBankAccountId"
                        placeholder="Ref Buyer Virtual BankAccount Id *"
                        value={PaymentData.RefBuyerVirtualBankAccountId}
                        handleChange={handlePaymentChange}
                        readOnly={true}
                      />
                    </div>
                  </div>
                </div>
              }
            </Content>
          )}
          <ButtonDiv>
            <Button
              onClick={() => {
                handleNavigate(`../open-account/${id}`);
              }}>
              Go Back
            </Button>
            <Button onClick={handleAbort}>Abort Trade</Button>
            {OpenTradeAccountDetails?.escrowService === "OFFLINE" ? (
              <Button onClick={(e) => handlePutSubmitPayment(e)}>
                Confirm Payment Mode
              </Button>
            ) : (
              (PaymentData.escrowServiceProviderUID && PaymentData.RefSellerVirtualBankAccountId) ? (
                <Button onClick={(e) => handlePutSubmitPayment(e)}>
                  Confirm
                </Button>
              ) : (
                <button className={classes.isDisabled}> Confirm </button>
              )
            )}
          </ButtonDiv>

          {(List.isError || isError) && (
            <Content marginBottom="0%">
              {List.isError}
              {isError}
              Something went wrong
            </Content>
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default ModifyPayment;
