import Content from "components/UI/Layout/Content";
import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import classes from "./Scheduled_Payments.module.css";
import cn from "classnames";
import SchedulePaymentDetails from "./SchedulePaymentDetails";
import { ScheduledPaymentsHeader } from "utils/TableHeader/PaymentSection";
import { openTradeAccount } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import { truncateStringID } from "utils/helper";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Loader from "components/Loader/Loader";
import { BsArrowLeft } from "react-icons/bs";
import { getClaim, getIdToken } from "utils/authUtil";
// import { useMsal } from "@azure/msal-react";
import { RiWechatLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { fetchMemberdata } from "utils/memberchat";

import { STATUS } from "utils/Constants";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import MultiSelect from "components/fields/MultiSelect";
import CustomizedSteppers from "components/UI/Stepper/Stepper";
import { ScheduleAndPaymentsFlow } from "utils/Section/Workflow";
import NoRecord from "components/no-data/noRecord";

const TableData = ({ hanndleCraateGroup }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [schedule, setSchedule] = useState(null);
  const [filteredList, setFilteredList] = useState(null);
  const [isError, setIsError] = useState(null);
  const { instance, accounts } = useMsal();
  const [isSingleScheduleOpen, setIsSingleScheduleOpen] = useState(false);
  const [scheduleData, setScheduleData] = useState(null);
  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const navigate = useNavigate();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [stageList, setStageList] = useState([]);
  const [selectedStage, setSelectedStage] = useState([]);
  const [selectedStageID, setSelectedStageId] = useState([]);

  const [subStageList, setSubStageList] = useState([]);
  const [selectedSubStage, setSelectedSubStage] = useState([]);
  const [selectedSubStageID, setSelectedSubStageId] = useState([]);

  const [selectStatusList, setSelectStatusList] = useState([]);
  const [selectStatusID, setSelectStatusID] = useState([]);

  const [CategoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryID, setSelectedCategoryId] = useState([]);

  const [SubCategoryList, setSubCategoryList] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedSubCategoryID, setSelectedSubCategoryId] = useState([]);

  const handleUpdateData = (item) => {
    setIsSingleScheduleOpen(true);
    sessionStorage.setItem("scheduleData", JSON.stringify(item));
    setScheduleData(item);
  };

  const handleNavigate = () => {
    setIsSingleScheduleOpen(false);
  };

  useEffect(() => {
    let URL = `${openTradeAccount}openaccounttrades/schedules?sellerMemberUID=${companyId}&buyerMemberUID=${companyId}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError(message);
        } else {
          let newData = data.sort(
            (a, b) => Date.parse(b.milestoneDate) - Date.parse(a.milestoneDate)
          );
          setSchedule(newData);
          setFilteredList(newData);
        }
      });
    });
  }, []);

  useEffect(() => {
    if (schedule) {
      const uniqueCategory = [
        ...new Set(schedule.map((item) => item.tradeStage)),
      ];
      let filterCategory =
        uniqueCategory &&
        uniqueCategory.map((item) => {
          return { label: item, value: item };
        });
      setStageList(filterCategory);
    }

    // if (schedule) {
    //   const uniqueCategory = [
    //     ...new Set(schedule.map((item) => item.tradeSubStage)),
    //   ];
    //   let filterCategory =
    //     uniqueCategory &&
    //     uniqueCategory.map((item) => {
    //       return { label: item, value: item };
    //     });
    //   setSubStageList(filterCategory);
    // }

    if (schedule) {
      const uniqueCategory = [
        ...new Set(
          schedule.map(
            (item) =>
              item.documentGroups &&
              item.documentGroups[0] &&
              item.documentGroups[0].docCategory
          )
        ),
      ];
      let filterCategory =
        uniqueCategory &&
        uniqueCategory.map((item) => {
          return { label: item, value: item };
        });
      setCategoryList(filterCategory);
    }

    // if (schedule) {
    //   const uniqueCategory = [
    //     ...new Set(
    //       schedule.map(
    //         (item) =>
    //           item.documentGroups &&
    //           item.documentGroups[0] &&
    //           item.documentGroups[0].docSubCategory
    //       )
    //     ),
    //   ];
    //   let filterCategory =
    //     uniqueCategory &&
    //     uniqueCategory.map((item) => {
    //       return { label: item, value: item };
    //     });
    //   setSubCategoryList(filterCategory);
    // }
  }, [schedule]);

  useEffect(() => {
    if (filteredList) {
      const uniqueCategory = [
        ...new Set(filteredList.map((item) => item.tradeSubStage)),
      ];
      let filterCategory =
        uniqueCategory &&
        uniqueCategory.map((item) => {
          return { label: item, value: item };
        });
      setSubStageList(filterCategory);
    }

    if (filteredList) {
      const uniqueCategory = [
        ...new Set(
          filteredList.map(
            (item) =>
              item.documentGroups &&
              item.documentGroups[0] &&
              item.documentGroups[0].docSubCategory
          )
        ),
      ];
      let filterCategory =
        uniqueCategory &&
        uniqueCategory.map((item) => {
          return { label: item, value: item };
        });
      setSubCategoryList(filterCategory);
    }
  }, [filteredList]);

  const fetchDatas = async (data) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    let queryData = "";
    for (let i = 0; i < data?.length; i++) {
      queryData += `organizationMemberId=${data[i]}&`;
    }

    queryData = queryData.substring(0, queryData.length - 1);
    console.log(queryData);
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?${queryData}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("fetchdata-CBCBCBCB", data);
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        setMemberList(nData);
      });
  };

  const chatHandler = async (ds) => {
    if (ds?.participants?.length > 0) {
      let usrData = [];
      let myds = ds?.participants;
      for (let i = 0; i < myds.length; i++) {
        usrData.push(myds[i]?.participant);
      }
      setItem(ds);

      //fetchDatas(usrData)
      let res = await fetchMemberdata(usrData);
      if (res?.length > 0) {
        let name = ds.openTradeScheduleUID
          ? truncateStringID(ds.openTradeScheduleUID, 7, "OT")
          : "NA";
        hanndleCraateGroup(res, name, name);
        setMemberList(res);
      }
    }
  };

  // const hanndleCraateGroup = () => {
  //   if (memberList?.length > 0) {
  //     let url = window.location.href;
  //     localStorage.setItem("lasturl", url);
  //     const selectedUsers = memberList;
  //     const params = new sdk.GroupChannelParams();
  //     params.addUserIds(selectedUsers);
  //     params.isDistinct = false;
  //     params.isEphemeral = false;
  //     params.isPublic = false;

  //     params.name = item.openTradeScheduleUID
  //       ? truncateStringID(item.openTradeScheduleUID, 7, "OT")
  //       : "NA";
  //     params.channelUrl = params.name;
  //     sdk.GroupChannel.createChannel(params, (response, error) => {
  //       const swapParams = sdk.getErrorFirstCallback();
  //       let groupChannel = response;
  //       let err = error;
  //       if (swapParams) {
  //         groupChannel = error;
  //         err = response;
  //       }
  //       if (err) {
  //         navigate("/chat?url=" + params.channelUrl);
  //         console.log(err);
  //       } else {
  //         navigate("/chat?url=" + params.channelUrl);
  //       }
  //     });
  //   }
  // };

  useEffect(() => {
    if (memberList);
  }, [memberList]);

  const Status = (status) => {
    switch (status) {
      case "MILESTONE_CREATED": // done
        return STATUS(status, "primary");
      case "MILESTONE_APPROVAL_REQUESTED": // done
        return STATUS(status, "primary");
      case "MILESTONE_APPROVED": // done
        return STATUS(status, "success");
      case "MILESTONE_DECLINED": // done
        return STATUS(status, "danger");
      case "MILESTONE_ABORTED": // done
        return STATUS(status, "danger");
      case "MILESTONE_REACHED": // done
        return STATUS(status, "primary");
      case "MILESTONE_COMPLETED": // done
        return STATUS(status, "success");
      case "DELIVERY_IN_PROGRESS":
        return STATUS(status, "warning");
      case "DELIVERY_COMPLETED": // done
        return STATUS(status, "success");
      case "DELIVERY_APPROVED": // done
        return STATUS(status, "success");
      case "DELIVERY_INITIATED":
        return STATUS(status, "warning");
      case "DELIVERY_DECLINED": // done
        return STATUS(status, "danger");
      case "PAYMENT_INITIATED":
        return STATUS(status, "warning");
      case "PAYMENT_APPROVED": // done
        return STATUS(status, "success");
      case "PAYMENT_PAST_DUE_DATE":
        return STATUS(status, "warning");
      case "PAYMENT_COMPLETED": // done
        return STATUS(status, "success");
      case "PAYMENT_DECLINED": // done
        return STATUS(status, "danger");
      case "DRAFT": // done
        return STATUS(status, "warning");
      case "OTHERS": // done
        return STATUS(status, "primary");
      case "NEW": // done
        return STATUS(status, "primary");
      case "ABORTED": // done
        return STATUS(status, "danger");
      default:
        return status;
    }
  };

  const handleMultiChange = (e, name) => {
    if (name === "stage") {
      const { value } = e.target;
      setSelectedStage(value);
    }

    if (name === "substage") {
      const { value } = e.target;
      setSelectedSubStage(value);
    }

    if (name === "status") {
      const { value } = e.target;
      setSelectStatusList(value);
    }

    if (name === "Category") {
      const { value } = e.target;
      setSelectedCategory(value);
    }

    if (name === "SubCategory") {
      const { value } = e.target;
      setSelectedSubCategory(value);
    }
  };

  useEffect(() => {
    if (selectedStage) {
      let temporaryList = [];
      selectedStage.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedStageId(temporaryList);
    }

    if (selectedSubStage) {
      let temporaryList = [];
      selectedSubStage.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedSubStageId(temporaryList);
    }

    if (selectStatusList) {
      let temporaryList = [];
      selectStatusList.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectStatusID(temporaryList);
    }

    if (selectedCategory) {
      let temporaryList = [];
      selectedCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedCategoryId(temporaryList);
    }

    if (selectedSubCategory) {
      let temporaryList = [];
      selectedSubCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedSubCategoryId(temporaryList);
    }
  }, [
    selectedStage,
    selectedSubStage,
    selectStatusList,
    selectedCategory,
    selectedSubCategory,
  ]);

  useEffect(() => {
    if (
      (selectStatusID && selectStatusID.length > 0) ||
      (selectedStageID && selectedStageID.length > 0) ||
      (selectedSubStageID && selectedSubStageID.length > 0) ||
      (selectedCategoryID && selectedCategoryID.length > 0) ||
      (selectedSubCategoryID && selectedSubCategoryID.length > 0)
    ) {
      let filtered =
        schedule &&
        schedule.filter(
          (data) =>
            // (selectedStageID.length > 0
            //   ? selectedStageID.includes(data.tradeStage)
            //   : data) &&
            // (selectedSubStageID.length > 0
            //   ? selectedSubStageID.includes(data.tradeSubStage)
            //   : data)
            (selectedSubStageID.length > 0 && selectedStageID.length > 0
              ? selectedSubStageID.includes(data.tradeStage) ||
                selectedStageID.includes(data.tradeStage)
              : selectedSubStageID.length > 0
              ? selectedSubStageID.includes(data.tradeSubStage)
              : selectedStageID.length > 0
              ? selectedStageID.includes(data.tradeStage)
              : data) &&
            (selectStatusID.length > 0
              ? selectStatusID.includes(data.tradeMilestoneStatus)
              : data) &&
            // (selectedCategoryID.length > 0
            //   ? selectedCategoryID.includes(
            //       data.documentGroups &&
            //         data.documentGroups[0] &&
            //         data.documentGroups[0].docCategory
            //     )
            //   : data) &&
            // (selectedSubCategoryID.length > 0
            //   ? selectedSubCategoryID.includes(
            //       data.documentGroups &&
            //         data.documentGroups[0] &&
            //         data.documentGroups[0].docSubCategory
            //     )
            //   : data)
            (selectedSubCategoryID.length > 0 && selectedCategoryID.length > 0
              ? selectedSubCategoryID.includes(
                  data.documentGroups &&
                    data.documentGroups[0] &&
                    data.documentGroups[0].docSubCategory
                ) ||
                selectedCategoryID.includes(
                  data.documentGroups &&
                    data.documentGroups[0] &&
                    data.documentGroups[0].docCategory
                )
              : selectedSubCategoryID.length > 0
              ? selectedSubCategoryID.includes(
                  data.documentGroups &&
                    data.documentGroups[0] &&
                    data.documentGroups[0].docSubCategory
                )
              : selectedCategoryID.length > 0
              ? selectedCategoryID.includes(
                  data.documentGroups &&
                    data.documentGroups[0] &&
                    data.documentGroups[0].docCategory
                )
              : data)
        );
      setFilteredList(filtered);
    } else if (
      selectedStageID.length === 0 &&
      selectedSubStageID.length === 0 &&
      selectStatusID.length === 0 &&
      selectedCategoryID.length === 0 &&
      selectedSubCategoryID.length === 0
    ) {
      setFilteredList(schedule);
    }
  }, [
    selectedStageID,
    selectedSubStageID,
    selectStatusID,
    selectedCategoryID,
    selectedSubCategoryID,
  ]);

  const StatusList = [
    { label: "NEW", value: "NEW" },
    { label: "MILESTONE_CREATED", value: "MILESTONE_CREATED" },
    {
      label: "MILESTONE_APPROVAL_REQUESTED",
      value: "MILESTONE_APPROVAL_REQUESTED",
    },
    { label: "MILESTONE_APPROVED", value: "MILESTONE_APPROVED" },
    { label: "MILESTONE_DECLINED", value: "MILESTONE_DECLINED" },
    { label: "MILESTONE_ABORTED", value: "MILESTONE_ABORTED" },
    { label: "MILESTONE_REACHED", value: "MILESTONE_REACHED" },
    { label: "MILESTONE_COMPLETED", value: "MILESTONE_COMPLETED" },
    { label: "DELIVERY_INITIATED", value: "DELIVERY_INITIATED" },
    { label: "DELIVERY_COMPLETED", value: "DELIVERY_COMPLETED" },
    { label: "DELIVERY_IN_PROGRESS", value: "DELIVERY_IN_PROGRESS" },
    { label: "DELIVERY_APPROVED", value: "DELIVERY_APPROVED" },
    { label: "DELIVERY_DECLINED", value: "DELIVERY_DECLINED" },
    { label: "PAYMENT_INITIATED", value: "PAYMENT_INITIATED" },
    { label: "PAYMENT_APPROVED", value: "PAYMENT_APPROVED" },
    { label: "PAYMENT_PAST_DUE_DATE", value: "PAYMENT_PAST_DUE_DATE" },
    { label: "PAYMENT_COMPLETED", value: "PAYMENT_COMPLETED" },
    { label: "PAYMENT_DECLINED", value: "PAYMENT_DECLINED" },
    { label: "OTHERS", value: "OTHERS" },
    { label: "ABORTED", value: "ABORTED" },
  ];

  // 1. Draft - "DRAFT",
  // 2. New   - "NEW",
  // 3. Milestone "MILESTONE_CREATED",
  // 4. Approval - "MILESTONE_APPROVAL_REQUESTED", "MILESTONE_APPROVED", "MILESTONE_DECLINED",
  // 6. Delivery - "MILESTONE_REACHED","DELIVERY_INITIATED", "DELIVERY_IN_PROGRESS", "DELIVERY_COMPLETED",
  // 7. Delivery Confirmed - "DELIVERY_APPROVED","DELIVERY_DECLINED",
  // 8. Payments - "PAYMENT_INITIATED", "PAYMENT_APPROVED", "PAYMENT_PAST_DUE_DATE", "PAYMENT_COMPLETED", "PAYMENT_DECLINED",
  // 9. Completed -    "MILESTONE_COMPLETED","ABORTED", "OTHERS"
  // 5. Aborted  - "MILESTONE_ABORTED",

  const StepperPosition = (status) => {
    switch (status) {
      case "DRAFT":
        return { index: 0, status: "progress" };
      case "NEW":
        return { index: 1, status: "progress" };
      case "MILESTONE_CREATED":
        return { index: 2, status: "completed" };

      case "MILESTONE_APPROVAL_REQUESTED":
        return { index: 3, status: "progress" };
      case "MILESTONE_APPROVED":
        return { index: 3, status: "completed" };
      case "MILESTONE_DECLINED":
        return { index: 3, status: "decline" };
      case "MILESTONE_REACHED":
        return { index: 4, status: "progress" };
      case "DELIVERY_INITIATED":
        return { index: 4, status: "progress" };
      case "DELIVERY_IN_PROGRESS":
        return { index: 4, status: "progress" };
      case "DELIVERY_COMPLETED":
        return { index: 4, status: "completed" };
      case "DELIVERY_APPROVED":
        return { index: 5, status: "completed" };
      case "DELIVERY_DECLINED":
        return { index: 5, status: "decline" };

      case "PAYMENT_INITIATED":
        return { index: 6, status: "progress" };
      case "PAYMENT_APPROVED":
        return { index: 6, status: "completed" };
      case "PAYMENT_PAST_DUE_DATE":
        return { index: 6, status: "progress" };
      case "PAYMENT_COMPLETED":
        return { index: 6, status: "completed" };
      case "PAYMENT_DECLINED":
        return { index: 6, status: "decline" };

      case "MILESTONE_COMPLETED":
        return { index: 7, status: "completed" };
      case "OTHERS":
        return { index: 7, status: "decline" };

      case "MILESTONE_ABORTED":
        return { index: 8, status: "decline" };

      default:
        return 0;
    }
  };

  return (
    <>
      <>
        {isLoading ? (
          <Content marginBottom="0%">
            <span>Loading...</span>
            <Loader />
          </Content>
        ) : (
          <>
            {schedule && (
              <>
                {isSingleScheduleOpen ? (
                  <>
                    <span
                      className={cn(classes.action)}
                      onClick={handleNavigate}>
                      <span>
                        <BsArrowLeft />
                      </span>
                      <span>Back To List</span>
                    </span>
                    {scheduleData && (
                      <>
                        <Content>
                          <h5>Schedule & Payments</h5>
                          <CustomizedSteppers
                            Value={ScheduleAndPaymentsFlow}
                            position={StepperPosition(
                              scheduleData.tradeMilestoneStatus
                            )}
                          />
                        </Content>
                        <Content>
                          <SchedulePaymentDetails data={scheduleData} />
                        </Content>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <Content marginBottom="2%">
                      <h5>Schedule & Payment Search</h5>
                      <div
                        className={cn(
                          classes.FilterDiv,
                          "d-flex justify-content-between align-items-baseline"
                        )}>
                        <div style={{ marginRight: "10px" }}>
                          <MultiSelect
                            name="memberlist"
                            selected={selectedStage}
                            placeholder="Stage"
                            options={stageList}
                            handleChange={(e) => handleMultiChange(e, "stage")}
                          />
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <MultiSelect
                            name="memberlist"
                            placeholder="Sub-Stage"
                            selected={selectedSubStage}
                            options={subStageList}
                            handleChange={(e) =>
                              handleMultiChange(e, "substage")
                            }
                          />
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <MultiSelect
                            name="memberlist"
                            placeholder="Status"
                            selected={selectStatusList}
                            options={StatusList}
                            handleChange={(e) => handleMultiChange(e, "status")}
                          />
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <MultiSelect
                            name="memberlist"
                            placeholder="Category"
                            selected={selectedCategory}
                            options={CategoryList}
                            handleChange={(e) =>
                              handleMultiChange(e, "Category")
                            }
                          />
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <MultiSelect
                            name="memberlist"
                            placeholder="Sub-Category"
                            selected={selectedSubCategory}
                            options={SubCategoryList}
                            handleChange={(e) =>
                              handleMultiChange(e, "SubCategory")
                            }
                          />
                        </div>
                        {/* <div></div> */}
                      </div>
                      {/* <div
                        className={cn(
                          classes.FilterDiv,
                          "d-flex justify-content-between align-items-center"
                        )}
                      >
                        
                      </div> */}
                    </Content>

                    <Content marginBottom="0%">
                      <div>
                        <h6>Payment Details</h6>
                      </div>
                      <div>
                        <Table
                          bordered
                          responsive
                          className={cn(classes.Table)}>
                          <thead>
                            <tr>
                              {ScheduledPaymentsHeader &&
                                ScheduledPaymentsHeader.map((header, index) => (
                                  <td key={index}>{header.name}</td>
                                ))}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredList &&
                              filteredList.map((item, index) => (
                                <tr key={index}>
                                  <td
                                    style={{
                                      color: "rgb(33,142,235",
                                      textDecorationLine: "underline",
                                    }}
                                    onClick={() => handleUpdateData(item)}>
                                    {item.openTradeScheduleUID &&
                                      truncateStringID(
                                        item.openTradeScheduleUID,
                                        7,
                                        "SP"
                                      )}
                                  </td>
                                  <td>{item.tradeStage}</td>
                                  <td>{item.tradeSubStage}</td>
                                  <td>
                                    {item.documentGroups &&
                                      item.documentGroups[0] &&
                                      item.documentGroups[0].docCategory}
                                  </td>
                                  <td>
                                    {item.documentGroups &&
                                      item.documentGroups[0] &&
                                      item.documentGroups[0].docSubCategory}
                                  </td>
                                  <td>
                                    {new Date(
                                      item.paymentDate
                                    ).toLocaleDateString()}
                                  </td>
                                  <td>{item.milestonePaymentAmount}</td>
                                  <td>{Status(item.tradeMilestoneStatus)}</td>
                                  <td>
                                    <div className={classes["flex-1"]}>
                                      <span
                                        className="px-3"
                                        onClick={(e) => chatHandler(item)}>
                                        <RiWechatLine size={22} />
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                      {filteredList && filteredList.length === 0 && (
                        <NoRecord />
                      )}
                    </Content>
                  </>
                )}
              </>
            )}
            {isError && <Content marginBottom="0%">{isError}</Content>}
          </>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    </>
  );
};

export default TableData;
