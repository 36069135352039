import React , {useContext ,useEffect , useState} from 'react'
import classes from './History.module.css'
import { storeContext } from "../../../contexts/Store/Store";
import { HistoryURL } from "../../../services/BaseURLInstance";
const History = ({params}) => {
  const { getData } = useContext(storeContext);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [Response, setResponse] = useState(null);

  var queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
  useEffect(() => {
    let isCancelled = false;

    if (idToken) {
      let URL = `${HistoryURL}history?${queryString}`
      getData(URL, idToken).then((data) => {
        if (!isCancelled) {
            setResponse(data)
        }
      });
    }

    return () => {
      isCancelled = true;
    };
    }, []);

    const headers = [
        { name : 'Domain'}, 
        { name: 'Entity Type' }, 
        { name : 'Activity'}, 
        { name : 'Actor'}, 
        { name : 'Date'},
    ];

  return (
    <>
       <div className={classes.Header}>
          {headers && headers.map((header) =>(
            <div> {header.name}</div>
          ))}
       </div>
       <div>        
       { Response  && Response.map( (item, i) => 
             <div className={classes.Column} key={i} >
             <div>{item.domain}</div>
             <div> {item.domainEntityType}</div>
             <div> {item.eventType} </div>
             <div>{item.actorMemberUID}</div>
             <div></div>
             </div>
       )}
       </div>
   </>
  )
}

export default History