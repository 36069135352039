import React, { useState, useEffect, useContext } from "react";
import { MdCheckBoxOutlineBlank, MdMailOutline } from "react-icons/md";
import { IoChatbubblesOutline } from "react-icons/io5";
import styled from "styled-components";
import MainTable from "../financing/mainTable";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { storeContext } from '../../../../../contexts/Store/Store'
import {NetworkURL} from '../../../../../services/BaseURLInstance';
import { useRef } from "react";

const Styles = styled.div`
  .tablebox{
    margin-right:20px;
    padding:16px;
    background: #343434;
    width:97.5%;
    border-radius: 10px;
  }
`;

export default ({hanndleCraateGroup}) => {
  const { fetchCompanyList } = useContext(storeContext)
  const { instance, accounts } = useMsal();
  // const memberRef=useRef(false);
  // const listRef=useRef(true);
  // const [idToken, setIdToken] = useState(null);
  // getIdToken(instance, accounts, setIdToken);
  const companyId = getClaim(accounts, "company_member_id");
  const [companyList,setCompanyList]=useState();
  let financerMemberUID = "";
  let traderMemberUID = "";
  const invoiceId = ""

  const [userType, setUserType] = useState("");
  const [response, setResponse] = useState("");


  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken).then((list) => {
      setCompanyList(list);
      let user = list.filter(item => {
        if (item.memberUID === companyId) {
          return item.memberType
        }
      })
      setUserType(user[0].memberType);
    }).catch((error) => {
      console.log(error)
    })
  }, [])


  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    if (userType !== "") {
      if (userType === "COMPANY") {
        traderMemberUID = companyId;
      }
      else {
        financerMemberUID = companyId;
      }
      let URL = `${process.env.REACT_APP_FINANCE_URL}finance/deals?traderMemberUID=${traderMemberUID}&financerMemberUID=${financerMemberUID}&invoiceId=${invoiceId}`;
      fetchCompanyList(URL, idToken).then((data) => {
        setResponse(data)
      }).catch((error) => {
        console.log(error)
      })
    }
  }, [userType])



  const finanacingInvoice = [
    { value: "uploadedInvoices", name: "Open Invoices" },
    { value: "financingOffers", name: "Document Access Request" },
    { value: "openUpcomingRePayments", name: "Upcoming Re-Payments" },
    { value: "openUpcomingDisbusals", name: "Pending Disbursals" },
  ];






  const headerFieldsList = [
    {
      Header: "Financer",
      accessor: "id",
      // Cell: (cell) => (
      //   <div onClick={() => handleNavigate(cell.value)}>{cell.value}</div>
      // ),
    },
    {
      Header: "Offer Details",
      accessor: "interestRate",
    },
    {
      Header: "Status",
      accessor: "financeDealStatus",
    },
    {
      Header: "Last Activity Date",
      accessor: "lastUpdatedDate",
    },
    {
      width: 100,
      Header: "Action",
      accessor: "action",
      Cell: (cell) => (
        <span
          value={cell.accessor}
        // onClick={() => handlePromise(cell.cell.row.values)}
        >
          <MdMailOutline size={26} color="#42A5F5" />
          <IoChatbubblesOutline size={26} color="#5D5D5D" />
        </span>
      ),
    },
  ];
  const columns = React.useMemo(() => headerFieldsList);
  return (
    <>
      {/* {isLoading && <Loader />} */}
      <Styles>
        {/* {isLoading && <Loader />} */}
        
        <div className="loanList">
          {
            companyList && 
            Array.isArray(response) && <div className="tablebox">
              <MainTable hanndleCraateGroup={hanndleCraateGroup} data={response} financerMemberUID={"dc428603-4f18-b99b-b712-6fcb7fde510e"} companyList={companyList} />
            </div>
          }
        </div>

      </Styles>
    </>
  );
};
