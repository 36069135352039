import React, { useState, useEffect, useCallback, useRef } from "react";
import Content from "components/UI/Layout/Content";
import Container from "components/UI/Layout/Container";
import Loader from "components/Loader/Loader";
import classes from "./documentNew.module.css";
import cn from "classnames";
import Table from "react-bootstrap/Table";
import { AllDocumentsHeader } from "utils/Section/Document";
import MultiSelect from "components/fields/MultiSelect";
import { useNavigate } from "react-router-dom";
// import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaFileSignature } from "react-icons/fa";
import { HiShare } from "react-icons/hi";
import { RiGitPullRequestFill } from "react-icons/ri";
import DocumentIcon from "components/Icons/document_icon";
import { FindCompanyName } from "utils/helper";
import { docGroupURL, documentURL } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import Upload from "./Upload";
import { CompanyList } from "utils/API/CompanyList.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import { BsThreeDotsVertical } from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// import Overlay from "react-bootstrap/Overlay";
import DocumentActions from "./documentsAction";
import { BsArrowLeft } from "react-icons/bs";
import { Success, Error } from "components/notifications/responseHandle";
import ShareDocument from "./shareDocument";
import DigitalSign from "./DigitalSign";
import RequestDocument from "./requestDocument";

const DirectorDocuments = ({ componentLocations }) => {
  const { instance, accounts } = useMsal();
  const CompanyObject = CompanyList();
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [categoryList, setCategoryList] = useState(null);
  const [subCategoryList, setSubCategoryList] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState([]);
  const [inputText, setInputText] = useState("");
  const [filterdResponse, setFilterdResponse] = useState(null);
  const [selected, setSelected] = useState(null);
  const [apiTrigger, setApiTrigger] = useState(false);
  const [selectedActionData, setSelectedData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [Role, setRole] = useState(null);
  const [uploadRole, setUploadRole] = useState(null);
  const [eSignRole, setEsignRole] = useState(null);
  const [shareRole, setSharerole] = useState(null);
  const [digitalSignRole, setDigitalSignRole] = useState(null);
  const [clickData, setclickData] = useState(null);
  const [docResponse, setDocResponse] = useState(null);
  const [memberList, setMemberList] = useState("");

  //rbac
  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setUploadRole(
        CheckPermission("Actions", "CompanyDocuments", "Upload", Role)
      );
      setEsignRole(
        CheckPermission("Actions", "CompanyDocuments", "ESign", Role)
      );
      setSharerole(
        CheckPermission("Actions", "CompanyDocuments", "Share", Role)
      );
      setDigitalSignRole(
        CheckPermission("Actions", "CompanyDocuments", "DigitalSignature", Role)
      );
    }
  }, [Role]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    if (e.target.name === "Category") {
      setSelectedCategory(value);
    } else if (e.target.name === "SubCategory") {
      setSelectedSubCategory(value);
    } else if (e.target.name === "search") {
      setInputText(e.target.value);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      let temporaryList = [];
      const list = selectedCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedCategoryId(temporaryList);
    }
    if (selectedSubCategory) {
      let temporaryList = [];
      const list = selectedSubCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedSubCategoryId(temporaryList);
    }
  }, [selectedCategory, selectedSubCategory]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${docGroupURL}bff/documentsGroups/summary?memberUID=${companyId}&template=Director`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setError(message);
          } else {
            let newData = [];
            let trade =
              data &&
              data.map((doc) => {
                if (componentLocations && componentLocations.memberLoginId) {
                  doc &&
                    doc.memberId === componentLocations.memberLoginId &&
                    doc.documents &&
                    doc.documents.length > 0 &&
                    doc.documents.map((dd) => newData.push(dd));
                } else {
                  doc &&
                    doc.documents &&
                    doc.documents.length > 0 &&
                    doc.documents.map((dd) => newData.push(dd));
                }
              });
            setResponse([...newData]);
            setFilterdResponse([...newData]);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [apiTrigger]);

  const handleDocumentPreview = (e, id) => {
    e.preventDefault();
    if (id === undefined || id === null || id === "") {
      Error("Document Not available");
    } else {
      handleNavigate(`/documents/preview/${id}`);
    }
  };

  useEffect(() => {
    if (response) {
      const uniqueCategory = [
        ...new Set(response.map((item) => item.docCategory)),
      ];
      let filterCategory =
        uniqueCategory &&
        uniqueCategory.map((item) => {
          return { label: item, value: item };
        });
      setCategoryList(filterCategory);
    }
  }, [response]);

  useEffect(() => {
    if (filterdResponse) {
      const uniqueSubCategory = [
        ...new Set(filterdResponse.map((item) => item.docSubCategory)),
      ];

      let filterSubCategory =
        uniqueSubCategory &&
        uniqueSubCategory.map((item) => {
          return { label: item, value: item };
        });
      setSubCategoryList(filterSubCategory);
    }
  }, [filterdResponse]);

  const filterByName = (searchData, name) => {
    return name.toLowerCase().includes(searchData);
  };

  useEffect(() => {
    if (
      (selectedCategoryId && selectedCategoryId.length > 0) ||
      (selectedSubCategoryId && selectedSubCategoryId.length > 0) ||
      inputText !== ""
    ) {
      let filtered =
        response &&
        response.filter(
          (data) =>
            (selectedSubCategoryId.length > 0 && selectedCategoryId.length > 0
              ? selectedSubCategoryId.includes(data.docSubCategory) ||
                selectedCategoryId.includes(data.docCategory)
              : selectedSubCategoryId.length > 0
              ? selectedSubCategoryId.includes(data.docSubCategory)
              : selectedCategoryId.length > 0
              ? selectedCategoryId.includes(data.docCategory)
              : data) &&
            (inputText !== ""
              ? filterByName(inputText, data.documentName)
              : data)
        );
      setFilterdResponse(filtered);
    } else if (
      selectedCategoryId &&
      selectedCategoryId.length === 0 &&
      inputText === ""
    ) {
      setFilterdResponse(response);
    }
  }, [selectedCategoryId, selectedSubCategoryId, inputText]);

  const ref = useRef(null);

  const handleClick = (value) => {
    if (clickData) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "end",
      });
      setSelected(value);
    } else {
      Error("You must select one document");
      return;
    }
  };

  const fetchDocumentDetails = (docId) => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(`${documentURL}documents/${docId}`, null, response).then(
        (data) => {
          let { message } = data;
          if (message) {
            Error(message);
          } else {
            const {
              name,
              owner,
              validated,
              isDeleted,
              id,
              docIssuer,
              ...rest
            } = data;

            // const finalRes = {
            //   name,
            //   owner: FindCompanyName(CompanyList, owner),
            //   validated,
            //   isDeleted,
            //   documentId: id,
            //   docIssuer,
            // };
            setDocResponse(data);
          }
        }
      );
    });
  };

  const handleClickSelect = (value, index) => {
    if (
      value.docReferenceId === "" ||
      value.docReferenceId === undefined ||
      value.docReferenceId === null
    ) {
      Error("Document not found");
    } else {
      Success(`Selected ${value.documentName}`);
      setclickData({
        index: index,
        value: true,
        id: value.docReferenceId,
        documentName: value.documentName,
        docCategory: value.docCategory,
        docSubCategory: value.docSubCategory,
        ownerId: value.ownerId,
        tags: value.tags,
      });
      fetchDocumentDetails(value.docReferenceId);
    }
  };

  useEffect(() => {
    if (CompanyObject && CompanyObject.response) {
      setMemberList(
        CompanyObject &&
          CompanyObject.response.filter(
            (obj) => obj.memberType !== "PERSON" && obj.memberType
          )
      );
    }
  }, [CompanyObject.response]);

  const childProps = {
    companyId,
    tempData: clickData,
    listMember: CompanyObject && CompanyObject.response, // memeber list company list
    docResponse,
    memListWithLoggedUser: memberList,
    folderLocation: "DirectorDocument",
  };

  const Component = () => {
    switch (selected) {
      case "shareDoc":
        return <ShareDocument {...childProps} />;
      case "digitalSign":
        return <DigitalSign {...childProps} />;
      case "requestDocument":
        return <RequestDocument {...childProps} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (filterdResponse) {
      componentLocations &&
        componentLocations.MandatoryCatsubCatMap(filterdResponse);
    }
  }, [filterdResponse]);

  return (
    <>
      <Container>
        {isLoading || CompanyObject.isLoading ? (
          <Content>
            <span>Loading.....</span>
            <Loader />
          </Content>
        ) : (
          <>
            {response &&
              filterdResponse &&
              !isError &&
              CompanyObject.response &&
              !CompanyObject.isError && (
                <>
                  {isUploading ? (
                    <span>
                      <Upload
                        Data={selectedActionData}
                        location="Director"
                        apiTrigger={apiTrigger}
                        setApiTrigger={setApiTrigger}
                        setIsUploading={setIsUploading}
                      />
                    </span>
                  ) : (
                    <>
                      {!componentLocations && (
                        <div
                          style={{
                            color: "rgb(66, 165, 245)",
                            cursor: "pointer",
                            marginBottom: "1%",
                          }}
                          onClick={() => handleNavigate(`/Documents`)}>
                          <span style={{ marginRight: "2px" }}>
                            <BsArrowLeft />
                          </span>
                          <span>Back To Documents</span>
                        </div>
                      )}
                      <Content marginBottom="2%">
                        <h5>Director Documents</h5>
                        <div className={cn(classes.MultiSelectDiv)}>
                          <span>
                            <MultiSelect
                              name="Category"
                              selected={selectedCategory}
                              placeholder="Category"
                              options={categoryList}
                              handleChange={handleSearchChange}
                            />
                          </span>
                          <span className="mx-4">
                            <MultiSelect
                              name="SubCategory"
                              selected={selectedSubCategory}
                              placeholder="Sub-Category"
                              options={subCategoryList}
                              handleChange={handleSearchChange}
                            />
                          </span>
                          <input
                            placeholder="Search..."
                            name="search"
                            value={inputText}
                            type="search"
                            onChange={handleSearchChange}
                          />
                        </div>
                      </Content>

                      <Content marginBottom="2%">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <h6>Director Documents List</h6>
                          </div>
                          <div className="d-flex">
                            <div className={classes.buttonDiv}>
                              <button
                                onClick={
                                  shareRole === "CREATE"
                                    ? (e) => {
                                        handleClick("shareDoc");
                                      }
                                    : " "
                                }>
                                <span className="mx-1">
                                  <HiShare />
                                </span>
                                <span>Share Document</span>
                              </button>

                              <button
                                onClick={(e) => {
                                  handleClick("requestDocument");
                                }}>
                                <span className="mx-1">
                                  <RiGitPullRequestFill />
                                </span>
                                <span>Request Document</span>
                              </button>

                              <button
                                onClick={
                                  digitalSignRole === "CREATE"
                                    ? (e) => {
                                        handleClick("digitalSign");
                                      }
                                    : " "
                                }>
                                <span className="mx-1">
                                  <FaFileSignature />
                                </span>
                                <span>Digital Signature</span>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className={cn(classes.TableDiv)}>
                          <Table
                            bordered
                            responsive
                            className={cn(classes.Table)}>
                            <thead>
                              <tr>
                                {AllDocumentsHeader.map((header, index) => (
                                  <td key={index}>{header.name}</td>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {filterdResponse &&
                                filterdResponse.map((data, index) => (
                                  <tr key={index} style={{ cursor: "pointer" }}>
                                    <td>
                                      <input
                                        className={cn(classes.Checkbox)}
                                        type="checkbox"
                                        onClick={() =>
                                          handleClickSelect(data, index)
                                        }
                                        checked={
                                          clickData &&
                                          clickData.id === data.docReferenceId
                                            ? true
                                            : false
                                        }
                                      />
                                    </td>
                                    <td
                                      className={cn(
                                        "d-flex justify-content-left"
                                      )}
                                      onClick={(e) =>
                                        handleDocumentPreview(
                                          e,
                                          data.docReferenceId
                                        )
                                      }>
                                      <span className={cn("d-flex px-2")}>
                                        {data.documentName && <DocumentIcon />}
                                      </span>
                                      <span>
                                        {data.documentName
                                          ? data.documentName
                                          : "NA"}
                                      </span>
                                    </td>
                                    <td>{data.docCategory}</td>
                                    <td>{data.docSubCategory}</td>
                                    <td>
                                      {CompanyObject.response &&
                                        FindCompanyName(
                                          CompanyObject.response,
                                          data.ownerId
                                        )}
                                    </td>
                                    <td>
                                      {data.updatedDate
                                        ? new Date(
                                            data.updatedDate
                                          ).toLocaleDateString()
                                        : "-"}
                                    </td>
                                    <td>
                                      {data.createdDate
                                        ? new Date(
                                            data.createdDate
                                          ).toLocaleDateString()
                                        : "-"}
                                    </td>

                                    <td>
                                      <OverlayTrigger
                                        trigger={["click", "focus"]}
                                        key="center"
                                        rootClose
                                        placement="left"
                                        overlay={
                                          <Popover>
                                            <Popover.Body>
                                              <DocumentActions
                                                Data={selectedActionData}
                                                apiTrigger={apiTrigger}
                                                setApiTrigger={setApiTrigger}
                                                setIsUploading={setIsUploading}
                                              />
                                            </Popover.Body>
                                          </Popover>
                                        }>
                                        <span
                                          style={{ color: "#42a5f5" }}
                                          onClick={() => {
                                            setSelectedData(data);
                                          }}>
                                          <BsThreeDotsVertical size={26} />
                                        </span>
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                ))}
                              {filterdResponse &&
                                filterdResponse.length === 0 && (
                                  <span>No Records Found</span>
                                )}
                            </tbody>
                          </Table>
                        </div>
                      </Content>
                    </>
                  )}
                </>
              )}

            {(isError || CompanyObject.isError) && (
              <Content>
                <span>Something went wrong</span>
                <span>{CompanyObject.isError}</span>
                <Loader />
              </Content>
            )}
          </>
        )}

        <div ref={ref}>
          {selected && <Content marginBottom="0%">{Component()}</Content>}
        </div>
      </Container>
    </>
  );
};

export default DirectorDocuments;
