import React, { useCallback, useEffect, useState } from "react";
import { ButtonStyled, CardNetworkWapper } from "components/Container.styled";
import Style from "./Styled";
import Table from "react-bootstrap/Table";
import { RiWechatLine } from "react-icons/ri";
import cn from "classnames";
import classes from "./usersub.module.css";
import { status } from "utils/DropDown/Payment";
//import Input from "components/fields/Input";
//import classes from "./profile.module.css";
import { useNavigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import Content from "components/UI/Layout/Content";

import SubscriptionPayment from "./SubscriptionPayment";

import {
  invoiceURL,
  profileURL,
  networkShareApi,
} from "services/BaseURLInstance";
import {
  getPaymentsByLoanId,
  getPaymentByUser,
  paymentDetails,
  makePayment,
  updatePayment,
  releasePayment,
  updateServiceDeal,
  setIsPercentageComplete,
  isPercentageComplete,
  getPaymentCollection,
} from "api/Payments";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";

import styles from "./usersub.module.css";
const UserSubscribe = () => {
  const [status, setStatus] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [postData, setPostData] = useState([]);

  const handleNavigate = useCallback((url) => navigate(url), []);
  const [isMakePaymentFormOpen, setIsMakePaymentFormOpen] = useState(false);

  const [paymentStatus, setPaymentStatus] = useState("");
  const [error, setError] = useState("");
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const { instance, accounts } = useMsal();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [response, setResponse] = useState([]);
  const [role, setRole] = useState();
  const [open, setOpen] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [load, setLoad] = useState(false);

  const [zeroth, setZeroth] = useState(false);

  const [isTermChecked, setIsTermChecked] = useState(false);
  console.log("isTermChecked", isTermChecked);

  const [paymentData, setPaymentData] = useState([]);

  const header = [
    {
      name: "Subscription Start Date",
      flex: "flex-1",
    },
    {
      name: "Subscription End Date",
      flex: "flex-1",
    },
    {
      name: "Transaction Id",
      flex: "flex-1",
    },
    {
      name: "Subscription Category",
      flex: "flex-1",
    },
    {
      name: "Subscription Status",
      flex: "flex-1",
    },
    {
      name: "Transaction Date",
      flex: "flex-1",
    },
    {
      name: "Amount",
      flex: "flex-1",
    },

    {
      name: "Payment Status",
      flex: "flex-1",
    },
  ];

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseForcancel = () => {
    setCancel(false);
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_NETWORK_URL}subscription?memberUID=${companyId}`,
          // `${invoiceURL}subscription?memberUID=${companyId}`,
          null,
          response
        ).then((data1) => {
          data1.sort(
            (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
          );
          setIsLoading(false);
          let { message, status } = data1;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setData(data1);
            setFilterData(data1);

            // console.log(data[0].membershipCategory, "getall");
          }
        });
      });
    }

    if (!isCancelled) {
      //setIsLoading(true);

      getIdTokenAsync(instance, accounts).then((resp) => {
        UseRequest(
          `${process.env.REACT_APP_NETWORK_URL}subscription/${companyId}`,
          null,
          resp
        ).then((data1) => {
          // setIsLoading(false);
          setResponse(data1);

          let { message, status } = data1;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          }
        });
      });
    }

    console.log(networkShareApi, companyId, "companyId", response);

    return () => {
      isCancelled = true;
    };
  }, []);

  const createSubscription = async () => {
    let start = new Date();
    let end = new Date();
    end.setDate(end.getDate() + 28);
    const requestBody = {
      memberUID: companyId,
      membershipCategory: "PREMIUM",
      subscriptionUID: zeroth.subscriptionUID,
      subscriptionStartDate:
        //"2023-07-07T12:38:50.999Z",
        start,
      subscriptionEndDate:
        //"2023-08-07T12:38:50.999Z",
        end,
      applicableSubscriptionAmount: "944",
      promoCode: "",
      paymentStatus: "PAYMENT_ABORTED",
      subscriptionStatus: "NOT_ACTIVATED",
      paymentRefId: "25842eeb-e06d-4a1a-82b9-12be82e3008c",
      createdBy: companyId,
      updateBy: companyId,
      createdDate: "2023-06-07T12:38:50.999Z",
      updatedDate: "2023-06-07T12:38:50.999Z",
    };

    await fetch(`${process.env.REACT_APP_NETWORK_URL}/subscription/`, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    })
      .then((data3) => data3.json())
      .then((result) => {
        setIsLoading(false);

        console.log("data1", result);
        //uncomment below code for testing payment update
        // upDateSubscription(result.subscriptionUID);

        //upDateSubscription();
        // setResponse(data);

        let { message, status } = result;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setIsError(message);
        }
      });
  };

  const cancelSubscription = async () => {
    setIsLoading(true);

    let start = new Date();
    let end = new Date();
    const requestBody = {
      subscriptionUID: zeroth.subscriptionUID,
      memberUID: companyId,
      membershipCategory: "FREE",
      subscriptionStatus: "NOT_ACTIVATED",
      subscriptionStartDate: start,
      subscriptionEndDate: end,
      applicableSubscriptionAmount: "944",
      promoCode: "",
      paymentStatus: "PAYMENT_COMPLETED",
      paymentRefId: zeroth.subscriptionUID,
      createdBy: companyId,
      updateBy: companyId,
      createdDate: new Date(),
      updatedDate: start,
    };

    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}subscription/${companyId}?subscriptionUID=${zeroth.subscriptionUID}`,
      {
        method: "PUT",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
      }
    )
      .then(() => {
        setIsLoading(false);

        setCancel(false);
        window.location.href = `http://${window.location.host}/profile/premium-subscription`;
      })

      .catch((error) => {
        console.log("Error:", error);
      });
  };

  console.log(status, "status", response);
  const makeLoanPayment = () => {
    const requestData = {
      paymentId: "",
      collectionRates: [
        {
          amount: 944,
          currency: "INR",
          rate: 0,
          state: "MAKE_PAYMENT",
        },
      ],
      tradxlinkPaymentOrderUID: "",
      senderMemberID: companyId,
      receiverMemberID: "",
      senderMemberAccount: companyId,
      receiverMemberAccount: "",
      currencySender: "USD",
      currencyReceiver: "USD",
      paymentAmount: "10",
      referenceInstrumentType: "OTHERS",
      paymentCategory: "DISBURSEMENT",
      paymentCollectionMethod: "BANK",
      paymentDueDate: "2022-11-11T09:09:32.407Z",
      paymentDescriptionSender: "test",
      paymentDescriptionReceiver: "",
      paymentReferenceData: "ce97ca08-dcf8-49b3-a146-ff15686f1b64",
      paymentStatus: "INITIATED",

      paymentAttemptCount: 0,
      paymentTransdactionDate: new Date(),
      paymentServiceProvider: "SAB_PAISA",

      isDeleted: false,
      tag: "",
      createdBy: companyId,
      updateBy: companyId,
      createdDate: new Date().toISOString(),
      updatedDate: new Date().toISOString(),
      paymentCompletedRedirectUrl:
        process.env.REACT_APP_PAYMENT_URL + "sabpaisa/handleurl",

      paymentErrorRedirectUrl: `http://${window.location.host}/profile/premium-subscription?success=true`,
    };

    function urlencodeFormData(fd) {
      var s = "";
      function encode(s) {
        return encodeURIComponent(s).replace(/%20/g, "+");
      }
      for (var pair of fd.entries()) {
        if (typeof pair[1] == "string") {
          s += (s ? "&" : "") + encode(pair[0]) + "=" + encode(pair[1]);
        }
      }
      return s;
    }
    function openURLWithPOST(url, postData) {
      const form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", url);

      for (const key in postData) {
        if (postData.hasOwnProperty(key)) {
          const input = document.createElement("input");
          input.setAttribute("type", "hidden");
          input.setAttribute("name", key);
          input.setAttribute("value", postData[key]);
          form.appendChild(input);
        }
      }
      console.log("data " + form);

      document.body.appendChild(form);
      form.submit();
    }

    makePayment(idToken, requestData)
      .then((response) => {
        if (response.status === "success") {
          console.log(response, "success");
          setPaymentData(response);

          const apiEndpoint =
            // "https://securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1";
            // "https://stage-securepay.sabpaisa.in/SabPaisa/sabPaisaInit?v=1";
            response.paymentUrl;

          var enc = new URLSearchParams();
          enc.append("encData", response.encodedData);

          enc.append("clientCode", response.clientCode);
          //  enc.append("submit", "Submit");
          createSubscription();
          // upDateSubscription();
          openURLWithPOST(apiEndpoint, Object.fromEntries(enc));
        }
        setIsLoading(false);
        if (response.paymentUrl) {
          console.log(response.paymentUrl, "success");

          // window.location.href = response.paymentUrl;
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  // function handleSuccessfulPayment() {
  //   // Your code to handle a successful payment
  //   const subIDs = zeroth.subscriptionUID;

  //   upDateSubscription(subIDs);
  //   console.log("Payment was successful! Calling function...", subIDs);
  // }

  // Check the URL to determine if the payment was successful
  console.log(zeroth, "zero");
  const upDateSubscription = async (subId) => {
    let start = new Date();
    let end = new Date();
    end.setDate(end.getDate() + 30);
    const requestBody = {
      // subscriptionId: data.subscriptionId,
      // memberUID: companyId,
      // membershipCategory: "PREMIUM",
      // subscriptionStatus: "ACTIVATED",
      // subscriptionStartDate: new Date().toISOString(),
      // subscriptionEndDate: "7/7/2023",
      // applicableSubscriptionAmount: "10",
      // promoCode: "",
      // paymentStatus: "PAYMENT_COMPLETED",
      // paymentRefId: "",
      // createdBy: companyId,
      // updateBy: companyId,
      // createdDate: new Date().toISOString(),
      // updatedDate: "2023-06-01T14:12:33.546Z",
      subscriptionUID: subId,
      memberUID: companyId,
      membershipCategory: "PREMIUM",
      subscriptionStatus: "NOT_ACTIVATED",
      subscriptionStartDate: start,
      subscriptionEndDate: end,
      applicableSubscriptionAmount: "944",
      promoCode: "",
      paymentStatus: "PAYMENT_INITIATED",
      paymentRefId: zeroth.subscriptionUID,
      createdBy: companyId,
      updateBy: companyId,
      createdDate: new Date().toISOString(),
      updatedDate: start,
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}subscription/${companyId}?subscriptionUID=${subId}`,

      {
        method: "PUT",
        body: JSON.stringify(requestBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
      }
    )
      .then((response) => {
        // Process the response
        if (response.ok) {
          console.log("Payment request successful....saurabh", response);
        }
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const currentURL = window.location.href;

  console.log(currentURL, "currentURL");
  // Example: Check if the URL contains a success parameter
  if (currentURL.includes("?success=true")) {
    if (zeroth && zeroth.subscriptionUID) {
      const subIDs = zeroth.subscriptionUID;

      upDateSubscription(subIDs);
    }
    // handleSuccessfulPayment();
  }
  useEffect(() => {
    setZeroth(data[0]);
  }, [data]);
  // useEffect(() => {
  //   upDateSubscription(postData.subscriptionUID);
  // }, [postData]);

  const paymentApiCall = () => {
    setOpen(true);
    // openURLWithPOST(apiEndpoint, Object.fromEntries(enc));
  };
  const unsubscribePremium = () => {
    setCancel(true);
    // openURLWithPOST(apiEndpoint, Object.fromEntries(enc));
  };

  const CheckedFnc = () => {
    if (isTermChecked) {
      setLoad(true);
      makeLoanPayment();
    } else {
      Error(
        'Please check the box of the "Terms & Conditions" to proceed further'
      );
    }
  };

  function isSubscriptionEndingSoon(subscriptionEndDate) {
    const currentDate = new Date();
    const threeDaysInMilliseconds = 25 * 24 * 60 * 60 * 1000; // 3 days in milliseconds

    // Parse the subscription end date from the API response, assuming it's in a valid format
    const subscriptionEnd = new Date(subscriptionEndDate);

    // Calculate the time difference in milliseconds
    const timeDifference = subscriptionEnd - currentDate;

    // Check if the subscription is ending in 3 days or less
    return timeDifference <= threeDaysInMilliseconds;
  }

  // Example usage when you have the subscription end date from an API call
  const subscriptionEndDateFromAPI = "2023-11-09T23:59:59Z";
  //  const isEndingSoon = isSubscriptionEndingSoon(subscriptionEndDateFromAPI);
  //console.log("hey", isEndingSoon);
  console.log(data[0], "here t is the reposne", zeroth);

  console.log(response, "here is the reposne");

  return (
    <>
      {isLoading ? (
        <Content>
          <span>Loading ...</span>
          <Loader />
        </Content>
      ) : (
        <>
          <Content>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ textAlign: "left", width: "60%" }}>
                <div className={classes.onboardingStatus}>
                  <span>Subscription Status : </span>{" "}
                  <span>
                    {" "}
                    {/* {zeroth && zeroth.subscriptionStatus}{" "} */}
                    {zeroth &&
                    zeroth.subscriptionType === "PREMIUM" &&
                    zeroth.subscriptionStatus === "ACTIVATED"
                      ? "PREMIUM"
                      : "FREE"}
                    {/* {data && data.membershipCategory === "PREMIUM"
                  ? data[0].subscriptionStatus
                  : "NOT SUBSCRIBED"}+
                  {"  "} */}
                  </span>{" "}
                  <div></div>
                  {/* <span> {zeroth && zeroth.subscriptionType}</span> */}
                  {/* <span>as on {new Date().toLocaleDateString()}</span> */}
                  {zeroth &&
                  zeroth.subscriptionType === "PREMIUM" &&
                  zeroth.subscriptionStatus === "ACTIVATED" ? (
                    <span>
                      *Valid from{" "}
                      {new Date(
                        zeroth.subscriptionStartDate
                      ).toLocaleDateString()}{" "}
                      to{" "}
                      {new Date(
                        zeroth.subscriptionEndDate
                      ).toLocaleDateString()}{" "}
                    </span>
                  ) : (
                    <span>as on {new Date().toLocaleDateString()}</span>
                  )}
                </div>
                <div style={{ marginTop: "4px" }}></div>
              </div>
              {data.subscriptionStatus}
              <div></div>
              <div style={{ marginLeft: "15%" }}>
                {(zeroth &&
                  isSubscriptionEndingSoon(zeroth.subscriptionEndDate)) ||
                (zeroth && zeroth.paymentStatus === "PAYMENT_ABORTED") ||
                (response && response.membershipCategory === "FREE") ? (
                  <ButtonStyled
                    className={
                      // zeroth &&
                      // zeroth.subscriptionType === "PREMIUM" &&
                      // zeroth.subscriptionStatus === "ACTIVATED"
                      //   ? styles.deleteBtn
                      //   :
                      styles.addBtn
                    }
                    style={
                      {
                        // justifyContent: "right",
                      }
                    }
                    onClick={(e) => {
                      // zeroth &&
                      // zeroth.subscriptionType === "PREMIUM" &&
                      // zeroth.subscriptionStatus === "ACTIVATED"
                      //   ? unsubscribePremium()
                      //   : // createSubscription();
                      // createSubscription();
                      //unsubscribePremium();

                      paymentApiCall();
                    }}
                  >
                    Subscribe now
                    {/* {zeroth &&
                    zeroth.subscriptionType === "PREMIUM" &&
                    zeroth.subscriptionStatus === "ACTIVATED"
                      ? "Cancel Subscription "
                      : "Subscribe now"} */}
                  </ButtonStyled>
                ) : (
                  " "
                )}
                {/* <ButtonStyled
                  className={
                    zeroth &&
                    zeroth.subscriptionType === "PREMIUM" &&
                    zeroth.subscriptionStatus === "ACTIVATED"
                      ? styles.deleteBtn
                      : styles.addBtn
                  }
                  style={
                    {
                      // justifyContent: "right",
                    }
                  }
                  onClick={(e) => {
                    zeroth &&
                    zeroth.subscriptionType === "PREMIUM" &&
                    zeroth.subscriptionStatus === "ACTIVATED"
                      ? unsubscribePremium()
                      : // createSubscription();
                        // createSubscription();
                        // unsubscribePremium();

                        paymentApiCall();
                  }}
                >
                  {zeroth &&
                  zeroth.subscriptionType === "PREMIUM" &&
                  zeroth.subscriptionStatus === "ACTIVATED"
                    ? "Cancel Subscription "
                    : "Subscribe now"}
                </ButtonStyled> */}
                {/* {isSubscriptionEndingSoon(zeroth.)} */}
                <Dialog
                  fullWidth={true}
                  maxWidth={"lg"}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    sx: { backgroundColor: "#343434", color: "white" },
                  }}
                >
                  <div style={{ padding: "2%" }}>
                    <div style={{ backgroundColor: "black" }}>
                      <DialogContent
                        sx={{}}
                        style={{ padding: "0px 24px 20px" }}
                      >
                        <div>
                          <SubscriptionPayment
                            paymentData={paymentData}
                            isTermChecked={isTermChecked}
                            setIsTermChecked={setIsTermChecked}
                          />
                        </div>
                      </DialogContent>
                      <div
                        style={{
                          width: "100%",
                          paddingRight: "3.5%",
                          paddingBottom: "2%",
                          display: "flex",
                          justifyContent: "right",
                        }}
                      >
                        <button
                          style={{
                            width: "20%",
                            margin: "1%",
                            padding: "1%",
                            color: "white",
                            border: "none",
                            backgroundColor: "#42a5f5",
                            borderRadius: "5px",
                            fontSize: "14px",
                          }}
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                        <>
                          <button
                            disabled={load}
                            style={
                              // !isTermChecked
                              //   ? {
                              //       width: "20%",
                              //       margin: "1%",
                              //       padding: "1%",
                              //       color: "white",
                              //       border: "none",
                              //       backgroundColor: "#42a5f5",
                              //       borderRadius: "5px",
                              //       fontSize: "14px",
                              //       pointerEvents: "none",
                              //       cursor: "not-allowed",
                              //     }
                              //   :
                              {
                                width: "20%",
                                margin: "1%",
                                padding: "1%",
                                color: "white",
                                border: "none",
                                backgroundColor: load ? "grey" : "#42a5f5",
                                borderRadius: "5px",
                                fontSize: "14px",
                              }
                            }
                            onClick={() => CheckedFnc()}
                          >
                            {!load ? "Submit" : "...Loading"}
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </Dialog>
                <Dialog
                  fullWidth={false}
                  maxWidth={"lg"}
                  open={cancel}
                  onClose={handleCloseForcancel}
                  PaperProps={{
                    sx: { backgroundColor: "#343434", color: "white" },
                  }}
                >
                  <div style={{ padding: "4%" }}>
                    <div style={{ backgroundColor: "black" }}>
                      <DialogContent
                        sx={{}}
                        style={{ padding: "20px 24px 20px" }}
                      >
                        <div>
                          <p>Are you Sure you want to unsubscribe ?</p>
                          <span></span>
                          <p></p>
                        </div>
                      </DialogContent>
                      <div
                        style={{
                          width: "100%",
                          paddingRight: "3.5%",
                          paddingBottom: "2%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <button
                          style={{
                            width: "20%",
                            margin: "1%",
                            padding: "1%",
                            color: "white",
                            border: "none",
                            backgroundColor: "#42a5f5",
                            borderRadius: "5px",
                            fontSize: "14px",
                          }}
                          onClick={handleCloseForcancel}
                        >
                          No
                        </button>
                        <>
                          <button
                            style={
                              // !isTermChecked
                              //   ? {
                              //       width: "20%",
                              //       margin: "1%",
                              //       padding: "1%",
                              //       color: "white",
                              //       border: "none",
                              //       backgroundColor: "#42a5f5",
                              //       borderRadius: "5px",
                              //       fontSize: "14px",
                              //       pointerEvents: "none",
                              //       cursor: "not-allowed",
                              //     }
                              //   :
                              {
                                width: "20%",
                                margin: "1%",
                                padding: "1%",
                                color: "white",
                                border: "none",
                                backgroundColor: "#42a5f5",
                                borderRadius: "5px",
                                fontSize: "14px",
                              }
                            }
                            onClick={() => cancelSubscription()}
                          >
                            yes
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </Dialog>
              </div>
            </div>

            <div style={{ display: "flex", marginTop: "2%" }}>
              <div>
                {zeroth &&
                zeroth.subscriptionType === "PREMIUM" &&
                zeroth.subscriptionStatus === "ACTIVATED"
                  ? "You are subscribed as premium member with access all the premium features on the platform."
                  : "Currently you are in default free subscription which limits certain features on the platform. "}
                <a href="https://tradxlink.com/pricing/">Know more details</a>
              </div>
              <div style={{ marginLeft: "33%" }}>
                {zeroth &&
                zeroth.subscriptionType === "PREMIUM" &&
                zeroth.subscriptionStatus === "ACTIVATED"
                  ? ""
                  : "₹ 800 Per Month"}
              </div>
            </div>
            <div></div>
          </Content>

          <div
            style={{
              marginTop: "2%",
              // width: "100%",

              background: "black",
              padding: "2% 2% 1.1% 2%",
              // paddingLeft: "10px",
              // borderRadius: "8px",
            }}
          >
            <Table bordered responsive className={cn(classes.Table)}>
              <thead>
                {" "}
                <tr>
                  {header.map((header, index) => (
                    <td key={index}>{header.name}</td>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((data, index) => (
                  <tr key={index}>
                    <td key={index}>
                      {" "}
                      {new Date(
                        data.subscriptionStartDate
                      ).toLocaleDateString()}
                    </td>
                    <td key={index}>
                      {data && data.subscriptionEndDate === "null"
                        ? "NOT APPLICABLE"
                        : new Date(
                            data.subscriptionEndDate
                          ).toLocaleDateString()}
                    </td>
                    <td key={index}>
                      {data && data.subscriptionType === "FREE"
                        ? "NOT APPLICABLE"
                        : data.PaymentTransaction}
                    </td>
                    <td key={index}>{data && data.subscriptionType}</td>
                    <td key={index}>{data && data.subscriptionStatus}</td>

                    <td key={index}>
                      {new Date(data.createdDate).toLocaleDateString()}
                    </td>
                    <td key={index}>
                      {data && data.applicableSubscriptionAmount} INR
                    </td>
                    <td key={index}>{data && data.paymentStatus}</td>

                    {/* <td>{subcribeData.startDate}</td>
                       <td>{subcribeData.endDate}</td>
                       <td>{subcribeData.PaymentTransaction}</td>
                       <td>{subcribeData.amount}</td>
                       <td>{subcribeData.status}</td> */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      )}
    </>
  );
};

export default UserSubscribe;
