import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { RiWechatLine, RiArrowDownSLine } from "react-icons/ri";
import Styles from "./styles";
import RequsitionField from "./requsitionField";
import Quote from "./quoteInputField";
import Documents from "../../../../Document/company/index";
// import History from "../uploadInvoice/Deals/history";
import History from "components/History/history";
import Alert from "../uploadInvoice/Deals/alerts";
import DocumentNavbar from "../../../../Document/index";
// import { export1 as alias1 } from "module-name";
import SyndicateLending from "pages/syndicatelending/index";
import { truncateStringID } from "utils/helper";

export default () => {
  const [headerSelect, setHeaderSelect] = useState("requsition");
  const [loanID, setLoanID] = useState();
  const [synSelect, setSynSelect] = useState("");
  const data = JSON.parse(localStorage.getItem("financeDeal"));
  const [headerSelectSyndicate, setHeaderSelectSyndicate] = useState("");
  const [isSyndicate, setIsSyndicate] = useState(false);
  const [sydicateMenu, setSydicateMenu] = useState("");
  const navigate = useNavigate();
  const handleBackToLoan = () => {
    navigate("/invoicefinancing/Loans");
  }; 
  const handleDealDetailsHeader = (e, value) => {
    setHeaderSelect(value);
  };

  const setSyndicate = (id) => {
    setLoanID(id);
   // setHeaderSelect("syndicatelending");
    switch (headerSelect) {
      case "syndicatelending":
        return <SyndicateLending data={loanID} />;
    }
  };

  const syndicate = {
    setSyndicate,
    data,
    setSydicateMenu
  };
  const Component = () => {
    switch (headerSelect) {
      case "requsition":
        return <RequsitionField data={data} />;
      case "quote":
        return <Quote data={syndicate} />;
      // return <Quote data={data} />
      case "documents":
        return (
          <>
            <DocumentNavbar />
            {/* <Documents data={data}/>  */}
          </>
        );
      case "history":
        return <History />;
      case "alerts":
        return <Alert />;
      case "syndicatelending": 
        return <SyndicateLending data={loanID} />;
      default:
        return <RequsitionField data={data} />;
    }
  };
  return (
    <Styles>
      <div className="loandetails">
        <div className="summary">
          <div className="summaryheading" style={{ display: "flex" }}>
            <div className="loanSummarytext">Summary</div>
            <div className="backtoloan">
              <div>
                <RiArrowDownSLine />
              </div>
              <div>Invoice Typeid: {data.createdBy.substr(6)}</div>
            </div>
          </div>
          <div className="table">
            <div className="header">
              <div
                style={{
                  borderLeft: "none",
                  borderTop: "none",
                  paddingLeft: "10px",
                }}
              >
                Financer
              </div>
              <div>Finance Id</div>
              <div>Supplier</div>
              <div>Requested Rate</div>
              <div>Status</div>
              <div>Last Activity Date</div>
              <div>Action</div>
            </div>
            <div className="row">
              <div style={{ borderLeft: "none", paddingLeft: "10px" }}>
                <div>
                  <BsBell />
                </div>
                <div style={{ paddingLeft: "10px" }}>{data.memberName}</div>
              </div>
              <div>{data.financeDealId
                ? truncateStringID(data.financeDealId, 7, "FD")
                : "NA"}</div>
              <div>{data.financeProductCategory}</div>
              <div>{data.interestRate}</div>
              <div>{data.financeDealStatus}</div>
              <div>{data.lastUpdatedDate.toString().substr(0, 10)}</div>
              <div style={{ borderRight: "none" }}>
                <div style={{ margin: "20px" }}>
                  <FiMail />
                </div>
                <div style={{ margin: "20px" }}>
                  <RiWechatLine />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="loanDetailsFields">
          <div>
            <div className="loanDetailsHeading">Deal Details</div>
            <div className="dealDetailsHeader">
              <div
                id="requsition"
                style={
                  headerSelect === "requsition"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                      }
                    : {}
                }
                onClick={(e) => handleDealDetailsHeader(e, "requsition")}
              >
                Requsition
              </div>
              <div
                id="quote"
                style={
                  headerSelect === "quote"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                      }
                    : {}
                }
                onClick={(e) => handleDealDetailsHeader(e, "quote")}
              >
                Quote 
              </div>
                 { sydicateMenu === "syndicatelending"
                      ? 
                <div
                  id="syndicatelending"
                  style={
                    headerSelect === "syndicatelending"
                      ? {
                          color: "#42A5F5",
                          textDecoration: "underline",
                          textUnderlineOffset: "10px",
                        }
                      : {}
                  }
                  onClick={(e) =>
                    handleDealDetailsHeader(e, "syndicatelending")
                  }
                >
               SyndicateLending
                </div>:""}
             
              <div
                id="documents"
                style={
                  headerSelect === "documents"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                      }
                    : {}
                }
                onClick={(e) => handleDealDetailsHeader(e, "documents")}
              >
                Documents
              </div>
              <div
                id="history"
                style={
                  headerSelect === "history"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                      }
                    : {}
                }
                onClick={(e) => handleDealDetailsHeader(e, "history")}
              >
                History
              </div>
              <div
                id="alerts"
                style={
                  headerSelect === "alerts"
                    ? {
                        color: "#42A5F5",
                        textDecoration: "underline",
                        textUnderlineOffset: "10px",
                      }
                    : {}
                }
                onClick={(e) => handleDealDetailsHeader(e, "alerts")}
              >
                Alerts
              </div>
            </div>
            <hr></hr>
            <div style={{ paddingBottom: "20px" }}>
              {headerSelect && Component()}
            </div>
          </div>
        </div>
      </div>
    </Styles>
  );
};
