import React, { useState, useRef } from "react";
import {
  GoogleMap,
  DirectionsRenderer,
  Marker,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useEffect } from "react";
import { Place } from "@material-ui/icons";


const DirectionsMap = React.memo(({ Data }) => {
  
//   const response= { 
//     "type": "FeatureCollection",
//     "features": [
//         {
//             "type": "Feature",
//             "properties": {
//                 "distance": 19674361,
//                 "mode": "sea",
//                 "departure": 1697631975501,
//                 "arrival": 1699339591501,
//                 "duration": 1707616000,
//                 "speed": 41.478,
//                 "areas": {
//                     "type": "FeatureCollection",
//                     "features": [
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11158,
//                                 "name": "Gela Canal",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     11.9276,
//                                     37.2765
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11146,
//                                 "name": "North of Sabang",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     94.68081647283823,
//                                     6.324861732860867
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11100,
//                                 "name": "Gibraltar",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     -5.7470196407155925,
//                                     35.95714400373309
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11117,
//                                 "name": "Suez Canal",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     32.35402374270163,
//                                     30.4438838927622
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11133,
//                                 "name": "Dover Strait",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     1.5143097626982471,
//                                     50.95849717205491
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11150,
//                                 "name": "Taiwan Strait",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     119.18853847770525,
//                                     24.334355579669797
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11135,
//                                 "name": "Malacca Strait",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     99.90993939997549,
//                                     3.7768863793412635
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 21120,
//                                 "name": "North Sea SECA",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     4.1835169836905335,
//                                     55.863787276691106
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11137,
//                                 "name": "Singapore Strait",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     103.90174796429032,
//                                     1.2059581075620434
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 21089,
//                                 "name": "High Risk Area",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     49.2132660404583,
//                                     5.8841119134226
//                                 ]
//                             }
//                         },
//                         {
//                             "type": "Feature",
//                             "properties": {
//                                 "id": 11106,
//                                 "name": "South Sri Lanka",
//                                 "alternatives": []
//                             },
//                             "geometry": {
//                                 "type": "Point",
//                                 "coordinates": [
//                                     80.925,
//                                     5.5787
//                                 ]
//                             }
//                         }
//                     ],
//                     "properties": null
//                 },
//                 "details": [],
//                 "secaIntersection": 760450,
//                 "hraIntersection": 2194590,
//                 "speedInKts": 22.396,
//                 "intersectsIceArea": false,
//                 "vessel": {
//                     "imo": 8677225,
//                     "name": "KSL PRIDE",
//                     "length": 83.0,
//                     "width": 15.0,
//                     "maxDraft": 4.2,
//                     "draft": 4.2
//                 }
//             },
//             "geometry": {
//                 "type": "LineString",
//                 "coordinates": [
//                     [
//                         121.6147,
//                         31.36636
//                     ],
//                     [
//                         121.64431980257798,
//                         31.353104041681526
//                     ],
//                     [
//                         121.65767309287818,
//                         31.34739678632157
//                     ],
//                     [
//                         121.66463313842658,
//                         31.34481958114731
//                     ],
//                     [
//                         121.6708596000971,
//                         31.342568202966078
//                     ],
//                     [
//                         121.67635247788971,
//                         31.340642651777873
//                     ],
//                     [
//                         121.69683777217685,
//                         31.332768051614647
//                     ],
//                     [
//                         121.73231548295848,
//                         31.318944402476397
//                     ],
//                     [
//                         121.76065795213563,
//                         31.308187814871427
//                     ],
//                     [
//                         121.78186517970826,
//                         31.300498288799737
//                     ],
//                     [
//                         121.79926318587937,
//                         31.29361166914451
//                     ],
//                     [
//                         121.81285197064892,
//                         31.287527955905738
//                     ],
//                     [
//                         121.83360078449002,
//                         31.28048393375037
//                     ],
//                     [
//                         121.86150962740263,
//                         31.272479602678406
//                     ],
//                     [
//                         121.89428846974481,
//                         31.265435337100648
//                     ],
//                     [
//                         121.93193731151656,
//                         31.2593511370171
//                     ],
//                     [
//                         121.9738005758753,
//                         31.25206545301241
//                     ],
//                     [
//                         122.01987826282102,
//                         31.24357828508658
//                     ],
//                     [
//                         122.05649691240357,
//                         31.236612000385044
//                     ],
//                     [
//                         122.08365652462295,
//                         31.231166598907798
//                     ],
//                     [
//                         122.10388575303443,
//                         31.22467964806904
//                     ],
//                     [
//                         122.11718459763802,
//                         31.217151147868766
//                     ],
//                     [
//                         122.13694555687769,
//                         31.205135487441485
//                     ],
//                     [
//                         122.16316863075346,
//                         31.188632666787196
//                     ],
//                     [
//                         122.18901708928263,
//                         31.17252772867104
//                     ],
//                     [
//                         122.22722785405648,
//                         31.148967145304
//                     ],
//                     [
//                         122.32415996366278,
//                         31.068705893780237
//                     ],
//                     [
//                         122.51570548786108,
//                         31.004327740247057
//                     ],
//                     [
//                         122.53153711137641,
//                         30.991654433465783
//                     ],
//                     [
//                         122.54586113123636,
//                         30.97728771890689
//                     ],
//                     [
//                         122.64677445241898,
//                         30.96384295913526
//                     ],
//                     [
//                         122.74009197347297,
//                         30.95149000190367
//                     ],
//                     [
//                         122.8594514681259,
//                         30.93574726062159
//                     ],
//                     [
//                         122.91905357431969,
//                         30.888208619983445
//                     ],
//                     [
//                         122.91889829205435,
//                         30.80887407998925
//                     ],
//                     [
//                         122.93997728944768,
//                         30.709610427106252
//                     ],
//                     [
//                         122.98229056649969,
//                         30.59041766133446
//                     ],
//                     [
//                         123.00281040376926,
//                         30.50599095883642
//                     ],
//                     [
//                         123.00153680125642,
//                         30.456330319612142
//                     ],
//                     [
//                         123.00085,
//                         30.419375
//                     ],
//                     [
//                         123.00075,
//                         30.395125
//                     ],
//                     [
//                         122.99956441584067,
//                         30.363219312606862
//                     ],
//                     [
//                         122.997293247522,
//                         30.323657937820585
//                     ],
//                     [
//                         122.9876948797127,
//                         30.225792471870133
//                     ],
//                     [
//                         122.97076931241277,
//                         30.0696229147555
//                     ],
//                     [
//                         122.91964446970395,
//                         29.86255020449336
//                     ],
//                     [
//                         122.83432035158624,
//                         29.60457434108372
//                     ],
//                     [
//                         122.72839026071641,
//                         29.31572432632114
//                     ],
//                     [
//                         122.60185419709444,
//                         28.996000160205625
//                     ],
//                     [
//                         122.50832469177229,
//                         28.784910507181028
//                     ],
//                     [
//                         122.44780174474995,
//                         28.682455367247343
//                     ],
//                     [
//                         122.37806668578331,
//                         28.55767174664456
//                     ],
//                     [
//                         122.29911951487239,
//                         28.41055964537267
//                     ],
//                     [
//                         122.19815898893961,
//                         28.24370467641726
//                     ],
//                     [
//                         122.07518510798499,
//                         28.057106839778335
//                     ],
//                     [
//                         121.89280177532271,
//                         27.793262299563796
//                     ],
//                     [
//                         121.65100899095279,
//                         27.452171055773654
//                     ],
//                     [
//                         121.36066404694756,
//                         27.024371196478864
//                     ],
//                     [
//                         121.02176694330709,
//                         26.509862721679433
//                     ],
//                     [
//                         120.81988879361512,
//                         26.186931363209787
//                     ],
//                     [
//                         120.75502959787171,
//                         26.055577121069927
//                     ],
//                     [
//                         120.685775,
//                         25.9298
//                     ],
//                     [
//                         120.61212499999999,
//                         25.8096
//                     ],
//                     [
//                         120.55215,
//                         25.718200000000003
//                     ],
//                     [
//                         120.50585,
//                         25.6556
//                     ],
//                     [
//                         120.4412474853981,
//                         25.572558432261392
//                     ],
//                     [
//                         120.35834245619426,
//                         25.469075296784172
//                     ],
//                     [
//                         120.25306073358334,
//                         25.329800572441346
//                     ],
//                     [
//                         120.12540231756527,
//                         25.154734259232914
//                     ],
//                     [
//                         119.91033773381197,
//                         24.93835564290425
//                     ],
//                     [
//                         119.60786698232342,
//                         24.680664723455354
//                     ],
//                     [
//                         119.38960832436067,
//                         24.49745334271563
//                     ],
//                     [
//                         119.25556175992372,
//                         24.388721500685072
//                     ],
//                     [
//                         119.12630549105927,
//                         24.294516492071224
//                     ],
//                     [
//                         119.0018395177673,
//                         24.214838316874083
//                     ],
//                     [
//                         118.90555027183328,
//                         24.14420203125941
//                     ],
//                     [
//                         118.83743775325718,
//                         24.082607635227212
//                     ],
//                     [
//                         118.72940292984579,
//                         23.99695922858311
//                     ],
//                     [
//                         118.58144580159907,
//                         23.88725681132709
//                     ],
//                     [
//                         118.21271100734307,
//                         23.454120636937837
//                     ],
//                     [
//                         117.62319854707779,
//                         22.69755070541534
//                     ],
//                     [
//                         117.31880741031844,
//                         22.309591754849247
//                     ],
//                     [
//                         117.29953759706504,
//                         22.290243785239568
//                     ],
//                     [
//                         117.28027311535445,
//                         22.270893543177028
//                     ],
//                     [
//                         117.26101396518666,
//                         22.25154102866163
//                     ],
//                     [
//                         117.24176013460419,
//                         22.232186247321756
//                     ],
//                     [
//                         117.22251162360703,
//                         22.212829199157397
//                     ],
//                     [
//                         117.20326842024963,
//                         22.193469889787906
//                     ],
//                     [
//                         117.18403052453198,
//                         22.17410831921327
//                     ],
//                     [
//                         117.16479792452046,
//                         22.15474449304383
//                     ],
//                     [
//                         117.14557062021507,
//                         22.13537841127958
//                     ],
//                     [
//                         117.1263485996941,
//                         22.11601007952185
//                     ],
//                     [
//                         117.10713186295754,
//                         22.096639497770646
//                     ],
//                     [
//                         117.08792039809558,
//                         22.07726667161834
//                     ],
//                     [
//                         117.0687142051082,
//                         22.057891601064934
//                     ],
//                     [
//                         117.04951327209744,
//                         22.03851429169385
//                     ],
//                     [
//                         117.03031759906332,
//                         22.019134743505088
//                     ],
//                     [
//                         117.01112717411979,
//                         21.999752962073153
//                     ],
//                     [
//                         116.99194199726685,
//                         21.980368947398045
//                     ],
//                     [
//                         116.97276205663029,
//                         21.960982705045367
//                     ],
//                     [
//                         116.95358735221009,
//                         21.94159423501512
//                     ],
//                     [
//                         116.91683615595522,
//                         21.903755828931466
//                     ],
//                     [
//                         116.86250846786568,
//                         21.847467486794404
//                     ],
//                     [
//                         116.8082234963367,
//                         21.791161397725702
//                     ],
//                     [
//                         116.75398124136828,
//                         21.734837561725367
//                     ],
//                     [
//                         116.69978141977083,
//                         21.678496107512565
//                     ],
//                     [
//                         116.64562403154434,
//                         21.6221370350873
//                     ],
//                     [
//                         116.59150879435057,
//                         21.565760472574294
//                     ],
//                     [
//                         116.53743570818952,
//                         21.50936641997355
//                     ],
//                     [
//                         116.4834044915711,
//                         21.45295500481991
//                     ],
//                     [
//                         116.4294151444953,
//                         21.39652622711337
//                     ],
//                     [
//                         116.37546738631707,
//                         21.340080213803404
//                     ],
//                     [
//                         116.32156121703642,
//                         21.283616964890008
//                     ],
//                     [
//                         116.26769635684985,
//                         21.227136606741777
//                     ],
//                     [
//                         116.21387280575738,
//                         21.17063913935871
//                     ],
//                     [
//                         116.160090284794,
//                         21.11412468853301
//                     ],
//                     [
//                         116.10634879395971,
//                         21.05759325426468
//                     ],
//                     [
//                         116.05264805512445,
//                         21.001044961773992
//                     ],
//                     [
//                         115.99898806828821,
//                         20.94447981106094
//                     ],
//                     [
//                         115.94536855615257,
//                         20.88789792677831
//                     ],
//                     [
//                         115.89178951871752,
//                         20.831299308926106
//                     ],
//                     [
//                         115.823775,
//                         20.808125
//                     ],
//                     [
//                         115.741325,
//                         20.818375
//                     ],
//                     [
//                         115.41399918089415,
//                         20.373490730659842
//                     ],
//                     [
//                         114.84179754268246,
//                         19.473472191979525
//                     ],
//                     [
//                         114.2757407921965,
//                         18.571730563619713
//                     ],
//                     [
//                         113.7158289294363,
//                         17.668265845580407
//                     ],
//                     [
//                         113.16134618385519,
//                         16.76328420189699
//                     ],
//                     [
//                         112.61229255545317,
//                         15.856785632569464
//                     ],
//                     [
//                         112.06799235684139,
//                         14.948963087763024
//                     ],
//                     [
//                         111.52844558801985,
//                         14.039816567477672
//                     ],
//                     [
//                         110.99301131624242,
//                         13.129527696849355
//                     ],
//                     [
//                         110.46168954150909,
//                         12.218096475878072
//                     ],
//                     [
//                         109.93386912301133,
//                         11.305694918111941
//                     ],
//                     [
//                         109.40955006074915,
//                         10.392323023550958
//                     ],
//                     [
//                         108.88814636345862,
//                         9.47814476030262
//                     ],
//                     [
//                         108.36965803113972,
//                         8.56316012836692
//                     ],
//                     [
//                         107.853519854311,
//                         7.647526494504669
//                     ],
//                     [
//                         107.33973183297246,
//                         6.731243858715863
//                     ],
//                     [
//                         106.82774540116324,
//                         5.814464331287862
//                     ],
//                     [
//                         106.31756055888334,
//                         4.897187912220669
//                     ],
//                     [
//                         105.8086414307082,
//                         3.9795627218361034
//                     ],
//                     [
//                         105.30098801663782,
//                         3.0615887601341667
//                     ],
//                     [
//                         105.0205176035901,
//                         2.5339040152052004
//                     ],
//                     [
//                         104.96723019156505,
//                         2.396508487049205
//                     ],
//                     [
//                         104.900664308994,
//                         2.2163026276381323
//                     ],
//                     [
//                         104.82081995587697,
//                         1.9932864369719827
//                     ],
//                     [
//                         104.71677283620119,
//                         1.7791107377793114
//                     ],
//                     [
//                         104.58852294996665,
//                         1.5737755300601184
//                     ],
//                     [
//                         104.50427820103897,
//                         1.4571684608600421
//                     ],
//                     [
//                         104.46403858941812,
//                         1.4292895301790822
//                     ],
//                     [
//                         104.41520502420755,
//                         1.3898840969183521
//                     ],
//                     [
//                         104.32906374600708,
//                         1.3134861931576012
//                     ],
//                     [
//                         104.24811052444967,
//                         1.298777755291746
//                     ],
//                     [
//                         104.16436453034571,
//                         1.2841108748902945
//                     ],
//                     [
//                         104.11639538676667,
//                         1.2763805562023722
//                     ],
//                     [
//                         104.07628776234459,
//                         1.2706978390941617
//                     ],
//                     [
//                         104.03996649891025,
//                         1.264583956832809
//                     ],
//                     [
//                         103.99116414524035,
//                         1.2547663857110662
//                     ],
//                     [
//                         103.93046298164624,
//                         1.2389214259656431
//                     ],
//                     [
//                         103.90242920641093,
//                         1.2318042760435228
//                     ],
//                     [
//                         103.88682909833636,
//                         1.2281332826962312
//                     ],
//                     [
//                         103.87758243458568,
//                         1.2251424017635673
//                     ],
//                     [
//                         103.87468921515884,
//                         1.2228316332455307
//                     ],
//                     [
//                         103.86819190675848,
//                         1.2184911402546423
//                     ],
//                     [
//                         103.85809050938462,
//                         1.2121209227909022
//                     ],
//                     [
//                         103.84554146572682,
//                         1.208741599652853
//                     ],
//                     [
//                         103.83054477578513,
//                         1.2083531708404953
//                     ],
//                     [
//                         103.81649500435451,
//                         1.2108240853091214
//                     ],
//                     [
//                         103.80339215143496,
//                         1.2161543430587316
//                     ],
//                     [
//                         103.79200739839247,
//                         1.218694820276414
//                     ],
//                     [
//                         103.78234074522702,
//                         1.2184455169621684
//                     ],
//                     [
//                         103.77192606150213,
//                         1.218287250269519
//                     ],
//                     [
//                         103.7607633472178,
//                         1.2182200201984656
//                     ],
//                     [
//                         103.74714618070125,
//                         1.2186570154781968
//                     ],
//                     [
//                         103.72303875257808,
//                         1.2200688464239704
//                     ],
//                     [
//                         103.70243759979356,
//                         1.226602959077871
//                     ],
//                     [
//                         103.67250174749775,
//                         1.2054410722760893
//                     ],
//                     [
//                         103.58763619097144,
//                         1.19727984009565
//                     ],
//                     [
//                         103.54733618089341,
//                         1.2058417047183996
//                     ],
//                     [
//                         103.52330401900147,
//                         1.212137894914887
//                     ],
//                     [
//                         103.502106368603,
//                         1.2190223655593622
//                     ],
//                     [
//                         103.47095608721379,
//                         1.2279476147877093
//                     ],
//                     [
//                         103.40930171864395,
//                         1.2443966565060378
//                     ],
//                     [
//                         103.17339286228648,
//                         1.4163544183504486
//                     ],
//                     [
//                         102.48810763364715,
//                         1.8312113129912528
//                     ],
//                     [
//                         102.27858276565775,
//                         1.941541881351128
//                     ],
//                     [
//                         102.09819912456581,
//                         2.100138147502026
//                     ],
//                     [
//                         101.95287025405833,
//                         2.218820774142342
//                     ],
//                     [
//                         101.78246827377131,
//                         2.3504551833223744
//                     ],
//                     [
//                         101.68129168789773,
//                         2.4282933863549947
//                     ],
//                     [
//                         101.64934049643759,
//                         2.452335383240202
//                     ],
//                     [
//                         101.58979111023811,
//                         2.497365091871168
//                     ],
//                     [
//                         101.4590697388299,
//                         2.596391222436253
//                     ],
//                     [
//                         101.28240124227898,
//                         2.69044784375307
//                     ],
//                     [
//                         101.16677479556024,
//                         2.7497440756648786
//                     ],
//                     [
//                         100.99656395195495,
//                         2.8335761500834877
//                     ],
//                     [
//                         100.8984,
//                         2.9311
//                     ],
//                     [
//                         100.82070412418028,
//                         3.006486350489449
//                     ],
//                     [
//                         100.67070412418029,
//                         3.153186350489449
//                     ],
//                     [
//                         100.42250137472676,
//                         3.3963287834964833
//                     ],
//                     [
//                         100.20128484999387,
//                         3.5826465948353157
//                     ],
//                     [
//                         100.00705454998162,
//                         3.712139784505948
//                     ],
//                     [
//                         99.85457954998162,
//                         3.8278897845059476
//                     ],
//                     [
//                         99.74385984999387,
//                         3.929896594835316
//                     ],
//                     [
//                         99.62615,
//                         4.01295
//                     ],
//                     [
//                         99.50145,
//                         4.07705
//                     ],
//                     [
//                         99.42575,
//                         4.11895
//                     ],
//                     [
//                         99.39905,
//                         4.13865
//                     ],
//                     [
//                         99.35585,
//                         4.17995
//                     ],
//                     [
//                         99.29615,
//                         4.242850000000001
//                     ],
//                     [
//                         99.253725,
//                         4.2886750000000005
//                     ],
//                     [
//                         99.22857499999999,
//                         4.317425
//                     ],
//                     [
//                         99.1999,
//                         4.334925
//                     ],
//                     [
//                         99.1677,
//                         4.341175
//                     ],
//                     [
//                         99.13905,
//                         4.347849999999999
//                     ],
//                     [
//                         99.11395,
//                         4.3549500000000005
//                     ],
//                     [
//                         99.01365356190323,
//                         4.413555999169749
//                     ],
//                     [
//                         98.8381606857097,
//                         4.523667997509246
//                     ],
//                     [
//                         98.72208342959976,
//                         4.603825500534437
//                     ],
//                     [
//                         98.66542179357342,
//                         4.65402850824532
//                     ],
//                     [
//                         98.6097932316702,
//                         4.720397509075571
//                     ],
//                     [
//                         98.55519774389006,
//                         4.80293250302519
//                     ],
//                     [
//                         98.49725000000001,
//                         4.8597
//                     ],
//                     [
//                         98.43594999999999,
//                         4.8907
//                     ],
//                     [
//                         98.392375,
//                         4.9155
//                     ],
//                     [
//                         98.366525,
//                         4.9341
//                     ],
//                     [
//                         98.34048754387007,
//                         4.954598069289047
//                     ],
//                     [
//                         98.31426263161023,
//                         4.976994207867143
//                     ],
//                     [
//                         98.28803592988297,
//                         4.99938930655254
//                     ],
//                     [
//                         98.2618074386883,
//                         5.021783365345236
//                     ],
//                     [
//                         98.23557714179731,
//                         5.0441763747163915
//                     ],
//                     [
//                         98.20934503921,
//                         5.066568334666006
//                     ],
//                     [
//                         98.18311111469461,
//                         5.0889592356609405
//                     ],
//                     [
//                         98.15687536825112,
//                         5.111349077701196
//                     ],
//                     [
//                         98.13063778364503,
//                         5.1337378512493
//                     ],
//                     [
//                         98.10439836087633,
//                         5.156125556305253
//                     ],
//                     [
//                         98.07815708370771,
//                         5.178512183327209
//                     ],
//                     [
//                         98.05191395213916,
//                         5.200897732315166
//                     ],
//                     [
//                         98.02566894993049,
//                         5.22328219372287
//                     ],
//                     [
//                         97.9994220770817,
//                         5.245665567550317
//                     ],
//                     [
//                         97.9731733173497,
//                         5.268047844246802
//                     ],
//                     [
//                         97.9469226707345,
//                         5.290429023812326
//                     ],
//                     [
//                         97.92067012099017,
//                         5.312809096691689
//                     ],
//                     [
//                         97.89441566811672,
//                         5.335188062884892
//                     ],
//                     [
//                         97.86815929586527,
//                         5.357565912832211
//                     ],
//                     [
//                         97.84190100423584,
//                         5.379942646533644
//                     ],
//                     [
//                         97.80777417141852,
//                         5.401525393865165
//                     ],
//                     [
//                         97.7657787974133,
//                         5.42231415482677
//                     ],
//                     [
//                         97.72378052536578,
//                         5.443100014253841
//                     ],
//                     [
//                         97.68177935527592,
//                         5.4638829721463775
//                     ],
//                     [
//                         97.63977526524974,
//                         5.484663005764392
//                     ],
//                     [
//                         97.59776825528724,
//                         5.505440115107885
//                     ],
//                     [
//                         97.55575830350756,
//                         5.526214277430794
//                     ],
//                     [
//                         97.51374540991071,
//                         5.5469854927331195
//                     ],
//                     [
//                         97.471729552629,
//                         5.567753738262681
//                     ],
//                     [
//                         97.42971073166242,
//                         5.588519014019477
//                     ],
//                     [
//                         97.38768892515665,
//                         5.609281297245175
//                     ],
//                     [
//                         97.34566413311168,
//                         5.630040587939773
//                     ],
//                     [
//                         97.30363633368668,
//                         5.650796863338741
//                     ],
//                     [
//                         97.26160552688165,
//                         5.6715501234420795
//                     ],
//                     [
//                         97.21957169086929,
//                         5.692300345479024
//                     ],
//                     [
//                         97.17753482564959,
//                         5.713047529449579
//                     ],
//                     [
//                         97.13549490940892,
//                         5.733791652576704
//                     ],
//                     [
//                         97.09345194214728,
//                         5.7545327148604
//                     ],
//                     [
//                         97.05140590206494,
//                         5.775270693517326
//                     ],
//                     [
//                         97.00935678916193,
//                         5.796005588547478
//                     ],
//                     [
//                         96.95043334801369,
//                         5.815647103133612
//                     ],
//                     [
//                         96.87463557862023,
//                         5.8341952372757255
//                     ],
//                     [
//                         96.79883278913093,
//                         5.852733214079813
//                     ],
//                     [
//                         96.72302497954576,
//                         5.871261033545876
//                     ],
//                     [
//                         96.64721212269058,
//                         5.889778629451669
//                     ],
//                     [
//                         96.57139421856539,
//                         5.908286001797193
//                     ],
//                     [
//                         96.49557124012338,
//                         5.926783084398591
//                     ],
//                     [
//                         96.41974318736456,
//                         5.9452698772558605
//                     ],
//                     [
//                         96.34391003336998,
//                         5.96374631422389
//                     ],
//                     [
//                         96.26807177813964,
//                         5.982212395302678
//                     ],
//                     [
//                         96.19222839488353,
//                         6.000668054386214
//                     ],
//                     [
//                         96.11637988360164,
//                         6.019113291474495
//                     ],
//                     [
//                         96.04052621763344,
//                         6.037548040500976
//                     ],
//                     [
//                         95.96466739697894,
//                         6.055972301465656
//                     ],
//                     [
//                         95.88880339510811,
//                         6.074386008341811
//                     ],
//                     [
//                         95.81293421202096,
//                         6.092789161129446
//                     ],
//                     [
//                         95.7370598213185,
//                         6.111181693842028
//                     ],
//                     [
//                         95.66118022300071,
//                         6.129563606479558
//                     ],
//                     [
//                         95.5852953908007,
//                         6.147934833096068
//                     ],
//                     [
//                         95.50940532471841,
//                         6.166295373691557
//                     ],
//                     [
//                         95.20928243468106,
//                         6.173046279278254
//                     ],
//                     [
//                         94.68492672068862,
//                         6.168187549856162
//                     ],
//                     [
//                         94.16058162635403,
//                         6.162816423806414
//                     ],
//                     [
//                         93.63624715167731,
//                         6.156932901129011
//                     ],
//                     [
//                         93.111925295985,
//                         6.15053798993932
//                     ],
//                     [
//                         92.58761605927711,
//                         6.1436316902373385
//                     ],
//                     [
//                         92.06332142359096,
//                         6.136215185162802
//                     ],
//                     [
//                         91.53904138892656,
//                         6.128288474715711
//                     ],
//                     [
//                         91.01477791732245,
//                         6.119852916548203
//                     ],
//                     [
//                         90.49053100877862,
//                         6.1109085106602805
//                     ],
//                     [
//                         89.96630260265545,
//                         6.101456788629923
//                     ],
//                     [
//                         89.44209269895293,
//                         6.091497750457128
//                     ],
//                     [
//                         88.91790321170788,
//                         6.081033100985165
//                     ],
//                     [
//                         88.39373414092029,
//                         6.070062840214032
//                     ],
//                     [
//                         87.869587372696,
//                         6.058588845518441
//                     ],
//                     [
//                         87.34546290703503,
//                         6.046611116898395
//                     ],
//                     [
//                         86.82136259954649,
//                         6.034131703453704
//                     ],
//                     [
//                         86.29728645023039,
//                         6.021150605184368
//                     ],
//                     [
//                         85.77323628167926,
//                         6.007670042037275
//                     ],
//                     [
//                         85.24921209389308,
//                         5.993690014012425
//                     ],
//                     [
//                         84.8864801052334,
//                         5.974500367129055
//                     ],
//                     [
//                         84.68504031570023,
//                         5.950101101387164
//                     ],
//                     [
//                         84.48361842197642,
//                         5.925628976300314
//                     ],
//                     [
//                         84.28221442406198,
//                         5.901083991868504
//                     ],
//                     [
//                         84.08082827501255,
//                         5.8764667675389894
//                     ],
//                     [
//                         83.87945997482814,
//                         5.851777303311772
//                     ],
//                     [
//                         83.67810947306924,
//                         5.82701622184558
//                     ],
//                     [
//                         83.47677676973586,
//                         5.802183523140414
//                     ],
//                     [
//                         83.27546181090673,
//                         5.777279833031354
//                     ],
//                     [
//                         83.07416459658185,
//                         5.7523051515184
//                     ],
//                     [
//                         82.87288506937264,
//                         5.7272601075778775
//                     ],
//                     [
//                         82.6716232292791,
//                         5.702144701209784
//                     ],
//                     [
//                         82.47037901546022,
//                         5.676959564496597
//                     ],
//                     [
//                         82.269152427916,
//                         5.651704697438317
//                     ],
//                     [
//                         82.06794340236837,
//                         5.626380735188497
//                     ],
//                     [
//                         81.86675193881734,
//                         5.600987677747137
//                     ],
//                     [
//                         81.66557796956411,
//                         5.5755261633038256
//                     ],
//                     [
//                         81.46442149460867,
//                         5.549996191858565
//                     ],
//                     [
//                         81.26328244284821,
//                         5.52439840460195
//                     ],
//                     [
//                         81.06216081428275,
//                         5.498732801533984
//                     ],
//                     [
//                         80.790075,
//                         5.4718
//                     ],
//                     [
//                         80.447025,
//                         5.4436
//                     ],
//                     [
//                         80.19886424916896,
//                         5.465275952351412
//                     ],
//                     [
//                         80.04559274750687,
//                         5.536827857054234
//                     ],
//                     [
//                         79.89228390691534,
//                         5.6083401153339985
//                     ],
//                     [
//                         79.73893772739433,
//                         5.679812727190702
//                     ],
//                     [
//                         79.58555326900199,
//                         5.751244648884033
//                     ],
//                     [
//                         79.43213053173832,
//                         5.822635880413992
//                     ],
//                     [
//                         79.27866857820544,
//                         5.893985377175634
//                     ],
//                     [
//                         79.12516740840334,
//                         5.965293139168957
//                     ],
//                     [
//                         78.97162608755252,
//                         6.036558120907902
//                     ],
//                     [
//                         78.81804461565298,
//                         6.107780322392466
//                     ],
//                     [
//                         78.66442206061899,
//                         6.178958697239392
//                     ],
//                     [
//                         78.51075842245055,
//                         6.250093245448681
//                     ],
//                     [
//                         78.35705277183155,
//                         6.3211829197242375
//                     ],
//                     [
//                         78.20330510876198,
//                         6.392227720066063
//                     ],
//                     [
//                         78.04951450677217,
//                         6.463226598250021
//                     ],
//                     [
//                         77.89568096586211,
//                         6.534179554276112
//                     ],
//                     [
//                         77.74180356248604,
//                         6.605085538977409
//                     ],
//                     [
//                         77.58788229664395,
//                         6.675944552353915
//                     ],
//                     [
//                         77.43391624779218,
//                         6.746755544281625
//                     ],
//                     [
//                         77.27990541593073,
//                         6.8175185147605415
//                     ],
//                     [
//                         77.10034558567062,
//                         6.905844861625725
//                     ],
//                     [
//                         76.89523675701187,
//                         7.011734584877174
//                     ],
//                     [
//                         76.69003400942375,
//                         7.117534606365816
//                     ],
//                     [
//                         76.48473734290627,
//                         7.223244926091652
//                     ],
//                     [
//                         76.27934399371483,
//                         7.328862745107622
//                     ],
//                     [
//                         76.07385396184944,
//                         7.434388063413726
//                     ],
//                     [
//                         75.86826449331312,
//                         7.5398180766663625
//                     ],
//                     [
//                         75.66257558810588,
//                         7.6451527848655285
//                     ],
//                     [
//                         75.45678450235866,
//                         7.750389378149041
//                     ],
//                     [
//                         75.25089123607145,
//                         7.8555278565169
//                     ],
//                     [
//                         75.04489305589084,
//                         7.960565404469769
//                     ],
//                     [
//                         74.83878996181681,
//                         8.065502022007648
//                     ],
//                     [
//                         74.6325792314077,
//                         8.170334887879083
//                     ],
//                     [
//                         74.4262608646635,
//                         8.275064002084074
//                     ],
//                     [
//                         74.2198321504583,
//                         8.379686537507798
//                     ],
//                     [
//                         74.0132930887921,
//                         8.484202494150257
//                     ],
//                     [
//                         73.80664098026739,
//                         8.588609038925863
//                     ],
//                     [
//                         73.59987582488418,
//                         8.692906171834617
//                     ],
//                     [
//                         73.39299493539443,
//                         8.797091053716745
//                     ],
//                     [
//                         73.18599831179814,
//                         8.90116368457225
//                     ],
//                     [
//                         72.58894258837056,
//                         9.082342962112026
//                     ],
//                     [
//                         71.60182776511166,
//                         9.340628886336075
//                     ],
//                     [
//                         70.61324244111248,
//                         9.596146929623794
//                     ],
//                     [
//                         69.62318661637298,
//                         9.84889709197518
//                     ],
//                     [
//                         68.63161020468274,
//                         10.098719844474516
//                     ],
//                     [
//                         67.63851320604178,
//                         10.345615187121798
//                     ],
//                     [
//                         66.64385228936501,
//                         10.589425447003542
//                     ],
//                     [
//                         65.64762745465245,
//                         10.83015062411975
//                     ],
//                     [
//                         64.64980245598318,
//                         11.067635132998642
//                     ],
//                     [
//                         63.650377293357195,
//                         11.301878973640225
//                     ],
//                     [
//                         62.64932311550146,
//                         11.532728896118012
//                     ],
//                     [
//                         61.64663992241597,
//                         11.760184900432003
//                     ],
//                     [
//                         60.64230654271083,
//                         11.984096336583189
//                     ],
//                     [
//                         59.636322976386026,
//                         12.204463204571564
//                     ],
//                     [
//                         58.62867598902946,
//                         12.421137734363988
//                     ],
//                     [
//                         57.61936558064113,
//                         12.634119925960459
//                     ],
//                     [
//                         56.608386678698054,
//                         12.843265184116568
//                     ],
//                     [
//                         55.59573928320023,
//                         13.048573508832312
//                     ],
//                     [
//                         54.581426672112,
//                         13.249903788128528
//                     ],
//                     [
//                         53.56544884543338,
//                         13.447256022005211
//                     ],
//                     [
//                         52.93167243175465,
//                         13.530840165788288
//                     ],
//                     [
//                         52.68009743107581,
//                         13.500656219477756
//                     ],
//                     [
//                         52.428586506155966,
//                         13.470221906696063
//                     ],
//                     [
//                         52.17713965699511,
//                         13.439537227443207
//                     ],
//                     [
//                         51.925757594325006,
//                         13.408603491629325
//                     ],
//                     [
//                         51.674440318145656,
//                         13.37742069925442
//                     ],
//                     [
//                         51.42318851774084,
//                         13.345990178951107
//                     ],
//                     [
//                         51.17200219311056,
//                         13.314311930719384
//                     ],
//                     [
//                         50.92088201192627,
//                         13.282387301694651
//                     ],
//                     [
//                         50.66982797418797,
//                         13.25021629187691
//                     ],
//                     [
//                         50.41884072579944,
//                         13.217800266683453
//                     ],
//                     [
//                         50.16792026676069,
//                         13.185139226114284
//                     ],
//                     [
//                         49.917067221061615,
//                         13.152234553646663
//                     ],
//                     [
//                         49.66628158870222,
//                         13.119086249280588
//                     ],
//                     [
//                         49.415563971621395,
//                         13.085695714330374
//                     ],
//                     [
//                         49.164914369819144,
//                         13.052062948796014
//                     ],
//                     [
//                         48.91433336305491,
//                         13.018189371605107
//                     ],
//                     [
//                         48.66382095132869,
//                         12.984074982757653
//                     ],
//                     [
//                         48.4133776921014,
//                         12.949721218569994
//                     ],
//                     [
//                         48.16300358537304,
//                         12.915128079042136
//                     ],
//                     [
//                         47.278812631903925,
//                         12.661167368084655
//                     ],
//                     [
//                         45.76080483169405,
//                         12.187839085697554
//                     ],
//                     [
//                         44.975811858521396,
//                         11.949070101963411
//                     ],
//                     [
//                         44.92383371238596,
//                         11.944860416882227
//                     ],
//                     [
//                         44.87185718538831,
//                         11.940641185816947
//                     ],
//                     [
//                         44.81988227752845,
//                         11.936412408767575
//                     ],
//                     [
//                         44.76790899484283,
//                         11.93217409328938
//                     ],
//                     [
//                         44.71593733733144,
//                         11.927926239382366
//                     ],
//                     [
//                         44.663967311007724,
//                         11.923668854634096
//                     ],
//                     [
//                         44.61199891587168,
//                         11.919401939044576
//                     ],
//                     [
//                         44.56003215791381,
//                         11.915125500233618
//                     ],
//                     [
//                         44.50806703713411,
//                         11.910839538201223
//                     ],
//                     [
//                         44.45610355949998,
//                         11.906544060599408
//                     ],
//                     [
//                         44.404141725011414,
//                         11.902239067428175
//                     ],
//                     [
//                         44.35218153961267,
//                         11.897924566371682
//                     ],
//                     [
//                         44.300223003303756,
//                         11.893600557429927
//                     ],
//                     [
//                         44.248266122005674,
//                         11.889267048319176
//                     ],
//                     [
//                         44.196310895718426,
//                         11.884924039039428
//                     ],
//                     [
//                         44.144357330339744,
//                         11.880571537338987
//                     ],
//                     [
//                         44.09240542586963,
//                         11.876209543217861
//                     ],
//                     [
//                         44.04045518818243,
//                         11.871838064456345
//                     ],
//                     [
//                         43.98850661727814,
//                         11.867457101054443
//                     ],
//                     [
//                         43.85160639769212,
//                         12.022566753112223
//                     ],
//                     [
//                         43.62975452942439,
//                         12.337167020629686
//                     ],
//                     [
//                         43.52406584826674,
//                         12.50573210861091
//                     ],
//                     [
//                         43.534540354219175,
//                         12.528262017055894
//                     ],
//                     [
//                         43.494691126693596,
//                         12.563859130414887
//                     ],
//                     [
//                         43.35943168518818,
//                         12.636855607824382
//                     ],
//                     [
//                         43.14005165167988,
//                         13.091648739200844
//                     ],
//                     [
//                         43.055501702913055,
//                         13.250875080042256
//                     ],
//                     [
//                         43.03265516105161,
//                         13.266132340807438
//                     ],
//                     [
//                         42.95242156380333,
//                         13.328142740006415
//                     ],
//                     [
//                         42.745990584850674,
//                         13.491288046455571
//                     ],
//                     [
//                         42.6533135230369,
//                         13.566770245954388
//                     ],
//                     [
//                         42.612977417269384,
//                         13.660083848192334
//                     ],
//                     [
//                         42.07949366091286,
//                         14.738857845384205
//                     ],
//                     [
//                         41.875190715539816,
//                         15.141229182950472
//                     ],
//                     [
//                         41.82224066236476,
//                         15.226789193288429
//                     ],
//                     [
//                         41.76924740792194,
//                         15.312336751018563
//                     ],
//                     [
//                         41.716210952211355,
//                         15.397871856140881
//                     ],
//                     [
//                         41.66313072821677,
//                         15.483394341516373
//                     ],
//                     [
//                         41.610006735938185,
//                         15.568904207145042
//                     ],
//                     [
//                         41.556838405567845,
//                         15.654401284934632
//                     ],
//                     [
//                         41.50362573710575,
//                         15.739885574885145
//                     ],
//                     [
//                         41.4503681579144,
//                         15.825356907936833
//                     ],
//                     [
//                         41.397065667993786,
//                         15.910815284089695
//                     ],
//                     [
//                         41.343717691837526,
//                         15.996260533302147
//                     ],
//                     [
//                         41.290324229445616,
//                         16.081692655574184
//                     ],
//                     [
//                         41.236884702404154,
//                         16.167111479867877
//                     ],
//                     [
//                         41.18339911071314,
//                         16.25251700618322
//                     ],
//                     [
//                         41.12986687301154,
//                         16.337909062471308
//                     ],
//                     [
//                         41.076287989299345,
//                         16.423287648732142
//                     ],
//                     [
//                         41.02266187522915,
//                         16.50865259189107
//                     ],
//                     [
//                         40.96898853080094,
//                         16.59400389194809
//                     ],
//                     [
//                         40.915267368641025,
//                         16.679341374787924
//                     ],
//                     [
//                         40.861498388749396,
//                         16.764665040410563
//                     ],
//                     [
//                         40.50922872206369,
//                         17.36923882550844
//                     ],
//                     [
//                         39.858458368583904,
//                         18.493062730081554
//                     ],
//                     [
//                         39.292439745751516,
//                         19.47247566797068
//                     ],
//                     [
//                         38.81117285356653,
//                         20.307477639175815
//                     ],
//                     [
//                         38.53384886822454,
//                         20.791308050597813
//                     ],
//                     [
//                         38.460467789725534,
//                         20.923966902236675
//                     ],
//                     [
//                         38.33005859047132,
//                         21.140631128128966
//                     ],
//                     [
//                         38.14262127046188,
//                         21.441300728274683
//                     ],
//                     [
//                         37.95440268709751,
//                         21.74175876875833
//                     ],
//                     [
//                         37.765402840378215,
//                         22.042005249579894
//                     ],
//                     [
//                         37.57559205380994,
//                         22.342031818758233
//                     ],
//                     [
//                         37.38497032739269,
//                         22.64183847629334
//                     ],
//                     [
//                         37.193507278366326,
//                         22.941416641427654
//                     ],
//                     [
//                         37.00120290673084,
//                         23.240766314161178
//                     ],
//                     [
//                         36.808026094090934,
//                         23.539878674113176
//                     ],
//                     [
//                         36.61397684044661,
//                         23.838753721283656
//                     ],
//                     [
//                         36.41902326157751,
//                         24.13738238437525
//                     ],
//                     [
//                         36.22316535748362,
//                         24.43576466338796
//                     ],
//                     [
//                         36.026370447092916,
//                         24.73389122434492
//                     ],
//                     [
//                         35.828638530405385,
//                         25.03176206724612
//                     ],
//                     [
//                         35.62993609761995,
//                         25.329367583179312
//                     ],
//                     [
//                         35.4302631487366,
//                         25.626707772144485
//                     ],
//                     [
//                         35.22958531248557,
//                         25.92377273752056
//                     ],
//                     [
//                         35.02790258886685,
//                         26.22056247930753
//                     ],
//                     [
//                         34.8251797115321,
//                         26.51706679985897
//                     ],
//                     [
//                         34.62141668048133,
//                         26.813285699174873
//                     ],
//                     [
//                         34.42287373394695,
//                         27.10493353897001
//                     ],
//                     [
//                         34.13288944091998,
//                         27.53554870938157
//                     ],
//                     [
//                         33.8426283845597,
//                         27.74126583708488
//                     ],
//                     [
//                         33.72128291530626,
//                         27.867766593699457
//                     ],
//                     [
//                         33.46173023208254,
//                         28.07998519976497
//                     ],
//                     [
//                         33.36699436046866,
//                         28.160485422437638
//                     ],
//                     [
//                         33.350557739390055,
//                         28.18000679707263
//                     ],
//                     [
//                         33.27124360347504,
//                         28.294718935966966
//                     ],
//                     [
//                         33.05795612734793,
//                         28.60957329069748
//                     ],
//                     [
//                         32.63666468932248,
//                         29.45924977493815
//                     ],
//                     [
//                         32.57188695885495,
//                         29.592667235640292
//                     ],
//                     [
//                         32.5466068710993,
//                         29.77463839338037
//                     ],
//                     [
//                         32.55338350038646,
//                         29.82832236167829
//                     ],
//                     [
//                         32.55305567990508,
//                         29.863844169887738
//                     ],
//                     [
//                         32.545295589173776,
//                         29.916725626218167
//                     ],
//                     [
//                         32.577692960948156,
//                         29.945481508960967
//                     ],
//                     [
//                         32.58508624281563,
//                         29.958550920103708
//                     ],
//                     [
//                         32.586379235011805,
//                         29.9756411008028
//                     ],
//                     [
//                         32.585642867336304,
//                         29.985766943494085
//                     ],
//                     [
//                         32.576581090573825,
//                         30.034126003558214
//                     ],
//                     [
//                         32.57236631067559,
//                         30.061853387667085
//                     ],
//                     [
//                         32.570680398721635,
//                         30.09978354112735
//                     ],
//                     [
//                         32.57017663532176,
//                         30.12402045077347
//                     ],
//                     [
//                         32.56815153655688,
//                         30.191958789257484
//                     ],
//                     [
//                         32.5645063587622,
//                         30.204210720463024
//                     ],
//                     [
//                         32.536154975913604,
//                         30.24620576274104
//                     ],
//                     [
//                         32.52238430424359,
//                         30.255652175406027
//                     ],
//                     [
//                         32.519399248469995,
//                         30.257566052854173
//                     ],
//                     [
//                         32.456937461040155,
//                         30.277266886169045
//                     ],
//                     [
//                         32.44649163310993,
//                         30.281501128474034
//                     ],
//                     [
//                         32.44094894890429,
//                         30.28499884300517
//                     ],
//                     [
//                         32.37720808051216,
//                         30.356398570284753
//                     ],
//                     [
//                         32.375367581891595,
//                         30.36104390635488
//                     ],
//                     [
//                         32.369729586654984,
//                         30.379806283350078
//                     ],
//                     [
//                         32.357044097321676,
//                         30.438328692926405
//                     ],
//                     [
//                         32.35402374270163,
//                         30.4438838927622
//                     ],
//                     [
//                         32.3507241446122,
//                         30.44675521055204
//                     ],
//                     [
//                         32.33866407851099,
//                         30.507731783680597
//                     ],
//                     [
//                         32.333840052085776,
//                         30.518814329111635
//                     ],
//                     [
//                         32.30993701705237,
//                         30.547816385659946
//                     ],
//                     [
//                         32.30489589348352,
//                         30.56174725586918
//                     ],
//                     [
//                         32.30353987540332,
//                         30.5722660867953
//                     ],
//                     [
//                         32.305796985847486,
//                         30.5827849177214
//                     ],
//                     [
//                         32.324191999209916,
//                         30.61642460041324
//                     ],
//                     [
//                         32.34509611377004,
//                         30.707024063151067
//                     ],
//                     [
//                         32.34107609173631,
//                         30.72084856613168
//                     ],
//                     [
//                         32.33952962704943,
//                         30.730311849053244
//                     ],
//                     [
//                         32.31772901757271,
//                         30.8065162283622
//                     ],
//                     [
//                         32.315261236136635,
//                         30.8830665989633
//                     ],
//                     [
//                         32.30803233657551,
//                         31.10384329866025
//                     ],
//                     [
//                         32.32278769472896,
//                         31.150922044155976
//                     ],
//                     [
//                         32.3351542839633,
//                         31.191871336690145
//                     ],
//                     [
//                         32.3436104753075,
//                         31.221002468591863
//                     ],
//                     [
//                         32.34448232861555,
//                         31.22342436272562
//                     ],
//                     [
//                         32.370820196703114,
//                         31.313862889421614
//                     ],
//                     [
//                         32.36805740879942,
//                         31.321992398712617
//                     ],
//                     [
//                         32.36810870600418,
//                         31.43459455072504
//                     ],
//                     [
//                         32.3661980774405,
//                         31.502751782809575
//                     ],
//                     [
//                         32.360414894544704,
//                         31.59462132705076
//                     ],
//                     [
//                         32.366656190907534,
//                         31.731493885491716
//                     ],
//                     [
//                         32.2654491210386,
//                         31.77663064796864
//                     ],
//                     [
//                         32.05887411705882,
//                         31.77565580062852
//                     ],
//                     [
//                         31.90740238084874,
//                         31.78649357855788
//                     ],
//                     [
//                         31.811033912408345,
//                         31.809143981756726
//                     ],
//                     [
//                         31.71466544396795,
//                         31.83179438495557
//                     ],
//                     [
//                         31.618296975527553,
//                         31.85444478815441
//                     ],
//                     [
//                         31.53942644787022,
//                         31.875997999998287
//                     ],
//                     [
//                         31.47805386099595,
//                         31.89645402048719
//                     ],
//                     [
//                         31.38578118298047,
//                         31.921414556461393
//                     ],
//                     [
//                         31.262608413823784,
//                         31.950879607920882
//                     ],
//                     [
//                         31.16941652193408,
//                         31.975759100237973
//                     ],
//                     [
//                         31.10620550731136,
//                         31.996053033412657
//                     ],
//                     [
//                         31.04479262339313,
//                         32.01404953142193
//                     ],
//                     [
//                         30.98517787017938,
//                         32.029748594265804
//                     ],
//                     [
//                         30.909980553685806,
//                         32.0500513370965
//                     ],
//                     [
//                         30.81920067391239,
//                         32.07495775991403
//                     ],
//                     [
//                         30.72837141143205,
//                         32.09979937854463
//                     ],
//                     [
//                         30.637492766244776,
//                         32.12457619298832
//                     ],
//                     [
//                         30.5465648484749,
//                         32.149288008122184
//                     ],
//                     [
//                         30.455587658122425,
//                         32.17393482394624
//                     ],
//                     [
//                         30.364561307954006,
//                         32.19851644574699
//                     ],
//                     [
//                         30.27348579796964,
//                         32.22303287352444
//                     ],
//                     [
//                         30.182361243586143,
//                         32.24748391298468
//                     ],
//                     [
//                         30.09118764480351,
//                         32.271869564127705
//                     ],
//                     [
//                         29.999965119696583,
//                         32.29618963308927
//                     ],
//                     [
//                         29.908693668265357,
//                         32.32044411986938
//                     ],
//                     [
//                         29.81737341125026,
//                         32.34463283104368
//                     ],
//                     [
//                         29.726004348651287,
//                         32.36875576661219
//                     ],
//                     [
//                         29.634586603881758,
//                         32.392812733600685
//                     ],
//                     [
//                         29.54312017694167,
//                         32.41680373200918
//                     ],
//                     [
//                         29.451605193924422,
//                         32.44072856932392
//                     ],
//                     [
//                         29.360041654830013,
//                         32.464587245544905
//                     ],
//                     [
//                         29.268429688438683,
//                         32.48837956862919
//                     ],
//                     [
//                         29.176769294750432,
//                         32.51210553857676
//                     ],
//                     [
//                         29.01875676947465,
//                         32.54763729910057
//                     ],
//                     [
//                         28.79439211261134,
//                         32.59497485020062
//                     ],
//                     [
//                         28.18941044061514,
//                         32.759378562456796
//                     ],
//                     [
//                         27.203811753486065,
//                         33.0408484358691
//                     ],
//                     [
//                         26.54630137813397,
//                         33.22456252943144
//                     ],
//                     [
//                         26.216879314558852,
//                         33.31052084314381
//                     ],
//                     [
//                         25.837949037769228,
//                         33.414594103475245
//                     ],
//                     [
//                         25.409510547765088,
//                         33.536782310425735
//                     ],
//                     [
//                         25.025175246836614,
//                         33.6416012058674
//                     ],
//                     [
//                         24.684943134983804,
//                         33.72905078980023
//                     ],
//                     [
//                         24.29724530929305,
//                         33.83375668632499
//                     ],
//                     [
//                         23.86208176976435,
//                         33.95571889544166
//                     ],
//                     [
//                         23.601649838773973,
//                         34.028730056478786
//                     ],
//                     [
//                         23.51594951632191,
//                         34.05279016943636
//                     ],
//                     [
//                         23.43020061989901,
//                         34.076790734530306
//                     ],
//                     [
//                         23.34440314950527,
//                         34.10073175176063
//                     ],
//                     [
//                         23.258557204631416,
//                         34.124613046303686
//                     ],
//                     [
//                         23.172662785277453,
//                         34.148434618159484
//                     ],
//                     [
//                         23.08671999331554,
//                         34.1721962928175
//                     ],
//                     [
//                         23.00072882874568,
//                         34.19589807027772
//                     ],
//                     [
//                         22.914689395829356,
//                         34.21953977635127
//                     ],
//                     [
//                         22.828601694566572,
//                         34.24312141103813
//                     ],
//                     [
//                         22.742465831615903,
//                         34.26664280047963
//                     ],
//                     [
//                         22.656281806977347,
//                         34.290103944675764
//                     ],
//                     [
//                         22.570049729714242,
//                         34.31350467010669
//                     ],
//                     [
//                         22.483769599826587,
//                         34.336844976772404
//                     ],
//                     [
//                         22.397441528789756,
//                         34.360124691500566
//                     ],
//                     [
//                         22.311065516603747,
//                         34.38334381429117
//                     ],
//                     [
//                         22.224641677163447,
//                         34.40650217232809
//                     ],
//                     [
//                         22.138170010468855,
//                         34.42959976561133
//                     ],
//                     [
//                         22.05165063284117,
//                         34.452636421689704
//                     ],
//                     [
//                         21.96508354428039,
//                         34.475612140563236
//                     ],
//                     [
//                         21.905244396655036,
//                         34.49113935718141
//                     ],
//                     [
//                         21.87213318996511,
//                         34.49921807154423
//                     ],
//                     [
//                         21.839015570954928,
//                         34.50728785004816
//                     ],
//                     [
//                         21.805891539624497,
//                         34.515348692693195
//                     ],
//                     [
//                         21.77276110384699,
//                         34.52340059063176
//                     ],
//                     [
//                         21.739624263622403,
//                         34.53144354386386
//                     ],
//                     [
//                         21.706481026873206,
//                         34.53947754355188
//                     ],
//                     [
//                         21.673331393599398,
//                         34.547502589695824
//                     ],
//                     [
//                         21.640175371772898,
//                         34.55551867346816
//                     ],
//                     [
//                         21.607012961393707,
//                         34.56352579486885
//                     ],
//                     [
//                         21.573844170483113,
//                         34.57152394508051
//                     ],
//                     [
//                         21.540668999041117,
//                         34.579513124103116
//                     ],
//                     [
//                         21.507487455138403,
//                         34.587493323129436
//                     ],
//                     [
//                         21.47429953877497,
//                         34.595464542159476
//                     ],
//                     [
//                         21.44110525807099,
//                         34.60342677239626
//                     ],
//                     [
//                         21.40790461302646,
//                         34.611380013839785
//                     ],
//                     [
//                         21.374697611811,
//                         34.61932425770341
//                     ],
//                     [
//                         21.341484254424614,
//                         34.62725950398713
//                     ],
//                     [
//                         21.3082645490865,
//                         34.635185743914704
//                     ],
//                     [
//                         21.27503849579665,
//                         34.64310297748612
//                     ],
//                     [
//                         21.126501744561217,
//                         34.6744562196164
//                     ],
//                     [
//                         20.8626542953802,
//                         34.72924547030554
//                     ],
//                     [
//                         20.527647928092264,
//                         34.79580507173758
//                     ],
//                     [
//                         20.121482642697423,
//                         34.87413502391253
//                     ],
//                     [
//                         19.806226201779644,
//                         34.95290756402227
//                     ],
//                     [
//                         19.581878605338936,
//                         35.03212269206682
//                     ],
//                     [
//                         19.389605458770426,
//                         35.09660122187737
//                     ],
//                     [
//                         19.229406762074117,
//                         35.146343153453934
//                     ],
//                     [
//                         18.99490272939539,
//                         35.21432080082495
//                     ],
//                     [
//                         18.686093360734247,
//                         35.300534163990434
//                     ],
//                     [
//                         18.29967750507822,
//                         35.40296494495477
//                     ],
//                     [
//                         17.835655162427308,
//                         35.52161314371796
//                     ],
//                     [
//                         16.972710575565902,
//                         35.73861205360538
//                     ],
//                     [
//                         15.710843744494005,
//                         36.05396167461703
//                     ],
//                     [
//                         15.023182746718541,
//                         36.22722736384214
//                     ],
//                     [
//                         14.909727582239514,
//                         36.258409121280714
//                     ],
//                     [
//                         14.807952844922623,
//                         36.28388479626518
//                     ],
//                     [
//                         14.71785853476787,
//                         36.30365438879553
//                     ],
//                     [
//                         14.627764224613117,
//                         36.32342398132588
//                     ],
//                     [
//                         14.537669914458366,
//                         36.34319357385624
//                     ],
//                     [
//                         14.307797846327638,
//                         36.41052455833759
//                     ],
//                     [
//                         13.938148020220936,
//                         36.52541693476994
//                     ],
//                     [
//                         13.635684422572872,
//                         36.61292616932663
//                     ],
//                     [
//                         13.40040705338345,
//                         36.67305226200765
//                     ],
//                     [
//                         13.247151276591552,
//                         36.71263648126112
//                     ],
//                     [
//                         13.175917092197185,
//                         36.73167882708704
//                     ],
//                     [
//                         13.118379961917677,
//                         36.747982957405284
//                     ],
//                     [
//                         13.074539885753033,
//                         36.761548872215855
//                     ],
//                     [
//                         13.035951362739612,
//                         36.77468649266378
//                     ],
//                     [
//                         13.002614392877412,
//                         36.787395818749076
//                     ],
//                     [
//                         12.967264969570156,
//                         36.80031230403581
//                     ],
//                     [
//                         12.929903092817844,
//                         36.813435948523995
//                     ],
//                     [
//                         12.884564948012503,
//                         36.828428229877
//                     ],
//                     [
//                         12.831250535154135,
//                         36.84528914809482
//                     ],
//                     [
//                         12.768424227390987,
//                         36.86306862534369
//                     ],
//                     [
//                         12.696086024723057,
//                         36.88176666162363
//                     ],
//                     [
//                         12.609187156562836,
//                         36.90675546283395
//                     ],
//                     [
//                         12.507727622910325,
//                         36.93803502897466
//                     ],
//                     [
//                         12.422113915062788,
//                         36.96614924521535
//                     ],
//                     [
//                         12.352346033020229,
//                         36.99109811155602
//                     ],
//                     [
//                         12.284133503623206,
//                         37.01323320004423
//                     ],
//                     [
//                         12.217476326871726,
//                         37.03255451068
//                     ],
//                     [
//                         12.148316808718265,
//                         37.054131542328946
//                     ],
//                     [
//                         12.07665494916283,
//                         37.07796429499107
//                     ],
//                     [
//                         11.970197699239979,
//                         37.111520919833566
//                     ],
//                     [
//                         11.828945058949715,
//                         37.154801416856415
//                     ],
//                     [
//                         11.657978379170899,
//                         37.230292914072365
//                     ],
//                     [
//                         11.356957300269846,
//                         37.391846660185934
//                     ],
//                     [
//                         11.161749260436897,
//                         37.47033402968325
//                     ],
//                     [
//                         10.98937869785335,
//                         37.50474982372508
//                     ],
//                     [
//                         10.851229226350608,
//                         37.532331772217454
//                     ],
//                     [
//                         10.689843991734156,
//                         37.56455180650764
//                     ],
//                     [
//                         10.52594385446314,
//                         37.597753184084276
//                     ],
//                     [
//                         10.359528814537562,
//                         37.631935904947355
//                     ],
//                     [
//                         10.262729582896942,
//                         37.64898056852603
//                     ],
//                     [
//                         10.221954447863448,
//                         37.648840477967425
//                     ],
//                     [
//                         10.001753882001276,
//                         37.65005046499281
//                     ],
//                     [
//                         9.323466851294116,
//                         37.6235778173417
//                     ],
//                     [
//                         9.066528283139103,
//                         37.61568501640564
//                     ],
//                     [
//                         9.00484250063385,
//                         37.61754784630094
//                     ],
//                     [
//                         8.913093393007127,
//                         37.616136692647345
//                     ],
//                     [
//                         8.791280960258929,
//                         37.611451555444866
//                     ],
//                     [
//                         8.658811482566897,
//                         37.60349563192267
//                     ],
//                     [
//                         8.515684959931027,
//                         37.59226892208074
//                     ],
//                     [
//                         8.346853009566642,
//                         37.578438656723044
//                     ],
//                     [
//                         8.152315631473739,
//                         37.56200483584958
//                     ],
//                     [
//                         7.9894539130799345,
//                         37.547091838549115
//                     ],
//                     [
//                         7.85826785438523,
//                         37.53369966482166
//                     ],
//                     [
//                         7.728839475579672,
//                         37.520807810475965
//                     ],
//                     [
//                         7.601168776663264,
//                         37.50841627551202
//                     ],
//                     [
//                         7.447323879650363,
//                         37.49269936818267
//                     ],
//                     [
//                         7.267304784540971,
//                         37.47365708848791
//                     ],
//                     [
//                         7.081741779593079,
//                         37.455551756755696
//                     ],
//                     [
//                         6.890634864806685,
//                         37.43838337298604
//                     ],
//                     [
//                         6.697629872684866,
//                         37.4193058905497
//                     ],
//                     [
//                         6.5027268032276195,
//                         37.3983193094467
//                     ],
//                     [
//                         6.327695581524777,
//                         37.3825198147494
//                     ],
//                     [
//                         6.172536207576338,
//                         37.371907406457794
//                     ],
//                     [
//                         6.027808173804553,
//                         37.363224832780965
//                     ],
//                     [
//                         5.893511480209419,
//                         37.35647209371889
//                     ],
//                     [
//                         5.736973173181523,
//                         37.34681894640197
//                     ],
//                     [
//                         5.558193252720866,
//                         37.33426539083019
//                     ],
//                     [
//                         5.3740694743667365,
//                         37.32090277864267
//                     ],
//                     [
//                         5.1846018381191366,
//                         37.30673110983942
//                     ],
//                     [
//                         4.929107825561117,
//                         37.286483995008425
//                     ],
//                     [
//                         4.607587436692677,
//                         37.26016143414968
//                     ],
//                     [
//                         4.15496207980736,
//                         37.22055700422949
//                     ],
//                     [
//                         3.5712317549051664,
//                         37.16767070524785
//                     ],
//                     [
//                         3.2154242959355424,
//                         37.133849141280805
//                     ],
//                     [
//                         3.087539702898489,
//                         37.119092312328355
//                     ],
//                     [
//                         2.9605303997403523,
//                         37.10428077687359
//                     ],
//                     [
//                         2.8343963864611332,
//                         37.0894145349165
//                     ],
//                     [
//                         2.701381520660268,
//                         37.07466179787411
//                     ],
//                     [
//                         2.5614858023377556,
//                         37.06002256574641
//                     ],
//                     [
//                         2.433460773473233,
//                         37.04235155572937
//                     ],
//                     [
//                         2.3173064340667002,
//                         37.021648767822995
//                     ],
//                     [
//                         2.2008566249022983,
//                         37.00051038410921
//                     ],
//                     [
//                         2.084111345980028,
//                         36.97893640458804
//                     ],
//                     [
//                         1.9557440089827693,
//                         36.95869858070328
//                     ],
//                     [
//                         1.8157546139105232,
//                         36.93979691245493
//                     ],
//                     [
//                         1.6584733454457516,
//                         36.91541495248038
//                     ],
//                     [
//                         1.4839002035884548,
//                         36.885552700779634
//                     ],
//                     [
//                         1.300768896019119,
//                         36.85675427635459
//                     ],
//                     [
//                         1.1090794227377452,
//                         36.829019679205246
//                     ],
//                     [
//                         0.8011991037366485,
//                         36.785714803169355
//                     ],
//                     [
//                         0.3771279390158294,
//                         36.7268396482469
//                     ],
//                     [
//                         0.06374516864400209,
//                         36.684622646652834
//                     ],
//                     [
//                         -0.13894920737883337,
//                         36.659063798387145
//                     ],
//                     [
//                         -0.3187802132699111,
//                         36.63498345540796
//                     ],
//                     [
//                         -0.47574784902923095,
//                         36.612381617715286
//                     ],
//                     [
//                         -0.6470301783921787,
//                         36.58998437195337
//                     ],
//                     [
//                         -0.8326272013587542,
//                         36.56779171812222
//                     ],
//                     [
//                         -1.2101986517928802,
//                         36.52882379415969
//                     ],
//                     [
//                         -1.779744529694557,
//                         36.47308060006578
//                     ],
//                     [
//                         -2.0882926845136134,
//                         36.443978212886925
//                     ],
//                     [
//                         -2.1596183321182676,
//                         36.44028584249123
//                     ],
//                     [
//                         -2.1940495775568096,
//                         36.42378332560832
//                     ],
//                     [
//                         -2.2563192981424742,
//                         36.4231467762195
//                     ],
//                     [
//                         -2.5001459399851615,
//                         36.39408799426453
//                     ],
//                     [
//                         -2.7664078803729675,
//                         36.36201749855883
//                     ],
//                     [
//                         -3.1363806665867893,
//                         36.31724902845074
//                     ],
//                     [
//                         -3.3963355206571926,
//                         36.28492637331286
//                     ],
//                     [
//                         -3.5462724425841783,
//                         36.26504953314518
//                     ],
//                     [
//                         -3.6760857662642312,
//                         36.24835044023051
//                     ],
//                     [
//                         -3.7857754916973514,
//                         36.23482909456884
//                     ],
//                     [
//                         -4.16314836007958,
//                         36.18482945276993
//                     ],
//                     [
//                         -4.808204371410917,
//                         36.0983515148338
//                     ],
//                     [
//                         -5.148616618662631,
//                         36.05312102731092
//                     ],
//                     [
//                         -5.20226934342077,
//                         36.047146471646485
//                     ],
//                     [
//                         -5.31325629601054,
//                         36.031941449182185
//                     ],
//                     [
//                         -5.367561701072362,
//                         36.02041565897592
//                     ],
//                     [
//                         -5.436927113101063,
//                         36.00327493362699
//                     ],
//                     [
//                         -5.557608221485394,
//                         35.968955393948434
//                     ],
//                     [
//                         -5.622076180204147,
//                         35.95783671498434
//                     ],
//                     [
//                         -5.7470196407155925,
//                         35.95714400373309
//                     ],
//                     [
//                         -5.8785953739439805,
//                         35.95558537607937
//                     ],
//                     [
//                         -5.954408912647453,
//                         35.95542712901873
//                     ],
//                     [
//                         -6.018318834568806,
//                         35.95614971999995
//                     ],
//                     [
//                         -6.060945221730872,
//                         35.956540877268225
//                     ],
//                     [
//                         -6.0929595003350405,
//                         35.95663046260124
//                     ],
//                     [
//                         -6.203739207005757,
//                         35.95689319260642
//                     ],
//                     [
//                         -6.234104415258572,
//                         35.95760083438986
//                     ],
//                     [
//                         -6.257094613507133,
//                         35.96032753623825
//                     ],
//                     [
//                         -6.282831537835711,
//                         35.96530917874608
//                     ],
//                     [
//                         -6.308825000000001,
//                         35.967225
//                     ],
//                     [
//                         -6.335075,
//                         35.966075
//                     ],
//                     [
//                         -6.3687000000000005,
//                         35.980275
//                     ],
//                     [
//                         -6.4097,
//                         36.009825
//                     ],
//                     [
//                         -6.477325,
//                         36.037549999999996
//                     ],
//                     [
//                         -6.571574999999999,
//                         36.06345
//                     ],
//                     [
//                         -6.705575,
//                         36.097625
//                     ],
//                     [
//                         -6.879325,
//                         36.140074999999996
//                     ],
//                     [
//                         -7.042325,
//                         36.18515
//                     ],
//                     [
//                         -7.1945749999999995,
//                         36.23285
//                     ],
//                     [
//                         -7.299049999999999,
//                         36.262675
//                     ],
//                     [
//                         -7.3557500000000005,
//                         36.274625
//                     ],
//                     [
//                         -7.408875,
//                         36.28005
//                     ],
//                     [
//                         -7.458425,
//                         36.278949999999995
//                     ],
//                     [
//                         -7.511075,
//                         36.29005
//                     ],
//                     [
//                         -7.566825,
//                         36.31335
//                     ],
//                     [
//                         -7.684975,
//                         36.3532
//                     ],
//                     [
//                         -7.865525,
//                         36.409600000000005
//                     ],
//                     [
//                         -7.980449999999999,
//                         36.4426
//                     ],
//                     [
//                         -8.02975,
//                         36.452200000000005
//                     ],
//                     [
//                         -8.061347754695172,
//                         36.45924726098806
//                     ],
//                     [
//                         -8.075243264085518,
//                         36.46374178296418
//                     ],
//                     [
//                         -8.0891403842312,
//                         36.46823469379262
//                     ],
//                     [
//                         -8.10303911513222,
//                         36.47272599347339
//                     ],
//                     [
//                         -8.116939456534283,
//                         36.4772156811044
//                     ],
//                     [
//                         -8.13084140843739,
//                         36.48170375668566
//                     ],
//                     [
//                         -8.14474497058518,
//                         36.48619021931514
//                     ],
//                     [
//                         -8.158650142977649,
//                         36.49067506899284
//                     ],
//                     [
//                         -8.172556925356417,
//                         36.49515830481683
//                     ],
//                     [
//                         -8.186465317721485,
//                         36.499639926787104
//                     ],
//                     [
//                         -8.200375319812537,
//                         36.50411993400178
//                     ],
//                     [
//                         -8.214286931629573,
//                         36.50859832646084
//                     ],
//                     [
//                         -8.228200152910176,
//                         36.51307510326252
//                     ],
//                     [
//                         -8.242114983654346,
//                         36.517550264406815
//                     ],
//                     [
//                         -8.256031423597648,
//                         36.52202380899199
//                     ],
//                     [
//                         -8.26994947274008,
//                         36.52649573701807
//                     ],
//                     [
//                         -8.283869130815248,
//                         36.53096604758338
//                     ],
//                     [
//                         -8.29779039782315,
//                         36.53543474068792
//                     ],
//                     [
//                         -8.311713273495325,
//                         36.53990181543015
//                     ],
//                     [
//                         -8.325637757831775,
//                         36.544367271810046
//                     ],
//                     [
//                         -8.362475,
//                         36.548125
//                     ],
//                     [
//                         -8.422225,
//                         36.551175
//                     ],
//                     [
//                         -8.490525,
//                         36.56405
//                     ],
//                     [
//                         -8.567375,
//                         36.58675
//                     ],
//                     [
//                         -8.613386767496445,
//                         36.60034299939322
//                     ],
//                     [
//                         -8.628560302489337,
//                         36.60482899817965
//                     ],
//                     [
//                         -8.64373560198652,
//                         36.60931307293902
//                     ],
//                     [
//                         -8.658912665987998,
//                         36.61379522367131
//                     ],
//                     [
//                         -8.674091493965477,
//                         36.61827544930011
//                     ],
//                     [
//                         -8.689272085918958,
//                         36.6227537498254
//                     ],
//                     [
//                         -8.704454441317324,
//                         36.62723012417099
//                     ],
//                     [
//                         -8.719638560160575,
//                         36.6317045723369
//                     ],
//                     [
//                         -8.734824441914924,
//                         36.63617709324714
//                     ],
//                     [
//                         -8.75001208658037,
//                         36.64064768690173
//                     ],
//                     [
//                         -8.765201493620282,
//                         36.645116352224946
//                     ],
//                     [
//                         -8.780392663034661,
//                         36.6495830892168
//                     ],
//                     [
//                         -8.795585594284177,
//                         36.65404789680179
//                     ],
//                     [
//                         -8.810780287368829,
//                         36.658510774979945
//                     ],
//                     [
//                         -8.825976741746445,
//                         36.66297172267601
//                     ],
//                     [
//                         -8.841174957417024,
//                         36.667430739889994
//                     ],
//                     [
//                         -8.856374933835696,
//                         36.67188782554691
//                     ],
//                     [
//                         -8.871576671002458,
//                         36.676342979646755
//                     ],
//                     [
//                         -8.886780168369626,
//                         36.68079620111479
//                     ],
//                     [
//                         -8.901985425937198,
//                         36.685247489951
//                     ],
//                     [
//                         -8.931349514699804,
//                         36.69243964410148
//                     ],
//                     [
//                         -8.996633894636263,
//                         36.707339173298585
//                     ],
//                     [
//                         -9.170985475175343,
//                         36.745276558268
//                     ],
//                     [
//                         -9.315204741610984,
//                         36.878780301110396
//                     ],
//                     [
//                         -9.348437125670547,
//                         36.97922223598092
//                     ],
//                     [
//                         -9.365337820957638,
//                         37.03456955312237
//                     ],
//                     [
//                         -9.376984288825446,
//                         37.078302897491575
//                     ],
//                     [
//                         -9.396061234582941,
//                         37.17253195877066
//                     ],
//                     [
//                         -9.422568658230121,
//                         37.317256736959635
//                     ],
//                     [
//                         -9.509699566974584,
//                         37.626788780061474
//                     ],
//                     [
//                         -9.65745396081633,
//                         38.10112808807621
//                     ],
//                     [
//                         -9.754400183030304,
//                         38.407664101456525
//                     ],
//                     [
//                         -9.800538233616507,
//                         38.54639682020244
//                     ],
//                     [
//                         -9.832644290968908,
//                         38.64207881710237
//                     ],
//                     [
//                         -9.859755387146805,
//                         38.721025729683305
//                     ],
//                     [
//                         -9.857998523114407,
//                         38.843794390764764
//                     ],
//                     [
//                         -9.849301641223663,
//                         39.28999549389134
//                     ],
//                     [
//                         -9.83307912013044,
//                         40.100551926090205
//                     ],
//                     [
//                         -9.8214212884925,
//                         40.65347879834407
//                     ],
//                     [
//                         -9.814328146309844,
//                         40.94877611065296
//                     ],
//                     [
//                         -9.803428005551998,
//                         41.50418997543867
//                     ],
//                     [
//                         -9.788720866218965,
//                         42.319720392701214
//                     ],
//                     [
//                         -9.782376718483464,
//                         42.75609501134747
//                     ],
//                     [
//                         -9.78540498427651,
//                         42.84192324139241
//                     ],
//                     [
//                         -9.78540498427651,
//                         43.194454147163356
//                     ],
//                     [
//                         -9.636866662430924,
//                         43.39803392908244
//                     ],
//                     [
//                         -9.549946329686701,
//                         43.51097470437532
//                     ],
//                     [
//                         -9.475131212095311,
//                         43.601136400348366
//                     ],
//                     [
//                         -9.319074760737212,
//                         43.81462922723077
//                     ],
//                     [
//                         -9.081776975612403,
//                         44.15145318502251
//                     ],
//                     [
//                         -8.921225226354913,
//                         44.37507154108689
//                     ],
//                     [
//                         -8.837419512964741,
//                         44.485484295423895
//                     ],
//                     [
//                         -8.61137508747586,
//                         44.79726015316064
//                     ],
//                     [
//                         -8.243091949888269,
//                         45.31039911429714
//                     ],
//                     [
//                         -7.874808812300677,
//                         45.82353807543363
//                     ],
//                     [
//                         -7.506525674713085,
//                         46.33667703657014
//                     ],
//                     [
//                         -7.220816358035983,
//                         46.71704550566001
//                     ],
//                     [
//                         -7.017680862269371,
//                         46.96464348270328
//                     ],
//                     [
//                         -6.784734423787725,
//                         47.26509406736646
//                     ],
//                     [
//                         -6.521977042591047,
//                         47.61839725964957
//                     ],
//                     [
//                         -6.354573763994531,
//                         47.83988664184334
//                     ],
//                     [
//                         -6.282524587998177,
//                         47.92956221394778
//                     ],
//                     [
//                         -6.175075,
//                         48.071075
//                     ],
//                     [
//                         -6.032225,
//                         48.264425
//                     ],
//                     [
//                         -5.9434249999999995,
//                         48.37475
//                     ],
//                     [
//                         -5.908675000000001,
//                         48.40205
//                     ],
//                     [
//                         -5.88675,
//                         48.422
//                     ],
//                     [
//                         -5.87765,
//                         48.4346
//                     ],
//                     [
//                         -5.8607499999999995,
//                         48.454375
//                     ],
//                     [
//                         -5.83605,
//                         48.481325
//                     ],
//                     [
//                         -5.809656648203222,
//                         48.516305311467555
//                     ],
//                     [
//                         -5.781569944609666,
//                         48.55931593440267
//                     ],
//                     [
//                         -5.726896534571802,
//                         48.625927586368405
//                     ],
//                     [
//                         -5.605006359848543,
//                         48.761246607862944
//                     ],
//                     [
//                         -4.433454747551307,
//                         49.216526744021984
//                     ],
//                     [
//                         -3.7431184729741136,
//                         49.48248112434755
//                     ],
//                     [
//                         -2.8436612615397663,
//                         49.82506412916522
//                     ],
//                     [
//                         -2.3889941873101175,
//                         49.90349425226527
//                     ],
//                     [
//                         -1.8951302794081486,
//                         49.98156482913126
//                     ],
//                     [
//                         -1.4754748628601069,
//                         50.0531739146663
//                     ],
//                     [
//                         -0.9654066350320023,
//                         50.14434503449242
//                     ],
//                     [
//                         -0.5495175495810194,
//                         50.21801475777589
//                     ],
//                     [
//                         -0.22780760650715792,
//                         50.27418308451674
//                     ],
//                     [
//                         0.04633858661909766,
//                         50.32305503284047
//                     ],
//                     [
//                         0.3862122513870724,
//                         50.38541838770039
//                     ],
//                     [
//                         0.47253568476440444,
//                         50.40243780295372
//                     ],
//                     [
//                         0.5067370241478619,
//                         50.408828096042456
//                     ],
//                     [
//                         0.5729,
//                         50.4225
//                     ],
//                     [
//                         0.6145384466557706,
//                         50.42872527725953
//                     ],
//                     [
//                         0.7647726424575273,
//                         50.457597071992794
//                     ],
//                     [
//                         0.812156037313929,
//                         50.46474297105633
//                     ],
//                     [
//                         0.861,
//                         50.4721
//                     ],
//                     [
//                         0.9104,
//                         50.4846
//                     ],
//                     [
//                         0.9664804483415,
//                         50.49484618777237
//                     ],
//                     [
//                         1.0284861391426847,
//                         50.52225233506476
//                     ],
//                     [
//                         1.0500037174906192,
//                         50.534815971428
//                     ],
//                     [
//                         1.1389406040322048,
//                         50.581503431802005
//                     ],
//                     [
//                         1.2273783338705995,
//                         50.63150002622297
//                     ],
//                     [
//                         1.2839039392786953,
//                         50.65843867697325
//                     ],
//                     [
//                         1.3558,
//                         50.7072
//                     ],
//                     [
//                         1.4552,
//                         50.9118
//                     ],
//                     [
//                         1.5143097626982471,
//                         50.95849717205491
//                     ],
//                     [
//                         1.6107170389701677,
//                         51.0228913905645
//                     ],
//                     [
//                         1.6469448847308519,
//                         51.04578817305556
//                     ],
//                     [
//                         1.6766632347800594,
//                         51.06517090887895
//                     ],
//                     [
//                         1.6990806960047526,
//                         51.08009036607848
//                     ],
//                     [
//                         1.731799073239813,
//                         51.10485108719786
//                     ],
//                     [
//                         1.8188948786916797,
//                         51.1372159903018
//                     ],
//                     [
//                         2.031638046973336,
//                         51.202505490665935
//                     ],
//                     [
//                         2.1092716955687396,
//                         51.2493406718224
//                     ],
//                     [
//                         2.16651729842215,
//                         51.263634799349774
//                     ],
//                     [
//                         2.265626255130826,
//                         51.28916379946011
//                     ],
//                     [
//                         2.4005476919977013,
//                         51.340179248325626
//                     ],
//                     [
//                         2.4838325295687866,
//                         51.36202564868486
//                     ],
//                     [
//                         2.6039300091214486,
//                         51.36954368695568
//                     ],
//                     [
//                         2.670610669726294,
//                         51.36968545433208
//                     ],
//                     [
//                         2.7239070045675433,
//                         51.364956963571
//                     ],
//                     [
//                         2.7921383760412155,
//                         51.36460214899434
//                     ],
//                     [
//                         2.8753047841473105,
//                         51.3686210106021
//                     ],
//                     [
//                         2.9493409911502684,
//                         51.37977283105448
//                     ],
//                     [
//                         3.0142469970500896,
//                         51.3980576103515
//                     ],
//                     [
//                         3.0580249999999998,
//                         51.406225000000006
//                     ],
//                     [
//                         3.0806750000000003,
//                         51.404275
//                     ],
//                     [
//                         3.1084647298975696,
//                         51.40346945801476
//                     ],
//                     [
//                         3.141394189692709,
//                         51.403808374044274
//                     ],
//                     [
//                         3.179994189692709,
//                         51.404933374044276
//                     ],
//                     [
//                         3.2242647298975697,
//                         51.40684445801476
//                     ],
//                     [
//                         3.2692,
//                         51.407700000000006
//                     ],
//                     [
//                         3.3148,
//                         51.4075
//                     ],
//                     [
//                         3.3624045663108335,
//                         51.40858797122845
//                     ],
//                     [
//                         3.412013698932501,
//                         51.41096391368534
//                     ],
//                     [
//                         3.456801257506016,
//                         51.41442423468855
//                     ],
//                     [
//                         3.496767242031379,
//                         51.418968934238094
//                     ],
//                     [
//                         3.5264151087488145,
//                         51.418207571103856
//                     ],
//                     [
//                         3.545744857658323,
//                         51.41214014528586
//                     ],
//                     [
//                         3.559227657726481,
//                         51.40739262420311
//                     ],
//                     [
//                         3.56686350895329,
//                         51.40396500785562
//                     ],
//                     [
//                         3.5748327846353973,
//                         51.39983145804041
//                     ],
//                     [
//                         3.5831354847728036,
//                         51.39499197475749
//                     ],
//                     [
//                         3.594905634010317,
//                         51.3906389139316
//                     ],
//                     [
//                         3.610143232347937,
//                         51.386772275562755
//                     ],
//                     [
//                         3.6242427527994394,
//                         51.38535190963966
//                     ],
//                     [
//                         3.6372041953648235,
//                         51.386377816162316
//                     ],
//                     [
//                         3.653010832640625,
//                         51.38509533238738
//                     ],
//                     [
//                         3.6716626646268438,
//                         51.38150445831485
//                     ],
//                     [
//                         3.6868054231365193,
//                         51.375130368488854
//                     ],
//                     [
//                         3.6984391081696515,
//                         51.36597306290939
//                     ],
//                     [
//                         3.7128227178162874,
//                         51.36273833354745
//                     ],
//                     [
//                         3.729956252076427,
//                         51.36542618040303
//                     ],
//                     [
//                         3.7461633183729974,
//                         51.36471141776141
//                     ],
//                     [
//                         3.761443916705998,
//                         51.36059404562261
//                     ],
//                     [
//                         3.7798944297853643,
//                         51.35598181157509
//                     ],
//                     [
//                         3.8015148576110955,
//                         51.35087471561884
//                     ],
//                     [
//                         3.819336753805255,
//                         51.346572882644985
//                     ],
//                     [
//                         3.8333601183678434,
//                         51.34307631265351
//                     ],
//                     [
//                         3.8651065047096793,
//                         51.34973224914544
//                     ],
//                     [
//                         3.914575912830763,
//                         51.366540692120786
//                     ],
//                     [
//                         3.949424332136446,
//                         51.39051661214032
//                     ],
//                     [
//                         3.969651762626728,
//                         51.42166000920403
//                     ],
//                     [
//                         3.9869619299965313,
//                         51.43702633399277
//                     ],
//                     [
//                         4.001354834245856,
//                         51.43661558650653
//                     ],
//                     [
//                         4.014520840472957,
//                         51.43329844106935
//                     ],
//                     [
//                         4.026459948677836,
//                         51.42707489768123
//                     ],
//                     [
//                         4.03352073972448,
//                         51.412843805475426
//                     ],
//                     [
//                         4.035703213612891,
//                         51.390605164451934
//                     ],
//                     [
//                         4.052799259068024,
//                         51.376306908912845
//                     ],
//                     [
//                         4.08480887608988,
//                         51.36994903885816
//                     ],
//                     [
//                         4.1229353206232275,
//                         51.374068957092504
//                     ],
//                     [
//                         4.1671785926680665,
//                         51.38866666361589
//                     ],
//                     [
//                         4.194095770620977,
//                         51.39343473507209
//                     ],
//                     [
//                         4.203686854481958,
//                         51.388373171461126
//                     ],
//                     [
//                         4.212483598541169,
//                         51.37948451957879
//                     ],
//                     [
//                         4.220486002798609,
//                         51.366768779425094
//                     ],
//                     [
//                         4.232107053463231,
//                         51.35740018278052
//                     ],
//                     [
//                         4.247346750535037,
//                         51.351378729645084
//                     ],
//                     [
//                         4.25723423032313,
//                         51.34745001274604
//                     ],
//                     [
//                         4.261769492827508,
//                         51.34561403208338
//                     ],
//                     [
//                         4.265182060235407,
//                         51.344821702395606
//                     ],
//                     [
//                         4.267471932546828,
//                         51.3450730236827
//                     ],
//                     [
//                         4.276479508165336,
//                         51.34465414191731
//                     ],
//                     [
//                         4.292204787090931,
//                         51.34356505709944
//                     ],
//                     [
//                         4.301491673870672,
//                         51.33930651796657
//                     ],
//                     [
//                         4.304340168504558,
//                         51.3318785245187
//                     ],
//                     [
//                         4.306851505168346,
//                         51.326929411254596
//                     ],
//                     [
//                         4.309025683862039,
//                         51.32445917817425
//                     ],
//                     [
//                         4.310606904728608,
//                         51.32220499032003
//                     ],
//                     [
//                         4.311595167768054,
//                         51.32016684769194
//                     ],
//                     [
//                         4.311932099465833,
//                         51.31498333228342
//                     ],
//                     [
//                         4.3114605,
//                         51.30249
//                     ]
//                 ]
//             }
//         }
//     ],
//     "properties": {
//         "distance": 19674361,
//         "mode": "sea",
//         "departure": 1697631975501,
//         "arrival": 1699339591501,
//         "duration": 1707616000,
//         "speed": 41.478,
//         "areas": {
//             "type": "FeatureCollection",
//             "features": [
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11158,
//                         "name": "Gela Canal",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             11.9276,
//                             37.2765
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11146,
//                         "name": "North of Sabang",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             94.68081647283823,
//                             6.324861732860867
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11100,
//                         "name": "Gibraltar",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             -5.7470196407155925,
//                             35.95714400373309
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11117,
//                         "name": "Suez Canal",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             32.35402374270163,
//                             30.4438838927622
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11133,
//                         "name": "Dover Strait",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             1.5143097626982471,
//                             50.95849717205491
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11150,
//                         "name": "Taiwan Strait",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             119.18853847770525,
//                             24.334355579669797
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11135,
//                         "name": "Malacca Strait",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             99.90993939997549,
//                             3.7768863793412635
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 21120,
//                         "name": "North Sea SECA",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             4.1835169836905335,
//                             55.863787276691106
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11137,
//                         "name": "Singapore Strait",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             103.90174796429032,
//                             1.2059581075620434
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 21089,
//                         "name": "High Risk Area",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             49.2132660404583,
//                             5.8841119134226
//                         ]
//                     }
//                 },
//                 {
//                     "type": "Feature",
//                     "properties": {
//                         "id": 11106,
//                         "name": "South Sri Lanka",
//                         "alternatives": []
//                     },
//                     "geometry": {
//                         "type": "Point",
//                         "coordinates": [
//                             80.925,
//                             5.5787
//                         ]
//                     }
//                 }
//             ],
//             "properties": null
//         },
//         "details": [],
//         "secaIntersection": 760450,
//         "hraIntersection": 2194590,
//         "speedInKts": 22.396,
//         "intersectsIceArea": false,
//         "vessel": {
//             "imo": 8677225,
//             "name": "KSL PRIDE",
//             "length": 83.0,
//             "width": 15.0,
//             "maxDraft": 4.2,
//             "draft": 4.2
//         }
//     }
// };
// console.log(response.features[0].geometry.coordinates);
 
  const [start, setStart] = useState({});
  const [end, setEnd] = useState({});
  const [way, setWay] = useState([]);
  const [directionResponse, setDirectionResponse] = useState(null);
  const route = "SEA";
  const waypoints = [];

  function addCordinates () {
    if (Data && Data.length > 0) {
      
      Data.forEach((voyage) => {
        voyage.voyage_details.forEach((details) => {
          console.log("gsggcckjsdfkjsfkjhkjdf",details)
          const { lat, lon, port_name } = details;
  
          const waypoint = {
            location: {
              lat,
              lng: lon,
            },
            title : port_name,
          };
  
          waypoints.push(waypoint);
        });
      });
  
      if (waypoints.length > 0) {
        setStart(waypoints[0].location);
        setEnd(waypoints[waypoints.length - 1].location);
      }
      const origin = waypoints[0].location;
      const destination = waypoints[waypoints.length - 1].location;
      setWay(waypoints);
      
      apifetch(origin,destination);
    }
  };
  console.log("datttttttttttttttttttttttttttttttttttttttttttttttttttttttatttatttata",Data,start,end,way);

  function apifetch(origin,destination){
    if(destination && origin){
      
      console.log("sttttttttttttttttttttttarttttt",origin.lng,origin.lat,destination.lng,destination.lat);

      //For api testing only (Limited calls left)
      
      const options = {
        method: 'GET',
        headers: {
          accept: 'application/json',
          'x-api-key': 'by9nhCJIHa2DUS7rBHUq14eSVkJFCZuY6fK0FD0t'
        }
      };
      
      fetch(`https://api.searoutes.com/route/v2/sea/${origin.lng},${origin.lat};${destination.lng},${destination.lat}?continuousCoordinates=true&allowIceAreas=false&avoidHRA=false&avoidSeca=false`, options)
        .then(response => response.json())
        .then(response =>  calculateRoute(response))
        .catch(err => console.error(err));

      //TODO for local testing
      // calculateRoute(response);
    }
};

  const [polylineCoordinates, setPolylineCoordinates] = useState([]);

    async function calculateRoute (response) {

    if(response && route === "SEA"){
      const coordinates = [];
      response.features[0].geometry.coordinates.map((item) => coordinates.push({lat:item[1],lng:item[0]}));
      setPolylineCoordinates(coordinates);
      console.log("cordinattttttttttttttttttesss",coordinates)

    }
    if(route === "AIR"){
      const coordinates = [];
      waypoints.map((item) => coordinates.push(item.location));
      setPolylineCoordinates(coordinates);
      console.log("cordinattttttttttttttttttesss",coordinates)
    }
    if(route === "LAND"){
     if(window.google){
      const directionService = new window.google.maps.DirectionsService();
      const origin = { lat: 40.756795, lng: -73.954298 };
      const destination = { lat: 41.756795, lng: -78.954298 };
      const waypoints = [
        {
          location: { lat: 40.5, lng: -74.0 },
          stopover: true, 
        },
        {
          location: { lat: 41.0, lng: -75.0 },
          stopover: true,
        },
       
      ];
      const result = await directionService.route({
        origin : origin,
        destination : destination,
        waypoints : waypoints,
        travelMode : window.google.maps.TravelMode.DRIVING,
      });
      setDirectionResponse(result);
    } 
    }
   };

  const mapContainerStyle = {
    width: "100%",
    height: "500px",
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey:"AIzaSyAcEYwMaeeG5915zeZi_hynHg7rTRyOMeU",
  });

  useEffect(() => {
    if (isLoaded) {
      addCordinates(); 
    }
  }, [isLoaded]);

  return (
    <div>
      { ( route === "SEA" && isLoaded) && ( 
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={start || { lat: 0, lng: 0 }} 
            zoom={2}
          >

            {way.map((waypoint, index) => (
              <Marker key={index} position={waypoint.location} title={waypoint.title}/>
            ))}

            {polylineCoordinates.length > 0 && (
              <Polyline
                 path={polylineCoordinates}
                 geodesic= {true}
                 options={{
                  strokeColor: "#ff2527",
                  strokeOpacity: 0.75,
                  strokeWeight: 2,
                  icons: [
                      {
                          offset: "0",
                          repeat: "20px"
                      }
                  ]
                  }}
              
               />

            )}


          </GoogleMap>)
      }
      { (route === "AIR" && isLoaded )&& ( 
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={start || { lat: 0, lng: 0 }} 
            zoom={2}
          >

            {way.map((waypoint, index) => (
              <Marker key={index} position={waypoint.location} title={waypoint.title}/>
            ))}

            {polylineCoordinates.length > 0 && (
              <Polyline
                 path={polylineCoordinates}
                 geodesic= {true}
                 options={{
                  strokeColor: "#ff2527",
                  strokeOpacity: 0.75,
                  strokeWeight: 2,
                  icons: [
                      {
                          offset: "0",
                          repeat: "20px"
                      }
                  ]
                  }}
              
               />

            )}


          </GoogleMap>)
      }
      { (route === "LAND" && isLoaded )&& ( 
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={start || { lat: 0, lng: 0 }} 
            zoom={2}
          >
           {directionResponse && <DirectionsRenderer directions={directionResponse} />}
        </GoogleMap>)
      }
    </div>
  );
});

export default DirectionsMap;
