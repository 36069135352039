import React from "react";
import TextField from "@mui/material/TextField";
import styles from "./Fields.module.css";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgb(66, 165, 245)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(66, 165, 245)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&:hover fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
  },
});

export default ({
  name,
  placeholder,
  required,
  handleChange,
  value,
  rows,
  readOnly,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <CssTextField
        type="text"
        name={name}
        multiline
        rows={rows ? rows : 6}
        maxRows={8}
        required={required}
        className={styles.inputStyle}
        label={placeholder}
        onChange={handleChange}
        value={value}
        inputProps={{ readOnly: readOnly }}
        autoComplete="off"
        style={{ color: "rgb(66, 165, 245)" }}
        InputLabelProps={{
          style: { color: "rgb(66, 165, 245)" },
        }}
      />
    </div>
  );
};

// import React from "react";

// export default ({ name, placeholder, required, _handleChange, readOnly}) => {
//   return (
//     <div>
//       <textarea
//         readOnly={readOnly}
//         type="text"
//         name={name}
//         style={{
//           height: "80px",
//         }}
//         required={required}
//         placeholder={placeholder}
//         onChange={_handleChange}
//         autoComplete="off"
//       />
//     </div>
//   );
// };
