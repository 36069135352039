import { useState, useEffect, useCallback } from "react";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import classes from "../../invoice.module.css";
import { BsBell } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { RiWechatLine } from "react-icons/ri";
import { FinanceDealslistHeader } from "utils/Section/InvoiceSection";
import { truncateStringID } from "utils/helper";
import { FindCompanyName } from "utils/helper";

const Summary = ({ Data, companyList, hanndleCraateGroup }) => {
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();

  const fetchData = async (companyId, id2, name) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        setMemberList(nData);
        hanndleCraateGroup(nData, name, name);
      });
  };

  const chatHandler = (data) => {
    if (data?.financerMemberUID !== "" && data?.traderMemberUID !== "") {
      setItem(data);
      let name = data.financeDealId
        ? truncateStringID(data.financeDealId, 7, "FD")
        : "NA";
      fetchData(data?.financerMemberUID, data?.traderMemberUID, name);
    }
  };

  return (
    <Table bordered responsive className={cn(classes.Table)}>
      <thead>
        <tr className="align-items-center">
          {FinanceDealslistHeader &&
            FinanceDealslistHeader.map((header, i) => (
              <td key={i}>{header.name}</td>
            ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <span className="mx-3">
              <BsBell size={22} />
            </span>
            {Data.financeDealId
              ? truncateStringID(Data.financeDealId, 7, "FD")
              : "NA"}
          </td>
          <td>
            {Data.refFinanceRequisitionId
              ? truncateStringID(Data.refFinanceRequisitionId, 7, "RQ")
              : "NA"}
          </td>
          <td>{Data.financerName}</td>
          <td>
            {companyList && FindCompanyName(companyList, Data.traderMemberUID)}
          </td>
          <td>{Data.financeProductCategory}</td>
          <td>{Data.financeDealStatus}</td>
          <td>{new Date(Data.lastUpdatedDate).toLocaleDateString()}</td>
          <td>
            <span className="mx-3"
              style={{ cursor: "pointer" }}>
              <FiMail size={22} />
            </span>
            <span onClick={() => chatHandler(Data)}
              style={{ cursor: "pointer" }}>
              <RiWechatLine size={22} />
            </span>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default Summary;
