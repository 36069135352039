import React, { useState, useContext, useRef } from "react";
import Style from "./styled";
import FirstLevelFlow from "./firstLevelFlow";
import Details from "./details";
import "./tradeworkflow.module.css";
import { useEffect } from "react";
import TradeOverview from "./tradeOverview";
import { useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { storeContext } from "../../../contexts/Store/Store";
import Error from "components/Error/error";
import { tradeURL, TRACKING } from "services/BaseURLInstance";
import SingleToogleTab from "components/UI/ToogleSelect/SingleToogleTab";
import TradeDocuments from "pages/Document/TradeDocuments";
import SideNav from "./tradeSideNav";
import ShipmentSummary from "../../financing/Tracking/shipmentSummary";
import { GlobalParticipant } from "./globalPraticipant";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { ToTitleCase } from "common/utils/toTitleText";
import Loader from "components/Loader/Loader";
import DocumentFinder from "components/documentFinder/documentFinder";
import { FaHandsHelping } from "react-icons/fa";

export default ({ hanndleCraateGroup }) => {
  const { id } = useParams();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const node = JSON.parse(sessionStorage.getItem("test"));
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const { fetchData, fetchCompanyList } = useContext(storeContext);
  const [initialState, setInitialState] = useState(1);
  const [tradeCompleteData, setTradeCompleteData] = useState();
  const [flowStage, setFlowStage] = useState();
  const [secondFlowSequence, setSecondFlowSequence] = useState(1);
  const [stageData, setStageData] = useState();
  const [selectedItem, setSelectedItem] = useState(
    node === "2" || node === 2 ? "overview" : "shipments"
  );
  const [collepseNav, setCollepseNav] = useState(true);
  const refContainer = useRef();
  const [dimensions, setDimensions] = useState({ width: 0 });
  const [globalParticipantMemberId, setGlobalParticipantMemberId] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [detailData, setDetailData] = useState(null);
  const [participantList, setParticipantList] = useState(null);

  useEffect(() => {
    let URL = `${tradeURL}trades/${id}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response)
      .then((data) => {
        if (data.code || data.message) {
          Error("Something Went Wrong");
        } else {
          setTradeCompleteData(data);
        }
      })
      .catch((error) => {});
  })}, []);

  useEffect(() => {
    if (refContainer.current) {
      setDimensions({
        width: refContainer.current.offsetWidth,
      });
    }
  }, []);

  useEffect(() => {
    if (tradeCompleteData) {
      (() => {
        const temp = tradeCompleteData.tradeLifeCycle.filter((item) => {
          return item.stageSequence === initialState.toString();
        });
        temp.sort((a, b) => a.subStageSequence - b.subStageSequence);
        setStageData(temp);
        setFlowStage(temp[0].tradeStage);
        setSecondFlowSequence(1);
      })();
    }
  }, [initialState, tradeCompleteData]);

  const handleSecondFlow = (data) => {
    setInitialState(data);
  };

  const handleSecondFlowSequence = (value) => {
    setSecondFlowSequence(value);
  };

  const [dropDown, setDropDown] = useState(true);

  const handleDrop = () => {
    if (dropDown) {
      setDropDown(false);
    } else {
      setDropDown(true);
    }
  };

  useEffect(() => {
    if (tradeCompleteData) {
      const getGlobalParticipantData = async () => {
        let URL = `${tradeURL}trades-participants?tradeId=${tradeCompleteData.tradeId}`;
        fetchCompanyList(URL, idToken)
          .then((data) => {
            if (data.code || data.message) {
              Error("Global Participant API is failing");
            } else {
              let total = data.length;

              let temp = [];
              let OBJ = [];
              data.forEach((item) => {
                temp.push(item.participant);
                OBJ.push({
                  participant: item.participant,
                  role: item.role,
                });
              });
              setParticipantList({
                list: OBJ,
                total: total,
              });
              setGlobalParticipantMemberId(temp);
            }
          })
          .catch((error) => {});
      };
      getGlobalParticipantData();
    }
  }, [tradeCompleteData]);

  const handleSelectedItem = (item) => {
    setSelectedItem();
    setSelectedItem(item);
  };
  const handleCollepseNav = (value) => {
    setCollepseNav(value);
  };

  useEffect(() => {
    // let URL = `${TRACKING}shipment/details?carrierNo=OOLU&memberUID=${companyId}`;
    let URL = `${TRACKING}shipment/details?carrierNo=${process.env.REACT_APP_TRACKING_TEST_CARRIER_NO}&memberUID=${process.env.REACT_APP_TRACKING_TEST_MEMEBERID}`;

    // Use the below URL for testing purposes on UAT and integration testing
    // let URL = `${TRACKING}shipment/details?carrierNo=${data.carrierNo}&memberUID=${companyId}`;

    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message);
          } else {
            let { tracking_data } = data;
            setDetailData(tracking_data);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  }, []);

  return (
    <>
      {tradeCompleteData && stageData ? (
        <div
          style={{
            backgroundColor: "#1e1e1e",
            padding: "1%",
            display: "flex",
            width: "100%",
            height: "100vh",
          }}>
          <div
            style={
              collepseNav
                ? { width: "5%", marginRight: "1%" }
                : { width: "15%", marginRight: "1%" }
            }>
            <SideNav
              globalParticipantMemberId={globalParticipantMemberId}
              handleSelectedItem={handleSelectedItem}
              handleCollepseNav={handleCollepseNav}
              selectedItem={selectedItem}
              hanndleCraateGroup={hanndleCraateGroup}
            />
          </div>
          <div
            ref={refContainer}
            style={
              collepseNav
                ? { width: "95%", overflow: "auto" }
                : { width: "85%", overflow: "auto" }
            }>
            <Style>
              <div style={{ marginTop: "15px" }}>
                <div>
                  <div style={{ marginTop: "-10px" }}>
                    {selectedItem === "overview" && (
                      <SingleToogleTab
                        title="Trade Overview"
                        icon={<FaHandsHelping size={26} />}
                        select={true}
                        Component={
                          <TradeOverview
                            data={tradeCompleteData}
                            setSelectedItem={setSelectedItem}
                            participantList={participantList}
                          />
                        }
                      />
                    )}
                  </div>

                  <div style={{ marginTop: "-10px" }}>
                    {selectedItem !== "overview" && (
                      <SingleToogleTab
                        title="Trade Overview"
                        icon={<FaHandsHelping size={26} />}
                        select={false}
                        Component={
                          <TradeOverview
                            data={tradeCompleteData}
                            setSelectedItem={setSelectedItem}
                            participantList={participantList}
                          />
                        }
                      />
                    )}
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    {selectedItem === "shipmentSummary" && (
                      <SingleToogleTab
                        title="Shipment Details"
                        select={true}
                        Component={
                          <ShipmentSummary
                            response={detailData}
                            location="TradeSection"
                          />
                        }
                      />
                    )}
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    {selectedItem === "lifeCycle" ? (
                      <SingleToogleTab
                        title="Trade Workflow"
                        select={true}
                        Component={
                          <FirstLevelFlow
                            collepseNav={collepseNav}
                            width={dimensions.width}
                            secondFlowSequence={secondFlowSequence}
                            handleSecondFlowSequence={handleSecondFlowSequence}
                            handleSecondFlow={handleSecondFlow}
                            Data={tradeCompleteData}
                            initialState={initialState}
                          />
                        }
                      />
                    ) : (
                      selectedItem === "shipments" && (
                        <SingleToogleTab
                          title="Trade Workflow"
                          select={false}
                          Component={
                            <FirstLevelFlow
                              collepseNav={collepseNav}
                              width={dimensions.width}
                              secondFlowSequence={secondFlowSequence}
                              handleSecondFlowSequence={
                                handleSecondFlowSequence
                              }
                              handleSecondFlow={handleSecondFlow}
                              Data={tradeCompleteData}
                              initialState={initialState}
                            />
                          }
                        />
                      )
                    )}
                  </div>
                  <div>
                    <div style={{ marginTop: "10px" }}>
                      {(selectedItem === "lifeCycle" ||
                        selectedItem === "shipments") && (
                        <SingleToogleTab
                          plannedEndDate={
                            stageData[secondFlowSequence]?.plannedEndDate
                              ? stageData[secondFlowSequence]?.plannedEndDate
                              : new Date().toLocaleDateString()
                          }
                          title={`Summary: ${
                            stageData &&
                            ToTitleCase(
                              stageData[secondFlowSequence - 1].tradeSubStage
                            )
                          }`}
                          select={true}
                          Component={
                            <Details
                              flowStage={flowStage}
                              secondFlowSequence={secondFlowSequence}
                              initialState={initialState}
                              stageData={stageData}
                              Data={tradeCompleteData}
                              hanndleCraateGroup={hanndleCraateGroup}
                            />
                          }
                        />
                      )}
                    </div>
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    {selectedItem === "documents" && (
                      <SingleToogleTab
                        title="Trade Documents"
                        select={true}
                        Component={
                          <TradeDocuments
                            State={{
                              location: "TRADE",
                              Data: tradeCompleteData,
                            }}
                          />
                        }
                      />
                    )}
                  </div>

                  <div style={{ marginTop: "10px" }}>
                    {selectedItem === "globalParticipants" && (
                      <SingleToogleTab
                        title="Trade Participants"
                        select={true}
                        Component={
                          <GlobalParticipant
                            location={"TRADE"}
                            Data={tradeCompleteData}
                            stageData={stageData}
                          />
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            </Style>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
