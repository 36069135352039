import styled from "styled-components";

const Screen= styled.div`
  width: 1366px;
  height: 1289px;
  blend: pass through;
  background: linear-gradient(180deg, #218EEB 0%, rgba(33, 142, 235, 0.76) 100%);
  .header{
    width: 270px;
    height: 59px;
    padding-top: 71px;
    margin-left: 548px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 50px;
    line-height: 59px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
  }
  .subHeader{
    width: 78px;
    height: 35px;
    margin-top: 10px;
    margin-left: 645px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 30px;
    line-height: 35px;
    line-height: 100%;
    align: Left;
    vertical align: Top;
  }
  .verifyPhoneHeading{
    width: 506px;
    height: 59px;
    margin-top: 93px;
    margin-left: 250px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 50px;
    line-height: 59px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
  }
  .horizontalLine{
    width: 866px;
    margin-top: 9px;
    margin-left: 250px;
    align:center
    hr{
        width:3px
    }
  }
  .email{
    display: flex;  
    width: 526px;
    height: 59px;
    margin-top: 61px;
    margin-left: 250px;
    background: Solid #E6E6E6;
  }
  .email> label{
    display: flex;  
    align-items: flex-end;
  }

  .email> input{ 
    border: none;
    color:#E6E6E6;
    width: 526px;
    height:19px;
    padding-top:43px;
    margin-left:14px;
    background: transparent;
    flex-wrap: wrap;
    ::placeholder { 
       color: white;
       opacity: 1;  
    }
   
  }
  .email>input:focus {
    outline: none;
  }
  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color:#E6E6E6 !important;
}
  .nextbutton{
    width: 867px;
    height: 85px;
    margin-left: 250px;
    margin-top: 76px;
    background: #FFFFFF;
    border-radius: 10px

  }
`;

export default Screen;
