import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #1e1e1e;
  border-radius: 10px;
  margin: 9px 16px 10px 0px;
  height: 156.55px;
  width: 219px;
  background: #1e1e1e;
  border-radius: 10px;
  .content {
    padding: 3px 0;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    flex: 1 1 auto;
    div {
      font-size: 67px;

      
    }
    span {
      margin-top: -12px;
      /* border:1px solid green; */

    }
  }
  .content_icons{
    color: #42a5f5;
  }
`;

export default Wrapper;
