import React, { useState, useEffect, useContext } from "react";
import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import styles from "../invoice.module.css";
import FinanceTable from "./FinanceTable";
import Content from "components/UI/Layout/Content";
import { getClaim } from "utils/authUtil";
import { SendBirdProvider as SBProvider } from "sendbird-uikit";
import { invoiceURL, profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import {
  FinanceCategory,
  // FinanceDealStatus,
} from "utils/Section/InvoiceSection";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import MultiSelect from "components/fields/MultiSelect";
import DateInput from "components/fields/DateInput";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { FinanceContext } from "../financeProvider";
import { Suspense } from "react";
import { FindCompanyName } from "utils/helper";

const FinanceDeal = ({ hanndleCraateGroup, role }) => {
  const { instance, accounts } = useMsal();
  const { companyList } = useContext(FinanceContext);
  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  const CompanyObject = companyList;
  let financerMemberUID = "";
  let traderMemberUID = "";
  const invoiceId = "";

  const [response, setResponse] = useState(null);
  const [filterData, setFilterData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [searchFinanceRole, setsearchFinanceRole] = useState(null);

  const [selectStatus, setSelectStatus] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [selectFinanceType, setFinanceType] = useState([]);
  const [selectedFinanceTypeId, setFinanceTypeId] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [FinanceDealStatus, setFinanceStatus] = useState([]);

  const [FinancerOptions, setFinancerOptions] = useState([]);
  const [selectedFinancer, setSelectedFinancer] = useState([]);
  const [selectedFinancerId, setSelectedFinancerId] = useState([]);

  const [BorrowerOptions, setBorrowerOptions] = useState([]);
  const [selectedBorrower, setSelectedBorrower] = useState([]);
  const [selectedBorrowerId, setSelectedBorrowerId] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && !response) {
      setIsLoading(true);
      if (memberType === "TRADER") {
        traderMemberUID = companyId;
      } else {
        financerMemberUID = companyId;
      }
      let URL = `${process.env.REACT_APP_FINANCE_URL}finance/deals?traderMemberUID=${traderMemberUID}&financerMemberUID=${financerMemberUID}&invoiceId=${invoiceId}`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            data.sort(
              (a, b) =>
                Date.parse(b.lastUpdatedDate) - Date.parse(a.lastUpdatedDate)
            );

            // STATUS
            const uniqueStatus = [
              ...new Set(data.map((item) => item.financeDealStatus)),
            ];
            let filterStatus =
              uniqueStatus &&
              uniqueStatus.map((item) => {
                return { label: item, value: item };
              });
            setFinanceStatus(filterStatus);

            // FinancerName
            const uniqueFinancer = [
              ...new Set(data.map((item) => item.financerName)),
            ];
            let filterFinancer =
              uniqueFinancer &&
              uniqueFinancer.map((item) => {
                return { label: item, value: item };
              });
            setFinancerOptions(filterFinancer);

            // // Borrower
            // const uniqueBorrower = [
            //   ...new Set(data.map((item) => item.traderMemberUID)),
            // ];
            // let filterBorrower =
            //   uniqueBorrower &&
            //   uniqueBorrower.map((item) => {
            //     return {
            //       label: CompanyObject && FindCompanyName(CompanyObject, item),
            //       value: item,
            //     };
            //   });
            // setBorrowerOptions(filterBorrower);

            setResponse(data);
            setFilterData(data);
          }
        });
      });
    }
  }, []);

  useEffect(() => {
    if (response) {
      // Borrower
      const uniqueBorrower = [
        ...new Set(response.map((item) => item.traderMemberUID)),
      ];
      let filterBorrower =
        uniqueBorrower &&
        uniqueBorrower.map((item) => {
          return {
            label: CompanyObject && FindCompanyName(CompanyObject, item),
            value: item,
          };
        });
      setBorrowerOptions(filterBorrower);
    }
  }, [response]);

  useEffect(() => {
    if (role) {
      // setUploadInvoiceRole(
      //   CheckPermission("Invoice", "Invoices", "UploadInvoices", Role)
      // );
      setsearchFinanceRole(
        CheckPermission("Invoice", "Invoices", "searchInvoices", role)
      );
    }
  }, [role]);

  const handleMultiChange = (e, name) => {
    if (name === "type") {
      const { value } = e.target;
      setFinanceType(value);
    }

    if (name === "status") {
      const { value } = e.target;
      setSelectStatus(value);
    }

    if (name === "Financer") {
      const { value } = e.target;
      setSelectedFinancer(value);
    }

    if (name === "Borrower") {
      const { value } = e.target;
      setSelectedBorrower(value);
    }

    if (name === "datefrom") {
      setDateFrom(new Date(e).toLocaleDateString());
    }

    if (name === "dateend") {
      setDateTo(new Date(e).toLocaleDateString());
    }
  };

  useEffect(() => {
    if (selectStatus) {
      let temporaryList = [];
      selectStatus.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedStatusId(temporaryList);
    }

    if (selectFinanceType) {
      let temporaryList = [];
      selectFinanceType.map((select) => {
        temporaryList.push(select.value);
      });
      setFinanceTypeId(temporaryList);
    }

    if (selectedFinancer) {
      let temporaryList = [];
      selectedFinancer.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedFinancerId(temporaryList);
    }

    if (selectedBorrower) {
      let temporaryList = [];
      selectedBorrower.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedBorrowerId(temporaryList);
    }
  }, [selectStatus, selectFinanceType, selectedFinancer, selectedBorrower]);

  useEffect(() => {
    let newdate = Date.parse(dateTo);
    let olddate = Date.parse(dateFrom);
    if (
      (selectedStatusId && selectedStatusId.length > 0) ||
      (selectedFinanceTypeId && selectedFinanceTypeId.length > 0) ||
      dateFrom ||
      dateTo ||
      selectedFinancerId ||
      selectedBorrowerId
    ) {
      let filtered =
        response &&
        response.filter(
          (data) =>
            (selectedFinanceTypeId.length > 0
              ? selectedFinanceTypeId.includes(data.financeProductCategory)
              : data) &&
            (selectedStatusId.length > 0
              ? selectedStatusId.includes(data.financeDealStatus)
              : data) &&
            (selectedFinancerId.length > 0
              ? selectedFinancerId.includes(data.financerName)
              : data) &&
            (selectedBorrowerId.length > 0
              ? selectedBorrowerId.includes(data.traderMemberUID)
              : data) &&
            (dateFrom ? Date.parse(data.lastUpdatedDate) >= olddate : data) &&
            (dateTo ? Date.parse(data.lastUpdatedDate) <= newdate : data)
        );
      setFilterData(filtered);
    } else if (
      selectedStatusId.length === 0 &&
      selectedFinanceTypeId.length === 0 &&
      !dateFrom &&
      !dateTo &&
      selectedFinancerId.length === 0 &&
      selectedBorrowerId.length === 0
    ) {
      setFilterData(response);
    }
  }, [
    selectedFinanceTypeId,
    selectedStatusId,
    dateFrom,
    dateTo,
    selectedFinancerId,
    selectedBorrowerId,
  ]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {response && CompanyObject && (
            <>
              <div style={{ marginBottom: "1%" }}>
                <Content padding="1.4% 2% 2% 2%">
                  <div>
                    <h5>Search Filter</h5>
                  </div>
                  <div className={styles.searchDiv}>
                    <div>
                      <MultiSelect
                        name="memberlist"
                        selected={selectFinanceType}
                        placeholder="Finance Type"
                        options={FinanceCategory}
                        handleChange={(e) => handleMultiChange(e, "type")}
                      />
                    </div>
                    <div>
                      <MultiSelect
                        name="memberlist"
                        selected={selectStatus}
                        placeholder="Status"
                        options={FinanceDealStatus}
                        handleChange={(e) => handleMultiChange(e, "status")}
                      />
                    </div>
                    <div>
                      <DateInput
                        backDate={new Date()}
                        name="DateFrom"
                        value={dateFrom}
                        placeholder="Date From *"
                        handleChange={(e) => handleMultiChange(e, "datefrom")}
                      />
                    </div>
                    <div>
                      <DateInput
                        name="DateFrom"
                        backDate={new Date()}
                        value={dateTo}
                        placeholder="Date To *"
                        handleChange={(e) => handleMultiChange(e, "dateend")}
                      />
                    </div>
                  </div>

                  <div className={styles.searchDiv1}>
                    <div>
                      <MultiSelect
                        name="memberlist"
                        selected={selectedFinancer}
                        placeholder="Financer"
                        options={FinancerOptions}
                        handleChange={(e) => handleMultiChange(e, "Financer")}
                      />
                    </div>
                    <div>
                      <MultiSelect
                        name="memberlist"
                        selected={selectedBorrower}
                        placeholder="Borrower"
                        options={BorrowerOptions}
                        handleChange={(e) => handleMultiChange(e, "Borrower")}
                      />
                    </div>
                  </div>
                </Content>
              </div>

              <Content padding="1.5% 2% 1% 2%">
                <h5>Finance Deals</h5>
                <FinanceTable
                  response={filterData}
                  companyList={CompanyObject}
                  hanndleCraateGroup={hanndleCraateGroup}
                  role={role}
                />
              </Content>
            </>
          )}

          {isError && <Content>{isError}</Content>}
        </>
      )}
      {/* 
      <SBProvider appId={APP_ID} userId={memberId} accessToken={chatToken}>
        {response && !CompanyObject.isLoading ? (
          <div>
            <div
              style={{
                backgroundColor: "black",
                padding: "1%",
                borderRadius: "5px",
              }}
            >
              {dropDownState ? (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>Search Filter</div>
                    <span
                      style={{ width: "50%", textAlign: "right" }}
                      onClick={handleDropUpDown}
                    >
                      <RiArrowDropDownLine size={30} color={"#42a5f5"} />
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: "50%" }}>Search Filter</div>
                    <span
                      style={{ width: "50%", textAlign: "right" }}
                      onClick={handleDropUpDown}
                    >
                      <RiArrowDropUpLine size={30} color={"#42a5f5"} />
                    </span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={style.filterButton}
                      style={
                        selectedFilter === "ALL"
                          ? { backgroundColor: "#42a5f5" }
                          : { backgroundColor: "" }
                      }
                      onClick={(e) => handleFilter(e, "ALL")}
                    >
                      ALL
                    </div>
                    <div
                      className={style.filterButton}
                      style={
                        selectedFilter === "NEW"
                          ? { backgroundColor: "#42a5f5" }
                          : { backgroundColor: "" }
                      }
                      onClick={(e) => handleFilter(e, "NEW")}
                    >
                      NEW
                    </div>
                    <div
                      className={style.filterButton}
                      style={
                        selectedFilter === "INTEREST_EXPRESSED"
                          ? { backgroundColor: "#42a5f5" }
                          : { backgroundColor: "" }
                      }
                      onClick={(e) => handleFilter(e, "INTEREST_EXPRESSED")}
                    >
                      INTEREST_EXPRESSED
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className={style.filterButton}
                      style={
                        selectedFilter === "DUE_DILIGENCE_INITIATED"
                          ? { backgroundColor: "#42a5f5" }
                          : { backgroundColor: "" }
                      }
                      onClick={(e) =>
                        handleFilter(e, "DUE_DILIGENCE_INITIATED")
                      }
                    >
                      DUE_DILIGENCE_INITIATED
                    </div>
                    <div
                      className={style.filterButton}
                      style={
                        selectedFilter === "DUE_DILIGENCE_COMPLETED"
                          ? { backgroundColor: "#42a5f5" }
                          : { backgroundColor: "" }
                      }
                      onClick={(e) =>
                        handleFilter(e, "DUE_DILIGENCE_COMPLETED")
                      }
                    >
                      DUE_DILIGENCE_COMPLETED
                    </div>
                    <div
                      className={style.filterButton}
                      style={
                        selectedFilter === "QUOTE_GENERATED"
                          ? { backgroundColor: "#42a5f5" }
                          : { backgroundColor: "" }
                      }
                      onClick={(e) => handleFilter(e, "QUOTE_GENERATED")}
                    >
                      QUOTE_GENERATED
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className={style.filterButton}
                        style={
                          selectedFilter === "QUOTE_ACCEPTED"
                            ? { backgroundColor: "#42a5f5" }
                            : { backgroundColor: "" }
                        }
                        onClick={(e) => handleFilter(e, "QUOTE_ACCEPTED")}
                      >
                        QUOTE_ACCEPTED
                      </div>
                      <div
                        className={style.filterButton}
                        style={
                          selectedFilter === "QUOTE_DECLINED"
                            ? { backgroundColor: "#42a5f5" }
                            : { backgroundColor: "" }
                        }
                        onClick={(e) => handleFilter(e, "QUOTE_DECLINED")}
                      >
                        QUOTE_DECLINED
                      </div>
                      <div
                        className={style.filterButton}
                        style={
                          selectedFilter === "PENDING_T_AND_C_ACCEPTANCE"
                            ? { backgroundColor: "#42a5f5" }
                            : { backgroundColor: "" }
                        }
                        onClick={(e) =>
                          handleFilter(e, "PENDING_T_AND_C_ACCEPTANCE")
                        }
                      >
                        PENDING_T_AND_C_ACCEPTANCE
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className={style.filterButton}
                        style={
                          selectedFilter === "FINANCE_AGREEMENT_COMPLETE"
                            ? { backgroundColor: "#42a5f5" }
                            : { backgroundColor: "" }
                        }
                        onClick={(e) =>
                          handleFilter(e, "FINANCE_AGREEMENT_COMPLETE")
                        }
                      >
                        FINANCE_AGREEMENT_COMPLETE
                      </div>
                      <div
                        className={style.filterButton}
                        style={
                          selectedFilter === "FINANCED"
                            ? { backgroundColor: "#42a5f5" }
                            : { backgroundColor: "" }
                        }
                        onClick={(e) => handleFilter(e, "FINANCED")}
                      >
                        FINANCED
                      </div>
                      <div
                        className={style.filterButton}
                        style={
                          selectedFilter === "CLOSED"
                            ? { backgroundColor: "#42a5f5" }
                            : { backgroundColor: "" }
                        }
                        onClick={(e) => handleFilter(e, "CLOSED")}
                      >
                        CLOSED
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                marginTop: "2%",
                backgroundColor: "black",
                paddingLeft: "1.5%",
                paddingRight: "1.5%",
                borderRadius: "5px",
              }}
            >
              <h6 style={{ paddingTop: "2%", paddingBottom: "1%" }}>
                Finance Deals
              </h6>
              <FinanceTable
                response={filterData}
                companyList={CompanyObject.response}
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </SBProvider> */}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default FinanceDeal;
