import React from "react";
import styles from "./details.module.css";
import Loader from "components/Loader/Loader";
import { FindCompanyName } from "utils/helper";
import { useContext } from "react";
import { FinanceContext } from "../Invoice/financeProvider";
import { Suspense } from "react";

const Summary = ({ data }) => {
  const {companyList} = useContext(FinanceContext);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const Response = [
    {
      name: "Invoice Number",
      value: data.invoiceNumber ? data.invoiceNumber : "Unknown",
    },
    {
      name: "Status",
      value: data.txlStatus ? data.txlStatus : "Unknown",
    },
    {
      name: "Buyer",
      value:
      companyList &&
        FindCompanyName(companyList && companyList, data.txlBuyerMemberId),
    },
    {
      name: "Seller",
      value:
      companyList &&
        FindCompanyName(companyList && companyList, data.txlSellerMemberId),
    },
    {
      name: "Funding data",
      value: "PENDING",
    },
    {
      name: "Buyer Consent",
      value: data.buyerConsent ? data.buyerConsent : "Unknown",
    },
    {
      name: "Invoice Value",
      value: data?.txlInvoiceAmount && data.txlInvoiceAmount,
    },
    {
      name: "Invoice Currency ",
      value: data.txlCurrencyCode,
      // value:
      //   CompanyNameFilter(CompanyList, data.txlSellerMemberId).length >= 1
      //     ? CompanyNameFilter(CompanyList, data.txlSellerMemberId)[0].memberName
      //     : "",
    },
    
    {
      name: "Invoice Financed Amount",
      value: data.financedAmount ? data.financedAmount : "Unknown",
    },
    {
      name: "Last Activity",
      value: data.updatedDate ? new Date(data.updatedDate).toLocaleDateString() : "Unknown",
    },
  ];

  return (
    <Suspense fallback={<Loader/>}>
    <div className={styles.summary}>
       {
        Response.map((data, i) => (
          <div className={styles.summaryWrapper} key={i}>
            <div style={{fontSize:"12px"}}>
              <span> {data.name} </span>
            </div>
            <div style={{fontSize:"12px"}}>
              <span
                style={
                  data.value === "PENDING"
                    ? addRed
                    : data.name === "Buyer"
                    ? addBlue
                    : data.name === "Invoice Document"
                    ? addBlue
                    : data.name === "Seller"
                    ? addBlue
                    : addWhite
                }
              >
                {data.value}
              </span>
            </div>
          </div>
        ))
       }
    </div>
    {data && data.txlSellerMemberId === companyId && (
     <span>* All the fields are mandatory please check/edit before proceeding.</span>
    )}
    </Suspense>
  );
};

export default Summary;

const addRed = {
  color: "red",
};

const addWhite = {
  color: "white",
};

const addGreen = {
  color: "green",
};

const addBlue = {
  color: "#42A5F5",
};
