// Using in Form
import React from "react";
import Select from "react-select";
import classes from "./Input.module.css";

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "black",
    cursor: "pointer",
    padding: "9px",
    fontSize: "14px !important",
    border: "1px solid #42a5f5",
    boxShadow: isFocused ? null : null,
    ":hover": {},
    ":active": {},
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      fontSize: "18px",
      backgroundColor: "#42a5f5",
      color: "white",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ":hover": {
      backgroundColor: "black",
      color: "white",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#42a5f5" : null,
      color: isFocused ? "white" : "black",
      zIndex: "999",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#42a5f5",
      },
      "&:click": {},
    };
  },
  placeholder: (provided, state) => ({
    ...provided,
    color: "#42a5f5",
    zIndex: "999",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const MultiSelect = ({
  isMulti,
  options,
  name,
  value,
  handleChange,
  isClearable,
  isDisabled,
  placeholder,
}) => {
  return (
    <div className={classes.multiSelect}>
      <label htmlFor={name}>{placeholder}</label>
      <Select
        closeMenuOnSelect={false}
        isMulti={isMulti ? isMulti : false}
        options={options ? options : []}
        placeholder={placeholder || name}
        value={value}
        onChange={(value) => handleChange({ target: { value, name } })}
        isClearable={isClearable ? isClearable : false}
        isDisabled={isDisabled ? isDisabled : false}
        styles={colourStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 5,
          colors: {
            ...theme.colors,
            text: "white",
            font: "white",
            primary25: "white",
            primary: "white",
            neutral80: "white",
            color: "white",
          },
        })}
      />
    </div>
  );
};

export default MultiSelect;
