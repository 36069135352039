import React, { useCallback } from "react";
import useAxios from "../../../../../services/useAxios";
import { supplierApi } from "../../../../../services/BaseURLInstance";
// import Loader from "../../../../../components/Loader";
import {CardWapper,Wrapper,ButtonStyled} from "../../../../../components/Container.styled";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import styles from '../../../invoice.module.css'
import Loader from '../../../../../components/Loader/Loader'

export default () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const { response, isLoading, error } = useAxios({
    api: supplierApi,
    method: "get",
    url: "bff/supplier/dashboard/invoices?memberId=12345",
    config: JSON.stringify({
      requireAuthentication: true,
      headers: {
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    }),
  });

  const finanacingInvoice = [
    { value: "uploadedInvoices", name: "Upload Invoices" },
    { value: "pendingBuyerConsentRequests", name: "Pending Buyer Consent" },
    { value: "financingOffers", name: "Financing Request" },
    { value: "openUpcomingRePayments", name: "Financing Request Amount" },
    { value: "openFinancingOffers", name: "Financing Request Quotes" },
    { value: "openConsentApprovals", name: "Financing Approved Amount" },
  ];

  

  return (
    <>
      {isLoading && <Loader />}
      <CardWapper style={cardWrapper}>
        <SectionStyled>
          <span  className ={styles.heading1}>Purchase Order Overview</span>
          <button className={styles.uploadInvoiceBtn}
             onClick={() => handleNavigate(`/financedashBoard/pofinancing/upload`)}
            >+ Upload New PO
          </button>
        </SectionStyled>

        <Wrapper>
          {finanacingInvoice.map((item , index) => (
            <div className={styles.card} key={index} >
              <div className={styles.content}>
                <div>{item.name}</div>
                <span style={{color: "#42A5F5"}}>{response && response[item.value]}</span>
              </div>
            </div>
          ))}
        </Wrapper>
        </CardWapper> 
    </>
  );
};

const SectionStyled = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding-right: 10px;
  align-items: baseline;
`;

const cardWrapper = {
  letterSpacing: "1px",
  // height: '270px',
  borderBottom: "0",
};
const cardWrappering={
  marginTop:"40px", 
  letterSpacing: "1px",
  borderBottom: "0",
}
