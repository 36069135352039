export default (title) => {
  let Industry = {
    AGRICULTURE: "Agriculture",
    FOOD_PRODUCTS: "Food Products",
    CANDY_SODA: "Candy Soda",
    BEER_LIQUOR: "Beer Liquor",
    TOBACCO_PRODUCTS: "Tobacco Products",
    RECREATION: "Recreation",
    ENTERTAINMENT: "Entertainment",
    PRINTING_PUBLISHING: "Printing Publishing",
    CONSUMER_GOODS: "Consumer Goods",
    APPAREL: "Apparel",
    HEALTHCARE: "Healthcare",
    MEDICAL_EQUIPMENT: "Medical Equipment",
    PHARMACEUTICAL_PRODUCTS: "Pharmaceutical Products",
    CHEMICALS: "Chemicals",
    RUBBER_PLASTIC_PRODUCTS: "Rubber Plastic Products",
    TEXTILES: "Textiles",
    CONSTRUCTION_MATERIALS: "Construction Material",
    CONSTRUCTION: "Construction",
    STEEL_WORKS: "SteelWorks",
    FABRICATED_PRODUCTS: "Fabricated Products",
    MACHINERY: "Machinery",
    ELECTRICAL_EQUIPMENT: "Electrical Equipment",
    AUTOMOBILES_TRUCKS: "Automobile Products",
    AIRCRAFT: "Aircraft",
    SHIPBUILDING_RAILROAD_EQUIPMENT: "Shipbuilding Railroad Material",
    DEFENSE: "Defense",
    PRECIOUS_METALS: "Precious Material",
    NON_METALLIC_INDUSTRIAL_METAL_MINING:
      "Mon Metallic Industrial Metal Mining",
    COAL: "Coal",
    PETROLEUM_NATURAL_GAS: "Petroleum Natural Gas",
    UTILITIES: "Utilities",
    COMMUNICATION: "Communication",
    PERSONAL_SERVICES: "Personal Services",
    BUSINESS_SERVICES: "Business Services",
    COMPUTER_HARDWARE: "Computer Hardware",
    ELECTRONIC_EQUIPMENT: "Electronic Equipment",
    COMPUTER_SOFTWARE: "Computer Software",
  };

  if (title) {
    return Industry[`${title}`];
  } else {
    return "UNDEFINED";
  }
};
