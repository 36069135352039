import React from 'react';
import { useEffect } from 'react';
import './paymentCollection.css';
import { getPaymentsByLoanId, getPaymentCollection } from 'api/Payments';
import { useState } from 'react';
import { truncateStringID } from "utils/helper";
import {getCountryCode} from '../../../../common/utils/getCountries';

export const PaymentCollection = ({ response, loanId, companyList, memberId, quoteData }) => {

    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const [paymentDetail, setPaymentDetails] = useState();
    const [paymentCollection, setPaymentCollection] = useState();
    const countries = getCountryCode(response, companyList);
    useEffect(() => {
        const countries = getCountryCode(response, companyList);
        const paymentAmount = response.financeDisbursalStatus==="DISBURSEMENT_COMPLETED"? response.financeRepaymentAmount :response.financeAmount;
        getPaymentCollection(paymentAmount, countries.financerCountry, response.financeCurrency, response.financerMemberUID, countries.traderCountry, idToken)
            .then((res) => {
                setPaymentCollection(res);
            })
            .catch((error) => {
                console.log("paymentCollection error", error)
            });
    }, [response])
    useEffect(() => {
        getPaymentsByLoanId(loanId, idToken, memberId)
            .then((res) => {
                let dateArray = [];
                res.length > 0 && res.forEach(item => {
                    dateArray.push(new Date(item.updatedDate));
                })
                dateArray.sort((date1, date2) => date1 - date2);
                const latestDate = new Date(dateArray[dateArray.length - 1]).toISOString();
                let newRes = [];
                newRes = res.length > 0 && res.filter((item) => {
                    return new Date(item.updatedDate).toISOString() == latestDate;
                })
                setPaymentDetails(newRes[0]);
            })
            .catch((error) => {
                console.log("paymentByLoan error", error)
            });
    }, [])
    const buyerMemberId = response.financerMemberUID;
    const sellerMemberId = response.traderMemberUID;
    const currency = response.financeCurrency;
    const paymentAmount = response.financeDisbursalStatus==="DISBURSEMENT_COMPLETED"? response.financeRepaymentAmount :response.financeAmount;
    const paymentMethodType = quoteData.paymentMethodType;
    return (
        <div>
            <div style={{ backgroundColor: "black", width: "100%", padding: "0%", border:"1px solid white", padding:'10px', marginTop:'1%' }}>
            <div className='panelHeading' style={{marginTop:'10px', marginBottom:"10px"}}>Payment Collection</div>
                <div>
                    <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Status</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentDetail?.paymentStatus ? paymentDetail.paymentStatus : 'New'}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Borrower Country</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{
                                companyList && companyList.map((item) => {
                                    if (item.memberUID === buyerMemberId) {
                                        return item.countryCode;
                                    }
                                })
                            }</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Financer Country</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'> {companyList.map((item) => {
                                if (item.memberUID === sellerMemberId) {
                                    return item.countryCode;
                                }
                            })}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Currency</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{currency}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Amount</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentAmount}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Collection Method</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentMethodType}</div>
                        </div>
                    </div>
                </div>
            </div>




            { quoteData.modeOfPayment!=="OFFLINE" && paymentCollection && paymentCollection.map((item)=>{
                return <div style={{ backgroundColor: "black", width: "100%", padding: "0%", border: "1px solid white", padding:"10px", marginTop: "20px"}}>
                    <div className='panelHeading' style={{ marginTop: "10px", marginBottom:'10px'}}>Payment Rate</div>
                     <div>
                        <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
                            <div className='overviewListItem'>
                                <div className='overViewListFirstTile'>Currency</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.currency}</div>
                            </div>
                            <div className='overviewListItem'>
                                <div className='overViewListFirstTile'>Rate</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.rate}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className='overviewListItem' style={{width:"49%"}}>
                                <div className='overViewListFirstTile'>Amount</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.amount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            })
            }
        </div>
    )
}