import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import { Grid } from "@mui/material";
import MultiSelect from "components/fields/MultiSelect";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { CONTAINER } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";
const Container = ({ containerData, setContainerData }) => {
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let transportModeOptions = [
    {
      label: "TRUCK",
      value: "TRUCK",
    },
    {
      label: "RAIL",
      value: "RAIL",
    },
    {
      label: "SEA",
      value: "SEA",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let containerTypeOptions = [
    {
      label: "Dry storage container",
      value: "DRY_STORAGE_CONTAINER",
    },
    {
      label: "Flat rack container",
      value: "FLAT_RACK_CONTAINER",
    },
    {
      label: "Open top container",
      value: "OPEN_TOP_CONTAINER",
    },
    {
      label: "Open side storage container",
      value: "OPEN_SIDE_STORAGE_CONTAINER",
    },
    {
      label: "Refrigerated ISO container",
      value: "REFRIGERATED_ISO_CONTAINER",
    },
    {
      label: "ISO Tanks",
      value: "ISO_Tanks",
    },
    {
      label: "Half height containers",
      value: "HALF_HEIGHT_CONTAINERS",
    },
    {
      label: "Special purpose containers",
      value: "SPECIAL_PURPOSE_CONTAINER",
    },
    {
      label: "Others",
      value: "OTHERS",
    },
  ];

  let containerSizeOptions = [
    {
      label: "10-Foot Container",
      value: "10-Foot_Container",
    },
    {
      label: "20-Foot Container",
      value: "20-Foot_Container",
    },
    {
      label: "40-Foot Container",
      value: "40-Foot_Container",
    },
    {
      label: "20-Foot High Cube",
      value: "20-Foot_High_Cube",
    },
    {
      label: "40-Foot High Cube",
      value: "40-Foot_High_Cube",
    },
  ];

  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);

  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setContainerData({
      ...containerData,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  const handleDateChange = (e, field) => {
    setContainerData({
      ...containerData,
      [field]: new Date(e).toISOString(),
    });
  };
  const handleMultiChange = (e, name) => {
    if (name === "commodity") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "shipmentMode") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "originType") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryFrom") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "portOrigin") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationPort") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityFrom") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityTo") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "origin") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationType") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destination") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "containerWeight") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "containerCount") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "containerType") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "containerSize") {
      setContainerData({
        ...containerData,
        [e.target.name]: e.target.value,
      });
    }

    sessionStorage.setItem("containerData", JSON.stringify(containerData));
    sessionStorage.setItem(
      "tabletransportLoadData",
      JSON.stringify(tabletransportLoadData)
    );

    console.log("containerData", containerData);
  };
  return (
    <>
      <Content>
        <div style={{ marginLeft: "10px" }}>
          <OverLayTrigger value={CONTAINER} />
        </div>
        <div style={{ marginBottom: "10px", marginLeft: "10px" }}>
          Mode of Shipment
        </div>
        <div style={{ width: "25%", margin: "10px" }}>
          <Select
            name="shipmentMode"
            options={transportModeOptions}
            placeholder="Mode of Shipment*"
            handleChange={handleChange}
            value={containerData.shipmentMode}
          />
        </div>
        <div style={{ margin: "10px" }}>Shipment details</div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country  From*"
              handleChange={handleChange}
              value={containerData.countryFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country To*"
              handleChange={handleChange}
              value={containerData.countryTo}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityFrom"
              placeholder="City From"
              handleChange={handleChange}
              value={containerData.cityFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityTo"
              placeholder="City To"
              handleChange={handleChange}
              value={containerData.cityTo}
              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="portOrigin"
              placeholder="From Port (If Applicable)"
              handleChange={handleChange}
              value={containerData.portOrigin}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="destinationPort"
              placeholder="To Port (If Applicable)"
              value={containerData.destinationPort}
              handleChange={handleChange}
            />
          </div>
        </div>

        <div>
          {/* <div className={classes.source}>
              <div style={{ marginBottom: "1.2%", width: "50%" }}>
                <label>Estimated Date of Loading</label>
              </div>
              <div style={{ marginBottom: "1.2%", width: "50%" }}>
                <label>Estimated date of Unloading</label>
              </div>
            </div> */}
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Grid item xs={12} md={6}>
                <DateInput
                  name="loadingDate"
                  placeholder="Estimated Loading Date MM/DD/YYYY*"
                  value={containerData.loadingDate}
                  handleChange={(e) => handleDateChange(e, "loadingDate")}
                />
              </Grid>
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Grid item xs={12} md={6}>
                <DateInput
                  name="unloadingDate"
                  placeholder="Estimated Unloading Date MM/DD/YYYY*"
                  value={containerData.unloadingDate}
                  handleChange={(e) => handleDateChange(e, "unloadingDate")}
                />
              </Grid>
            </div>
          </div>
        </div>
        <div>
          {/* <div>
              <div style={{ marginBottom: "1.2%" }}>
                <label>COMMODITY</label>
              </div>
            </div> */}

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="containerCount"
                placeholder="Expected No. of Containers*"
                handleChange={handleChange}
                value={containerData.containerCount}
                // readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="containerWeight"
                placeholder="Average Weight per Container (Metric Ton)*"
                handleChange={handleChange}
                value={containerData.containerWeight}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={containerTypeOptions}
                name="containerType"
                placeholder="Container Type"
                handleChange={handleChange}
                value={containerData.containerType}
                // readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={containerSizeOptions}
                name="containerSize"
                placeholder="Container Size"
                handleChange={handleChange}
                value={containerData.containerSize}
                // readOnly={true}
              />
            </div>
          </div>
          <label style={{ marginLeft: "10px" }}>Product Category</label>
          <div style={{ margin: "10px" }} className={classes.source}>
            {/* <div style={{ width: "100%" }}> */}
            <Grid item xs={12} md={5}>
              <MultiSelect
                options={Industry}
                name="commodity"
                placeholder="Product Category*"
                handleChange={(e) => handleMultiChange(e, "commodity")}
                selected={containerData.commodity}
              />
            </Grid>
            {/* </div> */}
          </div>
        </div>
      </Content>
    </>
  );
};

export default Container;
