import React from "react";
import { useState, useContext, useLayoutEffect } from "react";
import { useEffect } from "react";
import { BsBell } from "react-icons/bs";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { storeContext } from "../../../../contexts/Store/Store";
import { NetworkURL } from "../../../../services/BaseURLInstance";
import {
  CompanyNameFilter,
  notify,
  truncateStringID,
} from "../../../../utils/helper";
import Container from "components/UI/Layout/Container";
import Participants from "./participants";
import { withSendBird, SendBirdState } from "sendbird-uikit";
import { UseRequest } from "utils/API/Request.js";
import SelectWithSearch from "components/fields/SelectWithSearch";
import { AiOutlineDelete } from "react-icons/ai";
import Modal from "components/UI/Modal/Modal";
import Loader from "components/Loader/Loader";
import {
  useNavigate,
  Link,
  Route,
  BrowserRouter,
  Routes,
} from "react-router-dom";
import Service from "../../../Services/New-Service";
import { borderBottom } from "@mui/system";
import Content from "components/UI/Layout/Content";
import { RiWechatLine } from "react-icons/ri";
import { fetchMemberdata } from "utils/memberchat";
import { HiOutlineDocumentText, HiOutlineUserGroup } from "react-icons/hi";
import { BsPatchQuestion, BsPatchCheck } from "react-icons/bs";
import "../tradesidenav.css";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { ServiceType } from "utils/DropDown/ServiceType";
import { Error, Success } from "components/notifications/responseHandle";
import APIError from "components/Error/error";
import PaymentTerm from "./paymentTerm";
import "./changeState.css";
import { ToTitleCase } from "common/utils/toTitleText";
import { tradeURL } from "services/BaseURLInstance";
import MultiSelect from "components/fields/MultiSelect";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Divider,
} from "@mui/material";
import _ from "lodash";
import NoRecord from "components/no-data/noRecord";

var sdk = null;

const mapStoreToProps = (store: SendBirdState) => {
  const {
    stores: { sdkStore, userStore },
    config: {
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub,
    },
  } = store;
  sdk = sdkStore.sdk;
};

const Summary = ({
  handleHeaderSelectChange,
  trade,
  data,
  flowStage,
  secondFlowSequence,
  initialState,
  stageData,
  companyList,
  subStageDate,
  hanndleCraateGroup,
}) => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(null);
  const [signData, setSignData] = useState();
  const [document, setDocument] = useState();
  const [subData, setSubData] = useState();
  const [stageDatas, setStageDatas] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const [companyList, setCompanyList] = useState([]);
  const { fetchData, fetchCompanyList } = useContext(storeContext);
  const { instance, accounts } = useMsal();
  const [services, setServices] = useState();
  const [filterCompany, setFilterCompany] = useState({
    first: "",
    second: "",
  });
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubCategory, setSelectedSubCategory] = useState();
  const [serviceRole, setServiceRole] = useState([]);
  const [memberList, setMemberList] = useState();
  const [navigateService, setNavigateService] = useState(false);
  const companyId = getClaim(accounts, "company_member_id");
  const [item, setItem] = useState();
  const [pendingAction, setPendingAction] = useState([]);
  const [open, setOpen] = useState();
  const { serviceType, serviceSubType } = ServiceType(selectedCategory || null);
  const [selectedList, setSelectedList] = useState([]);
  const [displayList, setDisplayList] = useState(true);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteItem, setDeleteItem] = useState();
  const [newServiceList, setNewServiceList] = useState();
  const [roleList, setRoleList] = useState();
  const [currentUserRole, setCurrentUserRole] = useState();

  //chat part
  const chatHandler = async (ds) => {
    if (ds?.participants?.length > 0) {
      let usrData = [];
      let myds = ds?.participants;
      for (let i = 0; i < myds?.length; i++) {
        usrData.push(myds[i]?.participant);
      }
      setItem(ds);
      let res = await fetchMemberdata(usrData);
      if (res?.length > 0) {
        let name = ds.tradeId ? truncateStringID(ds.tradeId, 7, "TR") : "NA";
        hanndleCraateGroup(res, name, name);
        setMemberList(res);
      }
    }
  };
  // const hanndleCraateGroup = () => {

  //   if (memberList?.length > 0) {
  //     let url = window.location.href;
  //     localStorage.setItem("lasturl", url);
  //     const selectedUsers = memberList;
  //     const params = new sdk.GroupChannelParams();
  //     params.addUserIds(selectedUsers);
  //     params.isDistinct = false;
  //     params.isEphemeral = false;
  //     params.isPublic = false;

  //     params.name = item.tradeId
  //       ? truncateStringID(item.tradeId, 7, "TW") + "_" + item.tradeStage + "_" + item.tradeSubStage
  //       : "NA"
  //     params.channelUrl = truncateStringID(item.tradeId, 7, "TW") + truncateStringID(item.subStageUID, 7, "");
  //     sdk.GroupChannel.createChannel(params, (response, error) => {
  //       const swapParams = sdk.getErrorFirstCallback();
  //       let groupChannel = response;
  //       let err = error;
  //       if (swapParams) {
  //         groupChannel = error;
  //         err = response;
  //       }
  //       if (err) {
  //         navigate("/chat?url=" + params.channelUrl);
  //       }
  //       else {
  //         navigate("/chat?url=" + params.channelUrl);
  //       }
  //     });

  //   }
  // }

  useEffect(() => {
    if (memberList);
  }, [memberList]);

  useEffect(() => {
    if (subStageDate || newServiceList) {
      let tempServices = newServiceList
        ? newServiceList.availableServices
        : subStageDate?.availableServices && subStageDate?.availableServices;
      tempServices &&
        tempServices.forEach((item1, key1) => {
          subStageDate?.subscribedServices?.forEach((item2, key2) => {
            if (
              item1.serviceCategory === item2.serviceCategory &&
              item1.serviceSubCategory === item2.serviceSubCategory
            ) {
              tempServices[key1] = item2;
            }
          });
        });
      setServices(tempServices);
    }
  }, [subStageDate, newServiceList]);

  useLayoutEffect(() => {
    if (subStageDate) {
      let temp1 = [];
      if (subStageDate) {
        temp1 = subStageDate?.documentGroups?.map((item) => {
          return item.docCategory;
        });
        let arr = temp1;
        temp1 = arr?.filter((item, index) => arr?.indexOf(item) === index);
      }
      let URL = `${
        process.env.REACT_APP_BFF_URL
      }bff/documentsGroups/summary?memberUID=${companyId}&template=${
        trade ? trade + "&instanceID=" + `${subStageDate?.tradeId}` : ""
      }&docCategories=${temp1 ? temp1 : ""}&docFilterTags=${
        subStageDate
          ? [
              `TRADE-${subStageDate?.tradeId}`,
              `TRADE_STAGE-${subStageDate?.stageUID}`,
              `TRADE_SUB_STAGE-${subStageDate?.subStageUID}`,
            ]
          : ""
      }`;
      let temp = [];
      let isCancelled = false;
      let counthaveId = 0;
      let countnothaveId = 0;
      if (!isCancelled) {
        UseRequest(URL).then((data) => {
          let { message } = data;
          if (message) {
            setIsError(message);
            Error(message);
          } else {
            temp = data.map((item) => {
              return { name: item.docCategory, value: item.docCategory };
            });
            temp.push({ name: "CONSENT", value: "CONSENT" });
            // setTab(temp);
            // console.log("documentData", data)
            data?.map((item) => {
              item.documents?.map((item2) => {
                if (item2.docReferenceId !== "") {
                  counthaveId = counthaveId + 1;
                } else {
                  countnothaveId = countnothaveId + 1;
                }
              });
            });
            setDocument({
              data: data,
              counthaveId: counthaveId,
              countnothaveId: countnothaveId,
            });
          }
        });
      }
      return () => {
        isCancelled = true;
      };
    }
  }, [subStageDate]);

  useEffect(() => {
    let newListConsent = [];
    let pendingCount = 0;
    let approvedCount = 0;
    if (document) {
      const getConsentData = async () => {
        let idToken = JSON.parse(sessionStorage.getItem("idToken"));
        let URL = `${process.env.REACT_APP_DOCUMENT_URL}documents/sign-requests`;
        fetchCompanyList(URL, idToken)
          .then((consentData) => {
            document.data?.map((item) => {
              item.documents?.map((item2) => {
                consentData.map((item3) => {
                  if (item3.docId === item2.docReferenceId) {
                    newListConsent.push(item3);
                  }
                });
              });
            });
            newListConsent?.length > 0 &&
              newListConsent.map((item) => {
                if (item.status === "Pending") {
                  pendingCount = pendingCount + 1;
                } else {
                  approvedCount = approvedCount + 1;
                }
              });
            setSignData({
              pendingCount: pendingCount,
              approvedCount: approvedCount,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      };
      getConsentData();
    }
  }, [document]);

  useEffect(() => {
    if (companyList && subStageDate) {
      let filterArr = [];
      for (let i in subStageDate?.participants) {
        for (let j in companyList) {
          if (
            subStageDate?.participants[i]?.participant ===
            companyList[j].memberUID
          ) {
            let temp = companyList[j];
            temp["role"] = subStageDate?.participants[i]?.role;
            filterArr.push(temp);
          }
        }
      }
      subStageDate?.participantsToShare?.length > 0 &&
        subStageDate?.participantsToShare?.forEach((shareItem) => {
          filterArr.forEach((listItem) => {
            if (listItem.memberUID === shareItem.participant) {
              listItem["share"] = true;
            } else {
              listItem["share"] = false;
            }
          });
        });
      if (filterArr?.length % 2 == 0) {
        let temp = filterArr.slice(0, filterArr?.length / 2);
        let temp2 = filterArr.slice(filterArr?.length / 2, filterArr?.length);
        setFilterCompany({
          ...filterCompany,
          first: temp,
          second: temp2,
        });
      } else {
        let temp = filterArr.slice(0, filterArr.length / 2 + 1);
        let temp2 = filterArr.slice(filterArr.length / 2 + 1, filterArr.length);
        setFilterCompany({
          ...filterCompany,
          first: temp,
          second: temp2,
        });
      }
    }
  }, [subStageDate, companyList]);

  const handleService = (e, item) => {
    let referenceData = [
      {
        key: "TRADE",
        value: subStageDate?.tradeId,
      },
      {
        key: "TRADE_STAGE",
        value: subStageDate?.stageUID,
      },
      {
        key: "TRADE_SUB_STAGE",
        value: subStageDate?.subStageUID,
      },
    ];
    let category = [
      {
        serviceCategory: item.serviceCategory,
        serviceSubCategory: item.serviceSubCategory,
      },
    ];
    return navigate("/../../services/service", {
      state: { data: referenceData, category: category },
    });
  };

  useEffect(() => {
    let tempPendingAction = [];
    subStageDate?.subStageExitCriteria?.forEach((item) => {
      data?.tradeLifeCycle?.forEach((item2) => {
        if (
          item.stage === item2.stageSequence &&
          item.subStage === item2.subStageSequence
        ) {
          tempPendingAction.push({
            stageName: subStageDate.tradeStage,
            subStageName: subStageDate.tradeSubStage,
            status: subStageDate.status,
          });
        }
      });
    });
    setPendingAction(tempPendingAction);
  }, [subStageDate, data]);

  const handleClose = () => {
    setSelectedCategory();
    setSelectedSubCategory();
    setOpen(false);
  };
  const handleCreateService = () => {
    if (!selectedCategory && !selectedSubCategory) {
      Error("Please Enter Category and Sub-Category");
    } else {
      setIsLoading(true);
      const newObject = {
        serviceCategory: selectedCategory,
        serviceSubCategory: selectedSubCategory,
        subscriberRoles: serviceRole.length > 0 && serviceRole,
      };
      const newStageData = _.cloneDeep(subStageDate);
      let newAvailableService;
      if (newServiceList) {
        newAvailableService = newServiceList.availableServices;
      } else {
        newAvailableService = newStageData.availableServices;
      }
      newAvailableService.push(newObject);
      const serviceData = {
        buyerMemberId: subStageDate.buyerMemberId,
        sellerMemberId: subStageDate.sellerMemberId,
        stageUID: subStageDate.stageUID,
        subStageUID: subStageDate.subStageUID,
        tradeId: subStageDate.tradeId,
        stageDetailId: subStageDate.stageDetailId,
        availableServices: newAvailableService,
        subscribedServices: subStageDate.subscribedServices,
        createdBy: subStageDate.createdBy,
        updateBy: subStageDate.updateBy,
        createdDate: subStageDate.createdDate,
        updatedDate: subStageDate.updatedDate,
      };
      const idToken = JSON.parse(sessionStorage.getItem("idToken"));
      const requestOptions = {
        method: "POST",
        requireAuthentication: true,
        headers: {
          Authorization: "Bearer " + idToken,
          "Content-Type": "application/json",
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(serviceData),
      };
      const fetchData = async () => {
        await fetch(`${tradeURL}services`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data?.status >= 400 || !data) {
              setIsLoading(false);
              Error("Something Went Wrong");
            } else {
              setIsLoading(false);
              Success("Service Added successfully");
              setSelectedCategory();
              setSelectedSubCategory();
              setNewServiceList(data);
              setOpen(false);
            }
          });
      };
      fetchData();
    }
  };

  const DeleteFromModle = () => {
    setIsLoading(true);
    const newStageData = _.cloneDeep(subStageDate);
    let newAvailableService;
    if (newServiceList) {
      newAvailableService = newServiceList.availableServices.filter((item) => {
        return (
          item.serviceCategory !== deleteItem.selectedCategory &&
          item.selectedSubCategory !== deleteItem.selectedSubCategory
        );
      });
    } else {
      newAvailableService = newStageData.availableServices.filter((item) => {
        return (
          item.serviceCategory !== deleteItem.selectedCategory &&
          item.selectedSubCategory !== deleteItem.selectedSubCategory
        );
      });
    }
    //  let newAvailableService = newStageData.availableServices.filter((item)=>{
    //     return item.serviceCategory !== deleteItem.selectedCategory && item.selectedSubCategory !== deleteItem.selectedSubCategory
    //  })
    const serviceData = {
      buyerMemberId: subStageDate.buyerMemberId,
      sellerMemberId: subStageDate.sellerMemberId,
      stageUID: subStageDate.stageUID,
      subStageUID: subStageDate.subStageUID,
      tradeId: subStageDate.tradeId,
      stageDetailId: subStageDate.stageDetailId,
      availableServices: newAvailableService,
      subscribedServices: subStageDate.subscribedServices,
      createdBy: subStageDate.createdBy,
      updateBy: subStageDate.updateBy,
      createdDate: subStageDate.createdDate,
      updatedDate: subStageDate.updatedDate,
    };
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "POST",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(serviceData),
    };
    const fetchData = async () => {
      await fetch(`${tradeURL}services`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (data?.status >= 400 || !data) {
            setIsLoading(false);
            Error("Something Went Wrong");
          } else {
            setIsLoading(false);
            Success("Service Deleted successfully");
            setSelectedCategory();
            setSelectedSubCategory();
            setNewServiceList(data);
            setIsDelete(false);
          }
        });
    };
    fetchData();
  };

  const handleDeleteService = (selectedCategory, selectedSubCategory) => {
    setDeleteItem({
      ...deleteItem,
      selectedCategory: selectedCategory,
      selectedSubCategory: selectedSubCategory,
    });
    setIsDelete(true);
  };

  const handleChangeCategory = (e) => {
    {
      setSelectedCategory(e.target.value);
      setSelectedSubCategory(null);
      setSelectedList([]);
    }
  };

  const handleChangeSubCategory = (e) => {
    setSelectedSubCategory(e.target.value);
    setSelectedList([]);
    setDisplayList(true);
  };

  const handleMultiSelectRole = (e) => {
    const temp = e.target.value.map((item) => {
      return item.value;
    });
    setServiceRole(temp);
  };

  useEffect(() => {
    if (subStageDate?.participants) {
      let temp = subStageDate?.participants.map((item) => {
        return item.role;
      });
      temp = temp.filter((item, i, ar) => ar.indexOf(item) === i);
      let roleList = temp.map((item) => {
        return { label: item, value: item };
      });
      setRoleList(roleList);
    }
  }, [subStageDate]);

  useEffect(() => {
    if (subStageDate?.participants) {
      const user = subStageDate?.participants?.filter((item) => {
        return item.participant === companyId;
      });
      if (user.length > 0) {
        setCurrentUserRole(user[0].role);
      }
    }
  }, [subStageDate]);

  return (
    <div>
      {isLoading ? (
        <div>
          <Loader />
        </div>
      ) : subStageDate.serviceErrorCode ? (
        <APIError status={subStageDate.message} />
      ) : (
        pendingAction && (
          <div>
            <Container padding="0%">
              <Content padding="0.5% 0%">
                <Modal
                  show={isDelete}
                  onHide={() => {
                    // setServiceDelete(null);
                    setIsDelete(false);
                  }}
                  Heading="Are you sure you want to delete?"
                  Data={
                    <>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <div className="d-flex flex-row-reverse">
                          <Button onClick={(e) => DeleteFromModle(e)}>
                            Confirm
                          </Button>
                        </div>
                      )}
                    </>
                  }
                />

                <Dialog
                  PaperProps={{
                    style: { backgroundColor: "rgba(165, 165, 165, 0.5)" },
                  }}
                  fullWidth
                  maxWidth="lg"
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="responsive-dialog-title">
                  <DialogContent>
                    <div
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        padding: "2%",
                        borderRadius: "5px",
                      }}>
                      <div style={{ fontSize: "14px" }}>Add New Service</div>
                      <div style={{ display: "flex", marginTop: "4%" }}>
                        <div style={{ width: "33%", marginRight: "1%" }}>
                          <SelectWithSearch
                            placeholder="Service Category *"
                            value={selectedCategory}
                            options={
                              serviceType &&
                              serviceType.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )
                            }
                            handleChange={(e) => handleChangeCategory(e)}
                          />
                        </div>
                        <div style={{ width: "33%", marginRight: "1%" }}>
                          <SelectWithSearch
                            placeholder="Service Sub-Category *"
                            value={selectedSubCategory}
                            options={
                              serviceSubType &&
                              serviceSubType.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )
                            }
                            handleChange={(e) => handleChangeSubCategory(e)}
                          />
                        </div>
                        <div style={{ width: "33%" }}>
                          <MultiSelect
                            name="role"
                            placeholder="Select Role"
                            options={roleList}
                            handleChange={handleMultiSelectRole}
                          />
                        </div>
                      </div>
                      <div style={{ marginTop: "4%", textAlign: "right" }}>
                        <Button autoFocus onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button autoFocus onClick={handleCreateService}>
                          Create
                        </Button>
                      </div>
                    </div>
                  </DialogContent>
                </Dialog>
                <div className="summaryheading">
                  Details of {ToTitleCase(subStageDate?.tradeSubStage)}
                  <div
                    className="chaticon"
                    onClick={() => chatHandler(subStageDate)}>
                    Initiate Group Chat <RiWechatLine size={22} />
                  </div>
                </div>
                {
                  currentUserRole ? 
                  <div className="summaryheadingsub">
                  <span style={{ marginRight: "1%" }}>
                    <BsBell size={15} color={"#42A5F5"} />
                  </span>
                     Trade sub-stage summary for your preview and action
                    </div>
                    : <div className="summaryheadingsub">
                    <span style={{ marginRight: "1%" }}>
                      <BsBell size={15} color={"#42A5F5"} />
                    </span>
                    You are not a participant at this stage thus you will not access to all information and actions.
                    <div>Please rech out to Admin for help if required.</div>
                  </div>
                }
                <div className="participantcard">
                  <Content padding="0.3%">
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          borderRight: "1px solid white",
                          width: "50%",
                          paddingRight: "2%",
                        }}>
                        <div style={{ display: "flex" }}>
                          <div
                            className="textinnerdiv"
                            style={{
                              color: "#2180D1",
                              width: "50%",
                              margin: "0px 2px",
                            }}>
                            Participants
                          </div>
                          <div
                            className="textinnerdiv"
                            style={{
                              color: "#2180D1",
                              width: "50%",
                              justifyContent: "right",
                              display: "flex",
                            }}>
                            <button
                              className="link"
                              onClick={() =>
                                handleHeaderSelectChange("participants")
                              }>
                              Manage Participants{" "}
                              <HiOutlineUserGroup size={23} color={"white"} />
                            </button>
                          </div>
                        </div>
                        <div
                          className="participantlist"
                          style={{ display: "flex", flexDirection: "column" }}>
                          <div
                            className="participantlistdata"
                            style={{ width: "100%" }}>
                            <div style={{ marginRight: "1%" }}>
                              {filterCompany.first !== "" &&
                                filterCompany.first.map((item) => {
                                  return (
                                    <Participants
                                      item={item}
                                      subData={subStageDate}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                          <div
                            className="participantlistdata"
                            style={{ width: "100%" }}>
                            <div>
                              {filterCompany.second !== "" &&
                                filterCompany.second.map((item) => {
                                  return (
                                    <Participants
                                      item={item}
                                      subData={subStageDate}
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div
                          className="participantlistdata"
                          style={{
                            textAlign: "left",
                            width: "100%",
                            display: "flex",
                            marginLeft: "4%",
                            marginTop: "4%",
                          }}>
                          <div style={{ display: "flex", width: "20%" }}>
                            <div style={{ margin: "2%", marginTop: "4%" }}>
                              <BsPatchCheck size={20} color={"#198754"} />
                            </div>
                            <div
                              style={{
                                margin: "2%",
                                fontSize: "12px",
                                marginTop: "8px",
                              }}>
                              Share
                            </div>
                          </div>
                          <div style={{ display: "flex", width: "30%" }}>
                            <div style={{ margin: "2%", marginTop: "3%" }}>
                              <BsPatchQuestion size={20} color={"#FF0000"} />
                            </div>
                            <div
                              style={{
                                margin: "2%",
                                fontSize: "12px",
                                marginTop: "8px",
                              }}>
                              Don't Share
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "50%" }}>
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            fontSize: "14px",
                            padding: "0px 2px",
                            color: "#2180D1",
                          }}>
                          Trade Stage Dependencies
                        </div>
                        <div style={{ marginTop: "1%", color: "#FFC107" }}>
                          {pendingAction.length > 0 &&
                            pendingAction.map((item) => {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "left",
                                    alignItems: "center",
                                    marginTop: "1rem",
                                  }}>
                                  <div
                                    style={{
                                      fontSize: "12px",
                                      textAlign: "left",
                                      width: "55%",
                                      marginLeft: "3%",
                                    }}>
                                    {item.stageName} | {item.subStageName}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      width: "30%",
                                      justifyContent: "left",
                                      alignItems: "left",
                                    }}>
                                    <div
                                      style={{
                                        fontSize: "12px",
                                        textAlign: "left",
                                        color: "white",
                                      }}>
                                      Status:{" "}
                                    </div>
                                    <div
                                      style={
                                        item.status === "NEW"
                                          ? {
                                              fontSize: "12px",
                                              textAlign: "center",
                                              color: "#FFBE33",
                                              marginLeft: "3%",
                                              marginRight: "1%",
                                            }
                                          : item.status === "COMPLETED"
                                          ? {
                                              fontSize: "12px",
                                              textAlign: "center",
                                              color: "#248604",
                                              marginLeft: "3%",
                                              marginRight: "1%",
                                            }
                                          : item.status === "IN_PROGRESS"
                                          ? {
                                              fontSize: "12px",
                                              textAlign: "center",
                                              color: "#218EEB",
                                              marginLeft: "3%",
                                              marginRight: "1%",
                                            }
                                          : item.status === "DRAFT"
                                          ? {
                                              fontSize: "12px",
                                              textAlign: "center",
                                              color: "#A5A5A5",
                                              marginLeft: "3%",
                                              marginRight: "1%",
                                            }
                                          : item.status === "NOT_STARTED"
                                          ? {
                                              fontSize: "12px",
                                              textAlign: "center",
                                              color: "#FFBE33",
                                              marginLeft: "3%",
                                              marginRight: "1%",
                                            }
                                          : {
                                              fontSize: "12px",
                                              textAlign: "center",
                                              color: "#861204",
                                              marginLeft: "3%",
                                              marginRight: "1%",
                                            }
                                      }>
                                      {item.status}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </Content>
                </div>

                <div className="participantcard">
                  <Content padding="0.3%">
                    <div style={{ display: "flex" }}>
                      <div
                        className="textinnerdiv"
                        style={{
                          color: "#2180D1",
                          marginBottom: "2%",
                          width: "50%",
                          padding: "0px 2px",
                        }}>
                        Services
                      </div>
                      {data.createdBy === companyId && (
                        <div
                          className="textinnerdiv"
                          style={{
                            color: "#2180D1",
                            marginBottom: "2%",
                            width: "50%",
                            justifyContent: "right",
                            display: "flex",
                            padding: "0px 2px",
                          }}>
                          <button
                            className="link"
                            onClick={() => {
                              setOpen(true);
                            }}>
                            {" "}
                            Add Services{" "}
                            <MdOutlineMiscellaneousServices
                              size={25}
                              color={"white"}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}>
                      <div style={{ flex: 1, width: "100%" }}>
                        {services?.length > 0 ? (
                          services?.map((item) => {
                            if (item.serviceCategory != " ") {
                              return (
                                <div
                                  style={{
                                    display: "flex",
                                    border: "1px solid #A5A5A5",
                                    borderRadius: "5px",
                                    marginBottom: "1%",
                                  }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      padding: "1%",
                                      backgroundColor: "#A5A5A5",
                                      height: "2.5rem",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      marginRight: "10px",
                                      fontSize: "90%",
                                      width: "30%",
                                      borderRadius: "5px",
                                      color: "black",
                                      margin: "1%",
                                    }}>
                                    <div
                                      style={{
                                        color: "white",
                                        fontSize: "10px",
                                      }}>
                                      Service Category | Sub-Category
                                    </div>
                                    <div style={{ fontSize: "12px" }}>
                                      {item.serviceCategory} |{" "}
                                      {item.serviceSubCategory}
                                    </div>
                                  </div>
                                  <div style={{ width: "15%", margin: "1%" }}>
                                    {item.serviceRequisitionStatus ===
                                      "NOT_SUBSCRIBED" ||
                                    item.serviceRequisitionStatus ===
                                      undefined ||
                                    item.serviceRequisitionStatus === "" ? (
                                      <div
                                        style={{
                                          backgroundColor: "#FFC107",
                                          height: "2.5rem",
                                          borderRadius: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          fontSize: "12px",
                                          color: "black",
                                          // padding: "0px 10px",
                                          // width: "100%",
                                        }}>
                                        Not Subscribed
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor: "#34BE0F",
                                          height: "2.5rem",
                                          borderRadius: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          fontSize: "12px",
                                          padding: "0px 10px",
                                          width: "100%",
                                          color: "black",
                                        }}>
                                        Subscribed
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className="subscribedButton"
                                    style={{ width: "20%", margin: "1%" }}>
                                    {(item.serviceRequisitionStatus ===
                                      "NOT_SUBSCRIBED" ||
                                      item.serviceRequisitionStatus ===
                                        undefined ||
                                      item.serviceRequisitionStatus === "") &&
                                    item.subscriberRoles !== undefined &&
                                    item?.subscriberRoles?.includes(
                                      currentUserRole
                                    ) ? (
                                      <button
                                        style={{
                                          backgroundColor: "#2180D1",
                                          height: "2.5rem",
                                          borderRadius: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          fontSize: "12px",
                                          padding: "0px 10px",
                                          width: "100%",
                                          color: "white",
                                          border: "none",
                                        }}
                                        onClick={(e) => handleService(e, item)}>
                                        Subscribe
                                      </button>
                                    ) : item.serviceRequisitionStatus ===
                                        "NOT_SUBSCRIBED" ||
                                      item.serviceRequisitionStatus ===
                                        undefined ||
                                      item.serviceRequisitionStatus === "" ? (
                                      <button
                                        style={{
                                          // backgroundColor: "#2180D1",
                                          height: "2.5rem",
                                          borderRadius: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          fontSize: "12px",
                                          padding: "0px 10px",
                                          width: "100%",
                                          color: "white",
                                          border: "none",
                                        }}
                                        disabled>
                                        Subscribe
                                      </button>
                                    ) : (
                                      <button
                                        style={{
                                          backgroundColor: "#2180D1",
                                          height: "2.5rem",
                                          borderRadius: "5px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          fontSize: "12px",
                                          padding: "0px 10px",
                                          width: "100%",
                                          color: "white",
                                          border: "none",
                                        }}
                                        onClick={(e) => handleService(e, item)}>
                                        Abort
                                      </button>
                                    )}
                                  </div>
                                  <div style={{ width: "15%", margin: "1%" }}>
                                    {
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          display: "flex",
                                        }}>
                                        Requisition Link
                                      </div>
                                    }
                                    {
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          display: "flex",
                                        }}>
                                        Deal Link
                                      </div>
                                    }
                                  </div>
                                  <div style={{ width: "10%", margin: "1%" }}>
                                    {item.serviceRequisitionId ? (
                                      <button
                                        style={{
                                          fontSize: "12px",
                                          display: "flex",
                                          background: "transparent",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `../../../services/service-requests/${item.serviceRequisitionId}`
                                          )
                                        }>
                                        <a href="">Requisition Link</a>
                                      </button>
                                    ) : (
                                      <button
                                        style={{
                                          fontSize: "12px",
                                          display: "flex",
                                          color: "#2180D1",
                                          background: "transparent",
                                          border: "none",
                                        }}>
                                        NA
                                      </button>
                                    )}
                                    {item.serviceDealId ? (
                                      <button
                                        style={{
                                          fontSize: "12px",
                                          display: "flex",
                                          background: "transparent",
                                          border: "none",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            "../../../services/service-deals"
                                          )
                                        }>
                                        <a href="">Deal Link</a>
                                      </button>
                                    ) : (
                                      <button
                                        style={{
                                          fontSize: "12px",
                                          display: "flex",
                                          color: "#2180D1",
                                          background: "transparent",
                                          border: "none",
                                        }}>
                                        NA
                                      </button>
                                    )}
                                  </div>
                                  {subStageDate.createdBy === companyId && (
                                    <div
                                      className="subscribedButton"
                                      style={{ width: "5%", margin: "1%" }}>
                                      {(item.serviceDealStatus ===
                                        "NOT_SUBSCRIBED" ||
                                        item.serviceDealStatus === undefined ||
                                        item.serviceDealStatus === "") && (
                                        <button
                                          style={{
                                            background: "transparent",
                                            height: "2.5rem",
                                            borderRadius: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "0px 10px",
                                            width: "100%",
                                            color: "white",
                                            border: "none",
                                          }}
                                          onClick={(e) =>
                                            handleDeleteService(
                                              item.serviceCategory,
                                              item.serviceSubCategory
                                            )
                                          }>
                                          <AiOutlineDelete
                                            color={"red"}
                                            size={20}
                                          />
                                        </button>
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            }
                          })
                        ) : (
                          <NoRecord />
                        )}
                      </div>
                    </div>
                  </Content>
                </div>
                <div className="participantcard">
                  <Content padding="0.3%">
                    <div style={{ display: "flex" }}>
                      <div
                        className="textinnerdiv"
                        style={{
                          color: "#2180D1",
                          marginBottom: "2%",
                          width: "50%",
                          padding: "0px 2px",
                        }}>
                        Documents
                      </div>
                      <div
                        className="textinnerdiv"
                        style={{
                          color: "#2180D1",
                          marginBottom: "2%",
                          width: "50%",
                          textAlign: "right",
                          padding: "0px 2px",
                        }}>
                        <button
                          className="link"
                          onClick={() => {
                            handleHeaderSelectChange("documents");
                          }}>
                          Manage Document{" "}
                          <HiOutlineDocumentText size={25} color={"white"} />
                        </button>
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "68.5%",
                          borderRight: "2px solid white",
                        }}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "0px",
                          }}>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              fontSize: "12px",
                            }}>
                            <span
                              style={{
                                fontSize: "40px",
                                marginTop: "-24px",
                                marginRight: "-4%",
                              }}>
                              {document?.countnothaveId &&
                                document?.countnothaveId}
                            </span>{" "}
                            <span
                              style={{
                                marginLeft: "12px",
                                marginRight: "8px",
                                marginTop: "-5px",
                              }}>
                              <HiOutlineDocumentText
                                size={25}
                                color={"white"}
                              />
                            </span>{" "}
                            Upload Pending
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              fontSize: "12px",
                            }}>
                            <span
                              style={{
                                fontSize: "40px",
                                marginTop: "-24px",
                                marginRight: "-4%",
                              }}>
                              {document?.counthaveId && document?.counthaveId}
                            </span>{" "}
                            <span
                              style={{
                                marginLeft: "12px",
                                marginRight: "8px",
                                marginTop: "-5px",
                              }}>
                              <HiOutlineDocumentText
                                size={25}
                                color={"white"}
                              />
                            </span>{" "}
                            Uploaded
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "1%",
                          }}>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              fontSize: "12px",
                            }}>
                            <span
                              style={{
                                fontSize: "40px",
                                marginTop: "-24px",
                                marginRight: "-4%",
                              }}>
                              {signData?.pendingCount
                                ? signData?.pendingCount
                                : "0"}
                            </span>{" "}
                            <span
                              style={{
                                marginLeft: "12px",
                                marginRight: "12px",
                                marginTop: "-5px",
                              }}>
                              <HiOutlineDocumentText
                                size={25}
                                color={"white"}
                              />
                            </span>{" "}
                            Pending Signature
                          </div>
                          <div
                            style={{
                              display: "flex",
                              width: "50%",
                              fontSize: "12px",
                            }}>
                            <span
                              style={{
                                fontSize: "40px",
                                marginTop: "-24px",
                                marginRight: "-4%",
                              }}>
                              {signData?.approvedCount
                                ? signData?.approvedCount
                                : "0"}
                            </span>{" "}
                            <span
                              style={{
                                marginLeft: "12px",
                                marginRight: "12px",
                                marginTop: "-5px",
                              }}>
                              <HiOutlineDocumentText
                                size={25}
                                color={"white"}
                              />
                            </span>{" "}
                            Pending Share Request
                          </div>
                        </div>
                      </div>
                      {/* <div style={{ width: "30%", marginLeft: "2%", marginTop: "0.5%" }}>
                    <div style={{ display: 'flex', width: "100%" }}>
                      <div style={{ width: "40%", fontSize: "12px" }}>PO</div>
                      <div style={{ width: "20%", color: "#2180D1" }}>-</div>
                      <div style={{ width: "40%" }}></div>
                    </div>
                    <div style={{ display: 'flex', width: "100%", marginTop: "4%" }}>
                      <div style={{ width: "40%", fontSize: "12px" }}>Invoice</div>
                      <div style={{ width: "20%", color: "#2180D1" }}>-</div>
                      <div style={{ width: "40%" }}></div>
                    </div>
                    <div style={{ display: 'flex', width: "100%", marginTop: "4%" }}>
                      <div style={{ width: "40%", fontSize: "12px" }}>Finance Deal</div>
                      <div style={{ width: "20%", color: "#2180D1" }}>-</div>
                      <div style={{ width: "40%" }}></div>
                    </div>
                  </div> */}
                    </div>
                  </Content>
                </div>
                {(companyId === subStageDate.buyerMemberId ||
                  companyId === subStageDate.sellerMemberId) && (
                  <PaymentTerm subStageDate={subStageDate} data={data} />
                )}
              </Content>
            </Container>
          </div>
        )
      )}
    </div>
  );
};
export default withSendBird(Summary, mapStoreToProps);
