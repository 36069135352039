
import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";
import { Industry } from "utils/Section/trade";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from 'jsons/Currenciesr.json';
import { CheckCategoryTitle, CheckCountryTitle, CheckCurrencyTitle ,CheckPortTitle } from "utils/helper";
const CustomClearance = ({ response }) => {
  let incoterm = [
    {
      label: "EXW",
      value: "EXW",
    },
    {
      label: "DDP (Delivered Duty Paid)",
      value: "DDP (Delivered Duty Paid)",
    },
    {
      label: "FOB (Free on Board)",
      value: "FOB (Free on Board)",
    },
    {
      label: "CIF (Cost, Insurance and Freight) ",
      value: "CIF (Cost, Insurance and Freight)",
    },
    {
      label: "FAS (Free Alongside Ship)",
      value: "FAS (Free Alongside Ship)",
    },

  ]
  let paymentTerms = [
    {
      label: "Advance Payment",
      value: "Advance Payment",
    },
    {
      label: "Letter of credit",
      value: "Letter of credit",
    },
    {
      label: "Documents against Payments - D.A.P or D/P basis",
      value: "Documents against Payments - D.A.P or D/P basis",
    },
    {
      label: "Documents against Acceptance (D/A)",
      value: "Documents against Acceptance (D/A)",
    },
    {
      label: "Open Account",
      value: "Open Account",
    },

  ]


  const [serviceDetails, setServiceDetails] = useState([]);
  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails))

      //JSON.parse(response.ServiceReqDetails.commodity)
    }
  }, [response]);

  const renderList = JSON.stringify(serviceDetails.commodity)
  let listCommodity = serviceDetails.commodity?.map((employee, index) => {
    return (
      employee.value
    )
  })

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  return (
    <>
      {response && serviceDetails ?
        <>


          <div style={{ margin: "10px" }}>Customs Services Required For ?
          </div>

          <div className={classes.source} style={{ margin: "10px" }}>
            <div style={{ marginLeft: "1%", width: "50%" }}>


              <input
                className={classes.radio}
                type="radio"
                name="serviceType"
                value="IMPORT"
                // onChange={handleRadio}
                checked={serviceDetails.serviceType == "IMPORT"}
                readOnly={true}
              />
              <label>IMPORT</label>
            </div>  <div
              style={{
                // fontSize: "25px",
                marginLeft: "1%",
                width: "50%",
                textAlign: "right",
                marginRight: "1%",
              }}
            >
              <input
                className={classes.radio}
                type="radio"
                name="serviceType"
                value="EXPORT"

                //  onChange={handleRadio}
                checked={serviceDetails.serviceType == "EXPORT"}
                readOnly={true}
              />
              <label>EXPORT</label>
            </div>
          </div>
          <div style={{ margin: "10px" }}>
            Shipment details
          </div>


          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
            <Input
                options={Countries}
                name="countryFrom"
                placeholder="Country  From"
                readOnly={true}
                value={CheckCountryTitle(serviceDetails.countryFrom)}
              //  readOnly={true}
              />
            </div>


            <div style={{ width: "50%", margin: "10px" }}>
            <Input
                options={Countries}
                name="countryTo"
                placeholder="Country To"
                readOnly={true}
                value={CheckCountryTitle(serviceDetails.countryTo)}
              // readOnly={true}
              />
            </div>
          </div>
          {/* Countries */}
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input

                name="cityFrom"
                placeholder="City From"
                readOnly={true}
                value={serviceDetails && serviceDetails.cityFrom}
              // readOnly={true}
              />
            </div>


            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="cityTo"
                placeholder="City To"
                readOnly={true}
                value={serviceDetails && serviceDetails.cityTo}
              // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
            <Input
              //  options={port_code}
                name="portOrigin"
                placeholder="From Port"
                readOnly={true}
              //value ={  CheckPortTitle(serviceDetails.portOrigin)}
                value={serviceDetails && serviceDetails.portOrigin}
              />
            </div>


            <div style={{ width: "50%", margin: "10px" }}>
            <Input
             //   options={port_code}
                name="destinationPort"
                placeholder="To Port"
                value={serviceDetails && serviceDetails.destinationPort}
                readOnly={true}
              />
            </div>
          </div>


          {/* <div style={{ marginBottom: "1.2%" }}>
                <label>Customs Clearance Inspection Services required for ?
</label>
              </div>
              <div style={{ width: "50%", marginBottom:"2%" }}>
              <MultiSelect
                  //////options={incoterm}{incoterm}{incoterm}{inspectionType}
                  name="inspectionType"
                  placeholder="Inspection Type"
                  handleChange={(e) => handleMultiChange(e, "commodity")}
                  value={serviceDetails && serviceDetails.inspectiontype}
                // readOnly={true}
                />
              </div> */}

          <div>
            <div>
              <div style={{ margin: "10px" }}>
                <label>COMMODITY</label>
              </div>
                <div style={{ margin: "10px", width: "50%", paddingRight:"20px" }}>


              
                  <Input
                    options={Industry}
                    name="commodity"
                    placeholder="Product Category"

                    // handleChange={(e) => handleMultiChange(e, "commodity")}
                    value={listCommodity}
                    //{serviceDetails.commodity}




                    readOnly={true}
               
                  />
                </div>
              
            </div>

            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="ProductName"
                  placeholder="Product Name"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ProductName}
                // readOnly={true}
                />
             </div>
              {/* : [Insert Product Name]
             : [Insert Product Description]
              : []
              Shipper Address : [ Insert Address ] */}

              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="productDescription"
                  placeholder="Product Description"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.productDescription}
                // readOnly={true}
                />
              </div>
            </div>
            {/* <label>Shipment Details</label> */}
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="ShipperName"
                  placeholder="Shipper Name"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ShipperName}
                // readOnly={true}
                />
              </div>


              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="ReceiverName"
                  placeholder="Receiver Name"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ReceiverName}

                // readOnly={true}
                />


              </div>
            </div>



            <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="ShipperAddress"
                placeholder="Shipper Address"
                readOnly={true}
                value={serviceDetails && serviceDetails.ShipperAddress}

              />
            </div>
            </div>
            <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="ReceiverAddress"
                placeholder="Receiver Address "
                readOnly={true}
                value={serviceDetails && serviceDetails.ReceiverAddress}
              // readOnly={true}
              />
            </div>
            </div>
            <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
              <Input
                name="hsnOfCode"
                placeholder="HSN code of Goods"
                readOnly={true}
                value={serviceDetails && serviceDetails.hsnOfCode}
              // readOnly={true}
              />
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
              <Input
                  options={Currenciesr}
                  name="currencyType"
                  placeholder="Currency"
                  value={ CheckCurrencyTitle(serviceDetails.currencyType)}
                  readOnly={true}
                />

              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="currencyAmount"
                  placeholder=  {`Trade Amount (${serviceDetails && serviceDetails.currencyType})`}
                
                  readOnly={true}
                  value={serviceDetails && serviceDetails.currencyAmount}
                //readOnly={true}
                />
              </div>
            </div>




            <div className={classes.source}>


            </div>
          </div>










          <div >
            {/* <label>Payment Terms

</label> */}
              </div>
              
            <div className={classes.source}>
              <div style={{ width: "50%", margin:"10px" }}>
              <Input
                 options={paymentTerms}
                  name="paymentTerms"
                  placeholder="Payment Terms"
                  value={serviceDetails && serviceDetails.paymentTerms}
                  readOnly={true}
                />
              </div>

             
              <div style={{  width: "50%", margin:"10px" }}>
              <Input
                 options={incoterm}
                  name="incoterm"
                  placeholder="Incoterm"
                  value={serviceDetails && serviceDetails.incoterm}
                  readOnly={true}
                />
                {/* <textarea
                  style={{ height: "7rem", width: "100%" , background :"Black"}}
                  type="textarea"
                  name="requirement"
                  value={serviceDetails && serviceDetails.requirement}
                  onChange={(e) => handleChange(e, "requirement")}
                /> */}
          </div>





              </div>














        </> : <></>
      }
    </>
  )
}

export default CustomClearance