import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import style from "./service.module.css";
import SelectWithSearch from "components/fields/SelectWithSearch";
import CountryCodes from "jsons/CountryCodes.json";
import Content from "components/UI/Layout/Content";
import ServiceList from "components/UI/NetworkSection/ServiceCard";
import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import { CompanyList } from "utils/API/CompanyList.js";
import { ServiceType } from "utils/DropDown/ServiceType";
import Modal from "components/UI/Modal/Modal";
import AddService from "./AddService";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import Popover from "react-bootstrap/Popover";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import cn from "classnames";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { SearchServices, SearchServicesResult } from "utils/Section/FormInfo";
import { fetchMemberdata } from "utils/memberchat";
import { TRADXLINK_SUPPORT_ID } from "services/BaseURLInstance";
import { AuthContext } from "../../.././contexts/Auth/AuthContext";
import MultiSelect from "components/fields/MultiSelect";
import LimitedApi from "components/UI/Modal/LimitedApi";

export default ({ toggle, isOpen, toggleChat, hanndleCraateGroup }) => {
  const { instance, accounts } = useMsal();
  const CompanyObject = CompanyList();
  const location = useLocation();
  const navigate = useNavigate();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [servicesList, setServicesList] = useState(null);
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  const [filterList, setFilterList] = useState(null);
  const [Role, setRole] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    location.state?.category[0]?.country
      ? location.state?.category[0]?.country
      : null
  );
  const [displayList, setDisplayList] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(
    location.state?.category[0]?.serviceCategory
      ? location.state?.category[0]?.serviceCategory
      : null
  );
  const [selectedSubCategory, setSelectedSubCategory] = useState(
    location.state?.category[0]?.serviceSubCategory
      ? location.state?.category[0]?.serviceSubCategory
      : null
  );
  const [offerService, setOfferService] = useState(null);
  const [isLimited, setIsLimited] = useState(false);

  const [createReqRole, setSCreateReqRole] = useState(null);
  const [viewServiceRole, setViewServiceRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [selectedList, setSelectedList] = useState([]);

  const [isAddService, setIsAddService] = useState(false);

  const [isDelete, setIsDelete] = useState(false);
  const [isServiceDelete, setServiceDelete] = useState(null);
  const [isDeleteLoading, setDeleteLoading] = useState(false);

  const [isUpdatingData, setIsUpdatingData] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSelectedError, setIsSelectedError] = useState(null);
  const [isMyList, setMyList] = useState(false);

  const [industriesOptions, setIndustriesOptions] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedIndustryId, setSelectedIndustryId] = useState(null);

  const { serviceType, serviceSubType } = ServiceType(selectedCategory || null);
  const {
    Token,
    personName,
    companyName,
    isAuthenticated,
    loginCompanyData,
    setLoginCompanyData,
  } = useContext(AuthContext);

  const handleChangeCountry = (e) => {
    setSelectedCountry(e.target.value);
  };

  const RequestFunction = async () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(`${process.env.REACT_APP_SERVICE_URL}services`, null, response)
        .then((data) => {
          setIsLoading(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message);
          } else {
            setServicesList(data);
            setFilterList(data);
          }
        })
        .catch((error) => {
          if (error.message === "API_LIMIT_EXCEEDED") {
            setIsLimited(true);
          } else {
            Error(error);
          }
        });
    });
  };

  useEffect(() => {
    if (servicesList) {
      // Industry
      // Flatten the nested arrays into a single flat array
      const flattenedArray = servicesList.flatMap((item) => item.industry);

      // Create a Set to store unique values
      const uniqueValuesSet = new Set(flattenedArray);

      // Convert the Set back to an array to get unique values
      const uniqueCategory = [...uniqueValuesSet];

      let filterCategory =
        uniqueCategory &&
        uniqueCategory.map((item) => {
          return { label: item, value: item };
        });
      setIndustriesOptions(filterCategory);
    }
  }, [servicesList]);

  useEffect(() => {
    if (Role) {
      setSCreateReqRole(
        CheckPermission(
          "CreateRequisition",
          "ServicesSection",
          "createRequisition",
          Role
        )
      );
    }
    if (Role) {
      setOfferService(
        CheckPermission(
          "OfferNewServices",
          "ServicesSection",
          "offerNewServices",
          Role
        )
      );
    }
    if (Role) {
      setViewServiceRole(
        CheckPermission(
          "ViewmyServices",
          "ServicesSection",
          "viewmyServices",
          Role
        )
      );
    }
  }, [Role]);

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);

    if (!isCancelled) {
      RequestFunction();
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const handleChat = async () => {
    let memberUserId = [companyId, TRADXLINK_SUPPORT_ID];
    let res = await fetchMemberdata(memberUserId);
    let name = `Support-${companyName}`;
    if (res?.length > 0) {
      hanndleCraateGroup(res, name, name);
    }
  };

  const handleCreateRequisition = (e, value) => {
    let temp = [];
    temp.push(value);
    temp.push(location.state);
    navigate("./requisition", { state: temp });
  };

  const handleCheckbox = (click, value) => {
    if (selectedList && selectedList.length > 0 && value) {
      let filterData = selectedList.filter(
        (item) => item.serviceId === value.serviceId
      );

      if (filterData.length > 0) {
        let filterData = selectedList.filter(
          (item) => item.serviceId !== value.serviceId
        );
        setSelectedList(filterData);
      } else {
        setSelectedList([...selectedList, value]);
      }
    } else {
      setSelectedList([...selectedList, value]);
    }
  };

  const handleRequisitionNavigation = () => {
    if (selectedCategory && selectedSubCategory) {
      navigate("./category", {
        state: { selectedList: selectedList, tradeArray: location.state?.data },
      });
    } else {
      if (!selectedCategory) {
        Error("Please Filter Category");
      }
      if (!selectedSubCategory) {
        Error("Please Filter Sub-Category");
      }
    }
  };

  useEffect(() => {
    if (servicesList) {
      if (
        selectedCountry ||
        selectedCategory ||
        selectedSubCategory ||
        selectedIndustryId
      ) {
        let filtered =
          servicesList &&
          servicesList.filter(
            (data) =>
              (selectedCountry
                ? selectedCountry.includes(data.countriesOfOperations[0])
                : data) &&
              (selectedCategory
                ? selectedCategory.includes(data.serviceCategory[0])
                : data) &&
              (selectedSubCategory
                ? selectedSubCategory.includes(data.serviceSubCategory[0])
                : data) &&
              (selectedIndustryId
                ? selectedIndustryId.every((item) =>
                    data.industry.includes(item)
                  )
                : data)
          );
        setFilterList(filtered);
      } else if (
        selectedCountry &&
        selectedCategory &&
        selectedSubCategory &&
        selectedIndustryId
      ) {
        setFilterList(servicesList);
      }
    }
  }, [
    servicesList,
    selectedCountry,
    selectedCategory,
    selectedSubCategory,
    selectedIndustryId,
  ]);

  const handleChangeCategory = (e) => {
    {
      selectedCountry && setSelectedCategory(e.target.value);
      setSelectedSubCategory(null);
      setSelectedList([]);
    }
  };

  const handleChangeSubCategory = (e) => {
    setSelectedSubCategory(e.target.value);
    setSelectedList([]);
    setDisplayList(true);
  };

  const handleClearSearch = () => {
    setSelectedCountry(null);
    setSelectedCategory(null);
    setSelectedIndustry(null);
    setSelectedSubCategory(null);
    setFilterList(servicesList);
    // setDisplayList(false);
    setDisplayList(true);
  };

  useEffect(() => {
    if (selectedCategory && selectedSubCategory) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setIsSelectedError(null);
    setMyList(false);
  }, [selectedCategory, selectedSubCategory]);

  const handleServiceUpdate = (data) => {
    setIsAddService(true);
    setIsUpdatingData(data);
  };

  const Subscribe = () => {
    setDisplayList(false);
    console.log("displayList :", displayList);
    if (!selectedCountry) {
      Error("Please Select a Country");
      setIsSelectedError("Country");
    } else if (!selectedCategory) {
      Error("Please Select a Category");
      setIsSelectedError("Category");
    } else if (!selectedSubCategory) {
      setIsSelectedError("Sub-Category");
      Error("Please Select a Sub-Category");
    } else {
      setIsSelectedError(null);
    }
  };

  const MyServiceList = () => {
    if (isMyList) {
      setMyList(false);
      setFilterList(servicesList);
    } else {
      if (servicesList) {
        let filtered =
          servicesList &&
          servicesList.filter((data) => data.memberId === companyId);
        setFilterList(filtered);
        setMyList(true);
        setSelectedCountry(null);
        setSelectedCategory(null);
        setSelectedSubCategory(null);
      }
    }
  };

  const handleServiceDelete = (serviceId) => {
    setServiceDelete(serviceId);
    setIsDelete(true);
  };

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
          console.log(data, "newdata");
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  const ServiceDelete = (e) => {
    e.preventDefault();
    setDeleteLoading(true);

    let URL = `${process.env.REACT_APP_SERVICE_URL}services/${isServiceDelete}?memberUID=${companyId}`;
    const options = {
      method: "DELETE",
      body: {},
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setDeleteLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
          setIsError(message);
        } else {
          RequestFunction();
          Success("Deleted Successfully");
          setIsDelete(false);
        }
      });
    });
  };

  const handleMultiChange = (e, name) => {
    if (name === "industry") {
      const { value } = e.target;
      setSelectedIndustry(value);
    }
  };

  useEffect(() => {
    if (selectedIndustry) {
      let temporaryList = [];
      selectedIndustry.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedIndustryId(temporaryList);
    }
  }, [selectedIndustry]);

  return (
    <>
      <LimitedApi isLimited={isLimited} setIsLimited={setIsLimited} />
      <Modal
        show={isAddService}
        onHide={() => setIsAddService(false)}
        Data={
          <AddService
            isUpdatingData={isUpdatingData}
            setIsAddService={setIsAddService}
            RequestFunction={RequestFunction}
            setIsLimited={setIsLimited}
          />
        }
        Heading={isUpdatingData ? "Update Service" : "Add Service"}
      />
      <Modal
        show={isDelete}
        onHide={() => {
          setServiceDelete(null);
          setIsDelete(false);
        }}
        Heading="Are you sure you want to delete?"
        Data={
          <>
            {isDeleteLoading ? (
              <Loader />
            ) : (
              <div className="d-flex flex-row-reverse">
                <Button onClick={(e) => ServiceDelete(e)}>Confirm</Button>
              </div>
            )}
          </>
        }
      />

      <Content marginBottom="2%" padding="1% 2% 1% 2% ">
        <div>
          <h5>
            <OverLayTrigger value={SearchServices} />
          </h5>
        </div>
        <div>
          <div className="my-3">
            <span>
              {isSelectedError ? (
                <span>
                  {`Please select the filters below to proceed:`}
                  <span className="mx-2" style={{ color: "red" }}>
                    {isSelectedError}
                  </span>
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
          <div className={cn(style.searchDiv, "d-flex")}>
            <div>
              <div className="d-flex">
                <div className={cn(style.selectDiv)}>
                  <SelectWithSearch
                    disabled={
                      createReqRole === "NOTAPP" || createReqRole === "DELETE"
                        ? true
                        : false
                    }
                    placeholder={
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        key="top"
                        placement="top"
                        overlay={
                          <Popover id="popover-positioned-top">
                            <Popover.Body>
                              <strong>Country where you require service</strong>
                            </Popover.Body>
                          </Popover>
                        }>
                        <span>
                          Select Country * <AiOutlineInfoCircle size={26} />
                        </span>
                      </OverlayTrigger>
                    }
                    value={selectedCountry}
                    options={CountryCodes}
                    handleChange={(e) => handleChangeCountry(e)}
                  />
                </div>
                <div className={cn(style.selectDiv)}>
                  <SelectWithSearch
                    disabled={
                      createReqRole === "NOTAPP" || createReqRole === "DELETE"
                        ? true
                        : false
                    }
                    placeholder="Service Category *"
                    value={selectedCategory}
                    options={
                      serviceType &&
                      serviceType.sort((a, b) => a.label.localeCompare(b.label))
                    }
                    handleChange={(e) => handleChangeCategory(e)}
                  />
                </div>
              </div>
              <div className="d-flex">
                <div className={cn(style.selectDiv)}>
                  <SelectWithSearch
                    disabled={
                      createReqRole === "NOTAPP" || createReqRole === "DELETE"
                        ? true
                        : false
                    }
                    placeholder="Service Sub-Category *"
                    value={selectedSubCategory}
                    options={
                      serviceSubType &&
                      serviceSubType.sort((a, b) =>
                        a.label.localeCompare(b.label)
                      )
                    }
                    handleChange={(e) => handleChangeSubCategory(e)}
                  />
                </div>
                <div className={cn(style.selectDiv)}>
                  <MultiSelect
                    name="industry"
                    selected={selectedIndustry}
                    placeholder="Industry"
                    options={industriesOptions}
                    handleChange={(e) => handleMultiChange(e, "industry")}
                  />
                </div>
              </div>
              <div className="d-flex mt-2">
                <Button onClick={handleClearSearch}>Clear Selections</Button>
              </div>
            </div>
            <div>
              <div
                className={cn(
                  style.NewButtonDiv,
                  "d-flex  justify-content-between"
                )}
                style={{ flexDirection: "column" }}>
                {memberType !== "TRADER" && (
                  <>
                    <button
                      disabled={offerService === "CREATE" ? false : true}
                      onClick={() => {
                        setIsAddService(true);
                        setIsUpdatingData(null);
                      }}>
                      + Offer New Service
                    </button>
                    <button
                      disabled={
                        viewServiceRole === "NOTAPP" ||
                        viewServiceRole === "DELETE"
                          ? true
                          : false
                      }
                      onClick={() => {
                        MyServiceList();
                      }}
                      style={isMyList ? { opacity: "0.5" } : {}}>
                      View Offered Services
                    </button>
                  </>
                )}
                {selectedSubCategory === null ? (
                  <button
                    disabled={createReqRole === "CREATE" ? false : true}
                    onClick={() => {
                      Subscribe();
                    }}>
                    + Create Service Requisition
                  </button>
                ) : (
                  <button className={style.isDisabled}>
                    + Create Service Requisition
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Content>

      <Content padding="0.3% 2% 0.3% 2%">
        <div className={style.servicesHeader}>
          <h6 style={{ margin: "0px" }}>
            <OverLayTrigger value={SearchServicesResult} />
          </h6>

          {selectedList && selectedList.length > 0 && (
            <>
              <ButtonDiv>
                <Button
                  disabled={createReqRole === "CREATE" ? false : true}
                  onClick={() => handleRequisitionNavigation()}>
                  Continue Create Requisition
                </Button>{" "}
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Body>
                        <strong>Create service requisition</strong>
                      </Popover.Body>
                    </Popover>
                  }>
                  <span className="text-warning">
                    <AiOutlineInfoCircle size={26} />
                  </span>
                </OverlayTrigger>
              </ButtonDiv>
            </>
          )}
        </div>

        {isLoading || CompanyObject.isLoading ? (
          <Loader />
        ) : (
          <>
            {filterList &&
              displayList &&
              CompanyObject &&
              CompanyObject.response &&
              filterList.map((item, index) => (
                <div key={index}>
                  <ServiceList
                    item={item}
                    companyId={companyId}
                    companyList={CompanyObject && CompanyObject.response}
                    selectedCategory={selectedCategory}
                    handleCheckbox={handleCheckbox}
                    selectedList={selectedList && selectedList.length}
                    handleCreateRequisition={handleCreateRequisition}
                    isCheckboxHide={false}
                    isDisabled={isDisabled}
                    handleServiceUpdate={handleServiceUpdate}
                    handleServiceDelete={handleServiceDelete}
                    toggle={toggle}
                    isOpen={isOpen}
                    toggleChat={toggleChat}
                    hanndleCraateGroup={hanndleCraateGroup}
                    handleChat={handleChat}
                  />
                </div>
              ))}

            {filterList && filterList.length === 0 && (
              <div className="alert alert-danger" role="alert">
                <span>No Record Found</span>
              </div>
            )}

            {(CompanyObject.isError || isError) && (
              <div className="alert alert-danger" role="alert">
                {CompanyObject.isError || isError}
              </div>
            )}
          </>
        )}
      </Content>
    </>
  );
};
