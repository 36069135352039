import React from 'react';
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Select from "components/fields/Select";
import Currenciesr from '../../../jsons/Currenciesr.json'
import { useState } from 'react';
import { invoiceURL } from "services/BaseURLInstance";
import Loader from 'components/Loader/Loader';

export const EditSummary = ({ setInvoiceData, data, setEditSummaryCheck, editSummaryCheck }) => {
    const [isLoading, setIsLoading] = useState(false);
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const [editSummaryData, setEditSummaryData] = useState({
        invoiceValue: data?.txlInvoiceAmount && parseFloat(data?.txlInvoiceAmount),
        invoiceNumber: data?.invoiceNumber,
        invoiceCurrency: data?.txlCurrencyCode,
    })

    const handleChange = (e) => {
            setEditSummaryData({
                ...editSummaryData,
                [e.target.name]: e.target.value,
            })
    }
    const handleSubmitDetails = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        let general = JSON.parse(localStorage.getItem("uploadData"));
        general.txlInvoiceAmount = editSummaryData.invoiceValue
        general.invoiceNumber = editSummaryData.invoiceNumber
        general.txlCurrencyCode = editSummaryData.invoiceCurrency
        localStorage.setItem("uploadData", JSON.stringify(general));
        const requestOptions = {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
            body: localStorage.getItem("uploadData"),
        };
        const response = await fetch(`${invoiceURL}invoices/${data.txlInvoiceId}`, requestOptions);
        if (response.status >= 200 && response.status < 300) {
            const data = await response.json();
            setIsLoading(false);
            setInvoiceData(data);
            setEditSummaryCheck(!editSummaryCheck);
            window.location.reload();
        } else if (response.status === 409) {
            setIsLoading(false);
            Error("Invoice is already Created");
        } else {
            setIsLoading(false);
            Error("Fail to fetch from API");
        }
    }

    return (
        <>
            {  isLoading ? <Loader/> 
            :   <div style={{ marginTop: "2%" }}>
                    <Grid container>
                        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                            <Input
                                name="invoiceValue"
                                placeholder="Invoice Value"
                                value={editSummaryData.invoiceValue}
                                handleChange={(e) => handleChange(e)}
                                required={true}
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                            <Input
                                name="invoiceNumber"
                                placeholder="Invoice Number"
                                value={editSummaryData.invoiceNumber}
                                handleChange={(e) => handleChange(e)}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                            <Select
                                options={Currenciesr}
                                name="invoiceCurrency"
                                placeholder="Invoice Currency"
                                handleChange={(e) => handleChange(e)}
                                value={editSummaryData.invoiceCurrency}
                                required={true}
                            />
                        </Grid>
                    </Grid>
                    <ButtonDiv>
                        <Button
                            type="submit"
                            onClick={(e) => handleSubmitDetails(e)}
                        >
                            Submit
                        </Button>
                    </ButtonDiv>
                </div>
            }
        </>
    )
}