import React, { useState } from "react";
import Container from "components/UI/Layout/Container";
import styles from "pages/Documents/dashboard.module.css";
import { DocumentsServiceDealDashboard } from "utils/Config";
import AllDocument from "./Document";

const DocumentIndex = ({ serviceDealDetails }) => {
  const [hide, setHide] = useState(null);
  const [CategoryListURL, setCategoryListURL] = useState(null);

  return (
    <Container>
      {!hide ? (
        <div className={styles.dashboardWrapper}>
          <h5>Documents</h5>
          <div className={styles.dashboardSolution}>
            {DocumentsServiceDealDashboard.map((item) => (
              <div className={styles.solutionCard}>
                <div
                  onClick={() => setHide(`${item.path}`)}
                  className={styles.content}
                  style={{ cursor: "pointer" }}>
                  <div className={styles.content_icons}>{item.icon}</div>
                  <h6>{item.name}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <AllDocument
          serviceDealDetails={serviceDealDetails}
          hide={hide}
          setHide={setHide}
          CategoryListURL={CategoryListURL}
          setCategoryListURL={setCategoryListURL}
        />
      )}
    </Container>
  );
};

export default DocumentIndex;
