import React, { useState, useEffect, useCallback } from "react";
import Container from "components/UI/Layout/Container";
import { useNavigate } from "react-router-dom";
import styles from "./dashboard.module.css";
import { useParams } from "react-router-dom";
import { DocumentsDashboard } from "utils/Config";
import { Routes, Route } from "react-router-dom";

import TradeDocuments from "pages/Document/TradeDocuments";
import CompanyDocuments from "pages/Document/index";
import Director from "pages/Document/DirectorDocument";
import Consent from "pages/Document/consent/index";
import DocumentFinder from "components/documentFinder/documentFinder";
import { BsArrowLeft } from "react-icons/bs";

export default () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const [hide, setHide] = useState("");
  let path = useParams();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setHide(path["*"]);
    }
    return () => {
      isCancelled = true;
    };
  }, [path["*"]]);

  return (
    <>
      <Container>
        <Routes>
          <Route path="/trade/*" element={<TradeDocuments />} />
          <Route path="/company/*" element={<CompanyDocuments />} />
          <Route path="/director/*" element={<Director />} />
          <Route path="/consent/*" element={<Consent />} />
          <Route
            path="/searchDocument/*"
            element={
              <Container padding="1%">
                <button
                  style={{
                    padding: "1%",
                    color: "#42a5f5",
                    backgroundColor: "transparent",
                    border: "none",
                  }}
                  onClick={() => navigate(-1)}>
                  <BsArrowLeft /> Back To Documents
                </button>
                <DocumentFinder enableButton={false} />
              </Container>
            }
          />
        </Routes>
        {hide === "" && (
          <div className={styles.dashboardWrapper}>
            <h5>Documents</h5>
            <div className={styles.dashboardSolution}>
              {DocumentsDashboard.map((item, index) => (
                <div className={styles.solutionCard} key={index}>
                  <div
                    onClick={() => handleNavigate(`.${item.path}`)}
                    className={styles.content}
                    style={{ cursor: "pointer" }}>
                    <div className={styles.content_icons}>{item.icon}</div>
                    <h6>{item.name}</h6>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </Container>
    </>
  );
};
