import React, { useState, useEffect } from 'react'
import TableNoChat from "components/table/TableNoChat";
import styles from './syndicate.module.css'
import { FinanceColumn } from "./column";
import { Button } from '@mui/material';

function FinancerTable({ data, companyId }) {
    console.log("frt",data)
    const [trancheDataTable, setTrancheDataTable] = useState([]);
    const changeFinancer = data.changeFinancer;
    const handleAccept = data.handleAccept;
    const handleReject = data.handleReject;
    const showTable = data.showTable;
    const setShowTable = data.setShowTable;
    const editTranche = data.editTranche;
    const backFormTranchTable = ()=>{
        data.setListFinancer(true)
    }


    const enableDrawdown = () => {
        data.showDrawdown();
        setShowTable(false)
    }

    const addDrawdown = () => {
        data.addDrawdown();
        setShowTable(false)
    }
 

    const removeData = (e, item) => {
        data.removeFinancer(e, item)
    }
    useEffect(()=>{

    },[data])
    return (
        <>
            {(showTable) &&
                <>
                    <div className={styles.wrapper}>
                        <div className={styles.tableContainer}>
                            {Array.isArray(data.trancheData || data) ? (
                                <TableNoChat financerMemberUID={data?.apiData?.financerMemberUID} columns={FinanceColumn(editTranche, removeData, companyId, data?.apiData?.financerMemberUID, handleAccept, handleReject)} data={data.trancheData} companyId={companyId} />
                            ) : (
                                <p>No Records</p>
                            )}
                        </div>
                    </div>
{/* {  data.trancheData &&} */}
                    {(data?.apiData?.loanTranches[data?.idx]?.loanTrancheStatus==="DRAFT") && (data.tranchelist?.length>0 && data?.apiData?.financerMemberUID === data?.apiData?.companyId) &&(
                  
                           <div style={{textAlign:"right" }}>
                           
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "150px", margin: "0px 5px" }}
                                onClick={() => backFormTranchTable()}
                            >
                                Back
                            </Button>



                            <Button
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "170px", margin: "0px 5px" }}
                                onClick={() => changeFinancer()}
                            >
                                Create Tranche Item
                            </Button>
                           


                        </div>
                    )}
                     <div style={{textAlign:"right" }}>
                    <Button
                                variant="contained"
                                color="primary"
                                style={{ minWidth: "170px", margin: "0px 5px" }}
                                onClick={() => enableDrawdown()}
                            >
                                Create Drawdown
                            </Button>
                            </div>
                </>
            }
        </>
    )
}

export default FinancerTable