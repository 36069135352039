import React, { useState, useEffect } from 'react'
import {
    Card,
    Grid,
    Checkbox,
    Badge,
    Button
} from '@mui/material';

import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import styles from "./trade.module.css";
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import moment from 'moment';
import Select from "components/fields/Select";
import MultiSelect from "components/fields/MultiSelect";
import { CgDanger } from "react-icons/cg";
import { TiTick } from "react-icons/ti";
import { BsQuestion } from "react-icons/bs";
import { MdAdd } from "react-icons/md";

function Schedules(props) {
    const [type, setType] = useState('summary');
    const [save, setSave] = useState(true);

    const handleType = (e) => {
        setType(e);
    }

    const api_json = {
        milestone: "",
        payment_due_date: new Date().toLocaleDateString,
        document_type: "",
        payment_percent: "",
        payment_amount: "",
        notes: "",
        total_po_value: "",
        industry: "",
        owner: []
    }

    const [apiData, setApiData] = useState(api_json)
    const [DatePicker1, setDatePicker1] = useState(new Date());

    const handleChange = (e, v) => {
        if (v === "owners") {
            apiData[v] = e;
            setApiData({ apiData });
            return;
        }
        const { name, value } = e.target;
        apiData[name] = value;
        setApiData({ apiData });
    };

    const handleDateChange = (event, name) => {
        if (name === 'payment_due_date') {
            let newDate = new Date(event)
            let dateString = newDate.toISOString();

            setApiData({
                ...apiData,
                [name]: dateString,
            })

            let newDate1 = moment(new Date(event)).format('YYYY-MM-DD')
            if (name === 'payment_due_date') {
                setDatePicker1(newDate1)
            }
        }
    }

    const select_json = [
        { "label": "Chemical", "value": "chemical" },
        { "label": "Chemical1", "value": "chemical1" },
        { "label": "Chemical2", "value": "chemical2" }
    ]

    const Options = [
        { value: "ocean", label: "Ocean", color: "#00B8D9" },
        { value: "blue", label: "Blue", color: "#0052CC" },
        { value: "purple", label: "Purple", color: "#5243AA" },
        { value: "red", label: "Red", color: "#FF5630" },
        { value: "orange", label: "Orange", color: "#FF8B00" },
        { value: "yellow", label: "Yellow", color: "#FFC400" },
        { value: "green", label: "Green", color: "#36B37E" },
        { value: "forest", label: "Forest", color: "#00875A" },
        { value: "slate", label: "Slate", color: "#253858" },
        { value: "silver", label: "Silver", color: "#666666" }
    ];

    const names = [
        'Oliver Hansen',
        'Van Henry'
    ];

    const Tab3 = () => {
        props.enableSchedulePayment()
    }

    useEffect(() => {
        if (props.enableSchedulePayment === true) {
            setSave(false)
        }
    });
    return (
        <div>
            <Card style={{
                backgroundColor: "black",
                padding: "25px 25px 15px 25px",
                borderRadius: "5px",
            }}>
                <button type="button" className={styles.collapsible} onClick={() => handleType('summary')}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <b style={{ fontSize: "20px", fontWeight: "700", color: "white" }}>
                            MileStone 1
                        </b>
                        {type == 'summary' ? <FiChevronUp size={25} color="#42A5F5" /> : <FiChevronDown size={25} />}
                    </div>
                </button>
                {type === 'summary' &&
                    <Card
                        style={{
                            backgroundColor: "#343434",
                        }}>
                        <Grid container>
                            <Grid item xs={12} md={12} style={{ padding: "10px" }}>
                                <h4 style={{ color: "#42A5F5" }}> Details </h4>
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                                <Select
                                    options={select_json}
                                    name="milestone"
                                    placeholder="Milestone"
                                    handleChange={handleChange}
                                    value={apiData?.apiData?.milestone}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                                <Select
                                    options={select_json}
                                    name="document_type"
                                    placeholder="Document Type"
                                    handleChange={handleChange}
                                    value={apiData?.apiData?.document_type}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                                <Input
                                    name="payment_percent"
                                    placeholder="Pament Percent"
                                    required={true}
                                    value={apiData.payment_percent}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                                <Input
                                    name="payment_amount"
                                    placeholder="Payment Amount"
                                    required={true}
                                    value={apiData.payment_amount}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                                <Input
                                    name="notes"
                                    placeholder="Notes"
                                    required={true}
                                    value={apiData.notes}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                                <Select
                                    options={select_json}
                                    name="issuer"
                                    placeholder="Issuer"
                                    handleChange={handleChange}
                                    value={apiData?.apiData?.issuer}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                                <Select
                                    options={select_json}
                                    name="payment_currency"
                                    placeholder="Payment Currency"
                                    handleChange={handleChange}
                                    value={apiData?.apiData?.payment_currency}
                                />
                            </Grid>
                            <Grid item xs={12} md={3} style={{ padding: "10px 20px 10px 10px" }}>
                                <DateInput
                                    placeholder="Payment Due Date"
                                    value={DatePicker1}
                                    handleChange={(value) => handleDateChange(value, "payment_due_Date")}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ padding: "10px" }}>
                                <h4 style={{ color: "#42A5F5" }}> Participants </h4>
                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: "10px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CgDanger size={22} style={{ color: "white" }} />
                                    <h4 style={{ color: "white", marginLeft: "10px" }}> Owner </h4>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} style={{ padding: "10px" }}>
                                <MultiSelect
                                    name="owners"
                                    selected={apiData.owner}
                                    options={Options}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: "10px" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CgDanger size={22} style={{ color: "white" }} />
                                    <h4 style={{ color: "white", marginLeft: "10px" }}> Other Participants </h4>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} style={{ padding: "10px", marginBottom: "10px" }}>
                                <MultiSelect
                                    name="owners"
                                    selected={apiData.owner}
                                    options={Options}
                                    handleChange={handleChange}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} style={{ padding: "10px" }}>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: "15px" }}>
                                    <Badge badgeContent={<TiTick size={15} color="white" />} color="success" style={{ paddingLeft: "20px" }}></Badge>
                                    <h5 style={{ color: "white", marginLeft: "25px" }}> Document Signed </h5>
                                </div>
                                <div style={{ display: "flex", alignItems: "center", paddingBottom: "15px" }}>
                                    <Badge badgeContent={<BsQuestion size={15} color="white" />} color="error" style={{ paddingLeft: "20px" }}></Badge>
                                    <h5 style={{ color: "white", marginLeft: "25px" }}> Document Declined </h5>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Badge badgeContent={<CgDanger size={15} color="white" />} color="warning" style={{ paddingLeft: "20px" }}></Badge>
                                    <h5 style={{ color: "white", marginLeft: "25px" }}> Document Pending </h5>
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} style={{ padding: "10px" }}>
                                <div>
                                    {names.map(e => {
                                        return (
                                            <>
                                                <div style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-between",
                                                    borderBottom: "1px solid #FFFFFF",
                                                    background: "#0E1521"
                                                }}>
                                                    <h4 style={{ margin: "3px 0px", color: "white" }}>{e}</h4>
                                                    <Checkbox defaultChecked />
                                                </div>
                                            </>
                                        )
                                    })}
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                }
                <br></br>
                <br></br>
            </Card>
            {save && <div style={{ textAlign: "end", margin: "20px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => console.log(e)}
                >
                    <MdAdd size={25} />
                    Add Milestone
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => Tab3()}
                    style={{ marginLeft: "20px" }}
                >
                    Save and Proceed
                </Button>
            </div>}
        </div>
    )
}

export default Schedules