import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./quote.module.css";

import Content from "components/UI/Layout/Content";
import Select from "components/fields/Select";
import Input from "components/fields/Input";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { AuthContext } from "contexts/Auth/AuthContext";
import { openTradeAccount, paymentServiceURL } from "services/BaseURLInstance";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { CompanyList } from "utils/API/CompanyList.js";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { useNavigate, useParams } from "react-router-dom";
import Switch from '@mui/material/Switch';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { AccountInfoSelect } from "utils/Section/FormInfo";

const PaymentScreen = ({ handleConfirmPaymentDetails, setPaymentDetailsScreen, statusUpdateAfterPaymentVarify, currentStatus, quoteData }) => {
  const { id } = useParams();
  const { OpenTradeAccountDetails } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const { instance, accounts } = useMsal();
  const [companyList, setCompanyList] = useState(null);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [payment, setPayment] = useState(null);
  const [provider, setProvider] = useState([]);
  const [provider2, setProvider2] = useState([]);
  const [servicePaymentDetails, setServicePaymentDetails] = useState();


  const theme = createTheme({
    palette: {

      secondary: {
        // This is green.A700 as hex.
        main: '#808080',
      },
    },
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setPayment(data);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);


  useEffect(() => {
    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.paymentProvider,
          value: item.paymentProvider,
        };
      });
      setProvider(temp);
    }

    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.virtualAccountNumber,
          value: item.virtualAccountNumber,
        };
      });
      setProvider2(temp);
    }
  }, [payment]);

  const handlePaymentChange = (event) => {
    setServicePaymentDetails({
      ...servicePaymentDetails,
      [event.target.name]: event.target.value
    })
  };

  // CompanyList API
  const List = CompanyList();
  useEffect(() => {
    if (List && List.response) {
      let temp = List.response.filter((item) => {
        if (
          item.memberType === "FINANCER" ||
          item.memberType === "COMPANY" ||
          item.memberUID === companyId
        ) {
          return { label: item.memberName, value: item.memberUID };
        }
      });
      let filter =
        temp &&
        temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });

      setCompanyList(filter);
    }
  }, [List && List.response]);

  const handleAbort = (e) => {
    e.preventDefault();
  };

  const handlePutSubmitPayment = (e) => {
    e.preventDefault();
    const tempDetails = {
      paymentProviderName: quoteData?.paymentProviderName ? quoteData.paymentProviderName : servicePaymentDetails?.paymentProviderName ? servicePaymentDetails?.paymentProviderName : "",
      RefSellerVirtualBankAccountId: quoteData?.RefSellerVirtualBankAccountId ? quoteData?.RefSellerVirtualBankAccountId : servicePaymentDetails?.RefSellerVirtualBankAccountId ? servicePaymentDetails?.RefSellerVirtualBankAccountId : "",
      RefBuyerVirtualBankAccountId: servicePaymentDetails?.RefBuyerVirtualBankAccountId ? servicePaymentDetails?.RefBuyerVirtualBankAccountId : quoteData?.RefBuyerVirtualBankAccountId ? quoteData?.RefBuyerVirtualBankAccountId : "",
    }
    if (quoteData.serviceDealStatus === "PAYMENT_PENDING") {
       handleConfirmPaymentDetails(tempDetails)
       setPaymentDetailsScreen(false);
    }
    else {
      statusUpdateAfterPaymentVarify(e, currentStatus, tempDetails);
      setPaymentDetailsScreen(false);
    }
  }
  console.log("servicePaymentDetails :",servicePaymentDetails);
  console.log("quoteData :",quoteData);
  console.log("companyId :",companyId);
  return (
    <>
      {isLoading || List.isLoading ? (
        <Content marginBottom="0%">
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {!List.isError && !isError && (
            <Content marginBottom="0%">
              <div style={{ marginBottom: "3%" }}>
                <div style={{fontSize:'20px'}}>Payment Preferences</div>
                <div style={{ display: "flex" }}>
                  <div>
                    <ThemeProvider theme={theme}>
                      <Switch
                        color={quoteData.modeOfPayment === "OFFLINE" ? 'secondary' : "info"}
                        checked={true}
                      />
                    </ThemeProvider>
                  </div>
                  <div style={{ marginTop: "0.6%" }}>{quoteData.modeOfPayment === "OFFLINE" ? "Offline Payment" : "Online Payment"}</div>
                </div>
              </div>
              {quoteData.modeOfPayment !== "OFFLINE" &&
                <div>
                  <OverLayTrigger value={AccountInfoSelect} />
                  <div className={classes.FormDiv}>
                    <div>
                      <div>Payment Service Provider</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Service Provider
                      </div>
                    </div>
                    {quoteData?.paymentProviderName ?
                      <div>
                        <Input
                          name="paymentProviderName"
                          placeholder="Payment Service Provider"
                          value={quoteData?.paymentProviderName ? quoteData?.paymentProviderName : servicePaymentDetails?.paymentProviderName}
                          readOnly={true}
                        />
                      </div>
                      :
                      <div>
                        <Select
                          name="paymentProviderName"
                          placeholder="Payment Service Provider"
                          options={provider}
                          value={quoteData?.paymentProviderName ? quoteData?.paymentProviderName : servicePaymentDetails?.paymentProviderName}
                          handleChange={handlePaymentChange}
                          readOnly={quoteData.paymentProviderName ? true : false}
                        />
                      </div>
                    }
                    <div>
                      <div>Payment Account (Seller)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    {quoteData?.RefSellerVirtualBankAccountId ?
                      <div>
                        <Input
                          name="RefSellerVirtualBankAccountId"
                          placeholder="Ref Seller Virtual BankAccount Id"
                          value={quoteData?.RefSellerVirtualBankAccountId ? quoteData?.RefSellerVirtualBankAccountId : servicePaymentDetails?.RefSellerVirtualBankAccountId}
                          readOnly={true}
                        />
                      </div>
                      :
                      <div>
                        <Select
                          name="RefSellerVirtualBankAccountId"
                          options={provider2}
                          placeholder="Ref Seller Virtual BankAccount Id"
                          value={quoteData?.RefSellerVirtualBankAccountId ? quoteData?.RefSellerVirtualBankAccountId : servicePaymentDetails?.RefSellerVirtualBankAccountId}
                          handleChange={handlePaymentChange}
                          readOnly={quoteData.RefSellerVirtualBankAccountId ? true : false}
                        />
                      </div>
                    }
                    <div>
                      <div>Payment Account (Buyer)</div>
                      <div style={{ fontSize: "10px", opacity: "0.6" }}>
                        Select the Payment Account for this trade
                      </div>
                    </div>
                    <div>
                      {quoteData?.consumerMemberUID!==companyId ?
                        <div>
                          <Input
                            name="RefBuyerVirtualBankAccountId"
                            placeholder="Ref Buyer Virtual BankAccount Id *"
                            value={quoteData?.RefBuyerVirtualBankAccountId ? quoteData?.RefBuyerVirtualBankAccountId : servicePaymentDetails?.RefBuyerVirtualBankAccountId}
                            readOnly={true}
                          />
                        </div>
                        :
                        <div>
                          <Select
                            options={provider2}
                            name="RefBuyerVirtualBankAccountId"
                            placeholder="Ref Buyer Virtual BankAccount Id *"
                            value={quoteData?.RefBuyerVirtualBankAccountId ? quoteData?.RefBuyerVirtualBankAccountId : servicePaymentDetails?.RefBuyerVirtualBankAccountId}
                            handleChange={handlePaymentChange}
                            readOnly={quoteData?.RefSellerVirtualBankAccountId ? false : true}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>
              }
            </Content>
          )}
          <ButtonDiv>
            <Button
              onClick={() => {
                setPaymentDetailsScreen(false);
              }}>
              Go Back
            </Button>
            <Button onClick={handleAbort}>Abort Service</Button>
            {(quoteData.consumerMemberUID ==companyId && quoteData.modeOfPayment != "OFFLINE") && (
              ( (servicePaymentDetails && servicePaymentDetails.RefBuyerVirtualBankAccountId ) || quoteData.serviceDealStatus == 'PAYMENT_PENDING' ? (
                <Button onClick={(e) => handlePutSubmitPayment(e)}>Confirm</Button>
               ):(
                 <button className={classes.isDisabled}> Confirm </button>
               ) )
            )}
            {quoteData.consumerMemberUID !=companyId && quoteData.modeOfPayment != "OFFLINE" &&(
               ( servicePaymentDetails && servicePaymentDetails.RefSellerVirtualBankAccountId && servicePaymentDetails.paymentProviderName ? (
                <Button onClick={(e) => handlePutSubmitPayment(e)}>Confirm</Button>
               ):(
                 <button className={classes.isDisabled}> Confirm </button>
               ) )
            )}
           {quoteData && quoteData.modeOfPayment == "OFFLINE" && (
             <Button onClick={(e) => handlePutSubmitPayment(e)}>Confirm</Button>
           )}
          </ButtonDiv>

          {(List.isError || isError) && (
            <Content marginBottom="0%">
              {List.isError}
              {isError}
              Something went wrong
            </Content>
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default PaymentScreen;
