import React, { useState } from 'react'
import {
    Card,
    Grid,
    Checkbox,
    Button
} from '@mui/material';
import MultiSelect from "components/fields/MultiSelect";

function Buyer(props) {
    const [buyer, setBuyer] = useState([]);
    const [showbuyer, setShowbuyer] = useState(false);

    const Options = [
        { value: "ocean", label: "Ocean", color: "#00B8D9" },
        { value: "blue", label: "Blue", color: "#0052CC" },
        { value: "purple", label: "Purple", color: "#5243AA" },
        { value: "red", label: "Red", color: "#FF5630" },
        { value: "orange", label: "Orange", color: "#FF8B00" },
        { value: "yellow", label: "Yellow", color: "#FFC400" },
        { value: "green", label: "Green", color: "#36B37E" },
        { value: "forest", label: "Forest", color: "#00875A" },
        { value: "slate", label: "Slate", color: "#253858" },
        { value: "silver", label: "Silver", color: "#666666" }
    ];

    const handleChange = (e) => {
        setBuyer(e.target.value);
    };

    const names = [
        'Oliver Hansen',
        'Van Henry'
    ];

    const json = [
        { "CIN/ TIN/ PAN": "123456" },
        { "Buyer Name": "GoodYear Chemicals Corp." },
        { "Address": "London" },
        { "Email ID*": "demo@goodyearfoods.com" },
        { "Phone Number*": "+44-234567890" }
    ]
    return (
        <Card
            style={{
                backgroundColor: "#343434",
            }}>
            {showbuyer ?
                <div>
                    <div style={{ width: "95%", margin: "auto" }}>
                        <hr></hr>
                        <br></br>
                        <Grid container style={{ padding: "15px 0px 0px 0px", background: "#1e1e1e" }}>
                            <Grid style={{ padding: "15px 0px 0px 0px" }} xs={12} md={5}>
                                {json.map(f => {
                                    return (
                                        <Card style={{ display: "flex", borderRadius: "5px", margin: "0px", padding: "0px 20px", background: "#1e1e1e" }}>
                                            <div style={{ width: "48%" }}>
                                                <h4 style={{ color: "white", margin: "15px 0px" }}> {Object.keys(f)}</h4>
                                            </div>
                                            <div style={{ width: "4%", color: "white", margin: "15px 0px" }}> <h4>:</h4> </div>
                                            <div style={{ width: "48%" }}>
                                                <h4 style={{ margin: "15px 0px", color: "white" }}> {Object.values(f)} </h4>
                                            </div>
                                        </Card>
                                    )
                                })}
                            </Grid>
                            <Grid style={{ padding: "15px 0px 0px 0px" }} xs={12} md={6}>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                :
                <Grid container style={{ padding: "15px 0px 0px 0px" }}>
                    <Grid item xs={12} md={3} style={{ padding: "10px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <h4 style={{ color: "white", marginLeft: "10px" }}> Recipients : </h4>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={9} style={{ padding: "10px", marginBottom: "10px" }}>
                        <MultiSelect
                            name="buyer"
                            selected={buyer}
                            options={Options}
                            handleChange={handleChange}
                        />
                    </Grid>
                    <br></br>

                    <Grid item xs={12} md={3} style={{ padding: "10px" }}>

                    </Grid>
                    <Grid item xs={12} md={9} style={{ padding: "10px" }}>
                        <div>
                            {names.map(e => {
                                return (
                                    <>
                                        <div style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            borderBottom: "1px solid #FFFFFF",
                                            background: "#0E1521"
                                        }}>
                                            <h4 style={{ margin: "3px 0px", color: "white" }}>{e}</h4>
                                            <Checkbox defaultChecked />
                                        </div>
                                    </>
                                )
                            })}
                        </div>
                    </Grid>

                    <br></br>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>

                    </Grid>

                    <Grid item xs={12} md={6} style={{ padding: "10px", justifyContent: "space-around" }} container>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => console.log("search buyer")}
                        >
                            Search Buyer
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowbuyer(true)}
                        >
                            Select Buyer
                        </Button>
                    </Grid>
                </Grid>
            }
            <br></br>
            {showbuyer &&
                <div style={{ textAlign: "end" }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => console.log("first")}
                    >
                        Search Buyer
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => props.Function.enableBuy()}
                        style={{ margin: "20px 40px 20px 40px" }}
                    >
                        Confirm Buyer
                    </Button>
                </div>}
        </Card>
    )
}

export default Buyer