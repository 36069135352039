import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { ToTitleCase } from "common/utils/toTitleText";
import { BiRightArrowCircle } from "react-icons/bi";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { FaRegClock } from "react-icons/fa";

export default function CustomizedSteppers({ Value, activeStep, handleClick }) {
  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 2,
      border: 0,
      backgroundColor: "white",
      borderRadius: 1,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => {
    let activeColor = "#0381ff";
    switch (ownerState?.status) {
      case "NEW":
        activeColor = "#0381ff";
        break;
      case "NOT_STARTED":
        activeColor = "#0381ff";
        break;
      case "IN_PROGRESS":
        activeColor = "#ffc107";
        break;
      case "COMPLETED":
        activeColor = "#28a745";
        break;

      case "DRAFT":
        activeColor = "gray";
        break;
      case "CANCELLED":
        activeColor = "gray";
        break;
      case "OTHERS":
        activeColor = "gray";
        break;
    }

    return {
      backgroundColor: activeColor,
      zIndex: 1,
      color: "#fff",
      fontSize: "14px !important",
      letterSpacing: "1px !important",
      width: 50,
      height: 50,
      display: "flex",
      borderRadius: "50%",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",

      boxShadow:
        ownerState?.index + 1 === Number(activeStep) &&
        "white 0.1px 0.3px 5px 8px",
    };
  });

  function ColorlibStepIcon(props) {
    const { active, completed, className, index, status } = props;
    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active, index, status }}
        className={className}>
        <BiRightArrowCircle size={26} />
      </ColorlibStepIconRoot>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,

    icon: PropTypes.node,
    status: PropTypes.string,
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
    },
  });

  const ToolkitHandler = (item) => {
    return (
      <div>
        <div>
          <FaRegClock /> Planned Start Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Planned End Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Projected Start Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Projected End Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
      </div>
    );
  };

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={1}
        connector={<ColorlibConnector />}>
        {Value?.map((label, index) => (
          <Step key={label} completed={false}>
            <CustomWidthTooltip
              arrow
              title={<ToolkitHandler label={label} />}
              placement="top">
              <StepLabel
                onClick={() => handleClick(label)}
                StepIconComponent={(stepIconProps) => (
                  <ColorlibStepIcon
                    status={label?.status}
                    index={index}
                    {...stepIconProps}
                  />
                )}>
                {ToTitleCase(label?.tradeSubStage)}
              </StepLabel>
            </CustomWidthTooltip>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
