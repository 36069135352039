import React, {useState} from 'react';
import Screen from './newPassword.styled';
import {MdEmail} from "react-icons/md";
import {FiLock} from "react-icons/fi";
import {FaFacebookF} from "react-icons/fa";
import {BsTwitter} from "react-icons/bs";
import {ImGooglePlus} from "react-icons/im";
import { useNavigate } from 'react-router-dom';


const NewPassword = ()=>{

    const navigate=useNavigate();
    const [newPassword,setNewPassword]=useState({
        password:"",
        confirmPassword:""
    });
    
    const handleChange=(e)=>{
       e.preventDefault();
       const {name,value}=e.target;
       setNewPassword({
           ...newPassword,
           [name]:value
       })
    }
    const handleSubmit=()=>{
         navigate('/login');
    }
   


    return(
       <Screen>
            <div className='header'>TRADXLINK</div>
            <div className='subHeader'>Seller</div>
            <div className='newPasswordHeading'>Enter new password</div>
            <div className='password'>
                <label For="password"><FiLock/></label>
                <input type="password" name="password" placeholder="Password" value={newPassword.password} onChange={handleChange}/>
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <div className='password'>
                <label For="password"><FiLock/></label>
                <input type="password" name="confirmPassword" placeholder="Confirm Password" value={newPassword.confirmPassword} onChange={handleChange}/>
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <div className='checkBox'>
                <input type="checkbox" className="larger" name="checkBox"/>
                <div className='rememberpassword'>Remember password</div>
            </div>
            <button className='submitbutton' onClick={handleSubmit}>Submit</button>
       </Screen>
        
    )
}
export default NewPassword;