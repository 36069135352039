import React,{useEffect,useState} from "react";
import useAxios from "../../../../../services/useAxios";
import { loanApi } from "../../../../../services/BaseURLInstance";
import styled from "styled-components";
import Loader from "../../../../../components/Loader";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import LoanTable from "../loantable/LoanTable";

const Styles = styled.div`
  
  .tablebox{ 
    background: #343434;
    width:100%;
    border-radius: 5px;
  }
`;

export default (props)=>{
   const { instance, accounts } = useMsal();
    const [idToken, setIdToken] = useState(null);
    getIdToken(instance, accounts, setIdToken);
   
    const companyId = getClaim(accounts, "company_member_id");
    const { response, isLoading, error } = useAxios({
        api: loanApi,
        method: "get",
        url: `loans?traderMemberId=${companyId}&financerMemberId=&loanStatus=ACTIVE`,
        config: JSON.stringify({
          requireAuthentication: true,
          headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + idToken,
            "x-api-key":
              "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
          },
        }),
      });
    return(
         <Styles>
             {isLoading && <Loader />}
            <div className="loanList">
              {Array.isArray(response) ? <div className="tablebox">
                <LoanTable data={response}  />
              </div> : (
                <p>No Records</p>
              )}
            </div>
          </Styles>
    )
}