import styled from "styled-components";

const Screen= styled.div`
  width: 1366px;
  height: 1289px;
  blend: pass through;
  background: linear-gradient(180deg, #218EEB 0%, rgba(33, 142, 235, 0.76) 100%);
  .header{
    width: 270px;
    height: 59px;
    padding-top: 71px;
    margin-left: 548px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 50px;
    line-height: 59px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
  }
  .subHeader{
    width: 78px;
    height: 35px;
    margin-top: 10px;
    margin-left: 645px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 30px;
    line-height: 35px;
    line-height: 100%;
    align: Left;
    vertical align: Top;
  }
  .signupHeading{
    width: 226px;
    height: 59px;
    margin-top: 93px;
    margin-left: 77px;
    font-family: Roboto;
    font-style: Bold;
    font-size: 50px;
    line-height: 59px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
  }
  .horizontalLine{
    width: 568px;
    margin-top: 9px;
    margin-left: 77px;
    align:center
    hr{
        width:3px
    }
  }
  .lastname{
     margin-left: 80px;
  }
  .user{
    display: flex;  
    height: 59px;
    margin-top: 61px;
    margin-left: 88px;
    background-color: transparent;
  }
  .user> label{
    display: flex;  
    align-items: flex-end;
  }
 



  .user> input{ 
    border: none;
    color:#E6E6E6;
    width: 526px;
    height:19px;
    padding-top:43px;
    margin-left:14px;
    background: transparent;
    flex-wrap: wrap;
    ::placeholder { 
       color: white;
       opacity: 1;  
    }
   
  }


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  transition: background-color 5000s;
  -webkit-text-fill-color:#E6E6E6 !important;
}









  .user>input:focus {
    outline: none;
  }

  .email{
    margin-left: 80px;
}
.mobile-email{
  display: flex;  
  height: 59px;
  margin-top: 61px;
  margin-left: 88px;
  background: Solid #E6E6E6;
}
.mobile-email> label{
  display: flex;  
  align-items: flex-end;
}

.mobile-email> input{ 
  border: none;
  color:#E6E6E6;
  width: 526px;
  height:19px;
  padding-top:43px;
  margin-left:14px;
  background: transparent;
  flex-wrap: wrap;
  ::placeholder { 
     color: white;
     opacity: 1;  
  }
 
}
.mobile-email>input:focus {
  outline: none;
}


.confirmPassword{
    margin-left: 80px;
}

.password{
  display: flex;  
  height: 59px;
  margin-top: 61px;
  margin-left: 88px;
  background: Solid #E6E6E6;
}
.password> label{
  display: flex;  
  align-items: flex-end;
}

.password> input{ 
  border: none;
  color:#E6E6E6;
  width: 526px;
  height:19px;
  padding-top:43px;
  margin-left:14px;
  background: transparent;
  flex-wrap: wrap;
  ::placeholder { 
     color: white;
     opacity: 1;  
  }
 
}
.password>input:focus {
  outline: none;
}


  .checkBox{
    display: flex;
  }
  .larger{
    width: 30px;
    height: 30px;
    margin-left: 77px;
    margin-top: 85px;
    border-radius: 5px;
    border: 2px solid #E6E6E6;
  }
  .rememberpassword{
    width: 190px;
    height: 23px;
    margin-left: 12px;
    margin-top: 85px;
    font-family: Roboto;
    font-style: Regular;
    font-size: 20px;
    line-height: 23px;
    line-height: 100%;
    align: Left;
    vertical-align: Top;
    color: #E6E6E6;

  }
  
  .createAccountButton{
    width: 1212px;
    height: 85px;
    margin-left: 77px;
    margin-top: 76px;
    background: #FFFFFF;
    border-radius: 10px

  }
  .loginIcon{
    display:flex;
    margin-top: 133px;
    margin-left: 530px
  }
  .loginFacebook{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    background: #FFFFFF;
    font-size: 33px;
    align-items: center;
  }
  .loginTwitter{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    margin-left: 32px;
    background: #FFFFFF;
    font-size: 33px;
    align-items: center;
  }
  .loginGmail{
    width: 76px;
    height: 76px;
    border-radius: 50%;
    margin-left: 32px;
    background: #FFFFFF;
    font-size: 33px;
    align-items: center;

  }
  .accountCheck{
    width: 318px;
    height: 24px;
    margin-top: 40px;
    margin-left: 524px;
    font-family: Roboto;
    font-style: Italic;
    font-size: 30px;
    line-height: 24px;
    line-height: 68%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 32px;
    color: #FFFFFF;

  }
  .signin{
    width: 111px;
    height: 24px;
    margin-top: 24px;
    margin-left: 644px;
    font-family: Roboto;
    font-style: bold;
    font-size: 30px;
    line-height: 24px;
    line-height: 68%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    color: #FFFFFF;
    text-decoration: underline;

  }
  .underline{
      display:flex;
  }

`;

export default Screen;
