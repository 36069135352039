import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { customsForm } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";

const SteveDoring = ({ steveData, setSteveData }) => {
  let typeOffCargo = [
    {
      label: "Container",
      value: "Container",
    },
    {
      label: "Bulk",
      value: "Bulk",
    },
    {
      label: "Breakbulk",
      value: "Breakbulk",
    },
    {
      label: "ODC (Over Dimensional Cargo)",
      value: "ODC (Over Dimensional Cargo)",
    },
  ];

  let containers = [
    {
      label: "10-Foot Container",
      value: "10-Foot Container",
    },
    {
      label: "20-Foot Container",
      value: "20-Foot Container",
    },
    {
      label: "40-Foot Container",
      value: "40-Foot Container",
    },
    {
      label: "20-Foot High Cube",
      value: "20-Foot High Cube",
    },
    {
      label: "440-Foot High Cube",
      value: "40-Foot High Cube",
    },
    {
      label: "Not relevant",
      value: "Not relevant",
    },
  ];

  let cargoHazardousOptions = [
    {
      label: "EXPLOSIVES",
      value: "EXPLOSIVES",
    },
    {
      label: "GASES",
      value: "GASES",
    },
    {
      label: "FLAMMABLE_SOLIDS",
      value: "FLAMMABLE_SOLIDS",
    },
    {
      label: "OXIDIZERS_ORGANIC_PEROXIDES",
      value: "OXIDIZERS_ORGANIC_PEROXIDES",
    },
    {
      label: "TOXIC INFECTIOUS",
      value: "TOXIC_INFECTIOUS",
    },
    {
      label: "RADIOACTIVE",
      value: "RADIOACTIVE",
    },
    {
      label: "CORROSIVES",
      value: "CORROSIVES",
    },
    {
      label: "MISCELLANEOUS_HAZARDOUS",
      value: "MISCELLANEOUS_HAZARDOUS",
    },
    {
      label: "NOT_APPLICABLE",
      value: "NOT_APPLICABLE",
    },
  ];
  let containType = [
    {
      label: " Dry storage container",
      value: " Dry storage container",
    },
    {
      label: " Flat rack container",
      value: " Flat rack container",
    },
    {
      label: "  Open top container",
      value: "  Open top container",
    },
    {
      label: " Open side storage container",
      value: " Open side storage container",
    },
    {
      label: "  Refrigerated ISO containers",
      value: "  Refrigerated ISO containers",
    },
    {
      label: "ISO Tanks",
      value: "ISO Tanks",
    },
    {
      label: "Half height containers",
      value: "Half height containers",
    },
    {
      label: " Special purpose containers",
      value: " Special purpose containers",
    },
  ];

  let isHazard = [
    {
      label: "YES",
      value: "YES",
    },
    {
      label: "NO",
      value: "NO",
    },
  ];
  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setSteveData({
      ...steveData,
      [name]: value,
    });
  };

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      // if (date === "loadingDate") {
      //   setDatePicker1(newDate1);
      //   // setSteveData({
      //   //   ...steveData,
      //   //   [e.target.name]: [e.target.value],
      //   // });
      // }
      // if (date === "unloadingDate") {
      //   setDatePicker2(newDate1);
      // setSteveData({
      //   ...steveData,
      //   [e.target.name]: [e.target.value],
      // });
      //  }
      setSteveData({
        ...steveData,
        [date]: dateString,
      });
    } else if (e.target.name === "name") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "company") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    }
    // else if (e.target.name === "commodity") {
    //   setSteveData({
    //     ...steveData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    else if (e.target.name === "contactInfo") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "vesselName") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "imoNumber") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "port") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "arrival") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "departure") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "typeOfCargo") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "description") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "selectedCargo") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "containorType") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "containoirsize") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Lashing") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "loadinginfo") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "locationOfCargo") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "duration") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "equipments") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "laborreq") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "environment") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requirements") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ishazard") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "unnumber") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    }
    else if (e.target.name === "cargoHazardous") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "specialReq") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "regulations") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "emergencyplan") {
      setSteveData({
        ...steveData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDateChange = (e, field) => {
    setSteveData({
      ...steveData,
      [field]: new Date(e).toISOString(),
    });
  };
  return (
    <>
      <Content padding="1% 2% 1.4% 2%">
        <div className={classes.source} style={{fontSize:"18px", margin:"10px 10px 5px 10px"}}>Requester Information:</div>
        <div className={classes.source} style={{ margin: "0px 10px 10px 10px" }}>
          {" "}
          <Input
            name="name"
            placeholder="Name:*                        "
            style={{ marginTop: "2%", marginBottom: "2%" }}
            handleChange={handleChange}
            value={steveData.name}
          />
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="company"
            placeholder="Company/Organization:*"
            handleChange={handleChange}
            value={steveData.company}
          />
        </div>
        <div className={classes.source} style={{ margin: "10px" }}>
          {" "}
          <Input
            name="contactInfo"
            placeholder="Contact Information (Phone/Email )*"
            handleChange={handleChange}
            value={steveData.contactInfo}
          />
        </div>
        <div className={classes.source} style={{ marginTop: "1.5%", fontSize:"18px", marginLeft:"10px" }}>
          Cargo Details:
        </div>
        <div className={classes.source} style={{ marginTop: "0.5%",marginLeft:"10px",marginRight:"10px" }}>
          {" "}
          <Input
            name="vesselName"
            placeholder="Vessel Name:*"
            handleChange={handleChange}
            value={steveData.vesselName}
            // readOnly={true}
          />
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="imoNumber"
            placeholder="Vessel IMO Number:*"
            handleChange={handleChange}
            value={steveData.imoNumber}
            // readOnly={true}
          />{" "}
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="port"
            placeholder="Port of Call:*"
            handleChange={handleChange}
            value={steveData.port}
            // readOnly={true}
          />{" "}
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="arrival"
            placeholder="Arrival Date and Time:*"
            handleChange={handleChange}
            value={steveData.arrival}
            // readOnly={true}
          />
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="departure"
            placeholder="Departure Date and Time:*"
            handleChange={handleChange}
            value={steveData.departure}
            // readOnly={true}
          />{" "}
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="typeOfCargo"
            placeholder="Type of Cargo:*"
            handleChange={handleChange}
            value={steveData.typeOfCargo}
            // readOnly={true}
          />{" "}
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="description"
            placeholder="Description of Cargo (including any special handling requirements):*"
            handleChange={handleChange}
            value={steveData.description}
            // readOnly={true}
          />{" "}
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Select
            name="selectedCargo"
            options={typeOffCargo}
            placeholder="Type of Cargo:"
            handleChange={handleChange}
            value={steveData.selectedCargo}
            // readOnly={true}
          />{" "}
        </div>
        <div
          className={classes.source}
          style={{ margin: "0px 10px", marginTop: "1.5%" }}
        >
          If Container Cargo*
        </div>
        <div className={classes.source} style={{ margin: "0px 10px" }}>
          <div
            style={{ marginLeft: "10px", width: "50%", marginRight: "10px" }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType3"
              value="YES"
              onChange={handleRadio}
              //  checked={steveData.serviceType == "YES"}
            />
            <label>YES</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType3"
              value="NO"
              onChange={handleRadio}
              // checked={steveData.serviceType == "NO"}
            />
            <label>NO</label>
          </div>
        </div>
        {steveData.serviceType3 === "YES" ? (
          <>
            <div className={classes.source} style={{ marginTop: "0.5%", marginLeft:"10px", marginRight:"10px" }}>
              {" "}
              <Select
                options={containType}
                name="containorType"
                placeholder="Container Type:"
                handleChange={handleChange}
                value={steveData.containorType}
                // readOnly={true}
              />{" "}
            </div>
            <div className={classes.source} style={{ margin: "20px 10px" }}>
              {" "}
              <Select
                options={containers}
                name="containoirsize"
                placeholder="Container Size:"
                handleChange={handleChange}
                value={steveData.containoirsize}
                // readOnly={true}
              />{" "}
            </div>
          </>
        ) : (
          ""
        )}
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="requirements"
            placeholder="Packaging Requirements"
            handleChange={handleChange}
            value={steveData.requirements}
            // readOnly={true}
          />{" "}
        </div>
        <div className={classes.source} style={{ margin: "20px 10px" }}>
          {" "}
          <Input
            name="Lashing"
            placeholder="Lashing , Dunnaging and Shoring Requirements*"
            handleChange={handleChange}
            value={steveData.Lashing}
            // readOnly={true}
          />{" "}
        </div>
        <div
          className={classes.source}
          style={{ margin: "0px 10px", marginTop: "1.5%" }}
        >
          Inspection done : Y/ N*
        </div>
        <div className={classes.source} style={{ margin: "0px 10px" }}>
          <div
            style={{ marginLeft: "10px", width: "50%", marginRight: "10px" }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="YES"
              onChange={handleRadio}
              //  checked={steveData.serviceType == "YES"}
            />
            <label>YES</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="NO"
              onChange={handleRadio}
              // checked={steveData.serviceType == "NO"}
            />
            <label>NO</label>
          </div>
        </div>

        <div className={classes.source} style={{ margin: "0px 0px" }}>
          <div
            style={{
              marginLeft: "10px",
              width: "50%",
              marginRight: "10px",
              marginTop: "0.8%",
              marginBottom: "0%",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType2"
              value="Unloading"
              onChange={handleRadio}
              //  checked={steveData.serviceType == "YES"}
            />
            <label>Unloading</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
              marginTop: "0.8%",
              marginBottom: "0%",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType2"
              value="Loading"
              onChange={handleRadio}
              // checked={steveData.serviceType == "NO"}
            />
            <label>Loading</label>
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "5px 10px 10px 10px" }}>
            <Input
              name="loadinginfo"
              placeholder="Loading/Unloading Information:*"
              handleChange={handleChange}
              value={steveData.loadinginfo}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="locationOfCargo"
              placeholder="Location of Cargo (Berth/Wharf Number):*"
              handleChange={handleChange}
              value={steveData.locationOfCargo}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <DateInput
              name="preferred"
              placeholder="Preferred Start Date:*"
              value={steveData.preferred}
              handleChange={(e) => handleDateChange(e, "preferred")}
            />
          </div>
          <div style={{ width: "50%", margin: "10px" }}>
            <DateInput
              name="completion"
              placeholder="Preferred Completion Date :*"
              value={steveData.completion}
              handleChange={(e) => handleDateChange(e, "completion")}
            />
          </div>
        </div>

        <div style={{ width: "50%", margin: "10px" , paddingRight:"20px"}}>
          <Input
            name="duration"
            placeholder="Estimated Duration (in hours):*"
            handleChange={handleChange}
            value={steveData.duration}
            // readOnly={true}
          />
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="equipments"
              placeholder="Equipment Needed (Cranes, Forklifts, etc.):*"
              handleChange={handleChange}
              value={steveData.equipments}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="laborreq"
              placeholder="Labor Requirements (Number of Workers and Specialized Skills):*"
              handleChange={handleChange}
              value={steveData.laborreq}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="environment"
              placeholder="Safety and Environmental Considerations: "
              handleChange={handleChange}
              value={steveData.environment}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "5px 10px 0px 10px" }}>
            <label>Is Hazardous ?*</label>
          </div>

          {/* <div style={{ width: "50%", margin: "10px" }}>
            <Select
              options={isHazard}
              name="ishazard"
              placeholder="Hazardous ?"
              handleChange={handleChange}
              value={steveData.ishazard}
              // readOnly={true}
            />
          </div> */}
        </div>
        <div className={classes.source} style={{ margin: "0px 10px" }}>
          <div
            style={{
              marginLeft: "10px",
              width: "50%",
              marginRight: "10px",
              marginTop: "5px",
              marginBottom: "0%",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="ishazard"
              value="YES"
              onChange={handleRadio}
              //  checked={steveData.serviceType == "YES"}
            />
            <label>YES</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
              marginTop: "5px",
              marginBottom: "0%",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="ishazard"
              value="NO"
              onChange={handleRadio}
              // checked={steveData.serviceType == "NO"}
            />
            <label>NO</label>
          </div>
        </div>
        {steveData.ishazard === "YES" ? (
          <>
            <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
              <Select
                options={cargoHazardousOptions}
                name="cargoHazardous"
                placeholder="Cargo Hazardous"
                handleChange={handleChange}
                value={steveData.cargoHazardous}
              />
            </div>
          </>
        ) : (
          " "
        )}
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="unnumber"
              placeholder="UN NUMBER* "
              handleChange={handleChange}
              value={steveData.unnumber}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="specialReq"
              placeholder="Any Hazardous Materials or Special Safety Requirements: "
              handleChange={handleChange}
              value={steveData.specialReq}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="regulations"
              placeholder="Environmental Regulations Compliance:"
              handleChange={handleChange}
              value={steveData.regulations}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Input
              name="emergencyplan"
              placeholder="Emergency Response Plan:"
              handleChange={handleChange}
              value={steveData.emergencyplan}
              // readOnly={true}
            />
          </div>
        </div>
      </Content>
    </>
  );
};

export default SteveDoring;
