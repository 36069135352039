// Currently using it while creating requisition service.
import React, { useState } from "react";
import Styles from "./styles";
import useAxios from "services/useAxios";
import { requsitionApi, serviceURL } from "services/BaseURLInstance";
import { useNavigate } from "react-router-dom";
import { ButtonStyled } from "components/Container.styled";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";

import Select from "components/fields/Select";
import SelectWithSearch from "components/fields/SelectWithSearch";
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import { TextArea } from "components/fields";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ServiceCategory from "jsons/serviceCategory.json";
import PaymentType from "jsons/payment.json";
import ServiceSubCategory from "jsons/serviceSubCategories.json";
import Currenciesr from "jsons/Currenciesr.json";
import ConditionCheck from "jsons/conditionCheck.json";
import { Grid } from "@mui/material";
import {
  SERVICE_LABEL,
  LABEL,
  DOCUMENT_LABEL,
  INDUSTRY_LABEL,
  STATUS,
  COUNTRY,
} from "utils/Constants";

import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";

export default ({ requisitionData, setRequisitionData }) => {
  // const location = useLocation();
  // const selectedServiceList = location.state;

  // const [requisitionResponse, setRequisitionResponse] = useState();

  // let memberArray = [];
  // let newArr =
  //   selectedServiceList &&
  //   selectedServiceList.map((service) => {
  //     memberArray.push(service.updateBy);
  //   });

  // const [requisitionData, setRequisitionData] = useState({
  //   serviceRequisitionId: "",
  //   consumerMemberUID: "",
  //   ServiceReqDescription: "",
  //   pricingPreferences: "",
  //   referenceServiceId: selectedServiceList[0].serviceId
  //     ? selectedServiceList[0].serviceId
  //     : "",
  //   // referenceServiceId: serviceArray,
  //   servicePriceLow: "",
  //   servicePriceHigh: "",
  //   servicePaymentCurrency: "",
  //   serviceRequisitionStartDate: "2022-09-16T10:19:33.179+05:30",
  //   serviceRequisitionEndDate: "2022-09-16T10:19:33.179+05:30",
  //   serviceRequisitionStatus: "NEW",
  //   preferredPaymentDate: "2022-09-16T10:19:33.179+05:30",
  //   escrowPayment: true,
  //   isDeleted: false,
  //   createdBy: "",
  //   createdDate: "",
  //   lastUpdateBy: "",
  //   lastUpdatedDate: "",
  //   //  serviceProvidersSelected: selectedServiceList[0].memberId? [selectedServiceList[0].memberId]:[],
  //   serviceProvidersSelected: memberArray,
  //   serviceCategory: selectedServiceList[0].serviceCategory[0]
  //     ? [selectedServiceList[0].serviceCategory[0]]
  //     : [],
  //   serviceSubCategory: selectedServiceList[0].serviceSubCategory[0]
  //     ? [selectedServiceList[0].serviceSubCategory[0]]
  //     : [],
  //   industry: selectedServiceList[0].industry
  //     ? selectedServiceList[0].industry
  //     : [],
  //   countriesForSubscription: selectedServiceList[0].countriesOfOperations
  //     ? selectedServiceList[0].countriesOfOperations
  //     : [],
  //   serviceProviderPreferences: "SELECTED",
  //   preferredPaymentMethod: "",
  //   referenceData: [],
  // });

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  useEffect(() => {
    setRequisitionData({
      ...requisitionData,
      consumerMemberUID: companyId,
    });
  }, [companyId]);

  const handleInputChange = (e) => {
    setRequisitionData({
      ...requisitionData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (e, field) => {
    setRequisitionData({
      ...requisitionData,
      [field]: new Date(e).toISOString(),
    });
  };

  const handleChange = (e) => {
    setRequisitionData({
      ...requisitionData,
      [e.target.name]: [e.target.value],
    });
  };

  const onChangeValue = (e) => {
    setRequisitionData({
      ...requisitionData,
      serviceProviderPreferences: e.target.value,
    });
  };
  let transferOption = [
     // TODO To be enabled when Online Payment is Enabled in Tradxlink.
    // {
    //   label: "DIRECT TRANSFER",
    //   value: "DIRECT_TRANSFER",
    // },
    {
      label: "OFFLINE",
      value: "OFFLINE",
    },
  ];
  // const handleSendRequest = () => {
  //   const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  //   console.log("requisition data", requisitionData);
  //   requisitionData.referenceData = [];

  //   const requestOptions = {
  //     method: "POST",
  //     requireAuthentication: true,
  //     headers: {
  //       Authorization: "Bearer " + idToken,
  //       "Content-Type": "application/json",
  //       "x-api-key":
  //         "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
  //     },
  //     body: JSON.stringify(requisitionData),
  //   };
  //   const fetchData = async () => {
  //     await fetch(`${serviceURL}services/requisitions`, requestOptions)
  //       .then((response) => {
  //         // console.log("requisition data", response);
  //         // setRequisitionResponse(response);
  //         // setTimeout(() => {
  //         //   navigate("../service-requests");
  //         // }, 1000);
  //       })
  //       .catch(() => {
  //         alert("Api is not working");
  //       });
  //   };
  //   fetchData();
  // };

  // sessionStorage.setItem("requisitionData", JSON.stringify(requisitionData));

  const checkService = JSON.parse(
    sessionStorage.getItem("selectedServiceList")
  );
  console.log("checkService :", checkService);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div>
          <Grid container>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Input
                name="serviceCategory"
                placeholder="Service Category"
                // value={requisitionData.serviceCategory[0]}
                value={checkService && checkService[0].serviceCategory[0]}
                // options={ServiceSCategory}
                handleChange={(e) => handleChange(e)}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Input
                name="serviceSubCategory"
                placeholder="Service Sub-Category"
                // value={requisitionData.serviceSubCategory[0]}
                value={checkService && checkService[0].serviceSubCategory[0]}
                // options={ServiceSubCategory}
                handleChange={(e) => handleChange(e)}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <SelectWithSearch
                name="servicePaymentCurrency"
                placeholder="Currency*"
                value={requisitionData.servicePaymentCurrency}
                options={Currenciesr}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Input
                name="servicePriceLow"
                placeholder={`Min Price(${requisitionData.servicePaymentCurrency})*`}
                value={requisitionData.servicePriceLow}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Select
                name="preferredPaymentMethod"
                placeholder="Payment Method*"
                value={requisitionData.preferredPaymentMethod}
                options={PaymentType}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Input
                name="servicePriceHigh"
                placeholder={`Max Price(${requisitionData.servicePaymentCurrency})*`}
                value={requisitionData.servicePriceHigh}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <Select
                name="modeOfPayment"
                placeholder="Mode Of Payment*"
                value={requisitionData.modeOfPayment}
                options={transferOption}
                handleChange={(e) => handleInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <DateInput
                name="preferredPaymentDate"
                placeholder="Preferred Payment Date MM/DD/YYYY*"
                value={requisitionData.preferredPaymentDate}
                handleChange={(e) =>
                  handleDateChange(e, "preferredPaymentDate")
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "10px" }}>
            <TextArea
              name="ServiceReqDescription"
              placeholder="Service Request Description*"
              value={requisitionData.ServiceReqDescription}
              handleChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "10px" }}>
            <TextArea
              name="pricingPreferences"
              placeholder="Service Pricing Description*"
              value={requisitionData.pricingPreferences}
              handleChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid container>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <DateInput
                name="serviceRequisitionStartDate"
                placeholder="Requisition Start Date *"
                value={requisitionData.serviceRequisitionStartDate}
                handleChange={(e) =>
                  handleDateChange(e, "serviceRequisitionStartDate")
                }
              />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
              <DateInput
                name="serviceRequisitionEndDate"
                placeholder="Requisition End Date *"
                value={requisitionData.serviceRequisitionEndDate}
                handleChange={(e) =>
                  handleDateChange(e, "serviceRequisitionEndDate")
                }
              />
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <Styles>
        {
          <div className="my-2">
            <div>Select Service Providers</div>
            <div style={{ display: "flex" }}>
              <div onChange={onChangeValue}>
                <input
                  style={{ marginTop: "20px" }}
                  type="radio"
                  value="All"
                  name="All"
                />{" "}
                <br />
                <input
                  style={{ marginTop: "20px" }}
                  type="radio"
                  value="Selected"
                  name="Selected"
                  checked="checked"
                />{" "}
                <br />
                <input
                  style={{ marginTop: "20px" }}
                  type="radio"
                  value="Favourites"
                  name="Favourites"
                />
              </div>
              <div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "15px",
                    backgroundColor: "transparent",
                    border: "3px solid #42A5F5",
                    borderRadius: "5px",
                  }}
                >
                  Publish to All Providers
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "30px",
                    backgroundColor: "transparent",
                    border: "3px solid #42A5F5",
                    borderRadius: "5px",
                  }}
                >
                  Publish to Selected Providers
                </div>
                <div
                  style={{
                    textAlign: "center",
                    padding: "10px",
                    marginTop: "30px",
                    backgroundColor: "transparent",
                    border: "3px solid #42A5F5",
                    borderRadius: "5px",
                  }}
                >
                  Publish to Favourites
                </div>
              </div>
            </div>
          </div>
        }
        {requisitionResponse && (
          <div style={{ color: "lightgreen", marginLeft: "2%" }}>
            Your Service Request has been sent to the selected Service Providers
          </div>
        )}
        <ButtonDiv>
          <Button onClick={handleSendRequest}>Submit</Button>
        </ButtonDiv>
      </Styles> */}
    </>
  );
};
