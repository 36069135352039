import React, { useState, useEffect } from "react";
import classes from "../../New-Service/Category/freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { IoIosHelpCircle } from "react-icons/io";

const Insurance = ({ response }) => {
  const [serviceDetails, setServiceDetails] = useState([]);
  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails));
    }
  }, [response]);

  return (
    <>
      {response && serviceDetails ? (
        <>
          <Content>
            <div style={{ margin: "10px" }}>INSURANCE REQUIRED FOR?</div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="insuranceType"
                  placeholder="Insurance Required for?"
                  value={serviceDetails.insuranceType}
                  readOnly={true}
                />
              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="transitMode"
                  placeholder="Transit Mode"
                  value={serviceDetails.transitMode}
                  readOnly={true}
                />
              </div>
            </div>
            <div style={{ margin: "10px" }}>
              <Input
                name="commodity"
                placeholder="Commodity"
                readOnly={true}
                value={serviceDetails.commodity}
              />
            </div>
            <div style={{ margin: "10px" }}>Yearly Sum Insured</div>

            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="currency"
                  placeholder="Currency"
                  readOnly={true}
                  value={serviceDetails.currency}
                />
              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="insuredAmount"
                  placeholder={`Insurance Value(${serviceDetails.currency})*`}
                  readOnly={true}
                  value={serviceDetails.insuredAmount}
                />
              </div>
            </div>
            <div style={{ margin: "10px" }}>
              Per Sending Limit
              <OverlayTrigger
                trigger="hover"
                key="top"
                placement="right"
                overlay={
                  <Popover id="popover-positioned-right">
                    {/* <Popover.Header as="h3">Document Name</Popover.Header> */}
                    <Popover.Body>
                      <strong>
                        Maximum Sum Insured you send in a single
                        transport/transit
                      </strong>
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <IoIosHelpCircle />
                </span>
              </OverlayTrigger>
            </div>

            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="pslAmount"
                  placeholder={`Insurance Value(${serviceDetails.currency})`}
                  readOnly={true}
                  value={serviceDetails.pslAmount}
                />
              </div>
            </div>
            <div style={{ margin: "10px" }}>
              Per Location Limit
              <OverlayTrigger
                trigger="hover"
                key="top"
                placement="right"
                overlay={
                  <Popover id="popover-positioned-right">
                    {/* <Popover.Header as="h3">Document Name</Popover.Header> */}
                    <Popover.Body>
                      <strong>
                        Maximum Sum Insured you keep at your location
                      </strong>
                    </Popover.Body>
                  </Popover>
                }
              >
                <span>
                  <IoIosHelpCircle />
                </span>
              </OverlayTrigger>
            </div>

            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  name="pllAmount"
                  placeholder={`Insurance Value(${serviceDetails.currency})`}
                  readOnly={true}
                  value={serviceDetails.pllAmount}
                />
              </div>
            </div>

            <div style={{ margin: "10px" }}>
              {" "}
              Do you have temperature control in your cargo?{" "}
            </div>
            <span style={{ margin: "10px" }}>
              {" "}
              <input
                className={classes.radio}
                type="radio"
                name="temperatureControl"
                value="Yes"
                readOnly={true}
                checked={serviceDetails.temperatureControl == "Yes"}
              />
              <label>Yes</label>
            </span>
            <span style={{ margin: "10px" }}>
              {" "}
              <input
                className={classes.radio}
                type="radio"
                name="temperatureControl"
                value="No"
                readOnly={true}
                checked={serviceDetails.temperatureControl == "No"}
              />
              <label>No</label>
            </span>

            <div style={{ margin: "10px" }}> Is your cargo oversized? </div>
            <span style={{ margin: "10px" }}>
              {" "}
              <input
                className={classes.radio}
                type="radio"
                name="isOversized"
                value="Yes"
                readOnly={true}
                checked={serviceDetails.isOversized == "Yes"}
              />
              <label>Yes</label>
            </span>
            <span style={{ margin: "10px" }}>
              {" "}
              <input
                className={classes.radio}
                type="radio"
                name="isOversized"
                value="No"
                readOnly={true}
                checked={serviceDetails.isOversized == "No"}
              />
              <label>No</label>
            </span>

            <div style={{ margin: "10px" }}>
              {" "}
              Do you have Fragile/ Glass/ LED items in this cargo?{" "}
            </div>
            <span style={{ margin: "10px" }}>
              {" "}
              <input
                className={classes.radio}
                type="radio"
                name="fragileCargo"
                value="Yes"
                readOnly={true}
                checked={serviceDetails.fragileCargo == "Yes"}
              />
              <label>Yes</label>
            </span>
            <span style={{ margin: "10px" }}>
              {" "}
              <input
                className={classes.radio}
                type="radio"
                name="fragileCargo"
                value="No"
                readOnly={true}
                checked={serviceDetails.fragileCargo == "No"}
              />
              <label>No</label>
            </span>
          </Content>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Insurance;
