import React from "react";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import {IoIosArrowUp,IoIosArrowDown} from "react-icons/io";
import Details from "../details/Details";

const Styles = styled.div`
    
    .table{
        padding:16px;
        background-color:#000000;
        border-radius: 5px;
    }
    
   .header1{
       width:100%;
       display:flex;
       background-color: #343434; 
      
   }
   
   .header1> div{
       display:flex;
       justify-content: center;
       align-items: center;
       height:50.47px;
       width:20%;
       border-left: 1px solid white;
       font-family: Roboto;
       font-style: Medium;
        font-size: 18px;
        line-height: 24px;
        line-height: 114%;
        align: left;
        vertical-align: top;
        paragraph-spacing: 32px;
        border-width: thin;
        overflow:hidden;
   }
   .overtable{
      background-color: #343434;
      padding:16px;
      border-radius:5px;
   }
`;

export default ({ data }) => {
  
  return (
    <Styles>
             <div className="overtable">
                <div className="table">
                        <div className="header1" style={{borderTopLeftRadius: "5px",borderTopRightRadius: "5px"}}>
                            <div style={{borderLeft: "none",borderTop:"none",paddingLeft:"10px"}}>Loan Id</div>
                            <div >Financer</div>
                            <div >Reference</div>
                            <div >Date</div>
                            <div >Status</div>
                        </div>
                        <div>
                            {data.map((item)=>{
                                return <Details data={item}/>
                            })}
                        </div>
                </div>
               </div>
     </Styles>
)}


