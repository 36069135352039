import React, { useState, useEffect } from "react";
import classes from "../../New-Service/Category/freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import { Grid } from "@mui/material";
import { Countries } from "utils/DropDown/Country";
import Select from "components/fields/Select";

const Transport = ({ response }) => {
    const [serviceDetails, setServiceDetails] = useState(null);
    useEffect(() => {
        if (response && response.ServiceReqDetails) {
            setServiceDetails(JSON.parse(response.ServiceReqDetails))
        }
    }, [response]);

    const LoadHeader = [
        { name: "cargoValue" },
        { name: "Quantity" },
        { name: "volume" }
    ];


    let bb = Object.keys(port_codes);
    let cc = Object.values(port_codes);
    let port_code = [];

    bb.map((q, i) =>
        cc.map((w, o) => {
            if (i === o) {
                port_code.push({ label: w.name, value: q });
            }
        })
    );
    console.log("serviceDetails", serviceDetails);

    return (
        <>
            <Content>
                {response && serviceDetails && serviceDetails.cargoDetails ?
                    <>
                        <div className={classes.header} style={{ margin: "10px" }}>Where Are You Shipping?</div>
                        <div className={classes.source} style={{margin:"10px"}}>
                            <div style={{ marginLeft: "1%", width: "50%" }}>
                                <input
                                    className={classes.radio}
                                    type="radio"
                                    name="freightType"
                                    value="INTERNATIONAL"
                                    readOnly={true}
                                    checked={serviceDetails.freightType == "INTERNATIONAL"}
                                />
                                <label>International Freight</label>
                            </div>
                            <div
                                style={{
                                    // fontSize: "25px",
                                    marginLeft: "1%",
                                    width: "50%",
                                    textAlign: "right",
                                    marginRight: "1%",
                                }}
                            >
                                <input
                                    className={classes.radio}
                                    type="radio"
                                    name="freightType"
                                    value="DOMESTIC"
                                    readOnly={true}
                                    // onChange={handleRadio}
                                    checked={serviceDetails.freightType == "DOMESTIC"}
                                />
                                <label>Domestic Freight</label>
                            </div>
                        </div>
                        <div style={{ margin: '10px' }}>Mode of Shipment</div>
                        <div style={{ width: '25%', margin:"10px" }}>
                            <Input
                                name="transportMode"
                                placeholder="Transport Mode"
                                readOnly={true}
                                value={serviceDetails.transportMode}
                            />
                        </div>
                        <div>
                            <div className={classes.source}>
                                <div style={{  margin: "10px", width: "50%" }}>
                                    <label>CARGO READY DATE</label>
                                </div>
                                <div style={{margin: "10px", width: "50%" }}>
                                    {serviceDetails && serviceDetails.transportMode && serviceDetails.transportMode === "MARITIME" ? (
                                        <label>PREFERRED SAILING DATE</label>
                                    ) : (<label>PREFERRED DISPATCH DATE</label>
                                    )}

                                </div>
                            </div>
                            <div className={classes.source}>
                                <div style={{ width: "50%", margin: "10px" }}>
                                    <Grid item xs={12} md={6} >
                                        <Input
                                            name="CARGO_READY"
                                            placeholder="Cargo Ready Date"
                                            value={new Date(
                                                serviceDetails.CARGO_READY
                                              ).toLocaleDateString()}
                                            readOnly={true}
                                        />
                                    </Grid>
                                </div>
                                <div style={{ width: "50%", margin:"10px" }}>
                                    <Grid item xs={12} md={6} >
                                        <Input
                                            name="PREFERRED_SAILING_DATE"
                                            placeholder="PREFERRED SAILING DATE"
                                            value={new Date(
                                                serviceDetails.PREFERRED_SAILING_DATE
                                              ).toLocaleDateString()}
                                            readOnly={true}
                                        />
                                    </Grid>
                                </div>
                            </div>
                        </div>
                        <div style={{ margin: "10px" }}>
                            Shipment details
                        </div>
                        <div className={classes.source}>
                            <div style={{ width: "50%", margin: "10px" }}>
                                <Select
                                    options={Countries}
                                    name="countryFrom"
                                    placeholder="Country  From"
                                    value={serviceDetails.countryFrom}
                                    readOnly={true}
                                />
                            </div>


                            <div style={{ width: "50%", margin: "10px" }}>
                                <Select
                                    options={Countries}
                                    name="countryTo"
                                    placeholder="Country To"
                                    value={serviceDetails.countryTo}
                                    readOnly={true}
                                />
                            </div>
                        </div>
                        {/* Countries */}
                        <div className={classes.source}>
                            <div style={{ width: "50%", margin: "10px" }}>
                                <Input

                                    name="cityFrom"
                                    placeholder="City From"
                                    value={serviceDetails.cityFrom}
                                    readOnly={true}
                                />
                            </div>


                            <div style={{ width: "50%", margin: "10px" }}>
                                <Input
                                    name="cityTo"
                                    placeholder="City To"
                                    value={serviceDetails.cityTo}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div className={classes.source}>
                            <div style={{ width: "50%", margin: "10px" }}>
                                <Select
                                    options={port_code}
                                    name="portOrigin"
                                    placeholder="From Port"
                                    value={serviceDetails.portOrigin}
                                    readOnly={true}
                                />
                            </div>


                            <div style={{ width: "50%", margin: "10px" }}>
                                <Select
                                    options={port_code}
                                    name="destinationPort"
                                    placeholder="To Port"
                                    value={serviceDetails.destinationPort}
                                    readOnly={true}
                                />
                            </div>
                        </div>

                        <div>
                            <div>
                                <div style={{ margin: "10px" }}>
                                    <label>COMMODITY</label>
                                </div>
                            </div>
                            <div className={classes.source}>

                                <div style={{ width: "100%",margin:"10px" }}>
                                    <Input

                                        name="commodity"
                                        placeholder="Commodity"
                                        readOnly={true}
                                        value={serviceDetails.commodity}
                                    // readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>



                        <>
                            <div style={{ margin: "10px" }}>
                                Cargo Details
                            </div>
                            <div className={classes.TableContainer}>
                                <Table bordered responsive="sm" className={cn(classes.Table)}>
                                    <thead>
                                        <tr>
                                            {LoadHeader &&
                                                LoadHeader.map((header, i) => (
                                                    <td key={i}>{header.name}</td>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(serviceDetails && serviceDetails.cargoDetails && serviceDetails.cargoDetails).map((item, i) => (
                                            <tr key={i}>
                                                {/* <td>
                                                    {item &&
                                                        item.currency}
                                                </td> */}
                                                <td>{item &&
                                                    item.cargoValue}{" "}{item &&
                                                        item.currency}</td>
                                                <td>
                                                    {item &&
                                                        item.qty}{" "}{item &&
                                                            item.qtyUnit}
                                                </td>
                                                {/* <td>
                                                    {item &&
                                                        item.qtyUnit}
                                                </td> */}
                                                <td>
                                                    {item &&
                                                        item.volume}{" "}{item &&
                                                            item.volumeUnit}
                                                </td>
                                                {/* <td>
                                                    {item &&
                                                        item.volumeUnit}
                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>

                        {serviceDetails && serviceDetails.cargoHazardous &&
                            (
                                <div>
                                    <input
                                        className={classes.Checkbox}
                                        type="checkbox"
                                        checked={true}
                                    // onClick={handleCheckbox}
                                    // onChange={(e) => setIsHazardous(e.target.checked)}
                                    />
                                    <label
                                        style={{ margin: "10px", fontSize: "18px" }}
                                    >
                                        {" "}
                                        This cargo is hazardous
                                    </label>
                                </div>
                            )
                        }

                        {serviceDetails && serviceDetails.cargoHazardous && (

                            <div className={classes.source} >
                                <div className={classes.source} style={{ width: "40%" }}>
                                    <div style={{ width: "50%", margin:"10px" }}>
                                        <Input
                                            // options={cargoHazardousOptions}
                                            name="cargoHazardous"
                                            placeholder="cargo Hazardous"
                                            // handleChange={handleChange}
                                            value={serviceDetails && serviceDetails.cargoHazardous}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div style={{ width: "50%", margin:"10px" }}>
                                        <Input
                                            name="UN_Number"
                                            placeholder="UN#"
                                            value={serviceDetails && serviceDetails.UN_Number}
                                            // handleChange={handleChange}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        )}
                    </> : <></>
                }
            </Content>
        </>
    );
};

export default Transport;
