import React, { useState, useEffect } from "react";
import classes from "../../New-Service/Category/freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";

import Currenciesr from 'jsons/Currenciesr.json';
import { Countries } from "utils/DropDown/Country";


import { TextArea } from "components/fields";

import { CheckCategoryTitle, CheckCountryTitle, CheckCurrencyTitle } from "utils/helper";

const ExportCompliance = ({ response }) => {
  const [serviceDetails, setServiceDetails] = useState([]);
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (response && response.ServiceReqDetails) {
        setServiceDetails(JSON.parse(response.ServiceReqDetails))
       
      }
      
    }
    return () => {
      isCancelled = true;
    };
  }, [response]);

  // useEffect(() => {
  //   if (response && response.ServiceReqDetails) {
  //     setServiceDetails(JSON.parse(response.ServiceReqDetails))

  //     //JSON.parse(response.ServiceReqDetails.commodity)
  //   }
  // }, [response]);
  // console.log("serviceDetails", serviceDetails)

  let ice = serviceDetails.currencyType;
  return (<>

    {response && serviceDetails ?
      <>
        <div style={{  margin: "10px" }}>COMPLIANCE EXPORT DECLARATIONS
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%",  margin: "10px" }}>
            <Input
              name="ShipperName"
              placeholder="Supplier Name"
              readOnly={true}
              value={serviceDetails && serviceDetails.ShipperName}
            // readOnly={true}
            />
          </div>


          <div style={{ width: "50%",  margin: "10px" }}>
            <Input
              name="ReceiverName"
              placeholder="Buyer Name"
              readOnly={true}
              value={serviceDetails && serviceDetails.ReceiverName}

            // readOnly={true}
            />


          </div>
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%",  margin: "10px" }}>
            <Input
              name="contactInfoShipper"
              placeholder="Supplier Contact Information"
              readOnly={true}
              value={serviceDetails && serviceDetails.contactInfoShipper}
            // readOnly={true}
            />
          </div>


          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="contactInfoBuyer"
              placeholder="Buyer Contact Information"
              readOnly={true}
              value={serviceDetails && serviceDetails.contactInfoBuyer}

            // readOnly={true}
            />


          </div>
        </div>



        <div className={classes.source}>
        <div style={{ width: "100%", margin: "10px" }}>
          <Input
            name="ShipperAddress"
            placeholder="Supplier Address"
            readOnly={true}
            value={serviceDetails && serviceDetails.ShipperAddress}
          // readOnly={true}
          />
        </div>
        </div>
        <div className={classes.source}>
        <div style={{ width: "100%", margin: "10px" }}>
          <Input
            name="ReceiverAddress"
            placeholder="Buyer Address "
            readOnly={true}
            value={serviceDetails && serviceDetails.ReceiverAddress}
          // readOnly={true}
          />
        </div>
        </div>


        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              options={Countries}
              name="countryFrom"
              placeholder="Country  From"
            
              value={CheckCountryTitle(serviceDetails.countryFrom)}
              readOnly={true}
            />
          </div>


          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              options={Countries}
              name="countryTo"
              placeholder="Country To"
              
              value={CheckCountryTitle(serviceDetails.countryTo)}
              readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}






<div>
  <div>

  
       
            <div >
            
              <div className={classes.source}>
              
                </div>
            </div>
         
</div>
         
             

    
    
       

           
              <div style={{ margin: "10px" }}>
                <label>COMMODITY</label>
                </div>
                <div className={classes.source}>
                <div style={{ width: "50%", margin:"10px", paddingRight:"20px" }}>
                 <Input
                  //options={Industry}
                  name="commodity"
                  placeholder="Product Category"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.commodity}
               
                />
                </div>
              </div>
             
         
            
            <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
            <Input
                  name="ProductName"
                  placeholder="Product Name"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ProductName}
                // readOnly={true}
                />
              </div>
           

              <div style={{ width: "50%", margin:"10px" }}>
                <Input
                  name="productDescription"
                  placeholder="Product Description"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.productDescription}
                // readOnly={true}
                />
              </div>
        
          </div>
          <div className={classes.source}>

            <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
              <Input
                name="hsnOfCode"
                placeholder="HSN code of Goods"
                readOnly={true}
                value={serviceDetails && serviceDetails.hsnOfCode}
              // readOnly={true}
              />
            </div>

          </div>
          {/* <label>Shipment Details</label> */}

          {/* </div> */}
          <div className={classes.source}>




            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                //   options={qtyUnitOptions}
                name="quantityType"
                placeholder="Quantity of Goods "
                readOnly={true}
                value={serviceDetails && serviceDetails.quantityType}
              //  handleChange={handleChange}
              />

            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="quantity"
                placeholder="Quantity"
                //   handleChange={handleChange}
                value={serviceDetails && serviceDetails.quantity}
                readOnly={true}
              />
            </div>
          </div>





          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
           
              <Input
                options={Currenciesr}
                name = "currencyType"
                placeholder="Currency"
                readOnly={true}
               value={ CheckCurrencyTitle(serviceDetails.currencyType)}
               //  value={serviceDetails && serviceDetails.currencyType}
              />

            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="currencyAmount"
                placeholder={`Estimated Cost of Goods or Services  (${serviceDetails && serviceDetails.currencyType})`}

                readOnly={true}
                value={serviceDetails && serviceDetails.currencyAmount}
              // readOnly={true}
              />
            </div>
          </div>




          <div className={classes.source}>

            <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
              <Input
                //options={exportType}
                name="exportType"
                placeholder="Selected Type of Export "
                value={serviceDetails && serviceDetails.exportType}
                // handleChange={handleChange}
                readOnly={true}
              />

            </div>
          </div>
        </div>












        <div >
          {/* <label>INCOTERM 


</label> */}
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
            <Input
              ////options={incoterm}
              name="incoterm"
              placeholder="Incoterm"
              value={serviceDetails && serviceDetails.incoterm}
              readOnly={true}
            />

          </div>


          {serviceDetails && serviceDetails.incoterm == "OTHERS" ? <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="incoterms"
              placeholder="Incoterm"
              readOnly={true}
              value={serviceDetails && serviceDetails.incoterms}
            // readOnly={true}
            />
          </div> : <div></div>}
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
            <Input
              //options={paymentTerms}
              name="paymentTerms"
              placeholder="Payment Term"
              value={serviceDetails && serviceDetails.paymentTerms}
              readOnly={true}
            />

          </div>
          {serviceDetails && serviceDetails.paymentTerms == "OTHERS" ? <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="paymentTerm"
              placeholder="Payment Term"
              readOnly={true}
              value={serviceDetails && serviceDetails.paymentTerm}
            // readOnly={true}
            />
          </div> : <div></div>}
        </div>

        <div style={{ width: "100%", margin: "10px", paddingRight:"20px" }}>
          <TextArea
            name="requirement"
            placeholder="Export Declaration Compliance Specific coverage Requirements"
            value={serviceDetails && serviceDetails.requirement}
            readOnly={true}
          />




        </div>












      </> : <></>
    }



  </>
  )
};

export default ExportCompliance;