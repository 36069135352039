import React from "react";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import classes from "./Alert.module.css";
import { AlertHeader,api_response } from "utils/TableHeader/Alert";
const index = () => {
  return (
    <Container>
      <h5>Deal Detail</h5>
      <Content>
        <Table bordered responsive className={cn(classes.Table)}>
          <thead>
            <tr>
              {AlertHeader && 
                AlertHeader.map((header, index) => (
                <td key={index}>{header.name}</td>
              ))}
            </tr>
          </thead>

           <tbody>
            {api_response && api_response.length != 0 ? (
              api_response.map((data, index) => (
                <tr>
                  <td>{data.Alert}</td>
                  <td>{data.Origin}</td>
                  <td>{data.Type}</td>
                  <td>{data.Response}</td>
                  <td>{data.Date}</td>
                </tr>
              ))
            ) : (
              <span>No Data</span>
            )}
          </tbody> 
        </Table>
      </Content>
    </Container>
  );
};

export default index;
