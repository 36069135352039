import Modal from "react-bootstrap/Modal";
import classes from "./Modal.module.css";
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      className={classes.ModalContainer}
      show={props.show}
      onHide={props.onHide}
      size={props?.size || "lg"} // sm // lg // xl
      aria-labelledby="contained-modal-title-vcenter"
      centered={props?.centered ? false : true}
      backdrop="static"
      scrollable={true}>
      <Modal.Header
        closeButton
        className={classes.ModalHeader}
        closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter">
          {props.Heading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.ModalBody}>{props.Data}</Modal.Body>
    </Modal>
  );
}

export default MyVerticallyCenteredModal;
