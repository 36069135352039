import { createContext, useEffect, useState } from "react";

export const TradeContext = createContext();

export const TradeContextProvider = ({children})=>{
    const [companyList, setCompanyList] = useState();
    const companyId = JSON.parse(sessionStorage.getItem("companyId"));
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    useEffect(() => {
        const requestOptions = {
          method: 'GET',
          requireAuthentication: true,
          headers: {
            "Authorization": "Bearer " + idToken,
            "x-api-key":
              "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
          },
        };
        const fetchData = async () => {
          await fetch(`${process.env.REACT_APP_NETWORK_URL}members?memberId=${companyId}`, requestOptions)
            .then(response => response.json()).then((data) => {
              setCompanyList(data)
            }
            )
        }
        fetchData();
      }, [])
      return (
        <TradeContext.Provider value={{ companyList: companyList}}>
         {children}
        </TradeContext.Provider>
      );

}

