import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SearchMember from "components/SearchMember/searchMember";

import { withSendBird, sendBirdSelectors } from "sendbird-uikit";
import {BsPlusLg} from "react-icons/bs";
import {FaRegUser} from "react-icons/fa";
import userLogo from '../../images/chat.svg'
import { FiSearch } from "react-icons/fi";
import { fetchMemberdata } from "utils/memberchat";
import { useEffect } from "react";

function CustomChannelListHeader(props) {
  const {
    // from withSendBird
    createChannel,
    sdk,
    // from parent component as prop
    setChannelUrl,
    onSetQuery,
    user
  } = props;
 
  const [groupName,setGroupName]=useState("");
  const [memberUserId,setMemberUserId]=useState([]);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleMember =(data)=>{
        setMemberUserId(data);
  }
  const handleGroupName = (value)=>{
     setGroupName(value)
  }

  const hanndleCraateGroup =  async(users, name, chaturl) => {
    let res = await fetchMemberdata(memberUserId);
    const selectedUsers = res;
    let params = new sdk.GroupChannelParams();
    params.addUserIds(selectedUsers);
    params.isDistinct = false;
    params.isEphemeral = false;
    params.isPublic = false;

    params.name = groupName;
    params.channelUrl = chaturl;
    sdk.GroupChannel.createChannel(params, (response, error) => {
      const swapParams = sdk.getErrorFirstCallback();
      let groupChannel = response;
      let err = error;
      if (swapParams) {
        groupChannel = error;
        err = response;
      }
    });
    handleClose();
  };
  return (
    <>
      <div className="custom-channel-list tilteCust">
        <div style={{display:"flex",backgroundColor:"#2C2C2C",height:"3.5rem",alignItems:"center"}}>
          <img style={{width:"25px",height:"25px",marginLeft:"1rem"}}src={userLogo}></img>
          <div style={{width:"60%",fontSize:"1rem"}} className="toptitle">Communications</div>
          <div ><Button className="btnplus" onClick={handleClickOpen}><BsPlusLg/></Button></div>
        </div>
        <Dialog 
        classes={{
          paperScrollPaper:"chatcontainer"
        }}
        open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" className="toptitle_mod">Create Group</DialogTitle>
                   <DialogContent>
            <DialogContentText>
                 <div>
                    <SearchMember handleMember={handleMember} handleGroupName={handleGroupName}/>
                  </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={hanndleCraateGroup}
              autoFocus
              >
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      
      <div>
         <div style={{display:"flex"}}>
          <sapn style={{paddingTop:".7rem",backgroundColor:"white",color:"#2C2C2C",paddingLeft:"10px"}}><FiSearch/></sapn>
          <input style={{width:"100%",height:"2.5rem",border:"none", outline:"none",paddingLeft:"10px"}}
          onChange={(e) => {
            // apply a throttle here to avoid calling setQuery on every instance see the links for more info
            // https://codeburst.io/throttling-and-debouncing-in-javascript-b01cad5c8edf
            // https://github.com/bhaskarGyan/use-throttle
            onSetQuery({
              channelListQuery: {
                channelNameContainsFilter: e.target.value
              }
            });
          }}
          placeholder="Search channel"
        />
        </div>
      </div>
    </>
  );
}

export default withSendBird(CustomChannelListHeader, (state) => {
  return {
    createChannel: sendBirdSelectors.getCreateChannel(state),
    sdk: sendBirdSelectors.getSdk(state)
  };
});
