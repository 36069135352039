import React from "react";
import { ToTitleCase } from "../../../common/utils/toTitleText";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { FaRegClock } from "react-icons/fa";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { FaFileContract } from "react-icons/fa";
import { GrMoney } from "react-icons/gr";
import { GrVirtualMachine } from "react-icons/gr";
import { BsTruck } from "react-icons/bs";
import { FaShip } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 300,
  },
});

export const FirstLevelNode = ({
  index,
  item,
  initialState,
  handleClick,
  firstFlow,
  backgroundColor,
  width,
}) => {
  const stage = ToTitleCase(item.tradeStage);
  const icon =
    stage === "Procurement" ? (
      <AiOutlineShoppingCart size={26} />
    ) : stage === "Trade Contract" ? (
      <FaFileContract size={26} />
    ) : stage === "Financing" ? (
      <GrMoney size={26} />
    ) : stage === "Manufacturing" ? (
      <GrVirtualMachine size={26} />
    ) : stage === "Transport Export" ? (
      <BsTruck size={26} />
    ) : stage === "Shipping" ? (
      <FaShip size={26} />
    ) : stage === "Transport Import" ? (
      <BsTruck size={26} />
    ) : (
      <RiSecurePaymentLine size={26} />
    );

  const ToolkitHandler = () => {
    return (
      <div>
        <div>
          <FaRegClock /> Planned Start Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Planned End Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Projected Start Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Projected End Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CustomWidthTooltip arrow title={<ToolkitHandler />} placement="top">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}>
              {index + 1 === initialState ? (
                <div
                  className="icon4"
                  style={{ backgroundColor: backgroundColor }}
                  onClick={(e) => handleClick(e, index + 1, stage)}>
                  <span
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    {icon}
                  </span>
                </div>
              ) : (
                <div
                  className="icon"
                  style={{ backgroundColor: backgroundColor }}
                  onClick={(e) => handleClick(e, index + 1, stage)}>
                  <span
                    style={{
                      width: "50px",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    {icon}
                  </span>
                </div>
              )}
              <div
                style={
                  width >= 1400
                    ? {
                        marginTop: "1rem",
                        fontSize: "14px",
                        width: "78px",
                        justifyContent: "center",
                        textAlign: "center",
                      }
                    : width >= 1300 && width <= 1400
                    ? {
                        marginTop: "1rem",
                        fontSize: "12px",
                        width: "70px",
                        justifyContent: "center",
                        textAlign: "center",
                      }
                    : width >= 1100 && width <= 1200
                    ? {
                        marginTop: "1rem",
                        fontSize: "12px",
                        width: "70px",
                        justifyContent: "center",
                        textAlign: "center",
                      }
                    : {
                        marginTop: "1rem",
                        fontSize: "10px",
                        width: "50px",
                        justifyContent: "center",
                        textAlign: "center",
                      }
                }>
                {stage}
              </div>
            </div>
          </CustomWidthTooltip>
          {index !== firstFlow.length - 1 && (
            <div
              style={{
                width: "4rem",
              }}
              // style={
              //   width >= 1400
              //     ? { width: `4.5rem`, marginTop: "3%", display: "flex" }
              //     : width >= 1300 && width <= 1400
              //     ? { width: `4rem`, marginTop: "-7%", display: "flex" }
              //     : width >= 1100 && width <= 1200
              //     ? { width: `3rem`, marginTop: "-7%", display: "flex" }
              //     : width >= 900 && width <= 1000
              //     ? { width: `2.5rem`, marginTop: "-10%", display: "flex" }
              //     : { width: `1.8rem`, marginTop: "-14%", display: "flex" }
              // }
            >
              <hr
                className="vline"
                style={{ border: `2px solid ${backgroundColor}` }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SecondLevelNode = ({
  item,
  backgroundColor,
  secondFlowSequence,
  index,
  handleSecondFlow,
  initialArr,
  width,
}) => {
  const ToTitleCase = (str) => {
    return str
      .toLowerCase()
      .split("_")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };
  const ToolkitHandler = () => {
    return (
      <div>
        <div>
          <FaRegClock /> Planned Start Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Planned End Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Projected Start Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
        <div>
          <FaRegClock /> Projected End Date:{" "}
          {item.plannedEndDate
            ? item.plannedEndDate
            : new Date().toLocaleDateString()}
        </div>
      </div>
    );
  };
  return (
    <div style={{ display: "flex" }}>
      <CustomWidthTooltip arrow title={<ToolkitHandler />} placement="top">
        <div style={{ cursor: "pointer" }}>
          {item.subStageSequence == secondFlowSequence ? (
            <div
              className="icon3"
              style={
                width >= 1400
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "11px",
                      width: "100px",
                      textAlign: "center",
                      height: "40px",
                      fontWeight: "bold",
                    }
                  : width >= 1300 && width <= 1400
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "9px",
                      width: "88px",
                      textAlign: "center",
                      height: "40px",
                      fontWeight: "bold",
                    }
                  : width >= 1100 && width <= 1200
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "7.5px",
                      width: "75px",
                      textAlign: "center",
                      height: "37px",
                      fontWeight: "bold",
                    }
                  : width >= 900 && width <= 1000
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "6.5px",
                      width: "60px",
                      textAlign: "center",
                      height: "30px",
                      fontWeight: "bold",
                    }
                  : {
                      backgroundColor: backgroundColor,
                      fontSize: "6.5px",
                      width: "60px",
                      textAlign: "center",
                      height: "30px",
                      fontWeight: "bold",
                    }
              }
              onClick={(e) => handleSecondFlow(e, item)}>
              {ToTitleCase(item.tradeSubStage)}
            </div>
          ) : (
            <div
              className="icon2"
              style={
                width >= 1400
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "11px",
                      width: "100px",
                      textAlign: "center",
                      height: "40px",
                      fontWeight: "bold",
                    }
                  : width >= 1300 && width <= 1400
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "9px",
                      width: "88px",
                      textAlign: "center",
                      height: "40px",
                      fontWeight: "bold",
                    }
                  : width >= 1100 && width <= 1200
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "7.5px",
                      width: "75px",
                      textAlign: "center",
                      height: "37px",
                      fontWeight: "bold",
                    }
                  : width >= 900 && width <= 1000
                  ? {
                      backgroundColor: backgroundColor,
                      fontSize: "6.5px",
                      width: "60px",
                      textAlign: "center",
                      height: "30px",
                      fontWeight: "bold",
                    }
                  : {
                      backgroundColor: backgroundColor,
                      fontSize: "6.5px",
                      width: "60px",
                      textAlign: "center",
                      height: "30px",
                      fontWeight: "bold",
                    }
              }
              onClick={(e) => handleSecondFlow(e, item)}>
              {ToTitleCase(item.tradeSubStage)}
            </div>
          )}
        </div>
      </CustomWidthTooltip>
      {index !== initialArr.data.length - 1 && (
        <div
          style={
            width >= 1400
              ? {
                  width: `2rem`,
                  marginTop: "0px",
                  marginLeft: "4px",
                  marginRight: "4px",
                }
              : width >= 1300 && width <= 1400
              ? {
                  width: `2rem`,
                  marginTop: "5px",
                  marginLeft: "4px",
                  marginRight: "4px",
                }
              : width >= 1100 && width <= 1200
              ? {
                  width: `1rem`,
                  marginTop: "3px",
                  marginLeft: "4px",
                  marginRight: "4px",
                }
              : width >= 900 && width <= 1000
              ? {
                  width: `1rem`,
                  marginTop: "-2px",
                  marginLeft: "4px",
                  marginRight: "4px",
                }
              : {
                  width: `1rem`,
                  marginTop: "-2px",
                  marginLeft: "4px",
                  marginRight: "4px",
                }
          }>
          <hr
            className="vline"
            style={{ border: `2px solid ${backgroundColor}` }}
          />
        </div>
      )}
    </div>
  );
};
