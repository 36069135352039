import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";

import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";

import Table from "react-bootstrap/Table";
import cn from "classnames";
import { Countries } from "utils/DropDown/Country";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";

import { Grid } from "@mui/material";

const InspectionQuality = ({ response }) => {
  let inspectionMedium = [
    {
      label: "Visual inspection",
      value: "Visual inspection",
    },
    {
      label: "Physical inspection",
      value: "Physical inspection",
    },
    {
      label: "Remote inspection",
      value: "Remote inspection",
    },
  ];

  let inspectionServices = [
    {
      label: "Pre-production Inspections",
      value: "Pre-production Inspections",
    },
    {
      label: " During Production Inspections ",
      value: " During Production Inspections",
    },
    {
      label: "Pre-shipment Inspections ",
      value: "Pre-shipment Inspections ",
    },
    {
      label: "Sample Checking ",
      value: "Sample Checking ",
    },
    {
      label: "Container loading Supervision ( CLS ) and Unloading supervision ",
      value: "Container loading Supervision ( CLS ) and Unloading supervision ",
    },
    {
      label: "CLC – Container Loading Check",
      value: "CLC – Container Loading Check",
    },
    {
      label: "IPC – Initial Production Check",
      value: "IPC – Initial Production Check",
    },
    {
      label: "Piece by Piece Inspection (or Sorting Inspection)",
      value: "Piece by Piece Inspection (or Sorting Inspection)",
    },
    {
      label: "Final Random Inspection",
      value: "Final Random Inspection",
    },
  ];

  let loadstype = [
    {
      label: "Full container loads",
      value: "Full container loads",
    },
    {
      label: "General Cargo",
      value: "General Cargo",
    },
    {
      label: "Boxes/packages",
      value: "Boxes/packages",
    },
  ];

  let Packaging = [
    {
      label: "Palettes",
      value: "Palettes",
    },
    {
      label: "Drums",
      value: "Drums",
    },
    {
      label: "Containers",
      value: "Containers",
    },
    {
      label: "Boxes",
      value: "Boxes",
    },
    {
      label: "Bulk",
      value: "Bulk",
    },
    {
      label: "Break Bulk",
      value: "Break Bulk",
    },

    { label: "Not relevant", value: "Not relevant" },
  ];

  let containers = [
    {
      label: "10-Foot Container",
      value: "10-Foot Container",
    },
    {
      label: "20-Foot Container",
      value: "20-Foot Container",
    },
    {
      label: "40-Foot Container",
      value: "40-Foot Container",
    },
    {
      label: "20-Foot High Cube",
      value: "20-Foot High Cube",
    },
    {
      label: "440-Foot High Cube",
      value: "40-Foot High Cube",
    },
    {
      label: "Not relevant",
      value: "Not relevant",
    },
  ];

  let samples = [
    {
      label: "No approved sample",
      value: "No approved sample",
    },
    {
      label: "Approved sample at factory",
      value: "Approved sample at factory",
    },
    {
      label: "Not relevant",
      value: "Not relevant",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  let locations = [
    {
      label: "Supplier",
      value: "Supplier",
    },
    {
      label: "Freight forwarder",
      value: "Freight forwarder",
    },
    {
      label: "Logistic hub",
      value: "Logistic hub",
    },
    {
      label: "Warehouse",
      value: "Warehouse",
    },
    {
      label: "Building/site",
      value: "Building/site",
    },
  ];

  const [serviceDetails, setServiceDetails] = useState([]);

  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails));
    }
  }, [response]);

  let listService = serviceDetails.inspectionService?.map((user) => {
    return user.value;
  });
  let products = serviceDetails.commodity?.map((user) => {
    return user.value;
  });

  console.log(response, "saurabh");

  return (
    <>
      {response && serviceDetails ? (
        <>
          <div style={{ margin: "10px" }}>
            General Inspection
          </div>

          <div className={classes.source}>
            <Input
              name="ServiceReqDescription"
              placeholder="Give your own short description of the job*"
              value={serviceDetails && serviceDetails.ServiceReqDescription}
              //  handleChange={(e) => handleInputChange(e)}
              readOnly={true}
            />
          </div>
          <div style={{  margin: "10px" }}>
            INSPECTION MEDIUM
          </div>
          <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                // options={inspectionMedium}
                name="inspectionMedium"
                placeholder="Inspection Medium"
                //handleChange={handleChange}
                value={serviceDetails && serviceDetails.inspectionMedium}
                readOnly={true}
              />
            </div>
          </div>
          <div style={{ margin: "10px" }}>
            What type of Inspection Services you need?*
          </div>
          <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                // options={inspectionServices}
                name="inspectionService"
                placeholder="Inspection Service"
                value={listService}
                //     handleChange={(e) => handleMultiChange(e, "inspectionMedium")}

                readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            {/* <label>Product Category</label> */}
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                // options={inspectionServices}
                name="commodity"
                placeholder="Product Category"
                value={products}
                //     handleChange={(e) => handleMultiChange(e, "inspectionMedium")}

                readOnly={true}
              />
            </div>
          </div>

          <div>
            <div></div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <label>When do you need your inspection quote by?*</label>
              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <label>When do you need your inspection quote by?*</label>
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <DateInput
                  name="qouteBy"
                  placeholder="Start Date *"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.qouteBy}
                  // handleChange={(e) =>
                  //  handleDateChange(e, "qouteBy")
                  //      }
                />
              </div>

              <div
                style={{ width: "50%",  margin: "10px" }}
              >
                <DateInput
                  name="reportBy"
                  placeholder="Date *"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.reportBy}
                  //handleChange={(e) =>
                  //   handleDateChange(e, "reportBy")
                  //     }
                />
              </div>
            </div>

            <label style={{  margin: "10px" }}>
              What are we talking about?
            </label>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  // options={loadstype}
                  name="loadsType"
                  placeholder="Load Type "
                  readOnly={true}
                  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.loadsType}
                  // readOnly={true}
                />
              </div>
            </div>
            <label style={{ margin: "10px" }}>
              If it is packed, do you know how?
            </label>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  // options={Packaging}
                  name="packed"
                  placeholder="Select *"
                  readOnly={true}
                  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.packed}
                  // readOnly={true}
                />
              </div>
            </div>

            <label style={{ margin: "10px" }}>
              If it is packed in containers do you know what kind?
            </label>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  // options={containers}
                  name="packType"
                  placeholder="Packaging Type "
                  //handleChange={handleChange}
                  value={serviceDetails && serviceDetails.packType}
                  readOnly={true}
                />
              </div>
            </div>

            <div className={classes.source}></div>
          </div>

          <div style={{ margin: "10px" }}>
            <label>
              If the inspection is done against an approved sample, where is it?
            </label>
          </div>
          <div style={{ width: "100%", margin: "10px", paddingRight:"20px" }}>
            <Input
              // options={samples}
              name="approvedSample"
              placeholder="Approved Sample"
              readOnly={true}
              value={serviceDetails && serviceDetails.approvedSample}
              //handleChange={handleChange}
            />
          </div>

          <div style={{ margin: "10px" }}>
            <label>The company or the location is a ?</label>
          </div>
          <div style={{ width: "100%", margin: "10px", paddingRight:"20px" }}>
            <Input
              // options={samples}
              name="companyLocation"
              placeholder="Company Location"
              readOnly={true}
              value={serviceDetails && serviceDetails.companyLocation}
              //handleChange={handleChange}
            />
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="companyName"
                placeholder="Company Name"
                //handleChange={handleChange}
                value={serviceDetails && serviceDetails.companyName}
                readOnly={true}
              />
            </div>
            <div style={{ width: "50%", marginBottom: "10px" }}>
              <Input
                name="contactinfo"
                placeholder="Contact Information"
                //handleChange={handleChange}
                value={serviceDetails && serviceDetails.contactinfo}
                readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <Input
              name="address"
              placeholder="Address"
              //handleChange={handleChange}
              value={serviceDetails && serviceDetails.address}
              readOnly={true}
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default InspectionQuality;
