import React, { useState, useEffect } from "react";
import {
  SOLUTIONS_TYPE_TRADER,
  SOLUTIONS_TYPE_SERVICE_PROVIDER,
  SOLUTIONS_TYPE_FINANCER,
  SOLUTIONS_TYPE,
} from "utils/Config";
import { useNavigate } from "react-router-dom";
import styles from "./dashboard.module.css";
import { FaFileInvoice, FaHandshake } from "react-icons/fa";
import { CiDollar, CiBank } from "react-icons/ci";
import { IoMdChatbubbles } from "react-icons/io";
import { MdPayment, MdOutlineNotificationsNone } from "react-icons/md";
import { GrOrganization, GrServices } from "react-icons/gr";
import {
  docGroupURL,
  onBoardingURL,
  profileURL,
} from "services/BaseURLInstance";

import { useMsal } from "@azure/msal-react";
import Content from "components/UI/Layout/Content";

import Carousel from "react-bootstrap/Carousel";
import Icons from "country-flag-icons/react/3x2";
import { UseRequest } from "utils/API/Request.js";
import { getIdTokenAsync } from "utils/authUtil";
import Alert from "react-bootstrap/Alert";
import { Error, Success } from "components/notifications/responseHandle";
import Loader from "components/Loader/Loader";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import Modal from "react-bootstrap/Modal";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";

ChartJS.register(ArcElement, Tooltip, Legend);

export default () => {
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [show, setShow] = useState(true);
  const [subtype, setSubtype] = useState([]);

  const [trustData, setTrustData] = useState(null);
  const [welcomeMessage, setWelcomeMessage] = useState(null);
  const [variant, setVariant] = useState("");
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const [companyData, setCompanyData] = useState(null);
  const [isTermOpen, setIsTermOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && companyId) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${docGroupURL}bff/supplier/dashboard?memberId=${companyId}`,
          null,
          response
        )
          .then((data) => {
            let { message, status } = data;
            if (message || status) {
              Error(message);
              setIsError(message);
            } else {
              setResponse(data);
            }
          })
          .catch(() => {
            setIsLoading(false);
            setIsError();
          });
      });
    }

    if (!isCancelled && companyId) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${onBoardingURL}onboarding/participant/${companyId}`,
          null,
          response
        )
          .then((data) => {
            let { message, status } = data;
            if ((message, status)) {
              Error(message);
              setIsError(message || "Something went wrong");
            } else {
              setTrustData(data);
            }
          })
          .catch(() => {
            setIsLoading(false);
            setIsError();
          });
      });
    }

    if (!isCancelled && companyId) {
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${profileURL}financers/${companyId}`;
      } else {
        URL = `${profileURL}companies/${companyId}`;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            setCompanyData(data);
            if (data && data.tncStatus === true) {
              setIsTermOpen(false);
            } else {
              setIsTermOpen(true);
            }
          })
          .catch((error) => {
            setIsError(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [companyId]);

  const handleOnClick = (path) => {
    let url = window.location.href;
    localStorage.setItem("lasturl", url);
    navigate(path);
  };

  useEffect(() => {
    if (memberType === "TRADER") {
      setSubtype(SOLUTIONS_TYPE_TRADER);
    }

    if (memberType === "FINANCER") {
      setSubtype(SOLUTIONS_TYPE_FINANCER);
    }
    if (memberType === "SERVICE_PROVIDER") {
      setSubtype(SOLUTIONS_TYPE_SERVICE_PROVIDER);
    }
  }, [companyId]);

  const NewData = [
    {
      name: "Financing",
      subName: null,
      datatype: "currency",
      datavalue: 250,
      dataCurrency: "USD",
      dataValueType: null,
      dataValueYear: null,

      detailsType: "currency",
      details: {
        title: "Financing Opportunities",
        value: 38,
        subTitle: null,
      },

      topListTitle: "Top Financers",
      topListType: "currency",
      topListCurrency: "USD",
      topList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <CiBank size={24} />
              </span>
              CitiBank
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <CiBank size={24} />
              </span>
              Axis Bank
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <CiBank size={24} />
              </span>
              HDFC
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
      ],

      columnsListType: "currency",
      columnsListCurrency: "USD",
      columnsList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <CiDollar size={24} />
              </span>
              Open Financing
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <CiDollar size={24} />
              </span>
              Approved Financing
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <CiDollar size={24} />
              </span>
              Deal-in-Review
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <CiDollar size={24} />
              </span>
              Financing Open
            </>
          ),
          number: 5,
          value: 10,
        },
      ],

      requestCurrency: null,
      request: [
        {
          title: "Open Deals",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Syndicate Deals",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Disbursements",
          requestNo: 10,
          requestValue: null,
        },
      ],
      dataSet: {
        labels: [
          "Requisition KYB",
          "Finance Deals",
          "KYB",
          "Credit Assessment",
          "Financing Aggreement",
        ],
        datasets: [
          {
            label: "Financing Deals",
            data: [300, 500, 150, 200, 400],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
            ],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      name: "Network",
      subName: "Domain- IN",
      datatype: "number",
      datavalue: 250,
      dataCurrency: null,
      dataValueType: "Participants",
      dataValueYear: null,

      detailsType: "number",
      details: {
        title: "Network Recommendations",
        value: 38,
        subTitle: null,
      },

      topListTitle: "Top Countries in Network",
      topListType: "number",
      topListCurrency: null,
      topList: [
        {
          name: "UAE",
          value: 35,
          flags: "AE",
          connection: true,
        },
        {
          name: "USA",
          value: 35,
          flags: "CH",
          connection: true,
        },
        {
          name: "India",
          value: 35,
          flags: "EG",
          connection: true,
        },
      ],

      columnsListType: "number",
      columnsListCurrency: null,
      columnsList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Open Network Requests
            </>
          ),
          number: null,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Network Favorites
            </>
          ),
          number: null,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Open Notifications
            </>
          ),
          number: null,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Profile Access Request
            </>
          ),
          number: null,
          value: 10,
        },
      ],

      requestCurrency: null,
      request: [
        {
          title: "Pending Network Requests",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Notifications",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Favorites",
          requestNo: 10,
          requestValue: null,
        },
      ],

      dataSet: {
        labels: [
          "Exporter and Importers",
          "Freight and Logistics",
          "Transporters",
          "Shipping and Containers",
          "Inpection",
          "Trade Compliance",
          "Warehouse",
          "Financers",
          "Insurers",
        ],
        datasets: [
          {
            label: "My First Dataset",
            data: [300, 500, 150, 200, 400, 500, 150, 200, 400],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(255, 159, 64)",
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
            ],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      name: "Service(C)",
      datatype: "number",
      datavalue: 350,
      dataCurrency: null,
      dataValueType: "Service Providers",
      dataValueYear: null,

      subName: null,
      detailsType: "number",
      details: {
        title: "Service Recommendations",
        value: 38,
        subTitle: null,
      },

      topListTitle: "Top Service Providers",
      topListType: "currency",
      topListCurrency: "USD",
      topList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              ABC Freighters
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Trust Consultant
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Mehta Associates
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
      ],

      columnsListType: "currency",
      columnsListCurrency: "USD",
      columnsList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              Financing
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              KYB
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              Credit Assessment
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              Warehousing
            </>
          ),
          number: 5,
          value: 10,
        },
      ],

      requestCurrency: null,
      request: [
        {
          title: "Open Service Requisition",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "CLosed Serive Deals",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Pending T&C Approval",
          requestNo: 10,
          requestValue: null,
        },
      ],
      dataSet: {
        labels: [
          "Onboarding",
          "Credit Assessments",
          "Exporter and Importers",
          "Freight and Logistics",
          "Transporters",
          "Shipping and Containers",
          "Inpection",
          "Trade Compliance",
          "Warehouse",
          "Financers",
          "Insurers",
        ],
        datasets: [
          {
            label: "My First Dataset",
            data: [300, 500, 150, 200, 400, 300, 500, 150, 200, 400, 155],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(153, 102, 255)",
            ],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      name: "Service(P)",
      datatype: "number",
      datavalue: 150,
      dataCurrency: null,
      dataValueType: "Service Subscribers",
      dataValueYear: null,

      subName: null,
      detailsType: "number",
      details: {
        title: "Service Recommendations",
        value: 38,
        subTitle: null,
      },

      topListTitle: "Top Service Subscribers",
      topListType: "currency",
      topListCurrency: "USD",
      topList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              ABC Freighters
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Trust Consultant
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdOutlineNotificationsNone size={24} />
              </span>
              Finhelp Bank
            </>
          ),
          value: 35,
          flags: false,
          connection: false,
        },
      ],

      columnsListType: "currency",
      columnsListCurrency: "USD",
      columnsList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              New
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              Interest Expressed
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              Quote Generated
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              T&C Pending
            </>
          ),
          number: 5,
          value: 10,
        },
      ],

      requestCurrency: null,
      request: [
        {
          title: "Open Service Requisition",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "CLosed Serive Deals",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Pending T&C Approval",
          requestNo: 10,
          requestValue: null,
        },
      ],
      dataSet: {
        labels: [
          "New",
          "In-Pursuit",
          "T&C",
          "Deals Closed",
          "Service Delivery",
          "Payment and Settlement",
        ],
        datasets: [
          {
            label: "My First Dataset",
            data: [300, 500, 150, 200, 400, 144],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(153, 102, 255)",
            ],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      name: "Trade",
      datatype: "currency",
      datavalue: 250,
      dataCurrency: "USD",
      dataValueType: null,
      dataValueYear: 2022,

      subName: null,
      detailsType: "currency",
      details: {
        title: "Total Trade",
        value: 30,
      },

      topListTitle: "Top Countries in Trade",
      topListType: "currency",
      topListCurrency: "USD",
      topList: [
        {
          name: "UAE",
          value: 35,
          flags: "UAE",
          connection: false,
        },
        {
          name: "USA",
          value: 35,
          flags: "US",
          connection: false,
        },
        {
          name: "INDIA",
          flags: "IN",
          connection: false,
        },
      ],

      columnsListType: "number",
      columnsListCurrency: null,
      columnsList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              Incomming Trade Communications
            </>
          ),
          number: null,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              Outgoing Trade Communications
            </>
          ),
          number: null,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              Trade Services Open
            </>
          ),
          number: null,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <FaFileInvoice size={24} />
              </span>
              Trade Notifications
            </>
          ),
          number: null,
          value: 10,
        },
      ],

      requestCurrency: "USD",
      request: [
        {
          title: "Complete Trades",
          requestNo: 10,
          requestValue: 12,
        },
        {
          title: "Open Trades",
          requestNo: 10,
          requestValue: 14,
        },
        {
          title: "Pending Action",
          requestNo: 10,
          requestValue: 5,
        },
      ],
      dataSet: {
        labels: [
          "Procurement",
          "Onboarding",
          "Contract",
          "Financing",
          "Manufacturing",
          "Inland Transport(Export)",
          "Shipping",
          "Inland Transport(Import)",
          "Settlement",
        ],
        datasets: [
          {
            label: "My First Dataset",
            data: [300, 500, 150, 200, 400, 500, 150, 200, 400],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
            ],
            hoverOffset: 4,
          },
        ],
      },
    },
    {
      name: "Payments",
      datatype: "currency",
      datavalue: 250,
      dataCurrency: "USD",
      dataValueType: null,
      dataValueYear: 2022,

      subName: null,
      detailsType: "currency",
      details: {
        title: "Top Currencies",
        value: 38,
        subTitle: null,
      },

      topListTitle: "Top Payment Services Providers",
      topListType: "number",
      topListCurrency: null,
      topList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              TaazaPay
            </>
          ),
          value: null,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              PaySharpe
            </>
          ),
          value: null,
          flags: false,
          connection: false,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              ICICI
            </>
          ),
          value: null,
          flags: false,
          connection: false,
        },
      ],

      columnsListType: "currency",
      columnsListCurrency: "USD",
      columnsList: [
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              Open Escrow Payments
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              Completed Payments
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              Blocked Payments
            </>
          ),
          number: 5,
          value: 10,
        },
        {
          name: (
            <>
              <span style={{ marginRight: "5px" }}>
                <MdPayment size={24} />
              </span>
              Upcoming Payments
            </>
          ),
          number: 5,
          value: 10,
        },
      ],

      requestCurrency: null,
      request: [
        {
          title: "Upcoming Disbursals",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Upcoming Re-Payments",
          requestNo: 10,
          requestValue: null,
        },
        {
          title: "Blocked Payments",
          requestNo: 10,
          requestValue: null,
        },
      ],
      dataSet: {
        labels: [
          "Upcoming",
          "Initiated",
          "Released",
          "Blocked",
          "Completed",
          "Aborted",
        ],
        datasets: [
          {
            label: "My First Dataset",
            data: [300, 500, 150, 200, 400, 177],
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(75, 192, 192)",
            ],
            hoverOffset: 4,
          },
        ],
      },
    },
  ];

  let Filtereddata;
  if (memberType === "FINANCER") {
    Filtereddata = NewData;
  } else if (memberType === "SERVICE_PROVIDER") {
    Filtereddata = NewData;
  } else {
    Filtereddata = NewData.filter((data, index) => {
      return index !== 3;
    });
  }

  const CountryFlag = (flag) => {
    return <Icons.US title="United States" className={styles.icon} />;
  };

  useEffect(() => {
    if (trustData) {
      if (trustData.onboardingStatus === "PASSED") {
        setWelcomeMessage(<>Welcome to Tradxlink Trade Platform</>);
        setVariant("success");
      } else {
        setWelcomeMessage(
          <>
            Please complete your Tradxlink Onboarding process{" "}
            <Alert.Link
              onClick={() => navigate("./profile/company-profile/onboarding")}>
              Click
            </Alert.Link>
          </>
        );
        setVariant("danger");
      }
    }
  }, [trustData]);

  const handleTermSubmit = () => {
    if (isChecked) {
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${profileURL}financers/${companyId}`;
      } else {
        URL = `${profileURL}companies/${companyId}`;
      }
      companyData.tncStatus = true;
      companyData.tncAcceptance = new Date();

      const options = {
        method: "PUT",
        body: companyData,
      };
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, options, response)
          .then((data) => {
            Success("Thanks for accepting T&C");
            setIsTermOpen(false);
          })
          .catch((error) => {
            Error();
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    } else {
      Error("Please Click on the Checkbox to continue");
    }
  };

  return (
    <>
      <Modal
        show={isTermOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        scrollable={true}>
        <Modal.Body>
          <div className={styles.terms}>
            <div>
              <h5>TERMS & CONDITIONS</h5>
            </div>
            <div className={styles.termInputDiv}>
              <span>
                By registering as a member of Tradxlink Network, I agree to
                Tradxlink's{" "}
                <a
                  href="https://tradxlink.com/terms-conditions/"
                  target="_blank">
                  Terms & Conditions
                </a>
                . I also confirm to have read{" "}
                <a href="https://tradxlink.com/privacy-policy/" target="_blank">
                  Tradxlink Privacy Policy Guidelines
                </a>{" "}
                and acknowledge to adhere the same for my organization.
              </span>
            </div>
            <div className={styles.termNote}>
              <input
                type="checkbox"
                checked={isChecked}
                onClick={() => setIsChecked(!isChecked)}
              />{" "}
              <span>
                * Please tick checkbox and Submit after you have read the above
                Terms & Conditions
              </span>
            </div>
          </div>
          <ButtonDiv>
            <Button
              style={{ background: "gray !important" }}
              onClick={() => {
                instance.logoutRedirect({ postLogoutRedirectUri: "/" });
              }}>
              Cancel
            </Button>
            <Button onClick={(e) => handleTermSubmit(e)}>Submit</Button>
          </ButtonDiv>
        </Modal.Body>
      </Modal>

      {isLoading ? (
        <Content>
          Loading...
          <Loader />
        </Content>
      ) : (
        <>
          {response && (
            <>
              {show && (
                <Alert
                  className={styles.AlertDiv}
                  key={variant}
                  variant={variant}
                  onClose={() => setShow(false)}
                  dismissible>
                  {welcomeMessage}
                </Alert>
              )}

              <div className={styles.dashboardContainer}>
                <div
                  className={styles.dashboardWrapper}
                  style={{ paddingTop: "0.5%" }}>
                  <h6
                    style={{ margin: "10px 10px 15px 30px", fontSize: "20px" }}>
                    Dashboard
                  </h6>
                  <Carousel
                    className={styles.dashboardCarousel}
                    indicators={true}
                    // activeIndex={0}
                  >
                    {Filtereddata.map((responsed, index) => (
                      <Carousel.Item key={index}>
                        <div className={styles.container}>
                          <div className={styles.containerLeft}>
                            <div className={styles.Head}>
                              <span>{responsed.name}</span>
                              <span className={styles.Participant}>
                                {responsed.datatype === "currency" ? (
                                  <>
                                    <span>{responsed.dataCurrency} </span>
                                    <span>{responsed.datavalue} </span>
                                    {responsed.dataValueYear && (
                                      <span>for {responsed.dataValueYear}</span>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <span>{responsed.datavalue} </span>
                                    <span>{responsed.dataValueType}</span>
                                  </>
                                )}
                              </span>
                            </div>

                            <div className={styles.ChartDiv}>
                              <div>
                                {index === 0 ? (
                                  <>
                                    <Bar
                                      data={responsed.dataSet}
                                      height={360}
                                      width={400}
                                      options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        // layout: {
                                        //   padding: 10,
                                        // },
                                        plugins: {
                                          title: {
                                            color: "#42a5f5",
                                            display: true,
                                            // padding: {
                                            //   top: 10,
                                            //   bottom: 30,
                                            // },
                                            text: `${responsed.name} Chart`,
                                            font: {
                                              size: 14,
                                            },
                                          },
                                          legend: {
                                            position: "right",
                                            display: true,
                                            labels: {
                                              color: "#42a5f5",
                                              font: {
                                                size: 14,
                                              },
                                            },
                                          },
                                        },
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {index === 1 ? (
                                  <>
                                    <Doughnut
                                      data={responsed.dataSet}
                                      height={350}
                                      width={400}
                                      options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        // layout: {
                                        //   padding: 10,
                                        // },
                                        plugins: {
                                          title: {
                                            color: "#42a5f5",
                                            display: true,
                                            // padding: {
                                            //   top: 10,
                                            //   bottom: 30,
                                            // },
                                            text: `${responsed.name} Chart`,
                                            font: {
                                              size: 14,
                                            },
                                          },
                                          legend: {
                                            position: "right",
                                            display: true,
                                            labels: {
                                              color: "#42a5f5",
                                              font: {
                                                size: 14,
                                              },
                                            },
                                          },
                                        },
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {index === 2 ? (
                                  <>
                                    <Doughnut
                                      data={responsed.dataSet}
                                      height={350}
                                      width={400}
                                      options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        // layout: {
                                        //   padding: 10,
                                        // },
                                        plugins: {
                                          title: {
                                            color: "#42a5f5",
                                            display: true,
                                            // padding: {
                                            //   top: 10,
                                            //   bottom: 30,
                                            // },
                                            text: `${responsed.name} Chart`,
                                            font: {
                                              size: 14,
                                            },
                                          },
                                          legend: {
                                            position: "right",
                                            display: true,
                                            labels: {
                                              color: "#42a5f5",
                                              font: {
                                                size: 14,
                                              },
                                            },
                                          },
                                        },
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {index === 3 ? (
                                  // && memberType === "TRADER"
                                  <>
                                    <Doughnut
                                      data={responsed.dataSet}
                                      height={350}
                                      width={400}
                                      options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        // layout: {
                                        //   padding: 10,
                                        // },
                                        plugins: {
                                          title: {
                                            color: "#42a5f5",
                                            display: true,
                                            // padding: {
                                            //   top: 10,
                                            //   bottom: 30,
                                            // },
                                            text: `${responsed.name} Chart`,
                                            font: {
                                              size: 14,
                                            },
                                          },
                                          legend: {
                                            position: "right",
                                            display: true,
                                            labels: {
                                              color: "#42a5f5",
                                              font: {
                                                size: 14,
                                              },
                                            },
                                          },
                                        },
                                      }}
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}
                                {index === 4 ? (
                                  <>
                                    <Doughnut
                                      data={responsed.dataSet}
                                      height={350}
                                      width={400}
                                      options={{
                                        responsive: true,
                                        maintainAspectRatio: true,
                                        // layout: {
                                        //   padding: 10,
                                        // },
                                        plugins: {
                                          title: {
                                            color: "#42a5f5",
                                            display: true,
                                            // padding: {
                                            //   top: 10,
                                            //   bottom: 30,
                                            // },
                                            text: `${responsed.name} Chart`,
                                            font: {
                                              size: 14,
                                            },
                                          },
                                          legend: {
                                            position: "right",
                                            display: true,
                                            labels: {
                                              color: "#42a5f5",
                                              font: {
                                                size: 14,
                                              },
                                            },
                                          },
                                        },
                                      }}
                                    />
                                  </>
                                ) : (
                                  <Bar
                                    data={responsed.dataSet}
                                    height={350}
                                    width={400}
                                    options={{
                                      responsive: true,
                                      maintainAspectRatio: true,
                                      // layout: {
                                      //   padding: 10,
                                      // },
                                      plugins: {
                                        title: {
                                          color: "#42a5f5",
                                          display: true,
                                          // padding: {
                                          //   top: 10,
                                          //   bottom: 30,
                                          // },
                                          text: `${responsed.name} Chart`,
                                          font: {
                                            size: 14,
                                          },
                                        },
                                        legend: {
                                          position: "right",
                                          display: true,
                                          labels: {
                                            color: "#42a5f5",
                                            font: {
                                              size: 14,
                                            },
                                          },
                                        },
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            </div>

                            {/* <div className={styles.Request}>
                              {responsed.request.map((rate, i) => (
                                <div className={styles.ratingDiv} key={i}>
                                  <div>{rate.requestNo}</div>
                                  <div>{rate.title}</div>
                                  {rate.requestValue && (
                                    <div className={styles.colorBlue}>
                                      <span>
                                        {responsed.requestCurrency}
                                        {rate.requestValue} M
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div> */}
                          </div>
                          <div className={styles.containerRight}>
                            <div className={styles.ColumnDiv}>
                              {responsed.columnsList &&
                                responsed.columnsList.map((detail, i) => (
                                  <div key={i} className={styles.Column}>
                                    <div>{detail.name}</div>
                                    {detail.number && (
                                      <div>{detail.number} </div>
                                    )}

                                    {responsed.columnsListType === "number" ? (
                                      <div>{detail.value}</div>
                                    ) : (
                                      <div>
                                        {responsed.columnsListCurrency}
                                        {detail.value}
                                        {" M"}
                                      </div>
                                    )}
                                  </div>
                                ))}
                            </div>

                            <div className={styles.TopListDiv}>
                              <div>
                                <div>{responsed.topListTitle}</div>
                                <div className={styles.listDiv}>
                                  {responsed.topList &&
                                    responsed.topList.map((list, i) => (
                                      <div className={styles.list} key={i}>
                                        {list.flags ? (
                                          <div>{CountryFlag(list.flags)}</div>
                                        ) : (
                                          <div>
                                            <div>{list.name}</div>
                                          </div>
                                        )}

                                        <div className={styles.colorBlue}>
                                          {responsed.topListType ===
                                          "currency" ? (
                                            <>
                                              <span>
                                                {responsed.topListCurrency}
                                                {list.value} M
                                              </span>
                                            </>
                                          ) : (
                                            <>
                                              <span>{list.value}</span>
                                              <span className={styles.subtype}>
                                                {list.connection &&
                                                  " Connection"}
                                              </span>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                              <div className={styles.topDetails}>
                                <div className={styles.topDetailsTitle}>
                                  {responsed.details && responsed.details.title}
                                </div>
                                <div className={styles.topDetailsValue}>
                                  {responsed.detailsType === "currency" ? (
                                    <>
                                      <span>{responsed.dataCurrency}</span>
                                      <span>
                                        {responsed.details &&
                                          responsed.details.value}
                                      </span>
                                      <span className={styles.subTitle}>
                                        {responsed.details &&
                                          responsed.details.subTitle &&
                                          responsed.details.subTitle}
                                      </span>
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        {responsed.details &&
                                          responsed.details.value}
                                      </span>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className={styles.Request}>
                              {responsed.request.map((rate, i) => (
                                <div className={styles.ratingDiv} key={i}>
                                  <div>{rate.requestNo}</div>
                                  <div>{rate.title}</div>
                                  {rate.requestValue && (
                                    <div className={styles.colorBlue}>
                                      <span>
                                        {responsed.requestCurrency}{" "}
                                        {rate.requestValue} M
                                      </span>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  <span style={{ marginLeft: "70%", color: "yellow" }}>
                    * Sample Data for representation purpose
                  </span>
                </div>

                <div
                  className={styles.dashboardWrapper}
                  style={{ padding: "1% 2% 1% 2%" }}>
                  <div
                    className={styles.dashboardTitle}
                    style={{ margin: "0px 0px 0px 14px", fontSize: "20px" }}>
                    Solutions
                  </div>
                  <div className={styles.dashboardSolution}>
                    {subtype.map((item, index) => (
                      <div className={styles.solutionCard} key={index}>
                        <div
                          className={styles.content}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOnClick(item.path)}>
                          <div className={styles.content_icons}>
                            {item.icon}
                          </div>
                          <span>{item.name}</span>
                        </div>
                      </div>
                    ))}
                    {SOLUTIONS_TYPE.map((item, index) => (
                      <div className={styles.solutionCard} key={index}>
                        <div
                          className={styles.content}
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOnClick(item.path)}>
                          <div className={styles.content_icons}>
                            {item.icon}
                          </div>
                          <span>{item.name}</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </>
          )}

          {isError && <span>{isError}</span>}
        </>
      )}

      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </>
  );
};
