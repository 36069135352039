import React, { useState, useEffect, useLayoutEffect } from "react";
import Company from "./company";
import Consent from "./consent/index";
import Loader from "components/Loader/Loader";
import styles from "./documentNew.module.css";
import { useSearchParams } from "react-router-dom";
import { NetworkURL } from "services/BaseURLInstance";
import Wrapper from "components/UI/Layout/Wrapper";
import Content from "components/UI/Layout/Content";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { Error } from "components/notifications/responseHandle";
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";

const DocumentGroup = ({
  // flowStage,
  // initialState,
  dealData, // Using for finance deal documents
  // stageData,
  // secondFlowSequence,
  trade, // Using in Trade Section
  subStageDate, // Using in Trade Section
  serviceDealDetails,
  invoiceId,
  serviceId, // Using in Service Request Document
  componentLocations, // using in Profile Stepper
  serviceDealLocations, // using in Service Deal Document
  OpenTradeAccountDetails, // using in Open Trade Account
}) => {
  const { serviceDealLocation, docCategoryList } = serviceDealLocations || {}; // using in Service Deal Document
  const [searchParams] = useSearchParams(); // Using in Newtwork Section Company Details Document
  let currentNetwork = searchParams.get("currentNetwork"); // Using in Newtwork Section Company Details Document

  const [data, setData] = useState(null);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [selected, setSelected] = useState(0);
  const [component, setComponent] = useState();
  const [memberDetails, setMemberDetails] = useState(null);
  const [tab, setTab] = useState("");
  const [CompanyList, setCompanyList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const { instance, accounts } = useMsal();
  const [documentTemplate, setDocumentTemplate] = useState("");

  const navigate = useNavigate();
  const handleNavigate = (link) => {
    navigate(`/${link}`);
  };

  useEffect(() => {
    let docTemplete = "";
    let customDocCategories = null;

    let instanceID = null;
    let customTags = null;

    if (
      serviceDealDetails ||
      serviceDealLocation === "serviceRequestRequired"
    ) {
      if (serviceDealLocation === "companyDoc") {
        instanceID = companyId;
        docTemplete = "Global";
      } else if (
        serviceDealLocation === "contractsDoc" ||
        serviceDealLocation === "paymentsDoc" ||
        serviceDealLocation === "deliveryDoc"
      ) {
        instanceID = serviceDealDetails.serviceDealId;
        docTemplete = "Trade";
      } else if (serviceDealLocation === "serviceRequestRequired") {
        instanceID = serviceId;
        docTemplete = "Trade";
      } else {
        instanceID = serviceDealDetails.referenceServiceRequisitionId;
        docTemplete = "Trade";
      }
    }

    if (OpenTradeAccountDetails) {
      if (serviceDealLocation === "companyDocOAT") {
        instanceID = companyId;
        docTemplete = "Global";
        customTags = [];
      } else if (serviceDealLocation === "paymentDocOAT") {
        instanceID = OpenTradeAccountDetails.openTradeAccountId;
        docTemplete = "Trade";
        customTags = [`OAT-${OpenTradeAccountDetails.openTradeAccountId}`];
      } else if (serviceDealLocation === "deliveryDocOAT") {
        instanceID = OpenTradeAccountDetails.openTradeAccountId;
        docTemplete = "Trade";
        customTags = [`OAT-${OpenTradeAccountDetails.openTradeAccountId}`];
      }
    }

    if (trade) {
      // For Trade Section
      instanceID = subStageDate.tradeId;
    }

    if (dealData) {
      // Using for finance deal documents
      instanceID = companyId;
      docTemplete = "Global";
    }

    if (
      componentLocations &&
      componentLocations.componentLocation &&
      componentLocations.componentLocation === "onboardingStepper"
    ) {
      docTemplete = "Global";
      customDocCategories = "COMPANY";
    }

    let temp1 = [];
    if (subStageDate && subStageDate?.documentGroups) {
      temp1 = subStageDate?.documentGroups.map((item) => {
        return item.docCategory;
      });
      let arr = temp1;
      temp1 = arr.filter((item, index) => arr.indexOf(item) === index);
    }

    if (currentNetwork) {
      // Using in Newtwork Section Company Details Document
      instanceID = currentNetwork;
    }

    setDocumentTemplate(docTemplete); // Using in Request Document
    const docCategory =
      temp1 && temp1.length > 0
        ? temp1
        : docCategoryList
        ? docCategoryList
        : customDocCategories
        ? customDocCategories
        : "";
    let URL = `${
      process.env.REACT_APP_BFF_URL
    }bff/documentsGroups/summary?memberUID=${
      currentNetwork ? currentNetwork : companyId
    }&instanceID=${instanceID ? instanceID : companyId}&template=${
      trade
        ? trade // TODO  document template to be associated with service deal ID
        : docTemplete
        ? docTemplete
        : "Global"
    }&docCategories=${docCategory}&docFilterTags=${
      dealData && componentLocations?.location !== "FDCompanyDocument"
        ? [
            `FINANCE_DEAL-${dealData.financeDealId}`,
            `FINANCE_REQUISITION-${dealData.refFinanceRequisitionId}`,
            `INVOICE-${dealData.referenceFinanceInstrumentId}`,
          ]
        : serviceDealDetails && serviceDealLocation === "requirementDoc"
        ? [
            // `SERVICE_DEAL-${serviceDealDetails.serviceDealId}`,
            `SERVICE_REQUISITION-${serviceDealDetails.referenceServiceRequisitionId}`,
          ]
        : serviceDealDetails &&
          (serviceDealLocation === "contractsDoc" ||
            serviceDealLocation === "paymentsDoc" ||
            serviceDealLocation === "deliveryDoc")
        ? [
            `SERVICE_DEAL-${serviceDealDetails.serviceDealId}`,
            `SERVICE_REQUISITION-${serviceDealDetails.referenceServiceRequisitionId}`,
          ]
        : subStageDate
        ? [
            `TRADE-${subStageDate.tradeId}`,
            `TRADE_STAGE-${subStageDate.stageUID}`,
            `TRADE_SUB_STAGE-${subStageDate.subStageUID}`,
          ]
        : invoiceId
        ? [`INVOICE-${invoiceId}`]
        : serviceId
        ? [`SERVICE_REQUISITION-${serviceId}`]
        : customTags
        ? customTags
        : ""
    }`;
    let DocCategoryTab = [];
    let isCancelled = false;

    if (!isCancelled) {
      if (trade === "Trade") {
        if (!isEmpty(docCategory)) {
          setIsLoading(true);
          getIdTokenAsync(instance, accounts).then((response) => {
            UseRequest(URL, null, response)
              .then((data) => {
                let { message } = data;
                if (message) {
                  setIsError(message);
                  Error(message);
                } else {
                  if (
                    componentLocations &&
                    componentLocations.componentLocation &&
                    componentLocations.componentLocation === "onboardingStepper"
                  ) {
                    DocCategoryTab.push({ name: "COMPANY", value: "COMPANY" });
                  } else if (
                    serviceDealLocation === "companyDoc" ||
                    serviceDealLocation === "contractsDoc" ||
                    serviceDealLocation === "paymentsDoc" ||
                    serviceDealLocation === "requirementDoc" ||
                    serviceDealLocation === "deliveryDoc" ||
                    serviceDealLocation === "serviceRequestRequired" ||
                    serviceDealLocation === "companyDocOAT" ||
                    serviceDealLocation === "paymentDocOAT"
                  ) {
                    const uniqueCategory = [
                      ...new Set(data.map((item) => item.docCategory)),
                    ];
                    DocCategoryTab = uniqueCategory.map((item) => {
                      return { name: item, value: item };
                    });
                  } else {
                    const uniqueCategory = [
                      ...new Set(data.map((item) => item.docCategory)),
                    ];
                    DocCategoryTab = uniqueCategory.map((item) => {
                      return { name: item, value: item };
                    });
                  }
                  setTab(DocCategoryTab);
                  setData(data);
                }
              })
              .catch(() => {
                setIsLoading(false);
              });
          });
        }
      } else {
        setIsLoading(true);
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(URL, null, response)
            .then((data) => {
              let { message } = data;
              if (message) {
                setIsError(message);
                Error(message);
              } else {
                if (
                  componentLocations &&
                  componentLocations.componentLocation &&
                  componentLocations.componentLocation === "onboardingStepper"
                ) {
                  DocCategoryTab.push({ name: "COMPANY", value: "COMPANY" });
                } else if (
                  serviceDealLocation === "companyDoc" ||
                  serviceDealLocation === "contractsDoc" ||
                  serviceDealLocation === "paymentsDoc" ||
                  serviceDealLocation === "requirementDoc" ||
                  serviceDealLocation === "deliveryDoc" ||
                  serviceDealLocation === "serviceRequestRequired" ||
                  serviceDealLocation === "companyDocOAT" ||
                  serviceDealLocation === "paymentDocOAT"
                ) {
                  const uniqueCategory = [
                    ...new Set(data.map((item) => item.docCategory)),
                  ];
                  DocCategoryTab = uniqueCategory.map((item) => {
                    return { name: item, value: item };
                  });
                } else {
                  const uniqueCategory = [
                    ...new Set(data.map((item) => item.docCategory)),
                  ];
                  DocCategoryTab = uniqueCategory.map((item) => {
                    return { name: item, value: item };
                  });
                }
                setTab(DocCategoryTab);
                setData(data);
              }
            })
            .catch(() => {
              setIsLoading(false);
            });
        });
      }
    }

    if (!isCancelled) {
      let network = currentNetwork || companyId;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${NetworkURL}members?memberId=${network}`, null, response)
          .then((data) => {
            let { message } = data;
            if (message) {
              setIsError(message);
              Error(message);
            } else {
              setCompanyList(data);
              let user = data?.find((item) => {
                if (item?.memberUID === network) {
                  return item?.memberName;
                }
              });
              setMemberDetails(user);
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [subStageDate]);

  useEffect(() => {
    if (tab.length > 0 && data.length > 0) {
      let categoryProps = {
        category: tab[0].name, // using
        list: data[0], // using
        memberDetails: memberDetails, // using for request documents
        ActiveTab: tab[0].name, // using for tab name
        CompanyList: CompanyList, // using for memeber list
        folderLocation: serviceDealLocation, // using for folder location of every document
        OpenTradeAccountDetails: OpenTradeAccountDetails, // using for open trade account Details
        idToken: JSON.parse(sessionStorage.getItem("idToken")), // not using
        documentTemplate: documentTemplate, // Using in Request Document
        companyId: companyId, // not using
        dealData: dealData ? dealData : "",
        componentLocations: componentLocations,
        trade: trade,
        serviceDealDetails: serviceDealDetails ? serviceDealDetails : "",
        subStageDate: subStageDate ? subStageDate : "",
        invoiceId: invoiceId ? invoiceId : "",
        serviceId: serviceId ? serviceId : "",
        currentNetwork: currentNetwork, // Using in Newtwork Section Company Details Document
        referenceData: subStageDate
          ? [
              {
                key: "TRADE",
                value: subStageDate.tradeId,
              },
              {
                key: "TRADE_STAGE",
                value: subStageDate.stageUID,
              },
              {
                key: "TRADE_SUB_STAGE",
                value: subStageDate.subStageUID,
              },
            ]
          : "",
      };
      setComponent(<Company {...categoryProps} />);
    }
  }, [tab, subStageDate, data, CompanyList]);

  const handleComponent = (e, key, name) => {
    e.preventDefault();
    let ListData = data
      ? data.filter((result) => result.docCategory === name)
      : [];

    if (data) {
      if (name === "CONSENT") {
        setComponent(<Consent CompanyList={CompanyList} />);
        setSelected(key);
      } else {
        let categoryProps = {
          category: name,
          list: ListData[0],
          ActiveTab: name,
          memberDetails: memberDetails, // using for request documents
          CompanyList: CompanyList,
          idToken: JSON.parse(sessionStorage.getItem("idToken")),
          companyId: companyId,
          folderLocation: serviceDealLocation, // using fro folder location of every document
          OpenTradeAccountDetails: OpenTradeAccountDetails, // using for open trade account Details
          trade: trade,
          dealData: dealData ? dealData : "",
          serviceDealDetails: serviceDealDetails ? serviceDealDetails : "", // using for service contract details
          subStageDate: subStageDate ? subStageDate : "",
          documentTemplate: documentTemplate, // Using in Request Document
          invoiceId: invoiceId ? invoiceId : "",
          serviceId: serviceId ? serviceId : "", // using for service Request details ID
          currentNetwork: currentNetwork, // Using in Newtwork Section Company Details Document
          referenceData: subStageDate
            ? [
                {
                  key: "TRADE",
                  value: subStageDate.tradeId,
                },
                {
                  key: "TRADE_STAGE",
                  value: subStageDate.stageUID,
                },
                {
                  key: "TRADE_SUB_STAGE",
                  value: subStageDate.subStageUID,
                },
              ]
            : "",
        };
        setComponent(<Company {...categoryProps} />);
        setSelected(key);
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <Content>
          <span>Loading.....</span>
          <Loader />
        </Content>
      ) : (
        <>
          {tab && CompanyList && (
            <>
              {!componentLocations &&
                !OpenTradeAccountDetails &&
                !serviceId &&
                !serviceDealLocations &&
                !currentNetwork && (
                  <div
                    style={{
                      color: "rgb(66, 165, 245)",
                      cursor: "pointer",
                      marginBottom: "4px",
                    }}
                    onClick={() => handleNavigate("Documents")}>
                    <span style={{ marginRight: "2px" }}>
                      <BsArrowLeft />
                    </span>
                    <span>Back To Documents</span>
                  </div>
                )}
              <div style={{ marginBottom: "4px" }}>
                <span>
                  Documents of Network Member:
                  {memberDetails && memberDetails.memberName}
                </span>
              </div>
              <div className={styles.DocumentNavbar}>
                {tab &&
                  tab.map((item, index) => (
                    <div
                      key={index}
                      onClick={(e) => handleComponent(e, index, item.name)}
                      className={
                        selected === index || (selected === 0 && index === 0)
                          ? styles.active
                          : styles.notActive
                      }>
                      {item.name}
                    </div>
                  ))}
              </div>
              <Wrapper>{data.length > 0 && <>{component}</>}</Wrapper>
            </>
          )}

          {isError && <Content>Something went wrong</Content>}
        </>
      )}
    </>
  );
};
export default DocumentGroup;
