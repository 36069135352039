import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const Success = (_msg) => {
  toast.success(_msg, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "black",
    },
  });
};

export const Error = (_msg) => {
  toast.error(_msg || "Something Went Wrong", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "black",
    },
  });
};

export const Alert = (_msg) => {
  toast.warn(_msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "black",
    },
  });
};

export const Info = (_msg) => {
  toast.info(_msg, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "black",
    },
  });
};

export const ToastLoading = (_msg) => {
  const loadingId = toast.loading(_msg || "Please wait...", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "black",
    },
  });

  return loadingId;
};

export const ToastSuccess = (_msg, id) => {
  toast.update(id, {
    render: _msg || "Successfully updated",
    type: "success",
    isLoading: false,
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    style: {
      backgroundColor: "black",
    },
  });
};
