import React, { useState, useEffect } from "react";
import Loader from "components/Loader/Loader";
import CardNetwork from "./NetworkDetail";
import { useMsal } from "@azure/msal-react";
import styles from "./allNetwork.module.css";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { Participants, NetworkParticipants } from "utils/Section/FormInfo";
import { NetworkURL } from "services/BaseURLInstance";
import Content from "components/UI/Layout/Content";
import { UseRequest } from "utils/API/Request.js";
import { getIdTokenAsync } from "utils/authUtil";
import { Error } from "components/notifications/responseHandle";
import { Countries } from "utils/DropDown/Country";
import SelectWithSearch from "components/fields/SelectWithSearch";

export default (props) => {
  const { hanndleCraateGroup } = props;
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [hadTriggered, sethadTriggered] = useState(false);

  const [response, setResponse] = useState("");
  const [favoriteData, setFavoriteData] = useState("");
  const [networkData, setNetworkData] = useState("");

  const [FilterData, setFilterData] = useState("");
  const [searchData, setSearchData] = useState("");

  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true); // Company
  const [isOpen3, setIsOpen3] = useState(true); // FINANCER
  const [isOpen4, setIsOpen4] = useState(true);
  const [isOpen5, setIsOpen5] = useState(true);
  const [isOpen6, setIsOpen6] = useState(true);
  const [newData, setNewData] = useState("");

  const [country, setCountry] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [documentData, setDocumentData] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${NetworkURL}members/details`, null, response).then(
          (data) => {
            setIsLoading(false);
            let { message, path } = data;
            if (message || path) {
              Error(message);
              setIsError(message);
            } else {
              setResponse(data);
              let sorted = data.sort((a, b) =>
                a.companyName.localeCompare(b.companyName)
              );
              setNewData(sorted);
              // let id = data.companyLogoURL || data.financerLogo;
              // if (data.companyLogoURL || data.financerLogo) {
              //   getIdTokenAsync(instance, accounts).then((response) => {
              //     UseRequest(
              //       `${documentURL}documents/${id}`,
              //       null,
              //       response
              //     ).then((res) => {
              //       let { message, path } = res;
              //       if (message || path) {
              //         Error(message);
              //       } else {
              //         setDocumentData(res);
              //       }
              //     });
              //   });
              // }
            }
          }
        );
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [hadTriggered]);

  //Gives company favorite and network details
  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${NetworkURL}network/${companyId}/?memberId=${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message);
          } else {
            setFavoriteData(data.favoriteMembers);
            setNetworkData(data.connectedMembers);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
    // }, [isOpen4, isOpen5]);
  }, []);

  const handleChange = (e) => {
    setSearchData(e.target.value);
  };

  const handleCountry = (e) => {
    if (e.target.name === "searchCountry") setCountry(e.target.value);
  };

  const filterFavorite = (memberUID) => {
    if (favoriteData) {
      return favoriteData.some((data) => data === memberUID);
    }
    return false;
  };

  const filterNetwork = (memberUID) => {
    if (networkData) {
      return networkData.some((data) => data === memberUID);
    }
    return false;
  };

  const filterByName = (searchData, name) => {
    return name.toLowerCase().includes(searchData.toLowerCase());
  };

  useEffect(() => {
    if (response) {
      setFilterData(response);
    }
  }, [hadTriggered, response]);

  useEffect(() => {
    let data = [];
    const checkData = (item) => {
      const condition4 =
        country === "" ? true : item.companyCountry === country;

      return (
        (isOpen2 === false
          ? item.memberType !== "COMPANY"
          : item.memberType !== "") &&
        (isOpen3 === false
          ? item.memberType !== "FINANCER"
          : item.memberType !== "") &&
        (isOpen6 === false
          ? item.memberType !== "SERVICE_PROVIDER"
          : item.memberType !== "") &&
        (isOpen4 === false
          ? filterNetwork(item.memberUID)
          : item.memberType !== "") &&
        (isOpen5 === false
          ? filterFavorite(item.memberUID)
          : item.memberType !== "") &&
        (searchData !== ""
          ? filterByName(searchData, item.companyName)
          : item) &&
        condition4
      );
    };
    data = newData && newData.filter(checkData);
    setFilterData(data);
  }, [isOpen2, isOpen3, isOpen4, isOpen5, isOpen6, searchData, country]);

  useEffect(() => {
    setIsOpen2(true);
    setIsOpen3(true);
    setIsOpen4(true);
    setIsOpen5(true);
    setIsOpen6(true);
    setSearchData("");
  }, [isOpen1]);

  return (
    <>
      {isLoading ? (
        <Content marginBottom="0%">
          Loading...
          <Loader />
        </Content>
      ) : (
        <>
          {FilterData !== "" && (
            <>
              <Content marginBottom="2%" padding="1% 2% 2% 2%">
                <h5>Network</h5>
                <h6>
                  <OverLayTrigger value={Participants} />{" "}
                </h6>
                <div className={styles.searchDiv}>
                  <div>
                    <div className={styles.filterDiv}>
                      <div
                        onClick={() => setIsOpen1(!isOpen1)}
                        className={
                          !isOpen2 ||
                          !isOpen3 ||
                          !isOpen4 ||
                          !isOpen5 ||
                          !isOpen6
                            ? styles.notSelectOption
                            : styles.selectOption
                        }>
                        All
                      </div>
                      <div
                        onClick={() => setIsOpen2(!isOpen2)}
                        className={
                          isOpen2 ? styles.selectOption : styles.notSelectOption
                        }>
                        Company
                      </div>
                      <div
                        onClick={() => setIsOpen3(!isOpen3)}
                        className={
                          isOpen3 ? styles.selectOption : styles.notSelectOption
                        }>
                        Financer
                      </div>
                    </div>

                    <div className={styles.filterDiv}>
                      <div
                        onClick={() => setIsOpen4(!isOpen4)}
                        className={
                          isOpen4 ? styles.selectOption : styles.notSelectOption
                        }>
                        Trusted
                      </div>
                      <div
                        onClick={() => setIsOpen5(!isOpen5)}
                        className={
                          isOpen5 ? styles.selectOption : styles.notSelectOption
                        }>
                        My Network
                      </div>
                      <div
                        onClick={() => setIsOpen6(!isOpen6)}
                        className={
                          isOpen6 ? styles.selectOption : styles.notSelectOption
                        }>
                        Service Provider
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className={styles.inputSearchDiv}>
                      <input
                        style={{ marginBottom: "2%" }}
                        type="text"
                        name="search"
                        value={searchData}
                        placeholder="Search by Name...."
                        onChange={(e) => handleChange(e)}
                      />
                      <div>
                        <SelectWithSearch
                          style={{
                            marginTop: "1%",
                            padding: "12px 16px",
                          }}
                          options={Countries}
                          name="searchCountry"
                          placeholder="Search by Country...."
                          handleChange={handleCountry}
                          small="small"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Content>

              <div
                className={styles.scrollDiv}
                style={{
                  height: "1000px",
                  overflow: "scroll",
                }}>
                <h6>
                  <OverLayTrigger value={NetworkParticipants} />
                </h6>
                <CardNetwork
                  response={FilterData}
                  setResponse={setFilterData}
                  hanndleCraateGroup={hanndleCraateGroup}
                  documentData={documentData}
                  hadTriggered={hadTriggered}
                  sethadTriggered={sethadTriggered}
                />
              </div>
            </>
          )}

          {isError && <Content>{isError}</Content>}
        </>
      )}
    </>
  );
};
