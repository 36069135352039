const ServicesList = [
  {
    type: "PROCUREMENT",
    subtype: ["PURCHASE_T&C", "ORDER_MANAGEMENT"],
  },
  {
    type: "TRADE_CONTRACT",
    subtype: ["PRICING_REVIEW", "SIGN_TRADE_CONTRACT"],
  },
  {
    type: "MANUFACTURING",
    subtype: [
      "SAMPLING_GOODS",
      "PROTOTYPE",
      "PRODUCTION",
      "INVOICING",
      "GOODS_PACKAGING",
      "GOODS_DISPATCH",
    ],
  },

  {
    type: "TRANSPORT_EXPORT",
    subtype: [
      "DELIVERY_PLANNING",
      "PACKING",
      "TRANSPORT",
      "GOODS_INSURANCE",
      "CUSTOMS_EXPORT",
      "EXPORT_TERMINAL",
    ],
  },
  {
    type: "SHIPPING",
    subtype: [
      "SAMPLING_GOODS",
      "SHIPPING_ORDER",
      "SCHEDULING_AGREEMENT",
      "LOADING",
      "SUMMARY_DECLARATION",
      "SHIPPING_DELIVERY",
    ],
  },
  {
    type: "TRANSPORT_IMPORT",
    subtype: [
      "TERMINAL",
      "UNLOADING",
      "SAMPLING_GOODS",
      "INSPECTION",
      "CUSTOMS_IMPORT",
      "TRANSPORT",
      "STORAGE",
    ],
  },
  {
    type: "SETTLEMENT",
    subtype: [
      "QUALITY_ASSESMENT",
      "INVOICING_FINAL",
      "PAYMENTS",
      "ACCOUNT_SETTLEMENT",
    ],
  },
];

export const STAGE = (name) => {
  let StageList = ServicesList.map((item) => ({
    label: item.type,
    value: item.type,
  }));

  const Empty = [];
  let temp = ServicesList.filter((item) => {
    if (item.type === name) {
      return item;
    }
  });
  let SubStageList =
    temp &&
    temp[0] &&
    temp[0].subtype.map((item) => ({
      label: item,
      value: item,
    }));

  if (name !== null) {
    return { StageList, SubStageList };
  } else {
    return { StageList, Empty };
  }
};
