import React, { useState } from "react";
import styles from "pages/Documents/dashboard.module.css";
import { DocumentsFinanceDealDashboard } from "utils/Config";
import Container from "components/UI/Layout/Container";
import { HiArrowNarrowLeft } from "react-icons/hi";
import DocumentNavbar from "pages/Document/index";
import TradeDocuments from "pages/Document/TradeDocuments";
import DocumentUpload from "./DocumentUpload";
import Button from "components/UI/Button/Button";

const Index = ({ response }) => {
  const [hide, setHide] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const componentLocations = {
    componentLocation: "financeDealDocument",
    financeDealDetails: response,
    location: "FDCompanyDocument",
  };

  return (
    <div>
      <Container>
        <>
          {!response ? (
            <span>Loading</span>
          ) : (
            <>
              {!hide ? (
                <div className={styles.dashboardWrapper}>
                  <h5>Documents</h5>
                  <div className={styles.dashboardSolution}>
                    {DocumentsFinanceDealDashboard.map((item, index) => (
                      <div className={styles.solutionCard} key={index}>
                        <div
                          onClick={() => setHide(`${item.path}`)}
                          className={styles.content}
                          style={{ cursor: "pointer" }}>
                          <div className={styles.content_icons}>
                            {item.icon}
                          </div>
                          <h6>{item.name}</h6>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{ color: "#218eeb", cursor: "pointer" }}
                    onClick={() => {
                      setHide();
                      setIsUploading(false);
                    }}>
                    <span>
                      <HiArrowNarrowLeft size={28} />
                    </span>
                    <span>Back to List </span>
                  </div>
                  {hide === "Trade" && (
                    <div>
                      {isUploading ? (
                        <div>
                          <DocumentUpload
                            setIsUploading={setIsUploading}
                            response={response}
                            location={"contractsDoc"}
                          />
                        </div>
                      ) : (
                        <>
                          <div className="d-flex justify-content-end">
                            <Button onClick={() => setIsUploading(true)}>
                              Upload
                            </Button>
                          </div>
                          <TradeDocuments
                            componentLocations={componentLocations}
                          />
                        </>
                      )}
                    </div>
                  )}
                  {hide === "Company" && (
                    <div>
                      <DocumentNavbar
                        dealData={response}
                        componentLocations={componentLocations}
                      />
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      </Container>
    </div>
  );
};

export default Index;
