import React, { useState } from "react";
import classes from "./freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CountryCodes from "jsons/CountryCodes.json";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
// import { Tooltip } from 'react-tippy';
import { IoIosHelpCircle } from "react-icons/io";
import MultiSelect from "components/fields/MultiSelect";
import { Grid } from "@mui/material";
import { useEffect } from "react";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { customsForm } from "utils/Section/FormInfo";
const InsuranceCredit = ({ insuranceCreditData, setInsuranceCreditData }) => {
  let insuranceTypeOptions = [
    {
      label: "EXPORT",
      value: "EXPORT",
    },
    {
      label: "IMPORT",
      value: "IMPORT",
    },
    {
      label: "INLAND",
      value: "INLAND",
    },
  ];

  let transitModeOptions = [
    {
      label: "AIR",
      value: "AIR",
    },
    {
      label: "RAIL",
      value: "RAIL",
    },
    {
      label: "ROAD",
      value: "ROAD",
    },
    {
      label: "SEA",
      value: "SEA",
    },
  ];

  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [productList, setProductlist] = useState([]);
  const [countryList, setcountryList] = useState([]);

  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setInsuranceCreditData({
      ...insuranceCreditData,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );
  const handleMultiChange = (e, name) => {
    if (name === "product") {
      // const { value } = e.target;
      // setSelectList(value)
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (name === "countryMS") {
      const { value } = e.target;
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChange = (e, date) => {
    if (date === "CARGO_READY" || date === "PREFERRED_SAILING_DATE") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "CARGO_READY") {
        setDatePicker1(newDate1);
        // setInsuranceCreditData({
        //   ...insuranceCreditData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "PREFERRED_SAILING_DATE") {
        setDatePicker2(newDate1);
        // setInsuranceCreditData({
        //   ...insuranceCreditData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setInsuranceCreditData({
        ...insuranceCreditData,
        [date]: dateString,
      });
    } else if (e.target.name === "interCompany") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "insuranceType1") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "insuranceType2") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "insuranceType3") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "PREFERRED_SAILING_DATE") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "total") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currency") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "turnoverAmount") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "salesValue") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "tradeValue") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "credit") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
      // } else if (e.target.name === "commodity") {
      //   setInsuranceCreditData({
      //     ...insuranceCreditData,
      //     [e.target.name]: e.target.value,
      //   });
    } else if (e.target.name === "country") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "secured") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cash") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "interCompany") {
      setInsuranceCreditData({
        ...insuranceCreditData,
        [e.target.name]: e.target.value,
      });
    }
    // else if (e.target.name === "total") {
    //   setInsuranceCreditData({
    //     ...insuranceCreditData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    sessionStorage.setItem(
      "insuranceCreditData",
      JSON.stringify(insuranceCreditData)
    );

    console.log("insuranceCreditData", insuranceCreditData);
  };

  useEffect(() => {
    if (
      insuranceCreditData.secured ||
      insuranceCreditData.cash ||
      insuranceCreditData.credit ||
      insuranceCreditData.interCompany
    ) {
      setInsuranceCreditData({
        ...insuranceCreditData,
        total:
          (insuranceCreditData.credit
            ? parseInt(insuranceCreditData.credit)
            : 0) +
          (insuranceCreditData.cash ? parseInt(insuranceCreditData.cash) : 0) +
          (insuranceCreditData.secured
            ? parseInt(insuranceCreditData.secured)
            : 0) +
          (insuranceCreditData.interCompany
            ? parseInt(insuranceCreditData.interCompany)
            : 0),
      });
    }
  }, [insuranceCreditData]);
  console.log("insuranceCreditData", insuranceCreditData);
  return (
    <>
      <Content padding="0.5% 2% 1.5% 2%">
        <div style={{ margin: "10px" }}>
          <span>
            <input
              className={classes.radio}
              type="radio"
              name="tenure"
              value="Tenure"
              onChange={handleRadio}
              checked={insuranceCreditData.tenure == "Tenure"}
            />
            <label>Tenure (FY)</label>
          </span>
          <span style={{ marginLeft: "5%" }}>
            <input
              className={classes.radio}
              type="radio"
              name="tenure"
              value="OneTime"
              onChange={handleRadio}
              checked={insuranceCreditData.tenure == "OneTime"}
            />
            <label>One Time</label>
          </span>
        </div>

        <div style={{ margin: "0px 10px" }}>TURNOVER</div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
            <Select
              options={Currenciesr}
              name="currency"
              placeholder="Currency*"
              handleChange={handleChange}
              value={insuranceCreditData.currency}
            />
          </div>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
            <Input
              name="turnoverAmount"
              placeholder={`Turnover Amount(${insuranceCreditData.currency})*`}
              handleChange={handleChange}
              value={insuranceCreditData.turnoverAmount}
            />
          </div>
        </div>
        {insuranceCreditData.tenure === "Tenure" &&
        insuranceCreditData.tenure === "Tenure" ? (
          <>
            <div style={{ margin: "5px 10px 0px 10px" }}>
              Estimated insurable sales t/o for current financial year
            </div>
            <div style={{ margin: "5px 10px 10px 10px" }}>
              <Input
                name="salesValue"
                placeholder={`Estimated Insurable sales for Current FY(${insuranceCreditData.currency})*`}
                handleChange={handleChange}
                value={insuranceCreditData.salesValue}
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ margin: "0px 10px 0px 10px" }}>Trade Value</div>
            <div style={{ margin: "5px 10px 10px 10px" }}>
              <Input
                name="salesValue"
                placeholder={`Trade value(${insuranceCreditData.currency})`}
                handleChange={handleChange}
                value={insuranceCreditData.salesValue}
              />
            </div>
          </>
        )}
        <hr
          style={{ border: "2px solid white", margin: "20px 10px 10px 10px" }}
        ></hr>
        <div style={{ margin: "12px 10px 0px 10px " }}>
          Are you seeking Credit Insurance for
        </div>
        <div style={{ margin: "0px 10px 10px 10px" }}>
          <span style={{ marginLeft: "10px" }}>
            <input
              className={classes.radio}
              type="radio"
              name="insuranceType1"
              value="EXPORT"
              onChange={handleRadio}
              checked={insuranceCreditData.insuranceType1 == "EXPORT"}
            />
            <label>Export</label>
          </span>
          <span style={{ marginLeft: "5%" }}>
            <input
              className={classes.radio}
              type="radio"
              name="insuranceType1"
              value="DOMESTIC"
              onChange={handleRadio}
              checked={insuranceCreditData.insuranceType1 == "DOMESTIC"}
            />
            <label>Domestic</label>
          </span>
          <span style={{ marginLeft: "5%" }}>
            <input
              className={classes.radio}
              type="radio"
              name="insuranceType1"
              value="BOTH"
              onChange={handleRadio}
              checked={insuranceCreditData.insuranceType1 == "BOTH"}
            />
            <label>Both</label>
          </span>
        </div>
        <div
          className={classes.source}
          style={{ margin: "10px 10px 0px 10px" }}
        >
          <div style={{ width: "50%" }}>Product</div>
          {insuranceCreditData.insuranceType1 &&
          insuranceCreditData.insuranceType1 === "DOMESTIC" ? (
            <div style={{ width: "50%", marginLeft: "18px" }}>Country</div>
          ) : (
            <div style={{ width: "50%", marginLeft: "18px" }}>
              Exporting Countries
            </div>
          )}
        </div>
        <div className={classes.source} style={{ marginBottom: "0.3%" }}>
          <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
            <Grid item xs={12} md={5}>
              <MultiSelect
                options={Industry}
                name="product"
                placeholder="Product Category*"
                handleChange={(e) => handleMultiChange(e, "product")}
                selected={insuranceCreditData.product}
              />
            </Grid>
          </div>
          {insuranceCreditData.insuranceType1 &&
          insuranceCreditData.insuranceType1 === "DOMESTIC" ? (
            <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
              <Grid item xs={12} md={6}>
                <Select
                  options={CountryCodes}
                  name="country"
                  placeholder="Country*"
                  handleChange={handleChange}
                  value={insuranceCreditData.Country}
                />
              </Grid>
            </div>
          ) : (
            <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
              <Grid item xs={12} md={6}>
                <MultiSelect
                  options={CountryCodes}
                  name="countryMS"
                  placeholder="Country*"
                  handleChange={(e) => handleMultiChange(e, "countryMS")}
                  selected={insuranceCreditData.countryMS}
                />
              </Grid>
            </div>
          )}
        </div>
        <hr
          style={{ border: "2px solid white", margin: "10px 10px 10px 10px" }}
        ></hr>
        {insuranceCreditData.tenure === "Tenure" &&
        insuranceCreditData.tenure === "Tenure" ? (
          <>
            <div style={{ margin: "0px 10px" }}>
              Pecentage of total Turnover by:
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
                <Input
                  name="credit"
                  placeholder="% Credit*"
                  value={insuranceCreditData.credit}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
                <Input
                  name="cash"
                  placeholder="% Cash*"
                  value={insuranceCreditData.cash}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="secured"
                  placeholder="% Secured*"
                  value={insuranceCreditData.secured}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="interCompany"
                  placeholder="% Inter-Company*"
                  value={insuranceCreditData.interCompany}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
              <Input
                name="total"
                placeholder="% Total"
                handleChange={handleChange}
                value={
                  (insuranceCreditData.credit
                    ? parseInt(insuranceCreditData.credit)
                    : 0) +
                  (insuranceCreditData.cash
                    ? parseInt(insuranceCreditData.cash)
                    : 0) +
                  (insuranceCreditData.secured
                    ? parseInt(insuranceCreditData.secured)
                    : 0) +
                  (insuranceCreditData.interCompany
                    ? parseInt(insuranceCreditData.interCompany)
                    : 0)
                }
              />
            </div>
          </>
        ) : (
          <>
            <div style={{ margin: "0px 10px" }}>
              Pecentage of total Trade Value by:
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
                <Input
                  name="credit"
                  placeholder="% Credit*"
                  value={insuranceCreditData.credit}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
                <Input
                  name="cash"
                  placeholder="% Cash*"
                  value={insuranceCreditData.cash}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="secured"
                  placeholder="% Secured*"
                  value={insuranceCreditData.secured}
                  handleChange={handleChange}
                />
              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="interCompany"
                  placeholder="% Inter-Company*"
                  value={insuranceCreditData.interCompany}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
              <Input
                name="total"
                placeholder="% Total"
                handleChange={handleChange}
                readOnly={true}
                value={
                  (insuranceCreditData.credit
                    ? parseInt(insuranceCreditData.credit)
                    : 0) +
                  (insuranceCreditData.cash
                    ? parseInt(insuranceCreditData.cash)
                    : 0) +
                  (insuranceCreditData.secured
                    ? parseInt(insuranceCreditData.secured)
                    : 0) +
                  (insuranceCreditData.interCompany
                    ? parseInt(insuranceCreditData.interCompany)
                    : 0)
                }
              />
            </div>
          </>
        )}
      </Content>
    </>
  );
};

export default InsuranceCredit;
