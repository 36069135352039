import React, { useState } from 'react';
import Style from 'components/StyledCss/styled';
import OpenAccount from './OpenAccount';
import Overview from './overview';
import Info from "./Info";
import AlertsPayment from "./AlertsPayment";

const OpenTradeAccount = () => {

    const [header, setHeader] = useState("overview");
    const [confirmSchedule, setConfirmSchedule] = useState(false)

    const handleHeader = (e, value) => {
        setHeader(value);
    }

    const enableSP = () => {
        setHeader("schedule_and_payments");
        setConfirmSchedule(true)
    }

    const enableSchedulePayment = {
        enableSP
    }

    const confirmSchedulePayment = {
        enableSP,
        confirmSchedule
    }

    const component = () => {
        switch (header) {
            case ("overview"):
                return <Overview />
            case ("open_trade_account"):
                return <OpenAccount enableSchedulePayment={enableSchedulePayment} />
            case ("schedule_and_payments"):
                return <Info enableSchedulePayment={confirmSchedulePayment} />
            case ("alerts_and_notification"):
                return <AlertsPayment />
        }
    }
    return (
        <Style>
            <div>
                <div className='main'>
                    <div className='navbar'>
                        <div onClick={(e) => handleHeader(e, "overview")} style={(header === "overview") ? { color: "#42A5F5", textDecoration: "underline", textUnderlineOffset: "10px", textAlign: "center" } : { textAlign: "center" }}>Overview</div>
                        <div onClick={(e) => handleHeader(e, "open_trade_account")} style={(header === "open_trade_account") ? { color: "#42A5F5", textDecoration: "underline", textUnderlineOffset: "10px", textAlign: "center" } : { textAlign: "center" }}>Open Account Trade</div>
                        <div onClick={(e) => handleHeader(e, "schedule_and_payments")} style={(header === "schedule_and_payments") ? { color: "#42A5F5", textDecoration: "underline", textUnderlineOffset: "10px", textAlign: "center" } : { textAlign: "center" }}>Schedule & Payments</div>
                        <div onClick={(e) => handleHeader(e, "alerts_and_notification")} style={(header === "alerts_and_notification") ? { color: "#42A5F5", textDecoration: "underline", textUnderlineOffset: "10px", textAlign: "center" } : { textAlign: "center" }}>Alerts & Notifications</div>
                    </div>
                    <hr />
                    {(header) ? component() : ""}
                </div>
            </div>
        </Style>
    )
}

export default OpenTradeAccount