export const Header = [
  { name: "Select" },
  { name: "Name" },
  { name: "Type" },
  { name: "Owner" },
  { name: "Last modified" },
  { name: "Actions" },
];

export const Info = [
  { name: "NAME", value: "name" },
  { name: "OWNER", value: "owner" },
  { name: "VALIDATED", value: "validated" },
  { name: "IS DELETED", value: "isDeleted" },
  { name: "DOCUMENT ID", value: "id" },
  { name: "DOC ISSUER", value: "docIssuer" },
];
