import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./opentrade.module.css";
import cn from "classnames";
import CreatedByLabel from "components/UI/Label/CreatedByLabel";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";
import Currenciesr from "jsons/Currenciesr.json";
import { ToastContainer } from "react-toastify";
import { AuthContext } from "contexts/Auth/AuthContext";
import { openTradeAccount, paymentServiceURL } from "services/BaseURLInstance";
import { FindCompanyName } from "utils/helper";
import { UseRequest } from "utils/API/Request.js";
import { CompanyList } from "utils/API/CompanyList.js";
import { Countries } from "utils/DropDown/Country.js";
import {
  Industry,
  TradeStatus,
  EscrowService,
  PaymentCurrencies,
  PreferredPaymentMethod,
  EscrowPaymentDropDown,
} from "utils/Section/trade";

import moment from "moment";
import { Success, Error } from "components/notifications/responseHandle";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { ModifyTadeInfo } from "utils/Section/FormInfo";
import { useCalendarState } from "@mui/x-date-pickers/internals";
import { Grid } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

const ModifyTradeForm = (setIsTradeFormOpen, setIsSearchResult) => {
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [companyList, setCompanyList] = useState(null);

  const [DatePicker1, setDatePicker1] = useState(new Date());
  const [DatePicker2, setDatePicker2] = useState(new Date());
  const [DatePicker3, setDatePicker3] = useState(new Date());
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [IsPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
  const [newTradeResponse, setNewTradeResponse] = useState(null);
  const [isAdded, setIsAdded] = useState(null);
  const [isMemberId, setIsMemberId] = useState(null);
  const [isBuyerConsent, setIsBuyerConsent] = useState(false);
  const [payment, setPayment] = useState(null);
  const [provider, setProvider] = useState([]);
  const [provider2, setProvider2] = useState([]);
  const { OpenTradeAccountDetails, setOpenTradeAccountDetails } =
    useContext(AuthContext);
  const [modifyData, setModifyData] = useState(null);
  const { id } = useParams();
  const { response } = CompanyList();
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const [isLoading1, setIsLoading1] = useState(false);
  const [isError1, setIsError1] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [isError2, setIsError2] = useState(false);
  const [toast, setToast] = useState();

  const [NewTradeData, setNewTradeData] = useState({
    sellerMemberId: "",
    buyerMemberId: "",
    countryOfOrigin: "",
    countryOfDestination: "",
    poReference: "",
    invoiceReference: "",
    tradeValue: "",
    industryCategory: "",
    industrySubCategory: "", //OTHERS
    contractDate: "",
    createdDate: "",
    updatedDate: "",
    openTradeStatus: "",

    escrowService: "", // boolean_type
    paymentCurrency: "",
    preferredPaymentMethod: "",
    escrowPayment: "", // boolean_type
  });
  // const [sellerId, setSellerId] = useState(NewTradeData.sellerMemberId);

  const [PaymentData, setPaymentData] = useState({
    escrowServiceProviderUID: "",
    RefSellerVirtualBankAccountId: "",
    escrowConsentStatus: "NEW",
    referenceEscrowAccount: "",
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading1(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/user/${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading1(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
          } else {
            setPayment(data);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${openTradeAccount}openaccounttrades/${id}`,
          null,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
          } else {
            setModifyData(data);
          }
        });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (modifyData) {
      setNewTradeData({
        sellerMemberId: modifyData && modifyData.sellerMemberId,
        buyerMemberId: modifyData && modifyData.buyerMemberId,
        countryOfOrigin: modifyData && modifyData.countryOfOrigin,
        countryOfDestination: modifyData && modifyData.countryOfDestination,
        poReference: modifyData && modifyData.poReference,
        invoiceReference: modifyData && modifyData.invoiceReference,
        tradeValue: modifyData && modifyData.tradeValue,
        industryCategory: modifyData && modifyData.industryCategory,
        industrySubCategory: modifyData && modifyData.industrySubCategory, //OTHERS
        contractDate: modifyData && modifyData.contractDate,
        createdDate: modifyData && modifyData.createdDate,
        updatedDate: modifyData && modifyData.updatedDate,
        openTradeStatus: modifyData && modifyData.openTradeStatus,

        escrowService: modifyData && modifyData.escrowService, // boolean_type
        paymentCurrency: modifyData && modifyData.paymentCurrency,
        preferredPaymentMethod: modifyData && modifyData.preferredPaymentMethod,
        escrowPayment: modifyData && modifyData.escrowPayment, // boolean_type
      });

      setPaymentData({
        escrowServiceProviderUID: modifyData.escrowServiceProviderUID,
        RefSellerVirtualBankAccountId: modifyData.RefSellerVirtualBankAccountId,
        escrowConsentStatus: "NEW",
        referenceEscrowAccount: modifyData.referenceEscrowAccount,
      });
    }
  }, [modifyData]);

  useEffect(() => {
    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.paymentProvider,
          value: item.paymentProvider,
        };
      });
      setProvider(temp);
    }

    if (payment && payment[0] !== null) {
      let temp = payment.map((item) => {
        return {
          id: item.virtualAccountNumber,
          label: item.virtualAccountNumber,
          value: item.virtualAccountNumber,
        };
      });
      setProvider2(temp);
    }
  }, [payment]);

  const handleChange = (event, date) => {
    if (
      date === "contractDate" ||
      date === "createdDate" ||
      date === "updatedDate"
    ) {
      let newDate = new Date(event);
      let dateString = newDate.toISOString();

      let newDate1 = moment(new Date(event)).format("YYYY-MM-DD");
      if (date === "contractDate") {
        setDatePicker1(newDate1);
      }
      if (date === "createdDate") {
        setDatePicker2(newDate1);
      }
      if (date === "updatedDate") {
        setDatePicker3(newDate1);
      }

      setNewTradeData({
        ...NewTradeData,
        [date]: dateString,
      });
    } else if (event.target.name === "industryCategory") {
      setNewTradeData({
        ...NewTradeData,
        [event.target.name]: [event.target.value],
      });
    } else if (
      event.target.name === "countryOfOrigin" ||
      event.target.name === "countryOfDestination"
    ) {
      setNewTradeData({
        ...NewTradeData,
        [event.target.name]: event.target.value,
      });
    } else {
      setNewTradeData({
        ...NewTradeData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handlePaymentChange = (event) => {
    if (event.target.name === "escrowServiceProviderUID") {
      let value = event.target.value;
      const temp =
        payment &&
        payment[0] !== null &&
        payment.filter((res) => res.paymentProvider === event.target.value);
      setPaymentData({
        ...PaymentData,
        [event.target.name]: event.target.value,
        ["RefSellerVirtualBankAccountId"]: temp[0].virtualAccountNumber,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const options = {
      method: "PUT",
      body: newTradeResponse ? newTradeResponse : NewTradeData,
    };
    if (NewTradeData) {
      // NewTradeData.openTradeStatus = "NEW";
      NewTradeData.escrowConsentStatus = "BUYER_NOT_LINKED";
    }
    if (newTradeResponse) {
      // newTradeResponse.openTradeStatus = "NEW";
      newTradeResponse.escrowConsentStatus = "BUYER_NOT_LINKED";
    }
    let URL = `${openTradeAccount}openaccounttrades/${id}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        let { message, path } = data;
        if (message || path) {
          setIsError(message || "Something went wrong");
          Error(message);
        } else {
          setOpenTradeAccountDetails(data);
          Success("Trade Updated Successfully...");
          handleNavigate(`../open-account/${id}`);
        }
      });
    });
  };

  // CompanyList API
  const List = CompanyList();
  useEffect(() => {
    if (List && List.response) {
      let temp =
        List &&
        List.response.filter((item) => {
          if (
            item.memberType === "FINANCER" ||
            item.memberType === "COMPANY" ||
            item.memberUID === companyId
          ) {
            return { label: item.memberName, value: item.memberUID };
          }
        });

      let filter =
        temp &&
        temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });
      setCompanyList(filter);
    }
  }, [List && List.response]);

  const handleAbort = (e) => {
    e.preventDefault();
    if (newTradeResponse) {
      newTradeResponse.openTradeStatus = "ABORTED";
    }
    if (NewTradeData) {
      NewTradeData.openTradeStatus = "ABORTED";
    }
    const options = {
      method: "PUT",
      body: newTradeResponse ? newTradeResponse : NewTradeData,
    };
    // let URL = "";
    // if (newTradeResponse) {
    //   URL = `${openTradeAccount}openaccounttrades/${newTradeResponse.openTradeAccountId}`;
    // }
    // if (NewTradeData) {
    //   URL = `${openTradeAccount}openaccounttrades/${NewTradeData.openTradeAccountId}`;
    // }

    let URL = `${openTradeAccount}openaccounttrades/${id}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response).then((data) => {
        setIsLoading(false);
        let { message, path } = data;
        if (message || path) {
          setIsError(message || "Something went wrong");
          Error(message);
        } else {
          Success("Buyer Added successfully...");
          if (data.openTradeStatus === "ABORTED") {
            handleNavigate(`../open-account`);
          }
        }
      });
    });
  };

  return (
    <>
      {isLoading || List.isLoading || isLoading1 || isLoading2 ? (
        <Loader />
      ) : (
        <>
          {List && List.response && (
            <Content>
              <OverLayTrigger value={ModifyTadeInfo} />

              <form>
                <div className={classes.FormDiv}>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="buyerMemberId"
                      placeholder="Buyer"
                      options={companyList}
                      value={NewTradeData.buyerMemberId}
                      handleChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      name="sellerMemberId"
                      placeholder="Seller"
                      options={companyList}
                      value={NewTradeData.sellerMemberId}
                      handleChange={handleChange}
                      // readOnly={true}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      options={Countries}
                      name="countryOfOrigin"
                      placeholder="Country of Origin"
                      handleChange={handleChange}
                      value={NewTradeData.countryOfOrigin}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      options={Countries}
                      name="countryOfDestination"
                      placeholder="Country of Destination"
                      handleChange={handleChange}
                      value={NewTradeData.countryOfDestination}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Input
                        name="poReference"
                        placeholder="PO Reference *"
                        value={NewTradeData.poReference}
                        handleChange={handleChange}
                      />
                    </Grid> */}

                  {/* <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Input
                        name="invoiceReference"
                        placeholder="Invoice Reference *"
                        value={NewTradeData.invoiceReference}
                        handleChange={handleChange}
                      />
                    </Grid> */}
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      options={Currenciesr}
                      name="paymentCurrency"
                      placeholder="Payment Currency"
                      handleChange={handleChange}
                      value={NewTradeData.paymentCurrency}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Input
                      name="tradeValue"
                      placeholder="Trade Value *"
                      value={NewTradeData.tradeValue}
                      handleChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      options={Industry}
                      name="industryCategory"
                      placeholder="Industry"
                      handleChange={handleChange}
                      value={NewTradeData.industryCategory}
                    />
                  </Grid>

                  <div>
                    <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <DateInput
                        name="contractDate"
                        placeholder="Contract Date"
                        value={DatePicker1}
                        backDate={true}
                        handleChange={(e) => handleChange(e, "contractDate")}
                      />
                    </Grid>
                  </div>
                  {/* <div>
                      <DateInput
                        name="createdDate"
                        placeholder="Creation Date *"
                        value={DatePicker2}
                        handleChange={(e) => handleChange(e, "createdDate")}
                      />
                    </div> */}
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      options={TradeStatus}
                      name="openTradeStatus"
                      placeholder="Trade Status*"
                      handleChange={handleChange}
                      value={NewTradeData.openTradeStatus}
                      readOnly={true}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <DateInput
                        name="updatedDate"
                        placeholder="Last Activity Date"
                        value={DatePicker3}
                        handleChange={(e) => handleChange(e, "updatedDate")}
                      />
                    </Grid> */}

                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      options={PreferredPaymentMethod}
                      name="preferredPaymentMethod"
                      placeholder="Preferred Payment Method"
                      handleChange={handleChange}
                      value={NewTradeData.preferredPaymentMethod}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Select
                      options={EscrowService}
                      name="escrowService"
                      placeholder="Mode Of Payments"
                      handleChange={handleChange}
                      value={NewTradeData.escrowService}
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                      <Select
                        options={EscrowPaymentDropDown}
                        name="escrowPayment"
                        placeholder="Payments"
                        handleChange={handleChange}
                        value={NewTradeData.escrowPayment}
                        readOnly={isAdded && true}
                      />
                    </Grid> */}
                  <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                    <Input
                      name="ConsentStatus"
                      placeholder="Buyer Consent Status"
                      value={
                        (isAdded && isAdded.escrowConsentStatus) || "DRAFT"
                      }
                      handleChange={handleChange}
                      readOnly={true}
                    />
                  </Grid>
                </div>

                <ButtonDiv>
                  <Button
                    onClick={() => {
                      handleNavigate(`../open-account`);
                    }}>
                    Cancel
                  </Button>
                  <Button onClick={(e) => handleAbort(e)} type="button">
                    Abort Trade
                  </Button>
                  {!isAdded && (
                    <>
                      {newTradeResponse ? (
                        <>
                          {/* <Button onClick={handleAbort}>Abort Trade</Button> */}
                          {/* <Button
                              onClick={(e) => handlePutSubmitPayment(e)}
                              type="submit"
                            >
                              Send for Buyer Consent
                            </Button> */}
                        </>
                      ) : (
                        <>
                          <Button
                            onClick={(e) => handleSubmit(e)}
                            type="submit">
                            Update Open Trade
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {/* {newTradeResponse ? (
                  <>
                    {isBuyerConsent ? (
                      <></>
                    ) : (
                      <Button type="submit" onClick={handleConsent}>
                        Send for Buyer Consent
                      </Button>
                    )}
                  </>
                ) : (
                  <Button type="submit">Create Trade </Button>
                )} */}
                </ButtonDiv>
              </form>
              {toast && <div style={{ color: "green" }}>{toast}</div>}
              <div
                className={cn(
                  classes.StatusDiv,
                  "d-flex justify-content-between"
                )}>
                <div>
                  <CreatedByLabel
                    createdBy={
                      NewTradeData.sellerMemberId && response
                        ? FindCompanyName(response, NewTradeData.sellerMemberId)
                        : "NA"
                    }
                    createdDate={NewTradeData && NewTradeData.createdDate}
                    lastUpdateBy={
                      NewTradeData.sellerMemberId && response
                        ? FindCompanyName(response, NewTradeData.sellerMemberId)
                        : "NA"
                    }
                    lastUpdatedDate={NewTradeData.lastUpdatedDate}
                  />
                </div>
              </div>
            </Content>
          )}

          {(isError1 || isError2) && <span>Something went wrong</span>}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default ModifyTradeForm;
