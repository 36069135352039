import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";

import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import {
  CheckCategoryTitle,
  CheckCountryTitle,
  CheckCurrencyTitle,
  CheckPortTitle,
} from "utils/helper";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { Countries } from "utils/DropDown/Country";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";

const FreightInvoice = ({ response }) => {
  const [serviceDetails, setServiceDetails] = useState([]);
  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails));
    }
  }, [response]);

  return (
    <>
      {response && serviceDetails ? (
        <>
          <Content>
            <label>Freight Invoicing Requisition Form : </label>
            <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
              Requestor Information
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="RequestorName"
                placeholder=" Requestor Name:"
                readOnly={true}
                value={serviceDetails && serviceDetails.RequestorName}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="requesterDepart"
                placeholder="Requestor Department:"
                readOnly={true}
                value={serviceDetails && serviceDetails.requesterDepart}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="requestEmail"
                placeholder="Requestor Email:"
                readOnly={true}
                value={serviceDetails && serviceDetails.requestEmail}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="requestPhone"
                placeholder=" Requestor Phone Number: "
                readOnly={true}
                value={serviceDetails && serviceDetails.requestPhone}
                // readOnly={true}
              />
            </div>

            <div className={classes.source}>
              <div style={{ width: "50%", marginBottom: "2%" }}>
                <DateInput
                  name="requestdate"
                  placeholder="Date Of Request"
                  value={serviceDetails && serviceDetails.requestdate}
                  readOnly={true}
                />
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
              Supplier Information
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="supplierName"
                placeholder="Supplier Name"
                readOnly={true}
                value={serviceDetails && serviceDetails.supplierName}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="supplierContact"
                placeholder=" Supplier Contact Information* "
                readOnly={true}
                value={serviceDetails && serviceDetails.supplierContact}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="supplierPhone"
                placeholder=" Supplier Phone number"
                readOnly={true}
                value={serviceDetails && serviceDetails.supplierPhone}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="supplierEmail"
                placeholder="Supplier Email "
                readOnly={true}
                value={serviceDetails && serviceDetails.supplierEmail}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="supplierAdd"
                placeholder="Supplier Email "
                readOnly={true}
                value={serviceDetails && serviceDetails.supplierAdd}
                // readOnly={true}
              />
            </div>

            <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
              Freight Shipment Details
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", marginBottom: "2%" }}>
                <DateInput
                  name="shipmentDate"
                  placeholder="Shipment Date"
                  value={serviceDetails && serviceDetails.shipmentDate}
                  readOnly={true}
                />
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="refferenceNo"
                placeholder="Shipment Reference Number:"
                readOnly={true}
                value={serviceDetails && serviceDetails.refferenceNo}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="describeGoods"
                placeholder=" Description of Goods "
                readOnly={true}
                value={serviceDetails && serviceDetails.describeGoods}
                // readOnly={true}
              />
            </div>

            <div className={classes.source} style={{ marginBottom: "2%" }}>
              <div style={{ width: "50%" }}>
                <Input
                  // options={Countries}
                  name="countryFrom"
                  placeholder="Country of Origin*"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.countryFrom}
                  // readOnly={true}
                />
              </div>

              <div style={{ width: "50%", marginLeft: "1%" }}>
                <Input
                  // options={Countries}
                  name="countryTo"
                  placeholder="Country of Destination"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.countryTo}
                  // readOnly={true}
                />
              </div>
            </div>
            <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
              Service Details
            </div>
            <div style={{ width: "50%", marginBottom: "1%" }}>
              <Input
                // options={Countries}
                name="serviceTyp"
                placeholder="Freight Service Type*"
                readOnly={true}
                value={serviceDetails && serviceDetails.serviceTyp}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              {/* <Input
          style={{ height: "7rem", width: "100%" }}
          name="freightService"
          placeholder=" Freight Service Type"
readOnly={true}          value={serviceDetails && serviceDetails.freightService}
          // readOnly={true}
        /> */}
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="serviceLevel"
                placeholder="Service Level"
                readOnly={true}
                value={serviceDetails && serviceDetails.serviceLevel}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="billingMethod"
                placeholder="Billing Method"
                readOnly={true}
                value={serviceDetails && serviceDetails.billingMethod}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="specialinstruct"
                placeholder="Special Handling Instructions:"
                readOnly={true}
                value={serviceDetails && serviceDetails.specialinstruct}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
              Cost and Payment Information
            </div>
            <div style={{ width: "100%", marginBottom: "1%" }}>
              <Input
                style={{ height: "7rem", width: "100%" }}
                name="estimated"
                placeholder="Estimated Freight Cost:"
                readOnly={true}
                value={serviceDetails && serviceDetails.estimated}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "50%", marginBottom: "1%" }}>
              <Input
                options={Currenciesr}
                name="currency"
                placeholder="Currency*"
                readOnly={true}
                value={serviceDetails && serviceDetails.currency}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "50%", marginBottom: "1%" }}>
              <Input
                // options={paymentTerms}
                name="paymentTerm"
                placeholder="Payment Terms*"
                readOnly={true}
                value={serviceDetails && serviceDetails.paymentTerm}
                // readOnly={true}
              />
            </div>
          </Content>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default FreightInvoice;
