import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import classes from "./PaymentAccounts.module.css";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import { paymentServiceURL } from "services/BaseURLInstance";
import { TableStatus } from "utils/helper";
import { PaymentAccountListHeader } from "utils/TableHeader/PaymentSection";
import { Countries } from "utils/DropDown/Country";
import { AccountType, status } from "utils/DropDown/Payment";
import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { AddPaymentValdations } from "utils/Validations/Payment";

import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import UpdateAccount from "./UpdateAccount";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { AddPaymentAccount } from "utils/Section/FormInfo";
import country_of_exports from "jsons/CountryCodes.json";
import SelectWithSearch from "components/fields/SelectWithSearch";

import NoRecord from "components/no-data/noRecord";

const PaymentTransaction = () => {
  // const Country = [
  //   { label: "Canada", value: "Canada" },
  //   { label: "India", value: "India" },
  //   { label: "Singapore", value: "Singapore" },
  //   { label: "US", value: "US" },
  // ];

  // const Currency = [
  //   { label: "USD", value: "USD" },
  //   { label: "SGD", value: "SGD" },
  //   { label: "INR", value: "INR" },
  // ];
  const [CurrencyOptions, SetCurrencyOptions] = useState([]);

  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const [AccountList, setAccountList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const [isAdding, setIsAdding] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingData, setIsUpdatingData] = useState(null);

  //rbac
  const [Role, setRole] = useState(null);
  const [addAccountRole, setAddAccountRole] = useState(null);
  const [isCountry, setIsCountry] = useState(null);
  const [isCurrency, setIsCurrency] = useState(null);
  const [isBankCodes, setIsBankCodes] = useState(null);
  const [isInvalid, setIsInvalid] = useState(null);
  const [apiTrigger, setApiTrigger] = useState(false);
  const [isPaymentCodeApiFailed, setIsPaymentCodeApiFailed] = useState(false);

  const [Data, setData] = useState({
    memberID: companyId,
    paymentAccountCurrency: "",
    country: "",
    providerDetails: [],
    paymentAccountHolderName: "", // 1
    accountType: "", // 2
    bankName: "", // 3
    paymentAccountNumber: "", // 4 // unique
    status: "ACTIVE", // 5
    countryCode: "",
    bankCodes: [],
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}payments/account?memberId=${companyId}`,
          null,
          response
        )
          .then((data) => {
            setAccountList(data);
          })
          .catch((error) => {
            Error("Something went wrong");
            setIsError("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [apiTrigger]);

  const handleChange = (e) => {
    if (e.target.name === "country") {
      let countryFilter = Countries.filter(
        (country) => country.value === e.target.value
      );

      if (countryFilter.length > 0) {
        setIsCountry(countryFilter[0].label);
        setData({
          ...Data,
          [e.target.name]: countryFilter[0].label,
        });
      }
    } else if (e.target.name === "currency") {
      setIsCurrency(e.target.value);
      setData({
        ...Data,
        ["paymentAccountCurrency"]: e.target.value,
      });
    } else {
      setData({
        ...Data,
        [e.target.name]: e.target.value,
      });
    }
  };

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      )
        .then((data) => {
          setRole(data.role);
        })
        .catch((error) => {
          Error("Something went wrong");
          setIsError("Something went wrong");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setAddAccountRole(
        CheckPermission("AddAccount", "PaymentAccounts", "addAccount", Role)
      );
    }
  }, [Role]);

  useEffect(() => {
    if (isCountry) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}banks/code/${isCountry}`,
          null,
          response
        )
          .then((data) => {
            let TEMP = [];
            data.forEach((code, index) =>
              TEMP.push({
                label: code.currency,
                value: code.currency,
              })
            );
            SetCurrencyOptions(TEMP);
          })
          .catch((error) => {
            setIsPaymentCodeApiFailed(true);
            Error("Something went wrong");
          });
      });
    }
  }, [isCountry]);

  useEffect(() => {
    if (isCountry && isCurrency) {
      Data.bankCodes = [];
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${paymentServiceURL}banks/codes?country=${isCountry}&currency=${isCurrency}`,
          null,
          response
        )
          .then((data) => {
            let { bankCodes } = data;
            if (bankCodes) {
              setIsBankCodes(data);
              bankCodes.forEach(
                (code, index) =>
                  (Data.bankCodes[index] = { bankCodeType: code, bankCode: "" })
              );
              setIsInvalid(null);
            } else {
              setIsBankCodes(null);
              setIsInvalid(data);
            }
          })
          .catch((error) => {
            setIsPaymentCodeApiFailed(true);
            Error("Something went wrong");
          });
      });
    }
  }, [isCountry, isCurrency]);

  const handleAddAccount = () => {
    setData({
      memberID: companyId,
      paymentAccountCurrency: "",
      country: "",
      providerDetails: [],
      paymentAccountHolderName: "",
      accountType: "",
      bankName: "",
      paymentAccountNumber: "", // unique
      status: "ACTIVE",
      countryCode: "",
      bankCodes: [],
    });
    setIsAdding(true);
  };

  const handleClickBack = () => {
    setData({
      memberID: companyId,
      paymentAccountCurrency: "",
      country: "",
      providerDetails: [],
      paymentAccountHolderName: "",
      accountType: "",
      bankName: "",
      paymentAccountNumber: "", // unique
      status: "ACTIVE",
      countryCode: "",
      bankCodes: [],
    });
    setIsAdding(false);
    setIsUpdating(false);
  };

  const HandleSubmit = async (e) => {
    let checkValidation = AddPaymentValdations(Data, isBankCodes);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      e.preventDefault();
      setIsLoading(true);
      const options = {
        method: "POST",
        body: Data,
      };
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${paymentServiceURL}payments/account`, options, response)
          .then((data) => {
            // setIsLoading(false);
            // let { message } = data;
            // if (message) {
            //   Error(message);
            // } else {
            //   Success("Account added successfully");
            //   setIsAdding(false);
            //   setApiTrigger(true);
            //   setIsUpdating(false);
            //   setIsBankCodes(null);
            // }
            Success("Account added successfully");
            setIsAdding(false);
            setApiTrigger(true);
            setIsUpdating(false);
            setIsBankCodes(null);
          })
          .catch((error) => {
            Error("Something went wrong");
            setIsError("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  };

  const handleUpdate = (user) => {
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${paymentServiceURL}payments/account/get-detail?memberId=${user.memberID}&accountNumber=${user.paymentAccountNumber}`,
        null,
        response
      )
        .then((data) => {
          setIsUpdating(true);
          setIsUpdatingData(data);
        })
        .catch((error) => {
          Error("Something went wrong");
          setIsError("Something went wrong");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const handleChangeBankCode = (event, index) => {
    let Object = {
      bankCodeType: event.target.name,
      bankCode: event.target.value,
    };
    Data.bankCodes[index] = Object;
  };

  return (
    <>
      {isLoading ? (
        <Content>
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          {!isAdding && !isUpdating && (
            <Content padding="0.8% 2%">
              <div className="d-flex justify-content-between py-3">
                <div style={{fontSize:"18px"}}>Payments Account</div>
                <Button
                  onClick={() =>
                    addAccountRole === "CREATE" ? handleAddAccount() : " "
                  }>
                  + Add Account
                </Button>
              </div>
              {AccountList && (
                <>
                <Table bordered responsive className={cn(classes.Table)}>
                  <thead>
                    <tr>
                      {PaymentAccountListHeader.map((header, index) => (
                        <td key={index}>{header.name}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {AccountList.map((user, index) => (
                      <tr key={index}>
                        <td
                          onClick={() => handleUpdate(user)}
                          style={{ cursor: "pointer" }}>
                          {user.bankName}
                        </td>
                        <td>{user.paymentAccountHolderName}</td>
                        <td>{user.paymentAccountNumber}</td>
                        <td>{user.country}</td>
                        <td>{user.paymentAccountCurrency}</td>
                        <td>{TableStatus(`${"VERIFICATION_PASS"}`)}</td>
                      </tr>
                    ))}
                  </tbody>
                  
                </Table>
                {AccountList && AccountList.length === 0 && <NoRecord />}
                </> 
              )}
            </Content>
          )}

          {isAdding && (
            <Content padding="0.5% 2%">
              <OverLayTrigger value={AddPaymentAccount} />
              <div className={classes.UserManagement}>
                <div className={classes.InputList}>
                  <Input
                    name="paymentAccountHolderName"
                    value={Data.paymentAccountHolderName}
                    placeholder="Account Holder Name"
                    handleChange={handleChange}
                  />
                </div>
                <div className={classes.InputList}>
                  <Input
                    name="bankName"
                    value={Data.bankName}
                    placeholder="Bank Name"
                    handleChange={handleChange}
                  />
                </div>
                <div className={classes.InputList}>
                  <SelectWithSearch
                    options={country_of_exports}
                    name="country"
                    // value={Data.country}
                    placeholder="Country"
                    handleChange={handleChange}
                  />
                </div>
                <div className={classes.InputList}>
                  <Select
                    options={CurrencyOptions}
                    name="currency"
                    placeholder="Currency"
                    handleChange={handleChange}
                  />
                </div>

                {isCountry &&
                  isCurrency &&
                  isBankCodes &&
                  isBankCodes.bankCodes &&
                  isBankCodes.bankCodes.map((item, index) => (
                    <div className={classes.InputList}>
                      <Input
                        name={item}
                        placeholder={item}
                        handleChange={(e) => handleChangeBankCode(e, index)}
                      />
                    </div>
                  ))}
                {isInvalid && isCountry && isCurrency && (
                  <div>
                    <span style={{ color: "red" }}>{isInvalid}</span>
                  </div>
                )}

                <div className={classes.InputList}>
                  <Select
                    options={AccountType}
                    name="accountType"
                    value={Data.accountType}
                    placeholder="Account Type"
                    handleChange={handleChange}
                  />
                </div>

                <div className={classes.InputList}>
                  <Input
                    name="paymentAccountNumber"
                    value={Data.paymentAccountNumber}
                    placeholder="Account Number"
                    handleChange={handleChange}
                  />
                </div>
                <div className={classes.InputList}>
                  <Select
                    options={status}
                    name="status"
                    value={Data.status}
                    placeholder="Status"
                    handleChange={handleChange}
                  />
                </div>
                <div></div>
              </div>

              <ButtonDiv>
                <Button onClick={() => handleClickBack()}>Cancel</Button>
                <Button
                  // disabled={
                  //   isPaymentCodeApiFailed ? isPaymentCodeApiFailed : false
                  // }
                  onClick={(e) => HandleSubmit(e)}>
                  Submit
                </Button>
              </ButtonDiv>
            </Content>
          )}

          {isUpdating && (
            <Content>
              <UpdateAccount
                isUpdatingData={isUpdatingData}
                setIsUpdating={setIsUpdating}
              />
            </Content>
          )}

          {isError && <Content>{isError}</Content>}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default PaymentTransaction;
