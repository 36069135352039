const CompanyList = [
  {
    docCategory: "COMPANY",
    docSubCategory: [
      "CoI",
      "PANCard",
      "TAN",
      "GSTIN",
      "ExportLicense",
      "ImportLicense",
    ],
  },
  {
    docCategory: "OTHERS",
    docSubCategory: ["OTHERS"],
  },
];

export const CompanyDocuments = (name) => {
  let CompanyCategory = CompanyList.map((item) => ({
    label: item.docCategory,
    value: item.docCategory,
  }));

  const Empty = [];
  let temp = CompanyList.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });

  let CompanySubCategory =
    temp &&
    temp[0] &&
    temp[0].docSubCategory.map((item) => ({
      label: item,
      value: item,
    }));

  if (name !== null) {
    return { CompanyCategory, CompanySubCategory };
  } else {
    return { CompanyCategory, Empty };
  }
};

const ServiceContractDocumentsList = [
  {
    docCategory: "CONTRACT",
    docSubCategory: ["SERVICE_CONTRACT-DRAFT", "SERVICE_CONTRACT-SIGNED"],
  },
];

export const ServiceContractDocuments = (name) => {
  let ServiceContractCategory = ServiceContractDocumentsList.map((item) => ({
    label: item.docCategory,
    value: item.docCategory,
  }));

  const Empty = [];
  let temp = ServiceContractDocumentsList.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });

  let ServiceContractSubCategory =
    temp &&
    temp[0] &&
    temp[0].docSubCategory.map((item) => ({
      label: item,
      value: item,
    }));

  if (name !== null) {
    return { ServiceContractCategory, ServiceContractSubCategory };
  } else {
    return { ServiceContractCategory, Empty };
  }
};

const PaymentsDocumentsList = [
  {
    docCategory: "PAYMENTS",
    docSubCategory: [
      "TRANSACTION_DEBIT_ADVICE",
      "PAYMENT_RECEIPT_DOCUMENT",
      "PAYMENT_ACKNOWLEDGEMENT",
    ],
  },
  {
    docCategory: "OTHERS",
    docSubCategory: ["OTHERS"],
  },
];

export const PaymentsDocuments = (name) => {
  let PaymentCategory = PaymentsDocumentsList.map((item) => ({
    label: item.docCategory,
    value: item.docCategory,
  }));

  const Empty = [];
  let temp = PaymentsDocumentsList.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });

  let PaymentSubCategory =
    temp &&
    temp[0] &&
    temp[0].docSubCategory.map((item) => ({
      label: item,
      value: item,
    }));

  if (name !== null) {
    return { PaymentCategory, PaymentSubCategory };
  } else {
    return { PaymentCategory, Empty };
  }
};

const DocGroup = [
  {
    serviceCategory: "TRADE_CONTRACT",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "TRADE_CONTRACT",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRADE_CONTRACT",
        docSubCategory: [
          "TRADE_CONTRACT-DRAFT",
          "TRADE_CONTRACT-SIGNED",
          "OTHERS",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "LEGAL",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "LEGAL",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "LEGAL",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "MEMBER_ONBOARDING",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "KYC",
        docSubCategory: ["DIN", "PASSPORT", "AADHAR", "PAN"],
      },
      {
        docCategory: "MEMBER_ONBOARDING",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "ONBOARDING",
        docSubCategory: ["MEMBER_DUE_DILIGENCE_REPORT"],
      },
      {
        docCategory: "MEMBER_ONBOARDING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "KYB",
    serviceSubCategory: "IDENTITY_VERIFICATION",
    required: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_PAN",
          "CERTIFICATE_OF_INCORPORATION",
          "GSTIN",
          "EXPORT/IMPORT_LICENSE",
          "OTHERS",
        ],
      },
    ],
    delivered: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "KYB",
    serviceSubCategory: "BACKGROUND_CHECK",
    required: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_PAN",
          "CERTIFICATE_OF_INCORPORATION",
          "GSTIN",
          "EXPORT/IMPORT_LICENSE",
          "OTHERS",
        ],
      },
    ],
    delivered: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "KYB",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_PAN",
          "CERTIFICATE_OF_INCORPORATION",
          "GSTIN",
          "EXPORT/IMPORT_LICENSE",
          "OTHERS",
        ],
      },
    ],
    delivered: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FINANCING",
    serviceSubCategory: "FINANCING_PO",
    required: [
      {
        docCategory: "SALES_ORDER",
        docSubCategory: ["PURCHASE_ORDER", "SALES_ORDER", "PROFORMA_INVOICE"],
      },
      {
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: ["FINANCE_REQUISITION"],
      },
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: ["FINANCE_DEAL"],
      },
      {
        docCategory: "LOAN_STRUCTURE",
        docSubCategory: ["LOAN_STRUCTURE"],
      },
      {
        docCategory: "FINANCING_AGREEMENT",
        docSubCategory: ["FINANCING_AGREEMENT(T&C)"],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FINANCING",
    serviceSubCategory: "FINANCING_INVOICE",
    required: [
      {
        docCategory: "SALES_ORDER",
        docSubCategory: [
          "PURCHASE_ORDER",
          "SALES_ORDER",
          "PROFORMA_INVOICE",
          "FINAL_INVOICE",
        ],
      },
      {
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: ["FINANCE_REQUISITION"],
      },
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: ["FINANCE_DEAL"],
      },
      {
        docCategory: "LOAN_STRUCTURE",
        docSubCategory: ["LOAN_STRUCTURE"],
      },
      {
        docCategory: "FINANCING_AGREEMENT",
        docSubCategory: ["FINANCING_AGREEMENT(T&C)"],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FINANCING",
    serviceSubCategory: "SYNDICATION_FINANCING",
    required: [
      {
        docCategory: "SALES_ORDER",
        docSubCategory: [
          "PURCHASE_ORDER",
          "SALES_ORDER",
          "PROFORMA_INVOICE",
          "FINAL_INVOICE",
        ],
      },
      {
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: ["FINANCE_REQUISITION"],
      },
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: ["FINANCE_DEAL"],
      },
      {
        docCategory: "LOAN_STRUCTURE",
        docSubCategory: ["LOAN_STRUCTURE"],
      },
      {
        docCategory: "FINANCING_AGREEMENT",
        docSubCategory: ["FINANCING_AGREEMENT(T&C)"],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FINANCING",
    serviceSubCategory: "CREDIT_ASSESSMENT",
    required: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "FINANCE",
        docSubCategory: ["ANNUAL_STATEMENT"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "FINANCE_DUE_DILIGENCE",
        docSubCategory: ["CREDIT_ASSESSMENT_REPORT"],
      },
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FINANCING",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FINANCING",
    serviceSubCategory: "FREIGHT_FINANCING",
    required: [
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "FRIEGHT_PLAN_INFO",
          "TRANSPORT_ORDER",
          "CARGO_DECLARATIONS",
          "BILL_OF_LADING",
          "AIRWAY_BILL",
        ],
      },
    ],
    delivered: [
      {
        docCategory: "FINANCING",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "FINANCE_REQUISITION",
        docSubCategory: ["FINANCE_DEAL"],
      },
      {
        docCategory: "LOAN_STRUCTURE",
        docSubCategory: ["LOAN_STRUCTURE"],
      },
      {
        docCategory: "FINANCING_AGREEMENT",
        docSubCategory: ["FINANCING_AGREEMENT(T&C)"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "ACCOUNTS_TAXATION",
    serviceSubCategory: "TAXES",
    required: [
      {
        docCategory: "ACCOUNTS_TAXATION",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "ACCOUNTS_TAXATION",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "ACCOUNTS_TAXATION",
    serviceSubCategory: "ACCOUNTING",
    required: [
      {
        docCategory: "ACCOUNTS_TAXATION",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "ACCOUNTS_TAXATION",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "ACCOUNTS_TAXATION",
    serviceSubCategory: "ANALYTICS_AND_REPORTING",
    required: [
      {
        docCategory: "ACCOUNTS_TAXATION",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "ACCOUNTS_TAXATION",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "COMPLIANCE",
    serviceSubCategory: "TRADE",
    required: [
      {
        docCategory: "SALES_ORDER",
        docSubCategory: ["FINAL_INVOICE", "PURCHASE_ORDER"],
      },
      {
        docCategory: "TRADE_CONTRACT",
        docSubCategory: ["TRADE_CONTRACT-SIGNED"],
      },
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRADE_COMPLIANCE",
        docSubCategory: ["TRADE_COMPLIANCE_REPORT"],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "COMPLIANCE",
    serviceSubCategory: "FINANCIAL",
    required: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRADE_COMPLIANCE",
        docSubCategory: ["FINANCIAL_COMPLIANCE_REPORT"],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "COMPLIANCE",
    serviceSubCategory: "IMPORT_DECLARATIONS",
    required: [
      {
        docCategory: "SALES_ORDER",
        docSubCategory: ["PURCHASE_ORDER", "FINAL_INVOICE"],
      },
      {
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: ["GOODS_INFO", "IMPORT_DECLARATION"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["BILL_OF_LADING"],
      },
      {
        docCategory: "PACKING",
        docSubCategory: ["PACKING_LIST"],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRADE_COMPLIANCE",
        docSubCategory: ["IMPORT_DECLARATION_COMPLIANCE_REPORT", "OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "COMPLIANCE",
    serviceSubCategory: "EXPORT_DECLARATIONS",
    required: [
      {
        docCategory: "SALES_ORDER",
        docSubCategory: ["PURCHASE_ORDER", "FINAL_INVOICE"],
      },
      {
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: ["GOODS_INFO", "IMPORT_DECLARATION"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["EXPORT_DECLARATION"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["BILL_OF_LADING"],
      },
      {
        docCategory: "PACKING",
        docSubCategory: ["PACKING_LIST"],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["EXPORT_DECLARATION_COMPLIANCE_REPORT", "OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "COMPLIANCE",
    serviceSubCategory: "LMPC_CERTIFICATE",
    required: [
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_PAN",
          "CERTIFICATE_OF_INCORPORATION",
          "GSTIN",
          "EXPORT/IMPORT_LICENSE",
          "OTHERS",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "LMPC_CERTIFICATE",
        docSubCategory: ["LMPC_CERTIFICATE"],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "COMPLIANCE",
    serviceSubCategory: "LUT",
    required: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_PAN",
          "CERTIFICATE_OF_INCORPORATION",
          "GSTIN",
          "EXPORT/IMPORT_LICENSE",
          "OTHERS",
        ],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "LUT_CERTIFICATE",
        docSubCategory: ["LUT_CERTIFICATE"],
      },
      {
        docCategory: "COMPLIANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "INSURANCE",
    serviceSubCategory: "CREDIT",
    required: [
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "COMPANY_BACKGROUND_CHECK",
          "CREDIT_ASSESSMENT",
          "KYB_ASSESSMENT",
          "OTHERS",
        ],
      },
      {
        docCategory: "FINANCING_AGREEMENT",
        docSubCategory: ["FINANCING_AGREEMENT(T&C)"],
      },
      {
        docCategory: "LOAN_STRUCTURE",
        docSubCategory: ["LOAN_STRUCTURE"],
      },
      {
        docCategory: "INSURANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "CREDIT_INSURANCE",
        docSubCategory: ["CREDIT_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "INSURANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "INSURANCE",
    serviceSubCategory: "CARGO",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "FRIEGHT_PLAN_INFO",
          "TRANSPORT_ORDER",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "BILL_OF_LADING",
          "AIRWAY_BILL",
        ],
      },
      {
        docCategory: "TRADE_COMPLIANCE",
        docSubCategory: ["TRADE_COMPLIANCE_REPORT"],
      },
      {
        docCategory: "KYB",
        docSubCategory: [
          "COMPANY_DUE_DILIGENCE",
          "FINANCE_DUE_DILIGENCE",
          "OTHERS",
        ],
      },
      {
        docCategory: "INSURANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "INSURANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "INSURANCE",
    serviceSubCategory: "INLAND",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "FREIGHT_PLAN_INFO",
          "TRANSPORT_ORDER",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "AIRWAY_BILL",
        ],
      },
      {
        docCategory: "TRADE_COMPLIANCE",
        docSubCategory: ["TRADE_COMPLIANCE_REPORT"],
      },
      {
        docCategory: "INSURANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "INSURANCE",
        docSubCategory: ["OTHERS"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "PAYMENTS",
    serviceSubCategory: "PAYMENT_ADVANCES",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
        ],
      },
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: ["ACCOUNT_STATEMENT"],
      },
      {
        docCategory: "KYB",
        docSubCategory: ["COMPANY_BACKGROUND_CHECK"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: [
          "PAYMENT_ACKNOWLEDGEMENT",
          "SWIFT_COPY",
          "BANK_RECEIPT",
          "TRANSACTION_DEBIT_ADVICE",
        ],
      },
      {
        docCategory: "PAYMENTS",
        docSubCategory: ["PAYMENT_RECEIPT_DOCUMENT"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "PAYMENTS",
    serviceSubCategory: "ESCROW",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
        ],
      },
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: ["ACCOUNT_STATEMENT"],
      },
      {
        docCategory: "KYB",
        docSubCategory: ["COMPANY_BACKGROUND_CHECK"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: [
          "PAYMENT_ACKNOWLEDGEMENT",
          "SWIFT_COPY",
          "BANK_RECEIPT",
          "TRANSACTION_DEBIT_ADVICE",
        ],
      },
      {
        docCategory: "PAYMENTS",
        docSubCategory: ["PAYMENT_RECEIPT_DOCUMENT"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "PAYMENTS",
    serviceSubCategory: "CROSS_BORDER",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
        ],
      },
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: ["ACCOUNT_STATEMENT"],
      },
      {
        docCategory: "KYB",
        docSubCategory: ["COMPANY_BACKGROUND_CHECK"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: [
          "PAYMENT_ACKNOWLEDGEMENT",
          "SWIFT_COPY",
          "BANK_RECEIPT",
          "TRANSACTION_DEBIT_ADVICE",
        ],
      },
      {
        docCategory: "PAYMENTS",
        docSubCategory: ["PAYMENT_RECEIPT_DOCUMENT"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "PAYMENTS",
    serviceSubCategory: "DOMESTIC",
    required: [
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: ["ACCOUNT_STATEMENT"],
      },
      {
        docCategory: "KYB",
        docSubCategory: ["COMPANY_BACKGROUND_CHECK"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "PAYMENT_SETTLEMENT",
        docSubCategory: [
          "PAYMENT_ACKNOWLEDGEMENT",
          "BANK_RECEIPT",
          "TRANSACTION_DEBIT_ADVICE",
        ],
      },
      {
        docCategory: "PAYMENTS",
        docSubCategory: ["PAYMENT_RECEIPT_DOCUMENT"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FREIGHT",
    serviceSubCategory: "EXPORT",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "DG_OR_NON_DG_CERTIFICATE",
        ],
      },
      {
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: ["INCOTERM"],
      },
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "FREIGHT_CARRIERS",
          "BILL_OF_LADING",
          "AIRWAY_BILL",
          "FREIGHT_PLAN_INFO",
          "TRANSPORT_ORDER",
        ],
      },
      {
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: [
          "FREIGHT_FORWARDER_QUOTE",
          "ADVANCE_SHIPPING_NOTICE",
          "DELIVERY_DOCUMENT",
          "SHIPPING_BILL",
        ],
      },
      {
        docCategory: "PLAN_CONTAINER",
        docSubCategory: ["ETA_OF_CONTAINER", "ETD_OF_SHIP"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "FREIGHT",
    serviceSubCategory: "IMPORT",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "DG_OR_NON_DG_CERTIFICATE",
        ],
      },
      {
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: ["INCOTERM"],
      },
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "FREIGHT_CARRIERS",
          "BILL_OF_LADING",
          "AIRWAY_BILL",
          "FREIGHT_PLAN_INFO",
          "TRANSPORT_ORDER",
        ],
      },
      {
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: [
          "FREIGHT_FORWARDER_QUOTE",
          "ADVANCE_SHIPPING_NOTICE",
          "DELIVERY_DOCUMENT",
          "SHIPPING_BILL",
        ],
      },
      {
        docCategory: "PLAN_CONTAINER",
        docSubCategory: ["ETA_OF_CONTAINER", "ETD_OF_SHIP"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "TRANSPORT",
    serviceSubCategory: "TRUCK",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "DG_OR_NON_DG_CERTIFICATE",
        ],
      },
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["TRANSPORT_ORDER"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "TRANSPORT",
    serviceSubCategory: "MARITIME",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "DG_OR_NON_DG_CERTIFICATE",
        ],
      },
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["TRANSPORT_ORDER", "BILL_OF_LADING"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "TRANSPORT",
    serviceSubCategory: "RAIL",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "DG_OR_NON_DG_CERTIFICATE",
        ],
      },
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["TRANSPORT_ORDER", "BILL_OF_LADING"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "TRANSPORT",
    serviceSubCategory: "AIR",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "CARGO_DECLARATIONS",
          "WAREHOUSE_RECEIPT",
          "DG_OR_NON_DG_CERTIFICATE",
        ],
      },
      {
        docCategory: "GOODS_INSURANCE",
        docSubCategory: ["GOODS_INSURANCE_CERTIFICATE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["TRANSPORT_ORDER", "AIRWAY_BILL"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "TERMINAL",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: [
          "IMPORT_DECLARATION",
          "GOODS_INFO",
          "DEMURRAGE_CHARGES_RECEIPT",
        ],
      },
      {
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: ["EXPORT_DECLARATION"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: ["TERMINAL_WAREHOUSE_DOCUMENT"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["BILL_OF_LADING"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "SHIPPING",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["PACKING_LIST", "FINAL_INVOICE"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["BILL_OF_LADING"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: ["SHIPPING_BILL", "ADVANCE_SHIPPING_NOTICE"],
      },
      {
        docCategory: "SCHEDULING_AGREEMENT",
        docSubCategory: ["SHIPPING_SCHEDULE"],
      },
      {
        docCategory: "SHIPPING_DELIVERY",
        docSubCategory: ["TELEX_B/L"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "CONTAINER",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["PACKING_LIST", "FINAL_INVOICE"],
      },
      {
        docCategory: "SHIPPING_ORDER",
        docSubCategory: ["SHIPPING_BILL"],
      },
      {
        docCategory: "SCHEDULING_AGREEMENT",
        docSubCategory: ["SHIPPING_SCHEDULE"],
      },
      {
        docCategory: "DELIVERY_PLANNING",
        docSubCategory: ["SHIPPING_BILL", "ADVANCE_SHIPPING_NOTICE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "PLAN_CONTAINER",
        docSubCategory: ["ETA_OF_CONTAINER", "ETD_OF_SHIP"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "CUSTOMS",
    serviceSubCategory: "CUSTOM_CLEARANCE",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["PACKING_LIST", "FINAL_INVOICE"],
      },
      {
        docCategory: "CUSTOMS(IMPORT)",
        docSubCategory: [
          "INSPECTION_CERTIFICATE",
          "CUSTOM_DECLARATIONS",
          "CUSTOM_CLEARANCE",
          "TARIFF_PAYMENT",
        ],
      },
      {
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: [
          "INSPECTION_CERTIFICATE",
          "EXPORT_GENERAL_MANIFEST",
          "BILL_OF_EXCHANGE",
          "EXPORT_DECLARATION",
          "CUSTOM_CLEARANCE",
          "TARIFF_PAYMENT",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "CUSTOMS",
        docSubCategory: ["BILL_OF_ENTRY"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },

  {
    serviceCategory: "CUSTOMS",
    serviceSubCategory: "CUSTOMS_INSPECTION",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: ["PACKING_LIST", "FINAL_INVOICE"],
      },
      {
        docCategory: "CUSTOMS(IMPORT)",
        docSubCategory: ["CUSTOM_DECLARATIONS"],
      },
      {
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: ["EXPORT_DECLARATION"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["GOODS_INFO"],
      },
      {
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: ["GOODS_INFO"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "CUSTOMS(IMPORT)",
        docSubCategory: ["INSPECTION_CERTIFICATE"],
      },
      {
        docCategory: "CUSTOMS(EXPORT)",
        docSubCategory: ["INSPECTION_CERTIFICATE"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "INSPECTION",
    serviceSubCategory: "QUALITY",
    required: [
      {
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: ["GOODS_RECEIPT_ACKNOWLEDGEMENT", "WAREHOUSE_RECEIPT"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["GOODS_INFO"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: ["INSPECTION_REPORT"],
      },
      {
        docCategory: "INSPECTION",
        docSubCategory: [
          "SAMPLING_DETAILS",
          "INSPECTION_CERTIFICATE",
          "INSPECTION_REPORTS",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "INSPECTION",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: ["GOODS_RECEIPT_ACKNOWLEDGEMENT", "WAREHOUSE_RECEIPT"],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["GOODS_INFO"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: ["INSPECTION_REPORT"],
      },
      {
        docCategory: "INSPECTION",
        docSubCategory: [
          "SAMPLING_DETAILS",
          "INSPECTION_CERTIFICATE",
          "INSPECTION_REPORTS",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "WAREHOUSE",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["GOODS_INFO"],
      },
      {
        docCategory: "TERMINAL(IMPORT)",
        docSubCategory: ["GOODS_INFO"],
      },
      {
        docCategory: "PACKING",
        docSubCategory: ["PACKING_LIST"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "WAREHOUSE_STORAGE",
        docSubCategory: [
          "WAREHOUSE_STORAGE_INVOICE",
          "GOODS_RECEIPT_ACKNOWLEDGEMENT",
          "WAREHOUSE_RECEIPT",
        ],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "STEVEDORING",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "TRANSPORT",
        docSubCategory: [
          "CERTIFICATE_OF_ORIGIN",
          "PACKING_LIST",
          "FINAL_INVOICE",
          "FRIEGHT_PLAN_INFO",
          "TRANSPORT_ORDER",
          "CARGO_DECLARATIONS",
          "BILL_OF_LADING",
          "AIRWAY_BILL",
        ],
      },
      {
        docCategory: "TERMINAL(EXPORT)",
        docSubCategory: ["GOODS_INFO"],
      },
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
  {
    serviceCategory: "INVOICING",
    serviceSubCategory: "OTHERS",
    required: [
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS"],
      },
    ],
    delivered: [
      {
        docCategory: "OTHERS",
        docSubCategory: ["OTHERS", "COMMERCIAL_SERVICE_INVOICE"],
      },
    ],
  },
];

export const DocumentGroup = (
  ServiceCategory,
  ServiceSubCategory,
  TYPE,
  selectedCategory
) => {
  console.log("ServiceCategory", ServiceCategory, ServiceSubCategory);

  let Group = DocGroup?.filter(
    (data) =>
      data.serviceCategory === ServiceCategory &&
      data.serviceSubCategory === ServiceSubCategory
  );

  let RequiredDocuments = [];
  let DeliveredDocuments = [];
  let Category = [];
  let SubCategory = [];
  let Empty = [];

  if (TYPE === "Requirement" || TYPE === "Required") {
    RequiredDocuments =
      Group && Group[0] && Group[0].required ? Group[0].required : [];

    Category =
      RequiredDocuments &&
      RequiredDocuments?.map((item) => ({
        label: item.docCategory,
        value: item.docCategory,
      }));

    let temp =
      RequiredDocuments &&
      RequiredDocuments?.filter((item) => {
        if (item.docCategory === selectedCategory) {
          return item;
        }
      });

    SubCategory =
      temp &&
      temp[0] &&
      temp[0].docSubCategory?.map((item) => ({
        label: item,
        value: item,
      }));

    if (selectedCategory !== null) {
      return { Category, SubCategory };
    } else {
      return { Category, Empty };
    }
  } else if (TYPE === "Delivery") {
    DeliveredDocuments = Group && Group[0] && Group[0].delivered;
    Category = DeliveredDocuments?.map((item) => ({
      label: item.docCategory,
      value: item.docCategory,
    }));

    let temp = DeliveredDocuments?.filter((item) => {
      if (item.docCategory === selectedCategory) {
        return item;
      }
    });

    SubCategory =
      temp &&
      temp[0] &&
      temp[0].docSubCategory?.map((item) => ({
        label: item,
        value: item,
      }));

    if (selectedCategory !== null) {
      return { Category, SubCategory };
    } else {
      return { Category, Empty };
    }
  }
};
