import React, { useState, useEffect, useCallback, useContext } from "react";
import { UseRequest } from "utils/API/Request.js";
import styles from "./Financing.module.css";
import { BsBell } from "react-icons/bs";
import { CgChevronDown } from "react-icons/cg";
import { AiOutlineArrowRight } from "react-icons/ai";
import { CheckPermission } from "utils/Constants/RBAC";
import { useNavigate } from "react-router-dom";
import { financeURL, invoiceURL, profileURL } from "services/BaseURLInstance";
import LinkBuyerSeller from "./linkBuyerSeller";
import { ToastContainer } from "react-toastify";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import Select from "components/fields/Select";
import { Grid } from "@mui/material";
import { getIdTokenAsync } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { truncateStringID } from "utils/helper";
import {
  Success,
  Error,
  Info,
  Alert,
} from "components/notifications/responseHandle";
import Content from "components/UI/Layout/Content";
import { FinancingValdations } from "utils/Validations/Financing";
import { storeContext } from "contexts/Store/Store";
import { NetworkURL } from "services/BaseURLInstance";

import {
  loan_product_type,
  disbursement_mode_options,
} from "utils/Section/InvoiceSection";
import Loader from "components/Loader/Loader";
import { validateInput } from "./validateInputs";
import { FinanceContext } from "../Invoice/financeProvider";

const Financing = ({ invoiveData }) => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const {companyList} = useContext(FinanceContext);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const [open1, setOpen1] = useState("No");
  const [open2, setOpen2] = useState("No");
  const [open3, setOpen3] = useState("No");
  const [open4, setOpen4] = useState("No");
  const [open5, setOpen5] = useState("No");
  const [open6, setOpen6] = useState("No");
  const { instance, accounts } = useMsal();
  const [buyerDetails, setBuyerDetails] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [Role, setRole] = useState(null);
  const [linkBuyerRole, setLinkBuyerRole] = useState(null);
  const [financerequestRole, setFinanceRequestrole] = useState(null);
  const [data, setData] = useState({
    sellerMemberUID: "",
    totalAmountWithInstrument: invoiveData && invoiveData.txlInvoiceAmount,
    financeCurrency: invoiveData && invoiveData.txlCurrencyCode,
    buyerMemberUID: "",
    invoiceUID: "",
    financeProductType: "FINANCE_INVOICE",
    financerPreferences: "ALL",
    financeAmountRequested: "",
    interestRateMin: "",
    preferredPaymentDate: "",
    preferredPaymentMethod: "",
    financeRequsitionStartDate: "",
    financeRequsitionEndDate: "",
    financersSelected: [],
    isDeleted: false,
  });

  const payment_mode = [
    // { value: "ESCROW", label: "ESCROW" },
    // { value: "DIRECT", label: "DIRECT" },
    { value: "OFFLINE", label: "OFFLINE" },
  ];

  const editChangeHandle = () => {
    setOpen1("Yes");
    setOpen2("No");
    setBuyerDetails([]);
  };

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);
  useEffect(() => {
    if (Role) {
      setFinanceRequestrole(
        CheckPermission("Requisition", "Invoice", "RequestFinancing", Role)
      );
      setLinkBuyerRole(
        CheckPermission("Requisition", "Invoice", "LinkBuyer", Role)
      );
    }
  }, [Role]);

  console.log(Role, linkBuyerRole, financerequestRole, " setLinkBuyerRole");

  const handleRecipientSubmit = async (txlBuyerMemberId) => {
    setIsLoading(true);
    invoiveData.buyerConsent = "IN_PROCESS";
    invoiveData.txlBuyerMemberId = txlBuyerMemberId;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(invoiveData),
    };
    
    await fetch(
      `${invoiceURL}invoices/${invoiveData.txlInvoiceId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.code > 300) {
          setIsLoading(false);
          setOpen1("temp");
          setOpen3("Yes");
          Error("Buyer is not Added");
        } else {
          setBuyerDetails(data);
          setOpen3("Yes");
          Success("Buyer Added successfully...");
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setOpen1("temp");
        setOpen3("Yes");
        Error("Something went wrong try again....");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleApproveDecline = async (e, status) => {
    e.preventDefault();
    invoiveData.buyerConsent = status;
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(invoiveData),
    };
    await fetch(
      `${invoiceURL}invoices/${invoiveData.txlInvoiceId}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        setBuyerDetails(data);
        setOpen2("Yes");
        Success("Buyer Added successfully...");
      })
      .catch((err) => {
        Error("Something went wrong try again....");
      })
      .finally(() => {});
  };

  //   txlInvoiceId
  useEffect(() => {
    setData({
      ...data,
      sellerMemberUID: companyId,
      buyerMemberUID: invoiveData ? invoiveData.txlBuyerMemberId : "",
      invoiceUID: invoiveData ? invoiveData.txlInvoiceId : "",
      financeRequsitionStartDate: new Date().toISOString(),
      financeRequsitionEndDate: new Date().toISOString(),
      preferredPaymentDate: new Date().toISOString(),
    });
  }, [buyerDetails]);

  const handleChange = (event) => {
    console.log("AkashTestingEvent", event);
    if (event.target.name === "financeAmountRequested") {
      let requestFinanceAmountTemp = parseFloat(data.totalAmountWithInstrument);
      let financeAmountTemp = parseFloat(
        event.target.value === "" ? "0" : event.target.value
      );
      if (financeAmountTemp <= requestFinanceAmountTemp) {
        let val = event.target.value;
        if (val <= 0) {
          Error("Required Finance Amount can not be less or equal to 0");
          setData({
            ...data,
            [event.target.name]: "",
          });
        } else {
          setData({
            ...data,
            [event.target.name]: val,
          });
        }
      } else {
        Error("Required finance amount can't be greater then invoice amount");
      }
    }
    if (event.target.name === "interestRateMin") {
      let val = event.target.value;
      if (val <= 0) {
        Error("Interest rate should not be less or equal to 0");
        setData({
          ...data,
          [event.target.name]: "",
        });
      } else {
        setData({
          ...data,
          [event.target.name]: val,
        });
      }
    }
    if (
      event.target.name !== "financeAmountRequested" &&
      event.target.name !== "interestRateMin"
    ) {
      setData({
        ...data,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleSubmit = async (event) => {
    let valid = validateInput(data);
    if (valid) {
      Error(valid);
    } else {
      setIsLoading(true);
      event.preventDefault();
      data.buyerMemberUID = invoiveData.txlBuyerMemberId;
      let checkValidation = FinancingValdations(data);
      if (checkValidation) {
        Error(checkValidation);
        return;
      } else {
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
            "x-api-key":
              "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
          },
          body: JSON.stringify(data),
        };
        await fetch(`${financeURL}finance/requisitions`, requestOptions)
          .then((response) => response.json())
          .then((data) => {
            if (data.code >= 300) {
              Error(data.userMessage.Message);
              setIsLoading(false);
            } else {
              Success("Finance Requisition Added successfully...");
              setIsLoading(false);
              setOpen6("Yes");
              setTimeout(() => {
                navigate("../finance-deals");
              }, 1000);
            }
          })
          .catch((err) => {
            Error("Something went wrong try again....");
          });
      }
    }
  };

  useEffect(() => {
    if (invoiveData && invoiveData.txlBuyerMemberId) {
      setOpen3("Yes");
    }
  }, [invoiveData]);

  const handleProcced = () => {
    if (invoiveData.buyerConsent === "COMPLETED") {
      setOpen4("Yes");
    } else {
      Error("Please Connect with Buyer to complete the invoice consent");
      setOpen4("Yes"); // To be removed once buyer consent start working
    }
  };
  const handleChangeDate = (e, name) => {
    if (
      name == "financeRequsitionEndDate" ||
      name == "financeRequsitionStartDate" ||
      name == "preferredPaymentDate"
    ) {
      setData({
        ...data,
        [name]: new Date(e).toISOString(),
      });
    }
  };
  return (
    <div className={styles.container}>
      {invoiveData &&
        (invoiveData.buyerConsent === "NOT_STARTED" ||
          invoiveData.buyerConsent === "NOT_REQUESTED") &&
        open1 === "No" &&
        (invoiveData.txlBuyerMemberId === "" ||
          invoiveData.txlBuyerMemberId === null) && (
          <>
            {companyId === invoiveData.txlSellerMemberId ? (
              <>
                <div>
                  <div>
                    {" "}
                    <span
                      style={{
                        color: "rgba(51, 195, 12, 1)",
                        fontSize: "14px",
                      }}
                    >
                      Please check the invoice summary and details before linking the buyer. 
                      Once buyer is linked you won't be able to make any changes
                    </span>
                  </div>
                  <div>
                    <span
                      style={{
                        color: "rgba(51, 195, 12, 1)",
                        fontSize: "14px",
                      }}
                    >
                      Go ahead and link your buyer
                    </span>{" "}
                  </div>
                  <div className={styles.buttonDiv}>
                    <button
                      className={styles.activeBtn}
                      onClick={() =>
                        linkBuyerRole === "CREATE" ? setOpen1("Yes") : " "
                      }
                      style={{ fontSize: "14px" }}
                    >
                      Link Buyer
                    </button>
                    <button
                      onClick={() => handleNavigate("../invoices")}
                      className={styles.activeBtn}
                      style={{ fontSize: "14px" }}
                    >
                      Back to Invoices
                    </button>
                  </div>
                </div>
              </>
            ) : null}
          </>
        )}

      {invoiveData &&
        (invoiveData.buyerConsent === "NOT_STARTED" ||
          invoiveData.buyerConsent === "NOT_REQUESTED") &&
        buyerDetails === "" &&
        open1 === "Yes" &&
        open2 === "No" && (
          <div>
            <LinkBuyerSeller handleRecipientSubmit={handleRecipientSubmit} />
          </div>
        )}

      {invoiveData &&
        (invoiveData.buyerConsent === "NOT_STARTED" ||
          invoiveData.buyerConsent === "NOT_REQUESTED") &&
        buyerDetails !== "" &&
        open2 === "Yes" &&
        open3 === "No" && (
          <div className={styles.Buyer}>
            <div className={styles.toggleHeading}>
              <div style={{ fontSize: "14px" }}>Link Buyer</div>
              <div>
                <CgChevronDown size={28} />
              </div>
            </div>
            <hr />

            <div className={styles.buyerDetails}>
              <div>
                <div>Member Id</div>
                <div>Share Status</div>
              </div>

              {buyerDetails && buyerDetails.invoiceNumber && (
                <span>{buyerDetails.invoiceNumber}</span>
              )}
            </div>

            <div className={styles.buttonDiv}>
              <button
                onClick={() => setOpen3("Yes")}
                className={styles.activeBtn}
                style={{ fontSize: "14px" }}
              >
                Confirm Invoice
              </button>
              <button
                onClick={editChangeHandle}
                className={styles.btn}
                style={{ fontSize: "14px" }}
              >
                Edit / Change
              </button>
            </div>
          </div>
        )}

      {invoiveData &&
        invoiveData.buyerConsent !== "NOT_STARTED" &&
        invoiveData.txlStatus !== "IN_PURSUIT" &&
        companyId === invoiveData.txlSellerMemberId &&
        open3 === "Yes" &&
        open4 === "No" && (
          <div className={styles.div1}>
            <div>
              <span style={{ color: "rgba(51, 195, 12, 1)", fontSize: "14px" }}>
                Your Invoice has been sent for validation to buyer
              </span>{" "}
            </div>
            <div>
              <span style={{ color: "rgba(51, 195, 12, 1)", fontSize: "14px" }}>
                Go ahead and Proceed to Financing
              </span>{" "}
            </div>
            <div className={styles.buttonDiv}>
              <button
                onClick={() => (financerequestRole ? handleProcced() : " ")}
                className={styles.activeBtn}
                style={{ fontSize: "14px" }}
              >
                Proceed to Financing
              </button>
            </div>
          </div>
        )}

      {invoiveData &&
        invoiveData.txlStatus !== "IN_PURSUIT" &&
        open4 === "Yes" &&
        open5 === "No" && (
          <div className={styles.div2}>
            <div style={{ fontSize: "14px" }}>
              Financing Deals for Invoice ID: {invoiveData.invoiceNumber}
            </div>
            <div style={{ display: "flex", margin: "15px 0px" }}>
              <div style={{ marginRight: "10px" }}>
                <BsBell size={30} color="#42A5F5" />
              </div>
              <div style={{ fontSize: "14px" }}>
                You haven't initiated the funding request on this invoice
              </div>
            </div>
            <div className={styles.buttonDiv}>
              <button
                onClick={() => (financerequestRole ? setOpen5("Yes") : " ")}
                className={styles.activeBtn}
                style={{ fontSize: "14px" }}
              >
                Create Financing Request
              </button>
            </div>
          </div>
        )}

      {/* // Form  */}
      {isLoading ? (
        <Loader />
      ) : (
        invoiveData &&
        invoiveData.txlStatus !== "IN_PURSUIT" &&
        open5 === "Yes" &&
        open6 === "No" && (
          <div className={styles.FinancingForm}>
            <div className={styles.toggleHeading}>
              <div className={styles.radioHeading} style={{ fontSize: "14px" }}>
                Financing Request
              </div>
              <div>
                <CgChevronDown size={28} />
              </div>
            </div>
            <hr />
            <form>
                 <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
                 <div style={{width:"49%", marginRight:"2%"}}>
                  <Input
                    name="invoiceAmount"
                    placeholder="Invoice Amount"
                    value={data.totalAmountWithInstrument}
                    readOnly={true}
                    type="number"
                    required={true}
                  />
                  </div>
                  <div style={{width:"49%"}}>
                  <Input
                    name="currency"
                    placeholder="Invoice Currency"
                    value={data.financeCurrency}
                    readOnly={true}
                    required={true}
                  />
                </div>
                </div>

                <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
                 <div style={{width:"49%", marginRight:"2%"}}>
                  {companyList.map((item) => {
                    if (item.memberUID === data.sellerMemberUID) {
                      return (
                        <Input
                          name="sellerMemberUID"
                          placeholder="Seller ID"
                          value={item.memberName}
                          handleChange={handleChange}
                          readOnly={true}
                          required={true}
                        />
                      );
                    }
                  })}
                 </div>
                  <div style={{width:"49%"}}>
                  <Select
                    options={loan_product_type}
                    name="financeProductType"
                    placeholder="Loan Product Type"
                    handleChange={handleChange}
                    value={data.financeProductType}
                    required={true}
                    readOnly={true}
                  />
                </div>
                </div>

                <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
                 <div style={{width:"49%", marginRight:"2%"}}>
                  <Input
                    name="invoiceUID"
                    placeholder="Invoice Reference"
                    value={
                      data.invoiceUID
                        ? truncateStringID(data.invoiceUID, 7, "IR")
                        : "NA"
                    }
                    handleChange={handleChange}
                    readOnly={true}
                    required={true}
                  />
                 </div>
                  <div style={{width:"49%"}}>
                  <Input
                    name="financeAmountRequested"
                    placeholder="Required Finance Amount"
                    value={data.financeAmountRequested}
                    handleChange={handleChange}
                    type="number"
                    required={true}
                  />
                </div>
                </div>

                 <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
                 <div style={{width:"49%", marginRight:"2%"}}>
                  <Input
                    name="interestRateMin"
                    placeholder="% Interest Rate Per Annum"
                    value={data.interestRateMin}
                    handleChange={handleChange}
                    type="number"
                    required={true}
                  />
                 </div>
                  <div style={{width:"49%"}}>
                <Select
                  options={payment_mode}
                  name="modeOfPayment"
                  placeholder="Payment Mode"
                  handleChange={handleChange}
                  value={data?.modeOfPayment}
                  required={true}
                />
                </div>
                </div>

                <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
                 <div style={{width:"49%", marginRight:"2%"}}>
                  <DateInput
                    name="preferredPaymentDate"
                    placeholder="Disbursement Date *"
                    value={data.preferredPaymentDate}
                    handleChange={(e) =>
                      handleChangeDate(e, "preferredPaymentDate")
                    }
                    required={true}
                  />
                 </div>
                  <div style={{width:"49%"}}>
                  <Select
                    options={disbursement_mode_options}
                    name="preferredPaymentMethod"
                    placeholder="Disbursement Mode"
                    handleChange={handleChange}
                    value={data.preferredPaymentMethod}
                    required={true}
                  />
                </div>
                </div>

                 <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
                 <div style={{width:"49%", marginRight:"2%"}}>
                  <DateInput
                    placeholder="Financing Start Date *"
                    value={data.financeRequsitionStartDate}
                    handleChange={(e) =>
                      handleChangeDate(e, "financeRequsitionStartDate")
                    }
                    required={true}
                  />
                 </div>
                  <div style={{width:"49%"}}>
                  <DateInput
                    placeholder="Financing End Date *"
                    value={data.financeRequsitionEndDate}
                    handleChange={(e) =>
                      handleChangeDate(e, "financeRequsitionEndDate")
                    }
                    required={true}
                  />
                </div>
                </div>
              <div className={styles.inputRadio} onChange={handleChange}>
                <div
                  className={styles.radioHeading}
                  style={{ fontSize: "14px" }}
                >
                  Select Financier
                </div>
                <div
                  className={styles.radioInput}
                  style={{ color: "gray", fontSize: "14px" }}
                >
                  <input
                    type="radio"
                    value="recommended"
                    name="financersSelected"
                  />
                  <span>Recommended</span>{" "}
                </div>
                <div
                  className={styles.radioInput}
                  style={{ color: "#42a5f5", fontSize: "14px" }}
                >
                  <input
                    type="radio"
                    value="publish"
                    name="financersSelected"
                    checked="checked"
                  />
                  <span>Publish to all</span>{" "}
                </div>
                <div
                  className={styles.radioInput}
                  style={{ color: "gray", fontSize: "14px" }}
                >
                  <input
                    type="radio"
                    value="favorites"
                    name="financersSelected"
                  />
                  <span>Select Favorites</span>{" "}
                </div>
              </div>
              <div></div>
              <ButtonDiv>
                <Button className={styles.submitBtn} onClick={handleSubmit}>
                  Submit
                </Button>
              </ButtonDiv>
            </form>
          </div>
        )
      )}

      {invoiveData &&
        invoiveData.txlStatus !== "IN_PURSUIT" &&
        open6 === "Yes" && (
          <div className={styles.submited} style={{ fontSize: "14px" }}>
            Your Invoice has been published as per your funding perferences
          </div>
        )}

      {invoiveData && invoiveData.txlStatus === "IN_PURSUIT" && (
        <div className={styles.financeBtn}>
          <button onClick={() => handleNavigate("./")}>
            <div style={{ fontSize: "14px" }}>Proceed to Finance Deals</div>
            <div>
              <AiOutlineArrowRight size={20} />
            </div>
          </button>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Financing;
