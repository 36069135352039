import React, { useEffect, useState } from "react";
import LoanTable from "./LoanTable";
import { loanApi } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import Content from "components/UI/Layout/Content";
import { useMsal } from "@azure/msal-react";
import { CompanyList } from "utils/API/CompanyList.js";
import { getIdTokenAsync } from "utils/authUtil";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { UseRequest } from "utils/API/Request.js";

const Index = () => {
  const { instance, accounts } = useMsal();
  const CompanyObject = CompanyList();

  let financerMemberUID = "";
  let traderMemberId = "";

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const [LoanStatus, setLoanStatus] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (memberType === "TRADER") {
        traderMemberId = companyId;
      } else {
        financerMemberUID = companyId;
      }
      setIsLoading(true);
      let URL = `${loanApi}loans?financerMemberId=${financerMemberUID}&traderMemberId=${traderMemberId}`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setResponse(data);

            // STATUS
            const uniqueStatus = [
              ...new Set(data.map((item) => item.loanStatus)),
            ];
            let filterStatus =
              uniqueStatus &&
              uniqueStatus.map((item) => {
                return { label: item, value: item };
              });
            setLoanStatus(filterStatus);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (response) {
      response.sort(
        (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
      );
    }
  }, [response]);

  return (
    <>
      {isLoading || CompanyObject.isLoading ? (
        <Content>
          <span>Loading....</span>
          <Loader />
        </Content>
      ) : (
        <>
          {response && CompanyObject.response && (
            <div>
              <LoanTable
                response={response}
                companies={CompanyObject.response}
                LoanStatus={LoanStatus}
              />
            </div>
          )}
          {isError && <span>Failed to load</span>}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Index;
