import React from "react";
import { Badge, Checkbox } from '@mui/material';
import { GoCalendar } from "react-icons/go";

export const FinanceColumn = (editTranch, removeData, companyId, financerMemberUID, handleAccept, handleReject) => [
    {
        Header: "Bank",
        accessor: "financerName",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "transparent",
                        border: "none",

                        color: "#fff",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px -10px"
                    }}
                    type="button"
                >
                    {cell.value}
                </button>
            </div>
        ),

    },
    {
        Header: "Borrower",
        accessor: "borrower",
    },

    
    {
        Header: "Asset Ratio",
        accessor: "assetRatio"
    },
    {
        Header: "Amount",
        accessor: "trancheFinanceAmount"
    },
    {
        Header: "Updated Date",
        accessor: "lastUpdatedDate",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <>
                    <span>{new Date(cell.value).toLocaleDateString()}</span>
                    <GoCalendar size={25} />
                </>
            </div>

        ),
    },
    {
        Header: "Status",
        accessor: "loanStatus",
    },
    {
        Header: "Action",
        Cell: (cell) => (
           
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {(cell?.row?.original?.loanStatus==="DRAFT") && (companyId === financerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => editTranch()}
                >
                    Modify
                </button>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => removeData(cell.row.index, cell.row)}
                >
                    Remove
                </button>
                </>}
                {(cell?.row?.original?.loanStatus==="IN_REVIEW") && (companyId === cell?.row?.original?.syndicateFinancerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => handleAccept("APPROVED",cell.row.index, cell?.row)}
                >
                    APPROVE
                </button>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => handleReject("DECLINED",cell.row.index, cell.row)}
                >
                    DECLINE
                </button>
                </>}

                {(cell?.row?.original?.loanStatus==="APPROVED") && (companyId === financerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => handleReject("INACTIVE", cell.row.index, cell.row)}
                >
                    ABORT
                </button>
                
                </>}

                {(cell?.row?.original?.loanStatus==="DECLINED") && (companyId === financerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() =>{
                        handleReject("IN_REVIEW", cell.row.index, cell.row)
                        editTranch()
                    }}
                >
                    Modify
                </button>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() =>{
                        handleReject("IN_REVIEW", cell.row.index, cell.row)
                    }}
                >
                    Send
                </button>
                </>}
            </div>
        ),
    },
];

export const DrawDownColumn = (editDrawdown, removeDrowdown, companyId, financerMemberUID, handleAccept, handleReject) => [
   
    {
        Header: "Bank",
        accessor: "Fianciers",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <button
                    className="buttonIcon"
                    type="button"
                    style={{
                        backgroundColor: "transparent",
                        border: "none",
                        color: "#fff",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                >
                    {cell.value?.label}
                </button>
            </div>
        ),

    },
    {
        Header: "Borrower",
        accessor: "borrower",
    },
    {
        Header: "Amount",
        accessor: "trancheComponentDrawdownAmount"
    },
    {
        Header: "Start Date",
        accessor: "trancheComponentDrawdownStartDate",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <>
                    <span>{new Date(cell.value).toLocaleDateString()}</span>
                    <GoCalendar size={25} />
                </>
            </div>

        ),
    },
    {
        Header: "End Date",
        accessor: "trancheComponentDrawdownEndDate",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <>
                    <span>{new Date(cell.value).toLocaleDateString()}</span>
                    <GoCalendar size={25} />
                </>
            </div>

        ),
    },
    {
        Header: "Updated Date",
        accessor: "lastUpdatedDate",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <>
                    <span>{new Date(cell.value).toLocaleDateString()}</span>
                    <GoCalendar size={25} />
                </>
            </div>

        ),
    },
    {
        Header: "Status",
        accessor: "trancheComponentDrawdownStatus",
    },
    {
        Header: "Action",
        accessor: "bank",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {(cell?.row?.original?.trancheComponentDrawdownStatus==="DRAFT" || cell?.row?.original?.trancheComponentDrawdownStatus===undefined) && (companyId === financerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => editDrawdown()}
                >
                    Modify
                </button>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => removeDrowdown(cell.row.index, cell.row)}
                >
                    Remove
                </button>
                </>}
                {(cell?.row?.original?.trancheComponentDrawdownStatus==="IN_REVIEW") && (companyId === cell?.row?.original?.syndicateFinancerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => handleAccept("APPROVED", cell.row.index, cell?.row)}
                >
                    APPROVE
                </button>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => handleReject("DECLINED", cell.row.index, cell.row)}
                >
                    DECLINE
                </button>
                </>}


                {(cell?.row?.original?.trancheComponentDrawdownStatus==="APPROVED") && (companyId === financerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() => handleReject("INACTIVE", cell.row.index, cell.row)}
                >
                    ABORT 
                </button>
                
                </>}



                {(cell?.row?.original?.trancheComponentDrawdownStatus==="DECLINED") && (companyId === financerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() =>{
                        handleReject("IN_REVIEW", cell.row.index, cell.row)
                        editDrawdown()
                    }}
                >
                    Modify
                </button>
               
                </>}
                {(cell?.row?.original?.trancheComponentDrawdownStatus==="DECLINED") && (companyId === financerMemberUID) &&
              <>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() =>{
                        editDrawdown()
                    }}
                >
                    Modify
                </button>
                <button
                    className="buttonIcon"
                    style={{
                        backgroundColor: "#42A5F5",
                        border: "none",
                        color: "black",
                        fontSize: "17px",
                        padding: "9px 12px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        margin: "5px 20px 5px 10px"

                    }}
                    type="button"
                    onClick={() =>{
                        handleReject("IN_REVIEW", cell.row.index, cell.row)
                    }}
                >
                    Send
                </button>
                </>}
                
            </div>
        ),
    },

]

export const DDColumn = [
    {
        Header: "Bank or Company",
        accessor: "bank",
    },

    {
        Header: "Reference",
        accessor: "reference",
    },
    {
        Header: "Amount",
        accessor: "amount",
    },
    {
        Header: "Disbursement Date",
        accessor: "dis_date",
    },
    {
        Header: "Overdue by",
        accessor: "overdue",
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {cell.value.map(f => {
                    return (
                        <>
                            <button
                            type="button"
                                className="buttonIcon"
                                style={{
                                    backgroundColor: "#42A5F5",
                                    border: "none",
                                    color: "black",
                                    fontSize: "17px",
                                    padding: "9px 12px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    margin: "5px 20px 5px -10px"

                                }}
                                onClick={() => console.log(cell)}
                            >
                                {f}
                            </button>
                        </>
                    )
                })
                }
            </div>

        ),
    },
]
