import React from "react";
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import styles from './syndicate.module.css'
export const StepperOne = (props) => {
  // console.log("OVER", props)
  // const {idx} = props?.data;
  const { trancheFinanceAmount, interestRate, loanFinanceStartDate, loanFinanceEndDate } = props?.data?.data || props?.apiData
  //const { trancheFinanceAmount, interestRate, loanFinanceStartDate, loanFinanceEndDate } =props?.data?.data?.loanTranches[idx]
  const handleChange = props.data?.handleChange || props.handleChange

  return (
    <>
      <br></br>
     
      <h4 style={{ margin: "0px", color:"#fff", textAlign:"left" }}>Tranche Overview</h4>
      <br></br>
      <Grid container>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            name="trancheFinanceAmount"
            placeholder="Required Finance Amount"
            required={true}
            value={trancheFinanceAmount}
            //handleChange={handleChange}
            handleChange={(event) => handleChange(event, "trancheFinanceAmount")}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
          <Input
            name="interestRate"
            placeholder="Interest Rate"
            required={true}
            value={interestRate}
           // handleChange={handleChange}

            handleChange={(event) => handleChange(event, "interestRate")}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
          <DateInput
            name="loanFinanceStartDate"
            placeholder="Finance Start Date"
            value={loanFinanceStartDate}
            handleChange={(event) => handleChange(event, "loanFinanceStartDate")}
          />
        </Grid>
        <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
          <DateInput
            name="loanFinanceEndDate"
            placeholder="Finance End Date"
            value={loanFinanceEndDate}
            handleChange={(event) => handleChange(event, "loanFinanceEndDate")}
          />
        </Grid>
      </Grid>
   
    </>
  );
};
