import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoBig from "./image/Picture1.png";
import logoSmall from "./image/Picture2.png";
import LogoLargeWhite from "./image/LogoLargeWhite.jpeg";
import LogoSmallBlue from "./image/LogoSmallBlue.jpeg";
import { FaHireAHelper, FaNetworkWired } from "react-icons/fa";
import { MdOutlineNotificationsNone } from "react-icons/md";
import { CgMenuRight } from "react-icons/cg";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { RiWechatLine } from "react-icons/ri";
import { BsFillPersonFill } from "react-icons/bs";
import { FiLogOut, FiLogIn } from "react-icons/fi";
import { HiUsers, HiUserAdd, HiChartBar } from "react-icons/hi";
import { AuthContext } from "../../contexts/Auth/AuthContext";
import {
  NetworkURL,
  TRADXLINK_SUPPORT_ID,
  documentURL,
} from "services/BaseURLInstance";
import { getIdTokenAsync } from "utils/authUtil";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { loginRequest } from "../../authConfig";
import styles from "./header.module.css";

import { UseRequest } from "utils/API/Request.js";
import { NodeName, FindCompanyName, ImageURLSrc } from "utils/helper.js";
import { Help } from "@material-ui/icons";
import { fetchMemberdata } from "utils/memberchat";
import { CompanyList } from "utils/API/CompanyList.js";

function Header({ toggle, isOpen, toggleChat, hanndleCraateGroup }) {
  const { instance, accounts } = useMsal();

  const {
    Token,
    companyId,
    personName,
    companyName,
    isAuthenticated,
    loginCompanyData,
    setLoginCompanyData,
  } = useContext(AuthContext);

  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const [companyData, setCompanyData] = useState(null);
  const [documentData, setDocumentData] = useState(null);

  const navigate = useNavigate();
  const [drop, setDrop] = useState(false);
  const showDropdown = (e) => {
    setDrop(true);
  };
  const hideDropdown = (e) => {
    setDrop(false);
  };
  const CompanyObject = CompanyList();
  const list = CompanyObject && CompanyObject.response;

  const handleClick = (navigate_route) => {
    // if (window.location.pathname === navigate_route) {
    //   window.history.back();
    // } else {
    //   navigate(navigate_route);
    // }
    navigate(navigate_route);
    toggleChat("header");
  };

  const handleChat = async () => {
    let memberUserId = [companyId, "e32c28f1-e78d-4e6e-847d-08f5af2627fa"];
    let res = await fetchMemberdata(memberUserId);
    let name1 = FindCompanyName(list, companyId).split(" ")[0];
    let name2 = "TradxSupport";
    let name = name1 + "-" + name2;
    if (res?.length > 0) {
      hanndleCraateGroup(res, name, name);
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && companyId) {
      if (memberType === "FINANCER") {
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(`${NetworkURL}financers/${companyId}`).then((data) => {
            let { message, error } = data;
            if (message || error) {
            } else {
              setCompanyData(data);
              let id = data.companyLogoURL || data.financerLogo;
              if (data.companyLogoURL || data.financerLogo) {
                getIdTokenAsync(instance, accounts).then((response) => {
                  UseRequest(
                    `${documentURL}documents/${id}`,
                    null,
                    response
                  ).then((res) => {
                    let { message, path } = res;
                    if (message || path) {
                      // Error(message);
                    } else {
                      setDocumentData(res.id);
                    }
                  });
                });
              }
            }
          });
        });
      } else if (memberType === "COMPANY") {
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(
            `${NetworkURL}companies/${companyId}`,
            null,
            response
          ).then((data) => {
            let { message, error } = data;
            if (message || error) {
            } else {
              setCompanyData(data);
              let id = data.companyLogoURL || data.financerLogo;
              if (data.companyLogoURL || data.financerLogo) {
                getIdTokenAsync(instance, accounts).then((response) => {
                  UseRequest(
                    `${documentURL}documents/${id}`,
                    null,
                    response
                  ).then((res) => {
                    let { message, path } = res;
                    if (message || path) {
                      // Error(message);
                    } else {
                      setDocumentData(res.id);
                    }
                  });
                });
              }
            }
          });
        });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [companyId]);

  return (
    <div className={styles.Header}>
      <div className={styles.LogoDiv}>
        <div className={styles.Logo}>
          {isOpen ? (
            <img className={styles.Logo1} src={LogoLargeWhite} />
          ) : (
            <img className={styles.Logo2} src={LogoSmallBlue} />
          )}
        </div>

        <div className={styles.bars}>
          <CgMenuRight onClick={toggle} />
        </div>
      </div>

      <div className={styles.Node}>
        {companyData && companyName ? (
          <span>
            Welcome to {companyName} on TradxLink{" "}
            {NodeName(
              companyData &&
                companyData.nodeNetworkName &&
                companyData.nodeNetworkName
            )}
          </span>
        ) : (
          <></>
        )}
      </div>

      <div className={styles.navContainer}>
        <>
          <div onClick={handleChat} className={styles.inviteStyle2}>
            <Help size={20} />
            Support
          </div>
          <div
            onClick={() => handleClick("/invite")}
            className={styles.inviteStyle}
          >
            <HiUserAdd size={20} />
            Invite Member
          </div>
          <RiWechatLine
            style={{ cursor: "pointer" }}
            onClick={() => toggleChat("global")}
            size={24}
          />

          <div className="chatremove">
            <IoIosArrowDropleftCircle onClick={() => toggleChat()} size={40} />
          </div>

          {/* <MdOutlineNotificationsNone
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleClick("/alert");
            }}
            size={24}
          /> */}
          <FaNetworkWired
            style={{ cursor: "pointer" }}
            onClick={() => handleClick("/networks")}
            size={24}
          />
        </>

        <span
          className={styles.FaUserCircle}
          onMouseEnter={showDropdown}
          onMouseLeave={hideDropdown}
        >
          <img
            src={ImageURLSrc(documentData)}
            alt="Logo"
            width="30"
            height="30"
          />
        </span>
      </div>

      {drop === true && (
        <div
          className={styles.dropdown}
          onMouseEnter={showDropdown}
          onMouseLeave={hideDropdown}
        >
          <div className={styles.firstDiv}>
            <div className={styles.firstDiv1}>
              <img
                src={ImageURLSrc(documentData)}
                alt="Logo"
                width="40"
                height="40"
              />
            </div>
            <div className={styles.firstDiv2}>
              <div>
                {personName !== null ? personName : "Please login first"}
              </div>
              <div className={styles.opacity}>
                {companyName !== null ? companyName : "www.unknown.com"}
              </div>
            </div>
          </div>

          <div
            className={styles.secondDiv}
            onClick={() => handleClick("/profile")}
          >
            <div>
              <BsFillPersonFill size={20} />
            </div>
            <div>My profile</div>
          </div>
          <div
            onClick={() => handleClick("/profile/user-management")}
            className={styles.secondDiv}
          >
            <div>
              <HiUsers size={20} />
            </div>
            <div>Manage users</div>
          </div>
          <AuthenticatedTemplate>
            <div
              className={styles.secondDiv}
              onClick={() => {
                instance.logoutRedirect({ postLogoutRedirectUri: "/" });
                setDrop(!drop);
              }}
            >
              <div>
                <FiLogOut size={20} />
              </div>
              <div>Logout</div>
            </div>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <div
              className={styles.secondDiv}
              onClick={() => {
                instance.loginRedirect(loginRequest);
                setDrop(!drop);
              }}
            >
              <div>
                <FiLogIn size={20} />
              </div>
              <div>Login</div>
            </div>
          </UnauthenticatedTemplate>
        </div>
      )}
    </div>
  );
}

export default Header;
