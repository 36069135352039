import styled from "styled-components";

const Wrapper = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 0rem;
  background: #1b1b1b;
  border-top: 1px solid #666;
  font-weight: 600;
  font-size: 17px;
  color: #fff;
  section {
    margin-left: 60px;
  }
  a {
    color: #fff;
    margin: 0px 10px;
    text-decoration: none;
  }
`;
export default Wrapper;
