import React from 'react'
import styles from '../../invoice.module.css';

const ExportDetails = ({data}) => {
    const Response = [
        {
            name: 'Shipping Bill',
            value: data ? data.ShipBno : '',
        },
        {
            name: 'Date',
            value: data ? data.ShipBDt : '',
        },
        {
            name: 'Port',
            value: data ? data.Port : '',
        },
        {
            name: 'Supplier Refund Status',
            value: data ? data.RefClm : '',
        },
        {
            name: 'Additional Currency Code',
            value: data ? data.ForCur : '',
        },
        {
            name: 'Country of Export',
            value: data ? data.CntCode : '',
        },
        {
            name: 'Export Duty',
            value: data ? data.ExpDuty : '',
        },
    ]
    return (
        <div className={styles.summary}>
            { Response && Response.map((data, i) => (
            <div className={styles.summaryWrapper}key={i}>
                <div> <span> {data.name} </span> </div>
                <div> <span style={addBlue}> {data.value} </span> </div>
            </div> 
            ))}
        </div>
    )
    }

export default ExportDetails;

const addBlue = {
    color: "#42A5F5"
}

