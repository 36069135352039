import React, { useCallback, useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { HiArrowNarrowLeft } from "react-icons/hi";
import Summary from "./Summary";
import Details from "./Details";
import Deals from "./Deals";

import ToogleTab from "components/UI/ToogleSelect/ToogleTab";
import Content from "components/UI/Layout/Content";
import Financing from "./Financing";
import Loader from "components/Loader/Loader";
import { invoiceURL, NetworkURL } from "services/BaseURLInstance";
import { BsBell } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { AiFillEye } from "react-icons/ai";
import { UseRequest } from "utils/API/Request.js";
import { getIdTokenAsync } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { getClaim } from "utils/authUtil";
import { useContext } from "react";
import { storeContext } from "contexts/Store/Store";
// import Error from "components/Error/error";
import DetailUpdate from "../Invoice/Invoices/InvoiceDetailsForm/SuccessInvoice/details";
import { EditSummary } from "./editSummary";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { FinanceContext } from "../Invoice/financeProvider";

const InvoiceDetails = ({ hanndleCraateGroup }) => {
  const {companyList} = useContext(FinanceContext);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const handleNavigate = useCallback((url) => navigate(url), []);
  const [invoiveData, setInvoiceData] = useState(null);
  const [selected, setSelected] = useState(location.state?.selected ? location.state?.selected : 0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  let companyData = {}
  const [detailUpdateCheck, setDetailUpdateCheck] = useState(location.state?.detailEdit ? true : false);
  const [editSummaryCheck, setEditSummaryCheck] = useState(false);

  useEffect(() => {
    if(companyList){
        let user = companyList.filter((item) => {
          if (item.memberUID === companyId) {
            return item.memberType;
          }
        });
        companyData= {
          companyList: companyList,
          companyType: user[0].memberType,
        };
      }
  }, [companyList]);

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    let URL = `${invoiceURL}invoices/${params.id}`;
    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL)
          .then((data) => {
            setIsLoading(false);
            setInvoiceData(data);
          })
          .catch((error) => {
            setIsError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const toggle = (i) => {
    setSelected(i);
  };

  const TabData = [
    editSummaryCheck
      ? {
          title:
            invoiveData?.txlBuyerMemberId === companyId
              ? "Summary"
              : "Summary *",
          description: (
            <EditSummary
              setInvoiceData={setInvoiceData}
              data={invoiveData && invoiveData}
              setEditSummaryCheck={setEditSummaryCheck}
              editSummaryCheck={editSummaryCheck}
            />
          ),
        }
      : {
          // title: invoiveData?.txlSellerMemberId === companyId ? "Summary*" : "Summary",
          title:
            invoiveData?.txlBuyerMemberId === companyId
              ? "Summary"
              : "Summary *",
          description: <Summary data={invoiveData && invoiveData} />,
        },
    detailUpdateCheck
      ? {
          title:
            invoiveData?.txlBuyerMemberId === companyId
              ? "Details (Optional)"
              : "Details (Optional)*",
          description: (
            <DetailUpdate
              setInvoiceData={setInvoiceData}
              setDetailUpdateCheck={setDetailUpdateCheck}
              detailUpdateCheck={detailUpdateCheck}
            />
          ),
        }
      : {
          // title: "Details (Optional)*",
          title:
            invoiveData?.txlBuyerMemberId === companyId
              ? "Details (Optional)"
              : "Details (Optional)*",
          description: (
            <Details
              hanndleCraateGroup={hanndleCraateGroup}
              Data={invoiveData && invoiveData}
              companyId={companyId}
            />
          ),
        },
    {
      title: "Deals",
      description:
        invoiveData?.txlBuyerMemberId === companyId ? (
          ""
        ) : (
          <Deals id={params.id} />
        ),
    },
  ];

  const handleDocumentPreview = (id) => {
    if (id === undefined) {
      alert("Not available");
    } else {
      handleNavigate(`/documents/preview/${id}`);
    }
  };

  const handleBuyerConsent = () => {
    setIsLoading(true);
    let URL = `${invoiceURL}invoices/${params.id}`;
    let tempInvoiceData = { ...invoiveData };
    tempInvoiceData.buyerConsent = "COMPLETED";
    let options = {
      method: "PUT",
      body: tempInvoiceData,
    };
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options)
        .then((data) => {
          if (data.error) {
            setIsLoading(false);
            Error("Buyer Consent Adding Fail");
            alert("API is failing");
          } else {
            setIsLoading(false);
            Success("Buyer Consent Approved");
            setInvoiceData(data);
          }
        })
        .catch((error) => {
          Error("Buyer Consent Adding Fail");
          setIsError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <>
      <>
        <button
          onClick={() => handleNavigate(`/financing/invoice/invoices`)}
          style={{
            display: "flex",
            letterSpacing: "1px",
            color: "#42A5F5",
            background: "transparent",
            border: "none",
            margin: "1%",
          }}>
          <div style={{ marginTop: "-3px" }}>
            <HiArrowNarrowLeft size={20} />
          </div>
          <div style={{ fontSize: "14px" }}>Back to List </div>
        </button>

        {isLoading ? (
          <Content>
            <Loader />
          </Content>
        ) : (
          <>
            {invoiveData && !isError && (
              <>
                <div style={{ marginBottom: "1%" }}>
                  <Content>
                    <div className="d-flex justify-content-between">
                      <span style={{ color: "#42A5F5", fontSize: "14px" }}>
                        Invoice ID : {invoiveData.invoiceNumber}
                      </span>
                      <span style={{ color: "#42A5F5" }}>
                        <span
                          onClick={() =>
                            handleDocumentPreview(invoiveData.document.id)
                          }>
                          <AiFillEye size={26} />
                        </span>
                        <HiOutlineMail size={26} className="mx-4" />
                        <BsBell size={26} />
                      </span>
                    </div>
                  </Content>
                </div>
                <ToogleTab
                  selected={selected}
                  toggle={toggle}
                  TabData={TabData}
                  invoiveData={invoiveData}
                  companyId={companyId}
                  detailUpdateCheck={detailUpdateCheck}
                  setDetailUpdateCheck={setDetailUpdateCheck}
                  setEditSummaryCheck={setEditSummaryCheck}
                  editSummaryCheck={editSummaryCheck}
                />
                {companyData && companyData.companyType !== "FINANCER" ? (
                  invoiveData?.txlCurrencyCode !== "" &&
                  invoiveData?.txlCurrencyCode !== null &&
                  invoiveData?.txlCurrencyCode &&
                  invoiveData?.invoiceNumber !== "" &&
                  invoiveData?.invoiceNumber !== null &&
                  invoiveData?.invoiceNumber &&
                  invoiveData?.txlInvoiceAmount !== "" &&
                  invoiveData?.txlInvoiceAmount !== null &&
                  invoiveData?.txlInvoiceAmount !== 0 ? (
                    <Financing invoiveData={invoiveData} />
                  ) : (
                    <div style={{ color: "#33C30C" }}>
                      Please enter the mandatory fields
                    </div>
                  )
                ) : (
                  ""
                )}
                {companyId === invoiveData.txlBuyerMemberId &&
                invoiveData.buyerConsent === "IN_PROCESS" ? (
                  <div style={{ display: "flex", marginTop: "5%" }}>
                    <div
                      style={{
                        color: "rgba(51, 195, 12, 1)",
                        fontSize: "14px",
                        width: "50%",
                      }}>
                      Please provide your consent
                    </div>
                    <div style={{ width: "50%", textAlign: "right" }}>
                      <button
                        onClick={handleBuyerConsent}
                        style={{
                          backgroundColor: "#42a5f5",
                          fontSize: "14px",
                          color: "white",
                          border: "none",
                          padding: "1%",
                          borderRadius: "5px",
                        }}>
                        Complete the Consent
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
            {isError && <span>Failed to Load</span>}
          </>
        )}
      </>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default InvoiceDetails;
