import React, { useEffect, useState, useContext } from "react";
import { BsCircleFill } from "react-icons/bs";
import Style from "./styled";

import { IoIosArrowDown } from "react-icons/io";
import ProgressBar from "@ramonak/react-progress-bar";
import { ButtonStyled } from "../../components/Container.styled";
import { misURL } from "../../services/BaseURLInstance";
import { storeContext } from "../../contexts/Store/Store";
import { useMsal } from "@azure/msal-react";
import { getClaim } from "utils/authUtil";
import { Pie } from "react-chartjs-2";
import Select from "../../components/fields/Select";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    title: {
      display: false,
      text: "Chart.js Bar Chart - Stacked",
    },
    legend: {
      display: true,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    xAxes: [{ display: false }],
    display: false,
    y: {
      grid: {
        color: "grey",
      },
      ticks: {
        precision: 0,
      },
      title: {
        font: {
          size: 15,
        },
        color: "white",
        display: true,
        text: "Number Of Invoices",
      },
    },
    x: {
      grid: {
        color: "grey",
      },
      title: {
        font: {
          size: 15,
        },
        width: "300px",
        color: "white",
        display: true,
        text: "Timeline",
      },
    },
    // yAxes: [

    //     gridLines: {
    //       drawBorder: false,
    //     },
    //   },
    // ],
    xAxes: [
      {
        ticks: {
          precision: 0,
        },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};
const option2 = {
  indexAxis: "y",
  //   interaction: {
  //     mode: "nearest",
  //     axis: "x",
  //     intersect: false,
  //   },

  plugins: {
    title: {
      display: false,
    },

    tooltip: {
      enabled: true,
    },
    legend: {
      display: true,
    },
  },
  responsive: true,
  interaction: {
    mode: "index",
    intersect: false,
  },
  scales: {
    // xAxes: [{
    //     barThickness: 1,
    //     maxBarThickness: 1,
    // }]
    // xAxes: [{
    //     barPercentage: 0.1,
    //     display: false,
    // }],
    x: {
      display: false,
    },

    y: {
      display: false,
    },
  },
};
const labels = [
  "Jan",

  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default () => {
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const { fetchCompanyList } = useContext(storeContext);
  const { fetchMemberData } = useContext(storeContext);
  const [percentage, setPercentage] = useState();
  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const { getData } = useContext(storeContext);
  const [years, setYears] = useState([
    { label: new Date().getFullYear(), value: new Date().getFullYear() },
  ]);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [barData, setBarData] = useState();
  const [stackedBarData, setStackedBarData] = useState();
  const [response, setResponse] = useState();

  const [monthlyData, setMonthlyData] = useState({
    1: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    2: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    3: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    4: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    5: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    6: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    7: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    8: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    9: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    10: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    11: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
    12: {
      newInvoices: 0,
      inProgressInvoices: 0,
      financedInvoices: 0,
      rejectedInvoices: 0,
      closedInvoices: 0,
    },
  });
  let data = {
    labels,
    datasets: [
      {
        label: "New Invoices",
        data: [],
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "In Progress Invoices",
        data: [],
        backgroundColor: "rgb(75, 192, 192)",
      },
      {
        label: "Financed Invoices",
        data: [],
        backgroundColor: "rgb(255, 245, 222)",
      },
      {
        label: "Rejected Invoices",
        data: [],
        backgroundColor: "rgb(75, 102, 156)",
      },
      {
        label: "Closed Invoices",
        data: [],
        backgroundColor: "rgb(255, 234, 111 )",
      },
    ],
  };
  let stackedData = {
    labels: [""],
    datasets: [
      {
        label: "New Invoices",
        data: [],
        backgroundColor: "rgb(255, 99, 132)",
        stack: "Stack 0",
        barPercentage: 0.5,
      },
      {
        label: "In Progress Invoices",
        data: [],
        backgroundColor: "rgb(75, 192, 192)",
        stack: "Stack 0",
        barPercentage: 0.5,
      },
      {
        label: "Financed Invoices",
        data: [],
        backgroundColor: "rgb(255, 245, 222)",
        stack: "Stack 0",
        barPercentage: 0.5,
      },
      {
        label: "Rejected Invoices",
        data: [],
        backgroundColor: "rgb(75, 102, 156)",
        stack: "Stack 0",
        barPercentage: 0.5,
      },
      {
        label: "Closed Invoices",
        data: [],
        backgroundColor: "rgb(255, 234, 111 )",
        stack: "Stack 0",
        barPercentage: 0.5,
      },
    ],
  };

  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
      },
    };

    const memberType = JSON.parse(sessionStorage.getItem("memberType"));

    if (memberType === "TRADER") {
      let URL = `${misURL}invoices/invoices/mis/summary?traderMemberUID=${companyId}`;
      fetchCompanyList(URL, idToken)
        .then((data) => {
          setResponse(data);
        })
        .catch((error) => {
          console.log(error);
        });
      // fetchCompanyList(URL, idToken)
      //   .then((data) => {
      //     setResponse(data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    } else {
      let URL = `${misURL}invoices/invoices/mis/summary?financerMemberUID=${companyId}`;
      fetchCompanyList(URL, idToken)
        .then((data) => {
          setResponse(data);
        })
        .catch((error) => {
          console.log(error);
        });

      // fetchCompanyList(URL, idToken)
      //   .then((data) => {
      //     setResponse(data);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    }
    // let URL = `${misURL}invoices/invoices/mis/summary?traderMemberUID=${companyId}`;

    // let temp = (response.totalFinancingApproved * 100) / response.totalFinancingRequested;
    // setPercentage(temp);
  }, []);

  useEffect(() => {
    if (response) {
      stackedData.datasets.forEach((item) => {
        item.data = [];
      });
      Object.keys(response.invoicesStatusMetrics)?.forEach((value, key) => {
        stackedData.datasets[key].data.push(
          response.invoicesStatusMetrics[value]
        );
      });
      setStackedBarData(stackedData);
    }
  }, [response]);

  useEffect(() => {
    if (response) {
      const temp = [
        ...new Set(
          response.invoicesTimeSeries.map((item) => {
            return item.year;
          })
        ),
      ];
      const newArray = temp.map((item) => {
        return {
          label: item,
          value: item,
        };
      });
      setYears(newArray);
    }
  }, [response]);

  useEffect(() => {
    if (response) {
      data.datasets.forEach((item) => {
        item.data = [];
      });
      response.invoicesTimeSeries.forEach((item) => {
        if (
          item.year === currentYear
          // || item.monthString ===labels
        ) {
          Object.keys(item.invoicesMetricsForMonth).forEach((value, key) => {
            data.datasets[key].data.push(item.invoicesMetricsForMonth[value]);
          });
        }
      });
      setBarData(data);
    }
  }, [response, currentYear]);
  const handleChange = (e) => {
    setCurrentYear(e.target.value);
  };

  return (
    <Style>
      {response ? (
        <div>
          <div className="cardWrapper">
            <div>
              <div style={{ fontSize: "20px", marginLeft: "2%" }}>
                Invoices Overview
              </div>
              {/* <div className="btn">
                <ButtonStyled style={{ width: "180px" }}>
                  +Add Trade
                </ButtonStyled>
              </div> */}
            </div>
            <div
              style={{
                border: "1px solid transparent",
                margin: "2%",
                background: "rgba(25, 25, 25, 25)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  marginLeft: "1%",
                  marginRight: "2%",
                  marginTop: "40px",
                }}
              >
                <div
                  style={{ width: "50%", textAlign: "left", fontSize: "20px" }}
                >
                  Invoices Uploaded{" "}
                  <span style={{ color: "#42a5f5" }}>
                    {response.invoicesUploaded}
                  </span>
                </div>
                <div
                  style={{ width: "50%", textAlign: "right", fontSize: "20px" }}
                >
                  Invoices Financed{" "}
                  <span style={{ color: "#42a5f5" }}>
                    {response.invoicesFinanced}
                  </span>
                </div>
              </div>

              <div
                style={{
                  height: "30px",
                  display: "flex",
                  justifyContent: "center",
                  height: "12rem",
                }}
              >
                <div style={{ width: "30%" }}>
                  {stackedBarData && (
                    <Bar options={option2} data={stackedBarData} />
                  )}
                </div>
              </div>

              <div className="iconDiv">
                {/* <div
              style={{
                margintop: "20px",

                display: "flex",
              }}
            > */}
                <div className="item">
                  <div className="font">
                    {response.invoicesStatusMetrics.newInvoices}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>New</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response.invoicesStatusMetrics.financedInvoices}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>Financed</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response.invoicesStatusMetrics.inProgressInvoices}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>In-Process</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response.invoicesStatusMetrics.rejectedInvoices}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>Rejected</div>
                </div>
                <div className="item">
                  <div className="font">
                    {response.invoicesStatusMetrics.closedInvoices}
                  </div>
                  <div>
                    <BsCircleFill color="#218EEB" size={40} />
                  </div>
                  <div>Closed</div>
                </div>
              </div>
            </div>
            <div
              style={{
                border: "1px solid transparent",
                margin: "2%",
                background: "rgba(25, 25, 25, 25)",
                borderRadius: "8px",
              }}
            >
              <div
                style={{
                  marginTop: "50px",
                  width: "96.5%",
                  margin: "2%",
                  background: "rgba(255, 255, 255, 0.05)",
                  paddingLeft: "10px",
                  borderRadius: "8px",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ display: "flex", padding: "2%" }}>
                    <div
                      style={{
                        //   font-size:"22px",
                        fontSize: "20px",
                        //  fontSize: "20px",
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Monthly Trend{" "}
                    </div>
                    <div
                      style={{
                        width: "48%",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <div style={{ width: "20%" }}>
                        <Select
                          placeholder="Year"
                          options={years}
                          name="year"
                          handleChange={handleChange}
                          value={currentYear}
                        />
                      </div>
                    </div>
                  </div>
                  {barData && (
                    <div
                      className="font"
                      style={{
                        marginTop: "40px",
                        textAlign: "center",
                      }}
                    >
                      <Bar height={80} options={options} data={barData} />
                    </div>
                  )}
                  {/* {barData && (
                // <div
                //   style={{
                //     border: "1px solid transparent",
                //     margin: "1%",
                //     background: "rgba(25, 25, 25, 25)",
                //     borderRadius: "8px",
                //   }}
                // >
                <div
                  className="font"
                  style={{
                    marginTop: "40px",
                    textAlign: "center",
                  }}
                >
                  <Bar options={options} data={barData} />
                </div>
                // </div>
              )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Style>
  );
};
