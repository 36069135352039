import { Countries } from "utils/DropDown/Country";

export default (value) => {
  let Temp = Countries.filter((country) => country.value === value);

  if (Temp.length > 0) {
    return Temp[0].label;
  } else {
    return "NA";
  }
};
