import react, { useState, useContext,useEffect } from 'react';
import {IoIosArrowUp,IoIosArrowDown} from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import {BsBell} from "react-icons/bs";
import {FiMail} from "react-icons/fi";
import {RiWechatLine} from "react-icons/ri";
import { AiOutlineConsoleSql } from 'react-icons/ai';
import {storeContext} from '../../../../../contexts/Store/Store'
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { fetchMemberdata } from "utils/memberchat";
import {NetworkURL} from '../../../../../services/BaseURLInstance';
import { truncateStringID } from 'utils/helper';

const Styles = styled.div`
   .row{
    width:100%;
    display:flex;
   }
   
   .row>div{
    display:flex;
    height:50.47px;
    width: 16.285%;
    justify-content: center;
    align-items: center;
    border: 0.1px solid #FFFFFF;
    font-family: Roboto;
    font-style: Medium;
    font-size: 18px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    overflow:hidden;
       
   }

   .details{
        margin-top:20px;
        margin-left:40px;
        padding-bottom:20px;
        font-family: Roboto;
        font-style: Regular;
        font-size: 16px;
        line-height: 24px;
        line-height: 128%;
        align: left;
        vertical-align: Top;
        paragraph-spacing: 26px;
   }
   .details-screen{
       display:flex;
   }
   .details-button{
         margin-top:20px;
         margin-left:120px;
   }
   .details-button>button{
        display: block; 
        margin: 4px;
        background-color: #42A5F5; 
        color: black; 
        cursor: pointer; 
        width: 214.24px; 
        height:38.09px;
       
   }
   .details>hr{
         height: .0000000001px;
         Opacity: 20%
   }
   .details>div{
       
   }
`;


const TableData =({data,financerMemberUID, companyList, hanndleCraateGroup})=>{

    const { fetchCompanyList } = useContext(storeContext)
    const { instance, accounts } = useMsal();
    // const [idToken, setIdToken] = useState(null);
    // getIdToken(instance, accounts, setIdToken);
    const companyId = getClaim(accounts, "company_member_id");
    const navigate=useNavigate();
    const [check,setCheck]=useState(true);
    // const [companyList,setCompanyList]=useState("");



    const handleClick=()=>{
        if(check){
            setCheck(false)
        }
        else{
            setCheck(true)
        }
    }

    const handleDetails=(e,data,companyList)=>{ 
        companyList.map(item=>{
            if(item.memberUID===data.financerMemberUID)
            {
                data.memberName=item.memberName
                e.preventDefault();
                localStorage.setItem('financeDeal', JSON.stringify(data));
                navigate('/financedashBoard/pofinancing/summary');

            }})
    }


    const chatHandler = async(el)=>{     
        let usrData =[el?.financerMemberUID,el?.traderMemberUID ];
        let ds=[];
        let res = await fetchMemberdata(usrData);
        if (res?.length > 0) {
            let name = el.financeDealId
            ? truncateStringID(el.financeDealId, 7, "FD")
            : "NA";
            hanndleCraateGroup(res, name, name);
          }
    }
    return(
        <Styles>
            {
            (companyList)  && <div>
                <div className="row" style={{cursor:"pointer"}}>
                <div  onClick={(e)=>handleDetails(e,data,companyList)} style={{borderLeft: "none",paddingLeft:"10px"}}>
              <div>
                <BsBell size={22} />
              </div>
              {data.financeDealId
                ? truncateStringID(data.financeDealId, 7, "FD")
                : "NA"}
            </div>
                    <div style={{paddingLeft:"10px"}}>
                        <div><BsBell/></div>
                        {
                        companyList.map(item=>{
                                if(item.memberUID===data.financerMemberUID)
                                {
                                    return <div style={{borderLeft: "none",paddingLeft:"10px"}}>{item.memberName}</div>
                                }
                        })
                        }     
                    </div>
                <div style={{borderLeft: "none"}}>{data.financeProductCategory}</div>
                <div style={{borderLeft: "none"}}>{data.financeDealStatus}</div>
                <div style={{borderLeft: "none"}}>{data.lastUpdatedDate}</div>
                <div style={{borderLeft: "none", borderRight:"none"}}>
                    <div style={{margin:"20px"}}>< FiMail/></div>
                    <div style={{margin:"20px"}} >
                    <span className="px-3"  onClick={(e) => chatHandler(data)}>
                      <RiWechatLine size={22} />
                    </span>
                      </div>
                </div>
            </div>
            </div>
            }
        </Styles>
    )
}
export default TableData;