import React from "react";
import Trade from "pages/Trade";
import TradeWorkflow from "pages/TradeOver/tradeWorkflow";
import { useState } from "react";
import Navbar from "../../components/UI/Navbar/Navbar";
import { Routes, Route, Navigate } from "react-router-dom";
import TradeList from "./tradeList";
import NewTrade from "./newTrade";
import { TradeContextProvider } from "./tradeProvider";

export default ({ hanndleCraateGroup }) => {
  const [current, setCurrent] = useState("overview");
  const handleCurrent = (e, value) => {
    setCurrent(value);
  };
  const tabs = [
    { name: "Overview", value: "overview" },
    { name: "Trades", value: "trades" },
  ];
  return (
    <div>
      <Navbar tabs={tabs} />
      <Routes>
        <Route path="/" element={<Navigate to="overview" />} />
        <Route
          path="/overview"
          element={
            <TradeContextProvider>
              <Trade />
            </TradeContextProvider>
          }
        />
        <Route
          path="/trades"
          element={
            <TradeContextProvider>
              <TradeList hanndleCraateGroup={hanndleCraateGroup} />
            </TradeContextProvider>
          }
        />
        <Route
          path="/tradeflow/:id/:node"
          element={
            <TradeContextProvider>
              <TradeWorkflow hanndleCraateGroup={hanndleCraateGroup} />
            </TradeContextProvider>
          }
        />
        <Route
          exact
          path={"/tradeflow/:id"}
          element={
            <TradeContextProvider>
              <TradeWorkflow hanndleCraateGroup={hanndleCraateGroup} />
            </TradeContextProvider>
          }
        />
        <Route
          path="/newtrade"
          element={
            <TradeContextProvider>
              <NewTrade />
            </TradeContextProvider>
          }
        />
      </Routes>
    </div>
  );
};
