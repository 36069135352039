import react from "react";
import { CgFileDocument } from "react-icons/cg";
import { BsBell } from "react-icons/bs";
import { useEffect, useState } from "react";
import ParticipantStatus from "../../../../jsons/participantStatus.json";
import { Grid } from "@mui/material";
import Select from "components/fields/Select";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { BiBadgeCheck } from "react-icons/bi";
import { tradeURL } from "services/BaseURLInstance";
import Content from "components/UI/Layout/Content";
import Container from "components/UI/Layout/Container";
import { FindCompanyName } from "utils/helper";
import Loader from "components/Loader/Loader";
import { Error, Success } from "components/notifications/responseHandle";
import "./changeState.css";
import { useContext } from "react";
import { TradeContext } from "../../tradeProvider";
import { Suspense } from "react";
import DateInput from "components/fields/DateInput";
import {AiOutlineInfoCircle} from "react-icons/ai";
import APIError from "components/Error/error";
export default ({
  subStageDate,
}) => {
  const [participantsToSign, setParticipantsToSign] = useState("");
  const [newState, setNewState] = useState();
  const { instance, accounts } = useMsal();
  const {companyList} = useContext(TradeContext);
  const [loading, setIsLoading] = useState(false);
  const [newDate, setNewDate] = useState();
  
  const companyId = getClaim(accounts, "company_member_id");
  useEffect(() => {
    const newParticipantsToSign = [];
    subStageDate?.participantsToSign?.forEach((item)=>{
      subStageDate?.participants.forEach((value)=>{
        if(item.participant === value.participant)
          newParticipantsToSign.push({
            ...item,
            plannedEndDate: value.plannedEndDate,
          });
      })
    })
    setParticipantsToSign(newParticipantsToSign);
  }, [subStageDate.participants, loading]);

  const handleChange = async (e, temp) => {
    let data = participantsToSign;
    data.forEach((item) => {
      if (item.participant === temp.participant) {
        item.signStatus = e.target.value;
      }
    });
    await setParticipantsToSign([]);
    setParticipantsToSign(data);
  };


  const handleChangeState = (e, item) => {
    setNewState(e.target.value);
  };

  const handleStateChange = () => {
    if(newDate===undefined && newState!=="SIGNED")
    {
       Error("Please select the completion date");
    }
    else{
    setIsLoading(true);
    if(newState || newDate){
      subStageDate.participants.forEach((value) => {
        if (companyId === value.participant) {
          value.status = newState;
          value.plannedEndDate = newDate;
        }
      });
  }
   else{
    subStageDate.participants.forEach((value) => {
      if (companyId === value.participant) {
        value.plannedEndDate = new Date().toISOString();
      }
    });
   }
    const tempAvailableService = subStageDate.availableServices.map(item => {
      return {
        serviceCategory: "TRADE_CONTRACT",
        serviceSubCategory: "OTHERS",
      }
    })
    let updateDataTemp = {...subStageDate};
    updateDataTemp.availableServices = tempAvailableService;
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(updateDataTemp),
    };
    const fetchData = async () => {
      await fetch(
        `${tradeURL}tradeLifeCycle/${updateDataTemp.subStageUID}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {

          if(Object.keys(data).length>0)
          {
            setIsLoading(false)
            Success("State Changed Successfully")
          }
          else{
            setIsLoading(false)
            Error("State not Changed Please Connect Admin")
          }
          console.log("updatetradeData", data);
          // window.location.reload(false)
          //navigate("../trades")
        });
    };
    fetchData();
  }
  };

  const handleEndDateChange = (e, key) =>{
      console.log("Date", e, new Date(e).toISOString());
      const date = new Date(e).toISOString();
      setNewDate(date);
  }

  return (
    <>
    { 
    loading? <Loader></Loader> :
    subStageDate.serviceErrorCode? <APIError status={subStageDate.message}/> :
    participantsToSign===null 
    ? <div style={{margin: "2%"}}>No data available</div>
    : <div style={{ padding: "20px" }}>
     <Container>
        <Content>
          <div>Change Sub-Stage Status</div>
          <div style={{display:"flex"}}>
          <div style={{ marginTop: "20px", marginBottom: "2%", fontSize:'12px', flex:1 }}>
            <BsBell size={20} color={"rgb(66, 165, 245)"} /> Trade Sub-Stage is
            pending signatures by participants
          </div>
          <div style={{ marginTop: "20px", marginBottom: "2%", fontSize:'12px', width:"50%" }}>
             {subStageDate?.documentCheck==="NOT_STARTED" 
                ? <div style={{color:"rgb(255, 193, 7)"}}> <AiOutlineInfoCircle size={20} color={"rgb(255, 193, 7)"} /> Please note that there are documents which are not still uploaded yet.</div> 
                : subStageDate.documentCheck==="IN_PROGRESS" && <div style={{color:"rgb(255, 193, 7)"}}> <AiOutlineInfoCircle size={20} color={"rgb(66, 165, 245)"} /> Please note that there are documents which are not still uploaded yet.</div> 
              }
              {subStageDate?.serviceCheck==="NOT_STARTED" 
                ? <div style={{color:"rgb(255, 193, 7)"}}> <AiOutlineInfoCircle size={20} color={"rgb(255, 193, 7)"} /> Please note that there are Services which are still not Subscribed.</div> 
                : subStageDate.serviceCheck==="IN_PROGRESS" && <div style={{color:"red"}}> <AiOutlineInfoCircle size={20} color={"red"} /> Please note that there are Services which are still In-Progress. 
                Complete the Service Delivery before marking the Stage as Completed.</div> 
              }
              {subStageDate?.serviceDealCheck==="NOT_STARTED" 
                ? <div style={{color:"rgb(255, 193, 7)"}}> <AiOutlineInfoCircle size={20} color={"rgb(255, 193, 7)"} /> Please note that there are Service Deals which are still not Completed.</div> 
                : subStageDate.serviceDealCheck==="IN_PROGRESS" && <div style={{color:"red"}}> <AiOutlineInfoCircle size={20} color={"red"} /> Please note that there are Service Deals which are still not Completed. 
                Complete the Service Delivery before marking the Stage as Completed..</div> 
              }
          </div>
          </div>
          <div
            style={{
              display: "flex",
              backgroundColor: "#A5A5A540",
              padding: "10px",
              width: "100%",
            }}
          >
            <div style={{ width: "20%",  alignContent: "center" }}>
              {participantsToSign !== "" &&
                participantsToSign?.map((item) => {
                  if(item.participant){
                  return (
                    <div style={{ overflow:"auto", margin:"20px", height:"30%"}}>
                      <div
                        style={{
                          paddingLeft: "10px",
                          fontSize: "15px",
                        }}
                      >
                        {item.role}
                      </div>
                      <div style={{ fontSize: "10px", paddingLeft: "10px" }}>
                        {companyList && FindCompanyName(companyList, item.participant)}
                      </div>
                    </div>
                  );
                }
                })}
            </div>
            <div style={{ width: "75%",  alignContent: "center", fontSize:"12px"}}>
              {participantsToSign !== "" &&
                participantsToSign?.map((item) => {
                  if(item.participant){
                    return (
                      <div style={{ width: "100%" }}>
                        {companyId === item.participant && item.signStatus !== "SIGNED"? (
  
                          <div style={{ display: "flex", margin: "2%" }}>
                            <div style={{ width: "40%" }}>
                              <Select
                                name="status"
                                placeholder="Status"
                                value={newState ? newState : item.signStatus}
                                options={ParticipantStatus}
                                handleChange={(e) => handleChangeState(e, item)}
                                readOnly={item.signStatus !== "SIGNED" ? false : true}
                              />
                            </div>
                            <div style={{ fontSize: "90%", width: "20%" }}>
                              {item.signStatus === "SIGNED" ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <span style={{ marginRight: "10%" }}>
                                    <BiBadgeCheck color={"#34BE0F"} size={20} />
                                  </span>
                                  <div>Signed</div>
                                </div>
                              ) : item.signStatus === "CANCELLED" ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <span style={{ marginRight: "10%" }}>
                                    <BiBadgeCheck color={"#FF0000"} size={20} />
                                  </span>
                                  <div>Cancelled</div>
                                </div>
                              ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <span style={{ marginRight: "10%" }}>
                                    <BiBadgeCheck color={"#FFC000"} size={20} />
                                  </span>
                                  <div>Pending</div>
                                </div>
                              )}
                            </div>
                            <div style={{ width: "25%", marginTop:"0.5%" }}>
                              <DateInput
                                name="plannedEndDate"
                                placeholder="Completion Date"
                                value={newDate ? newDate : item?.plannedEndDate && item?.plannedEndDate}
                                handleChange={(e) =>
                                  handleEndDateChange(e, "plannedEndDate")
                                }
                              />
                            </div>
                            <div style={{ width: "20%" ,display: "flex", alignItems: "center", justifyContent: "center", height: "4rem"}}>
                              {companyId === item.participant 
                               && (
                                  <div
                                    style={{ marginLeft: "10%"}}
                                  >
                                    <button
                                      style={{fontSize:"12px"}}
                                      className="changeButtom"
                                      onClick={handleStateChange}
                                    >
                                      Change Status
                                    </button>
                                  </div>
                                )}
                            </div>
                          </div>
                        ) : (
                          <div style={{ display: "flex", margin: "2%"}}>
                            <div style={{ width: "38%" }}>
                              <Select
                                name="status"
                                placeholder="Status"
                                value={item.signStatus}
                                options={ParticipantStatus}
                                handleChange={(e) => handleChangeState(e, item)}
                                readOnly={true}
                              />
                            </div>
                            <div style={{ fontSize: "90%", width: "20%" }}>
                              {item.signStatus === "SIGNED" ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <span style={{ marginRight: "10%" }}>
                                    <BiBadgeCheck color={"#34BE0F"} size={20} />
                                  </span>
                                  <div>Signed</div>
                                </div>
                              ) : item.signStatus === "CANCELLED" ? (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <span style={{ marginRight: "10%" }}>
                                    <BiBadgeCheck color={"#FF0000"} size={20} />
                                  </span>
                                  <div>Cancelled</div>
                                </div>
                              ) : (
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
                                  <span style={{ marginRight: "10%" }}>
                                    <BiBadgeCheck color={"#FFC000"} size={20} />
                                  </span>
                                  <div>Pending</div>
                                </div>
                              )}
                            </div>
                            <div style={{width:"40%", fontSize:"12px", marginTop:"2.5%"}}>Completion Date : {item?.plannedEndDate? new Date(item?.plannedEndDate).toLocaleDateString(): new Date().toLocaleDateString()}</div>
                          </div>
                        )}
                      </div>
                    );
                  } 
                })}
            </div>
          </div>
        </Content>
      </Container>
    </div>
  }
  </>
  );
};
