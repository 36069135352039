import React, { useState, useEffect } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaQuestion, FaQuestionCircle } from "react-icons/fa";

import styles from "./Document.module.css";
import { DocumentSign } from "components/Icons/document_icon";
import { FindCompanyName } from "utils/helper";
import { ToastContainer } from "react-toastify";
import StatusContainer from "components/StatusContainer";
import { docUpdateURL } from "services/BaseURLInstance";
import MultiSelect from "components/fields/MultiSelect";
import Content from "components/UI/Layout/Content";
import Loader from "components/Loader/Loader";
import cn from "classnames";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import { Success, Error } from "components/notifications/responseHandle";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { DigitalSignature } from "utils/Section/FormInfo";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import classes from "./documentNew.module.css";

export default ({
  // idToken,
  companyId,
  tempData,
  // userType,
  docResponse,
  listMember,
}) => {
  const { instance, accounts } = useMsal();
  const [status, setstatus] = useState("signatureDeclined");
  const response = listMember;
  console.log("listMember ??", listMember);

  const CompanyName = JSON.parse(sessionStorage.getItem("companyName"));
  const [companyList, setCompanyList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // CompanyList API  // using
  useEffect(() => {
    if (listMember && listMember) {
      let temp =
        listMember &&
        listMember.filter((item) => {
          if (
            item.memberType === "FINANCER" ||
            item.memberType === "COMPANY" ||
            item.memberType === "SERVICE_PROVIDER" ||
            item.memberUID === companyId
          ) {
            return { label: item.memberName, value: item.memberUID };
          }
        });
      let filter =
        temp &&
        temp.map((item) => {
          return { label: item.memberName, value: item.memberUID };
        });
      setCompanyList(filter);
    }
  }, []);

  // using
  const handleMultiChange = (e) => {
    const { value } = e.target;
    setSelectList(value);
  };

  // using
  useEffect(() => {
    let temporaryList = [];
    selectList.map((select) => {
      temporaryList.push(select.value);
    });
    setSelectedId(temporaryList);
  }, [selectList]);

  const stateComp = () => {
    switch (status) {
      case "signatureDeclined":
        return {
          style: <DocumentSign color="#E60909" />,
          sign: <FaQuestion size={10} />,
        };
      case "signaturePending":
        return {
          style: <DocumentSign color="#FFC000" />,
          sign: <FaQuestion size={10} />,
        };
    }
  };

  const handleSubmit = async () => {
    if (!tempData) {
      Error("You must select one document");
      return;
    }
    if (selectedId && selectedId.length === 0) {
      Error("You must select one Member");
      return;
    }

    let requestParams = {
      documentId: tempData?.id,
      ownerMemberId: companyId,
      validatorMemberIds: selectedId,
    };
    setIsLoading(true);

    const options = {
      method: "POST",
      body: requestParams,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${docUpdateURL}documents/sign-requests`,
        options,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, path } = data;
        if (message || path) {
          Error(message || "Something went wrong");
        } else {
          Success("Signed Successfully");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      });
    });

    // const requestOptions = {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: "Bearer " + idToken,
    //     "x-api-key":
    //       "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
    //   },
    //   body: JSON.stringify(requestParams),
    // };
    // await fetch(`${docUpdateURL}documents/sign-requests`, requestOptions)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setIsLoading(false);
    //     let { message } = data;
    //     if (message) {
    //       Error(message);
    //     } else {
    //       Success("Added Successfully");
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 2000);
    //     }
    //   });
  };

  return (
    <div style={{ marginTop: "2%" }}>
      <Content>
        {listMember === null || isLoading ? (
          <Content>
            Loading...
            <Loader />
          </Content>
        ) : (
          <>
            {listMember && (
              <Content>
                <div className="d-flex" style={{ marginBottom: "2%" }}>
                  <OverLayTrigger value={DigitalSignature} />
                </div>

                <div className={styles.shareDocGrid}>
                  <div className="d-flex">
                    <div className="col-4">
                      <span>Owner Signature</span>
                    </div>
                    {CompanyName ? (
                      <div className={cn(styles.textRight, "col")}>
                        <StatusContainer color={"green"} value={CompanyName} />
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex">
                    <div className="col-4">
                      <span>Third Party Signatures</span>
                    </div>
                    <div className={classes.TPSignatures}>
                      {docResponse &&
                        docResponse.validatedBy &&
                        docResponse.validatedBy.map((element) => (
                          <>
                            {stateComp() && (
                              <div
                                style={{
                                  backgroundColor: "#1cbb8c2e",
                                  color: "#1cbb8c",
                                }}>
                                {/* <StatusContainer
                                  color={"green"}
                                  value={FindCompanyName(response, element)}
                                /> */}
                                {FindCompanyName(response, element)}
                              </div>
                            )}
                          </>
                        ))}
                      {docResponse &&
                        docResponse?.pendingValidators &&
                        docResponse?.pendingValidators.map((element) => (
                          <>
                            {stateComp() && (
                              <div
                                style={{
                                  backgroundColor: "#fcb92c2e",
                                  color: "#fcb92c",
                                }}>
                                {/* <StatusContainer
                                  color={"yellow"}
                                  value={FindCompanyName(response, element)}
                                /> */}
                                {FindCompanyName(response, element)}
                              </div>
                            )}
                          </>
                        ))}
                      {docResponse &&
                        docResponse?.pendingValidators.length === 0 &&
                        docResponse?.validatedBy.length === 0 && (
                          // <StatusContainer
                          //   color={"yellow"}
                          //   value={"No additional signatories"}
                          // />
                          <div
                            style={{
                              backgroundColor: "#fcb92c2e",
                              color: "#fcb92c",
                            }}>
                            No additional signatories
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="col-4">
                      <span>Request Signatures</span>
                    </div>
                    <div className="col">
                      <form>
                        <div>
                          <MultiSelect
                            name="memberlist"
                            placeholder="Select Members..."
                            selected={selectList}
                            // options={companyList}
                            options={
                              companyList &&
                              companyList.sort((a, b) =>
                                a.label.localeCompare(b.label)
                              )
                            }
                            handleChange={handleMultiChange}
                          />
                        </div>
                        <ButtonDiv>
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className={cn(styles.ButtonReq, "my-2")}>
                            Request Signature
                          </button>
                        </ButtonDiv>
                      </form>
                    </div>
                  </div>
                </div>
                <div className={cn(styles.signatureColumn, "col")}>
                  <div className="d-flex">
                    <span className="mx-2">
                      <BsCheckCircleFill
                        style={{
                          color: "#4CAF50",
                        }}
                        size={22}
                      />
                    </span>
                    <span>Document Signed</span>
                  </div>
                  <div className="d-flex">
                    <span className="mx-2">
                      <FaQuestionCircle color="#E60909" size={22} />
                    </span>
                    <span>Signature Declined</span>
                  </div>
                  <div className="d-flex">
                    <span className="mx-2">
                      <FaQuestionCircle color="#FFC000" size={22} />
                    </span>
                    <span>Signature Pending</span>
                  </div>
                </div>
              </Content>
            )}
          </>
        )}
      </Content>
    </div>
  );
};
