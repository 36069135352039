import React, { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ImHistory } from "react-icons/im";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import { documentURL } from "services/BaseURLInstance";
import classes from "./company.module.css";
import cn from "classnames";
import Modal from "components/UI/Modal/Modal";
import VersionTable from "./VersionTable";
import InfoTable from "./infoTable";
import { onBoardingURL } from "services/BaseURLInstance";
import {
  Error,
  ToastLoading,
  ToastSuccess,
} from "components/notifications/responseHandle";
import { UseRequest, UseRequestDownload } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { FaFileSignature } from "react-icons/fa";
import { AiOutlineCloudUpload, AiOutlineCloudDownload } from "react-icons/ai";

const Action = ({
  Data,
  PropsData,
  folderLocation, // using fro folder location of every document
  OpenTradeAccountDetails, // using for open trade account details
  componentLocations, // using in Profile Stepper
}) => {
  let {
    subStageDate,
    dealData,
    referenceData,
    serviceDealDetails,
    invoiceId,
    serviceId,
  } = PropsData;

  console.log("DataData", Data);

  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const handleNavigate = (url, value) => {
    navigate(url, { state: value });
  };

  //rbac
  const [uploadRole, setUploadRole] = useState(null);
  const [eSignRole, setEsignRole] = useState(null);
  const [shareRole, setSharerole] = useState(null);
  const [digitalSignRole, setDigitalSignRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Role, setRole] = useState(null);
  const [docResponse, setDocResponse] = useState(null);
  const [openInfo, setOpenInfo] = useState(false);
  const [versionDialogOpen, setVersionDialogOpen] = useState(false);
  const [versionData, setVersionData] = useState(null);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  //rbac
  const Request = () => {
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      )
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message);
          } else {
            setRole(data.role);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setUploadRole(
        CheckPermission("Actions", "CompanyDocuments", "Upload", Role)
      );
      setEsignRole(
        CheckPermission("Actions", "CompanyDocuments", "ESign", Role)
      );
      setSharerole(
        CheckPermission("Actions", "CompanyDocuments", "Share", Role)
      );
      setDigitalSignRole(
        CheckPermission("Actions", "CompanyDocuments", "DigitalSignature", Role)
      );
    }
  }, [Role]);

  const handleClickInfo = () => {
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${documentURL}documents/${Data.docReferenceId}`,
          null,
          response
        ).then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
          } else {
            setDocResponse(data);
            setOpenInfo(true);
          }
        });
      });
    }
  };

  const handleClickHistory = () => {
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${documentURL}documents/${Data.docReferenceId}/history`,
          null,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
          } else {
            setVersionData(data);
            setVersionDialogOpen(true);
          }
        });
      });
    }
  };

  const handleClickDownload = async () => {
    const loadingId = ToastLoading(`${Data.documentName} downloading...`);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequestDownload(
        `${process.env.REACT_APP_DOCUMENT_URL}documents/${Data.docReferenceId}/download`,
        response
      ).then((blob) => {
        ToastSuccess(`${Data.documentName} downloaded successfully`, loadingId);
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = Data.documentName;
        a.click();
      });
    });
  };

  const handleClickSign = () => {
    const loadingId = ToastLoading(`${Data.documentName} signing...`);
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      let requestPayload = {
        // companyName: "",
        memberId: Data.memberId,
        documentId: Data.docReferenceId,
        documentCategory: Data.docCategory,
        documentSubCategory: Data.docSubCategory,
        template: "Global",
        documentType: "", // Adhar
      };
      const options = {
        method: "POST",
        body: requestPayload,
      };
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${onBoardingURL}onboarding/esignature`, options, response)
          .then((data) => {
            let { message, path } = data;
            if (message || path) {
              Error(message);
            } else {
              ToastSuccess(
                `${Data.documentName} signing successfully`,
                loadingId
              );
              window.open(`${data.result && data.result.url}`);
            }
          })
          .catch((error) => {
            ToastSuccess(`${Data.documentName} signing Failed`, loadingId);
          });
      });
    }
  };

  const handleClickAdharSign = () => {
    const loadingId = ToastLoading(`${Data.documentName} signing...`);
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      let requestPayload = {
        // companyName: "",
        memberId: Data.memberId,
        documentId: Data.docReferenceId,
        documentCategory: Data.docCategory,
        documentSubCategory: Data.docSubCategory,
        template: "Global",
        documentType: "AAdhaar",
      };
      const options = {
        method: "POST",
        body: requestPayload,
      };
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${onBoardingURL}onboarding/esignature`,
          options,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
          } else {
            ToastSuccess(
              `${Data.documentName} signing successfully`,
              loadingId
            );
            window.open(`${data.result && data.result.url}`);
          }
        });
      });
    }
  };

  const esignUpload = () => {
    let requestPayload = {
      file: "file",
      name: "name",
      isPulblic: true,
      docId: "ID",
    };
    const options = {
      method: "POST",
      body: requestPayload,
    };
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        "http://localhost:50013/onboarding/esignatureDocumentUpload",
        options,
        response
      ).then((data) => {
        let { message, path } = data;
        if (message || path) {
          Error(message);
        } else {
        }
      });
    });
  };

  return (
    <>
      {isLoading ? (
        <span>Loading...</span>
      ) : (
        <>
          {Role && (
            <span>
              <Modal
                show={versionDialogOpen}
                onHide={() => setVersionDialogOpen(false)}
                Data={<VersionTable Data={versionData} />}
                Heading={"Documents Version"}
              />
              <Modal
                show={openInfo}
                onHide={() => setOpenInfo(false)}
                Data={<InfoTable Data={docResponse} />}
                Heading={"Documents Details"}
              />
              <div className={cn(classes.ActionDiv)}>
                <div></div>
                <div className={cn(classes.openAction)}>
                  <span
                    onClick={() => handleClickInfo()}
                    className={
                      Data?.docReferenceId === "" ? classes.isDisabled : ""
                    }>
                    <AiOutlineInfoCircle size={16} />
                    <span style={{ marginLeft: "5px" }}> Information</span>
                  </span>

                  <span
                    onClick={() => handleClickHistory()}
                    className={
                      Data?.docReferenceId === "" ? classes.isDisabled : ""
                    }>
                    <ImHistory size={16} />
                    <span style={{ marginLeft: "5px" }}> History</span>
                  </span>

                  <span
                    onClick={() => handleClickDownload()}
                    className={
                      Data?.docReferenceId === "" ? classes.isDisabled : ""
                    }>
                    <AiOutlineCloudDownload size={16} />
                    <span style={{ marginLeft: "5px" }}> Download</span>
                  </span>

                  <span
                    className={
                      Data?.ownerId && Data?.ownerId !== companyId
                        ? classes.isDisabled
                        : ""
                    }
                    onClick={
                      uploadRole === "CREATE"
                        ? () => {
                            if (Data.docReferenceId) {
                              handleNavigate(
                                `/documents/upload?Id=${
                                  dealData
                                    ? dealData.financeDealId
                                    : serviceDealDetails
                                    ? serviceDealDetails.serviceDealId
                                    : subStageDate
                                    ? subStageDate.tradeId
                                    : invoiceId
                                    ? invoiceId
                                    : serviceId
                                    ? serviceId
                                    : ""
                                }`,
                                {
                                  state: {
                                    docCategory: Data.docCategory
                                      ? Data.docCategory
                                      : "",
                                    docSubCategory: Data.docSubCategory
                                      ? Data.docSubCategory
                                      : "",
                                    docReferenceId: Data.docReferenceId
                                      ? Data.docReferenceId
                                      : "",
                                    dealData: dealData ? dealData : "",
                                    serviceDealDetails: serviceDealDetails
                                      ? serviceDealDetails
                                      : "",
                                    subStageDate: subStageDate
                                      ? subStageDate
                                      : "",
                                    ref: Data.docReferenceId,
                                    referenceData: referenceData
                                      ? referenceData
                                      : "",
                                    invoiceId: invoiceId ? invoiceId : "",
                                    serviceId: serviceId ? serviceId : "",
                                    publicView: Data?.publicView,
                                  },
                                  folderLocation: folderLocation, // using fro folder location of every document
                                  OpenTradeAccountDetails:
                                    OpenTradeAccountDetails, // using for open trade account details
                                  // componentLocations: componentLocations, // using for Profile Stepper location // TODO verified this code is required or not
                                }
                              );
                            } else {
                              handleNavigate(`/documents/upload`, {
                                state: {
                                  docCategory: Data.docCategory
                                    ? Data.docCategory
                                    : "",
                                  docSubCategory: Data.docSubCategory
                                    ? Data.docSubCategory
                                    : "",
                                  docReferenceId: Data.docReferenceId
                                    ? Data.docReferenceId
                                    : "",
                                  dealData: dealData ? dealData : "",
                                  serviceDealDetails: serviceDealDetails
                                    ? serviceDealDetails
                                    : "",
                                  subStageDate: subStageDate
                                    ? subStageDate
                                    : "",
                                  ref: Data.docReferenceId,
                                  referenceData: referenceData
                                    ? referenceData
                                    : "",
                                  invoiceId: invoiceId ? invoiceId : "",
                                  serviceId: serviceId ? serviceId : "",
                                  publicView: Data?.publicView,
                                },
                                folderLocation: folderLocation, // using fro folder location of every document
                                OpenTradeAccountDetails:
                                  OpenTradeAccountDetails, // using for open trade account details
                                // componentLocations: componentLocations, // using for Profile Stepper location // TODO verified this code is required or not
                              });
                            }
                          }
                        : () => {}
                    }>
                    <AiOutlineCloudUpload size={16} />
                    <span style={{ marginLeft: "5px" }}> Upload</span>
                  </span>

                  <span
                    className={
                      Data?.docReferenceId === "" ? classes.isDisabled : ""
                    }
                    onClick={() =>
                      eSignRole === "CREATE" ? handleClickSign() : () => {}
                    }>
                    <FaFileSignature size={16} />
                    <span style={{ marginLeft: "5px" }}>E-sign</span>
                  </span>

                  <span
                    onClick={() => handleClickAdharSign()}
                    className={
                      Data?.docReferenceId === "" ? classes.isDisabled : ""
                    }>
                    <FaFileSignature size={16} />
                    <span style={{ marginLeft: "5px" }}>Aadhar</span>
                  </span>
                </div>
                <div></div>
              </div>
            </span>
          )}
        </>
      )}
    </>
  );
};

export default Action;
