export const UserTableHead = [
  { name: "User Name" },
  { name: "Email" },
  { name: "Phone" },
  { name: "Role" },
  { name: "Updated Date" },
  { name: "Status" },
];

export const UserOnboardingStatus = [
  {
    label: "VERIFICATION_PASS",
    value: "VERIFICATION_PASS",
  },
  {
    label: "VERIFICATION_FAIL",
    value: "VERIFICATION_FAIL",
  },
  {
    label: "NOT_STARTED",
    value: "NOT_STARTED",
  },
  {
    label: "IN_PROCESS",
    value: "IN_PROCESS",
  },
];

export const UserStatus = [
  {
    label: "ACTIVE",
    value: true,
  },
  {
    label: "NOT ACTIVE",
    value: false,
  },
];

export const UserRole = [
  {
    label: "MANAGEMENT",
    value: "MANAGEMENT",
  },
  {
    label: "FINANCEANDACCOUNTS",
    value: "FINANCEANDACCOUNTS",
  },
  {
    label: "OPERATIONS",
    value: "OPERATIONS",
  },
  {
    label: "ADMIN",
    value: "ADMIN",
  },
  {
    label: "LEGAL",
    value: "LEGAL",
  },
  {
    label: "CREDIT",
    value: "CREDIT",
  },
];

// Company
// 	- MANAGEMENT
// 	- FINANCEANDACCOUNTS
// 	- OPERATIONS
// 	- ADMIN
// 	- LEGAL

// Financer
// 	- MANAGEMENT
// 	- CREDIT
// 	- OPERATIONS
// 	- ADMIN
// 	- LEGAL

// Service Provider
// 	- MANAGEMENT
// 	- FINANCEANDACCOUNTS
// 	- OPERATIONS
// 	- ADMIN

export const SelectUserType = [
  {
    label: "Active",
    value: "AF1",
  },
  {
    label: "Not Active",
    value: "AF2",
  },
  {
    label: "None",
    value: "AF3",
  },
];

export const UserToggle = [
  {
    name: "Active",
    value: "Active",
  },
  {
    name: "Not Active",
    value: "notactive",
  },
  {
    name: "Blocked",
    value: "Blocked",
  },
];

export const CountryType = [
  {
    label: "Private Limited Company",
    value: "PRIVATE_LIMITED",
  },
  {
    label: "Public Limited Company",
    value: "PUBLIC_LIMITED",
  },
  {
    label: "LLP or Partnership Firm",
    value: "PARTNERSHIP_FIRM",
  },
  {
    label: "Others",
    value: "OTHERS",
  },
];

export const CountryTypeForFinancer = [
  {
    label: "Private Limited Company",
    value: "PRIVATE",
  },
  {
    label: "Public Limited Company",
    value: "PUBLIC",
  },
  {
    label: "LLP or Partnership Firm",
    value: "PARTNERSHIP",
  },
  {
    label: "Others",
    value: "OTHER",
  },
];
