import React, { useContext } from "react";
import Container from "components/UI/Layout/Container";
import Wrapper from "components/UI/Layout/Wrapper";
import Navbar from "components/UI/Navbar/Navbar";
import { Routes, Route, Navigate } from "react-router-dom";

import Overview from "./Overview/index";
import OpenTradeAccount from "./OpenTradeAccount";
import TradeDetails from "./OpenTradeAccount/AccountDetails/index";
import SechedulePayment from "./Schedule_Payments/SechedulePayment";
import EscrowPayment from "./OpenTradeAccount/EscrowPayment";
import MilestonePayment from "./OpenTradeAccount/MilestonePayment";
import ModifyTradeForm from "./OpenTradeAccount/ModifyTradeForm";
import AddPayment from "./OpenTradeAccount/AddPayment";

import { OpenTradeAccountTab } from "utils/Section/trade";
import { AuthContext } from "contexts/Auth/AuthContext";
import ModifyPayment from "./OpenTradeAccount/Modifypayment";

const Index = ({ hanndleCraateGroup }) => {
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  const { OpenTradeAccountDetails } = useContext(AuthContext);
  return (
    <Container>
      <Navbar tabs={OpenTradeAccountTab} />
      <Wrapper>
        <Routes>
          <Route path="/" element={<Navigate to="overview" />} />
          <Route path="overview" element={<Overview />} />
          <Route
            path="/open-account"
            element={
              memberType === "TRADER" ? (
                <OpenTradeAccount hanndleCraateGroup={hanndleCraateGroup} />
              ) : (
                <Overview />
              )
            }
          />

          {/* <Route
            path="/open-account/:id"
            element={
              OpenTradeAccountDetails ? (
                <TradeDetails />
              ) : (
                <Navigate to="/trade-account/open-account" />
              )
            }
          /> */}

          <Route path="/open-account/:id" element={<TradeDetails />} />
          <Route path="/open-account/*" element={<TradeDetails />} />
          <Route
            path="/open-account/:id?status=PENDING"
            element={
              OpenTradeAccountDetails ? (
                <TradeDetails />
              ) : (
                <Navigate to="/trade-account/open-account" />
              )
            }
          />
          <Route
            path="/open-account/escrow-payment/:id"
            element={<EscrowPayment />}
          />
          <Route
            path="/open-account/Schedule-payment/:id"
            element={<MilestonePayment />}
          />
          <Route
            path="/open-account/Add-payment/:id"
            element={<AddPayment/>}
          />
          <Route
            path="/open-account/Modify-payment/:id"
            element={<ModifyPayment />}
          />
          <Route
            path="/open-account/trade-form/:id"
            element={<ModifyTradeForm />}
          />

          <Route
            path="/schedule-payments"
            element={
              <SechedulePayment hanndleCraateGroup={hanndleCraateGroup} />
            }
          />
        </Routes>
      </Wrapper>
    </Container>
  );
};

export default Index;
