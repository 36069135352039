import React from "react";
import { Routes, Route } from "react-router-dom";
import { Container } from "../../components/Container.styled";
import CompanyDetails from "./Detail/Details";
import AllNetwork from "./allNetwork";

export default (props) => {
  const { toggle, isOpen, toggleChat, hanndleCraateGroup } = props;
  return (
    <>
      <Container>
        <Routes>
          <Route exact path="/" element={<AllNetwork hanndleCraateGroup={hanndleCraateGroup}
            toggle={toggle}
            isOpen={isOpen}
            toggleChat={toggleChat} />} />
          <Route exact path="type/:type/id/:id" element={<CompanyDetails
            hanndleCraateGroup={hanndleCraateGroup}
            toggle={toggle}
            isOpen={isOpen}
            toggleChat={toggleChat} />} />
        </Routes>
      </Container>
    </>
  );
};
