import React, { useEffect } from "react";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";
import "vis-network/styles/vis-network.css";
import { FindCompanyName, truncateString } from "utils/helper";
import classes from "./graph.module.css";
import { FormattedDateTime } from "utils/helper";
import Table from "react-bootstrap/Table";

const Header = [
  {
    name: "Document ID",
  },
  {
    name: "Owner",
  },
  {
    name: "Holder",
  },
  {
    name: " Copies",
  },
  {
    name: " Versions",
  },
  {
    name: " Shared With",
  },
  {
    name: " Signed By",
  },
  {
    name: " Verified By",
  },
  {
    name: " Issued By",
  },
];

function NetworkGraph({ memeberList, Data }) {
  const nodes = new DataSet();
  const edges = new DataSet();

  const memberMapping = new Map();
  const documentMapping = new Map();

  // For each node
  const nodesStyle = [
    {
      label: "Document",
      color: "#42a5f5",
    },
    {
      label: "Member",
      color: "#54B435",
    },
  ];

  // For each edge node
  const edgesStyle = [
    {
      label: "Version of doc",
      color: "blue",
    },
    {
      label: "Copy of doc",
      color: "red",
    },

    {
      label: "Doc owne",
      color: "gray",
    },
    {
      label: "Doc holder",
      color: "purple",
    },
    {
      label: "Doc shared with",
      color: "green",
    },
    {
      label: "Doc signed by",
      color: "yellow",
    },
    {
      label: "Doc verified by",
      color: "purple",
    },
    {
      label: "Doc issued by",
      color: "pink",
    },
    {
      label: "",
      color: "",
    },
  ];
  const memberEdgeMapping = new Map();

  const addMemberEdges = (data, label, color) => {
    if (!memberEdgeMapping.has(color)) {
      memberEdgeMapping.set(color, data);
      // edgesStyle.push({
      //   label: label,
      //   color: color,
      // });
    }
  };

  function addMemberNode(data) {
    if (!memberMapping.has(data.id)) {
      const memberId = nodes.length + 1;
      memberMapping.set(data.id, memberId);

      const node = {
        id: memberId,
        label:
          memeberList && memeberList.length > 0
            ? truncateString(FindCompanyName(memeberList, data.id), 8)
            : "Loading..",
        color: "#54B435",
        title:
          memeberList && memeberList.length > 0
            ? FindCompanyName(memeberList, data.id)
            : "Loading..",
      };

      // if (isNodePushed == false) {
      //   nodesStyle.push({
      //     label: "Member",
      //     color: "#54B435",
      //   });
      //   isNodePushed = true;
      // }

      nodes.add(node);
    }
  }

  function addDocumentNode(data, type) {
    switch (type) {
      case "parent":
        if (!documentMapping.has(data.documentUID)) {
          const memberId = nodes.length + 1;
          documentMapping.set(data.documentUID, memberId);
          const node = {
            id: nodes.length + 1,
            label: "Document",
            color: "#42a5f5",
            title: `Document ID : ${
              data.documentUID
            } \n Created Date : ${FormattedDateTime(data.createdDate)} `,
          };
          nodes.add(node);
        }
        return;
      case "document":
        if (!documentMapping.has(data.id)) {
          const memberId = nodes.length + 1;
          documentMapping.set(data.id, memberId);
          const node = {
            id: nodes.length + 1,
            label: "Document",
            color: "#42a5f5",
            title: `Document ID : ${
              data.id
            } \n Created Date : ${FormattedDateTime(data.createdDate)} `,
          };
          nodes.add(node);
        }
        return;
    }
  }

  function findIndexById(idMap, targetId) {
    for (let [key, value] of idMap.entries()) {
      if (key === targetId) {
        return value;
      }
    }
    return null; // Return null if the targetId is not found
  }

  function addDocumentEdge(fromId, data, KEY) {
    let fromIndex = findIndexById(documentMapping, fromId);
    let toIndex = findIndexById(documentMapping, data.id);

    switch (KEY) {
      case "versionOf":
        addMemberEdges(data.id, "Version of doc", "blue");
        const edgeversionOf = {
          from: fromIndex,
          to: toIndex,
          label: "Has Version",
          // title: "Has Version",
          title: `Has Version : ${
            data.id
          } \n Created Date : ${FormattedDateTime(data.createdDate)} `,
          color: "blue",
        };
        edges.add(edgeversionOf);
        break;
      case "copyOf":
        addMemberEdges(data.id, "Copy of doc", "red");
        const edgecopyOf = {
          from: fromIndex,
          to: toIndex,
          label: "Has Copy",
          // title: "Has Copy",
          title: `Has Copy : ${data.id} \n Created Date : ${FormattedDateTime(
            data.createdDate
          )} `,
          color: "red",
        };
        edges.add(edgecopyOf);
        break;
    }
  }

  function addMemberEdge(fromId, data, KEY) {
    let fromIndex = findIndexById(documentMapping, fromId);
    let toIndex = findIndexById(memberMapping, data.id);

    switch (KEY) {
      case "ownerUID":
        addMemberEdges(data.id, "Doc owner", "gray");
        const edgeownerUID = {
          from: fromIndex,
          to: toIndex,
          label: "Owner",
          title: `Owner : ${data.id} \n Created Date : ${FormattedDateTime(
            data.createdDate
          )} `,
          color: "gray",
        };
        edges.add(edgeownerUID);
        break;
      case "holderUID":
        addMemberEdges(data.id, "Doc holder", "purple");
        const edgeholderUID = {
          from: fromIndex,
          to: toIndex,
          label: "Doc holder",
          title: `Doc holder : ${data.id} \n Created Date : ${FormattedDateTime(
            data.createdDate
          )} `,
          color: "purple",
        };
        edges.add(edgeholderUID);
        break;
      case "sharedWith":
        addMemberEdges(data.id, "Doc shared with", "green");
        const edgesharedWith = {
          from: fromIndex,
          to: toIndex,
          label: "Shared With",
          title: `Shared With : ${
            data.id
          } \n Created Date : ${FormattedDateTime(data.createdDate)} `,
          color: "green",
        };
        edges.add(edgesharedWith);
        break;

      case "signedBy":
        addMemberEdges(data.id, "Doc signed by", "yellow");
        const edgesignedBy = {
          from: fromIndex,
          to: toIndex,
          label: "Signed By",
          title: `Signed By : ${data.id} \n Created Date : ${FormattedDateTime(
            data.createdDate
          )} `,
          color: "yellow",
        };
        edges.add(edgesignedBy);
        break;

      case "verifiedBy":
        addMemberEdges(data.id, "Doc verified by", "purple");
        const verifiedBy = {
          from: fromIndex,
          to: toIndex,
          label: "Verified By",
          title: `Verified By : ${
            data.id
          } \n Created Date : ${FormattedDateTime(data.createdDate)} `,
          color: "purple",
        };
        edges.add(verifiedBy);
        break;

      case "issuedBy":
        addMemberEdges(data.id, "Doc issued by", "pink");
        const issuedBy = {
          from: fromIndex,
          to: toIndex,
          label: "Issued By",
          title: `Issued By : ${data.id} \n Created Date : ${FormattedDateTime(
            data.createdDate
          )} `,
          color: "pink",
        };
        edges.add(issuedBy);
        break;
    }
  }

  // Creating Nodes
  Data.forEach((item, i) => {
    // DOCUMENT NODE
    addDocumentNode(item, "parent");
    item?.copyOf?.map((data) => addDocumentNode(data, "document"));
    item?.versionOf?.map((data) => addDocumentNode(data, "document"));

    // Member Node
    item?.ownerUID?.map((data) => addMemberNode(data, "member"));
    item?.holderUID?.map((data) => addMemberNode(data, "member"));
    item?.sharedWith?.map((data) => addMemberNode(data, "member"));
    item?.signedBy?.map((data) => addMemberNode(data, "member"));
    item?.verifiedBy?.map((data) => addMemberNode(data, "member"));
    item?.issuedBy?.map((data) => addMemberNode(data, "member"));
  });

  // Creating Edges
  Data.forEach((item, i) => {
    // Document Edge
    item?.versionOf?.map((data) =>
      addDocumentEdge(item.documentUID, data, "versionOf")
    );

    // Document Edge
    item?.copyOf?.map((data) =>
      addDocumentEdge(item.documentUID, data, "copyOf")
    );

    // Memeber Edge
    item?.ownerUID?.map((data) =>
      addMemberEdge(item.documentUID, data, "ownerUID")
    );

    // Memeber Edge
    item?.holderUID?.map((data) =>
      addMemberEdge(item.documentUID, data, "holderUID")
    );

    // Memeber Edge
    item?.sharedWith?.map((data) =>
      addMemberEdge(item.documentUID, data, "sharedWith")
    );

    // Memeber Edge
    item?.signedBy?.map((data) =>
      addMemberEdge(item.documentUID, data, "signedBy")
    );

    // Memeber Edge
    item?.verifiedBy?.map((data) =>
      addMemberEdge(item.documentUID, data, "verifiedBy")
    );

    // Memeber Edge
    item?.issuedBy?.map((data) =>
      addMemberEdge(item.documentUID, data, "issuedBy")
    );
  });

  useEffect(() => {
    const container = document.getElementById("network-container");
    const data = { nodes, edges };

    const options = {
      physics: {
        enabled: true,
        barnesHut: {
          gravitationalConstant: -4000,
          springLength: 500,
        },
        minVelocity: 0.75,
      },

      nodes: {
        shape: "circle",
        size: 40,
        font: {
          color: "white",
          size: 20,
        },
      },
      edges: {
        width: 4,
        arrows: {
          to: {
            enabled: true,
            type: "arrow",
            scaleFactor: 2,
          },
        },
        font: {
          size: 20,
          strokeColor: "white",
          color: "white",
          strokeWidth: 0,
        },
        labelHighlightBold: false,
      },
    };

    const network = new Network(container, data, options);

    return () => {
      network.destroy();
    };
  }, []);

  return (
    <>
      <h5> Document Trace Graph</h5>
      <div className={classes.graphContainer}>
        <div className={classes.left}>
          <h6>Legends</h6>
          <div className={classes.NodeDiv}>
            {nodesStyle &&
              nodesStyle.map((item, index) => (
                <div key={index}>
                  <div
                    className={classes.circleNode}
                    style={{ backgroundColor: item.color }}></div>
                  <div>{item.label}</div>
                </div>
              ))}
          </div>

          <div className={classes.edgeDiv}>
            {edgesStyle &&
              edgesStyle.map((item, index) => (
                <div key={index}>
                  <div
                    className={classes.customArrow}
                    style={{ borderColor: item.color }}></div>
                  <div>{item.label}</div>
                </div>
              ))}
          </div>
        </div>
        <div className={classes.right}>
          <h6>Document Graph</h6>
          <div
            id="network-container"
            style={{
              height: "600px",
            }}></div>
        </div>
      </div>
      <Table bordered responsive className={classes.Table}>
        <thead>
          <tr>
            {Header.map((header, index) => (
              <td key={index}>{header.name}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {Data?.length > 0 ? (
            Data?.map((data, index) => (
              <tr key={index}>
                <td>{data.documentUID}</td>
                <td>
                  {data?.ownerUID?.length > 0
                    ? data?.ownerUID?.map((res, i) => (
                        <div key={i}>
                          {FindCompanyName(memeberList && memeberList, res.id)}
                        </div>
                      ))
                    : "No Record"}
                </td>
                <td>
                  {data?.holderUID?.length > 0
                    ? data?.holderUID?.map((res, i) => (
                        <div key={i}>
                          {FindCompanyName(memeberList && memeberList, res.id)}
                        </div>
                      ))
                    : "No Record"}
                </td>
                <td>
                  {data?.copyOf?.length > 0
                    ? data?.copyOf?.map((res, i) => (
                        <div key={i}>
                          {/* {FindCompanyName(memeberList && memeberList, res.id)} */}
                          {res.id}
                        </div>
                      ))
                    : "No Record"}
                </td>
                <td>
                  {data?.versionOf?.length > 0
                    ? data?.versionOf?.map((res, i) => (
                        <div key={i}>
                          {/* {FindCompanyName(memeberList && memeberList, res.id)} */}
                          {res.id}
                        </div>
                      ))
                    : "No Record"}
                </td>
                <td>
                  {data?.sharedWith?.length > 0
                    ? data?.sharedWith?.map((res, i) => (
                        <div key={i}>
                          {FindCompanyName(memeberList && memeberList, res.id)}
                        </div>
                      ))
                    : "No Record"}
                </td>
                <td>
                  {data?.signedBy?.length > 0
                    ? data?.signedBy?.map((res, i) => (
                        <div key={i}>
                          {FindCompanyName(memeberList && memeberList, res.id)}
                        </div>
                      ))
                    : "No Record"}
                </td>
                <td>
                  {data?.verifiedBy?.length > 0
                    ? data?.verifiedBy?.map((res, i) => (
                        <div key={i}>
                          {FindCompanyName(memeberList && memeberList, res.id)}
                        </div>
                      ))
                    : "No Record"}
                </td>
                <td>
                  {data?.issuedBy?.length > 0
                    ? data?.issuedBy?.map((res, i) => (
                        <div key={i}>
                          {FindCompanyName(memeberList && memeberList, res.id)}
                        </div>
                      ))
                    : "No Record"}
                </td>
              </tr>
            ))
          ) : (
            <div>No Record Found</div>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default NetworkGraph;
