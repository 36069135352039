import React from 'react';
import { useEffect } from 'react';
import './paymentCollection.css';
import { getPaymentsByLoanId, getPaymentCollection } from 'api/Payments';
import { useState } from 'react';
import { truncateStringID } from "utils/helper";

export const PaymentCollection = ({ response, loanId, companyList, memberId, response2 }) => {
    let oatPaymentAmount = 0;
    if(response2)
    {
        oatPaymentAmount = (parseFloat(response2.tradeValue) * parseFloat(response.milestonePaymentPercentage))/100;
    }
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const [paymentDetail, setPaymentDetails] = useState();
    const [paymentCollection, setPaymentCollection] = useState();
    useEffect(() => {
        getPaymentCollection(response?.financeAmount || oatPaymentAmount.toString(), response2?.countryOfDestination || "US", response?.financeCurrency || response2?.paymentCurrency, response?.financerMemberUID|| response2?.buyerMemberId , response2?.countryOfOrigin || 'IN', idToken)
            .then((res) => {
                setPaymentCollection(res);
            })
            .catch((error) => {
                console.log("paymentCollection error", error)
            });
    }, [response2, response])
    useEffect(() => {
        getPaymentsByLoanId(loanId, idToken, memberId)
            .then((res) => {
                let dateArray = [];
                res.length > 0 && res.forEach(item => {
                    dateArray.push(new Date(item.updatedDate));
                })
                dateArray.sort((date1, date2) => date1 - date2);
                const latestDate = new Date(dateArray[dateArray.length - 1]).toISOString();
                let newRes = [];
                newRes = res.length > 0 && res.filter((item) => {
                    return new Date(item.updatedDate).toISOString() == latestDate;
                })
                setPaymentDetails(newRes[0]);
            })
            .catch((error) => {
                console.log("paymentByLoan error", error)
            });
    }, [])

    const buyerMemberId = paymentDetail?.receiverMemberID ? paymentDetail?.receiverMemberID
        : response?.receiverMemberID ? response?.receiverMemberID
            : paymentDetail?.consumerMemberUID ? paymentDetail?.consumerMemberUID
                : response?.consumerMemberUID && response?.consumerMemberUID;

    const sellerMemberId = paymentDetail?.senderMemberID ? paymentDetail?.senderMemberID
        : response?.senderMemberID ? response?.senderMemberID
            : paymentDetail?.providerMemberUID ? paymentDetail?.providerMemberUID
                : response?.providerMemberUID && response?.providerMemberUID;

    const currency = paymentDetail?.currencyReceiver ? paymentDetail.currencyReceiver
        : response?.financeCurrency ? response?.financeCurrency
            : paymentDetail?.servicePaymentCurrency ? paymentDetail?.servicePaymentCurrency
                : response?.servicePaymentCurrency ? response?.servicePaymentCurrency : response2?.paymentCurrency;

    const paymentAmount = paymentDetail?.paymentAmount ? paymentDetail.paymentAmount
        : response?.financeAmount ? response?.financeAmount
            : paymentDetail?.paymentAmount ? paymentDetail?.paymentAmount
                : response?.servicePriceOffer ? response?.servicePriceOffer : oatPaymentAmount;

    const paymentMethodType = paymentDetail?.paymentMethodType ? paymentDetail.paymentMethodType
        : response?.paymentMethodType ? response?.paymentMethodType
            : paymentDetail?.paymentMethodType ? paymentDetail?.paymentMethodType
                : response?.preferredPaymentMethod ? response?.preferredPaymentMethod: response2?.preferredPaymentMethod;

    return (
        <div>
            { response ?<div style={{ backgroundColor: "black", width: "100%", padding: "0%", border:"1px solid white", padding:"20px", paddingTop:'10px', marginTop:"10px"}}>
                <div>
                    <div className='panelHeading' style={{marginTop:"20px"}}>Payment Collection</div>
                    <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Status</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentDetail?.paymentStatus ? paymentDetail.paymentStatus : 'New'}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Buyer Country</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{
                                companyList && companyList.map((item) => {
                                    if (item.memberUID === buyerMemberId) {
                                        return item.countryCode;
                                    }
                                })
                            }</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Seller Country</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'> {companyList.map((item) => {
                                if (item.memberUID === sellerMemberId) {
                                    return item.countryCode;
                                }
                            })}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Currency</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{currency}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Amount</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentAmount}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Collection Method</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentMethodType}</div>
                        </div>
                    </div>
                </div>
            </div>
            :
            <div className='panelHeading' style={{marginTop:"20px",border:"1px solid white", padding:"20px", paddingTop:'10px', fontSize:"11px"}} >
                 <div style={{marginTop:"10px", fontSize:"14px", marginBottom:"10px"}}>Payment Collection</div>
                  Payment not initiated
                </div>
            }
            { response2.escrowService!=="OFFLINE" &&  paymentCollection && paymentCollection.map((item)=>{
                return <div style={{ backgroundColor: "black", width: "100%", padding: "20px", border:"1px solid white", marginTop:"20px"}}>
                     <div>
                        <div className='panelHeading' style={{ marginTop:"10px"}}>Payment Rate</div>
                        <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
                            <div className='overviewListItem'>
                                <div className='overViewListFirstTile'>Currency</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.currency}</div>
                            </div>
                            <div className='overviewListItem'>
                                <div className='overViewListFirstTile'>Rate</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.rate}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className='overviewListItem' style={{width:"49%"}}>
                                <div className='overViewListFirstTile'>Amount</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.amount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            })
            }
        </div>
    )
}