import React from "react";
import styles from "../details.module.css";

const SupplierDetails = ({ Data }) => {
  const Response = [
    {
      name: "Supplier Tax Number",
      value: Data ? Data.Gstin : "NA",
    },
    {
      name: "Supplier Legal Name",
      value: Data ? Data.LglNm : "",
    },
    {
      name: "Supplier Address 1",
      value: Data ? Data.Addr1 : "",
    },
    {
      name: "Supplier Address 2",
      value: Data ? Data.Addr2 : "",
    },
    {
      name: "Supplier place",
      value: Data ? Data.Loc : "",
    },
    {
      name: "Pincode",
      value: Data ? Data.Pin : "",
    },
    {
      name: "Supplier State Code",
      value: Data ? Data.Stcd : "",
    },
  ];
  return (
    <div className={styles.summary}>
      {Response &&
        Response.map((data, i) => (
          <div className={styles.summaryWrapper} key={i}>
            <div style={{fontSize:"12px"}}>
              <span> {data.name} </span>
            </div>
            <div style={{fontSize:"12px"}}>
              <span style={addBlue}> {data.value} </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SupplierDetails;

const addBlue = {
  color: "#42A5F5",
};
