import { loginRequest } from "authConfig";

const requiresInteraction = (errorMessage) => {
  if (!errorMessage || !errorMessage.length) {
    return false;
  }

  return (
    errorMessage.indexOf("consent_required") > -1 ||
    errorMessage.indexOf("interaction_required") > -1 ||
    errorMessage.indexOf("login_required") > -1
  );
};

export function getIdToken(instance, accounts, setToken) {
  if (accounts.length > 0) {
    const request = {
      scopes: [...loginRequest.scopes],
      account: accounts[0],
    };
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setToken(response.idToken);
        sessionStorage.setItem(
          "personName",
          JSON.stringify(
            response.account &&
              response.account.idTokenClaims &&
              response.account.idTokenClaims.given_name
          )
        );
        sessionStorage.setItem(
          "companyName",
          JSON.stringify(
            response.account &&
              response.account.idTokenClaims &&
              response.account.idTokenClaims.company_name
          )
        );
        sessionStorage.setItem("idToken", JSON.stringify(response.idToken));
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (requiresInteraction(error.errorCode)) {
          instance.acquireTokenPopup(request).then((response) => {
            setToken(response.idToken);
            sessionStorage.setItem("idToken", JSON.stringify(response.idToken));
          });
        }
      });
  }
}

export async function getIdTokenAsync(instance, accounts) {
  if (accounts.length > 0) {
    const request = {
      scopes: [...loginRequest.scopes],
      account: accounts[0],
      popupWindowAttributes: {
        popupSize: { height: 600, width: 800 },
        popupPosition: {
          top: 50,
          left: 50,
        },
      },
    };

    return instance
      .acquireTokenSilent(request)
      .then((response) => {
        sessionStorage.setItem("idToken", JSON.stringify(response.idToken));
        return response;
      })
      .catch((error) => {
        // acquireTokenSilent can fail for a number of reasons, fallback to interaction
        if (requiresInteraction(error.errorCode)) {
          return instance.acquireTokenPopup(request).then((response) => {
            sessionStorage.setItem("idToken", JSON.stringify(response.idToken));
            window.location.reload();
            return response;
          });
        }
      });
  }
}

export function getClaim(accounts, key) {
  return accounts[0]?.idTokenClaims[key];
}
