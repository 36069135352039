
import React , {useContext, useEffect, useState} from 'react'
import styled from "styled-components";
import {CompanyNameFilter, notify} from '../../../../../../utils/helper'
import {storeContext} from '../../../../../../contexts/Store/Store'
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";

import styles from '../../../../invoice.module.css';
import {invoiceFinanceContext} from '../../../../../../contexts/invoiceFinance/invoiceFinanceContext'

import {NetworkURL} from '../../../../../../services/BaseURLInstance'

const Summary = () => {
    const {invoiceData} = React.useContext(invoiceFinanceContext)

    const [CompanyList , setCompanyList] = useState([]);
    const { fetchData } = useContext(storeContext)
    const { instance, accounts } = useMsal();
    const [idToken, setIdToken] = useState(null);
    getIdToken(instance, accounts, setIdToken);
    const companyId = getClaim(accounts, "company_member_id");

    useEffect(() => {
        const getData = async () => {
          let URL = `${NetworkURL}members?memberId=${companyId}`;
          const Data = await fetchData(URL)
          { Data && Data.data && (
            setCompanyList(Data.data)
          )} 
          if(Data && Data.error) { 
            notify('warn')
          }
        }
        getData();
      },[])

    const Response = [
        {
            name: 'Status',
            value: invoiceData.txlStatus ? invoiceData.txlStatus : 'Unknown',
        },
        {
            name: 'Funding Details',
            value: 'PENDING'
        },
        {
            name: 'Buyer Consent',
            value: 'PENDING'
        },
        {
            name: 'Value',
            value: invoiceData.txlInvoiceAmount ? invoiceData.txlInvoiceAmount : 'Unknown',
        },
        {
            name: 'Last Activity',
            value: invoiceData.updatedDate ? invoiceData.updatedDate : 'Unknown',
        },

        {
            name: 'Invoice Document',
            value: invoiceData.invoiceNumber ? invoiceData.invoiceNumber : 'Unknown',
        },
        {
            name: 'Buyer',
            // value: invoiceData.txlBuyerMemberId ? invoiceData.txlBuyerMemberId : 'Unknown',
            value : CompanyNameFilter(CompanyList, invoiceData.txlBuyerMemberId).length >=1 ? CompanyNameFilter(CompanyList, invoiceData.txlBuyerMemberId)[0].memberName : 'Unknown' 
        },
        {
            name: 'Seller',
            // value: invoiceData.txlSellerMemberId ? invoiceData.txlSellerMemberId : 'Unknown',
            value: CompanyNameFilter(CompanyList, invoiceData.txlSellerMemberId).length >=1 ? CompanyNameFilter(CompanyList, invoiceData.txlSellerMemberId)[0].memberName : 'Unknown Member' 
        },
        
    ]

    return (
    <div className={styles.summary}>

        { Response && Response.map((data, i) => (
           <div className={styles.summaryWrapper} key={i} >
            <div> <span> {data.name} </span> </div>
            <div> <span style = {

              data.value === 'PENDING' ?  addRed : 
              data.name === 'Buyer' ?  addBlue : 
              data.name === 'Invoice Document' ?  addBlue :
              data.name === 'Seller' ?  addBlue : addWhite
              
            }> {data.value} </span> </div>
           </div> 
        ))}

    </div>
  )
}

export default Summary;


const addRed = {
  color: "red"       
}

const addWhite = {
    color: "white"       
  }

const addGreen = {
    color: "green"
}

const addBlue = {
    color: "#42A5F5"
}


