import React from "react";
import TextField from "@mui/material/TextField";
import styles from "./Fields.module.css";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "rgb(66, 165, 245)",
    // fontSize: "12px ",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "rgb(66, 165, 245)",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&:hover fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgb(66, 165, 245)",
    },
  },
  fontSize: "12px ",
});

export default ({
  name,
  placeholder,
  required,
  options,
  handleChange,
  value,
  readOnly,
  disabled,
}) => {
  return (
    <div className={styles.inputWrapper}>
      <CssTextField
        id="outlined-select-currency"
        select
        disabled={disabled}
        name={name}
        label={placeholder}
        autoComplete="off"
        required={required}
        className={styles.inputStyle}
        value={value}
        inputProps={{ readOnly: readOnly }}
        onChange={handleChange}
        style={{ color: "rgb(66, 165, 245)" }}
        InputLabelProps={{
          style: { color: "rgb(66, 165, 245)" },
        }}
        sx={{
          svg: { color: "rgb(66, 165, 245)" },
        }}>
        {options &&
          options.map((lpt) => (
            <MenuItem key={lpt.value} value={lpt.value}>
              {lpt.label}
            </MenuItem>
          ))}
      </CssTextField>
    </div>
  );
};

// import "./styles.css";
// import React, { useState, useMemo } from "react";
// import {
//   Box,
//   FormControl,
//   Select,
//   MenuItem,
//   InputLabel,
//   ListSubheader,
//   TextField,
//   InputAdornment,
// } from "@mui/material";
// import {BiSearchAlt} from "react-icons/bi";
// export default ({name, placeholder, required, options, handleChange, value, readOnly, disabled,
// }) => {
// //   const allOptions = options?.map(item=>{
// //     return item.label
// //   })
//   const containsText = (text, searchText) => {
//     if(text){
//       return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
//     }
//   }
//   const [searchText, setSearchText] = useState("");
//   const displayedOptions = useMemo(
//     () => options?.filter((option) => containsText(option.label, searchText)),
//     [searchText, options]
//   );

//   return (
//     <Box>
//       <FormControl fullWidth focused required={required} disabled={disabled}>
//         <InputLabel id="search-select-label">{placeholder}</InputLabel>
//         <Select
//           name={name}
//           inputProps={{readOnly: readOnly}}
//           MenuProps={{
//             PaperProps: {
//               sx: {
//                 maxHeight: 230,
//                 '& .MuiMenuItem-root': {
//                   padding: 0.5,
//                   paddingLeft:2,
//                   fontSize: 12,
//                 },
//               },
//             },
//           }}
//           labelId="search-select-label"
//           id="search-select"
//           value={value}
//           label={placeholder}
//           onChange={(e) => handleChange(e)}
//           onClose={() => setSearchText("")}
//           variant="outlined"
//           sx={{
//           color: "#fff",
//           "& .MuiSvgIcon-root": {
//               color: "rgb(66, 165, 245)",
//           },
//           }}

//           // This prevents rendering empty string in Select's value
//           // if search text would exclude currently selected option.
//           // renderValue={() => value}
//         >
//           {/* TextField is put into ListSubheader so that it doesn't
//               act as a selectable item in the menu
//               i.e. we can click the TextField without triggering any selection.*/}
//           <ListSubheader sx={{color:"red", backgroundColor:"black",margin:1, borderRadius:0.5,padding:2, paddingBottom:1, marginTop:0}}>
//             <TextField
//              focused
//              sx={{
//               }}
//               size="small"
//               label='Search Item'
//               autoFocus
//               fullWidth
//               InputProps={{
//                 startAdornment: (
//                   <InputAdornment position="start">
//                     <BiSearchAlt color = "rgb(66, 165, 245)" />
//                   </InputAdornment>
//                 )
//               }}
//               onChange={(e) => setSearchText(e.target.value)}
//               onKeyDown={(e) => {
//                 if (e.key !== "Escape") {
//                   e.stopPropagation();
//                 }
//               }}
//             />
//           </ListSubheader>
//           {displayedOptions?.map((option, i) => (
//             <MenuItem key={i} value={option.value}>
//               {option.label}
//             </MenuItem>
//           ))}
//         </Select>
//       </FormControl>
//     </Box>
//   );
// }
