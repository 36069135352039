import React from "react";
import { Checkbox, Badge } from '@mui/material';
import { FaDownload } from "react-icons/fa";
import { AiOutlineCloudUpload } from "react-icons/ai";

export const TradeAccountColumn = [
    {
        Header: "Select",
        accessor: "select",
        Cell: (cell) => (
            <Checkbox checked={cell.value} />
        )
    },
    {
        Header: "Open Trade Id",
        accessor: "open_trade_id",
    },
    {
        Header: "Counter Party",
        accessor: "counter_party",
    },
    {
        Header: "Created At",
        accessor: "created_at",
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {cell.value.map(f => {
                    return (
                        <>
                            <button
                                className="buttonIcon"
                                style={{
                                    backgroundColor: "#42A5F5",
                                    border: "none",
                                    color: "black",
                                    fontSize: "17px",
                                    padding: "9px 12px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    margin: "5px 20px 5px -10px"

                                }}
                                onClick={() => console.log(cell)}
                            >
                                {f}
                            </button>
                        </>
                    )
                })
                }
            </div>
        ),
    },
];

export const PaymentColumn = [
    {
        Header: "Open Trade Id",
        accessor: "open_trade_id",
    },
    {
        Header: "Milestone",
        accessor: "milestone",
    },
    {
        Header: "Due Date",
        accessor: "due_date",
    },
    {
        Header: "Amount",
        accessor: "amount",
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                {cell.value.map(f => {
                    return (
                        <>
                            <button
                                className="buttonIcon"
                                style={{
                                    backgroundColor: f === "Payment Declined" ? "red" : "green",
                                    border: "none",
                                    color: "black",
                                    fontSize: "17px",
                                    padding: "9px 12px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    margin: "5px 20px 5px -10px"
                                }}
                                onClick={() => console.log(cell)}
                            >
                                {f}
                            </button>
                        </>
                    )
                })
                }
            </div>
        ),
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <FaDownload size={20} />
                <AiOutlineCloudUpload size={22} />
            </div>
        ),
    }
]

export const ScheduleColumn = [
    {
        Header: "S.No",
        accessor: "serial_number"
    },
    {
        Header: "Milestone",
        accessor: "milestone",
    },
    {
        Header: "Document",
        accessor: "document",
    },
    {
        Header: "Payment",
        accessor: "payment",
    },
    {
        Header: "Amount",
        accessor: "amount",
    },
    {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Badge color={cell.value === "Active" ? "success" : "warning"} badgeContent=" "></Badge>
            </div>
        ),
    },
    {
        Header: "Action",
        accessor: "action",
        Cell: (cell) => (
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                <FaDownload size={20} />
                <AiOutlineCloudUpload size={22} />
            </div>
        ),
    },
]