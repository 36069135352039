import { useState, useEffect, useContext } from 'react';
import { BsPatchQuestion, BsPatchCheck } from 'react-icons/bs';
import { MdOutlinePayments } from 'react-icons/md';
import { storeContext } from "../../../../contexts/Store/Store";
import { openTradeAccount } from "services/BaseURLInstance";
import { truncateStringID } from "utils/helper";
import { useNavigate } from 'react-router-dom';
import { UseRequest } from "utils/API/Request.js";
import './changeState.css';
import Content from 'components/UI/Layout/Content';

export default ({subStageDate, data}) => {
    console.log("testingTrade",subStageDate, data);
    const { fetchData, fetchCompanyList } = useContext(storeContext);
    const [paymentTermData, setPaymemtTermData] = useState();
    const [paymentTermDataSchedule, setPaymemtTermDataSchedule] = useState();
    const openTradeAccountId = '479a5828-4dd3-436a-997e-7bac9fe8eed7'
    const navigate = useNavigate();
    useEffect(() => {
        const getPaymentTermData = async () => {
            let idToken = JSON.parse(sessionStorage.getItem("idToken"));
            let URL = `${openTradeAccount}openaccounttrades/details/${subStageDate.tradeId}`;
            fetchCompanyList(URL, idToken)
                .then((openTradeAccountData) => {
                    // console.log("openTradeAccountData", openTradeAccountData)
                    setPaymemtTermData(openTradeAccountData);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        getPaymentTermData();
    }, []);

    useEffect(() => {
        let complete = 0;
        if(paymentTermData!=='' && paymentTermData!==null && paymentTermData!==undefined && paymentTermData?.openTradeAccountId)
        {
        const getPaymentTermData = async () => {
            let idToken = JSON.parse(sessionStorage.getItem("idToken"));
            let URL = `${openTradeAccount}/openaccounttrades/schedules?openAccountTradeId=${paymentTermData?.openTradeAccountId}&sellerMemberUID=&buyerMemberUID=&status=`;
            fetchCompanyList(URL, idToken)
                .then((openTradeAccountDataSchedule) => {
                    // console.log("openTradeAccountDataSchedule", openTradeAccountDataSchedule)
                    openTradeAccountDataSchedule.map((item) => {
                        if (item.tradeMilestoneStatus === 'MILESTONE_COMPLETED') {
                            complete = complete + 1;
                        }
                    })
                    setPaymemtTermDataSchedule({
                        data: openTradeAccountDataSchedule,
                        complete: complete,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        getPaymentTermData();
      }
    }, [paymentTermData]);

    const handleOpenAccountTrade = () => {
       if(!paymentTermData?.openTradeAccountId)
         {
            const tempPaymentTermData = {
                openTradeAccountId: "",
                sellerMemberId: data.sellerMemberId,
                buyerMemberId: data.buyerMemberId,
                tradeId:data.tradeId,
                RefSellerVirtualBankAccountId: "",
                RefBuyerVirtualBankAccountId: "",
                openTradeStatus: "DRAFT",
                industryCategory: [data.industry],
                industrySubCategory: "",
                countryOfOrigin:data.countryOfOrigin,
                countryOfDestination: data.countryOfDestination,
                contractDate: data.contractDate,
                poReference: data.poReference,
                invoiceReference: data.invoiceReference,
                escrowService: "",
                escrowServiceProviderUID: "",
                escrowConsentStatus: "",
                referenceEscrowAccount: "",
                paymentCurrency: data.paymentCurrency,
                preferredPaymentMethod: data.preferredPaymentMethod, 
                escrowPayment: false,
                notes: "",
                instrumentType: "",
                refInstrumentId: "",
                tags: data.tag,
                isDeleted: false,
                createdBy: " ",
                updateBy: " ",
                createdDate: " ",
                updatedDate: " ",
                tradeValue: data.tradeValue,
              }
          const options = {
            method: "POST",
            body: tempPaymentTermData,
          };
          UseRequest(`${openTradeAccount}openaccounttrades`, options).then(
            (data) => {
              let { message, status } = data;
              if (message || status) {
                // Error(message || "Something Went Wrong");
              } else {
                // Success("Trade Added Successfully...");
                setPaymemtTermData(data);
              }
            }
          );
        }
    }

    const handlePaymentTerm = () =>{
         navigate(`../../trade-account/open-account/${paymentTermData.openTradeAccountId}`, {state:{index:1}})
    }
    return (
        <div className="participantcard">
            <Content padding="0.3%">
            <div style={{ display: "flex" }}>
                <div className="textinnerdiv" style={{ color: "#2180D1", marginBottom: "2%", width: "50%", padding:"0px 2px" }}>
                    Payment Terms
                </div>
                <div className="textinnerdiv" style={{ color: "#2180D1", marginBottom: "2%", width: "50%", textAlign: "right" , padding:"0px 2px"}}>
                    <button className="link">Manage Payment Terms <MdOutlinePayments size={25} color={'white'} /></button>
                </div>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ width: "70%", borderRight: "2px solid white" }}>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div style={{ display: "flex", width: "80%" }}>
                            <div style={{ display: "flex", fontSize: "64px", marginTop: "-22px", marginRight: "4px" }}>{paymentTermDataSchedule?.data?.length}</div>
                            <div>
                                <div style={{ fontSize: "22px" }}>Payment Terms</div>
                                <div style={{ fontSize: "12px" }}>Active - { (paymentTermDataSchedule?.data?.length && paymentTermDataSchedule?.complete) ? paymentTermDataSchedule?.data?.length - paymentTermDataSchedule?.complete : "0"}, Complete - {paymentTermDataSchedule?.complete ?? "0" }</div>
                            </div>
                        </div>
                        <div style={{ width: "20%" }}>
                            <div style={{ margin: "4%", fontSize: '12px', display: 'flex' }}>
                                <div><BsPatchQuestion size={20} color={'#DC3545'} /></div>
                                <div style={{ marginTop: "2px", marginLeft: "5px" }}>Delayed</div>
                            </div>
                            <div style={{ margin: "4%", fontSize: '12px', display: 'flex' }}>
                                <div><BsPatchCheck size={20} color={'#FFC107'} /> </div>
                                <div style={{ marginTop: "2px", marginLeft: "5px" }}>Upcoming</div>
                            </div>
                            <div style={{ margin: "4%", fontSize: '12px', display: 'flex' }}>
                                <div><BsPatchCheck size={20} color={'#198754'} /> </div>
                                <div style={{ marginTop: "2px", marginLeft: "5px" }}>Completed</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            paymentTermDataSchedule?.data?.map((item) => {
                                return <div style={{ display: "flex", margin: "1%", width: "100%" }}>
                                    <div style={{ margin: "1%", width: "5%" }}>{
                                        item.tradeMilestoneStatus === 'MILESTONE_COMPLETED'
                                            ? <BsPatchCheck size={20} color={'#198754'} />
                                            : <BsPatchCheck size={20} color={'#FFC107'} />
                                    }
                                    </div>
                                    <div style={{ margin: "1%", width: "75%", fontSize:"12px" }}>ETA {new Date(item.milestoneDate).toLocaleDateString()} {item.tradeStage}/{item.tradeSubStage} {item.documentGroups[0].docCategory} - {item.documentGroups[0].docSubCategory}</div>
                                    <button className="link" style={{ margin: "1%", color: "#2180D1", width: "20%", fontSize:"12px" }} onClick={() => navigate(`../../trade-account/open-account/${paymentTermData.openTradeAccountId}`)}>{item.openTradeAccountId
                                        ? `${truncateStringID(item.openTradeAccountId, 7, " OAT")}`
                                        : "Not Available"}</button>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div style={{ width: "30%", marginLeft: "2%" }}>
                    <div style={{ width: "100%", height: "100%" }}>
                        <div>
                         { paymentTermData && paymentTermData?.openTradeAccountId &&
                        <button className="link" style={{ width: "100%", textAlign: 'center', color: "#2180D1", fontSize:"14px", background:"transparent"}} onClick={() => navigate(`../../trade-account/open-account/${paymentTermData.openTradeAccountId}`)}>
                            {console.log(`../../trade-account/open-account/${paymentTermData.openTradeAccountId}`)}
                            Open Account Trade -{paymentTermData?.openTradeAccountId
                                ? `${truncateStringID(paymentTermData?.openTradeAccountId, 7, " OAT")}`
                                : "Not Available"}
                        </button>
                         }
                        <div style={{ width: "100%", textAlign: 'center', color: "#2180D1", display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2rem" }}>
                            {paymentTermData?.openTradeAccountId && paymentTermData?.openTradeStatus === 'TRADE_COMPLETED'
                                ? <div style={{  color: "#198754", width: "70%", padding: "1%", borderRadius: "5px", fontSize:"12px"}}><span style={{color:"white"}}>Status:</span> {paymentTermData?.openTradeStatus}</div>
                                : paymentTermData?.openTradeAccountId && <div style={{  color: "#FFC107", width: "70%", padding: "1%", borderRadius: "5px", fontSize:"12px" }}><span style={{color:"white"}}>Status:</span> {paymentTermData?.openTradeStatus}</div>
                            }
                        </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <button className='createPayment' style={{ backgroundColor: "#42A5F5", width: "60%", textAlign: 'center', padding: "2%", borderRadius: "5px", margin: '3%' }} onClick = { paymentTermData?.openTradeAccountId ? handlePaymentTerm :handleOpenAccountTrade }>
                               {paymentTermData?.openTradeAccountId? 'Manage Payment Term' : 'Create Open Account'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </Content>
        </div>
    )
}