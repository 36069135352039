import { useState, useEffect } from "react";
// import { useKeycloak } from "@react-keycloak/web";
// import { API_ACCESS_INFO } from "./api";
import { getIdToken, getIdTokenAsync } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";

export default function useFetch({
  api,
  method,
  url,
  data = null,
  config = null,
}) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  //   const { keycloak, initialized } = useKeycloak();
  //   const kcToken = keycloak?.token ?? "";
  const { instance, accounts } = useMsal();

  useEffect(() => {
    getIdTokenAsync(instance, accounts).then((token) => {

      const fetchData = async () => {
        try {
          const configModify = JSON.parse(config) || {};
          if (configModify) {
            configModify.params = {
              ...(configModify.params ? configModify.params : {}),
              // ...API_ACCESS_INFO,
            };
            //   if (initialized) {
            configModify.headers = {
              requireAuthentication: true,
              Authorization: `Bearer ${token}`,
              "x-api-key":
                "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            };
            //   }
          }
          api[method](url, configModify, JSON.parse(data))
            .then((res) => {
              setResponse(res.data);
            })
            .catch((err) => {
              let msg = "";
              if (err.response?.data?.error?.message) {
                msg = err.response.data.error.message;
              } else {
                msg = err.response.data;
              }
              setError(msg);
            })
            .finally((res) => {
              setIsLoading(false);
            });
        } catch (err) {
          setError(err);
        }
      };
      fetchData();
    })
  }, []);

  return { response, error, isLoading };
}
