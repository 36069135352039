import React, { useState } from "react";
import classes from "../../../components/UI/Table/GlobalTable.module.css";
import { FindCompanyName, truncateStringID } from "../../../utils/helper";
import { GoMail } from "react-icons/go";
import { RiWechatLine } from "react-icons/ri";
import { BsBell } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";

const DealDetailTable = ({
  data,
  companyList,
  dealStatus,
  handledealStatus,
  serviceDealDetails,
  hanndleCraateGroup,
}) => {
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const header = [
    {
      name: "Service Deal",
      flex: "flex-1",
    },
    {
      name: "Consumer",
      flex: "flex-1",
    },
    {
      name: "Provider",
      flex: "flex-1",
    },
    {
      name: "Quoted Max Price",
      flex: "flex-1",
    },
    {
      name: "Category",
      flex: "flex-1",
    },
    {
      name: "Status",
      flex: "flex-1",
    },
    {
      name: "Last Activity Date",
      flex: "flex-1",
    },
    {
      name: "Action",
      flex: "flex-1",
    },
  ];

  const fetchData = async (companyId, id2, name) => {
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        hanndleCraateGroup(nData, name, name);
        setMemberList(nData);
      });
  };

  const chatHandler = (data) => {
    if (data?.consumerMemberUID !== "" && data?.providerMemberUID !== "") {
      setItem(data);
      let name = data.serviceDealId
        ? truncateStringID(data.serviceDealId, 7, "SD")
        : "NA";
      fetchData(data?.consumerMemberUID, data?.providerMemberUID, name);
    }
  };

  return (
    <div>
      <div className={classes["invoice-tableHeader"]}>
        {header.map((list) => (
          <div key={list.name} className={classes[`${list.flex}`]}>
            {list.name}
          </div>
        ))}
      </div>

      {data.map((list) => (
        <>
          <div className={classes["invoice-tableb"]}>
            <div className={classes["flex-1"]}>
              <BsBell style={{ marginRight: "5px" }} />
              {list.serviceDealId
                ? truncateStringID(list.serviceDealId, 7, "SD")
                : "NA"}
            </div>
            <div className={classes["flex-1"]}>
              <span></span>
              {FindCompanyName(companyList, list.consumerMemberUID)}
            </div>

            <div className={classes["flex-1"]}>
              {FindCompanyName(companyList, list.providerMemberUID)}
            </div>

            <div className={classes["flex-1"]}>
              {list.servicePriceOffer} {list.servicePaymentCurrency}
            </div>
            <div className={classes["flex-1"]}>{list.serviceCategory[0]}</div>
            <div className={classes["flex-1"]}>{list.serviceDealStatus}</div>
            <div className={classes["flex-1"]}>
              {new Date(list.lastUpdatedDate).toLocaleDateString()}
            </div>
            <div className={classes["flex-1"]} style={{ cursor: "pointer" }}>
              <GoMail style={{ marginRight: "15px" }} size="22" />
              <OverlayTrigger
                   trigger={["hover", "focus"]}
                   key="top"
                   placement="top"
                   overlay={
                    <Popover id="popover-positioned-top">
                       <Popover.Body>
                        <strong>Chat</strong>
                       </Popover.Body>
                    </Popover>
                  }>
                  <span onClick={() => chatHandler(list)}>
                    <RiWechatLine size="22" />
                  </span>
                </OverlayTrigger>
              {/* <span onClick={() => chatHandler(list)}>
                <RiWechatLine size="22" />
              </span> */}
            </div>
          </div>
        </>
      ))}
    </div>
  );
};

export default DealDetailTable;
