import React, { useState, useLayoutEffect, useContext } from "react";
import { Button, Card } from "@mui/material";
import { MdAdd } from "react-icons/md";
import SolutionStyled from "components/StyledCss/Solution.styled";
import Wrapper from "components/StyledCss/Wapper.styled";
import { useNavigate } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { tradeURL } from "../../services/BaseURLInstance";
import { storeContext } from "../../contexts/Store/Store";
import Loader from "components/Loader/Loader";
import Content from "components/UI/Layout/Content";
import { ToastContainer } from "react-toastify";
import classes from "./service.module.css";
import { TbCircleFilled } from "react-icons/tb";



function Trade() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isError, setIsError] = useState(null);
  const handleOnClick = (path) => {
    let url = window.location.href;
    localStorage.setItem("lasturl", url);
    navigate(path);
  };

  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const { fetchData, fetchCompanyList } = useContext(storeContext);
  const [additionalData, setAdditionalData] = useState();
  useLayoutEffect(() => {
    setIsLoading(true);
    const findData = async () => {
      let URL = `${tradeURL}trades/summary?memberId=${companyId}`;
      await fetchData(URL, idToken).then((response) => {
        setIsLoading(false);
        setAdditionalData(response.data);
      });
    };
    findData();
  }, []);

  const SOLUTIONS_TYPE = [
    {
      name: "New Trade",
      icon: <HiOutlineUserGroup />,
      path: "/tradeover/newtrade",
    },
  ];

  const handleNavigate = () => {
    navigate("../newtrade");
  };

  return (
    <div>
      <div style={{ padding: "2% 2%", background: "#1E1E1E" }}>
          <Content>
            {isLoading ? (
              <span>
                Loading....
                <Loader />
              </span>
            ) : (
              <>
                { additionalData && (
                  <>
                    <div
                      className="d-flex justify-content-between my-2"
                      style={{ color: "#42a5f5" }}>
                      <div>
                        <h5>Trades Summary</h5>
                      </div>
                      <div>
                        <h5>All Trades</h5>
                      </div>
                    </div>
                    {/* <ColumnList list={serviceOverview} response={response} /> */}
                    <div className={classes.serviceSummary}>
                      <div>
                        <div className={classes.summaryHead}>
                          <div className={classes.summaryHead1}>
                            {additionalData.newServiceRequisitions
                              ? additionalData.newServiceRequisitions
                              : 0}
                          </div>
                          <div className={classes.summaryHead2}>
                            <div style={{ fontSize: "22px" }}>
                              New Trades
                            </div>
                          </div>
                        </div>
                        <div className={classes.summaryAll}>
                          <div className={classes.summaryList}>
                            <span>
                              <span style={{ position: "relative" }}>
                                <TbCircleFilled size={38} color={"#dc3545"} />
                                <span className={classes.iconValue}>
                                  {additionalData.openTrades ? additionalData.openTrades : 0}
                                </span>
                              </span>
                            </span>
                            <span>Blocked</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#dc3545"} />
                              <span className={classes.iconValue}>
                                {additionalData.expiredRequisitions
                                  ? additionalData.expiredRequisitions
                                  : 0}
                              </span>
                            </span>
                            <span>Aborted</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#d39e00"} />
                              <span className={classes.iconValue}>
                                {additionalData.tradesInProgress
                                  ? additionalData.tradesInProgress
                                  : 0}
                              </span>
                            </span>
                            <span>In-Progress</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#218838"} />
                              <span className={classes.iconValue}>
                                {additionalData.closedTrades
                                  ? additionalData.closedTrades
                                  : 0}
                              </span>
                            </span>
                            <span>Completed</span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className={classes.summaryHead}>
                          <div className={classes.summaryHead1}>
                            {additionalData.subscribedServices
                              ? additionalData.subscribedServices
                              : 0}
                          </div>
                          <div className={classes.summaryHead2}>
                            <div style={{ fontSize: "22px" }}>
                              Subscribed Services
                            </div>
                          </div>
                        </div>
                        <div className={classes.summaryAll}>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#c82333"} />
                              <span className={classes.iconValue}>
                                {additionalData.toBeSubscribedServices
                                  ? additionalData.toBeSubscribedServices
                                  : 0}
                              </span>
                            </span>
                            <span>Aborted</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#c82333"} />
                              <span className={classes.iconValue}>
                                {additionalData.expiredServicedeals
                                  ? additionalData.expiredServicedeals
                                  : 0}
                              </span>
                            </span>
                            <span>Closed</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#e0a800"} />
                              <span className={classes.iconValue}>
                                {additionalData.approvedServiceDeals
                                  ? additionalData.approvedServiceDeals
                                  : 0}
                              </span>
                            </span>
                            <span>Not Subscribed</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#e0a800"} />
                              <span className={classes.iconValue}>
                                {additionalData.inProgressServiceDeals
                                  ? additionalData.inProgressServiceDeals
                                  : 0}
                              </span>
                            </span>
                            <span>In-Pursuit</span>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className={classes.summaryHead}>
                          <div className={classes.summaryHead1}>{additionalData?.pendingDocumentUploads ? additionalData?.pendingDocumentUploads: 0}</div>
                          <div className={classes.summaryHead2}>
                            <div style={{ fontSize: "22px" }}>
                              Pending Documents
                            </div>
                          </div>
                        </div>
                        <div className={classes.summaryAll}>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#c82333"} />
                              <span className={classes.iconValue}>{additionalData?.pendingSignature ? additionalData?.pendingSignature : 0}</span>
                            </span>
                            <span>Pending TP Sign</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#e0a800"} />
                              <span className={classes.iconValue}>
                                {additionalData.serviceDealsPaymentPending
                                  ? additionalData.serviceDealsPaymentPending
                                  : 0}
                              </span>
                            </span>
                            <span>Pending Doc Share</span>
                          </div>
                          <div className={classes.summaryList}>
                            <span style={{ position: "relative" }}>
                              <TbCircleFilled size={38} color={"#218838"} />
                              <span className={classes.iconValue}>{additionalData?.newDocSignatureRequest? additionalData?.newDocSignatureRequest: 0}</span>
                            </span>
                            <span>Sign Requests</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {isError && (
                  <div className="alert alert-danger" role="alert">
                    {isError}
                  </div>
                )}
              </>
            )}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </Content>
      </div>
    </div>
  );
}

export default Trade;
