import React, { useState, useEffect } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import { CheckCategoryTitle, CheckCountryTitle, CheckCurrencyTitle ,CheckPortTitle } from "utils/helper";

import Currenciesr from 'jsons/Currenciesr.json';
import { Countries } from "utils/DropDown/Country";

import { TextArea } from "components/fields";



const ImportCompliance =({response})=>{









    const [serviceDetails, setServiceDetails] = useState([]);
    useEffect(() =>{
    if(response && response.ServiceReqDetails){
    setServiceDetails(JSON.parse(response.ServiceReqDetails))
    
    //JSON.parse(response.ServiceReqDetails.commodity)
    }
    },[response]);







console.log(serviceDetails  , "saurabh")
  
    return(<>

{response && serviceDetails ?
        <>



      <div style={{ margin:"10px" }}>COMPLIANCE IMPORT DECLARATIONS


</div>

<div className={classes.source}>
            <div style={{ width: "50%", margin:"10px"}}>
            <Input
                  name="ShipperName"
                  placeholder="Supplier Name"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ShipperName}
                // readOnly={true}
                />
              </div>
              

              <div style={{ width: "50%", margin:"10px"}}>
              <Input
                  name="ReceiverName"
                  placeholder="Buyer Name"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ReceiverName}
                  
                // readOnly={true}
                />
             
  
              </div>
            </div>
            <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px"}}>
            <Input
                  name="contactInfoShipper"
                  placeholder="Supplier Contact Information"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.contactInfoShipper}
                // readOnly={true}
                />
              </div>
              

              <div style={{ width: "50%", margin:"10px"}}>
              <Input
                  name="contactInfoBuyer"
                  placeholder="Buyer Contact Information"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.contactInfoBuyer}
                  
                // readOnly={true}
                />
             
  
              </div>
            </div>
            


            <div className={classes.source}>
            <div style={{ width: "100%", margin:"10px"  }}>
            <Input
                  name="ShipperAddress"
                  placeholder="Supplier Address"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ShipperAddress}
                // readOnly={true}
                />
              </div>
              </div>

            <div className={classes.source}>

              <div style={{ width: "100%", marginBottom:"10px" }}>
            <Input
                  name="ReceiverAddress"
                  placeholder="Buyer Address "
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ReceiverAddress }
                // readOnly={true}
                />
              </div>
              </div>

     
          
          <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
            <Input
                 options={Countries}
                  name="countryFrom"
                  placeholder="Country  From"
                  readOnly={true}
                  value={CheckCountryTitle(serviceDetails.countryFrom)}
                // readOnly={true}
                />
              </div>
          

              <div style={{ width: "50%", margin:"10px" }}>
              <Input
                options={Countries}
                  name="countryTo"
                  placeholder="Country To"
                  readOnly={true}
                  value={CheckCountryTitle(serviceDetails.countryTo)}
                // readOnly={true}
                />
              </div>
            </div>
          {/* Countries */}
        
             
         
    
    
       

              <div>
             <div>
              <div style={{ margin: "10px" }}>
                <label>COMMODITY</label>
                </div>
                <div className={classes.source}>
                <div style={{ width: "50%", margin:"10px", paddingRight:"20px" }}>
                 <Input
                  //options={Industry}
                  name="commodity"
                  placeholder="Product Category"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.commodity}
                // readOnly={true}
               //     
               //     name="memberlist"
               //     selected={selectStatus}
               //     placeholder="Status"
               //     //options={InvoiceStatus}
               //    
               //   />
                />
                </div>
              </div>
              
            </div> 
            
            <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
            <Input
                  name="ProductName"
                  placeholder="Product Name"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.ProductName}
                // readOnly={true}
                />
              </div>
              {/* : [Insert Product Name]
               : [Insert Product Description]
               : []
                Shipper Address : [ Insert Address ] */}

              <div style={{ width: "50%", margin:"10px" }}>
                <Input
                  name="productDescription"
                  placeholder="Product Description"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.productDescription}
                // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>

            <div style={{ width: "50%", margin:"10px", paddingRight:"20px" }}>
              <Input
                  name="hsnOfCode"
                  placeholder="HSN code of Goods"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.hsnOfCode}
                // readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
             <Input
                
                  name="quantityType"
                  placeholder="Quantity"
                  value={serviceDetails && serviceDetails.quantityType}
                  readOnly={true}
                />
         
              </div>
              <div style={{ width: "50%", margin:"10px" }}>
              <Input
                  name="quantity"
                  placeholder="Quantity"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.quantity}
             
                />
              </div>
              </div>
              
              <div className={classes.source}>
            <div style={{ width: "50%", margin:"10px" }}>
            <Input
                  options={Currenciesr}
                  readOnly={true}
                  name="currencyType"
                  placeholder="Currency"
                  value={ CheckCurrencyTitle(serviceDetails.currencyType)}
                  
                />
            
              </div>
              <div style={{ width: "50%", marginBottom:"10px" }}>
              <Input
                  name="currencyAmount"
                  placeholder=  {`Estimated Cost of Goods or Services  (${serviceDetails && serviceDetails.currencyType})`}
                  readOnly={true}
                  value={serviceDetails && serviceDetails.currencyAmount}
                // readOnly={true}
                />
              </div>
              </div>
            
              
            

            <div className={classes.source}>

              
            </div>
            </div>










          

              <div >
                {/* <label>INCOTERM 


</label> */}
              </div>
              <div className={classes.source}>
              <div style={{  width: "50%", margin:"10px", paddingRight:"20px" }}>
               <Input
                  ////options={incoterm}
                  name="incoterm"
                  placeholder="Incoterm"
                  value={serviceDetails && serviceDetails.incoterm}
                  readOnly={true}
                />
               
              </div>

              
              { serviceDetails && serviceDetails.incoterm == "OTHERS"? <div style={{ width: "50%", margin:"10px" }}>
              <Input
                  name="incoterms"
                  placeholder="Incoterm"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.incoterms}
                // readOnly={true}
                />
              </div>  : <div></div> } 


              </div>
              <div className={classes.source}>
    <div style={{  width: "50%", margin:"10px", paddingRight:"20px" }}>
               <Input
                  //options={paymentTerms}
                  name="paymentTerms"
                  placeholder="Payment Term"
                  value={serviceDetails && serviceDetails.paymentTerms}
                  readOnly={true}
                />
               
              </div>
{ serviceDetails && serviceDetails.paymentTerms == "OTHERS"? <div style={{ width: "50%", margin:"10px" }}>
              <Input
                  name="paymentTerm"
                  placeholder="Payment Term"
                  readOnly={true}
                  value={serviceDetails && serviceDetails.paymentTerm}
                // readOnly={true}
                />
              </div>  : <div></div> } 



              </div>
              <div >
             
              </div>
              <div style={{ width: "100%", margin:"10px" , paddingRight:"20px"}}>
              <TextArea
              name="requirement"
              placeholder="Import Declaration Compliance Specific coverage Requirements"
              value={serviceDetails && serviceDetails.requirement}
              readOnly={true}
            />



           
              </div>


     
    


              
      


             

              </> : <></>
            }
     


    </>
    )
};

export default ImportCompliance;