import { Routes, Route, Navigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import EditProfile from "./editProfile.js";
import CompanyDetails from "./companyDetails.js";
import PaymentPage from "./PaymentPage";
import Settings from "./Settings.js";
import UserManagement from "./UserManagement/UserManagement";
import UserSubscribe from "./UserSubscribe";
import Navbar from "components/UI/Navbar/Navbar";
import Container from "components/UI/Layout/Container";
import Wrapper from "components/UI/Layout/Wrapper";
import Edit from "./Edit";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";

import { CheckPermission } from "utils/Constants/RBAC";
import { profileURL } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import ProfileStepper from "./profileStepper.js";

export default ({ toggle, isOpen, toggleChat, hanndleCraateGroup }) => {
  const tabs = [
    { name: "Company Profile", value: "company-profile" },
    { name: "User Management", value: "user-management" },
    { name: "Premium Subscription", value: "premium-subscription" },
    // { name: "Settings", value: "Settings" },
  ];
  const [viewUserRole, setViewuserRole] = useState(null);
  const { instance, accounts } = useMsal();
  const [Role, setRole] = useState(null);
  const [settingRole, setSettingRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);

  // const Request = () => {
  //   getIdTokenAsync(instance, accounts).then((response) => {
  //     UseRequest(
  //       `${profileURL}Persons/${response.idTokenClaims.member_id}`,
  //       null,
  //       response
  //     ).then((data) => {
  //       setIsLoading(false);
  //       let { message, status } = data;
  //       if (message || status) {
  //         Error(message);
  //       } else {
  //         setRole(data.role);
  //       }
  //     });
  //   });
  // };

  // useEffect(() => {
  //   Request();
  // }, []);

  useEffect(() => {
    if (Role) {
      setViewuserRole(
        CheckPermission("viewUser", "UserManagement", "viewUser", Role)
      );
      setSettingRole(CheckPermission("Setting", "Settings", "Setting", Role));
    }
  }, [Role]);
  return (
    <div>
      <Container>
        <Navbar tabs={tabs} />
        <Wrapper>
          <Routes>
            <Route exact path="/" element={<Navigate to="company-profile" />} />
            <Route
              exact
              path="/company-profile"
              element={
                <CompanyDetails
                  hanndleCraateGroup={hanndleCraateGroup}
                  toggle={toggle}
                  isOpen={isOpen}
                  toggleChat={toggleChat}
                />
              }
            />{" "}
            <Route
              exact
              path="/premium-subscription"
              element={<UserSubscribe />}
            />
            <Route exact path="/edit" element={<Edit />} />
            <Route exact path="/edits" element={<EditProfile />} />
            <Route exact path="/paymentPage" element={<PaymentPage />} />
            {viewUserRole === "NOTAPP" ? (
              <Route
                exact
                path="/"
                element={<Navigate to="company-profile" />}
              />
            ) : (
              <Route
                exact
                path="/user-management"
                element={<UserManagement />}
              />
            )}
            {/* {settingRole === "NOTAPP" ? (
              <Route
                exact
                path="/Settings"
                element={<Navigate to="company-profile" />}
              />
            ) : (
              <Route exact path="/Settings" element={<Settings />} />
            )} */}
            <Route
              exact
              path="/company-profile/onboarding"
              element={<ProfileStepper />}
            />
          </Routes>
        </Wrapper>
      </Container>
    </div>
  );
};
