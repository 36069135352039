import React, { useCallback, useContext, useState } from "react";
// import useAxios from "../../../../services/useAxios";
import { invoiceURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import {
  Container,
  CardWapper,
  ActionBarStyled,
} from "components/Container.styled";

import {
  Routes,
  Route,
  Link,
  Outlet,
  useNavigate,
  NavLink,
} from "react-router-dom";
import styled from "styled-components";
import GeneralDetail from "../Details/generals";
import SupplierDetail from "../Details/supplierDetails";
import BuyerDetail from "../Details/buyerDetails";
import ItemList from "../Details/itemList";
import ExportDetails from "../Details/exportDetails";
import Miscellaneous from "../Details/miscellaneous";
import styles from "../../../invoice.module.css";
import { Success, Error } from "components/notifications/responseHandle";
import { invoiceFinanceDetailsContext } from "contexts/invoiceFinance/invoiceFinanceDetailsContext";
import { invoiceFinanceContext } from "contexts/invoiceFinance/invoiceFinanceContext";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
// import { useEffect } from "react";

import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import {checkInvoiceUploadValidation} from './validationCheck';

export default ({setInvoiceData, setDetailUpdateCheck, detailUpdateCheck}) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();
  // const [idToken, setIdToken] = useState(null);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  // getIdToken(instance, accounts, setIdToken);
  const companyId = getClaim(accounts, "company_member_id");

  const handleNavigate = useCallback((url) => navigate(url), []);
  const [selected, setSelected] = React.useState("General");
  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    {
      title: "General",
      description: "General",
      value: "General",
    },

    {
      title: "Supplier Details",
      description: "Supplier Details",
      value: "SupplierDetails",
    },
    {
      title: "Buyer Details",
      description: "Buyer Details",
      value: "BuyerDetails",
    },
    {
      title: "Item List",
      description: "Item List",
      value: "ItemList",
    },
    {
      title: "Export Details",
      description: "Export Details",
      value: "ExportDetails",
    },
    {
      title: "Miscellaneous",
      description: "Miscellaneous",
      value: "Miscellaneous",
    },
  ];

  const { handleNext } = useContext(invoiceFinanceDetailsContext);
  const { setSaveInvoiceData, invoiceData } = useContext(invoiceFinanceContext);

  const handleSubmitDetails = async (event) => {
    const validationText = checkInvoiceUploadValidation("last");
    if (validationText) {
      Error(validationText);
    }
    else{
    setIsLoading(true);
    let general = JSON.parse(localStorage.getItem("uploadData"));
    general.txlSellerMemberId = companyId;
    general.document.downloadUri = "asdsdsad";
    localStorage.setItem("uploadData", JSON.stringify(general));
    event.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: localStorage.getItem("uploadData"),
    };
    const response = await fetch(`${invoiceURL}invoices/${general.txlInvoiceId}`, requestOptions);
    if (response.status >= 200  && response.status<300) {
      const data = await response.json();
      setIsLoading(false);
      setSaveInvoiceData(data);
      setInvoiceData(data);
      setDetailUpdateCheck(!detailUpdateCheck);
    } else if (response.status === 409) {
      setIsLoading(false);
      Error("Invoice is already Created");
    } else {
      setIsLoading(false);
      Error("Fail to fetch from API");
    }
    }
  };

  const handleNextButton = (e, value) => {
    const validationText = checkInvoiceUploadValidation(value);
    if (validationText) {
      Error(validationText);
    }
    else {
       setSelected(value)
    }
  }

  return (
    <>
      <ActionBarStyled className={styles.actionBarStyled}>
        {tabs.map((item, index) => (
          <NavBar
            key={index}
            onClick={() => setSelected(item.value)}
            style={selected === item.value ? addBlue : addWhite}
          >
            <li>{item.title}</li>
          </NavBar>
        ))}
      </ActionBarStyled>
      { isLoading
       ?  <Loader/> :
       <>
      {selected === "General" ? (
        <form className={styles.detailsForm} onSubmit={handleNext}>
          <GeneralDetail />
          {/* <div className={styles.detailsBtnDiv}>
            <button
              type="submit"
              onClick={() => setSelected("SupplierDetails")}
            >
              Next
            </button>
          </div> */}
          <ButtonDiv>
            <Button
              type="submit"
              onClick={(e) => handleNextButton(e,"SupplierDetails")}
            >
              Next
            </Button>
          </ButtonDiv>
        </form>
      ) : (
       ""
      )}

      {selected === "SupplierDetails" ? (
        <form className={styles.detailsForm} onSubmit={handleNext}>
          <SupplierDetail />
          {/* <div className={styles.detailsBtnDiv}>
            <button type="submit" onClick={() => setSelected("General")}>
              Previous
            </button>
            <button type="submit" onClick={() => setSelected("BuyerDetails")}>
              Next
            </button>
          </div> */}
          <ButtonDiv>
            <Button type="submit" onClick={() => setSelected("General")}>
              Previous
            </Button>
            <Button type="submit" onClick={(e) => handleNextButton(e,"BuyerDetails")}>
              Next
            </Button>
          </ButtonDiv>
        </form>
      ) : (
        ""
      )}

 
      {selected === "BuyerDetails" ? (
        <form className={styles.detailsForm} onSubmit={handleNext}>
          <BuyerDetail />
          <ButtonDiv>
            <Button
              type="submit"
              onClick={() => setSelected("SupplierDetails")}
            >
              Previous
            </Button>
            <Button type="submit" onClick={(e) => handleNextButton(e,"ItemList")}>
              Next
            </Button>
          </ButtonDiv>
        </form>
      ) : (
        ""
      )}

      {selected === "ItemList" ? (
        <form className={styles.detailsForm} onSubmit={handleNext}>
          <ItemList
            itemList={
              invoiceData &&
              invoiceData.invoiceDetails &&
              invoiceData.invoiceDetails.ItemList
            }
          />
          <ButtonDiv>
            <Button type="submit" onClick={() => setSelected("BuyerDetails")}>
              Previous
            </Button>
            <Button type="submit" onClick={() => setSelected("ExportDetails")}>
              Next
            </Button>
          </ButtonDiv>
        </form>
      ) : (
        ""
      )}

      {selected === "ExportDetails" ? (
        <form className={styles.detailsForm} onSubmit={handleNext}>
          <ExportDetails />
          <ButtonDiv>
            <Button type="submit" onClick={() => setSelected("ItemList")}>
              Previous
            </Button>  
            <Button type="submit" onClick={(e) => handleNextButton(e,"Miscellaneous")}>
              Next
            </Button>
          </ButtonDiv>
        </form>
      ) : (
        ""
      )}

      {selected === "Miscellaneous" ? (
        <div
          className={styles.detailsForm}
        >
          <Miscellaneous />
          <ButtonDiv>
            <Button type="submit" onClick={() => setSelected("ExportDetails")}>
              Previous
            </Button>
            <Button type="submit" onClick={(e) => handleSubmitDetails(e)}>Submit Form</Button>
          </ButtonDiv>
        </div>
      ) : (
        ""
      )}
      </>
     }
    </>
  );
};

const NavBar = styled.div`
  padding-bottom: 20px;
  li {
    cursor: pointer;
    :hover {
      color: #218eeb;
    }
  }
`;

const addWhite = {
  color: "white",
};
const addBlue = {
  color: "#42A5F5",
};
