import React, {useState} from 'react';
import Screen from './verifyPhoneOtp.styled';
import {MdEmail} from "react-icons/md";
import {FiLock} from "react-icons/fi";
import {FaFacebookF} from "react-icons/fa";
import {BsTwitter} from "react-icons/bs";
import {ImGooglePlus} from "react-icons/im";
import { useNavigate } from 'react-router-dom';


const VerifyPhoneOtp= ()=>{

    const navigate=useNavigate();
    const [otp,setOtp]=useState("");
    const handleChange=(e)=>{
        e.preventDefault();
             setOtp(e.target.value+" ");
     }
     const handleNext=()=>{
         if(otp==="1 2 3 4 5 6 ")
         {
          navigate('/verifyemail')
         }
         else{
             setOtp("");
             alert("Please enter correct OTP");
         }
     }

    return(
       <Screen>
            <div className='header'>TRADXLINK</div>
            <div className='subHeader'>Seller</div>
            <div className='otpHeading'>Phone Number Verification code</div>
            <div className='otp'>
                <input type="text" name="otp" placeholder="1 2 3 4 5 6" value={otp} onChange={handleChange} />
                <div className='resendOtp'>Resend</div>
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <button className='nextbutton' onClick={handleNext}>Next</button>
       </Screen>
        
    )
}
export default VerifyPhoneOtp;