import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftShort } from "react-icons/bs";
import { useLocation, useParams } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
// import History from "../../Invoicefinancing/uploadInvoice/Deals/history";
import History from "../../../components/History/history";
import classes from "../service.module.css";
import Content from "components/UI/Layout/Content";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";
import DealDetailTable from "../Table/DealDetailTable";
import Requisition from "../servicedeal/deals/serviceRequisition/serviceRequisition";
import Quote from "../servicedeal/deals/serviceQuote/serviceQuote";
import FreightDetail from ".././New-ServiceRequest/Category/Freights";
import { CheckPermission } from "utils/Constants/RBAC";
import { invoiceURL, profileURL } from "services/BaseURLInstance";
import SingleToogleTab from "components/UI/ToogleSelect/SingleToogleTab";

import {
  createPayment,
  makePayment,
  getPaymentByUser,
  paymentDetails,
  updatePayment,
  releasePayment,
  addPaymentUser,
  updateServiceDeal,
  getPaymentsByLoanId,
  getPaymentsByServiceId,
  getPaymentCollection,
} from "api/Payments";
import { storeContext } from "../../../contexts/Store/Store";
import { getClaim } from "utils/authUtil";
import { NetworkURL } from "../../../services/BaseURLInstance";
import { ButtonStyled } from "../../../components/Container.styled";
import RequestDetails from "../New-ServiceRequest/RequestDetails";
import CustomizedSteppers from "components/UI/Stepper/Stepper";
import { DealWorkFlow, DealWorkFlowDecline } from "utils/Section/Workflow";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { getIdTokenAsync } from "utils/authUtil";
import Loader from "components/Loader/Loader";
import DocumentSection from "./DocumentSection/documentIndex";
import { StepperPosition } from "common/utils/stepperPosition";
import { PaymentsSummary } from "./paymentSummary";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { PaymentCollection } from "./paymentCollection";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { Success } from "components/notifications/responseHandle";
import { getCountryCode } from "../../../common/utils/getCountries";
import PaymentScreen from "../servicedeal/deals/serviceQuote/paymentScreen";

export default ({ hanndleCraateGroup }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const { instance, accounts } = useMsal();
  const [userPaymentData, setUserPaymentData] = useState();
  const [receiverPaymentDetail, setReceiverPaymentDetail] = useState({});
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [dealStatus, setDealStatus] = useState();
  const [paymentData, setPaymentData] = useState({ paymentStatus: "new" });
  const [response, setResponse] = useState();
  const [senderDetail, setSenderDetail] = useState({});
  const [receiverDetail, setReceiverDetail] = useState({});
  const companyId = getClaim(accounts, "company_member_id");
  const { fetchData, fetchCompanyList } = useContext(storeContext);
  const [companyList, setCompanyList] = useState();
  const [isActive, setIsActive] = useState("Requisition Summary");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [Role, setRole] = useState(null);
  const [paymentRole, setPaymentRole] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState();
  const [button, setButton] = useState();
  const [isDeliveryDeclined, setIsDeliveryDeclined] = useState(false);
  const [paymentDetailsScreen, setPaymentDetailsScreen] = useState(false);
  const [servicePaymentDetails, setServicePaymentDetails] = useState({
    paymentProviderName: "",
    RefSellerVirtualBankAccountId: "",
    RefBuyerVirtualBankAccountId: "",
  });
  const [paymentCollection, setPaymentCollection] = useState();

  const [isChecked, setIsChecked] = useState(false);

  const handledealStatus = async (value) => {
    await setDealStatus("");
    setDealStatus(value);
  };

  useEffect(() => {
    if (Role) {
      setPaymentRole(
        CheckPermission("Payments", "ServicesDeal", "payments", Role)
      );
    }
  }, [Role]);

  useEffect(() => {
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setIsError(message || "Something went wrong");
        } else {
          console.log("updatePayment1", location.search);
          setIsLoading(false);
          setResponse();
          setResponse(data);
        }
      });
    });

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${NetworkURL}members?memberId=${companyId}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setIsError(message || "Something went wrong");
        } else {
          setCompanyList(data);
        }
      });
    });
  }, []);

  const handleUpdateStatus = (value) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let data = response;
    data.serviceDealStatus = value;
    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data),
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${id}`,
        requestOptions
      )
        .then((data) => data.json())
        .then((response) => {
          handledealStatus(value);
          setResponse(response);
          // setToast(`Status Updated Successfully`);
          setTimeout(() => {
            navigate("../service-deals");
          }, 3000);
        });
    };
    fetchData();
  };

  const findPaymentUser = (sender = true, companyId) => {
    getPaymentByUser(idToken, companyId)
      .then((userData) => {
        if (sender) {
          setUserPaymentData(userData);
        } else {
          setReceiverPaymentDetail(userData);
        }
      })
      .catch((error) => {
        console.log("Get Payment API error", error);
      });
  };

  useEffect(() => {
    if (response?.consumerMemberUID) {
      findPaymentUser(false, response?.consumerMemberUID);
    }
    if (response?.providerMemberUID) {
      findPaymentUser(true, response?.providerMemberUID);
    }
  }, [response]);

  const fetchUpdatePayment = async (res, value) => {
    await updatePayment(res, value, idToken)
      .then((resData) => {
        handleUpdateStatus("PAYMENT_COMPLETED");
        setPaymentData(resData);
        setPaymentResponse(resData);
        navigate(`/services/service-deals/${id}`);
      })
      .catch((error) => {
        console.log("updatePayment error", error);
      });
  };

  useEffect(() => {
    if (companyList) {
      const countries = getCountryCode(response, companyList);
      const paymentAmount = response?.servicePriceOffer;
      getPaymentCollection(
        paymentAmount,
        countries.financerCountry,
        response?.servicePaymentCurrency,
        response?.consumerMemberUID,
        countries.traderCountry,
        idToken
      )
        .then((res) => {
          setPaymentCollection(res);
        })
        .catch((error) => {
          console.log("paymentCollection error", error);
        });
    }
  }, [response, companyList]);

  useEffect(() => {
    if (response) {
      getPaymentsByLoanId(response.serviceDealId, idToken, companyId)
        .then((res) => {
          console.log("updatePayment2", location.search, res);
          let dateArray = [];
          res.length > 0 &&
            res.forEach((item) => {
              dateArray.push(new Date(item.updatedDate));
            });
          dateArray.sort((date1, date2) => date1 - date2);
          const latestDate = new Date(
            dateArray[dateArray.length - 1]
          ).toISOString();
          let newRes = [];
          newRes =
            res.length > 0 &&
            res.filter((item) => {
              return new Date(item.updatedDate).toISOString() == latestDate;
            });
          setPaymentData(newRes[0]);
          setPaymentResponse(newRes[0]);
          if (location.search === "?status=PENDING") {
            setTimeout(() => {
              fetchUpdatePayment(newRes[0], "PENDING");
            }, 1000);
          } else if (location.search === "?status=FAILED") {
            setTimeout(() => {
              fetchUpdatePayment(newRes[0], "FAILED");
            }, 1000);
          }
        })
        .catch((error) => {
          console.log("paymentByLoan error", error);
        });
    }
  }, [response]);

  useEffect(() => {
    response && setDealStatus(response.serviceDealStatus);
  }, [response]);

  useEffect(() => {
    findPaymentUser(true, companyId);
    if (paymentData.paymentId) {
      getPaymentDetailsData(paymentData.paymentId);
    }
  }, []);

  const getPaymentDetailsData = async (paymentId) => {
    if (paymentId)
      await paymentDetails(paymentId)
        .then((data) => {
          console.log("AkashTestingServicePayment", data);
          setPaymentData(data);
        })
        .catch((error) => console.log("error", error));
  };

  const UpdateLoanStatus = async () => {
    updateServiceDeal(idToken, response.loanReferenceId, {
      ...response,
      paymentStatus: "INACTIVE",
      financeRePaymentStatus: "REPAYMENT_DUE",
      financeDisbursalStatus: "DISBURSEMENT_COMPLETED", /// After Release Payment
    })
      .then(() => {})
      .catch((error) => {
        console.log("loan status update error", error);
      });
  };

  const findReleasePayment = async () => {
    releasePayment(paymentData.paymentId).then(() => {
      UpdateLoanStatus();
    });
  };

  const makeLoanPayment = () => {
    const tax =
      response?.taxes !== "" && response?.taxes !== " "
        ? parseFloat(response?.taxes)
        : 0;
    const servicePriceOffer =
      response?.servicePriceOffer !== "" && response?.servicePriceOffer !== " "
        ? parseFloat(response?.servicePriceOffer)
        : "0";
    const amount = (servicePriceOffer + tax).toString();
    const requestData = {
      collectionRates: paymentCollection,
      tradxlinkPaymentOrderUID: "",
      referenceInstrumentType: "SERVICE_DEAL",
      senderMemberID: response.consumerMemberUID,
      receiverMemberID: response.providerMemberUID,
      senderMemberAccount: response.RefBuyerVirtualBankAccountId,
      receiverMemberAccount: response.RefSellerVirtualBankAccountId,
      currencySender: response.servicePaymentCurrency,
      currencyReceiver: response.servicePaymentCurrency,
      paymentAmount: amount.toString(),
      paymentCategory: "DISBURSEMENT",
      paymentCollectionMethod: "BANK",
      paymentDueDate: "2022-11-11T09:09:32.407Z",
      paymentDescriptionSender: response?.pricingDescription
        ? response?.pricingDescription
        : "test",
      paymentDescriptionReceiver: "test",
      paymentReferenceData: response.serviceDealId,
      paymentStatus: "INITIATED",
      txnType: "services",
      paymentType: response.modeOfPayment !== "OFFLINE" ? "CHECKOUT" : "",
      paymentAttemptCount: 0,
      paymentTransdactionDate: new Date().toISOString(),
      paymentServiceProvider:
        response.modeOfPayment === "OFFLINE" ? "OFFLINE_PAYMENT" : "TAZAPAY",
      isDeleted: false,
      tag: "",
      createdBy: response.consumerMemberUID,
      updateBy: companyId,
      createdDate: response.createdDate,
      updatedDate: response.lastUpdatedDate,
      paymentCompletedRedirectUrl: `http://${window.location.host}/services/service-deals/${id}?status=PENDING`,
      paymentErrorRedirectUrl: `http://${window.location.host}/services/service-deals/${id}?status=FAILED`,
    };
    makePayment(idToken, requestData)
      .then((data) => {
        setIsLoading(false);
        if (response.modeOfPayment !== "OFFLINE") {
          window.location.href = data.paymentUrl;
        } else {
          fetchUpdatePayment(data, "RELEASE_COMPLETED");
          handleUpdateStatus("PAYMENT_COMPLETED");
          window.location.reload();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log("error", error);
      });
  };

  const paymentApiCall = () => {
    if (button === "Make Payment" || button === "Re-Initiate Payment") {
      makeLoanPayment();
    } else if (button === "Release Payment") {
      findReleasePayment();
    } else {
      console.log("else case");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [Component, setIsComponent] = useState();

  const Navbar = [
    {
      title: "Requisition Summary",
      description: (
        <Requisition
          status={dealStatus}
          handledealStatus={handledealStatus}
          referenceServiceRequisitionId={
            response && response.referenceServiceRequisitionId
          }
          serviceDealDetails={response}
          hanndleCraateGroup={hanndleCraateGroup}
          setPaymentDetailsScreenParent={setPaymentDetailsScreen}
          setButton={setButton}
          paymentData={paymentData}
        />
      ),
    },
    {
      title: "Requisition Detail",
      description: (
        <RequestDetails
          referenceId={response && response.referenceServiceRequisitionId}
        />
      ),
    },
    {
      title: "Quote",
      description: (
        <Quote
          status={dealStatus}
          handledealStatus={handledealStatus}
          serviceDealDetails={response}
          hanndleCraateGroup={hanndleCraateGroup}
          setPaymentDetailsScreenParent={setPaymentDetailsScreen}
          setButton={setButton}
          paymentData={paymentData}
        />
      ),
    },
    {
      title: "Documents",
      description: <DocumentSection serviceDealDetails={response} />,
    },
    {
      title: "Payment Summary",
      description: (
        <PaymentsSummary
          response={response}
          loanId={id}
          companyList={companyList}
          memberId={companyId}
        />
      ),
    },
    {
      title: "History",
      description: (
        <History
          dealstatus="SERVICE DEAL STATUS"
          screen="SERVICES"
          id={id}
          companyList={companyList}
        />
      ),
    },
  ];

  const handleChange = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };

  const CheckStatus = (status) => {
    if (status === "ABORTED") {
      return true;
    } else {
      return false;
    }
  };

  // const [quoteData, setQuoteData] = useState(response);

  const handleClick = (e, value) => {
    // console.log("quoteDatatobesend", quoteData);
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let data = response;
    data.serviceDealStatus = value;
    if (response.serviceDealStatus === "SERVICE_IN_PROGRESS") {
      Success("Service Initiated");
    } else if (response.serviceDealStatus === "DELIVERED") {
      Success("Service Delivered Successfully");
    } else if (response.serviceDealStatus === "PAYMENT_PENDING") {
      Success("Payment Requested Successfully");
    } else if (response.serviceDealStatus === "ABORTED") {
      Success("Service Deal Aborted Successfully");
    } else if (response.serviceDealStatus === "COMPLETED") {
      Success("Service Completed Successfully");
    } else if (response.serviceDealStatus === "DELIVERY_CONFIRMED") {
      Success("Service Delivery Confirmed");
    } else if (response.serviceDealStatus === "PAYMENT_COMPLETED") {
      Success("Payment Initiated Successfully");
    }

    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data),
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${response.serviceDealId}`,
        requestOptions
      )
        .then((data) => data.json())
        .then((response) => {
          handledealStatus(value);
          // setQuoteData(response);
          // setToast(`Status Updated Successfully`);
          setTimeout(() => {
            navigate("../service-deals");
          }, 3000);
        });
    };
    fetchData();
  };

  const handleClickDecline = (e, value) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let data = response;
    data.serviceDealStatus = value;
    if (
      response.serviceDealStatus === "SERVICE_IN_PROGRESS" &&
      isDeliveryDeclined === "true"
    ) {
      Success("Service Delivery Declined");
    }

    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data),
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${response.serviceDealId}`,
        requestOptions
      )
        .then((data) => data.json())
        .then((response) => {
          handledealStatus(value);
          setIsDeliveryDeclined(true);
          Success("Service Delivery Declined");
          // setToast(`Status Updated Successfully`);
          setTimeout(() => {
            navigate("../service-deals");
          }, 3000);
        });
    };
    fetchData();
  };

  const handleConfirmPaymentDetails = (details) => {
    let data = response;
    data.paymentProviderName = details.paymentProviderName;
    data.RefSellerVirtualBankAccountId = details.RefSellerVirtualBankAccountId;
    data.RefBuyerVirtualBankAccountId = details.RefBuyerVirtualBankAccountId;
    console.log("Details", details);
    const requestOptions = {
      method: "PUT",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(data),
    };
    const fetchData = async () => {
      await fetch(
        `${process.env.REACT_APP_SERVICE_URL}services/deals/${response.serviceDealId}`,
        requestOptions
      )
        .then((data) => data.json())
        .then((result) => {
          setPaymentDetailsScreen(false);
          if (
            result.RefSellerVirtualBankAccountId &&
            result.RefBuyerVirtualBankAccountId
          ) {
            setOpen(true);
          } else if (response.modeOfPayment === "OFFLINE") {
            makeLoanPayment();
          }
        });
    };
    fetchData();
  };

  const CheckedFnc = () => {
    if (isChecked) {
      paymentApiCall();
    } else {
      Error(
        'Please check the box of the "Terms & Conditions" to proceed further'
      );
    }
  };

  return (
    <>
      <div
        className={classes.headDiv}
        onClick={() => {
          navigate("../service-deals");
        }}>
        <div className={classes.headTitle} style={{ cursor: "pointer" }}>
          <BsArrowLeftShort size={22} /> <div>Back to Service Deals</div>
        </div>
      </div>

      {isLoading ? (
        <Content>
          <span>Loading...</span>
          <span>
            <Loader />
          </span>
        </Content>
      ) : (
        <>
          {response && companyList && (
            <>
              {paymentDetailsScreen ? (
                <PaymentScreen
                  quoteData={response}
                  handleConfirmPaymentDetails={handleConfirmPaymentDetails}
                  setServicePaymentDetails={setServicePaymentDetails}
                  setPaymentDetailsScreen={setPaymentDetailsScreen}
                  servicePaymentDetails={servicePaymentDetails}
                />
              ) : (
                <>
                  <Content marginBottom="2%" padding="1% 2% 2% 2%">
                    <h5>Service Deal</h5>
                    <CustomizedSteppers
                      Value={
                        CheckStatus(response.serviceDealStatus)
                          ? DealWorkFlowDecline(response.serviceDealStatus)
                          : DealWorkFlow
                      }
                      position={StepperPosition(response.serviceDealStatus)}
                      isDeclined={CheckStatus(response.serviceDealStatus)}
                    />
                  </Content>

                  <Content marginBottom="2%" padding="2% 2% 0.2% 2%">
                    <DealDetailTable
                      data={[response]}
                      companyList={companyList}
                      dealStatus={dealStatus}
                      handledealStatus={handledealStatus}
                      serviceDealDetails={response}
                      hanndleCraateGroup={hanndleCraateGroup}
                    />

                    <div>
                      {response.consumerMemberUID === companyId &&
                        response.serviceDealStatus === "PAYMENT_PENDING" &&
                        (paymentData?.paymentStatus === null ||
                        paymentData?.paymentStatus === "" ||
                        paymentData?.paymentStatus === "new" ? (
                          <div style={{ textAlign: "right" }}>
                            <ButtonDiv>
                              <Button
                                onClick={() => {
                                  if (
                                    response.RefBuyerVirtualBankAccountId &&
                                    response.RefSellerVirtualBankAccountId
                                  ) {
                                    // setOpen(true);
                                    setPaymentDetailsScreen(true);
                                    setButton("Make Payment");
                                  } else {
                                    setPaymentDetailsScreen(true);
                                  }
                                }}>
                                Make Payment
                              </Button>
                            </ButtonDiv>
                          </div>
                        ) : paymentData?.paymentStatus === "FAILED" &&
                          paymentData?.createdBy === companyId ? (
                          <div style={{ textAlign: "right" }}>
                            <ButtonDiv>
                              <Button
                                onClick={() => {
                                  setOpen(true);
                                  setButton("Re-Initiate Payment");
                                }}>
                                Re-Initiate Payment
                              </Button>
                            </ButtonDiv>
                          </div>
                        ) : (paymentData?.paymentStatus ===
                            "MONEY_DISBURED_TO_PROVIDER" ||
                            paymentData?.paymentStatus === "REFUND_FAILED") &&
                          paymentData?.createdBy === companyId ? (
                          <div style={{ textAlign: "right" }}>
                            <ButtonDiv>
                              <Button
                                onClick={() => {
                                  setOpen(true);
                                  setButton("Release Payment");
                                }}>
                                Release Payment
                              </Button>
                            </ButtonDiv>
                            <ButtonDiv>
                              <Button
                                onClick={() => {
                                  setOpen(true);
                                  setButton("Refund Payment");
                                }}>
                                Refund Payment
                              </Button>
                            </ButtonDiv>
                          </div>
                        ) : paymentData?.paymentStatus ===
                          "RELEASE_INITITATED" ? (
                          <div
                            style={{
                              color: "#19cb46",
                              textAlign: "right",
                              marginTop: "10px",
                            }}>
                            Payment release initiated
                          </div>
                        ) : paymentData?.paymentStatus ===
                          "RELEASE_COMPLETED" ? (
                          <div
                            style={{
                              color: "#19cb46",
                              textAlign: "right",
                              marginTop: "10px",
                            }}>
                            Payment release Completed
                          </div>
                        ) : paymentData?.paymentStatus === "RELEASE_PENDING" ? (
                          <div
                            style={{
                              color: "#19cb46",
                              textAlign: "right",
                              marginTop: "10px",
                            }}>
                            Payment release updates Pending
                          </div>
                        ) : (
                          <div
                            style={{
                              color: "#19cb46",
                              textAlign: "right",
                              marginTop: "10px",
                            }}>
                            Payment In-Progress...
                          </div>
                        ))}
                    </div>
                    <ButtonDiv>
                      {response.serviceDealStatus === "BID_COMPLETED" &&
                        response.providerMemberUID === companyId && (
                          <Button
                            onClick={(e) =>
                              handleClick(e, "SERVICE_IN_PROGRESS")
                            }>
                            Start Service
                          </Button>
                        )}
                      {response.serviceDealStatus === "SERVICE_IN_PROGRESS" &&
                        response.providerMemberUID === companyId && (
                          <Button onClick={(e) => handleClick(e, "DELIVERED")}>
                            Delivery Complete
                          </Button>
                        )}
                      {response.serviceDealStatus === "DELIVERED" &&
                        response.consumerMemberUID === companyId && (
                          <>
                            <Button
                              onClick={(e) => {
                                handleClickDecline(e, "SERVICE_IN_PROGRESS");
                              }}>
                              Decline Delivery
                            </Button>
                            <Button
                              onClick={(e) =>
                                handleClick(e, "DELIVERY_CONFIRMED")
                              }>
                              Confirm Delivery
                            </Button>
                          </>
                        )}
                      {response.serviceDealStatus === "DELIVERY_CONFIRMED" &&
                        response.providerMemberUID === companyId && (
                          <Button
                            onClick={(e) => handleClick(e, "PAYMENT_PENDING")}>
                            Request for payment
                          </Button>
                        )}
                      {response.serviceDealStatus === "PAYMENT_COMPLETED" &&
                        response.providerMemberUID === companyId && (
                          <Button onClick={(e) => handleClick(e, "COMPLETED")}>
                            Complete Service
                          </Button>
                        )}
                    </ButtonDiv>
                  </Content>

                  <Content padding="1% 2% 1% 2%">
                    <div
                      className={classes.headDiv}
                      style={{ fontSize: "20px" }}>
                      Deal Detail
                    </div>
                    <ToogleNavbar
                      Navbar={Navbar}
                      isActive={isActive}
                      handleChange={handleChange}
                      handleComponent={handleComponent}
                    />

                    <div>
                      {Component !== undefined
                        ? Component
                        : response && (
                            <Requisition
                              status={dealStatus}
                              handledealStatus={handledealStatus}
                              referenceServiceRequisitionId={
                                response.referenceServiceRequisitionId
                              }
                            />
                          )}
                    </div>
                  </Content>
                </>
              )}
            </>
          )}
          {response && (
            <Dialog
              fullWidth={true}
              maxWidth={"lg"}
              open={open}
              onClose={handleClose}
              PaperProps={{
                sx: { backgroundColor: "#343434", color: "white" },
              }}>
              <div style={{ padding: "2%" }}>
                <div style={{ backgroundColor: "black" }}>
                  <DialogContent sx={{}}>
                    <div>
                      <PaymentCollection
                        response={response}
                        loanId={id}
                        companyList={companyList}
                        memberId={companyId}
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                      />
                    </div>
                  </DialogContent>
                  <div
                    style={{
                      width: "100%",
                      paddingRight: "3.5%",
                      paddingBottom: "2%",
                      display: "flex",
                      justifyContent: "right",
                    }}>
                    <button
                      style={{
                        width: "20%",
                        margin: "1%",
                        padding: "1%",
                        color: "white",
                        border: "none",
                        backgroundColor: "#42a5f5",
                        borderRadius: "5px",
                        fontSize: "14px",
                      }}
                      onClick={handleClose}>
                      Cancel
                    </button>
                    <>
                      <button
                        // disabled = {isChecked}
                        style={{
                          width: "20%",
                          margin: "1%",
                          padding: "1%",
                          color: "white",
                          border: "none",
                          backgroundColor: "#42a5f5",
                          borderRadius: "5px",
                          fontSize: "14px",
                        }}
                        onClick={(e) => {
                          CheckedFnc();
                        }}>
                        Submit
                      </button>
                    </>
                  </div>
                </div>
              </div>
            </Dialog>
          )}

          {isError && (
            <Content>
              <div className="alert alert-danger" role="alert">
                {isError}
              </div>
            </Content>
          )}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
