import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { BsBell } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { RiWechatLine } from "react-icons/ri";
import { withSendBird, SendBirdState } from "sendbird-uikit";
import { AiFillEye } from "react-icons/ai";
import { truncateStringID } from "utils/helper";

import { FindCompanyName } from "utils/helper";

const Styles = styled.div`
  .row {
    width: 100%;
    display: flex;
  }

  .row > div {
    display: flex;
    height: 50.47px;
    width: 14.285%;
    justify-content: center;
    align-items: center;
    border: 0.1px solid #ffffff;
    font-family: Roboto;
    font-style: Medium;
    font-size: 14px;
    line-height: 24px;
    line-height: 114%;
    align: left;
    vertical-align: top;
    paragraph-spacing: 32px;
    overflow: hidden;
  }

  .details {
    margin-top: 20px;
    margin-left: 40px;
    padding-bottom: 20px;
    font-family: Roboto;
    font-style: Regular;
    font-size: 16px;
    line-height: 24px;
    line-height: 128%;
    align: left;
    vertical-align: Top;
    paragraph-spacing: 26px;
  }
  .details-screen {
    display: flex;
  }
  .details-button {
    margin-top: 20px;
    margin-left: 120px;
  }
  .details-button > button {
    display: block;
    margin: 4px;
    background-color: #42a5f5;
    color: black;
    cursor: pointer;
    width: 214.24px;
    height: 38.09px;
  }
  .details > hr {
    height: 0.0000000001px;
    opacity: 20%;
  }
  .details > div {
  }
`;

var sdk = null;

const mapStoreToProps = (store: SendBirdState) => {
  const {
    stores: { sdkStore, userStore },
    config: {
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub,
    },
  } = store;
  sdk = sdkStore.sdk;
};

const TableData = ({ data, financerMemberUID, companyList }) => {
  const navigate = useNavigate();
  const [check, setCheck] = useState(true);
  const [memberList, setMemberList] = useState();
  const handleClick = () => {
    if (check) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  };

  const handleDetails = (e, data, memberName) => {
    e.preventDefault();
    data.memberName = memberName;
    navigate(`/financedashBoard/invoicefinancing/${data.financeDealId}`, {
      state: { companyList: companyList },
    });
  };

  const fetchData = async (companyId, id2) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        setMemberList(nData);
      });
  };
  const chatHandler = () => {
    if (data?.financerMemberUID !== "" && data?.traderMemberUID !== "") {
      fetchData(data?.financerMemberUID, data?.traderMemberUID);
    }
  };
  const hanndleCraateGroup = () => {
    if (memberList?.length > 0) {
      let url = window.location.href;
      localStorage.setItem("lasturl", url);
      const selectedUsers = memberList;
      const params = new sdk.GroupChannelParams();
      params.addUserIds(selectedUsers);
      params.isDistinct = true;
      params.isEphemeral = false;
      params.isPublic = false;

      params.name = data.financeDealId
        ? truncateStringID(data.financeDealId, 7, "FD")
        : "NA";
      //params.name = "FD " + data.financeDealId;
      //params.channelUrl = "FD-" + data.financeDealId;
      sdk.GroupChannel.createChannel(params, (response, error) => {
        const swapParams = sdk.getErrorFirstCallback();
        let groupChannel = response;
        let err = error;
        if (swapParams) {
          groupChannel = error;
          err = response;
        }
        if (err) {
          navigate("/chat?url=" + "FD-" + data.financeDealId);
          console.log(err);
        } else {
          //navigate('/chat');
          navigate("/chat?url=" + groupChannel.url);
        }
      });
    }
  };
  useEffect(() => {
    if (memberList) hanndleCraateGroup();
  }, [memberList]);
  return (
    <Styles>
      {companyList && (
        <div>
          <div className="row">
            <div>
              <div className="px-2">
                <BsBell size={22} />
              </div>
              {data.financeDealId
                ? truncateStringID(data.financeDealId, 7, "FD")
                : "NA"}
            </div>
            <div style={{ borderLeft: "none", paddingLeft: "10px" }}>
              {companyList.map((item) => {
                if (item.memberUID === data.financerMemberUID) {
                  return (
                    <div
                      style={{ borderLeft: "none", paddingLeft: "10px" }}
                      onClick={(e) => handleDetails(e, data, item.memberName)}
                    >
                      {item.memberName}
                    </div>
                  );
                }
              })}
            </div>
            <div>
              {companyList &&
                FindCompanyName(companyList, data.traderMemberUID)}
            </div>
            <div style={{ borderLeft: "none" }}>
              {data.financeProductCategory}
            </div>
            {/* <div onClick={()=>navigate(`../invoicesDetails/${data.referenceFinanceInstrumentId}`)}><a href="">Invoice Details</a></div> */}
            <div style={{ borderLeft: "none" }}>{data.financeDealStatus}</div>
            <div style={{ borderLeft: "none" }}>
              {new Date(data.lastUpdatedDate).toLocaleDateString()}
            </div>
            <div style={{ borderLeft: "none", borderRight: "none" }}>
              <div style={{ margin: "10px" }}>
                <FiMail size={22} />
              </div>
              <div style={{ margin: "10px" }} onClick={chatHandler}>
                <RiWechatLine size={22} />
              </div>
              <div
                style={{ margin: "10px" }}
                onClick={() =>
                  navigate(
                    `../invoicesDetails/${data.referenceFinanceInstrumentId}`
                  )
                }
              >
                <a href="">
                  <AiFillEye size={22} />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </Styles>
  );
};
export default withSendBird(TableData, mapStoreToProps);
