import React from 'react'
import styled from "styled-components";
import styles from '../../invoice.module.css';


const BuyerDetails = ({data}) => {
    const Response = [
        {
            name: 'Buyer GSTIN',
            value: data ? data.Gstin : '',
        },
        {
            name: 'Buyer Legal Name',
            value: data ? data.LglNm : '',
        },
        {
            name: 'Buyer Address 1',
            value: data ? data.Addr1 : '',
        },
        {
            name: 'Buyer Address 2',
            value: data ? data.Addr2 : '',
        },
        {
            name: 'Buyer place',
            value: data ? data.Loc : '',
        },
        {
            name: 'Pincode',
            value: data ? data.Pin : '',
        },
    ]
    return (
        <div className={styles.summary}>
            { Response && Response.map((data, i) => (
            <div className={styles.summaryWrapper}key={i}>
                <div> <span> {data.name} </span> </div>
                <div> <span style={addBlue}> {data.value} </span> </div>
            </div> 
            ))}
        </div>
    )
    }
    
export default BuyerDetails;

const addBlue = {
    color: "#42A5F5"
}