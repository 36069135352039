import React, { useCallback, useContext, useState, useReducer, useEffect } from "react";
import { invoiceURL } from "../../../../../services/BaseURLInstance";
import A from "../../../../../components/A";
import Loader from "../../../../../components/Loader";
import {
  CardWapper,
  ButtonStyled,
  CancelButtonStyled,
} from "../../../../../components/Container.styled";
import { useNavigate } from "react-router-dom";
import { MdOutlineUploadFile } from "react-icons/md";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { invoiceFinanceContext } from '../../../../../contexts/invoiceFinance/invoiceFinanceContext'
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";




export default () => {
  const { instance, accounts } = useMsal();
  const [idToken, setIdToken] = useState(null);
  getIdToken(instance, accounts, setIdToken);

  const { data, invoiceData, handleChange, setInvoiceData } = useContext(invoiceFinanceContext)
  const { invoiceNumber } = invoiceData

  const params = useParams();
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);


  const handleUpload = async (event) => {
    event.preventDefault();
    var body = new FormData()
    body.append('name', 'invoice')
    body.append('file', data.file)
    const requestOptions = {
      method: 'POST',
      headers: {
        "Authorization": "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: body
    };
    await fetch(`${invoiceURL}invoices/upload`, requestOptions)
      .then(response => response.json())
      .then(data => {
        localStorage.setItem("uploadData", JSON.stringify(data));
        setInvoiceData(data);
        const { invoiceNumber } = data
        handleNavigate(`/financedashBoard/pofinancing/invoices/${invoiceNumber}`)
      });
  }


  return (
    <>
      {/* {isLoading && <Loader />} */}
      <CardWapper>
        <A>Upload Documents - {params.id}</A>

        <FormStyled onSubmit={(e) => handleUpload(e)}>
          <SectionStyled>
            <UploadStyle>
              <CircleDiv>
                <MdOutlineUploadFile fontSize={60} backgroundColor="#fff" />
              </CircleDiv>
              <p>Drag and drop files here</p>
              <input
                type="file"
                name="file"
                // hidden
                accept=".doc,.  docx,.pdf,.jpeg"
                id="uploadId"
                // style={{ display: 'none' }}
                onChange={handleChange}
              />
              <UploadButton htmlFor="uploadId" class="btn" type="button">
                Choose File
              </UploadButton>
            </UploadStyle>
          </SectionStyled>
          <ActionSection>
            <CancelButtonStyled
            // onClick={() => handleNavigate(`/invoicefinancing/invoices`)}
            >
              Cancel
            </CancelButtonStyled>
            <ButtonStyled type='submit'
            //  onClick={() => handleNavigateHandler() }
            > Upload
            </ButtonStyled>
          </ActionSection>
        </FormStyled>
      </CardWapper>
    </>
  );
};







const UploadButton = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0071e9;
  background: #ffffff;
  border: 1px solid #0071ef;
  border-radius: 4px;
  padding: 9px 35px;
  &:hover {
    opacity: 0.5;
  }
`;
const FormStyled = styled.form`
  flex: 1;
  padding: 16px;
`;

const SectionStyled = styled.div`
  padding: 40px;
  margin: 16px;
  background-color: #2e2e2e;
`;

const UploadStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 160px;
  border: 2px dashed #d7dadf;
  p {
    color: #99abbe;
    padding: 5px 0px;
  }
`;

const CircleDiv = styled.div`
  border-radius: 50%;
  border: 1px solid;
  padding: 5px;
  display: flex;
  align-items: center;
  background: #aed4ff;
`;

const ActionSection = styled.div`
  display: flex;
  justify-content: end;
  button {
    margin-right: 30px;
  }
`;



