import React, { useState, useEffect } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { ImHistory } from "react-icons/im";
import classes from "./company/company.module.css";
import cn from "classnames";
import Modal from "components/UI/Modal/Modal";
import VersionTable from "./company/VersionTable";
import InfoTable from "./company/infoTable";
import { profileURL } from "services/BaseURLInstance";
import { CheckPermission } from "utils/Constants/RBAC";
import { FaFileSignature } from "react-icons/fa";
import { AiOutlineCloudUpload, AiOutlineCloudDownload } from "react-icons/ai";
import {
  ToastLoading,
  ToastSuccess,
  Error,
} from "components/notifications/responseHandle";
import { documentURL } from "services/BaseURLInstance";
import { UseRequest, UseRequestDownload } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import Upload from "./Upload";
import { useNavigate } from "react-router-dom";
import { onBoardingURL } from "services/BaseURLInstance";

const DocumentsAction = ({
  Data,
  apiTrigger,
  setApiTrigger,
  setIsUploading,
}) => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [Role, setRole] = useState(null);
  const [uploadRole, setUploadRole] = useState(null);
  const [eSignRole, setEsignRole] = useState(null);
  const [shareRole, setSharerole] = useState(null);
  const [digitalSignRole, setDigitalSignRole] = useState(null);
  const { instance, accounts } = useMsal();
  const [openInfo, setOpenInfo] = useState(false);
  const [infoRowData, setInfoRowData] = useState(null);
  const [versionDialogOpen, setVersionDialogOpen] = useState(false);
  const [versionData, setVersionData] = useState(null);
  const [docUpload, setDocUpload] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickInfo = async (data) => {
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${documentURL}documents/${Data.docReferenceId}`,
          null,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
          } else {
            setOpenInfo(true);
            setInfoRowData(data);
          }
        });
      });
    }
  };

  const handleClickHistory = async () => {
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${documentURL}documents/${Data.docReferenceId}/history`,
          null,
          response
        ).then((data) => {
          let { message } = data;
          if (message) {
            Error(message);
          } else {
            setVersionDialogOpen(true);
            setVersionData(data);
          }
        });
      });
    }
  };
  //rbac
  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setUploadRole(
        CheckPermission("Actions", "CompanyDocuments", "Upload", Role)
      );
      setEsignRole(
        CheckPermission("Actions", "CompanyDocuments", "ESign", Role)
      );
      setSharerole(
        CheckPermission("Actions", "CompanyDocuments", "Share", Role)
      );
      setDigitalSignRole(
        CheckPermission("Actions", "CompanyDocuments", "DigitalSignature", Role)
      );
    }
  }, [Role]);

  const handleClickDownload = async () => {
    const loadingId = ToastLoading(`${Data.documentName} downloading...`);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequestDownload(
        `${documentURL}documents/${Data.documentId}/download`,
        response
      ).then((blob) => {
        ToastSuccess(`${Data.documentName} downloaded successfully`, loadingId);
        var a = document.createElement("a");
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = Data.documentName;
        a.click();
      });
    });
  };

  const handleClickSign = () => {
    const loadingId = ToastLoading(`${Data.documentName} signing...`);
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      let requestPayload = {
        companyName: "",
        memberId: Data.memberId,
        documentId: Data.docReferenceId,
      };
      const options = {
        method: "PUT",
        body: requestPayload,
      };
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          "http://localhost:50013/onboarding/esign/aadhaaresign",
          options,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
          } else {
            ToastSuccess(
              `${Data.documentName} signing successfully`,
              loadingId
            );
          }
        });
      });
    }
  };

  const handleClickAdharSign = () => {
    const loadingId = ToastLoading(`${Data.documentName} signing...`);
    if (!Data.docReferenceId) {
      Error("Doc ReferenceId not found");
      return;
    } else {
      let requestPayload = {
        // companyName: "",
        memberId: Data.memberId,
        documentId: Data.docReferenceId,
        documentCategory: Data.docCategory,
        documentSubCategory: Data.docSubCategory,
        template: "Global",
        documentType: "AAdhaar",
      };
      const options = {
        method: "POST",
        body: requestPayload,
      };
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${onBoardingURL}onboarding/esignature`,
          options,
          response
        ).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
          } else {
            ToastSuccess(
              `${Data.documentName} signing successfully`,
              loadingId
            );
            window.open(`${data.result && data.result.url}`);
          }
        });
      });
    }
  };

  return (
    <div>
      <Modal
        show={openInfo}
        onHide={() => setOpenInfo(false)}
        Data={<InfoTable Data={infoRowData} />}
        Heading={"Documents Details"}
      />

      <Modal
        show={versionDialogOpen}
        onHide={() => setVersionDialogOpen(false)}
        Data={<VersionTable Data={versionData} />}
        Heading={"Documents Version"}
        scrollable={true}
      />

      <Modal
        show={docUpload}
        onHide={() => setDocUpload(false)}
        Data={
          <Upload
            Data={Data}
            apiTrigger={apiTrigger}
            setApiTrigger={setApiTrigger}
            location="Trade"
          />
        }
        Heading={"Documents Upload"}
      />

      <div className={cn(classes.ActionDiv)}>
        <div></div>
        <div className={cn(classes.openAction)}>
          <span
            onClick={() => handleClickInfo()}
            className={Data?.docReferenceId === "" ? classes.isDisabled : ""}>
            <AiOutlineInfoCircle size={16} />
            <span style={{ marginLeft: "5px" }}> Information</span>
          </span>
          <span
            onClick={() => handleClickHistory()}
            className={Data?.docReferenceId === "" ? classes.isDisabled : ""}>
            <ImHistory size={16} />
            <span style={{ marginLeft: "5px" }}> History</span>
          </span>

          <span
            onClick={() => handleClickDownload()}
            className={Data?.docReferenceId === "" ? classes.isDisabled : ""}>
            <AiOutlineCloudDownload size={16} />
            <span style={{ marginLeft: "5px" }}> Download</span>
          </span>

          <span
            className={
              Data?.ownerId && Data?.ownerId !== companyId
                ? classes.isDisabled
                : ""
            }
            onClick={
              uploadRole === "CREATE" ? () => setIsUploading(true) : " "
            }>
            <AiOutlineCloudUpload size={16} />
            <span style={{ marginLeft: "5px" }}>Upload</span>
          </span>

          <span
            className={Data?.docReferenceId === "" ? classes.isDisabled : ""}
            onClick={() => (eSignRole === "CREATE" ? handleClickSign() : " ")}>
            <FaFileSignature size={16} />
            <span style={{ marginLeft: "5px" }}>E-sign</span>
          </span>

          <span
            onClick={() => handleClickAdharSign()}
            className={Data?.docReferenceId === "" ? classes.isDisabled : ""}>
            <FaFileSignature size={16} />
            <span style={{ marginLeft: "5px" }}>Aadhar</span>
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default DocumentsAction;
