import React from "react";
import classes from "./Label.module.css";

const CreatedByLabel = ({
  createdBy,
  createdDate,
  lastUpdateBy,
  lastUpdatedDate,
}) => {
  return (
    <div className={classes.Label}>
      <div>
        <span>Created by - {createdBy ? createdBy : "NA"}</span>
        <span>Created Date - {createdDate ? createdDate : "NA"}</span>
      </div>
      <div>
        <span>Modified by - {lastUpdateBy ? lastUpdateBy : "NA"}</span>
        <span>
          Modified Date - {lastUpdatedDate ? lastUpdatedDate : "NA"}{" "}
        </span>
      </div>
    </div>
  );
};

export default CreatedByLabel;
