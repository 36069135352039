import React from "react";

const StatusContainer = ({ value, color }) => {
  const colors = {
    green: ["#1cbb8c", "#1cbb8c2e"],
    red: ["#ff3d60", "#ff3d602e"],
    yellow: ["#fcb92c", "#fcb92c2e"],
    blue: ["#082cb5", "#aab9f2"],
    violet: ["#970ab9", "#e0afec"],
    rose: ["#ff3d60", "#ff3d602e"],
  };
  const colorList = colors[color];
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
        <div>
          <span
            style={{
              color: colorList[0],
              whiteSpace: "nowrap",
              backgroundColor: colorList[1],
              width: "85px",
              fontSize: "14px",
              padding: "4px 10px",
              borderRadius: "20px",
            }}>
            {value}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default StatusContainer;
