import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "components/Loader/Loader";
import { truncateStringID } from "utils/helper";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { storeContext } from "contexts/Store/Store";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import { ButtonStyled, ButtonStyledDisable} from "components/Container.styled";

const Requsition = ({ Data, companyList, hanndleCraateGroup, requisitionData }) => {
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();

  const payment_mode = [
    { value: "ESCROW", label: "ESCROW" },
    { value: "DIRECT", label: "DIRECT" },
    { value: "OFFLINE", label: "OFFLINE" },
  ];


  const handleBackToFinanceDeal = () => {
    navigate("/financing/invoice/finance-deals");
  };

  const fetchData = async (companyId, id2, name) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        setMemberList(nData);
        hanndleCraateGroup(nData, name, name);
      });
  };

  const chatHandler = (Data) => {
    if (Data?.financerMemberUID !== "" && Data?.traderMemberUID !== "") {
      setItem(Data);
      let name = Data.financeDealId
        ? truncateStringID(Data.financeDealId, 7, "FD")
        : "NA";
      fetchData(Data?.financerMemberUID, Data?.traderMemberUID, name);
    }
  };
  return (
    <div
      style={{
        backgroundColor: "black",
        padding: "2%",
        marginTop: "1%",
        borderRadius: "5px",
        paddingBottom: "0px",
      }}>
      {requisitionData && companyList ? (
        <>
          <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
              <div
                style={{display:"flex", width:"49%", marginRight:"2%"}}
                onClick={() =>
                  navigate(`../../invoice/invoices/${requisitionData.invoiceUID}`)
                }>
                <Input
                  name="Invoice Reference"
                  placeholder="Invoice Reference *"
                  value={
                    requisitionData.invoiceUID
                      ? truncateStringID(requisitionData.invoiceUID, 7, "IV")
                      : "NA"
                  }
                  readOnly={true}
                />
              </div>
              <div style={{display:"flex", width:"49%"}}>
              <Input
                name="Requisiton ID "
                placeholder="Requisiton ID  *"
                value={
                  requisitionData.requisitionId
                    ? truncateStringID(requisitionData.requisitionId, 7, "RQ")
                    : "NA"
                }
                readOnly={true}
              />
              </div>
          </div>

          <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
            <div style={{width:"49%", marginRight:"2%"}}>
              <Select
                  options={payment_mode}
                  name="modeOfPayment"
                  placeholder="Payment Mode"
                  // handleChange={handleChange}
                  value={requisitionData?.modeOfPayment}
                  required={true}
                  readOnly={true}
                />
              </div>
             <div style={{display:"flex", width:"49%"}}>
              <Input
                name="Invoice Amount"
                placeholder="Invoice Amount *"
                value={requisitionData.totalAmountWithInstrument}
                readOnly={true}
              />
              </div>
            </div>
           
            <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
            <div style={{width:"49%", marginRight:"2%"}}>
              <Input
                name="Invoice Currency"
                placeholder="Invoice Currency *"
                value={requisitionData.financeCurrency}
                readOnly={true}
              />
             </div>
             <div style={{display:"flex", width:"49%"}}>
              {companyList.map((item) => {
                if (item.memberUID === requisitionData.sellerMemberUID) {
                  return (
                    <Input
                      name="Seller ID"
                      placeholder="Seller ID *"
                      value={item.memberName}
                      readOnly={true}
                    />
                  );
                }
              })}
            </div>
            </div>

            <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
            <div style={{width:"49%", marginRight:"2%"}}>
              <Input
                name="Loan Product Type"
                placeholder="Loan Product Type *"
                value={requisitionData.financeProductType}
                readOnly={true}
              />
            </div>
             <div style={{display:"flex", width:"49%"}}>
              <Input
                name="Required Finance Amount"
                placeholder="Required Finance Amount *"
                value={requisitionData.financeAmountRequested}
                readOnly={true}
              />
            </div>
            </div>
            
            <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
            <div style={{width:"49%", marginRight:"2%"}}>
              <Input
                name="Interest Rate"
                placeholder="% Interest Rate Per Annum *"
                value={requisitionData.interestRateMin}
                readOnly={true}
              />
              </div>
             <div style={{width:"49%"}}>
              <DateInput
                name="Disbursement Date"
                placeholder="Disbursement Date *"
                value={requisitionData.preferredPaymentDate}
                readOnly={true}
              />
             </div>
             </div>

            <div style={{display:"flex", width:"100%", justifyContent:"center", alignItems:"center", marginBottom:"1%"}}>
            <div style={{width:"49%", marginRight:"2%"}}>
              <Input
                name="Disbursement Mode"
                placeholder="Disbursement Mode *"
                value={requisitionData.preferredPaymentMethod}
                readOnly={true}
              />
            </div>
             <div style={{width:"49%"}}>
              <DateInput
                name="Financing Requisition start Date"
                placeholder="Financing Requisition Start Date *"
                value={requisitionData.financeRequsitionStartDate}
                readOnly={true}
              />
            </div>
            </div>
            <div style={{display:"flex", width:"100%", alignItems:"center", marginBottom:"3%"}}>
            <div style={{width:"49%", marginRight:"2%"}}>
              <DateInput
                name="Financing Requisition End Date"
                placeholder="Financing Requisition End Date *"
                value={requisitionData.financeRequsitionEndDate}
                readOnly={true}
              />
            </div>
            </div>
           
          <ButtonDiv>
            <ButtonStyled onClick={handleBackToFinanceDeal}>
              Back to finance deals
            </ButtonStyled>
            {/* <ButtonStyled
              onClick={() => chatHandler(response)}
              style={{ cursor: "pointer" }}>
              Request More Info
            </ButtonStyled> */}
            <ButtonStyledDisable 
            //  onClick={(e)=>{handleUpdateStatus(e, "ABORT")}}
            >
            Abort
            </ButtonStyledDisable>
          </ButtonDiv>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Requsition;
