import React, { useState, useContext } from "react";
import styles from "./invite.module.css";
import logo from "./tradxlink.png";
import { getClaim } from "utils/authUtil";
import { NetworkURL } from "../../services/BaseURLInstance";
import { useMsal } from "@azure/msal-react";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";
import { useNavigate } from "react-router-dom";
import { InviteMemberValdations } from "utils/Validations/Profile";
import { AuthContext } from "../../contexts/Auth/AuthContext";

export const Invite = () => {
  const { personName, companyName } = useContext(AuthContext);

  const navigate = useNavigate();
  const { accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [sendingRequest, setSendingRequest] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState("");

  const [data, setData] = useState({
    referredOrganizationName: "",
    referrerOrganizationName: companyName,
    email: "",
    phone: "",
    requestStatus: "NEW",
    referrerMemberUID: companyId,
  });

  const handleChange = (e) => {
    setData((prevData) => {
      return { ...prevData, [e.target.name]: e.target.value };
    });
  };

  const HandleSubmit = async (e) => {
    setSendingRequest(true);
    let isCancelled = false;
    if (!isCancelled) {
      e.preventDefault();
      let checkValidation = InviteMemberValdations(data);
      if (checkValidation) {
        Error(checkValidation);
        setSendingRequest(false);
        return;
      } else {
        data.referrerOrganizationName = companyName;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + idToken,
            "x-api-key":
              "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
          },
          body: JSON.stringify(data),
        };
        await fetch(
          `${NetworkURL}network/${companyId}/ReferredMembers`,
          requestOptions
        )
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.json();
          })
          .then((response) => {
            setSendingRequest(false);
            if (response.referredMemberUID) {
              Success("Member Invited Successfully");
              navigate(`/dashboard`);
            } else {
              Error("Member with Email/Phone already exists");
            }
          })
          .catch((err) => {
            setShowToast(true);
            setErrorMsg(err.message);
          });
      }
    }

    return () => {
      isCancelled = true;
    };
  };

  return (
    <div className={styles.inviteContainer}>
      <form onSubmit={HandleSubmit} className={styles.container}>
        <div className={styles.imgDiv}>
          <img src={logo} alt="TradXlink logo" />
        </div>

        <div className={styles.text1}>Refer To TradXLink Network</div>

        <div
          className={styles.formStyle}
          style={{ border: "20px solid #1E1E1E", backgroundColor: "black" }}>
          <div className={styles.inputDiv}>
            <div className={styles.text3}>Company Name *</div>
            <div>
              <input
                name="referredOrganizationName"
                onChange={handleChange}
                type="text"
                placeholder="Enter Company Name"
              />
            </div>
          </div>

          <div className={styles.inputDiv}>
            <div className={styles.text3}>Email *</div>
            <div>
              <input
                name="email"
                onChange={handleChange}
                type="text"
                placeholder="Enter Email"
              />
            </div>
          </div>

          <div className={styles.inputDiv}>
            <div className={styles.text3}>Phone *</div>
            <div>
              <input
                name="phone"
                onChange={handleChange}
                type="text"
                placeholder="Enter Phone"
              />
            </div>
          </div>

          {/* <div className={styles.inputDiv}>
            <div className={styles.text3}>Referal Link</div>
            <div className={styles.copyIconDiv}>
              <input type="text" value={"133XYXQSDD132BK23"} disabled />
              <span onClick={CopyValue} className={styles.copyIcon}>
                <MdContentCopy size={24} />
              </span>
            </div>
          </div>

          <div className={styles.inputDiv}>
            <div className={styles.text3}>Refer using WhatsApp and Email</div>
            <div className={styles.iconDiv}>
              <FaWhatsappSquare size={36} />
              <FiMail size={36} />
            </div>
          </div> */}
        </div>

        <div className={styles.buttonDiv}>
          <button
            type="submit"
            className="d-flex align-items-center justify-content-center"
            style={
              sendingRequest
                ? { backgroundColor: "gray", cursor: "not-allowed" }
                : {}
            }>
            {sendingRequest && (
              <Spinner
                style={{ color: "#42A5F5", marginRight: "5px" }}
                animation="border"
              />
            )}
            <span>Refer To Network</span>
          </button>
        </div>

        <div className={styles.text2}>
          Accelerate your Business with TradXLink Network
        </div>
      </form>

      {/* <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
    </div>
  );
};
