import React from "react";
import styles from "../edit.module.css";
import { GoLocation } from "react-icons/go";
import { BsUpload } from "react-icons/bs";
import Select from "components/fields/Select";
import Input from "components/fields/Input";
import { ImageURLSrc, CheckCountryTitle } from "utils/helper";
import { CountryType } from "utils/Section/Profile";
import CountryCodes from "jsons/CountryCodes.json";
import MultiSelect from "components/fields/MultiSelect";

const CompanyDescription = ({
  companyData,
  handleChange,
  handleLocation,
  setIsUpload,
  coordinate,
  documentData,
}) => {
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));
  console.log("companyDatacompanyData", companyData);

  return (
    <div className={styles.profileForm}>
      <div className={styles.imgDiv}>
        <div>
          <div className={styles.FormDiv}>
            <div style={{ margin: "0px 10px 10px 10px" }}>
              <Input
                name={
                  memberType === "FINANCER" ? "financerName" : "companyName"
                }
                value={
                  memberType === "FINANCER"
                    ? companyData.financerName
                    : companyData.companyName
                }
                placeholder="Enterprise Name*"
                handleChange={(e) => handleChange(e)}
                readOnly={true}
              />
            </div>

            <div style={{ margin: "0px 10px 10px 10px" }}>
              <MultiSelect
                name={
                  memberType === "FINANCER" ? "countryCode" : "companyCountry"
                }
                selected={
                  memberType === "FINANCER"
                    ? {
                        label: CheckCountryTitle(companyData.countryCode),
                        value: companyData.countryCode,
                      }
                    : {
                        label: CheckCountryTitle(companyData.companyCountry),
                        value: companyData.companyCountry,
                      }
                }
                placeholder="Country*"
                options={CountryCodes}
                handleChange={(e) => handleChange(e, "country")}
                isSingle={true}
                isDisabled={true}
              />
            </div>
          </div>

          <div className={styles.FormDiv}>
            <div style={{ margin: "10px" }}>
              <Input
                name={
                  memberType === "FINANCER" ? "websiteUrl" : "companySiteURL"
                }
                value={
                  memberType === "FINANCER"
                    ? companyData.websiteUrl
                    : companyData.companySiteURL
                }
                placeholder="Enterprise Website"
                handleChange={(e) => handleChange(e)}
              />
            </div>

            <div
              style={{ margin: "10px" }}
              className={styles.locationDiv}
              onClick={(e) => handleLocation(e)}>
              <div className={styles.location}>
                <GoLocation size={26} />
              </div>
              <Input
                value={
                  memberType === "FINANCER"
                    ? companyData.financerGeoLocation
                    : companyData.memberGeoLocation
                }
                cursor={true}
                name="locationCordinate"
                placeholder="Geo-Location"
                readOnly={true}
              />
            </div>
          </div>
        </div>

        <div>
          <img
            className="logoimg"
            src={ImageURLSrc(documentData && documentData.id)}
          />

          <span
            onClick={() => setIsUpload(true)}
            style={{ cursor: "pointer", marginLeft: "5px" }}>
            <BsUpload size={24} />
          </span>
        </div>
      </div>

      <div className={styles.FormDiv} style={{ margin: "0px 10px 10px 0px" }}>
        <div style={{ margin: "0px 10px 10px 10px" }}>
          <Select
            options={CountryType}
            name="companyType"
            value={companyData.companyType}
            placeholder="Type of Company*"
            handleChange={handleChange}
          />
        </div>
      </div>

      <div className={styles.FormDiv} style={{ margin: "10px 10px 0px 0px" }}>
        <div style={{ margin: "10px" }}>
          <label For="CreatedBy" className={styles.LabelStyle}>
            Enterprise Description*
          </label>
          <textarea
            className="companydes"
            type="text"
            name={
              memberType === "FINANCER"
                ? "financerDescription"
                : "companyDescription"
            }
            value={
              memberType === "FINANCER"
                ? companyData.financerDescription
                : companyData.companyDescription
            }
            onChange={handleChange}
          />
        </div>
      </div>

      <div className={styles.FormDiv} style={{ margin: "4px 10px 10px 0px" }}>
        <div style={{ margin: "10px" }}>
          <label For="CreatedBy" className={styles.LabelStyle}>
            Products and Services*
          </label>
          <textarea
            className="companydes"
            type="text"
            name={
              memberType === "FINANCER"
                ? "financerProductsServices"
                : "companyProductsServices"
            }
            value={
              memberType === "FINANCER"
                ? companyData.financerProductsServices
                : companyData.companyProductsServices
            }
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyDescription;
