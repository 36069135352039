import React, { useState } from "react";
import classes from "./documentSection.module.css";
import { MdDriveFolderUpload } from "react-icons/md";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { Success, Error } from "components/notifications/responseHandle";
import Loader from "components/Loader/Loader";
import Select from "components/fields/Select";
import { docGroupURL, documentURL } from "services/BaseURLInstance";

const DocumentUpload = ({
  setIsUploading,
  Category,
  SubCategory,
  response, // service
  location,
  selectedCategory,
  setSelectedCategory,
}) => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [isNext, setIsNext] = useState(false);

  const [data, setData] = useState({
    name: "",
    file: "",
  });

  const handleChange = (e, position) => {
    if (position === "category") {
      setSelectedCategory(e.target.value);
      setSelectedSubCategory("");
    } else if (position === "subcategory") {
      setSelectedSubCategory(e.target.value);
    } else {
      setData({
        ...data,
        file: e.target.files[0],
        name: e.target.files[0].name,
      });
    }
  };

  const handleDocumentUpdate = async (event) => {
    event.preventDefault();
    var body = new FormData();
    body.append("name", data.name.split(".").slice(0, -1).join("."));
    body.append("file", data.file);
    body.append("public", true);

    if (selectedCategory === "" || selectedSubCategory === "") {
      Error("Please Select Category & Sub-Category");
      return;
    } else if (data.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${documentURL}documents`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setIsLoading(false);
            setIsError(message || "Something Went Wrong");
          } else {
            handleDocumentGroupUpdate(data);
          }
        });
    }
  };

  const handleDocumentGroupUpdate = async (data) => {
    let instance = response && response.openTradeAccountId;

    let requestPayload = {
      memberId: companyId,
      ownerId: companyId,
      docReferenceId: data.id,
      documentName: data.name,
      docCategory: selectedCategory,
      docSubCategory: selectedSubCategory,
      participantsToSign: [],
      participantsToShare: [],
      referenceData: [],
      tags: [`OAT-${response && response.openTradeAccountId}`],
    };
    await fetch(
      docGroupURL +
        `bff/documentsGroups/documentUpdate?documentId=${data.id}&template=Trade&instanceID=${instance}`, // instanceID === trade id   if refence date has TRADE key use it value to populate instance ID  if trade is not present use SERVICE DEAL ID is instanceID  which is in URL // URL will become &instanceId= '2sddfdfdfdfdfb'
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(requestPayload),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        let { message, status } = data;
        if (message || status) {
          Error(message);
          setIsLoading(false);
          setIsError(message || "Something Went Wrong");
        } else {
          setIsUploading(false);
          Success("Uploaded successfully");
        }
      });
  };

  const handleNext = () => {
    if (selectedCategory === "" || selectedSubCategory === "") {
      Error("Please Select Category & Sub-Category");
      return;
    } else {
      setIsNext(!isNext);
    }
  };

  return (
    <>
      {isLoading ? (
        <div>
          <span>Loading...</span>
          <Loader />
        </div>
      ) : (
        <>
          {isError ? (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          ) : (
            <div>
              {!isNext ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div style={{ width: "33%", marginRight: "10px" }}>
                      <Select
                        placeholder="Select Category *"
                        options={Category}
                        handleChange={(e) => handleChange(e, "category")}
                        value={selectedCategory}
                      />
                    </div>
                    <div style={{ width: "33%" }}>
                      <Select
                        placeholder="Select Sub-Category *"
                        options={SubCategory}
                        handleChange={(e) => handleChange(e, "subcategory")}
                        value={selectedSubCategory}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className={classes.ButtonNext}
                      onClick={() => handleNext()}>
                      Next
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <form
                    onSubmit={(e) => handleDocumentUpdate(e)}
                    className={classes.UploadForm}>
                    <h5>Upload Document</h5>
                    <div className={classes.Upload}>
                      <label onChange={handleChange} htmlFor="formId">
                        <div className={classes.UploadDiv}>
                          <span style={{ color: "#42a5f5" }}>
                            <input
                              name="file"
                              accept=".doc,.  docx,.pdf,.jpeg"
                              type="file"
                              id="formId"
                              hidden
                            />
                            <MdDriveFolderUpload size={40} />
                          </span>

                          <span className="mx-2">
                            {data.name.split(".").slice(0, -1).join(".")}
                          </span>
                        </div>
                      </label>
                    </div>

                    <ButtonDiv>
                      <Button
                        onClick={() => setIsUploading(false)}
                        style={{ cursor: "pointer" }}>
                        Cancel
                      </Button>
                      <Button type="submit" style={{ cursor: "pointer" }}>
                        Upload
                      </Button>
                    </ButtonDiv>
                  </form>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DocumentUpload;
