export const fetchMemberdata = async (data, ...arg) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: 'GET',
      requireAuthentication: true,
      headers: {
        "Authorization": "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    let queryData ="";
    for(let i=0; i<data?.length; i++){
      queryData +=`organizationMemberId=${data[i]}&`
    }
   
   queryData = queryData.substring(0, queryData.length - 1);
   let responseData=[];
   await fetch(`${process.env.REACT_APP_NETWORK_URL}Persons/Organization?${queryData}`, requestOptions)
      .then(response => response.json()).then((data) => {
        for(let i=0; i<data?.length; i++){
            responseData.push(data[i]?.memberUID);
        }
      }
      )
      return responseData
  }