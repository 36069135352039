const ServicesList = [
  {
    type: "TRADE_CONTRACT",
    subtype: ["OTHERS"],
  },
  {
    type: "MEMBER_ONBOARDING",
    subtype: ["OTHERS"],
  },
  {
    type: "KYB",
    subtype: ["IDENTITY_VERIFICATION", "BACKGROUND_CHECK", "OTHERS"],
  },
  {
    type: "FINANCING",
    subtype: [
      "FINANCING_PO",
      "FINANCING_INVOICE",
      "FREIGHT_FINANCING",
      "SYNDICATION_FINANCING",
      "CREDIT_ASSESSMENT",
      "OTHERS",
    ],
  },
  {
    type: "ACCOUNTS_TAXATION",
    subtype: ["TAXES", "ACCOUNTING", "ANALYTICS_REPORTING"],
  },
  {
    type: "COMPLIANCE",
    subtype: [
      "TRADE",
      "FINANCIAL",
      "IMPORT_DECLARATIONS",
      "EXPORT_DECLARATIONS",
      "LMPC_IMPORT_LICENSE",
      "LUT",
    ],
  },
  {
    type: "PAYMENTS",
    subtype: [
      "PAYMENT_ADVANCES",
      "ESCROW",
      "CROSS_BORDER",
      "DOMESTIC",
      "FOREIGN_EXCHANGE",
    ],
  },
  {
    type: "FREIGHT",
    subtype: ["IMPORT", "EXPORT"],
  },
  {
    type: "TRANSPORT",
    subtype: ["TRUCK", "MARITIME", "RAIL", "AIR"],
  },
  {
    type: "TERMINAL",
    subtype: ["OTHERS"],
  },
  {
    type: "SHIPPING",
    subtype: ["OTHERS"],
  },
  {
    type: "CONTAINER",
    subtype: ["OTHERS"],
  },

  {
    type: "CUSTOMS",
    subtype: ["CUSTOM_CLEARANCE", "CUSTOMS_INSPECTION"],
  },
  {
    type: "INSPECTION",
    subtype: ["QUALITY", "OTHERS"],
  },

  {
    type: "WAREHOUSE",
    subtype: ["OTHERS"],
  },
  {
    type: "INVOICING",
    subtype: ["OTHERS"],
  },
  {
    type: "INSURANCE",
    subtype: ["CREDIT", "CARGO", "INLAND"],
  },
  {
    type: "STEVEDORING",
    subtype: ["OTHERS"],
  },
];

export const ServiceType = (name) => {
  let serviceType = ServicesList.map((item) => ({
    label: item.type,
    value: item.type,
  }));

  const Empty = [];
  let temp = ServicesList.filter((item) => {
    if (item.type === name) {
      return item;
    }
  });
  let serviceSubType =
    temp &&
    temp[0] &&
    temp[0].subtype.map((item) => ({
      label: item,
      value: item,
    }));

  if (name !== null) {
    return { serviceType, serviceSubType };
  } else {
    return { serviceType, Empty };
  }
};
