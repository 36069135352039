// contains readOnly field being used in Service request and Service deals

import React, { useState, useEffect } from "react";
import classes from "../../New-Service/Category/freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import { Industry } from "utils/Section/trade";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import { Grid } from "@mui/material";
import Select from "components/fields/Select";
import { Countries } from "utils/DropDown/Country";

const Warehousing = ({ response }) => {
    const [serviceDetails, setServiceDetails] = useState(null);
    useEffect(() => {
        if (response && response.ServiceReqDetails) {
            setServiceDetails(JSON.parse(response.ServiceReqDetails))
        }
    }, [response]);


    const LoadHeader = [
        { name: "QTY" },
        { name: "Quantity Unit" },
        { name: "Area Required (sq ft)" },
        { name: "Gross Weight Per Container" },
        { name: "Measure Unit" },
    ];


    let bb = Object.keys(port_codes);
    let cc = Object.values(port_codes);
    let port_code = [];

    bb.map((q, i) =>
        cc.map((w, o) => {
            if (i === o) {
                port_code.push({ label: w.name, value: q });
            }
        })
    );

    return (
        <>
            <Content>
                {response && serviceDetails && serviceDetails.cargoDetails ? (
                    <>
                        <div className={classes.header} style={{margin:"10px"}}>Where do you need warehousing services?</div>
                        <div className={classes.source} style={{ margin: "10px" }}>
                            <div style={{ marginLeft: "1%", width: "50%" }}>
                                <input
                                    className={classes.radio}
                                    type="radio"
                                    name="freightType"
                                    value="INTERNATIONAL"
                                    readOnly={true}
                                    checked={serviceDetails.freightType == "INTERNATIONAL"}
                                />
                                <label>INTERNATIONAL</label>
                            </div>
                            <div
                                style={{
                                    // fontSize: "25px",
                                    marginLeft: "1%",
                                    width: "50%",
                                    textAlign: "right",
                                    marginRight: "1%",
                                }}
                            >
                                <input
                                    className={classes.radio}
                                    type="radio"
                                    name="freightType"
                                    value="DOMESTIC"
                                    readOnly={true}
                                    checked={serviceDetails.freightType == "DOMESTIC"}
                                />
                                <label>DOMESTIC</label>
                            </div>
                        </div>


                        <div className={classes.source}>
                            <div style={{ width: "50%", margin:"10px" }}>
                                <Select
                                    options={Countries}
                                    name="country"
                                    placeholder="Country"
                                    readOnly={true}
                                    value={serviceDetails.country}
                                />
                            </div>
                            <div style={{ width: "50%",margin:"10px" }}>
                                <Input
                                    name="city"
                                    placeholder="City"
                                    readOnly={true}
                                    value={serviceDetails.city}
                                />
                            </div>
                        </div>
                        <div>
                            {/* <div className={classes.source}>
                                <div style={{ marginBottom: "1.2%", width: "50%" }}>
                                    <label>Estimated Storage Date</label>
                                </div>
                            </div> */}
                            <div className={classes.source}>
                                <div style={{ width: "50%", margin:"10px" }}>
                                    <Input
                                        name="commodity"
                                        placeholder="Commodity"
                                        readOnly={true}
                                        value={serviceDetails.commodity}
                                    // readOnly={true}
                                    />
                                </div>
                                <div style={{ width: "50%", margin:"10px" }}>
                                    <Grid item xs={12} md={6} >
                                        <Input
                                            name="ESTIMATED_STORAGE_DATE"
                                            placeholder="Estimated Storage Date"
                                            value={new Date(
                                                serviceDetails.ESTIMATED_STORAGE_DATE
                                              ).toLocaleDateString()}
                                        />
                                    </Grid>
                                </div>

                            </div>
                        </div>
                        <div>
                        </div>
                        <>
                            <div style={{ margin: "10px" }}>
                                Cargo Details
                            </div>

                            {/* <div style={{ marginBottom: "2%" }}>
                                    <Button onClick={handleCargo}>+ Add Loads</Button>
                                </div> */}
                            <div className={classes.TableContainer}>
                                <Table bordered responsive="sm" className={cn(classes.Table)}>
                                    <thead>
                                        <tr>
                                            {LoadHeader &&
                                                LoadHeader.map((header, i) => (
                                                    <td key={i}>{header.name}</td>
                                                ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(serviceDetails && serviceDetails.cargoDetails && serviceDetails.cargoDetails).map((item, i) => (
                                            <tr key={i}>
                                                <td>
                                                    {item &&
                                                        item.quantity}
                                                </td>
                                                <td>
                                                    {item &&
                                                        item.qtyUnit}
                                                </td>
                                                <td>
                                                    {item &&
                                                        item.areaRequired}
                                                </td>
                                                <td>
                                                    {item &&
                                                        item.grossWeightPerContainer}
                                                </td>
                                                <td>
                                                    {item &&
                                                        item.measureUnit}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </>


                        {serviceDetails && serviceDetails.cargoHazardous &&
                            (
                                <div>
                                    <input
                                        className={classes.Checkbox}
                                        type="checkbox"
                                        checked={true}
                                    // onClick={handleCheckbox}
                                    // onChange={(e) => setIsHazardous(e.target.checked)}
                                    />
                                    <label
                                        style={{ margin: "10px", fontSize: "18px"}}
                                    >
                                        {" "}
                                        This cargo is hazardous
                                    </label>
                                </div>
                            )
                        }

                        {serviceDetails && serviceDetails.cargoHazardous && (
                            <div className={classes.source} style={{ marginBottom: "2%" }}>
                                <div className={classes.source} style={{ width: "40%" }}>
                                    <div style={{ width: "50%", margin:"10px" }}>
                                        <Input
                                            // options={cargoHazardousOptions}
                                            name="cargoHazardous"
                                            placeholder="cargo Hazardous"
                                            // handleChange={handleChange}
                                            value={serviceDetails && serviceDetails.cargoHazardous}
                                            readOnly={true}
                                        />
                                    </div>
                                    <div style={{ width: "50%", margin:"10px" }}>
                                        <Input
                                            name="UN_Number"
                                            placeholder="UN#"
                                            value={serviceDetails && serviceDetails.UN_Number}
                                            // handleChange={handleChange}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>

                        )}
                    </>
                ) : <></>}
            </Content>
        </>
    );
};

export default Warehousing;
