import React, { useState } from 'react'
import { Grid, Button } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import Select from "components/fields/Select";

export default function NewDrawdown(props) {
    const [dummy, setDummy] = useState(true)
    const backFormCreateDrwodown = props.backFormCreateDrwodown;
    const updateDown = () => {
        setDummy(false)
        props.enableDrawdown()
    }

  

    const cancelDrawdown = () => {
        props.goBack()
        setDummy(false)
    }

    const handleChange = props.handleChange;
    const showDraw = dummy || props.newDrawdown
    return (
        <>
            {showDraw && props.drawdownList.map((c, i) => (
                <Grid container >
                    <Grid item xs={12} md={12} style={{ padding: "10px" }}>
                        <h4>{`Drawdown ${i + 1}`}</h4>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Select
                            options={props.options}
                            name="Bank"
                            placeholder="Bank"
                            handleChange={(e) => handleChange(e, i, "Drawdown")}
                            value={c.Bank}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="trancheComponentDrawdownAmount"
                            placeholder="Amount"
                            required={false}
                            value={c.trancheComponentDrawdownAmount}
                            handleChange={(e) => handleChange(e, i, "Drawdown")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                        <DateInput
                            placeholder="Finance Start Date"
                            value={c.trancheComponentDrawdownStartDate || new Date().toLocaleDateString}
                            handleChange={(event) => handleChange(event, i, "trancheComponentDrawdownStartDate")}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                        <DateInput
                            placeholder="Finance End Date"
                            value={c.trancheComponentDrawdownEndDate || new Date().toLocaleDateString}
                            handleChange={(event) => handleChange(event, i, "trancheComponentDrawdownEndDate")}
                        />
                    </Grid>
                </Grid>
            ))
            }
            {showDraw &&
                <div style={{ textAlign: "right" }}>
                    <br></br>

                   
                 
                    <Button
                        variant="contained"
                        color="primary"
                        type='button'
                        style={{ minWidth: "200px", margin: "0px 20px" }}
                        onClick={backFormCreateDrwodown}
                    >
                       Back
                    </Button>
                   
                    <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        style={{ minWidth: "200px", margin: "0px 20px" }}
                        onClick={() => updateDown()}
                    >
                        Next
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ minWidth: "200px", margin: "0px 20px" }}
                        onClick={() => props.createDrawdown()}
                    >
                        Add Another
                    </Button>
                </div>}
        </>
    )
}
