import React from "react";
import classes from "./Column.module.css";

const ColumnList = ({ list, response }) => {
  return (
    <div className={classes.column}>
      {list &&
        list.map((item, index) => (
          <div className={classes.columnList} key={index}>
            <div className={classes.columnContent}>
              <div>{item.name}</div>
              <span style={{ color: "#42A5F5" }}>
                {response && response[item.value] === "null"
                  ? "NA"
                  : response[item.value]}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ColumnList;
