import React from 'react'
import styles from '../../../../invoice.module.css';

const Response = [
    {
        name: 'Contract Reference',
        value: 'Open'
    },
    {
        name: 'Project Reference',
        value: 'PENDING'
    },
    {
        name: 'PO Reference',
        value: '21-Jan-2022'
    },
    {
        name: 'PO Reference Date',
        value: 'PENDING'
    },
]

const Miscellaneous = () => {
    return (
        <div className={styles.summary}>
            { Response && Response.map((data, i) => (
            <div className={styles.summaryWrapper}key={i}>
                <div> <span> {data.name} </span> </div>
                <div> <span style={addBlue}> {data.value} </span> </div>
            </div> 
            ))}
        </div>
    )
}

export default Miscellaneous;

const addBlue = {
    color: "#42A5F5"
}


