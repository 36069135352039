import React, { useEffect, useState } from "react";
import Content from "components/UI/Layout/Content";
import classes from "./transactions.module.css";
import cn from "classnames";
import { FaDownload } from "react-icons/fa";
import Table from "react-bootstrap/Table";
import { CompanyList } from "utils/API/CompanyList.js";
import Loader from "components/Loader/Loader";
import { FindCompanyName } from "utils/helper";
import { STATUS } from "utils/Constants";
import MultiSelect from "components/fields/MultiSelect";
import { paymentServiceURL } from "services/BaseURLInstance"; // REACT_APP_PAYMENT_URL
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
// import Currencies from "jsons/Currenciesr.json";
import {
  TransactionsHeader,
  // QuickFilters,
} from "utils/TableHeader/PaymentSection";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import DateInput from "components/fields/DateInput";
import NoRecord from "components/no-data/noRecord";

const PaymentTransaction = () => {
  const List = CompanyList();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [response, setResponse] = useState(null);
  const [isError, setIsError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filterdLists, setFilterLists] = useState([]);
  const { instance, accounts } = useMsal();
  const [selectCurrency, setSelectCurrency] = useState([]);
  const [selectedCurrencyId, setSelectedCurrencyId] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  const [selectedStatusId, setSelectedStatusId] = useState([]);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [QuickFilters, setQuickFilters] = useState([]);
  const [Currencies, setCurrencies] = useState([]);

  useEffect(() => {
    if (selectStatus) {
      let temporaryList = [];
      selectStatus.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedStatusId(temporaryList);
    }

    if (selectCurrency) {
      let temporaryList = [];
      selectCurrency.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedCurrencyId(temporaryList);
    }
  }, [selectStatus, selectCurrency]);

  useEffect(() => {
    let URL = `${paymentServiceURL}payments?memberId=${companyId}`;
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            setResponse(data);
            setFilterLists(data);
            console.log("datadata", data);
            // let temp = [];
            // data.map((list) =>
            //   temp.push({
            //     label: list.paymentStatus,
            //     value: list.paymentStatus,
            //   })
            // );
            const uniquePaymentStatusSet = new Set();
            const uniquePaymentStatus = [];
            data.forEach((item) => {
              if (!uniquePaymentStatusSet.has(item.paymentStatus)) {
                uniquePaymentStatusSet.add(item.paymentStatus);
                uniquePaymentStatus.push({
                  label: item.paymentStatus,
                  value: item.paymentStatus,
                });
              }
            });
            setQuickFilters(uniquePaymentStatus);

            const uniqueCurrencySet = new Set();
            const uniqueCurrency = [];
            data.forEach((item) => {
              if (!uniqueCurrencySet.has(item.currencySender)) {
                uniqueCurrencySet.add(item.currencySender);
                uniqueCurrency.push({
                  label: item.currencySender,
                  value: item.currencySender,
                });
              }
            });

            setCurrencies(uniqueCurrency);
          })
          .catch((error) => {
            Error("Something went wrong");
            setIsError("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let newdate = Date.parse(dateTo);
    let olddate = Date.parse(dateFrom);

    if (
      (selectedCurrencyId && selectedCurrencyId.length > 0) ||
      (selectedStatusId && selectedStatusId.length > 0) ||
      dateFrom ||
      dateTo
    ) {
      let filtered =
        response &&
        response.filter(
          (data) =>
            (selectedCurrencyId.length > 0
              ? selectedCurrencyId.includes(data.currencySender)
              : data) &&
            (selectedStatusId.length > 0
              ? selectedStatusId.includes(data.paymentStatus)
              : data) &&
            (dateFrom ? Date.parse(data.paymentDueDate) >= olddate : data) &&
            (dateTo ? Date.parse(data.paymentDueDate) <= newdate : data)
          // (dateFrom
          //   ? new Date(data.paymentDueDate).toLocaleDateString() >= dateFrom
          //   : data) &&
          // (dateTo
          //   ? new Date(data.paymentDueDate).toLocaleDateString() < dateTo
          //   : data)
        );
      setFilterLists(filtered);
    } else if (
      selectedCurrencyId.length === 0 &&
      selectedStatusId.length === 0 &&
      !dateFrom &&
      !dateTo
    ) {
      setFilterLists(response);
    }
  }, [selectedCurrencyId, selectedStatusId, dateFrom, dateTo]);

  const handleMultiChange = (e, name) => {
    if (name === "currency") {
      const { value } = e.target;
      setSelectCurrency(value);
    }

    if (name === "status") {
      const { value } = e.target;
      setSelectStatus(value);
    }

    if (name === "datefrom") {
      setDateFrom(new Date(e).toLocaleDateString());
    }

    if (name === "dateend") {
      setDateTo(new Date(e).toLocaleDateString());
    }
  };

  //   "enum": [
  //     "INITIATED",
  //     "MONEY_DISBURED_TO_PROVIDER",
  //     "PENDING",
  //     "RELEASE_INITITATED",
  //     "RELEASE_FAILED",
  //     "RELEASE_COMPLETED",
  //     "VERIFICATION_DOC_FAILED",
  //     "ADDITIONAL_DOC_REQUIRED",
  //     "ACKNOWLEDGED",
  //     "ABORTED",
  //     "FAILED",
  //     "REFUND_INITATED",
  //     "REFUND_FAILED",
  //     "REFUND_REJECTED",
  //     "REFUND_COMPLETED",
  //     "REFUND_PENDING"
  // ]

  const Status = (status) => {
    switch (status) {
      case "INITIATED":
        return STATUS(status, "warning");
      case "MONEY_DISBURED_TO_PROVIDER":
        return STATUS(status, "success");
      case "PENDING":
        return STATUS(status, "warning");

      case "RELEASE_INITITATED":
        return STATUS(status, "warning");
      case "RELEASE_FAILED":
        return STATUS(status, "danger");
      case "RELEASE_COMPLETED":
        return STATUS(status, "success");

      case "VERIFICATION_DOC_FAILED":
        return STATUS(status, "danger");

      case "ADDITIONAL_DOC_REQUIRED":
        return STATUS(status, "warning");
      case "ACKNOWLEDGED":
        return STATUS(status, "success");
      case "ABORTED":
        return STATUS(status, "danger");

      case "FAILED":
        return STATUS(status, "danger");

      case "REFUND_INITATED":
        return STATUS(status, "warning");
      case "REFUND_FAILED":
        return STATUS(status, "danger");
      case "REFUND_REJECTED":
        return STATUS(status, "danger");
      case "REFUND_COMPLETED":
        return STATUS(status, "success");
      case "REFUND_PENDING":
        return STATUS(status, "warning");

      default:
        return status;
    }
  };

  return (
    <>
      {isLoading || List.isLoading ? (
        <Content marginBottom="0%">
          Loading...
          <Loader />
        </Content>
      ) : (
        <>
          {response && List.response && (
            <>
              <Content marginBottom="2%" padding="1.3% 2% 2% 2%">
                <h5>Search</h5>

                <div
                  className={cn(
                    classes.QuickFilters,
                    "d-flex align-items-flex-start"
                  )}>
                  <div>
                    <MultiSelect
                      name="memberlist"
                      placeholder="Currency"
                      selected={selectCurrency}
                      options={
                        Currencies &&
                        Currencies.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )
                      }
                      handleChange={(e) => handleMultiChange(e, "currency")}
                    />
                  </div>

                  <div>
                    <MultiSelect
                      name="memberlist"
                      placeholder="Status"
                      selected={selectStatus}
                      options={
                        QuickFilters &&
                        QuickFilters.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )
                      }
                      handleChange={(e) => handleMultiChange(e, "status")}
                    />
                  </div>
                  <div>
                    <DateInput
                      backDate={true}
                      name="DateFrom"
                      value={dateFrom}
                      placeholder="Date From *"
                      handleChange={(e) => handleMultiChange(e, "datefrom")}
                    />
                  </div>
                  <div>
                    <DateInput
                      backDate={true}
                      name="DateFrom"
                      value={dateTo}
                      placeholder="Date To *"
                      handleChange={(e) => handleMultiChange(e, "dateend")}
                    />
                  </div>
                </div>
              </Content>

              <Content padding="1.3% 2% 1% 2%">
                {filterdLists && filterdLists.length > 0 ? (
                  <div>
                    <div
                      className={cn(
                        "d-flex justify-content-between align-items-center pb-2"
                      )}>
                      <div style={{ fontSize: "18px" }}>Search Results</div>
                      <div
                        className={cn("align-items-center pe-auto")}
                        style={{ cursor: "pointer" }}>
                        <span>Download All</span>
                        <span className={cn(classes.icon, "pl-1")}>
                          <FaDownload />
                        </span>
                      </div>
                    </div>

                    <div className={cn(classes.TableContainer)}>
                      <Table bordered responsive className={cn(classes.Table)}>
                        <thead>
                          <tr>
                            {TransactionsHeader.map((header, index) => (
                              <td key={index}>{header.name}</td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {filterdLists.map((data, index) => (
                            <tr key={index}>
                              <td>
                                {List.response &&
                                  FindCompanyName(
                                    List.response,
                                    data.senderMemberID
                                  )}
                              </td>
                              <td>
                                {List.response &&
                                  FindCompanyName(
                                    List.response,
                                    data.receiverMemberID
                                  )}
                              </td>
                              <td>{data.currencySender}</td>
                              <td>{data.paymentAmount}</td>
                              <td>{data.paymentServiceProvider}</td>
                              <td>
                                {data.paymentType ? data.paymentType : "NA"}
                              </td>
                              <td>
                                {new Date(
                                  data.paymentTransdactionDate
                                ).toLocaleDateString()}
                              </td>
                              <td style={{ minWidth: "270px" }}>
                                {Status(data.paymentStatus)}
                              </td>

                              <td>
                                {data.referenceInstrumentType
                                  ? data.referenceInstrumentType
                                  : "NA"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ) : (
                  <NoRecord />
                )}
              </Content>
            </>
          )}
          {(isError || List.isError) && (
            <Content marginBottom="0%">Something went wrong</Content>
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default PaymentTransaction;
