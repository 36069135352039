import React, { useState, useEffect } from "react";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { serviceURL } from "services/BaseURLInstance";
import Content from "components/UI/Layout/Content";
import classes from "../service.module.css";
import DealTable from "../Table/DealTable";
import Loader from "components/Loader/Loader";
import MultiSelect from "components/fields/MultiSelect";
import cn from "classnames";
// import { StatusFilterListDeal } from "utils/Section/Services";
import { Countries } from "utils/DropDown/Country";
import { CompanyList } from "utils/API/CompanyList.js";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { getIdTokenAsync } from "utils/authUtil";
import { FindCompanyName } from "utils/helper";
import SingleToogleTab from "components/UI/ToogleSelect/SingleToogleTab";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { serviceDeals } from "utils/Section/FormInfo";
export default ({ hanndleCraateGroup }) => {
  const { accounts, instance } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const chatToken = getClaim(accounts, "chat_token");

  const CompanyObject = CompanyList();
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [filteredList, setFilteredList] = useState([]);

  const [selectList, setSelectList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);

  const [ConsumerList, setConsumerList] = useState([]);
  const [selectedConsumer, setSelectedConsumer] = useState([]);
  const [selectedConsumerId, setSelectedConsumerId] = useState([]);

  const [ProviderList, setProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedProviderId, setSelectedProviderId] = useState([]);

  const [subCategoryList, setSubCategoryList] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState([]);

  const [CategoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [CategoryID, setCategoryID] = useState([]);
  const [StatusFilterListDeal, setStatusFilterList] = useState([]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${serviceURL}services/deals?consumerMemberUID=${companyId}&providerMemberUID=${companyId}&serviceId=&status=`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setIsError(message || "Something went wrong");
          } else {
            let newData = data.sort(
              (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
            );
            setResponse(newData);
            setFilteredList(newData);

            // STATUS
            const uniqueStatus = [
              ...new Set(data.map((item) => item.serviceDealStatus)),
            ];
            let filterStatus =
              uniqueStatus &&
              uniqueStatus.map((item) => {
                return { label: item, value: item };
              });
            setStatusFilterList(filterStatus);

            // Category
            const uniqueCategory = [
              ...new Set(data.map((item) => item.serviceCategory[0])),
            ];
            let filterCategory =
              uniqueCategory &&
              uniqueCategory.map((item) => {
                return { label: item, value: item };
              });
            setCategoryList(filterCategory);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (response) {
      const uniqueConsumer = [
        ...new Set(response.map((item) => item.consumerMemberUID)),
      ];

      let filterConsumer =
        uniqueConsumer &&
        uniqueConsumer.map((item) => {
          return {
            label: FindCompanyName(
              CompanyObject.response && CompanyObject.response,
              item
            ),
            value: item,
          };
        });
      setConsumerList(filterConsumer);

      const uniqueProvider = [
        ...new Set(response.map((item) => item.providerMemberUID)),
      ];

      let filterProvider =
        uniqueProvider &&
        uniqueProvider.map((item) => {
          return {
            label: FindCompanyName(
              CompanyObject.response && CompanyObject.response,
              item
            ),
            value: item,
          };
        });
      setProviderList(filterProvider);
    }
  }, [response]);

  const handleMultiChange = (e, name) => {
    if (name === "Status") {
      const { value } = e.target;
      setSelectList(value);
    }

    if (name === "Category") {
      const { value } = e.target;
      setSelectedCategory(value);
    }

    if (name === "subCategory") {
      const { value } = e.target;
      setSelectedSubCategory(value);
    }

    if (name === "consumer") {
      const { value } = e.target;
      setSelectedConsumer(value);
    }

    if (name === "provider") {
      const { value } = e.target;
      setSelectedProvider(value);
    }
  };

  useEffect(() => {
    if (selectList) {
      let temporaryList = [];
      selectList.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedId(temporaryList);
    }

    if (selectedSubCategory) {
      let temporaryList = [];
      selectedSubCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedSubCategoryId(temporaryList);
    }

    if (selectedCategory) {
      let temporaryList = [];
      selectedCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setCategoryID(temporaryList);
    }

    if (selectedConsumer) {
      let temporaryList = [];
      selectedConsumer.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedConsumerId(temporaryList);
    }

    if (selectedProvider) {
      let temporaryList = [];
      selectedProvider.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedProviderId(temporaryList);
    }
  }, [
    selectList,
    selectedSubCategory,
    selectedCategory,
    selectedConsumer,
    selectedProvider,
  ]);

  useEffect(() => {
    if (
      (selectedId && selectedId.length > 0) ||
      (selectedSubCategoryId && selectedSubCategoryId.length > 0) ||
      (CategoryID && CategoryID.length > 0) ||
      (selectedConsumerId && selectedConsumerId.length > 0) ||
      (selectedProviderId && selectedProviderId.length > 0)
    ) {
      console.log("selectedProviderId 00", response);
      let filtered =
        response &&
        response.filter(
          (data) =>
            (selectedId.length > 0
              ? selectedId.includes(data.serviceDealStatus)
              : data) &&
            // (selectedSubCategoryId.length > 0
            //   ? selectedSubCategoryId.includes(data.serviceSubCategory[0])
            //   : data) &&
            // (CategoryID.length > 0
            //   ? CategoryID.includes(data.serviceCategory[0])
            //   : data)
            // (selectedSubCategoryId.length > 0 && CategoryID.length > 0
            //   ? selectedSubCategoryId.includes(data.serviceSubCategory[0]) ||
            //     CategoryID.includes(data.serviceCategory[0])
            //   : selectedSubCategoryId.length > 0
            //   ? selectedSubCategoryId.includes(data.serviceSubCategory[0])
            //   : CategoryID.length > 0
            //   ? CategoryID.includes(data.serviceCategory[0])
            //   : data) &&
            (CategoryID.length > 0
              ? CategoryID.includes(data.serviceCategory[0])
              : data) &&
            (selectedSubCategoryId.length > 0
              ? selectedSubCategoryId.includes(data.serviceSubCategory[0])
              : data) &&
            (selectedConsumerId.length > 0
              ? selectedConsumerId.includes(data.consumerMemberUID)
              : data) &&
            (selectedProviderId.length > 0
              ? selectedProviderId.includes(data.providerMemberUID)
              : data)
        );
      setFilteredList(filtered);
    } else if (
      selectedId.length === 0 &&
      selectedSubCategoryId.length === 0 &&
      CategoryID.length === 0 &&
      selectedConsumerId.length === 0 &&
      selectedProviderId.length === 0
    ) {
      setFilteredList(response);
    }
  }, [
    selectedId,
    selectedSubCategoryId,
    CategoryID,
    selectedConsumerId,
    selectedProviderId,
  ]);

  useEffect(() => {
    if (filteredList) {
      const uniqueSubCategory = [
        ...new Set(filteredList.map((item) => item.serviceSubCategory[0])),
      ];

      let filterSubCategory =
        uniqueSubCategory &&
        uniqueSubCategory.map((item) => {
          return { label: item, value: item };
        });
      setSubCategoryList(filterSubCategory);
    }
  }, [filteredList]);

  return (
    <>
      {isLoading || CompanyObject.isLoading ? (
        <Content>
          Loading...
          <Loader />
        </Content>
      ) : (
        <>
          {response && CompanyObject && CompanyObject.response && (
            <>
              <SingleToogleTab
                title="Search Results"
                select={true}
                Component={
                  <>
                    <div
                      className={cn(
                        classes.SearchDiv,
                        "d-flex justify-content-between align-items-center my-3"
                      )}>
                      <div>
                        <MultiSelect
                          name="Status"
                          selected={selectList}
                          placeholder="Status"
                          options={StatusFilterListDeal}
                          handleChange={(e) => handleMultiChange(e, "Status")}
                        />
                      </div>

                      <div>
                        <MultiSelect
                          name="category"
                          options={CategoryList}
                          selected={selectedCategory}
                          placeholder="Category.."
                          handleChange={(e) => handleMultiChange(e, "Category")}
                        />
                      </div>
                      <div>
                        <MultiSelect
                          name="subCategory"
                          options={subCategoryList}
                          selected={selectedSubCategory}
                          placeholder="Sub-Category"
                          handleChange={(e) =>
                            handleMultiChange(e, "subCategory")
                          }
                        />
                      </div>
                    </div>

                    <div
                      className={cn(
                        classes.SearchDiv,
                        "d-flex justify-content-between align-items-center"
                      )}>
                      <div>
                        <MultiSelect
                          name="Status"
                          selected={selectedConsumer}
                          placeholder="Consumer"
                          options={ConsumerList}
                          handleChange={(e) => handleMultiChange(e, "consumer")}
                        />
                      </div>
                      <div>
                        <MultiSelect
                          name="category"
                          options={ProviderList}
                          selected={selectedProvider}
                          placeholder="Provider"
                          handleChange={(e) => handleMultiChange(e, "provider")}
                        />
                      </div>
                      <div></div>
                    </div>
                  </>
                }
              />

              <Content padding="1% 2%">
                <OverLayTrigger value={serviceDeals} />
                <DealTable
                  data={filteredList}
                  companyList={CompanyObject.response}
                  hanndleCraateGroup={hanndleCraateGroup}
                />
              </Content>
            </>
          )}

          {(isError || CompanyObject.isError) && (
            <Content>
              <div className="alert alert-danger" role="alert">
                {isError || CompanyObject.isError}
              </div>
            </Content>
          )}
        </>
      )}
    </>
  );
};
