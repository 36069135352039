import React, { useState, useContext, useEffect, useCallback } from "react";
import classes from "./Schedules.module.css";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { MilestoneHeader } from "utils/Section/trade";
import Button_Modal from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Content from "components/UI/Layout/Content";
import { AuthContext } from "contexts/Auth/AuthContext";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";
import Participant from "./Participant";
import AddSchedule from "./AddSchedule";
import UpdateSchedule from "./UpdateSchedule";
import { BsArrowLeft } from "react-icons/bs";
import { MdCloudUpload } from "react-icons/md";
import { profileURL, NetworkURL } from "services/BaseURLInstance";
import { AiFillEye } from "react-icons/ai";
import { openTradeAccount } from "services/BaseURLInstance";
import { useParams, useNavigate } from "react-router-dom";
import { truncateStringID } from "utils/helper";
import { CheckPermission } from "utils/Constants/RBAC";
import Upload from "../Upload";
import { PaymentsSummary } from "components/payment/paymentDetails";
import { ToastContainer } from "react-toastify";

import {
  Success,
  Error,
  ToastLoading,
  ToastSuccess,
} from "components/notifications/responseHandle";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest, UseRequestDownload } from "utils/API/Request.js";
// import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { createMilestone } from "utils/Section/FormInfo";
import Popover from "react-bootstrap/Popover";
import { AiOutlineInfoCircle } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { STATUS } from "utils/Constants";
import { BsThreeDotsVertical } from "react-icons/bs";
import UploadDocument from "./UploadDocument";
import { AiOutlineCloudDownload, AiOutlineCloudUpload } from "react-icons/ai";

import NoRecord from "components/no-data/noRecord";

const Schedules = ({ setSelected }) => {
  const { id } = useParams();
  const { OpenTradeAccountDetails, isApiTrigger, setApiTrigger } =
    useContext(AuthContext);
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [isUploadingData, setIsUploadingData] = useState(null);

  const [isSingleScheduleOpen, setIsSingleScheduleOpen] = useState(false);
  const { instance, accounts } = useMsal();
  const [isScheduleFormOpen, setIsScheduleFormOpen] = useState(false);
  const [isActive, setIsActive] = useState("Milestone");
  const [isPercentageComplete, setIsPercentageComplete] = useState(0);

  const [schedule, setSchedule] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);
  const [docUpload, setDocUpload] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const [singleSchedule, setSingleSchedule] = useState(null);
  const scheduleData = JSON.parse(sessionStorage.getItem("scheduleData"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isAllTradeMilestoneDeclined, setIsAllTradeMilestoneDeclined] =
    useState(false);
  const [counter, setCounter] = useState(0);
  const [dataLength, setDataLength] = useState();
  const [Role, setRole] = useState(null);
  const [createmilStoneRole, setCreateMilestoneRole] = useState(null);
  const [companyList, setCompanyList] = useState();

  const [Component, setIsComponent] = useState(
    <UpdateSchedule
      isScheduleFormOpen={isScheduleFormOpen}
      setIsScheduleFormOpen={setIsScheduleFormOpen}
      setIsPercentageComplete={setIsPercentageComplete}
      isPercentageComplete={isPercentageComplete}
      setSelected={setSelected}
      isSingleScheduleOpen={isSingleScheduleOpen}
      setIsSingleScheduleOpen={setIsSingleScheduleOpen}
    />
  );

  const GetResponse = async () => {
    let URL = `${openTradeAccount}openaccounttrades/schedules?openAccountTradeId=${id}&sellerMemberUID=&buyerMemberUID=&status=`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message || "Something Went Wrong");
          setError(message);
        } else {
          setSchedule(data);
          const checkDeclinedMilestone =
            data &&
            data.filter(
              (item, i) => item.tradeMilestoneStatus !== "MILESTONE_DECLINED"
            );
          if (data.length !== 0 && checkDeclinedMilestone.length === 0) {
            setIsAllTradeMilestoneDeclined(true);
          }
        }
      });
    });
  };

  useEffect(() => {
    GetResponse();
  }, [isApiTrigger]);

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      ).then((data) => {
        setIsLoading(false);
        let { message, status } = data;
        if (message || status) {
          Error(message);
        } else {
          setRole(data.role);
        }
      });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setCreateMilestoneRole(
        CheckPermission(
          "CreateMilestone",
          "OpenAccountTradeTab",
          "createMilestone",
          Role
        )
      );
    }
  }, [Role]);

  const handleToogle = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };

  const handleUpdateData = (item) => {
    setIsSingleScheduleOpen(true);
    sessionStorage.setItem("scheduleData", JSON.stringify(item));
  };

  useEffect(() => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${NetworkURL}members?memberId=${companyId}`,
        null,
        response
      ).then((data) => {
        let { message, path } = data;
        if (message || path) {
          Error(message);
          setError(message || "Something went wrong");
        } else {
          setCompanyList(data);
        }
      });
    });
  }, []);

  const Navbar = [
    {
      title: "Milestone",
      description: (
        <UpdateSchedule
          isScheduleFormOpen={isScheduleFormOpen}
          setIsScheduleFormOpen={setIsScheduleFormOpen}
          setIsPercentageComplete={setIsPercentageComplete}
          isPercentageComplete={isPercentageComplete}
          setSelected={setSelected}
        />
      ),
    },

    {
      title: "Participant",
      description: <Participant />,
    },
    {
      title: "Payment Summary",
      description: (
        <PaymentsSummary
          companyList={companyList}
          memberId={companyId}
          OpenTradeAccountDetails={OpenTradeAccountDetails}
          response={schedule}
          setSelected={setSelected}
        />
      ),
    },
  ];

  const handleDocUpload = (item) => {
    setDocUpload(true);
    setSingleSchedule(item);
  };

  const handleViewDocuments = (e, id) => {
    e.preventDefault();
    if (id === undefined) {
      Error("Document ID Not Avilable");
    } else {
      handleNavigate(`/documents/preview/${id}`);
    }
  };

  const MilestoneStatus = (Data) => {
    if (Data.tradeMilestoneStatus === "MILESTONE_APPROVAL_REQUESTED") {
      Data.tradeMilestoneStatus = "MILESTONE_DECLINED";
    }
    const options = {
      method: "PUT",
      body: Data,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${Data.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            setIsScheduleFormOpen(false);
            Success("Declined Successfully");
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
        });
    });
  };

  const handleDeclineMilestone = (e) => {
    let URL = `${openTradeAccount}openaccounttrades/schedules?openAccountTradeId=${id}&sellerMemberUID=&buyerMemberUID=&status=`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            data &&
              data.map((item) => {
                return MilestoneStatus(item);
              });
            setApiTrigger(!isApiTrigger);
            handleUpdateOpenAccountTrade();
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
          setIsLoading(false);
        });
    });
  };

  const handleUpdateOpenAccountTrade = async () => {
    OpenTradeAccountDetails.openTradeStatus = "TRADE_DECLINED";
    const options1 = {
      method: "PUT",
      body: OpenTradeAccountDetails,
    };
    let URL1 = `${openTradeAccount}openaccounttrades/${OpenTradeAccountDetails.openTradeAccountId}`;
    getIdTokenAsync(instance, accounts)
      .then((response) => {
        UseRequest(URL1, options1, response).then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          }
        });
      })
      .catch(() => {
        Error("Something Went Wrong");
      })
      .finally(() => {
        setIsLoading(false);
        setApiTrigger(!isApiTrigger);
      });
  };

  const MilestoneApprovalStatus = (Data) => {
    if (Data.tradeMilestoneStatus === "MILESTONE_APPROVAL_REQUESTED") {
      Data.tradeMilestoneStatus = "MILESTONE_APPROVED";
    }
    const options = {
      method: "PUT",
      body: Data,
    };
    let URL = `${openTradeAccount}openaccounttrades/schedules/${Data.openTradeScheduleUID}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
          } else {
            setIsScheduleFormOpen(false);
            // Success("Milestone Approved Successfully...");
            // setApiTrigger(!isApiTrigger);
          }
        })
        .catch(() => {
          Error("Something Went Wrong");
        })
        .finally(() => {
          // setIsLoading(false);
        });
    });
  };

  const handleApproveMilestone = () => {
    let URL = `${openTradeAccount}openaccounttrades/milestoneApproval?openAccountTradeId=${id}`;

    const options = {
      method: "PUT",
      body: {
        providerMemberUID: companyId,
        consumerMemberUID: companyId,
      },
    };
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          setApiTrigger(!isApiTrigger);
          setIsLoading(false);
          Success("Milestone Approved Successfully...");
        })
        .catch(() => {
          Error("Something Went Wrong");
        })
        .finally(() => {});
    });
  };

  const Status = (status) => {
    switch (status) {
      case "MILESTONE_CREATED": // done
        return STATUS(status, "primary");
      case "MILESTONE_APPROVAL_REQUESTED": // done
        return STATUS(status, "primary");
      case "MILESTONE_APPROVED": // done
        return STATUS(status, "success");
      case "MILESTONE_DECLINED": // done
        return STATUS(status, "danger");
      case "MILESTONE_ABORTED": // done
        return STATUS(status, "danger");
      case "MILESTONE_REACHED": // done
        return STATUS(status, "primary");
      case "MILESTONE_COMPLETED": // done
        return STATUS(status, "success");
      case "DELIVERY_IN_PROGRESS":
        return STATUS(status, "warning");
      case "DELIVERY_COMPLETED": // done
        return STATUS(status, "success");
      case "DELIVERY_APPROVED": // done
        return STATUS(status, "success");
      case "DELIVERY_INITIATED":
        return STATUS(status, "warning");
      case "DELIVERY_DECLINED": // done
        return STATUS(status, "danger");
      case "PAYMENT_INITIATED":
        return STATUS(status, "warning");
      case "PAYMENT_APPROVED": // done
        return STATUS(status, "success");
      case "PAYMENT_PAST_DUE_DATE":
        return STATUS(status, "warning");
      case "PAYMENT_COMPLETED": // done
        return STATUS(status, "success");
      case "PAYMENT_DECLINED": // done
        return STATUS(status, "danger");
      case "DRAFT": // done
        return STATUS(status, "warning");
      case "OTHERS": // done
        return STATUS(status, "primary");
      case "NEW": // done
        return STATUS(status, "primary");
      case "ABORTED": // done
        return STATUS(status, "danger");
      default:
        return status;
    }
  };

  useEffect(() => {
    if (schedule) {
      let temp = 0;
      schedule.map((data) => (temp += Number(data.milestonePaymentPercentage)));
      setIsPercentageComplete(temp);
    }
  }, [schedule]);

  const handleDocumentUpload = (data) => {
    setIsUploadingDocument(true);
    setIsUploadingData(data);
  };

  const handleDocumentDownload = (Data) => {
    let memberUID = Data.providerMemberUID;
    let instanceID = id;
    let docCategories =
      Data.documentGroups &&
      Data.documentGroups[0] &&
      Data.documentGroups[0].docCategory;
    let docSubCategory =
      Data.documentGroups &&
      Data.documentGroups[0] &&
      Data.documentGroups[0].docSubCategory;
    let docFilterTags = [`OAT-${id}`];
    let URL = `${process.env.REACT_APP_BFF_URL}bff/documentsGroups/summary?memberUID=${memberUID}&instanceID=${instanceID}&template=Trade&docCategories=${docCategories}&docFilterTags=${docFilterTags}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response)
        .then((data) => {
          let filter =
            data &&
            data[0] &&
            data[0].documents &&
            data[0].documents.filter((doc) => {
              return doc.docSubCategory === docSubCategory;
            });
          filter && filter[0] && filter[0].docReferenceId
            ? DownloadDocument(filter && filter[0])
            : DownloadDocument();
        })
        .catch((err) => {
          Error("Something went wrong");
        });
    });
  };

  const DownloadDocument = (Data) => {
    if (Data) {
      const loadingId = ToastLoading(`${Data.documentName} downloading...`);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequestDownload(
          `${process.env.REACT_APP_DOCUMENT_URL}documents/${Data.docReferenceId}/download`,
          response
        ).then((blob) => {
          ToastSuccess(
            `${Data.documentName} downloaded successfully`,
            loadingId
          );
          var a = document.createElement("a");
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = Data.documentName;
          a.click();
        });
      });
    } else {
      Error("Document not Found");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Modal
            show={docUpload}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onHide={() => setDocUpload(false)}>
            <Modal.Header closeButton className={classes.Modal}>
              <Modal.Title id="contained-modal-title-vcenter">
                Upload Document
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.Modal}>
              <Content>
                <Upload Data={singleSchedule} />
              </Content>
            </Modal.Body>
            <Modal.Footer className={classes.Modal}>
              <Button_Modal onClick={() => setDocUpload(false)}>
                Close
              </Button_Modal>
            </Modal.Footer>
          </Modal>

          {schedule &&
            OpenTradeAccountDetails &&
            !isScheduleFormOpen &&
            !isUploadingDocument &&
            !isSingleScheduleOpen && (
              <>
                {(OpenTradeAccountDetails.openTradeStatus === "NEW" ||
                  OpenTradeAccountDetails.openTradeStatus ===
                    "TRADE_IN_REVIEW" ||
                  OpenTradeAccountDetails.openTradeStatus ===
                    "TRADE_APPROVED" ||
                  OpenTradeAccountDetails.openTradeStatus ===
                    "TRADE_IN_PROGRESS" ||
                  OpenTradeAccountDetails.openTradeStatus ===
                    "BUYER_TRADE_COMPLETED") &&
                  OpenTradeAccountDetails.sellerMemberId === companyId && (
                    <div className="my-4">
                      {isPercentageComplete && isPercentageComplete === 100 ? (
                        <span>
                          <button className={classes.isDisabled}>
                            + Create a Milestone
                          </button>
                          {/* <OverLayTrigger value={createMilestone} />  */}
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            key="top"
                            placement="top"
                            overlay={
                              <Popover id="popover-positioned-top">
                                <Popover.Body>
                                  <strong>
                                    Milestone percentage reached 100%
                                  </strong>
                                </Popover.Body>
                              </Popover>
                            }>
                            <span className="text-warning">
                              <AiOutlineInfoCircle size={26} />
                            </span>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        <Button
                          onClick={() =>
                            createmilStoneRole === "CREATE"
                              ? setIsScheduleFormOpen(true)
                              : " "
                          }>
                          + Create a Milestone
                        </Button>
                      )}
                    </div>
                  )}
                {schedule[0] != null &&
                  (schedule[0].tradeMilestoneStatus ===
                    "MILESTONE_APPROVAL_REQUESTED" ||
                    schedule[0].tradeMilestoneStatus ===
                      "MILESTONE_DECLINED") &&
                  OpenTradeAccountDetails.openTradeStatus != "TRADE_DECLINED" &&
                  OpenTradeAccountDetails.escrowService != "ESCROW_PAYMENT" &&
                  OpenTradeAccountDetails.openTradeStatus ===
                    "TRADE_APPROVED" &&
                  OpenTradeAccountDetails.buyerMemberId === companyId && (
                    <div className="my-4">
                      <Button onClick={() => handleApproveMilestone()}>
                        Approve All Milestone
                      </Button>
                      <Button onClick={() => handleDeclineMilestone()}>
                        Decline All Milestone
                      </Button>
                    </div>
                  )}

                <div className={cn(classes.TableContainer, "my-2")}>
                  <Table bordered responsive="sm" className={cn(classes.Table)}>
                    <thead>
                      <tr>
                        {MilestoneHeader &&
                          MilestoneHeader.map((header, i) => (
                            <td key={i}>{header.name}</td>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {schedule.map((item, i) => (
                        <tr key={i}>
                          <td
                            style={{
                              color: "rgb(33,142,235",
                              textDecorationLine: "underline",
                            }}
                            onClick={() => {
                              handleUpdateData(item);
                              setIsScheduleFormOpen(true);
                            }}>
                            {item.openTradeScheduleUID &&
                              truncateStringID(
                                item.openTradeScheduleUID,
                                7,
                                "TM"
                              )}
                          </td>
                          <td>{item.tradeStage}</td>
                          <td>{item.tradeSubStage}</td>
                          <td>
                            {item.documentGroups &&
                              item.documentGroups[0] &&
                              item.documentGroups[0].docCategory}
                          </td>
                          <td>
                            {item.documentGroups &&
                              item.documentGroups[0] &&
                              item.documentGroups[0].docSubCategory}
                          </td>
                          <td>
                            {new Date(item.paymentDate).toLocaleDateString()}
                          </td>
                          <td>
                            {(
                              parseFloat(OpenTradeAccountDetails.tradeValue) *
                              (parseFloat(item.milestonePaymentPercentage) /
                                100)
                            ).toFixed(2)}
                          </td>
                          <td>{item.milestonePaymentPercentage}</td>
                          <td>{Status(item.tradeMilestoneStatus)}</td>
                          <td style={{ cursor: "pointer" }}>
                            <div className={classes.ActionsDiv}>
                              <span
                                onClick={() => handleDocumentDownload(item)}>
                                <OverlayTrigger
                            trigger={["hover", "focus"]}
                            key="top"
                            placement="top"
                            color = "#42a5f5"
                            overlay={
                              <Popover id="popover-positioned-top">
                                <Popover.Body>
                                  <strong>
                                    Download
                                  </strong>
                                </Popover.Body>
                              </Popover>
                            }>
                            <span>
                            <AiOutlineCloudDownload size={26} />
                            </span>
                          </OverlayTrigger>
                              </span>
                              {OpenTradeAccountDetails.sellerMemberId ===
                                companyId && (
                                <span
                                  onClick={() => handleDocumentUpload(item)}>
                                  <OverlayTrigger
                            trigger={["hover", "focus"]}
                            key="top"
                            placement="top"
                            color = "#42a5f5"
                            overlay={
                              <Popover id="popover-positioned-top">
                                <Popover.Body>
                                  <strong>
                                    Upload
                                  </strong>
                                </Popover.Body>
                              </Popover>
                            }>
                            <span >
                            <AiOutlineCloudUpload size={26} />
                            </span>
                          </OverlayTrigger>
                                </span>
                              )}
                            </div>

                            {/* <OverlayTrigger
                              trigger={["click", "focus"]}
                              key="center"
                              rootClose
                              placement="left"
                              overlay={
                                <Popover>
                                  <Popover.Body>
                                    <Actions
                                      isUploadingDocument={isUploadingDocument}
                                      setIsUploadingDocument={
                                        setIsUploadingDocument
                                      }
                                    />
                                  </Popover.Body>
                                </Popover>
                              }>
                              <span>
                                <BsThreeDotsVertical size={26} />
                              </span>
                            </OverlayTrigger> */}

                            {/* {OpenTradeAccountDetails.sellerMemberId ===
                              companyId &&
                              (item.tradeMilestoneStatus ===
                                "MILESTONE_APPROVED" ||
                                item.tradeMilestoneStatus ===
                                  "DELIVERY_DECLINED") && (
                                <span
                                  onClick={() => handleDocUpload(item)}
                                  style={{ marginRight: "3%" }}>
                                  <MdCloudUpload size={26} />
                                </span>
                              )}
                            {OpenTradeAccountDetails.sellerMemberId ===
                              companyId &&
                              item &&
                              item.documentGroups[0] &&
                              item.documentGroups[0].docReferenceId && (
                                <span
                                  onClick={(e) =>
                                    handleViewDocuments(
                                      e,
                                      item.documentGroups[0].docReferenceId
                                    )
                                  }
                                  style={{ marginRight: "3%" }}>
                                  <AiFillEye size={26} />
                                </span>
                              )}
                            {OpenTradeAccountDetails.buyerMemberId ===
                              companyId &&
                              item.tradeMilestoneStatus ===
                                "DELIVERY_INITIATED" &&
                              item.documentGroups[0] &&
                              item.documentGroups[0].docReferenceId && (
                                <span
                                  onClick={(e) =>
                                    handleViewDocuments(
                                      e,
                                      item.documentGroups[0].docReferenceId
                                    )
                                  }
                                  style={{ marginRight: "3%" }}>
                                  <AiFillEye size={26} />
                                </span>
                              )} */}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  {schedule && schedule.length === 0 && <NoRecord />}
                </div>
              </>
            )}

          {isScheduleFormOpen && !isSingleScheduleOpen && (
            <AddSchedule
              setIsScheduleFormOpen={setIsScheduleFormOpen}
              setIsPercentageComplete={setIsPercentageComplete}
              isPercentageComplete={isPercentageComplete}
              setSelected={setSelected}
            />
          )}

          {isSingleScheduleOpen && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <div
                    className={cn(
                      classes.AccountSchedule,
                      "d-flex align-items-center"
                    )}
                    onClick={() => {
                      setIsSingleScheduleOpen(false);
                      setIsScheduleFormOpen(false);
                    }}
                    style={{ marginTop: "10px" }}>
                    <span style={{ color: "#218eeb", cursor: "pointer" }}>
                      <BsArrowLeft />
                    </span>
                    <span style={{ color: "#218eeb", cursor: "pointer" }}>
                      Back To List
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <ToogleNavbar
                  Navbar={Navbar}
                  isActive={isActive}
                  handleChange={handleToogle}
                  handleComponent={handleComponent}
                />
              </div>
              <div>{Component !== undefined && Component}</div>
            </>
          )}

          {isUploadingDocument && (
            <UploadDocument
              setIsUploadingDocument={setIsUploadingDocument}
              Data={isUploadingData}
            />
          )}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Schedules;
