import React, { useCallback, useState, useEffect, useContext } from "react";
import CustomizedSteppers from "components/UI/Stepper/Stepper";
import { CompanyOnboardingFlow } from "utils/Section/Workflow";
import Content from "components/UI/Layout/Content";
import classes from "./profile.module.css";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { OnBoardingProfileInfo } from "utils/Section/FormInfo";
import { HiArrowNarrowLeft } from "react-icons/hi";
import {
  NetworkURL,
  documentURL,
  onBoardingURL,
} from "services/BaseURLInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { EditProfileOnboardingValdations } from "utils/Validations/Profile";
import { getIdTokenAsync } from "utils/authUtil";
import Modal from "components/UI/Modal/Modal";
import Upload from "./Upload";
import CompanyDescription from "./ProfileForm/companyDescription";
import CompanyContacts from "./ProfileForm/companyContacts";
import CompanyRegistration from "./ProfileForm/companyRegistrationDetails";
import DirectorDocuments from "pages/Document/DirectorDocument";
import CompanyDocuments from "pages/Document/index";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import { AuthContext } from "contexts/Auth/AuthContext";
import { ProfileOnComDoc, ProfileOnDirector } from "utils/Section/Document";

const ProfileStepper = () => {
  const { isApiTrigger } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  const { instance, accounts } = useMsal();
  const [companyData, setCompanyData] = useState(null);
  const [coordinate, setCoordinate] = useState({});
  const [isUpload, setIsUpload] = useState(null);
  const [documentData, setDocumentData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [companyTypeError, setCompanyTypeError] = useState(null);
  const [isCompanyTypeError, setIsCompanyTypeError] = useState(false);
  const [isAllFilledDocument, setIsFilledDocument] = useState([]);

  const [imageData, setImageData] = useState({
    name: "",
    file: "",
  });

  const [contactNo, setContactNo] = useState({
    primaryNo: "",
    secondaryNo: "",
  });

  const [stepperStatus, setStepperStatus] = useState({
    index: 0,
    status: "progress",
  });

  const profileStepperIndex = JSON.parse(
    sessionStorage.getItem("profileStepperIndex")
  );

  useEffect(() => {
    if (profileStepperIndex === 3) {
      setStepperStatus({
        index: 3,
        status: "progress",
      });
      sessionStorage.setItem("profileStepperIndex", null);
    }
  }, [profileStepperIndex]);

  const handlePrevStatus = () => {
    setStepperStatus({
      index: stepperStatus.index - 1,
      status: "progress",
    });
  };

  const handleNextStatus = () => {
    if (
      ((companyData && companyData.companyCountry === "IN") ||
        (companyData && companyData.countryCode === "IN")) &&
      (companyData.companyType === "PRIVATE_LIMITED" ||
        companyData.companyType === "PUBLIC_LIMITED")
    ) {
      if (
        stepperStatus &&
        (stepperStatus.index === 0 ||
          stepperStatus.index === 1 ||
          stepperStatus.index === 2)
      ) {
        handleUpdateCompanyAPI();
      } else if (stepperStatus.index === 3 || stepperStatus.index === 4) {
        if (isAllFilledDocument && isAllFilledDocument.length > 0) {
          isAllFilledDocument.map((data) =>
            Error(`Please upload ${data.docSubCategory} documents`)
          );
          // Error("Please upload documents");
        } else {
          setStepperStatus({
            index: stepperStatus.index + 1,
            status: "progress",
          });
        }
      } else {
        setStepperStatus({
          index: stepperStatus.index + 1,
          status: "progress",
        });
      }
    } else {
      setIsCompanyTypeError(true);
      setCompanyTypeError(
        "Currently we are not supporting the onboarding of the type of company you selected. Kindly reach out to admin at below mentioned email : support@tradxlink.com "
      );
      Error(
        "Currently we are not supporting the onboarding of the type of company you selected. Kindly reach out to admin at below mentioned email : support@tradxlink.com "
      );
    }
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${NetworkURL}financers/${companyId}`;
      } else {
        URL = `${NetworkURL}companies/${companyId}`;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          let { message } = data;
          setIsLoading(false);
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setCompanyData(data);
            let id = data.companyLogoURL || data.financerLogo;
            if (data.companyLogoURL || data.financerLogo) {
              getIdTokenAsync(instance, accounts).then((response) => {
                UseRequest(
                  `${documentURL}documents/${id}`,
                  null,
                  response
                ).then((res) => {
                  let { message, path } = res;
                  if (message || path) {
                    Error(message);
                  } else {
                    setDocumentData(res);
                  }
                });
              });
            }
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, [isApiTrigger]);

  useEffect(() => {
    if (companyData) {
      setContactNo({
        ...contactNo,
        primaryNo:
          (companyData &&
            companyData.companyPhone &&
            companyData.companyPhone[0]) ||
          "",
        secondaryNo:
          (companyData &&
            companyData.companyPhone &&
            companyData.companyPhone[1]) ||
          "",
      });
    }
  }, [companyData]);

  useEffect(() => {
    let contactNum = [];
    contactNum.push(contactNo.primaryNo);
    contactNum.push(contactNo.secondaryNo);
    setCompanyData({
      ...companyData,
      companyPhone: contactNum,
    });
  }, [contactNo.primaryNo, contactNo.secondaryNo]);

  const handleChange = (e, name) => {
    if (name === "country") {
      const { name, value } = e?.target;
      setCompanyData({
        ...companyData,
        [name]: value?.value,
      });
    } else if (name === "dateOfIncorporation") {
      setCompanyData({
        ...companyData,
        [name]: new Date(e).toISOString(),
      });
    } else if (
      e.target.name === "companyAddress" ||
      e.target.name === "financerAddress"
    ) {
      let TempAddAddress = [];
      TempAddAddress.push(e.target.value);
      setCompanyData({
        ...companyData,
        companyAddress: TempAddAddress,
      });
    } else if (e.target.name === "companyPhonePrimary") {
      setContactNo({
        ...contactNo,
        primaryNo: e.target.value,
      });
    } else if (e.target.name === "companyPhoneSecondary") {
      setContactNo({
        ...contactNo,
        secondaryNo: e.target.value,
      });
    } else if (
      e.target.name === "companyProductsServices" ||
      e.target.name === "financerProductsServices"
    ) {
      setCompanyData({
        ...companyData,
        [e.target.name]: [e.target.value],
      });
    } else {
      setCompanyData({
        ...companyData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleLocation = () => {
    setIsLoading(true);
    navigator.geolocation.getCurrentPosition(function (position) {
      let cordArray = [];
      cordArray.push(position.coords.latitude);
      cordArray.push(position.coords.longitude);
      if (memberType === "FINANCER") {
        setCompanyData({
          ...companyData,
          financerGeoLocation: JSON.stringify(cordArray),
        });
      } else {
        setCompanyData({
          ...companyData,
          memberGeoLocation: JSON.stringify(cordArray),
        });
      }
      setIsLoading(false);
    });
  };

  const handleUpdateCompanyAPI = () => {
    let checkValidation = EditProfileOnboardingValdations(
      companyData,
      stepperStatus
    );
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      setStepperStatus({
        index: stepperStatus.index + 1,
        status: "progress",
      });

      if (memberType === "FINANCER") {
        companyData.financerLegalName = "financerLegalName";
        companyData.financerTradeName = "financerTradeName";
        companyData.reference = "reference";
        companyData.rating = "rating";
        companyData.financerWhatsApp = "financerWhatsApp";
        companyData.financerPlace = "financerPlace";
        companyData.financerPin = "financerPin";
        companyData.financerStateCode = "financerStateCode";
        companyData.financerLogo = "";
        companyData.profileCompletion = "profileCompletion";
        companyData.verified = "verified";
        companyData.isActive = true;
        companyData.isDeleted = "isDeleted";
        companyData.tag = "tag";
        companyData.updateBy = "updateBy";
      } else {
        companyData.dateOfIncorporation =
          companyData.dateOfIncorporation || new Date();
      }

      setIsLoading(true);
      const options = {
        method: "PUT",
        body: companyData,
      };
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${NetworkURL}financers/${companyId}`;
      } else {
        URL = `${NetworkURL}companies/${companyId}`;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, options, response)
          .then((data) => {})
          .catch(() => {
            Error("Something went wrong");
            setIsError("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }
  };

  const handleUpdateOnboarding = () => {
    setIsLoading(true);
    let Payload = {
      memberId: companyId,
      onboardingStatus: "NEW",
      participantType: memberType === "FINANCER" ? "FINANCER" : "COMPANY",
    };
    const options = {
      method: "POST",
      body: Payload,
    };
    let URL = `${onBoardingURL}onboarding/participant`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          handleNavigate(`/profile`);
        })
        .catch(() => {
          Error("Something went wrong");
          setIsError("Something went wrong");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const MandatoryCatsubCatMap = (List) => {
    if (stepperStatus.index === 3) {
      const filteredData = List.filter(
        (item) =>
          ProfileOnComDoc.docCategory.includes(item.docCategory) &&
          ProfileOnComDoc.docSubCategory.includes(item.docSubCategory) &&
          item.docReferenceId === ""
      );
      setIsFilledDocument(filteredData);
    } else {
      const filteredData = List.filter(
        (item) =>
          ProfileOnDirector.docCategory.includes(item.docCategory) &&
          ProfileOnDirector.docSubCategory.includes(item.docSubCategory) &&
          item.docReferenceId === ""
      );
      setIsFilledDocument(filteredData);
    }
  };

  const componentLocations = {
    componentLocation: "onboardingStepper",
    memberLoginId: companyId,
    stepperIndex: 2,
    MandatoryCatsubCatMap: MandatoryCatsubCatMap,
  };

  return (
    <>
      <div
        style={{ color: "#218eeb", cursor: "pointer" }}
        onClick={() => handleNavigate(`../company-profile`)}>
        <span>
          <HiArrowNarrowLeft size={28} />
        </span>
        <span>Back to List </span>
      </div>

      <>
        {isLoading ? (
          <Content>
            <span>Loading...</span>
            <Loader />
          </Content>
        ) : (
          <>
            <Modal
              show={isUpload}
              onHide={() => setIsUpload(false)}
              Data={
                <Upload
                  imageData={imageData}
                  setImageData={setImageData}
                  companyData={companyData}
                  setIsUpload={setIsUpload}
                />
              }
              Heading={"Upload Image"}
            />

            {companyData && !isError && (
              <>
                <div>
                  <OverLayTrigger value={OnBoardingProfileInfo} />
                </div>
                <Content>
                  <div>
                    <CustomizedSteppers
                      Value={CompanyOnboardingFlow}
                      position={stepperStatus}
                    />
                  </div>

                  <hr
                    style={{ border: "2px solid white", borderRadius: "6px" }}
                  />

                  <form>
                    <>
                      {isCompanyTypeError ? (
                        <div>
                          <div className="alert alert-danger" role="alert">
                            {companyTypeError}
                          </div>

                          <ButtonDiv>
                            <Button onClick={() => handleNavigate(`/profile`)}>
                              OK
                            </Button>
                          </ButtonDiv>
                        </div>
                      ) : (
                        <>
                          <div className={classes.ButtonDiv}>
                            {stepperStatus.index !== 0 && (
                              <button
                                type="button"
                                className={classes.nextButton}
                                onClick={handlePrevStatus}>
                                Previous
                              </button>
                            )}

                            {stepperStatus.index !== 5 && (
                              <button
                                type="button"
                                className={classes.nextButton}
                                onClick={handleNextStatus}>
                                Next
                              </button>
                            )}

                            {stepperStatus.index === 5 && (
                              <button
                                className={classes.nextButton}
                                onClick={(e) => handleUpdateOnboarding(e)}>
                                Submit
                              </button>
                            )}
                          </div>

                          <div>
                            {stepperStatus.index === 0 && (
                              <>
                                <div>Company Description </div>
                                <div>
                                  <CompanyDescription
                                    companyData={companyData}
                                    handleChange={handleChange}
                                    handleLocation={handleLocation}
                                    setIsUpload={setIsUpload}
                                    coordinate={coordinate}
                                    documentData={documentData}
                                  />
                                </div>
                              </>
                            )}
                            {stepperStatus.index === 1 && (
                              <div>
                                <CompanyContacts
                                  companyData={companyData}
                                  handleChange={handleChange}
                                  handleLocation={handleLocation}
                                  contactNo={contactNo}
                                />
                              </div>
                            )}
                            {stepperStatus.index === 2 && (
                              <div>
                                <CompanyRegistration
                                  companyData={companyData}
                                  handleChange={handleChange}
                                />
                              </div>
                            )}
                            {stepperStatus.index === 3 && (
                              <div>
                                <CompanyDocuments
                                  componentLocations={componentLocations}
                                />
                              </div>
                            )}
                            {stepperStatus.index === 4 && (
                              <div>
                                <DirectorDocuments
                                  componentLocations={componentLocations}
                                />
                              </div>
                            )}
                            {stepperStatus.index === 5 && (
                              <div>
                                Please Submit to confirm your onboarding updates
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </>
                  </form>
                </Content>
              </>
            )}

            {isError && <Content>{isError}</Content>}
          </>
        )}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    </>
  );
};

export default ProfileStepper;
