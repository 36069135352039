import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { customsForm } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";

const CustomeClearance = ({
  customeClearanceData,
  setCustomeClearanceData,
}) => {
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];
  let paymentTerms = [
    {
      label: "Advance Payment",
      value: "Advance Payment",
    },
    {
      label: "Letter of credit",
      value: "Letter of credit",
    },
    {
      label: "Documents against Payments - D.A.P or D/P basis",
      value: "Documents against Payments - D.A.P or D/P basis",
    },
    {
      label: "Documents against Acceptance (D/A)",
      value: "Documents against Acceptance (D/A)",
    },
    {
      label: "Open Account",
      value: "Open Account",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let incoterm = [
    {
      label: "EXW",
      value: "EXW",
    },
    {
      label: "DDP (Delivered Duty Paid)",
      value: "DDP (Delivered Duty Paid)",
    },
    {
      label: "FOB (Free on Board)",
      value: "FOB (Free on Board)",
    },
    {
      label: "CIF (Cost, Insurance and Freight) ",
      value: "CIF (Cost, Insurance and Freight)",
    },
    {
      label: "FAS (Free Alongside Ship)",
      value: "FAS (Free Alongside Ship)",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let qtyUnitOptions = [
    {
      label: "MT",
      value: "MT",
    },
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "LB",
      value: "LB",
    },
    {
      label: "BOXES",
      value: "BOXES",
    },
    {
      label: "PCS",
      value: "PCS",
    },
  ];

  // let inspectionType = [
  //   {
  //     label: "Packaging ",
  //     value: "Packaging",
  //   },
  //   {
  //     label: "Labeling",
  //     value: "Labeling",
  //   },

  //   {
  //     label: "Documentation and Document Verification",
  //     value: "Documentation and Document Verification",
  //   },
  //   {
  //     label: "Compliance with all relevant regulations and standards",
  //     value: "Compliance with all relevant regulations and standards",
  //   },
  //   {
  //       label: "Physical inspection",
  //       value: "Physical inspection",
  //     },
  //     {
  //       label: "Sample testing",
  //       value: "Sample testing",
  //     },
  // ];

  const [selectList, setSelectList] = useState([]);
  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setCustomeClearanceData({
      ...customeClearanceData,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  // const handleMultiChange = (event) => {
  //   if (event.target.name === "validatedBy") {
  //     setDocumentData({
  //       ...DocumentData,
  //       [event.target.name]: event.target.value,
  //     });
  //   }
  // const handleMultiChange = (e) => {
  //   const { value } = e.target;
  //   setSelectList(value);
  // };
  const handleMultiChange = (e, name) => {
    if (name === "commodity") {
      setCustomeClearanceData({
        ...customeClearanceData,

        [e.target.name]: e.target.value,
      });
      console.log(customeClearanceData, "hello");
    } else if (name === "inspectionType") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    }
  };

  // const handleRadio = (event) => {
  //   const target = event.target;
  //   const name = target.name;
  //   const value = target.value;

  //   setCustomeClearanceData({
  //     ...customeClearanceData,
  //     [name]: value,
  //   });
  // };

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "loadingDate") {
        setDatePicker1(newDate1);
        // setCustomeClearanceData({
        //   ...customeClearanceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "unloadingDate") {
        setDatePicker2(newDate1);
        // setCustomeClearanceData({
        //   ...customeClearanceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setCustomeClearanceData({
        ...customeClearanceData,
        [date]: dateString,
      });
    }
    // else if (e.target.name === "serviceType") {
    //   setCustomeClearanceData({
    //     ...customeClearanceData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    else if (e.target.name === "countryFrom") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "portOrigin") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationPort") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityFrom") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityTo") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ProductName") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "productDescription") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ShipperName") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverAddress") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverName") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ShipperAddress") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerms") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerm") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "hsnOfCode") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyType") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyAmount") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "quantity") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "weightType") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "incoterms") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "incoterm") {
      setCustomeClearanceData({
        ...customeClearanceData,
        [e.target.name]: e.target.value,
      });
    } 
  };
  sessionStorage.setItem(
    "customeClearanceData",
    JSON.stringify(customeClearanceData)
  );
  console.log("customeClearanceData", customeClearanceData);
  return (
    <>
      <Content padding="1% 2% 1.4% 2%">
        <div style={{ marginLeft: "10px" }}>
          <OverLayTrigger value={customsForm} />
        </div>
        <div style={{ margin: "10px 10px 0px 10px" }}>Customs Services Required For ?*</div>

        <div className={classes.source} style={{margin:"5px 20px"}}>
          <div style={{ width: "50%" }}>
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="IMPORT"
              onChange={handleRadio}
              checked={customeClearanceData.serviceType == "IMPORT"}
            />
            <label>IMPORT</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              // marginLeft: "1%",
              width: "50%",
              textAlign: "right",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="EXPORT"
              onChange={handleRadio}
              checked={customeClearanceData.serviceType == "EXPORT"}
            />
            <label>EXPORT</label>
          </div>
        </div>
        <div style={{ margin: "10px 10px 0px 10px" }}>Shipment details</div>
        {/* <div className={classes.source} style={{ marginBottom: "4%" }}>
          <div className={classes.source} style={{ width: "40%" }}>
          <div style={{ width: "30%" }}>
                <Select
                  name="originType"
                  options={sourceOptions}
                  placeholder="From"
                  handleChange={handleChange}
                  value={customeClearanceData.originType}
                />
              </div>
              {customeClearanceData.originType && customeClearanceData.originType === "PORT" ?(
              <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="origin"
                  placeholder="Port"
                  value={customeClearanceData.origin}
                  handleChange={handleChange}
                />
              </div>
              ):(<div style={{ width: "70%" }}>
              <Input
                name="origin"
                placeholder="Country"
                value={customeClearanceData.origin}
                handleChange={handleChange}
              />
            </div>)}
            </div>
            <div style={{ width: "20%" }}></div>
    
            <div className={classes.source} style={{ width: "40%" }}>
              <div style={{ width: "30%" }}>
                <Select
                  options={destinationOptions}
                  name="destinationType"
                  placeholder="To"
                  handleChange={handleChange}
                  value={customeClearanceData.originType}
                />
              </div>

              {customeClearanceData.originType && customeClearanceData.originType === "PORT" ?(
                <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="destination"
                  placeholder="Port"
                  value={customeClearanceData.destination}
                  handleChange={handleChange}
                />
              </div>
              ):(<div style={{ width: "70%" }}>
                  <Input
                name="destination"
                placeholder="Country"
                value={customeClearanceData.destination}
                handleChange={handleChange}
              />
               </div>)}
               </div>
          </div>
          {/* cityFrom
          cityTo */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country  From*"
              handleChange={handleChange}
              value={customeClearanceData.countryFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country To*"
              handleChange={handleChange}
              value={customeClearanceData.countryTo}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityFrom"
              placeholder="City From "
              handleChange={handleChange}
              value={customeClearanceData.cityFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityTo"
              placeholder="City To"
              handleChange={handleChange}
              value={customeClearanceData.cityTo}
              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="portOrigin"
              placeholder="From Port*"
              handleChange={handleChange}
              value={customeClearanceData.portOrigin}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="destinationPort"
              placeholder="To Port*"
              value={customeClearanceData.destinationPort}
              handleChange={handleChange}
            />
          </div>
        </div>
        <hr style={{border:"2px solid white", margin:"10px 10px 10px 10px"}}></hr>
        <div>
          <div>
            <div>
              <label style={{ margin: "0px 10px" }}>COMMODITY</label>
              <div className={classes.source}>
                <div
                  style={{ width: "50%", margin: "5px 10px 10px 10px", paddingRight: "20px" }}
                >
                  <MultiSelect
                    options={Industry}
                    name="commodity"
                    placeholder="Product Category*"
                    handleChange={(e) => handleMultiChange(e, "commodity")}
                    selected={customeClearanceData.commodity}
                    // readOnly={true}
                    //
                    //     name="memberlist"
                    //     selected={selectStatus}
                    //     placeholder="Status"
                    //     options={InvoiceStatus}
                    //
                    //   />
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="ProductName"
                placeholder="Product Name*"
                handleChange={handleChange}
                value={customeClearanceData.ProductName}
                // readOnly={true}
              />
            </div>
            {/* : [Insert Product Name]
: [Insert Product Description]
: []
Shipper Address : [ Insert Address ] */}

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="productDescription"
                placeholder="Product Description*"
                handleChange={handleChange}
                value={customeClearanceData.productDescription}
                // readOnly={true}
              />
            </div>
          </div>
          {/* <label>Shipment Details</label> */}
          {/* <div className={classes.source}>
            <div style={{ width: "50%", marginBottom:"2%" ,marginTop:"1%"}}>
            <Input
              name="productDescription"
              placeholder="Product Description"
              handleChange={handleChange}
              value={customeClearanceData.productDescription}
            // readOnly={true}
            />
          </div>
        </div> */}
          <hr style={{border:"2px solid white", margin:"10px 10px 10px 10px"}}></hr>
          <label style={{ margin:"0px 10px"}}>Shipment Details</label>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
              <Input
                name="ShipperName"
                placeholder="Shipper Name*"
                handleChange={handleChange}
                value={customeClearanceData.ShipperName}
                // readOnly={true}
              />
            </div>

            <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
              <Input
                name="ReceiverName"
                placeholder="Receiver Name*"
                handleChange={handleChange}
                value={customeClearanceData.ReceiverName}

                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div
              style={{ width: "100%", margin: "10px" }}
            >
              <Input
                name="ShipperAddress"
                placeholder="Shipper Address*"
                handleChange={handleChange}
                value={customeClearanceData.ShipperAddress}
                // readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <div
              style={{ width: "100%", margin: "10px" }}
            >
              <Input
                name="ReceiverAddress"
                placeholder="Receiver Address* "
                handleChange={handleChange}
                value={customeClearanceData.ReceiverAddress}
                // readOnly={true}
              />
            </div>
          </div>
          <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
            <Input
              name="hsnOfCode"
              placeholder="HSN code of Goods*"
              handleChange={handleChange}
              value={customeClearanceData.hsnOfCode}
              // readOnly={true}
            />
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <SelectWithSearch
                options={Currenciesr}
                name="currencyType"
                placeholder="Currency*"
                value={customeClearanceData.currencyType}
                handleChange={handleChange}
              />
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="currencyAmount"
                placeholder={`Trade Amount(${customeClearanceData.currencyType ? customeClearanceData.currencyType :""})*`}
                handleChange={handleChange}
                value={customeClearanceData.currencyAmount}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}></div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              // marginBottom: "2%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Select
              options={incoterm}
              name="incoterms"
              placeholder="Incoterm*"
              value={customeClearanceData.incoterms}
              handleChange={handleChange}
            />
          </div>

          {customeClearanceData.incoterms === "OTHERS" ? (
            <div
              style={{
                width: "100%",
                // marginBottom: "2%",
                // marginLeft: "10px",
                margin: "10px",
              }}
            >
              <Input
                name="incoterm"
                placeholder="Please Enter Your Incoterm"
                value={customeClearanceData.incoterm}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              // marginBottom: "2%",
              // marginLeft: "10px",
              margin: "10px"
            }}
          >
            <Select
              options={paymentTerms}
              name="paymentTerms"
              placeholder="Payment Term*"
              value={customeClearanceData.paymentTerms}
              handleChange={handleChange}
            />
          </div>
          {customeClearanceData.paymentTerms == "OTHERS" ? (
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="paymentTerm"
                placeholder="Please Enter Your Payment Term"
                value={customeClearanceData.paymentTerm}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </Content>
    </>
  );
};

export default CustomeClearance;
