import React, { useCallback, useContext, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Table from "../../../components/table";
import { FiMail } from "react-icons/fi";
import { IoMdNotifications } from "react-icons/io";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";

import styled from "styled-components";
import "./SuccessInvoice.css";

import Summary from "./SuccessInvoice/summary";
import { Details } from "./SuccessInvoice/details";
import Workflow from "./SuccessInvoice/workflow";
import { Deals } from "./SuccessInvoice/deals";
import { invoiceFinanceContext } from "../../../contexts/invoiceFinance/invoiceFinanceContext";

export const SuccessInvoice = (props) => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);

  const [selected, setSelected] = React.useState("");
  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  const data = [
    // {
    //   title: "Workflow",
    //   description : <Workflow/>
    // },

    {
      title: "Summary",
      description: <Summary />,
    },
    {
      title: "Details",
      description: <Details />,
    },
    {
      title: "Deals",
      description: <Deals />,
    },
  ];

  const { invoiceData } = useContext(invoiceFinanceContext);

  const { invoiceNumber } = invoiceData;

  return (
    <>
      {invoiceData !== "" ? (
        <>
          <div
            onClick={() =>
              handleNavigate(`/financedashBoard/invoicefinancing/invoices`)
            }
            style={{ display: "flex", letterSpacing: "1px", color: "#42A5F5" }}
          >
            <span style={{ margin: "0px 10px" }}>
              {" "}
              <HiArrowNarrowLeft size={28} />{" "}
            </span>{" "}
            <span>Back to List </span>
          </div>
          <ItemDetails>
            <div>
              {" "}
              Invoices<span>:{invoiceNumber} </span>
            </div>
            <div>
              <span>
                <FiMail size={28} />{" "}
              </span>
              <span>
                {" "}
                <IoMdNotifications size={28} />{" "}
              </span>
            </div>
          </ItemDetails>

          <AccordionWrapper>
            <Accordion>
              {data.map((item, i) => (
                <div style={cardWapper} key={i}>
                  <div style={title} onClick={() => toggle(i)}>
                    <span> {item.title}</span>
                    <span>
                      {selected === i ? (
                        <CgChevronUp size={30} />
                      ) : (
                        <CgChevronDown size={30} />
                      )}
                    </span>
                  </div>

                  <div className={selected === i ? "show" : "description"}>
                    {" "}
                    {item.description}
                  </div>
                </div>
              ))}
            </Accordion>
          </AccordionWrapper>
        </>
      ) : (
        handleNavigate(`/financedashBoard/invoicefinancing/invoices`)
      )}
    </>
  );
};

const ItemDetails = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;
  // border:1px solid red;
  border-radius: 5px;
  margin-bottom: 15px;
  background-color: #2e2e2e;
  font-weight: bold;
  letter-spacing: 1px;

  span {
    margin-right: 10px;
  }
`;

const cardWapper = {
  backgroundColor: "black",
  borderBottom: "0",
  margin: "10px 0px",
  padding: "15px",
  borderRadius: "5px",
  letterSpacing: "2px",
};

const title = {
  // color : "red",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  // marginTop : "10px"
};

const Accordion = styled.div`
  width: 100%;
`;

const AccordionWrapper = styled.div`
  padding: 15px;
  background: rgb(46, 46, 46);
  border-radius: 10px;
`;
