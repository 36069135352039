import React, { useEffect, useState } from "react";
import classes from "pages/Services/New-Service/Category/freight.module.css";

import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";

import { Industry } from "utils/Section/trade";

import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";

const TradeContract = ({ response }) => {
  let transport = [
    {
      label: "AIR",
      value: "AIR",
    },
    {
      label: "SEA",
      value: "SEA",
    },
    {
      label: "COURIER",
      value: "COURIER",
    },
  ];
  let paymentTerms = [
    {
      label: "Advance Payment",
      value: "Advance Payment",
    },
    {
      label: "Letter of credit",
      value: "Letter of credit",
    },
    {
      label: "Documents against Payments - D.A.P or D/P basis",
      value: "Documents against Payments - D.A.P or D/P basis",
    },
    {
      label: "Documents against Acceptance (D/A)",
      value: "Documents against Acceptance (D/A)",
    },
    {
      label: "Open Account",
      value: "Open Account",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let incoterm = [
    {
      label: "EXW",
      value: "EXW",
    },
    {
      label: "DDP (Delivered Duty Paid)",
      value: "DDP (Delivered Duty Paid)",
    },
    {
      label: "FOB (Free on Board)",
      value: "FOB (Free on Board)",
    },
    {
      label: "CIF (Cost, Insurance and Freight) ",
      value: "CIF (Cost, Insurance and Freight)",
    },
    {
      label: "FAS (Free Alongside Ship)",
      value: "FAS (Free Alongside Ship)",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];

  let tradeType = [
    {
      label: "Free Trade Agreement",
      value: "Free Trade Agreement",
    },
    {
      label: "Preferential Trade Agreement",
      value: "Preferential Trade Agreement",
    },
    {
      label: " Comprehensive Economic Partnership Agreement",
      value: " Comprehensive Economic Partnership Agreement",
    },
    {
      label: "  Comprehensive Economic Cooperation Agreement",
      value: "  Comprehensive Economic Cooperation Agreemen",
    },
    {
      label: "Framework agreement",
      value: "PCFramework agreementS",
    },
  ];

  const [serviceDetails, setServiceDetails] = useState([]);
  useEffect(() => {
    console.log(response, "ye hai response");
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails));

      //JSON.parse(response.ServiceReqDetails.commodity)
    }
  }, [response]);

  console.log(serviceDetails, " trade");
  //   let listService = serviceDetails.service?.map((employee, index) => {
  //     return employee.value;
  //   });

  return (
    <>
      {response && serviceDetails ? (
        <>
          <Content>
            <div>{/* <OverLayTrigger value={COMPLIANCE_IMPORT} /> */}</div>
            <div style={{  margin: "10px" }}>
              Trade Contract Services Required For :
            </div>
            <div className={classes.source} style={{ margin: "10px" }}>
              <div style={{ marginLeft: "1%", width: "50%" }}>
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType"
                  value="IMPORT"
                  // onChange={handleRadio}
                  checked={serviceDetails.serviceType == "IMPORT"}
                  readOnly={true}
                />
                <label>IMPORT</label>
              </div>{" "}
              <div
                style={{
                  // fontSize: "25px",
                  marginLeft: "1%",
                  width: "50%",
                  textAlign: "right",
                  marginRight: "1%",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType"
                  value="EXPORT"
                  //  onChange={handleRadio}
                  checked={serviceDetails.serviceType === "EXPORT"}
                  readOnly={true}
                />
                <label>EXPORT</label>
              </div>
            </div>
            <div style={{margin:"10px"}}>Select Type of Service required</div>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "10px", paddingRight:"20px" }}
              >
                <Input
                  //options={tradeType}
                  name="tradeType"
                  placeholder="Trade Type"
                  // // handleChange={handleChange}={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.tradeType}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              {" "}
              <div
                style={{ width: "100%", margin: "10px" }}
              >
                <Input
                  name="contratDetails"
                  placeholder="Trade Contract Description"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.contratDetails}
                  readOnly={true}
                />
              </div>
            </div>
            <label style={{margin:"10px"}}>Personal Details</label>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="personName"
                  placeholder="Name"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.personName}
                  readOnly={true}
                />
              </div>

              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="contactName"
                  placeholder="Contact Details"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.contactName}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="emailadd"
                  placeholder="E-mail Address*"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.emailadd}
                  readOnly={true}
                />
              </div>

              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="companyName"
                  placeholder="Comapany Name"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.companyName}
                  readOnly={true}
                />
              </div>
            </div>

            <label style={{margin:"10px"}}>Exporter Details</label>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="exporterCompanyName"
                  placeholder="Exporter Company Name"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.exporterName}
                  readOnly={true}
                />
              </div>

              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="exporterCountry"
                  placeholder="Exporter Country"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.exporterCompany}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div
                style={{ width: "50%",  margin: "10px" }}
              >
                <Input
                  name="exporterEAdd"
                  placeholder="Exporter E-mail Address*"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.exporterAdd}
                  readOnly={true}
                />
              </div>

              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="exporterAddress"
                  placeholder="Exporter Address"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.exporterAddress}
                  readOnly={true}
                />
              </div>
            </div>
            <label style={{margin:"10px"}}>Importer Details</label>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="importerCompanyName"
                  placeholder="Importer Company Name"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.importerName}
                  readOnly={true}
                />
              </div>

              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="importerCountry"
                  placeholder="Importer Country"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.importerCountry}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="importerEmail"
                  placeholder="Importer E-mail Address*"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.importerEmail}
                  readOnly={true}
                />
              </div>

              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="importerAddress"
                  placeholder="Importer Address"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.importerAddress}
                  readOnly={true}
                />
              </div>
            </div>
            {/* <div className={classes.source}> */}
            <label style={{margin:"10px"}}> Juridiction Country</label>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px", paddingRight:"20px" }}>
                <Input
                  //options={Countries}
                  name="countryj"
                  placeholder="Country  "
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.countryj}
                  readOnly={true}
                />
              </div>
            </div>
            {/* Countries */}

            <div>
              <div>
                <div style={{ margin: "10px" }}>
                  <label>COMMODITY</label>
                </div>
                  <div className={classes.source}>
                    <div
                      style={{
                        width: "50%",
                        // marginBottom: "2%",
                        margin: "10px",
                        paddingRight:"20px"
                      }}
                    >
                      <Input
                        // // // // // //options
                        name="commodity"
                        placeholder="Product Category"
                        // handleChange={handleChange}={// handleChange={handleChange}}
                        value={serviceDetails && serviceDetails.commodity}
                        readOnly={true}
                        //
                        //     name="memberlist"
                        //     selected={selectStatus}
                        //     placeholder="Status"
                        //      // //options={InvoiceStatus}
                        //
                        //   />
                      />
                    </div>
                  </div>
                
              </div>

              <div className={classes.source}>
                <div style={{ width: "50%", margin: "10px",paddingRight:"20px" }}>
                  <Input
                    name="CommodityValue"
                    placeholder="Commodity Value"
                    // handleChange={handleChange}={// handleChange={handleChange}}
                    value={serviceDetails && serviceDetails.CommodityValue}
                    readOnly={true}
                  />
                </div>
              </div>

              <div className={classes.source}>
                <div style={{ width: "50%", margin: "10px" }}>
                  <Input
                    // //options={Currenciesr}
                    name="currencyType"
                    placeholder="Currency"
                    value={serviceDetails && serviceDetails.currencyType}
                    // handleChange={handleChange}={// handleChange={handleChange}}
                  />
                </div>
                <div style={{ width: "50%", margin: "10px" }}>
                  <Input
                    name="currencyAmount"
                    placeholder={`Estimated Cost of Goods or Services (${
                      serviceDetails && serviceDetails.currencyType
                    })`}
                    // handleChange={handleChange}={// handleChange={handleChange}}
                    value={serviceDetails && serviceDetails.currencyAmount}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>

            <div >
              {/* <label>INCOTERM 


</label> */}
            </div>
            <div className={classes.source}>
              <div style={{ width: "100%", margin: "10px" }}>
                <Input
                  // //options={incoterm}
                  name="incoterms"
                  placeholder="Incoterm"
                  value={serviceDetails && serviceDetails.incoterms}
                  // handleChange={handleChange}={// handleChange={handleChange}}
                />
              </div>

              {serviceDetails && serviceDetails.incoterms == "OTHERS" ? (
                <div style={{ width: "100%", margin: "10px" }}>
                  <Input
                    name="incoterm"
                    placeholder="Please Enter Your Incoterm"
                    value={serviceDetails && serviceDetails.incoterm}
                    // handleChange={handleChange}={// handleChange={handleChange}}
                    readOnly={true}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>

            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" , paddingRight:"20px"}}>
                <Input
                  // //options={paymentTerms}
                  name="paymentTerms"
                  placeholder="Payment Term"
                  value={serviceDetails && serviceDetails.paymentTerms}
                  // handleChange={handleChange}={// handleChange={handleChange}}
                />
              </div>
              {serviceDetails && serviceDetails.paymentTerms == "OTHERS" ? (
                <div style={{ width: "50%", margin: "10px" }}>
                  <Input
                    name="paymentTerm"
                    placeholder="Please Enter Your Payment Term"
                    value={serviceDetails && serviceDetails.paymentTerm}
                    // handleChange={handleChange}={// handleChange={handleChange}}
                    readOnly={true}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className={classes.source}>
              <div
                style={{
                  width: "50%",
                  // marginRight: "2%",
                  // marginBottom: "2%",
                  margin: "10px",
                }}
              >
                <DateInput
                  name="startDate"
                  placeholder="Shipment Start Date"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.startDate}
                  readOnly={true}
                />
              </div>

              <div
                style={{
                  width: "50%",

                  // marginBottom: "2%",
                  margin: "10px",
                }}
              >
                <DateInput
                  name="enddate"
                  placeholder="Shipment End Date"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.enddate}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div
                style={{ width: "50%", margin: "10px" }}
              >
                <Input
                  name="origin"
                  placeholder="Origin Of Shipment"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.origin}
                  readOnly={true}
                />
              </div>

              <div
                style={{ width: "50%",  margin: "10px" }}
              >
                <Input
                  name="destination"
                  placeholder="Destination of Shipment"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.destination}
                  readOnly={true}
                />
              </div>
            </div>
            <div className={classes.source}>
              <div style={{ width: "50%", margin: "10px" , paddingRight:"20px"}}>
                <Input
                  // //options={transport}
                  name="transport"
                  placeholder="Mode Of transport"
                  // handleChange={handleChange}={// handleChange={handleChange}}
                  value={serviceDetails && serviceDetails.transport}
                />
              </div>{" "}
            </div>

            <div style={{ width: "100%", margin: "10px" , paddingRight:"20px"}}>
              <TextArea
                name="requirement"
                placeholder="Special Requirements of Shipment *  "
                value={serviceDetails && serviceDetails.requirement}
                // handleChange={handleChange}={// handleChange={handleChange}}
              />
            </div>
          </Content>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default TradeContract;
