import React, { useCallback, useState, useEffect, useContext } from "react";
import Table from "components/table/Table";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import {ImCheckboxChecked,ImCheckboxUnchecked,ImHistory,} from "react-icons/im";
import { MdCloudUpload } from "react-icons/md";
import ShareDocument from "../shareDocument";
import DocumentActivity from "../documentActivity";
import DigitalSign from "../DigitalSign";
import styles from '../Document.module.css'
import DocumentIcon from 'components/Icons/document_icon';
import { setCookie } from "components/Helper";
import { storeContext } from 'contexts/Store/Store';
import { FindCompanyName, notify } from 'utils/helper';
import RequestDocument from ".././requestDocument.js";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Divider } from '@mui/material';
import { documentDetails, documentVersion } from "api/Documents/documentDetails";
import { DoubleTick } from "components/icon";
import VersionDialog from "../versionDialog";
import { NetworkURL } from '../../../services/BaseURLInstance'

export default ({ category, idToken, companyId, list, tags, secondaryCompany }) => {
  const navigate = useNavigate();
  const [response, setResponse] = useState([]);
  const [temp, setTemp] = useState({ index: "", value: "" });
  const [userType, setUserType] = useState("");
  const { fetchCompanyList } = useContext(storeContext)
  const [listMember, setListMember] = useState("");
  const [memberList, setMemberList] = useState("");
  // const [rowData, setRowData] = useState({})
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [versionDialogOpen, setVersionDialogOpen] = useState(false)
  const [versionData, setVersionData] = useState([]);
  const [docResponse, setDocResponse] = useState("")

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    fetchCompanyList(URL, idToken).then((companyList) => {
      setCompanyList(companyList)
      let user = companyList.find(item => {
        if (item.memberUID === companyId) {
          return item.memberName
        }
      })

      let overallList = companyList.filter((item) => item.memberUID !== companyId).filter((obj => obj.memberType !== "PERSON" && obj.memberType))
      setMemberList(companyList.filter((obj => obj.memberType !== "PERSON" && obj.memberType)))
      setListMember(overallList)
      setUserType(user.memberName)
    }).catch((error) => {
      console.log(error)
    })
  }, [])

  useEffect(() => {
    if (idToken && category && companyId) {
      setResponse(list.documents)
    }
  }, [category])

  const handleNavigation = (_e, value) => {
    setSelected(value);
  };

  const fetchDocumentDetails = (docId) => {
    documentDetails(docId, idToken).then((response) => {
      const { name, owner, validated, isDeleted, id, docIssuer, ...rest } = response;

      const finalRes = {
        name, owner: FindCompanyName(companyList, owner), validated, isDeleted, documentId: id, docIssuer
      }
      setData(finalRes)
      setDocResponse(response);
    }).catch(err => {
      console.log(err, "error")
    })
  }

  const handleClickInfo = (rowData) => {
    if (!rowData.docReferenceId) {
      return
    }
    // setRowData(rowData);
    setOpen(true);
    setTimeout(() => {
      if (!data?.docReferenceId) {
        fetchDocumentDetails(rowData.docReferenceId);
        setOpen(true);
      }
    }, 1000);
  };

  const findMemberName = (ownerId) => {
    let user = companyList.find(item => {
      if (item.memberUID === ownerId) {
        return item.memberName
      }
    })
    return user?.memberName || user
  }

  const handleClickHistory = (rowData) => {
    if (!rowData.docReferenceId) {
      return
    }
    // setRowData(rowData);
    setTimeout(() => {
      fetchDocumentVersion(rowData.docReferenceId);
      setVersionDialogOpen(true);
    }, 1000);
  };

  const fetchDocumentVersion = (docId) => {
    documentVersion(docId, idToken).then((res) => {
      setVersionData(res)
    }).catch(error => console.log("error", error))
  }

  const downloadDocument = async (documentId) => {
    let requestParams = {
      "docIds": [documentId],
    }
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(requestParams)
    };
    await fetch(`${process.env.REACT_APP_DOCUMENT_URL}documents/download`, requestOptions)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => {
        notify('success', "Downloaded")
      })
      .catch(err => {
        console.log('error', err);
        notify('error')
      })
  }

  const versionDialogClose = () => {
    setVersionDialogOpen(false);
  };

  const getColors = (member_id) => {
    let color1 = "lightgray";
    let color2 = "lightgray";
    if (data && data?.validatedBy && data?.validatedBy.includes(member_id)) {
      color1 = "green";
    } else if (data && data?.pendingValidators && data?.pendingValidators.includes(member_id)) {
      color1 = "#52D5F5";
    }
    if (data?.docSharedWithMembers?.length > 0) {
      color2 = "green";
    } else if (data?.docSharePending?.length > 0) {
      color2 = "#52D5F5";
    }
    return [color1, color2];
  }

  const handleClickDownload = (docId) => { downloadDocument(docId) };

  const handleClickSelect = (value) => {
    if (value.original.docReferenceId !== "") {
      setTemp({ index: value.index, value: true, id: value.original.docReferenceId, documentName: value.original.documentName, docCategory: value.original.docCategory, docSubCategory: value.original.docSubCategory })
      fetchDocumentDetails(value.original.docReferenceId);
    }
  };

  const childProps = {
    companyId, idToken, tempData: temp, listMember, userType, docResponse, tags, memListWithLoggedUser: memberList
  }

  const Component = () => {
    switch (selected) {
      case ("documentActi"):
        return <DocumentActivity {...childProps} />
      case ("shareDoc"):
        return <ShareDocument {...childProps} />
      case ("digitalSign"):
        return <DigitalSign {...childProps} />
      case ("requestDocument"):
        return <RequestDocument {...childProps} />
    }
  }

  const columns = [
    {
      Header: "Select",
      accessor: "select",
      Cell: (cell) => {
        return (
          <button
            className="buttonIcon"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
            }}
            value={cell.accessor}
          >
            {parseInt(temp.index) === cell.row.index ? <ImCheckboxChecked onClick={() => handleClickSelect(cell.row)} size={26} /> : <ImCheckboxUnchecked onClick={() => handleClickSelect(cell.row)} size={26} />}
          </button>
        );
      }
    },
    {
      Header: "Name",
      accessor: "documentName",
      Cell: (cell) => {
        return (
          <>
            {cell.value && <OwnerStyled>
              <DocumentIcon color={true ? "#34BE0F " : "#FF0000"} />
              <DownloadUpload>
                <button
                  className="buttonIcon"
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    color: "white",
                    fontSize: "18px",
                  }}
                  value={cell.accessor}
                  onClick={() => handleNavigate("/documentspreview")}
                >
                  {cell.value}
                </button>
              </DownloadUpload>
            </OwnerStyled>}
          </>
        )
      },

    },
    {
      Header: "Type",
      accessor: "docSubCategory",
    },
    {
      Header: "Owner",
      Cell: (cell) => {
        if (cell) {
          return findMemberName(cell?.row?.original?.ownerId || cell?.row?.original?.memberId)
        } else {
          return "-"
        }
      }
    },
    {
      Header: "Info",
      accessor: "info",
      Cell: (cell) => (
        <button
          className="buttonIcon"
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "white",
            cursor: "pointer",
          }}
          value={cell.accessor}
          onClick={() => handleClickInfo(cell.row.original)}

        >
          <AiOutlineInfoCircle size={26} />
        </button>
      ),
    },
    {
      Header: "Version",
      accessor: "history",
      Cell: (cell) => (
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
            color: "white",
            cursor: "pointer",

          }}
          value={cell.accessor}
          onClick={() => handleClickHistory(cell.row.original)}
        >
          <ImHistory size={26} />
        </button>
      ),
    },
    {
      Header: "Download/Upload",
      Cell: (cell) => (
        <DownloadUpload>
          {cell.row.original.documentName && <button
            className="buttonIcon"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              cursor: secondaryCompany ? "none" : "pointer"
            }}
            value={cell.accessor}
            disabled={secondaryCompany}
            onClick={() => handleClickDownload(cell.row.values)}
          >
            <FaDownload size={25}> </FaDownload>
          </button>}
          <button
            className="buttonIcon"
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "white",
              marginLeft: "32px",
              cursor: secondaryCompany ? "none" : "pointer"
            }}
            disabled={secondaryCompany}
            value={cell.accessor}
            onClick={() => {
              setCookie("upload_doc", JSON.stringify(cell.row.original), 60);
              setCookie("DocCategory", "FINANCE", 60);
              if (tags.length > 0 && tags.filter(i => i !== undefined && i !== null).length > 0) {
                handleNavigate(`/documents/upload?financeId=${tags[0]}`)
              } else {
                handleNavigate(`/documents/upload`)
              }
            }}
          >
            <MdCloudUpload size={26} />
          </button>
        </DownloadUpload >
      ),
    },
  ];

  const [selected, setSelected] = useState("shareDoc");

  const handleNavigate = useCallback((url) => navigate(url), []);

  return (
    <>
      <div className={styles.Card}>
        <div className={styles.cardHeader}>
          <span className={styles.heading1}>Finance Documents</span>
        </div>
        {(response) &&
          <div className={styles.tableWrapper}>
            {response && Array.isArray(response) && (
              <Table columns={columns} data={response} className={styles.companyTable} />
            )}
          </div>
        }

        <div className={styles.buttonDiv}>
          <button className={styles.buttonStyle} onClick={(e) => { handleNavigation(e, "shareDoc") }} >
            Share Document
          </button>

          <button className={styles.buttonStyle} onClick={(e) => { handleNavigation(e, "documentActi") }} >
            Document History
          </button>

          <button className={styles.buttonStyle} onClick={(e) => { handleNavigation(e, "requestDocument") }}>
            Request Document
          </button>

          <button className={styles.buttonStyle} onClick={(e) => { handleNavigation(e, "digitalSign") }}>
            Digital Signature
          </button>
        </div>
      </div>

      {(selected) && Component()}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ backgroundColor: "#5a8afa" }} id="responsive-dialog-title">
          {"Document Details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container spacing={{ xs: 2 }} columns={{ xs: 12, sm: 12, md: 12 }}>
              {Object.keys(data).map((obj, index) =>
                <Grid item xs={6} md={6} key={index}>
                  <span style={{ color: "black", fontSize: "16px", fontWeight: "bolder" }}>{obj?.toUpperCase()}</span>
                  <span style={{ color: "blue", fontSize: "14px" }}>{` : ${data[obj]} `}</span>
                </Grid>
              )
              }
            </Grid>
            <Divider style={{ marginTop: "10px" }} />
            <h2>Share Document & Digital Sign Members</h2>
            {docResponse && docResponse?.pendingValidators && docResponse?.pendingValidators.map((ite) => {
              let colors = getColors(ite);
              return (<Grid xs={12} md={12} style={{ display: "flex", justifyContent: "space-between" }}>
                <span style={{ width: "50%", alignItems: "center", display: "flex" }} >
                  {FindCompanyName(companyList, ite)}:
                </span>
                <span style={{ width: "50%" }}>
                  <DoubleTick tickColor1={colors?.[0]} tickColor2={colors?.[1]} />
                </span>
              </Grid>)
            }
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {
        versionDialogOpen && <VersionDialog versionDialogClose={versionDialogClose} versionDialogOpen={versionDialogOpen} versionData={versionData} />
      }
    </>
  );
};


const DownloadUpload = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  button:nth-child(1) {
    margin: 0px 10px;
    cursor: pointer;
  }
  button:nth-child(2) {
    cursor: pointer;
  }
  justify-content: space-evenly;
`;

const OwnerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  float: left;
`;




