import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import classes from "./Modal.module.css";
import Input from "components/fields/Input";
import { Error, Success } from "components/notifications/responseHandle";
function Example({ show, setShow, response }) {
  const [formData, setFormData] = useState({
    subject: "",
    body: "",
  });
  console.log("formData", formData);

  const handleClose = () => setShow(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (formData.subject.trim() === "") {
      Error("Subject is required");
    }
    if (formData.body.trim() === "") {
      Error("Body is required");
    }
  };

  return (
    <>
      <Modal
        centered
        show={show}
        className={classes.ModalContainer}
        onHide={handleClose}>
        <Modal.Header closeButton className={classes.ModalHeader}>
          <Modal.Title>Send Mail</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.ModalBody}>
          <div>
            <div>
              <label className={classes.label}>Subject</label>
            </div>
            <input
              className={classes.input}
              type="text"
              value={formData.subject}
              name="subject"
              placeholder="Subject"
              onChange={(e) => handleChange(e)}
            />
          </div>
          <div className="my-2">
            <div>
              <label className={classes.label}>Body</label>
            </div>
            <textarea
              style={{ height: "100px" }}
              className={classes.input}
              type="text"
              name="body"
              placeholder="Body"
              onChange={(e) => handleChange(e)}
              value={formData.body}
            />
          </div>
          <div className="d-flex justify-content-end my-2">
            <div style={{ marginRight: "10px" }} onClick={handleClose}>
              <Button variant="secondary">Cancel</Button>
            </div>
            <Button variant="primary" onClick={handleSubmit}>
              Send
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Example;
