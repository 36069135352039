import { App as SendbirdApp } from "sendbird-uikit";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../chatapp/styles.css";
import { IoIosArrowDroprightCircle } from "react-icons/io";
import { SendBirdProvider as SBProvider } from "sendbird-uikit";
import CustomizedApp from "./CustomizedApp";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";

const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
//const userId="nebula"

const ChatApp = ({
  isOpenChat,
  toggleChat,
  hanndleCraateGroup,
  chatURL,
  isOpen,
}) => {
  const { accounts } = useMsal();
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(
      `/${localStorage
        .getItem("lasturl")
        .substring(22, localStorage.getItem("lasturl").length)}`
    );
  };

  return (
    <>
      <div
        style={{
          display:
            isOpenChat || window.location.href.includes("/chat")
              ? "block"
              : "none",
        }}>
        <div
          style={
            isOpenChat
              ? {
                  width: isOpen ? "85vw" : "95vw",
                  minWidth: isOpen ? "85vw" : "95vw",
                  display: "flex",
                }
              : {
                  width: "54px",
                  minWidth: "54px",
                  background: "#0d48a1",
                  display: "none",
                }
          }>
          {}
          <div
            style={{
              width: "4%",
              alignItems: "center",
              display: "flex",
              cursor: "pointer",
            }}>
            <div onClick={() => toggleChat()}>
              <IoIosArrowDroprightCircle size={40} />
            </div>
          </div>
          <div
            style={{
              fontFamily: "sans-serif",
              textAlign: "center",
              width: "96%",
            }}>
            <SBProvider
              appId={APP_ID}
              userId={memberId}
              accessToken={chatToken}
              theme="dark">
              <CustomizedApp chatURL={chatURL} />
            </SBProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatApp;
