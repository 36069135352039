import React from "react";
import { useContext } from "react";

import { BsPatchQuestion, BsPatchCheck } from "react-icons/bs";
import { TradeContext } from "../../tradeProvider";
import { Container, Row, Col } from "react-bootstrap";

export default ({ item, subData }) => {
  const { companyList } = useContext(TradeContext);
  return (
    <>
      {/* <div
        style={{
          padding: "1%",
          borderRadius: "5px",
          width: "90%",
          display: "flex",
        }}>
        <div
          style={{
            fontSize: "12px",
            color: "#218EEB",
            marginRight: "2%",
            textAlign: "left",
            alignItems: "center",
          }}>
          {item.role}
        </div>
        <div
          style={{
            marginRight: "1%",
            textAlign: "left",
            display: "flex",
          }}>
          {item.share ? (
            <BsPatchCheck size={17} color={"#198754"} />
          ) : (
            <BsPatchQuestion size={17} color={"#FF0000"} />
          )}
          <span
            style={{
              fontSize: "12px",
              width: "100%",
              marginLeft: "5px",
            }}>
            {item.memberName}
          </span>
        </div>
      </div> */}
      <Container style={{ marginBottom: "10px" }}>
        <Row style={{ padding: "1%", borderRadius: "5px" }}>
          <Col md={6} style={{ fontSize: "12px", color: "#218EEB" }}>
            {item.role}
          </Col>
          <Col xs={6} style={{ display: "flex", alignItems: "left" }}>
            {item.share ? (
              <BsPatchCheck size={17} color={"#198754"} />
            ) : (
              <BsPatchQuestion size={17} color={"#FF0000"} />
            )}
            <span style={{ fontSize: "12px", marginLeft:"8px" }}>{item.memberName}</span>
          </Col>
        </Row>
      </Container>
    </>
  );
};
