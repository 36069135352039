import React, {useState} from 'react';
import Screen from './verifyPhone.styled';
import { useNavigate } from 'react-router-dom';
import {FaMobileAlt, FaUser} from "react-icons/fa";

const VerifyPhone = ()=>{

    const navigate=useNavigate();
    const [mobile,setMobile]=useState("");

    const handleChange=(e)=>{
        e.preventDefault();
        setMobile(e.target.value)
     }
     const handleVerify=()=>{   
        navigate('/verifyphone-otp')
     }

    return(
       <Screen>
            <div className='header'>TRADXLINK</div>
            <div className='subHeader'>Seller</div>
            <div className='verifyPhoneHeading'>Phone Number Verify</div>
            <div className='mobile'>
                <label For="phone"><FaMobileAlt/></label>
                <input type="text" name="mobile" placeholder="Phone Number" value={mobile} onChange={handleChange} />
            </div>
            <div className='horizontalLine'><hr></hr></div>
            <button className='nextbutton' onClick={handleVerify}>Send Verification code</button>
       </Screen>
        
    )
}
export default VerifyPhone;