import React, { useEffect, useState, useContext } from "react";
import LoanTable from "../../../components/loantable/LoanTable";
import { loanApi } from "../../../services/BaseURLInstance";
import styled from "styled-components";
import Loader from "../../../components/Loader/Loader";
import { storeContext } from "../../../contexts/Store/Store";

import { CompanyList } from "utils/API/CompanyList.js";

const Styles = styled.div`
  .tablebox {
    background: #343434;
    width: 100%;
    border-radius: 5px;
  }
`;

export default (props) => {
  const { fetchCompanyList } = useContext(storeContext);

  const CompanyObject = CompanyList();
  let financerMemberUID = "";
  let traderMemberId = "";

  const [response, setResponse] = useState(null);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (memberType === "TRADER") {
        traderMemberId = companyId;
      } else {
        financerMemberUID = companyId;
      }

      let URL = `${loanApi}loans?traderMemberId=${traderMemberId}&financerMemberId=${financerMemberUID}&loanStatus=ACTIVE`;
      fetchCompanyList(URL, idToken)
        .then((companyList) => {
          setResponse(companyList);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <Styles>
      {response && !CompanyObject.isLoading ? (
        <div className="loanList">
          {Array.isArray(response) && (
            <div className="tablebox">
              <LoanTable data={response} companyList={CompanyObject.response} />
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </Styles>
  );
};
