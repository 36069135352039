import React, {useState} from 'react';
import Screen from './signup.styled';
import {MdEmail} from "react-icons/md";
import {FaMobileAlt, FaUser} from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import {BiLockAlt} from "react-icons/bi";


const Signup = ()=>{

    const navigate=useNavigate();
    const [user,setUser]=useState({
        firstname:"",
        lastname:"",
        mobile:"",
        email:"",
        password:"",
        confirmPassword:"",
        checkBox:""
    });
    
    const handleChange=(e)=>{
       e.preventDefault();
       
       const {name,value}=e.target;
       setUser({
           ...user,
           [name]:value
       })
    }
    const handleCreateAccount=()=>{
        if(user.firstname!=="" && user.lastname!=="" && user.mobile!=="" && user.email!=="" && user.password!=="" && user.confirmPassword!=="")
        { 
         navigate('/verifyphone');
        }
        else{
            alert("Please fill the required details")
        }
    }
   const handleLogin=()=>{
         
              navigate('/login');
   }

    return(
       <Screen>
            <div className='header'>TRADXLINK</div>
            <div className='subHeader'>Seller</div>
            <div className='signupHeading'>Sign-up</div>
            <div className='user'>
                <label For="firstname"><FaUser/></label>
                <input type="text" name="firstname" placeholder="First Name" value={user.firstname} onChange={handleChange} />
                <label For="lastname" className='lastname'><FaUser/></label>
                <input type="text" name="lastname" placeholder="Last Name" value={user.lastname} onChange={handleChange} />
            </div>
            <div  className='underline'>
                <div className='horizontalLine'><hr></hr></div>
                <div className='horizontalLine'><hr></hr></div>
            </div>
            <div className='mobile-email'>
                <label For="mobile"><FaMobileAlt/></label>
                <input type="text" name="mobile" placeholder="Mobile" value={user.mobile} onChange={handleChange} />
                <label For="email" className='email'><MdEmail/></label>
                <input type="email" name="email" placeholder="Email" value={user.email} onChange={handleChange} />
            </div>
            <div  className='underline'>
                <div className='horizontalLine'><hr></hr></div>
                <div className='horizontalLine'><hr></hr></div>
            </div>
            <div className='password'>
                <label For="password"><BiLockAlt/></label>
                <input type="password" name="password" placeholder="Password" value={user.password} onChange={handleChange} />
                <label For="confirmPassword" className='confirmPassword'><BiLockAlt/></label>
                <input type="password" name="confirmPassword" placeholder="Confirm Password" value={user.confirmPassword} onChange={handleChange} />
            </div>
            <div  className='underline'>
                <div className='horizontalLine'><hr></hr></div>
                <div className='horizontalLine'><hr></hr></div>
            </div>

            <div className='checkBox'>
                <input type="checkbox" className="larger" name="checkBox" value={user.checkBox} onChange={handleChange}/>
                <div className='rememberpassword'>Terms and conditions</div>
            </div>
            <button className='createAccountButton' onClick={handleCreateAccount}>Create Account</button>
            <div className='accountCheck'><em>Already have an account</em></div>
            <div className='signin' onClick={handleLogin}>Login</div>
       </Screen>
        
    )
}
export default Signup;