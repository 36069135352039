import { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import classes from "../invoice.module.css";
import { useNavigate } from "react-router-dom";
import { CheckPermission } from "utils/Constants/RBAC";
import { BsBell } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { RiWechatLine } from "react-icons/ri";
import { AiFillEye } from "react-icons/ai";
import { fetchMemberdata } from "utils/memberchat";
import FinanceDetails from "./FinanceDetail";
import { OverlayTrigger } from "react-bootstrap";
import { Popover } from "react-bootstrap";
import { FinanceDealslistHeader } from "utils/Section/InvoiceSection";
import { truncateStringID } from "utils/helper";
import { FindCompanyName } from "utils/helper";
import {LABEL,STATUS} from "utils/Constants";

const FinanceTable = ({ response, companyList, hanndleCraateGroup, role }) => {
  const navigate = useNavigate();
  const [memberList, setMemberList] = useState();
  const [isFinanceDetails, setIsFinanceDetails] = useState(false);
  const [viewreqRole, setViewReqRole] = useState(null);
  const handleDetails = (data) => {
    navigate(`/financing/invoice/finance-deals/${data.financeDealId}`);
    setIsFinanceDetails(true);
  };

  const chatHandler = async (data) => {
    if (data?.financerMemberUID !== "" && data?.traderMemberUID !== "") {
      let res = await fetchMemberdata([data.financerMemberUID, data.traderMemberUID]);
      console.log("AkashTestingChat", res);
      let name = data.financeDealId
        ? truncateStringID(data.financeDealId, 7, "FD")
        : "NA";
      setMemberList(res);
      hanndleCraateGroup(res, name, name);
    }
  };

  useEffect(() => {
    if (role) {
      setViewReqRole(
        CheckPermission("Requisition", "FinanceDeals", "ViewReqisition", role)
      );
    }
  }, [role]);

  useEffect(() => {
    if (memberList);
  }, [memberList]);

  const Status = (status) => {
    switch (status) {
      case "NEW":
        return STATUS(status, "primary");
      case "INTEREST_EXPRESSED":
        return STATUS(status, "warning");
      case "DUE_DILIGENCE_INITIATED":
        return STATUS(status, "warning");
      case "DUE_DILIGENCE_COMPLETED":
        return STATUS(status, "success");
      case "QUOTE_GENERATED":
        return STATUS(status, "primary");
      case "QUOTE_DECLINED":
        return STATUS(status, "danger");
      case "QUOTE_ACCEPTED":
        return STATUS(status, "warning");
      case "PENDING_T_AND_C_ACCEPTANCE":
        return STATUS(status, "warning");
      case "FINANCE_AGREEMENT_COMPLETE":
        return STATUS(status, "success");
      case "FINANCED":
        return STATUS(status, "success");
      case "DECLINED":
        return STATUS(status, "danger");
      case "ABORTED":
        return STATUS(status, "danger");
      case "CLOSED":
        return STATUS(status, "danger");
      default:
        return;
    }
  };

  return (
    <>
      {isFinanceDetails ? (
        <FinanceDetails companyList={companyList} />
      ) : (
        <Table bordered responsive className={cn(classes.Table)}>
          <thead>
            <tr>
              {FinanceDealslistHeader &&
                FinanceDealslistHeader.map((header, i) => (
                  <td style={{fontSize:"80%" }} key={i}>{header.name}</td>
                ))}
            </tr>
          </thead>
          <tbody>
            {response.map((data, index) => (
              <tr key={index}>
                <td
                  onClick={() => handleDetails(data)}
                  className={cn(classes.columnHover)}
                  style={{
                    cursor: "pointer",
                    color: "rgb(33,142,235",
                    textDecorationLine: "underline",
                    fontSize:"80%",
                  }}>
                  <span className="px-2">
                    <BsBell size={22} />
                  </span>
                  {data.financeDealId
                    ? truncateStringID(data.financeDealId, 7, "FD")
                    : "NA"}
                </td>
                <td style={{fontSize:"80%" }}>
                  {data.refFinanceRequisitionId
                    ? truncateStringID(data.refFinanceRequisitionId, 7, "RQ")
                    : "NA"}
                </td>
                <td style={{fontSize:"80%" }}>{data.financerName.length>30 ? data.financerName.substring(0,20).concat("...") : data.financerName}</td>
                <td style={{fontSize:"80%" }}>
                  {companyList &&
                    FindCompanyName(companyList, data.traderMemberUID)}
                </td>
                <td style={{fontSize:"80%" }}>{LABEL(data.financeProductCategory)}</td>
                <td style={{ maxWidth: "170px", fontSize:"70%", overflow:"hidden" }}>
                  {Status(data.financeDealStatus)}
                </td>
                <td style={{fontSize:"80%" }}>{new Date(data.lastUpdatedDate).toLocaleDateString()}</td>
                <td className={cn(classes.columnHover)} style={{fontSize:"80%" }}>
                  <span style={{ cursor: "pointer" }}>
                    <FiMail size={22} />
                  </span>
                  <OverlayTrigger
                                trigger={["hover", "focus"]}
                                key="top"
                                placement="top"
                                overlay={
                                <Popover id="popover-positioned-top">
                                <Popover.Body>
                                <strong>Chat</strong>
                                </Popover.Body>
                                </Popover>
                               }>
                             <span
                                className="px-3"
                                onClick={() => chatHandler(data)}
                                style={{ cursor: "pointer" }}>
                                <RiWechatLine size={22} />
                             </span>
                      </OverlayTrigger>
                  {/* <span
                    className="px-3"
                    onClick={() => chatHandler(data)}
                    style={{ cursor: "pointer" }}>
                    <RiWechatLine size={22} />
                  </span> */}
                   <OverlayTrigger
                                trigger={["hover", "focus"]}
                                key="top"
                                placement="top"
                                overlay={
                                <Popover id="popover-positioned-top">
                                <Popover.Body>
                                <strong>View</strong>
                                </Popover.Body>
                                </Popover>
                               }>
                             <span
                               onClick={() =>
                                navigate(
                                   `/financing/invoice/invoices/${data.referenceFinanceInstrumentId}`
                                    )
                                    }
                               style={{ cursor: "pointer" }}>
                               <AiFillEye size={22} />
                             </span>
                      </OverlayTrigger>
                  {/* <span
                    onClick={() =>
                      navigate(
                        `/financing/invoice/invoices/${data.referenceFinanceInstrumentId}`
                      )
                    }
                    style={{ cursor: "pointer" }}>
                    <AiFillEye size={22} />
                  </span> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
export default FinanceTable;
