import React from "react";
import Table from "react-bootstrap/Table";
import classes from "./company.module.css";
import cn from "classnames";
import Content from "components/UI/Layout/Content";
import Loader from "components/Loader/Loader";

import { FindCompanyName } from "utils/helper";
import { CompanyList } from "utils/API/CompanyList.js";

const VersionTable = ({ Data }) => {
  const { response, isLoading, isError } = CompanyList();
  return (
    <>
      {Data == null || isLoading ? (
        <Loader />
      ) : (
        <Content>
          {Data && response && (
            <Table bordered responsive className={cn(classes.Table)}>
              <thead>
                <tr>
                  <td>Version</td>
                  <td>Modified By</td>
                  <td>Modified Date</td>
                  <td>Validated</td>
                  <td>Document Link</td>
                </tr>
              </thead>
              <tbody>
                {Data &&
                  Data.map((data, index) => (
                    <tr key={index}>
                      <td>{data?.version}</td>
                      <td>
                        {data.updateBy !== null
                          ? FindCompanyName(response, data?.updateBy)
                          : "-"}
                      </td>
                      <td>
                        {data.updatedDate !== null
                          ? new Date(data.updatedDate).toLocaleDateString()
                          : "-"}
                      </td>
                      <td>{data.validated ? "True" : "False"}</td>
                      <td>
                        <a
                          href={data.downloadUri ? data.downloadUri : "-"}
                          target="_blank"
                          rel="noopener noreferrer">
                          Click
                        </a>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
          {isError && <span>{isError}</span>}
        </Content>
      )}
    </>
  );
};

export default VersionTable;
