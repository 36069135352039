import React, { useCallback, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { BsInfoCircle } from "react-icons/bs";
import { FaCheck, FaQuestion } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import styles from "../../../../../Document/Document.module.css";
import { networkShareApi, linkMemberURL } from "../../../../../../services/BaseURLInstance";
import useAxios from "../../../../../../services/useAxios";
import axios from "axios";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { storeContext } from '../../../../../../contexts/Store/Store'


const Styles = styled.div`
  
  
  .column2 {
    width: 25%;
    margin-top: 30px;
    text-align: center;
    margin-left: 3%;
    div {
      display: flex;
      font-family: Roboto;
      font-style: Regular;
      font-size: 14px;
      line-height: 15px;
      line-height: 91%;
      align: left;
      vertical-align: Top;
      paragraph-spacing: 32px;
      margin: 14px;
      span {
        margin-top: 16px;
      }
    }
    .icon {
      position: absolute;
      margin-top: 18px;
      margin-left: 18px;
      size: 6px;
      color: black;
    }
  }
  .iconDiv {
    margin-top: -15px;
    margin-left: -18px;
    display: flex;
    .icon {
      position: absolute;
      margin-top: -32px;
      margin-left: 4px;
      size: 6px;
      color: black;
    }
  }



  label {
    font-family: Roboto;
    font-style: Regular;
    font-size: 16px;
    line-height: 16px;
    line-height: 85%;
    vertical-align: top;
    width: 300px;
  }
  .searchitems {
    background-color: #ffffff0d;
  }

  .searchitem {
    display: flex;
    width: 100%;
    height: 52px;
    align-items: center;
    color: white;
    border-bottom: 1px solid rgb(196, 215, 245);
    .container {
      margin-left: 75%;
      float: right;
    }
  }
  .checkbox {
    background: transparent;
    color: #42a5f5;
    border: 1px solid #5d5d5d;
    width: 35px;
    height: 35px;
  }
  .lastButton {
    margin-left: 26%;
    margin-top: 20px;
    button {
      width: 263px;
      height: 53px;
      margin-left: 2%;
      border-radius: 5px;
      /* border: none; */
      font-family: Roboto;
      font-style: Bold;
      font-size: 18px;
      line-height: 24px;
      line-height: 114%;
      align: center;
      vertical-align: top;
      paragraph-spacing: 32px;
    }
  }
  .innerInputDiv {
    overflow-x: scroll;
    background: white;
    border: none;
    width: 750px;
    ::-webkit-scrollbar{
      display: none;
    }
  }


`;
export default ({ handleRecipientSubmit }) => {
  const [search, setSearch] = useState();
  const [newList, setNewList] = useState();
  const [checkItem, setCheckItem] = useState({
    item: "",
    count: 0
  });
  const [status, setStatus] = useState("signatureDeclined");
  const { instance, accounts } = useMsal();
  // const [idToken, setIdToken] = useState(null);
  // getIdToken(instance, accounts, setIdToken);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const { fetchData } = useContext(storeContext)
  const [response, setResponse] = useState('')
  const [Error, setError] = useState('')

  const [memberList, setMemberList] = useState("");

  useEffect(() => {
    const getData = async () => {
      let URL = `${linkMemberURL}network/`;
      const Data = await fetchData(URL)
      {
        Data && Data.data && (
          setResponse(Data.data)
        )
      }
      {
        Data && Data.error && (
          setError(Data.error)
        )
      }
    }
    getData();
  }, [])

  useEffect(() => {
    if (checkItem.count === 0) {
      if (memberList === "" && response !== null) {
        let memberListData = [];
        let temp = [];
        if (response) {
          for (let i = 0; i < response.length; i++) {
            temp = response[i].companyName;
            if (temp) {
              memberListData.push(temp);
            }
          }
          if (memberListData.length !== 0) {
            setMemberList(memberListData);
          }
        }
      }
    }
  })

  const handleChange = (e) => {
    if (e.target.value !== "") {
      let temp = [];
      temp = memberList.filter((item) => {
        if (item.toLowerCase().includes(e.target.value.toLowerCase())) {
          return item;
        }
        else {
          return "";
        }
      });
      setNewList(temp);
    }
    else {
      setNewList("");
    }
  };


  const handleChecked = (e, item) => {
    if (checkItem.count === 0) {
      setCheckItem({
        ...checkItem,
        item: item,
        count: 1
      });
    }
  }


  const handleDelete = (e, value) => {
    setCheckItem({
      ...checkItem,
      item: "",
      count: 0
    });
  };

  const handleConfirmMember = () => {
    let general = JSON.parse(localStorage.getItem("uploadData"));
    let memberId = response.filter(item => {
      return item.companyName === checkItem.item

    })
    general.txlBuyerMemberId = memberId[0].memberUID;
    localStorage.setItem("uploadData", JSON.stringify(general));
    handleRecipientSubmit();

  }

  return (
    <div className={styles.Card} style={{ marginTop: "16px" }} >
      <div style={{ padding: "16px", background: "#1E1E1E", borderRadius: "10px" }}>
        <Styles>
          <div className={styles.heading1}>Link Buyer</div>
          <div className={styles.recipientBox}>
            <div className={styles.Recipient}>Recipient</div>
            <div className={styles.row}>
              <div className={styles.column1}>
                <div className={styles.field}>
                  <div className={styles.overInputDiv}>
                    <div className={styles.inputdiv}>
                      <div className="innerInputDiv">
                        {checkItem.item !== "" && (
                          <div className={styles.resultfield}>
                            <div className={styles.result}>
                              <input type="text" value={checkItem.item} />
                              <button className={styles.crossbtn} onClick={(e) => handleDelete(e)} >
                                <MdCancel size={12} />
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="searchcontainer" >
                      <div className={styles.searchInput}>
                        <span>
                          <FiSearch size={20} />
                        </span>
                        <input type="text" placeholder="Search" onChange={handleChange} />
                      </div>
                      <div className="searchitems">
                        {newList && (
                          <div>
                            {newList.map((item, key) => {
                              if (key !== newList.length - 1) {
                                return (
                                  <div className="searchitem">
                                    <div className="searchItemText" style={{ marginLeft: "20px", backgroundColor: "red", width: "80px" }}>{item}</div>
                                    <div className="container">
                                      <button className="checkbox" onClick={(e) => handleChecked(e, item)}>
                                        {checkItem.item.includes(item) && <FaCheck />}
                                      </button>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    style={{ borderBottom: "none" }}
                                    className="searchitem"
                                  >
                                    <div style={{ marginLeft: "20px", width: "80px" }}>
                                      {item}
                                    </div>
                                    <div className="container">
                                      <button
                                        className="checkbox"
                                        onClick={(e) => handleChecked(e, item)}
                                      >
                                        {checkItem.item.includes(item) && <FaCheck />}
                                      </button>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        )}
                      </div>
                    </div>

                  </div>
                </div>
              </div>

            </div>

          </div>

          <div className="lastButton">

            <button style={{
              background: "#42A5F5", color: "white", border: "solid 1px white", marginLeft: "450px",
              cursor: "pointer",
              position: "relative"
            }} onClick={handleConfirmMember}>
              Confirm Member
            </button>
          </div>


        </Styles>
      </div>
    </div>
  );
};


