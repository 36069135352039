import React, { useState } from 'react'
import { PaymentColumn } from "./column";
import styles from "./trade.module.css";
import Table from "components/table";
import { MdAdd } from "react-icons/md";
import {
    Card,
    Grid,
    Button
} from '@mui/material';

function AlertsPayment() {
    const [active, setActive] = useState('');

    const tabs = [
        { name: "New Invoice", value: "new" },
        { name: "Financed", value: "financed" },
        { name: "Closed", value: "closed" },
        { name: "In-Progress", value: "progress" },
    ];

    const api_response = [
        {
            "open_trade_id": "HDFC",
            "milestone": "Shipped",
            "due_date": "01-01-2022",
            "amount": "1000",
            "status": ["Payment Declined"],
            "action": true
        },
        {
            "open_trade_id": "ICICI",
            "milestone": "Received",
            "due_date": "02-02-2022",
            "amount": "2000",
            "status": ["Open Trade Completed"],
            "action": true
        },
    ]

    return (
        <>
            <div style={{ borderRadius: "10px", background: "#343434", padding: "20px", marginTop: "20px" }}>
                <Card style={{
                    backgroundColor: "#000000",
                    padding: "20px",
                    borderRadius: "5px",
                }}>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <h4 style={{ color: "white", margin: "0px" }}>Search Schedule</h4>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => console.log("first")}
                        >
                            <MdAdd size={25} />
                            Start New Open Trade
                        </Button>
                    </div>
                    <Grid container style={{ padding: "15px 0px 0px 0px" }}>
                        <Grid container xs={12} md={10}>
                            {tabs.map((item, index) => (
                                <Grid item xs={6} md={3} style={{ padding: "10px" }}>
                                    <div className={styles.searchDiv}>
                                        <div key={index} onClick={() => setActive(index)}
                                            className={index == active ? styles.activeDiv : styles.nonActiveDiv}
                                        >
                                            {item.name}
                                        </div>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={2} style={{ padding: "10px" }}>
                            <input type="text" placeholder="Search" className={styles.inputSearch} />
                        </Grid>
                    </Grid>
                </Card>
                <br></br>
                <Card style={{
                    backgroundColor: "#000000",
                    padding: "20px",
                    borderRadius: "5px",
                }}>
                    <h4 style={{ color: "white", margin: "0px" }}>Search Results</h4>

                    <div className={styles.wrapper}>
                        <div className={styles.tableContainer}>
                            {Array.isArray(api_response) ? (
                                <Table columns={PaymentColumn} data={api_response} />
                            ) : (
                                <p>No Records</p>
                            )}
                        </div>
                    </div>
                </Card>

            </div>
        </>
    )
}

export default AlertsPayment