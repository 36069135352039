import React, { useCallback, useState, useContext } from "react";
import { Routes, Route, Link, Outlet, NavLink } from "react-router-dom";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  Container,
  ActionBarStyled,
} from "../../../../components/Container.styled";
import Overview from "../../finance/pofinance/overview";
import styles from "../../../Invoicefinancing/invoice.module.css";
import UploadDocs from "../../finance/pofinance/uploadInvoice/Upload";
import Invoice from "../pofinance/Invoice";
import { SuccessInvoice } from "../pofinance/uploadInvoice/SuccessInvoice";
import { SavedInvoice } from "../pofinance/uploadInvoice/SavedInvoice/savedInvoice";
import Financing from "../pofinance/financing";
import Summary from "../pofinance/financing/summary";
import Loans from "../pofinance/loans";
import LoanDetails from "../pofinance/loandetails";

export default ({ hanndleCraateGroup }) => {
  const navigate = useNavigate();

  const tabs = [
    { name: "Overview", value: "overview" },
    { name: "Purchase Order", value: "purchaseorder" },
    { name: "Finanacing Deals", value: "financing" },
    { name: "Loans", value: "loans" },
    { name: "Alert Notification", value: "notification" },
  ];

  const handleChatOpen = () => {
    navigate("/chat");
  };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: "98%" }}>
        {/* {isLoading && <Loader />} */}
        <Container className={styles.container}>
          <ActionBarStyled className={styles.actionBarStyled}>
            {tabs.map((item, index) => (
              <NavLink
                to={item.value}
                key={index}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <li>{item.name}</li>
              </NavLink>
            ))}
          </ActionBarStyled>
          <div className={styles.containerWrapper}>
            <Routes>
              <Route path="overview" element={<Overview />} />
              <Route path="upload" element={<UploadDocs />} />
              <Route
                path="purchaseorder"
                element={<Invoice hanndleCraateGroup={hanndleCraateGroup} />}
              />
              <Route path="invoices/:id" element={<SuccessInvoice />} />
              <Route path="invoicesDetails/:id" element={<SavedInvoice />} />
              <Route
                path="financing"
                element={<Financing hanndleCraateGroup={hanndleCraateGroup} />}
              />
              <Route path="summary" element={<Summary />} />
              <Route path="loans" element={<Loans />} />
              <Route path="loandetails" element={<LoanDetails />} />
              {/*  <Route path="financing" element={<Financing />} />
              <Route path="success" element={<SuccessInvoice />} />
              <Route path="loans" element={<Loans />} />
              <Route path="loandetails" element={<LoanDetails />} />*/}
              <Route path="" element={<Overview />} />
            </Routes>
          </div>
        </Container>
      </div>
      <div style={{ marginTop: "400px" }} onClick={handleChatOpen}>
        <IoIosArrowDropleftCircle size={35} />
      </div>
    </div>
  );
};
