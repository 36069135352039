
import React, { useState, useEffect } from "react";
// import {invoiceFinanceDetailsContext} from '../../../../contexts/invoiceFinance/invoiceFinanceDetailsContext'
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import moment from "moment";
import Select from "components/fields/Select";
import country_of_exports from "jsons/CountryCodes.json"
import country_codes from "jsons/Currenciesr.json"
import port_codes from "jsons/PortCodes.json"

const ExportDetails = () => {
    //const {exportData,handleExportChange} = useContext(invoiceFinanceDetailsContext)
    const [expoData, setExpoData] = useState({
        ShipBNo: "",
        ShipDate: "",
        Port: "",
        RefClm: "",
        ForCur: "",
        CntCode: "",
        ExpDuty: 0,
    });
    const [DatePicker1, setDatePicker1] = useState(new Date());

    useEffect(() => {
        let general = JSON.parse(localStorage.getItem("uploadData"));
        if (general.invoiceDetails.ExpDtls === null) {
            general.invoiceDetails.ExpDtls = {
                "ShipBNo": "",
                "ShipBDt": "",
                "Port": "",
                "RefClm": "",
                "ForCur": "",
                "CntCode": "",
                "ExpDuty": 0
            }
            localStorage.setItem("uploadData", JSON.stringify(general))
        }
        general = JSON.parse(localStorage.getItem("uploadData"));
        setExpoData({
            ...expoData,
            ShipBNo: general.invoiceDetails.ExpDtls.ShipBNo,
            ShipDate: general.invoiceDetails.ExpDtls.ShipDate,
            Port: general.invoiceDetails.ExpDtls.Port,
            RefClm: general.invoiceDetails.ExpDtls.RefClm,
            ForCur: general.invoiceDetails.ExpDtls.ForCur,
            CntCode: general.invoiceDetails.ExpDtls.CntCode,
            ExpDuty: general.invoiceDetails.ExpDtls.ExpDuty,

        });
    }, [])
    const handleExportChange = (event, name) => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        if (name === "ShipDate") {
            general.invoiceDetails.ExpDtls.ShipDate = event;
            localStorage.setItem("uploadData", JSON.stringify(general));
            let newDate1 = moment(new Date(event)).format('YYYY-MM-DD')
            setDatePicker1(newDate1)
            setExpoData({
                ...expoData,
                [name]: event
            })
            return
        }

        if (event.target.name === "ShipBNo") {
            general.invoiceDetails.ExpDtls.ShipBNo = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setExpoData({
                ...expoData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "Port") {
            general.invoiceDetails.ExpDtls.Port = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setExpoData({
                ...expoData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "RefClm") {
            general.invoiceDetails.ExpDtls.RefClm = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setExpoData({
                ...expoData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "ForCur") {
            general.invoiceDetails.ExpDtls.ForCur = event.target.value;
            general.txlCurrencyCode = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setExpoData({
                ...expoData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "CntCode") {
            general.invoiceDetails.ExpDtls.CntCode = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setExpoData({
                ...expoData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "ExpDuty") {
            general.invoiceDetails.ExpDtls.ExpDuty = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setExpoData({
                ...expoData,
                [event.target.name]: event.target.value
            })
        }
    }

    let bb = Object.keys(port_codes)
    let cc = Object.values(port_codes)
    let port_code = []

    bb.map((q, i) => (
        cc.map((w, o) => {
            if (i === o) {
                port_code.push({ "label": w.name, "value": q })
            }
        })
    ))
    return (
        <Grid container>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="ShipBNo"
                    placeholder="Shipping Bill"
                    value={expoData.ShipBNo}
                    handleChange={handleExportChange}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                <DateInput
                    placeholder="Shipping Bill Date"
                    value={DatePicker1}
                    handleChange={(value) => handleExportChange(value, "ShipDate")}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Select
                    options={port_code}
                    name="Port"
                    placeholder="Port Code"
                    handleChange={handleExportChange}
                    value={expoData.Port}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="RefClm"
                    placeholder="Supplier Refund Status"
                    value={expoData.RefClm}
                    handleChange={handleExportChange}
                />
            </Grid>

            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Select
                    options={country_codes}
                    name="ForCur"
                    placeholder="Additional Currency Code"
                    handleChange={handleExportChange}
                    value={expoData.ForCur}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Select
                    options={country_of_exports}
                    name="CntCode"
                    placeholder="Country of Export"
                    handleChange={handleExportChange}
                    value={expoData.CntCode}
                />
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                <Input
                    name="ExpDuty"
                    placeholder="Export Duty"
                    value={expoData.ExpDuty}
                    handleChange={handleExportChange}
                />
            </Grid>
        </Grid>
    )
}

export default ExportDetails
