import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import { Countries } from "utils/DropDown/Country";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { CUSTOMS_INS } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";

const CustomsInspection = ({
  customInspectionData,
  setCustomInspectionData,
}) => {
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];
  let qtyUnitOptions = [
    {
      label: "MT",
      value: "MT",
    },
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "LB",
      value: "LB",
    },
    {
      label: "BOXES",
      value: "BOXES",
    },
    {
      label: "PCS",
      value: "PCS",
    },
  ];

  let inspectionType = [
    {
      label: "Packaging ",
      value: "Packaging",
    },
    {
      label: "Labeling",
      value: "Labeling",
    },

    {
      label: "Documentation and Document Verification",
      value: "Documentation and Document Verification",
    },
    {
      label: "Compliance with all relevant regulations and standards",
      value: "Compliance with all relevant regulations and standards",
    },
    {
      label: "Physical inspection",
      value: "Physical inspection",
    },
    {
      label: "Sample testing",
      value: "Sample testing",
    },
  ];

  const [selectList, setSelectList] = useState([]);
  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setCustomInspectionData({
      ...customInspectionData,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );
  //const [selectList, setSelectList] = useState([]);
  //   const handleChange = (e, v) => {
  //     if (v === "owners") {
  //         apiData[v] = e;
  //         setApiData({ apiData });
  //         return;
  //     }
  //     const { name, value } = e.target;
  //     apiData[name] = value;
  //     setApiData({ apiData });
  // };
  const handleMultiChange = (e, name) => {
    if (name === "commodity") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
        //  selectList[name]: value,
      });
    }
    if (name === "inspectionType") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
        //  selectList[name]: value,
      });
    }
  };

  // else if(name === "inspectionType"){

  //    setCustomInspectionData({
  //     ...customInspectionData,
  //     [e.target.name]: e.target.value,
  //   });

  // } }

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "loadingDate") {
        setDatePicker1(newDate1);
        // setCustomeClearanceData({
        //   ...customeClearanceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "unloadingDate") {
        setDatePicker2(newDate1);
        // setCustomeClearanceData({
        //   ...customeClearanceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setCustomInspectionData({
        ...customInspectionData,
        [date]: dateString,
      });
    } else if (e.target.name === "serviceType") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryFrom") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "portOrigin") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationPort") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityFrom") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityTo") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    }

    // else if (e.target.name === "inspectiontype") {
    //     setCustomInspectionData({
    //       ...customInspectionData,
    //       [e.target.name]: e.target.value,
    //     });
    //   }
    // else if (e.target.name === "commodity") {
    //   setCustomInspectionData({
    //     ...customInspectionData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    else if (e.target.name === "ProductName") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "productDescription") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    }
    // else if (e.target.name === "inspectiontype") {
    //   setCustomInspectionData({
    //     ...customInspectionData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    else if (e.target.name === "ShipperName") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverAddress") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ReceiverName") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ShipperAddress") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contactInfo") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "hsnOfCode") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyType") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyAmount") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "quantity") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "weightType") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requirement") {
      setCustomInspectionData({
        ...customInspectionData,
        [e.target.name]: e.target.value,
      });
    }
  };
  sessionStorage.setItem(
    "customInspectionData",
    JSON.stringify(customInspectionData)
  );
  console.log("customInspectionData", customInspectionData);
  return (
    <>
      <Content padding="0.8% 2% 1.5% 2%">
        <div style={{ margin: "10px" }}>
          <OverLayTrigger value={CUSTOMS_INS} />
        </div>
        <div style={{ margin: "5px 10px 0px 10px" }}>
          Customs Clearance Inspection Services Required For ?
        </div>

        <div className={classes.source} style={{ margin: "0px 10px" }}>
          <div
            style={{ marginLeft: "10px", width: "50%", marginRight: "10px" }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="IMPORT"
              onChange={handleRadio}
              checked={customInspectionData.serviceType == "IMPORT"}
            />
            <label>IMPORT</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="EXPORT"
              onChange={handleRadio}
              checked={customInspectionData.serviceType == "EXPORT"}
            />
            <label>EXPORT</label>
          </div>
        </div>
        <div style={{ margin: "5px 10px 0px 10px" }}>Shipment details</div>
        {/* <div className={classes.source} style={{ marginBottom: "4%" }}>
          <div className={classes.source} style={{ width: "40%" }}>
          <div style={{ width: "30%" }}>
                <Select
                  name="originType"
                  options={sourceOptions}
                  placeholder="From"
                  handleChange={handleChange}
                  value={customInspectionData.originType}
                />
              </div>
              {customInspectionData.originType && customInspectionData.originType === "PORT" ?(
              <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="origin"
                  placeholder="Port"
                  value={customInspectionData.origin}
                  handleChange={handleChange}
                />
              </div>
              ):(<div style={{ width: "70%" }}>
              <Input
                name="origin"
                placeholder="Country"
                value={customInspectionData.origin}
                handleChange={handleChange}
              />
            </div>)}
            </div>
            <div style={{ width: "20%" }}></div>
    
            <div className={classes.source} style={{ width: "40%" }}>
              <div style={{ width: "30%" }}>
                <Select
                  options={destinationOptions}
                  name="destinationType"
                  placeholder="To"
                  handleChange={handleChange}
                  value={customInspectionData.originType}
                />
              </div>

              {customInspectionData.originType && customInspectionData.originType === "PORT" ?(
                <div style={{ width: "70%" }}>
                <Select
                  options={port_code}
                  name="destination"
                  placeholder="Port"
                  value={customInspectionData.destination}
                  handleChange={handleChange}
                />
              </div>
              ):(<div style={{ width: "70%" }}>
                  <Input
                name="destination"
                placeholder="Country"
                value={customInspectionData.destination}
                handleChange={handleChange}
              />
               </div>)}
               </div>
          </div>
          {/* cityFrom
          cityTo */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country  From*"
              handleChange={handleChange}
              value={customInspectionData.countryFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country To*"
              handleChange={handleChange}
              value={customInspectionData.countryTo}
              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityFrom"
              placeholder="City From"
              handleChange={handleChange}
              value={customInspectionData.cityFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityTo"
              placeholder="City To"
              handleChange={handleChange}
              value={customInspectionData.cityTo}
              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="portOrigin"
              placeholder="From Port*"
              handleChange={handleChange}
              value={customInspectionData.portOrigin}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="destinationPort"
              placeholder="To Port*"
              value={customInspectionData.destinationPort}
              handleChange={handleChange}
            />
          </div>
        </div>

        <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
          <MultiSelect
            options={inspectionType}
            name="inspectionType"
            placeholder="Inspection Type*"
            handleChange={(e) => handleMultiChange(e, "inspectionType")}
            selected={customInspectionData.inspectionType}
            // readOnly={true}
          />
        </div>
        <hr style={{border:"2px solid white", margin:"20px 10px 10px 10px"}}></hr>
        <div>
          <div>
            <div>
              <label style={{ margin: "0px 10px" }}>COMMODITY</label>
              <div
                style={{ width: "50%", margin: "5px 10px 10px 10px", paddingRight: "20px" }}
              >
                <MultiSelect
                  options={Industry}
                  name="commodity"
                  placeholder="Product Category*"
                  handleChange={(e) => handleMultiChange(e, "commodity")}
                  selected={customInspectionData.commodity}
                  // readOnly={true}
                  //
                  //     name="memberlist"
                  //     selected={selectStatus}
                  //     placeholder="Status"
                  //     options={InvoiceStatus}
                  //
                  //   />
                />
              </div>
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="ProductName"
                placeholder="Product Name*"
                handleChange={handleChange}
                value={customInspectionData.ProductName}
                // readOnly={true}
              />
            </div>
            {/* : [Insert Product Name]
: [Insert Product Description]
: []
Shipper Address : [ Insert Address ] */}

            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="productDescription"
                placeholder="Product Description*"
                handleChange={handleChange}
                value={customInspectionData.productDescription}
                // readOnly={true}
              />
            </div>
          </div>
          <hr style={{border:"2px solid white", margin:"10px 10px 10px 10px"}}></hr>
          <label style={{ margin: "0px 10px" }}>Shipment Details</label>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
              <Input
                name="ShipperName"
                placeholder="Shipper Name*"
                handleChange={handleChange}
                value={customInspectionData.ShipperName}
                // readOnly={true}
              />
            </div>
            <div style={{ width: "50%", margin: "5px 10px 10px 10px" }}>
              <Input
                name="ReceiverName"
                placeholder="Receiver Name*"
                handleChange={handleChange}
                value={customInspectionData.ReceiverName}

                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="ShipperAddress"
                placeholder="Shipper Address*"
                handleChange={handleChange}
                value={customInspectionData.ShipperAddress}
                // readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="ReceiverAddress"
                placeholder="Receiver Address* "
                handleChange={handleChange}
                value={customInspectionData.ReceiverAddress}
                // readOnly={true}
              />
            </div>
          </div>
          <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
            <Input
              name="hsnOfCode"
              placeholder="HSN code of Goods*"
              handleChange={handleChange}
              value={customInspectionData.hsnOfCode}
              // readOnly={true}
            />
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={Currenciesr}
                name="currencyType"
                placeholder="Currency*"
                value={customInspectionData.currencyType}
                handleChange={handleChange}
              />
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="currencyAmount"
                placeholder={`Trade Amount(${customInspectionData.currencyType ? customInspectionData.currencyType :""})*`}
                handleChange={handleChange}
                value={customInspectionData.currencyAmount}
                // readOnly={true}
              />
            </div>
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <Select
                options={qtyUnitOptions}
                name="weightType"
                placeholder="Quantity*"
                value={customInspectionData.weightType}
                handleChange={handleChange}
              />
            </div>
            <div style={{ width: "50%", margin: "10px" }}>
              <Input
                name="quantity"
                placeholder="Quantity*"
                handleChange={handleChange}
                value={customInspectionData.quantity}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}></div>
        </div>

        <div style={{ margin: "10px" }}>
          <label>
            Contact information for the person responsible for the shipment
          </label>
        </div>
        <div style={{ width: "100%", margin: "10px", paddingRight: "20px" }}>
          <Input
            name="contactInfo"
            placeholder="  "
            handleChange={handleChange}
            value={customInspectionData.contactInfo}
            // readOnly={true}
          />
        </div>

        <div style={{ margin: "10px" }}>
          <label>
            Any special instructions or requirements for the Customs Clearance
            Inspection
          </label>
        </div>
        <div style={{ width: "100%", margin: "10px", paddingRight: "20px" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="requirement"
            placeholder=" Requirement "
            handleChange={handleChange}
            value={customInspectionData.requirement}
            // readOnly={true}
          />
          {/* <textarea
                  style={{ height: "7rem", width: "100%" , background :"Black"}}
                  type="textarea"
                  name="requirement"
                  value={customInspectionData.requirement}
                  onChange={(e) => handleChange(e, "requirement")}
                /> */}
        </div>
      </Content>
    </>
  );
};

export default CustomsInspection;
