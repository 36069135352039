export const AccountType = [
  {
    label: "SAVINGS",
    value: "SAVINGS",
  },
  {
    label: "VIRTUAL",
    value: "VIRTUAL",
  },
];

export const status = [
  {
    label: "ACTIVE",
    value: "ACTIVE",
  },
];
