import React, { useState } from "react";
// import { invoiceFinanceDetailsContext } from '../../../../contexts/invoiceFinance/invoiceFinanceDetailsContext'
import { useEffect } from "react";

import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import Select from "components/fields/Select";
import moment from "moment";

const GeneralDetail = () => {
    // const {generalData, handleGeneralChange} = useContext(invoiceFinanceDetailsContext)

    const [generalData, setGeneralData] = useState({
        invoiceNumber: "",
        TaxSch: "",
        SupTyp: "",
        DocDtlsType: "",
        DocDtlsNo: "",
        DocDtlsDt: "",
        AssValue: "",
        TotlnvVal: "",
    });

    const [DatePicker1, setDatePicker1] = useState(new Date());

    useEffect(() => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        setGeneralData({
            ...generalData,
            invoiceNumber: general.invoiceNumber,
            TaxSch: general.TaxSch,
            SupTyp: general.SupTyp,
            DocDtlsType: general.DocDtlsType,
            DocDtlsNo: general.DocDtlsNo,
            DocDtlsDt: general.DocDtlsDt,
            AssValue: general.AssValue,
            TotlnvVal: general.TotlnvVal
        });
    }, [])

    const handleGeneralChange = (event, name) => {
        const general = JSON.parse(localStorage.getItem("uploadData"));
        if (name === "DocDtlsDt") {
            general.DocDtlsDt = event;
            localStorage.setItem("uploadData", JSON.stringify(general));

            let newDate1 = moment(new Date(event)).format('YYYY-MM-DD')
            setDatePicker1(newDate1)

            setGeneralData({
                ...generalData,
                [name]: event
            })
            return
        }
        if (event.target.name === "invoiceNumber") {
            general.invoiceNumber = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "TaxSch") {
            general.TaxSch = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "SupTyp") {
            general.SupTyp = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "DocDtlsType") {
            general.DocDtlsType = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "DocDtlsNo") {
            general.DocDtlsNo = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "AssValue") {
            general.AssValue = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
        else if (event.target.name === "TotlnvVal") {
            general.TotlnvVal = event.target.value;
            localStorage.setItem("uploadData", JSON.stringify(general));
            setGeneralData({
                ...generalData,
                [event.target.name]: event.target.value
            })
        }
    }

    const supply_types = [
        { "label": "B2B", "value": "B2B" },
        { "label": "SEZWP", "value": "SEZWP" },
        { "label": "SEZWOP", "value": "SEZWOP" },
        { "label": "EXPWP", "value": "EXPWP" },
        { "label": "EXPWOP", "value": "EXPWOP" },
        { "label": "DEXP", "value": "DEXP" }
    ]

    return (
        <>
            <Grid container>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="invoiceNumber"
                        placeholder="Invoice Reference Number"
                        value={generalData.invoiceNumber}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="TaxSch"
                        placeholder="Tax Scheme"
                        value={generalData.TaxSch}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Select
                        options={supply_types}
                        name="SupTyp"
                        placeholder="Supply Types"
                        handleChange={handleGeneralChange}
                        value={generalData.SupTyp}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="DocDtlsType"
                        placeholder="Document Types Code"
                        value={generalData.DocDtlsType}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="DocDtlsNo"
                        placeholder="Document Number"
                        value={generalData.DocDtlsNo}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px 20px 10px 10px" }}>
                    <DateInput
                        placeholder="Document Date"
                        value={DatePicker1}
                        handleChange={(value) => handleGeneralChange(value, "DocDtlsDt")}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="AssValue"
                        placeholder="Taxable Value Total"
                        value={generalData.AssValue}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                    <Input
                        name="TotlnvVal"
                        placeholder="Total Invoice Value"
                        value={generalData.TotlnvVal}
                        handleChange={handleGeneralChange}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default GeneralDetail;
