import React, { useState, useEffect, useContext } from "react";
import Loader from "../../../components/Loader/Loader";

import styled from "styled-components";
import MainTable from "../financing/mainTable";
import { storeContext } from "../../../contexts/Store/Store";

import { CompanyList } from "utils/API/CompanyList.js";

const Styles = styled.div`
  .tablebox {
    margin-right: 20px;
    padding: 16px;
    background: #343434;
    width: 97.5%;
    border-radius: 10px;
  }
`;

export default () => {
  const CompanyObject = CompanyList();
  let financerMemberUID = "";
  let traderMemberUID = "";
  const invoiceId = "";

  const { fetchCompanyList } = useContext(storeContext);
  const [response, setResponse] = useState(null);
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (memberType === "TRADER") {
        traderMemberUID = companyId;
      } else {
        financerMemberUID = companyId;
      }

      let URL = `${process.env.REACT_APP_FINANCE_URL}finance/deals?traderMemberUID=${traderMemberUID}&financerMemberUID=${financerMemberUID}&invoiceId=${invoiceId}`;
      fetchCompanyList(URL, idToken)
        .then((data) => {
          setResponse(data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      {response && !CompanyObject.isLoading ? (
        <Styles>
          {Array.isArray(response) && (
            <div>
              <MainTable
                data={response}
                financerMemberUID={financerMemberUID}
                CompanyListData={CompanyObject.response}
              />
            </div>
          )}
        </Styles>
      ) : (
        <Loader />
      )}
    </>
  );
};
