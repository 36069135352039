import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { PaymentTab } from "utils/Section/Payment";
import Container from "components/UI/Layout/Container";
import Wrapper from "components/UI/Layout/Wrapper";
import Navbar from "components/UI/Navbar/Navbar";

import PaymentTransactions from "./PaymentTransactions/index";
import PaymentAccounts from "./PaymentAccounts/index";
import PaymentProvider from "./PaymentProviders/index";
const index = () => {
  return (
    <>
      <Container>
        <Navbar tabs={PaymentTab} />
        <Wrapper>
          <Routes>
            <Route path="/" element={<Navigate to="payment-transactions" />} />
            <Route
              path="payment-transactions"
              element={<PaymentTransactions />}
            />
            <Route path="payment-accounts" element={<PaymentAccounts />} />
            <Route path="payment-providers" element={<PaymentProvider />} />
          </Routes>
        </Wrapper>
      </Container>
    </>
  );
};

export default index;
