export const OpenTradeAccountTab = [
  { name: "Overview", value: "overview" },
  { name: "Open Account Trade", value: "open-account" },
  { name: "Schedule & Payments", value: "schedule-payments" },
];

export const schedulePaymentTab = [
  { name: "Milestone", value: "Milestone" },
  { name: "Participants", value: "Participants" },
];

export const ToggleSelect = [
  { name: "All", value: "" },
  { name: "NEW", value: "NEW" },
  { name: "PENDING BUYER CONSENT", value: "PENDING_BUYER_CONSENT" },
  { name: "APPROVED BUYER CONSENT", value: "APPROVED_BUYER_CONSENT" },
  { name: "TRADE APPROVED", value: "TRADE_APPROVED" },
  { name: "TRADE IN-PROGRESS", value: "TRADE_IN_PROGRESS" },
  { name: "BUYER TRADE COMPLETED", value: "BUYER_TRADE_COMPLETED" },
  { name: "TRADE COMPLETED", value: "TRADE_COMPLETED" },
  { name: "Trade Aborted", value: "ABORTED" },
];

export const AccountlistHeader = [
  { name: "Open Trade Id" },
  { name: "Buyer" },
  { name: "Seller" },
  { name: "Country of Origin" },
  { name: "Country of Destination" },
  { name: "Created on" },
  { name: "Status" },
  { name: "Action" },
];

export const PaymentlistHeader = [
  { name: "Service Provider" },
  { name: "Account Name" },
  { name: "Account Number" },
  { name: "Status" },
  { name: "Info" },
  { name: "Action" },
];

export const SummaryList = [
  { name: "Buyer", value: "buyerMemberId" },
  { name: "Seller", value: "sellerMemberId" },
  { name: "Country of Origin", value: "countryOfOrigin" },
  { name: "Country of Destination", value: "countryOfDestination" },
  { name: "Trade Value", value: "tradeValue" },
  { name: "Payment Currency", value: "paymentCurrency" },
  {
    name: "Contract Date", value: 
      new Date("contractDate").toLocaleString("en-US", { timeZone: "Asia/Kolkata" })
  },
  { name: "Status", value: "openTradeStatus" },
  { name: "Buyer Consent Status", value: "escrowConsentStatus" }
]

// export const PaymentList = [
//   { name: "Payment Service Provider", value: "escrowServiceProviderUID" || "paymentProvider" },
//   { name: "Buyer Virtual Bank Id", value: "RefBuyerVirtualBankAccountId" },
//   { name: "Seller Virtual Bank Id", value: "RefSellerVirtualBankAccountId" }
// ]

export const ScheduleAndPayment = [
  { name: "Milestone Stage" },
  { name: "Milestone Sub-Stage" },
  { name: "Category" },
  { name: "Sub-Category" },
  { name: "Payment Due Date" },
  { name: "Payment" },
  { name: "Amount" },
  { name: "Status" },
  { name: "Action" },
];

export const MilestoneHeader = [
  { name: "Milestone Id" },
  { name: "Milestone Stage" },
  { name: "Milestone Sub-Stage" },
  { name: "Document Category" },
  { name: "Document Sub-Category" },
  { name: "Payment Due" },
  { name: "Amount" },
  { name: "Percentage" },
  { name: "Status" },
  { name: "Action" },
];

export const ParticipantHeader = [
  { name: "Participant Name" },
  { name: "Country" },
  { name: "Role" },
  { name: "Date Since" },
  { name: "Status" },
];

export const MilestoneStage = [
  { label: "ONBOARDING", value: "ONBOARDING" },
  { label: "TRADE_CONTRACT", value: "TRADE_CONTRACT" },
  { label: "EXPENSE_MANAGEMENT", value: "EXPENSE_MANAGEMENT" },
  { label: "FINANCING", value: "FINANCING" },
  { label: "MANUFACTURING", value: "MANUFACTURING" },
  { label: "TRANSPORT_EXPORT", value: "TRANSPORT_EXPORT" },
  { label: "SHIPPING", value: "SHIPPING" },
  { label: "PROCUREMENT", value: "PROCUREMENT" },
  { label: "TRANSPORT_IMPORT", value: "TRANSPORT_IMPORT" },
  { label: "SETTLEMENT", value: "SETTLEMENT" },
  { label: "MARKETING", value: "MARKETING" },
  { label: "OTHERS", value: "OTHERS" },
];

export const ParticipantRoles = [
  { label: "Buyer", value: "BUYER" },
  { label: "Seller", value: "SELLER" },
];

export const ConditionCheck = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const ParticipantStatus = [
  {
    label: "Not Requested",
    value: "NOT_REQUESTED",
  },
  {
    label: "Signed",
    value: "SIGNED",
  },
  {
    label: "In Queue",
    value: "IN_QUEUE",
  },
  {
    label: "Declined",
    value: "DECLINED",
  },
  {
    label: "Cancelled",
    value: "CANCELLED",
  },
];

export const Industry = [
  {
    label: "AGRICULTURE",
    value: "AGRICULTURE",
  },
  {
    label: "FOOD PRODUCTS",
    value: "FOOD_PRODUCTS",
  },
  {
    label: "CANDY SODA",
    value: "CANDY_SODA",
  },
  {
    label: "BEER LIQUOR",
    value: "BEER_LIQUOR",
  },
  {
    label: "TOBACCO PRODUCTS",
    value: "TOBACCO_PRODUCTS",
  },
  {
    label: "RECREATION",
    value: "RECREATION",
  },
  {
    label: "ENTERTAINMENT",
    value: "ENTERTAINMENT",
  },
  {
    label: "PRINTING PUBLISHING",
    value: "PRINTING_PUBLISHING",
  },
  {
    label: "CONSUMER GOODS",
    value: "CONSUMER_GOODS",
  },
  {
    label: "APPAREL",
    value: "APPAREL",
  },
  {
    label: "HEALTHCARE",
    value: "HEALTHCARE",
  },
  {
    label: "MEDICAL EQUIPMENT",
    value: "MEDICAL_EQUIPMENT",
  },
  {
    label: "PHARMACEUTICAL PRODUCTS",
    value: "PHARMACEUTICAL_PRODUCTS",
  },
  {
    label: "CHEMICALS",
    value: "CHEMICALS",
  },
  {
    label: "RUBBER PLASTIC PRODUCTS",
    value: "RUBBER_PLASTIC_PRODUCTS",
  },
  {
    label: "TEXTILES",
    value: "TEXTILES",
  },
  {
    label: "CONSTRUCTION MATERIALS",
    value: "CONSTRUCTION_MATERIALS",
  },
  {
    label: "CONSTRUCTION",
    value: "CONSTRUCTION",
  },
  {
    label: "STEEL WORKS",
    value: "STEEL_WORKS",
  },
  {
    label: "FABRICATED_PRODUCTS",
    value: "FABRICATED_PRODUCTS",
  },
  {
    label: "MACHINERY",
    value: "MACHINERY",
  },
  {
    label: "ELECTRICAL_EQUIPMENT",
    value: "ELECTRICAL_EQUIPMENT",
  },
  {
    label: "AUTOMOBILES_TRUCKS",
    value: "AUTOMOBILES_TRUCKS",
  },
  {
    label: "AIRCRAFT",
    value: "AIRCRAFT",
  },
  {
    label: "SHIPBUILDING_RAILROAD_EQUIPMENT",
    value: "SHIPBUILDING_RAILROAD_EQUIPMENT",
  },
  {
    label: "DEFENSE",
    value: "DEFENSE",
  },
  {
    label: "PRECIOUS_METALS",
    value: "PRECIOUS_METALS",
  },
  {
    label: "NON_METALLIC_INDUSTRIAL_METAL_MINING",
    value: "NON_METALLIC_INDUSTRIAL_METAL_MINING",
  },
  {
    label: "COAL",
    value: "COAL",
  },
  {
    label: "PETROLEUM_NATURAL_GAS",
    value: "PETROLEUM_NATURAL_GAS",
  },
  {
    label: "UTILITIES",
    value: "UTILITIES",
  },
  {
    label: "COMMUNICATION",
    value: "COMMUNICATION",
  },
  {
    label: "PERSONAL_SERVICES",
    value: "PERSONAL_SERVICES",
  },
  {
    label: "PERSONAL_SERVICES",
    value: "PERSONAL_SERVICES",
  },
  {
    label: "COMPUTER_HARDWARE",
    value: "COMPUTER_HARDWARE",
  },
  {
    label: "COMPUTER_SOFTWARE",
    value: "COMPUTER_SOFTWARE",
  },
  {
    label: "ELECTRONIC_EQUIPMENT",
    value: "ELECTRONIC_EQUIPMENT",
  },
  {
    label: "OTHERS",
    value: "OTHERS",
  },
];

export const TradeStatus = [
  {
    label: "NEW",
    value: "NEW",
  },
  {
    label: "DRAFT",
    value: "DRAFT",
  },
  {
    label: "SCHEDULE_APPROVED",
    value: "SCHEDULE_APPROVED",
  },
  {
    label: "SCHEDULE_DECLINED",
    value: "SCHEDULE_DECLINED",
  },
  {
    label: "MILESTONE_REACHED",
    value: "MILESTONE_REACHED",
  },
  {
    label: "DELIVERY_COMPLETED",
    value: "DELIVERY_COMPLETED",
  },
  {
    label: "PAYMENT_INITIATED",
    value: "PAYMENT_INITIATED",
  },
  {
    label: "PAYMENT_COMPLETED",
    value: "PAYMENT_COMPLETED",
  },
  {
    label: "PAYMENT_DECLINED",
    value: "PAYMENT_DECLINED",
  },
  {
    label: "OTHERS",
    value: "OTHERS",
  },
];

export const EscrowService = [
  // TODO To be enabled when Online Payment is Enabled in Tradxlink.
  // {
  //   label: "Escrow Payment",
  //   value: "ESCROW_PAYMENT",
  // },
  // {
  //   label: "Direct Transfer",
  //   value: "DIRECT_TRANSFER",
  // },
  {
    label: "Offline",
    value: "OFFLINE",
  },
];

export const PaymentCurrencies = [
  {
    label: "INR",
    value: "INR",
  },
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "DHM",
    value: "DHM",
  },
];

export const PreferredPaymentMethod = [
  {
    label: "ACCOUNT_TRANSFER",
    value: "ACCOUNT_TRANSFER",
  }
];

export const EscrowPaymentDropDown = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];

export const StatusTradeFilterList = [
  { label: "NEW", value: "NEW" },
  { label: "DRAFT", value: "DRAFT" },
  { label: "TRADE_IN_REVIEW", value: "TRADE_IN_REVIEW" },
  { label: "TRADE_APPROVED", value: "TRADE_APPROVED" },
  { label: "TRADE_IN_PROGRESS", value: "TRADE_IN_PROGRESS" },
  { label: "TRADE_COMPLETED", value: "TRADE_COMPLETED" },
  { label: "BUYER_TRADE_COMPLETED", value: "BUYER_TRADE_COMPLETED" },
  { label: "SELLER_TRADE_COMPLETED", value: "SELLER_TRADE_COMPLETED" },
  { label: "OTHERS", value: "OTHERS" },
  { label: "ABORTED", value: "ABORTED" },
];

export const StatusTrade = [
  { label: "NEW", value: "NEW" },
  { label: "IN_PROGRESS", value: "IN_PROGRESS" },
  { label: "COMPLETED", value: "COMPLETED" },
  { label: "NOT_STARTED", value: "NOT_STARTED" },
  { label: "CANCELLED", value: "CANCELLED" },
];
