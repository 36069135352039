import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Container from "components/UI/Layout/Container";
import { useState } from "react";
import Loader from "components/Loader/Loader";
import Table from "react-bootstrap/Table";
import DocumentIcon from "components/Icons/document_icon";
import { getIdTokenAsync } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { UseRequest } from "utils/API/Request.js";
import { docGroupURL } from "services/BaseURLInstance";
import cn from "classnames";
import classes from "./documentNew.module.css";
import { CompanyList } from "utils/API/CompanyList.js";
import { FindCompanyName } from "utils/helper";
import { Error } from "components/notifications/responseHandle";
import {
  DocumentCatogery,
  DocumentSubCatogery,
} from "utils/DropDown/DocumentCatogery";
import {
  DirectorDocumentSelect,
  DirectorDocumentSubCatogery,
} from "utils/DropDown/DirectorDocumentGroup";
import {
  GlobalDocumentSelect,
  GlobalDocumentSubCatogery,
} from "utils/DropDown/GlobalDocumentGroup";
import SelectWithSearch from "components/fields/SelectWithSearch";
import Input from "components/fields/Input";
import { useEffect } from "react";
import {ToTitleCase} from "../../common/utils/toTitleText"

const AllDocumentsHeader = [
  { name: "Select" },
  { name: "Name" },
  { name: "Category" },
  { name: "Sub-Category" },
  { name: "Owner" },
  { name: "Last modified" },
  { name: "Created On" },
];

const tempalateList = [
  {
    label: "GLOBAL",
    value: "GLOBAL",
  },
  {
    label: "TRADE",
    value: "TRADE",
  },
  {
    label: "DIRECTOR",
    value: "DIRECTOR",
  },
];

const DocumentFinder = ({ setOpen, setDocument, enableButton = true, data }) => {
  let docTemplateName = "";
  if ( data?.state?.dealData || data?.state?.serviceDealDetails || data?.state?.invoiceId ) {
    docTemplateName = "GLOBAL";
  } else if ( data?.state?.subStageDate || data?.state?.serviceId ) {
    docTemplateName = "TRADE";
  } else {
    docTemplateName = "GLOBAL";
  }

  if (data?.OpenTradeAccountDetails) {
    if (data?.folderLocation === "companyDocOAT") {
      docTemplateName = "GLOBAL";
    } else if ( data?.folderLocation === "paymentDocOAT" || data?.folderLocation === "deliveryDocOAT" ) {
      docTemplateName = "TRADE";
    }
  }

  if (data?.folderLocation === "companyDoc") {
    docTemplateName = "GLOBAL";
  } else if (data?.folderLocation === "serviceRequestRequired") {
    docTemplateName = "TRADE";
  } else if (data?.folderLocation === "requirementDoc") {
    docTemplateName = "TRADE";
  } else if ( data?.folderLocation === "paymentsDoc" || data?.folderLocation === "contractsDoc" || data?.folderLocation === "deliveryDoc" ) {
    docTemplateName = "TRADE";
  }
  const cat = data?.state?.docCategory ? data?.state?.docCategory : data?.state?.subStageDate?.documentGroups ? data?.state?.subStageDate?.documentGroups[0].docCategory : "";
  const subCat =data?.state?.docSubCategory? data?.state?.docSubCategory :  data?.state?.subStageDate?.documentGroups? data?.state?.subStageDate?.documentGroups[0].docSubCategory : "";
  const CompanyObject = CompanyList();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const [response, setResponse] = useState();
  const [filterdResponse, setFilterdResponse] = useState();
  const [template, setTemplate] = useState(docTemplateName);
  const [category, setCategory] = useState(cat);
  const [subCategory, setSubCategory] = useState(subCat);
  const [tags, setTags] = useState("");
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const tradeCategoryList = DocumentCatogery();
  const tradeSubCategoryList = DocumentSubCatogery(category);
  const directCategoryList = DirectorDocumentSelect();
  const directSubCategoryList = DirectorDocumentSubCatogery(category);
  const globalCategoryList = GlobalDocumentSelect();
  const globalSubCategoryList = GlobalDocumentSubCatogery(category);
  const [checkedKey, setCheckedKey] = useState(-1);
  const [searchFilter, setSearchFilter] = useState();

    const handleDocumentPreview = (e, id) => {
    e.preventDefault();
    if (id === undefined || id === null || id === "") {
      Error("Document Not available");
    } else {
      handleNavigate(`/documents/preview/${id}`);
    }
  };

  const handleSearchChange = (e, value) => {
    if (value === "template") {
      setTemplate(e.target.value);
    } else if (value === "category") {
      if (template) {
        setCategory(e.target.value);
      } else {
        Error("Please select template first");
      }
    } else if (value === "subCategory") {
      if (category) {
        setSubCategory(e.target.value);
      } else {
        Error("Please select category first");
      }
    } else if (value === "tags") {
      setTags(e.target.value);
    }
  };
  const handleCancel = () => {
    if (checkedKey !== -1) {
      setDocument("");
    }
    setOpen(false);
  };
  const handleCheckBox = (e, index, data) => {
    if (index === checkedKey) {
      setCheckedKey(-1);
    } else {
      setCheckedKey(index);
    }
  };
  const handleSubmit = () => {
    if (checkedKey !== -1) {
      setOpen(false);
      const returnObject = filterdResponse[checkedKey];
      //   returnObject["template"] = template; // TODO
      setDocument(returnObject);
    } else {
      Error("Please select the document");
    }
  };
  const handleSearchFilter = (e) => {
    setSearchFilter(e.target.value.toLowerCase());
  };

  const handleSearchDocument = () => {
    URL = `${docGroupURL}bff/documentsGroups/summary?memberUID=${companyId}&template=${ToTitleCase(template)}&category=${category}&docFilterTags=${tags}`;
    setIsLoading(true);
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, null, response).then((data) => {
        setIsLoading(false);
        let { message } = data;
        if (message) {
          Error(message);
          setIsError(message);
        } else {
          let newData = [];
          data &&
            data.map(
              (doc) =>
                doc &&
                doc.documents &&
                doc.documents.length > 0 &&
                doc.documents.map((dd) => {
                  if (dd.docReferenceId !== "") {
                    newData.push(dd);
                  }
                })
            );

          if (subCategory) {
            newData = newData.filter((item) => {
              return item.docSubCategory === subCategory;
            });
          }
          setResponse([...newData]);
          setFilterdResponse([...newData]);
        }
      });
    });
  };

  useEffect(() => {
    if (searchFilter && response) {
      const tempFilterData = response.filter((item) => {
        return item.documentName.toLowerCase().includes(searchFilter);
      });
      setFilterdResponse(tempFilterData);
    } else {
      setFilterdResponse(response);
    }
  }, [searchFilter]);
  return (
    <>
      <Container padding="1%">
        <div
          style={{
            backgroundColor: "black",
            padding: "1%",
            borderRadius: "5px",
          }}>
          <div style={{ marginLeft: "1%" }}>Search Documents</div>
          <div style={{ width: "50%", textAlign: "left", margin: "1%" }}>
            <input
              style={{
                padding: "1% 2%",
                borderRadius: "5px",
                width: "60%",
                height: "40px",
              }}
              name="search"
              placeholder="Search Document Name"
              onChange={(e) => handleSearchFilter(e)}></input>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <span style={{ width: "24%", margin: "1%" }}>
              <SelectWithSearch
                name="template"
                selected={template}
                value={template}
                placeholder="Template"
                options={tempalateList}
                handleChange={(e) => handleSearchChange(e, "template")}
              />
            </span>
            <span style={{ width: "24%", margin: "1%" }}>
              <SelectWithSearch
                name="category"
                selected={category}
                value={category}
                placeholder="Category"
                options={
                  template === "GLOBAL"
                    ? globalCategoryList?.Category
                    : template === "TRADE"
                    ? tradeCategoryList
                    : template === "DIRECTOR"
                    ? directCategoryList.Category
                    : []
                }
                handleChange={(e) => handleSearchChange(e, "category")}
              />
            </span>
            <span style={{ width: "24%", margin: "1%" }}>
              <SelectWithSearch
                name="subCategory"
                selected={subCategory}
                value={subCategory}
                placeholder="Sub-Category"
                options={
                  template === "GLOBAL" && category
                    ? globalSubCategoryList
                    : template === "TRADE" && category
                    ? tradeSubCategoryList
                    : template === "DIRECTOR" && category
                    ? directSubCategoryList
                    : []
                }
                handleChange={(e) => handleSearchChange(e, "subCategory")}
              />
            </span>
            <span style={{ width: "24%", margin: "1%" }}>
              <Input
                name="tags"
                selected={tags}
                placeholder="Tags"
                handleChange={(e) => handleSearchChange(e, "tags")}
              />
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              marginRight: "1%",
            }}>
            <button
              style={{
                backgroundColor: "#42a5f5",
                padding: "1%",
                borderRadius: "5px",
                color: "#fff",
                border: "none",
                fontSize: "14px",
              }}
              onClick={() => {
                handleSearchDocument();
              }}>
              Search Documents
            </button>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "black",
            padding: "1%",
            borderRadius: "5px",
            marginTop: "1%",
          }}>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              {CompanyObject.response && filterdResponse ? (
                <>
                  <div style={{ marginLeft: "1%" }}>Documents List</div>
                  <div
                    style={{
                      margin: "1%",
                      maxHeight: "20rem",
                      overflow: "scroll",
                    }}>
                    <Table bordered responsive className={cn(classes.Table)}>
                      <thead>
                        <tr>
                          {AllDocumentsHeader.map((header, index) => (
                            <td key={index}>{header.name}</td>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {filterdResponse &&
                          filterdResponse.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <div
                                  style={{
                                    display: "flex",

                                    padding: "1%",
                                    justifyContent: "center",
                                  }}
                                  onClick={(e) =>
                                    handleCheckBox(e, index, data)
                                  }>
                                  {index === checkedKey ? (
                                    <input
                                      style={{ width: "20px" }}
                                      type="checkbox"
                                      checked
                                    />
                                  ) : (
                                    <input
                                      style={{ width: "20px" }}
                                      type="checkbox"
                                    />
                                  )}
                                </div>
                                {/* <input
                                        style={{ cursor: "pointer" }}
                                        className={cn(classes.Checkbox)}
                                        type="checkbox"
                                        onChange={()=>handleCheckBox(data)}
                                    /> */}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                className={cn("d-flex justify-content-left")}
                                onClick={(e) =>
                                  handleDocumentPreview(e, data.docReferenceId)
                                }>
                                <span className={cn("d-flex px-2")}>
                                  {data.documentName && <DocumentIcon />}
                                </span>
                                <span>
                                  {data.documentName ? data.documentName : "NA"}
                                </span>
                              </td>
                              <td>{data.docCategory}</td>
                              <td>{data.docSubCategory}</td>
                              <td>
                                {CompanyObject.response &&
                                  FindCompanyName(
                                    CompanyObject.response,
                                    data.ownerId
                                  )}
                              </td>
                              <td>
                                {data.updatedDate
                                  ? new Date(
                                      data.updatedDate
                                    ).toLocaleDateString()
                                  : "-"}
                              </td>
                              <td>
                                {data.createdDate
                                  ? new Date(
                                      data.createdDate
                                    ).toLocaleDateString()
                                  : "-"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                  {enableButton && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginRight: "1%",
                      }}>
                      <button
                        style={{
                          backgroundColor: "#42a5f5",
                          padding: "1%",
                          borderRadius: "5px",
                          color: "#fff",
                          border: "none",
                          fontSize: "14px",
                          margin: "1%",
                          width: "20%",
                          marginRight: "0%",
                        }}
                        onClick={() => handleCancel()}>
                        Cancel
                      </button>
                      <button
                        style={{
                          backgroundColor: "#42a5f5",
                          padding: "1%",
                          borderRadius: "5px",
                          color: "#fff",
                          border: "none",
                          fontSize: "14px",
                          margin: "1%",
                          width: "20%",
                          marginRight: "0%",
                        }}
                        onClick={handleSubmit}>
                        Submit
                      </button>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div style={{ marginLeft: "1%" }}>No documents available</div>
                  {enableButton && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        marginRight: "1%",
                      }}>
                      <button
                        style={{
                          backgroundColor: "#42a5f5",
                          padding: "1%",
                          borderRadius: "5px",
                          color: "#fff",
                          border: "none",
                          fontSize: "14px",
                          margin: "1%",
                          width: "20%",
                          marginRight: "0%",
                        }}
                        onClick={() => handleCancel()}>
                        Cancel
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </Container>
    </>
  );
};

export default DocumentFinder;
