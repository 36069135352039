import React from "react";
import classes from "./Toogle.module.css";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import { useEffect } from "react";

const SingleToogleTab = ({
  title,
  Component,
  select,
  dropDown,
  plannedEndDate,
  icon,
}) => {
  const [selected, setSelected] = React.useState(select);
  useEffect(() => {
    if (select) {
      setSelected(select);
    } else {
      setSelected(false);
    }
  }, [select]);

  return (
    <div>
      <div
        className={classes.singleToogleTab}
        style={{ padding: "1% 2% 1% 2%" }}>
        <div
          className={classes.toogleTitle}
          style={{ alignItems: "end" }}
          onClick={() => setSelected(!selected)}>
          <span style={{ fontSize: "18px" }}>
            {" "}
            {icon && icon} {title}
          </span>
          {dropDown && dropDown === "true" ? (
            ""
          ) : (
            <span>
              {selected ? (
                <>
                  <div style={{ display: "flex" }}>
                    {plannedEndDate && (
                      <div style={{ marginTop: "5px" }}>
                        Completion Date:{" "}
                        {plannedEndDate
                          ? plannedEndDate
                          : new Date().toLocaleDateString()}
                      </div>
                    )}
                    <CgChevronUp size={30} />
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: "flex" }}>
                    {plannedEndDate && (
                      <div style={{ marginTop: "5px" }}>
                        {" "}
                        Completion Date:{" "}
                        {plannedEndDate
                          ? plannedEndDate
                          : new Date().toLocaleDateString()}
                      </div>
                    )}
                    <CgChevronDown size={30} />
                  </div>
                </>
              )}
            </span>
          )}
        </div>

        <div className={selected ? "show" : "description"}>{Component}</div>
      </div>
    </div>
  );
};

export default SingleToogleTab;
