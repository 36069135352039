import React, { useState, useContext, useCallback } from "react";

import Content from "components/UI/Layout/Content";
import ToogleTab from "components/UI/ToogleSelect/ToogleTab";

import { BsBell } from "react-icons/bs";
import { HiOutlineMail, HiArrowNarrowLeft } from "react-icons/hi";
import Summary from "./Summary";
import Schedule from "./Schedule";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { truncateStringID } from "utils/helper";
import { AuthContext } from "contexts/Auth/AuthContext";
import CustomizedSteppers from "components/UI/Stepper/Stepper";
import {
  OpenAccountFlow,
  OpenAccountFlowDecline,
} from "utils/Section/Workflow";
import { useEffect } from "react";
import { Error } from "components/notifications/responseHandle";

import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { openTradeAccount } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";

const AccountSchedule = () => {
  const { instance, accounts } = useMsal();
  const location = useLocation();
  let { id } = useParams();
  const [selected, setSelected] = useState(
    location.search === "?status=PENDING"
      ? 1
      : location.state
      ? location.state.index
      : 0
  );

  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const { OpenTradeAccountDetails, setOpenTradeAccountDetails } =
    useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);

  const toggle = (i) => {
    setSelected(i);
  };

  const TabData = [
    {
      title: "Summary",
      description: <Summary setSelected={setSelected} />,
    },
    {
      title: "Milestones",
      description: <Schedule setSelected={setSelected} />,
    },
  ];

  const StepperPosition = (status) => {
    switch (status) {
      case "DRAFT":
        return { index: 0, status: "progress" };
      case "NEW":
        return { index: 1, status: "progress" };

      case "TRADE_IN_REVIEW":
        return { index: 2, status: "progress" };

      case "TRADE_APPROVED":
        return { index: 3, status: "completed" };
      case "TRADE_DECLINED":
        return { index: 3, status: "decline" };

      case "TRADE_IN_PROGRESS":
        return { index: 4, status: "progress" };

      case "BUYER_TRADE_COMPLETED":
        return { index: 5, status: "completed" };

      case "TRADE_COMPLETED":
        return { index: 6, status: "completed" };
      case "SELLER_TRADE_COMPLETED":
        return { index: 6, status: "completed" };

      case "ABORTED":
        return { index: 6, status: "decline" };
      case "OTHERS":
        return { index: 6, status: "decline" };

      default:
        return 0;
    }
  };

  const CheckStatus = (status) => {
    if (status === "ABORTED") {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${openTradeAccount}openaccounttrades/${id}`, null, response)
          .then((data) => {
            setOpenTradeAccountDetails(data);
          })
          .catch(() => {
            Error("Something Went Wrong");
            setError("Something Went Wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, []);

  return (
    <>
      <div
        style={{ color: "#218eeb", cursor: "pointer" }}
        onClick={() => handleNavigate(`/trade-account/open-account`)}>
        <span>
          <HiArrowNarrowLeft size={28} />
        </span>
        <span>Back to List </span>
      </div>

      {isLoading ? (
        <Content>
          <span>Loading ...</span>
          <span>
            <Loader />
          </span>
        </Content>
      ) : (
        <>
          {OpenTradeAccountDetails ? (
            <div>
              <Content marginBottom="2%">
                <div className="d-flex justify-content-between">
                  <span style={{ color: "#42A5F5", marginBottom: "2%" }}>
                    Open trade Account ID
                    {OpenTradeAccountDetails.openTradeAccountId
                      ? `${truncateStringID(
                          OpenTradeAccountDetails.openTradeAccountId,
                          7,
                          " OT"
                        )}`
                      : "Not Available"}
                  </span>
                  <span>
                    <HiOutlineMail size={26} className="mx-4" />
                    <BsBell size={26} />
                  </span>
                </div>

                <h5>Open Account Trade</h5>
                <CustomizedSteppers
                  Value={
                    CheckStatus(OpenTradeAccountDetails.openTradeStatus)
                      ? OpenAccountFlowDecline(
                          OpenTradeAccountDetails.openTradeStatus
                        )
                      : OpenAccountFlow
                  }
                  position={StepperPosition(
                    OpenTradeAccountDetails.openTradeStatus
                  )}
                  isDeclined={CheckStatus(
                    OpenTradeAccountDetails.openTradeStatus
                  )}
                />
              </Content>

              <ToogleTab
                selected={selected}
                toggle={toggle}
                TabData={TabData}
              />
            </div>
          ) : (
            <Content>Something went wrong</Content>
          )}
        </>
      )}
    </>
  );
};

export default AccountSchedule;
