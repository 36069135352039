import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  .card {
    display: flex;
    flex: 47%;
    flex-direction: column;
    background: #1e1e1e;
    border-radius: 10px;
    margin: 5px 10px 5px 0px;
    .content {
      font-weight: 500;
      font-size: 16px;
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1rem;
      flex: 1 1 auto;
      span {
        color: #218eeb;
      }
    }
  }
`;

export default Wrapper;
