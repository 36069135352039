import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { AiOutlineInfoCircle } from "react-icons/ai";

const OverLayTrigger = ({ value, marginleft }) => {
  return (
    <div className="d-flex align-items-center my-2">
      <span style={ marginleft ? {marginLeft : `${marginleft}`, fontSize: "18px" } : { fontSize: "18px" } }>
        {value && value.title ? value.title : "NA"}
      </span>
      <span className="mx-1">
        <OverlayTrigger
          trigger={["hover", "focus"]}
          key="top"
          placement="top"
          overlay={
            <Popover id="popover-positioned-top">
              <Popover.Body>
                <strong>{value && value.Info ? value.Info : "NA"}</strong>
              </Popover.Body>
            </Popover>
          }>
          <span className="text-warning">
            <AiOutlineInfoCircle size={18} />
          </span>
        </OverlayTrigger>
      </span>
    </div>
  );
};

export default OverLayTrigger;
