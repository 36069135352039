import React, { useState, useEffect, useContext } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import { profileURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";

import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import SelectWithSearch from "components/fields/SelectWithSearch";
import { storeContext } from "contexts/Store/Store";
import { NetworkURL } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import { getIdTokenAsync } from "utils/authUtil";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";

const CreditAsessment = ({ CreditData, setCreditData }) => {
  const { fetchCompanyList } = useContext(storeContext);
  const [response, setResponse] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setError] = useState(null);

  const [companyList, setCompanyList] = useState();
  const { instance, accounts } = useMsal();
  const companyId = getClaim(accounts, "company_member_id");
  let Factors = [
    {
      label: "Financial health and stability",
      value: "Financial health and stability",
    },
    {
      label: "Credit ratings",
      value: "Credit ratings",
    },
    {
      label: "Payment history",
      value: "Payment history",
    },
    {
      label: " Industry Risk and analysis",
      value: " Industry Risk and analysis",
    },
    {
      label: " Management team",
      value: " Management team",
    },
    {
      label: " Market position",
      value: " Market position",
    },
    {
      label: " Legal and regulatory environment ",
      value: " Legal and regulatory environment ",
    },
  ];

  useEffect(() => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    let URL = `${NetworkURL}members?memberId=${companyId}`;
    setIsLoading(true);
    fetchCompanyList(URL, idToken)
      .then((response) => {
        let nMember = response;
        nMember = nMember.filter(
          (item) =>
            item?.memberType !== undefined &&
            item?.memberType !== "PERSON" &&
            item?.memberType !== "FINANCER" &&
            item?.memberType !== "SERVICE_PROVIDER"
        );
        setResponse(response);
        const transformedData = nMember.map((option) => ({
          label: option.memberName,
          value: option.memberName,
        }));
        setCompanyList(transformedData); // Update the component's state with the transformed data
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  console.log("transformedData 22", companyList);

  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setCreditData({
      ...CreditData,
      [name]: value,
    });
  };

  const handleMultiChange = (e, name) => {
    if (name === "Factors") {
      setCreditData({
        ...CreditData,

        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChange = (e, date) => {
    if (e.target.name === "requirement") {
      setCreditData({
        ...CreditData,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "newCompanyName") {
      setCreditData({
        ...CreditData,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "newContactInfo") {
      setCreditData({
        ...CreditData,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "newAddress") {
      setCreditData({
        ...CreditData,
        [e.target.name]: e.target.value,
      });
    }
    if (e.target.name === "companyName") {
      const Filted = response?.filter(
        (list) => list.memberName === e.target.value
      );
      console.log("memberuiiiiid", Filted);
      if (Filted && Filted.length > 0) {
        let URL = "";
        if (Filted[0]?.memberType === "FINANCER") {
          URL = `${profileURL}financers/${Filted[0]?.memberUID}`;
        } else {
          URL = `${profileURL}companies/${Filted[0]?.memberUID}`;
        }
        setIsLoading(true);
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(URL, null, response)
            .then((data) => {
              console.log("datadata", data);

              setCreditData({
                ...CreditData,
                [e.target.name]: e.target.value,
                contactInfo: `${data.companyPhone[0]},${" "}${
                  data.companyEMail
                }`,
                address: data.companyAddress[0],
                email: data.companyEMail,
              });
            })
            .catch((error) => {
              Error();
            })
            .finally(() => {
              setIsLoading(false);
            });
        });
      } else {
        setCreditData({
          ...CreditData,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <Content>
          {" "}
          <div style={{ margin: "10px" }}>CREDIT ASSESMENT</div>
          <div style={{ margin: "10px" }}>
            Credit Assesment Services Required for* ?
          </div>
          <div className={classes.source} style={{ margin: "10px" }}>
            <div
              style={{ marginLeft: "10px", width: "50%", marginRight: "10px" }}
            >
              <input
                className={classes.radio}
                type="radio"
                name="serviceType"
                value="Existing"
                onChange={handleRadio}
                checked={CreditData.serviceType == "Existing"}
              />
              <label>Existing Participant</label>
            </div>{" "}
            <div
              style={{
                // fontSize: "25px",
                marginLeft: "10px",
                width: "50%",
                textAlign: "right",
                marginRight: "10px",
              }}
            >
              <input
                className={classes.radio}
                type="radio"
                name="serviceType"
                value="New"
                onChange={handleRadio}
                checked={CreditData.serviceType == "New"}
              />
              <label>New Participant</label>
            </div>
          </div>
          <div style={{ width: "100%", margin: "10px", paddingRight: "20px" }}>
            {CreditData.serviceType === "Existing" ? (
              <div>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                >
                  <SelectWithSearch
                    options={companyList}
                    name="companyName"
                    placeholder="Select Company*"
                    handleChange={handleChange}
                    selected={CreditData.companyName}
                    value={CreditData.companyName}
                  />{" "}
                </div>

                {/* <div style={{ width: "100%", marginBottom: "1%" }}>
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="companyName"
                    placeholder=" Company Name "
                    handleChange={handleChange}
                    value={CreditData.companyName}
                    // readOnly={true}
                  />
                </div> */}
                <div style={{ width: "100%", marginBottom: "1%" }}>
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="contactInfo"
                    placeholder=" Company Contact Information* "
                    handleChange={handleChange}
                    value={CreditData.contactInfo}
                    // readOnly={true}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "1%" }}>
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="address"
                    placeholder=" Company Address* "
                    handleChange={handleChange}
                    value={CreditData.address}
                    // readOnly={true}
                  />
                </div>
              </div>
            ) : (
              <>
                {" "}
                <div
                  style={{
                    width: "100%",
                    marginBottom: "1%",
                    marginTop: "2%",
                  }}
                >
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="newCompanyName"
                    placeholder=" Company Name* "
                    handleChange={handleChange}
                    value={CreditData.newCompanyName}
                    // readOnly={true}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "1%" }}>
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="newContactInfo"
                    placeholder=" Company Contact Information* "
                    handleChange={handleChange}
                    value={CreditData.newContactInfo}
                    // readOnly={true}
                  />
                </div>
                <div style={{ width: "100%", marginBottom: "1%" }}>
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="newAddress"
                    placeholder=" Company Address *"
                    handleChange={handleChange}
                    value={CreditData.newAddress}
                    // readOnly={true}
                  />
                </div>
              </>
            )}
          </div>
          <div style={{ marginLeft: "1%", marginTop: "2%" }}>
            Credit Assesment should cover what all factors ?*
          </div>
          <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
            <MultiSelect
              options={Factors}
              name="Factors"
              placeholder="Factors*"
              handleChange={(e) => handleMultiChange(e, "Factors")}
              selected={CreditData.Factors}
              // readOnly={true}
            />
          </div>
          <div style={{ margin: "10px", marginLeft: "1%", marginTop: "2%" }}>
            <label>
              Give your own Credit Assesment coverage specific coverage
              Requirements
            </label>
          </div>
          <div style={{ width: "100%", margin: "10px", paddingRight: "20px" }}>
            <TextArea
              style={{ height: "7rem", width: "100%" }}
              name="requirement"
              placeholder=" Requirement "
              handleChange={handleChange}
              value={CreditData.requirement}
              // readOnly={true}
            />
          </div>
        </Content>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default CreditAsessment;
