import React, { useState } from "react";
import classes from "./freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import { Grid } from "@mui/material";
import Currenciesr from "jsons/Currenciesr.json";
import { useEffect } from "react";
import { Countries } from "utils/DropDown/Country";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { FREIGHT_IMPORT } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";
import {Error} from "components/notifications/responseHandle";
const Details = ({
  freightCargoDataList,
  setFreightCargoDataList,
  formdata,
  setFormData,
  isAdded,
  setIsAdded,
  addCargo,
  setAddCargo,
  isHazardous,
  setIsHazardous,
}) => {
  const [cargoData, setCargoData] = useState({
    Containers_Count: "",
    Container_Size: "",
    Cargo_Weight_Per_Container: "",
    Currency: "",
    Cargo_Value: "",
    Package_Type: "",
    Total_Quantity: "",
    Total_Weight: "",
    Max_Dimension: "",
    Length: "",
    Weidth: "",
    Height: "",
    Total_Units: "",
    Total_Volume: "",
    Total_WeightAir: "",
    Total_Package: "",
    Volume_unit: "",
    weight_unit: "",
    package_unit: "",
    weight_unit1: "",
  });
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "ISD",
      value: "ISD",
    },
    {
      label: "FACTORY",
      value: "FACTORY",
    },
    {
      label: "CFS",
      value: "CFS",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "ISD",
      value: "ISD",
    },
    {
      label: "FACTORY",
      value: "FACTORY",
    },
    {
      label: "CFS",
      value: "CFS",
    },
  ];

  let containerTypeOptions = [
    {
      label: "10-Foot Container",
      value: "10-Foot Container",
    },
    {
      label: "20-Foot Container",
      value: "20-Foot Container",
    },
    {
      label: "40-Foot Container",
      value: "40-Foot Container",
    },
    {
      label: "20-Foot High Cube",
      value: "20-Foot High Cube",
    },
    {
      label: "40-Foot High Cube",
      value: "40-Foot High Cube",
    },
  ];

  let transportModeOptions = [
    {
      label: "AIR",
      value: "AIR",
    },
    {
      label: "RAIL",
      value: "RAIL",
    },
    {
      label: "ROAD",
      value: "ROAD",
    },
    {
      label: "SEA",
      value: "SEA",
    },
  ];

  let measureUnitOptions = [
    {
      label: "LB",
      value: "LB",
    },
    {
      label: "KG",
      value: "KG",
    },
  ];

  let volumeUnitOptions = [
    {
      label: "CBM",
      value: "CBM",
    },
    {
      label: "CC",
      value: "CC",
    },
    {
      label: "CFT",
      value: "CFT",
    },
  ];

  let packageOptions = [
    {
      label: "Pallet",
      value: "Pallet",
    },
    {
      label: "Box",
      value: "Box",
    },
    {
      label: "Loose",
      value: "Loose",
    },
  ];

  let cargoHazardousOptions = [
    {
      label: "EXPLOSIVES",
      value: "EXPLOSIVES",
    },
    {
      label: "GASES",
      value: "GASES",
    },
    {
      label: "FLAMMABLE_SOLIDS",
      value: "FLAMMABLE_SOLIDS",
    },
    {
      label: "OXIDIZERS_ORGANIC_PEROXIDES",
      value: "OXIDIZERS_ORGANIC_PEROXIDES",
    },
    {
      label: "TOXIC INFECTIOUS",
      value: "TOXIC_INFECTIOUS",
    },
    {
      label: "RADIOACTIVE",
      value: "RADIOACTIVE",
    },
    {
      label: "CORROSIVES",
      value: "CORROSIVES",
    },
    {
      label: "MISCELLANEOUS_HAZARDOUS",
      value: "MISCELLANEOUS_HAZARDOUS",
    },
    {
      label: "NOT_APPLICABLE",
      value: "NOT_APPLICABLE",
    },
  ];

  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [tableCargoData, setTableCargoData] = useState([]);
  const LoadHeader = [
    { name: "Container Count" },
    { name: "Container Size" },
    { name: "Cargo Weight Per Container (Metric ton)" },
    { name: "Cargo Value" },
  ];

  const LoadHeaderRoad = [
    { name: "Package Type" },
    { name: "Total Quantity" },
    { name: "Total Weight" },
    { name: "Length(cm)" },
    { name: "Width(cm)" },
    { name: "Height(cm)" },
  ];

  const LoadHeaderAir = [
    { name: "Total Units" },
    { name: "Total Volume" },
    { name: "Total Weight" },
    { name: "Total Package" },
  ];

  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  const handleCargo = (event) => {
    setAddCargo(true);
  };


  const handleAddLoad = () => {
   
     if(formdata.transportMode === "SEA" ||
           formdata.transportMode === "RAIL"){
             if(cargoData.Containers_Count===""){
              Error("Please enter Container Count");
              return;
             }
             if(cargoData.Container_Size===""){
              Error("Please enter Container Size");
              return;
             }
             if(cargoData.Cargo_Weight_Per_Container ===""){
              Error("Please enter Cargo Weight Per Container");
              return;
             }
             if(cargoData.Currency ===""){
              Error("Please enter Currency");
              return;
             }
             if(cargoData.Cargo_Value ===""){
              Error("Please enter Cargo Value");
              return;
             }
      }
      if(formdata.transportMode === "AIR"){
        if(cargoData.Total_Units===""){
          Error("Please enter Total Units");
          return;
         }
         if(cargoData.Total_Volume ===""){
          Error("Please enter Total Volume");
          return;
         }
         if(cargoData.Volume_unit===""){
          Error("Please enter Volume Unit");
          return;
         }
         if(cargoData.Total_WeightAir ===""){
          Error("Please enter Total Weight");
          return;
         }
         if(cargoData.weight_unit1 ===""){
          Error("Please enter weight unit");
          return;
         }
         if(cargoData.Total_Package ===""){
          Error("Please enter Total Package");
          return;
         }
         if(cargoData.package_unit ===""){
          Error("Please enter Package Unit");
          return;
         }
      }
      if(formdata.transportMode === "ROAD"){
        if(cargoData.Package_Type ===""){
          Error("Please enter Package Type");
          return;
         } if(cargoData.Total_Quantity ===""){
          Error("Please enter Total Quantity");
          return;
         } if(cargoData.Total_Weight ===""){
          Error("Please enter Total Weight");
          return;
         } if(cargoData.weight_unit ===""){
          Error("Please enter weight unit");
          return;
         } if(cargoData.Length ===""){
          Error("Please enter Length");
          return;
         } if(cargoData.Weidth ===""){
          Error("Please enter Weidth");
          return;
         } if(cargoData.Height ===""){
          Error("Please enter Height");
          return;
         }
      }

    setFreightCargoDataList([...freightCargoDataList, cargoData]);
    setAddCargo(false);
    setCargoData({
      Containers_Count: "",
      Container_Size: "",
      Cargo_Weight_Per_Container: "",
      Currency: "",
      Cargo_Value: "",
      Package_Type: "",
      Total_Quantity: "",
      Total_Weight: "",
      Max_Dimension: "",
      Length: "",
      Weidth: "",
      Height: "",
      Total_Units: "",
      Total_Volume: "",
      Total_WeightAir: "",
      Total_Package: "",
      Volume_unit: "",
      weight_unit: "",
      package_unit: "",
      weight_unit1: "",
    });
  };

  const handleChange = (e, date) => {
    if (date === "CARGO_READY" || date === "PREFERRED_SAILING_DATE") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "CARGO_READY") {
        setDatePicker1(newDate1);
        // setFormData({
        //   ...formdata,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "PREFERRED_SAILING_DATE") {
        setDatePicker2(newDate1);
        // setFormData({
        //   ...formdata,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setFormData({
        ...formdata,
        [date]: dateString,
      });
    } else if (e.target.name === "CARGO_READY") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "PREFERRED_SAILING_DATE") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Containers_Count") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Container_Size") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Cargo_Weight_Per_Container") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Currency") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Cargo_Value") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Volume_unit") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Package_Type") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Total_Quantity") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Total_Weight") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Max_Dimension") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Length") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Weidth") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Height") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Total_Units") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Total_Volume") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Total_Weight") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Total_WeightAir") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "weight_unit1") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "Total_Package") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "weight_unit") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "package_unit") {
      setCargoData({
        ...cargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryFrom") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityFrom") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cityTo") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "portOrigin") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationPort") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destinationType") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destination") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cargoHazardous") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "UN_Number") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "transportMode") {
      setFormData({
        ...formdata,
        [e.target.name]: e.target.value,
      });
    }

    sessionStorage.setItem("formdata", JSON.stringify(formdata));
    sessionStorage.setItem("tableCargoData", JSON.stringify(tableCargoData));

    console.log("formdata", formdata);
  };
  useEffect(() => {
    if (!isHazardous) {
      setFormData({
        ...formdata,
        cargoHazardous: "",
        UN_Number: "",
      });
    }
  }, [isHazardous]);
    
  const checkAddLoad = () =>{setIsAdded(false)};
  const combineFncAddload =() =>{
    handleCargo();
    checkAddLoad();
    };

  return (
    <>
      <Content padding="1% 2% 1.5% 2%">
        <div style={{ margin: "10px" }}>
          <OverLayTrigger value={FREIGHT_IMPORT} />
        </div>
        <div className={classes.header} style={{ margin: "10px" }}>
          Where Are You Shipping?
        </div>
        <div className={classes.source} style={{ margin: "10px" }}>
          <div
            style={{ marginLeft: "10px", width: "50%", marginRight: "10px" }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="freightType"
              value="INTERNATIONAL"
              onChange={handleRadio}
              checked={formdata.freightType == "INTERNATIONAL"}
            />
            <label>International Freight</label>
          </div>
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "10px",
              width: "50%",
              textAlign: "right",
              marginRight: "10px",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="freightType"
              value="DOMESTIC"
              onChange={handleRadio}
              checked={formdata.freightType == "DOMESTIC"}
            />
            <label>Domestic Freight</label>
          </div>
        </div>
        <div style={{ margin: "10px 10px 0px 10px" }}>Mode of Shipment</div>
        <div style={{ width: "25%", margin: "10px" }}>
          <Select
            name="transportMode"
            options={transportModeOptions}
            placeholder="Transport Mode*"
            handleChange={handleChange}
            value={formdata.transportMode}
          />
        </div>
        {/* <div style={{ marginBottom: "1%", marginTop: "2%" }}>
          Shipment details
        </div>
        <div className={classes.source} style={{ marginBottom: "4%" }}>
          <div className={classes.source} style={{ width: "40%" }}>
            <div style={{ width: "30%" }}>
              <Select
                options={sourceOptions}
                name="originType"
                placeholder="From"
                handleChange={handleChange}
                value={formdata.originType}
              />
            </div>
            <div style={{ width: "70%" }}>
              <Select
                options={port_code}
                name="origin"
                placeholder="Origin"
                value={formdata.origin}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div style={{ width: "20%" }}></div>
          <div className={classes.source} style={{ width: "40%" }}>
            <div style={{ width: "30%" }}>
              <Select
                options={destinationOptions}
                name="destinationType"
                placeholder="To"
                handleChange={handleChange}
                value={formdata.destinationType}
              />
            </div>
            <div style={{ width: "70%" }}>
              <Select
                options={port_code}
                name="destination"
                placeholder="Destination"
                value={formdata.destination}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={classes.source}>
            <div style={{ marginBottom: "1.2%", width: "50%", marginLeft: "1%" }}>
              <label>CARGO READY DATE</label>
            </div>
            {formdata.transportMode && formdata.transportMode === "SEA" ? (
              <div style={{ marginBottom: "1.2%", width: "50%", marginLeft: "1%" }}>
                <label>PREFERRED SAILING DATE</label>
              </div>
            ) : (<div style={{ marginBottom: "1.2%", width: "50%", marginLeft: "1%" }}>
              <label>PREFERRED DISPATCH DATE</label>
            </div>)}
          </div>
          <div className={classes.source}>
            <div style={{ width: "50%", marginBottom: "2%" }}>
              <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                <DateInput
                  name="CARGO_READY"
                  placeholder="Cargo Ready Date MM/DD/YYYY"
                  value={DatePicker1}
                  handleChange={(e) => handleChange(e, "CARGO_READY")}
                />
              </Grid>
            </div>
            <div style={{ width: "50%" }}>
              {formdata.transportMode && formdata.transportMode === "SEA" ? (
                <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                  <DateInput
                    name="PREFERRED_SAILING_DATE"
                    placeholder="PREFERRED SAILING DATE MM/DD/YYYY"
                    value={DatePicker2}
                    handleChange={(e) =>
                      handleChange(e, "PREFERRED_SAILING_DATE")
                    }
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
                  <DateInput
                    name="PREFERRED_SAILING_DATE"
                    placeholder="PREFERRED DISPATCH DATE MM/DD/YYYY"
                    value={DatePicker2}
                    handleChange={(e) =>
                      handleChange(e, "PREFERRED_SAILING_DATE")
                    }
                  />
                </Grid>
              )}
            </div>
          </div>
        </div> */}
        <hr style={{border:"2px solid white", margin:"20px 10px 10px 10px"}}></hr>
        <div style={{ margin: "10px 10px 0px 10px" }}>Shipment details</div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country  From*"
              handleChange={handleChange}
              value={formdata.countryFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country To*"
              handleChange={handleChange}
              value={formdata.countryTo}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityFrom"
              placeholder="City From"
              handleChange={handleChange}
              value={formdata.cityFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="cityTo"
              placeholder="City To"
              handleChange={handleChange}
              value={formdata.cityTo}
              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="portOrigin"
              placeholder="From Port*"
              handleChange={handleChange}
              value={formdata.portOrigin}
            />
          </div>

          <div style={{ width: "50%", margin: "10px" }}>
            <SelectWithSearch
              options={
                port_code &&
                port_code.sort((a, b) => a.label.localeCompare(b.label))
              }
              name="destinationPort"
              placeholder="To Port*"
              value={formdata.destinationPort}
              handleChange={handleChange}
            />
          </div>
        </div>
        <hr style={{border:"2px solid white", margin:"10px 10px 10px 10px"}}></hr>
        <div>
          <div>
            <div style={{ margin: "10px 10px 0px 10px" }}>
              <label>COMMODITY</label>
            </div>
          </div>
          <div className={classes.source}>
            <div style={{ width: "100%", margin: "8px 10px 10px 10px" }}>
              <SelectWithSearch
                options={Industry}
                name="commodity"
                placeholder="Commodity*"
                handleChange={handleChange}
                value={formdata.commodity}
                // readOnly={true}
              />
            </div>
          </div>
        </div>

        <>
          <div style={{ margin: "10px" }}>Cargo Details</div>

          <div style={{ margin: "10px" }}>
            <Button onClick={combineFncAddload}>+ Add Loads</Button>
          </div>
          <div className={classes.TableContainer}>
            <Table bordered responsive="sm" className={cn(classes.Table)}>
              {formdata.transportMode && (
                <>
                  <thead>
                    {(formdata.transportMode === "SEA" ||
                      formdata.transportMode === "RAIL") && (
                      <>
                        <tr>
                          {LoadHeader &&
                            LoadHeader.map((header, i) => (
                              <td key={i}>{header.name}</td>
                            ))}
                        </tr>
                      </>
                    )}
                    {formdata.transportMode === "AIR" && (
                      <>
                        <tr>
                          {LoadHeaderAir &&
                            LoadHeaderAir.map((header, i) => (
                              <td key={i}>{header.name}</td>
                            ))}
                        </tr>
                      </>
                    )}
                    {formdata.transportMode === "ROAD" && (
                      <>
                        <tr>
                          {LoadHeaderRoad &&
                            LoadHeaderRoad.map((header, i) => (
                              <td key={i}>{header.name}</td>
                            ))}
                        </tr>
                      </>
                    )}
                  </thead>
                </>
              )}
              <tbody>
                {formdata.transportMode &&
                  freightCargoDataList &&
                  freightCargoDataList.map((tableCargoData1, i) => (
                    <tr key={i}>
                      {(formdata.transportMode === "SEA" ||
                        formdata.transportMode === "RAIL") && (
                        <>
                          <td>
                            {tableCargoData1 &&
                              tableCargoData1.Containers_Count}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Container_Size}
                          </td>
                          <td>
                            {tableCargoData1 &&
                              tableCargoData1.Cargo_Weight_Per_Container}
                            {" MT"}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Cargo_Value}{" "}
                            {tableCargoData1 && tableCargoData1.Currency}
                          </td>
                        </>
                      )}
                      {formdata.transportMode === "ROAD" && (
                        <>
                          <td>
                            {tableCargoData1 && tableCargoData1.Package_Type}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Total_Quantity}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Total_Weight}{" "}
                            {tableCargoData1 && tableCargoData1.weight_unit}
                          </td>
                          {/* <td>
                        {tableCargoData1 &&
                          tableCargoData1.Max_Dimension}{" cm cube"}
                      </td> */}
                          <td>
                            {tableCargoData1 && tableCargoData1.Length}
                            {" cm"}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Weidth}
                            {" cm"}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Height}
                            {" cm"}
                          </td>
                        </>
                      )}
                      {formdata.transportMode === "AIR" && (
                        <>
                          <td>
                            {tableCargoData1 && tableCargoData1.Total_Units}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Total_Volume}{" "}
                            {tableCargoData1 && tableCargoData1.Volume_unit}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Total_WeightAir}{" "}
                            {tableCargoData1 && tableCargoData1.weight_unit1}
                          </td>
                          <td>
                            {tableCargoData1 && tableCargoData1.Total_Package}{" "}
                            {tableCargoData1 && tableCargoData1.package_unit}
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          {addCargo && formdata.transportMode && (
            <>
              <div className={classes.loads} style={{backgroundColor:"black"}}>
                {(formdata.transportMode === "SEA" ||
                  formdata.transportMode === "RAIL") && (
                  <>
                    <div className={classes.source}>
                      <div style={{ width: "50%", margin: "10px" }}>
                        <Input
                          name="Containers_Count"
                          placeholder="Container Count"
                          value={cargoData.Containers_Count}
                          handleChange={handleChange}
                        />
                      </div>
                      <div style={{ width: "50%", margin: "10px" }}>
                        <Select
                          options={containerTypeOptions}
                          name="Container_Size"
                          placeholder="Container Size"
                          handleChange={handleChange}
                          value={cargoData.Container_Size}
                        />
                      </div>
                    </div>

                    <div className={classes.source}>
                      <div style={{ width: "50%", margin: "10px" }}>
                        <Input
                          name="Cargo_Weight_Per_Container"
                          placeholder="Cargo Weight Per Container (Metric ton)"
                          value={cargoData.Cargo_Weight_Per_Container}
                          handleChange={handleChange}
                        />
                      </div>
                      <div style={{ width: "20%", margin: "10px" }}>
                        <Select
                          options={Currenciesr}
                          name="Currency"
                          placeholder="Select Currency"
                          handleChange={handleChange}
                          value={cargoData.Currency}
                        />
                      </div>
                      <div style={{ width: "30%", margin: "10px" }}>
                        <Input
                          name="Cargo_Value"
                          placeholder={`Cargo Value(${cargoData.Currency})`}
                          handleChange={handleChange}
                          value={cargoData.Cargo_Value}
                        />
                      </div>
                    </div>
                  </>
                )}
                {formdata.transportMode === "AIR" && (
                  <>
                    <div className={classes.source}>
                      <div style={{ width: "50%", margin: "10px" }}>
                        <Input
                          name="Total_Units"
                          placeholder="Total Units"
                          value={cargoData.Total_Units}
                          handleChange={handleChange}
                        />
                      </div>
                      <div style={{ width: "30%", margin: "10px" }}>
                        <Input
                          name="Total_Volume"
                          placeholder="Total Volume"
                          handleChange={handleChange}
                          value={cargoData.Total_Volume}
                        />
                      </div>
                      <div style={{ width: "20%", margin: "10px" }}>
                        <Select
                          options={volumeUnitOptions}
                          name="Volume_unit"
                          placeholder="Volume unit"
                          handleChange={handleChange}
                          value={cargoData.Volume_unit}
                        />
                      </div>
                    </div>

                    <div className={classes.source}>
                      <div style={{ width: "30%", margin: "10px" }}>
                        <Input
                          name="Total_WeightAir"
                          placeholder="Total Weight"
                          value={cargoData.Total_WeightAir}
                          handleChange={handleChange}
                        />
                      </div>
                      <div style={{ width: "20%", margin: "10px" }}>
                        <Select
                          options={measureUnitOptions}
                          name="weight_unit1"
                          placeholder="Weight unit"
                          value={cargoData.weight_unit1}
                          handleChange={handleChange}
                        />
                      </div>
                      <div style={{ width: "30%", margin: "10px" }}>
                        <Input
                          name="Total_Package"
                          placeholder="Total Package"
                          handleChange={handleChange}
                          value={cargoData.Total_Package}
                        />
                      </div>
                      <div style={{ width: "20%", margin: "10px" }}>
                        <Select
                          options={packageOptions}
                          name="package_unit"
                          placeholder="Package unit"
                          handleChange={handleChange}
                          value={cargoData.package_unit}
                        />
                      </div>
                    </div>
                  </>
                )}
                {formdata.transportMode === "ROAD" && (
                  <>
                    <div className={classes.source}>
                      <div style={{ width: "50%", margin: "10px" }}>
                        <Select
                          options={packageOptions}
                          name="Package_Type"
                          placeholder="Package Type"
                          value={cargoData.Package_Type}
                          handleChange={handleChange}
                        />
                      </div>
                      <div style={{ width: "50%", margin: "10px" }}>
                        <Input
                          name="Total_Quantity"
                          placeholder="Total Quantity"
                          handleChange={handleChange}
                          value={cargoData.Total_Quantity}
                        />
                      </div>
                    </div>

                    <div className={classes.source}>
                      <div style={{ width: "30%", margin: "10px" }}>
                        <Input
                          name="Total_Weight"
                          placeholder="Total Weight"
                          value={cargoData.Total_Weight}
                          handleChange={handleChange}
                        />
                      </div>
                      <div style={{ width: "20%", margin: "10px" }}>
                        <Select
                          options={measureUnitOptions}
                          name="weight_unit"
                          placeholder="Weight Unit"
                          value={cargoData.weight_unit}
                          handleChange={handleChange}
                        />
                      </div>
                      {/* <div style={{ width: "12.25%", margin: "10px" }}>
                        <Input
                          name="Max_Dimension"
                          placeholder="Max Dimension(cm)"
                          readOnly={true}
                          handleChange={handleChange}
                          value={cargoData.Max_Dimension}
                        />
                      </div> */}
                      <div style={{ width: "16.25%", margin: "10px" }}>
                        <Input
                          name="Length"
                          placeholder="Length(cm)"
                          handleChange={handleChange}
                          value={cargoData.Length}
                        />
                      </div>
                      <div style={{ width: "16.25%", margin: "10px" }}>
                        <Input
                          name="Weidth"
                          placeholder="Width(cm)"
                          handleChange={handleChange}
                          value={cargoData.Weidth}
                        />
                      </div>
                      <div style={{ width: "16.25%", margin: "10px" }}>
                        <Input
                          name="Height"
                          placeholder="Height(cm)"
                          handleChange={handleChange}
                          value={cargoData.Height}
                        />
                      </div>
                    </div>
                  </>
                )}
                <ButtonDiv>
                  <Button onClick={handleAddLoad}>Add</Button>
                </ButtonDiv>
              </div>
            </>
          )}
        </>
        <div>
          <input
            className={classes.Checkbox}
            type="checkbox"
            onChange={(e) => setIsHazardous(e.target.checked)}
          />
          <label style={{ marginLeft: "10px", fontSize: "18px" }}>
            {" "}
            This cargo is hazardous
          </label>
        </div>

        {isHazardous && (
          <div className={classes.source} style={{ marginBottom: "2%" }}>
            <div className={classes.source} style={{ width: "40%" }}>
              <div style={{ width: "50%", margin: "10px" }}>
                <Select
                  options={cargoHazardousOptions}
                  name="cargoHazardous"
                  placeholder="Cargo Hazardous"
                  handleChange={handleChange}
                  value={formdata.cargoHazardous}
                />
              </div>
              <div style={{ width: "50%", margin: "10px" }}>
                <Input
                  name="UN_Number"
                  placeholder="UN#"
                  value={formdata.UN_Number}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}
      </Content>
    </>
  );
};

export default Details;
