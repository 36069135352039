import React, { useState } from "react";
import { Card, Grid, Button } from '@mui/material';
import { SelectedFinancers } from "./SelectedFinancers";
import MultiSelect from "components/fields/MultiSelect";

export const StepperTwo = (props) => {

  const { financers } = props.apiData;
 
  //const [listFinancer, setListFinancer] = useState(true)

  const createDealStructure = props.createDealStructure;
  const listFinancer= props.listFinancer;
  const financeTable = props.financeTable;
  const setListFinancer= props.setListFinancer;
  const confirmFinancers= props.confirmFinancers;
  const showfinacerTable = props.showfinacerTable;
  const enableDrawdown = props.enableDrawdown;
  const showDrawdown = props.showDrawdown;
  const enableStepper2 = props.enableStepper2;
  const resetFlow = props.resetFlow;
  const disabledealStructure = props.disabledealStructure;
  const enableStepper=  props.enableStepper;
  const backFormTranchTable = props.backFormTranchTable;

  const showFinancersTable= props.showFinancersTable; 
  const setShowFinancersTable= props.setShowFinancersTable;

  const selectedFinanacerFunc= props.selectedFinanacerFunc;
  const showSelectedFinancer= props.showSelectedFinancer;
  const changeFinancer = props.changeFinancer;
  const editTranche = props.editTranche;
  const createFinancer = props.createFinancer;
  
  const enableComp = () => {
    setListFinancer(true);
    enableStepper2()
  }

  const financeProps = {
    props,
    createDealStructure,
    showfinacerTable,
    enableDrawdown,
    showDrawdown,
    changeFinancer,
    enableComp,
    resetFlow,
    disabledealStructure,
    backFormTranchTable,
    setListFinancer,
    showFinancersTable,
    setShowFinancersTable,
    financeTable,
    editTranche,
    createFinancer
  }
  
  return (
    <>
      {listFinancer &&
        <Card style={{
          backgroundColor: "#1E1E1E",
          padding: "30px 20px",
          borderRadius: "5px",
          margin: "0px 10px"
        }}>
          <h4 style={{ margin: "0px", color: "white" }}>
            Select Financers
          </h4>
          <br></br>

          <Grid container>
            <Grid item xs={12} md={2}>
              <h5 style={{ margin: "0px", color: "white" }}>
                Financers
              </h5>
            </Grid>
            <Grid item xs={12} md={10} style={{ minHeight: "150px" }}>
              <MultiSelect
                name="financers"
                selected={financers}
                options={props.options}
                handleChange={props.handleChange}
              />
            </Grid>
          </Grid>

          <br></br>
          <br></br>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
              variant="contained"
              color="primary"
              onClick={()=>enableStepper()}
              style={{ width: "200px" }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={selectedFinanacerFunc}
              style={{ width: "200px", marginLeft:"20px" }}
            >
              Confirm Financers
            </Button>
          </div>
        </Card>}
      {showSelectedFinancer && <SelectedFinancers {...financeProps} />}
    </>
  );
};
