import React, { useCallback, useState, useEffect } from "react";
import { ButtonStyled } from "components/Container.styled";
import { NetworkURL } from "services/BaseURLInstance";
import { useNavigate } from "react-router-dom";
import { GoLocation } from "react-icons/go";
import { ToastContainer } from "react-toastify";
import { Error, Success } from "components/notifications/responseHandle";
import styles from "./edit.module.css";
import Loader from "components/Loader/Loader";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";

import Content from "components/UI/Layout/Content";
import { EditProfileValdations } from "utils/Validations/Profile";
import cn from "classnames";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { EditProfileInfo } from "utils/Section/FormInfo";
import { getIdTokenAsync } from "utils/authUtil";
import { BsUpload } from "react-icons/bs";
import Modal from "components/UI/Modal/Modal";
import Upload from "./Upload";
import { documentURL } from "services/BaseURLInstance";
import Popover from "react-bootstrap/Popover";
import { AiOutlineInfoCircle } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const EditProfile = () => {
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  const { instance, accounts } = useMsal();
  const [companyData, setCompanyData] = useState(null);
  const [coordinate, setCoordinate] = useState({});
  const [isUpload, setIsUpload] = useState(null);
  const [documentData, setDocumentData] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const [imageData, setImageData] = useState({
    name: "",
    file: "",
  });

  const [contactNo, setContactNo] = useState({
    primaryNo: "",
    secondaryNo: "",
  });

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${NetworkURL}financers/${companyId}`;
      } else {
        URL = `${NetworkURL}companies/${companyId}`;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          let { message } = data;
          setIsLoading(false);
          if (message) {
            Error(message);
            setIsError(message);
          } else {
            setCompanyData(data);
            let id = data.companyLogoURL || data.financerLogo;
            if (data.companyLogoURL || data.financerLogo) {
              getIdTokenAsync(instance, accounts).then((response) => {
                UseRequest(
                  `${documentURL}documents/${id}`,
                  null,
                  response
                ).then((res) => {
                  let { message } = res;
                  if (message) {
                    Error(message);
                  } else {
                    setDocumentData(res);
                  }
                });
              });
            }
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (companyData) {
      setContactNo({
        ...contactNo,
        primaryNo:
          (companyData &&
            companyData.companyPhone &&
            companyData.companyPhone[0]) ||
          "",
        secondaryNo:
          (companyData &&
            companyData.companyPhone &&
            companyData.companyPhone[1]) ||
          "",
      });
    }
  }, [companyData]);

  useEffect(() => {
    let contactNum = [];
    contactNum.push(contactNo.primaryNo);
    contactNum.push(contactNo.secondaryNo);
    setCompanyData({
      ...companyData,
      companyPhone: contactNum,
    });
  }, [contactNo.primaryNo, contactNo.secondaryNo]);

  const handleChange = (e) => {
    if (e.target.name === "companyAddress") {
      let TempAddAddress = [];
      TempAddAddress.push(e.target.value);
      setCompanyData({
        ...companyData,
        companyAddress: TempAddAddress,
      });
    } else if (e.target.name === "companyPhonePrimary") {
      setContactNo({
        ...contactNo,
        primaryNo: e.target.value,
      });
    } else if (e.target.name === "companyPhoneSecondary") {
      setContactNo({
        ...contactNo,
        secondaryNo: e.target.value,
      });
    } else if (e.target.name === "companyProductsServices") {
      setCompanyData({
        ...companyData,
        [e.target.name]: [e.target.value],
      });
    } else {
      setCompanyData({
        ...companyData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleLocation = (e) => {
    e.preventDefault();
    navigator.geolocation.getCurrentPosition(function (position) {
      let cordArray = [];
      cordArray.push(position.coords.latitude);
      cordArray.push(position.coords.longitude);
      // setCompanyData({
      //   ...companyData,
      //   locationCordinate: cordArray,
      // });
      setCoordinate({
        ...coordinate,
        locationCordinate: cordArray,
      });
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    console.log("companyData 11", companyData);

    let checkValidation = EditProfileValdations(companyData);
    if (checkValidation) {
      Error(checkValidation);
      return;
    } else {
      companyData.dateOfIncorporation =
        companyData.dateOfIncorporation || new Date();
      setIsLoading(true);
      const options = {
        method: "PUT",
        body: companyData,
      };
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${NetworkURL}financers/${companyId}`;
      } else {
        URL = `${NetworkURL}companies/${companyId}`;
      }
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, options, response).then((data) => {
          setIsLoading(false);
          let { message, error } = data;
          if (message || error) {
            let display = message || "Something went wrong";
            Error(display);
            setIsError(message);
          } else {
            Success("Added Successfully");
            handleNavigate(`/profile`);
          }
        });
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Content>
          <span>Loading...</span>
          <Loader />
        </Content>
      ) : (
        <>
          <Modal
            show={isUpload}
            onHide={() => setIsUpload(false)}
            Data={
              <Upload
                imageData={imageData}
                setImageData={setImageData}
                companyData={companyData}
              />
            }
            Heading={"Upload Image"}
          />
          {companyData && !isError && (
            <div className={styles.edit}>
              <OverLayTrigger value={EditProfileInfo} />
              <form
                onSubmit={(e) => handleSave(e)}
                className={styles.formStyle}>
                <div className="loanDetailsFields">
                  <div className={styles.inputFieldView}>
                    <div className={styles.imgDiv}>
                      <img
                        className="logoimg"
                        src="https://www.jdfulwiler.com/wp-content/uploads/2017/08/abc-company-test-logo-300x205.png"
                      />

                      <span
                        onClick={() => setIsUpload(true)}
                        style={{ cursor: "pointer" }}>
                        <BsUpload size={24} />
                      </span>
                    </div>

                    <div className={styles.firstDiv}>
                      <div>
                        <label For="dealid">Enterprise Name *</label>
                        <input
                          type="text"
                          name={
                            memberType === "FINANCER"
                              ? "financerName"
                              : "companyName"
                          }
                          value={
                            memberType === "FINANCER"
                              ? companyData.financerName
                              : companyData.companyName
                          }
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div>
                        <label For="NBFCID">Country *</label>
                        <input
                          type="text"
                          value={companyData.companyCountry}
                          name="companyCountry"
                          onChange={handleChange}
                        />
                      </div>
                    </div>

                    {/* Second One */}
                    <div className={styles.SecondDiv}>
                      <div>
                        <label For="CreatedDate">Enterprise Website *</label>
                        <input
                          type="text"
                          value={companyData.companySiteURL}
                          onChange={(e) => handleChange(e)}
                          name="companySiteURL"
                        />
                      </div>

                      <div className={styles.locationDiv}>
                        <label For="LastUpdatedBy">Geo-Location *</label>
                        <div
                          className={styles.location}
                          onClick={handleLocation}>
                          <GoLocation />
                        </div>
                        <input
                          type="text"
                          // value={companyDetails.locationCordinate}
                          name="locationCordinate"
                        />
                      </div>
                    </div>

                    <div className={styles.inputDiv}>
                      <label For="CreatedBy">Enterprise Description</label>
                      <textarea
                        className="companydes"
                        type="text"
                        value={companyData.companyDescription}
                        name="companyDescription"
                        onChange={handleChange}
                      />
                    </div>

                    <div className={styles.inputDiv}>
                      <label For="CreatedBy">Products and Services*</label>
                      <textarea
                        className="companydes"
                        type="text"
                        value={companyData.companyProductsServices}
                        name="companyProductsServices"
                        onChange={handleChange}
                      />
                    </div>

                    {/* Company Contacts */}
                    <div className={styles.heading2}>
                      <span>Company Contacts</span>
                    </div>

                    <div className={styles.inputDiv}>
                      <div className={styles.inputDiv1}>
                        <div></div>
                        <div className={styles.textCenter}>WhatsApp</div>
                        <div className={styles.textCenter}>SMS</div>
                      </div>

                      <div className={styles.inputDiv1}>
                        <div>
                          <label For="LoanProductType">
                            Primary Phone No. *{" "}
                          </label>
                          <input
                            type="text"
                            value={contactNo.primaryNo}
                            name="companyPhonePrimary"
                            onChange={handleChange}
                          />
                        </div>
                        <div className={cn(styles.textCenter, styles.Checkbox)}>
                          <input type="checkbox" name="whatsapp" value="true" />
                        </div>
                        <div className={cn(styles.textCenter, styles.Checkbox)}>
                          <input type="checkbox" name="sms" value="true" />
                        </div>
                      </div>

                      <div className={styles.inputDiv1}>
                        <div>
                          <label For="LoanProductType">
                            Secondary Phone No. *{" "}
                          </label>
                          <input
                            type="text"
                            value={contactNo.secondaryNo}
                            name="companyPhoneSecondary"
                            onChange={handleChange}
                          />
                        </div>
                        <div className={cn(styles.textCenter, styles.Checkbox)}>
                          <input type="checkbox" name="whatsapp" value="true" />
                        </div>
                        <div className={cn(styles.textCenter, styles.Checkbox)}>
                          <input type="checkbox" name="sms" value="true" />
                        </div>
                      </div>
                    </div>

                    <div className={styles.firstDiv}>
                      <div>
                        <label For="dealid">Twitter Link* </label>
                        <input
                          type="text"
                          name="twitterLink"
                          value={companyData.twitterLink}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div>
                        <label For="NBFCID">Linkedin Link *</label>
                        <input
                          type="text"
                          name="linkedInLink"
                          value={companyData.linkedInLink}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div
                      className={styles.firstDiv}
                      style={{ marginTop: "33px" }}>
                      <div>
                        <label For="companySiteURL">Email *</label>
                        <input
                          type="text"
                          value={
                            memberType === "FINANCER"
                              ? companyData.financerEMail
                              : companyData.companyEMail
                          }
                          onChange={(e) => handleChange(e)}
                          name={
                            memberType === "FINANCER"
                              ? "financerEMail"
                              : "companyEMail"
                          }
                        />
                      </div>
                      <div>
                        <label For="CreatedBy">Company Address * </label>
                        <textarea
                          type="text"
                          value={companyData.companyAddress}
                          name="companyAddress"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    {/* Registration Details */}
                    <div className={styles.heading2}>
                      <span>Registration Details (General)</span>
                    </div>

                    <div className={styles.registrationDiv}>
                      <div>
                        <label For="dealid">
                          Registration Number{" "}
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            key="top"
                            placement="top"
                            overlay={
                              <Popover id="popover-positioned-top">
                                <Popover.Body>
                                  <strong>
                                    Eg. GSTIN for India , EIN/TIN for USA
                                  </strong>
                                </Popover.Body>
                              </Popover>
                            }>
                            <span className="text-warning">
                              <AiOutlineInfoCircle size={26} />
                            </span>
                          </OverlayTrigger>
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <input
                          type="text"
                          name="registrationNumber"
                          value={companyData.registrationNumber}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <label For="NBFCID">
                          Date Of Incorporation{" "}
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <input
                          type="text"
                          name="dateOfIncorporation"
                          value={
                            companyData.dateOfIncorporation
                              ? new Date(
                                  companyData.dateOfIncorporation
                                ).toLocaleDateString()
                              : new Date().toLocaleDateString()
                          }
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className={styles.registrationDiv}>
                      <div>
                        <label For="GSTIN">
                          Tax Number{" "}
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            key="top"
                            placement="top"
                            overlay={
                              <Popover id="popover-positioned-top">
                                <Popover.Body>
                                  <strong>e.g. GSTIN for India</strong>
                                </Popover.Body>
                              </Popover>
                            }>
                            <span className="text-warning">
                              <AiOutlineInfoCircle size={26} />
                            </span>
                          </OverlayTrigger>
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <input
                          type="text"
                          name="GSTIN"
                          value={companyData.GSTIN}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div>
                        <label For="dealid">SIC Code </label>
                        <input
                          type="text"
                          name="sicCode"
                          value={companyData.sicCode}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className={styles.registrationDiv}>
                      <div>
                        <label For="LEI">LEI </label>
                        <input
                          type="text"
                          name="lei"
                          value={companyData.lei}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div>
                        <label For="dunsNumber">DUNS Number</label>
                        <input
                          type="text"
                          value={companyData.dunsNumber}
                          name="dunsNumber"
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className={styles.heading2}>
                      <span>Registration Details (India)</span>
                    </div>

                    <div className={styles.registrationDiv}>
                      <div>
                        <label For="dealid">
                          PAN{/* PAN <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <input
                          type="text"
                          name="pan"
                          value={companyData.pan}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div>
                        <label For="GSTIN">TAN</label>
                        <input
                          type="text"
                          name="tan"
                          value={companyData.tan}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className={styles.registrationDiv}>
                      <div>
                        <label For="dealid">
                          UDHYAM
                          {/* UDHYAM <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <input
                          type="text"
                          name="udhyamId"
                          // value={companyData.udhyamId}
                          // onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className={styles.btnDiv}>
                      <ButtonStyled onClick={() => handleNavigate(`/profile`)}>
                        Cancel
                      </ButtonStyled>
                      <ButtonStyled type="submit">Save</ButtonStyled>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}

          {isError && <Content>{isError}</Content>}
        </>
      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default EditProfile;
