let DirectorDocument = [
  {
    docCategory: "DIRECTOR",

    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DIRECTOR",
        docSubCategory: "PAN",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DIRECTOR",
        docSubCategory: "AADHAR",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "DIRECTOR",
        docSubCategory: "Passport",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "KYC",
    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "KYC",
        docSubCategory: "KYB Report",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "ACCREDITATIONS",
    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "ACCREDITATIONS",
        docSubCategory: "ACCREDITATIONS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
  {
    docCategory: "OTHERS",
    template: "Director",
    country: "IN",
    tags: [],
    documents: [
      {
        docReferenceId: "",
        documentName: "",
        docCategory: "OTHERS",
        docSubCategory: "OTHERS",
        tags: [],
        ownerId: "",
        participantsToSign: [],
        participantsToShare: [],
      },
    ],
  },
];

export const DirectorDocumentSelect = (name) => {
  let Category = DirectorDocument.map((item) => ({
    label: item.docCategory,
    value: item.docCategory,
  }));

  const Empty = [];
  let temp = DirectorDocument.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });
  let SubCategory =
    temp &&
    temp[0] &&
    temp[0].documents.map((item) => ({
      label: item.docSubCategory,
      value: item.docSubCategory,
    }));

  if (name !== null) {
    return { Category, SubCategory };
  } else {
    return { Category, Empty };
  }
};

export const DirectorDocumentSubCatogery = (name) => {
  let temp = DirectorDocument.filter((item) => {
    if (item.docCategory === name) {
      return item;
    }
  });
  let SubCategory =
    temp &&
    temp[0] &&
    temp[0].documents.map((item) => ({
      label: item.docSubCategory,
      value: item.docSubCategory,
    }));
  return SubCategory;
};
