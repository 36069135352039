import React from "react";
import {
  SendBirdProvider as SBProvider,
  withSendBird,
  SendBirdState,
} from "sendbird-uikit";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import App from "./App";

export default function RootApp() {
  const { accounts } = useMsal();
  const APP_ID = process.env.REACT_APP_CHAT_APP_ID;
  const memberId = getClaim(accounts, "member_id");
  const chatToken = getClaim(accounts, "chat_token");

  return (
    <SBProvider appId={APP_ID} userId={memberId} accessToken={chatToken}>
      <App />
    </SBProvider>
  );
}
