import React, { memo } from "react";
import { FaDownload } from "react-icons/fa";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import pdfFile from "../../../pdf/dentist.pdf";
import styles from "./documents.module.css";
import { documentURL } from "../../../services/BaseURLInstance";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

import cn from "classnames";
import { truncateString } from "utils/helper";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import { FindCompanyName } from "utils/helper";

const DocumentsPreview = ({ data1, data2, response }) => {
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));

  const fileTypes = {
    bmp: "image/bmp",
    csv: "text/csv",
    doc: "application/msword",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    htm: "text/htm",
    html: "text/html",
    jpg: "image/jpg",
    jpeg: "image/jpeg",
    pdf: "application/pdf",
    png: "image/png",
    ppt: "application/vnd.ms-powerpoint",
    pptx: "applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation",
    tiff: "image/tiff",
    txt: "text/plain",
    xls: "application/vnd.ms-excel",
    xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  };

  const headers = {};
  if (idToken) {
    headers.Authorization = "Bearer " + idToken;
  }

  const docs = [
    {
      uri: `${documentURL}documents/${data1.id}/download`,
      fileType: fileTypes[data1.fileType.toLowerCase()],
      fileName: data1.name,
    },
  ];

  return (
    <>
      <div className={styles.flexRowJustify}>
        <div className={styles.heading1}>Document Viewer</div>
        <div>
          <FaDownload />
        </div>
      </div>

      <div className={styles.Container} style={{marginTop:"1%"}}>
        <div className={styles.Heading}>Document Details</div>

        <div className={cn(styles.Row)}>
          <div className={cn(styles.Column)}>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Name</div>
              <div>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Header as="h3">Document Name</Popover.Header>
                      <Popover.Body>
                        <strong>{data1.name}</strong>
                      </Popover.Body>
                    </Popover>
                  }>
                  <span>
                    {data1.name
                      ? truncateString(data1.name, 22)
                      : "Not Available"}
                  </span>
                </OverlayTrigger>
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Category</div>
              <div>
                {data2[0].docCategory ? data2[0].docCategory : "Not Available"}
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Sub-Category</div>
              <div>
                {data2[0].docSubCategory
                  ? data2[0].docSubCategory
                  : "Not Available"}
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Public</div>
              <div>
                {String(data1.public) ? String(data1.public) : "Not Available"}
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Document Size</div>
              <div>Not Binded</div>
            </div>
          </div>

          <div className={cn(styles.Column)}>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>UID</div>
              <div>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Header as="h3">Document UID</Popover.Header>
                      <Popover.Body>
                        <strong>{data1.id}</strong>
                      </Popover.Body>
                    </Popover>
                  }>
                  <span>
                    {data1.id ? truncateString(data1.id, 20) : "Not Available"}
                  </span>
                </OverlayTrigger>
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Version No</div>
              <div>{data1.version ? data1.version : "Not Available"}</div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Upload date</div>
              <div>Not Binded</div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Updated Date</div>
              <div>
                {data1.updatedDate
                  ? new Date(data1.updatedDate).toLocaleDateString()
                  : "Not Available"}
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Updated By</div>
              <div>
                {data1.updateBy
                  ? FindCompanyName(response && response, data1.updateBy)
                  : "Not Available"}
              </div>
            </div>
          </div>

          <div className={cn(styles.Column)}>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Owner</div>
              <div>
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key="top"
                  placement="top"
                  overlay={
                    <Popover id="popover-positioned-top">
                      <Popover.Header as="h3">Owner</Popover.Header>
                      <Popover.Body>
                        <strong>
                          {data1.owner &&
                            FindCompanyName(response && response, data1.owner)}
                        </strong>
                      </Popover.Body>
                    </Popover>
                  }>
                  <span>
                    {data1.owner
                      ? FindCompanyName(response && response, data1.owner)
                      : "Not Available"}
                  </span>
                </OverlayTrigger>
              </div>
            </div>

            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Validated</div>
              <div>{data1.validated ? data1.validated : "Not Available"}</div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Validated By</div>
              <div>
                {String(data1.validatedBy)
                  ? FindCompanyName(
                      response && response,
                      String(data1.validatedBy)
                    )
                  : "Not Available"}
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Validity Start Date</div>
              <div>
                {data1.validityStartDate
                  ? new Date(data1.validityStartDate).toLocaleDateString()
                  : "Not Available"}
              </div>
            </div>
            <div className={cn(styles.List, "d-flex justify-content-between")}>
              <div>Validity End Date</div>
              <div>
                {data1.validityEndDate
                  ? new Date(data1.validityEndDate).toLocaleDateString()
                  : "Not Available"}
              </div>
            </div>
          </div>
        </div>

        <h6>Tags</h6>
        <div className={styles.TagDiv}>
          {data2 &&
            data2[0] &&
            data2[0].tags &&
            data2[0].tags.map((tag, index) => (
              <div key={index}>
                <span>{tag}</span>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.pdfView}>
        <DocViewer
          requestHeaders={headers}
          documents={docs}
          initialActiveDocument={docs[1]}
          pluginRenderers={DocViewerRenderers}
          config={{
            noRenderer: {
              overrideComponent: ({ document, fileName }) => {
                const fileText = fileName || document?.fileType || "";
                if (fileText) {
                  return <div>no renderer for {fileText}</div>;
                }
                return <div>no renderer</div>;
              },
            },
            loadingRenderer: {
              overrideComponent: ({ document, fileName }) => {
                const fileText = fileName || document?.fileType || "";
                if (fileText) {
                  return <div>loading ({fileText})</div>;
                }
                return <div>loading</div>;
              },
            },
            csvDelimiter: ",",
            pdfZoom: {
              defaultZoom: 1.1,
              zoomJump: 0.2,
            },
          }}
        />
      </div>
    </>
  );
};
export default memo(DocumentsPreview);
