import React, { useCallback, useEffect, useState } from "react";
import Loader from "components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { profileURL } from "services/BaseURLInstance";
import Table from "./Table";
import classes from "../service.module.css";
import { useMsal } from "@azure/msal-react";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import { searchReqrequest } from "utils/Section/FormInfo";
import Content from "components/UI/Layout/Content";
import { CompanyList } from "utils/API/CompanyList.js";
import { UseRequest } from "utils/API/Request.js";
import { CheckPermission } from "utils/Constants/RBAC";
import { CgChevronDown, CgChevronUp } from "react-icons/cg";
import MultiSelect from "components/fields/MultiSelect";
import cn from "classnames";
import { getIdTokenAsync } from "utils/authUtil";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";
import { CheckCountryTitle } from "utils/helper";
import AddServiceDeal from "components/UI/AddServiceDeal/index";

export default ({ hanndleCraateGroup }) => {
  const CompanyObject = CompanyList();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [response, setResponse] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [Role, setRole] = useState(null);
  const [searchServiceRole, setSearchServiceRole] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [selected, setSelected] = useState(true);
  const [selectList, setSelectList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedCountryList, setSelectedCountryList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState(null);
  const [CategoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [CategoryID, setCategoryID] = useState([]);
  const [StatusFilterList, setStatusFilterList] = useState([]);
  const [Countries, setCountries] = useState([]);
  const [isAddServiceDeal, setIsAddServiceDeal] = useState(false);
  const [clickedServiceData, setClickedServiceData] = useState(null);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_SERVICE_URL}services/requisitions?providerMemberUID=${companyId}&consumerMemberUID=${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setIsError(message || "Something went wrong");
          } else {
            let newData = data.sort(
              (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
            );
            setResponse(newData);
            setFilteredList(newData);

            // STATUS
            const uniqueStatus = [
              ...new Set(data.map((item) => item.serviceRequisitionStatus)),
            ];
            let filterStatus =
              uniqueStatus &&
              uniqueStatus.map((item) => {
                return { label: item, value: item };
              });
            setStatusFilterList(filterStatus);

            // COUNTRY
            const uniqueCOUNTRY = [
              ...new Set(data.map((item) => item.countriesForSubscription[0])),
            ];
            let filterCountry =
              uniqueCOUNTRY &&
              uniqueCOUNTRY.map((item) => {
                return { label: CheckCountryTitle(item), value: item };
              });
            setCountries(filterCountry);

            // Categories
            const uniqueCategory = [
              ...new Set(data.map((item) => item.serviceCategory[0])),
            ];
            let filterCategory =
              uniqueCategory &&
              uniqueCategory.map((item) => {
                return { label: item, value: item };
              });
            setCategoryList(filterCategory);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${profileURL}Persons/${response.idTokenClaims.member_id}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message || "Something Went Wrong");
            setIsError(message);
          } else {
            setRole(data.role);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    if (filteredList) {
      const uniqueSubCategory = [
        ...new Set(filteredList.map((item) => item.serviceSubCategory[0])),
      ];

      let filterSubCategory =
        uniqueSubCategory &&
        uniqueSubCategory.map((item) => {
          return { label: item, value: item };
        });
      setSubCategoryList(filterSubCategory);
    }
  }, [filteredList]);

  useEffect(() => {
    if (Role) {
      setSearchServiceRole(
        CheckPermission(
          "ServiceRequisitionView",
          "ServicesRequest",
          "ServiceRequisitionView",
          Role
        )
      );
    }
  }, [Role]);

  const handleNavigateInvoice = (id) => {
    if (id) {
      const valid = true;
      handleNavigate(`../service-requests/${id}`);
    } else {
      Error("Something went wrong");
    }
  };

  const handleMultiChange = (e, name) => {
    if (name === "Status") {
      const { value } = e.target;
      setSelectList(value);
    }

    if (name === "Countries") {
      const { value } = e.target;
      setSelectedCountryList(value);
      let countryFilter = Countries.filter(
        (country) => country.label === e.target.value
      );
    }
    if (name === "subCategory") {
      const { value } = e.target;
      setSelectedSubCategory(value);
    }

    if (name === "Category") {
      const { value } = e.target;
      setSelectedCategory(value);
    }
  };

  useEffect(() => {
    if (selectList) {
      let temporaryList = [];
      selectList.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedId(temporaryList);
    }

    if (selectedCountryList) {
      let temporaryList = [];
      selectedCountryList.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedCountry(temporaryList);
    }
    if (selectedSubCategory) {
      let temporaryList = [];
      selectedSubCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setSelectedSubCategoryId(temporaryList);
    }

    if (selectedCategory) {
      let temporaryList = [];
      selectedCategory.map((select) => {
        temporaryList.push(select.value);
      });
      setCategoryID(temporaryList);
    }
  }, [selectList, selectedSubCategory, selectedCountryList, selectedCategory]);

  useEffect(() => {
    if (
      (selectedId && selectedId.length > 0) ||
      (selectedCountry && selectedCountry.length > 0) ||
      (selectedSubCategoryId && selectedSubCategoryId.length > 0) ||
      (CategoryID && CategoryID.length > 0)
    ) {
      let filtered =
        response &&
        response.filter(
          (data) =>
            (selectedId.length > 0
              ? selectedId.includes(data.serviceRequisitionStatus)
              : data) &&
            (selectedCountry.length > 0
              ? selectedCountry.includes(data.countriesForSubscription[0])
              : data) &&
            (CategoryID.length > 0
              ? CategoryID.includes(data.serviceCategory[0])
              : data) &&
            (selectedSubCategoryId.length > 0
              ? selectedSubCategoryId.includes(data.serviceSubCategory[0])
              : data)
        );
      setFilteredList(filtered);
    } else if (
      selectedId.length === 0 &&
      selectedSubCategoryId.length === 0 &&
      selectedCountry.length === 0 &&
      CategoryID.length === 0
    ) {
      setFilteredList(response);
    }
  }, [selectedId, selectedSubCategoryId, selectedCountry, CategoryID]);

  return (
    <>
      <>
        {isLoading || CompanyObject.isLoading ? (
          <Content marginBottom="0%">
            Loading...
            <Loader />
          </Content>
        ) : (
          <>
            {isAddServiceDeal ? (
              <AddServiceDeal
                setIsAddServiceDeal={setIsAddServiceDeal}
                clickedServiceData={clickedServiceData}
              />
            ) : (
              <>
                {filteredList && CompanyObject && CompanyObject.response && (
                  <>
                    <div
                      className={classes.toogleTab}
                      style={{ padding: "0.6% 2% 1.9% 2%" }}>
                      <div
                        className={classes.toogleTitle}
                        onClick={() => setSelected(!selected)}
                        style={{ margin: "8px 0px" }}>
                        <span style={{ fontSize: "18px" }}>Search Results</span>
                        <span>
                          {selected ? (
                            <CgChevronUp size={30} />
                          ) : (
                            <CgChevronDown size={30} />
                          )}
                        </span>
                      </div>

                      <div className={selected ? "show" : "description"}>
                        <div
                          className={cn(
                            classes.SearchDiv,
                            "d-flex justify-content-between align-items-center"
                          )}>
                          <div
                            style={{ marginLeft: "2px", marginRight: "2px" }}>
                            <MultiSelect
                              name="Status"
                              selected={selectList}
                              placeholder="Status"
                              options={StatusFilterList}
                              handleChange={(e) =>
                                handleMultiChange(e, "Status")
                              }
                            />
                          </div>
                          <div
                            style={{ marginLeft: "2px", marginRight: "2px" }}>
                            <MultiSelect
                              disabled={
                                searchServiceRole === "DELETE" ||
                                searchServiceRole === "NOTAPP"
                                  ? true
                                  : false
                              }
                              name="Countries"
                              options={Countries}
                              selected={selectedCountryList}
                              placeholder="Country"
                              handleChange={(e) =>
                                handleMultiChange(e, "Countries")
                              }
                            />
                          </div>

                          <div
                            style={{ marginLeft: "2px", marginRight: "2px" }}>
                            <MultiSelect
                              disabled={
                                searchServiceRole === "DELETE" ||
                                searchServiceRole === "NOTAPP"
                                  ? true
                                  : false
                              }
                              name="category"
                              options={
                                CategoryList &&
                                CategoryList.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )
                              }
                              selected={selectedCategory}
                              placeholder="Category"
                              handleChange={(e) =>
                                handleMultiChange(e, "Category")
                              }
                            />
                          </div>
                          <div
                            style={{ marginLeft: "2px", marginRight: "2px" }}>
                            <MultiSelect
                              name="subCategory"
                              options={
                                subCategoryList &&
                                subCategoryList.sort((a, b) =>
                                  a.label.localeCompare(b.label)
                                )
                              }
                              selected={selectedSubCategory}
                              placeholder="Sub-Category"
                              handleChange={(e) =>
                                handleMultiChange(e, "subCategory")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Content padding="1% 2% 1% 2%">
                      <div>
                        <OverLayTrigger value={searchReqrequest} />
                        <Table
                          data={filteredList}
                          hanndleCraateGroup={hanndleCraateGroup}
                          companyList={CompanyObject && CompanyObject.response}
                          handleNavigateInvoice={handleNavigateInvoice}
                          setIsAddServiceDeal={setIsAddServiceDeal}
                          setClickedServiceData={setClickedServiceData}
                        />
                      </div>
                    </Content>
                  </>
                )}
              </>
            )}

            {(isError || (CompanyObject && CompanyObject.isError)) && (
              <Content marginBottom="0%">
                <div className="alert alert-danger" role="alert">
                  {CompanyObject.isError || isError}
                </div>
              </Content>
            )}
          </>
        )}
      </>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
