import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import classes from "pages/Services/New-Service/Category/freight.module.css";

import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";
import SelectWithSearch from "components/fields/SelectWithSearch";

const Crediasses = ({ response }) => {
  const [serviceDetails, setServiceDetails] = useState([]);

  let Factors = [
    {
      label: "Financial health and stability",
      value: "Financial health and stability",
    },
    {
      label: "Credit ratings",
      value: "Credit ratings",
    },
    {
      label: "Payment history",
      value: "Payment history",
    },
    {
      label: " Industry Risk and analysis",
      value: " Industry Risk and analysis",
    },
    {
      label: " Management team",
      value: " Management team",
    },
    {
      label: " Market position",
      value: " Market position",
    },
    {
      label: " Legal and regulatory environment ",
      value: " Legal and regulatory environment ",
    },
  ];
  useEffect(() => {
    if (response && response.ServiceReqDetails) {
      setServiceDetails(JSON.parse(response.ServiceReqDetails));
    }
  }, [response]);
  console.log(serviceDetails, "hel");

  return (
    <>
      {response && serviceDetails ? (
        <>
          <Content>
            {" "}
            <div style={{ margin: "10px" }}>CREDIT ASSESMENT</div>
            <div style={{ margin: "10px" }}>
              Credit Assesment Services Required for ?
            </div>
            <div className={classes.source} style={{ margin: "10px" }}>
              <div
                style={{
                  marginLeft: "10px",
                  width: "50%",
                  marginRight: "10px",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType"
                  value="Existing"
                  // onChange={handleRadio}
                  checked={serviceDetails.serviceType == "Existing"}
                  readOnly={true}
                />
                <label>Existing Participant</label>
              </div>{" "}
              <div
                style={{
                  // fontSize: "25px",
                  marginLeft: "10px",
                  width: "50%",
                  textAlign: "right",
                  marginRight: "10px",
                }}
              >
                <input
                  className={classes.radio}
                  type="radio"
                  name="serviceType"
                  value="New"
                  // onChange={handleRadio}
                  checked={serviceDetails.serviceType == "New"}
                  readOnly={true}
                />
                <label>New Participant</label>
              </div>
            </div>
            <div>
              {serviceDetails.serviceType === "Existing" ?
              ( <>
              <div
                style={{ width: "100%", marginBottom: "1%", marginLeft: "1%" }}
              >
                <Input
                  style={{ height: "7rem", width: "100%" }}
                  name="companyName"
                  placeholder=" Company Name "
                  //  handleChange={handleChange}
                  value={serviceDetails.companyName}
                  readOnly={true}
                />
              </div>
              <div
                style={{ width: "100%", marginBottom: "1%", marginLeft: "1%" }}
              >
                <Input
                  style={{ height: "7rem", width: "100%" }}
                  name="contactInfo"
                  placeholder=" Company Contact Information "
                  // handleChange={handleChange}
                  value={serviceDetails.contactInfo}
                  readOnly={true}
                />
              </div>
              <div
                style={{ width: "100%", marginBottom: "1%", marginLeft: "1%" }}
              >
                <Input
                  style={{ height: "7rem", width: "100%" }}
                  name="address"
                  placeholder=" Company Address "
                  //  handleChange={handleChange}
                  value={serviceDetails.address}
                  readOnly={true}
                />
              </div>
              </>
              ) : 
               (
                <>
                <div
                  style={{ width: "100%", marginBottom: "1%", marginLeft: "1%" }}
                >
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="companyName"
                    placeholder=" Company Name "
                    //  handleChange={handleChange}
                    value={serviceDetails.newCompanyName}
                    readOnly={true}
                  />
                </div>
                <div
                  style={{ width: "100%", marginBottom: "1%", marginLeft: "1%" }}
                >
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="contactInfo"
                    placeholder=" Company Contact Information "
                    // handleChange={handleChange}
                    value={serviceDetails.newContactInfo}
                    readOnly={true}
                  />
                </div>
                <div
                  style={{ width: "100%", marginBottom: "1%", marginLeft: "1%" }}
                >
                  <Input
                    style={{ height: "7rem", width: "100%" }}
                    name="address"
                    placeholder=" Company Address "
                    //  handleChange={handleChange}
                    value={serviceDetails.newAddress}
                    readOnly={true}
                  />
                </div>
                </>
                )}
            </div>
            <div style={{ marginLeft: "1%", marginTop: "1%" }}>
              Credit Assesment should cover what all factors ?
            </div>
            <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
              <MultiSelect
                options={Factors}
                name="Factors"
                placeholder="Factors*"
                //  handleChange={(e) => handleMultiChange(e, "Factors")}
                selected={serviceDetails.Factors}
                readOnly={true}
              />
            </div>
            <div style={{ margin: "10px" }}>
              <label>
                Give your own Credit Assesment coverage specific coverage
                Requirements
              </label>
            </div>
            <div
              style={{ width: "100%", margin: "10px" }}
            >
              <TextArea
                style={{ height: "7rem", width: "100%" }}
                name="requirement"
                placeholder=" Requirement "
                //  handleChange={handleChange}
                value={serviceDetails.requirement}
                readOnly={true}
              />
            </div>
          </Content>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default Crediasses;
