import React, { useState, useEffect, useCallback } from "react";
import { consentAction, signAction } from "api/Documents/consent";
import Loader from "components/Loader/Loader";
import { NetworkURL } from "services/BaseURLInstance";
import Content from "components/UI/Layout/Content";
import { FindCompanyName, FormattedDateTime } from "utils/helper";
import cn from "classnames";
import classes from "../documentNew.module.css";
import Table from "react-bootstrap/Table";
import { ConsentHeader } from "utils/Section/Document";
import Button from "components/UI/Button/Button";
import { useNavigate } from "react-router-dom";
import { UseRequest } from "utils/API/Request.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { BsArrowLeft } from "react-icons/bs";
import { Success, Error } from "components/notifications/responseHandle";
import STATUS from "utils/Constants/Status";

export default () => {
  const Token = JSON.parse(sessionStorage.getItem("idToken"));
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const handleNavigate = useCallback((url) => navigate(url), []);
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [allData, setAllData] = useState([]);
  const [CompanyList, setCompanyList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [shareRequest, setShareRequest] = useState(null);
  const [signRequest, setSignRequest] = useState(null);
  const [signTPRequest, setSignTPRequest] = useState(null);
  const [isError, setIsError] = useState(null);
  const [isTrigger, setTrigger] = useState(false);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(`${NetworkURL}members?memberId=${companyId}`, null, response)
          .then((data) => {
            setCompanyList(data);
          })
          .catch((error) => {
            setIsLoading(false);
            setIsError("Something went wrong");
          });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_DOCUMENT_URL}documents/consent-requests`,
          null,
          response
        )
          .then((data) => {
            const shareRequestWithType = data?.map((item) => ({
              ...item,
              type: "SHARE_REQUEST",
              createdDate: item.shareRequestDate,
            }));
            setShareRequest(shareRequestWithType);
          })
          .catch((error) => {
            setIsLoading(false);
            setIsError("Something went wrong");
          });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_DOCUMENT_URL}documents/sign-requests`,
          null,
          response
        )
          .then((data) => {
            const signRequestWithType = data?.map((item) => ({
              ...item,
              type: "SIGN_REQUEST",
              createdDate: item.signRequestDate,
            }));
            setSignRequest(signRequestWithType);
          })
          .catch((error) => {
            setIsLoading(false);
            setIsError("Something went wrong");
          });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_DOCUMENT_URL}documents/sign-requests/consents`,
          null,
          response
        )
          .then((data) => {
            const signTPRequestWithType = data?.map((item) => ({
              ...item,
              type: "SIGN_TP_REQUEST",
              createdDate: item.signRequestDate,
            }));
            setSignTPRequest(signTPRequestWithType);
          })
          .catch((error) => {
            setIsLoading(false);
            setIsError("Something went wrong");
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [isTrigger]);

  const handleDocumentPreview = (e, id) => {
    e.preventDefault();
    if (id === undefined) {
      alert("Not available");
    } else {
      handleNavigate(`/documents/preview/${id}`);
    }
  };

  const handleSign = async (data, actionType) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        approved: actionType === "APPROVE" ? true : false,
      }),
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + Token,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOCUMENT_URL}documents/sign-requests/${data.requestId}`,
        requestOptions
      );

      if (response.status === 200) {
        Success("Signed successfully");
        setTrigger(!isTrigger);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      Error();
    }
  };

  const handleShareApproval = async (data, actionType) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        approved: actionType === "APPROVE" ? true : false,
        receiverMemberUUID: data.receiverMemberUUID,
        requesterMemberUUID: data.requesterMemberUUID,
      }),
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + Token,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOCUMENT_URL}documents/consent-requests/${data.requestId}`,
        requestOptions
      );

      if (response.status === 200) {
        Success("Shared successfully");
        setTrigger(!isTrigger);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      Error();
    }
  };

  const handleTPSign = async (data, actionType) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        approved: actionType === "APPROVE" ? true : false,
      }),
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + Token,
        "Content-Type": "application/json",
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DOCUMENT_URL}documents/sign-requests/consents/${data.requestId}`,
        requestOptions
      );

      if (response.status === 200) {
        Success("Third party signed successfully");
        setTrigger(!isTrigger);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      Error();
    }
  };

  const CheckType = (expression, actionType, data) => {
    switch (expression) {
      case "SHARE_REQUEST":
        handleShareApproval(data, actionType);
        break;
      case "SIGN_REQUEST":
        handleSign(data, actionType);
        break;
      case "SIGN_TP_REQUEST":
        handleTPSign(data, actionType);
        break;
      default:
    }
  };

  const Status = (status) => {
    switch (status) {
      case "Approved":
        return STATUS(status, "success");
      case "Pending":
        return STATUS(status, "warning");
      case "Declined":
        return STATUS(status, "danger");
    }
  };

  const CategoryStatus = (status) => {
    switch (status) {
      case "SHARE_REQUEST":
        return (
          <div className={"py-1 px-1"} style={{ backgroundColor: "#053B50" }}>
            {status}
          </div>
        );
      case "SIGN_REQUEST":
        return (
          <div className={"py-1 px-1"} style={{ backgroundColor: "#176B87" }}>
            {status}
          </div>
        );
      case "SIGN_TP_REQUEST":
        return (
          <div className={"py-1 px-1"} style={{ backgroundColor: "#64CCC5" }}>
            {status}
          </div>
        );
    }
  };

  // Merge the three arrays and sort based on shareRequestDate
  useEffect(() => {
    const mergedAndSortedData = [
      ...(shareRequest || []),
      ...(signRequest || []),
      ...(signTPRequest || []),
    ].sort((a, b) => {
      const dateA = a.createdDate ? Date.parse(a.createdDate) : null;
      const dateB = b.createdDate ? Date.parse(b.createdDate) : null;

      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return 1;
      if (dateB === null) return -1;

      return dateB - dateA;
    });
    setAllData(mergedAndSortedData);
  }, [shareRequest, signRequest, signTPRequest]);

  return (
    <>
      {isLoading ? (
        <Content>
          Loading...
          <Loader />
        </Content>
      ) : (
        <>
          {isError ? (
            <div>{isError}</div>
          ) : (
            <>
              <div
                style={{
                  color: "rgb(66, 165, 245)",
                  cursor: "pointer",
                  marginBottom: "1%",
                }}
                onClick={() => navigate(-1)}>
                <span style={{ marginRight: "2px" }}>
                  <BsArrowLeft />
                </span>
                <span>Back To Documents</span>
              </div>
              <Content>
                <h5 style={{ marginBottom: "20px" }}>
                  Consent and Digital Signature Request
                </h5>
                <Table bordered responsive className={cn(classes.Table)}>
                  <thead>
                    <tr>
                      {ConsentHeader.map((header, i) => (
                        <td key={i}>{header.name}</td>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {allData &&
                      allData.map((data, index) => (
                        <tr key={index}>
                          <td
                            onClick={(e) =>
                              handleDocumentPreview(e, data.docId)
                            }>
                            Document Link
                          </td>
                          <td>
                            {CompanyList && data.receiver
                              ? FindCompanyName(CompanyList, data.owner)
                              : data.validatorMemberUUID
                              ? FindCompanyName(
                                  CompanyList,
                                  data.validatorMemberUUID
                                )
                              : "-"}
                          </td>
                          <td>
                            {CompanyList && data.requesterMemberUUID
                              ? FindCompanyName(
                                  CompanyList,
                                  data.requesterMemberUUID
                                )
                              : FindCompanyName(
                                  CompanyList,
                                  data.validatorMemberUUID
                                  /* To-Do - Consider changing to correct field for Requestor as it can be owner too*/
                                )}
                          </td>
                          <td>
                            {CompanyList && data.owner
                              ? FindCompanyName(CompanyList, data.owner)
                              : "-"}
                          </td>
                          <td>{Status(data.status)}</td>
                          <td>
                            {data.signRequestDate
                              ? FormattedDateTime(data.signRequestDate)
                              : FormattedDateTime(data.shareRequestDate)}
                          </td>
                          <td>{CategoryStatus(data.type)}</td>
                          <td>
                            {data.type === "SHARE_REQUEST" ? (
                              // Case of handling Share Approval Request
                              <>
                                {data.status === "Pending" &&
                                  data.owner === companyId && (
                                    <div className="d-flex justify-content-center">
                                      <Button
                                        onClick={() =>
                                          CheckType(data.type, "APPROVE", data)
                                        }>
                                        Approve
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          CheckType(data.type, "REJECT", data)
                                        }>
                                        Reject
                                      </Button>
                                    </div>
                                  )}
                              </>
                            ) : (
                              <>
                                {data.type === "SIGN_REQUEST" ? (
                                  <>
                                    {data.status === "Pending" &&
                                      data.validatorMemberUUID ===
                                        companyId && (
                                        <div className="d-flex justify-content-center">
                                          <Button
                                            onClick={() =>
                                              // Handle Response of Second Party Signature Approval Request
                                              CheckType(
                                                data.type,
                                                "APPROVE",
                                                data
                                              )
                                            }>
                                            Approve
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              CheckType(
                                                data.type,
                                                "REJECT",
                                                data
                                              )
                                            }>
                                            Reject
                                          </Button>
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    {/* // Handle Response of Second Party Signature Approval Request type === SIGN_TP_REQUEST  */}
                                    {data.status === "Pending" &&
                                      data.owner === companyId && (
                                        <div className="d-flex justify-content-center">
                                          <Button
                                            onClick={() =>
                                              CheckType(
                                                data.type,
                                                "APPROVE",
                                                data
                                              )
                                            }>
                                            Approve
                                          </Button>
                                          <Button
                                            onClick={() =>
                                              CheckType(
                                                data.type,
                                                "REJECT",
                                                data
                                              )
                                            }>
                                            Reject
                                          </Button>
                                        </div>
                                      )}
                                  </>
                                )}
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Content>
            </>
          )}
        </>
      )}
    </>
  );
};
