import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { NetworkURL } from "services/BaseURLInstance";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";
import ServiceDeal from "components/UI/InvoiceSection/Deals/ServiceDeal";
import ChangeState from "components/UI/InvoiceSection/Deals/ChangeState";
import Documents from "pages/Document/index";
// import History from "../../Invoicefinancing/uploadInvoice/Deals/history";
import History from "components/History/history";
import Loader from "components/Loader/Loader";
import { CompanyList } from "utils/API/CompanyList.js";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { Error } from "components/notifications/responseHandle";

import DocumentIndex from "../New-ServiceRequest/documentSection/documentIndex";

const serviceDealHeader = [
  {
    name: "Service Deal",
    flex: "flex-1",
  },
  {
    name: "Consumer",
    flex: "flex-1",
  },
  {
    name: "Provider",
    flex: "flex-1",
  },
  {
    name: "Offered Max Rate",
    flex: "flex-1",
  },
  {
    name: "Status",
    flex: "flex-1",
  },
  {
    name: "Last Activity Date",
    flex: "flex-1",
  },
  {
    name: "Action",
    flex: "flex-1",
  },
];

const Deal = ({hanndleCraateGroup}) => {
  const { instance, accounts } = useMsal();
  let { id } = useParams();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const [isActive, setIsActive] = useState("Service Deals");
  const [serviceDealData, setServiceDealData] = useState(null);
  const [companyList, setCompanyList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [Component, setIsComponent] = useState(null);
  const [filteredList, setFilteredList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [response, setResponse] = useState(null);
  const CompanyObject = CompanyList();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      let URL = `${process.env.REACT_APP_SERVICE_URL}services/deals?providerMemberUID=&consumerMemberUID=&serviceId=${id}&status=`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response).then((data) => {
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message || "Something went wrong try again....");
          } else {
            setServiceDealData(data);
          }
        });
      });
    }

    if (!isCancelled) {
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${NetworkURL}members?memberId=${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, path } = data;
          if (message || path) {
            Error(message);
            setIsError(message || "Something went wrong try again....");
          } else {
            setCompanyList(data);
          }
        });
      });
    }
    console.log("serviceDealData", serviceDealData);
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      setIsLoading(true);
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(
          `${process.env.REACT_APP_SERVICE_URL}services/requisitions?providerMemberUID=${companyId}&consumerMemberUID=${companyId}`,
          null,
          response
        ).then((data) => {
          setIsLoading(false);
          let { message, status } = data;
          if (message || status) {
            Error(message);
            setIsError(message || "Something went wrong");
          } else {
            let newData = data.sort(
              (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
            );
            setResponse(newData);
            setFilteredList(newData);

            const uniqueCategory = [
              ...new Set(data.map((item) => item.serviceCategory[0])),
            ];

            let filterCategory =
              uniqueCategory &&
              uniqueCategory.map((item) => {
                return { label: item, value: item };
              });
            setCategoryList(filterCategory);
          }
        });
      });
    }
    return () => {
      isCancelled = true;
    };
  }, []);

  const Navbar = [
    {
      title: "Service Deals",
      description: (
        <ServiceDeal
          header={serviceDealHeader}
          data={serviceDealData}
          companyList={companyList}
        />
      ),
    },
    // {
    //   title: "Change State",
    //   description: <ChangeState />,
    // },
    {
      title: "Documents",
      // description: <Documents serviceId={id} />,
      description: <DocumentIndex serviceId={id} response={serviceDealData} />,
    },
    {
      title: "History",
      description: <History dealstatus="SERVICE DEAL STATUS" screen="SERVICES" id={id} companyList={companyList} />,
    },
  ];

  const handleChange = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };

  return (
    <>
      {isLoading ? (
        <div>
          <span>Loading....</span> <Loader />
        </div>
      ) : (
        <>
          {serviceDealData && companyList ? (
            <>
              <ToogleNavbar
                Navbar={Navbar}
                isActive={isActive}
                handleChange={handleChange}
                handleComponent={handleComponent}
              />
              <div className="my-4">
                {Component === null ? (
                  <ServiceDeal
                    header={serviceDealHeader}
                    data={serviceDealData}
                    companyList={companyList}
                    hanndleCraateGroup = {hanndleCraateGroup}
                    chatData={filteredList}
                    compist={CompanyObject && CompanyObject.response}
                  />
                ) : (
                  Component
                )}
              </div>
            </>
          ) : (
            <div className="alert alert-danger" role="alert">
              {isError}
            </div>
          )}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Deal;
