import React from 'react';
import { useEffect } from 'react';
import './paymentCollection.css';
import { getPaymentsByLoanId } from 'api/Payments';
import { useState } from 'react';
import { FindCompanyName } from "utils/helper";
import { truncateStringID } from "utils/helper";
import Loader from 'components/Loader/Loader';
import { PaymentCollection } from './paymentCollection';

export const PaymentsSummary = ({ response, loanId, companyList, memberId, OpenTradeAccountDetails }) => {
    const scheduleData = JSON.parse(sessionStorage.getItem("scheduleData"));
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const [paymentDetail, setPaymentDetails] = useState();
    const [isLoading,setIsLoading] = useState(false);
    useEffect(() => {
        if(memberId)
        {
            setIsLoading(true);
            getPaymentsByLoanId(loanId || scheduleData.openTradeScheduleUID, idToken, memberId)
            .then((res) => {
                setIsLoading(false);
                let dateArray = [];
                res.length > 0 && res.forEach(item => {
                    dateArray.push(new Date(item.updatedDate));
                })
                dateArray.sort((date1, date2) => date1 - date2);
                const latestDate = new Date(dateArray[dateArray.length - 1]).toISOString();
                let newRes = [];
                newRes = res.length > 0 && res.filter((item) => {
                    return new Date(item.updatedDate).toISOString() == latestDate;
                })
                setPaymentDetails(newRes[0]);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log("paymentByLoan error", error)
            });
        }
    }, [memberId])
    const collectAmoount = paymentDetail?.paymentAmount && paymentDetail?.paymentFee ? 
    parseFloat(paymentDetail?.paymentAmount) + parseFloat(paymentDetail?.paymentFee) : 
    paymentDetail?.paymentAmount ? 
    parseFloat(paymentDetail?.paymentAmount):
    paymentDetail?.paymentFee  ? 
    parseFloat(paymentDetail?.paymentFee):
    "NA";
    return (
        <>
            {  isLoading ? <Loader/> :
             <div className='panel'>
                    {paymentDetail ?
                    <div style={{ backgroundColor: "black", width: "100%", border: "1px solid white", padding:"20px", paddingTop:'10px'}}>
                        <div>
                        <div style={{marginTop:"20px", marginBottom:"10px", fontSize:"14px"}}>Payment Transaction Details</div>
                            <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Payment Type</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{ paymentDetail?.paymentType? paymentDetail?.paymentType: paymentDetail?.paymentServiceProvider ? paymentDetail.paymentServiceProvider : 'NA'}</div>
                                </div>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Payment Status</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{paymentDetail?.paymentStatus? paymentDetail?.paymentStatus: 'NA'}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Transaction Type</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{paymentDetail?.txnType? paymentDetail?.txnType: 'NA'}</div>
                                </div>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Payment Provider Transaction No.</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{paymentDetail?.txnNumber? paymentDetail?.txnNumber: 'NA'}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Transaction Reference Id</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>
                                    {paymentDetail?.paymentId
                                      ? truncateStringID(paymentDetail.paymentId, 7, "TR")
                                        : "NA"}</div>
                                </div>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Initiated By</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{companyList &&
                                          FindCompanyName(companyList, paymentDetail?.senderMemberID ? paymentDetail?.senderMemberID: 'NA')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Seller</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>
                                    {companyList &&
                                          FindCompanyName(companyList, paymentDetail?.receiverMemberID? paymentDetail?.receiverMemberID:'NA')}</div>
                                </div>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Buyer</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>
                                    {companyList &&
                                          FindCompanyName(companyList, paymentDetail?.senderMemberID? paymentDetail?.senderMemberID: 'NA')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Currency</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{paymentDetail?.currencyReceiver?paymentDetail?.currencyReceiver:"NA"}</div>
                                </div>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Amount</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{paymentDetail?.paymentAmount? paymentDetail?.paymentAmount: 'NA'}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Collect Amount</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{collectAmoount}</div>
                                </div>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Disburse Amount</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>NA</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Fee Tier Percentage</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>100%</div>
                                </div>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Fee Amount</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{paymentDetail?.paymentFee? paymentDetail?.paymentFee: 'NA'}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div style={{ display: "flex", alignItems: "center", width: "98%" }}>
                                <div className='overviewListItem'>
                                    <div className='overViewListFirstTile'>Fee Paid By</div>
                                    <div className='colon'>:</div>
                                    <div className='overViewListSecondTile'>{companyList &&
                                          FindCompanyName(companyList, paymentDetail?.senderMemberID? paymentDetail?.senderMemberID: 'NA')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className='panelHeading' style={{marginTop:"20px",border:"1px solid white", padding:"20px", paddingTop:'10px', marginTop:"10px", fontSize:"11px"}} >
                        <div style={{marginTop:"10px", marginBottom:"10px", fontSize:"14px"}}>Payment Transaction Details</div>
                        Payment not initiated
                        </div>
                    }
                    <PaymentCollection response={scheduleData} loanId={loanId || scheduleData.openTradeScheduleUID} companyList={companyList} memberId={memberId} response2={OpenTradeAccountDetails} />
                </div>
            }
        </>
    )
}