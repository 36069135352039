import React, { useState } from "react";
import classes from "./freight.module.css";
import Container from "components/UI/Layout/Container";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import { Countries } from "utils/DropDown/Country.js";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import OverLayTrigger from "components/UI/Toast/OverLayTrigger";
import {Error} from "components/notifications/responseHandle";

import { WAREHOUSE } from "utils/Section/FormInfo";
import SelectWithSearch from "components/fields/SelectWithSearch";
const Warehousing = ({
  warehouseData,
  setWarehouseData,
  warehouseCargoDataList,
  setWarehouseCargoDataList,
  setIsWhAdded,
  isWhAdded,
  addWhCargo,
  setAddWhCargo,
  isWhHazardous,
  setisWhHazardous,
}) => {
  const [warehouseCargoData, setWarehouseCargoData] = useState({
    quantity: "",
    qtyUnit: "",
    areaRequired: "",
    grossWeightPerContainer: "",
    measureUnit: "",
  });

  //   let sourceOptions = [
  //     {
  //       label: "PORT",
  //       value: "PORT",
  //     },
  //     {
  //       label: "ISD",
  //       value: "ISD",
  //     },
  //     {
  //       label: "FACTORY",
  //       value: "FACTORY",
  //     },
  //     {
  //       label: "CFS",
  //       value: "CFS",
  //     },
  //   ];

  //   let destinationOptions = [
  //     {
  //       label: "PORT",
  //       value: "PORT",
  //     },
  //     {
  //       label: "ISD",
  //       value: "ISD",
  //     },
  //     {
  //       label: "FACTORY",
  //       value: "FACTORY",
  //     },
  //     {
  //       label: "CFS",
  //       value: "CFS",
  //     },
  //   ];

  //   let containerTypeOptions = [
  //     {
  //       label: "20IN_STANDARD_CONTAINER",
  //       value: "20IN_STANDARD_CONTAINER",
  //     },
  //     {
  //       label: "40IN_STANDARD_CONTAINER",
  //       value: "40IN_STANDARD_CONTAINER",
  //     },
  //     {
  //       label: "40_HIGH_CUBE_STANDARD_CONTAINER",
  //       value: "40_HIGH_CUBE_STANDARD_CONTAINER",
  //     },
  //     {
  //       label: "OTHERS",
  //       value: "OTHERS",
  //     },
  //   ];

  let quantityUnitOptions = [
    {
      label: "PALLETS",
      value: "PALLETS",
    },
    {
      label: "BOXES",
      value: "BOXES",
    },
  ];

  let measureUnitOptions = [
    {
      label: "Metric Ton",
      value: "Metric Ton",
    },
    {
      label: "KG",
      value: "KG",
    },
  ];
  let cargoHazardousOptions = [
    {
      label: "EXPLOSIVES",
      value: "EXPLOSIVES",
    },
    {
      label: "GASES",
      value: "GASES",
    },
    {
      label: "FLAMMABLE_SOLIDS",
      value: "FLAMMABLE_SOLIDS",
    },
    {
      label: "OXIDIZERS_ORGANIC_PEROXIDES",
      value: "OXIDIZERS_ORGANIC_PEROXIDES",
    },
    {
      label: "TOXIC INFECTIOUS",
      value: "TOXIC_INFECTIOUS",
    },
    {
      label: "RADIOACTIVE",
      value: "RADIOACTIVE",
    },
    {
      label: "CORROSIVES",
      value: "CORROSIVES",
    },
    {
      label: "MISCELLANEOUS_HAZARDOUS",
      value: "MISCELLANEOUS_HAZARDOUS",
    },
    {
      label: "NOT_APPLICABLE",
      value: "NOT_APPLICABLE",
    },
  ];

  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");

  const LoadHeader = [
    { name: "QTY" },
    { name: "Quantity Unit" },
    { name: "Area Required (sq ft)" },
    { name: "Gross Weight Per Container" },
    { name: "Measure Unit" },
  ];

  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setWarehouseData({
      ...warehouseData,
      [name]: value,
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  const handleCargo = (event) => {
    setAddWhCargo(true);
  };

  const handleAddLoad = (e) => {
    if(warehouseCargoData.quantity ===""){
      Error("Please enter QTY");
      return;
    } if(warehouseCargoData.qtyUnit ===""){
      Error("Please enter Quantity Unit");
      return;
    } if(warehouseCargoData.areaRequired ===""){
      Error("Please enter Area Required");
      return;
    } if(warehouseCargoData.grossWeightPerContainer ===""){
      Error("Please enter Gross Weight Per Container");
      return;
    } if(warehouseCargoData.measureUnit ===""){
      Error("Please enter Measure Unit");
      return;
    }
    e.preventDefault();
    setWarehouseCargoDataList([...warehouseCargoDataList, warehouseCargoData]);
    setAddWhCargo(false);
    setWarehouseCargoData({
      quantity: "",
      qtyUnit: "",
      areaRequired: "",
      grossWeightPerContainer: "",
      measureUnit: "",
    });
  };

  const handleChange = (e, date) => {
    if (date === "CARGO_READY" || date === "ESTIMATED_STORAGE_DATE") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "CARGO_READY") {
        setDatePicker1(newDate1);
        // setWarehouseData({
        //   ...warehouseData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "ESTIMATED_STORAGE_DATE") {
        setDatePicker2(newDate1);
        // setWarehouseData({
        //   ...warehouseData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setWarehouseData({
        ...warehouseData,
        [date]: dateString,
      });
    } else if (e.target.name === "CARGO_READY") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ESTIMATED_STORAGE_DATE") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "quantity") {
      setWarehouseCargoData({
        ...warehouseCargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "qtyUnit") {
      setWarehouseCargoData({
        ...warehouseCargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "areaRequired") {
      setWarehouseCargoData({
        ...warehouseCargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "grossWeightPerContainer") {
      setWarehouseCargoData({
        ...warehouseCargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "measureUnit") {
      setWarehouseCargoData({
        ...warehouseCargoData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "country") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "city") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destination") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "cargoHazardous") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "UN_Number") {
      setWarehouseData({
        ...warehouseData,
        [e.target.name]: e.target.value,
      });
    }
    sessionStorage.setItem("warehouseData", JSON.stringify(warehouseData));
  };
  useEffect(() => {
    if (!isWhHazardous) {
      setWarehouseData({
        ...warehouseData,
        cargoHazardous: "",
        UN_Number: "",
      });
    }
  }, [isWhHazardous]);

  const checkAddLoad = () =>{setIsWhAdded(false)};
  const combineFncAddload =() =>{
    handleCargo();
    checkAddLoad();
    };

  return (
    <>
      <Content>
        <div style={{ margin: "10px" }}>
          <OverLayTrigger value={WAREHOUSE} />
        </div>
        <div className={classes.header} style={{ margin: "10px" }}>
          Where do you need warehousing services?
        </div>
        <div
          className={classes.source}
          style={{
            marginBottom: "2%",
            marginLeft: "10px",
            marginRight: "10px",
          }}
        >
          <div style={{ marginLeft: "1%", width: "50%" }}>
            <input
              className={classes.radio}
              type="radio"
              name="freightType"
              value="INTERNATIONAL"
              onChange={handleRadio}
              checked={warehouseData.freightType == "INTERNATIONAL"}
            />
            <label>INTERNATIONAL</label>
          </div>
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "1%",
              width: "50%",
              textAlign: "right",
              marginRight: "1%",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="freightType"
              value="DOMESTIC"
              onChange={handleRadio}
              checked={warehouseData.freightType == "DOMESTIC"}
            />
            <label>DOMESTIC</label>
          </div>
        </div>

        <div className={classes.source} style={{ marginBottom: "2%" }}>
          <div
            style={{ width: "50%", marginLeft: "10px", marginRight: "10px" }}
          >
            <SelectWithSearch
              options={Countries}
              name="country"
              placeholder="Country*"
              handleChange={handleChange}
              value={warehouseData.country}
            />
          </div>
          <div
            style={{ width: "50%", marginLeft: "10px", marginRight: "10px" }}
          >
            <Input
              name="city"
              placeholder="City"
              handleChange={handleChange}
              value={warehouseData.city}
            />
          </div>
        </div>
        <div>
          <div className={classes.source}>
            <div
              style={{ width: "50%", marginLeft: "10px", marginRight: "10px" }}
            >
              <Select
                options={Industry}
                name="commodity"
                placeholder="Commodity*"
                handleChange={handleChange}
                value={warehouseData.commodity}
                // readOnly={true}
              />
            </div>

            <div
              style={{ width: "50%", marginLeft: "10px", marginRight: "10px" }}
            >
              <Grid item xs={12} md={6}>
                <DateInput
                  name="ESTIMATED_STORAGE_DATE"
                  placeholder="Estimated Storage Date*"
                  value={DatePicker2}
                  handleChange={(e) =>
                    handleChange(e, "ESTIMATED_STORAGE_DATE")
                  }
                />
              </Grid>
            </div>
          </div>
        </div>
        <div></div>

        <>
          <div
            style={{ marginBottom: "1%", marginTop: "2%", marginLeft: "10px" }}
          >
            CARGO DETAILS
          </div>

          <div style={{ marginBottom: "2%" }}>
            <Button onClick={combineFncAddload}>+ Add Loads</Button>
          </div>
          <div className={classes.TableContainer}>
            <Table bordered responsive="sm" className={cn(classes.Table)}>
              <thead>
                <tr>
                  {LoadHeader &&
                    LoadHeader.map((header, i) => (
                      <td key={i}>{header.name}</td>
                    ))}
                </tr>
              </thead>
              <tbody>
                {warehouseCargoDataList &&
                  warehouseCargoDataList.map((res, i) => (
                    <tr key={i}>
                      <td>{res && res.quantity}</td>
                      <td>{res && res.qtyUnit}</td>
                      <td>{res && res.areaRequired}</td>
                      <td>{res && res.grossWeightPerContainer}</td>
                      <td>{res && res.measureUnit}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>

          {addWhCargo && (
            <>
              <div className={classes.loads}>
                <div className={classes.source} style={{ marginBottom: "2%" }}>
                  <div className={classes.source} style={{ width: "50%" }}>
                    <div
                      style={{
                        width: "30%",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <Input
                        name="quantity"
                        placeholder="QTY*"
                        value={warehouseCargoData.quantity}
                        handleChange={handleChange}
                      />
                    </div>
                    <div
                      style={{
                        width: "70%",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <Select
                        options={quantityUnitOptions}
                        name="qtyUnit"
                        placeholder="Quantity Unit*"
                        handleChange={handleChange}
                        value={warehouseCargoData.qtyUnit}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <Input
                      name="areaRequired"
                      placeholder="Area Required (sq ft)*"
                      handleChange={handleChange}
                      value={warehouseCargoData.areaRequired}
                    />
                  </div>
                </div>

                <div className={classes.source}>
                  <div
                    style={{
                      width: "50%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <Input
                      name="grossWeightPerContainer"
                      placeholder="Gross Weight Per Container*"
                      value={warehouseCargoData.grossWeightPerContainer}
                      handleChange={handleChange}
                    />
                  </div>
                  <div
                    style={{
                      width: "50%",
                      marginLeft: "10px",
                      marginRight: "10px",
                    }}
                  >
                    <Select
                      options={measureUnitOptions}
                      name="measureUnit"
                      placeholder="Measure Unit*"
                      handleChange={handleChange}
                      value={warehouseCargoData.measureUnit}
                    />
                  </div>
                </div>
                <ButtonDiv>
                  <Button onClick={(e) => handleAddLoad(e)}>Add</Button>
                </ButtonDiv>
              </div>
            </>
          )}
        </>
        <div>
          <input
            className={classes.Checkbox}
            type="checkbox"
            onChange={(e) => setisWhHazardous(e.target.checked)}
          />
          <label
            style={{ marginLeft: "1%", fontSize: "18px", marginBottom: "1%" }}
          >
            This cargo is hazardous
          </label>
        </div>

        {isWhHazardous && (
          <div className={classes.source} style={{ marginBottom: "2%" }}>
            <div className={classes.source} style={{ width: "40%" }}>
              <div
                style={{
                  width: "50%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Select
                  options={cargoHazardousOptions}
                  name="cargoHazardous"
                  placeholder="cargo Hazardous"
                  handleChange={handleChange}
                  value={warehouseData.cargoHazardous}
                />
              </div>
              <div
                style={{
                  width: "50%",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                <Input
                  name="UN_Number"
                  placeholder="UN#"
                  value={warehouseData.UN_Number}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}
      </Content>
    </>
  );
};

export default Warehousing;
