import { createContext, useState, useReducer, useEffect, useContext, useCallback } from 'react';
export const invoiceFinanceContext = createContext(); //type

export const InvoiceFinanceContextProvider = ({ children }) => { //context provider

  //Here we store response data when upload Data submitted  // Using in Invoice
  const [invoiceData, setInvoiceData] = useState("");
 
  //Here we store response data when invoice details submitted  
  const [SavedInvoiceData, setSaveInvoiceData] = useState('')

  const [financeData, setFinanceData] = useState([])

  const [data, setData] = useState({
    name: 'invoice',
    file: ''
  })


  const handleChange = (e) => {
    setData({
      ...data,
      file: e.target.files[0],
      name: e.target.files[0].name
    })
  }

  return <invoiceFinanceContext.Provider value={{
    invoiceData, data,
    handleChange, setInvoiceData,
    SavedInvoiceData, setSaveInvoiceData,
    financeData, setFinanceData
  }}>
    {children}
  </invoiceFinanceContext.Provider>
};