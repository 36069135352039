import React, { useState, useEffect, useContext } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import { profileURL } from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import DateInput from "components/fields/DateInput";
import { Countries } from "utils/DropDown/Country";
import Currenciesr from "jsons/Currenciesr.json";
import moment from "moment";

import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";
import { getClaim } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import SelectWithSearch from "components/fields/SelectWithSearch";
import { storeContext } from "contexts/Store/Store";
import { NetworkURL } from "services/BaseURLInstance";
import { UseRequest } from "utils/API/Request.js";
import { getIdTokenAsync } from "utils/authUtil";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";

const FrightInvoicing = ({ fiData, setFiData }) => {
  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");

  let serviceType = [
    {
      label: "Air Frieght",
      value: "Air Frieght",
    },
    {
      label: "Ocean Frieght",
      value: "Ocean Frieght",
    },
    {
      label: "Groung Frieght",
      value: "Ground Frieght",
    },
  ];

  let paymentTerms = [
    {
      label: "Advance Payment",
      value: "Advance Payment",
    },
    {
      label: "Letter of credit",
      value: "Letter of credit",
    },
    {
      label: "Documents against Payments - D.A.P or D/P basis",
      value: "Documents against Payments - D.A.P or D/P basis",
    },
    {
      label: "Documents against Acceptance (D/A)",
      value: "Documents against Acceptance (D/A)",
    },
    {
      label: "Open Account",
      value: "Open Account",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  const handleDateChange = (e, field) => {
    setFiData({
      ...fiData,
      [field]: new Date(e).toISOString(),
    });
  };

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "loadingDate") {
        setDatePicker1(newDate1);
        // setCustomeClearanceData({
        //   ...customeClearanceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "unloadingDate") {
        setDatePicker2(newDate1);
        // setCustomeClearanceData({
        //   ...customeClearanceData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setFiData({
        ...fiData,
        [date]: dateString,
      });
    } else if (e.target.name === "RequestorName") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requesterDepart") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requestEmail") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requestPhone") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requestdate") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "supplierName") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "supplierContact") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "supplierPhone") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "supplierEmail") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    }
    // else if (e.target.name === "inspectiontype") {
    //   setFiData({
    //     ...fiData,
    //     [e.target.name]: e.target.value,
    //   });
    // }
    else if (e.target.name === "supplierAdd") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "refferenceNo") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "describeGoods") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryFrom") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryTo") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "serviceTyp") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "serviceLevel") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "billingMethod") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "specialinstruct") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "estimated") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currency") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerms") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerm") {
      setFiData({
        ...fiData,
        [e.target.name]: e.target.value,
      });
    }
  };

  return (
    <>
      <Content>
        <label>Freight Financing Requisition Form : </label>
        <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
          Requestor Information
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="RequestorName"
            placeholder=" Requestor Name:*"
            handleChange={handleChange}
            value={fiData.RequestorName}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="requesterDepart"
            placeholder="Requestor Department:*"
            handleChange={handleChange}
            value={fiData.requesterDepart}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="requestEmail"
            placeholder="Requestor Email:*"
            handleChange={handleChange}
            value={fiData.requestEmail}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="requestPhone"
            placeholder=" Requestor Phone Number: *"
            handleChange={handleChange}
            value={fiData.requestPhone}
            // readOnly={true}
          />
        </div>

        <div className={classes.source}>
          <div style={{ width: "50%", marginBottom: "2%", paddingRight:"5px" }}>
            <DateInput
              name="requestdate"
              placeholder="Date Of Request*"
              value={fiData.requestdate}
              handleChange={(e) => handleDateChange(e, "requestdate")}
            />
          </div>
        </div>
        <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
          Supplier Information
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="supplierName"
            placeholder="Supplier Name*"
            handleChange={handleChange}
            value={fiData.supplierName}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="supplierContact"
            placeholder=" Supplier Contact Information* "
            handleChange={handleChange}
            value={fiData.contactInfo}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="supplierPhone"
            placeholder=" Supplier Phone number*"
            handleChange={handleChange}
            value={fiData.supplierPhone}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="supplierEmail"
            placeholder="Supplier Email* "
            handleChange={handleChange}
            value={fiData.supplierEmail}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="supplierAdd"
            placeholder="Supplier Address* "
            handleChange={handleChange}
            value={fiData.supplierAdd}
            // readOnly={true}
          />
        </div>

        <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
          Freight Shipment Details
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", marginBottom: "2%" , paddingRight:"5px"}}>
            <DateInput
              name="shipmentDate"
              placeholder="Shipment Date*"
              value={fiData.shipmentDate}
              handleChange={(e) => handleDateChange(e, "shipmentDate")}
            />
          </div>
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="refferenceNo"
            placeholder="Shipment Reference Number:*"
            handleChange={handleChange}
            value={fiData.refferenceNo}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="describeGoods"
            placeholder=" Description of Goods "
            handleChange={handleChange}
            value={fiData.describeGoods}
            // readOnly={true}
          />
        </div>

        <div className={classes.source} style={{ marginBottom: "2%" }}>
          <div style={{ width: "50%" }}>
            <SelectWithSearch
              options={Countries}
              name="countryFrom"
              placeholder="Country of Origin*"
              handleChange={handleChange}
              value={fiData.countryFrom}
              // readOnly={true}
            />
          </div>

          <div style={{ width: "50%", marginLeft: "1%" }}>
            <SelectWithSearch
              options={Countries}
              name="countryTo"
              placeholder="Country of Destination*"
              handleChange={handleChange}
              value={fiData.countryTo}
              // readOnly={true}
            />
          </div>
        </div>
        <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
          Service Details
        </div>
        <div style={{ width: "50%", marginBottom: "1%", paddingRight:"5px" }}>
          <SelectWithSearch
            options={serviceType}
            name="serviceTyp"
            placeholder="Freight Service Type*"
            handleChange={handleChange}
            value={fiData.serviceTyp}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          {/* <Input
          style={{ height: "7rem", width: "100%" }}
          name="freightService"
          placeholder=" Freight Service Type"
          handleChange={handleChange}
          value={fiData.freightService}
          // readOnly={true}
        /> */}
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="serviceLevel"
            placeholder="Service Level*"
            handleChange={handleChange}
            value={fiData.serviceLevel}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="billingMethod"
            placeholder="Billing Method*"
            handleChange={handleChange}
            value={fiData.billingMethod}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="specialinstruct"
            placeholder="Special Handling Instructions:"
            handleChange={handleChange}
            value={fiData.specialinstruct}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "100%", marginBottom: "1%", marginTop: "2%" }}>
          Cost and Payment Information
        </div>
        <div style={{ width: "100%", marginBottom: "1%" }}>
          <Input
            style={{ height: "7rem", width: "100%" }}
            name="estimated"
            placeholder="Estimated Freight Cost:*"
            handleChange={handleChange}
            value={fiData.estimated}
            // readOnly={true}
          />
        </div>
        <div style={{ width: "50%", marginBottom: "1%", paddingRight:"8px" }}>
          <SelectWithSearch
            options={Currenciesr}
            name="currency"
            placeholder="Currency*"
            handleChange={handleChange}
            value={fiData.currency}
            // readOnly={true}
          />
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              // marginBottom: "2%",
              // marginLeft: "10px",
            }}
          >
            <SelectWithSearch
              options={paymentTerms}
              name="paymentTerms"
              placeholder="Payment Term*"
              value={fiData.paymentTerms}
              handleChange={handleChange}
            />
          </div>
          {fiData.paymentTerms == "OTHERS" ? (
            <div style={{ width: "100%", marginLeft: "10px" }}>
              <Input
                name="paymentTerm"
                placeholder="Please Enter Your Payment Term"
                value={fiData.paymentTerm}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </Content>
    </>
  );
};

export default FrightInvoicing;
