import React, { useState, useCallback } from "react";
import {useLocation} from "react-router-dom";
import CustomChannelListHeader from "./CustomChannelListHeader";


import {
  Channel as SBConversation,
  ChannelList,
  ChannelList as SBChannelList,
  ChannelSettings as SBChannelSettings,
  // MessageSearch,
  withSendBird
} from "sendbird-uikit";
import { useEffect } from "react";

var channelQuery = {
    channelListQuery: {
        includeEmpty: true,
    }
}

var selectedChannelUrl = '';

const channelSorter = (channelList) => {
    return channelList.sort((first, second) =>{
        return first.url === selectedChannelUrl ? -1 : second.url === selectedChannelUrl ? 1 : 0;
    })
};

//TODO https://codesandbox.io/s/custompopup-7do2j?file=/src/CustomChannelListHeader.jsx
function CustomizedApp(props) {


  const {chatURL} = props;

  const [query, setQuery] = useState({
    channelListQuery: {
      includeEmpty: true,
      channelNameContainsFilter: chatURL,
      show_empty:true,
      show_member:true,
      limit: 100,
    }
  });
const hanndleCraateGroup = props;
  const {
    stores: { sdkStore, userStore },
    config: {
      isOnline,
      userId,
      appId,
      accessToken,
      theme,
      userListQuery,
      logger,
      pubSub
    }
  } = props;

  
  
  // useState
  const [showSettings, setShowSettings] = useState(false);
  const [currentChannelUrl, setCurrentChannelUrl] = useState("");
  // const [inputText, setInputText] = useState('');

  const search = useLocation().search;
  //selectedChannelUrl = new URLSearchParams(search).get('url');
  selectedChannelUrl = chatURL;
  // useEffect(()=>{
  //   if(channelFilter!=="")
  //   {
  //     channelQuery.channelListQuery.channelNameContainsFilter=channelFilter;
  //   }
  //   else{
  //     delete channelQuery.channelListQuery.channelNameContainsFilter;
  //   } 
  //   channelQuery=JSON.parse( JSON.stringify(channelQuery));
  // },[channelFilter])
 
  
  return (
    <div className="customized-app">
      <div className="sendbird-app__wrap">
        <div className="sendbird-app__channellist-wrap">
        <CustomChannelListHeader
            user={userStore.user}
            setChannelUrl={(url) => {setCurrentChannelUrl(url);}}
            onSetQuery={setQuery}
            hanndleCraateGroup={hanndleCraateGroup}
          />
         
          <SBChannelList
            // disableAutoSelect={true}
            sortChannelList={channelSorter}
            queries={query}
            onChannelSelect={(channel) => {
              if (channel && channel.url) {
                setCurrentChannelUrl(channel.url);
              }
            }}
           
          />
        </div>
        <div className="sendbird-app__conversation-wrap">
      
          {/* <div>
            <textarea onChange={(e) => {
              setInputText(e.target.value);
            }} />
            <MessageSearch
              stores={props.stores}
              config={props.config}
              channelUrl={currentChannelUrl}
              searchString={inputText}
            />
          </div> */}
           <h1 className="toptitleright">{userStore?.user?.nickname}</h1>
          <SBConversation
            channelUrl={currentChannelUrl}
            onChatHeaderActionClick={() => {
              setShowSettings(true);
            }}
          />
         
        </div>
          {showSettings && (
            <div className="sendbird-app__settingspanel-wrap">  
            
              <SBChannelSettings
                channelUrl={currentChannelUrl}
                onCloseClick={() => {
                  setShowSettings(false);
                }}
              />
          </div>
        )}
      </div>
    </div>
  );
}

export default withSendBird(CustomizedApp);
