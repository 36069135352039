import React , {useContext, useEffect, useState} from 'react'
import styled from "styled-components";
import styles from '../../invoice.module.css';
import {CompanyNameFilter, notify} from '../../../../utils/helper'
import {storeContext} from '../../../../contexts/Store/Store'
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import {NetworkURL} from '../../../../services/BaseURLInstance'

export const Summary = ({data}) => {
    const [CompanyList , setCompanyList] = useState([]);
    const { fetchData } = useContext(storeContext)
    const { instance, accounts } = useMsal();
    // const [idToken, setIdToken] = useState(null);
    // getIdToken(instance, accounts, setIdToken);
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const companyId = getClaim(accounts, "company_member_id");

    useEffect(() => {
        const getData = async () => {
          let URL = `${NetworkURL}members?memberId=${companyId}`;
          const Data = await fetchData(URL)
          { Data && Data.data && (
            setCompanyList(Data.data)
          )} 
          if(Data && Data.error) { 
            notify('warn')
          }
        }
        getData();
      },[])

    const Response = [
        {
            name: 'Status',
            value: data.txlStatus ? data.txlStatus : 'Unknown',
        },
        {
            name: 'Funding data',
            value: 'PENDING'
        },
        {
            name: 'Buyer Consent',
            value: 'PENDING'
        },
        {
            name: 'Value',
            value: data.txlInvoiceAmount ? data.txlInvoiceAmount : 'Unknown',
        },
        {
            name: 'Last Activity',
            value: data.updatedDate ? data.updatedDate : 'Unknown',
        },

        {
            name: 'Invoice Document',
            value: data.invoiceNumber ? data.invoiceNumber : 'Unknown',
        },
        {
            name: 'Buyer',
            value : CompanyNameFilter(CompanyList, data.txlBuyerMemberId).length >=1 ? CompanyNameFilter(CompanyList, data.txlBuyerMemberId)[0].memberName : '' 
        },
        {
            name: 'Seller',
            value: CompanyNameFilter(CompanyList, data.txlSellerMemberId).length >=1 ? CompanyNameFilter(CompanyList, data.txlSellerMemberId)[0].memberName : '' 
        },
        
    ]

    return (
        <div className={styles.summary}>

            { Response && Response.map((data, i) => (
            <div className={styles.summaryWrapper} key={i}>
                <div> <span> {data.name} </span> </div>
                <div> <span style = {

                data.value === 'PENDING' ?  addRed : 
                data.name === 'Buyer' ?  addBlue : 
                data.name === 'Invoice Document' ?  addBlue :
                data.name === 'Seller' ?  addBlue : addWhite
                
                }> {data.value} </span> </div>
            </div> 
            ))}

        </div>
    )
}

const addRed = {
  color: "red"       
}

const addWhite = {
    color: "white"       
  }

const addGreen = {
    color: "green"
}

const addBlue = {
    color: "#42A5F5"
}


