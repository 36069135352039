import React, { useState, useEffect } from "react";
import Content from "components/UI/Layout/Content";
import { PaymentlistHeader } from "utils/Section/trade";
import cn from "classnames";
import classes from "../Payment.module.css";
import Table from "react-bootstrap/Table";
import Loader from "components/Loader/Loader";
import { paymentServiceURL, profileURL } from "services/BaseURLInstance";
import Button from "components/UI/Button/Button";
import { UseRequest } from "utils/API/Request.js";
import PaymentAccounts from "./PaymentAccounts.js";
import Modal from "react-bootstrap/Modal";
import { BsInfoCircle } from "react-icons/bs";
import Info from "./Info";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { CheckPermission } from "utils/Constants/RBAC";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";

const Payment = () => {
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [companyData, setCompanyData] = useState(null);
  const [isInitiateOpen, setIsInitiateOpen] = useState(false);
  const [isAssociate, setIsAssociate] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [newFilter, setNewFilter] = useState([]);
  const [isInfo, setIsInfo] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const { instance, accounts } = useMsal();
  const [providersList, setProvidersList] = useState([]);

  //rbac
  const [Role, setRole] = useState(null);
  const [actionRole, setActionRole] = useState(null);
  // const Array = ["TAZAPAY", "PAYSHARPE", "TRANSFERWISE"];
  const personId = JSON.parse(sessionStorage.getItem("personId"));
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  useEffect(() => {
    let isCancelled = false;
    setIsLoading(true);
    if (!isCancelled) {
      let URL = `${paymentServiceURL}payments/user/${companyId}`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            setResponse(data);
          })
          .catch((error) => {
            setIsError(error);
            setIsLoading(false);
          });
      });
    }

    if (!isCancelled) {
      let URL = `${paymentServiceURL}providers/by-status-active`;
      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            setProvidersList(data);
          })
          .catch((error) => {
            setIsError(error);
            setIsLoading(false);
          });
      });
    }

    if (!isCancelled) {
      let URL = "";
      if (memberType === "FINANCER") {
        URL = `${profileURL}financers/${companyId}`;
      } else {
        URL = `${profileURL}companies/${companyId}`;
      }

      getIdTokenAsync(instance, accounts).then((response) => {
        UseRequest(URL, null, response)
          .then((data) => {
            setCompanyData(data);
          })
          .catch((error) => {
            setIsError(error);
          })
          .finally(() => {
            setIsLoading(false);
          });
      });
    }

    return () => {
      isCancelled = true;
    };
  }, [isSubscribed]);

  useEffect(() => {
    let NewArray = [];

    if (
      response &&
      response[0] !== null &&
      providersList &&
      providersList.length > 0
    ) {
      let TEMP = [];
      providersList.map((provider) => TEMP.push(provider.providerName));

      TEMP.map((arr) => {
        if (response && response[0] !== null) {
          const filter = response.filter(
            (item) => item.paymentProvider === arr
          );

          if (filter.length > 0) {
            NewArray.push(filter[0]);
          } else {
            NewArray.push({
              paymentProvider: arr,
              kyb: "notinitiate",
            });
          }
        } else {
          NewArray.push({
            paymentProvider: arr,
            kyb: "notinitiate",
          });
        }
      });

      setNewFilter(NewArray);
    }
  }, [isSubscribed, response, providersList]);

  // useEffect(() => {
  //   let isCancelled = false;
  //   let URL = "";
  //   if (memberType === "FINANCER") {
  //     URL = `${profileURL}financers/${companyId}`;
  //   } else {
  //     URL = `${profileURL}companies/${companyId}`;
  //   }
  //   if (!isCancelled) {
  //     getIdTokenAsync(instance, accounts).then((response) => {
  //       UseRequest(URL, null, response).then((data) => {
  //         setCompanyData(data);
  //       });
  //     });
  //   }
  //   return () => {
  //     isCancelled = true;
  //   };
  // }, [isSubscribed]);

  const Request = () => {
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        `${profileURL}Persons/${response.idTokenClaims.member_id}`,
        null,
        response
      )
        .then((data) => {
          // setIsLoading(false);
          // let { message, status } = data;
          // if (message || status) {
          //   Error(message);
          // } else {
          //   setRole(data.role);
          // }
          setRole(data.role);
        })
        .catch((error) => {
          setIsError(error);
          Error("Something went wrong");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  useEffect(() => {
    Request();
  }, []);

  useEffect(() => {
    if (Role) {
      setActionRole(
        CheckPermission("Action", "PaymentProviders", "action", Role)
      );
    }
  }, [Role]);

  const handleSubscribe = async (e, header) => {
    e.preventDefault();
    setIsLoading(true);
    let Data = {};
    if (memberType === "FINANCER") {
      Data.memberUID = companyData && companyData.memberUID;
      Data.firstName = companyData && companyData.financerName;
      Data.lastName = companyData && companyData.financerName;
      Data.countryCode = companyData && companyData.countryCode;
      Data.country = companyData && companyData.countryCode;
      Data.email = companyData && companyData.financerEMail;
      Data.paymentProvider = header;
      Data.businessName = companyData && companyData.financerName;
      Data.industryBusinessType = "Business";
      Data.customerUID = JSON.parse(sessionStorage.getItem("personId"));
      Data.phone =
        companyData && companyData.financerPhone && companyData.financerPhone[0]
          ? companyData.financerPhone[0]
          : "";
    } else {
      Data.memberUID = companyData && companyData.memberUID;
      Data.firstName = companyData && companyData.companyName;
      Data.lastName = companyData && companyData.companyName;
      Data.countryCode = companyData && companyData.companyCountry;
      Data.country = companyData && companyData.companyCountry;
      Data.email = companyData && companyData.companyEMail;
      Data.paymentProvider = header;
      Data.businessName = companyData && companyData.companyName;
      Data.industryBusinessType = "Business";
      Data.customerUID = JSON.parse(sessionStorage.getItem("personId"));
      Data.phone =
        companyData && companyData.companyPhone && companyData.companyPhone[0]
          ? companyData.companyPhone[0]
          : "";
    }

    const options = {
      method: "POST",
      body: Data,
    };
    let URL = `${paymentServiceURL}payments/user`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((response) => {
          // let { message, status } = response;
          // if (message) {
          //   Error(message || "Something went wrong");
          // } else {
          //   Success("Subscribe SuccessFully");
          //   setIsSubscribed(!isSubscribed);
          // }
          Success("Subscribe SuccessFully");
          setIsSubscribed(!isSubscribed);
        })
        .catch(() => {
          Error("Something went wrong");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  const handleOpenInfo = (data) => {
    setInfoData(data);
  };

  const handleInitiateKYB = async (e, header) => {
    e.preventDefault();
    setIsLoading(true);
    let Data = {};
    const options = {
      method: "POST",
      body: Data,
    };
    let URL = `${paymentServiceURL}kyb/application?memberId=${header.memberUID}&personId=${personId}&paymentProvider=${header.paymentProvider}`;
    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(URL, options, response)
        .then((data) => {
          // let { message, status } = data;
          // if (message || status) {
          //   Error(message || "Something went wrong");
          // } else {
          //   Success("Initiate KYB SuccessFully");
          //   setIsSubscribed(!isSubscribed);
          // }
          Success("Initiate KYB SuccessFully");
          setIsSubscribed(!isSubscribed);
        })
        .catch(() => {
          Error("Something went wrong");
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <Content padding="1.8% 2% 1% 2%">
      <Modal
        show={isInfo}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setIsInfo(false)}>
        <Modal.Header closeButton className={classes.Modal}>
          <Modal.Title id="contained-modal-title-vcenter">
            Informations
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.Modal}>
          <Content>
            <Info Data={infoData} />
          </Content>
        </Modal.Body>
        <Modal.Footer className={classes.Modal}>
          <Button onClick={() => setIsInfo(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      {isLoading ? (
        <span>
          Loading...
          <Loader />
        </span>
      ) : (
        <>
          {isInitiateOpen ? (
            <PaymentAccounts
              setIsInitiateOpen={setIsInitiateOpen}
              isAssociate={isAssociate}
              setIsSubscribed={setIsSubscribed}
              isSubscribed={isSubscribed}
            />
          ) : (
            <>
              {response && newFilter && !isError && (
                <>
                  <h6 style={{ fontSize: "18px" }}>
                    Payment Service Providers
                  </h6>
                  <div style={{ fontSize: "12px", marginBottom: "10px" }}>
                    Please select or unselect the payment service providers
                    which you will preferr to subscribe
                  </div>
                  <Table bordered responsive className={cn(classes.Table)}>
                    <thead>
                      <tr>
                        {PaymentlistHeader &&
                          PaymentlistHeader.map((header, i) => (
                            <td key={i}>{header.name}</td>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {newFilter &&
                        newFilter.map((header, i) => (
                          <tr key={i}>
                            <td>{header.paymentProvider}</td>
                            <td>
                              {header && header.firstName
                                ? header.firstName
                                : "NA"}
                            </td>
                            <td>
                              {header && header.virtualAccountNumber
                                ? header.virtualAccountNumber
                                : "NA"}
                            </td>
                            <td>
                              {header && header.status ? header.status : "NA"}
                            </td>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsInfo(true);
                                handleOpenInfo(header);
                              }}>
                              <BsInfoCircle />
                            </td>
                            <td>
                              {providersList[i]?.isSubscriptionRequired ? (
                                <div className={classes.ButtonDiv}>
                                  {header.status !== "ACCOUNT_INITIATED" &&
                                    header.status !== "KYB_SUCCESS" &&
                                    header.status !==
                                      "ACCOUNT_CREATION_SUCCESS" && (
                                      <button
                                        onClick={(e) =>
                                          actionRole === "CREATE"
                                            ? handleSubscribe(
                                                e,
                                                header.paymentProvider
                                              )
                                            : " "
                                        }>
                                        Subscribe
                                      </button>
                                    )}

                                  {header.status === "ACCOUNT_INITIATED" && (
                                    <button
                                      onClick={(e) =>
                                        actionRole === "CREATE"
                                          ? handleInitiateKYB(e, header)
                                          : " "
                                      }>
                                      Initiate KYB
                                    </button>
                                  )}

                                  {header.status === "KYB_SUCCESS" && (
                                    <button
                                      onClick={
                                        actionRole === "CREATE"
                                          ? () => {
                                              setIsAssociate(
                                                header.paymentProvider
                                              );
                                              setIsInitiateOpen(true);
                                            }
                                          : "  "
                                      }>
                                      Associate Account
                                    </button>
                                  )}

                                  {header.status ===
                                    "ACCOUNT_CREATION_SUCCESS" && (
                                    <button>UnSubscribe</button>
                                  )}
                                </div>
                              ) : (
                                "Not Applicable"
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </>
              )}

              {isError && <span>Something went wrong</span>}
            </>
          )}
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Content>
  );
};

export default Payment;
