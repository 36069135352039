import React, { useState } from "react";
import ToogleNavbar from "components/UI/Navbar/ToogleNavbar";

import General from "./Details/General";
import SupplierDetail from "./Details/SupplierDetails";
import BuyerDetails from "./Details/BuyerDetails";
import ExportDetails from "./Details/ExportDetails";
import Miscellaneous from "./Details/Miscellaneous";
import ItemList from "./Details/ItemList";

const Details = ({ Data , companyId}) => {
  const [isActive, setIsActive] = useState("General");
  const [Component, setIsComponent] = useState(<General Data={Data} />);

  const Navbar = [
    {
      title: "General",
      description: <General Data={Data} />,
    },
    {
      title: "Supplier Details",
      description: (
        <SupplierDetail
          Data={Data && Data.invoiceDetails && Data.invoiceDetails.SellerDtls}
        />
      ),
    },
    {
      title: "Buyer Details",
      description: (
        <BuyerDetails
          Data={Data && Data.invoiceDetails && Data.invoiceDetails.BuyerDtls}
        />
      ),
    },
    {
      title: "Item List",
      description: (
        <div style={{fontSize:"12px"}}>
        <ItemList
          itemList={Data && Data.invoiceDetails && Data.invoiceDetails.ItemList}
        />
        </div>
      ),
    },
    {
      title: "Export Details",
      description: (
        <div style={{fontSize:"14px"}}>
        <ExportDetails
          Data={Data && Data.invoiceDetails && Data.invoiceDetails.ExpDtls}
        />
        </div>
      ),
    },
    {
      title: "Miscellaneous",
      description: <div style={{fontSize:"14px"}}>
      <Miscellaneous Data= {Data && Data.invoiceDetails && Data.invoiceDetails.RefDtls.ContrDtls}/>
      </div>
    },
  ];

  const handleChange = (title) => {
    setIsActive(title);
  };

  const handleComponent = (component) => {
    setIsComponent(component);
  };

  return (
    <div>
      {Data && (
        <>
          <ToogleNavbar
            Navbar={Navbar}
            isActive={isActive}
            handleChange={handleChange}
            handleComponent={handleComponent}
          />

          {Component !== undefined && Component}
        </>
      )}
      {Data.txlBuyerMemberId !== companyId && <span>* Invoice Details are optional but we recommend to fill the details.</span>}
    </div>
  );
};

export default Details;
