import React from "react";
import { PaymentProviderInfo } from "utils/Section/Payment";
import classes from "../Payment.module.css";
import { truncateStringID } from "utils/helper";
import { FindCompanyName } from "utils/helper";
import { CompanyList } from "utils/API/CompanyList.js";
import Loader from "components/Loader/Loader";

const Info = ({ Data }) => {
  const { response, isLoading, isError } = CompanyList();
  const InforStatus = (item) => {
    switch (item.value) {
      case "businessName":
        return Data.businessName ? Data.businessName : "NA";
      case "country":
        return Data.country ? Data.country : "NA";
      case "email":
        return Data.email ? Data.email : "NA";
      case "phone":
        return Data.phone ? Data.phone : "NA";
      case "paymentProvider":
        return Data.paymentProvider ? Data.paymentProvider : "NA";
      case "memberUID":
        return response ? FindCompanyName(response, Data.memberUID) : "NA";
      case "customerUID":
        return Data.customerUID
          ? truncateStringID(Data.customerUID, 7, "PP")
          : "NA";
      case "virtualAccountNumber":
        return Data.virtualAccountNumber
          ? truncateStringID(Data.virtualAccountNumber, 7, "PP")
          : "NA";
      default:
        return "NA";
    }
  };

  return (
    <div>
      {isLoading ? (
        <span>
          Loading...
          <Loader />
        </span>
      ) : (
        <>
          {response && (
            <div className={classes.column}>
              {PaymentProviderInfo &&
                PaymentProviderInfo.map((item, index) => (
                  <div className={classes.columnList} key={index}>
                    <div className={classes.columnContent}>
                      <div>{item.name}</div>
                      <span style={{ color: "#42A5F5" }}>
                        {InforStatus(item)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {isError && <span>Something went wrong</span>}
        </>
      )}
    </div>
  );
};

export default Info;
