import { useState, useEffect, useContext } from "react";
import classes from "./Schedules.module.css";
import cn from "classnames";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Content from "components/UI/Layout/Content";
import {
  docGroupURL,
  documentURL,
  openTradeAccount,
  docUpdateURL,
} from "services/BaseURLInstance";
import Loader from "components/Loader/Loader";
import { MdDriveFolderUpload } from "react-icons/md";
import { UseRequest } from "utils/API/Request.js";
import { ToastContainer } from "react-toastify";
import { useMsal } from "@azure/msal-react";
import { getIdTokenAsync } from "utils/authUtil";
import { Success, Error } from "components/notifications/responseHandle";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { AuthContext } from "contexts/Auth/AuthContext";

const Upload = ({ setIsUploadingDocument, Data }) => {
  const { OpenTradeAccountDetails } = useContext(AuthContext);
  console.log("OpenTradeAccountDetails", OpenTradeAccountDetails);

  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  const { instance, accounts } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const [data, setData] = useState({
    name: "",
    file: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  };

  const handleDocumentUpload = async (event) => {
    event.preventDefault();
    var body = new FormData();
    body.append("name", data.name);
    body.append("public", false);
    body.append("file", data.file);

    if (data.file === "") {
      Error("Please Select Documents");
      return;
    } else {
      setIsLoading(true);
      const requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: body,
      };
      await fetch(`${documentURL}documents`, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          handleDocumentGroupUpdate(data);
        })
        .catch((error) => {
          Error("Something Went Wrong");
          setIsError("Something Went Wrong");
          setIsLoading(false);
        });
    }
  };

  const handleDocumentGroupUpdate = async (data) => {
    let requestPayload = {
      memberId: companyId,
      ownerId: companyId,
      docReferenceId: data.id,
      documentName: data.name,
      tags: [`OAT-${Data.openTradeAccountId}`],
      docCategory:
        Data.documentGroups &&
        Data.documentGroups[0] &&
        Data.documentGroups[0].docCategory,
      docSubCategory:
        Data.documentGroups &&
        Data.documentGroups[0] &&
        Data.documentGroups[0].docSubCategory,
      participantsToSign: Data.participantsToSign,
      participantsToShare: Data.participantsToShare,
      referenceData: [],
    };

    const options = {
      method: "PUT",
      body: requestPayload,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        docGroupURL +
          `bff/documentsGroups/documentUpdate?documentId=${data.id}&template=Trade&instanceID=${Data.openTradeAccountId}`,
        options,
        response
      )
        .then((data) => {
          setIsUploaded(true);
          handleDocumentShare(data);
        })
        .catch((err) => {
          Error("Something Went Wrong");
          setIsError("Something Went Wrong");
          setIsLoading(false);
        });
    });
  };

  const handleDocumentShare = async (Data) => {
    const requestParams = {
      docIds: [Data.docReferenceId],
      members: [OpenTradeAccountDetails.buyerMemberId],
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: JSON.stringify(requestParams),
    };

    await fetch(`${docUpdateURL}documents/share`, requestOptions)
      .then((response) => {
        if (response.ok) {
          return response;
        }
      })
      .then((data) => {
        handleUpdateCall(OpenTradeAccountDetails.buyerMemberId, Data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleUpdateCall = async (memberId, Data) => {
    let requestPayload = {
      docCategory: Data.docCategory,
      docSubCategory: Data.docSubCategory,
      documentName: Data.documentName,
      memberId: memberId,
      ownerId: companyId,
      docReferenceId: Data.docReferenceId,
      participantsToShare: [OpenTradeAccountDetails.buyerMemberId],
      tags: Data.tags,
    };

    const options = {
      method: "PUT",
      body: requestPayload,
    };

    getIdTokenAsync(instance, accounts).then((response) => {
      UseRequest(
        docGroupURL +
          `bff/documentsGroups/documentUpdate?documentId=${Data.docReferenceId}&memberUID=${companyId}&template=Trade&instanceID=${OpenTradeAccountDetails.openTradeAccountId}`,
        options,
        response
      )
        .then((data) => {
          Success(`Success Document Shared ${memberId} Member`);
        })
        .finally(() => {
          setIsLoading(false);
        });
    });
  };

  return (
    <>
      <div
        style={{ color: "#218eeb", cursor: "pointer" }}
        onClick={() => setIsUploadingDocument(false)}>
        <span>
          <HiArrowNarrowLeft size={28} />
        </span>
        <span>Back to List </span>
      </div>

      <Content>
        {isLoading ? (
          <span>
            Loading...
            <Loader />
          </span>
        ) : (
          <>
            {isUploaded ? (
              <span>Uploaded successfully</span>
            ) : (
              <form
                onSubmit={(e) => handleDocumentUpload(e)}
                className={classes.UploadForm}>
                <h5>Upload File...</h5>

                <div className={classes.Upload}>
                  <label onChange={handleChange} htmlFor="formId">
                    <div className={classes.UploadDiv}>
                      <span style={{ color: "#42a5f5" }}>
                        <input
                          name="file"
                          accept=".doc,.  docx,.pdf,.jpeg"
                          type="file"
                          id="formId"
                          hidden
                        />
                        <MdDriveFolderUpload size={40} />
                      </span>

                      <span className="mx-2">
                        {data.name.split(".").slice(0, -1).join(".")}
                      </span>
                    </div>
                  </label>
                </div>

                <ButtonDiv>
                  <Button onClick={() => setIsUploadingDocument(false)}>
                    Cancel
                  </Button>
                  <Button type="submit">Upload</Button>
                </ButtonDiv>
              </form>
            )}

            {isError && <span>Something went wrong</span>}

            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </>
        )}
      </Content>
    </>
  );
};
export default Upload;
