import React from 'react';
import { BsClipboardData } from 'react-icons/bs';

const Error = (props) => {
    return (
        <div style={{textAlign:"center", marginTop: "10%" }}>
            <div style={{ }}>
               <BsClipboardData size={100}/>
            </div>
            <div style={{ marginTop: "2%"  }}>
                Error : {props.status}
            </div>
        </div>
    )
}
export default Error;