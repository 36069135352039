import React from 'react'
import {
    Card,
    Grid
} from '@mui/material';
function Overview() {

    const cardData = [
        { "Open Trades": "2" },
        { "Pending Documents": "3" },
        { "Pending Buyer Approval": "4" },
        { "Upcoming Payments": "5" },
        { "New Payments": "6" },
        { "New Trades": "7" }
    ]
    return (
        <div style={{ borderRadius: "10px", background: "#343434", padding: "20px", marginTop: "20px" }}>
            <Card style={{
                backgroundColor: "#000000",
                padding: "20px",
                borderRadius: "5px",
            }}>
                <h4 style={{ color: "white", margin: "0px" }}>Invoices Overview</h4>
                <Grid container style={{ padding: "15px 0px 0px 0px" }}>
                    {cardData.map(f => (
                        <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                            <Card style={{ display: "flex", justifyContent: "space-between", background: "#1E1E1E", borderRadius: "5px", margin: "0px", padding: "0px 20px" }}>
                                <h4 style={{ color: "white", margin: "15px 0px" }}> {Object.keys(f)} </h4>
                                <h4 style={{ color: "#218EEB", margin: "15px 0px" }}> {Object.values(f)} </h4>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Card>
        </div>
    )
}

export default Overview