import React from 'react';
import { useEffect } from 'react';
import './paymentCollection.css';
import { getPaymentsByLoanId, getPaymentCollection } from 'api/Payments';
import { useState } from 'react';
import { truncateStringID } from "utils/helper";
import {getCountryCode} from '../../../common/utils/getCountries';

export default ({ response, loanId, companyList, memberId, quoteData }) => {

    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const [paymentDetail, setPaymentDetails] = useState();
    const [paymentCollection, setPaymentCollection] = useState();
    
    useEffect(() => {
        if(companyList){
        const countries = getCountryCode(response, companyList);
        const tax = (response?.taxes!=="" && response?.taxes!==" ") ? parseFloat(response?.taxes) : 0;
        const servicePriceOffer = response?.servicePriceOffer!=='' && response?.servicePriceOffer!==' ' ? parseFloat(response?.servicePriceOffer) : "0";
        const paymentAmount = (servicePriceOffer +  tax).toString(); 
        getPaymentCollection(paymentAmount, countries.financerCountry, response.servicePaymentCurrency, response.consumerMemberUID, countries.traderCountry, idToken)
            .then((res) => {
                setPaymentCollection(res);
            })
            .catch((error) => {
                console.log("paymentCollection error", error)
            });
          }
    }, [response, companyList])
    useEffect(() => {
        getPaymentsByLoanId(loanId, idToken, memberId)
            .then((res) => {
                let dateArray = [];
                res.length > 0 && res.forEach(item => {
                    dateArray.push(new Date(item.updatedDate));
                })
                dateArray.sort((date1, date2) => date1 - date2);
                const latestDate = new Date(dateArray[dateArray.length - 1]).toISOString();
                let newRes = [];
                newRes = res.length > 0 && res.filter((item) => {
                    return new Date(item.updatedDate).toISOString() == latestDate;
                })
                setPaymentDetails(newRes[0]);
            })
            .catch((error) => {
                console.log("paymentByLoan error", error)
            });
    }, [])
    const senderMemberID = paymentDetail?.senderMemberID;
    const receiverMemberID = paymentDetail?.receiverMemberID;
    const currency = paymentDetail?.currencySender;
    const paymentAmount = paymentDetail?.paymentAmount;
    const paymentMethodType = paymentDetail?.paymentServiceProvider;
    return (
        <div>
            <div style={{ backgroundColor: "black", width: "100%", padding: "0%", border:"1px solid white", padding:"20px", paddingTop:'10px', marginTop:'20px' }}>
            <div className='panelHeading' style={{marginTop:'10px', marginBottom:"10px"}}>Payment Collection</div>
                { paymentDetail ? 
                <div>
                <div>
                    <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Status</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentDetail?.paymentStatus ? paymentDetail.paymentStatus : 'New'}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Consumer Country</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{
                                companyList && companyList.map((item) => {
                                    if (item.memberUID === senderMemberID) {
                                        return item.countryCode;
                                    }
                                })
                            }</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Provider Country</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'> {companyList.map((item) => {
                                if (item.memberUID === receiverMemberID) {
                                    return item.countryCode;
                                }
                            })}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Currency</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{currency}</div>
                        </div>
                    </div>
                </div>
                <div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Amount</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentAmount}</div>
                        </div>
                        <div className='overviewListItem'>
                            <div className='overViewListFirstTile'>Payment Collection Method</div>
                            <div className='colon'>:</div>
                            <div className='overViewListSecondTile'>{paymentMethodType}</div>
                        </div>
                    </div>
                </div>
                </div>
                :<div style={{fontSize:"11px"}}>Payment not initiated</div>
              }
            </div>




            { quoteData.modeOfPayment!=="OFFLINE" && paymentCollection && paymentCollection.map((item)=>{
                return <div style={{ backgroundColor: "black", width: "100%", padding: "0%", border: "1px solid white", padding:"20px", paddingTop:"10px", marginTop: "20px"}}>
                    <div className='panelHeading' style={{ marginTop: "10px", marginBottom:'10px'}}>Payment Exchange Rate</div>
                     <div>
                        <div style={{ display: "flex", marginTop: "1%", justifyContent: "center", alignItems: "center" }}>
                            <div className='overviewListItem'>
                                <div className='overViewListFirstTile'>Currency</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.currency}</div>
                            </div>
                            <div className='overviewListItem'>
                                <div className='overViewListFirstTile'>Rate</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.rate}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div className='overviewListItem' style={{width:"49%"}}>
                                <div className='overViewListFirstTile'>Amount</div>
                                <div className='colon'>:</div>
                                <div className='overViewListSecondTile'>{item.amount}</div>
                            </div>
                        </div>
                    </div>
                </div>
            })
            }
        </div>
    )
}