import React, { useContext, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { MdOutlineUploadFile } from "react-icons/md";
import styled from "styled-components";
import { invoiceFinanceContext } from "contexts/invoiceFinance/invoiceFinanceContext";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Content from "components/UI/Layout/Content";
import { invoiceURL, docGroupURL, documentURL } from "services/BaseURLInstance";

import classes from "../invoice.module.css";
import { MdDriveFolderUpload } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import { Success, Error } from "components/notifications/responseHandle";

export default ({ setIsUploadOpen, setIsInvoiveDetailFormOpen }) => {
  const navigate = useNavigate();
  const idToken = JSON.parse(sessionStorage.getItem("idToken"));
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));

  const { setInvoiceData } = useContext(invoiceFinanceContext);
  const [isUploading, setIsUploading] = useState(false);
  const [isError, setIsError] = useState(null);

  const [data, setData] = useState({
    name: "invoice",
    file: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      file: e.target.files[0],
      name: e.target.files[0].name,
    });
  };

  const handleUpload = async (event) => {
    setIsUploading(true);
    event.preventDefault();
    var body = new FormData();
    body.append("name", "invoice");
    body.append("file", data.file);
    body.append("txlSellerMemberId", companyId);
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
      body: body,
    };
    await fetch(`${invoiceURL}invoices/upload`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setIsUploadOpen(false);
        handleUpdateCall(data);
        localStorage.setItem("uploadData", JSON.stringify(data));
        setInvoiceData(data);
        navigate(`./${data.txlInvoiceId}`);
        // setIsInvoiveDetailFormOpen(true);
      });
  };

  const handleUpdateCall = async (data) => {
    let instanceID = null;
    if (data && data.instanceID) {
      instanceID = data.instanceID;
    } else {
      instanceID = data.txlInvoiceId;
    }

    let requestPayload = {
      memberId: companyId,
      ownerId: companyId,
      docReferenceId: data.document.id,
      documentName: data.invoiceNumber,
      docCategory: "SALES_ORDER",
      docSubCategory: "FINAL_INVOICE",
      tags: [`INVOICE-${data.document.id}`],
      participantsToSign: [],
      participantsToShare: [],
      referenceData: [],
    };
    await fetch(
      docGroupURL +
        `bff/documentsGroups/documentUpdate?documentId=${data.document.id}&template=Trade&instanceID=${instanceID}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + idToken,
          "x-api-key":
            "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
        },
        body: JSON.stringify(requestPayload),
      }
    )
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        throw [response.status, await response.json()];
      })
      .then(() => {
        // navigate(-1);
      });
  };

  return (
    <>
      {isUploading ? (
        <>
          <span>Uploading....</span>
          <Loader />
        </>
      ) : (
        <>
          {!isError && (
            <form
              onSubmit={(e) => handleUpload(e)}
              className={classes.UploadForm}>
              <h5>Upload Document</h5>

              <div className={classes.Upload}>
                <label onChange={handleChange} htmlFor="formId">
                  <div className={classes.UploadDiv}>
                    <span style={{ color: "#42a5f5" }}>
                      <input
                        name="file"
                        accept=".doc,.  docx,.pdf,.jpeg"
                        type="file"
                        id="formId"
                        hidden
                      />
                      <MdDriveFolderUpload size={40} />
                    </span>

                    <span className="mx-2">
                      {data.name.split(".").slice(0, -1).join(".")}
                    </span>
                  </div>
                </label>
              </div>

              <ButtonDiv>
                <Button
                  style={{ cursor: "pointer" }}
                  onClick={() => setIsUploadOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit" style={{ cursor: "pointer" }}>
                  Upload
                </Button>
              </ButtonDiv>
            </form>
          )}

          {isError && <span>Something went wrong</span>}

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}
    </>
  );
};

const UploadButton = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0071e9;
  background: #ffffff;
  border: 1px solid #0071ef;
  border-radius: 4px;
  padding: 9px 35px;
  &:hover {
    opacity: 0.5;
  }
`;
const FormStyled = styled.form`
  flex: 1;
  padding: 16px;
`;

const SectionStyled = styled.div`
  padding: 40px;
  margin: 16px;
  background-color: #2e2e2e;
`;

const UploadStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 160px;
  border: 2px dashed #d7dadf;
  p {
    color: #99abbe;
    padding: 5px 0px;
  }
`;

const CircleDiv = styled.div`
  border-radius: 50%;
  border: 1px solid;
  padding: 5px;
  display: flex;
  align-items: center;
  background: #aed4ff;
`;
