import React from "react";
import styles from "../details.module.css";



const Miscellaneous = ({Data}) => {
  const Response = [
    {
      name: "Contract Reference",
      value: Data ? Data.ContrRefr : "",
    },
    {
      name: "Project Reference",
      value: Data ? Data.ProjRefr : "",
    },
    {
      name: "PO Reference",
      value: Data ? Data.PORefr : "",
    },
    {
      name: "PO Reference Date",
      value: Data ? new Date(Data.PORefDt).toLocaleDateString() : "",
    },
  ];
  return (
    <div className={styles.summary}>
      {Response &&
        Response.map((data, i) => (
          <div className={styles.summaryWrapper} key={i}  style={{fontSize:"12px"}}>
            <div>    
              <span> {data.name} </span>
            </div>
            <div>    
              <span style={addBlue}> {data.value} </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Miscellaneous;

const addBlue = {
  color: "#42A5F5",
};
