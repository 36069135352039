export const getCountryCode = (data, companyList) => {
    let countries={
        financerCountry:'',
        traderCountry:'',
    };
     companyList.forEach((item)=>{
        if(data?.financerMemberUID===item.memberUID){
            countries["financerCountry"]=item.countryCode;
              
        }
        else if(data?.consumerMemberUID===item.memberUID){
            countries["financerCountry"]=item.countryCode;
        }
        else if(data?.traderMemberUID===item.memberUID){
            countries["traderCountry"]=item.countryCode;
        }
        else if(data?.providerMemberUID===item.memberUID){
            countries["traderCountry"]=item.countryCode;
        }
     })
     return countries;
}