import * as React from 'react';
import {
    Button, Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle,
    Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function VersionDialog({ versionDialogClose, versionDialogOpen, versionData }) {

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (versionDialogOpen) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [versionDialogOpen]);

    return (
        <div>
            <Dialog
                fullWidth
                open={true}
                onClose={versionDialogClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="scroll-dialog-title">Document Version</DialogTitle>
                <DialogContent >
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Version</StyledTableCell>
                                        <StyledTableCell align="right">Modified Date</StyledTableCell>
                                        <StyledTableCell align="right">Modified By</StyledTableCell>
                                        <StyledTableCell align="right">Validated</StyledTableCell>
                                        <StyledTableCell align="right">Document Link</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {versionData.map((row) => (
                                        <StyledTableRow key={row.id}>
                                            <StyledTableCell component="th" scope="row">
                                                {row.version}
                                            </StyledTableCell>
                                            <StyledTableCell align="right">{"-"}</StyledTableCell>
                                            <StyledTableCell align="right">{" - "}</StyledTableCell>
                                            <StyledTableCell align="right">{row.validated ? "True" : "False"}</StyledTableCell>
                                            <StyledTableCell align="right">{row.downloadUri ? row.downloadUri : "-"}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={versionDialogClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

