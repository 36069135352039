import React, { useEffect, useContext } from "react";
import { tradeURL } from "../../../services/BaseURLInstance";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { RiWechatLine } from "react-icons/ri";
import Table from "react-bootstrap/Table";
import styles from "../invoice.module.css";
import { useState } from "react";
import { CompanyNameFilter } from "../../../utils/helper";
import { ToastContainer } from "react-toastify";
import { truncateStringID } from "utils/helper";
import MultiSelect from "components/fields/MultiSelect";
import DateInput from "components/fields/DateInput";
import { tradeListHeader } from "utils/Section/InvoiceSection";
import cn from "classnames";
import { STATUS } from "utils/Constants";
import SingleToogleTab from "components/UI/ToogleSelect/SingleToogleTab";
import {Error} from "components/notifications/responseHandle";
import { FindCompanyName } from "utils/helper";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Select from "components/fields/Select";
import { TradeContext } from "../tradeProvider";
import { CheckCountryTitle } from "utils/helper";
import { getIdTokenAsync } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { UseRequest } from "utils/API/Request.js";
import NoRecord from "components/no-data/noRecord";

export default ({ hanndleCraateGroup }) => {
  const { companyList } = useContext(TradeContext);
  const [dropDownState, setDropDownState] = useState(true);
  const navigate = useNavigate();
  sessionStorage.setItem("test", 2);
  const [Data, setData] = useState();
  const [newFilterData, setNewFilterData] = useState();
  const [selectedFilter, setSelectedFilter] = useState("ALL");
  const [filterData, setFilterData] = useState();
  const [memberList, setMemberList] = useState();
  const [item, setItem] = useState();
  const [selectStatus, setSelectStatus] = useState([]);
  const [activityDateTo, setActivityDateTo] = useState();
  const [activityDateFrom, setActivityDateFrom] = useState();
  const [consumerList, setConsumerList] = useState([]);
  const [providerList, setProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState([]);
  const [selectedConsumer, setSelectedConsumer] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [provider, setProvider] = useState();
  const [selectOrigin, setSelectOrigin] = useState([]);
  const [selectDestination, setSelectDestination] = useState([]);
  const [selectStatusId, setSelectStatusId] = useState([]);
  const [selectOriginId, setSelectOriginId] = useState([]);
  const [selectDestinationId, setSelectDestinationId] = useState([]);
  const [selectConsumerId, setSelectConsumerId] = useState([]);
  const [selectProviderId, setSelectProviderId] = useState([]);
  const [status, setStatus] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [CountryOriginOptions, setCountryOriginOptions] = useState([]);
  const [CountryDestinationOptions, setCountryDestinationOptions] = useState([]);
  const [tradeData, setTradeData] = useState();
  const { instance, accounts } = useMsal();
  const companyId = JSON.parse(sessionStorage.getItem("companyId"));
  

  useEffect(()=>{
      setIsLoading(true);
      let URL = `${tradeURL}trades?memberID=${companyId}`;    
        getIdTokenAsync(instance, accounts).then((response) => {
          UseRequest(URL, null, response)
          .then((data) => {
            data.sort(
              (a, b) => Date.parse(b.createdDate) - Date.parse(a.createdDate)
            );
            setTradeData(data);
            setData(data);
            setNewFilterData(data);
          })
            .catch(() => {})
            .finally(() => {
              setIsLoading(false);
            });
        });
  },[])

  useEffect(() => {
    let newdate = Date.parse(activityDateTo);
    let olddate = Date.parse(activityDateFrom);
    if (
      (selectConsumerId && selectConsumerId.length > 0) ||
      (selectProviderId && selectProviderId.length > 0) ||
      activityDateFrom ||
      activityDateTo ||
      (selectStatusId && selectStatusId.length > 0) ||
      (selectOrigin && selectOrigin.length > 0) ||
      (selectDestinationId && selectDestinationId.length > 0)
    ) {
      let newData =
        Data &&
        Data.filter(
          (data) =>
            (selectConsumerId.length > 0
              ? selectConsumerId.includes(data.sellerMemberId)
              : data) &&
            (selectProviderId.length > 0
              ? selectProviderId.includes(data.buyerMemberId)
              : data) &&
            (activityDateFrom
              ? Date.parse(data.createdDate) >= olddate
              : data) &&
            (activityDateTo ? Date.parse(data.createdDate) <= newdate : data) &&
            (selectStatusId.length > 0
              ? selectStatusId.includes(data.status)
              : data) &&
            (selectOriginId.length > 0
              ? selectOriginId.includes(data.countryOfOrigin)
              : data) &&
            (selectDestinationId.length > 0
              ? selectDestinationId.includes(data.countryOfDestination)
              : data)
        );
      setNewFilterData(newData);
    } else if (
      selectConsumerId.length === 0 &&
      selectProviderId.length === 0 &&
      !activityDateFrom &&
      !activityDateTo &&
      selectStatusId.length === 0 &&
      selectOriginId.length === 0 &&
      selectDestinationId.length === 0
    ) {
      setNewFilterData(Data);
    }
  }, [
    selectConsumerId,
    selectProviderId,
    activityDateTo,
    activityDateFrom,
    selectStatusId,
    selectOriginId,
    selectDestinationId,
  ]);

  useEffect(() => {
    if (tradeData) {
      // STATUS
      const uniqueStatus = [...new Set(tradeData.map((item) => item.status))];
      let filterStatus =
        uniqueStatus &&
        uniqueStatus.map((item) => {
          return { label: item, value: item };
        });
      setStatus(filterStatus);

      // CountryOfOrigin
      const uniqueCountryOfOrigin = [
        ...new Set(tradeData.map((item) => item.countryOfOrigin)),
      ];
      let filterCountry =
        uniqueCountryOfOrigin &&
        uniqueCountryOfOrigin.map((item) => {
          return { label: CheckCountryTitle(item), value: item };
        });
      setCountryOriginOptions(filterCountry);

      // CountryOfDestination
      const uniqueCountryOfDestination = [
        ...new Set(tradeData.map((item) => item.countryOfDestination)),
      ];
      let filterAACountry =
        uniqueCountryOfDestination &&
        uniqueCountryOfDestination.map((item) => {
          return { label: CheckCountryTitle(item), value: item };
        });
      setCountryDestinationOptions(filterAACountry);
    }
  }, [tradeData]);

  useEffect(() => {
    if (selectedConsumer) {
      let temList = [];
      selectedConsumer.map((select) => {
        temList.push(select.value);
      });
      setSelectConsumerId(temList);
    }

    if (selectedProvider) {
      let temList = [];
      selectedProvider.map((select) => {
        temList.push(select.value);
      });
      setSelectProviderId(temList);
    }

    if (selectStatus) {
      let temList = [];
      selectStatus.map((select) => {
        temList.push(select.value);
      });
      setSelectStatusId(temList);
    }

    if (selectOrigin) {
      let temList = [];
      selectOrigin.map((select) => {
        temList.push(select.value);
      });
      setSelectOriginId(temList);
    }

    if (selectDestination) {
      let temList = [];
      selectDestination.map((select) => {
        temList.push(select.value);
      });
      setSelectDestinationId(temList);
    }
  }, [
    selectedConsumer,
    selectedProvider,
    selectStatus,
    selectOrigin,
    selectDestination,
  ]);

  const handleMoveToTrade = async (e, value) => {
    const filterData = Data.filter((item) => {
      return item.tradeId === value.tradeId;
    });
    navigate(`../tradeflow/${filterData[0].tradeId}`, {
      state: { companyList: companyList },
    });
  };

  const fetchDatas = async (companyId, id2) => {
    const idToken = JSON.parse(sessionStorage.getItem("idToken"));
    const requestOptions = {
      method: "GET",
      requireAuthentication: true,
      headers: {
        Authorization: "Bearer " + idToken,
        "x-api-key":
          "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
      },
    };
    await fetch(
      `${process.env.REACT_APP_NETWORK_URL}Persons/Organization?organizationMemberId=${companyId}&organizationMemberId=${id2}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        let nData = [];

        for (let i = 0; i < data?.length; i++) {
          nData.push(data[i]?.memberUID);
        }
        setMemberList(nData);
      });
  };

  const chatHandler = (e, ds) => {
    if (ds?.buyerMemberId !== "" && ds?.sellerMemberId !== "") {
      setItem(ds);
      fetchDatas(ds?.buyerMemberId, ds?.sellerMemberId);
    }
  };

  const serviceProviders = [
    {
      label: "Buyer",
      value: "Buyer",
    },
    {
      label: "Seller",
      value: "Seller",
    },
    {
      label: "Freight Service Provider",
      value: "Freight Service Provider",
    },
  ];

  const handleMultiChange = (e, name) => {
    if (name === "status") {
      const { value } = e.target;
      setSelectStatus(value);
    }
    if (name === "countryOfOrigin") {
      const { value } = e.target;
      setSelectOrigin(value);
    }

    if (name === "countryOfDestination") {
      const { value } = e.target;
      setSelectDestination(value);
    }
    if (name === "activityDateFrom") {
      setActivityDateFrom(new Date(e).toISOString());
    }
    if (name === "activityDateTo") {
      if (activityDateFrom && activityDateFrom <= new Date(e).toISOString()) {
        setActivityDateTo(new Date(e).toISOString());
      } else {
        Error("Activity date to should be greater then activity date from");
      }
    }
    if (name === "consumer") {
      const { value } = e.target;
      setSelectedConsumer(value);
    }

    if (name === "provider") {
      const { value } = e.target;
      setSelectedProvider(value);
    }
  };

  useEffect(() => {
    if (Data && companyList) {
      const uniqueConsumer = [
        ...new Set(Data.map((item) => item.sellerMemberId)),
      ];
      let filterConsumer =
        uniqueConsumer &&
        uniqueConsumer.map((item) => {
          return {
            label: FindCompanyName(companyList && companyList, item),
            value: item,
          };
        });
      setConsumerList(filterConsumer);
      const uniqueProvider = [
        ...new Set(Data.map((item) => item.buyerMemberId)),
      ];
      let filterProvider =
        uniqueProvider &&
        uniqueProvider.map((item) => {
          return {
            label: FindCompanyName(companyList && companyList, item),
            value: item,
          };
        });
      setProviderList(filterProvider);
    }
  }, [Data, companyList]);

  const Status = (status) => {
    switch (status) {
      case "NEW":
        return STATUS(status, "primary");
      case "IN_PROGRESS":
        return STATUS(status, "warning");
      case "COMPLETED":
        return STATUS(status, "success");
      case "NOT_STARTED":
        return STATUS(status, "warningS");
      case "CANCELLED":
        return STATUS(status, "danger");
      default:
        return status;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = () => {
    setOpen(false);
    if (provider) {
      navigate(`../newtrade`, { state: { provider: provider } });
    } else {
      Error("Please Select Provider");
    }
  };

  const handleProviderType = (e) => {
    setProvider(e.target.value);
  };

  const handleSearchFilter = (e) => {
    const text = e.target.value.toString().toLowerCase();
    const filterList = tradeData.filter((item) => {
      return item.tradeId.includes(text);
    });
    setNewFilterData(filterList);
  };

  return (
    <>
      {isLoading ?  <div style={{marginTop:"10%"}}><Loader /></div> 
      : Data !== "" && (
        <div
          style={{
            backgroundColor: "#FFFFFF1A",
            padding: "1%",
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
          }}>
              <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    width: "40%",
                    height: "40%",
                    backgroundColor: "#343434",
                    color: "white",
                  },
                }}>
                <div style={{ padding: "3%", height: "100%" }}>
                  <div style={{ backgroundColor: "black", height: "100%" }}>
                    <div
                      style={{
                        padding: "4%",
                        fontSize: "18px",
                        paddingLeft: "8%",
                      }}>
                      Select your Role
                    </div>
                    <DialogContent sx={{ margin: "10px" }}>
                      <Select
                        name="serviceProvider"
                        placeholder="Select"
                        selected={selectedProvider}
                        options={serviceProviders}
                        handleChange={(e) => handleProviderType(e)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button onClick={handleSelect}>Select</Button>
                    </DialogActions>
                  </div>
                </div>
              </Dialog>
          <SingleToogleTab
            title="Search Trades"
            select={true}
            Component={
              <>
                <div style={{ display: "flex" }}>
                  <div style={{ width: "50%", textAlign: "left" }}>
                    <input
                      style={{
                        padding: "1% 2%",
                        borderRadius: "5px",
                        width: "60%",
                        height: "40px",
                      }}
                      name="search"
                      placeholder="Search Trade ID.."
                      onChange={(e) => handleSearchFilter(e)}></input>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <button
                      className={styles.uploadInvoiceBtn}
                      style={{
                        fontSize: "16px",
                        border: "none",
                        width: "60%",
                        height: "40px",
                      }}
                      onClick={handleClickOpen}>
                      +Initiate New Trade
                    </button>
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      width: "25%",
                      marginTop: "1%",
                      marginRight: "0.5%",
                    }}>
                    <MultiSelect
                      name="consumer"
                      selected={selectedConsumer}
                      placeholder="Seller"
                      options={consumerList}
                      handleChange={(e) => handleMultiChange(e, "consumer")}
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      marginTop: "1%",
                      marginRight: "0.5%",
                    }}>
                    <MultiSelect
                      name="provider"
                      options={providerList}
                      selected={selectedProvider}
                      placeholder="Buyer"
                      handleChange={(e) => handleMultiChange(e, "provider")}
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      marginTop: "1%",
                      marginRight: "0.5%",
                    }}>
                    <DateInput
                      name="activityDateFrom"
                      placeholder="Date From"
                      backDate={true}
                      value={activityDateFrom ? activityDateFrom : ""}
                      handleChange={(e) =>
                        handleMultiChange(e, "activityDateFrom")
                      }
                    />
                  </div>
                  <div style={{ width: "25%", marginTop: "1%" }}>
                    <DateInput
                      name="activityDateTo"
                      placeholder="Date To"
                      backDate={true}
                      value={activityDateTo ? activityDateTo : ""}
                      handleChange={(e) =>
                        handleMultiChange(e, "activityDateTo")
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div
                    style={{
                      width: "25%",
                      marginTop: "1%",
                      marginRight: "0.5%",
                    }}>
                    <MultiSelect
                      name="status"
                      selected={selectStatus?.status}
                      placeholder="Status"
                      options={status}
                      handleChange={(e) => handleMultiChange(e, "status")}
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      marginTop: "1%",
                      marginRight: "0.5%",
                    }}>
                    <MultiSelect
                      name="countryOfOrigin"
                      selected={selectOrigin?.countryOfOrigin}
                      placeholder="Country of Origin"
                      options={CountryOriginOptions}
                      handleChange={(e) =>
                        handleMultiChange(e, "countryOfOrigin")
                      }
                    />
                  </div>
                  <div
                    style={{
                      width: "25%",
                      marginTop: "1%",
                      marginRight: "0.5%",
                    }}>
                    <MultiSelect
                      name="countryOfDestination"
                      selected={selectDestination?.countryOfDestination}
                      placeholder="Country of Destination"
                      options={CountryDestinationOptions}
                      handleChange={(e) =>
                        handleMultiChange(e, "countryOfDestination")
                      }
                    />
                  </div>
                  <div style={{ width: "25%", marginTop: "1%" }}></div>
                </div>
              </>
            }
          />
          <div>
            <div className={styles.wrapper}>
              <div style={{ display: "flex" }}>
                <div
                  className={styles.wrapperTitle}
                  style={{ width: "50%", fontSize: "18px" }}>
                  Trades Search Results
                </div>
              </div>
              <div
                className={styles.tableContainer}
                style={{ marginBottom: "-1.3%" }}>
                {Data?.length > 0 && newFilterData ? (
                  <>
                  <Table bordered responsive className={cn(styles.Table)}>
                    <thead>
                      <tr>
                        {tradeListHeader.map((header, index) => (
                          <td key={index}>{header.name}</td>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {newFilterData &&
                        newFilterData.map((user, index) => (
                          <tr key={index}>
                            <td
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleMoveToTrade(e, user)}
                              className={cn(styles.columnHover)}>
                              {user.tradeId
                                ? truncateStringID(user.tradeId, 7, "TR")
                                : "NA"}
                            </td>
                            <td>
                              {CompanyNameFilter(
                                companyList,
                                user.buyerMemberId
                              ).length >= 1
                                ? CompanyNameFilter(
                                    companyList,
                                    user.buyerMemberId
                                  )[0].memberName
                                : ""}
                            </td>
                            <td>
                              {CompanyNameFilter(
                                companyList,
                                user.sellerMemberId
                              ).length >= 1
                                ? CompanyNameFilter(
                                    companyList,
                                    user.sellerMemberId
                                  )[0].memberName
                                : ""}
                            </td>
                            <td>{CheckCountryTitle(user.countryOfOrigin)}</td>
                            <td>
                              {CheckCountryTitle(user.countryOfDestination)}
                            </td>
                            <td style={{ minWidth: "120px" }}>
                              {Status(user.status)}
                            </td>
                            <td>
                              {new Date(user.createdDate).toLocaleDateString()}
                            </td>
                            <td>
                              <RiWechatLine size={22} />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                  {newFilterData && newFilterData.length === 0 && <NoRecord/>}
                  </>
                ) : (
                  <NoRecord/>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
