import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { TRADE_FORM } from "utils/Section/FormInfo";

import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { Countries } from "utils/DropDown/Country";
import MultiSelect from "components/fields/MultiSelect";
import { TextArea } from "components/fields";
import SelectWithSearch from "components/fields/SelectWithSearch";

const TradesOthers = ({ tradesData, setTradesData }) => {
  let sourceOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let destinationOptions = [
    {
      label: "PORT",
      value: "PORT",
    },
    {
      label: "COUNTRY",
      value: "COUNTRY",
    },
  ];

  let transport = [
    {
      label: "AIR",
      value: "AIR",
    },
    {
      label: "SEA",
      value: "SEA",
    },
    {
      label: "COURIER",
      value: "COURIER",
    },
  ];
  let paymentTerms = [
    {
      label: "Advance Payment",
      value: "Advance Payment",
    },
    {
      label: "Letter of credit",
      value: "Letter of credit",
    },
    {
      label: "Documents against Payments - D.A.P or D/P basis",
      value: "Documents against Payments - D.A.P or D/P basis",
    },
    {
      label: "Documents against Acceptance (D/A)",
      value: "Documents against Acceptance (D/A)",
    },
    {
      label: "Open Account",
      value: "Open Account",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let incoterm = [
    {
      label: "EXW",
      value: "EXW",
    },
    {
      label: "DDP (Delivered Duty Paid)",
      value: "DDP (Delivered Duty Paid)",
    },
    {
      label: "FOB (Free on Board)",
      value: "FOB (Free on Board)",
    },
    {
      label: "CIF (Cost, Insurance and Freight) ",
      value: "CIF (Cost, Insurance and Freight)",
    },
    {
      label: "FAS (Free Alongside Ship)",
      value: "FAS (Free Alongside Ship)",
    },
    {
      label: "OTHERS",
      value: "OTHERS",
    },
  ];
  let qtyUnitOptions = [
    {
      label: "MT",
      value: "MT",
    },
    {
      label: "KG",
      value: "KG",
    },
    {
      label: "LB",
      value: "LB",
    },
    {
      label: "BOXES",
      value: "BOXES",
    },
    {
      label: "PCS",
      value: "PCS",
    },
  ];

  let tradeType = [
    {
      label: "Free Trade Agreement",
      value: "Free Trade Agreement",
    },
    {
      label: "Preferential Trade Agreement",
      value: "Preferential Trade Agreement",
    },
    {
      label: " Comprehensive Economic Partnership Agreement",
      value: " Comprehensive Economic Partnership Agreement",
    },
    {
      label: "  Comprehensive Economic Cooperation Agreement",
      value: "  Comprehensive Economic Cooperation Agreemen",
    },
    {
      label: "Framework agreement",
      value: "PCFramework agreementS",
    },
  ];

  // let inspectionType = [
  //   {
  //     label: "Packaging ",
  //     value: "Packaging",
  //   },
  //   {
  //     label: "Labeling",
  //     value: "Labeling",
  //   },

  //   {
  //     label: "Documentation and Document Verification",
  //     value: "Documentation and Document Verification",
  //   },
  //   {
  //     label: "Compliance with all relevant regulations and standards",
  //     value: "Compliance with all relevant regulations and standards",
  //   },
  //   {
  //       label: "Physical inspection",
  //       value: "Physical inspection",
  //     },
  //     {
  //       label: "Sample testing",
  //       value: "Sample testing",
  //     },
  // ];

  const [selectList, setSelectList] = useState([]);
  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  const handleRadio = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    setTradesData({
      ...tradesData,
      [name]: value,
    });
  };

  let services = [
    {
      label: " Ensure shipment is custom compliant",
      value: " Ensure shipment is custom compliant",
    },
    {
      label: "Make all necessary customs and additional agency declarations",
      value: "Make all necessary customs and additional agency declarations",
    },
    {
      label: "Make payment for the duties and taxes",
      value: "Make payment for the duties and taxes",
    },
    {
      label:
        "Follow up with the government authorities regarding the status of imported / Exported goods",
      value:
        "BOXFollow up with the government authorities regarding the status of imported / Exported goodsES",
    },
    {
      label: "Apply for import/export permits",
      value: "Apply for import/export permits",
    },
  ];
  const handleDateChange = (e, field) => {
    setTradesData({
      ...tradesData,
      [field]: new Date(e).toISOString(),
    });
  };

  let bb = Object.keys(port_codes);
  let cc = Object.values(port_codes);
  let port_code = [];

  bb.map((q, i) =>
    cc.map((w, o) => {
      if (i === o) {
        port_code.push({ label: w.name, value: q });
      }
    })
  );

  // const handleMultiChange = (event) => {
  //   if (event.target.name === "validatedBy") {
  //     setDocumentData({
  //       ...DocumentData,
  //       [event.target.name]: event.target.value,
  //     });
  //   }
  // const handleMultiChange = (e) => {
  //   const { value } = e.target;
  //   setSelectList(value);
  // };
  const handleMultiChange = (e, name) => {
    if (name === "service") {
      setTradesData({
        ...tradesData,

        [e.target.name]: e.target.value,
      });
      console.log(tradesData, "hello");
    }
    //   else if(name === "inspectionType"){

    //      setTradesData({
    //       ...tradesData,
    //       [e.target.name]: e.target.value,
    //     });

    //   }
  };

  // const handleRadio = (event) => {
  //   const target = event.target;
  //   const name = target.name;
  //   const value = target.value;

  //   setTradesData({
  //     ...tradesData,
  //     [name]: value,
  //   });
  // };

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "loadingDate") {
        setDatePicker1(newDate1);
        // setTradesData({
        //   ...tradesData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "unloadingDate") {
        setDatePicker2(newDate1);
        // setTradesData({
        //   ...tradesData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setTradesData({
        ...tradesData,
        [date]: dateString,
      });
    } else if (e.target.name === "serviceType") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "tradeType") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contratDetails") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "personName") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contactName") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "emailadd") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "companyName") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "exporterCompanyName") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "exporterCountry") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "exporterAddress") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "exporterName") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "importerName") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "importerCountry") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "importerEmail") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "exporterEAdd") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "importerAddress") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerms") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "paymentTerm") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "incoterms") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "incoterm") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "importerName") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyType") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "countryj") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "CommodityValue") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyAmount") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "currencyType") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "startDate") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "enddate") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "origin") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "destination") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "transport") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "requirement") {
      setTradesData({
        ...tradesData,
        [e.target.name]: e.target.value,
      });
    }
  };
  sessionStorage.setItem("tradesData", JSON.stringify(tradesData));
  console.log("tradesData", "saurabh", tradesData);

  return (
    <>
      <Content padding="1% 2% 1.4% 2%">
        <div style={{ marginLeft: "10px" }}>
          <OverLayTrigger value={TRADE_FORM} />
        </div>
        <div style={{ margin: "10px 10px 0px 10px" }}>
          Trade Contract Required for
        </div>

        <div
          className={classes.source}
          style={{
            marginBottom: "10px",
            marginLeft: "10px",
            marginRight: "10px",
            marginTop: "0px",
          }}
        >
          <div style={{ marginLeft: "1%", width: "50%" }}>
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="IMPORT"
              onChange={handleRadio}
              checked={tradesData.serviceType == "IMPORT"}
            />
            <label>IMPORT</label>
          </div>{" "}
          <div
            style={{
              // fontSize: "25px",
              marginLeft: "1%",
              width: "50%",
              textAlign: "right",
              marginRight: "1%",
            }}
          >
            <input
              className={classes.radio}
              type="radio"
              name="serviceType"
              value="EXPORT"
              onChange={handleRadio}
              checked={tradesData.serviceType == "EXPORT"}
            />
            <label>EXPORT</label>
          </div>
        </div>

        <div>{/* <OverLayTrigger value={COMPLIANCE_IMPORT} /> */}</div>

        <div style={{ marginLeft: "10px" }}>
          Select Type of Service required
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              paddingRight: "20px",
              // marginBottom: "1%",
              // marginTop: "1%",
              // marginLeft: "10px",
              // marginRight: "10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Select
              options={tradeType}
              name="tradeType"
              placeholder="Trade Type"
              handleChange={handleChange}
              value={tradesData.tradeType}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          {" "}
          <div
            style={{
              width: "100%",
              // marginBottom: "1%",
              // marginTop: "1%",
              // marginLeft: "10px",
              // marginRight: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="contratDetails"
              placeholder="Trade Contract Description"
              handleChange={handleChange}
              value={tradesData.contratDetails}
              // readOnly={true}
            />
          </div>
        </div>
        <label style={{ marginLeft: "10px" }}>Personal Details</label>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="personName"
              placeholder="Name*"
              handleChange={handleChange}
              value={tradesData.personName}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="contactName"
              placeholder="Contact Details*"
              handleChange={handleChange}
              value={tradesData.contactName}

              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="emailadd"
              placeholder="E-mail Address*"
              handleChange={handleChange}
              value={tradesData.emailadd}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="companyName"
              placeholder="Comapany Name*"
              handleChange={handleChange}
              value={tradesData.companyName}

              // readOnly={true}
            />
          </div>
        </div>

        <label style={{ marginLeft: "10px" }}>Exporter Details</label>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="exporterCompanyName"
              placeholder="Exporter Company Name*"
              handleChange={handleChange}
              value={tradesData.exporterCompanyName}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="exporterCountry"
              placeholder="Exporter Country*"
              handleChange={handleChange}
              value={tradesData.exporterCompany}

              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="exporterEAdd"
              placeholder="Exporter E-mail Address*"
              handleChange={handleChange}
              value={tradesData.exporterEAdd}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="exporterAddress"
              placeholder="Exporter Address*"
              handleChange={handleChange}
              value={tradesData.exporterAddress}

              // readOnly={true}
            />
          </div>
        </div>
        <label style={{ marginLeft: "10px" }}>Importer Details</label>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="importerCompanyName"
              placeholder="Importer Company Name*"
              handleChange={handleChange}
              value={tradesData.importerName}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "5px 10px 10px 10px",
            }}
          >
            <Input
              name="importerCountry"
              placeholder="Importer Country*"
              handleChange={handleChange}
              value={tradesData.importerCountry}

              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="importerEmail"
              placeholder="Importer E-mail Address*"
              handleChange={handleChange}
              value={tradesData.importerEmail}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="importerAddress"
              placeholder="Importer Address*"
              handleChange={handleChange}
              value={tradesData.importerAddress}

              // readOnly={true}
            />
          </div>
        </div>
        {/* <div className={classes.source}> */}
        <label style={{ marginLeft: "10px" }}> Juridiction Country</label>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginLeft: "10px",
              margin: "5px 10px 10px 10px",
              paddingRight: "20px",
            }}
          >
            <SelectWithSearch
              options={Countries}
              name="countryj"
              placeholder="Country* "
              handleChange={handleChange}
              value={tradesData.countryj}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        <hr
          style={{ border: "2px solid white", margin: "10px 10px 10px 10px" }}
        ></hr>
        <div>
          <div>
            <div>
              <label style={{ marginLeft: "10px" }}>COMMODITY</label>
              <div className={classes.source}>
                <div
                  style={{
                    width: "50%",
                    // marginBottom: "2%",
                    // marginTop: "1%",
                    // marginLeft: "10px",
                    margin: "5px 10px 10px 10px",
                    paddingRight: "20px",
                  }}
                >
                  <Select
                    options={Industry}
                    name="commodity"
                    placeholder="Product Category*"
                    handleChange={handleChange}
                    value={tradesData.commodity}
                    // readOnly={true}
                    //
                    //     name="memberlist"
                    //     selected={selectStatus}
                    //     placeholder="Status"
                    //     options={InvoiceStatus}
                    //
                    //   />
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={classes.source}>
            <div
              style={{
                width: "50%",
                // marginBottom: "2%",
                // marginLeft: "10px",
                margin: "10px",
                paddingRight: "20px",
              }}
            >
              <Input
                name="CommodityValue"
                placeholder="Commodity Value*"
                handleChange={handleChange}
                value={tradesData.CommodityValue}
                // readOnly={true}
              />
            </div>
          </div>

          <div className={classes.source}>
            <div
              style={{
                width: "50%",
                // marginBottom: "2%",
                // marginLeft: "10px",
                margin: "10px",
              }}
            >
              <Select
                options={Currenciesr}
                name="currencyType"
                placeholder="Currency*"
                value={tradesData.currencyType}
                handleChange={handleChange}
              />
            </div>
            <div
              style={{
                width: "50%",
                // marginBottom: "2%",
                // marginLeft: "10px",
                margin: "10px",
              }}
            >
              <Input
                name="currencyAmount"
                placeholder={`Estimated Cost of Goods or Services (${tradesData.currencyType})`}
                handleChange={handleChange}
                value={tradesData.currencyAmount}
                // readOnly={true}
              />
            </div>
          </div>
        </div>

        <div>
          {/* <label>INCOTERM 


</label> */}
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              // marginBottom: "2%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Select
              options={incoterm}
              name="incoterms"
              placeholder="Incoterm*"
              value={tradesData.incoterms}
              handleChange={handleChange}
            />
          </div>

          {tradesData.incoterms === "OTHERS" ? (
            <div
              style={{
                width: "100%",
                // marginBottom: "2%",
                // marginLeft: "10px",
                margin: "10px",
              }}
            >
              <Input
                name="incoterm"
                placeholder="Please Enter Your Incoterm"
                value={tradesData.incoterm}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <div className={classes.source}>
          <div
            style={{
              width: "100%",
              // marginBottom: "2%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Select
              options={paymentTerms}
              name="paymentTerms"
              placeholder="Payment Term*"
              value={tradesData.paymentTerms}
              handleChange={handleChange}
            />
          </div>
          {tradesData.paymentTerms == "OTHERS" ? (
            <div style={{ width: "100%", margin: "10px" }}>
              <Input
                name="paymentTerm"
                placeholder="Please Enter Your Payment Term"
                value={tradesData.paymentTerm}
                handleChange={handleChange}
                // readOnly={true}
              />
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginRight: "10px",
              // marginBottom: "2%",
              // marginTop: "1%",
              margin: "10px",
            }}
          >
            <DateInput
              name="startDate"
              placeholder="Shipment Start Date*"
              handleChange={(e) => handleDateChange(e, "startDate")}
              value={tradesData.startDate}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",

              // marginBottom: "2%",
              // marginTop: "1%",
              // marginRight: "10px",
              margin: "10px",
            }}
          >
            <DateInput
              name="enddate"
              placeholder="Shipment End Date*"
              handleChange={(e) => handleDateChange(e, "enddate")}
              value={tradesData.enddate}

              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="origin"
              placeholder="Origin Of Shipment*"
              handleChange={handleChange}
              value={tradesData.origin}
              // readOnly={true}
            />
          </div>

          <div
            style={{
              width: "50%",
              // marginBottom: "2%",
              // marginTop: "1%",
              // marginLeft: "10px",
              margin: "10px",
            }}
          >
            <Input
              name="destination"
              placeholder="Destination of Shipment*"
              handleChange={handleChange}
              value={tradesData.destination}

              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source}>
          <div
            style={{
              width: "50%",
              // marginBottom: "1%",
              // marginLeft: "10px",
              margin: "10px",
              paddingRight: "20px",
            }}
          >
            <Select
              options={transport}
              name="transport"
              placeholder="Mode Of transport*"
              handleChange={handleChange}
              value={tradesData.transport}
            />
          </div>{" "}
        </div>

        <div
          style={{
            width: "100%",
            // marginBottom: "2%",
            // marginLeft: "10px",
            margin: "10px",
            paddingRight: "20px",
          }}
        >
          <TextArea
            name="requirement"
            placeholder="Special Requirements of Shipment   "
            value={tradesData.requirement}
            handleChange={handleChange}
          />
        </div>
      </Content>
    </>
  );
};

export default TradesOthers;
