import React, { useState } from 'react';
import Styles from './styles';
import useAxios from "../../../services/useAxios";
import { requsitionApi } from '../../../services/BaseURLInstance';
import { useNavigate } from 'react-router-dom';
import { ButtonStyled } from "../../../components/Container.styled";
import { getClaim, getIdToken } from "utils/authUtil";
import { useMsal } from "@azure/msal-react";
import { Grid } from '@mui/material';
import Input from "components/fields/Input";
import DateInput from "components/fields/DateInput";
import Select from "components/fields/Select";



export default ({ data }) => {

    const { instance, accounts } = useMsal();
    const [idToken, setIdToken] = useState(null);
    getIdToken(instance, accounts, setIdToken);
    const companyId = getClaim(accounts, "company_member_id");
    const navigate = useNavigate();
    const { response, isLoading, error } = useAxios({
        api: requsitionApi,
        method: "get",
        url: `finance/requisitions/${data.refFinanceRequisitionId}`,
        config: JSON.stringify({
            requireAuthentication: true,
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + idToken,
                "x-api-key":
                    "PMAK-622794ce55bddf5b7a8531bb-f9f7e2b21a003698e8376adeb31fb25591",
            },
        }),
    });
    const handleBackToFinanceDeal = () => {
        navigate('/invoicefinancing/financing')
    }
   
    return (
        <div style={{ backgroundColor: "black", padding: "2%", marginTop: "1%", borderRadius: "5px" }}>
            {(response) &&
                <Grid container>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="Invoice Amount"
                            placeholder="Invoice Amount *"
                            value={response.totalAmountWithInstrument}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="Seller ID"
                            placeholder="Seller ID *"
                            readOnly={true}
                            value={response.sellerMemberUID}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="Loan Product Type"
                            placeholder="Loan Product Type *"
                            value={response.financeProductCategory}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="Invoice Reference"
                            placeholder="Invoice Reference *"
                            value={response.invoiceUID}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="Required Finance Amount"
                            placeholder="Required Finance Amount *"
                            value={response.financeAmountRequested}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="Interest Rate"
                            placeholder="Interest Rate *"
                            value={response.interestRateMin}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px",marginRight:".82%" }}>
                        <DateInput
                            name="Disbursement Date"
                            placeholder="Disbursement Date *"
                            value={response.preferredPaymentDate}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                        <Input
                            name="Disbursement Mode"
                            placeholder="Disbursement Mode *"
                            value={response.preferredPaymentMethod}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px",marginRight:".82%" }}>
                        <DateInput
                            name="Financing Start Date"
                            placeholder="Financing Start Date *"
                            value={response.financeRequsitionStartDate}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={5.9} style={{ padding: "10px" }}>
                        <DateInput
                            name="Financing End Date"
                            placeholder="Financing End Date * *"
                            value={response.financeRequsitionEndDate}
                            readOnly={true}
                        />
                    </Grid>
                </Grid>
            }
             <div className='requsitionButtonDiv'>
                <ButtonStyled onClick={handleBackToFinanceDeal}>Back to finance deals</ButtonStyled>
                <ButtonStyled>Request More Info</ButtonStyled>
                <ButtonStyled>abort</ButtonStyled>
            </div>
        </div>
    )
}

{/* <Styles>
        <div style={{marginTop:"33px",marginLeft:"41px"}}>Financing Request</div>
           {
               (response) &&
                  <div className="inputFieldView">
                            <div className="setOfInput">
                                <div className="inputField">
                                    <div className="label">
                                        <label For="dealid">Invoice Amount</label>
                                    </div>
                                    <div>
                                        <input type="text" value={response.totalAmountWithInstrument}/>
                                    </div>  
                                </div>
                                <div className="inputField">
                                    <div className="label">
                                        <label For="NBFCID">Seller ID</label>
                                    </div>
                                    <div>
                                        <input type="text" value={data.memberName}/>
                                    </div>  
                                </div>
                            </div>
                        <div className="setOfInput">
                            <div className="inputField">
                                <div className="label">
                                    <label For="LoanProductType">Loan Product Type</label>
                                </div>
                                <div>
                                    <input type="text" value={response.financeProductCategory}/>
                                </div>  
                            </div>
                            <div className="inputField">
                                <div className="label">
                                    <label For="InvoiceReference">Invoice Reference</label>
                                </div>
                                <div>
                                    <input type="text" value={response.invoiceUID}/>
                                </div>  
                            </div>
                        </div>
                        <div className="setOfInput">
                            <div className="inputField">
                                <div className="label">
                                    <label For="FinanceAmount">Required Finance Amount</label>
                                </div>
                                <div>
                                    <input type="text" value={response.financeAmountRequested}/>
                                </div>  
                            </div>
                            <div className="inputField">
                                <div className="label">
                                    <label For="InterestRate">Interest Rate</label>
                                </div>
                                <div>
                                    <input type="text" value={response.interestRateMin}/>
                                </div>  
                            </div>
                        </div>
                        <div className="setOfInput">
                            <div className="inputField">
                                <div className="label">
                                    <label For="RequestedFinanceAmount">Disbursement Date</label>
                                </div>
                                <div>
                                    <input type="text" value={response.preferredPaymentDate}/>
                                </div>  
                            </div>
                            <div className="inputField">
                                <div className="label">
                                    <label For="SellerID">Disbursement Mode</label>
                                </div>
                                <div>
                                    <input type="text" value={response.preferredPaymentMethod}/>
                                </div>  
                            </div>
                        </div>
                        <div className="setOfInput">
                            <div className="inputField">
                                <div className="label">
                                    <label For="DealExpiryDate">Financing Start Date *</label>
                                </div>
                                <div>
                                    <input type="text" value={response.financeRequsitionStartDate} />
                                </div>  
                            </div>
                            <div className="inputField">
                                <div className="label" >
                                    <label For="PaymentMethod">Financing End Date *</label>
                                </div>
                                <div >
                                    <input type="text"  value={response.financeRequsitionEndDate}/>
                                </div>  
                            </div>
                        </div>
                        <div className='requsitionButtonDiv'>
                            <ButtonStyled onClick={handleBackToFinanceDeal}>Back to finance deals</ButtonStyled>
                            <ButtonStyled>Request More Info</ButtonStyled>
                            <ButtonStyled>abort</ButtonStyled>
                        </div>
                </div>
                
             }
    </Styles>
  */}