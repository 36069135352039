import React, { useState } from "react";
import Select, { components } from "react-select";
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.key !== "placeholder" ? child : null
      )}
    </ValueContainer>
  );
};

const colourStyles = {
  control: (styles, { isFocused }) => ({
    ...styles,
    backgroundColor: "black",
    color: "red",
    cursor: "pointer",
    padding: "5px",
    fontSize: "16px",
    minHeight: "55px",
    border: "1px solid #42a5f5",
    boxShadow: isFocused ? null : null,
    ":hover": {},
    ":active": {},
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#42a5f5",
      color: "white",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "white",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ":hover": {
      backgroundColor: "black",
      color: "white",
    },
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#42a5f5" : null,
      color: isFocused ? "white" : "black",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#42a5f5",
      },
      "&:click": {},
    };
  },
  singleValue: (provided) => ({
    ...provided,
    color: "white",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),

  valueContainer: (provided, state) => ({
    ...provided,
    overflow: "visible",
  }),
  placeholder: (base, state) => ({
    ...base,
    position: "absolute",
    color: "#42a5f5",
    backgroundColor: "black",
    padding: "0px 5px",
    top:
      state.hasValue ||
      state.selectProps.inputValue ||
      state.selectProps.isFocused
        ? "-54%"
        : "0%",
    transition: "top 0.2s, font-size 0.2s",
    fontSize:
      (state.hasValue ||
        state.selectProps.inputValue ||
        state.selectProps.isFocused) &&
      14,
  }),
};

const MultiSelect = ({
  options,
  name,
  selected,
  handleChange,
  isClearable,
  isDisabled,
  placeholder,
  isSingle,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <>
      {isSingle ? (
        <Select
          components={{
            ValueContainer: CustomValueContainer,
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          isFocused={focused}
          closeMenuOnSelect={false}
          isMulti={false}
          options={options}
          placeholder={placeholder || name}
          value={selected}
          onChange={(value) => handleChange({ target: { value, name } })}
          isClearable={isClearable ? isClearable : false}
          isDisabled={isDisabled}
          styles={colourStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              text: "white",
              font: "white",
              primary25: "white",
              primary: "white",
              neutral80: "white",
              color: "white",
            },
          })}
        />
      ) : (
        <Select
          components={{
            ValueContainer: CustomValueContainer,
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          isFocused={focused}
          closeMenuOnSelect={false}
          isMulti
          options={options}
          placeholder={placeholder || name}
          value={selected}
          onChange={(value) => handleChange({ target: { value, name } })}
          isClearable={isClearable ? isClearable : false}
          isDisabled={isDisabled}
          styles={colourStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: 5,
            colors: {
              ...theme.colors,
              text: "white",
              font: "white",
              primary25: "white",
              primary: "white",
              neutral80: "white",
              color: "white",
            },
          })}
        />
      )}
    </>
  );
};

export default MultiSelect;
