import React, { useState } from "react";
import classes from "./freight.module.css";
import Content from "components/UI/Layout/Content";
import Input from "components/fields/Input";
import Select from "components/fields/Select";
import DateInput from "components/fields/DateInput";
import ButtonDiv from "components/UI/Layout/ButtonDiv";
import Button from "components/UI/Button/Button";
import Loader from "components/Loader/Loader";
import moment from "moment";
import { Industry } from "utils/Section/trade";
import CargoLoads from "./CargoLoads";
import Table from "react-bootstrap/Table";
import cn from "classnames";
import port_codes from "jsons/PortCodes.json";
import Currenciesr from "jsons/Currenciesr.json";
import { TextArea } from "components/fields";
import { Countries } from "utils/DropDown/Country";
import { Grid } from "@mui/material";
import MultiSelect from "components/fields/MultiSelect";

import OverLayTrigger from "components/UI/Toast/OverLayTrigger";

import { INSPECTION_OTHERS } from "utils/Section/FormInfo";

const InspectionReg = ({ regData, setRegData }) => {
  const [DatePicker1, setDatePicker1] = useState("");
  const [DatePicker2, setDatePicker2] = useState("");
  const [addCargo, setAddCargo] = useState(false);
  const [isHazardous, setIsHazardous] = useState(false);
  const [tabletransportLoadData, setTabletransportLoadData] = useState([]);
  const [selectStatus, setSelectStatus] = useState([]);
  let inspectionMedium = [
    {
      label: "Visual inspection",
      value: "Visual inspection",
    },
    {
      label: "Physical inspection",
      value: "Physical inspection",
    },
    {
      label: "Remote inspection",
      value: "Remote inspection",
    },
  ];

  let inspectionServices = [
    {
      label: "Pre-production Inspections",
      value: "Pre-production Inspections",
    },
    {
      label: " During Production Inspections ",
      value: " During Production Inspections",
    },
    {
      label: "Pre-shipment Inspections ",
      value: "Pre-shipment Inspections ",
    },
    {
      label: "Sample Checking ",
      value: "Sample Checking ",
    },
    {
      label: "Container loading Supervision ( CLS ) and Unloading supervision ",
      value: "Container loading Supervision ( CLS ) and Unloading supervision ",
    },
    {
      label: "CLC – Container Loading Check",
      value: "CLC – Container Loading Check",
    },
    {
      label: "IPC – Initial Production Check",
      value: "IPC – Initial Production Check",
    },
    {
      label: "Piece by Piece Inspection (or Sorting Inspection)",
      value: "Piece by Piece Inspection (or Sorting Inspection)",
    },
    {
      label: "Final Random Inspection",
      value: "Final Random Inspection",
    },
  ];

  let loadstype = [
    {
      label: "Full container loads",
      value: "Full container loads",
    },
    {
      label: "General Cargo",
      value: "General Cargo",
    },
    {
      label: "Boxes/packages",
      value: "Boxes/packages",
    },
  ];

  let Packaging = [
    {
      label: "Palettes",
      value: "Palettes",
    },
    {
      label: "Drums",
      value: "Drums",
    },
    {
      label: "Containers",
      value: "Containers",
    },
    {
      label: "Boxes",
      value: "Boxes",
    },
    {
      label: "Bulk",
      value: "Bulk",
    },
    {
      label: "Break Bulk",
      value: "Break Bulk",
    },

    { label: "Not relevant", value: "Not relevant" },
  ];

  let containers = [
    {
      label: "10-Foot Container",
      value: "10-Foot Container",
    },
    {
      label: "20-Foot Container",
      value: "20-Foot Container",
    },
    {
      label: "40-Foot Container",
      value: "40-Foot Container",
    },
    {
      label: "20-Foot High Cube",
      value: "20-Foot High Cube",
    },
    {
      label: "440-Foot High Cube",
      value: "40-Foot High Cube",
    },
    {
      label: "Not relevant",
      value: "Not relevant",
    },
  ];

  let samples = [
    {
      label: "No approved sample",
      value: "No approved sample",
    },
    {
      label: "Approved sample at factory",
      value: "Approved sample at factory",
    },
    {
      label: "Not relevant",
      value: "Not relevant",
    },
    {
      label: "Other",
      value: "Other",
    },
  ];

  let locations = [
    {
      label: "Supplier",
      value: "Supplier",
    },
    {
      label: "Freight forwarder",
      value: "Freight forwarder",
    },
    {
      label: "Logistic hub",
      value: "Logistic hub",
    },
    {
      label: "Warehouse",
      value: "Warehouse",
    },
    {
      label: "Building/site",
      value: "Building/site",
    },
  ];

  let regInspections = [
    {
      label: "REGULATIONS and STANDARDS",
      value: "REGULATIONS and STANDARDS",
    },
    {
      label: "ADMINISTRATIVE OR OPERATIONAL",
      value: "ADMINISTRATIVE OR OPERATIONAL",
    },
    {
      label: "Goods Distribution Practices",
      value: "Goods Distribution Practices",
    },
    {
      label: "Goods Manufacturing Practices",
      value: "Goods Manufacturing Practices",
    },
    {
      label: "Supplier Audit",
      value: "Supplier Audit",
    },
    {
      label: "Others",
      value: "Others",
    },
  ];

  // Pre-production Inspections (PPI)
  // (DPI/DUPRO)
  // (PSI)
  // (SC)
  //
  //
  //
  //
  //
  // Others ( if others is selected -- input field )
  // const handleInputChange = (e) => {
  //   setregData({
  //     ...regData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleChange = (e, date) => {
    if (date === "loadingDate" || date === "unloadingDate") {
      let newDate = new Date(e);
      let dateString = newDate.toISOString();
      let newDate1 = moment(newDate).format("YYYY-MM-DD");

      if (date === "loadingDate") {
        setDatePicker1(newDate1);
        // setRegData({
        //   ...regData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      if (date === "unloadingDate") {
        setDatePicker2(newDate1);
        // setRegData({
        //   ...regData,
        //   [e.target.name]: [e.target.value],
        // });
      }
      setRegData({
        ...regData,
        [date]: dateString,
      });
    } else if (e.target.name === "ServiceReqDescription") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "inspectionMedium") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "inspectionService") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "commodity") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "qouteBy") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "reportBy") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "loadsType") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "packed") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "packType") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "ServiceReqDescription") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "companyLocation") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "companyName") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "contactinfo") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    } else if (e.target.name === "address") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    }
  };
  const handleDateChange = (e, field) => {
    setRegData({
      ...regData,
      [field]: new Date(e).toISOString(),
    });
  };

  const handleMultiChange = (e, name) => {
    if (name === "commodity") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    }
    if (name === "regInspection") {
      setRegData({
        ...regData,
        [e.target.name]: e.target.value,
      });
    }
  };
  console.log(regData, "saurabh");

  return (
    <>
      <Content padding="1% 2% 1.5% 2%">
        <div style={{ margin: "10px" }}>
          <OverLayTrigger value={INSPECTION_OTHERS} />
        </div>
        <div style={{ margin: "10px" }}>Regulatory Inspection</div>

        <div style={{ margin: " 0px 10px" }}>
          Types of Regulatory Inspection(RI)* ?
        </div>
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
            <MultiSelect
              options={regInspections}
              name="regInspection"
              placeholder="Regulatory Inspection(RI)"
              handleChange={(e) => handleMultiChange(e, "regInspection")}
              selected={regData.regInspection}
              // readOnly={true}
            />
          </div>
        </div>

        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <Select
              options={inspectionMedium}
              name="inspectionMedium"
              placeholder="Inspection Medium*"
              handleChange={handleChange}
              value={regData.inspectionMedium}
              // readOnly={true}
            />
          </div>
        </div>
        {/* Countries */}
        {/* <label>What type of Inspection Services you need?*
</label> */}
        <Grid container>
          <div style={{ width: "50%", margin: "10px", paddingRight: "20px" }}>
            <MultiSelect
              options={Industry}
              name="commodity"
              placeholder="Product Category*"
              handleChange={(e) => handleMultiChange(e, "commodity")}
              selected={regData.commodity}
            />
          </div>
        </Grid>
        <hr style={{border:"2px solid white", margin:"10px 10px 10px 10px"}}></hr>
        <div>
          <div></div>
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px 10px 0px 10px" }}>
              <label>When do you need your inspection quote by?*</label>
            </div>
            <div style={{ width: "50%", margin: "10px 10px 0px 10px" }}>
              <label>When do you require your report by?*</label>
            </div>
          </div>
          {/* <Grid container>
                 
                <Grid item xs={12} md={5.9} style={{ padding: "10px" }}> */}
          <div className={classes.source}>
            <div style={{ width: "50%", margin: "10px" }}>
              <DateInput
                name="qouteBy"
                placeholder="Date *"
                value={regData.qouteBy}
                handleChange={(e) => handleDateChange(e, "qouteBy")}
              />
            </div>

            {/* </Grid> */}

            <div style={{ width: "50%", margin: "10px" }}>
              {/* <Grid item xs={12} md={5.9} style={{ padding: "10px" }}> */}
              <DateInput
                name="reportBy"
                placeholder="Date *"
                value={regData.reportBy}
                handleChange={(e) => handleDateChange(e, "reportBy")}
              />
              {/* </Grid> */}
            </div>
          </div>
        </div>

        <div>
          {/* <label>Give your own Inspection Specific Details Description


    
    </label> */}
        </div>

        <div className={classes.source}>
          <div style={{ width: "100%", margin: "10px" }}>
            <TextArea
              name="ServiceReqDescription"
              placeholder="Give your own short description of the job*"
              value={regData.ServiceReqDescription}
              handleChange={handleChange}
            />
          </div>
        </div>

        {/* <div style={{ marginBottom: "1.2%" , marginTop: "2%" }}>
                    <label>The company or the location is a ?

    
    
    </label>
                  </div> */}
        {/* <div style={{  width: "100%", marginBottom:"2%" }}>
                  <Select
                   options={locations}
                      name="companyLocation"
                      placeholder="Select *"
                      value={regData.companyLocation}
                      handleChange={handleChange}
                    />
                   
                  </div> */}
        <div className={classes.source}>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="companyName"
              placeholder="Company Name*"
              handleChange={handleChange}
              value={regData.companyName}
              // readOnly={true}
            />
          </div>
          <div style={{ width: "50%", margin: "10px" }}>
            <Input
              name="contactinfo"
              placeholder="Contact Information*"
              handleChange={handleChange}
              value={regData.contactinfo}
              // readOnly={true}
            />
          </div>
        </div>
        <div className={classes.source} style={{ margin: "10px" }}>
          <Input
            name="address"
            placeholder="Address*"
            handleChange={handleChange}
            value={regData.address}
            // readOnly={true}
          />
        </div>
      </Content>
    </>
  );
};

export default InspectionReg;
