export const InvoiceListHeader = [
  { name: "Invoice ID" },
  { name: "Buyer" },
  { name: "Seller" },
  { name: "Created Date" },
  { name: "Status" },
  { name: "Action" },
];

export const TrackingListHeader = [
  { name: "Carrier Number" },
  { name: "Container Number" },
  { name: "BoL Number" },
  { name: "Bookmark ID" },
  { name: "Last Updated" },
  { name: "Status" },
];

export const tradeListHeader = [
  { name: "Trade ID" },
  { name: "Buyer" },
  { name: "Seller" },
  { name: "Origin Country" },
  { name: "Destination Country" },
  { name: "Status" },
  { name: "Activity Date" },
  { name: "Action" },
];

export const participantHeader = [
  { name: "Participant Name" },
  { name: "Country" },
  { name: "Role" },
  { name: "Date Since" },
  { name: "Status" },
];

export const InvoiceStatus = [
  { label: "New Invoice", value: "NEW" },
  { label: "In-Pursuit", value: "IN_PURSUIT" },
  { label: "Financed", value: "FINANCED" },
  { label: "Closed", value: "CLOSED" },
  { label: "Blocked", value: "BLOCKED" },
  { label: "Open", value: "OPEN" },
  { label: "Pending", value: "PENDING" },
];

export const FinanceDealStatus = [
  { label: "New Finance", value: "NEW" },
  { label: "INTEREST_EXPRESSED", value: "INTEREST_EXPRESSED" },
  { label: "DUE_DILIGENCE_INITIATED", value: "DUE_DILIGENCE_INITIATED" },
  { label: "DUE_DILIGENCE_COMPLETED", value: "DUE_DILIGENCE_COMPLETED" },
  { label: "QUOTE_GENERATED", value: "QUOTE_GENERATED" },
  { label: "QUOTE_DECLINED", value: "QUOTE_DECLINED" },
  { label: "QUOTE_ACCEPTED", value: "QUOTE_ACCEPTED" },
  { label: "PENDING_T_AND_C_ACCEPTANCE", value: "PENDING_T_AND_C_ACCEPTANCE" },
  { label: "FINANCE_AGREEMENT_COMPLETE", value: "FINANCE_AGREEMENT_COMPLETE" },
  { label: "FINANCED", value: "FINANCED" },
  { label: "DECLINED", value: "DECLINED" },
  { label: "ABORTED", value: "ABORTED" },
  { label: "CLOSED", value: "CLOSED" },
];

export const FinanceCategory = [
  { label: "TERM_FINANCE", value: "TERM_FINANCE" },
  // { label: "GENERAL_FINANCE", value: "GENERAL_FINANCE" },
  { label: "SYNDICATE_FINANCE", value: "SYNDICATE_FINANCE" },
];

export const LoanStatus = [
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "INACTIVE", value: "INACTIVE" },
  { label: "DRAFT", value: "DRAFT" },
  { label: "IN_REVIEW", value: "IN_REVIEW" },
  { label: "CLOSED", value: "CLOSED" },
  { label: "LOCKED", value: "LOCKED" },
  { label: "BLOCKED", value: "BLOCKED" },
];

export const InvoiceTab = [
  { name: "Overview", value: "overview" },
  { name: "Invoices", value: "invoices" },
  { name: "Finance Deals", value: "finance-deals" },
  { name: "Loans", value: "loans" },
];

export const invoiceTabForFinancer = [
  { name: "Overview", value: "overview" },
  { name: "Finance Deals", value: "finance-deals" },
  { name: "Loans", value: "loans" },
]

export const InvoiceTabRestrict = [
  // { name: "Overview", value: "overview" },
  { name: "Invoices", value: "invoices" },
  { name: "Finance Deals", value: "finance-deals" },
  { name: "Loans", value: "loans" },
];

export const InvoiceOverview = [
  { value: "newInvoices", name: "New Invoices" },
  { value: "totalInvoiceAmount", name: "Total Invoices Amount" },
  { value: "pendingBuyerConsentRequests", name: "Buyer Consent Pending" },
  { value: "financedInvoices", name: "Invoices Financed" },
  { value: "totalFinanceApprovedAmount", name: "Invoice Financed Amount" },
  { value: "openFinanceDealsAmount", name: "Finance Deals Pending Amount" },
];
export const serviceOverview = [
  { value: "newServiceRequisitions", name: "New Requisitions" },
  { value: "openServiceRequisitions", name: "Open Requisitions" },
  { value: "closedRequisitions", name: "Closed Requisitions" },
  { value: "newServiceDeals", name: "New ServiceDeals" },
  { value: "openServiceDeals", name: "Open ServiceDeals" },
  { value: "inProgressServiceDeals", name: "In-Progress ServiceDeals" },
  { value: "approvedServiceDeals", name: "Approved ServiceDeals" },
  { value: "closedServiceDeals", name: "Closed ServiceDeals" },
  {
    value: "serviceDealsPaymentPending",
    name: "Payment Pending (ServiceDeals)",
  },
  { value: "serviceDealsCompleted", name: "Completed ServiceDeals" },
];

export const FinanceDealslistHeader = [
  { name: "Finance Id" },
  { name: "Requisition Id" },
  { name: "Financer" },
  { name: "Borrower" },
  { name: "Finance Category" },
  { name: "Status" },
  { name: "Last Activity Date" },
  { name: "Action" },
];

export const GeneralDetailList = [
  {
    name: "Invoice Reference Number",
    value: "invoiceNumber",
  },
  {
    name: "Tax Schema",
    value: "PENDING",
  },
  {
    name: "Supply Types",
    value: "21-Jan-2022",
  },
  {
    name: "Document Types code",
    value: "PENDING",
  },
  {
    name: "Document Number",
    value: "RS. 12M",
  },
  {
    name: "Date",
    value: "Invoice Docs uploaded",
  },
  {
    name: "Taxable Value Total",
    value: "100 T Wheat",
  },
  {
    name: "Total Invoice Value",
    value: "INV-0022234654",
  },
];

export const LoanTableHeader = [
  { name: "Loan Id" },
  { name: "Financer" },
  { name: "Borrower" },
  { name: "Date" },
  { name: "Status" },
  { name: "Action" },
];

export const FinanceDealsHeader = [
  { name: "Finance Id" },
  { name: "Financer" },
  { name: "Offered Interested Rate" },
  { name: "Status" },
  { name: "Last Activity Date" },
  { name: "Action" },
];

export const HistoryHeader = [
  { name: "Category" },
  { name: "Activity" },
  { name: "Actor" },
  { name: "Origin" },
  { name: "Activity Date" },
];

export const loan_product_type = [
  { label: "FINANCE_INVOICE", value: "FINANCE_INVOICE" },
  { label: "FINANCE_PURCHASE_ORDER", value: "FINANCE_PURCHASE_ORDER" },
  { label: "FINANCE_PAYBLES", value: "FINANCE_PAYBLES" },
  { label: "FINANCE_RECEIVABLES", value: "FINANCE_RECEIVABLES" },
];

export const disbursement_mode_options = [
  { label: "ACCOUNT_TRANSFER", value: "ACCOUNT_TRANSFER" },
  { label: "CHEQUE", value: "CHEQUE" },
  { label: "SI", value: "SI" },
];
