import React from "react";
import { BsCheck } from "react-icons/bs";

const DocumentGreenIcon = ({ color = "#34BE0F" }) => {
  return (
    <span style={{ position: "relative" }}>
      <svg
        style={{ position: "absolute", top: "0px", left: "-5" }}
        width="20"
        height="21"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.5245 10.7227C17.2082 11.2619 16.7803 11.6952 16.2417 12.0236C16.4057 12.6095 16.4115 13.2012 16.2592 13.7988C16.1068 14.3963 15.808 14.9181 15.3626 15.3631C14.9172 15.8081 14.3961 16.1063 13.7984 16.2599C13.2006 16.4114 12.5971 16.4056 11.9877 16.2424C11.683 16.7806 11.2619 17.2091 10.7224 17.5249C10.1829 17.8416 9.60938 18 9 18C8.39062 18 7.81617 17.8416 7.27763 17.5249C6.73811 17.2091 6.31601 16.7806 6.01229 16.2424C5.4262 16.4056 4.82846 16.4114 4.21908 16.2599C3.6097 16.1063 3.08183 15.8081 2.63741 15.3631C2.19202 14.9181 1.89315 14.3963 1.74081 13.7988C1.58749 13.2012 1.59429 12.6095 1.75828 12.0236C1.21876 11.6952 0.791806 11.2619 0.475472 10.7227C0.159138 10.1834 0 9.61017 0 9C0 8.3908 0.159138 7.81658 0.475472 7.27734C0.791806 6.7381 1.21876 6.30476 1.75828 5.97636C1.59429 5.39145 1.58749 4.79877 1.74081 4.20123C1.89315 3.60369 2.19202 3.08291 2.63741 2.63694C3.08183 2.19195 3.60388 1.89366 4.20162 1.74015C4.79935 1.58858 5.3903 1.59441 5.97639 1.75764C6.30437 1.21937 6.73811 0.790889 7.27763 0.475116C7.81617 0.158372 8.39062 0 9 0C9.60938 0 10.1829 0.158372 10.7224 0.475116C11.2619 0.790889 11.6947 1.21937 12.0236 1.75764C12.6087 1.59441 13.2006 1.58858 13.7984 1.74015C14.3961 1.89366 14.9172 2.19195 15.3626 2.63694C15.808 3.08291 16.1068 3.60369 16.2592 4.20123C16.4115 4.79877 16.4057 5.39145 16.2417 5.97636C16.7803 6.30476 17.2082 6.7381 17.5245 7.27734C17.8418 7.81658 18 8.3908 18 9C18 9.61017 17.8418 10.1834 17.5245 10.7227Z"
          fill={color}
        />
        <BsCheck fill="white" />
      </svg>
    </span>
  );
};
export default DocumentGreenIcon;
