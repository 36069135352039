import React from "react";
import ColumnList from "components/UI/ColumnList/ColumnList";
import { GeneralDetailList } from "utils/Section/InvoiceSection";
import classes from "./Column.module.css";

const GeneralDetail = ({ Data }) => {
  const Response = [
    {
      name: "Invoice Number",
      value: Data ? Data.invoiceNumber : "NA",
    },
    {
      name: "Tax Scheme",
      value: Data ? Data.invoiceDetails?.TranDtls?.TaxSch : "",
    },
    {
      name: "Supply Types",
      value: Data ? Data.invoiceDetails?.TranDtls?.SupTyp : "",
    },
    {
      name: "Document Date",
      value: Data ? Data.invoiceDetails?.DocDtls?.Dt : "",
    },
    {
      name: "Document Number",
      value: Data ? Data.invoiceDetails?.DocDtls?.No : "",
    },
    {
      name: "Document Types Code",
      value: Data ? Data.invoiceDetails?.DocDtls?.Type: "",
    },
    {
      name: "AssValue",
      value: Data ? Data.invoiceDetails?.ValDtls?.TotInvValFc : "",
    },
    {
      name: "Total Invoice Value",
      value: Data ? Data.txlInvoiceAmount : "",
    },
  ];
  return (
    <div className={classes.column} style={{margin:"1%", marginTop:"2%"}}>
      {Response &&
        Response.map((item, index) => (
          <div className={classes.columnList} key={index}>
            <div className={classes.columnContent}>
              <div style={{fontSize:"12px"}}>{item.name}</div>
              <span style={{ color: "#42A5F5", fontSize:"12px" }}>
                  {item.value}
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default GeneralDetail;
