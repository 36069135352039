import React from "react";
import styles from "../edit.module.css";
import cn from "classnames";
import Input from "components/fields/Input";

const CompanyContacts = ({ companyData, handleChange, contactNo }) => {
  const memberType = JSON.parse(sessionStorage.getItem("memberType"));

  return (
    <div className={styles.profileForm}>
      <div className={styles.heading2} style={{margin:"10px"}}>
        <span style={{fontSize:"18px"}}>Company Contacts</span>
      </div>

      {/* <div className={styles.inputDiv}>
        <div className={styles.inputDiv1}>
          <div></div>
          <div className={styles.textCenter}>WhatsApp</div>
          <div className={styles.textCenter}>SMS</div>
        </div>

        <div className={styles.inputDiv1}>
          <div>
            <Input
              value={contactNo.secondaryNo}
              name="companyPhoneSecondary"
              placeholder="Secondary Phone No."
              handleChange={(e) => handleChange(e)}
            />
          </div>
          <div className={cn(styles.textCenter, styles.Checkbox)}>
            <input type="checkbox" name="whatsapp" value="true" />
          </div>
          <div className={cn(styles.textCenter, styles.Checkbox)}>
            <input type="checkbox" name="sms" value="true" />
          </div>
        </div>
      </div> */}

      <div className={styles.FormDiv}>
        <div style={{margin:"10px"}}>
          <Input
            value={contactNo.primaryNo}
            name="companyPhonePrimary"
            placeholder="Primary Phone No*"
            handleChange={(e) => handleChange(e)}
          />
        </div>
        <div style={{margin:"10px"}}>
          <Input
            value={contactNo.secondaryNo}
            name="companyPhoneSecondary"
            placeholder="Secondary Phone No."
            handleChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <div className={styles.FormDiv}>
        <div style={{margin:"10px"}}>
          <Input
            name="twitterLink"
            value={companyData.twitterLink}
            placeholder="Twitter Link "
            handleChange={(e) => handleChange(e)}
          />
        </div>

        <div style={{margin:"10px"}}>
          <Input
            name="linkedInLink"
            value={companyData.linkedInLink}
            placeholder="Linkedin Link "
            handleChange={(e) => handleChange(e)}
          />
        </div>
      </div>

      <div className={styles.FormDiv}>
        <div style={{margin:"10px"}}>
          <Input
            name={memberType === "FINANCER" ? "financerEMail" : "companyEMail"}
            value={
              memberType === "FINANCER"
                ? companyData.financerEMail
                : companyData.companyEMail
            }
            placeholder="Email*"
            handleChange={(e) => handleChange(e)}
          />
        </div>
        <div style={{margin:"20px 10px 10px 10px"}}>
          <label For="CreatedBy" className={styles.LabelStyle}>
            Company Address *
          </label>
          <textarea
            type="text"
            value={
              memberType === "FINANCER"
                ? companyData.financerAddress
                : companyData.companyAddress
            }
            onChange={(e) => handleChange(e)}
            name={
              memberType === "FINANCER" ? "financerAddress" : "companyAddress"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyContacts;
